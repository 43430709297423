import { Box, Button, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React from 'react';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface ICareTeamGridTopProps {
  title: string;
  tooltip: string;
  buttonLabel: string;
  disabled: boolean;
  handleAdd: () => void;
}

export const CareTeamGridTop = (props: ICareTeamGridTopProps) => {
  const { title, tooltip, buttonLabel, handleAdd, disabled } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb="16px"
    >
      <Typography variant="subtitle1">{title}</Typography>
      <CustomTooltip title={tooltip ?? ''}>
        <span>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={disabled}
            startIcon={<Icon icon={ICONS.Add} size={14} color={COLORS.WHITE} />}
            onClick={handleAdd}
          >
            {buttonLabel}
          </Button>
        </span>
      </CustomTooltip>
    </Box>
  );
};
