import React from 'react';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import { DropdownComponent, DropdownType } from 'graphql/graphqlTypes';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';

export interface IDropdownProps {
  component: DropdownComponent;
  storageType: ChecklistStorageType;
  entityLookups: IEntityLookup[];
}

const StyledReviewTypography = styled(Typography)({
  color: COLORS.BLACK,
  fontWeight: 700,
});

const getLookupDisplayValue = (
  model: DropdownComponent,
  lookups: ILookup[]
) => {
  let displayValue: string | null | undefined = undefined;
  const lookup = lookups?.find((l: ILookup) => l.id === Number(model.lookupId));
  if (lookup) {
    const lookupValue = lookup.values.find(
      (lv: ILookupValue) => lv.id === Number(model.value)
    );
    if (lookupValue) {
      displayValue = lookupValue.name;
    }
  }
  return displayValue;
};

const getEntityDisplayValue = (
  model: DropdownComponent,
  entityLookups: IEntityLookup[],
  bigTableValues: IEntityLookup[]
) => {
  let displayValue: string | null | undefined = undefined;
  const entity = (model.isBigTable ? bigTableValues : entityLookups)?.find(
    (l: IEntityLookup) => l.name === model.entityName
  );
  if (entity) {
    const lookupValue = entity.values.find(
      (lv: ILookupValue) => lv.id === Number(model.value)
    );
    if (lookupValue) {
      displayValue = lookupValue.name;
    }
  }
  return displayValue;
};

const Dropdown = (props: IDropdownProps) => {
  const lookups = useSelector(
    (state: IState) =>
      state.checklist.documentsState[props.storageType].checklist?.lookups
  ) as ILookup[];

  const bigTableValues = useSelector(
    (state: IState) =>
      state.checklist.documentsState[props.storageType].checklist
        ?.bigTableValues
  ) as IEntityLookup[];

  const model = props.component;
  let displayValue = model.displayValue;
  if (!displayValue) {
    if (model.dropDownType === DropdownType.Lookup) {
      displayValue = getLookupDisplayValue(model, lookups);
    } else if (model.dropDownType === DropdownType.Entity) {
      displayValue = getEntityDisplayValue(
        model,
        props.entityLookups,
        bigTableValues
      );
    } else {
      displayValue = model.value;
    }
  }
  return (
    <StyledReviewTypography variant="body1">
      {displayValue}
    </StyledReviewTypography>
  );
};

export default Dropdown;
