import React from 'react';
import { BaseReminder } from 'graphql/graphqlTypes';
import { Box, Button, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { RemindersGrid } from 'components/home/RemindersGrid';
import { ILookupValue } from 'backend/types/lookupValue';
import { FilterGrid } from 'components/gridFormatters';
import { useGetEpisodeTypesQuery } from 'graphql/hooks/getEpisodeTypes';

interface IRemindersGrid {
  reminders: BaseReminder[];
  episodeReminders: BaseReminder[];
  handleAddReminder: () => void;
  updateFilter: (filter: ILookupValue) => void;
  selectedEpisodeRemindersFilter: ILookupValue;
  editingRowIds?: Array<number>;
  setEditingRowIds?: (rows: Array<number>) => void;
  isDisabled?: boolean;
  removeEditingRowId: (rowId: number) => void;
}

const OpenRemindersTab = ({
  reminders,
  episodeReminders,
  handleAddReminder,
  selectedEpisodeRemindersFilter,
  updateFilter,
  editingRowIds,
  setEditingRowIds,
  isDisabled,
  removeEditingRowId,
}: IRemindersGrid) => {
  const { data } = useGetEpisodeTypesQuery();
  const items: ILookupValue[] = [
    { id: -1, name: 'All' },
    ...(data?.getEpisodeTypes?.map<ILookupValue>((x) => ({
      id: x.id,
      name: x.name ?? '',
    })) ?? []),
  ];

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="16px"
      >
        <Typography variant="subtitle1" style={{ paddingBottom: '16px' }}>
          Member Reminders
        </Typography>
        <Button
          variant="contained"
          size="small"
          disabled={editingRowIds?.length !== 0}
          color="primary"
          startIcon={<Icon icon={ICONS.Add} size={14} color={COLORS.WHITE} />}
          onClick={handleAddReminder}
        >
          Add Reminder
        </Button>
      </Box>
      <Box>
        <RemindersGrid
          reminders={reminders}
          readonly={false}
          showEpisodeIdColumn={false}
          setEditingRowIds={setEditingRowIds}
          editingRowIds={editingRowIds}
          isDisabled={isDisabled}
          removeEditingRowId={removeEditingRowId}
        />
      </Box>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        pb="16px"
        pt="30px"
      >
        <Typography variant="subtitle1">Episode Reminders</Typography>
        <FilterGrid
          selectedItemId={selectedEpisodeRemindersFilter.id}
          onChange={updateFilter}
          items={items}
        />
      </Box>
      <Box>
        <RemindersGrid
          reminders={episodeReminders}
          showEpisodeTypeColumn
          readonly
          showEpisodeIdColumn={false}
          isDisabled={isDisabled}
        />
      </Box>
    </Box>
  );
};
export default OpenRemindersTab;
