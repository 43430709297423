import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import React, { ReactElement } from 'react';
import { EditingCell } from 'components/gridFormatters';
import { familyColumn } from '../family';
import { CaregiverCell } from './CaregiverCell';
import { RelationshipEditCell } from './RelationshipEditCell';
import { TextEditorCell } from './TextEditorCell';
import { ConsentToShareCell } from './ConsentToShareCell';
import { GridEditCell } from 'components/gridFormatters/GridEditCell';

export const FamilyEditCell = (
  props: TableEditRow.CellProps
): ReactElement<TableEditRow.CellProps> => {
  const { column } = props;
  switch (column.name) {
    case familyColumn.isCaregiver:
      return <CaregiverCell {...props} />;
    case familyColumn.relationshipId:
      return <RelationshipEditCell {...props} />;
    case familyColumn.name:
      return <GridEditCell {...props} />;
    case familyColumn.note:
      return <TextEditorCell {...props} />;
    case familyColumn.consentToShare:
      return <ConsentToShareCell {...props} />;
    default:
      return <EditingCell {...props} />;
  }
};
