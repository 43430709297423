import React from 'react';
import Divider from '@mui/material/Divider';
import { Patient, QuickNoteType } from 'graphql/graphqlTypes';
import { formatPatientAge } from 'components/scenario/patient/PatientCard.helper';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import styled from 'styled-components';
import { Ellipsis } from 'components/text';

const StyledDivider = styled(Divider)({
  marginLeft: '16px',
  marginRight: '16px',
  backgroundColor: COLORS.WHITE,
  height: '24px',
  marginTop: '0px',
  opacity: '0.2',
});
const StyledIcon = styled(Icon)({
  marginTop: '5px',
  marginLeft: '16px',
  marginRight: '16px',
});

const StyledTypography = styled(Typography)`
  display: inline;
`;

export interface IQuickNoteTitleProps {
  patientInfo?: Patient | null;
  type: QuickNoteType;
}

const QuickNoteTitle = (props: IQuickNoteTitleProps) => {
  const { patientInfo, type } = props;

  const title = type === QuickNoteType.Episode ? 'Episode Note' : 'Member Note';
  return (
    <>
      {patientInfo && patientInfo.mRN ? (
        <div
          style={{ width: '100%', display: 'flex', color: COLORS.PALE_GREY }}
        >
          <StyledTypography
            noWrap
            variant="subtitle1"
            style={{ minWidth: '100px' }}
          >
            {title}
          </StyledTypography>
          <StyledDivider orientation="vertical" flexItem />
          <Ellipsis>
            <StyledTypography noWrap variant="subtitle2">
              {patientInfo.fullName}
            </StyledTypography>
          </Ellipsis>
          <StyledIcon
            icon={ICONS.Critical}
            size={10}
            height={'15'}
            color={COLORS.LIGHT_GREY}
          />
          <Ellipsis>
            <StyledTypography noWrap variant="subtitle2">
              {formatPatientAge(patientInfo)}
            </StyledTypography>
          </Ellipsis>
          <StyledIcon
            icon={ICONS.Critical}
            size={10}
            height={'15'}
            color={COLORS.LIGHT_GREY}
          />
          <Ellipsis>
            <StyledTypography noWrap variant="subtitle2">
              Patient ID {patientInfo.mRN}
            </StyledTypography>
          </Ellipsis>
        </div>
      ) : null}
    </>
  );
};

export default QuickNoteTitle;
