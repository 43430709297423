import React from 'react';
import { Typography } from '@mui/material';
import bgImg from 'assets/img/gettyimages-1027587952-2048x2048.jpg';
import logo from 'assets/img/Symphony-Logo-White.png';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const LoginLeftWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
  background-size: cover !important;
  background: url(${bgImg});
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #013566;
  opacity: 0.6;
`;

const LoginLeftContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 5%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Banner = styled.div`
  line-height: 1.22;
  color: ${COLORS.WHITE};
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-bottom: 200px;
`;

const Footer = styled.div`
  display: flex;
  color: ${COLORS.WHITE};
  margin-bottom: 50px;
`;

const StyledLogo = styled.img`
  max-width: 250px;
  height: auto;
  margin-top: 5%;
`;

export const LoginLeft = () => {
  return (
    <div style={{ display: 'flex', width: '50%' }}>
      <LoginLeftWrapper>
        <Background />
      </LoginLeftWrapper>
      <LoginLeftContainer>
        <div>
          <StyledLogo alt="Symphony" src={logo} />
        </div>
        <Banner data-testid="banner-message">
          <Typography variant="h1">Better Workflows.</Typography>
          <Typography variant="h1">Better Outcomes.</Typography>
          <Typography
            variant="h1"
            style={{ fontWeight: 500, fontSize: '60px' }}
          >
            Powerfully Simple.
          </Typography>
        </Banner>
        <Footer>
          <Typography variant="h6" style={{ color: COLORS.WHITE }}>
            © {new Date().getFullYear()} Advantasure Inc. Patents Pending.
          </Typography>
        </Footer>
      </LoginLeftContainer>
    </div>
  );
};
