import React, { useEffect, useState } from 'react';
import { useGetUserProvidersSelectModelQuery } from 'graphql/hooks/getUserProvidersSelectModel';
import ProvidersList from 'components/roleslist/ProvidersList';

const SelectProviderForm = () => {
  const [currentCareSiteId, setCurrentCareSiteId] = useState<number>(0);

  const {
    data: providersSelectModel,
    isSuccess: isProvidersSelectLoaded,
    isFetching: isFetchingProvidersSelect,
  } = useGetUserProvidersSelectModelQuery();

  useEffect(() => {
    if (
      isProvidersSelectLoaded &&
      !isFetchingProvidersSelect &&
      providersSelectModel?.getUserProvidersSelectModel?.careSites.length == 1
    ) {
      setCurrentCareSiteId(
        providersSelectModel.getUserProvidersSelectModel.careSites[0].id
      );
    }
  }, [
    providersSelectModel,
    isProvidersSelectLoaded,
    isFetchingProvidersSelect,
  ]);

  return (
    <ProvidersList
      padding="0"
      selectedCareSiteId={currentCareSiteId}
      providersSelectModel={providersSelectModel}
      isFetchingProvidersSelect={isFetchingProvidersSelect}
      isProvidersSelectLoaded={isProvidersSelectLoaded}
    />
  );
};

export default SelectProviderForm;
