import TableCell from '@mui/material/TableCell';
import React, { useState } from 'react';
import { CellComponentStyle } from 'components/gridFormatters';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { ILookupValue } from 'backend/types/lookupValue';
import RemoteAutocomplete from 'components/autocomplete/RemoteAutocomplete';
import { validateRequired } from 'util/validationUtils';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { useLazySuggestProvidersQuery } from 'graphql/hooks/suggestProviders';

export const ProviderAutocompleteCell = (props: TableEditRow.CellProps) => {
  const { value, onValueChange } = props;

  const currentProviders =
    useSelector(
      (state: IState) => state.patientDetailsCareTeam.updatedProviders
    )?.map((provider) => provider.id) ?? [];

  const [providerSearch, setProviderSearch] = useState(value?.name ?? '');
  const [getProviders, { data }] = useLazySuggestProvidersQuery();

  const [error, setError] = useState<boolean>(
    validateRequired(value?.id, true).hasError
  );

  const onChange = (newValue: ILookupValue | null) => {
    setError(validateRequired(newValue?.id, true).hasError);
    onValueChange({ id: newValue?.id, name: newValue?.name });
  };

  return (
    <TableCell style={CellComponentStyle}>
      <RemoteAutocomplete
        inputValue={providerSearch}
        onInputChange={setProviderSearch}
        suggestOptions={() => getProviders({ term: providerSearch })}
        options={
          data?.suggestProviders
            ?.filter((x) => !currentProviders.includes(x.id))
            ?.map((p) => ({ id: p.id, name: p.name } as ILookupValue)) ?? []
        }
        value={
          value && value.id > 0
            ? ({ id: value.id, name: value.name } as ILookupValue)
            : null
        }
        onChange={onChange}
        error={error}
        noOptionsText="Please enter 3 or more characters"
      />
    </TableCell>
  );
};
