import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import TimelineTypeFormatter from '../formatters/TimelineTypeFormatter';

const TimelineTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TimelineTypeFormatter} {...props} />
);

export default TimelineTypeProvider;
