import React from 'react';
import { HealthServiceCodesActionValue } from 'graphql/graphqlTypes';
import { StyledServiceRowDiv } from './styles';
import { ServiceRowReview } from './ServiceRowReview';
import { DecisionGroup } from './DecisionGroup';

export interface IServiceRowFinalOutComeProps {
  item: HealthServiceCodesActionValue;
  determinationReasonName?: string | null;
}

export const ServiceRowFinalOutCome = (props: IServiceRowFinalOutComeProps) => {
  const { item, determinationReasonName } = props;

  return (
    <>
      <StyledServiceRowDiv>
        <ServiceRowReview
          type="Requested"
          hideDates={false}
          hideValues={false}
          item={item}
        />

        <ServiceRowReview
          type="Approved"
          hideDates={false}
          hideValues={false}
          item={item}
        />
      </StyledServiceRowDiv>
      <DecisionGroup
        determinationDecision={
          item.healthServiceActionValue?.determinationDecision
        }
        determinationReason={determinationReasonName}
      />
    </>
  );
};
export default ServiceRowFinalOutCome;
