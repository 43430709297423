import React from 'react';
import ServerAutocomplete from 'components/autocomplete/ServerAutocomplete';
import { ILookupValue } from 'backend/types/lookupValue';
import { IDropdownProps } from './Dropdown';

export interface IDropdownBigTableProps extends IDropdownProps {
  handleChange: (value: ILookupValue | null) => void;
  hasError: boolean;
  initValue?: ILookupValue;
}

export const DropdownBigTable = (props: IDropdownBigTableProps) => {
  const {
    component,
    handleSuggestItems,
    handleChange,
    isActionSelected,
    isReadOnly,
    hasError,
    initValue,
  } = props;
  return (
    <ServerAutocomplete
      data-testid={`ServerAutocomplete_${component.uniqueID}`}
      disabled={isReadOnly}
      initValue={initValue}
      suggestItems={(
        request: string,
        callback: (arg: ILookupValue[]) => void
      ) => {
        if (component.entityName && request && request.length >= 3) {
          handleSuggestItems(component.entityName, request, callback);
        }
      }}
      onChange={(event, value) => handleChange(value)}
      error={hasError && isActionSelected}
      color={component.color ?? undefined}
    />
  );
};
