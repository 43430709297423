import React, { useState } from 'react';
import { PatientClinicHospital } from 'graphql/graphqlTypes';
import { Grid as MuiGrid } from '@mui/material';
import {
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import {
  IdProvider,
  SelectedRow,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { claimColumns } from './columnSettings/claimColumns';
import { claimGridColumns } from './columnSettings/claimGridColumns';
import Loader from 'components/loader';
import { ClaimColumnExtension } from 'components/home/claimsList/columnSettings/claimColumnExtension';
import { COLORS } from 'consts/styles';
import SearchBar from 'components/searchbar';
import ClaimsDateProvider from 'components/home/claimsList/providers/ClaimsDateProvider';
import { ClaimsDetails } from './ClaimsDetails';

export interface IClaimsProps {
  claims: PatientClinicHospital[];
  isFetching: boolean;
}

const ClaimsGrid = (props: IClaimsProps) => {
  const { claims, isFetching } = props;

  const [searchTerm, setSearchTerm] = useState('');

  const [selectedClaimId, setSelectedClaimId] = useState<number | null>(null);
  const handleSelectionChange = (values: Array<number | string>) => {
    if (values.length > 1) {
      setSelectedClaimId(Number(values[1]));
    }
  };

  const isFullWidth = selectedClaimId === null;
  return (
    <div
      style={{ display: 'flex', flex: '1', height: '100%', paddingTop: '24px' }}
    >
      <MuiGrid container spacing={6} data-cy="claims-grid">
        <MuiGrid
          item
          xs={isFullWidth ? 12 : 8}
          style={{
            borderRight: isFullWidth ? '0' : `1px solid ${COLORS.GREY25}`,
          }}
        >
          <SearchBar
            onSubmit={(x) => {
              setSearchTerm(x);
              setSelectedClaimId(null);
            }}
            placeholder="Search..."
            containerStyle={{ marginBottom: '20px' }}
          />
          <Grid
            rows={claims}
            columns={claimGridColumns}
            getRowId={(row: PatientClinicHospital) => row.id}
          >
            <SelectionState
              selection={[selectedClaimId as number]}
              defaultSelection={[0]}
              onSelectionChange={handleSelectionChange}
            />
            <IntegratedSelection />
            <ClaimsDateProvider for={[claimColumns.claimDate]} />
            <IdProvider for={[claimColumns.claimId]} />
            <SortingState
              defaultSorting={[
                { columnName: claimColumns.claimDate, direction: 'desc' },
              ]}
              columnExtensions={ClaimColumnExtension}
            />
            <SearchState value={searchTerm} />
            <IntegratedSorting columnExtensions={ClaimColumnExtension} />
            <IntegratedFiltering />
            <SymphonyTable columnExtensions={ClaimColumnExtension} />
            <SymphonyTableHeaderRow showSortingControls />
            <TableSelection
              selectByRowClick
              highlightRow
              rowComponent={SelectedRow}
              showSelectionColumn={false}
            />
          </Grid>
        </MuiGrid>
        <MuiGrid item xs={4} hidden={isFullWidth} style={{ padding: '20px' }}>
          <ClaimsDetails
            selectedClaimId={selectedClaimId}
            setSelectedClaimId={setSelectedClaimId}
            claims={claims}
          />
        </MuiGrid>
      </MuiGrid>

      <Loader active={isFetching} />
    </div>
  );
};

export default ClaimsGrid;
