import React from 'react';
import { useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { openFaxFlowModal } from 'store/ui/modals/searchPatient';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { COLORS } from 'consts/styles';
import { EPISODE_SOURCE_FAX } from '../consts';
import { EpisodeTypes } from 'components/home/patientdetails/tabscontent/episodesTab/consts';
import {
  AppealGrievTabName,
  CasesTabName,
  EpisodesTabName,
  OrgDetTabName,
} from 'components/home/patientdetails/DialogBody.helpers';

const EpisodeNumberLink = styled(Link)({
  root: {
    width: '120px',
    height: '16px',
    color: COLORS.SYMPHONY_BLUE,
    cursor: 'pointer',
    lineHeight: '20px',
  },
});

const StyledSpan = styled.span`
  font-weight: 500;
`;

export const getEpisodeTabName = (episodeType: EpisodeTypes | string) => {
  switch (episodeType.toLowerCase()) {
    case 'cases':
    case 'case':
      return CasesTabName;
    case 'orgdet':
      return OrgDetTabName;
    case 'member appeal':
    case 'provider appeal':
    case 'grievance':
    case 'appeal':
    case 'incident':
    case 'appealgriev':
      return AppealGrievTabName;
    default:
      return EpisodesTabName;
  }
};

export const getEpisodeRemindersTabName = (episodeType: string) => {
  return episodeType ? getEpisodeTabName(episodeType) : 'Reminders';
};

export const getEpisodeRemindersSubTabName = (episodeType: string) => {
  const tabName = getEpisodeRemindersTabName(episodeType);
  return episodeType && (tabName === 'Episodes' || tabName === 'Cases')
    ? 'Reminders'
    : '';
};

const EpisodeNumberFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const dispatch = useDispatch();

  const handleClick = (e: {
    stopPropagation: () => void;
    nativeEvent: { stopImmediatePropagation: () => void };
  }) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    let patientId: number | undefined;
    if (props.row.patientId) {
      patientId = Number(props.row.patientId);
    }

    if (
      !patientId &&
      props.row.id &&
      props.row.EpisodeSource !== EPISODE_SOURCE_FAX
    ) {
      patientId = Number(props.row.id);
    }

    if (!patientId) {
      // We should always have attachment here
      if (Number(props.row.episodeAttachmentId) > 0) {
        dispatch(
          openFaxFlowModal({
            episodeId: Number(props.row.episodeId),
            episodeAttachmentId: Number(props.row.episodeAttachmentId),
          })
        );
        window.open(
          `/FaxPreview/${props.row.episodeAttachmentId}`,
          '_blank',
          'menubar=1,resizable=1,width=1350,height=1250'
        );
      }
      return;
    }
    dispatch(
      openPatientDetailsModal({
        patientId,
        episodeId: Number(props.row.episodeId ?? props.row.id),
        activeTabName: getEpisodeTabName(props.row.EpisodeType),
        activeSubTabName: '',
      })
    );
  };

  return (
    <>
      {props.value ? (
        <EpisodeNumberLink onClick={handleClick}>
          <CustomTooltip
            title={getEpisodeTabName(props.row.EpisodeType) ?? ''}
            containerElement="span"
          >
            <StyledSpan>{props.value}</StyledSpan>
          </CustomTooltip>
        </EpisodeNumberLink>
      ) : null}
    </>
  );
};

export default EpisodeNumberFormatter;
