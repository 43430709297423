import React from 'react';
import StyledLink from './StyledLink';
import { IChecklistLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { useDispatch } from 'react-redux';
import { updateNonMozartDocumentState } from 'store/ui/print/documents';

interface IChecklistPrintLinkProps extends IChecklistLinkProps {
  label?: string;
}

const ChecklistPrintLink = (props: IChecklistPrintLinkProps) => {
  const dispatch = useDispatch();

  const handlePrint = (checklistId: number) => {
    dispatch(
      updateNonMozartDocumentState({
        selectedChecklistIds: [checklistId],
      })
    );
  };

  return (
    <>
      <CustomTooltip
        title={props.title ?? 'Generates a PDF version of the document'}
      >
        <StyledLink
          onClick={() => handlePrint(Number(props.checklistId))}
          target={'blank'}
          data-cy="Print"
        >
          Print
        </StyledLink>
      </CustomTooltip>
    </>
  );
};

export default ChecklistPrintLink;
