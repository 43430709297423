import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import Reminder from 'components/actions/sections/SectionBody/Components/Reminder';
import { COLORS } from 'consts/styles';
import { ChecklistStorageType } from 'store/actions/types';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistViewModel, ReminderComponent } from 'graphql/graphqlTypes';
import { useGetEpisodeRemindersQuery } from 'graphql/hooks/getEpisodeReminders';
import { getCompletedChecklistReminders } from 'features/mozart/MozartWorkflowLayout.helpers';

const ReminderBox = styled(Box)({
  margin: '36px 0 14px 0',
  display: 'flex',
  flexDirection: 'row',
});

export const MozartReminders = () => {
  const mozartCompletedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );
  const completedChecklists = Object.values(mozartCompletedChecklists);
  const episodeId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART]
        .episodeId as number
  );

  const { data: episodeReminders } = useGetEpisodeRemindersQuery(
    { episodeId: episodeId },
    { skip: !episodeId }
  );

  const getReminders = (
    completedChecklists: ChecklistViewModel[]
  ): ReminderComponent[] => {
    const completedChecklistReminders =
      getCompletedChecklistReminders(completedChecklists);

    return completedChecklistReminders.filter(
      (c) =>
        episodeReminders?.getEpisodeReminders.some(
          (e: { id: number }) => e.id === c.reminderId
        ) &&
        (c.isReminderModified || c.isReminderCreated)
    );
  };

  const reminders = getReminders(completedChecklists);
  if (reminders.length > 0) {
    return (
      <>
        <ReminderBox>
          <Typography variant="h4" data-testid="Header">
            Created Actions
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: COLORS.GREY100,
              marginLeft: '5px',
            }}
          >
            ({reminders.length})
          </Typography>
        </ReminderBox>
        {reminders.map((reminder: ReminderComponent, itemIndex: number) => (
          <Box
            display="flex"
            flexDirection="column"
            key={reminder.id}
            style={{
              backgroundColor: `${
                !(itemIndex & 1) ? COLORS.WHITE : COLORS.PALE_GREY
              }`,
              width: '100%',
            }}
          >
            <Reminder
              component={reminder}
              disabled={true}
              categoryId=""
              handleChecklistInputChange={() => void 0}
              autoSave={false}
              storageType={ChecklistStorageType.MOZART}
              isActionSelected={true}
              isReadOnly={true}
            />
          </Box>
        ))}
      </>
    );
  } else {
    return null;
  }
};
