import React from 'react';
import TextFilter from './editors/TextFilter';
import DateFilter from './editors/DateFilter';
import BoolFilter from './editors/BoolFilter';
import LookupFilter from './editors/LookupFilter';
import IntFilter from './editors/IntFilter';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { IEditorProps } from 'backend/types/grid/IEditorProps';

const FilterEditor = (props: TableFilterRow.EditorProps) => {
  const typedProps = props as IEditorProps;
  if (!(typedProps.filteringEnabled && typedProps.column.filterType)) {
    return null;
  }
  switch (typedProps.column.filterType) {
    case 'TextAttributeType':
      return <TextFilter {...typedProps} />;
    case 'DateAttributeType':
    case 'DateTimeAttributeType':
      return <DateFilter {...typedProps} />;
    case 'BoolAttributeType':
      return <BoolFilter {...typedProps} />;
    case 'IntAttributeType':
      return <IntFilter {...typedProps} />;
    case 'LookupAttributeType':
    case 'ReferenceAttributeType':
      return <LookupFilter {...typedProps} />;
    default:
      return <TextFilter {...typedProps} />;
  }
};

export default FilterEditor;
