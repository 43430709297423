import { UpdateActionValueModelInput } from 'graphql/graphqlTypes';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { FailedRequestItem } from 'store/failedRequests/failedRequestsSlice';

const useComponentHasFailedRequest = (id: string, componentId?: string) => {
  const failedRequests = useSelector(
    (state: IState) => state.failedRequest.requests ?? []
  );

  return failedRequests.some((item: FailedRequestItem) => {
    const itemData = item.data as UpdateActionValueModelInput;
    if (!componentId) {
      return itemData.id === id;
    }

    return (
      itemData.id === id && componentId && itemData.componentId === componentId
    );
  });
};

export default useComponentHasFailedRequest;
