import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React from 'react';
interface IConditionIconProps {
  style?: React.CSSProperties;
  className?: string;
}
export const ConditionIcon = (props: IConditionIconProps) => {
  const { style, className } = props;
  return (
    <Icon
      icon={ICONS.Condition_Arrow}
      size={12}
      color={COLORS.BLACK}
      style={style ?? { marginLeft: '2px' }}
      title="Conditional Logic"
      className={className}
    />
  );
};
