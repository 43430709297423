import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { Box } from '@mui/material';

export const ComponentStyledLabel = styled(Box)<{
  $error: boolean;
  $color?: string;
}>(({ $error, $color }) => ({
  color: $error ? COLORS.RED100 : $color ?? COLORS.BLACK10,
}));
