import { LockMozartActionRequestName } from 'components/failedRequests/FailedRequests';
import { useLockMozartActionMutation } from 'graphql/hooks/lockMozartAction';
import { useDispatch } from 'react-redux';
import {
  addFailedRequest,
  removeFailedRequest,
} from 'store/failedRequests/failedRequestsSlice';

export const getLockMozartActionIdToRetry = (
  workflowInstanceId: string,
  actionInstanceId: string
) => {
  return `${workflowInstanceId}-${actionInstanceId}`;
};

const useLockMozartActionImmediately = () => {
  const dispatch = useDispatch();
  const [lockMozartAction] = useLockMozartActionMutation();

  const dispatchLockMozartActionFailed = (
    workflowInstanceId: string,
    actionInstanceId: string,
    message?: string | null
  ) => {
    dispatch(
      addFailedRequest({
        name: LockMozartActionRequestName,
        idToRetry: getLockMozartActionIdToRetry(
          workflowInstanceId,
          actionInstanceId
        ),
        variables: {
          workflowInstanceId,
          actionInstanceId,
        },
        message: message,
      })
    );
  };

  return (
    workflowInstanceId: string,
    actionInstanceId: string,
    onSuccess?: () => void
  ) => {
    return lockMozartAction({
      workflowInstanceId: workflowInstanceId,
      actionInstanceId: actionInstanceId,
    }).then((result) => {
      if ('error' in result) {
        dispatchLockMozartActionFailed(workflowInstanceId, actionInstanceId);
        return;
      } else {
        const data = result.data;
        if (!data.lockMozartAction?.result) {
          dispatchLockMozartActionFailed(
            workflowInstanceId,
            actionInstanceId,
            data.lockMozartAction?.message
          );
          return;
        }
      }

      if (onSuccess) {
        onSuccess();
      }

      dispatch(
        removeFailedRequest(
          getLockMozartActionIdToRetry(workflowInstanceId, actionInstanceId)
        )
      );
    });
  };
};

export default useLockMozartActionImmediately;
