import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';

export const StubCellComponent = (props: Table.CellProps) => (
  <th
    colSpan={props.colSpan}
    rowSpan={props.rowSpan}
    style={{
      fontSize: '12px',
      fontWeight: 'normal',
      lineHeight: 'normal',
      padding: '10px 2px 5px 0px',
      color: COLORS.BLACK,
      backgroundColor: COLORS.GREY10,
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      height: '24px',
      maxHeight: '40px',
      borderTopLeftRadius: '4px',
    }}
    data-cy="table-cell"
  />
);
