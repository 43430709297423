import { PatientCareTeamItem } from './types';
import {
  PatientCareTeamFamilyViewModel,
  PatientCareTeamMemberViewModel,
  PatientCareTeamProviderViewModel,
  PatientCondition,
} from 'graphql/graphqlTypes';

export const getAvailableId = <T>(entities: T[] | null | undefined): number => {
  return !entities ? -1 : (entities?.length ?? 0) * -1 - 1;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const hasAnyError = (errors: {}): boolean =>
  Object.values(errors).some(Boolean);

export const isConditionsValid = (rows: PatientCondition[]): boolean => {
  return rows.every((value) => value.status);
};

export const isTeamMembersValid = (
  rows: PatientCareTeamMemberViewModel[]
): boolean => {
  return rows.every((value) => value.member?.id);
};

export const validateTeamMember = (
  teamMembers: PatientCareTeamMemberViewModel[],
  isOwnerRequired: boolean
): { hasError: boolean; message: string } => {
  if (isOwnerRequired) {
    const isOwnerSelected = teamMembers?.some(
      (teamMember) => teamMember.isOwner
    );
    return {
      hasError: isOwnerSelected !== true || isOwnerSelected === undefined,
      message: `Please select Owner.`,
    };
  }
  return {
    hasError: false,
    message: '',
  };
};

export const isProvidersValid = (
  rows: PatientCareTeamProviderViewModel[]
): boolean => {
  return rows.every((value) => value.provider?.id);
};

export const isFamilyValid = (
  rows: PatientCareTeamFamilyViewModel[]
): boolean => {
  return rows.every((value) => value.name);
};

export const getUpdatedRows = <T extends PatientCareTeamItem>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowChanges: { [p: string]: any },
  rows: T[]
) => {
  return rows.map((item) => ({ ...item, ...(rowChanges[item.id] ?? {}) }));
};
