import React from 'react';
import { useSelector } from 'react-redux';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import { ChecklistViewModel } from 'graphql/graphqlTypes';
import Sections from 'components/actions/sections/sections';
import ReviewSections from 'components/review/sections';
import { itemsWithProblem } from './problems.helper';

export type CarePlanProblemRowDetailsProps = TableRowDetail.ContentProps & {
  isReadOnly: boolean;
};

export const CarePlanProblemRowDetails = (
  props: CarePlanProblemRowDetailsProps
) => {
  const { isReadOnly, row } = props;
  const problemId = Number(row.id);
  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN]?.checklist
  ) as ChecklistViewModel;
  const problemChecklist: ChecklistViewModel = {
    ...checklist,
    categories:
      checklist?.categories.map((category) => ({
        ...category,
        items: category.items.filter(itemsWithProblem(problemId)),
      })) ?? [],
  };
  const checklistItems = problemChecklist.categories?.flatMap(
    (category) => category?.items
  );

  if (checklistItems.length === 0) {
    return null;
  }

  return isReadOnly ? (
    <ReviewSections
      {...props}
      checklist={problemChecklist}
      storageType={ChecklistStorageType.CAREPLAN}
    />
  ) : (
    <Sections
      storageType={ChecklistStorageType.CAREPLAN}
      checklist={problemChecklist}
      autoSave
      hideNumbers
      disableSelection
    />
  );
};
