import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type AmendChecklistMutationVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
}>;

export type AmendChecklistMutation = {
  __typename?: 'Mutation';
  amendChecklist?: number | null;
};

export const AmendChecklistDocument = `
    mutation amendChecklist($checklistId: Int!) {
  amendChecklist(checklistId: $checklistId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    amendChecklist: build.mutation<
      AmendChecklistMutation,
      AmendChecklistMutationVariables
    >({
      query: (variables) => ({ document: AmendChecklistDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAmendChecklistMutation } = injectedRtkApi;
