import React, { useState } from 'react';
import { MenuItem, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import Menu from 'components/menu';
import { IExportOption } from 'components/home/TabsBody';

export const ExportToggleButton = ({
  handleTriggerExportGridData,
  buttonMessage,
}: {
  handleTriggerExportGridData: (exportOptions: IExportOption) => void;
  buttonMessage: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const hasSelection = useSelector(
    (state: IState) => state.home.homeModel.selection
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (hasSelection && hasSelection.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      handleTriggerExportGridData({
        isExportingGridData: true,
        exportSelection: false,
      });
    }
  };
  const handleMenuItemClick = (exportAll: boolean) => {
    handleTriggerExportGridData({
      isExportingGridData: true,
      exportSelection: exportAll,
    });
    handleClose();
  };
  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={buttonMessage} placement="bottom" enterDelay={300}>
        <IconButton
          onClick={handleButtonClick}
          data-testid="save-icon"
          style={{
            marginLeft: '-4px',
            marginRight: '-4px',
            width: '40px',
          }}
          size="large"
        >
          <Icon size={20} icon={ICONS.ExportNew} color={COLORS.SYMPHONY_BLUE} />
        </IconButton>
      </Tooltip>
      <Menu open={menuOpen} onClose={handleClose} anchor={anchorEl}>
        <MenuItem onClick={() => handleMenuItemClick(false)}>
          Export all data
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(true)}>
          Export selected rows
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportToggleButton;
