import { ChecklistCategory } from 'graphql/graphqlTypes';

export const GetHtmlId = (name: string, id: string): string =>
  `${name.replace(/[^\w]+/g, '-').toLowerCase()}-${id}`;

export const isCompletedCategory = (category: ChecklistCategory) => {
  return (
    category?.items.some((item) => item.isVisible && item.isSelected) ?? false
  );
};

export const isVisibleCategory = (category: ChecklistCategory) => {
  return category?.items.some((x) => x.isVisible) ?? false;
};
