import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
  conditionsColumnNames,
  editCondition,
  labelCondition,
} from './conditions';
import ConditionsGrid from './conditionsGrid';
import Loader from 'components/loader';
import { useCommitConditionsChanges } from './conditions.hooks';
import { PatientCondition } from 'graphql/graphqlTypes';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import {
  setIsInEditMode,
  updateDirtyTabs,
} from 'store/patientdetails/patientDetailsSlice';
import { useDispatch } from 'react-redux';
import { ChangeSet } from '@devexpress/dx-react-grid';

export interface IConditionsProps {
  patientId: number;
  isReadOnly?: boolean;
  handleUpdatePatientTabValid: (valid: boolean) => void;
}

const ConditionsTab = (props: IConditionsProps) => {
  const dispatch = useDispatch();
  const { conditionsData, conditionsCommitChanges } =
    useCommitConditionsChanges(props);

  const [editingRowIds, setEditingRowIds] = useState<number[]>([]);
  const isInEditMode = editingRowIds.length > 0;

  const conditionsColumns = [
    {
      name: conditionsColumnNames.description,
      title: 'Description',
    },
    { name: conditionsColumnNames.code, title: 'Code' },
    {
      name: conditionsColumnNames.status,
      title: 'Status',
      getCellValue: (row: PatientCondition) => ({
        status: row?.status ?? false,
        isReadOnly: props.isReadOnly || isInEditMode,
      }),
    },
    { name: conditionsColumnNames.note, title: 'Notes' },
  ];

  const onAddCondition = () => {
    const ids = (conditionsData ?? []).map((x) => Math.abs(x.id)) ?? [];
    const nextId = ids.length > 0 ? -1 * (Math.max(...ids) + 1) : -1;
    const newRow = {
      id: nextId,
      status: true,
      description: '',
      note: '',
    } as PatientCondition;
    conditionsCommitChanges({ added: [newRow] });
    setEditingRowIds([...editingRowIds, nextId]);
  };

  const commitGridChanges = (changeSet: ChangeSet) => {
    conditionsCommitChanges(changeSet);
    dispatch(updateDirtyTabs('Conditions'));
  };

  useEffect(() => {
    dispatch(setIsInEditMode({ isInEditMode: isInEditMode }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRowIds]);

  if (!conditionsData) {
    return <Loader active={true} />;
  }

  return (
    <Grid container spacing={3} style={{ marginTop: 0 }}>
      <Grid item>
        <Box mb="32px">
          <Box
            display="flex"
            justifyContent="space-between"
            pb="12px"
            alignItems="center"
          >
            <Typography variant="subtitle1">Conditions</Typography>
            {!props.isReadOnly ? (
              <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={isInEditMode}
                startIcon={
                  <Icon icon={ICONS.Add} size={14} color={COLORS.WHITE} />
                }
                onClick={onAddCondition}
              >
                Add
              </Button>
            ) : null}
          </Box>
          <ConditionsGrid
            rows={conditionsData}
            columns={conditionsColumns}
            commitChanges={commitGridChanges}
            editCell={editCondition}
            labelCell={labelCondition}
            getRowId={(row) => row.id}
            handleUpdatePatientTabValid={props.handleUpdatePatientTabValid}
            isReadOnly={props.isReadOnly}
            editingRowIds={editingRowIds}
            setEditingRowIds={setEditingRowIds}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConditionsTab;
