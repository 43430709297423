import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeSummaryHistoryQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeSummaryHistoryQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeSummaryHistory: Array<{
    __typename?: 'EpisodeSummary';
    id: number;
    timeStamp?: any | null;
    summary?: string | null;
    author?: string | null;
  }>;
};

export const GetEpisodeSummaryHistoryDocument = `
    query getEpisodeSummaryHistory($episodeId: Int!) {
  getEpisodeSummaryHistory(episodeId: $episodeId) {
    id
    timeStamp
    summary
    author
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeSummaryHistory: build.query<
      GetEpisodeSummaryHistoryQuery,
      GetEpisodeSummaryHistoryQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeSummaryHistoryDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEpisodeSummaryHistoryQuery,
  useLazyGetEpisodeSummaryHistoryQuery,
} = injectedRtkApi;
