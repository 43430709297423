/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch } from 'react-redux';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { Link } from '@mui/material';
import { COLORS } from 'consts/styles';
import { getEpisodeTabName } from './EpisodeNumberFormatter';
import Icon, { ICONS } from 'components/icon';
import { mapClockHoursToColor } from 'util/helpers/clockHelper';
import { ClockState } from 'graphql/graphqlTypes';
import moment from 'moment';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { EPISODE_SOURCE_FAX } from '../consts';

const ClockLink = styled(Link)({
  root: {
    width: '120px',
    height: '16px',
    cursor: 'pointer',
    color: COLORS.BLACK,
    verticalAlign: 'middle',
  },
});

const StyledWrapper = styled.div`
  display: flex;
`;

const StyledSpan = styled.div`
  margin-left: 5px;
`;

const EpisodesListClockFormatter = (props: DataTypeProvider.ValueFormatterProps) => {

  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch(
      openPatientDetailsModal({
        patientId: Number(
          props.row.patientId ??
            (props.row.EpisodeSource !== EPISODE_SOURCE_FAX
              ? props.row.id
              : undefined)
        ),
        episodeId: parseInt(props.row.episodeId),
        activeTabName: getEpisodeTabName(props.row.EpisodeType),
        activeSubTabName: props.value.startDateTime ? 'Counter' : 'TAT',
      })
    );
  };

  return (
    <StyledWrapper>
      {props.value ? (
        <>
          {props.value.state === ClockState.Completed && <span>DONE</span>}
          {props.value.state === ClockState.Error && <span>ERROR</span>}
          {props.value.state === ClockState.Paused && (
            <>
              <Icon icon={ICONS.Clock} size={24} color={COLORS.YELLOW100} />
              <StyledSpan>PAUSED</StyledSpan>
            </>
          )}
          {props.value.state === ClockState.Active &&
            props.value.dueDateTime && (
              <>
                <Icon
                  icon={ICONS.ClockFilled}
                  size={18}
                  color={mapClockHoursToColor(
                    moment(props.value.dueDateTime).toDate()
                  )}
                />
                <ClockLink onClick={handleClick}>
                  <CustomTooltip
                    title={getEpisodeTabName(props.row.EpisodeType) ?? ''}
                  >
                    <StyledSpan>{props.value.timeRemaining}</StyledSpan>
                  </CustomTooltip>
                </ClockLink>
              </>
            )}
          {props.value.state === ClockState.Active &&
            props.value.startDateTime && (
              <>
                <Icon
                  icon={ICONS.ClockFilled}
                  size={18}
                  color={COLORS.SYMPHONY_BLUE}
                />
                <ClockLink onClick={handleClick}>
                  <CustomTooltip
                    title={getEpisodeTabName(props.row.EpisodeType) ?? ''}
                  >
                    <StyledSpan>{props.value.timeElapsed}</StyledSpan>
                  </CustomTooltip>
                </ClockLink>
              </>
            )}
        </>
      ) : null}
    </StyledWrapper>
  );
};

export default EpisodesListClockFormatter;
