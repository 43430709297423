import React from 'react';
import { Box, Grid } from '@mui/material';
import { BaseReminder, Tag } from 'graphql/graphqlTypes';
import { IconFallBack } from 'components/IconFallBack';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import Icon, { ICONS } from 'components/icon';
import { mapStatusToIconColor } from 'util/helpers/iconHelpers';

interface ITagProps {
  tags: Tag[];
  patientTopReminder?: BaseReminder;
}

const Tags = ({ tags, patientTopReminder }: ITagProps) => {
  if (!tags && !patientTopReminder) {
    return null;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      style={{ paddingTop: '4px', marginBottom: '4px' }}
    >
      <Grid item style={{ padding: '6px' }}>
        <CustomTooltip
          title={
            patientTopReminder?.importance
              ? `Reminder status: ${patientTopReminder.importance}`
              : 'Reminder status'
          }
        >
          <Box>
            <Icon
              icon={ICONS.Bell}
              size={18}
              color={mapStatusToIconColor(patientTopReminder?.importance ?? '')}
            />
          </Box>
        </CustomTooltip>
      </Grid>
      {tags &&
        tags.map((tag) => (
          <Grid item key={tag.id} style={{ padding: '6px' }}>
            {tag?.name ? (
              <IconFallBack
                name={tag?.name ?? ''}
                image={tag?.imagePath ?? ''}
                type="Tag"
                iconSize={18}
                description={tag.description ?? ''}
              />
            ) : null}
          </Grid>
        ))}
    </Grid>
  );
};

export default Tags;
