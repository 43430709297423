import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetAdvancedSearchRequiredFieldsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAdvancedSearchRequiredFieldsQuery = {
  __typename?: 'EntitiesQuery';
  getAdvancedSearchRequiredFields?: Array<string | null> | null;
};

export const GetAdvancedSearchRequiredFieldsDocument = `
    query getAdvancedSearchRequiredFields {
  getAdvancedSearchRequiredFields
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdvancedSearchRequiredFields: build.query<
      GetAdvancedSearchRequiredFieldsQuery,
      GetAdvancedSearchRequiredFieldsQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAdvancedSearchRequiredFieldsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAdvancedSearchRequiredFieldsQuery,
  useLazyGetAdvancedSearchRequiredFieldsQuery,
} = injectedRtkApi;
