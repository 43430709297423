import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { resetChecklist } from 'store/actions/checklistSlice';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import {
  MozartDefinitionType,
  MozartProgressState,
  MozartActionType,
} from 'graphql/graphqlTypes';
import { FooterContainer } from 'components/footer/FooterContainer';
import { resetMozartState } from 'store/actions/mozartSlice';
import { MozartOutOfTurnSteps } from '..';
import { MozartNextStepFooter } from 'features/mozart/components/MozartFooter/MozartNextStepFooter';
import { MozartFinishFooter } from 'features/mozart/components/MozartFooter/MozartFinishFooter';
import { isWorkflowInErrorState } from 'features/mozart/MozartWorkflowLayout.helpers';

const StyledFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledFinishButtonsWrapper = styled.div`
  display: flex;
  padding-right: 20px;
`;
const StyledActionsWrapper = styled.div`
  display: flex;
`;

const StyledCompletedActionsWrapper = styled.div`
  padding-left: 25%;
`;

export const MozartFooter = () => {
  const dispatch = useDispatch();

  const messages = useSelector((state: IState) => state.mozart.mozartMessages);

  const mozartWorkflowInstanceState = useSelector(
    (state: IState) => state.mozart.mozartWorkflowInstanceState
  );

  const isWorkflowCompleted = messages.some(
    (message) =>
      message.type === MozartDefinitionType.Workflow &&
      message.state === MozartProgressState.Completed
  );

  const hasWorkflowFailed =
    messages.some((message) => isWorkflowInErrorState(message)) ||
    mozartWorkflowInstanceState?.toUpperCase() ==
      MozartProgressState.Failed.toString().toUpperCase() ||
    mozartWorkflowInstanceState?.toUpperCase() ==
      MozartProgressState.PermanentFailure.toString().toUpperCase();

  const isMozartWorkflowInRoleCareSite = messages.some(
    (message) =>
      message.state === MozartProgressState.Paused &&
      message.actionType === MozartActionType.None &&
      message.type === MozartDefinitionType.Action
  );

  const resetState = () => {
    dispatch(resetChecklist(ChecklistStorageType.MOZART));
    dispatch(resetMozartState());
  };

  if (isWorkflowCompleted) {
    return (
      <FooterContainer data-testid="ReviewFooter" shouldHideLoader>
        <StyledCompletedActionsWrapper>
          <MozartOutOfTurnSteps isWorkflowFailed={hasWorkflowFailed} />
        </StyledCompletedActionsWrapper>
        <StyledFinishButtonsWrapper>
          <MozartFinishFooter handleResetState={resetState} />
        </StyledFinishButtonsWrapper>
      </FooterContainer>
    );
  }

  return (
    <StyledFooterWrapper data-testid="StyledFooterWrapper">
      <StyledActionsWrapper>
        <MozartOutOfTurnSteps isWorkflowFailed={hasWorkflowFailed} />
      </StyledActionsWrapper>
      <StyledButtonsWrapper data-testid="mozart-footer">
        <MozartNextStepFooter
          handleResetState={resetState}
          isWorkflowFailed={hasWorkflowFailed}
          isMozartWorkflowInRoleCareSite={isMozartWorkflowInRoleCareSite}
        />
      </StyledButtonsWrapper>
    </StyledFooterWrapper>
  );
};
