import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetProviderAttributesQueryVariables = Types.Exact<{
  providerId: Types.Scalars['Int'];
  providerAddressId?: Types.InputMaybe<Types.Scalars['Int']>;
  patientId: Types.Scalars['Int'];
}>;

export type GetProviderAttributesQuery = {
  __typename?: 'EntitiesQuery';
  getProviderAttributes?: Array<{
    __typename?: 'KeyValuePairOfStringAndString';
    key?: string | null;
    value?: string | null;
  } | null> | null;
};

export const GetProviderAttributesDocument = `
    query getProviderAttributes($providerId: Int!, $providerAddressId: Int, $patientId: Int!) {
  getProviderAttributes(
    providerId: $providerId
    providerAddressId: $providerAddressId
    patientId: $patientId
  ) {
    key
    value
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProviderAttributes: build.query<
      GetProviderAttributesQuery,
      GetProviderAttributesQueryVariables
    >({
      query: (variables) => ({
        document: GetProviderAttributesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetProviderAttributesQuery,
  useLazyGetProviderAttributesQuery,
} = injectedRtkApi;
