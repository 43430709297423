import React, { useState } from 'react';
import { EntityAttributeComponent } from 'graphql/graphqlTypes';
import { ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import { ValidationWrapper } from 'components/validation/ValidationWrapper';
import { validateRequired } from 'util/validationUtils';
import { useDispatch } from 'react-redux';
import { setChecklistComponentValidation } from 'store/actions/checklistSlice';
import { IComponentProps } from '../types';

import MultiSelect, { IDropdownItem } from 'components/select/MultiSelect';
import MultiAutocomplete from 'components/autocomplete/MultiAutocomplete';

export interface IMultiSelectAttributeProps
  extends IComponentProps<EntityAttributeComponent> {
  lookup?: ILookup;
  selectedValues: ILookupValue[];
  categoryId: string;
  handleSuggestItems: (
    entity: string,
    request: string,
    callback: (arg: ILookupValue[]) => void
  ) => void;
  isReadOnly: boolean;
}

const MultiSelectAttribute = (props: IMultiSelectAttributeProps) => {
  const {
    lookup,
    component,
    selectedValues,
    categoryId,
    isReadOnly,
    storageType,
    handleChecklistInputChange,
    handleSuggestItems,
  } = props;
  const dispatch = useDispatch();
  const selectedIds = selectedValues.map((val: ILookupValue) => val.id);
  const [entries, setEntries] = useState(lookup?.values ?? []);

  const Select = component.autocomplete ? MultiAutocomplete : MultiSelect;

  return (
    <ValidationWrapper
      validation={(value: number[]) => {
        const toValidate = value.join(',');
        // TODO: required validation should handle any type of value
        const result = validateRequired(toValidate, component.required);
        dispatch(
          setChecklistComponentValidation({
            storageType,
            error: {
              uuid: component.uniqueID,
              error: result.message ?? '',
              fieldName: component.userDefinedId ?? component.componentType,
              categoryId: categoryId,
              isValid: !result.message,
            },
          })
        );

        return result.hasError;
      }}
    >
      <Select
        value={selectedIds}
        items={entries.map((val: ILookupValue) => val as IDropdownItem)}
        disabled={isReadOnly}
        onChange={(values: number[]) => {
          handleChecklistInputChange(
            values.join(','),
            { ...component },
            component.id
          );
        }}
        onInputChange={
          component.isBigTable
            ? (input) => {
                component.entityName &&
                  input.length >= 3 &&
                  handleSuggestItems(input, component.entityName, (values) => {
                    setEntries(values ?? []);
                  });
              }
            : undefined
        }
      />
    </ValidationWrapper>
  );
};

export default MultiSelectAttribute;
