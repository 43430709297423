import React from 'react';
import { Table, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { CellComponentStyle } from 'components/gridFormatters/CellComponentStyle';
import { COLORS } from 'consts/styles';

export const HomeCellComponent = (props: Table.DataCellProps) => (
  <VirtualTable.Cell
    {...props}
    style={
      props.row.PatientIsActive === true || props.row.PatientIsActive === 'Yes'
        ? CellComponentStyle
        : { ...CellComponentStyle, color: COLORS.RED100 }
    }
    data-cy="table-cell"
  >
    {props.children}
  </VirtualTable.Cell>
);
