import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ReminderComponent } from 'graphql/graphqlTypes';
import moment from 'moment';
import { IValidationResult } from 'util/validationUtils';
import { IComponentProps } from '../types';
import { useChecklistValidation } from 'components/actions/sections/SectionBody/Items/Orderable';
import {
  MOMENT_DATETIME_ISO_FORMAT,
  MOMENT_ISO_FORMAT,
} from 'components/constants';
import { getDate, validateDate } from './index.helpers';
import { ChecklistStorageType } from 'store/actions/types';
import { StyledWrapper } from './index';
import styled from 'styled-components';
import { usePrevious } from 'hooks';
import { isEqual } from 'date-fns';
import DateComponent from 'components/DateComponent';

export interface IReminderProps extends IComponentProps<ReminderComponent> {
  disabled?: boolean;
  autoSave: boolean;
  isReadOnly: boolean;
}

interface ReminderDateProps {
  component: ReminderComponent;
  disabled?: boolean;
  autoSave: boolean;
  isReadOnly: boolean;
  isActionSelected: boolean;
  categoryId: string;
  storageType: ChecklistStorageType;
  handleReminderInputChange: (
    value: string | Date | null,
    reminderComponentType: string
  ) => void;
}

const StyledInnerWrapper = styled.div`
  margin-right: 10px;
`;
const StyledTypography = styled(Typography)`
  color: ${COLORS.BLACK};
`;

const ReminderDate = (props: ReminderDateProps) => {
  const {
    component,
    disabled,
    isActionSelected,
    categoryId,
    storageType,
    autoSave,
    isReadOnly,
    handleReminderInputChange,
  } = props;

  const reduxDateDue = useSelector((state: IState) =>
    getDate(
      (
        state.checklist.documentsState[storageType]?.checklistComponents[
          component.uniqueID
        ]?.component as ReminderComponent
      )?.dateDue
    )
  );

  const getInitDueDateValue = () => {
    if (reduxDateDue) {
      return reduxDateDue;
    }
    if (component.dateDue) {
      return moment(component.dateDue).toDate();
    }
    return null;
  };

  const [dateDue, changeDateDue] = useState<Date | null>(getInitDueDateValue());

  const [dateValidation, setDateValidation] = useState<IValidationResult>(
    validateDate(dateDue, isActionSelected)
  );

  const previousDateDue = usePrevious(dateDue);
  const previousReduxDate = usePrevious(reduxDateDue);

  useEffect(() => {
    if (
      isEqual(
        new Date(previousDateDue as unknown as Date),
        new Date(reduxDateDue as unknown as Date)
      ) ||
      isEqual(
        new Date(previousReduxDate as unknown as Date),
        new Date(reduxDateDue as unknown as Date)
      )
    ) {
      return;
    }
    if (!previousDateDue && !reduxDateDue) {
      return;
    }
    if (
      component.dateDue &&
      moment(reduxDateDue).format(MOMENT_DATETIME_ISO_FORMAT).toString() ===
        moment(component.dateDue).format(MOMENT_DATETIME_ISO_FORMAT).toString()
    ) {
      return;
    }

    if (reduxDateDue !== undefined) {
      handleDateChange(reduxDateDue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDateDue]);

  useChecklistValidation(
    dateValidation.hasError && isActionSelected,
    autoSave,
    isActionSelected,
    isReadOnly,
    component.uniqueID + '-date',
    categoryId,
    component.userDefinedId || component.componentType,
    dateValidation.message ?? 'Reminder date is invalid',
    storageType
  );

  useEffect(() => {
    const resultDate = validateDate(dateDue, isActionSelected);
    setDateValidation(resultDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionSelected]);

  const [saveIsPending, setIsPending] = useState(true);
  const handleDateChange = (date: Date | null) => {
    changeDateDue(date as Date);
    const result = validateDate(date as Date, isActionSelected);
    setDateValidation(result);
    if (date && !result.hasError) {
      handleReminderInputChange(
        moment(date).format(MOMENT_ISO_FORMAT),
        'DateDue'
      );
      if (saveIsPending) {
        handleReminderInputChange('false', 'IsDateDuePending');
        setIsPending(false);
      }
    }
  };

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledTypography variant="body1">
          Date due{disabled && ':'}
        </StyledTypography>
      </StyledInnerWrapper>
      <StyledWrapper id={`uuid-${component.uniqueID}-date`}>
        {!disabled ? (
          <DateComponent
            readOnly={isReadOnly}
            testId="reminder-date-due-input"
            value={dateDue}
            placeholder="mm/dd/yyyy"
            onChange={handleDateChange}
            error={dateValidation.hasError && isActionSelected && !isReadOnly}
          />
        ) : (
          <Typography variant="body2">
            {moment(dateDue).format('MM/DD/yyyy')}
          </Typography>
        )}
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default ReminderDate;
