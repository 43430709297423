import { COLORS } from 'consts/styles';
import moment from 'moment';

export const mapClockHoursToColor = (dueDate: Date) => {
  const hours = (dueDate.getTime() - moment().toDate().getTime()) / 3600000;
  if (hours >= 48) {
    return COLORS.COMPLETED_GREEN;
  } else if (hours >= 24) {
    return COLORS.YELLOW100;
  } else if (hours >= 0) {
    return COLORS.ORANGE100;
  } else {
    return COLORS.RED100;
  }
};
