import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { TableSelection } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)<{ $someSelected: boolean }>`
  .MuiIconButton-label:after {
    display: ${(props) => (props.$someSelected ? 'none' : 'block')};
  }
  &:not(checked) .MuiIconButton-label:after {
    left: 7px !important;
    top: 7px !important;
  }
`;

const HeaderSelectCell = (props: TableSelection.HeaderCellProps) => {
  const { someSelected, allSelected, ...restProps } = props;
  return (
    <td
      style={{
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: COLORS.GREY10,
        padding: '10px 0px 5px 10px',
        maxWidth: '32px',
      }}
    >
      <StyledCheckbox
        icon={<Icon icon={ICONS.Empty} size={24} color={COLORS.LIGHT_GREY} />}
        indeterminateIcon={
          <Icon
            icon={ICONS.Partially_Selected}
            size={14}
            color={COLORS.SYMPHONY_BLUE}
            style={{ marginTop: '-4px' }}
          />
        }
        indeterminate={someSelected}
        checked={allSelected}
        $someSelected={someSelected}
        onChange={(event) => props.onToggle(event.target.checked)}
        checkedIcon={
          <Icon icon={ICONS.Selected} size={24} color={COLORS.SYMPHONY_BLUE} />
        }
        {...restProps}
      />
    </td>
  );
};

export default HeaderSelectCell;
