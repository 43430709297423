import React from 'react';
import { Patient } from 'graphql/graphqlTypes';
import { Box, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { formatAddress } from 'components/scenario/patient/PatientCard.helper';

interface IInfoItem {
  key: string;
  value?: string | null;
}

interface IPatientInfoProps {
  patient: Patient;
  backgroundColor?: string;
}
const StyledText = styled.span`
  font-weight: 400;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 4px;
`;

const StyledWrapper = styled.div`
  padding-right: 5px;
`;

const Info = (props: IPatientInfoProps) => {
  const { patient, backgroundColor } = props;

  const col1: IInfoItem[] = [
    { key: 'Home', value: patient?.homePhone },
    { key: 'Cell', value: patient?.cellPhone },
    { key: 'Alt 1', value: patient?.alternatePhone },
    { key: 'Alt 2', value: patient?.alternatePhone2 },
  ].filter((item) => item.value);
  const col2: IInfoItem[] = [
    { key: 'Email', value: patient?.email },
    { key: 'Language', value: patient?.language?.name },
    { key: 'PCP', value: patient?.primaryCareProvider?.name },
    { key: 'PCP#', value: patient?.primaryCareProvider?.preferredPhone },
    { key: 'PCP Fax', value: patient?.primaryCareProvider?.preferredFax },
  ].filter((item) => item.value);

  const hasDetails = col1.length > 0 || col2.length > 0;
  const formattedAddress = formatAddress(patient);

  if (!hasDetails) {
    return null;
  }

  return (
    <Box
      py={1}
      px="24px"
      style={{
        backgroundColor: backgroundColor ?? 'transparent',
      }}
    >
      <Grid container direction="row">
        {col1.length > 0 ? (
          <Grid item xs={6}>
            {col1.map((item: IInfoItem, index) => (
              <StyledItem key={`info-${index}`}>
                <StyledWrapper>
                  <Typography variant="body2">{item.key}:</Typography>
                </StyledWrapper>
                <div>
                  <Typography>{item.value}</Typography>
                </div>
              </StyledItem>
            ))}
          </Grid>
        ) : null}
        <Grid item xs={6}>
          {col2?.map((item: IInfoItem, index) => (
            <StyledItem key={`info-${index}`}>
              <StyledWrapper>
                <Typography variant="body2">{item.key}:</Typography>
              </StyledWrapper>
              <div>
                <Typography>{item.value}</Typography>
              </div>
            </StyledItem>
          ))}
        </Grid>
        {formattedAddress !== '' ? (
          <Grid item xs={12}>
            <div>
              <Typography variant="body2">
                Address: <StyledText>{formattedAddress}</StyledText>
              </Typography>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default Info;
