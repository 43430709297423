import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { COLORS } from 'consts/styles';
import React from 'react';
import styled from 'styled-components';

const theme = {
  small: {
    width: '136px',
    height: '32px',
  },
  medium: {
    width: '308px',
    height: '54px',
  },
};

const StyledSelect = styled(Select)<SelectProps>(
  ({ fullWidth, size }: SelectProps) => ({
    width: fullWidth ? 'inherit' : theme[size ?? 'medium'].width,
    height: theme[size ?? 'medium'].height,
    '.MuiSelect-root': {
      paddingLeft: '17px',
    },
    paper: {
      borderRadius: '4px',
      border: '1px solid #CECECE',
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    },
  })
);

export interface ICountryCodeSelectorProps {
  countryCodes: { name: string; id: string }[];
  selectedCountryCodeId: string;
  setSelectedCountryCodeId: (value: string) => void;
  title: string;
  fullWidth?: boolean;
  size?: 'medium' | 'small';
  id?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  className?: string;
  color?: string;
  width?: string;
}

export const CountryCodeSelector = ({
  countryCodes,
  selectedCountryCodeId,
  setSelectedCountryCodeId,
  title,
  fullWidth,
  width,
  color,
  ...rest
}: ICountryCodeSelectorProps) => {
  if (countryCodes.length > 0) {
    return (
      <Box>
        <Typography
          variant="body1"
          style={{ marginBottom: '8px', color: COLORS.GREY100 }}
        >
          {title}
        </Typography>
        <FormControl
          variant="outlined"
          style={{
            width: fullWidth ? '100%' : '330px',
            marginBottom: '8px',
          }}
        >
          <StyledSelect
            style={{ minWidth: width ?? '134px', color: color }}
            fullWidth={fullWidth}
            data-testid="country-selector"
            value={selectedCountryCodeId}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              style: {
                left: '0',
                marginTop: '0px',
              },
              PaperProps: {
                style: {
                  left: '0',
                  marginTop: {
                    medium: '4px',
                    small: '0px',
                  }[rest.size ?? 'medium'],
                  borderRadius: '4px',
                  border: '1px solid #CECECE',
                  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
                  maxHeight: '400px',
                  minWidth: {
                    medium: '306px',
                    small: '134px',
                  }[rest.size ?? 'medium'],
                },
              },
            }}
          >
            {countryCodes.map((item) => {
              const result = [
                <MenuItem
                  value={item.id}
                  key={item.id}
                  onClick={() => setSelectedCountryCodeId(item.id)}
                >
                  {item.name}
                </MenuItem>,
              ];
              if (item.id == '') {
                result.push(
                  <Divider data-testid="divider" key={`${item.id}-divider`} />
                );
              }
              return result;
            })}
          </StyledSelect>
        </FormControl>
      </Box>
    );
  }

  return null;
};
