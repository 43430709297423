import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/styles';

const StyledMenuItem = styled(MenuItem)({
  padding: '2px 20px',
});

const StyledMenuItemText = styled(ListItemText)({
  display: 'block',
  margin: '0px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  primary: {
    display: 'inline',
    fontWeight: 400,
  },
});

const StyledTextField = styled(TextField)`
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 0;
  min-height: 32px;
`;

const StyledAutocomplete = MuiStyled(Autocomplete)({
  minWidth: '300px',
  maxWidth: '300px',
  minHeight: '32px',
  '& * .MuiInputBase-root': { gap: '5px', backgroundColor: '#FFF' },
  '& * .MuiChip-deleteIcon': { height: '14px', color: COLORS.GREY100 },
  '& * .MuiChip-label': { padding: '4px 8px 4px 14px' },
  '& * .MuiChip-root': {
    height: '22px',
    fontSize: '12px',
    margin: '0px !important',
  },
  '& .MuiOutlinedInput-root': {
    minHeight: '32px',
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
  },
  '& .MuiPaper-root': {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#CECECE',
  },
  '& .MuiAutocomplete-option': {
    padding: '0px',
    display: 'block',
    backgroundColor: '#FFF !important',
  },
});

export interface IAutocompleteItem {
  sortOrder?: number;
  id: number;
  name: string;
}

export interface IMultiAutocompleteProps {
  error?: boolean;
  items: IAutocompleteItem[];
  value?: number[];
  onChange: (selected: number[]) => void;
  onInputChange?: (input: string) => void;
}

const MultiAutocomplete = (props: IMultiAutocompleteProps) => {
  const { items, error, value, onChange, onInputChange } = props;
  const [selected, setSelected] = useState<number[]>(value ?? []);
  const [input, setInput] = useState<string>('');
  const isMounted = useRef<boolean>();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      onChange(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (isMounted) {
      onInputChange?.(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const order = items.reduce(
    (acc: { [id: number]: number }, item) => (
      (acc[item.id] = item.sortOrder ?? item.id), acc
    ),
    {}
  );

  const changeSelected = <T,>(_: SyntheticEvent<T>, selValue: unknown) => {
    const newValue = selValue as number[];
    newValue.sort((a: number, b: number) => (order[a] > order[b] ? 1 : -1));
    setSelected(newValue);
  };

  const changeInput = <T,>(_: SyntheticEvent<T>, inputValue: string) =>
    setInput(inputValue);

  const options = items.map(({ id }: IAutocompleteItem) => id);
  const getItemById = (id: number) =>
    items.find((item: IAutocompleteItem) => item.id === id);

  return (
    <StyledAutocomplete
      data-testid="MultiAutocomplete"
      multiple
      options={options}
      disableCloseOnSelect
      value={selected}
      onChange={changeSelected}
      onInputChange={changeInput}
      getOptionLabel={(option) =>
        getItemById(option as number)?.name ?? 'unknown'
      }
      renderOption={(params, option, { selected: optSelected }) => (
        <StyledMenuItem {...params}>
          <Checkbox checked={optSelected} color="primary" />
          <StyledMenuItemText
            primary={getItemById(option as number)?.name ?? 'unknown'}
          />
        </StyledMenuItem>
      )}
      size="small"
      renderInput={(params) => (
        <StyledTextField
          {...params}
          error={error}
          variant="outlined"
          fullWidth={true}
          placeholder="search..."
        />
      )}
    />
  );
};

export default MultiAutocomplete;
