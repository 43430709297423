import React from 'react';

import { UserFreeTextComponent } from 'graphql/graphqlTypes';
import { StyledReviewTypography } from '../styles';
import { Divider } from '@mui/material';

export interface IUserFreeTextProps {
  component: UserFreeTextComponent;
}

const UserFreeText = ({ component }: IUserFreeTextProps) => {
  return (
    <div style={{ display: component.multiline ? 'inline-block' : 'flex' }}>
      {component.displayAsIcon &&
      component.value &&
      component.value.length > 0 ? (
        <Divider />
      ) : null}
      <div>
        {component.value?.split('\n').map((str, index) => (
          <StyledReviewTypography
            variant="body1"
            key={component.uniqueID + index.toString()}
            $color={component.color ?? undefined}
          >
            {str}
          </StyledReviewTypography>
        ))}
      </div>
    </div>
  );
};

export default UserFreeText;
