import { useReminderStatusAndDays } from '../RemindersGrid.hooks';
import TableCell from '@mui/material/TableCell';
import Icon, { ICONS } from 'components/icon';
import React from 'react';
import { COLORS } from 'consts/styles';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { mapStatusToText } from 'util/helpers/iconHelpers';

export const StatusCell = (props: ILabelCellProps) => {
  const { statusColor, days, status } = useReminderStatusAndDays(props.row);
  let icon = ICONS.Bell;
  let color = props.row.isNew && days === 0 ? COLORS.GREEN100 : statusColor;

  if (props.row.isCompleted) {
    icon = ICONS.ProgressCompleted;
    color = COLORS.GREY60;
  } else if (props.row.isDeleted) {
    icon = ICONS.Removed;
    color = COLORS.GREY60;
  } else if (props.row.isExpired) {
    icon = ICONS.Expired;
    color = COLORS.GREY60;
  }
  return (
    <TableCell style={CellComponentStyle}>
      <Icon
        title={`Reminder (${
          props.row.isNew && days === 0
            ? 'New'
            : mapStatusToText(status.toString())
        })`}
        icon={icon}
        size={16}
        color={color}
      />
    </TableCell>
  );
};
