import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import CountLinkFormatter from '../formatters/CountLinkFormatter';
import {
  dashBoardAuthorization,
  dashBoardType,
} from '../Dashboard/DashBoardGridHelper';

interface CountLinkProviderProps extends DataTypeProviderProps {
  priority: string | null;
  defaultAuthorization: dashBoardAuthorization;
  dashBoardType: dashBoardType;
}

const CountLinkProvider = (props: CountLinkProviderProps) => {
  const formatterComponent = (
    formatterProps: DataTypeProvider.ValueFormatterProps
  ) => (
    <CountLinkFormatter
      {...formatterProps}
      priority={props.priority}
      defaultAuthorization={props.defaultAuthorization}
      dashBoardType={props.dashBoardType}
    />
  );
  return (
    <DataTypeProvider formatterComponent={formatterComponent} {...props} />
  );
};

export default CountLinkProvider;
