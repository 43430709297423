import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import {
  ChecklistStorageType,
  IChecklistError,
  ISetStoplightValidationBody,
} from 'store/actions/types';
import { NavButton } from './components/NavButton';
import { ChecklistCategory } from 'graphql/graphqlTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  carePlanStoplightsSelector,
  selectCarePlanValidationErrors,
  selectChecklistValidationErrors,
  stoplightsSelector,
} from 'pages/workflow/helperSelectors';
import { IState } from 'store';
import styled from 'styled-components';
import { setErrorBarVisible } from 'store/actions/checklistSlice';

const StyledErrorPaper = styled(Paper)`
  background-color: ${COLORS.RED20};
  display: flex;
  padding: 9px 60px;
  align-items: center;
  border-radius: 0;
  position: sticky;
  top: 64px;
  z-index: 20;
  border-bottom: 1px solid ${COLORS.GREY25};
`;

const StyledTotalErrors = styled.span`
  color: ${COLORS.RED160};
`;
const StyledCurrentError = styled.span`
  color: #aa7f7f;
`;

export interface IErrorNotificationProps {
  stopLights?: Array<ISetStoplightValidationBody>;
  categories: Array<ChecklistCategory>;
  storageType: ChecklistStorageType;
}

type INotification = {
  type: 'stoplight' | 'validation';
  uuid: string;
  model: IChecklistError | ISetStoplightValidationBody;
};
const ErrorNotification = (props: IErrorNotificationProps) => {
  const { categories, storageType } = props;
  const dispatch = useDispatch();
  const [currentError, setCurrentError] = useState(0);

  const showErrorBarForStorageType = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].showErrorBar
  );
  const showErrorBarCarePlan = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN].showErrorBar
  );
  const showErrorBar = showErrorBarForStorageType || showErrorBarCarePlan;

  const mapStoplights = (
    stopLightsArg: ISetStoplightValidationBody[]
  ): INotification[] =>
    stopLightsArg?.map<INotification>((model) => ({
      type: 'stoplight',
      uuid: model.uuid,
      model: model,
    })) ?? [];

  const stopLights = useSelector(stoplightsSelector(storageType));
  const stopLightsErrors = mapStoplights(stopLights);
  const carePlanStopLights = useSelector(carePlanStoplightsSelector);
  const carePlanStopLightsErrors = mapStoplights(carePlanStopLights);

  const mapValidationErrors = (
    errorMessagesArg: IChecklistError[]
  ): INotification[] =>
    errorMessagesArg?.map<INotification>((model) => ({
      type: 'validation',
      uuid: model.uuid,
      model: model,
    })) ?? [];

  const errorMessages = useSelector(
    selectChecklistValidationErrors(storageType)
  );
  const validationErrors = mapValidationErrors(errorMessages);

  const carePlanErrorMessages = useSelector(selectCarePlanValidationErrors);
  const carePlanValidationErrors = mapValidationErrors(carePlanErrorMessages);

  if (!showErrorBar && !stopLights) {
    return null;
  }

  const getCategoryIndex = (categoryId: string): number => {
    const foundIndex =
      categories
        .filter((x) => x.items?.some((y) => y.isVisible) ?? false)
        .findIndex((x) => x.id === categoryId) + 1;
    if (foundIndex === 0) {
      return categories.length + 1;
    } else {
      return foundIndex;
    }
  };

  const errors = stopLightsErrors
    .concat(validationErrors)
    .concat(carePlanStopLightsErrors)
    .concat(carePlanValidationErrors)
    .sort(
      (a, b) =>
        getCategoryIndex(a.model.categoryId) -
        getCategoryIndex(b.model.categoryId)
    );

  if (errors.length === 0 && showErrorBar) {
    dispatch(
      setErrorBarVisible({
        storageType: storageType,
        visible: false,
      })
    );
  }

  if (errors.length > 0 && currentError >= errors.length) {
    setCurrentError(errors.length - 1);
  }

  const handleClick = (offset = 0) => {
    const error = errors[currentError + offset];
    const section = errors && document.querySelector(`#uuid-${error.uuid}`);
    if (!section) {
      // eslint-disable-next-line no-console
      console.error(`#uuid-${error.uuid} is not found!!`);
    }
    section?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <>
      {showErrorBar && stopLights ? (
        <StyledErrorPaper elevation={0}>
          {errors && errors?.length > 0 && (
            <>
              <Box
                display="flex"
                flexGrow={0}
                alignItems="center"
                paddingRight="20px"
              >
                <NavButton
                  onClick={() => {
                    setCurrentError(currentError - 1);
                    handleClick(-1);
                  }}
                  direction="prev"
                  disabled={currentError === 0}
                />
                <Typography data-testid="current-error">
                  <StyledCurrentError>{currentError + 1}</StyledCurrentError>/
                  <StyledTotalErrors>{errors?.length}</StyledTotalErrors>
                </Typography>
                <NavButton
                  onClick={() => {
                    setCurrentError(currentError + 1);
                    handleClick(1);
                  }}
                  direction="next"
                  disabled={
                    errors.length === 0 || errors.length - 1 === currentError
                  }
                />
              </Box>
              <Box display="flex" flexGrow={1} alignItems="center">
                <Icon
                  size={18}
                  icon={ICONS.warning_filled}
                  color={COLORS.RED100}
                  style={{ marginTop: '-2px' }}
                />
                <Box paddingLeft="10px">
                  {errors[currentError]?.type === 'stoplight' && (
                    <Typography
                      variant="body1"
                      data-testid="current-message"
                      style={{ color: '#560001' }}
                    >
                      <span style={{ fontWeight: 700 }}>
                        {
                          (
                            errors[currentError]
                              ?.model as ISetStoplightValidationBody
                          ).errorType
                        }
                      </span>
                      {`: ${
                        (
                          errors[currentError]
                            ?.model as ISetStoplightValidationBody
                        ).measureLevel
                      } ${
                        (
                          errors[currentError]
                            ?.model as ISetStoplightValidationBody
                        ).displayName
                      } (Section ${getCategoryIndex(
                        errors[currentError]?.model.categoryId
                      )})`}
                    </Typography>
                  )}
                  {errors[currentError]?.type === 'validation' && (
                    <Typography
                      variant="body1"
                      data-testid="current-message"
                      style={{ color: '#560001' }}
                    >
                      <span style={{ fontWeight: 700 }}>
                        {
                          (errors[currentError]?.model as IChecklistError)
                            .fieldName
                        }{' '}
                        in Section{' '}
                        {getCategoryIndex(
                          errors[currentError]?.model.categoryId
                        )}
                        :
                      </span>
                      <span>
                        {' '}
                        {(errors[currentError]?.model as IChecklistError).error}
                      </span>
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box display="flex" flexGrow={0}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: COLORS.RED100,
                    color: COLORS.WHITE,
                  }}
                  onClick={() => handleClick()}
                  data-testid="fix-button"
                >
                  Fix it
                </Button>
              </Box>
            </>
          )}
        </StyledErrorPaper>
      ) : null}
    </>
  );
};

export default ErrorNotification;
