import React, { ReactElement } from 'react';

import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { IFilterCellProps } from 'backend/types/grid/IFilterCellProps';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledFilterRowCell = styled(TableFilterRow.Cell)({
  color: COLORS.GREY100,
  paddingTop: '4px!important',
  paddingBottom: '4px!important',
});

const FilterCell = (props: TableFilterRow.CellProps) => {
  const typedProps = props as IFilterCellProps;

  return (
    <StyledFilterRowCell {...props}>
      {React.Children.map(
        typedProps.children,
        (child) =>
          child && React.cloneElement(child as ReactElement, { ...props })
      )}
    </StyledFilterRowCell>
  );
};

export default FilterCell;
