import React, { ReactNode } from 'react';
import PanelHighlighter from 'components/panel/sectionPanel/highlighter';
import { ChecklistStorageType } from 'store/actions/types';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

const StyledPanelWrapper = styled.div`
  border: 1px solid #d5d5d5;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: ${COLORS.WHITE};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  flex: 1;
  align-items: stretch;
  border-top: 0;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

interface IPanelProps {
  padding?: string | null;
  children: ReactNode;
  testId?: string;
  hideBorder?: boolean;
  sectionIndex: number;
  storageType: ChecklistStorageType;
}

const SectionPanel = (props: IPanelProps) => {
  const { padding, children, testId, sectionIndex, storageType } = props;

  return (
    <StyledPanelWrapper
      data-testid={testId}
      style={{
        padding: padding ? padding : '24px',
      }}
    >
      <PanelHighlighter
        sectionIndex={sectionIndex}
        storageType={storageType}
        isHighlightedDefault={storageType === ChecklistStorageType.MOZART}
      />
      <StyledWrapper>{children}</StyledWrapper>
    </StyledPanelWrapper>
  );
};

export default SectionPanel;
