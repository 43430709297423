export enum claimColumns {
  id = 'id',
  claimId = 'claimId',
  claimDate = 'claimDate',
  serviceFrom = 'serviceFrom',
  serviceTo = 'serviceTo',
  diagnosis = 'diagnosis',
  procedure = 'procedure',
  billingProviderName = 'billingProviderName',
  paidAmount = 'paidAmount',
  performingProvider = 'performingProvider',
}
