import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SearchMedicationCodesQueryVariables = Types.Exact<{
  request: Types.SearchMedicationCodeActionContextInput;
}>;

export type SearchMedicationCodesQuery = {
  __typename?: 'EntitiesQuery';
  searchMedicationCodes?: Array<{
    __typename?: 'SearchResult';
    id: number;
    code: string;
    description?: string | null;
    shortDescription?: string | null;
    notificationMessage?: string | null;
    byCodeFound: boolean;
    codeCategory?: Types.MedicationCodeCategoryType | null;
    expirationDate?: any | null;
  } | null> | null;
};

export const SearchMedicationCodesDocument = `
    query searchMedicationCodes($request: SearchMedicationCodeActionContextInput!) {
  searchMedicationCodes(request: $request) {
    id
    code
    description
    shortDescription
    notificationMessage
    byCodeFound
    codeCategory
    expirationDate
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchMedicationCodes: build.query<
      SearchMedicationCodesQuery,
      SearchMedicationCodesQueryVariables
    >({
      query: (variables) => ({
        document: SearchMedicationCodesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useSearchMedicationCodesQuery,
  useLazySearchMedicationCodesQuery,
} = injectedRtkApi;
