import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorPopupState } from './types';
import { ICONS } from 'components/icon';

export const initialErrorPopupState: ErrorPopupState = {
  isOpen: false,
  message: '',
  title: '',
  returnHomeAction: false,
};

const errorPopupSlice = createSlice({
  name: 'ErrorPopup',
  initialState: initialErrorPopupState,
  reducers: {
    showErrorPopup(
      state: ErrorPopupState,
      action: PayloadAction<{
        message: string;
        title?: string;
        icon?: ICONS;
        shouldReturnHome?: boolean;
      }>
    ) {
      state.isOpen = true;
      state.message = action.payload.message;
      state.title = action.payload.title ?? 'Internal Error';
      state.icon = action.payload.icon;
      state.returnHomeAction = Boolean(action.payload.shouldReturnHome);
    },
    closeErrorPopup(state: ErrorPopupState) {
      state.isOpen = false;
      state.returnHomeAction = false;
    },
  },
});

export const { showErrorPopup, closeErrorPopup } = errorPopupSlice.actions;

export default errorPopupSlice.reducer;
