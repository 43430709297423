import React, { useEffect, useState } from 'react';
import { MOMENT_ISO_FORMAT } from 'components/constants';
import { addDays, isValid, subDays } from 'date-fns';
import {
  hasValue,
  IValidationResult,
  validateAttributeComparisonDates,
  validateDateField,
} from 'util/validationUtils';
import { IComponentProps } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { useChecklistValidation } from '../../Items/Orderable';
import { IState } from 'store';
import LinkFieldsIcon from 'components/linkFieldsIcon/LinkFieldsIcon';
import moment from 'moment';
import { formatDate, getCareSiteNow } from 'util/helpers/dateTimeHelpers';
import DateComponent from 'components/DateComponent';
import {
  DateComponent as DateComponentProps,
  Maybe,
} from 'graphql/graphqlTypes';
import { setEntityAttributesBeingSetValue } from 'store/actions/checklistSlice';
import { useAttributeComparison } from 'hooks/useAttributeValidation';
import { getDateErrorMessage } from './date.helper';

export interface IDateComponentProps
  extends IComponentProps<DateComponentProps> {
  autoSave: boolean;
  isReadOnly: boolean;
  isUpdateActionValueRequestFailed: boolean;
  fieldName: Maybe<string> | undefined;
}

const DateComp = (props: IDateComponentProps) => {
  const {
    component,
    handleChecklistInputChange,
    categoryId,
    isActionSelected,
    storageType,
    autoSave,
    isReadOnly,
    isUpdateActionValueRequestFailed,
    sectionItemId,
  } = props;

  const reduxDate = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistComponents[
        component.uniqueID
      ]?.component?.value
  );

  const changingActions = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.actionsBeingSetVisible
  );

  const dispatch = useDispatch();
  const { primaryComparison, secondaryComparison } = useAttributeComparison(
    storageType,
    component.primaryComparison,
    component.secondaryComparison
  );
  const [attributeComparisonError, setAttributeComparisonError] =
    useState<IValidationResult>({ hasError: false });

  const initSelectedDate = () => {
    if (reduxDate) {
      return new Date(formatDate(reduxDate));
    }
    if (component.value && isValid(component.value)) {
      return new Date(formatDate(component.value));
    }
    return null;
  };

  const [selectedDate, setSelectedDate] = useState(initSelectedDate());

  const validateInputDate = (date: Date | null): IValidationResult =>
    validateDateField(date, {
      required: component.required,
      daysBeforeLimit: component.daysBeforeLimit,
      daysAfterLimit: component.daysAfterLimit,
      primaryComparison: component.primaryComparison,
      secondaryComparison: component.secondaryComparison,
    });

  const [error, setError] = useState<IValidationResult>(
    validateInputDate(selectedDate)
  );

  const handleValidation = (date: Date | null) => {
    const result = validateInputDate(date);
    setError(result);

    if (!result.hasError) {
      const attributComparisonResult = validateAttributeComparisonDates(
        date,
        primaryComparison,
        secondaryComparison
      );

      setAttributeComparisonError(attributComparisonResult);
    }
  };

  useEffect(() => {
    setSelectedDate(initSelectedDate());

    dispatch(
      setEntityAttributesBeingSetValue({
        storageType,
        value: reduxDate ?? component.value,
        fieldName: props.fieldName,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDate, component.value]);

  useEffect(() => {
    handleValidation(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionSelected, selectedDate]);

  const minDate = hasValue(component.daysBeforeLimit)
    ? subDays(moment().toDate(), component.daysBeforeLimit)
    : null;
  const maxDate = hasValue(component.daysAfterLimit)
    ? addDays(moment().toDate(), component.daysAfterLimit)
    : null;

  const onChange = (date: Date | null) => {
    setSelectedDate(date as Date);
    const result = validateInputDate(date as Date);
    setError(result);
    if (!date) {
      handleChecklistInputChange('', component, undefined, props.fieldName);
      return;
    }
    if (!result.hasError) {
      handleChecklistInputChange(
        moment(date).format(MOMENT_ISO_FORMAT),
        component,
        undefined,
        props.fieldName
      );
    }
  };

  const careSiteNow = getCareSiteNow().toDate();

  useEffect(() => {
    if (
      component.useCurrentDate &&
      changingActions?.some((x) => x.actionId === sectionItemId! && x.selected)
    ) {
      onChange(careSiteNow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changingActions]);

  const errorMessage = getDateErrorMessage(attributeComparisonError);

  useChecklistValidation(
    (error.hasError || attributeComparisonError.hasError) && isActionSelected,
    autoSave,
    isActionSelected,
    isReadOnly,
    component.uniqueID,
    categoryId,
    component.userDefinedId || component.componentType,
    errorMessage,
    storageType
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DateComponent
        testId="KeyboardDatePicker"
        required={component.required}
        readOnly={isReadOnly}
        placeholder="mm/dd/yyyy"
        value={selectedDate}
        minDate={minDate ?? moment('1900-01-01').toDate()}
        maxDate={maxDate ?? moment('2100-01-01').toDate()}
        onChange={onChange}
        error={
          ((error.hasError || attributeComparisonError.hasError) &&
            isActionSelected &&
            !isReadOnly) ||
          isUpdateActionValueRequestFailed
        }
        {...(component.userDefinedId
          ? { 'data-userdefinedid': component.userDefinedId }
          : '')}
        color={component.color ?? undefined}
      />
      {(component.mirrorGroup || component.oneWaySrc) && (
        <LinkFieldsIcon linkMessage={component.linkMessage as string} />
      )}
    </div>
  );
};

export default DateComp;
