import { Box, Typography } from '@mui/material';
import { SearchResult } from 'graphql/graphqlTypes';
import React from 'react';
import {
  MedCodeOptionWrapper,
  MedCodeSuggestionTypography,
} from 'components/actions/sections/SectionBody/Items/MedicationCodes/StyledComponents';
import { COLORS } from 'consts/styles';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import styled from 'styled-components';

export interface IMedCodeProps {
  value: SearchResult;
  handleClickReadMore: (e: React.MouseEvent<HTMLElement>) => void;
}

const OptionWrapperBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '330px',
  justifyContent: 'flex-start',
});

const ReadMoreWrapperBox = styled(Typography)({
  display: 'flex',
  paddingTop: '20px',
  justifyContent: 'flex-end',
  color: COLORS.SYMPHONY_BLUE,
});

const MedCodeOption = ({ value, handleClickReadMore }: IMedCodeProps) => {
  return (
    <MedCodeOptionWrapper>
      <OptionWrapperBox>
        <MedCodeSuggestionTypography variant="body2">
          {value.code}
        </MedCodeSuggestionTypography>
        <CustomTooltip title={value.description ?? ''}>
          <MedCodeSuggestionTypography noWrap variant="body1">
            {value.description}
          </MedCodeSuggestionTypography>
        </CustomTooltip>
      </OptionWrapperBox>
      <ReadMoreWrapperBox noWrap variant="body2" onClick={handleClickReadMore}>
        Read more
      </ReadMoreWrapperBox>
    </MedCodeOptionWrapper>
  );
};

export default MedCodeOption;
