import React, { useEffect, useState } from 'react';
import {
  Column,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';

import { Box } from '@mui/material';
import {
  SelectedRow,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { updateSelectedMedicationItem } from 'store/patientdetails/patientDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import HeaderSelectCell from 'components/home/grid/selectors/HeaderSelectCell';
import SelectCell from 'components/home/grid/selectors/SelectCell';
import { MedicationLabelCell } from './components/MedicationLabelCell';
import {
  MedicationViewModel,
  PatientMedicationClaim,
} from 'graphql/graphqlTypes';

const getRowId = (row: MedicationViewModel | PatientMedicationClaim) => row.id;

interface IMedicationsGridProps {
  medications: Array<MedicationViewModel | PatientMedicationClaim>;
  columns: Column[];
  columnExtensions: Array<
    Table.ColumnExtension &
      SortingState.ColumnExtension &
      IntegratedSorting.ColumnExtension
  >;
  searchTerm?: string;
  isMultiSelect: boolean;
}

export const MedicationsGrid = ({
  medications,
  columns,
  columnExtensions,
  searchTerm,
  isMultiSelect,
}: IMedicationsGridProps) => {
  const [selectedItem, setSelectedItem] = useState<Array<number | string>>([]);

  const [selectedMedication] =
    useSelector(
      (state: IState) =>
        state.patientDetails.medicationsTab.selectedMedicationItems
    ) ?? [];

  const selectedActionType = useSelector(
    (state: IState) => state.patientDetails.medicationsTab.selectedActionType
  );

  const dispatch = useDispatch();

  const handleSelectionChange = (values: Array<number | string>) => {
    const newId =
      values.find((value) => value !== selectedMedication) ??
      selectedMedication;
    if (isMultiSelect) {
      setSelectedItem(values);
    } else {
      setSelectedItem([newId]);
    }

    dispatch(
      updateSelectedMedicationItem(
        isMultiSelect
          ? {
              id: values as number[],
              selectedActionType: 'details',
            }
          : {
              id: [Number(newId)],
              selectedActionType: 'form',
            }
      )
    );
  };
  useEffect(() => {
    if (selectedActionType === '') {
      setSelectedItem([]);
    }
  }, [selectedActionType]);

  return (
    <Box>
      <Grid rows={medications} columns={columns} getRowId={getRowId}>
        <SortingState columnExtensions={columnExtensions} />
        <IntegratedSorting columnExtensions={columnExtensions} />
        <SelectionState
          selection={selectedItem}
          defaultSelection={[0]}
          onSelectionChange={handleSelectionChange}
        />
        <SearchState value={searchTerm} />
        <IntegratedFiltering />
        <IntegratedSelection />
        <SymphonyTable
          cellComponent={MedicationLabelCell}
          columnExtensions={columnExtensions}
        />

        <SymphonyTableHeaderRow showSortingControls />
        <TableSelection
          selectByRowClick={!isMultiSelect}
          cellComponent={SelectCell}
          highlightRow
          showSelectAll
          headerCellComponent={HeaderSelectCell}
          rowComponent={SelectedRow}
          showSelectionColumn={isMultiSelect}
        />
      </Grid>
    </Box>
  );
};
