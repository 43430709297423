export enum searchPatientColumn {
  lastName = 'lastName',
  firstName = 'firstName',
  mrn = 'mRN',
  mrn2 = 'mRN2',
  dob = 'dOB',
}

export const searchPatientColumnsExtension = [
  { columnName: searchPatientColumn.lastName, width: '24%' },
  { columnName: searchPatientColumn.firstName, width: '24%' },
  { columnName: searchPatientColumn.mrn, width: '17%' },
  { columnName: searchPatientColumn.mrn2, width: '17%' },
  { columnName: searchPatientColumn.dob, width: '18%' },
];

export const searchPatientColumns = () => {
  return [
    { name: searchPatientColumn.lastName, title: 'Last Name' },
    { name: searchPatientColumn.firstName, title: 'First Name' },
    { name: searchPatientColumn.mrn, title: 'MBR ID' },
    { name: searchPatientColumn.mrn2, title: 'Medicaid ID' },
    { name: searchPatientColumn.dob, title: 'DOB' },
  ];
};
