import React from 'react';
import { Link, Typography } from '@mui/material';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

const DisabledDeleteText = styled(Typography)({
  color: COLORS.GREY100,
  textDecoration: 'underline',
});

export const DeleteIndividualService = ({
  enableDelete,
  onDelete,
  id,
}: {
  enableDelete: boolean | undefined;
  onDelete: (id: number) => void;
  id: number;
}) => {
  return enableDelete ? (
    <Link
      onClick={() => onDelete(id)}
      data-testid="EnabledDeleteLink"
      style={{ cursor: 'pointer' }}
    >
      <Typography variant="body1">Delete</Typography>
    </Link>
  ) : (
    <CustomTooltip title={'You cannot delete the last remaining code'}>
      <DisabledDeleteText data-testid="DisabledDeleteLink" variant="body1">
        Delete
      </DisabledDeleteText>
    </CustomTooltip>
  );
};
