import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import { Button, Typography } from '@mui/material';
import Loader from 'components/loader';
import { ChecklistStatus, Patient } from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from 'store';
import { COLORS } from 'consts/styles';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import ReviewSections from 'components/review/sections';
import { openChecklist } from 'store/ui/modals/checklist';

interface ChecklistViewDialogProps {
  open: boolean;
  onClose: () => void;
  storageType: ChecklistStorageType;
  isLoading: boolean;
  patientData: Patient | null;
}

const ChecklistViewDialog = (props: ChecklistViewDialogProps) => {
  const { open, storageType, isLoading, onClose, patientData } = props;
  const dispatch = useDispatch();

  const checklistIds = useSelector(
    (state: IState) => state.ui.modals.checklist.checklistIds || []
  );
  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType]?.checklist
  );

  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    if (open && checklist && checklistIds.length > 0) {
      const checklistIdAsNumber = checklist?.id
        ? Number(checklist?.id)
        : checklistIds[0];
      const initialIndex = checklistIds.indexOf(checklistIdAsNumber);
      setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
    }
  }, [open, checklist, checklistIds]);

  const handleNext = () => {
    if (currentIndex < checklistIds.length - 1 && !isLoading) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      const checklistId = checklistIds[newIndex];
      dispatch(openChecklist({ checklistId: checklistId || 0 }));
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0 && !isLoading) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      const checklistId = checklistIds[newIndex];
      dispatch(openChecklist({ checklistId: checklistId || 0 }));
    }
  };

  const handleClose = () => {
    onClose();
  };

  const getTitle = () => {
    if (!checklist) {
      return '';
    }
    if (checklist?.scenarioName) {
      return checklist?.scenarioName;
    }

    const date = formatDate(
      checklist?.finishedOn ?? checklist?.updatedOn ?? checklist?.createdOn
    );
    const owner = checklist?.owner?.fullName ?? '';
    const status = {
      [ChecklistStatus.Amended]: 'Amended',
      [ChecklistStatus.Completed]: 'Done',
      [ChecklistStatus.InProcess]: 'In process',
    }[checklist?.status];

    return `${patientData?.fullName} | ${status} ${date} by ${owner}`;
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {checklist && (
            <>
              <Typography
                variant="h4"
                style={{ color: COLORS.WHITE, fontWeight: 500 }}
              >
                {getTitle()}
              </Typography>
              {checklist.reasonForNote?.name && (
                <Typography
                  variant="h4"
                  style={{ color: COLORS.WHITE, fontWeight: 500 }}
                >
                  {`Reason for note: ${checklist.reasonForNote?.name}`}
                </Typography>
              )}
            </>
          )}
        </DialogTitle>
        <DialogContent
          style={{
            height: '566px',
            overflowX: 'auto',
            overflowY: 'scroll',
            padding: '24px 16px 0px 24px',
            backgroundColor: `${COLORS.GREY4}`,
          }}
          dividers
        >
          {!isLoading && checklist ? (
            <ReviewSections storageType={storageType} />
          ) : (
            <Loader active={isLoading} />
          )}
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'flex-end',
            display: 'flex',
            alignContent: 'center',
            padding: '20px 24px',
          }}
        >
          <Button
            onClick={handlePrevious}
            disabled={currentIndex === 0 || isLoading}
            color="primary"
            size="large"
            style={{ minWidth: '100px', marginRight: 0 }}
            variant="text"
          >
            Previous
          </Button>
          <Button
            onClick={handleNext}
            disabled={currentIndex === checklistIds.length - 1 || isLoading}
            color="primary"
            size="large"
            style={{ minWidth: '100px', marginRight: 10 }}
            variant="text"
          >
            Next
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            size="large"
            style={{ minWidth: '150px' }}
            variant="contained"
            data-cy="ok"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChecklistViewDialog;
