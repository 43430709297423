import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import throttle from 'lodash/throttle';
import { ILookupValue } from 'backend/types/lookupValue';
import { usePrevious } from 'hooks';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';

interface IAutocompleteProps {
  id?: string;
  label?: string;
  labelId?: string;
  initValue?: ILookupValue | null;
  error?: boolean | null;
  disabled?: boolean;
  emptyOption?: boolean;

  suggestItems(request: string, callback: (arg: ILookupValue[]) => void): void;

  onChange(event: ChangeEvent<unknown>, newValue: ILookupValue | null): void;
  color?: string;
}

type IAutocompleteRef = {
  current: HTMLDivElement | null;
};

const StyledTextField = styled(TextField)`
  font-size: 14px;
  color: ${COLORS.NAVY_BLUE};
  background-color: ${COLORS.WHITE};
  .MuiAutocomplete-inputRoot {
    padding: 3.5px 32px 3.5px 6px !important;
  }
`;

const emptyOptionStyle = (override: boolean) => {
  return override
    ? {
        '& + .MuiAutocomplete-popper .MuiAutocomplete-option:first-child': {
          fontStyle: 'italic',
        },
      }
    : null;
};

const emptyInitialValue = {
  id: 0,
  name: '',
} as ILookupValue;
const emptyLookupValue = {
  id: 0,
  name: 'Empty',
};

const buildOptions = (
  value: ILookupValue | null,
  results: ILookupValue[],
  emptyOption: boolean | undefined
): ILookupValue[] => {
  const newOptions: ILookupValue[] = value
    ? results.filter((x) => x.id != value.id)
    : results;
  if (value) {
    newOptions.unshift(value);
  }
  if (emptyOption) {
    newOptions.unshift(emptyLookupValue);
  }
  return newOptions;
};

const buildSingleOptions = (
  value: ILookupValue | null,
  emptyOption: boolean | undefined
) => {
  const newOptions: ILookupValue[] = value ? [value] : [];
  if (emptyOption) {
    newOptions.unshift(emptyLookupValue);
  }
  return newOptions;
};

const ServerAutocomplete = forwardRef<IAutocompleteRef, IAutocompleteProps>(
  (props: IAutocompleteProps, ref) => {
    const {
      suggestItems,
      onChange,
      initValue,
      label,
      disabled,
      color,
      emptyOption,
      ...restProps
    } = props;

    const [value, setValue] = useState<ILookupValue | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<ILookupValue[]>([]);
    const [addEmptyLookup, setAddEmptyLookup] = useState<boolean>(false);
    const prevInputValue = usePrevious(inputValue);

    const fetchItemsFromServer = useMemo(
      () =>
        throttle(
          (request: string, callback: (arg: ILookupValue[]) => void) =>
            suggestItems(request, callback),
          200
        ),
      [suggestItems]
    );

    useEffect(() => {
      let active = true;

      if (inputValue === '') {
        setOptions(buildSingleOptions(value, emptyOption));
      } else if (inputValue !== prevInputValue) {
        fetchItemsFromServer(inputValue, (results: ILookupValue[]) => {
          if (active) {
            if (results.length > 0 || !emptyOption) {
              setOptions(buildOptions(value, results, emptyOption));
            } else {
              setOptions([]);
              setAddEmptyLookup(true);
            }
          }
        });
      }

      return () => {
        active = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, inputValue, fetchItemsFromServer]);

    useEffect(() => {
      if (addEmptyLookup) {
        setOptions([emptyLookupValue]);
      }
      setAddEmptyLookup(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addEmptyLookup]);

    useEffect(() => {
      setValue(initValue ?? null);
    }, [initValue]);

    const handleAutocompleteChange = (
      event: ChangeEvent<unknown>,
      newValue: ILookupValue | null
    ) => {
      if (emptyOption) {
        if (newValue != null) {
          const currentOptions = options.filter(
            (x) => !(x.id == emptyLookupValue.id || x.id == newValue.id)
          );
          const newOptions = [emptyLookupValue, newValue, ...currentOptions];
          setOptions(newOptions);
          setValue(newValue);
          onChange(event, newValue);
        } else {
          const newOptions = [emptyLookupValue];
          setOptions(newOptions);
          setValue(null);
        }
      } else {
        const newNotNullValue =
          newValue === null || newValue === undefined
            ? emptyInitialValue
            : newValue;
        const currentOptions = options.filter(
          (x) => !(x.id == emptyLookupValue.id || x.id == newValue?.id)
        );
        const newOptions = [newNotNullValue, ...currentOptions];
        setOptions(newOptions);
        setValue(newNotNullValue);
        onChange(event, newNotNullValue);
      }
    };

    const handleInputChange = (
      _event: ChangeEvent<unknown>,
      newInputValue: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (reason === 'reset') {
        setValue(null);
        setInputValue('');
      }
      setInputValue(newInputValue);
    };

    const styleOverride = emptyOptionStyle(!!emptyOption);

    return (
      <Autocomplete
        disablePortal={emptyOption}
        getOptionLabel={(option: ILookupValue) => option.name}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        disabled={disabled}
        style={{ width: '100%', color: color }}
        ref={ref}
        includeInputInList
        filterSelectedOptions={!emptyOption}
        isOptionEqualToValue={(option: ILookupValue, newValue: ILookupValue) =>
          option.id === newValue.id
        }
        value={value}
        onChange={(
          event: ChangeEvent<unknown>,
          newValue: ILookupValue | null
        ) => {
          handleAutocompleteChange(event, newValue);
        }}
        onInputChange={(
          _event: ChangeEvent<unknown>,
          newInputValue: string,
          reason
        ) => {
          handleInputChange(_event, newInputValue, reason);
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <StyledTextField
            type="text"
            {...params}
            variant="outlined"
            size="small"
            disabled={disabled}
            fullWidth={true}
            label={label}
            error={props.error || false}
          />
        )}
        {...restProps}
        sx={styleOverride}
      />
    );
  }
);

ServerAutocomplete.displayName = 'ServerAutocomplete';

export default ServerAutocomplete;
