import { CreateWorkflowChecklistMutation } from 'graphql/hooks/createWorkflowChecklist';
import { ClientError } from 'graphql-request';
import { SerializedError } from '@reduxjs/toolkit';
import {
  BaseComponent,
  ChecklistCategory,
  ChecklistItem,
  ChecklistItemOrderable,
  ChecklistViewModel,
  MozartActionType,
  MozartDefinitionType,
  MozartProgressState,
  MozartWorkflowActionInfo,
  MozartWorkflowUiModel,
  ReminderComponent,
} from 'graphql/graphqlTypes';
import { GetMozartWorkflowNavigationInfoQuery } from 'graphql/hooks/getMozartWorkflowNavigationInfo';
import { IWorkflowAction, IWorkflowViewModel } from 'store/actions/types';
import { last } from 'lodash';

export const isWorkflowCompleted = (msg: MozartWorkflowUiModel) =>
  msg &&
  msg.type === MozartDefinitionType.Workflow &&
  msg.state === MozartProgressState.Completed;

export const isWorkflowPaused = (msg: MozartWorkflowUiModel) =>
  msg &&
  msg.type === MozartDefinitionType.Action &&
  msg.actionType === MozartActionType.PauseWorkflow &&
  (msg.state === MozartProgressState.Paused ||
    msg.state === MozartProgressState.Started);

export const isWorkflowInErrorState = (msg: MozartWorkflowUiModel) =>
  msg.type === MozartDefinitionType.Exception &&
  (msg.state === MozartProgressState.Failed ||
    msg.state === MozartProgressState.PermanentFailure);

export const isWorkflowInvalid = (msg?: MozartWorkflowUiModel | null) =>
  msg?.type === MozartDefinitionType.Exception &&
  msg?.state === MozartProgressState.None;

export const isWorkflowInPermanentFailureState = (msg: MozartWorkflowUiModel) =>
  msg.type === MozartDefinitionType.Exception &&
  msg.state === MozartProgressState.PermanentFailure;

const isLoadingCompleted = (msg: MozartWorkflowUiModel) =>
  msg && msg.state === MozartProgressState.Paused;

export const isMozartReady = (messages: MozartWorkflowUiModel[]): boolean => {
  const lastMessage = last(messages);
  if (!lastMessage) {
    return false;
  }

  return (
    isLoadingCompleted(lastMessage) ||
    isWorkflowInErrorState(lastMessage) ||
    isWorkflowCompleted(lastMessage)
  );
};

export type createWorkflowChecklistResult =
  | { data: CreateWorkflowChecklistMutation }
  | {
      error: Pick<ClientError, 'name' | 'message' | 'stack'> | SerializedError;
    };

export const getMozartWorkflowViewModel = (
  navigationInfo: GetMozartWorkflowNavigationInfoQuery,
  checklistsByIds: {
    [checklistId: string]: {
      id: string;
      categories: ChecklistCategory[];
    } | null;
  }
): IWorkflowViewModel => {
  const actions = navigationInfo.getMozartWorkflowActions?.actions ?? [];
  return {
    actions: actions
      .map<IWorkflowAction>((action) => ({
        id: action?.actionId,
        stepName: action?.stepName,
        checklistId: action?.checklistId,
        categories: getSectionItems(
          action as MozartWorkflowActionInfo,
          checklistsByIds
        ),
      }))
      .filter((i) => i.checklistId !== null && i.categories.length),
  };
};

const getSectionItems = (
  step: MozartWorkflowActionInfo,
  checklistsByIds: {
    [checklistId: string]: {
      id: string;
      categories: ChecklistCategory[];
    } | null;
  }
) => {
  const checklist =
    step.checklistId && checklistsByIds[step.checklistId?.toString()];
  return checklist ? checklist.categories : [];
};

export const shouldNotCreateNextChecklist = (
  data: MozartWorkflowUiModel[],
  userRoleId: number
) => {
  const workflowCompleted = data.some(isWorkflowCompleted);
  const workflowPaused = data.some(isWorkflowPaused);
  const createChecklistMessages = data.find(
    (x) =>
      x.actionType === MozartActionType.UserInput &&
      x.state === MozartProgressState.Paused
  );

  const stepRoles = createChecklistMessages?.roles ?? [];
  const userNotAuthorizedToStartChecklist =
    stepRoles.length > 0 && !stepRoles.includes(userRoleId);

  const isException = data.some(
    (x) => x.type === MozartDefinitionType.Exception
  );

  return (
    workflowCompleted ||
    workflowPaused ||
    userNotAuthorizedToStartChecklist ||
    isException
  );
};

export const getLastCompletedAuthorizationStatus = (completedChecklists: {
  [id: string]: ChecklistViewModel;
}) => {
  const list = Object.values(completedChecklists);

  // SYM-17528: Mozart OP Workflow. Wrong Episode status is shown in the UI part when switching form Clinical Review to Pending MD
  // Sort the checklists in descending order and return the latest authorization status
  // We need to do this because we cache checklists while it is being filled
  // and thus authorizationStatus might be stale.
  list.sort((a, b) => {
    const lastChangeA = new Date(a.finishedOn ?? a.updatedOn ?? a.createdOn);
    const lastChangeB = new Date(b.finishedOn ?? b.updatedOn ?? b.createdOn);
    return lastChangeB.getTime() - lastChangeA.getTime();
  });
  const authStatus = list.find(
    (x) => x?.authorizationStatus?.name
  )?.authorizationStatus;
  return { name: authStatus?.name, style: authStatus?.style };
};

export const getCompletedChecklistReminders = (
  completedChecklists: ChecklistViewModel[]
): ReminderComponent[] => {
  if (completedChecklists) {
    const categories = completedChecklists.flatMap((x) => x.categories);

    const allCategories = categories.concat(
      categories.flatMap((x) => x?.subCategories ?? [])
    );

    return allCategories
      .flatMap((x) => x?.items)
      .filter(
        (item: ChecklistItem) =>
          item?.isVisible && item?.type === 'orderable' && item.isSelected
      )
      .map((item: ChecklistItem) => item as ChecklistItemOrderable)
      .flatMap((line) => line.lines)
      .flatMap((component) => component.components)
      .filter(
        (component: BaseComponent) =>
          component.componentType === 'ReminderComponent'
      )
      .map((component: BaseComponent) => component as ReminderComponent);
  }
  return [];
};
