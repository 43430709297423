import { IRole } from './types';
import { ILoginUser } from '../user/types';

export const ROLES_GET = 'ROLES_GET';
export type ROLES_GET = typeof ROLES_GET;

export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export type ROLES_SUCCESS = typeof ROLES_SUCCESS;

export const ROLES_FAIL = 'ROLES_FAIL';
export type ROLES_FAIL = typeof ROLES_FAIL;

export const ROLES_SET_PRIMARY = 'ROLES_SET_PRIMARY';
export type ROLES_SET_PRIMARY = typeof ROLES_SET_PRIMARY;

export const SET_ROLES_LOADING = 'SET_ROLES_LOADING';
export type SET_ROLES_LOADING = typeof SET_ROLES_LOADING;

export interface IGetRolesStartAction {
  type: ROLES_GET;
}

export const getRolesStart = (): IGetRolesStartAction => ({
  type: ROLES_GET,
});

export interface IGetRolesSuccessAction {
  type: ROLES_SUCCESS;
  user: ILoginUser;
  careSiteId: number;
  roles: IRole[];
  isSuperAdmin: boolean;
}

export const getRolesSuccess = (
  user: ILoginUser,
  careSiteId: number,
  roles: IRole[],
  isSuperAdmin: boolean
): IGetRolesSuccessAction => ({
  type: ROLES_SUCCESS,
  user: user,
  careSiteId: careSiteId,
  roles: roles ? roles : ([] as IRole[]),
  isSuperAdmin: isSuperAdmin,
});

export interface ISetPrimaryAction {
  type: ROLES_SET_PRIMARY;
  roleId: number | null;
}

export interface ISetRolesLoadingAction {
  type: SET_ROLES_LOADING;
  loading: boolean;
}

export const setRolesLoading = (loading: boolean): ISetRolesLoadingAction => ({
  type: SET_ROLES_LOADING,
  loading: loading,
});
export const setPrimary = (roleId: number | null): ISetPrimaryAction => ({
  type: ROLES_SET_PRIMARY,
  roleId: roleId,
});

export interface IGetRolesFailAction {
  type: ROLES_FAIL;
  error: string;
}

export const getRolesFail = (error: string): IGetRolesFailAction => ({
  type: ROLES_FAIL,
  error: error,
});

export type IRoleActions =
  | IGetRolesFailAction
  | ISetPrimaryAction
  | IGetRolesSuccessAction
  | IGetRolesStartAction
  | ISetRolesLoadingAction;
