import { UseFormSetValue, useForm } from 'react-hook-form';
import { ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import {
  EpisodeBalancerCriteria,
  EpisodeBalancerCriteriaInput,
  User,
} from 'graphql/graphqlTypes';
import { formConstants } from 'components/constants';
import { toLookupValue } from 'util/lookupHelper';
import { EpisodeDistributeForm } from './types';

export const toAddEpisodeBalancerCriteriaMutationVariables = (
  source: EpisodeDistributeForm
): EpisodeBalancerCriteriaInput => {
  return {
    userIds: source.users.map((x) => x.id),
    totalEpisodesOwned: Number(source.totalEpisodesOwned),
    careStageIds: source.careStages.map((x) => x.id),
    pathwayIds: source.pathways.map((x) => x.id),
    serviceLocationIds: source.serviceLocations.map((x) => x.id),
  };
};

export const toUserLookupValues = (
  users: User[],
  skipNameSort?: boolean
): ILookupValue[] => {
  const lookupValues =
    users.map((x) => {
      return { id: x.id, name: x.name };
    }) ?? [];
  return mapDataToLookupValues(lookupValues, skipNameSort);
};
export const toLookupValues = (
  lookups: ILookup[],
  lookupName: string,
  skipNameSort?: boolean
): ILookupValue[] => {
  const lookupValues =
    lookups
      .find((x) => x.name == lookupName)
      ?.values?.map((x) => {
        return { id: x.id, name: x.name };
      }) ?? [];
  return mapDataToLookupValues(lookupValues, skipNameSort);
};

export const mapDataToLookupValues = (
  data: { id: number; name?: string | null }[],
  skipNameSort?: boolean
) => {
  return data
    .map((item) => {
      const newItem: ILookupValue = {
        ...item,
        id: item.id,
        name: item.name ?? '',
      };
      return newItem;
    })
    .sort((a, b) => {
      if (!skipNameSort) {
        const comparison = a.name.localeCompare(b.name);
        if (comparison !== 0) {
          return comparison;
        }
      }
      return a.id - b.id;
    });
};

export const resetToDefaultEpisodeDistributeDetails = (
  episodeBalancerCriteria: EpisodeBalancerCriteria,
  setValue: UseFormSetValue<EpisodeDistributeForm>
) => {
  setValue(
    'users',
    episodeBalancerCriteria.users.map((x) => toLookupValue(x))
  );
  setValue('totalEpisodesOwned', episodeBalancerCriteria.totalEpisodesOwned);
  setValue(
    'careStages',
    episodeBalancerCriteria.careStages.map((x) => toLookupValue(x))
  );
  setValue(
    'pathways',
    episodeBalancerCriteria.pathways.map((x) => toLookupValue(x))
  );
  setValue(
    'serviceLocations',
    episodeBalancerCriteria.serviceLocations.map((x) => toLookupValue(x))
  );
};

export const useEpisodeDistributeForm = () => {
  return useForm<EpisodeDistributeForm>({
    defaultValues: {
      users: [],
      totalEpisodesOwned: '',
      careStages: [],
      pathways: [],
      serviceLocations: [],
    },
    mode: formConstants.defaultMode,
    reValidateMode: formConstants.defaultReValidateMode,
  });
};
