export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) {
    return '0 Bytes';
  }
  const bytesInKilobyte = 1024;
  const fractionDigits = Math.max(0, decimals);
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const exponent = Math.floor(Math.log(bytes) / Math.log(bytesInKilobyte));
  return `${parseFloat(
    (bytes / Math.pow(bytesInKilobyte, exponent)).toFixed(fractionDigits)
  )} ${sizes[exponent]}`;
};
