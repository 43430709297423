import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { CellComponentStyle } from './CellComponentStyle';

interface EditingCellProps extends TableEditRow.CellProps {
  inputProps?: { maxLength: number };
  error?: boolean;
  multiline?: boolean;
  fullwidth?: boolean;
  autoFocus?: boolean;
  required?: boolean;
}

export const EditingCell = (props: EditingCellProps) => (
  <TableEditRow.Cell
    {...props}
    style={{ ...CellComponentStyle, ...{ padding: '0px 24px 0 8px' } }}
  >
    <TextField
      value={props.value}
      inputProps={props.inputProps}
      onChange={(event: ChangeEvent<{ value: unknown }>) =>
        props.onValueChange(event.target.value)
      }
      multiline={props.multiline}
      fullWidth={props.fullwidth}
      error={props.error}
      size="small"
      variant="outlined"
      autoFocus={props.autoFocus}
      required={props.required}
      style={{ marginRight: '10px' }}
    />
  </TableEditRow.Cell>
);
