import React from 'react';
import { Button, IconButton } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

interface IMozartOutOfTurnSteps {
  handleToggle: (event: React.MouseEvent<HTMLElement>, open: boolean) => void;
  displayType: 'button' | 'dropdown';
  menuOpen?: boolean;
}

const StyledButton = styled(Button)<{ $menuOpen: boolean }>`
  background-color: ${(props) => (props.$menuOpen ? COLORS.BLUE20 : 'inherit')};
`;

const ToggleButton = (props: IMozartOutOfTurnSteps) => {
  const { handleToggle, displayType, menuOpen } = props;

  return (
    <div data-testid="mozart-oot-toggle">
      {displayType === 'button' ? (
        <IconButton
          style={{ padding: '6px' }}
          data-testid="mozart-oot-toggle-icon-button"
          onClick={(e) => handleToggle(e, true)}
          size="large"
        >
          <Icon size={20} icon={ICONS.MoreVert} color={COLORS.SYMPHONY_BLUE} />
        </IconButton>
      ) : (
        <StyledButton
          variant="text"
          color="primary"
          data-testid="mozart-oot-toggle-dropdown-button"
          $menuOpen={Boolean(menuOpen)}
          onClick={(e) => handleToggle(e, true)}
        >
          Other Actions
          <Icon
            size={20}
            icon={ICONS.ArrowDropDown}
            color={COLORS.SYMPHONY_BLUE}
          />
        </StyledButton>
      )}
    </div>
  );
};

export default ToggleButton;
