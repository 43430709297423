import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import TimelineMoreInfoFormatter from '../formatters/TimelineMoreInfoFormatter';

const TimelineMoreInfoProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TimelineMoreInfoFormatter} {...props} />
);

export default TimelineMoreInfoProvider;
