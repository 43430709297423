import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SearchPatientsQueryVariables = Types.Exact<{
  fullSearch: Types.Scalars['String'];
}>;

export type SearchPatientsQuery = {
  __typename?: 'EntitiesQuery';
  searchPatients: Array<{
    __typename?: 'SearchPatientDialogPatientsViewModel';
    id: number;
    lastName?: string | null;
    firstName?: string | null;
    mRN: string;
    mRN2?: string | null;
    dOB: string;
  }>;
};

export const SearchPatientsDocument = `
    query searchPatients($fullSearch: String!) {
  searchPatients(fullSearch: $fullSearch) {
    id
    lastName
    firstName
    mRN
    mRN2
    dOB
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchPatients: build.query<
      SearchPatientsQuery,
      SearchPatientsQueryVariables
    >({
      query: (variables) => ({ document: SearchPatientsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSearchPatientsQuery, useLazySearchPatientsQuery } =
  injectedRtkApi;
