import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { useGetCareSiteQuery } from 'graphql/hooks/getCareSite';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { hasValue } from 'util/validationUtils';
import { Button, Grid, Typography } from '@mui/material';
import { StyledLinkContainer } from 'pages/error/errorStyles';
import { useNavigate } from 'react-router';
import { ROUTE } from 'consts/ROUTE';

const StyledTitle = styled(Typography)`
  font-size: 16px;
  letter-spacing: 0.15;
  line-height: 24px;
  font-weight: 500;
  margin-top: 80px;
  margin-bottom: 16px;
`;

const SupportInformation = () => {
  // TODO: [SYM-18285] - Need to a way to provide a specific fallback value for "caresiteId". Currently using empty string
  //  only to make the compiler happy.
  const caresiteId =
    useSelector((state: IState) => state.user.currentUser.careSite) ?? '';

  const { data } = useGetCareSiteQuery(
    { caresiteId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const supportInformation = data?.getCareSite?.supportInformation;
  const displayText = hasValue(supportInformation)
    ? parse(supportInformation)
    : '';
  const navigate = useNavigate();

  const handleHomeClick = useCallback(() => {
    navigate(ROUTE.Home);
  }, [navigate]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      data-cy="support-information"
    >
      <Grid item xs={4} textAlign="center">
        <StyledTitle variant="h4">Support Information</StyledTitle>
        <Typography>{displayText}</Typography>
        <StyledLinkContainer>
          <Button
            size="small"
            variant="text"
            color="primary"
            onClick={handleHomeClick}
          >
            Return to Home page
          </Button>
        </StyledLinkContainer>
      </Grid>
    </Grid>
  );
};

export default SupportInformation;
