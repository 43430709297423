import * as React from 'react';
import { TableCell } from '@mui/material';
import { CellComponentStyle } from 'components/gridFormatters';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import moment from 'moment';
import { MOMENT_ISO_FORMAT } from 'components/constants';
import DateComponent from 'components/DateComponent';
import { formatDate } from 'components/actions/sections/SectionBody/Items/HealthService/Components/ServiceRow.helpers';

export const DateEditor = (
  props: TableEditRow.CellProps & { autoFocus?: boolean }
) => {
  const { value, onValueChange, autoFocus } = props;

  const handleDateChange = (newDate: Date | null | unknown) => {
    const val = moment(newDate as Date).format(MOMENT_ISO_FORMAT);
    onValueChange(val);
  };
  return (
    <TableCell style={CellComponentStyle}>
      <DateComponent
        value={formatDate(value)}
        testId="date-editor"
        onChange={handleDateChange}
        required={true}
        autoFocus={autoFocus}
      />
    </TableCell>
  );
};
