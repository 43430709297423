import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetReminderHistoryQueryVariables = Types.Exact<{
  reminderId: Types.Scalars['Int'];
}>;

export type GetReminderHistoryQuery = {
  __typename?: 'EntitiesQuery';
  getReminderHistory?: {
    __typename?: 'ReminderHistoryData';
    id?: number | null;
    rows?: Array<{
      __typename?: 'ReminderHistoryRow';
      action?: string | null;
      dateFormatted?: string | null;
      userName?: string | null;
    } | null> | null;
  } | null;
};

export const GetReminderHistoryDocument = `
    query getReminderHistory($reminderId: Int!) {
  getReminderHistory(reminderId: $reminderId) {
    id
    rows {
      action
      dateFormatted
      userName
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getReminderHistory: build.query<
      GetReminderHistoryQuery,
      GetReminderHistoryQueryVariables
    >({
      query: (variables) => ({
        document: GetReminderHistoryDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetReminderHistoryQuery, useLazyGetReminderHistoryQuery } =
  injectedRtkApi;
