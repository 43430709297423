import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type FinishMozartWorkflowChecklistMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type FinishMozartWorkflowChecklistMutation = {
  __typename?: 'Mutation';
  finishMozartWorkflowChecklist?: {
    __typename?: 'MozartActionResult';
    result: boolean;
    message?: string | null;
    isDataValidationExceptionOccured?: boolean | null;
  } | null;
};

export const FinishMozartWorkflowChecklistDocument = `
    mutation finishMozartWorkflowChecklist($id: UUID!) {
  finishMozartWorkflowChecklist(id: $id) {
    result
    message
    isDataValidationExceptionOccured
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    finishMozartWorkflowChecklist: build.mutation<
      FinishMozartWorkflowChecklistMutation,
      FinishMozartWorkflowChecklistMutationVariables
    >({
      query: (variables) => ({
        document: FinishMozartWorkflowChecklistDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFinishMozartWorkflowChecklistMutation } = injectedRtkApi;
