import Icon, { ICONS } from 'components/icon';
import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

interface ICarePlanIconProps {
  problemId: number;
  storageType: string;
}

export const CarePlanIcon = ({
  problemId,
  storageType,
}: ICarePlanIconProps) => {
  const allProblemsSelector = createSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklist?.entities,
    (entities) => entities?.find((x) => x.name === 'Problem')?.values ?? []
  );

  const allProblems = useSelector(allProblemsSelector);

  const title = allProblems?.find((x) => x.id === problemId)?.name ?? '';
  return (
    <Icon
      testId={`icon-${problemId}`}
      tooltipContainerElement="div"
      title={`Add ${title} to the Problem List`}
      icon={ICONS.CarePlan}
      size={18}
      style={{ marginLeft: '2px' }}
    />
  );
};
