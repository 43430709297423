import React from 'react';
import { useDispatch } from 'react-redux';
import { QuickNoteType } from 'graphql/graphqlTypes';
import StyledLink from './StyledLink';
import { IQuickNoteProps } from './types';
import { AppDispatch } from 'store';
import { openQuickNoteAsync } from 'store/ui/modals/quickNoteThunks';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IQuickNoteUpdateProps extends IQuickNoteProps {
  patientId?: number;
  episodeId?: number;
}

const QuickNoteUpdateLink = (props: IQuickNoteUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleOpenQuickNoteDialog = async (
    quickNoteId: number,
    patientId: number,
    episodeId: number | undefined
  ) => {
    const shouldRefetch = await dispatch(
      openQuickNoteAsync({
        patientId: patientId,
        type: QuickNoteType.Episode,
        episodeId: episodeId ?? 0,
        baseQuickNoteId: quickNoteId,
      })
    ).unwrap();

    props.callback && props.callback(shouldRefetch);
  };

  return (
    <CustomTooltip
      title={
        props.title ??
        'Creates a new document, transferring the maximum information possible from the original'
      }
    >
      <StyledLink
        onClick={() =>
          handleOpenQuickNoteDialog(
            props.quickNoteId,
            props.patientId ?? 0,
            props.episodeId
          )
        }
        data-cy="Update"
      >
        Update
      </StyledLink>
    </CustomTooltip>
  );
};

export default QuickNoteUpdateLink;
