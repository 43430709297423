import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clearEpisodeSummaryDirtyState } from 'store/ui/dirtyConfirmationModal/episodeSummary';

interface UseDirtyConfirmation {
  handleDirtyConfirm: (
    isDirty: boolean,
    onConfirm: () => void,
    onCancel?: () => void
  ) => void;
  clearDirtyState: () => void;
  showModal: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
}

export const useDirtyConfirmation = (): UseDirtyConfirmation => {
  const [showModal, setShowModal] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState<(() => void) | null>(
    null
  );
  const [cancelCallback, setCancelCallback] = useState<(() => void) | null>(
    null
  );
  const dispatch = useDispatch();
  const showDirtyConfirmation = useCallback(
    (onConfirm: () => void, onCancel?: () => void) => {
      setConfirmCallback(() => onConfirm);
      setCancelCallback(() => onCancel || null);
      setShowModal(true);
    },
    []
  );

  const handleDirtyConfirm = useCallback(
    (isDirty: boolean, onConfirm: () => void, onCancel?: () => void) => {
      if (isDirty) {
        showDirtyConfirmation(onConfirm, onCancel);
        return;
      }

      onConfirm();
    },
    [showDirtyConfirmation]
  );

  const clearDirtyState = () => {
    dispatch(clearEpisodeSummaryDirtyState());
  };

  const hideDirtyConfirmation = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleConfirm = useCallback(() => {
    confirmCallback?.();
    hideDirtyConfirmation();
  }, [confirmCallback, hideDirtyConfirmation]);

  const handleCancel = useCallback(() => {
    hideDirtyConfirmation();
    cancelCallback?.();
  }, [cancelCallback, hideDirtyConfirmation]);

  return {
    handleDirtyConfirm,
    clearDirtyState,
    showModal,
    handleConfirm,
    handleCancel,
  };
};
