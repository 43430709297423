import React from 'react';
import { ComputedValueComponent } from 'graphql/graphqlTypes';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from 'components/constants';
import { Typography } from '@mui/material';

export interface IReadOnlyTimerProps {
  component: ComputedValueComponent;
}

const ReadOnlyTimer = (props: IReadOnlyTimerProps) => {
  const { component } = props;

  let addValue = '';
  if (component.targetDate) {
    const targetDate = moment(component.targetDate);
    if (targetDate.isValid()) {
      addValue = ` (${targetDate.format(MOMENT_DATE_FORMAT)})`;
    }
  }
  return (
    <Typography variant="body1">
      {component.value}
      {addValue}
    </Typography>
  );
};

export default ReadOnlyTimer;
