import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

interface IStyledButtonProps {
  $activeFilter: boolean;
}

const StyledButtonGroupItem = styled(Button)<IStyledButtonProps>(
  ({ $activeFilter }) => ({
    padding: '5px 26px',
    border: $activeFilter
      ? `1px solid ${COLORS.SYMPHONY_BLUE}`
      : `1px solid ${COLORS.LIGHT_GREY}`,
    borderRightColor: $activeFilter
      ? `${COLORS.SYMPHONY_BLUE}!important`
      : COLORS.LIGHT_GREY,
    borderColor: $activeFilter ? COLORS.SYMPHONY_BLUE : COLORS.LIGHT_GREY,
    minWidth: '87px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    backgroundColor: $activeFilter ? '#c5e3ff' : 'white',
    color: $activeFilter ? COLORS.SYMPHONY_BLUE : COLORS.GREY100,
  })
);

export enum Filter {
  All,
  Favorite,
  Recent,
}

export interface IFilterProps {
  filters: Filter[];
  active?: Filter;
  onFilterChange?: (type: Filter) => void;
}

export const ProviderFilter = ({
  filters,
  active,
  onFilterChange,
}: IFilterProps) => {
  return (
    <ButtonGroup fullWidth={true}>
      {filters.map((filter: Filter, index: number) => (
        <StyledButtonGroupItem
          key={index}
          variant="outlined"
          size="small"
          $activeFilter={active === filter}
          data-cy={`providers-filter-${Filter[filter]}`}
          onMouseDown={(event) => {
            event?.preventDefault();
            onFilterChange?.(filter);
          }}
        >
          {Filter[filter]}
        </StyledButtonGroupItem>
      ))}
    </ButtonGroup>
  );
};
