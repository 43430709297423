import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  ChangeSet,
  Column,
  EditingState,
  FilteringState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  GridEditActions,
  StubCellComponent,
  SymphonyTable,
  SymphonyTableHeaderRow,
  TableEditCell,
  TableEditRows,
} from 'components/gridFormatters';
import { Getter } from '@devexpress/dx-react-core';
import { rearrangeColumnsEditLast } from 'components/home/attachmentsList/attachments.helpers';
import { PatientCareTeamItem } from './types';
import { getUpdatedRows } from './careTeam.helpers';

type ColumnExtension =
  | EditingState.ColumnExtension
  | FilteringState.ColumnExtension
  | IntegratedSorting.ColumnExtension
  | Table.ColumnExtension;

interface ICareTeamGridProps<T> {
  itemName: string;
  rows: T[];
  commitChanges: (changeSet: ChangeSet) => void;
  cancelChanges: (changeSet: ChangeSet) => void;
  columns: Column[];
  columnExtensions?: Array<ColumnExtension>;
  labelCell: React.ComponentType<Table.DataCellProps>;
  editCell: (
    props: TableEditRow.CellProps
  ) => ReactElement<TableEditRow.CellProps>;
  editEnabled: boolean;
  setEditEnabled: (status: boolean) => void;
  editingRowIds: number[];
  setEditingRowIds?: (rowIds: number[]) => void;
  validate: (rows: T[]) => boolean;
  onUpdatePatientTabValid: (valid: boolean) => void;
}

const CareTeamGrid = <T extends PatientCareTeamItem>(
  props: ICareTeamGridProps<T>
) => {
  const {
    itemName,
    rows,
    columns,
    columnExtensions,
    commitChanges,
    cancelChanges,
    labelCell,
    editCell,
    editEnabled,
    setEditEnabled,
    editingRowIds,
    setEditingRowIds,
    validate,
    onUpdatePatientTabValid,
  } = props;
  const [errors, setErrors] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rowChanges, setRowChanges] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const updatedRows = getUpdatedRows(rowChanges, rows);
    const isValid = validate(updatedRows);
    onUpdatePatientTabValid(isValid);
    setErrors(!isValid);
  }, [onUpdatePatientTabValid, rowChanges, rows, validate]);

  const onEditingRowIdsChange = (curEditingRowIds: Array<number | string>) => {
    setEditingRowIds?.(curEditingRowIds as number[]);
    setEditEnabled(curEditingRowIds.length === 0);
  };

  const onCancel = () => {
    const [id] = editingRowIds;
    if (id > 0) {
      return;
    }
    const cancelRow = rows.find((x) => x.id === id)!;
    const isValid = validate([cancelRow]);
    if (!isValid) {
      cancelChanges({ deleted: [id] });
    }
  };

  const cleanupRef = useRef({ editingRowIds, onCancel });
  cleanupRef.current.editingRowIds = editingRowIds;
  cleanupRef.current.onCancel = onCancel;
  useEffect(
    () => () => {
      if (cleanupRef.current.editingRowIds.length) {
        cleanupRef.current.onCancel();
      }
    },
    []
  );

  return (
    <Grid rows={rows ?? []} columns={columns} getRowId={(item) => item.id}>
      <EditingState
        onCommitChanges={commitChanges}
        columnExtensions={columnExtensions ?? []}
        onRowChangesChange={setRowChanges}
        rowChanges={rowChanges}
        editingRowIds={editingRowIds}
        onEditingRowIdsChange={onEditingRowIdsChange}
      />
      <SymphonyTable
        cellComponent={labelCell}
        columnExtensions={columnExtensions ?? []}
      />
      <SymphonyTableHeaderRow />
      <TableEditRow cellComponent={editCell} rowComponent={TableEditRows} />
      <TableEditColumn
        showEditCommand
        showDeleteCommand
        cellComponent={TableEditCell}
        headerCellComponent={StubCellComponent}
        commandComponent={(cmdProps) => (
          <GridEditActions
            {...cmdProps}
            errors={errors}
            editEnabled={editEnabled}
            itemName={itemName}
            onCancel={onCancel}
          />
        )}
        width={160}
      />
      <Getter name="tableColumns" computed={rearrangeColumnsEditLast} />
    </Grid>
  );
};

export default CareTeamGrid;
