import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';

const StyledGridItem = styled(Grid)({
  marginTop: '10px',
  marginBottom: '30px',
});
export interface ITitleProp {
  label?: string | null;
}

const Title = (props: ITitleProp) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4} />
      <StyledGridItem item xs={8}>
        {props.label}
      </StyledGridItem>
    </Grid>
  );
};
export default Title;
