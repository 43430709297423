import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdateRemindersMutationVariables = Types.Exact<{
  entitiesReminders:
    | Array<Types.InputMaybe<Types.UpdateReminderDtoInput>>
    | Types.InputMaybe<Types.UpdateReminderDtoInput>;
}>;

export type UpdateRemindersMutation = {
  __typename?: 'Mutation';
  updateEntityReminders?: boolean | null;
};

export const UpdateRemindersDocument = `
    mutation updateReminders($entitiesReminders: [UpdateReminderDtoInput]!) {
  updateEntityReminders(entitiesReminders: $entitiesReminders)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateReminders: build.mutation<
      UpdateRemindersMutation,
      UpdateRemindersMutationVariables
    >({
      query: (variables) => ({ document: UpdateRemindersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateRemindersMutation } = injectedRtkApi;
