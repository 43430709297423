import React from 'react';
import Icon, { ICONS, IIconProps } from '../icon';
import { COLORS } from 'consts/styles';
import Loader from 'components/loader';
import StyledLink from './StyledLink';
import { useDownloadFile } from './hooks';
import { IAttachmentLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IAttachmentViewLinkProps extends IAttachmentLinkProps {
  asIcon?: boolean;
  iconProps?: Partial<IIconProps>;
  label?: string;
  isDisabled: boolean;
}

const AttachmentViewLink = (props: IAttachmentViewLinkProps) => {
  const { downloadFile, isDownloadingFile } = useDownloadFile(props.label);

  let content: JSX.Element | string | undefined;
  if (props?.asIcon) {
    content = (
      <Icon
        icon={ICONS.Document}
        size={16}
        color={COLORS.SYMPHONY_BLUE}
        style={{ display: 'inline' }}
        {...props.iconProps}
      />
    );
  } else {
    content = props?.label ?? 'View';
  }

  const isNewFile = props.attachmentId < 0;

  return (
    <>
      <CustomTooltip title={props.title ?? 'View'}>
        <StyledLink
          onClick={() => {
            if (isNewFile) {
              return;
            }
            downloadFile({
              id: Number(props.attachmentId),
              name: props.fileName ?? '',
            });
          }}
          $isDisabled={props.isDisabled}
          data-cy="View"
        >
          {content}
        </StyledLink>
      </CustomTooltip>
      <Loader active={isDownloadingFile} />
    </>
  );
};

export default AttachmentViewLink;
