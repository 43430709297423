import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React from 'react';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

export interface ILinkFieldsIconProps {
  linkMessage?: string;
}

const StyledWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-left: 9px;
`;

const LinkFieldsIcon = (props: ILinkFieldsIconProps) => {
  return (
    <StyledWrapper>
      <CustomTooltip title={props.linkMessage ?? ''}>
        <Icon icon={ICONS.LinkFields} size={22} color={COLORS.GREY100} />
      </CustomTooltip>
    </StyledWrapper>
  );
};

export default LinkFieldsIcon;
