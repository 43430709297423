import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type ChangeReminderStateMutationVariables = Types.Exact<{
  model: Types.ChangeReminderStateDtoInput;
}>;

export type ChangeReminderStateMutation = {
  __typename?: 'Mutation';
  changeReminderState?: boolean | null;
};

export const ChangeReminderStateDocument = `
    mutation changeReminderState($model: ChangeReminderStateDtoInput!) {
  changeReminderState(model: $model)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    changeReminderState: build.mutation<
      ChangeReminderStateMutation,
      ChangeReminderStateMutationVariables
    >({
      query: (variables) => ({
        document: ChangeReminderStateDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeReminderStateMutation } = injectedRtkApi;
