import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from 'components/modal/DialogTitle';
import DialogActions from 'components/modal/DialogActions';
import {
  Button,
  DialogContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import EpisodeSummaryTitle from './Title';
import { useGetEpisodeSummaryHistoryQuery } from 'graphql/hooks/getEpisodeSummaryHistory';
import { EpisodeSummary } from 'graphql/graphqlTypes';
import Loader from 'components/loader';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import { DATETIME_SECONDS_FORMAT } from 'components/constants';
import MultiLineText from 'components/text/MultiLineText';
import { ChecklistStorageType } from 'store/actions/types';
import EpisodeSummaryEdit from './EpisodeSummaryEdit';
import ActionButton from 'components/scenario/ActionButton';
import { ICONS } from 'components/icon';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { useDirtyConfirmation } from 'hooks/useDirtyConfirmationModal';
import { DirtyConfirmationModal } from 'components/modal/DirtyConfirmationModal';
import { EditableActions } from './EpisodeSummary';

const StyledBody = styled.div`
  min-height: 566px;
  overflow-x: auto;
  background-color: ${COLORS.PALE_GREY};
  padding: 0px 10px 20px 28px;
`;

const StyledEditBody = styled.div`
  padding: 5px 25px 5px 10px;
  margin-bottom: 5px !important;
`;

const StyledDialogBody = styled(DialogContent)`
  padding: 0;
  background-color: ${COLORS.GREY4};
`;

const StyledScrollableSection = styled.div`
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
`;

export interface EpisodeSummaryDialogProps {
  open: boolean;
  episodeId?: number | null;
  onClose: () => void;
  storageType: ChecklistStorageType;
  refetchEpisodeSummary?: () => void;
}

const EpisodeSummaryDialog = (props: EpisodeSummaryDialogProps) => {
  const { open, onClose, episodeId, storageType, refetchEpisodeSummary } =
    props;
  const episodeIdInt = episodeId ? Number(episodeId) : 0;
  const episodeSummaryPrivilege = hasUserPrivilege(
    Privilege.AllowSeeEpisodeSummary
  );
  const [episodeSummaries, setEpisodeSummaries] = useState<EpisodeSummary[]>(
    []
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isEpisodeSummaryDirty, setIsEpisodeSummaryDirty] = useState(false);

  const { handleDirtyConfirm, handleConfirm, handleCancel, showModal } =
    useDirtyConfirmation();

  const {
    data,
    isFetching: isFetchingDetails,
    refetch: refetchEpisodeSummaryHistory,
  } = useGetEpisodeSummaryHistoryQuery(
    {
      episodeId: episodeIdInt,
    },
    { skip: episodeIdInt <= 0, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (
      !isFetchingDetails &&
      data?.getEpisodeSummaryHistory != undefined &&
      data?.getEpisodeSummaryHistory.length > 0
    ) {
      setEpisodeSummaries(data.getEpisodeSummaryHistory);
    }
  }, [data?.getEpisodeSummaryHistory, isFetchingDetails]);

  const handleClose = () => {
    handleDirtyConfirm(isEpisodeSummaryDirty, onConfirmDiscardChanges);
  };

  const onConfirmDiscardChanges = () => {
    setIsEditing(false);
    refetchEpisodeSummary?.();
    onClose();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <>
      <DirtyConfirmationModal
        showModal={showModal}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        sectionName={''}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <EpisodeSummaryTitle title="Episode Summary History" />
        </DialogTitle>
        <StyledDialogBody dividers>
          <Grid container>
            <Grid item xs={12}>
              {isEditing && (
                <StyledEditBody>
                  <EpisodeSummaryEdit
                    storageType={storageType}
                    summary={
                      episodeSummaries.length > 0
                        ? episodeSummaries[0].summary ?? ''
                        : ''
                    }
                    setIsEditing={setIsEditing}
                    refetchEpisodeSummaryHistory={refetchEpisodeSummaryHistory}
                    setIsEpisodeSummaryDirty={setIsEpisodeSummaryDirty}
                  />
                </StyledEditBody>
              )}
              <StyledBody>
                <Grid
                  container
                  alignItems="center"
                  style={{ paddingBottom: '20px' }}
                >
                  {episodeSummaries.length === 0 && (
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Typography variant="body1" mb={1}>
                        No summary history available
                      </Typography>
                    </Grid>
                  )}
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    {episodeSummaries.map((summary, index) => (
                      <Paper
                        key={summary.id}
                        elevation={3}
                        sx={{
                          pt: index === 0 ? 0 : 2,
                          pr: index === 0 ? 1 : 2,
                          pb: 2,
                          pl: 2,
                          mb: 2,
                          width: '98%',
                        }}
                      >
                        {index === 0 && !isEditing && (
                          <EditableActions>
                            <ActionButton
                              handleClick={handleEditClick}
                              icon={ICONS.EditAlt}
                              iconSize={20}
                              testId="summary-edit-icon"
                              tooltipText="Edit Summary"
                              text={''}
                              disabled={!episodeSummaryPrivilege}
                            />
                          </EditableActions>
                        )}

                        <Typography variant="body1" mb={1}>
                          <StyledScrollableSection
                            data-testid={`multiline-text-${index}`}
                          >
                            <MultiLineText text={summary?.summary ?? ''} />
                          </StyledScrollableSection>
                        </Typography>
                        <Divider sx={{ mt: 1, mb: 1, opacity: 0.95 }} />
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Typography variant="body2" color="textSecondary">
                              {summary.author}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mr: 2,
                            }}
                          >
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{
                                backgroundColor: '#e2e3de',
                                height: 'auto',
                                ml: 1,
                              }}
                            />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ ml: 1 }}
                            >
                              {formatDateTime(
                                summary.timeStamp,
                                DATETIME_SECONDS_FORMAT
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}
                  </Grid>
                </Grid>
                <Loader active={isFetchingDetails} />
              </StyledBody>
            </Grid>
          </Grid>
        </StyledDialogBody>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: '20px 28px' }}
        >
          <div style={{ flexGrow: 1 }} />
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              data-cy="-note-cancel"
              size="large"
              style={{ minWidth: '150px', height: '40px' }}
            >
              Exit
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EpisodeSummaryDialog;
