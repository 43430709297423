import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { Button, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { MozartActionContainer } from './MozartActionContainer';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { MozartIndicator } from 'features/mozart/components/MozartIndicator/MozartIndicator';
import { ChecklistStorageType } from 'store/actions/types';
import { getLastCompletedAuthorizationStatus } from 'features/mozart/MozartWorkflowLayout.helpers';
import { MozartReminders } from './MozartReminders';

const StyledContentWrapper = styled.div`
  padding-left: 12px;
`;

const StyledTypography = styled(Typography)`
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 18px;
  padding-top: 2px;
`;

const StyledButton = styled(Button)`
  margin-top: 28px;
`;

const StyledStatusContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${COLORS.GREY4};
  padding: 16px 32px;
  margin: 20px -32px 0px -32px;
`;

const StyledEpisodeStatusLabel = styled(Typography)`
  padding-right: 12px;
`;

const StyledContainer = styled.div`
  display: flex;
`;
const IconColumn = styled.div``;

export interface IMozartActionSection {
  actionTitle: string;
  actionText: string;
  actionIcon: ICONS;
  actionIconColor: COLORS;
  actionButtonText?: string;
  actionButtonHandler?: () => void;
  isDoneStep?: boolean;
  borderColor?: COLORS;
}

export const MozartActionSection = ({
  actionTitle,
  actionText,
  actionIcon,
  actionIconColor,
  actionButtonText,
  actionButtonHandler,
  isDoneStep,
  borderColor,
}: IMozartActionSection) => {
  const markUp = { __html: actionText };
  const isMozartReadOnly = useSelector(
    (state: IState) => state.mozart.mozartViewOnly
  );

  const authorizationStatus = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].checklist
        ?.authorizationStatus?.name
  );
  const completedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );

  const status =
    authorizationStatus ??
    getLastCompletedAuthorizationStatus(completedChecklists);

  return (
    <MozartActionContainer isDoneStep={isDoneStep} borderColor={borderColor}>
      <StyledContainer>
        <IconColumn>
          <Icon size={24} icon={actionIcon} color={actionIconColor} />
        </IconColumn>
        <StyledContentWrapper>
          <StyledTypography variant="h3">{actionTitle}</StyledTypography>
          <Typography dangerouslySetInnerHTML={markUp} />
          {actionButtonText && (
            <StyledButton
              onClick={actionButtonHandler}
              data-testid="mozart-action-button"
              variant="outlined"
              disabled={isMozartReadOnly}
            >
              {actionButtonText}
            </StyledButton>
          )}
        </StyledContentWrapper>
      </StyledContainer>

      {isDoneStep && status ? (
        <StyledStatusContainer>
          <StyledEpisodeStatusLabel variant="body2">
            Episode status:
          </StyledEpisodeStatusLabel>
          <MozartIndicator />
        </StyledStatusContainer>
      ) : null}
      {isDoneStep ? <MozartReminders /> : null}
    </MozartActionContainer>
  );
};
