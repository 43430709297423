import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMozartChecklistQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  readOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetMozartChecklistQuery = {
  __typename?: 'EntitiesQuery';
  getMozartChecklist?: {
    __typename?: 'GetMozartActionProcessResult';
    pollingTimeout: number;
    mozartWorkflowInstanceState?: string | null;
    checklist?: { __typename?: 'Checklist'; id: number } | null;
    steps?: Array<{
      __typename?: 'MozartWorkflowUIModel';
      id: number;
      uniqueMsgId: any;
      scopeId: any;
      workflowInstanceId: any;
      stepInstanceId: any;
      actionInstanceId: any;
      autoScrollToActionInstanceId?: any | null;
      checkListId: number;
      stepName?: string | null;
      createdBy?: string | null;
      createdOn?: any | null;
      updatedBy?: string | null;
      updatedOn?: any | null;
      postEntityDiffLocationURI?: string | null;
      state: Types.MozartProgressState;
      actionType: Types.MozartActionType;
      type: Types.MozartDefinitionType;
      roles?: Array<number> | null;
      executionNote?: string | null;
      rightHead?: string | null;
      leftHead?: string | null;
      showMessageKey?: string | null;
      showMessageValue?: string | null;
    } | null> | null;
  } | null;
};

export const GetMozartChecklistDocument = `
    query getMozartChecklist($id: UUID!, $readOnly: Boolean) {
  getMozartChecklist(id: $id, readOnly: $readOnly) {
    checklist {
      id
    }
    steps {
      id
      uniqueMsgId
      scopeId
      workflowInstanceId
      stepInstanceId
      actionInstanceId
      autoScrollToActionInstanceId
      checkListId
      stepName
      createdBy
      createdOn
      updatedBy
      updatedOn
      postEntityDiffLocationURI
      state
      actionType
      type
      roles
      executionNote
      rightHead
      leftHead
      showMessageKey
      showMessageValue
    }
    pollingTimeout
    mozartWorkflowInstanceState
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMozartChecklist: build.query<
      GetMozartChecklistQuery,
      GetMozartChecklistQueryVariables
    >({
      query: (variables) => ({
        document: GetMozartChecklistDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetMozartChecklistQuery, useLazyGetMozartChecklistQuery } =
  injectedRtkApi;
