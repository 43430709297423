import React from 'react';
import { Box, styled } from '@mui/material';
import { ChecklistItemAssociatedClaims } from 'graphql/graphqlTypes';
import AssociatedClaimTable from 'components/actions/sections/SectionBody/Items/AssociatedClaim/AssociatedClaimTable';
import { mapAssociatedClaim } from 'components/actions/sections/SectionBody/Items/AssociatedClaim/AssociatedClaim.helper';

const StyledBox = styled(Box)({
  padding: '14px 64px 0px 64px',
});

export interface IAssociatedClaimReviewProps {
  item: ChecklistItemAssociatedClaims;
}

const AssociatedClaimReview = ({ item }: IAssociatedClaimReviewProps) => {
  const associatedClaimAttributes = item.options.associatedClaimAttributes;
  const claimActionValues = mapAssociatedClaim(item.acValue);

  return claimActionValues.length > 0 ? (
    <StyledBox id={`uuid-${item.uid}`}>
      <AssociatedClaimTable
        claimAttributes={associatedClaimAttributes}
        claimActionValues={claimActionValues}
      />
    </StyledBox>
  ) : null;
};
export default AssociatedClaimReview;
