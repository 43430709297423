import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import { Box } from '@mui/material';

const PriorAuthReqIconFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  {
    return props.value ? (
      <Box data-testid="Checkbox_selected">
        <Icon
          icon={ICONS.Checkbox_selected}
          size={14}
          color={COLORS.SYMPHONY_BLUE}
        />
      </Box>
    ) : (
      <Box data-testid="Checkbox_deselected">
        <Icon
          icon={ICONS.Checkbox_deselected}
          size={14}
          color={COLORS.GREY100}
        />
      </Box>
    );
  }
};

export default PriorAuthReqIconFormatter;
