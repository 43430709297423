import { ChangeSet } from '@devexpress/dx-react-grid';

interface RowWithId {
  id: number | string;
}

export const getChangedRows = <T extends RowWithId>(
  changeSet: ChangeSet,
  rows: T[]
): T[] => {
  let changedRows: T[] = rows;
  const { added, changed, deleted } = changeSet;
  if (added) {
    changedRows = [...rows, ...added];
  }
  if (changed) {
    changedRows = changedRows.map((row) =>
      changed[row.id] ? { ...row, ...changed[row.id] } : row
    );
  }
  if (deleted) {
    const deletedSet = new Set(deleted);
    changedRows = changedRows.filter((row) => !deletedSet.has(row.id));
    // eslint-disable-next-line no-console
    console.warn(
      'Are you sure it is expected behaviour? ' +
        'Delete will just drop items from array.'
    );
  }
  return changedRows;
};
