import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type LockMozartActionMutationVariables = Types.Exact<{
  workflowInstanceId: Types.Scalars['UUID'];
  actionInstanceId: Types.Scalars['UUID'];
}>;

export type LockMozartActionMutation = {
  __typename?: 'Mutation';
  lockMozartAction?: {
    __typename?: 'MozartActionResult';
    result: boolean;
    message?: string | null;
  } | null;
};

export const LockMozartActionDocument = `
    mutation lockMozartAction($workflowInstanceId: UUID!, $actionInstanceId: UUID!) {
  lockMozartAction(
    workflowInstanceId: $workflowInstanceId
    actionInstanceId: $actionInstanceId
  ) {
    result
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    lockMozartAction: build.mutation<
      LockMozartActionMutation,
      LockMozartActionMutationVariables
    >({
      query: (variables) => ({ document: LockMozartActionDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLockMozartActionMutation } = injectedRtkApi;
