import React from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { Provider } from 'graphql/graphqlTypes';

const StyledMenuItem = styled(MenuItem)({
  padding: '6px 20px',
  display: 'block',
  width: '100%',
  maxWidth: '440px',
  '&.Mui-selected': {
    backgroundColor: COLORS.WHITE,
  },
});

const StyledListItemContainer = styled.ul`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexBox = styled(Box)({
  display: 'flex',
  marginTop: '0px',
  marginBottom: '0px',
  maxWidth: '98%',
});

const StyledTypography = styled(Typography)({
  margin: '0px 3px',
});

export const ProviderOption = (option: Provider) => {
  return (
    <StyledListItemContainer>
      <StyledMenuItem>
        <FlexBox>
          <StyledTypography variant="body2">
            {option.firstName} {option.lastName}
          </StyledTypography>
        </FlexBox>
        <FlexBox>
          <StyledTypography variant="body1">
            {option.providerId}
          </StyledTypography>
          <StyledTypography variant="body1">{option.nPI}</StyledTypography>
        </FlexBox>
      </StyledMenuItem>
    </StyledListItemContainer>
  );
};
