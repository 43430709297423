import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetCareSiteQueryVariables = Types.Exact<{
  caresiteId: Types.Scalars['String'];
}>;

export type GetCareSiteQuery = {
  __typename?: 'EntitiesQuery';
  getCareSite?: {
    __typename?: 'CareSite';
    supportInformation?: string | null;
  } | null;
};

export const GetCareSiteDocument = `
    query getCareSite($caresiteId: String!) {
  getCareSite(caresiteId: $caresiteId) {
    supportInformation
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCareSite: build.query<GetCareSiteQuery, GetCareSiteQueryVariables>({
      query: (variables) => ({ document: GetCareSiteDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCareSiteQuery, useLazyGetCareSiteQuery } = injectedRtkApi;
