import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Typography } from '@mui/material';

export const EpisodeTypeCell = (props: ILabelCellProps) => {
  const { value } = props;
  return (
    <Table.Cell {...props} style={CellComponentStyle}>
      <Typography>{value?.type?.name}</Typography>
    </Table.Cell>
  );
};
