import React, { useEffect } from 'react';
import { Box, Link, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import store, { IState } from 'store';
import {
  FailedRequestItem,
  removeFailedRequest,
} from 'store/failedRequests/failedRequestsSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { setChecklistItemSaving } from 'store/actions/checklistSlice';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import styled from 'styled-components';
import { useUpdateActionValue } from 'components/actions/sections/SectionBody/Items/UpdateActionValue.helpers';
import { useSelectAction } from 'components/actions/sections/SectionBody/Items/SelectActionMutation.helpers';
import {
  SelectActionModelInput,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import useLockMozartActionImmediately from 'features/mozart/components/MozartContent/components/useLockMozartActionImmediately';
import { LockMozartActionMutationVariables } from 'graphql/hooks/lockMozartAction';

export const UpdateActionValueRequestName = 'updateActionValue';
export const SelectActionRequestName = 'selectAction';
export const LockMozartActionRequestName = 'lockMozartAction';
export const CreateOrUpdatePatientRequestName = 'createOrUpdatePatient';

export const actionNamesHandledInFailedRequests = [
  UpdateActionValueRequestName,
  SelectActionRequestName,
  LockMozartActionRequestName,
  CreateOrUpdatePatientRequestName,
];

const StyledErrorPaper = styled(Paper)`
  background-color: ${COLORS.RED20};
  display: flex;
  padding: 9px 60px;
  align-items: center;
  border-radius: 0;
  position: sticky;
  top: 64px;
  z-index: 20;
  border-bottom: 1px solid ${COLORS.GREY25};
`;

const TryAgainLink = styled(Link)({
  cursor: 'pointer',
  display: 'inline-block',
});

export interface IFailedRequestsParams {
  storageType: ChecklistStorageType;
}

const getErrorMessage = (lockMozartActionFailedRequest?: FailedRequestItem) => {
  if (!lockMozartActionFailedRequest) {
    return 'Your changes could not be saved, because of technical issues. ';
  }

  return `Failed to maintain workflow lock${
    lockMozartActionFailedRequest.message
      ? `(${lockMozartActionFailedRequest.message}). `
      : '. '
  }`;
};

const FailedRequests = ({ storageType }: IFailedRequestsParams) => {
  const { updateActionValueExtended } = useUpdateActionValue();
  const { selectActionExtended } = useSelectAction();
  const lockMozartActionImmediately = useLockMozartActionImmediately();
  const failedRequests = useSelector(
    (state: IState) => state.failedRequest.requests ?? []
  );

  const lockMozartActionFailedRequest = failedRequests.find(
    (item: FailedRequestItem) => item.name === LockMozartActionRequestName
  );

  useEffect(() => {
    const hasFailedRequests = failedRequests.length > 0;
    store.dispatch(
      setChecklistItemSaving({
        storageType,
        isSaving: hasFailedRequests,
      })
    );

    window.onbeforeunload = () =>
      hasFailedRequests
        ? 'This_will_show_notification_before_closing_window'
        : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedRequests]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    retryRequests();
  };

  const retryRequests = () => {
    if (lockMozartActionFailedRequest) {
      const data =
        lockMozartActionFailedRequest.data as LockMozartActionMutationVariables;
      lockMozartActionImmediately(
        data.workflowInstanceId,
        data.actionInstanceId,
        () => retryOtherRequests(failedRequests)
      );
    } else {
      retryOtherRequests(failedRequests);
    }
  };

  const retryOtherRequests = (requests: FailedRequestItem[]) => {
    retryUpdateActionValueRequests(requests);
    retrySelectActionRequests(requests);
    logUnsupportedRequests(requests);
  };

  const retryUpdateActionValueRequests = (requests: FailedRequestItem[]) => {
    requests
      .filter(
        (item: FailedRequestItem) => item.name === UpdateActionValueRequestName
      )
      .forEach((request: FailedRequestItem) => {
        updateActionValueExtended(
          request.data as UpdateActionValueModelInput,
          request.idToRetry
        );
        if (request.idToRetry) {
          store.dispatch(removeFailedRequest(request.idToRetry));
        }
      });
  };

  const retrySelectActionRequests = (requests: FailedRequestItem[]) => {
    requests
      .filter(
        (item: FailedRequestItem) => item.name === SelectActionRequestName
      )
      .forEach((request: FailedRequestItem) => {
        selectActionExtended(
          request.data as SelectActionModelInput,
          request.idToRetry
        );
        if (request.idToRetry) {
          store.dispatch(removeFailedRequest(request.idToRetry));
        }
      });
  };

  const logUnsupportedRequests = (requests: FailedRequestItem[]) => {
    const unsupportedRequests = requests.filter(
      (item: FailedRequestItem) =>
        !actionNamesHandledInFailedRequests.includes(item.name)
    );
    unsupportedRequests.forEach((request: FailedRequestItem) => {
      // eslint-disable-next-line no-console
      console.error('Request is not supported to send retry: ', request.name);
    });
  };

  return (
    <>
      {failedRequests.length > 0 ? (
        <StyledErrorPaper>
          <>
            <Box display="flex" flexGrow={1} alignItems="center">
              <Icon
                size={18}
                icon={ICONS.warning_filled}
                color={COLORS.RED100}
                style={{ marginTop: '-2px' }}
              />
              <Box paddingLeft="10px">
                <Typography
                  variant="body1"
                  data-testid="current-message"
                  style={{ color: '#560001' }}
                >
                  <span style={{ fontWeight: 700 }}>
                    Internal server error:{' '}
                  </span>
                  {getErrorMessage(lockMozartActionFailedRequest)}
                  <TryAgainLink
                    onClick={handleClick}
                    data-testid="failed-requests-try-agin-link"
                  >
                    Try again
                  </TryAgainLink>
                </Typography>
              </Box>
            </Box>
          </>
        </StyledErrorPaper>
      ) : null}
    </>
  );
};

export default FailedRequests;
