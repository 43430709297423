import { ILookupValue } from 'backend/types/lookupValue';
import { Typography } from '@mui/material';
import React from 'react';

export interface IReadonlyEntityAttributeProps {
  selectedValues: ILookupValue[];
}

export const ReadonlyEntityAttribute = (
  props: IReadonlyEntityAttributeProps
) => {
  const names = props.selectedValues?.map((lv: ILookupValue) => lv.name) || [];
  return <Typography noWrap>{names.join(',')}</Typography>;
};
