import React from 'react';
import { suggestEntities } from 'store/patientdetails/middlewares';

import ActionButton from '../../Components/ActionButton/ActionButton';
import EntityAttribute from '../../Components/EntityAttribute';
import ComputedValue from '../../Components/ComputedValue';
import Reminder from '../../Components/Reminder';
import DateTime from '../../Components/DateTime';
import Dropdown from '../../Components/Dropdown';
import UserFreeText from '../../Components/UserFreeText';
import DateComp from '../../Components/Date';
import {
  Expression,
  FixedText,
  IExpressionProps,
  IFixedTextProps,
} from 'features/checklist';
import { ChecklistStorageType } from 'store/actions/types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ICheckBoxRadioSelection } from './Orderable';
import {
  ActionButtonComponent,
  BaseComponent,
  CheckboxRadioButtonComponent,
  ChecklistItemOrderable,
  ChecklistProblemViewModel,
  ComputedValueComponent,
  DateComponent,
  DateTimeComponent,
  DropdownComponent,
  EntityAttributeComponent,
  ExternalUrlComponent,
  ReminderComponent,
  UserFreeTextComponent,
} from 'graphql/graphqlTypes';
import CheckboxRadioComponent from '../../Components/RadioCheckBoxButton/CheckboxRadioComponent';
import ExternalUrl from '../../Components/ExternalUrl';
import { checklistItemComponentWidth } from 'util/helpers/styleHelpers';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';

interface IStyledBaseComponentProps {
  componentWidth: string;
  componentColor: string;
}

const ComponentContainer = styled.div<IStyledBaseComponentProps>`
  width: ${(props) =>
    props.componentWidth === 'auto' ? '100%' : props.componentWidth};
  color: ${(props) => `${props.componentColor}!important` ?? 'inherit'};
  margin-right: 16px;
  display: flex;
  align-self: center;
  font-size: 14px;
  align-items: center;
`;

export interface IOrderableComponentProps {
  // TODO 2: move internal component logic (& prop extraction) to components themselves.
  component: BaseComponent;
  componentIndex: number;
  isActionSelected: boolean;
  lastComponentInRow: boolean;
  storageType: ChecklistStorageType;
  stoplightStatusCompleted: boolean | null;
  item: ChecklistItemOrderable;
  categoryId: string;
  autoSave: boolean;
  sectionItemId: string;
  isReadOnly: boolean;
  selectedValue: { [groupId: string]: ICheckBoxRadioSelection[] };
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent,
    componentId?: string,
    fieldName?: string | null,
    itemUid?: string
  ) => void;
  carePlanProblems?: ChecklistProblemViewModel[];
  updateCarePlanProblems?: (problems: ChecklistProblemViewModel[]) => void;
}

const OrderableComponentInner = ({
  component,
  componentIndex,
  isActionSelected,
  lastComponentInRow,
  storageType,
  stoplightStatusCompleted,
  item,
  categoryId,
  autoSave,
  isReadOnly,
  selectedValue,
  sectionItemId,
  handleChecklistInputChange,
  carePlanProblems,
  updateCarePlanProblems,
}: IOrderableComponentProps) => {
  const dispatch = useDispatch();

  const hasCondition = (value: string) =>
    item.conditions.some((cond) => cond.value === value);

  const hasConditionValue = (value: string, conditionValue: string) =>
    item.conditions.some(
      (cond) => cond.value === value && cond.conditionValue == conditionValue
    );

  const width = checklistItemComponentWidth(
    component?.width,
    component?.style ?? ''
  );

  const isUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    sectionItemId,
    component.id
  );

  const renderComponent = (componentType: string) => {
    switch (componentType) {
      case 'FixedTextComponent':
        return (
          <FixedText
            key={componentIndex}
            {...(component as IFixedTextProps)}
            hasError={!stoplightStatusCompleted}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
            hasStoplights={!!item.qualityMeasure?.id}
          />
        );
      case 'ExpressionComponent':
        return (
          <Expression
            key={componentIndex}
            {...(component as unknown as IExpressionProps)}
            hasError={!stoplightStatusCompleted}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
            hasStoplights={!!item.qualityMeasure?.id}
            storageType={storageType}
            categoryId={categoryId}
            isActionSelected={isActionSelected}
            onChange={(value) => handleChecklistInputChange(value, component)}
          />
        );
      case 'ReminderComponent':
        return (
          <Reminder
            component={component as ReminderComponent}
            handleChecklistInputChange={handleChecklistInputChange}
            isActionSelected={isActionSelected}
            categoryId={categoryId}
            storageType={storageType}
            autoSave={autoSave}
            disabled={isReadOnly}
            isReadOnly={isReadOnly}
          />
        );
      case 'CheckboxRadioButtonComponent': {
        return (
          <CheckboxRadioComponent
            key={componentIndex}
            component={component as CheckboxRadioButtonComponent}
            categoryId={categoryId}
            sectionItemId={sectionItemId}
            selected={selectedValue}
            storageType={storageType}
            handleChecklistInputChange={handleChecklistInputChange}
            hasCondition={hasCondition}
            isActionSelected={isActionSelected}
            autoSave={autoSave}
            carePlanProblems={carePlanProblems}
            updateCarePlanProblems={updateCarePlanProblems}
            isReadOnly={isReadOnly}
          />
        );
      }
      case 'UserFreeTextComponent':
        return (
          <UserFreeText
            key={componentIndex}
            component={component as UserFreeTextComponent}
            storageType={storageType}
            handleChecklistInputChange={handleChecklistInputChange}
            isActionSelected={isActionSelected}
            categoryId={categoryId}
            autoSave={autoSave}
            lastComponentInRow={lastComponentInRow}
            isReadOnly={isReadOnly}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
          />
        );
      case 'DateComponent':
        return (
          <DateComp
            key={componentIndex}
            component={component as DateComponent}
            storageType={storageType}
            handleChecklistInputChange={handleChecklistInputChange}
            isActionSelected={isActionSelected}
            categoryId={categoryId}
            autoSave={autoSave}
            isReadOnly={isReadOnly}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
            sectionItemId={sectionItemId}
            fieldName=""
          />
        );
      case 'ActionButtonComponent':
        return (
          <ActionButton
            component={component as ActionButtonComponent}
            storageType={storageType}
            actionId={item.uid}
            item={item}
            handleChecklistInputChange={handleChecklistInputChange}
            isReadOnly={isReadOnly}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
          />
        );
      case 'DropdownComponent':
        return (
          <Dropdown
            key={componentIndex}
            component={component as DropdownComponent}
            storageType={storageType}
            handleChecklistInputChange={handleChecklistInputChange}
            handleSuggestItems={(entity, request, callback) => {
              dispatch(suggestEntities(entity, request, callback));
            }}
            isActionSelected={isActionSelected}
            categoryId={categoryId}
            autoSave={autoSave}
            isReadOnly={isReadOnly}
            parentWidth={width}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
          />
        );
      case 'DateTimeComponent':
        return (
          <DateTime
            key={componentIndex}
            component={component as DateTimeComponent}
            storageType={storageType}
            handleChecklistInputChange={handleChecklistInputChange}
            isActionSelected={isActionSelected}
            categoryId={categoryId}
            autoSave={autoSave}
            isReadOnly={isReadOnly}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
            sectionItemId={sectionItemId}
            fieldName=""
          />
        );
      case 'EntityAttributeComponent':
        return (
          <EntityAttribute
            key={componentIndex}
            component={component as EntityAttributeComponent}
            categoryId={categoryId}
            sectionItemId={sectionItemId}
            storageType={storageType}
            handleChecklistInputChange={handleChecklistInputChange}
            handleSuggestItems={(entity, request, callback) => {
              dispatch(suggestEntities(entity, request, callback));
            }}
            isActionSelected={isActionSelected}
            autoSave={autoSave}
            lastComponentInRow={lastComponentInRow}
            isReadOnly={isReadOnly}
            parentWidth={width}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
            hasCondition={hasConditionValue}
          />
        );
      case 'ComputedComponent':
        return (
          <ComputedValue
            key={componentIndex}
            component={component as ComputedValueComponent}
            storageType={storageType}
            categoryId={categoryId}
            handleChecklistInputChange={handleChecklistInputChange}
            isActionSelected={isActionSelected}
            isReadOnly={isReadOnly}
          />
        );
      case 'ExternalUrlComponent':
        return (
          <ExternalUrl
            key={componentIndex}
            component={component as ExternalUrlComponent}
          />
        );
      default:
        return <>{component.componentType}</>;
    }
  };
  return (
    <ComponentContainer
      componentWidth={width}
      componentColor={component?.color ?? 'inherit'}
    >
      {renderComponent(component.componentType)}
    </ComponentContainer>
  );
};

export const OrderableComponent = (props: IOrderableComponentProps) => {
  const component = props.component;
  const cypressId = `${component.componentType}_${component.id}_${component.userDefinedId}_${component.value}_${component.name}`;

  return (
    <div
      id={`uuid-${component.uniqueID}`}
      data-cy={cypressId}
      style={
        props.lastComponentInRow &&
        (component.componentType === 'UserFreeTextComponent' ||
          component.componentType === 'EntityAttributeComponent')
          ? { width: '100%' }
          : undefined
      }
    >
      <OrderableComponentInner {...props} />
    </div>
  );
};
