import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { AutocompleteCell } from 'components/gridFormatters';
import { ILookupValue } from 'backend/types/lookupValue';

export const OwnerEditCell = (props: TableEditRow.CellProps) => {
  const users = useSelector((state: IState) => state.home.mainModel.users).map(
    (user) => ({ id: user.id, name: user.name } as ILookupValue)
  );
  return <AutocompleteCell options={users} {...props} />;
};
