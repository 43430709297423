import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomeTabsBody from 'components/home/TabsBody';
import {
  getHomeModelSuccess,
  getTabModelSuccess,
  updateActiveTab,
} from 'store/home/action';
import { clearSearch } from 'store/ui/header';
import { IState } from 'store';
import AntTab from 'components/tabs/AntTab';
import { IHomeModel, ITabModel, SearchTab } from 'store/home/types';
import { Tabs } from '@mui/material';
import Loader from 'components/loader';
import { useGetHomeModelQuery } from 'graphql/hooks/getHomePageModel';
import { useGetTabModelQuery } from 'graphql/hooks/getHomePageTabModel';
import { EntityListTabDefinition } from 'graphql/graphqlTypes';
import DropdownTab from 'components/home/DropdownTab';
import { useWidth } from 'hooks/useWidth';

export interface ISortedTabs {
  visibleTabs: EntityListTabDefinition[];
  dropdownTabs: EntityListTabDefinition[];
}

export const getSplitTabs = (
  tabs: EntityListTabDefinition[],
  maxNumber: number,
  screenSize: string
): ISortedTabs => {
  if (tabs && tabs?.length > 0) {
    const offsetMaxNumber =
      maxNumber - (screenSize === 'lg' || screenSize === 'xs' ? 1 : 2);
    if (screenSize === 'xs') {
      return { visibleTabs: [], dropdownTabs: tabs };
    }
    if (screenSize !== 'xs') {
      return tabs.length <= offsetMaxNumber
        ? { visibleTabs: tabs, dropdownTabs: [] }
        : {
            visibleTabs: tabs.slice(0, offsetMaxNumber),
            dropdownTabs: tabs.slice(offsetMaxNumber, tabs.length),
          };
    }
  }

  return { visibleTabs: [], dropdownTabs: [] };
};

const MainTabs = ({
  isHomeGridDataLoading,
}: {
  isHomeGridDataLoading: boolean;
}) => {
  const currentRole = useSelector(
    (state: IState) => state.user.currentUser.role
  );
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const activeTab = useSelector(
    (state: IState) => state.home?.homeModel?.activeTab
  );
  const [modelLoadingFinished, setModelLoadingFinished] = useState(false);
  const dispatch = useDispatch();
  const tabsRef = useRef<HTMLDivElement>(null);

  const { width, screenSize } = useWidth(tabsRef.current);

  const maxTabCount = width && Math.round(width / 130);

  const {
    data: homeModelData,
    isFetching: isFetchingHomeModel,
    isSuccess: isHomeModelLoaded,
    refetch: refetchHomeModel,
  } = useGetHomeModelQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: tabModelData,
    isFetching: isFetchingTabModel,
    refetch: refetchTabModel,
    isUninitialized,
  } = useGetTabModelQuery(
    { tabId: activeTab },
    {
      refetchOnMountOrArgChange: true,
      skip: !isHomeModelLoaded || activeTab === SearchTab,
    }
  );

  useEffect(() => {
    if (!isUninitialized) {
      refetchTabModel();
      refetchHomeModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRole, currentCareSiteId]);

  useLayoutEffect(() => {
    if (homeModelData && tabModelData) {
      const modelFilteredTabs =
        homeModelData.getHomeModel?.tabs?.filter(
          (tab) => tab?.name !== 'Search'
        ) ?? [];
      const model = {
        ...homeModelData.getHomeModel,
        tabs: modelFilteredTabs,
        activeTab: homeModelData?.getHomeModel?.activeTab,
      };
      dispatch(getHomeModelSuccess(model as IHomeModel));
      if (tabModelData) {
        dispatch(getTabModelSuccess(tabModelData.getTabModel as ITabModel));
      }
      setModelLoadingFinished(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeModelData, tabModelData]);

  useLayoutEffect(() => {
    if (modelLoadingFinished) {
      setTimeout(() => {
        setModelLoadingFinished(false);
      }, 10);
    }
  }, [modelLoadingFinished]);

  const filteredTabs = homeModelData?.getHomeModel?.tabs?.filter(
    (tab) => tab?.name !== 'Search'
  );

  const handleTabChange = (tabIndex: number | string) => {
    activeTab !== tabIndex && dispatch(updateActiveTab(tabIndex as number));
    if (activeTab === SearchTab) {
      dispatch(clearSearch());
    }
  };

  const isFetchingAnyModel =
    isFetchingHomeModel || isFetchingTabModel || isHomeGridDataLoading;

  const tabs =
    filteredTabs &&
    getSplitTabs(
      filteredTabs as EntityListTabDefinition[],
      maxTabCount ?? 0,
      screenSize
    );

  return (
    <div
      style={{ flexGrow: 1 }}
      ref={tabsRef}
      data-testid="home-tabs-body"
      data-cy="home-tabs-body"
    >
      <>
        {activeTab !== SearchTab && (
          <Tabs
            value={activeTab !== SearchTab && activeTab}
            onChange={(_, value) => handleTabChange(value)}
            aria-label="main grid"
            variant="scrollable"
            scrollButtons={false}
          >
            {tabs &&
              tabs.visibleTabs.length > 0 &&
              tabs?.visibleTabs?.map((item, index) => (
                <AntTab
                  key={item?.index}
                  label={item?.name ?? ''}
                  value={item?.index ?? 0}
                  isLast={
                    tabs.dropdownTabs.length === 0 &&
                    tabs.visibleTabs.length === index + 1
                  }
                  isFirst={index === 0}
                />
              ))}
            {tabs && tabs?.dropdownTabs.length > 0 ? (
              <DropdownTab
                tabs={tabs.dropdownTabs}
                activeTab={activeTab}
                handleTabChange={handleTabChange}
              />
            ) : null}
            <div style={{ borderBottom: '1px solid #D7D7D7', width: '100%' }} />
          </Tabs>
        )}
        <HomeTabsBody
          isLoadingCompleted={modelLoadingFinished}
          handleSetLoadingComplete={setModelLoadingFinished}
        />
        <Loader active={isFetchingAnyModel} />
      </>
    </div>
  );
};

export default MainTabs;
