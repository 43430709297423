import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Loader from 'components/loader';
import { useCommitRisksChanges } from './risk.hooks';
import CareGapsGrid from './careGapsGrid';
import { careGapsColumns, editCareGap, labelCareGap } from './careGaps';
import RiskScoresView from './riskScoresView';
import { labelRiskScore, riskScoresColumns } from './riskScores';
import ClinicalConditionView from './clinicalConditionView';
import { setIsInEditMode } from 'store/patientdetails/patientDetailsSlice';
import { useDispatch } from 'react-redux';

export interface IRisksProps {
  handleUpdatePatientTabValid: (valid: boolean) => void;
  disabled: boolean;
}

const RiskTab = (props: IRisksProps) => {
  const dispatch = useDispatch();
  const { handleUpdatePatientTabValid, disabled } = props;
  const { risksData, careGapsCommitChanges, saveCareGapsChanges } =
    useCommitRisksChanges();

  const [editEnabled, setEditEnabled] = useState(!disabled ?? true);

  useEffect(() => {
    dispatch(setIsInEditMode({ isInEditMode: !editEnabled }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEnabled]);

  if (!risksData) {
    return <Loader active={true} />;
  }

  return (
    <Grid container>
      <Grid item>
        <CareGapsGrid
          title="Potential Quality of Care Gaps"
          itemName="Gap"
          rows={risksData.patientCareGap}
          columns={careGapsColumns}
          commitChanges={careGapsCommitChanges}
          saveChanges={saveCareGapsChanges}
          editCell={editCareGap}
          labelCell={labelCareGap}
          getRowId={(row) => row.id}
          editEnabled={editEnabled}
          setEditEnabled={setEditEnabled}
          handleUpdatePatientTabValid={handleUpdatePatientTabValid}
        />
      </Grid>
      <Grid item>
        <RiskScoresView
          title="MARA Risk Scores"
          rows={risksData.patientRiskHistory}
          columns={riskScoresColumns}
          getRowId={(row) => row.id}
          labelCell={labelRiskScore}
        />
      </Grid>
      <Grid item>
        <ClinicalConditionView
          title="Clinical Condition Risk Driver"
          rows={risksData.patientRiskClinicalClassification}
          width={800}
          legendPosition="right"
        />
      </Grid>
    </Grid>
  );
};

export default RiskTab;
