import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { getParStateDisplayValue } from 'util/helpers/provider.helpers';

const ParFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const displayValue = getParStateDisplayValue(props.value);
  return <Typography title={displayValue}>{displayValue}</Typography>;
};

export default ParFormatter;
