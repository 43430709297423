import { MenuItem as MuiItem, styled } from '@mui/material';
import { COLORS } from 'consts/styles';

const StyledMenuItem = styled(MuiItem)({
  padding: '8px 20px',
  display: 'block',
  width: '100%',
  '&.Mui-selected': {
    backgroundColor: COLORS.WHITE,
  },
});

export const MenuItem = StyledMenuItem;
