import { Table, TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { attachmentColumns } from '../columnSettings/attachmentColumns';
import { EditingCell } from 'components/gridFormatters';
import React from 'react';
import { AttachmentLabelCell } from './attachmentLabelCell';
import { AttachmentCategoryEditCell } from 'components/gridFormatters/AttachmentCategoryEditCell';

export const AttachmentEditCell = (props: TableEditRow.CellProps) => {
  const { column } = props;

  switch (column.name) {
    case attachmentColumns.name:
      return <EditingCell {...props} fullwidth={true} autoFocus />;
    case attachmentColumns.attachmentCategory:
      return <AttachmentCategoryEditCell {...props} />;
    case attachmentColumns.actions:
      return <Table.Cell {...props} />;
    default:
      return <AttachmentLabelCell {...props} />;
  }
};
