import React from 'react';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import IconButton from '@mui/material/IconButton';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IRefreshButtonProperties {
  onClick: () => void;
}

const RefreshButton = (props: IRefreshButtonProperties) => {
  return (
    <CustomTooltip title="Refresh page">
      <IconButton
        aria-label="refresh"
        onClick={() => {
          props.onClick();
        }}
        size="large"
      >
        <Icon icon={ICONS.Refresh} size="16" color={COLORS.GREY100} />
      </IconButton>
    </CustomTooltip>
  );
};

export default RefreshButton;
