import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCommitCareTeamChanges } from './careTeam.hooks';
import Loader from 'components/loader';
import CareTeamGrid from './careTeamGrid';
import { Box, Grid } from '@mui/material';
import {
  editingStateTeamMembersColumnsExtension,
  teamMembersColumns,
} from './teamMembers';
import { TeamMemberLabelCell } from './cells/TeamMemberLabelCell';
import { TeamMemberEditCell } from './cells/TeamMemberEditCell';
import { ProviderLabelCell } from './cells/ProviderLabelCell';
import {
  editingStateProvidersColumnsExtension,
  providersColumns,
} from './providers';
import ProviderEditCell from './cells/ProviderEditCell';
import { editingStateFamilyColumnsExtension, familyColumns } from './family';
import { FamilyEditCell } from './cells/FamilyEditCell';
import { FamilyLabelCell } from './cells/FamilyLabelCell';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  commitFamily,
  commitProviders,
  commitTeamMembers,
  selectProvider,
} from 'store/patientdetails/patientDetails.careTeam.slice';

import {
  getAvailableId,
  isFamilyValid,
  validateTeamMember,
  isProvidersValid,
  isTeamMembersValid,
} from './careTeam.helpers';
import ProviderDetails from './providerDetails/providerDetails';
import { IState } from 'store';
import { CareTeamGridTop } from './CareTeamGridTop';
import {
  PatientCareTeamFamilyViewModel,
  PatientCareTeamMemberViewModel,
  PatientCareTeamProviderViewModel,
} from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { setIsInEditMode } from 'store/patientdetails/patientDetailsSlice';

const ErrorBox = styled.div`
  padding: 0px 24px;
  margin-top: 10px;
  color: ${COLORS.RED100};
  font-weight: bold;
`;

interface ICareTeamTabProps {
  patientId: number;
  handleUpdatePatientTabValid: (valid: boolean) => void;
}

const CareTeamTab = ({
  patientId,
  handleUpdatePatientTabValid,
}: ICareTeamTabProps) => {
  const dispatch = useDispatch();
  const dirtyTabs = useSelector(
    (state: IState) => state.patientDetails.dirtyTabs
  );

  const {
    careTeamData,
    teamMembersCommitChanges,
    teamMembersCancelChanges,
    providersCommitChanges,
    providersCancelChanges,
    familyCommitChanges,
    familyCancelChanges,
  } = useCommitCareTeamChanges();
  const [editingTeamMemberRowIds, setEditingTeamMemberRowIds] = useState<
    number[]
  >([]);
  const [editingProviderRowIds, setEditingProviderRowIds] = useState<number[]>(
    []
  );
  const [editingFamilyRowIds, setEditingFamilyRowIds] = useState<number[]>([]);

  const [editEnabled, setEditEnabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const selectedProviderId = useSelector(
    (state: IState) => state.patientDetailsCareTeam.selectedProviderId
  );

  const currentMembers =
    careTeamData?.teamMembers.map((x: PatientCareTeamMemberViewModel) =>
      Number(x.member.id)
    ) ?? ([] as number[]);
  const freeUsers =
    careTeamData?.users?.filter((x) => !currentMembers.includes(x.id)) ?? [];

  const isInEditMode =
    editingProviderRowIds.length > 0 ||
    editingFamilyRowIds.length > 0 ||
    editingTeamMemberRowIds.length > 0;

  useEffect(() => {
    dispatch(setIsInEditMode({ isInEditMode: isInEditMode }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInEditMode]);

  useEffect(() => {
    if (
      handleUpdatePatientTabValid &&
      careTeamData &&
      dirtyTabs.includes('Care Team')
    ) {
      const result = validateTeamMember(
        careTeamData.teamMembers,
        careTeamData.owner['required']
      );
      const isEditing =
        editingTeamMemberRowIds.length > 0 ||
        editingProviderRowIds.length > 0 ||
        editingFamilyRowIds.length > 0;
      const isTabValid =
        isTeamMembersValid(careTeamData.teamMembers) &&
        isProvidersValid(careTeamData.providers) &&
        isFamilyValid(careTeamData.family) &&
        !result.hasError &&
        !isEditing;
      handleUpdatePatientTabValid(isTabValid);

      const message = result.hasError ? result.message : '';
      setErrorMessage(message);
    }
  }, [
    careTeamData,
    dirtyTabs,
    editingFamilyRowIds.length,
    editingProviderRowIds.length,
    editingTeamMemberRowIds.length,
    handleUpdatePatientTabValid,
  ]);

  const validRef = useRef({
    isTeamMembersValid: true,
    isProvidersValid: true,
    isFamilyValid: true,
  });

  const updatePatientTabValid = useCallback(() => {
    if (dirtyTabs.includes('Care Team')) {
      handleUpdatePatientTabValid(
        validRef.current.isTeamMembersValid &&
          validRef.current.isProvidersValid &&
          validRef.current.isFamilyValid
      );
    }
  }, [dirtyTabs, handleUpdatePatientTabValid]);

  const handleUpdateTeamMembersValid = useCallback(
    (valid: boolean) => {
      let isValid = valid;
      if (careTeamData) {
        const result = validateTeamMember(
          careTeamData.teamMembers,
          careTeamData.owner.required
        );
        if (result.hasError) {
          isValid = false;
          setErrorMessage(result.message);
        }
      }
      validRef.current.isTeamMembersValid = isValid;
      updatePatientTabValid();
    },
    [careTeamData, updatePatientTabValid]
  );

  const handleUpdateProvidersValid = useCallback(
    (valid: boolean) => {
      validRef.current.isProvidersValid = valid;
      updatePatientTabValid();
    },
    [updatePatientTabValid]
  );

  const handleUpdateFamilyValid = useCallback(
    (valid: boolean) => {
      validRef.current.isFamilyValid = valid;
      updatePatientTabValid();
    },
    [updatePatientTabValid]
  );

  if (!careTeamData) {
    return <Loader active />;
  }

  const handleAddTeamMember = () => {
    const newId = getAvailableId(careTeamData.teamMembers);
    dispatch(
      commitTeamMembers({
        added: [{ id: newId, member: {} } as PatientCareTeamMemberViewModel],
      })
    );
    setEditingTeamMemberRowIds([newId]);
    setEditEnabled(false);
  };

  const handleAddProvider = () => {
    const newId = getAvailableId(careTeamData.providers);
    dispatch(
      commitProviders({
        added: [
          { id: newId, provider: {} } as PatientCareTeamProviderViewModel,
        ],
      })
    );
    setEditingProviderRowIds([newId]);
    setEditEnabled(false);
  };

  const handleAddFamily = () => {
    const newId = getAvailableId(careTeamData.family);
    dispatch(
      commitFamily({
        added: [{ id: newId } as PatientCareTeamFamilyViewModel],
      })
    );
    setEditingFamilyRowIds([newId]);
    setEditEnabled(false);
  };

  const handleCloseProviderDetails = () => {
    dispatch(
      selectProvider({
        id: null,
      })
    );
  };

  return (
    <Grid container spacing={3} style={{ marginTop: 0 }}>
      {errorMessage ? <ErrorBox>{errorMessage}</ErrorBox> : null}
      <Grid
        item
        xs={!selectedProviderId ? 12 : 9}
        style={{
          borderRight: !selectedProviderId ? '0' : `1px solid ${COLORS.GREY25}`,
        }}
      >
        <Grid item>
          <Box mb="32px">
            <CareTeamGridTop
              title="Symphony Team"
              tooltip="Add new team member"
              buttonLabel="Add Member"
              disabled={isInEditMode || freeUsers.length <= 0}
              handleAdd={handleAddTeamMember}
            />
            <CareTeamGrid
              itemName="team member"
              rows={careTeamData.teamMembers}
              columns={teamMembersColumns()}
              commitChanges={teamMembersCommitChanges}
              cancelChanges={teamMembersCancelChanges}
              editCell={TeamMemberEditCell}
              labelCell={TeamMemberLabelCell}
              editEnabled={editEnabled}
              setEditEnabled={setEditEnabled}
              columnExtensions={editingStateTeamMembersColumnsExtension}
              editingRowIds={editingTeamMemberRowIds}
              setEditingRowIds={setEditingTeamMemberRowIds}
              onUpdatePatientTabValid={handleUpdateTeamMembersValid}
              validate={isTeamMembersValid}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box mb="32px">
            <CareTeamGridTop
              title="Providers"
              tooltip="Add new provider"
              buttonLabel="Add Provider"
              disabled={isInEditMode}
              handleAdd={handleAddProvider}
            />
            <CareTeamGrid
              itemName="provider"
              rows={careTeamData.providers}
              columns={providersColumns()}
              commitChanges={providersCommitChanges}
              cancelChanges={providersCancelChanges}
              editCell={ProviderEditCell}
              labelCell={ProviderLabelCell}
              editEnabled={editEnabled}
              setEditEnabled={setEditEnabled}
              columnExtensions={editingStateProvidersColumnsExtension}
              editingRowIds={editingProviderRowIds}
              setEditingRowIds={setEditingProviderRowIds}
              onUpdatePatientTabValid={handleUpdateProvidersValid}
              validate={isProvidersValid}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box mb="32px">
            <CareTeamGridTop
              title="Friends & Family"
              tooltip="Add new friends & family"
              buttonLabel="Add Friend/Family"
              disabled={isInEditMode}
              handleAdd={handleAddFamily}
            />
            <CareTeamGrid
              itemName="friends & family"
              rows={careTeamData.family}
              columns={familyColumns()}
              commitChanges={familyCommitChanges}
              cancelChanges={familyCancelChanges}
              editCell={FamilyEditCell}
              labelCell={FamilyLabelCell}
              editEnabled={editEnabled}
              setEditEnabled={setEditEnabled}
              columnExtensions={editingStateFamilyColumnsExtension}
              editingRowIds={editingFamilyRowIds}
              setEditingRowIds={setEditingFamilyRowIds}
              onUpdatePatientTabValid={handleUpdateFamilyValid}
              validate={isFamilyValid}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={3} hidden={!selectedProviderId} style={{ padding: 0 }}>
        <ProviderDetails
          patientId={patientId}
          providerId={selectedProviderId ?? 0}
          closeProviderDetails={() => handleCloseProviderDetails()}
        />
      </Grid>
    </Grid>
  );
};

export default CareTeamTab;
