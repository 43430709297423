import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CommitRemindersAction,
  IMedicationsTab,
  IOpenPatientDialogAction,
  IPatientDetailsState,
  IUpdateCareTeamMembersAction,
  IUpdateMedicationAction,
  IUpdateMedicationsRequest,
  UpdateEpisodeRemindersAction,
  UpdateMemberRemindersAction,
} from './types';
import { IPatientForm } from 'components/home/patientdetails/tabscontent/backgroundTab/types';
import {
  getChangedRows,
  mapBaseReminderToInputDto,
} from 'components/home/RemindersGrid/RemindersGrid.helpers';
import {
  BaseReminder,
  PatientCondition,
  PatientContactsViewModel,
  PatientEditRiskTabViewModel,
  PatientEditTagsTabViewModel,
  PatientInsuranceTabViewModel,
  PatientMedicationClaim,
  PatientRisk,
  ReminderDtoInput,
  UpdateReminderDtoInput,
} from 'graphql/graphqlTypes';
import { GetPatientInsuranceTabQuery } from 'graphql/hooks/getPatientInsuranceTab';
import {
  getOrAddUpdatedEpisodeReminder,
  getOrAddUpdatedMemberReminder,
  removeEpisodeReminder,
  removeMemberReminder,
  setDefaultRemindersState,
} from './patientDetails.helpers';

export const initialPatientDetailsState: IPatientDetailsState = {
  isOpened: false,
  activeTabName: null,
  activeSubTabName: '',
  patientId: 0,
  episodeId: 0,
  updatedReminders: {
    memberReminders: [],
    episodeReminders: [],
  },
  memberReminders: null,
  episodeTab: {},
  patientDetails: null,
  contacts: null,
  insurance: null,
  arePatientTagsUpdated: false,
  patientTags: null,
  claimsTab: null,
  conditions: null,
  riskScores: null,
  risksTab: null,
  medicationsTab: {
    allergiesCheck: false,
    medicationsCheck: false,
  } as IMedicationsTab,
  careTeamMembers: null,
  dirtyTabs: [],
  refreshTimelineGridData: false,
  isInEditMode: false,
  refreshPatientDetails: false,
  showSuccessMessage: false,
  clearPatientDetailsDialog: true,
};

export const addOrReplaceBy = <T>(
  rows: T[],
  predicate: (x: T) => boolean,
  newRow: T
): T[] => {
  const withoutUpdated = rows?.filter(predicate) ?? [];

  return [...withoutUpdated, newRow];
};

const patientDetailsSlice = createSlice({
  name: 'PatientDetailsState',
  initialState: initialPatientDetailsState,
  reducers: {
    updatePatientEpisodeRemindersRows: (
      state,
      action: PayloadAction<UpdateEpisodeRemindersAction>
    ) => {
      state.episodeTab[action.payload.episodeId] = {
        reminders: action.payload.reminders,
      };
    },
    removeJustAddedReminder: (state) => {
      const itemsToRemove: number[] = [];
      const notJustAdded = (x: BaseReminder) => {
        const shouldLeave = 'isJustAdded' in x ? !x.isJustAdded : true;
        !shouldLeave && itemsToRemove.push(x.id);
        return shouldLeave;
      };

      const removeJustAddedRows = (x: UpdateReminderDtoInput) => ({
        ...x,
        reminders: x.reminders.filter((y) =>
          itemsToRemove.includes(y!.reminderId!)
        ),
      });

      Object.keys(state.episodeTab).forEach((episodeId) => {
        state.episodeTab[episodeId].reminders =
          state.episodeTab[episodeId].reminders?.filter(notJustAdded);
      });
      const memberReminders = state.memberReminders?.filter(notJustAdded);
      state.memberReminders = memberReminders ?? null;

      state.updatedReminders.episodeReminders =
        state.updatedReminders.episodeReminders.map(removeJustAddedRows);
      state.updatedReminders.memberReminders =
        state.updatedReminders.memberReminders.map(removeJustAddedRows);
    },
    commitReminders: (state, action: PayloadAction<CommitRemindersAction>) => {
      const { episodeId, reminders } = action.payload;
      setDefaultRemindersState(state, episodeId);
      if (reminders.deleted && reminders.deleted[0]) {
        const deletedId = reminders.deleted[0] as number;
        if (episodeId) {
          removeEpisodeReminder(state, episodeId, deletedId);
        } else {
          removeMemberReminder(state, deletedId);
        }

        return;
      }

      // Update episode tab
      let updatedReminder: ReminderDtoInput | null = null;
      if (episodeId) {
        const episodeReminders = state.episodeTab[episodeId].reminders;
        const {
          changedRows: episodeChangedRows,
          updatedRows: episodeUpdatedRows,
        } = getChangedRows(reminders, episodeReminders);

        if (episodeUpdatedRows?.length) {
          state.episodeTab[episodeId].reminders = episodeChangedRows;
          updatedReminder = mapBaseReminderToInputDto(episodeUpdatedRows[0]);
        }
      }

      // Update reminder tab
      const memberReminders = state.memberReminders;
      const { changedRows: memberChangedRows, updatedRows: memberUpdatedRows } =
        getChangedRows(reminders, memberReminders);

      if (memberUpdatedRows?.length) {
        state.memberReminders = memberChangedRows;

        // Case where we modify a reminder in both episode and reminder tab
        // should not happen. But if it does, we will use reminder from episode tab
        updatedReminder =
          updatedReminder ?? mapBaseReminderToInputDto(memberUpdatedRows[0]);
      }

      if (updatedReminder) {
        const dto = updatedReminder;
        const changedReminders = episodeId
          ? getOrAddUpdatedEpisodeReminder(state, episodeId)
          : getOrAddUpdatedMemberReminder(state);
        changedReminders.reminders = addOrReplaceBy(
          changedReminders.reminders,
          (x) => x!.reminderId !== dto.reminderId,
          dto
        );
      }
    },
    setIsInEditMode: (
      state,
      action: PayloadAction<{ isInEditMode: boolean }>
    ) => {
      state.isInEditMode = action.payload.isInEditMode;
    },
    setRefreshPatientDetails: (
      state,
      action: PayloadAction<{ refreshPatientDetails: boolean }>
    ) => {
      state.refreshPatientDetails = action.payload.refreshPatientDetails;
    },
    updatePatientRemindersRows: (
      state,
      action: PayloadAction<UpdateMemberRemindersAction>
    ) => {
      state.memberReminders = action.payload.reminders;
    },
    openPatientDetailsModal(
      state,
      action: PayloadAction<IOpenPatientDialogAction>
    ) {
      state.isOpened = true;
      state.patientId = action.payload.patientId || 0;
      state.episodeId = action.payload.episodeId || 0;
      state.editingChecklistId = action.payload.editingChecklistId;
      state.activeTabName = action.payload.activeTabName || '';
      state.activeSubTabName = action.payload.activeSubTabName || '';
    },
    setActiveTab: (
      state,
      action: PayloadAction<{ tab: string; subTab?: string }>
    ) => {
      state.activeTabName = action.payload.tab;
      state.activeSubTabName = action.payload.subTab || '';
    },
    closePatientDetailsModal() {
      return initialPatientDetailsState;
    },
    updatePatientContacts(
      state,
      action: PayloadAction<PatientContactsViewModel>
    ) {
      state.contacts = action.payload;
    },
    updatePatientRiskScores(state, action: PayloadAction<PatientRisk>) {
      state.riskScores = action.payload;
    },
    updatePatientRisks(
      state,
      action: PayloadAction<PatientEditRiskTabViewModel>
    ) {
      state.risksTab = action.payload;
    },
    updateContactsPrimary(
      state,
      action: PayloadAction<{
        id: string;
        value: boolean;
        type: 'addresses' | 'phones' | 'emails';
      }>
    ) {
      if (!state.contacts) {
        return;
      }

      const payload = action.payload;
      const contacts = state.contacts[payload.type];
      contacts.forEach((item) => {
        if (item.type === payload.id) {
          item.isPrimary = payload.value;
        } else if (payload.value) {
          item.isPrimary = false;
        }
      });
      if (!state?.dirtyTabs?.includes('Background')) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        state.dirtyTabs = [...state?.dirtyTabs, 'Background'];
      }
    },
    updateOkToContactPhone: (
      state,
      action: PayloadAction<{
        id: string;
        value: boolean;
      }>
    ) => {
      if (!state.contacts) {
        return;
      }

      const payload = action.payload;
      const found = state.contacts.phones.find((x) => x.type === payload.id);
      if (found) {
        found.isOk = payload.value;
      }
    },
    updatePatientTags(
      state,
      action: PayloadAction<{
        tags: Array<PatientEditTagsTabViewModel> | null;
        areUpdated: boolean;
      }>
    ) {
      state.arePatientTagsUpdated = action.payload.areUpdated;
      state.patientTags = action.payload.tags;
    },
    updatePatientConditions(
      state,
      action: PayloadAction<Array<PatientCondition> | null>
    ) {
      state.conditions = action.payload;
    },
    updatePatientConditionStatus(
      state,
      action: PayloadAction<{
        id: number;
        value: boolean;
      }>
    ) {
      if (!state.conditions) {
        return;
      }

      const payload = action.payload;
      const found = state.conditions.find((x) => x.id === payload.id);
      if (found) {
        found.status = payload.value;
      }
    },
    patientInsuranceLoaded(
      state,
      action: PayloadAction<GetPatientInsuranceTabQuery>
    ) {
      state.insurance = action.payload
        .getPatientInsuranceTab as PatientInsuranceTabViewModel;
    },
    updateMemberDetails(state, action: PayloadAction<IPatientForm | null>) {
      state.patientDetails = action.payload;
    },
    setActiveEpisode(state, action: PayloadAction<number>) {
      state.episodeId = action.payload;
    },
    updateMedicationsData(
      state,
      action: PayloadAction<IUpdateMedicationAction>
    ) {
      if (action.payload.isUpdate && !state?.dirtyTabs?.includes('Meds')) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        state.dirtyTabs = [...state?.dirtyTabs, 'Meds'];
      }
      state.medicationsTab.medicationsCheck = action.payload.medicationsCheck;
      state.medicationsTab.allergyNotes = action.payload.allergyNotes;
      state.medicationsTab.allergiesCheck = action.payload.allergiesCheck;
      state.medicationsTab.lastReviewed = action.payload.lastReviewed;
    },
    updateMedicationClaims(
      state,
      action: PayloadAction<PatientMedicationClaim[] | []>
    ) {
      state.medicationsTab.medicationsClaims = action.payload;
    },
    updateMedicationsList(
      state,
      action: PayloadAction<IUpdateMedicationsRequest>
    ) {
      const { isUpdate, medicationsAttributeDef, isDelete, medications } =
        action.payload;
      if ((isUpdate || isDelete) && !state?.dirtyTabs?.includes('Meds')) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        state.dirtyTabs = [...state?.dirtyTabs, 'Meds'];
      }
      if (isDelete) {
        const toDelete = state.medicationsTab.medicationsList!.find(
          (x) => x.id === medications[0].id
        );
        if (toDelete) {
          toDelete.deleted = true;
        }
        return;
      }
      if (medicationsAttributeDef) {
        state.medicationsTab.medicationsAttributeDef = medicationsAttributeDef;
      }
      medications.forEach((updated) => {
        state.medicationsTab.medicationsList = addOrReplaceBy(
          state.medicationsTab.medicationsList!,
          (item) => updated.id !== item.id,
          { ...updated, changed: true, deleted: false }
        );
      });
    },
    updateSelectedMedicationItem(
      state,
      action: PayloadAction<{
        id?: number[];
        selectedActionType?: 'form' | 'details' | '';
        isNew?: boolean;
      }>
    ) {
      if (action.payload.id !== undefined) {
        state.medicationsTab.selectedMedicationItems = action.payload.id;
      }
      if (action.payload.selectedActionType !== undefined) {
        state.medicationsTab.selectedActionType =
          action.payload.selectedActionType;
      }
      state.medicationsTab.isNew = action.payload.isNew;
    },
    updateCareTeamMembers(
      state,
      action: PayloadAction<IUpdateCareTeamMembersAction>
    ) {
      state.careTeamMembers = action.payload.teamMembers;
    },
    updateDirtyTabs(state, action: PayloadAction<string>) {
      if (action.payload === '') {
        state.dirtyTabs = [];
        return;
      }
      if (!state?.dirtyTabs?.includes(action.payload)) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        state.dirtyTabs = [...state?.dirtyTabs, action.payload];
      }
    },
    updateClearPatientDetailsDialog(state, action: PayloadAction<boolean>) {
      state.clearPatientDetailsDialog = action.payload;
    },
    clearAfterSave(state): IPatientDetailsState {
      return {
        ...initialPatientDetailsState,
        isOpened: true,
        activeTabName: state.activeTabName,
        activeSubTabName: state.activeSubTabName,
        patientId: state.patientId,
        episodeId: state.episodeId,
        editingChecklistId: state.editingChecklistId,
      };
    },
    clearMedicationsList(state) {
      state.medicationsTab.medicationsList = undefined;
    },
    keepSelectedPatientDetailsTabInfo(state) {
      return {
        ...initialPatientDetailsState,
        patientId: state.patientId,
        episodeId: state.episodeId,
      };
    },
    refreshTimelineGridData(state, action: PayloadAction<boolean>) {
      state.refreshTimelineGridData = action.payload;
    },
    updateShowSuccessMessage(state, action: PayloadAction<boolean>) {
      state.showSuccessMessage = action.payload;
    },
  },
});

export const {
  updatePatientEpisodeRemindersRows,
  openPatientDetailsModal,
  closePatientDetailsModal,
  patientInsuranceLoaded,
  updatePatientContacts,
  updatePatientRiskScores,
  updatePatientRisks,
  updateContactsPrimary,
  updatePatientTags,
  updatePatientConditions,
  updatePatientConditionStatus,
  updateOkToContactPhone,
  updateMemberDetails,
  updatePatientRemindersRows,
  commitReminders,
  removeJustAddedReminder,
  setActiveEpisode,
  setActiveTab,
  updateMedicationsData,
  updateSelectedMedicationItem,
  updateMedicationsList,
  updateMedicationClaims,
  updateCareTeamMembers,
  updateDirtyTabs,
  updateClearPatientDetailsDialog,
  clearAfterSave,
  clearMedicationsList,
  keepSelectedPatientDetailsTabInfo,
  refreshTimelineGridData,
  setIsInEditMode,
  setRefreshPatientDetails,
  updateShowSuccessMessage,
} = patientDetailsSlice.actions;

export default patientDetailsSlice.reducer;
