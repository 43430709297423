import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Chip, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControl from '../../formControl';
import ErrorComp from 'components/error';
import MenuItem from '@mui/material/MenuItem';
import ServerAutocomplete from 'components/autocomplete/ServerAutocomplete';
import { ILookupValue } from 'backend/types/lookupValue';
import { suggestEntities } from 'store/patientdetails/middlewares';
import CancelIcon from '@mui/icons-material/Cancel';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import store, { IState } from 'store';
import { COLORS } from 'consts/styles';
import { IMainModel } from 'store/home/types';
import { FieldError, useForm } from 'react-hook-form';
import { IBackgroundTabProps, IPatientForm } from '../../types';
import {
  updateDirtyTabs,
  updateMemberDetails,
} from 'store/patientdetails/patientDetailsSlice';
import { usePrevious } from 'hooks';

import Radio from '@mui/material/Radio';
import moment from 'moment';
import useDeepCompareEffect from 'use-deep-compare-effect';
import RiskInfo from './riskInfo';
import { Privilege } from 'store/roles/types';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { StatusBadge } from 'components/badge';
import { api, GetPatientByIdQuery } from 'graphql/hooks/getPatientById';
import { useGetProviderDetailsQuery } from 'graphql/hooks/getProviderDetails';
import styled from 'styled-components';
import theme from 'theme';
import DateComponent from 'components/DateComponent';
import { isValid } from 'date-fns';

const StyledTextField = styled(TextField)({
  fontSize: '14px',
  '& .Mui-disabled': {
    color: COLORS.GREY100,
    backgroundColor: COLORS.GREY4,
    '& .MuiSelect-icon': {
      color: COLORS.GREY60,
    },
  },
});

const StyledFormWrap = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const SpacedColumn = styled(Grid)<{ $showMargin: boolean }>(
  ({ $showMargin }) => ({
    marginRight: $showMargin ? '60px' : 'inherit',
    [theme.breakpoints.down('lg')]: {
      marginRight: $showMargin ? '20px' : 'inherit',
    },
  })
);

const StyledChip = styled(Chip)({
  marginRight: 2,
  marginBottom: 4,
  height: '20px',
});

const StyledSelect = styled(Select)({
  paper: {
    borderRadius: '4px',
    border: '1px solid #CECECE',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
  '.MuiSelect-select': {
    overflow: 'initial',
    whiteSpace: 'initial',
  },
});

const skipGetProviderDetailsQuery = (
  patientId: number,
  primaryCareProvider?: { id?: number }
) => (primaryCareProvider?.id ?? 0) === 0 || patientId === 0;

const getFormattedPhone = (data: GetPatientByIdQuery | undefined): string =>
  (data?.getPatientById?.preferredPhoneNumber
    ? data?.getPatientById?.preferredPhone + ' '
    : '') +
  (data?.getPatientById?.preferredPhoneNumber
    ? `(${data?.getPatientById?.preferredPhoneNumber})`
    : '');

const RequiredFieldError = (props: {
  fieldName: string;
  fieldError?: FieldError;
}) => {
  return (
    <>
      {props.fieldError && (
        <ErrorComp>
          <span>{props.fieldName} is required</span>
        </ErrorComp>
      )}
    </>
  );
};

interface IPatientDetailsTabProps extends IBackgroundTabProps {
  disabled: boolean;
  patientDetails: IPatientForm | null;
}

const PatientDetailsTab = (props: IPatientDetailsTabProps) => {
  const canShowRiskInfo = (): boolean => {
    return hasUserPrivilege(Privilege.Risk);
  };
  const dispatch = useDispatch();
  const {
    isExternalDataReadOnly,
    handleUpdatePatientTabValid,
    disabled,
    patientDetails,
  } = props;

  const {
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<IPatientForm>({
    values: patientDetails!,
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const formValues = watch();

  const dispatchUpdateMember = () => {
    dispatch(updateMemberDetails(formValues));
  };

  const refreshPatientDetails = useSelector(
    (state: IState) => state.patientDetails.refreshPatientDetails
  );

  useEffect(() => {
    if (refreshPatientDetails) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPatientDetails]);

  const showRisk = canShowRiskInfo();

  const mainModel = useSelector((state: IState) =>
    state.home.mainModel ? state.home.mainModel : ({} as IMainModel)
  );

  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const { data } = api.endpoints.getPatientById.select({
    patientId,
  })(store.getState());

  const [gender, setGender] = useState<number>(patientDetails?.Gender ?? 0);
  const [populations, setPopulations] = useState<Array<number>>(
    patientDetails?.Populations?.map((item) => Number(item)) ?? []
  );
  const [dobDate, handleDobDateChange] = useState<Date | null>(
    patientDetails?.DOB ? moment(patientDetails?.DOB).toDate() : null
  );
  const [dodDate, handleDodDateChange] = useState<Date | null>(
    patientDetails?.DOD ? moment(patientDetails?.DOD).toDate() : null
  );
  const MenuProps = {
    style: {
      left: '0',
      marginTop: '2px',
      maxHeight: 350,
      width: 370,
    },
  };

  const { data: providerData } = useGetProviderDetailsQuery(
    {
      providerId: formValues.PrimaryCareProvider?.id ?? 0,
      patientId: patientId,
    },
    {
      skip: skipGetProviderDetailsQuery(
        patientId,
        formValues.PrimaryCareProvider
      ),
      refetchOnMountOrArgChange: true,
    }
  );

  const setIsModified = (modified: boolean) => {
    if (modified) {
      dispatch(updateDirtyTabs('Background'));
    }
  };

  const handlePcpAutoCompleteChange = (
    _event: ChangeEvent<unknown>,
    newValue: ILookupValue | null
  ) => {
    formValues.PrimaryCareProvider = newValue ? newValue : ({} as ILookupValue);
    dispatchUpdateMember();
    setIsModified(true);
  };

  const handleDateChange = (date: Date | null) => {
    handleDobDateChange(date);
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
    handleUpdatePatientTabValid &&
      handleUpdatePatientTabValid(moment(date).isValid());
    formValues.DOB = formattedDate;
    dispatchUpdateMember();
    setIsModified(true);
  };

  const handleDODChange = (date: Date | null) => {
    if (!isValid(date)) {
      handleDodDateChange(null);
      handleUpdatePatientTabValid(true);
      formValues.DOD = undefined;
      dispatchUpdateMember();
      setIsModified(true);
      return;
    }
    handleDodDateChange(date);
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
    handleUpdatePatientTabValid &&
      handleUpdatePatientTabValid(moment(date).isValid());
    formValues.DOD = formattedDate;
    dispatchUpdateMember();
    setIsModified(true);
  };

  const handlePopulationsChange = (value: number) => {
    if (!isExternalDataReadOnlyOrDisabled) {
      const updatedPopulations = populations.includes(value)
        ? populations?.filter((item) => item !== value)
        : [...populations, value];
      setPopulations(updatedPopulations);
      formValues.Populations = updatedPopulations.map((item) => Number(item));
      dispatchUpdateMember();
      setIsModified(true);
    }
  };

  const handleGenderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = Number(event.target.value);
    formValues.Gender = selectedValue;
    dispatchUpdateMember();
    setGender(selectedValue);
    setIsModified(selectedValue !== patientDetails?.Gender);
  };

  useEffect(() => {
    if (patientDetails) {
      const dob = patientDetails?.DOB
        ? moment(patientDetails?.DOB).toDate()
        : null;
      handleDobDateChange(dob);
      const dod = patientDetails?.DOD
        ? moment(patientDetails?.DOD).toDate()
        : null;
      handleDodDateChange(dod);
      setGender(patientDetails?.Gender ?? 0);
      setPopulations(
        patientDetails?.Populations?.map((item) => Number(item)) ?? []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const formattedAddress = [
    data?.getPatientById?.address1,
    data?.getPatientById?.address2,
    data?.getPatientById?.city,
    data?.getPatientById?.state,
    data?.getPatientById?.zipCode,
  ]
    .filter(Boolean)
    .join(' ');

  const formattedPhone = getFormattedPhone(data);

  const previousPatientData = usePrevious(patientDetails);

  useDeepCompareEffect(() => {
    if (previousPatientData !== formValues) {
      dispatch(updateMemberDetails(formValues));
    }
  }, [formValues]);

  const isExternalDataReadOnlyOrDisabled = isExternalDataReadOnly || disabled;
  const registerDob = register('DOB', {
    required: true,
  });
  const registerDod = register('DOD', {
    required: false,
  });

  const registerGender = register('Gender', {
    required: true,
  });

  const getListItemPrefix = (item: ILookupValue) => {
    const isDisabledItem = !(mainModel.enabledPopulations ?? []).find(
      (x) => String(x.id) === String(item.id)
    );
    if (item.isDeleted) {
      return '(Deleted) ';
    }
    return isDisabledItem ? '(Disabled) ' : '';
  };

  const getListItemText = (item: ILookupValue) => {
    return `${getListItemPrefix(item)}${item.name}`;
  };

  return (
    <>
      <StyledFormWrap>
        <form>
          <Grid container spacing={5}>
            <SpacedColumn $showMargin={true} item sm={4}>
              <Box>
                <FormControl title="First Name" isRequired={true}>
                  <StyledTextField
                    id="firstName"
                    type="text"
                    {...register('FirstName', { required: true })}
                    required
                    size="small"
                    data-testid="member-details-first-name"
                    disabled={isExternalDataReadOnlyOrDisabled}
                    onBlur={() =>
                      setIsModified(
                        formValues.FirstName !== data?.getPatientById?.firstName
                      )
                    }
                    fullWidth={true}
                    variant="outlined"
                  />
                  <RequiredFieldError
                    fieldError={errors.FirstName}
                    fieldName="First Name"
                  />
                </FormControl>
                <FormControl title="Middle Name" isRequired={false}>
                  <StyledTextField
                    id="middleName"
                    type="text"
                    disabled={isExternalDataReadOnlyOrDisabled}
                    {...register('MiddleName')}
                    size="small"
                    onBlur={() =>
                      setIsModified(
                        formValues.MiddleName !==
                          data?.getPatientById?.middleName
                      )
                    }
                    fullWidth={true}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl title="Last Name" isRequired={true}>
                  <StyledTextField
                    id="lastName"
                    type="text"
                    disabled={isExternalDataReadOnlyOrDisabled}
                    {...register('LastName', { required: true })}
                    size="small"
                    required
                    onBlur={() =>
                      setIsModified(
                        formValues.LastName !== data?.getPatientById?.lastName
                      )
                    }
                    fullWidth={true}
                    variant="outlined"
                  />
                  {errors.LastName && (
                    <ErrorComp>
                      <span>Last Name is required</span>
                    </ErrorComp>
                  )}
                  <RequiredFieldError
                    fieldError={errors.LastName}
                    fieldName="Last Name"
                  />
                </FormControl>
                <FormControl title="Date of Birth" isRequired={true}>
                  <DateComponent
                    value={dobDate}
                    onChange={(date: Date | null | unknown) =>
                      handleDateChange(date as Date)
                    }
                    readOnly={isExternalDataReadOnlyOrDisabled}
                    id="dob"
                    name={registerDob.name}
                    testId="member-details-dob"
                  />
                  <RequiredFieldError fieldError={errors.DOB} fieldName="DOB" />
                </FormControl>
                <FormControl title="Date of Death" isRequired={false}>
                  <DateComponent
                    value={dodDate}
                    onChange={(date: Date | null | unknown) =>
                      handleDODChange(date as Date)
                    }
                    readOnly={isExternalDataReadOnlyOrDisabled}
                    id="dod"
                    name={registerDod.name}
                    testId="member-details-dod"
                  />
                  <RequiredFieldError fieldError={errors.DOD} fieldName="DOD" />
                </FormControl>
                <FormControl title="Gender" isRequired={true}>
                  <RadioGroup
                    aria-label="gender"
                    name={registerGender.name}
                    value={gender}
                    onChange={handleGenderChange}
                    row
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      disabled={isExternalDataReadOnlyOrDisabled}
                      value={
                        mainModel.lookups
                          ?.find((l) => l.name === 'Gender')
                          ?.values?.find((x) => x.name === 'male')?.id
                      }
                      checked={
                        mainModel.lookups
                          ?.find((l) => l.name === 'Gender')
                          ?.values?.find((x) => x.name === 'male')?.id ===
                        gender
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      value={
                        mainModel.lookups
                          ?.find((l) => l.name === 'Gender')
                          ?.values?.find((x) => x.name === 'female')?.id
                      }
                      disabled={isExternalDataReadOnlyOrDisabled}
                      checked={
                        mainModel.lookups
                          ?.find((l) => l.name === 'Gender')
                          ?.values?.find((x) => x.name === 'female')?.id ===
                        gender
                      }
                      control={<Radio color="primary" />}
                      label="Female"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  title="Primary Address"
                  isAlignStart
                  isRequired={false}
                >
                  <StyledTextField
                    id="address"
                    type="text"
                    name="Address"
                    disabled
                    size="small"
                    multiline
                    rows={3}
                    value={formattedAddress}
                    fullWidth={true}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl title="Primary Phone" isRequired={false}>
                  <StyledTextField
                    id="phone"
                    type="text"
                    name="phone"
                    disabled
                    size="small"
                    value={formattedPhone}
                    fullWidth={true}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl title="PCP" isRequired={false}>
                  <ServerAutocomplete
                    id="pcp"
                    initValue={
                      formValues.PrimaryCareProvider?.id
                        ? {
                            id: Number(formValues?.PrimaryCareProvider?.id),
                            name: formValues?.PrimaryCareProvider?.name,
                          }
                        : null
                    }
                    disabled={isExternalDataReadOnlyOrDisabled}
                    suggestItems={(
                      request: string,
                      callback: (arg: ILookupValue[]) => void
                    ) => {
                      if (request && request.length >= 3) {
                        dispatch(
                          suggestEntities('Provider', request, callback)
                        );
                      }
                    }}
                    {...register('PrimaryCareProvider')}
                    onChange={(
                      event: ChangeEvent<unknown>,
                      newValue: ILookupValue | null
                    ) => handlePcpAutoCompleteChange(event, newValue)}
                  />
                </FormControl>
                <FormControl title="DPG" isRequired={false}>
                  <StyledTextField
                    id="dpg"
                    type="text"
                    name="dpg"
                    disabled
                    size="small"
                    value={providerData?.getProviderDetails?.dPG?.name}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl title="Seamless" isRequired={false}>
                  {providerData?.getProviderDetails?.seamless && (
                    <Typography>
                      {providerData?.getProviderDetails?.seamless === true
                        ? 'Y'
                        : 'N'}
                    </Typography>
                  )}
                </FormControl>
              </Box>
            </SpacedColumn>
            <SpacedColumn $showMargin={showRisk ?? false} item sm={4}>
              <FormControl title="Status" isRequired={false}>
                <StatusBadge active={data?.getPatientById?.isActive ?? false} />
              </FormControl>

              <FormControl title="MRN" isRequired={true}>
                <StyledTextField
                  id="mrn"
                  type="text"
                  {...register('MRN', { required: true })}
                  size="small"
                  required
                  disabled={isExternalDataReadOnlyOrDisabled}
                  onBlur={() =>
                    setIsModified(formValues.MRN !== data?.getPatientById?.mRN)
                  }
                  fullWidth={true}
                  variant="outlined"
                />
                <RequiredFieldError
                  fieldError={errors.MRN}
                  fieldName="Patient ID"
                />
              </FormControl>
              <FormControl title="Medicare" isRequired={false}>
                <StyledTextField
                  id="mrn2"
                  type="text"
                  {...register('MRN2')}
                  size="small"
                  onKeyDown={() =>
                    setIsModified(
                      formValues.MRN2 !== data?.getPatientById?.mRN2
                    )
                  }
                  disabled={isExternalDataReadOnlyOrDisabled}
                  fullWidth={true}
                  variant="outlined"
                />
              </FormControl>
              <FormControl title="MRN3" isRequired={false}>
                <StyledTextField
                  id="mrn3"
                  type="text"
                  {...register('MRN3')}
                  size="small"
                  onBlur={() =>
                    setIsModified(
                      formValues.MRN3 !== data?.getPatientById?.mRN3
                    )
                  }
                  disabled={isExternalDataReadOnlyOrDisabled}
                  fullWidth={true}
                  variant="outlined"
                />
              </FormControl>
              <FormControl title="MRN4" isRequired={false}>
                <StyledTextField
                  id="mrn4"
                  disabled={isExternalDataReadOnlyOrDisabled}
                  type="text"
                  {...register('MRN4')}
                  size="small"
                  onBlur={() =>
                    setIsModified(
                      formValues.MRN4 !== data?.getPatientById?.mRN4
                    )
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              </FormControl>
              <FormControl title="MRN5" isRequired={false}>
                <StyledTextField
                  {...register('MRN5')}
                  id="mrn5"
                  type="text"
                  disabled={isExternalDataReadOnlyOrDisabled}
                  size="small"
                  onBlur={() =>
                    setIsModified(
                      formValues.MRN5 !== data?.getPatientById?.mRN5
                    )
                  }
                  fullWidth={true}
                  variant="outlined"
                />
              </FormControl>
              <FormControl title="Owner" isRequired={false}>
                <StyledTextField
                  id="owner"
                  disabled
                  size="medium"
                  value={data?.getPatientById?.owner?.name ?? ''}
                  fullWidth={true}
                  variant="outlined"
                />
              </FormControl>
              <FormControl title="Populations" isRequired={false}>
                <StyledSelect
                  variant="outlined"
                  id="populations"
                  disabled={isExternalDataReadOnlyOrDisabled}
                  multiple
                  {...register('Populations')}
                  autoWidth={true}
                  style={{ width: '100%' }}
                  renderValue={() => {
                    const renderValue = mainModel.lookups
                      ?.find((l) => l.name === 'Populations')
                      ?.values?.filter((x) => populations?.indexOf(x.id) > -1);
                    return renderValue?.map((item) => (
                      <StyledChip
                        key={item.id}
                        label={item.name}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onDelete={() => {
                          handlePopulationsChange(item.id);
                        }}
                        deleteIcon={
                          <CancelIcon
                            style={{
                              width: 14,
                              height: 14,
                              color: COLORS.GREY100,
                            }}
                          />
                        }
                      />
                    ));
                  }}
                  value={populations}
                  MenuProps={{
                    ...MenuProps,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  {mainModel.lookups
                    ?.find((l) => l.name === 'Populations')
                    ?.values?.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={String(item.id)}
                        onClick={() => handlePopulationsChange(item.id)}
                        {...(item.isDeleted ||
                        (mainModel.enabledPopulations &&
                          !mainModel.enabledPopulations.find(
                            (x) => String(x.id) === String(item.id)
                          ))
                          ? { disabled: true }
                          : '')}
                      >
                        <ListItemText style={{ marginLeft: '9px' }}>
                          {getListItemText(item)}
                        </ListItemText>
                      </MenuItem>
                    ))}
                </StyledSelect>
              </FormControl>
              <FormControl title="Guardian" isRequired={false}>
                <StyledTextField
                  {...register('Guardian')}
                  id="guardian"
                  type="text"
                  size="small"
                  onBlur={() =>
                    setIsModified(
                      formValues.Guardian !== data?.getPatientById?.guardian
                    )
                  }
                  disabled={isExternalDataReadOnlyOrDisabled}
                  fullWidth={true}
                  variant="outlined"
                />
              </FormControl>
              <FormControl
                title="Additional Notes"
                isRequired={false}
                isAlignStart
              >
                <StyledTextField
                  id="note"
                  type="text"
                  {...register('Note')}
                  multiline
                  size="small"
                  onBlur={() =>
                    setIsModified(
                      formValues.Note !==
                        data?.getPatientById?.entityAttributes?.find(
                          (item) => item?.name === 'Note'
                        )?.value ?? ''
                    )
                  }
                  disabled={isExternalDataReadOnlyOrDisabled}
                  rows={3}
                  fullWidth={true}
                  variant="outlined"
                />
              </FormControl>
            </SpacedColumn>
            {showRisk ? (
              <RiskInfo patientId={patientId} showRisk={showRisk} />
            ) : null}
          </Grid>
        </form>
      </StyledFormWrap>
    </>
  );
};

export default PatientDetailsTab;
