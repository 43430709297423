import React from 'react';
import { ILabelCellProps, StarSwitchCell } from 'components/gridFormatters';
import { useDispatch, useSelector } from 'react-redux';
import { updateCaregiver } from 'store/patientdetails/patientDetails.careTeam.slice';
import { updateDirtyTabs } from 'store/patientdetails/patientDetailsSlice';
import { IState } from 'store';

export const CaregiverCell = (props: ILabelCellProps) => {
  const { row } = props;
  const dispatch = useDispatch();
  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );
  const changeValue = (value: boolean) => {
    dispatch(
      updateCaregiver({
        id: row.id,
        value: value,
      })
    );

    if (!isInEditMode) {
      dispatch(updateDirtyTabs('Care Team'));
    }
  };
  return <StarSwitchCell {...props} changeValue={changeValue} />;
};
