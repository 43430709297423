import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from 'components/modal/DialogContent';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { COLORS } from 'consts/styles';

interface DialogProps {
  open: boolean;
  onClose: (changed: boolean) => void;
  children?: JSX.Element;
}

const HeaderDialog = (props: DialogProps) => {
  const { open, onClose } = props;

  return (
    <Dialog
      onClose={() => onClose(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="md"
    >
      <DialogContent dividers style={{ width: '600px', minHeight: '800px' }}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            color="primary"
            component="span"
            onClick={() => onClose(false)}
            size="large"
          >
            <Close style={{ color: COLORS.BLACK }} />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="center">
          {props.children}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default HeaderDialog;
