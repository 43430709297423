import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetRecentSearchesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetRecentSearchesQuery = {
  __typename?: 'EntitiesQuery';
  getRecentSearches: Array<string>;
};

export const GetRecentSearchesDocument = `
    query getRecentSearches {
  getRecentSearches
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRecentSearches: build.query<
      GetRecentSearchesQuery,
      GetRecentSearchesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetRecentSearchesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRecentSearchesQuery, useLazyGetRecentSearchesQuery } =
  injectedRtkApi;
