import React, { useEffect } from 'react';
import Confirmation from 'components/modal/Confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import { setShowLeavingChecklistConfirmation } from 'store/ui/modals/checklist';
import { useDiscardChecklistMutation } from 'graphql/hooks/discardChecklist';
import { useFinishChecklistLaterMutation } from 'graphql/hooks/finishChecklistLater';
import { ILogoutUser } from 'store/user/types';
import { logoutUser } from 'store/user/middlewares';
import { useNavigate } from 'react-router';
import Loader from 'components/loader';
import { useUnlockMozartActionMutation } from 'graphql/hooks/unlockMozartAction';
import { MozartActionType, MozartProgressState } from 'graphql/graphqlTypes';
import { useDiscardMozartWorkflowMutation } from 'graphql/hooks/discardMozartWorkflow';
import { clearFailedRequests } from 'store/failedRequests/failedRequestsSlice';

const ChecklistExitModal = ({
  checklistStorage,
}: {
  checklistStorage: ChecklistStorageType;
}) => {
  const checklistExitModel = useSelector(
    (state: IState) => state.ui.modals.checklist.checklistExitModal
  );
  const checklistId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[checklistStorage].checklist?.id
  );
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );
  const mozartInstanceId = useSelector(
    (state: IState) => state.mozart.mozartInstanceId
  );
  const mozartMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );
  const workflowInstanceId = mozartMessages.find(
    (item) => item.workflowInstanceId
  )?.workflowInstanceId;
  const actionInstanceId = mozartMessages.find(
    (item) =>
      item.actionType === MozartActionType.UserInput &&
      item.state === MozartProgressState.Paused
  )?.actionInstanceId;

  const [
    discardChecklist,
    { isLoading: isDiscardingChecklist, isSuccess: discardChecklistSuccess },
  ] = useDiscardChecklistMutation();

  const [
    discardMozartChecklist,
    {
      isLoading: isDiscardingMozartChecklist,
      isSuccess: discardMozartChecklistSuccess,
    },
  ] = useDiscardMozartWorkflowMutation();

  const [
    finishChecklistLater,
    { isLoading: isFinishingLaterChecklist, isSuccess: finishSuccess },
  ] = useFinishChecklistLaterMutation();
  const [
    unlockMozartAction,
    { isSuccess: unlockSuccess, isLoading: isUnlockingMozart },
  ] = useUnlockMozartActionMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    dispatch(
      setShowLeavingChecklistConfirmation({
        exitModalOpen: false,
        action: null,
        route: null,
      })
    );
  };

  const handleExitChecklist = () => {
    if (checklistExitModel.action) {
      if (checklistExitModel.shouldLogout) {
        const user: ILogoutUser = {
          sessionId: sessionId,
        };
        dispatch(logoutUser(user, navigate, true, true));
        return;
      }

      if (checklistExitModel.route) {
        handleCloseModal();
        navigate(checklistExitModel.route);
      }
    }
  };

  const discardMozartWorkflow = () => {
    discardMozartChecklist({
      id: mozartInstanceId,
      checklistId: Number(checklistId),
    });
    dispatch(clearFailedRequests());
  };

  const handleExitAction = () => {
    const payload = {
      checklistId: Number(checklistId),
      connectionId: sessionId,
    };
    if (checklistExitModel.action === 'Discard') {
      if (checklistStorage === ChecklistStorageType.MOZART) {
        discardMozartWorkflow();
      } else if (checklistStorage === ChecklistStorageType.CHECKLIST) {
        discardChecklist(payload);
      }
    }
    if (checklistExitModel.action === 'FinishLater') {
      if (checklistStorage === ChecklistStorageType.MOZART) {
        unlockMozartAction({
          workflowInstanceId,
          actionInstanceId,
        });
      } else {
        finishChecklistLater(payload);
      }
    }
  };

  useEffect(() => {
    if (
      finishSuccess ||
      discardChecklistSuccess ||
      discardMozartChecklistSuccess ||
      unlockSuccess
    ) {
      handleExitChecklist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    discardChecklistSuccess,
    finishSuccess,
    unlockSuccess,
    discardMozartChecklistSuccess,
  ]);

  const exitText =
    checklistStorage === ChecklistStorageType.MOZART
      ? 'Are you sure you want to discard this step?'
      : 'Are you sure you want to discard this encounter?';

  return (
    <div data-cy="checklist-exit-modal" data-testid="checklistModal">
      <Confirmation
        open={checklistExitModel.exitModalOpen}
        title="Exit Checklist?"
        okButtonText="Yes"
        okEvent={handleExitAction}
        cancelEvent={handleCloseModal}
      >
        <>{exitText}</>
      </Confirmation>
      <Loader
        active={
          isDiscardingChecklist ||
          isDiscardingMozartChecklist ||
          isFinishingLaterChecklist ||
          isUnlockingMozart
        }
      />
    </div>
  );
};
export default ChecklistExitModal;
