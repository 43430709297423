import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientInsuranceTabQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientInsuranceTabQuery = {
  __typename?: 'EntitiesQuery';
  getPatientInsuranceTab: {
    __typename?: 'PatientInsuranceTabViewModel';
    insuranceCurrent: Array<{
      __typename?: 'PatientEditInsuranceCurrentViewModel';
      name?: string | null;
      number?: string | null;
      insuranceId?: string | null;
      effective?: string | null;
      term?: string | null;
    }>;
    insuranceHistory: Array<{
      __typename?: 'PatientEditInsuranceHistoryViewModel';
      contract?: string | null;
      number?: string | null;
      lob?: string | null;
      group?: string | null;
      effective?: string | null;
      disenrolled?: string | null;
      benefitNo?: string | null;
      benefitDescription?: string | null;
      inconsistentDates: boolean;
      gapWithPreviousDate: boolean;
      gapWithNextDate: boolean;
      newGroup: boolean;
    }>;
  };
};

export const GetPatientInsuranceTabDocument = `
    query getPatientInsuranceTab($patientId: Int!) {
  getPatientInsuranceTab(patientId: $patientId) {
    insuranceCurrent {
      name
      number
      insuranceId
      effective
      term
    }
    insuranceHistory {
      contract
      number
      lob
      group
      effective
      disenrolled
      benefitNo
      benefitDescription
      inconsistentDates
      gapWithPreviousDate
      gapWithNextDate
      newGroup
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientInsuranceTab: build.query<
      GetPatientInsuranceTabQuery,
      GetPatientInsuranceTabQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientInsuranceTabDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientInsuranceTabQuery,
  useLazyGetPatientInsuranceTabQuery,
} = injectedRtkApi;
