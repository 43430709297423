import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type ForwardOwnerMutationVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
  userId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type ForwardOwnerMutation = {
  __typename?: 'Mutation';
  forwardOwner?: {
    __typename?: 'ActionEpisodeResult';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const ForwardOwnerDocument = `
    mutation forwardOwner($episodeId: Int!, $userId: Int) {
  forwardOwner(episodeId: $episodeId, userId: $userId) {
    success
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    forwardOwner: build.mutation<
      ForwardOwnerMutation,
      ForwardOwnerMutationVariables
    >({
      query: (variables) => ({ document: ForwardOwnerDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useForwardOwnerMutation } = injectedRtkApi;
