import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import TimelineDetailsFormatter from '../formatters/TimelineDetailsFormatter';

const TimelineDetailsProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TimelineDetailsFormatter} {...props} />
);

export default TimelineDetailsProvider;
