import { reminderColumn } from '../consts';
import TableCell from '@mui/material/TableCell';
import { Typography } from '@mui/material';
import React from 'react';
import {
  DateEditor,
  ImportanceEditCell,
  OwnerEditCell,
  ReasonEditCell,
  StatusCell,
} from './index';
import { CellComponentStyle, EditingCell } from 'components/gridFormatters';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { TextEditorCell } from 'components/home/patientdetails/tabscontent/careTeamTab/cells/TextEditorCell';
import { DueDateTimeType } from 'graphql/graphqlTypes';
import { DateTimeEditor } from './dateTimeEditor';

const ReminderEditCell = (props: TableEditRow.CellProps) => {
  const { value, column } = props;
  switch (column.name) {
    case reminderColumn.reason:
      return <ReasonEditCell {...props} />;
    case reminderColumn.importance:
      return <ImportanceEditCell {...props} />;
    case reminderColumn.status:
      return <StatusCell {...props} />;
    case reminderColumn.date:
      return props.row.dueDateTimeType === DueDateTimeType.Date ? (
        <DateEditor {...props} autoFocus />
      ) : (
        <DateTimeEditor {...props} autoFocus />
      );
    case reminderColumn.note:
      return <TextEditorCell {...props} />;
    case reminderColumn.actions:
      return <TableCell style={CellComponentStyle} />;
    case reminderColumn.days:
      return (
        <TableCell style={CellComponentStyle}>
          <Typography>{value ?? 'New'}</Typography>
        </TableCell>
      );
    case reminderColumn.owner:
      return <OwnerEditCell {...props} />;
    default:
      return <EditingCell {...props} />;
  }
};
export default ReminderEditCell;
