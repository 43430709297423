import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledDialogTitle = styled(MuiDialogTitle)({
  margin: 0,
  padding: '16px 28px',
  backgroundColor: COLORS.SYMPHONY_BLUE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const StyledTitleTextWrapper = styled.div`
  padding-right: 12px;
  display: inline-flex;
  max-width: 98%;
`;

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  isCloseDisabled?: boolean;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, isCloseDisabled, ...other } = props;
  return (
    <StyledDialogTitle {...other}>
      <StyledTitleTextWrapper>{children}</StyledTitleTextWrapper>
      {onClose ? (
        <IconButton
          data-testid="dialog-title-close-icon"
          aria-label="close"
          onClick={onClose}
          style={{ padding: 2 }}
          data-cy="close"
          size="large"
          disabled={isCloseDisabled}
        >
          <Icon icon={ICONS.Close} size={24} color={COLORS.WHITE} />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
};

export default DialogTitle;
