import React from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import { IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const ToggleCell = (props: TableRowDetail.ToggleCellProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expanded, tableColumn, tableRow, ...restProps } = props;

  return (
    <td style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
      <IconButton {...restProps} size="large">
        {expanded ? (
          <ArrowDropUpIcon style={{ color: COLORS.GREY100 }} />
        ) : (
          <ArrowDropDownIcon style={{ color: COLORS.GREY100 }} />
        )}
      </IconButton>
    </td>
  );
};

export default ToggleCell;
