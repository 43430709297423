import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetLetterSummaryQueryVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
}>;

export type GetLetterSummaryQuery = {
  __typename?: 'EntitiesQuery';
  getLetterSummary: Array<{
    __typename?: 'Attachment';
    id: number;
    name?: string | null;
    createdOn?: any | null;
    description?: string | null;
    isDeleted: boolean;
    isVisible: boolean;
    fileExtension: string;
    createdByUser?: { __typename?: 'User'; fullName?: string | null } | null;
  }>;
};

export const GetLetterSummaryDocument = `
    query getLetterSummary($checklistId: Int!) {
  getLetterSummary(checklistId: $checklistId) {
    id
    name
    createdOn
    description
    isDeleted
    isVisible
    createdByUser {
      fullName
    }
    fileExtension
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLetterSummary: build.query<
      GetLetterSummaryQuery,
      GetLetterSummaryQueryVariables
    >({
      query: (variables) => ({ document: GetLetterSummaryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetLetterSummaryQuery, useLazyGetLetterSummaryQuery } =
  injectedRtkApi;
