import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import ReminderOverdueFormatter from '../formatters/ReminderOverdueFormatter';

const ReminderOverdueProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={ReminderOverdueFormatter} {...props} />
);

export default ReminderOverdueProvider;
