import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMozartWorkflowNavigationInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type GetMozartWorkflowNavigationInfoQuery = {
  __typename?: 'EntitiesQuery';
  getMozartWorkflowActions?: {
    __typename?: 'GetMozartWorkflowActionsResult';
    actions: Array<{
      __typename?: 'MozartWorkflowActionInfo';
      actionId: any;
      stepName?: string | null;
      checklistId?: number | null;
    }>;
  } | null;
};

export const GetMozartWorkflowNavigationInfoDocument = `
    query getMozartWorkflowNavigationInfo($id: UUID!) {
  getMozartWorkflowActions(id: $id) {
    actions {
      actionId
      stepName
      checklistId
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMozartWorkflowNavigationInfo: build.query<
      GetMozartWorkflowNavigationInfoQuery,
      GetMozartWorkflowNavigationInfoQueryVariables
    >({
      query: (variables) => ({
        document: GetMozartWorkflowNavigationInfoDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetMozartWorkflowNavigationInfoQuery,
  useLazyGetMozartWorkflowNavigationInfoQuery,
} = injectedRtkApi;
