import React, { MouseEventHandler } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 12px 0;
`;

const StyledButtonLeft = styled(Button)({
  minWidth: '152px',
});

const StyledButtonRight = styled(Button)({
  minWidth: '152px',
  marginLeft: '16px',
});

export interface IButtonsProp {
  handleCancel?: MouseEventHandler;
}

const Buttons = (props: IButtonsProp) => {
  return (
    <ButtonsWrapper>
      <StyledButtonLeft
        onClick={props.handleCancel}
        color="primary"
        data-cy="cancel"
        variant="outlined"
        size="large"
      >
        Cancel
      </StyledButtonLeft>
      <StyledButtonRight
        color="primary"
        variant="contained"
        data-cy="save"
        size="large"
        type="submit"
      >
        Save
      </StyledButtonRight>
    </ButtonsWrapper>
  );
};
export default Buttons;
