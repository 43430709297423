import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IState } from 'store';

interface IStyledPanelWrapperProps {
  navigation?: boolean;
  isHighlighted?: boolean;
  isPrinting: boolean;
}

const StyledHeaderText = styled(Typography)({
  paddingBottom: '20px',
  wordBreak: 'break-all',
});

const StyledPanelWrapper = styled.div<IStyledPanelWrapperProps>`
  border: 1px solid ${COLORS.GREY25};
  background-color: ${COLORS.WHITE};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.isPrinting ? `5px` : `24px`)};
  flex: 1;
  align-items: stretch;
  border-top: ${(props) =>
    props.isHighlighted
      ? `4px solid ${COLORS.SYMPHONY_BLUE}`
      : `1px solid ${COLORS.GREY25}`};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

interface IPanelProps {
  header?: string | null;
  padding?: string | null;
  children: ReactNode;
  navigation?: boolean;
  testId?: string;
  isHighlighted?: boolean;
}

const Panel = (props: IPanelProps) => {
  const { header, padding, children, navigation, testId, isHighlighted } =
    props;
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isOptimizedView = printDocuments.isOptimizedView;
  return (
    <StyledPanelWrapper
      isPrinting={isOptimizedView}
      navigation={navigation}
      isHighlighted={isHighlighted}
      data-testid={testId}
      style={{ padding: padding ? padding : '24px' }}
    >
      <StyledContainer>
        {header ? (
          <StyledHeaderText variant="h4">{header}</StyledHeaderText>
        ) : null}
        {children}
      </StyledContainer>
    </StyledPanelWrapper>
  );
};

export default Panel;
