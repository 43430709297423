import { Table } from '@devexpress/dx-react-grid-material-ui';
import { CellComponentStyle } from './CellComponentStyle';
import { Typography } from '@mui/material';
import React from 'react';
import { ILabelCellProps } from './index';

export const LabelCell = ({ cellColor, ...props }: ILabelCellProps) => (
  <Table.Cell {...props} style={CellComponentStyle} data-cy="table-cell">
    {props.value
      ?.toString()
      ?.split('\n')
      .map((str: string, index: number) => (
        <Typography key={index.toString()} style={{ color: cellColor }}>
          {str}
        </Typography>
      ))}
  </Table.Cell>
);
