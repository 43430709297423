import React, { useEffect } from 'react';
import { useUpdateChecklistMutation } from 'graphql/hooks/updateChecklist';
import Loader from 'components/loader';
import StyledLink from './StyledLink';
import { IChecklistLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { setIsAmendingChecklist } from 'store/actions/checklistSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

interface IChecklistUpdateProps extends IChecklistLinkProps {
  label?: string;
  callback?: () => void;
}

const ChecklistUpdateLink = (props: IChecklistUpdateProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [
    updateChecklist,
    {
      isLoading: isUpdatingChecklist,
      isSuccess: isSuccessUpdateChecklist,
      data: updateChecklistResult,
    },
  ] = useUpdateChecklistMutation();

  useEffect(() => {
    if (isSuccessUpdateChecklist) {
      if (props.callback) {
        props.callback();
      }
      dispatch(
        setIsAmendingChecklist({
          storageType: ChecklistStorageType.CHECKLIST,
          isAmendingChecklist: true,
        })
      );
      navigate(`/Checklist/Actions/${updateChecklistResult?.updateChecklist}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdateChecklist]);

  const onUpdateChecklist = (checklistId: number) => {
    updateChecklist({ checklistId: Number(checklistId) });
  };

  return (
    <>
      <CustomTooltip
        title={
          props.title ??
          'Creates a new document, transferring the maximum information possible from the original'
        }
      >
        <StyledLink
          onClick={() => onUpdateChecklist(props.checklistId)}
          data-cy="Update"
        >
          Update
        </StyledLink>
      </CustomTooltip>
      <Loader active={isUpdatingChecklist} />
    </>
  );
};

export default ChecklistUpdateLink;
