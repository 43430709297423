import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import { SearchPatientDialogActions } from './SearchPatientDialogActions';
import { SearchPatientDialogBody } from './SearchPatientDialogBody';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import styled from 'styled-components';

const StyledDialogContent = styled(DialogContent)({
  height: '75vh',
  overflowY: 'auto',
  overflowX: 'hidden',
});

const StyledSpan = styled.span`
  font-size: 14px;
`;

export interface ISearchPatientDialogProps {
  open: boolean;
  onNew(patientId: number, episodeId: number): void;
  onClose(): void;
}

export const SearchPatientDialog = ({
  open,
  onNew,
  onClose,
}: ISearchPatientDialogProps) => {
  const episodeId = useSelector(
    (state: IState) => state.ui.modals.searchPatient.episodeId
  );
  const episodeAttachmentId = useSelector(
    (state: IState) => state.ui.modals.searchPatient.episodeAttachmentId
  );
  const [selectedPatientId, setSelectedPatient] = useState(0);
  const [selectedEpisodeId, setSelectedEpisode] = useState(0);

  const handleClose = () => {
    onClose();
    setSelectedPatient(0);
    setSelectedEpisode(0);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <StyledSpan>Find Member</StyledSpan>
      </DialogTitle>
      <StyledDialogContent dividers>
        <SearchPatientDialogBody
          selectedPatientId={selectedPatientId}
          onPatientSelection={(selPatientId: number) =>
            setSelectedPatient(selPatientId)
          }
          onEpisodeSelection={(selEpisodeId: number) =>
            setSelectedEpisode(selEpisodeId)
          }
        />
      </StyledDialogContent>
      <DialogActions
        style={{ justifyContent: 'space-between', padding: ' 20px 28px' }}
      >
        <SearchPatientDialogActions
          episodeId={episodeId}
          episodeAttachmentId={episodeAttachmentId}
          selectedEpisodeId={selectedEpisodeId}
          selectedPatientId={selectedPatientId}
          onNew={onNew}
          onClose={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};
