export interface ILookupValue<T = number> {
  id: T;
  name: string;
  sortOrder?: number | null;
  image?: string | null;
  isDeleted?: boolean | null;
  isDisabled?: boolean | null;
  parentLookupValueId?: string | null;
}

export type ILookupValueOrDefault<T = number> =
  | ILookupValue<T>
  | typeof defaultAutocompleteValue;

export const defaultAutocompleteValue = {
  id: undefined,
  name: '',
  isDisabled: true,
};

export enum LookupKnownName {
  CareStage = 'EpisodeCareStage',
  Pathway = 'Pathway',
  EpisodeServiceLocation = 'EpisodeServiceLocation',
  ProblemPriority = 'ProblemPriority',
  ProblemProgress = 'ProblemProgress',
  GoalOutcome = 'GoalOutcome',
}
