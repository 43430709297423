import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import PriorAuthDisabledMessFormatter from '../formatters/PriorAuthDisabledMessFormatter';

const PriorAuthDisabledMessProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={PriorAuthDisabledMessFormatter}
    {...props}
  />
);

export default PriorAuthDisabledMessProvider;
