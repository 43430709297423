import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import SearchBar from 'components/searchbar';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { FilterGrid } from 'components/gridFormatters';
import { ILookupValue } from 'backend/types/lookupValue';
import {
  MedicationViewModel,
  PatientMedicationStatusFilter,
} from 'graphql/graphqlTypes';
import Clipboard from 'components/clipboard/Clipboard';
import styled from 'styled-components';
interface IGridHeaderProps {
  handleSearchTermChange: (searchTerm: string) => void;
  handleFilterChange: (filter: ILookupValue) => void;
  handleAddMedication: () => void;
  selectedFilter: ILookupValue;
  columnOpen: boolean;
  medications: Array<MedicationViewModel>;
}

export const StyledDivider = styled(Divider)`
  margin-top: 6px;
  margin-right: 20px;
  height: 20px;
  color: ${COLORS.GREY25};
`;

export const MedicationStatusAll: ILookupValue = {
  id: -1,
  name: PatientMedicationStatusFilter.All,
};
export const MedicationStatusActive: ILookupValue = {
  id: 0,
  name: PatientMedicationStatusFilter.Active,
};
export const MedicationStatusDiscontinued: ILookupValue = {
  id: 1,
  name: PatientMedicationStatusFilter.Discontinued,
};
const MedicationStatusFilters: ILookupValue[] = [
  MedicationStatusAll,
  MedicationStatusActive,
  MedicationStatusDiscontinued,
];
const GridHeader = ({
  handleSearchTermChange,
  handleFilterChange,
  handleAddMedication,
  selectedFilter,
  columnOpen,
  medications,
}: IGridHeaderProps) => {
  const medicationsToCopy = medications
    .map((x) => `${x.medication} - ${x.instructions} (${x.patientState})`)
    .join('\r\n');
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '10px',
      }}
    >
      <SearchBar
        onSubmit={handleSearchTermChange}
        placeholder="Search Member's Meds..."
      />
      {!columnOpen ? (
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" pr="20px">
            <FilterGrid
              selectedItemId={selectedFilter.id}
              onChange={handleFilterChange}
              items={MedicationStatusFilters}
            />
          </Box>
          <StyledDivider orientation="vertical" />
          <Clipboard
            copyText={medicationsToCopy}
            snackBarTitle="Text copied to clipboard"
            snackBarText="Medication Info copied to clipboard"
            buttonLabel="Copy"
            tooltip="Copy Medications, Instructions, RX Status to your clipboard"
            icon={ICONS.Copy}
          />
          <StyledDivider orientation="vertical" />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleAddMedication}
            startIcon={<Icon icon={ICONS.Add} size={14} color={COLORS.WHITE} />}
          >
            Add med
          </Button>
        </Box>
      ) : null}
    </div>
  );
};
export default GridHeader;
