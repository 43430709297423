import React, { ChangeEvent, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { ReminderComponent } from 'graphql/graphqlTypes';
import { StyledColumn, StyledWrapper } from '.';
import styled from 'styled-components';

interface IReminderNoteProps {
  component: ReminderComponent;
  handleReminderInputChange: (
    value: string | Date | null,
    reminderComponentType: string
  ) => void;
  disabled?: boolean;
  isReadOnly: boolean;
  showComment: boolean;
  changeShowComment: (show: boolean) => void;
}
const StyledTypography = styled(Typography)`
  color: ${COLORS.BLACK};
`;
const StyledGreyTypography = styled(Typography)`
  color: ${COLORS.GREY100};
`;

const ReminderNote = (props: IReminderNoteProps) => {
  const {
    component,
    disabled,
    isReadOnly,
    handleReminderInputChange,
    showComment,
    changeShowComment,
  } = props;

  const [comment, changeComment] = useState<string>(component.comment || '');

  return (
    <>
      {!disabled && (
        <Grid
          container
          style={{ display: showComment ? 'inline-flex' : 'none' }}
        >
          <StyledColumn item xs={12}>
            <StyledWrapper style={{ marginRight: '10px' }}>
              <StyledTypography variant="body2">Note:</StyledTypography>
            </StyledWrapper>
            <StyledWrapper style={{ marginRight: '10px', width: '85%' }}>
              <TextField
                disabled={isReadOnly}
                size="small"
                fullWidth
                value={comment}
                variant="outlined"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  changeComment(event.target.value)
                }
                onBlur={() => handleReminderInputChange(comment, 'Comment')}
              />
            </StyledWrapper>
            <StyledWrapper style={{ marginRight: '10px' }}>
              <IconButton
                edge="end"
                disabled={isReadOnly}
                style={{
                  display: showComment ? 'block' : 'none',
                  padding: '4px',
                }}
                title="remove comment"
                onClick={() => {
                  changeShowComment(false);
                  changeComment('');
                }}
                size="large"
              >
                <Icon icon={ICONS.Remove} size={20} color={COLORS.RED100} />
              </IconButton>
            </StyledWrapper>
          </StyledColumn>
        </Grid>
      )}
      {disabled && comment && comment.trim().length > 0 && (
        <Grid container>
          <StyledColumn item xs={12}>
            <StyledWrapper style={{ marginRight: '10px' }}>
              <StyledGreyTypography variant="body2">
                Note: {comment}
              </StyledGreyTypography>
            </StyledWrapper>
          </StyledColumn>
        </Grid>
      )}
    </>
  );
};

export default ReminderNote;
