import React from 'react';
import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import styled from 'styled-components';
import {
  ReminderIconValue,
  reminderIconValueColor,
  useReminderClickHandle,
} from './ReminderOverdueFormatter.helpers';

const StyledLink = styled(Link)<{ $linkColor: string }>`
  cursor: pointer;
  color: ${({ $linkColor }) => $linkColor};
  line-height: 20px;
`;

const ReminderOverdueFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const [reminderClickHandle] = useReminderClickHandle(
    props.row.patientId,
    props.row.episodeId,
    props.row.EpisodeType,
    props.row.id,
    props.row.EpisodeSource
  );

  const reminderLinkColor = () => {
    const reminderIcon = String(
      props.row.Patient_ReminderIcon
    ) as ReminderIconValue;
    return reminderIconValueColor(reminderIcon);
  };

  return (
    <>
      {reminderLinkColor() ? (
        <StyledLink
          $linkColor={reminderLinkColor()}
          onClick={reminderClickHandle}
        >
          {props.value}
        </StyledLink>
      ) : null}
    </>
  );
};

export default ReminderOverdueFormatter;
