import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetLetterDialogModelQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetLetterDialogModelQuery = {
  __typename?: 'EntitiesQuery';
  getLetterDialogModel?: {
    __typename?: 'LetterDialogModel';
    printShopIsActive?: boolean | null;
    letterGroups?: Array<{
      __typename?: 'DictionaryValue';
      id?: number | null;
      key?: string | null;
      value?: string | null;
    } | null> | null;
    letters?: Array<{
      __typename?: 'LetterModel';
      id: number;
      name: string;
      letterGroup?: string | null;
      letterType: Types.LetterType;
    } | null> | null;
    faxParameter?: {
      __typename?: 'FaxParameters';
      isFaxConfiguredByCareSite?: boolean | null;
      isFaxConfiguredByRole?: boolean | null;
      isFaxCoverCreated?: boolean | null;
      isLetterTransmittalByFax?: boolean | null;
    } | null;
    patientLanguage?: {
      __typename?: 'LookupValue';
      name?: string | null;
    } | null;
  } | null;
};

export const GetLetterDialogModelDocument = `
    query getLetterDialogModel($patientId: Int!) {
  getLetterDialogModel(patientId: $patientId) {
    letterGroups {
      id
      key
      value
    }
    letters {
      id
      name
      letterGroup
      letterType
    }
    faxParameter {
      isFaxConfiguredByCareSite
      isFaxConfiguredByRole
      isFaxCoverCreated
      isLetterTransmittalByFax
    }
    printShopIsActive
    patientLanguage {
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLetterDialogModel: build.query<
      GetLetterDialogModelQuery,
      GetLetterDialogModelQueryVariables
    >({
      query: (variables) => ({
        document: GetLetterDialogModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetLetterDialogModelQuery,
  useLazyGetLetterDialogModelQuery,
} = injectedRtkApi;
