/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { Asterisk } from 'components/text';
import {
  CareSiteFaxNumberViewModel,
  FaxDialogViewModel,
} from 'graphql/graphqlTypes';

const width = '305px';

const PaddedBox = styled(Box)({
  padding: '20px 24px',
});

const PaddedGrayBox = styled(PaddedBox)({
  backgroundColor: COLORS.GREY4,
});

const FormContainer = styled(Box)({
  flexGrow: 1,
});

const StyledInputField = styled(TextField)({
  width,
});

const StyledDropdown = styled(Select)({
  width,
});

const InputRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const FirstInputRow = styled(InputRow)({
  marginBottom: '20px',
});

const Label = styled(Box)({
  maxWidth: '110px',
  width: '110px',
  display: 'flex',
  flexDirection: 'row',
});

export enum PreviewLabel {
  Preview = 'Preview',
  RefreshPreview = 'Refresh Preview',
}

export interface IFaxForm {
  initial?: FaxDialogViewModel;
  faxNumbers?: CareSiteFaxNumberViewModel[] | null;
  onErrors: () => void;
  onFilled: () => void;
  onSubmit: (data: FaxFormData) => void;
  previewDisplay: string;
  setSendFax: (sendFax: boolean) => void;
}

export type FaxFormData = {
  recipientTo: string;
  recipientFaxNumber: string;
  senderFrom: string;
  senderRespondTo: number;
  note?: string;
};

const schema = yup.object({
  note: yup.string().nullable(),
  recipientTo: yup.string().required(),
  recipientFaxNumber: yup
    .string()
    .required()
    .matches(/^\d+$/, 'Must be only digits')
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
  senderFrom: yup.string().required(),
  senderRespondTo: yup.number().positive().integer().required(),
});

const getDefaultValues = (initial?: FaxDialogViewModel): FaxFormData => ({
  recipientTo: initial?.recipientTo ?? '',
  recipientFaxNumber: initial?.recipientFaxNumber ?? '',
  senderFrom: initial?.senderFrom ?? '',
  senderRespondTo: initial?.senderRespondTo ?? 0,
  note: initial?.note ?? '',
});

const FaxFormComponent = forwardRef<HTMLInputElement | null, IFaxForm>(
  (
    { initial, faxNumbers, onErrors, onFilled, previewDisplay, ...rest },
    ref
  ) => {
    const {
      control,
      handleSubmit,
      formState: { errors, isValid, isDirty, submitCount },
    } = useForm<FaxFormData>({
      resolver: yupResolver(schema),
      defaultValues: getDefaultValues(initial),
    });

    const onChange = () => {
      rest.setSendFax(false);
    };

    const onSubmit = handleSubmit((data) => rest.onSubmit(data));

    useEffect(() => {
      submitCount && isDirty && !isValid ? onErrors() : onFilled();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty, isValid, submitCount]);

    return (
      <FormContainer>
        <form
          data-testid="fax-form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
          onChange={onChange}
        >
          <input type="submit" hidden ref={ref} />
          <PaddedBox>
            <Typography variant="subtitle2">Recipient</Typography>
          </PaddedBox>
          <PaddedGrayBox>
            <FirstInputRow>
              <Label>
                <Asterisk mr="2px" />
                <Typography variant="body1">To:</Typography>
              </Label>
              <Controller
                name="recipientTo"
                control={control}
                render={({ field }) => (
                  <StyledInputField
                    size="medium"
                    variant="outlined"
                    {...field}
                    error={!!errors.recipientTo}
                  />
                )}
              />
            </FirstInputRow>
            <InputRow>
              <Label>
                <Asterisk mr="2px" />
                <Typography variant="body1">Fax No.:</Typography>
              </Label>
              <Controller
                name="recipientFaxNumber"
                control={control}
                render={({ field }) => (
                  <StyledInputField
                    size="medium"
                    variant="outlined"
                    {...field}
                    error={!!errors.recipientFaxNumber}
                  />
                )}
              />
            </InputRow>
          </PaddedGrayBox>
          <PaddedBox>
            <Label>
              <Typography variant="subtitle2">Sender</Typography>
            </Label>
          </PaddedBox>
          <PaddedGrayBox>
            <FirstInputRow>
              <Label>
                <Asterisk mr="2px" />
                <Typography variant="body1">From:</Typography>
              </Label>
              <Controller
                name="senderFrom"
                control={control}
                render={({ field }) => (
                  <StyledInputField
                    size="medium"
                    variant="outlined"
                    {...field}
                    error={!!errors.senderFrom}
                  />
                )}
              />
            </FirstInputRow>
            <InputRow>
              <Label>
                <Asterisk mr="2px" />
                <Typography variant="body1">Respond to:</Typography>
              </Label>
              <Controller
                name="senderRespondTo"
                control={control}
                render={({ field }) => (
                  <StyledDropdown
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    variant="outlined"
                    {...field}
                    error={!!errors.senderRespondTo}
                  >
                    {faxNumbers?.map(({ faxId, faxLabel, faxNumber }) => (
                      <MenuItem key={faxId} value={faxId}>
                        {faxLabel} {faxNumber}
                      </MenuItem>
                    ))}
                  </StyledDropdown>
                )}
              />
            </InputRow>
          </PaddedGrayBox>
          <PaddedBox>
            <InputRow>
              <Label style={{ paddingTop: '4px', marginBottom: 'auto' }}>
                <Typography>Notes: </Typography>
              </Label>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <StyledInputField
                    size="medium"
                    variant="outlined"
                    multiline
                    {...field}
                    error={!!errors.note}
                  />
                )}
              />
            </InputRow>
          </PaddedBox>
          <PaddedGrayBox>
            <Button
              variant="outlined"
              onClick={() => onSubmit()}
              startIcon={
                previewDisplay === PreviewLabel.RefreshPreview ? (
                  <Icon
                    icon={ICONS.Refresh}
                    size={14}
                    color={COLORS.SYMPHONY_BLUE}
                    style={{ marginTop: '-2px' }}
                  />
                ) : undefined
              }
            >
              {previewDisplay}
            </Button>
          </PaddedGrayBox>
        </form>
      </FormContainer>
    );
  }
);

FaxFormComponent.displayName = 'FaxForm';

export const FaxForm = FaxFormComponent;
