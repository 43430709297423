import React from 'react';
import { IconButton } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const StyledIconButton = styled(IconButton)<{ $activeButton: boolean }>`
  border: solid 1px ${COLORS.LIGHT_GREY};
  border-radius: 4px;
  height: 32px;
  margin: 12px 5px;
  width: 32px;
  color: ${COLORS.GREY100};
  background-color: inherit;
  ${({ $activeButton }) =>
    $activeButton &&
    `background-color: ${COLORS.PALE_SKY_BLUE};
     border-color: ${COLORS.SYMPHONY_BLUE};
     color: ${COLORS.SYMPHONY_BLUE};
     border: 1px solid ${COLORS.SYMPHONY_BLUE};
      &:enabled: {
        borderRightColor: ${COLORS.SYMPHONY_BLUE}!important,
      };
    `}
`;

interface IFilterButtonProps {
  isActive: boolean;
  icon: ICONS;
  title: string;
  onClick: () => void;
}

const FilterButton = (props: IFilterButtonProps) => {
  const { isActive, icon, title, onClick } = props;

  return (
    <CustomTooltip title={title ?? ''}>
      <StyledIconButton
        edge="end"
        size="medium"
        $activeButton={isActive}
        onClick={onClick}
      >
        <Icon
          icon={icon}
          size={18}
          color={isActive ? COLORS.SYMPHONY_BLUE : COLORS.GREY100}
        />
      </StyledIconButton>
    </CustomTooltip>
  );
};

export default FilterButton;
