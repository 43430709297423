import React from 'react';

import { EntityAttributeComponent } from 'graphql/graphqlTypes';
import { StyledReviewTypography } from '../styles';

export interface IBoolAttributeProps {
  component: EntityAttributeComponent;
}

const BoolAttribute = ({ component }: IBoolAttributeProps) => {
  return (
    <StyledReviewTypography
      variant="body1"
      $color={component.color ?? undefined}
    >
      {component.value?.toLowerCase() === 'true' ? 'Yes' : 'No'}
    </StyledReviewTypography>
  );
};
export default BoolAttribute;
