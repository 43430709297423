import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { Box, SelectChangeEvent } from '@mui/material';
import { ILookupValue } from 'backend/types/lookupValue';
import { usePrevious } from 'hooks';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledFormControl = styled(FormControl)({
  minWidth: '300px',
  minHeight: '32px',
  width: '100%',
});

const Chips = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px',
});

const StyledChip = styled(Chip)`
  max-width: 250px;
  height: 22px;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;

  &.MuiChip-label {
    overflow: hidden !important;
    padding: 4px 8px 4px 14px;
  }
`;

const StyledSelect = styled(Select)({
  padding: 0,
  minHeight: '22px',
});

const StyledMenuItem = styled(MenuItem)({
  padding: '2px 20px',
  selected: {
    '&.Mui-selected': {
      backgroundColor: COLORS.WHITE,
    },
  },
});

const StyledMenuItemText = styled(ListItemText)({
  display: 'block',
  margin: '0px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  primary: {
    display: 'inline',
    fontWeight: 400,
  },
});

const paperItemHeight = 48;
const paperItemPaddingTop = 8;

export interface IDropdownItem {
  sortOrder?: number;
  id: number;
  name: string;
  isDeleted?: boolean;
}

export interface IMultiDropdownProps {
  items: ILookupValue[];
  value?: number[];
  error?: boolean;
  sortAlphabetically?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange: (selected: number[]) => void;
  isItemDisabled?: (item: ILookupValue) => boolean | undefined;
}

const MultiSelect = (props: IMultiDropdownProps) => {
  const {
    items,
    error,
    value,
    onChange,
    sortAlphabetically,
    disabled,
    fullWidth,
    isItemDisabled,
  } = props;
  const [selected, setSelected] = React.useState<number[]>(value ?? []);
  const prevSelected = usePrevious(selected);

  useEffect(() => {
    if (prevSelected !== selected) {
      onChange(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const order = items.reduce(
    (acc: { [id: number]: number }, item) => (
      (acc[item.id] = item.sortOrder ?? item.id), acc
    ),
    {}
  );

  const alphaOrder = items.reduce(
    (acc: { [id: number]: string }, item) => (
      (acc[item.id] = item.name ?? ''), acc
    ),
    {}
  );

  const changeSelected = (newValues: number[]) => {
    newValues.sort((a: number, b: number) => {
      if (sortAlphabetically) {
        return alphaOrder[a] > alphaOrder[b] ? 1 : -1;
      }
      return order[a] > order[b] ? 1 : -1;
    });
    setSelected(newValues);
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newValues = event.target.value as number[];
    changeSelected(newValues);
  };

  const onDelete = (toDelete: number) => {
    const newValues = selected.filter((item) => item !== toDelete);
    changeSelected(newValues);
  };

  const chipEventBubbleOverride = (event: React.ChangeEvent<unknown>) =>
    event.stopPropagation();

  return (
    <StyledFormControl>
      <StyledSelect
        data-testid="MultiSelect"
        variant="outlined"
        multiple
        value={selected}
        onChange={handleChange}
        disabled={disabled}
        fullWidth={fullWidth}
        error={error}
        renderValue={(selectedItem) => (
          <Chips>
            {(selectedItem as number[]).map((selectedItemValue) => (
              <StyledChip
                key={selectedItemValue}
                label={items.find(({ id }) => id === selectedItemValue)?.name}
                clickable={true}
                onDelete={() => onDelete(selectedItemValue)}
                onMouseDown={chipEventBubbleOverride}
              />
            ))}
          </Chips>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: paperItemHeight * 4.5 + paperItemPaddingTop,
              width: '250px',
              transform: 'translate(0px, 5px)',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#CECECE',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {items.map((item: ILookupValue) => (
          <StyledMenuItem
            key={item.id}
            value={item.id}
            disabled={isItemDisabled && isItemDisabled(item)}
          >
            <Checkbox
              checked={selected.indexOf(item.id) > -1}
              color="primary"
              disabled={isItemDisabled && isItemDisabled(item)}
            />
            <StyledMenuItemText primary={item.name} />
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default MultiSelect;
