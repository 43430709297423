import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IScenario } from 'backend/types/scenario';
import Icon, { ICONS } from 'components/icon';
import IconButton from '@mui/material/IconButton';
import { COLORS } from 'consts/styles';
import { notMaybe } from 'util/helpers/arrayFunctions';
import { usePrevious } from 'hooks';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const StyledTableContainer = styled(TableContainer)({
  minHeight: '511px',
  maxHeight: '511px',
  borderRadius: '4px',
  border: `solid 1px ${COLORS.LIGHT_GREY}`,
  backgroundColor: COLORS.WHITE,
  padding: '12px 0px',
});

const StyledTable = styled(Table)({
  '& tr:nth-of-type(odd)': {
    backgroundColor: COLORS.GREY4,
  },
});

const StyledColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const StyledTableRow = styled(TableRow)({
  padding: '20px 16px',
});

interface IProps {
  selectedScenarioId?: number;
  scenarios: IScenario[];
  onSetSelectedScenario: (id: number) => void;
  onSetFavoriteScenario: (ids: number[]) => void;
  updateSelectedScenario: (id: number) => void;
}

const List = (props: IProps) => {
  const {
    selectedScenarioId,
    scenarios,
    onSetSelectedScenario,
    onSetFavoriteScenario,
    updateSelectedScenario,
  } = props;
  const [favorites, updateFavorites] = useState<number[]>([]);

  const ids =
    scenarios
      .filter((x: IScenario) => x.favorite)
      .map((x: IScenario) => x.id) ?? [];
  const previousIds = usePrevious(ids);

  const equalsArrays = (a: number[], b: number[]) =>
    a.length === b.length && a.every((v, i) => v === b[i]);
  const items = scenarios?.filter(notMaybe);
  const sortedItems = sortBy<IScenario>(items, [
    (item) => (!item.favorite ? 1 : 0),
  ]);

  useEffect(() => {
    if (!equalsArrays(ids, previousIds || [])) {
      updateFavorites(ids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  useEffect(() => {
    if (sortedItems.length > 0 && !selectedScenarioId) {
      updateSelectedScenario(sortedItems[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedItems]);

  const handleFavoriteClick = (id: number, isSetFavorite: boolean) => {
    if (!isSetFavorite) {
      updateFavorites([...favorites, id]);
      onSetFavoriteScenario([...favorites, id]);
      return;
    }
    const temp = [...favorites];
    const index = temp.indexOf(id);
    if (index > -1) {
      temp.splice(index, 1);
    }
    updateFavorites(temp);
    onSetFavoriteScenario(temp);
  };

  return (
    <StyledTableContainer>
      <StyledTable>
        <TableBody>
          {sortedItems.map((scenario: IScenario, key: number) => (
            <StyledTableRow
              key={scenario.id}
              data-cy={`scenario-${scenario.id}`}
              data-testid={`scenario-${key}`}
            >
              <TableCell
                style={{
                  border: 'none',
                  padding: '16px 0px',
                  cursor: 'pointer',
                  maxWidth: '85%',
                  wordBreak: 'break-word',
                }}
                onClick={() => onSetSelectedScenario(scenario.id)}
              >
                <StyledColumnWrapper>
                  <Radio
                    color="primary"
                    value={scenario.id}
                    data-testid={`radio-${key}`}
                    checked={scenario.id === selectedScenarioId}
                    onChange={() => onSetSelectedScenario(scenario.id)}
                    style={{ marginRight: '8px' }}
                  />
                  <Typography variant="body1">
                    {scenario.displayName}
                  </Typography>
                </StyledColumnWrapper>
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'right',
                  border: 'none',
                  padding: '13px 20px',
                }}
              >
                <CustomTooltip title="Favorite">
                  <IconButton
                    onClick={() =>
                      handleFavoriteClick(
                        scenario.id,
                        favorites.indexOf(scenario.id) >= 0
                      )
                    }
                    style={{ padding: '4px' }}
                    size="large"
                  >
                    <Icon
                      icon={
                        favorites.indexOf(scenario.id) >= 0
                          ? ICONS.Filled
                          : ICONS.Outline
                      }
                      size={16}
                      color={
                        favorites.indexOf(scenario.id) >= 0
                          ? COLORS.YELLOW100
                          : COLORS.LIGHT_GREY
                      }
                    />
                  </IconButton>
                </CustomTooltip>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default List;
