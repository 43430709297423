import FormControl from '@mui/material/FormControl';
import React, { ChangeEvent, CSSProperties, FormEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Icon, { ICONS } from 'components/icon';
import IconButton from '@mui/material/IconButton';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

interface ISearchForm {
  searchTerm: string;
}

export interface ISearchBarProperties {
  onSubmit: (searchTerm: string) => void;
  onClick?: () => void;
  onChange?: (event: string) => void;
  value?: string;
  placeholder?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  variant?: 'primary' | 'secondary';
  size?: 'medium' | 'large';
  setIsAdvancedSearch?: (value: boolean) => void;
}

interface IStyledIconButtonProps {
  variant: string;
}

const StyledOutlinedInput = styled(OutlinedInput)<{
  $inputSize?: 'medium' | 'large';
}>`
  height: 32px;
  background-color: white;
  padding-right: 3px;
  ${({ $inputSize }) => `
      width: ${$inputSize === 'large' ? '400px' : '270px'};
  `}
`;

const StyledIconButton = styled(IconButton)<IStyledIconButtonProps>`
  margin-top-right: -13px !important;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 32px;
  width: 38px;
  ${({ variant }) => `
      background: ${
        variant === 'primary' ? COLORS.SYMPHONY_BLUE : COLORS.BLUE10
      };
      &:hover {
      background: ${
        variant === 'primary' ? COLORS.SYMPHONY_BLUE : COLORS.BLUE10
      };
  `}
`;

const SearchBar = ({
  onClick,
  onChange,
  placeholder,
  style,
  value,
  containerStyle,
  setIsAdvancedSearch,
  ...rest
}: ISearchBarProperties) => {
  const { register, handleSubmit, setValue } = useForm<ISearchForm>();
  const [text, setText] = useState('');
  const onSubmit = (values: ISearchForm) => rest.onSubmit(values.searchTerm);

  const handleFormSubmit = (
    onSubmit: (values: ISearchForm) => void
  ): React.FormEventHandler<HTMLFormElement> | undefined => {
    return (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(onSubmit);
      e.stopPropagation();
    };
  };

  const variant = rest.variant ?? 'primary';
  const size = rest.size ?? 'medium';

  return (
    <form
      data-testid="search-bar-form"
      onSubmit={handleFormSubmit(onSubmit)}
      data-cy="search-bar"
      style={containerStyle}
    >
      <FormControl size="small" variant="outlined">
        <StyledOutlinedInput
          id="quickSearch"
          type="text"
          $inputSize={size ?? 'medium'}
          style={{
            borderRadius: '4px',
            ...(style ?? {}),
          }}
          inputProps={{
            style: { borderRadius: '4px' },
            'data-testid': 'search-bar-form-input',
          }}
          {...register('searchTerm')}
          placeholder={placeholder ?? 'Search...'}
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (
              setIsAdvancedSearch != null &&
              setIsAdvancedSearch !== undefined
            ) {
              setIsAdvancedSearch(true);
            }
            setValue('searchTerm', event.target.value);
            setText(event.target.value);
            onChange?.(event.target.value);
          }}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              handleSubmit(onSubmit)();
            }
          }}
          onMouseDown={onClick}
          autoComplete="off"
          endAdornment={
            <InputAdornment position="end">
              {(text || value) && (
                <IconButton
                  edge="end"
                  size="small"
                  style={{
                    marginRight: '3px',
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => {
                    setValue('searchTerm', '');
                    setText('');
                    onChange?.('');
                    onSubmit({ searchTerm: '' });
                  }}
                >
                  <Icon icon={ICONS.Remove} size={18} color={COLORS.GREY100} />
                </IconButton>
              )}
              <StyledIconButton
                data-testid="search-bar-form-submit-button"
                edge="end"
                size="small"
                onClick={handleSubmit(onSubmit)}
                variant={variant}
              >
                <Icon
                  icon={ICONS.Search}
                  size={14}
                  color={
                    variant === 'primary' ? COLORS.WHITE : COLORS.SYMPHONY_BLUE
                  }
                />
              </StyledIconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </form>
  );
};

export default SearchBar;
