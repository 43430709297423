import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'store';
import { ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';

export const selectReminderReasons = createSelector(
  (state: IState) => state.home?.mainModel?.lookups,
  (lookups: ILookup[]) =>
    (lookups.filter((x) => x.name === 'ReminderReason')[0]
      ?.values as ILookupValue[]) || []
);
