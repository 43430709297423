import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientDetailsTabsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPatientDetailsTabsQuery = {
  __typename?: 'EntitiesQuery';
  getPatientDetailsTabs?: Array<{
    __typename?: 'PatientDetailsTabDefinition';
    index: number;
    name?: string | null;
    title?: string | null;
    readOnly: boolean;
  } | null> | null;
};

export const GetPatientDetailsTabsDocument = `
    query getPatientDetailsTabs {
  getPatientDetailsTabs {
    index
    name
    title
    readOnly
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientDetailsTabs: build.query<
      GetPatientDetailsTabsQuery,
      GetPatientDetailsTabsQueryVariables | void
    >({
      query: (variables) => ({
        document: GetPatientDetailsTabsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientDetailsTabsQuery,
  useLazyGetPatientDetailsTabsQuery,
} = injectedRtkApi;
