import {
  IProvider,
  IRecentProvider,
} from 'components/providerSearchDialog/types';
import { setComponentValuesByUserDefinedIds } from 'store/actions/checklistSlice';
import { GetProviderSearchModelQuery } from 'graphql/hooks/getProviderSearchModel';
import { SearchProvidersQuery } from 'graphql/hooks/searchProviders';
import {
  BaseComponent,
  CheckboxRadioButtonComponent,
  ChecklistItemOrderable,
  KeyValuePairOfStringAndString,
  ProviderSearchResultRow,
} from 'graphql/graphqlTypes';
import { Dispatch } from 'redux';
import { ChecklistStorageType, IChecklistComponent } from 'store/actions/types';
import { IDummyProvider } from '../types';
import {
  copyDummyProviderComponentValue,
  copyDummyProviderSource,
} from 'store/actions/actionsSlice.helpers';

export const providerType = (authRole: string) => {
  return authRole === 'facility' ? 'facility' : 'provider';
};

export enum providerKeyForCopy {
  dummy_Requesting_Name = 'Dummy.Requesting.Name',
  dummy_Requesting_NPI = 'Dummy.Requesting.NPI',
  dummy_Requesting_StreetAddress = 'Dummy.Requesting.StreetAddress',
  dummy_Requesting_City = 'Dummy.Requesting.City',
  dummy_Requesting_State = 'Dummy.Requesting.State',
  dummy_Requesting_Zip = 'Dummy.Requesting.Zip',
  dummy_Facility_Name = 'Dummy.Facility.Name',
  dummy_Facility_NPI = 'Dummy.Facility.NPI',
  dummy_Facility_StreetAddress = 'Dummy.Facility.StreetAddress',
  dummy_Facility_City = 'Dummy.Facility.City',
  dummy_Facility_State = 'Dummy.Facility.State',
  dummy_Facility_Zip = 'Dummy.Facility.Zip',
  dummy_Attending_Name = 'Dummy.Attending.Name',
  dummy_Attending_NPI = 'Dummy.Attending.NPI',
  dummy_Attending_StreetAddress = 'Dummy.Attending.StreetAddress',
  dummy_Attending_City = 'Dummy.Attending.City',
  dummy_Attending_State = 'Dummy.Attending.State',
  dummy_Attending_Zip = 'Dummy.Attending.Zip',
  dummy_Admitting_Name = 'Dummy.Admitting.Name',
  dummy_Admitting_NPI = 'Dummy.Admitting.NPI',
  dummy_Admitting_StreetAddress = 'Dummy.Admitting.StreetAddress',
  dummy_Admitting_City = 'Dummy.Admitting.City',
  dummy_Admitting_State = 'Dummy.Admitting.State',
  dummy_Admitting_Zip = 'Dummy.Admitting.Zip',
  provider_IsDummy = 'Provider.IsDummy',
}

export const getProviderRows = (
  useDefaultProviders: boolean,
  modelQuery: GetProviderSearchModelQuery | undefined,
  searchQuery: SearchProvidersQuery | undefined,
  authRole: string
) => {
  const rows = (
    useDefaultProviders
      ? modelQuery?.getProviderSearchDialogModel?.rows ?? []
      : searchQuery?.searchProvider?.rows ?? []
  ) as ProviderSearchResultRow[];
  const typeName = providerType(authRole);
  return rows.filter(
    (row: ProviderSearchResultRow) =>
      row.providerType === undefined ||
      (row.providerType?.name?.length ?? 0) === 0 ||
      row?.providerType?.name?.toLowerCase() === typeName
  );
};

export const mapProviders = (
  rows: ProviderSearchResultRow[],
  favorites: string[],
  recent: IRecentProvider[]
) => {
  return rows.map((row: ProviderSearchResultRow) => {
    const providerId = Number(row.id ?? 0);
    return {
      ...row,
      id: Number(row.id ?? 0),
      isFavorite: favorites.includes(row.providerAddressUiId ?? ''),
      isRecent: recent.some(
        ({ id }: IRecentProvider) => Number(id) === providerId
      ),
    } as IProvider;
  });
};

export const updateLinkedComponents = (
  provAttributes: KeyValuePairOfStringAndString[],
  dispatch: Dispatch,
  storageType: ChecklistStorageType,
  actionId: string | null | undefined
) => {
  const values = (provAttributes.map((attrib) => ({
    userDefinedId: attrib.key,
    value: attrib.value,
  })) ?? []) as { userDefinedId: string; value: string | null }[];

  dispatch(
    setComponentValuesByUserDefinedIds({
      storageType,
      values,
      actionId,
    })
  );
};

export const updateDummyProviderSameAsValues = (
  provAttributes: KeyValuePairOfStringAndString[],
  components: unknown,
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent,
    componentId?: string,
    fieldName?: string | null,
    itemUid?: string
  ) => void,
  providerSource: string,
  providerTargetType: string
) => {
  const values = (provAttributes.map((attrib) => ({
    userDefinedId: attrib.key,
    value: attrib.value,
  })) ?? []) as { userDefinedId: string; value: string | null }[];
  const item = components as IChecklistComponent;
  const dummyProviderSourceValue = {
    Name: '',
    NPI: '',
    StreetAddress: '',
    City: '',
    State: '',
    Zip: '',
  } as IDummyProvider;
  const isDummyProvider =
    values
      .find((val) => val.userDefinedId === providerKeyForCopy.provider_IsDummy)
      ?.value?.toLowerCase() === 'true';
  Object.values(item).forEach((component) => {
    if (isDummyProvider) {
      copyDummyProviderComponentValue(
        component,
        dummyProviderSourceValue,
        providerSource
      );
      copyDummyProviderSource(
        component,
        handleChecklistInputChange,
        dummyProviderSourceValue,
        providerTargetType
      );
    }
  });
  return dummyProviderSourceValue;
};

export const updateLinkedRadioButtons = (
  provAttributes: KeyValuePairOfStringAndString[],
  item: ChecklistItemOrderable,
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent
  ) => void
) => {
  const parValue = provAttributes.find((x) => x.key === '_PAR');
  if (parValue) {
    const parComp = item.lines
      .flatMap((x) => x.components)
      .find((x) =>
        x.userDefinedId?.includes('_PAR')
      ) as CheckboxRadioButtonComponent;
    if (parComp) {
      handleChecklistInputChange(true, parComp);
    }
  } else {
    const nonParValue = provAttributes.find((x) => x.key === '_NonPAR');
    if (nonParValue) {
      const nonParComp = item.lines
        .flatMap((x) => x.components)
        .find((x) =>
          x.userDefinedId?.includes('_NonPAR')
        ) as CheckboxRadioButtonComponent;
      if (nonParComp) {
        handleChecklistInputChange(true, nonParComp);
      }
    }
  }
};

export const updateProviderAuthRoleHiddenCheckbox = (
  provAttributes: KeyValuePairOfStringAndString[],
  item: ChecklistItemOrderable,
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent,
    componentId?: string
  ) => void
) => {
  const isDummyId = 'Provider.IsDummy';
  const isDummy =
    provAttributes.find((attrib) => attrib?.key === isDummyId)?.value ===
    'true';
  const checkboxRadioButtonComponents = item.lines
    .flatMap((x) => x.components)
    .filter(
      (x) => x.componentType === 'CheckboxRadioButtonComponent'
    ) as CheckboxRadioButtonComponent[];
  const hiddenCheckboxAuthRoleComponent = checkboxRadioButtonComponents.find(
    (x) => x.hidden && x.userDefinedId === isDummyId
  );
  if (hiddenCheckboxAuthRoleComponent) {
    handleChecklistInputChange(
      isDummy,
      hiddenCheckboxAuthRoleComponent,
      `${hiddenCheckboxAuthRoleComponent.id}-sel`
    );
  }
};
