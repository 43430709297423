import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetClaimsQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetClaimsQuery = {
  __typename?: 'EntitiesQuery';
  getClaims?: Array<{
    __typename?: 'PatientClinicHospital';
    id: number;
    createdOn?: any | null;
    updatedOn?: any | null;
    claimId?: string | null;
    claimDate?: any | null;
    serviceFrom?: any | null;
    serviceTo?: any | null;
    billingProviderName?: string | null;
    paidAmount?: any | null;
    performingProvider?: string | null;
    diagnosis?: {
      __typename?: 'MedicationCode';
      code?: string | null;
      shortDescription?: string | null;
    } | null;
    medProcedure?: {
      __typename?: 'MedicationCode';
      code?: string | null;
      shortDescription?: string | null;
    } | null;
  } | null> | null;
};

export const GetClaimsDocument = `
    query getClaims($patientId: Int!) {
  getClaims(patientId: $patientId) {
    id
    createdOn
    updatedOn
    claimId
    claimDate
    serviceFrom
    serviceTo
    billingProviderName
    paidAmount
    performingProvider
    diagnosis {
      code
      shortDescription
    }
    medProcedure {
      code
      shortDescription
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClaims: build.query<GetClaimsQuery, GetClaimsQueryVariables>({
      query: (variables) => ({ document: GetClaimsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetClaimsQuery, useLazyGetClaimsQuery } = injectedRtkApi;
