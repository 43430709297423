import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import ReminderReasonFormatter from '../formatters/ReminderReasonFormatter';

const ReminderReasonProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={ReminderReasonFormatter} {...props} />
);

export default ReminderReasonProvider;
