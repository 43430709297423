import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetLookupValuesQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
  includeSystemValues?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetLookupValuesQuery = {
  __typename?: 'EntitiesQuery';
  getLookupValues?: Array<{
    __typename?: 'LookupValue';
    id: number;
    image?: string | null;
    isDefault?: boolean | null;
    isDeleted?: boolean | null;
    name?: string | null;
    sortOrder?: number | null;
  }> | null;
};

export const GetLookupValuesDocument = `
    query getLookupValues($name: String!, $includeSystemValues: Boolean) {
  getLookupValues(name: $name, includeSystemValues: $includeSystemValues) {
    id
    image
    isDefault
    isDeleted
    name
    sortOrder
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLookupValues: build.query<
      GetLookupValuesQuery,
      GetLookupValuesQueryVariables
    >({
      query: (variables) => ({ document: GetLookupValuesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetLookupValuesQuery, useLazyGetLookupValuesQuery } =
  injectedRtkApi;
