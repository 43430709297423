import React from 'react';
import { COLORS } from 'consts/styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import styled from 'styled-components';

const StyledHighlighter = styled.div<{ isActive: boolean }>`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-bottom: 20px;
  height: 4px;
  width: 100%;
  border-top: ${(props) => (props.isActive ? ` 0` : `1px solid #d5d5d5`)};
  background-color: ${(props) =>
    props.isActive ? `  ${COLORS.SYMPHONY_BLUE}` : `white`};
`;

interface IPanelHighlighterProps {
  storageType: ChecklistStorageType;
  sectionIndex: number;
  isHighlightedDefault?: boolean;
}

const PanelHighlighter = (props: IPanelHighlighterProps) => {
  const { sectionIndex, storageType, isHighlightedDefault } = props;
  const activeSectionIndex = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.activeSectionIndex
  );

  return (
    <StyledHighlighter
      isActive={
        (sectionIndex === activeSectionIndex || isHighlightedDefault) ?? false
      }
    />
  );
};

export default PanelHighlighter;
