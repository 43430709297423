import React from 'react';
import { ILabelCellProps } from 'components/gridFormatters';
import { useTeamMemberCareSiteUser } from '../careTeam.hooks';
import { CareTeamLabelCell } from './CareTeamLabelCell';

export const RoleCell = (props: ILabelCellProps) => {
  const value = useTeamMemberCareSiteUser(props.row);

  return <CareTeamLabelCell {...props} value={value?.roleName} />;
};
