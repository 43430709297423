import React, { useEffect } from 'react';
import { useAmendChecklistMutation } from 'graphql/hooks/amendChecklist';
import Loader from 'components/loader';
import StyledLink from './StyledLink';
import { IChecklistLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { useDispatch } from 'react-redux';
import { setIsAmendingChecklist } from 'store/actions/checklistSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { useNavigate } from 'react-router';

interface IChecklistAmendLinkProps extends IChecklistLinkProps {
  label?: string;
  callback?: () => void;
}

const ChecklistAmendLink = (props: IChecklistAmendLinkProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [
    amendChecklist,
    {
      isLoading: isAmendingChecklist,
      isSuccess: isSuccessAmendChecklist,
      data: amendChecklistResult,
    },
  ] = useAmendChecklistMutation();

  useEffect(() => {
    if (isSuccessAmendChecklist) {
      if (props.callback) {
        props.callback();
      }
      dispatch(
        setIsAmendingChecklist({
          storageType: ChecklistStorageType.CHECKLIST,
          isAmendingChecklist: true,
        })
      );
      navigate(`/Checklist/Actions/${amendChecklistResult?.amendChecklist}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAmendChecklist]);

  const onAmendChecklist = (checklistId: number) => {
    amendChecklist({ checklistId: Number(checklistId) });
  };

  return (
    <>
      <CustomTooltip
        title={props.title ?? 'Reopens original document for changes'}
      >
        <StyledLink
          onClick={() => onAmendChecklist(props.checklistId)}
          data-cy="Amend"
        >
          Amend
        </StyledLink>
      </CustomTooltip>
      <Loader active={isAmendingChecklist} />
    </>
  );
};

export default ChecklistAmendLink;
