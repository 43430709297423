import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LettersViewType = 'letters' | 'fax';

export interface IHeaderState {
  search: string;
  removeFilterChips: boolean;
}

export const initialHeaderState: IHeaderState = {
  search: '',
  removeFilterChips: false,
};

const headerSlice = createSlice({
  name: 'headerState',
  initialState: initialHeaderState,
  reducers: {
    clearSearch: (state) => {
      state.search = '';
      state.removeFilterChips = true;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setRemoveFilterChips: (state, action: PayloadAction<boolean>) => {
      state.removeFilterChips = action.payload;
    },
  },
});

export const { clearSearch, setSearch, setRemoveFilterChips } =
  headerSlice.actions;

export default headerSlice.reducer;
