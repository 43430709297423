import { Autocomplete, IconButton } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

export const StyledAutocomplete = styled(Autocomplete)({
  width: '440px',
  height: '32px',
  backgroundColor: COLORS.WHITE,
  input: {
    padding: '0!important',
  },
  paper: {
    border: '1px solid #CECECE',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  },
  '.MuiAutocomplete-inputRoot': {
    paddingLeft: '12px!important',
    paddingRight: '12px!important',
  },
  option: {
    backgroundColor: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&:active': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&[aria-selected="true"]': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&[data-focus="true"]': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
  },
});

export const StyledIconButton = styled(IconButton)`
  padding: 4px;
`;
