import React, { useCallback, useState } from 'react';
import AttachmentsGrid from 'components/home/attachmentsList/attachmentsGrid';
import { IState } from 'store';
import { useSelector } from 'react-redux';
import { useData } from 'components/gridFormatters';
import { commitAttachments } from 'store/patientdetails/patientDetails.attachment.slice';
import {
  GetPatientAttachmentsQuery,
  useLazyGetPatientAttachmentsQuery,
} from 'graphql/hooks/getPatientAttachments';
import { Attachment } from 'graphql/graphqlTypes';
import { Grid } from '@mui/material';
import { COLORS } from 'consts/styles';
import AttachmentMoveSidebar from 'components/home/patientdetails/tabscontent/attachmentsTab/components/attachmentMoveSidebar';
import styled from 'styled-components';
import Snackbar from 'components/snackbar';
import { ICONS } from 'components/icon';

export interface IMovedAttachmentDetails {
  attachmentTitle?: string;
  attachmentId: number;
  targetEpisodeId?: number;
  targetPatientId?: number;
  targetEpisodeNumber?: string;
  targetMemberId?: string;
}

const StyledWrapper = styled.div`
  display: flex;
  flex: 1 1 0;
  height: 100%;
  position: relative;
`;

const AttachmentsTab = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [movedAttachmentDetails, setMovedAttachmentDetails] =
    useState<IMovedAttachmentDetails>({} as IMovedAttachmentDetails);
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const selectedAttachmentToMove = useSelector(
    (state: IState) => state.patientDetailsAttachment.moveAttachmentId
  );
  const { data: attachmentData, isFetching } = useData(
    (state: IState) => state.patientDetailsAttachment?.attachmentsTab,
    useLazyGetPatientAttachmentsQuery,
    { patientId },
    (data: GetPatientAttachmentsQuery) =>
      commitAttachments({
        patientId,
        episodeId: undefined,
        changeSet: { added: data.getPatientAttachments as Attachment[] },
      }),
    !patientId
  );

  const handleToggleSnackBar = useCallback((shouldOpenSnackBar?: boolean) => {
    if (typeof shouldOpenSnackBar === 'boolean' && shouldOpenSnackBar) {
      setSnackbarOpen(true);
      return;
    }

    setSnackbarOpen(false);
    setMovedAttachmentDetails({} as IMovedAttachmentDetails);
  }, []);

  const handleUpdateMovedAttachment = useCallback(
    (attachmentDetails: IMovedAttachmentDetails) => {
      setMovedAttachmentDetails(attachmentDetails);
    },
    []
  );

  const attachmentType = movedAttachmentDetails.targetEpisodeId
    ? 'Episode'
    : 'Member';

  const attachmentMessage = `${
    movedAttachmentDetails?.attachmentTitle
  } attached to ${attachmentType} ${
    movedAttachmentDetails.targetEpisodeId
      ? movedAttachmentDetails.targetEpisodeNumber
      : movedAttachmentDetails.targetMemberId
  }`;

  const isFullWidth = selectedAttachmentToMove === 0;

  return (
    <StyledWrapper
      data-cy="attachments-tab-content"
      data-testid="attachments-tab"
    >
      <Grid container spacing={3} style={{ marginTop: 0 }}>
        <Grid
          item
          xs={isFullWidth ? 12 : 6}
          style={{
            borderRight: isFullWidth ? '0' : `1px solid ${COLORS.GREY25}`,
            paddingRight: isFullWidth ? 0 : '24px',
          }}
        >
          <AttachmentsGrid
            attachments={attachmentData ?? []}
            isFetching={isFetching}
            canAddFiles={true}
            isAttachmentsTab
          />
        </Grid>
        {selectedAttachmentToMove > 0 && (
          <Grid item xs={6} hidden={isFullWidth}>
            <Grid container spacing={3} style={{ marginTop: 0 }}>
              <AttachmentMoveSidebar
                attachmentData={attachmentData ?? []}
                handleUpdateMovedAttachmentDetails={handleUpdateMovedAttachment}
                handleToggleSnackBar={handleToggleSnackBar}
              />
            </Grid>
          </Grid>
        )}
        <Snackbar
          open={snackbarOpen}
          duration={4000}
          title="Success"
          icon={ICONS.Stoplight_completed}
          type="success"
          text={attachmentMessage}
          isSnackbarInsideContainer
          onClose={handleToggleSnackBar}
        />
      </Grid>
    </StyledWrapper>
  );
};

export default AttachmentsTab;
