import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Link, Typography } from '@mui/material';
import { useDropzone, FileRejection, Accept } from 'react-dropzone';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import Loader from 'components/loader';
import Icon, { ICONS } from 'components/icon';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { useGetAllowedFileTypesAndSizeQuery } from 'graphql/hooks/getAllowedFileTypesAndSize';
import { AllowedFileTypesAndSizeModel } from 'graphql/graphqlTypes';
import { setUploadedFiles } from 'store/fileUpload/fileUploadSlice';
import FileUploadItem from './fileUploadItem';
import { formatBytes } from './fileUpload.helper';
import { IState } from 'store';
import { IUploadedFile } from 'store/fileUpload/types';

let currentId = 0;
const getNewId = () => {
  return ++currentId;
};

const StyledWrapper = styled.div`
  border: 1px dashed ${COLORS.GREY25};
  border-radius: 4px;
  padding: 8px;
`;

const StyledDropzone = styled.div<{ $dragActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: ${COLORS.GREY60};
  ${({ $dragActive }) =>
    $dragActive &&
    `background-color: ${COLORS.BLUE10};
  `}
  &:hover {
    background-color: ${COLORS.BLUE10};
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)({
  marginLeft: '5px',
  marginRight: '5px',
  color: COLORS.SYMPHONY_BLUE,
  fontSize: '14px',
  cursor: 'pointer',
});

const StyledTypography = styled(Typography)({
  paddingTop: '10px',
  display: 'inline',
});

const InfoIcon = styled(Icon)({
  paddingLeft: '4px',
  marginTop: '-5px',
});

const StyledGrid = styled(Grid)({
  background: COLORS.GREY4,
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderRow = styled(Grid)({
  backgroundColor: COLORS.GREY10,
});

const HeaderText = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  padding: '10px 6px 5px 6px !important',
  color: COLORS.GREY100,
  backgroundColor: COLORS.GREY10,
});

export interface FileUploadProps {
  allowMultiple?: boolean;
  accept?: Accept;
}

export const updateFileWrapper = (
  files: IUploadedFile[],
  fileToUpdate: File,
  newFileName: string,
  categoryId?: number
) => {
  return files.map((fileWrapper) => {
    if (fileWrapper.file === fileToUpdate) {
      const { extension } = fileWrapper;
      const updatedFileName = `${newFileName}${extension}`;
      const updatedFile = new File([fileToUpdate], updatedFileName, {
        type: fileToUpdate.type,
      });
      return {
        ...fileWrapper,
        file: updatedFile,
        ...(categoryId !== undefined && { categoryId }),
      };
    }
    return fileWrapper;
  });
};

const FileUpload = (props: FileUploadProps) => {
  const { allowMultiple, accept } = props;

  const handleUpdateFileWrapper = (
    fileToUpdate: File,
    newFileName: string,
    categoryId?: number
  ) => {
    const updatedFiles = updateFileWrapper(
      files,
      fileToUpdate,
      newFileName,
      categoryId
    );
    setFiles(updatedFiles);
  };

  const updateFileName = (fileToUpdate: File, newName: string) => {
    handleUpdateFileWrapper(fileToUpdate, newName);
  };

  const updateCategory = (
    fileToUpdate: File,
    newFileName: string,
    categoryId: number
  ) => {
    handleUpdateFileWrapper(fileToUpdate, newFileName, categoryId);
  };

  const dispatch = useDispatch();

  const uploadedFilesInState = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  );
  const [files, setFiles] = useState<IUploadedFile[]>(uploadedFilesInState);
  const { data, isFetching } = useGetAllowedFileTypesAndSizeQuery();
  const allowedFileTypesAndSize =
    data?.getAllowedFileTypesAndSize as AllowedFileTypesAndSizeModel;
  const fileSizeLimitInBytes = allowedFileTypesAndSize?.fileSizeLimitInBytes;
  const allowedFileSize = formatBytes(fileSizeLimitInBytes);
  const allowedFileTypes = accept
    ? Object.values(accept)
        .flatMap((x) => x)
        .map((x) => x.replace('.', ''))
    : allowedFileTypesAndSize?.fileTypes;
  const [dragActive, setDragActive] = useState<boolean>(false);

  useEffect(() => {
    const uploadedFiles = files
      .filter((i) => i.fileId)
      .map((x) => {
        const name = x.name || '';
        return {
          id: x.id,
          name: name,
          file: x.file,
          fileId: x.fileId!,
          createdOn: x.createdOn,
          errors: x.errors,
          categoryId: x.categoryId,
        } as IUploadedFile;
      });
    dispatch(setUploadedFiles({ uploadedFiles }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const mappedAcceptedFiles = acceptedFiles.map((file) => {
        const fileNameParts = file.name.split('.');
        const extension = `.${fileNameParts.pop()}`;
        const name = fileNameParts.join('.');

        return {
          id: getNewId(),
          file,
          name,
          extension,
          error: [],
        };
      });
      const mappedRejectedFiles = rejectedFiles.map((file) => ({
        ...file,
        id: getNewId(),
      }));
      setFiles((current) => [
        ...current,
        ...mappedAcceptedFiles,
        ...mappedRejectedFiles,
      ]);
      setDragActive(false);
    },
    []
  );

  const onDragEnter = useCallback(() => {
    setDragActive(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDragActive(false);
  }, []);

  const onUpload = useCallback(
    (file: File, fileId: string, name?: string, createdOn?: Date) => {
      setFiles((current) =>
        current.map((x) => {
          return x.file === file
            ? {
                ...x,
                fileId,
                name,
                createdOn,
              }
            : x;
        })
      );
    },
    [setFiles]
  );

  const onDelete = useCallback(
    (file: File) => {
      setFiles((current) => current.filter((x) => x.file !== file));
    },
    [setFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: allowMultiple,
    accept: accept,
    maxSize: fileSizeLimitInBytes,
    onDrop,
    onDragEnter,
    onDragLeave,
  });

  return (
    <>
      <StyledWrapper>
        <StyledDropzone {...getRootProps()} $dragActive={dragActive}>
          <input data-testid="drop-input" {...getInputProps()} />
          <Icon
            size={36}
            icon={ICONS.Upload}
            style={{ paddingBottom: '5px' }}
          />
          <Box>
            <StyledTypography variant="body1">
              Drag and drop or
            </StyledTypography>
            <StyledLink>browse</StyledLink>
          </Box>
          <StyledBox>
            <Typography variant="h6">
              File sizes less than {allowedFileSize}
            </Typography>
            {allowedFileTypes && (
              <CustomTooltip
                title={`Supported file types: ${allowedFileTypes}`}
              >
                <div>
                  <InfoIcon
                    icon={ICONS.Info}
                    size={14}
                    color={COLORS.GREY100}
                    hoverColor={COLORS.SYMPHONY_BLUE}
                  />
                </div>
              </CustomTooltip>
            )}
          </StyledBox>
        </StyledDropzone>
      </StyledWrapper>
      <Grid container>
        <HeaderRow container>
          <Grid item xs={1}>
            <HeaderText>Type</HeaderText>
          </Grid>
          <Grid item xs={4}>
            <HeaderText>Name</HeaderText>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <HeaderText>Category</HeaderText>
          </Grid>
          <Grid item xs={2}>
            <HeaderText>File Size</HeaderText>
          </Grid>
          <Grid item xs={1}>
            <HeaderText>Actions</HeaderText>
          </Grid>
        </HeaderRow>
        {files.map((fileWrapper, index) => (
          <StyledGrid container key={fileWrapper.id}>
            <FileUploadItem
              file={fileWrapper.file}
              name={fileWrapper.name}
              extension={fileWrapper.extension}
              errors={fileWrapper.errors}
              onDelete={onDelete}
              onUpload={onUpload}
              index={index}
              updateFileName={updateFileName}
              updateCategory={updateCategory}
            />
          </StyledGrid>
        ))}
      </Grid>
      <Loader active={isFetching} />
    </>
  );
};

export default FileUpload;
