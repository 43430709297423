import {
  ChecklistViewModel,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import React, { useEffect, useState } from 'react';
import { ChecklistStorageType } from 'store/actions/types';
import { MozartStep } from 'features/mozart/components/MozartStep/MozartStep';
import ReviewSections from 'components/review/sections';
import { StyledSectionWrapper } from './MozartShowMessage';
import { useCreateAndViewWorkflowChecklistMutation } from 'graphql/hooks/createAndViewWorkflowChecklist';
import { useGetChecklistModelQuery } from 'graphql/hooks/getChecklist';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompletedChecklists } from 'store/actions/mozartSlice';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { IState } from 'store';

export const ShowModuleMessage = ({
  message,
}: {
  message: MozartWorkflowUiModel;
}) => {
  const [checklistId, setChecklistId] = useState('');
  const [createChecklist] = useCreateAndViewWorkflowChecklistMutation();
  const dispatch = useDispatch();
  const completedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );
  const scenarioId = useSelector((state: IState) => state.mozart.scenarioId);
  const { data: checklistData, isSuccess: isGetSuccessChecklist } =
    useGetChecklistModelQuery(
      {
        checklistId: message.checkListId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !message.checkListId,
      }
    );

  useEffect(() => {
    if (checklistId === '' && !message.checkListId) {
      createChecklist({
        id: message.workflowInstanceId,
        actionId: message.actionInstanceId,
        scenarioId: scenarioId,
      }).then((result) => {
        if ('data' in result) {
          const msg = result.data?.createAndViewWorkflowChecklist?.message;
          const viewChecklist = result.data?.createAndViewWorkflowChecklist
            ?.checklist as ChecklistViewModel;
          if (msg) {
            dispatch(
              showErrorPopup({
                message: msg,
                title: 'Failed to start Workflow',
              })
            );
          }
          if (viewChecklist) {
            const checklists = Object.values(completedChecklists);
            setChecklistId(viewChecklist.id);
            dispatch(
              updateCompletedChecklists({
                checklists: [...checklists, viewChecklist],
              })
            );
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isGetSuccessChecklist && checklistData?.getChecklist) {
      const checklists = Object.values(completedChecklists).map((item) => item);
      dispatch(
        updateCompletedChecklists({
          checklists: [
            ...checklists,
            checklistData?.getChecklist as ChecklistViewModel,
          ],
        })
      );
      setChecklistId(checklistData?.getChecklist.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetSuccessChecklist]);

  if (checklistId !== '') {
    return (
      <StyledSectionWrapper>
        <MozartStep
          currentStep={message.leftHead as string}
          message={message}
        />
        <ReviewSections
          storageType={ChecklistStorageType.MOZART}
          checklist={completedChecklists[checklistId.toString()]}
        />
      </StyledSectionWrapper>
    );
  }
  return null;
};
