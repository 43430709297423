import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LettersViewType = 'letters' | 'fax';

export interface ILettersState {
  open: boolean;
  view: LettersViewType;
  patientId: number;
  episodeId: number;
  checklistId: number;
  attachmentId?: number;
}

export const initialLettersState: ILettersState = {
  open: false,
  view: 'letters',
  patientId: 0,
  episodeId: 0,
  checklistId: 0,
};

const lettersSlice = createSlice({
  name: 'lettersModalState',
  initialState: initialLettersState,
  reducers: {
    openLetters: (
      state,
      {
        payload: { patientId, episodeId },
      }: PayloadAction<{
        patientId: number;
        episodeId: number;
      }>
    ) => {
      state.open = true;
      state.view = 'letters';
      state.episodeId = episodeId;
      state.patientId = patientId;
    },
    openAttachmentFax: (
      state,
      {
        payload: { patientId, episodeId, attachmentId },
      }: PayloadAction<{
        patientId: number;
        episodeId: number;
        attachmentId: number;
      }>
    ) => {
      state.open = true;
      state.view = 'fax';
      state.episodeId = episodeId;
      state.patientId = patientId;
      state.attachmentId = attachmentId;
    },
    openChecklistFax: (
      state,
      {
        payload: { patientId, episodeId, checklistId },
      }: PayloadAction<{
        patientId: number;
        episodeId: number;
        checklistId: number;
      }>
    ) => {
      state.open = true;
      state.view = 'fax';
      state.episodeId = episodeId;
      state.patientId = patientId;
      state.checklistId = checklistId;
    },
    close: (state) => {
      state.open = false;
      state.episodeId = 0;
      state.patientId = 0;
      state.checklistId = 0;
      state.attachmentId = undefined;
    },
  },
});

export const { openAttachmentFax, openChecklistFax, openLetters, close } =
  lettersSlice.actions;

export default lettersSlice.reducer;
