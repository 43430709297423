import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { resetChecklist } from 'store/actions/checklistSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { ROUTE } from 'consts/ROUTE';
import { useDiscardChecklistMutation } from 'graphql/hooks/discardChecklist';
import { useFinishChecklistLaterMutation } from 'graphql/hooks/finishChecklistLater';
import ReviewFooter from 'components/footer/ReviewFooter';
import ChecklistFooter from 'components/footer/ChecklistFooter';
import FinishFooter from 'components/footer/FinishFooter';
import { clearFailedRequests } from 'store/failedRequests/failedRequestsSlice';
import Confirmation from 'components/modal/Confirmation';
import { Typography } from '@mui/material';
import { useDirtyConfirmation } from 'hooks/useDirtyConfirmationModal';
import { DirtyConfirmationModal } from 'components/modal/DirtyConfirmationModal';

export interface IFooter {
  patientId?: string;
  checklistId?: string;
  amendIsAvailable?: boolean;
  variant?: string;
  storageType: ChecklistStorageType;
}

const Footer = ({ amendIsAvailable, variant, storageType }: IFooter) => {
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const patientId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].patientId
  );
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );
  const lettersIsAvailable = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist
        ?.lettersIsAvailableForUser
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [discardChecklist, { isLoading: isUpdatingChecklist }] =
    useDiscardChecklistMutation();

  const [finishChecklistLater, { isLoading: isFinishingLaterChecklist }] =
    useFinishChecklistLaterMutation();

  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const {
    handleDirtyConfirm,
    clearDirtyState,
    handleConfirm,
    handleCancel,
    showModal,
  } = useDirtyConfirmation();
  const isDirty = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary.isDirty
  );
  const sectionName = 'Episode Summary';

  const handleDiscard = () => {
    dispatch(resetChecklist(storageType));
    discardChecklist({
      checklistId: Number(checklistId),
      connectionId: sessionId,
    }).then(() => {
      navigate(ROUTE.Home);
    });
    dispatch(clearFailedRequests());
  };

  const handleFinishLater = () => {
    handleDirtyConfirm(isDirty, onConfirmFinishLater);
  };

  const onConfirmFinishLater = () => {
    clearDirtyState();
    dispatch(resetChecklist(storageType));
    finishChecklistLater({
      checklistId: Number(checklistId),
      connectionId: sessionId,
      entryStep: '1',
    }).then(() => {
      navigate(ROUTE.Home);
    });
  };

  const handleDiscardConfirmationModal = () => {
    handleDirtyConfirm(isDirty, onConfirmDiscard);
  };

  const onConfirmDiscard = () => {
    clearDirtyState();
    setShowDiscardConfirmation(true);
  };

  return (
    <>
      {variant === 'action' ? (
        <ChecklistFooter
          isLoading={isUpdatingChecklist || isFinishingLaterChecklist}
          handleFinishLater={handleFinishLater}
          storageType={storageType}
          handleDiscardConfirmation={handleDiscardConfirmationModal}
        />
      ) : null}
      {variant === 'finish' ? (
        <FinishFooter
          isAmendAvailable={amendIsAvailable}
          storageType={storageType}
          isLettersAvailable={lettersIsAvailable}
          patientId={patientId?.toString()}
        />
      ) : null}
      {variant === 'review' ? (
        <ReviewFooter
          handleFinishLater={handleFinishLater}
          patientId={patientId?.toString()}
          storageType={storageType}
          handleDiscardConfirmation={handleDiscardConfirmationModal}
        />
      ) : null}
      <Confirmation
        open={showDiscardConfirmation}
        title="Confirm Discard"
        okButtonText="Discard"
        okEvent={() => {
          handleDiscard();
          setShowDiscardConfirmation(false);
        }}
        cancelEvent={() => setShowDiscardConfirmation(false)}
        disableBackdropClick
      >
        <Typography>Are you sure you want to discard this Episode?</Typography>
      </Confirmation>
      <DirtyConfirmationModal
        showModal={showModal}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        sectionName={sectionName}
      />
    </>
  );
};
export default Footer;
