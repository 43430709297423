import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';

export const CareSiteName = () => {
  const careSite = useSelector(
    (state: IState) => state.user.currentUser.careSite
  );
  return <span>{careSite}</span>;
};
