import React from 'react';
import FilterButton from './filterButton';
import { Typography } from '@mui/material';
import { ICONS } from 'components/icon';
import { TimelineState } from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

interface IFilterBarProps {
  states: Array<TimelineState>;
  onUpdateModel: (states: TimelineState) => void;
}

const StyledFilterButton = styled(FilterButton)`
  margin: 5px 5px;
`;
const StyledTypography = styled(Typography)`
  margin: 5px 5px;
  color: ${COLORS.BLACK};
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
  align-items: center;
`;

const FilterBar = (props: IFilterBarProps) => {
  const { states, onUpdateModel } = props;

  return (
    <StyledButtonsWrapper data-cy="filter-bar">
      <StyledTypography>Show:</StyledTypography>
      <StyledFilterButton
        data-cy="filter-completed"
        icon={ICONS.ProgressCompleted}
        title="Completed documents"
        isActive={states.includes(TimelineState.DocumentCompleted)}
        onClick={() => onUpdateModel(TimelineState.DocumentCompleted)}
      />
      <StyledFilterButton
        data-cy="filter-incomplete"
        icon={ICONS.InProgress}
        title="Documents in progress"
        isActive={states.includes(TimelineState.DocumentInProgress)}
        onClick={() => onUpdateModel(TimelineState.DocumentInProgress)}
      />
      <StyledFilterButton
        data-cy="filter-attachments"
        icon={ICONS.AttachmentClip}
        title="Attachments"
        isActive={states.includes(TimelineState.HasAttachment)}
        onClick={() => onUpdateModel(TimelineState.HasAttachment)}
      />
      <StyledFilterButton
        data-cy="filter-letter"
        icon={ICONS.TimelineLetter}
        title="Letters"
        isActive={states.includes(TimelineState.HasLetter)}
        onClick={() => onUpdateModel(TimelineState.HasLetter)}
      />
      <StyledFilterButton
        data-cy="filter-open-reminders"
        icon={ICONS.Bell}
        title="Open reminders"
        isActive={states.includes(TimelineState.ReminderOpen)}
        onClick={() => onUpdateModel(TimelineState.ReminderOpen)}
      />
      <StyledFilterButton
        data-cy="filter-close-reminders"
        icon={ICONS.ClosedReminder}
        title="Closed reminders"
        isActive={states.includes(TimelineState.ReminderClosed)}
        onClick={() => onUpdateModel(TimelineState.ReminderClosed)}
      />
      <StyledFilterButton
        data-cy="filter-fax"
        icon={ICONS.FaxOutline}
        title="Faxes"
        isActive={states.includes(TimelineState.HasFax)}
        onClick={() => onUpdateModel(TimelineState.HasFax)}
      />
      <StyledFilterButton
        data-cy="filter-CM"
        icon={ICONS.CM}
        title="Care Management"
        isActive={states.includes(TimelineState.Cm)}
        onClick={() => onUpdateModel(TimelineState.Cm)}
      />
      <StyledFilterButton
        data-cy="filter-UM"
        icon={ICONS.UM}
        title="Utilization Management"
        isActive={states.includes(TimelineState.Um)}
        onClick={() => onUpdateModel(TimelineState.Um)}
      />
      <StyledFilterButton
        data-cy="filter-Education/Healthwise"
        icon={ICONS.Educate}
        title="Education/Healthwise"
        isActive={states.includes(TimelineState.HasEducation)}
        onClick={() => onUpdateModel(TimelineState.HasEducation)}
      />
    </StyledButtonsWrapper>
  );
};

export default FilterBar;
