import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import styled from 'styled-components';
import { Episode } from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import Icon, { ICONS } from 'components/icon';

const StyledMenuItem = styled(MenuItem)({
  padding: '6px 20px',
  display: 'block',
  width: '100%',
  maxWidth: '440px',
  '&.Mui-selected': {
    backgroundColor: COLORS.WHITE,
  },
});

const StyledListItemContainer = styled.ul`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexBox = styled.div`
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 98%;
`;

const StyledTypography = styled(Typography)({
  margin: '0 3px',
});

export interface IEpisodeOption {
  value: Episode;
}

export const EpisodeOption = ({ value }: IEpisodeOption) => {
  return (
    <StyledListItemContainer>
      <StyledMenuItem>
        <FlexBox>
          <StyledTypography variant="body2">
            {value.episodeNumber}
          </StyledTypography>
          <Icon
            icon={ICONS.Critical}
            size={10}
            height={'15'}
            color={COLORS.GREY100}
          />
          <StyledTypography variant="body1">
            {formatDate(value.createdOn)}
          </StyledTypography>
        </FlexBox>
      </StyledMenuItem>
    </StyledListItemContainer>
  );
};
