import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { EditingCell } from 'components/gridFormatters/index';
import React, { useState } from 'react';
import { validateRequired } from 'util/validationUtils';

export const GridEditCell = (
  props: TableEditRow.CellProps & { autoFocus?: boolean }
) => {
  const { value, onValueChange, autoFocus } = props;
  const [error, setError] = useState<boolean>(
    validateRequired(value, true).hasError
  );
  const onChange = (newValue: string) => {
    const isValid = validateRequired(newValue, true);
    setError(isValid.hasError);
    onValueChange(newValue);
  };

  return (
    <EditingCell
      {...props}
      onValueChange={onChange}
      fullwidth={true}
      error={error}
      autoFocus={autoFocus}
    />
  );
};
