import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

export const CareTeamLabelCell = (props: ILabelCellProps) => (
  <Table.Cell {...props} style={CellComponentStyle}>
    <Box display="flex" alignItems="center">
      <CustomTooltip title={props.value ?? ''}>
        <Box
          display="inline"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography noWrap>{props.value}</Typography>
        </Box>
      </CustomTooltip>
    </Box>
  </Table.Cell>
);
