import React from 'react';
import { BaseReminder } from 'graphql/graphqlTypes';
import { Box, Typography } from '@mui/material';
import { RemindersGrid } from 'components/home/RemindersGrid';

export interface IReminders {
  completedReminders: BaseReminder[];
  expiredReminders: BaseReminder[];
  deletedReminders: BaseReminder[];
}

interface IClosedRemindersTabProps {
  reminders: IReminders;
}

const ClosedRemindersTab = ({ reminders }: IClosedRemindersTabProps) => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="16px"
      >
        <Typography variant="subtitle1">Completed Reminders</Typography>
      </Box>
      <Box>
        <RemindersGrid
          reminders={reminders.completedReminders}
          readonly
          showEpisodeIdColumn
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="16px"
        pt="30px"
      >
        <Typography variant="subtitle1">Expired Reminders</Typography>
      </Box>
      <Box>
        <RemindersGrid
          reminders={reminders.expiredReminders}
          readonly
          showEpisodeIdColumn
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="16px"
        pt="30px"
      >
        <Typography variant="subtitle1">Deleted Reminders</Typography>
      </Box>
      <Box>
        <RemindersGrid
          reminders={reminders.deletedReminders}
          readonly
          showEpisodeIdColumn
        />
      </Box>
    </Box>
  );
};
export default ClosedRemindersTab;
