import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type DownloadFileQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  name: Types.Scalars['String'];
}>;

export type DownloadFileQuery = {
  __typename?: 'EntitiesQuery';
  downloadFile: {
    __typename?: 'DownloadFileDto';
    mimeType?: string | null;
    content?: string | null;
    fileName?: string | null;
  };
};

export const DownloadFileDocument = `
    query downloadFile($id: Int!, $name: String!) {
  downloadFile(id: $id, name: $name) {
    mimeType
    content
    fileName
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    downloadFile: build.query<DownloadFileQuery, DownloadFileQueryVariables>({
      query: (variables) => ({ document: DownloadFileDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDownloadFileQuery, useLazyDownloadFileQuery } =
  injectedRtkApi;
