import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import React from 'react';
import styled from 'styled-components';

const StyledTableHeaderRowComponent = styled(TableHeaderRow.Row)({
  backgroundColor: COLORS.GREY10,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableHeaderRowComponent = (props: any) => (
  <StyledTableHeaderRowComponent {...props}>
    {props.children}
  </StyledTableHeaderRowComponent>
);
