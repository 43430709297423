import React from 'react';
import { TableCell, Typography } from '@mui/material';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { COLORS } from 'consts/styles';
import { patientUseText } from '../consts';
import { PatientMedicationPatientUse } from 'graphql/graphqlTypes';

export const UseColumn = (props: ILabelCellProps) => {
  const { value } = props;
  return (
    <TableCell style={CellComponentStyle}>
      <Typography
        style={{
          color:
            value !== PatientMedicationPatientUse.TakesasPrescribed
              ? COLORS.RED100
              : '',
        }}
      >
        {patientUseText[value as PatientMedicationPatientUse]}
      </Typography>
    </TableCell>
  );
};
