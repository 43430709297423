import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMozartReducer, IWorkflowViewModel } from 'store/actions/types';
import {
  ChecklistItem,
  ChecklistItemHealthServices,
  ChecklistViewModel,
  MozartActionType,
  MozartProgressState,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import { isWorkflowInErrorState } from 'features/mozart/MozartWorkflowLayout.helpers';
import {
  findHsConditionItem,
  findHsConditionItemCategory,
  findHsConditionItemIndex,
  getHsConditionItemDecision,
} from './actionsSlice.helpers';
import { DeterminationDecision } from 'components/actions/sections/SectionBody/Items/HealthService/types';

export const initialMozartState: IMozartReducer = {
  workflowChecklistId: -1,
  mozartDefinitionId: null,
  mozartInstanceId: null,
  mozartWorkflow: null,
  mozartMessages: [],
  startPolling: false,
  startOotPolling: false,
  isUserAuthorizedMozartNextStep: true,
  isWorkflowPaused: false,
  completedChecklists: {},
  completedChecklistsIds: [],
  scenarioId: 0,
  mozartWorkflowInstanceState: null,
  duplicateCheckLoading: false,
  mozartViewOnly: false,
  isReadOnlyWorkflow: false,
  canContinueWorkflow: true,
  pollingTimeout: 120,
  authorizationStatus: null,
  restartMozart: false,
};

const mozartSlice = createSlice({
  name: 'mozart',
  initialState: initialMozartState,
  reducers: {
    setWorkflowChecklistId(state, action: PayloadAction<number>) {
      state.workflowChecklistId = action.payload;
    },
    resetMozartState() {
      return initialMozartState;
    },
    addMozartMessage(
      state,
      action: PayloadAction<{
        mozartMessages: MozartWorkflowUiModel[];
      }>
    ) {
      const messages = state.mozartMessages;
      const existingUniqueMsgIds = messages.map((x) => x.uniqueMsgId);
      const incomingMessages = action.payload.mozartMessages.filter(
        (x) => !existingUniqueMsgIds.includes(x.uniqueMsgId)
      );
      const incomingScopeIds = incomingMessages.map((x) => x.scopeId);
      state.mozartMessages = [
        ...messages.filter((x) => !incomingScopeIds.includes(x.scopeId)),
        ...incomingMessages,
      ];

      const instanceId = action.payload.mozartMessages.find(
        (x) => !!x.workflowInstanceId
      )?.workflowInstanceId;

      if (instanceId && state.mozartInstanceId !== instanceId) {
        state.mozartInstanceId = instanceId;
      }

      state.completedChecklistsIds = state.mozartMessages
        .filter(
          (item) =>
            (item?.state === MozartProgressState.Completed ||
              item?.state === MozartProgressState.UserInputComplete) &&
            item?.checkListId !== 0
        )
        .map((item) => item?.checkListId.toString());
    },
    mozartResetChecklist(state) {
      state.workflowChecklistId = -1;
      state.mozartMessages = state.mozartMessages.filter(
        (x) =>
          !(
            x.actionType === MozartActionType.UserInput &&
            x.state === MozartProgressState.Paused
          ) && !isWorkflowInErrorState(x)
      );
    },
    mozartStopPolling(state) {
      state.startPolling = false;
    },
    mozartStartPolling(state) {
      state.startPolling = true;
    },
    mozartStartOotPolling(state) {
      state.startOotPolling = true;
    },
    mozartStopOotPolling(state) {
      state.startOotPolling = false;
    },
    updateCompletedChecklists(
      state,
      action: PayloadAction<{ checklists: ChecklistViewModel[] }>
    ) {
      action.payload.checklists.forEach((checklist) => {
        state.completedChecklists[checklist.id] = checklist;
      });
    },
    updateCompletedChecklistsIsVisible(
      state,
      action: PayloadAction<{
        items: ChecklistItemHealthServices;
        checklistId?: string;
        decision: DeterminationDecision;
      }>
    ) {
      const { checklistId, items, decision } = action.payload;
      const checklist = state.completedChecklists[Number(checklistId)];
      items.conditions.forEach((condition) => {
        const lineItem = findHsConditionItem(condition.orderableId, checklist);
        if (lineItem) {
          const category = findHsConditionItemCategory(lineItem.uid, checklist);
          if (category) {
            const ind = findHsConditionItemIndex(lineItem.uid, category);
            const newItem: ChecklistItem = {
              ...lineItem,
            } as ChecklistItem;
            newItem.isVisible = getHsConditionItemDecision(
              decision,
              condition.value as string
            );
            category.items[ind] = newItem;
          }
        }
      });
    },
    mozartUserAuthorizedNextStep(state, action: PayloadAction<boolean>) {
      state.isUserAuthorizedMozartNextStep = action.payload;
    },
    mozartWorkflowPaused(state, action: PayloadAction<boolean>) {
      state.isWorkflowPaused = action.payload;
    },
    setMozartInstanceState(state, action: PayloadAction<string>) {
      state.mozartWorkflowInstanceState = action.payload;
    },

    setMozartCanContinueWorkflow(state, action: PayloadAction<boolean>) {
      state.canContinueWorkflow = action.payload;
    },

    mozartUpdateWorkflow(
      state,
      action: PayloadAction<{ workflow: IWorkflowViewModel }>
    ) {
      state.mozartWorkflow = action.payload.workflow;
    },
    setMozartGlobalParams(
      state,
      action: PayloadAction<{
        mozartDefinitionId?: string;
        mozartInstanceId?: string;
        scenarioId?: number;
        pollingTimeout?: number;
        restartMozart?: boolean;
      }>
    ) {
      if (action.payload.mozartDefinitionId) {
        state.mozartDefinitionId = action.payload.mozartDefinitionId;
      }
      if (action.payload.mozartInstanceId) {
        state.mozartInstanceId = action.payload.mozartInstanceId;
      }
      if (action.payload.scenarioId) {
        state.scenarioId = action.payload.scenarioId;
      }
      if (action.payload.pollingTimeout) {
        state.pollingTimeout = action.payload.pollingTimeout;
      }
      if (action.payload.restartMozart !== undefined) {
        state.restartMozart = action.payload.restartMozart;
      }
    },
    setDuplicateCheckLoading(state, action: PayloadAction<boolean>) {
      state.duplicateCheckLoading = action.payload;
    },
    setMozartViewOnly(state, action: PayloadAction<boolean>) {
      state.mozartViewOnly = action.payload;
    },
    setIsReadOnlyWorkflow(state, action: PayloadAction<boolean>) {
      state.isReadOnlyWorkflow = action.payload;
    },
    setMozartWorkflowEpisodeAuthorizationStatus(
      state,
      action: PayloadAction<{ name?: string | null; id?: number } | null>
    ) {
      state.authorizationStatus = action.payload;
    },
  },
});

export const {
  setWorkflowChecklistId,
  resetMozartState,
  addMozartMessage,
  mozartStartPolling,
  mozartStopPolling,
  mozartStartOotPolling,
  mozartStopOotPolling,
  mozartResetChecklist,
  mozartUserAuthorizedNextStep,
  mozartUpdateWorkflow,
  updateCompletedChecklists,
  updateCompletedChecklistsIsVisible,
  mozartWorkflowPaused,
  setMozartGlobalParams,
  setMozartInstanceState,
  setDuplicateCheckLoading,
  setMozartViewOnly,
  setIsReadOnlyWorkflow,
  setMozartCanContinueWorkflow,
  setMozartWorkflowEpisodeAuthorizationStatus,
} = mozartSlice.actions;

export default mozartSlice.reducer;
