import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import EpisodesListClockFormatter from '../formatters/EpisodesListClockFormatter';

const EpisodesListClockProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={EpisodesListClockFormatter}
    {...props}
  />
);

export default EpisodesListClockProvider;
