import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import EpisodesStateFormatter from '../formatters/EpisodesStateFormatter';

const EpisodesStateProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={EpisodesStateFormatter} {...props} />
);

export default EpisodesStateProvider;
