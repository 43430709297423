import { Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React from 'react';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IReminderIconProps {
  name: string;
  image: string;
  type: 'Reminder' | 'Tag';
  showText?: boolean;
  color?: COLORS;
  iconSize?: number;
  description?: string;
}

const REMINDER_ICONS: { [name: string]: ICONS } = {
  'UM Response': ICONS.ReminderCalendar,
  'Initial Encounter': ICONS.ReminderCalendar,
  'Due Date': ICONS.ReminderCalendar,
  'Follow Up': ICONS.ReminderCalendar,
  'Subsequent Encounter': ICONS.ReminderCalendar,
  'Optional Encounter': ICONS.ReminderCalendar,
  'CSR Reminder': ICONS.ReminderCalendar,
  'Fax Failed': ICONS.ReminderFax,
  'New Patient Assigned': ICONS.ReminderEnvelope,
  'Letter Needed': ICONS.ReminderEnvelope,
  'Team Notification': ICONS.ReminderEnvelope,
  'Letter Review Needed': ICONS.ReminderEnvelope,
  '48-72 hour Phone Call Needed': ICONS.ReminderPhoneCallP2P,
  'Phone Call Needed': ICONS.ReminderPhoneCallP2P,
  'Callback Needed': ICONS.ReminderPhoneCallP2P,
  'Review Medications w/Patient': ICONS.ReminderPills,
  'UM MD Response – EPSDT': ICONS.ReminderMedicalFile,
  'UM MD Response – Initial': ICONS.ReminderMedicalFile,
};

export const IMG_TO_SVG: { [name: string]: ICONS } = {
  'Calendar.png': ICONS.TagCalendar,
  'Calendar_30.png': ICONS.TagCalendar30,
  'Calendar_60.png': ICONS.TagCalendar60,
  'Calendar_90.png': ICONS.TagCalendar90,
  'DoNotSendNewsletter.png': ICONS.TagDoNotSendNewsletter,
  'DoNotSendEducation.png': ICONS.TagDoNotSendEducation,
  'DoNotSendHRA.png': ICONS.TagDoNotSendHRA,
  'DoNotCall.png': ICONS.TagDoNotCall,
  'Owner.png': ICONS.TagOwner,
  'DuplicatesActive.png': ICONS.TagDuplicatesActive,
  'DuplicatesInactive.png': ICONS.TagDuplicatesInactive,
  'SharedAddress.png': ICONS.TagSharedAddress,
  'NeedsTransportationAssist.png': ICONS.TagNeedsTransportationAssist,
  'SocialIsolation.png': ICONS.TagSocialIsolation,
  'Arrow.png': ICONS.Arrow,
  'Chat.png': ICONS.Chat,
  'Volume.png': ICONS.Volume,
  'Kidney.png': ICONS.Kidney,
  'Heart.png': ICONS.Heart,
  'No_Clipboard.png': ICONS.No_Clipboard,
  'No_Folder.png': ICONS.No_Folder,
  'No_Gear.png': ICONS.No_Gear,
};

const WrapperDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const IconDiv = styled.div({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  alignItems: 'center',
});

export const imageName = (image: string) =>
  image?.split('/')?.pop() ?? String(null);

export const IconFallBack = (props: IReminderIconProps) => {
  const { image, iconSize, color, name, showText, description, type } = props;
  const icon =
    type === 'Reminder' ? REMINDER_ICONS[name] : IMG_TO_SVG[imageName(image)];
  return (
    <CustomTooltip title={name ?? description ?? ''}>
      <WrapperDiv>
        <IconDiv>
          {icon && (
            <Icon
              size={iconSize ?? 20}
              icon={icon}
              color={color}
              style={{ marginRight: showText ? '4px' : '0' }}
            />
          )}
          {!icon && image && (
            <img
              alt=""
              style={{
                verticalAlign: 'middle',
                width: '16px',
                marginRight: showText ? '4px' : '0',
              }}
              src={image}
            />
          )}
          {showText && <Typography noWrap>{name}</Typography>}
        </IconDiv>
      </WrapperDiv>
    </CustomTooltip>
  );
};
