import { Table, TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import {
  EditingCell,
  GridEditCell,
  ILabelCellProps,
  LabelCell,
} from 'components/gridFormatters';
import React from 'react';
import { CodeCell } from './cells/codeCell';
import { NoteEditCell } from './cells/noteEditCell';
import { StatusCell } from './cells/statusCell';

export enum conditionsColumnNames {
  code = 'code',
  status = 'status',
  description = 'description',
  note = 'note',
}

export const conditionColumnsExtensionStyles = [
  {
    columnName: conditionsColumnNames.description,
    wordWrapEnabled: true,
  },
  {
    columnName: conditionsColumnNames.code,
    width: '160px',
  },
  {
    columnName: conditionsColumnNames.status,
    width: '160px',
  },
  {
    columnName: conditionsColumnNames.note,
    wordWrapEnabled: true,
  },
];

export const conditionColumnsExtensionSorting = [
  {
    columnName: conditionsColumnNames.description,
    sortingEnabled: true,
  },
  {
    columnName: conditionsColumnNames.code,
    sortingEnabled: true,
  },
  {
    columnName: conditionsColumnNames.status,
    sortingEnabled: true,
  },
  {
    columnName: conditionsColumnNames.note,
    sortingEnabled: true,
  },
];

export const conditionColumnsExtensionEditing = [
  {
    columnName: conditionsColumnNames.description,
    editingEnabled: true,
  },
  {
    columnName: conditionsColumnNames.code,
    editingEnabled: false,
  },
  {
    columnName: conditionsColumnNames.status,
    editingEnabled: false,
  },
  {
    columnName: conditionsColumnNames.note,
    editingEnabled: true,
  },
];

export const editCondition = (props: TableEditRow.CellProps) => {
  if (props.editingEnabled) {
    switch (props.column.name) {
      case conditionsColumnNames.description:
        return <GridEditCell {...props} autoFocus />;
      case conditionsColumnNames.note:
        return <NoteEditCell {...props} />;
      default:
        return <EditingCell {...props} />;
    }
  } else {
    switch (props.column.name) {
      case conditionsColumnNames.code:
        return <CodeCell {...props} />;
      case conditionsColumnNames.status:
        return <StatusCell {...props} />;
      default:
        return <Table.Cell {...props} />;
    }
  }
};

export const labelCondition = (props: ILabelCellProps) => {
  const { column } = props;

  switch (column.name) {
    case conditionsColumnNames.code:
      return <CodeCell {...props} />;
    case conditionsColumnNames.status:
      return <StatusCell {...props} />;
    default:
      return <LabelCell {...props} />;
  }
};
