import React from 'react';
import { v4 as uuid4 } from 'uuid';

export interface IMultiLineTextProps {
  text: string;
}
const MultiLineText = (props: IMultiLineTextProps) => {
  const { text } = props;
  // Function to convert newline characters to <br> tags
  const renderTextWithLineBreaks = (text: string) => {
    return text.split('\n').map((line) => (
      <React.Fragment key={uuid4()}>
        {line}
        <br />
      </React.Fragment>
    ));
  };
  return <>{renderTextWithLineBreaks(text)}</>;
};

export default MultiLineText;
