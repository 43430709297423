import React from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { ChecklistItem, ChecklistViewModel } from 'graphql/graphqlTypes';
import Sections from 'components/review/sections';
import { ChecklistStorageType } from 'store/actions/types';

export type CarePlanDetailsProps = TableRowDetail.ContentProps & {
  checklist: ChecklistViewModel;
};

const itemsWithProblem = (problemId: number) => (item: ChecklistItem) =>
  item?.orderSet?.problems?.some((problem) => problem.id === problemId);

export const CarePlanDetails = (props: CarePlanDetailsProps) => {
  const { checklist, row } = props;
  const problemId = Number(row.id);
  const problemChecklist: ChecklistViewModel = {
    ...checklist,
    categories:
      checklist?.categories.map((category) => ({
        ...category,
        items: category.items.filter(itemsWithProblem(problemId)),
      })) ?? [],
  };

  return (
    <Sections
      {...props}
      checklist={problemChecklist}
      storageType={ChecklistStorageType.CAREPLAN}
    />
  );
};
