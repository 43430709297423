import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { errorHandler } from 'store/errorHandler';

const TooltipFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  if (React.isValidElement(props.value)) {
    return (
      <CustomTooltip title={props.value ?? ''}>
        <Typography
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          noWrap
        >
          {props.value}
        </Typography>
      </CustomTooltip>
    );
  } else {
    const value = GetBestValueToDisplay(props.value);
    return (
      <CustomTooltip title={value ?? ''}>
        <Typography
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          noWrap
        >
          {value}
        </Typography>
      </CustomTooltip>
    );
  }
};

const TooltipProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TooltipFormatter} {...props} />
);

const GetBestValueToDisplay = (value: contentValue): string => {
  let stringValue = '';

  if (value == null) {
    return stringValue;
  }

  if (typeof value === 'undefined') {
    return stringValue;
  }

  if (
    typeof value === 'bigint' ||
    typeof value === 'number' ||
    typeof value === 'string' ||
    typeof value === 'boolean'
  ) {
    stringValue += value;
  } else if (Object.keys(value).includes('name')) {
    stringValue += GetBestValueToDisplay((value as GenericObject).name);
  } else if (Object.keys(value).includes('description')) {
    stringValue += GetBestValueToDisplay((value as GenericObject).description);
  } else if (Object.keys(value).includes('text')) {
    stringValue += GetBestValueToDisplay((value as GenericObject).text);
  } else if (typeof value === 'object') {
    const objectString = JSON.stringify(value);
    errorHandler('TooltipProvider', 'Error Parsing value: ' + objectString, '');
  }

  return stringValue;
};

interface GenericObject {
  name: scalarValue;
  description: scalarValue;
  text: scalarValue;
}

type contentValue = bigint | number | string | boolean | object;
type scalarValue = string | number | bigint | boolean;

export default TooltipProvider;
