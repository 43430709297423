import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { NoteEditCell } from 'components/home/patientdetails/tabscontent/conditionsTab/cells/noteEditCell';
import React from 'react';
import { CarePlanLabelCell } from 'components/home/patientdetails/tabscontent/episodesTab/subTabs/casesTab/CarePlanLabelCell';

export const CarePlanEditCell = (props: TableEditRow.CellProps) => {
  const { column } = props;
  if (column.name === 'note') {
    return <NoteEditCell {...props} />;
  }
  return <CarePlanLabelCell {...props} />;
};
