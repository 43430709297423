import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import {
  mapStatusToIconColor,
  mapStatusToText,
} from 'util/helpers/iconHelpers';
import { COLORS } from 'consts/styles';
import { getAttachmentIcon } from 'components/home/attachmentsList/columnFormatters/attachmentTypeCell';
import { ChecklistStatus } from 'graphql/graphqlTypes';
import { TimelineType } from 'components/constants';

const TimelineTypeFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  if (props.value === TimelineType.Checklist) {
    if (props.row.checklistStatus === ChecklistStatus.InProcess) {
      return (
        <Icon
          title="Document in progress"
          icon={ICONS.InProgress}
          size={18}
          color={COLORS.YELLOW100}
        />
      );
    } else {
      return (
        <Icon
          title="Document completed"
          icon={ICONS.ProgressCompleted}
          size={18}
          color={COLORS.GREY60}
        />
      );
    }
  } else if (props.value === TimelineType.Reminder) {
    return (
      <Icon
        title={`Reminder (${
          mapStatusToText(props.row.reminderStatus?.toString()) ?? ''
        })`}
        icon={ICONS.Bell}
        size={18}
        color={mapStatusToIconColor(props.row.reminderStatus?.toString())}
      />
    );
  } else {
    return (
      <Icon
        title={props.row.typeTitle}
        icon={getAttachmentIcon(props.row.typeTitle).icon}
        size={18}
      />
    );
  }
};

export default TimelineTypeFormatter;
