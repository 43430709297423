import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Menu } from '@mui/material';
import { IState } from 'store';
import { ILoginUser } from 'store/user/types';
import { IRole } from 'store/roles/types';
import { setUserRole } from 'store/roles/middlewares';
import Icon, { ICONS } from 'components/icon';

import Divider from '@mui/material/Divider';
import { useLoggedInAsSuperAdmin } from 'hooks/selectors';
import {
  StyledMenuItem,
  StyledMenuTrigger,
  StyledTypography,
  getColor,
  getDropdownIcon,
} from '../StyledComponents';
import { ILookupValue } from 'backend/types/lookupValue';
import { useResetStateAfterRoleChange } from 'components/roleslist/useResetStateAfterRoleChange';
import { SuperAdminRoleId } from 'consts/roles';

interface RolesButtonProps {
  handleClick: (event: React.MouseEvent<HTMLElement>, type: string) => void;
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  displayRole?: IRole;
  anchorEl: null | HTMLElement;
  setAnchorEl: (value: null | HTMLElement) => void;
  handleClose: (type: string) => void;
  selectedCareSite?: ILookupValue;
  roles: IRole[];
}

const RolesButton = ({
  handleClick,
  menuOpen,
  setMenuOpen,
  displayRole,
  anchorEl,
  setAnchorEl,
  handleClose,
  selectedCareSite,
  roles,
}: RolesButtonProps) => {
  const dispatch = useDispatch();
  const primaryRoleId = useSelector(
    (state: IState) => state.roles.primaryRoleId
  );
  const resetStateAfterRoleChange = useResetStateAfterRoleChange();
  const user = useSelector((state: IState) => state.user.auth);
  const roleName = useSelector((state: IState) => state.user.currentUser.role);
  const currentUserRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );
  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.userRoleId
  );
  const loggedInAsSuperAdmin = useLoggedInAsSuperAdmin();
  const superAdminRole = useMemo(
    () => roles.find((r) => r.roleId === SuperAdminRoleId),
    [roles]
  );
  const organizationAccessRoles = roles.filter(
    (r) => r.isOrganizationAccess !== null && r.isOrganizationAccess === true
  );
  const hasAnyOrganizationRoles = organizationAccessRoles.length > 0;
  const handleChangeUserRole = (
    selectedRoleId: number,
    curUser: ILoginUser,
    curPrimaryRoleId: number | null
  ) => {
    if (selectedRoleId !== currentRoleId) {
      dispatch(
        setUserRole(
          selectedRoleId,
          sessionId,
          curUser,
          curPrimaryRoleId ?? 0,
          isLoggedIn
        )
      );
      resetStateAfterRoleChange();
    }
    setMenuOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Box component="div">
        <StyledMenuTrigger
          onClick={(event) => handleClick(event, 'role')}
          $menuOpen={menuOpen}
          $disableHover={false}
        >
          <StyledTypography variant="h6" $textColor={getColor(menuOpen)}>
            {displayRole?.displayName}
          </StyledTypography>
          {getDropdownIcon(menuOpen)}
        </StyledMenuTrigger>
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => handleClose('role')}
          autoFocus={false}
          keepMounted={true}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              left: '0',
              marginTop: '5px',
              borderRadius: '4px',
              border: '1px solid #CECECE ',
              boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          {roles.map(
            (roleItem) =>
              roleItem.careSiteId === selectedCareSite?.id && (
                <StyledMenuItem
                  key={roleItem.id}
                  value={roleItem.displayName}
                  $active={roleName === roleItem.displayName}
                  $paddingLeft={
                    roleName === roleItem.displayName ? '20px' : '44px'
                  }
                  onClick={() =>
                    handleChangeUserRole(roleItem.id, user, primaryRoleId)
                  }
                >
                  {roleName === roleItem.displayName && (
                    <Icon
                      icon={ICONS.Checkmark}
                      size={12}
                      style={{ marginRight: '6px' }}
                    />
                  )}
                  {roleItem.displayName}
                </StyledMenuItem>
              )
          )}
          {superAdminRole && <Divider />}
          {superAdminRole && (
            <StyledMenuItem
              key="super-admin"
              value="Super Admin"
              $active={loggedInAsSuperAdmin}
              $paddingLeft={loggedInAsSuperAdmin ? '17px' : '41px'}
              onClick={() =>
                handleChangeUserRole(superAdminRole?.id, user, primaryRoleId)
              }
            >
              {loggedInAsSuperAdmin && (
                <Icon
                  icon={ICONS.Checkmark}
                  size={12}
                  style={{ marginRight: '6px' }}
                />
              )}
              Super Admin
            </StyledMenuItem>
          )}
          {hasAnyOrganizationRoles && <Divider />}
          {hasAnyOrganizationRoles &&
            organizationAccessRoles.map((roleItem) => (
              <StyledMenuItem
                key={roleItem.id}
                value={roleItem.displayName}
                $active={currentUserRoleId === roleItem.id}
                $paddingLeft={
                  currentUserRoleId === roleItem.id ? '20px' : '44px'
                }
                onClick={() =>
                  handleChangeUserRole(roleItem.id, user, primaryRoleId)
                }
              >
                {currentUserRoleId === roleItem.id && (
                  <Icon
                    icon={ICONS.Checkmark}
                    size={12}
                    style={{ marginRight: '6px' }}
                  />
                )}
                {roleItem.displayName}
              </StyledMenuItem>
            ))}
        </Menu>
      </Box>
    </>
  );
};

export default RolesButton;
