import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { styled } from '@mui/styles';

export interface IChipProps {
  className?: string;
  icon?: ICONS;
  type?: 'error' | 'success' | 'normal';
  text: string;
  onDelete?: () => void;
  variant?: 'outlined' | 'filled';
}

const CustomizedChip = ({
  icon,
  text,
  onDelete,
  type,
  variant,
  ...rest
}: IChipProps) => (
  <MuiChip
    size="small"
    variant={variant ?? 'outlined'}
    label={text}
    avatar={
      icon && (
        <Icon
          size={12}
          icon={icon}
          color={type === 'success' ? COLORS.GREEN100 : undefined}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      )
    }
    onDelete={onDelete}
    onMouseDown={(event) => event.stopPropagation()}
    {...rest}
  />
);

const Chip = styled(CustomizedChip)({
  borderRadius: '4px',
  maxWidth: '250px',
  height: '20px',
  textOverflow: 'ellipsis',
  fontColor: 'black',
  fontWeight: 400,
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '2px 8px',
  borderColor: ({ type }: IChipProps) =>
    type === 'error' ? COLORS.RED100 : COLORS.GREY25,

  '& .MuiChip-label': {
    fontSize: '12px',
    paddingLeft: '10px',
    color: ({ type }: IChipProps) =>
      type === 'success' ? COLORS.GREEN100 : COLORS.GREY100,
  },

  '& .MuiChip-avatarSmall': {
    height: '12px !important',
    width: '12px !important',
  },
  '& .MuiChip-deleteIcon': {
    height: '12px',
  },
});

export default Chip;
