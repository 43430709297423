import React from 'react';
import {
  TextField,
  InputAdornment,
  AutocompleteRenderInputParams,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { StyledIconButton } from './styles';

export interface IAutocompleteSearchInputProps {
  inputParams: AutocompleteRenderInputParams;
  searchTerm: string;
  onClear: () => void;
}

const AutocompleteSearchInput = (props: IAutocompleteSearchInputProps) => {
  const { inputParams, searchTerm, onClear } = props;
  return (
    <TextField
      {...inputParams}
      type="text"
      variant="outlined"
      fullWidth={true}
      InputProps={{
        ...inputParams.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <Icon size={14} icon={ICONS.Search} style={{ marginTop: '-2px' }} />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {searchTerm.length === 0 ? null : (
              <InputAdornment position="end">
                <StyledIconButton onClick={onClear}>
                  <Icon size={18} icon={ICONS.Remove} />
                </StyledIconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export default AutocompleteSearchInput;
