import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientAttachmentsQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientAttachmentsQuery = {
  __typename?: 'EntitiesQuery';
  getPatientAttachments: Array<{
    __typename?: 'Attachment';
    id: number;
    name?: string | null;
    createdOn?: any | null;
    description?: string | null;
    isDeleted: boolean;
    isVisible: boolean;
    fileExtension: string;
    createdByUser?: {
      __typename?: 'User';
      id: number;
      fullName?: string | null;
    } | null;
    attachmentType?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
    attachmentCategory?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
  }>;
};

export const GetPatientAttachmentsDocument = `
    query getPatientAttachments($patientId: Int!) {
  getPatientAttachments(patientId: $patientId) {
    id
    name
    createdOn
    description
    isDeleted
    isVisible
    createdByUser {
      id
      fullName
    }
    attachmentType {
      id
      name
    }
    fileExtension
    attachmentCategory {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientAttachments: build.query<
      GetPatientAttachmentsQuery,
      GetPatientAttachmentsQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientAttachmentsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientAttachmentsQuery,
  useLazyGetPatientAttachmentsQuery,
} = injectedRtkApi;
