export const getTextWidths = (
  texts: string[],
  font?: string | null
): number[] => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return [0];
  }
  context.font = font ?? getComputedStyle(document.body).font;
  return texts.map((text) => Math.floor(context.measureText(text).width));
};
