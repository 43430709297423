import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ChecklistStorageType } from 'store/actions/types';
import EmptyServices from './Components/EmptySevices';
import IndividualService from './Components/IndividualService';
import OverallHealthService from './Components/OverallHealthService';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { COLORS } from 'consts/styles';
import { notMaybe } from 'util/helpers/arrayFunctions';
import { debounce } from 'lodash';
import {
  deleteItemHealthService,
  getValidationDispatch,
  onHealthServiceItemValueChange,
  useSaveHealthService,
  onHealthServiceOverallItemValueChange,
  addMedicationCode,
  getRequestedUnits,
  applyOnHsDecisionGroup,
} from './HealthService.helpers';
import { useDeterminationReasons, useLookup } from './HealthService.hooks';
import {
  ChecklistItem,
  ChecklistItemHealthServices,
  ChecklistViewModel,
  HealthServiceCodesActionModel,
  HealthServiceCodesActionValue,
  ILookupValue,
  LookupValue,
  SearchResult,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { HEALTH_SERVICE } from 'components/constants';
import { getDefaultUnitType } from './HealthServiceV2helper';
import {
  DecisionAction,
  HealthServiceAuthorizationType,
  UnitTypes,
  hsVersion,
} from './types';
import MedicationCodesSearch from '../MedicationCodes/medicationCodesSearch';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';

export interface IChecklistItemHSRequestingProps {
  item: ChecklistItemHealthServices;
  categoryId: string;
  autoSave: boolean;
  orderableIndex: number;
  storageType: ChecklistStorageType;
  episodeId?: number;
  updateChecklistItemInputValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  selectChecklistItemOnSave: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
  readOnly: boolean;
  hsAuthorizationType: number;
  isDisplayEpisodeLevelRequestedParam: boolean;
  hSDisplayMode: number;
  isHsAllowAddingCodes: boolean;
  isHsAllowDeletingCode: boolean;
}

const IndividualServiceBox = styled(Box)({
  borderRadius: '4px',
  padding: '12px 60px 0px 60px',
  backgroundColor: COLORS.WHITE,
});
const OverallHealthServiceBox = styled(Box)({
  padding: '12px 16px 16px 60px',
  backgroundColor: COLORS.GREY4,
});
const HsRequestingBox = styled(Box)({
  padding: '0px 0px 12px 0px',
  backgroundColor: COLORS.WHITE,
});

const StyledTitle = styled(Typography)`
  padding-bottom: 4px;
`;

const HSRequestingCode = ({
  autoSave,
  item,
  orderableIndex,
  storageType,
  updateChecklistItemInputValueOnSave,
  categoryId,
  readOnly,
  hsAuthorizationType,
  isDisplayEpisodeLevelRequestedParam,
  hSDisplayMode,
  isHsAllowAddingCodes,
  isHsAllowDeletingCode,
}: IChecklistItemHSRequestingProps) => {
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist
  );

  const isUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    item.uid
  );

  const hsDefaultUnit = item.options.hSDefaultUnit ?? UnitTypes.units;
  const hsUnitTypeEditable = item.options.hSUnitTypeEditable;

  const unitTypes = useLookup('UnitsOfServiceType');

  const [defaultUnitType, setDefaultUnitType] = useState<
    ILookupValue | undefined
  >(undefined);

  useEffect(() => {
    setDefaultUnitType(getDefaultUnitType(unitTypes, hsDefaultUnit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitTypes]);

  useEffect(() => {
    const unitTypeModel = ensureOverallReqUnitType(model);
    setHsRequestingModel(unitTypeModel);
    const unitModel = ensureOverallRequestedValue(unitTypeModel);
    setHsRequestingModel(unitModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultUnitType]);

  const determinationReasons = useDeterminationReasons(item.options);
  const overallReqStartDate = item?.hSValue?.overallReqStartDate;
  const overallReqEndDate = item?.hSValue?.overallReqEndDate;

  const dispatch = useDispatch();

  const itemModel = item.hSValue as HealthServiceCodesActionModel;

  const [model, setHsRequestingModel] = useState<HealthServiceCodesActionModel>(
    () =>
      ({
        ...item.hSValue,
        healthServiceCodesActionValues:
          itemModel.healthServiceCodesActionValues.map((hSCodesActionValues) =>
            hSCodesActionValues?.healthServiceActionValue?.requestedUnitType ===
              null ||
            hSCodesActionValues?.healthServiceActionValue?.requestedUnitType ===
              undefined
              ? {
                  ...hSCodesActionValues,
                  healthServiceActionValue: {
                    ...hSCodesActionValues?.healthServiceActionValue,
                    requestedUnitType: defaultUnitType?.name ?? 'Units',
                    requestedUnits: '',
                  },
                }
              : { ...hSCodesActionValues }
          ),
        overallApprUnitType: item.hSValue?.overallApprUnitType
          ? item.hSValue?.overallApprUnitType
          : defaultUnitType,
        overallReqUnitType: item.hSValue?.overallReqUnitType
          ? item.hSValue?.overallReqUnitType
          : defaultUnitType,
        overallReqStartDate: overallReqStartDate,
        overallReqEndDate: overallReqEndDate,
      } as HealthServiceCodesActionModel)
  );

  const [overallData, setHsRequestingCodeOverallData] = useState({
    start: overallReqStartDate,
    end: overallReqEndDate,
    unitType: item.hSValue?.overallReqUnitType ?? defaultUnitType,
  });

  const ensureOverallReqUnitType = (
    updatedModel: HealthServiceCodesActionModel
  ) => {
    if (!model.overallReqUnitType && defaultUnitType) {
      return {
        ...updatedModel,
        overallReqUnitType: defaultUnitType as LookupValue,
      };
    }

    return updatedModel;
  };

  const ensureOverallRequestedValue = (
    updatedModel: HealthServiceCodesActionModel
  ) => {
    if (model.overallReqStartDate && model.overallReqEndDate) {
      return {
        ...updatedModel,
        totalReqUnits: getRequestedUnits(
          updatedModel.overallReqUnitType?.name as string,
          updatedModel,
          updatedModel.totalReqUnits
        ),
      };
    }
    return updatedModel;
  };

  const prepareAndSetHsRequestingModel = (
    updatedModel: HealthServiceCodesActionModel
  ) => {
    const validModel = ensureOverallReqUnitType(updatedModel);
    setHsRequestingModel(validModel);
  };

  const onChangeHSRequestingOverallData = (
    newModel: HealthServiceCodesActionModel
  ) => {
    const updatedModel = onHealthServiceOverallItemValueChange(newModel, null);
    setHsRequestingCodeOverallData({
      start: newModel.overallReqStartDate,
      end: newModel.overallReqEndDate,
      unitType: newModel.overallReqUnitType ?? defaultUnitType,
    });
    prepareAndSetHsRequestingModel(updatedModel);
  };

  const onDeleteHSRequestingCode = (id: number) => {
    const version =
      hsAuthorizationType === HealthServiceAuthorizationType.Episode
        ? hsVersion
        : null;

    const newModel = deleteItemHealthService(model, id, version);
    prepareAndSetHsRequestingModel(newModel);
  };

  const onHsRequestingIndividualServiceChange = (
    updatedValue: HealthServiceCodesActionValue
  ) => {
    const newModel = onHealthServiceItemValueChange(model, updatedValue);
    prepareAndSetHsRequestingModel(newModel);
  };

  const saveHealthService = useSaveHealthService();
  const hsRequestingCodeInputChange = debounce(
    (value: HealthServiceCodesActionModel) => {
      saveHealthService(
        value,
        checklistId,
        item,
        autoSave,
        updateChecklistItemInputValueOnSave,
        orderableIndex
      );
    },
    100
  );

  useEffect(() => {
    hsRequestingCodeInputChange(model);
    applyOnHsDecisionGroup(
      dispatch,
      storageType,
      checklist as ChecklistViewModel,
      item,
      DecisionAction.nonSet
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const onAddCode = (newCode: SearchResult) => {
    const newModel = addMedicationCode(newCode, model, unitTypes, hsVersion);
    setHsRequestingModel(newModel);
  };

  const onChangeOverallData = (newModel: HealthServiceCodesActionModel) => {
    setHsRequestingCodeOverallData({
      start: newModel.overallReqStartDate,
      end: newModel.overallReqEndDate,
      unitType: newModel.overallReqUnitType ?? defaultUnitType,
    });
    const updatedModel = onHealthServiceOverallItemValueChange(
      newModel,
      hsVersion
    );
    setHsRequestingModel(updatedModel);
  };

  const validationDispatch = getValidationDispatch(
    dispatch,
    item.uid,
    categoryId,
    storageType,
    HEALTH_SERVICE
  );

  const hsRequestingValidationDispatch = getValidationDispatch(
    dispatch,
    item.uid,
    categoryId,
    storageType,
    HEALTH_SERVICE
  );

  return (
    <Box id={`uuid-${item.uid}`} data-testid="hsRequestingCode">
      <Box bgcolor={COLORS.WHITE} padding="24px 20px 24px 60px">
        <Typography variant="body2">{item.options.codeLabel} </Typography>
      </Box>
      <HsRequestingBox>
        {hsAuthorizationType === HealthServiceAuthorizationType.Episode ? (
          <OverallHealthServiceBox>
            <OverallHealthService
              state={model}
              onChange={onChangeOverallData}
              unitTypes={unitTypes}
              readOnly={false}
              notApproved={true}
              validationDispatch={validationDispatch}
              isDisplayEpisodeLevelRequestedParam={
                isDisplayEpisodeLevelRequestedParam
              }
              hsAuthorizationType={hsAuthorizationType}
              hsDefaultUnit={hsDefaultUnit}
              hsUnitTypeEditable={hsUnitTypeEditable}
              hSDisplayMode={hSDisplayMode}
            />
          </OverallHealthServiceBox>
        ) : null}
        {hsAuthorizationType != HealthServiceAuthorizationType.Episode && (
          <OverallHealthServiceBox>
            <OverallHealthService
              state={model}
              onChange={onChangeHSRequestingOverallData}
              unitTypes={unitTypes}
              readOnly={false}
              notApproved={false}
              validationDispatch={hsRequestingValidationDispatch}
              hsRequestingCodes={true}
              isDisplayEpisodeLevelRequestedParam={
                isDisplayEpisodeLevelRequestedParam
              }
              hsAuthorizationType={hsAuthorizationType}
              hsDefaultUnit={hsDefaultUnit}
              hsUnitTypeEditable={hsUnitTypeEditable}
            />
          </OverallHealthServiceBox>
        )}
        <IndividualServiceBox id={`uuid-${item.uid}-medcodes`}>
          <StyledTitle variant="body2">Individual Services:</StyledTitle>
          {model.healthServiceCodesActionValues?.length > 0 ? (
            <>
              {model.healthServiceCodesActionValues
                ?.filter(notMaybe)
                .map((code) => (
                  <IndividualService
                    id={`uuid-${item.uid}-${code.id}`}
                    key={item.uid}
                    item={code}
                    onDelete={onDeleteHSRequestingCode}
                    unitTypes={unitTypes}
                    onChange={onHsRequestingIndividualServiceChange}
                    readOnly={readOnly}
                    determinationReasons={determinationReasons}
                    pendingOnly={false}
                    canApprove={false}
                    canDenied={false}
                    overallDates={overallData}
                    validationDispatch={hsRequestingValidationDispatch}
                    hsRequestingCodes={true}
                    enableDelete={
                      model.healthServiceCodesActionValues?.length > 1
                    }
                    hsAuthorizationType={hsAuthorizationType}
                    isHsAllowDeletingCode={isHsAllowDeletingCode}
                  />
                ))}
            </>
          ) : (
            <EmptyServices />
          )}
          {isHsAllowAddingCodes && (
            <MedicationCodesSearch
              minRequired={item.options.minRequired}
              maxAllowed={item.options.maxAllowed}
              type={item.options.type}
              readOnly={readOnly}
              selectedCodes={
                model.healthServiceCodesActionValues as SearchResult[]
              }
              onAddCode={onAddCode}
              style={{ padding: '0 0px 14px 0px' }}
            />
          )}
        </IndividualServiceBox>
        {isUpdateActionValueRequestFailed && (
          <Typography style={{ paddingLeft: '36px', color: 'red' }}>
            Internal server error: Your changes could not be saved, because of
            technical issues.
          </Typography>
        )}
      </HsRequestingBox>
    </Box>
  );
};
export default HSRequestingCode;
