import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnlockMozartActionMutation } from 'graphql/hooks/unlockMozartAction';
import { IState } from 'store';
import { ISystemValue } from 'backend/types/systemValue';
import useLockMozartActionImmediately from './useLockMozartActionImmediately';

interface LockFunction {
  (): Promise<void>;
  isDisposed?: boolean;
}

const oneMinute = 60000;
const getRefreshPeriod = (value: string) => {
  if (!value) {
    return oneMinute;
  }

  const result = Number(value);
  if (!result || isNaN(result) || result < 1) {
    return oneMinute;
  }

  return result * 1000;
};

const useMozartActionLock = (
  isEnabled: boolean,
  workflowInstanceId: string,
  actionInstanceId: string
) => {
  const dispatch = useDispatch();
  const lockInterval = useRef<NodeJS.Timeout | null>(null);
  const systemValues = useSelector(
    (state: IState) => state.home.mainModel.systemValues
  );

  const [unlockMozartAction] = useUnlockMozartActionMutation();
  const lockMozartActionImmediately = useLockMozartActionImmediately();
  const refreshPeriod = getRefreshPeriod(
    systemValues?.find(
      (sv: ISystemValue) => sv.name === 'Workflow Lockout Refresh Period'
    )?.value || ''
  );

  const lockAction = useMemo<LockFunction>(
    () => () => {
      return lockMozartActionImmediately(workflowInstanceId, actionInstanceId);
    },
    // eslint-disable-next-line
    [actionInstanceId, workflowInstanceId]
  );

  useEffect(() => {
    if (!isEnabled) {
      return undefined;
    }

    if (actionInstanceId) {
      lockAction().then(() => {
        // don't start interval if we cleaned up the component before firing this callback then
        if (!lockAction.isDisposed) {
          lockInterval.current = setInterval(lockAction, refreshPeriod);
        }
      });
    }

    return () => {
      lockAction.isDisposed = true;
      if (lockInterval.current) {
        clearInterval(lockInterval.current);
        lockInterval.current = null;
      }
      if (actionInstanceId) {
        dispatch(async () => {
          try {
            await unlockMozartAction({
              workflowInstanceId: workflowInstanceId,
              actionInstanceId: actionInstanceId,
            });
          } catch (_) {
            /* Ignore any errors here, logging should be handled by errorHandler
             * and lock will unlock after some time */
          }
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, workflowInstanceId, actionInstanceId]);
};

export default useMozartActionLock;
