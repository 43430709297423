import { ChangeSet } from '@devexpress/dx-react-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Attachment,
  AttachmentDtoInput,
  MoveAttachmentsModelInput,
} from 'graphql/graphqlTypes';
import {
  mapAttachmentToInputDto,
  setDefaultAttachmentState,
} from 'store/patientdetails/patientDetails.helpers';

export type UpdateAttachmentsInput = {
  [patientId: string]: {
    attachments: AttachmentDtoInput[];
    episodeAttachments: { [episodeId: number]: AttachmentDtoInput[] };
  };
};

interface IMoveAttachmentPayload {
  moveObject: MoveAttachmentsModelInput;
  isMoveToSamePatientEpisode: boolean;
}

export interface IPatientDetailsAttachmentState {
  updatedAttachments: UpdateAttachmentsInput;
  attachmentsTab: Attachment[] | null;
  episodeTab: { [episodeId: string]: Attachment[] };
  moveAttachmentId: number;
  selectedAttachmentIds: number[];
  attachmentsToMove: MoveAttachmentsModelInput[];
}

export const initialPatientDetailsAttachmentState: IPatientDetailsAttachmentState =
  {
    attachmentsTab: null,
    updatedAttachments: {},
    episodeTab: {},
    selectedAttachmentIds: [],
    moveAttachmentId: 0,
    attachmentsToMove: [],
  };

const getChangedRows = <T extends Attachment>(
  changeSet: ChangeSet,
  rows: T[]
): T[] => {
  let changedRows: T[] = rows;
  const { added, changed, deleted } = changeSet;
  if (added) {
    changedRows = [...rows, ...added];
  }
  if (changed) {
    changedRows = changedRows.map((row) =>
      changed[row.id] ? { ...row, ...changed[row.id] } : row
    );
  }
  if (deleted) {
    const deletedSet = new Set(deleted);
    changedRows = changedRows.map((row) =>
      deletedSet.has(row.id) ? { ...row, isDeleted: true } : row
    );
  }
  return changedRows;
};

const patientDetailsAttachmentSlice = createSlice({
  name: 'PatientDetailsAttachmentState',
  initialState: initialPatientDetailsAttachmentState,
  reducers: {
    clearAttachments: () => {
      return initialPatientDetailsAttachmentState;
    },
    commitAttachments: (
      state,
      action: PayloadAction<{
        patientId: number;
        episodeId?: number;
        changeSet: ChangeSet;
      }>
    ) => {
      const { patientId, episodeId, changeSet } = action.payload;
      setDefaultAttachmentState(state, patientId, episodeId);

      if (episodeId) {
        const attachments = state.episodeTab[episodeId];
        state.episodeTab[episodeId] = getChangedRows(changeSet, attachments);

        state.updatedAttachments[patientId].episodeAttachments[episodeId] =
          state.episodeTab[episodeId].map(mapAttachmentToInputDto);
      } else {
        const attachments = state.attachmentsTab!;
        state.attachmentsTab = getChangedRows(changeSet, attachments);
        state.updatedAttachments[patientId].attachments =
          state.attachmentsTab.map(mapAttachmentToInputDto);
      }
    },
    updateSelectedAttachmentIds: (state, action: PayloadAction<number[]>) => {
      state.selectedAttachmentIds = action.payload;
    },
    toggleAttachmentSidebar(state, action: PayloadAction<number>) {
      state.moveAttachmentId = action.payload;
    },
    moveAttachment(state, action: PayloadAction<IMoveAttachmentPayload>) {
      state.attachmentsToMove = [
        ...state.attachmentsToMove,
        action.payload.moveObject,
      ];
      state.moveAttachmentId = 0;

      if (action.payload.isMoveToSamePatientEpisode) {
        return;
      }
      if (state.attachmentsTab) {
        state.attachmentsTab = state?.attachmentsTab?.filter(
          (item) => item.id !== action.payload.moveObject.attachmentId
        );
      }
    },
  },
});

export const {
  commitAttachments,
  clearAttachments,
  updateSelectedAttachmentIds,
  toggleAttachmentSidebar,
  moveAttachment,
} = patientDetailsAttachmentSlice.actions;

export default patientDetailsAttachmentSlice.reducer;
