import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import { Typography } from '@mui/material';
import styled from 'styled-components';

const StyledTableCell = styled.td`
  height: 24px;
  max-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-bottom-color: ${COLORS.LIGHT_GREY};
  border-bottom-style: solid;
  background-color: ${COLORS.WHITE};
  padding-left: 6px;
`;

export const NoDataCellComponent = (props: Table.NoDataCellProps) => (
  <StyledTableCell {...props}>
    <Typography variant="body1" style={{ color: COLORS.GREY100 }}>
      {props.getMessage('noData')}
    </Typography>
  </StyledTableCell>
);
