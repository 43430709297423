import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from 'components/modal/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from 'components/modal/DialogActions';
import { Box, Button, DialogContent, Grid, Typography } from '@mui/material';
import { forceUpdate } from 'store/home/action';
import Sections from 'components/actions/sections/sections';
import { COLORS } from 'consts/styles';
import Loader from 'components/loader';
import { IState } from 'store';
import QuickNoteTitle from './Title';
import { ChecklistStorageType } from 'store/actions/types';
import ErrorNotification from 'components/ErrorNotification';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import {
  getActionsModelSuccess,
  resetChecklist,
} from 'store/actions/checklistSlice';
import { LookupSelect } from 'components/select';
import { useSaveQuickNoteMutation } from 'graphql/hooks/saveQuickNote';
import {
  ChecklistActionDataInput,
  ChecklistViewModel,
  FinishChecklistStatus,
  Patient,
  QuickNoteType,
} from 'graphql/graphqlTypes';
import { useGetPatientByIdQuery } from 'graphql/hooks/getPatientById';
import { useLoadQuickNoteQuery } from 'graphql/hooks/loadQuickNote';
import styled from 'styled-components';

const StyledBody = styled.div`
  min-height: 566px;
  overflow-x: auto;
  background-color: ${COLORS.PALE_GREY};
  padding: 20px 28px 20px 28px;
`;

const StyledDialogBody = styled(DialogContent)`
  padding: 0;
  background-color: ${COLORS.GREY4};
`;

const StyledErrorMessage = styled(Typography)`
  padding-left: 116px;
  margin-top: -14px;
  color: ${COLORS.RED100};
  font-weight: 400;
`;

interface QuickNoteDialogProps {
  open: boolean;
  patientId: number;
  episodeId?: number | null;
  type?: QuickNoteType | null;
  baseQuickNoteId?: number | null;
  onClose: () => void;
  shouldRefetchNotes?: (shouldRefetch: boolean) => void;
}

const QuickNoteDialog = (props: QuickNoteDialogProps) => {
  const {
    open,
    onClose,
    shouldRefetchNotes,
    episodeId,
    type,
    baseQuickNoteId,
    patientId,
  } = props;

  const [showErrorBar, setShowErrorBar] = useState(false);
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );

  const [reasonForNote, setReasonForNote] = useState<number>(0);

  const [inputDataObject, setInputDataObject] = useState<
    ChecklistActionDataInput[]
  >([] as ChecklistActionDataInput[]);

  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.QUICKNOTE]?.checklist
  );
  const checklistErrors = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.QUICKNOTE]
        ?.checklistErrors
  );

  const [
    saveQuickNote,
    {
      isLoading: isSavingQuickNote,
      isSuccess: isSuccessSaveQuickNote,
      data: saveQuickNoteResult,
    },
  ] = useSaveQuickNoteMutation();

  const dispatch = useDispatch();

  const handleSave = () => {
    if (
      Object.values(checklistErrors).filter((error) => !!error.error).length > 0
    ) {
      setShowErrorBar(true);
    } else {
      setShowErrorBar(false);
      saveQuickNote({
        data: {
          sessionId: sessionId,
          quickNote: {
            patientId: Number(patientId),
            episodeId: episodeId ? Number(episodeId) : null,
            baseQuickNoteId: baseQuickNoteId ? Number(baseQuickNoteId) : null,
            quickNoteType: type ?? QuickNoteType.Episode,
            actions: inputDataObject,
            reasonForNote: reasonForNote,
          },
        },
      });
    }
  };

  const handleClose = () => {
    dispatch(resetChecklist(ChecklistStorageType.QUICKNOTE));
    setReasonForNote(0);
    onClose();
  };

  useEffect(() => {
    const hasErrors = Object.values(checklistErrors).some((err) => !!err.error);
    if (!hasErrors) {
      setShowErrorBar(false);
    }
  }, [inputDataObject, checklistErrors, reasonForNote]);

  useEffect(() => {
    if (isSuccessSaveQuickNote) {
      if (
        saveQuickNoteResult?.saveQuickNote?.status ===
        FinishChecklistStatus.Success
      ) {
        afterSave();
      } else if (
        saveQuickNoteResult?.saveQuickNote?.status ===
        FinishChecklistStatus.Locked
      ) {
        dispatch(showErrorPopup({ message: 'Patient is Locked' }));
      } else {
        dispatch(
          showErrorPopup({
            message: (saveQuickNoteResult?.saveQuickNote?.errors ?? []).join(
              ','
            ),
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSaveQuickNote]);

  const afterSave = () => {
    dispatch(forceUpdate(true));
    shouldRefetchNotes && shouldRefetchNotes(true);
    handleClose();
  };

  const { data: patientData, isFetching: patientDetailsLoading } =
    useGetPatientByIdQuery(
      { patientId: Number(patientId) },
      {
        skip: !patientId || !open,
      }
    );

  const {
    data: quickNoteData,
    isFetching: isQuickNoteFetching,
    isLoading: isModelLoading,
    isSuccess: isGetSuccessChecklist,
  } = useLoadQuickNoteQuery(
    {
      patientId: Number(patientId),
      episodeId: episodeId ? Number(episodeId) : 0,
      baseQuickNoteId: baseQuickNoteId ? Number(baseQuickNoteId) : null,
      quickNoteType: type ?? QuickNoteType.Episode,
    },
    {
      skip: !patientId && !baseQuickNoteId,
    }
  );
  useEffect(() => {
    if (isGetSuccessChecklist && !isQuickNoteFetching) {
      dispatch(
        getActionsModelSuccess({
          storageType: ChecklistStorageType.QUICKNOTE,
          checklist: quickNoteData?.loadQuickNote as ChecklistViewModel,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetSuccessChecklist, quickNoteData, open]);

  const renderChecklist = () => {
    if (!checklist) {
      return null;
    }
    return checklist && checklist?.categories?.length > 0 ? (
      <Sections
        storageType={ChecklistStorageType.QUICKNOTE}
        autoSave={false}
        inputData={inputDataObject}
        setInputData={setInputDataObject}
        hideNumbers={true}
      />
    ) : (
      <StyledErrorMessage variant="h5">
        QuickNote is not defined. Please, check the settings for the current
        role.
      </StyledErrorMessage>
    );
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <QuickNoteTitle
            patientInfo={patientData?.getPatientById as Patient}
            type={props.type ?? QuickNoteType.Episode}
          />
        </DialogTitle>
        <StyledDialogBody dividers>
          <Grid container>
            <Grid item xs={12}>
              {showErrorBar ? (
                <ErrorNotification
                  storageType={ChecklistStorageType.QUICKNOTE}
                  categories={checklist?.categories ?? []}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <StyledBody>
                <Grid
                  container
                  alignItems="center"
                  style={{ paddingBottom: '20px' }}
                >
                  <Grid
                    item
                    xs={12}
                    direction={'row'}
                    style={{ display: 'flex' }}
                    alignItems="center"
                  >
                    <Box marginRight="8px">
                      <Typography>Reason for note:</Typography>
                    </Box>
                    <LookupSelect
                      id="reasonForNote"
                      value={reasonForNote > 0 ? reasonForNote.toString() : ''}
                      lookup="ReasonForNote"
                      error={reasonForNote <= 0}
                      required
                      size="small"
                      onChange={(e) => setReasonForNote(Number(e.target.value))}
                    />
                  </Grid>
                </Grid>
                {renderChecklist()}
                <Loader
                  active={
                    isQuickNoteFetching ||
                    isModelLoading ||
                    isSavingQuickNote ||
                    patientDetailsLoading
                  }
                />
              </StyledBody>
            </Grid>
          </Grid>
        </StyledDialogBody>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: '20px 28px' }}
        >
          <div style={{ flexGrow: 1 }} />
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button
              onClick={handleClose}
              color="primary"
              variant="outlined"
              data-cy="-note-cancel"
              size="large"
              style={{ minWidth: '150px', height: '40px' }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              size="large"
              color="primary"
              disabled={!reasonForNote || showErrorBar}
              variant="contained"
              style={{ minWidth: '150px', height: '40px' }}
              data-cy="note-save-and-exit"
            >
              Save & Exit
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuickNoteDialog;
