import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  IconButton,
  InputAdornment,
  PopperProps,
  TextField,
} from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { PatientClaimDto } from 'graphql/graphqlTypes';
import Icon, { ICONS } from 'components/icon';
import { ClaimOption } from './ClaimOption';
import { ClaimPopper } from './ClaimPopper';
import { AssociatedClaimAction } from './AssociatedClaim.helper';

const StyledAutocomplete = styled(Autocomplete)({
  width: '440px',
  height: '32px',
  marginRight: '20px',
  '& .MuiAutocomplete-inputRoot': {
    padding: '3.5px 12px!important',
  },
  backgroundColor: COLORS.WHITE,
});

const StyledIconButton = styled(IconButton)`
  padding: 4px;
`;

export interface IClaimSearchProps {
  claims: PatientClaimDto[];
  searchTerm: string;
  isReadOnly: boolean;
  isLoading: boolean;
  onSelect: (value: AssociatedClaimAction) => void;
  onSearch: (term: string) => void;
  onViewAllClick: () => void;
  onCantFindClick: () => void;
}

const ClaimSearch = ({
  claims,
  searchTerm,
  isReadOnly,
  isLoading,
  onSelect,
  onSearch,
  onViewAllClick,
  onCantFindClick,
}: IClaimSearchProps) => {
  const [options, setOptions] = useState<PatientClaimDto[]>(
    [] as PatientClaimDto[]
  );
  const [opened, setOpened] = useState<boolean | undefined>();
  const [selectedClaim, setSelectedClaim] = useState<PatientClaimDto | null>(
    null
  );

  useEffect(() => {
    if (isLoading) {
      setOptions([]);
    }
    if (claims && !isLoading) {
      setOptions(claims);
    }
  }, [isLoading, claims]);

  const onSelectionChange = (value: PatientClaimDto) => {
    if (value) {
      setSelectedClaim(null);
      onSelect(value as AssociatedClaimAction);
    }
    setOpened(false);
  };

  const getOptionLabel = (option: PatientClaimDto): string => {
    return option.claimId ?? '';
  };

  const AutocompletePopper = useCallback(
    (popperProps: PopperProps) => {
      return (
        <ClaimPopper
          {...popperProps}
          totalCount={options.length}
          onViewAllClick={onViewAllClick}
          onCantFindClick={onCantFindClick}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.length]
  );

  return (
    <>
      <StyledAutocomplete
        data-testid="claim-search-autocomplete"
        getOptionLabel={(option) => getOptionLabel(option as PatientClaimDto)}
        onChange={(_, value) => {
          onSelectionChange(value as PatientClaimDto);
        }}
        options={options}
        autoComplete
        openOnFocus={opened}
        size="small"
        loading={isLoading}
        includeInputInList
        value={selectedClaim}
        inputValue={searchTerm}
        disabled={isReadOnly}
        onInputChange={(event, value: string) => {
          if (
            event != null &&
            (event.type === 'change' || event.type === 'click')
          ) {
            onSearch(value);
          }
          setOpened(true);
        }}
        renderOption={(params, option) => (
          <li {...params} style={{ padding: 0 }}>
            <ClaimOption value={option as PatientClaimDto} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            type="text"
            {...params}
            variant="outlined"
            fullWidth={true}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {searchTerm.length > 0 && (
                    <InputAdornment
                      style={{ position: 'absolute', right: '30px' }}
                      position="end"
                    >
                      <StyledIconButton onClick={() => onSearch('')}>
                        <Icon icon={ICONS.Remove} size={20} />
                      </StyledIconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        )}
        PopperComponent={AutocompletePopper}
      />
    </>
  );
};

export default ClaimSearch;
