import { claimColumns } from './claimColumns';

export const ClaimColumnExtension = [
  {
    columnName: claimColumns.claimDate,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: claimColumns.diagnosis,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: claimColumns.performingProvider,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: claimColumns.procedure,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: claimColumns.paidAmount,
    editingEnabled: false,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
];
