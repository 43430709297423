import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type AttachFromEpisodeToEpisodeMutationVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
  targetPatientId: Types.Scalars['Int'];
  targetEpisodeId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type AttachFromEpisodeToEpisodeMutation = {
  __typename?: 'Mutation';
  attachFromEpisodeToEpisode?: {
    __typename?: 'ActionEpisodeResult';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const AttachFromEpisodeToEpisodeDocument = `
    mutation attachFromEpisodeToEpisode($episodeId: Int!, $targetPatientId: Int!, $targetEpisodeId: Int) {
  attachFromEpisodeToEpisode(
    episodeId: $episodeId
    targetPatientId: $targetPatientId
    targetEpisodeId: $targetEpisodeId
  ) {
    success
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    attachFromEpisodeToEpisode: build.mutation<
      AttachFromEpisodeToEpisodeMutation,
      AttachFromEpisodeToEpisodeMutationVariables
    >({
      query: (variables) => ({
        document: AttachFromEpisodeToEpisodeDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAttachFromEpisodeToEpisodeMutation } = injectedRtkApi;
