import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { Button, Divider } from '@mui/material';

interface IStyledButtonGroupItemProps {
  $activeButton: boolean;
}

export const StyledButtonGroupItem = styled(
  Button
)<IStyledButtonGroupItemProps>`
  border: solid 1px ${COLORS.LIGHT_GREY};
  min-width: 87px !important;
  font-weight: 500;
  letter-spacing: 0.25px;
  background-color: inherit;
  color: ${COLORS.SYMPHONY_BLUE};
  ${({ $activeButton }) =>
    $activeButton &&
    `background-color: ${COLORS.PALE_SKY_BLUE};
     border-color: ${COLORS.SYMPHONY_BLUE};
     color: ${COLORS.SYMPHONY_BLUE};
     border: 1px solid ${COLORS.SYMPHONY_BLUE};
     border-right-color: ${COLORS.SYMPHONY_BLUE}!important;
      &:enabled: {
        border-right-color: ${COLORS.SYMPHONY_BLUE}!important;
      };
    `}
`;

export const StyledDivider = styled(Divider)`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  height: 20px;
  background-color: ${COLORS.BLACK};
`;
