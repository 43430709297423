import React from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { TableCell } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { CellComponentStyle } from 'components/gridFormatters';

export const ToggleCellComponent = (props: TableRowDetail.ToggleCellProps) => (
  <TableCell style={CellComponentStyle}>
    <Icon
      size={24}
      icon={ICONS.ArrowDropDown}
      onClick={props.onToggle}
      style={!props.expanded ? { transform: 'rotate(270deg)' } : undefined}
    />
  </TableCell>
);
