import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { mapClockHoursToColor } from 'util/helpers/clockHelper';
import { ClockState } from 'graphql/graphqlTypes';
import moment from 'moment';

const EpisodeClockFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <Box display="flex" alignItems="center">
    {props.value ? (
      <>
        {props.value.state === ClockState.Completed && (
          <Typography style={{ color: COLORS.GREY100 }}>DONE</Typography>
        )}
        {props.value.state === ClockState.Error && (
          <Typography style={{ color: COLORS.GREY100 }}>ERROR</Typography>
        )}
        {props.value.state === ClockState.Paused && (
          <>
            <Icon icon={ICONS.Clock} size={24} color={COLORS.YELLOW100} />
            <Typography style={{ color: COLORS.GREY100, marginLeft: '5px' }}>
              PAUSED
            </Typography>
          </>
        )}
        {props.value.state === ClockState.Active && props.value.dueDateTime && (
          <>
            <Icon
              icon={ICONS.ClockFilled}
              size={18}
              color={mapClockHoursToColor(
                moment(props.value.dueDateTime).toDate()
              )}
            />
            <Typography
              style={{
                color: mapClockHoursToColor(
                  moment(props.value.dueDateTime).toDate()
                ),
                marginLeft: '5px',
              }}
            >
              {props.value.timeRemaining}
            </Typography>
          </>
        )}
        {props.value.state === ClockState.Active &&
          props.value.startDateTime && (
            <>
              <Icon
                icon={ICONS.ClockFilled}
                size={18}
                color={COLORS.SYMPHONY_BLUE}
              />
              <Typography
                style={{
                  color: COLORS.SYMPHONY_BLUE,
                  marginLeft: '5px',
                }}
              >
                {props.value.timeElapsed}
              </Typography>
            </>
          )}
      </>
    ) : null}
  </Box>
);

export default EpisodeClockFormatter;
