import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientConditionsQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientConditionsQuery = {
  __typename?: 'EntitiesQuery';
  getPatientById?: {
    __typename?: 'Patient';
    id: number;
    conditions?: Array<{
      __typename?: 'PatientCondition';
      id: number;
      description?: string | null;
      note?: string | null;
      status: boolean;
      code?: {
        __typename?: 'MedicationCode';
        id: number;
        code?: string | null;
        shortDescription?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export const GetPatientConditionsDocument = `
    query getPatientConditions($patientId: Int!) {
  getPatientById(id: $patientId) {
    id
    conditions {
      id
      code {
        id
        code
        shortDescription
      }
      description
      note
      status
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientConditions: build.query<
      GetPatientConditionsQuery,
      GetPatientConditionsQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientConditionsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientConditionsQuery,
  useLazyGetPatientConditionsQuery,
} = injectedRtkApi;
