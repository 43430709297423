import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { ILoginUser } from 'store/user/types';
import { loginUser } from 'store/user/middlewares';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { IState } from 'store';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { authForgotPassword } from 'store/user/action';

const StyledWrapper = styled.div`
  width: 308px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
`;

const StyledLabel = styled(Typography)({
  color: COLORS.GREY100,
  paddingBottom: '9px',
});

const StyledFormControl = styled.div`
  margin-top: 25px;
`;

const StyledErrorMessage = styled(Typography)({
  color: COLORS.RED100,
  marginTop: '10px',
});

const StyledHeader = styled(Typography)({
  color: COLORS.BLACK,
  fontWeight: 300,
  paddingBottom: '25px',
});
const StyledOutlinedInput = styled(OutlinedInput)({
  '.MuiInputBase-input': {
    height: '40px',
  },
  color: COLORS.BLACK10,
  'input:-internal-autofill-selected': {
    backgroundColor: 'transparent!important',
  },
});

export const LoginForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const isCaptchaRequired = useSelector(
    (state: IState) => state.user.isCaptchaRequired
  );
  const serverError = useSelector((state: IState) => state.user.error);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const dispatch = useDispatch();

  const SITE_KEY = '6Ldx8w8UAAAAAHnVUpgjffcezHWX8Am-BJLRrDJF';

  const onVerifyCaptcha = (token: string | null) => setValue('captcha', token);

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ILoginUser>();
  const onSubmit = (values: ILoginUser) => {
    dispatch(loginUser(values));
    recaptchaRef.current?.reset();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const isValidationError = !!errors.login || !!errors.password;
  let validationErrorMessage: JSX.Element | undefined;
  if (isValidationError) {
    validationErrorMessage = (
      <StyledErrorMessage>Incorrect username or password.</StyledErrorMessage>
    );
  } else if (serverError) {
    validationErrorMessage = (
      <StyledErrorMessage>
        {/* 
          In SSO, errors can contain html elements (<br/>, <b>).
        */}
        {<span dangerouslySetInnerHTML={{ __html: serverError }} />}
      </StyledErrorMessage>
    );
  }

  return (
    <>
      <StyledWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledHeader variant="h2">Log In</StyledHeader>
          {validationErrorMessage}
          <StyledFormControl>
            <StyledLabel>Username</StyledLabel>
            <StyledOutlinedInput
              id="login"
              placeholder="Login"
              type="text"
              style={{ height: '54px' }}
              fullWidth={true}
              {...register('login', { required: true })}
              data-cy="login"
            />
          </StyledFormControl>

          <div style={{ marginTop: '36px', marginBottom: '40px' }}>
            <StyledLabel variant="body1">Password</StyledLabel>
            <StyledOutlinedInput
              id="password"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth={true}
              style={{ height: '54px' }}
              {...register('password', { required: true })}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{ padding: '4px', marginRight: '-4px' }}
                    size="large"
                  >
                    {showPassword ? (
                      <CustomTooltip title="Hide password">
                        <VisibilityOff fontSize="small" />
                      </CustomTooltip>
                    ) : (
                      <CustomTooltip title="Show password">
                        <Visibility fontSize="small" />
                      </CustomTooltip>
                    )}
                  </IconButton>
                </InputAdornment>
              }
              onKeyPress={onKeyPress}
              data-cy="password"
            />
          </div>
          {isCaptchaRequired && (
            <ReCAPTCHA
              sitekey={SITE_KEY}
              onChange={onVerifyCaptcha}
              ref={recaptchaRef}
            />
          )}
          <StyledFormControl>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit(onSubmit)}
                  data-cy="sign-in"
                  style={{ width: '100%' }}
                >
                  Continue
                </Button>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  variant="body1"
                  style={{
                    color: COLORS.SYMPHONY_BLUE,
                    fontWeight: 700,
                    marginTop: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => dispatch(authForgotPassword())}
                >
                  Forget Password?
                </Typography>
              </div>
            </div>
          </StyledFormControl>
        </form>
      </StyledWrapper>
    </>
  );
};
