import React, { useEffect, useState } from 'react';
import { ConditionIcon } from './ConditionIcon';
import {
  getGroupId,
  ICheckBoxRadioSelection,
  useChecklistValidation,
} from '../../Items/Orderable';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { CarePlanIcon } from './CarePlanIcon';
import { ICheckboxRadioButtonProps } from './CheckboxRadioComponent';
import styled from 'styled-components';
import { ComponentStyledLabel } from '../ComponentStyledLabel';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';
import { COLORS } from 'consts/styles';

const StyledFormControlLabel = styled(FormControlLabel)({
  marginLeft: 0,
});

interface IStyleCheckBoxProps {
  $error: boolean;
}

const StyledCheckBox = styled(Checkbox)<IStyleCheckBoxProps>(({ $error }) => ({
  color: $error ? COLORS.RED100 : COLORS.GREY30,
  marginRight: '6px',
}));

const StyledInlineBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const hasError = (
  isActionSelected: boolean,
  required: boolean,
  groupRequired: boolean,
  group: ICheckBoxRadioSelection[],
  isChecked: boolean
) => {
  if (!isActionSelected) {
    return false;
  }

  if (required && !isChecked) {
    return true;
  }

  if (groupRequired) {
    return !group.length;
  }

  return false;
};

const CheckboxButton = (props: ICheckboxRadioButtonProps) => {
  const {
    component,
    categoryId,
    sectionItemId,
    selected,
    hasCondition,
    handleChecklistInputChange,
    isActionSelected,
    storageType,
    autoSave,
    addProblem,
    isReadOnly,
  } = props;

  const name = getGroupId(component, sectionItemId);
  const [isChecked, setIsChecked] = useState<boolean>(component.selected);

  const hasUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    sectionItemId,
    `${component.id}-sel`
  );

  const [error, setError] = useState<boolean>(() =>
    hasError(
      isActionSelected,
      component.required,
      component.groupRequired,
      selected[name] ?? [],
      component.selected
    )
  );

  const errorEffect = () =>
    setError(
      hasError(
        isActionSelected,
        component.required,
        component.groupRequired,
        selected[name] ?? [],
        isChecked
      )
    );
  useEffect(
    errorEffect,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  useEffect(
    errorEffect, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActionSelected]
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);

    handleChecklistInputChange(
      event.target.checked,
      component,
      `${component.id}-sel`
    );

    if (event.target.checked && component.problem && addProblem) {
      addProblem(Number(component.problem));
    }
  };

  useChecklistValidation(
    error,
    autoSave,
    isActionSelected,
    isReadOnly,
    name,
    categoryId,
    component.userDefinedId || component.type || component.componentType,
    'Fill in required checkbox',
    storageType
  );

  const onEditableBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChecklistInputChange(event.target.value, component);
  };

  return (
    <div id={`uuid-${name}`} hidden={component.hidden} style={{ flex: 'auto' }}>
      <StyledInlineBox>
        <StyledFormControlLabel
          value={component.value}
          control={
            <StyledCheckBox
              color="primary"
              checked={isChecked}
              onChange={onChange}
              $error={
                (error && !isReadOnly) || hasUpdateActionValueRequestFailed
              }
              data-testid={`checkbox-${component.value}`}
              disabled={isReadOnly}
            />
          }
          label={
            <ComponentStyledLabel $error={hasUpdateActionValueRequestFailed}>
              {component.editable && !isReadOnly ? null : component.value}
            </ComponentStyledLabel>
          }
          labelPlacement="end"
        />
        {component.editable && !isReadOnly ? (
          <TextField
            variant="outlined"
            onBlur={onEditableBlur}
            defaultValue={component.value}
            style={{ flex: 'auto' }}
          />
        ) : null}
        {hasCondition(component.id) ? <ConditionIcon /> : null}
        {component.problem ? (
          <CarePlanIcon
            problemId={Number(component.problem)}
            storageType={storageType}
          />
        ) : null}
      </StyledInlineBox>
    </div>
  );
};

export default CheckboxButton;
