import React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Dialog, Box } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { SearchResult } from 'graphql/graphqlTypes';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';

const StyledDialog = styled(Dialog)({
  height: '550px',
});

const StyledTitleTypography = styled(Typography)({
  color: COLORS.WHITE,
});

const StyledTypography = styled(Typography)({
  margin: '20px 0px 20px',
});

const StyledButton = styled(Button)({
  minWidth: '150px',
  height: '40px',
  marginRight: '20px',
  '&:hover': {
    minWidth: '150px',
    height: '40px',
  },
});

const StyledDialogContent = styled(DialogContent)({
  height: '500px',
  width: '550px',
  overflowX: 'auto',
  padding: '12px 16px 12px 32px',
});

export interface IMedicationCodeDialogProps {
  medicationCode: SearchResult;
  open: boolean;
  onClose: () => void;
  onAdd: () => void;
}

const MedicationCodeDialog = (props: IMedicationCodeDialogProps) => {
  const { medicationCode, open, onClose, onAdd } = props;

  return (
    <StyledDialog onClose={onClose} open={open} maxWidth="lg">
      <DialogTitle id="medicationCode-dialog" onClose={onClose}>
        <StyledTitleTypography variant="subtitle1">{`Code : ${medicationCode.code}`}</StyledTitleTypography>
      </DialogTitle>
      <StyledDialogContent dividers>
        <Box display="flex" flexDirection="column">
          <StyledTypography variant="h4">Short Description</StyledTypography>
          <Typography>{medicationCode.shortDescription}</Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <StyledTypography variant="h4">Long Description</StyledTypography>
          <Typography>{medicationCode.description}</Typography>
        </Box>
      </StyledDialogContent>
      <DialogActions>
        <Box>
          <StyledButton onClick={onClose} color="primary" variant="outlined">
            Cancel
          </StyledButton>
          <StyledButton onClick={onAdd} color="primary" variant="contained">
            Add
          </StyledButton>
        </Box>
      </DialogActions>
    </StyledDialog>
  );
};

export default MedicationCodeDialog;
