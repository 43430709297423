import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ReminderComponent } from 'graphql/graphqlTypes';
import moment, { Moment } from 'moment';
import { IValidationResult } from 'util/validationUtils';
import { IComponentProps } from '../types';
import { useChecklistValidation } from 'components/actions/sections/SectionBody/Items/Orderable';
import {
  MOMENT_DATETIME_ISO_FORMAT,
  MOMENT_ISO_FORMAT,
  MOMENT_TIME_FORMAT,
  MOMENT_DATETIME_FORMAT,
} from 'components/constants';
import { validateDate } from './index.helpers';
import { ChecklistStorageType } from 'store/actions/types';
import { StyledWrapper } from './index';
import styled from 'styled-components';
import { usePrevious } from 'hooks';
import { isEqual } from 'date-fns';
import DateComponent from 'components/DateComponent';
import TimeComponent from 'components/TimeComponent';
import { getCareSiteNow } from 'util/helpers/dateTimeHelpers';

export interface IReminderProps extends IComponentProps<ReminderComponent> {
  disabled?: boolean;
  autoSave: boolean;
  isReadOnly: boolean;
}

interface ReminderDateTimeProps {
  component: ReminderComponent;
  disabled?: boolean;
  autoSave: boolean;
  isReadOnly: boolean;
  isActionSelected: boolean;
  categoryId: string;
  storageType: ChecklistStorageType;
  handleReminderInputChange: (
    value: string | Date | null,
    reminderComponentType: string
  ) => void;
}

const StyledInnerWrapper = styled.div`
  margin-right: 10px;
`;
const StyledTypography = styled(Typography)`
  color: ${COLORS.BLACK};
`;

const StyledDiv = styled.div`
  margin-right: 8px;
`;

export const DefaultReminderTime = '17:00';

const ReminderDateTime = (props: ReminderDateTimeProps) => {
  const {
    component,
    disabled,
    isActionSelected,
    categoryId,
    storageType,
    autoSave,
    isReadOnly,
    handleReminderInputChange,
  } = props;

  const reduxDateDue = useSelector(
    (state: IState) =>
      (
        state.checklist.documentsState[storageType]?.checklistComponents[
          component.uniqueID
        ]?.component as ReminderComponent
      )?.dateDue
  );

  const getInitDueDateValue = () => {
    if (reduxDateDue) {
      return new Date(reduxDateDue);
    }
    if (component.dateDue) {
      return moment(component.dateDue).toDate();
    }
    return null;
  };

  const initialValue = getInitDueDateValue();
  const [dateDue, setDateDue] = useState<Date | null>(initialValue ?? null);
  const [timeDue, setTimeDue] = useState<Date | null>(initialValue ?? null);

  const [dateValidation, setDateValidation] = useState<IValidationResult>(
    validateDate(dateDue, isActionSelected)
  );

  const [timeValidation, setTimeValidation] = useState<IValidationResult>(
    validateDate(timeDue, isActionSelected)
  );

  const [fullValueError, setFullValueError] = useState(
    validateDate(dateDue, isActionSelected).hasError ||
      validateDate(timeDue, isActionSelected).hasError
  );

  const previousDateDue = usePrevious(dateDue);
  const previousReduxDate = usePrevious(reduxDateDue);

  useEffect(() => {
    if (
      isEqual(
        new Date(previousDateDue as unknown as Date),
        new Date(reduxDateDue as unknown as Date)
      ) ||
      isEqual(
        new Date(previousReduxDate as unknown as Date),
        new Date(reduxDateDue as unknown as Date)
      )
    ) {
      return;
    }
    if (!previousDateDue && !reduxDateDue) {
      return;
    }
    if (
      component.dateDue &&
      moment(reduxDateDue).format(MOMENT_DATETIME_ISO_FORMAT).toString() ===
        moment(component.dateDue).format(MOMENT_DATETIME_ISO_FORMAT).toString()
    ) {
      return;
    }

    if (reduxDateDue !== undefined) {
      const date = reduxDateDue ? new Date(reduxDateDue) : null;
      handleValidation(date);
      setDateDue(date);
      setTimeDue(date);
      saveDate(date, date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDateDue]);

  useChecklistValidation(
    (dateValidation.hasError || timeValidation.hasError || fullValueError) &&
      isActionSelected,
    autoSave,
    isActionSelected,
    isReadOnly,
    component.uniqueID + '-date',
    categoryId,
    component.userDefinedId || component.componentType,
    dateValidation.message ?? 'Reminder date is invalid',
    storageType
  );

  useEffect(() => {
    const resultDate = validateDate(dateDue, isActionSelected);
    setDateValidation(resultDate);

    const resultTime = validateDate(timeDue, isActionSelected);
    setTimeValidation(resultTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionSelected]);

  const careSiteNow = getCareSiteNow().toDate();

  const withChangedTime = (dateTime: Date | null, time: Date) => {
    if (dateTime) {
      const dateWithNewTime = new Date(dateTime);
      dateWithNewTime.setHours(
        time.getHours(),
        time.getMinutes(),
        time.getSeconds()
      );
      return dateWithNewTime;
    }
    return null;
  };

  const handleValidation = (date: Date | null): IValidationResult => {
    const result = validateDate(date, isActionSelected);

    setFullValueError(result.hasError);

    return result;
  };

  const getSelectedDateTime = (
    selectedDate: Date | null,
    selectedTime: Date | null
  ): Moment => {
    const date = moment(selectedDate).format(MOMENT_ISO_FORMAT);
    const time =
      selectedTime === null
        ? DefaultReminderTime
        : moment(selectedTime).format(MOMENT_TIME_FORMAT);
    return moment(`${date} ${time}`, MOMENT_DATETIME_ISO_FORMAT, true);
  };

  const saveDate = (selectedDate: Date | null, selectedTime: Date | null) => {
    if (!selectedDate) {
      handleReminderInputChange('', 'DateDue');
      return;
    }

    const selectedDateTime = getSelectedDateTime(selectedDate, selectedTime);
    const { hasError } = handleValidation(selectedDateTime.toDate());

    const date = moment(selectedDateTime).format(MOMENT_ISO_FORMAT);
    const time = moment(selectedDateTime).format(MOMENT_TIME_FORMAT);
    const formattedDateTime = `${date}T${time}`;

    if (!hasError) {
      handleReminderInputChange(formattedDateTime, 'DateDue');
    }
  };

  const handleDateChange = (date: Date | null) => {
    setDateDue(date as Date);
    const dateResult = validateDate(date as Date, isActionSelected);
    const timeResult = validateDate(timeDue, isActionSelected, false);

    setDateValidation(dateResult);
    setTimeValidation(timeResult);

    if (date && !dateResult.hasError && !timeResult.hasError) {
      const time = timeDue ? withChangedTime(careSiteNow, timeDue) : null;
      saveDate(date, time);
    }
  };

  const handleTimeChange = (time: Date | null) => {
    setTimeDue(time);

    const timeResult = validateDate(time as Date, isActionSelected, false);
    const dateResult = validateDate(dateDue, isActionSelected);

    setDateValidation(dateResult);
    setTimeValidation(timeResult);

    if (!timeResult.hasError && !dateResult.hasError) {
      saveDate(dateDue, time);
    }
  };

  const isError = (
    fieldError: boolean,
    fullValueError: boolean,
    isActionSelected: boolean,
    isReadOnly: boolean
  ) => (fieldError || fullValueError) && !isReadOnly && isActionSelected;

  const isDateError = isError(
    dateValidation.hasError,
    fullValueError,
    isActionSelected,
    isReadOnly
  );

  const isTimeError = isError(
    timeValidation.hasError,
    fullValueError,
    isActionSelected,
    isReadOnly
  );

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledTypography variant="body1">
          Date/Time due{disabled && ':'}
        </StyledTypography>
      </StyledInnerWrapper>
      <StyledWrapper id={`uuid-${component.uniqueID}-date-time`}>
        {!disabled ? (
          <>
            <StyledDiv>
              <DateComponent
                readOnly={isReadOnly}
                testId="reminder-date-due-input"
                value={dateDue}
                placeholder="mm/dd/yyyy"
                onChange={handleDateChange}
                error={isDateError}
              />
            </StyledDiv>
            <TimeComponent
              value={timeDue}
              onChange={handleTimeChange}
              timeFormat={MOMENT_TIME_FORMAT}
              isReadOnly={isReadOnly}
              testId="reminder-date-time-due-input"
              placeholder="hh:mm"
              required={false}
              useSeconds={false}
              error={isTimeError}
            />
          </>
        ) : (
          <Typography variant="body2">
            {moment(getSelectedDateTime(dateDue, timeDue)).format(
              MOMENT_DATETIME_FORMAT
            )}
          </Typography>
        )}
      </StyledWrapper>
    </StyledWrapper>
  );
};

export default ReminderDateTime;
