import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientCareTeamTabQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientCareTeamTabQuery = {
  __typename?: 'EntitiesQuery';
  getPatientCareTeamTab: {
    __typename?: 'PatientCareTeamTabViewModel';
    isCanChangeOwner: boolean;
    teamMembers: Array<{
      __typename?: 'PatientCareTeamMemberViewModel';
      id: number;
      isOtherCareSite: boolean;
      isOwner: boolean;
      note?: string | null;
      updatedOn?: any | null;
      member: { __typename?: 'User'; id: number; fullName?: string | null };
    }>;
    family: Array<{
      __typename?: 'PatientCareTeamFamilyViewModel';
      id: number;
      consentToShare: boolean;
      isCaregiver: boolean;
      name?: string | null;
      note?: string | null;
      relationshipId?: number | null;
    }>;
    providers: Array<{
      __typename?: 'PatientCareTeamProviderViewModel';
      id: number;
      isPrimary: boolean;
      note?: string | null;
      provider: {
        __typename?: 'Provider';
        id: number;
        name?: string | null;
        speciality?: {
          __typename?: 'LookupValue';
          name?: string | null;
        } | null;
      };
    }>;
    owner: {
      __typename?: 'PatientCareTeamOwnerViewModel';
      name: string;
      required: boolean;
    };
    relationships: Array<{
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    }>;
    specialties: Array<{
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    }>;
    users: Array<{
      __typename?: 'PatientCareTeamCareSiteUserViewModel';
      id: number;
      isOtherCareSite: boolean;
      name?: string | null;
      roleName?: string | null;
      stateGreen: number;
      stateOrange: number;
      stateRed: number;
      stateYellow: number;
      updatedOn?: any | null;
    }>;
  };
};

export const GetPatientCareTeamTabDocument = `
    query getPatientCareTeamTab($patientId: Int!) {
  getPatientCareTeamTab(patientId: $patientId) {
    isCanChangeOwner
    teamMembers {
      id
      member {
        id
        fullName
      }
      isOtherCareSite
      isOwner
      note
      updatedOn
    }
    family {
      id
      consentToShare
      isCaregiver
      name
      note
      relationshipId
    }
    providers {
      id
      isPrimary
      provider {
        id
        name
        speciality {
          name
        }
      }
      note
    }
    owner {
      name
      required
    }
    relationships {
      id
      name
    }
    specialties {
      id
      name
    }
    users {
      id
      isOtherCareSite
      name
      roleName
      stateGreen
      stateOrange
      stateRed
      stateYellow
      updatedOn
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientCareTeamTab: build.query<
      GetPatientCareTeamTabQuery,
      GetPatientCareTeamTabQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientCareTeamTabDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientCareTeamTabQuery,
  useLazyGetPatientCareTeamTabQuery,
} = injectedRtkApi;
