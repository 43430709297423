import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ErrorCode, FileError } from 'react-dropzone';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import { getAttachmentIcon } from 'components/home/attachmentsList/columnFormatters/attachmentTypeCell';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { IUploadedFileResponse, uploadFile } from './uploadFile';
import { formatBytes } from './fileUpload.helper';
import LinearProgressPercentage from './linearProgressPercentage';
import { ISelectItem, Select } from 'components/select';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ILookupValue } from 'graphql/graphqlTypes';

const FileSizeText = styled(Typography)({
  color: COLORS.GREY60,
});

const FileIcon = styled(Icon)({
  paddingLeft: '4px',
});

const StyledFormControl = styled.div`
  max-width: 300px;
  text-align: left;
  margin-bottom: 29px;
  margin-top: 1px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
`;

export interface FileUploadItemProps {
  file: File;
  name: string | undefined;
  extension: string | undefined;
  errors?: FileError[];
  onDelete: (file: File) => void;
  onUpload: (
    file: File,
    fileId: string,
    name?: string,
    createdOn?: Date
  ) => void;
  index: number;
  updateFileName: (file: File, newName: string) => void;
  updateCategory: (file: File, newFileName: string, categoryId: number) => void;
}

const FileUploadItem = (props: FileUploadItemProps) => {
  const {
    file,
    name,
    extension,
    errors,
    onDelete,
    onUpload,
    updateFileName,
    updateCategory,
  } = props;

  const [progress, setProgress] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<IUploadedFileResponse>();
  const fileName = file.name;

  const fileExtension = fileName.split('.').pop();

  const { icon, tooltip } = getAttachmentIcon(fileExtension!);
  const fileSize = formatBytes(file.size);

  const [attachmentName, setAttachmentName] = useState(name || '');
  const [attachmentCategory, setAttachmentCategory] = useState<string | null>(
    null
  );

  const [nameError, setNameError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);

  useEffect(() => {
    setNameError(!attachmentName);
    setCategoryError(!attachmentCategory);
  }, [attachmentName, attachmentCategory]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;

    if (newName.includes('.')) {
      return;
    }

    setAttachmentName(newName);
    updateFileName(file, newName); // Call updateFileName callback
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value;
    setAttachmentCategory(value);
    updateCategory(file, attachmentName, parseInt(value, 10));
  };

  const getErrorMessage = (fileError: FileError) => {
    switch (fileError.code) {
      case ErrorCode.FileInvalidType:
        return 'This file type is not supported';
      case ErrorCode.FileTooLarge:
        return 'Exceeding the maximum file size';
      default:
        return fileError.message;
    }
  };

  const errorMessages =
    !uploadedFile?.isSuccess && uploadedFile?.message
      ? [{ id: 0, message: uploadedFile?.message }]
      : errors?.map((x, index) => {
          return {
            id: index,
            message: getErrorMessage(x),
          };
        });

  useEffect(() => {
    if (!errors) {
      uploadFile(file, onUploadProgress, onUploadSuccess, onUploadError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const onUploadProgress = (percentage: number) => {
    setProgress(percentage);
  };

  const onUploadSuccess = (uploadedFile: IUploadedFileResponse) => {
    if (uploadedFile?.isSuccess) {
      onUpload(
        file,
        uploadedFile?.fileId ?? '',
        uploadedFile?.name,
        uploadedFile?.createdOn
      );
    }
    setUploadedFile(uploadedFile);
  };

  const onUploadError = (uploadedFile: IUploadedFileResponse) => {
    setUploadedFile(uploadedFile);
  };

  const handleDelete = useCallback(() => {
    onDelete(file);
  }, [file, onDelete]);

  const mapToDropdownItem = (lv: ILookupValue): ISelectItem => {
    return {
      id: lv.id.toString(),
      name: lv.name || '',
    };
  };

  const mainModel = useSelector((state: IState) => state.home.mainModel);
  const attachmentCategories =
    mainModel?.lookups
      ?.filter((x) => x.name === 'AttachmentCategory')[0]
      ?.values.filter((lv) => !lv.isDeleted)
      .map((lv: ILookupValue) => mapToDropdownItem(lv)) ?? [];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <CustomTooltip title={tooltip}>
            <div>
              <FileIcon testId="file-type-icon" icon={icon} size={28} />
            </div>
          </CustomTooltip>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="input-with-icon-adornment"
            data-testid="attachment-name"
            size="small"
            type="text"
            variant="outlined"
            placeholder="Search"
            style={{ width: 285 }}
            value={attachmentName}
            onChange={handleNameChange}
            error={nameError}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">{extension}</Typography>
        </Grid>
        <Grid item xs={3}>
          <StyledFormControl>
            <Select
              data-testid="attachment-category-filter"
              value={attachmentCategory}
              onChange={handleSelectChange}
              items={attachmentCategories.map(({ id, name }) => ({
                id: id,
                name: name,
              }))}
              size="small"
              fullWidth
              error={categoryError}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={2}>
          <FileSizeText variant="h6">{fileSize}</FileSizeText>
        </Grid>
        <Grid item xs={1}>
          <CustomTooltip title="Delete">
            <div>
              <IconButton
                data-testid="delete-file-icon"
                onClick={handleDelete}
                size="large"
              >
                <Icon size={18} icon={ICONS.Bin} />
              </IconButton>
            </div>
          </CustomTooltip>
        </Grid>
      </Grid>
      <ProgressBarContainer>
        {!errorMessages && progress < 100 && (
          <LinearProgressPercentage value={progress} />
        )}
        {errorMessages && (
          <LinearProgressPercentage value={100} errors={errorMessages} />
        )}
      </ProgressBarContainer>
    </>
  );
};

export default FileUploadItem;
