import { EpisodesTabChecklist } from 'graphql/graphqlTypes';

export const MEMBER_NOTES_COLUMNS = [
  {
    name: 'started',
    title: 'Started',
    getCellValue: (row: EpisodesTabChecklist) => row?.checklist?.createdOn,
  },
  {
    name: 'situation',
    title: 'Situation',
    getCellValue: (row: EpisodesTabChecklist) => ({
      checklistId: row?.checklist?.id ?? 0,
    }),
  },
  {
    name: 'author',
    title: 'Author',
    getCellValue: (row: EpisodesTabChecklist) =>
      row?.checklist?.createdByUser?.fullName,
  },
  {
    name: 'reason',
    title: 'Reason',
    getCellValue: (row: EpisodesTabChecklist) =>
      row?.checklist?.reasonForNote?.name,
  },
  {
    name: 'operations',
    title: ' ',
    getCellValue: (row: EpisodesTabChecklist) =>
      row?.checklist?.reasonForNote?.name,
  },
];
export const MEMBER_NOTES_COLUMNS_EXTENSIONS = [
  { columnName: 'started', sortingEnabled: false, width: 150 },
  {
    columnName: 'situation',
    sortingEnabled: false,
    width: 180,
  },
  { columnName: 'author', sortingEnabled: false, width: 220 },
  { columnName: 'reason', sortingEnabled: false, width: 150 },
  { columnName: 'actions', sortingEnabled: false, width: 190 },
];
