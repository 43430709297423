import { IValidationResult } from 'util/validationUtils';

export const getDateErrorMessage = (
  attributeComparisonError: IValidationResult
): string => {
  let errorMessage = 'Fill in required date';

  if (attributeComparisonError.hasError) {
    errorMessage = attributeComparisonError.message ?? errorMessage;
  }

  return errorMessage;
};
