import {
  ChecklistItem,
  ChecklistItemDuplicateCheck,
  DuplicateCheckActionValue,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';
import { INDIVIDUAL_COMPONENT_CODE } from 'components/constants';

export const useSaveDuplicateCheck = () => {
  const { updateActionValueExtended } = useUpdateActionValue();
  return (
    value: DuplicateCheckActionValue,
    checklistId: string | undefined,
    item: ChecklistItemDuplicateCheck,
    autoSave: boolean,
    updateChecklistItemInputValueOnSave?: (
      item: ChecklistItem,
      index: number,
      data: UpdateActionValueModelInput
    ) => void,
    orderableIndex?: number
  ) => {
    const saveData = {
      checklistId: Number(checklistId) ?? 0,
      componentId: INDIVIDUAL_COMPONENT_CODE,
      id: item.uid,
      type: 'string',
      value: JSON.stringify(value),
    };
    if (autoSave) {
      updateActionValueExtended(saveData, item.uid);
    } else if (updateChecklistItemInputValueOnSave !== undefined) {
      updateChecklistItemInputValueOnSave(item, orderableIndex ?? 0, saveData);
    }
  };
};
