import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import { SpanYellow, StyledWrapper, SpanGreen } from './styles';
import { DecisionAction } from 'components/actions/sections/SectionBody/Items/HealthService/types';

export const OverallRNReview = (props: {
  episodeNumber?: string | null;
  isOverallStatusApproved?: boolean;
  overallStatus: string;
  isOverallStatus?: boolean;
}) => {
  const {
    episodeNumber,
    isOverallStatusApproved,
    overallStatus,
    isOverallStatus,
  } = props;
  return (
    <>
      {!isOverallStatus && (
        <>
          <Typography variant="body1" data-testid="rnreview">
            Episode ID:
          </Typography>
          <Typography variant="body2">{episodeNumber}</Typography>
        </>
      )}

      <Typography variant="body1" style={{ display: 'contents' }}>
        {!isOverallStatus && <>Overall Status:</>}
        {isOverallStatusApproved ? (
          <SpanGreen>
            <StyledWrapper data-testid="Stoplight_completed">
              <Icon
                icon={ICONS.Stoplight_completed}
                size={18}
                color={COLORS.GREEN100}
                style={{ marginRight: '3px' }}
              />
              Approved
            </StyledWrapper>
          </SpanGreen>
        ) : (
          <SpanYellow
            style={
              overallStatus === DecisionAction.denied
                ? { backgroundColor: COLORS.RED20 }
                : {}
            }
          >
            <StyledWrapper data-testid="Stoplight_incompleted">
              <Icon
                icon={
                  overallStatus === DecisionAction.denied
                    ? ICONS.Denied
                    : ICONS.Stoplight_incompleted
                }
                size={18}
                color={
                  overallStatus === DecisionAction.denied
                    ? COLORS.RED100
                    : COLORS.YELLOW100
                }
                style={{ marginRight: '4px' }}
              />
              {overallStatus}
            </StyledWrapper>
          </SpanYellow>
        )}
      </Typography>
    </>
  );
};
