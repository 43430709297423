import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SaveRecentSearchesMutationVariables = Types.Exact<{
  value: Types.Scalars['String'];
}>;

export type SaveRecentSearchesMutation = {
  __typename?: 'Mutation';
  saveRecentSearches?: boolean | null;
};

export const SaveRecentSearchesDocument = `
    mutation saveRecentSearches($value: String!) {
  saveRecentSearches(currentValue: $value)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveRecentSearches: build.mutation<
      SaveRecentSearchesMutation,
      SaveRecentSearchesMutationVariables
    >({
      query: (variables) => ({
        document: SaveRecentSearchesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSaveRecentSearchesMutation } = injectedRtkApi;
