import React, { useLayoutEffect, useRef, useState } from 'react';
import { Grid as DataGrid, Table } from '@devexpress/dx-react-grid-material-ui';
import {
  Column,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { PatientRisk } from 'graphql/graphqlTypes';
import { CHARTS_PALETTE, COLORS } from 'consts/styles';
import {
  riskScoresColumnNames,
  riskScoresColumnsExtensionSorting,
  riskScoresColumnsExtensionStyles,
} from './riskScores';
import { take } from 'lodash';
import {
  ArgumentAxis,
  Chart,
  Legend,
  LineSeries,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Palette } from '@devexpress/dx-react-chart';
import { StyledButtonGroupItem } from 'components/actions/sections/SectionBody/Items/HealthService/styles';

interface IGridProps<T> {
  title: string;
  getRowId: (item: T) => number;
  rows: T[];
  columns: Column[];
  labelCell: React.ComponentType<Table.DataCellProps>;
}

const Label = (props: Legend.LabelProps) => (
  <Typography variant="caption" style={{ marginLeft: '10px' }}>
    {props.text}
  </Typography>
);

const RiskScoresView = <T extends PatientRisk>(props: IGridProps<T>) => {
  const { title, getRowId, rows, columns, labelCell } = props;
  const [chartWidth, setChartWidth] = useState<number>(0);
  const boxRef = useRef<HTMLDivElement>(null);
  const [showGrid, setShowGrid] = useState(true);
  const [displayRows, setDisplayRows] = useState<PatientRisk[]>(take(rows, 3));
  const [charRows, setChartRows] = useState<PatientRisk[]>(
    take(rows, 3).reverse()
  );
  const [showMore, setShowMore] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      setChartWidth(width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxRef.current]);

  return (
    <Grid container ref={boxRef}>
      <Box mb="32px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="16px"
        >
          <Typography variant="subtitle1">{title}</Typography>
          <Box>
            <ButtonGroup aria-label="outlined primary button group">
              <StyledButtonGroupItem
                onClick={() => setShowGrid(true)}
                size="small"
                variant="outlined"
                $activeButton={showGrid}
              >
                Table View
              </StyledButtonGroupItem>
              <StyledButtonGroupItem
                onClick={() => setShowGrid(false)}
                size="small"
                variant="outlined"
                $activeButton={!showGrid}
              >
                Chart View
              </StyledButtonGroupItem>
            </ButtonGroup>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="16px"
          width={'100%'}
        >
          {showGrid ? (
            <DataGrid rows={displayRows} columns={columns} getRowId={getRowId}>
              <SortingState
                defaultSorting={[
                  {
                    columnName: riskScoresColumnNames.createdOn,
                    direction: 'desc',
                  },
                ]}
                columnExtensions={riskScoresColumnsExtensionSorting}
              />
              <IntegratedSorting />
              <SymphonyTable
                cellComponent={labelCell}
                columnExtensions={riskScoresColumnsExtensionStyles}
              />
              <SymphonyTableHeaderRow showSortingControls />
            </DataGrid>
          ) : (
            <Paper>
              <Chart data={charRows} width={chartWidth}>
                <Palette scheme={CHARTS_PALETTE} />
                <ArgumentAxis />
                <ValueAxis />
                <LineSeries
                  valueField="ip"
                  name="IP Score"
                  argumentField="createdOn"
                />
                <LineSeries
                  valueField="eR"
                  name="ER Score"
                  argumentField="createdOn"
                />
                <LineSeries
                  valueField="op"
                  name="OP Score"
                  argumentField="createdOn"
                />
                <LineSeries
                  valueField="phy"
                  name="PHY Score"
                  argumentField="createdOn"
                />
                <LineSeries
                  valueField="rx"
                  name="RX Score"
                  argumentField="createdOn"
                />
                <LineSeries
                  valueField="med"
                  name="Med Score"
                  argumentField="createdOn"
                />
                <LineSeries
                  valueField="brx"
                  name="Part B RX Score"
                  argumentField="createdOn"
                />
                <LineSeries
                  valueField="oth"
                  name="Other"
                  argumentField="createdOn"
                />
                <LineSeries
                  valueField="tot"
                  name="Total Score"
                  argumentField="createdOn"
                />
                <Legend labelComponent={Label} />
              </Chart>
            </Paper>
          )}
        </Box>
        {rows.length > 3 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="16px"
          >
            {showMore ? (
              <Button
                onClick={() => {
                  setShowMore(false);
                  setDisplayRows(take(rows, 3));
                  setChartRows(take(rows, 3).reverse());
                }}
                size="small"
                style={{ color: COLORS.SYMPHONY_BLUE }}
                variant="text"
              >
                {'Show less'}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setShowMore(true);
                  setDisplayRows(take(rows, 12));
                  setChartRows(take(rows, 12).reverse());
                }}
                size="small"
                variant="text"
                style={{ color: COLORS.SYMPHONY_BLUE }}
              >
                {'Show more'}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default RiskScoresView;
