import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Paper, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import {
  GetPatientAttachmentsQuery,
  useLazyGetPatientAttachmentsQuery,
} from 'graphql/hooks/getPatientAttachments';
import { Attachment } from 'graphql/graphqlTypes';
import { IState } from 'store';
import { ITab } from 'store/patientdetails/types';
import {
  commitAttachments,
  updateSelectedAttachmentIds,
} from 'store/patientdetails/patientDetails.attachment.slice';
import { useData } from 'components/gridFormatters';
import AttachmentsGrid from 'components/home/attachmentsList/attachmentsGrid';
import AntTab from 'components/tabs/AntTab';
import FileUpload from 'components/fileUpload/fileUpload';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const StyledPaper = styled(Paper)({
  border: `1px solid ${COLORS.GREY25}`,
  marginTop: '-1px',
  width: '100%',
  display: 'flex',
});

const StyledPanel = styled(TabPanel)({
  width: '100%',
  minHeight: 'calc(100vh - 20rem)',
});

const AttachFilesDialogBody = (props: { patientId: number }) => {
  const { patientId } = props;

  const tabs: ITab[] = [
    { index: 1, name: 'MemberFiles', title: 'Member Files', readOnly: false },
    { index: 2, name: 'Upload', title: 'Upload', readOnly: false },
  ];
  const acceptFileTypes = {
    'application/pdf': ['.pdf'],
    'image/tiff': ['.tif', '.tiff'],
  };
  const [activeTabName, setActiveTabName] = useState<string>('MemberFiles');
  const allowedFileTypes = Object.values(acceptFileTypes)
    .flatMap((x) => x)
    .map((x) => x.replace('.', ''));

  const dispatch = useDispatch();

  const handleChange = useCallback(
    (_event: React.ChangeEvent<unknown>, newValue: string) => {
      setActiveTabName(newValue);
      dispatch(updateSelectedAttachmentIds([]));
    },
    [dispatch]
  );

  const { data, isFetching } = useData(
    (state: IState) => state.patientDetailsAttachment.attachmentsTab,
    useLazyGetPatientAttachmentsQuery,
    { patientId },
    (data: GetPatientAttachmentsQuery) =>
      commitAttachments({
        patientId,
        episodeId: undefined,
        changeSet: { added: data.getPatientAttachments as Attachment[] },
      }),
    !patientId
  );

  const attachmentData = data?.filter((x) =>
    allowedFileTypes.includes(x.fileExtension)
  );

  return (
    <StyledWrapper>
      {tabs && tabs.length > 0 ? (
        <>
          <TabContext value={activeTabName}>
            <Tabs
              value={activeTabName}
              onChange={handleChange}
              selectionFollowsFocus
              aria-label="main grid"
            >
              {tabs.map((item, index) => (
                <AntTab
                  key={item.index}
                  label={item.title ?? item.name}
                  value={item.name}
                  isLast={tabs.length === index + 1}
                  isFirst={index === 0}
                />
              ))}
            </Tabs>
            <StyledPaper square={true} elevation={0}>
              <TabPanel value="MemberFiles">
                <AttachmentsGrid
                  attachments={attachmentData ?? []}
                  isFetching={isFetching}
                  showInstantSearch
                  readonly
                  canSelectRow
                />
              </TabPanel>
              <StyledPanel value="Upload">
                <FileUpload allowMultiple accept={acceptFileTypes} />
              </StyledPanel>
            </StyledPaper>
          </TabContext>
        </>
      ) : null}
    </StyledWrapper>
  );
};

export default AttachFilesDialogBody;
