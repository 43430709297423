import React, { useMemo } from 'react';
import { MenuItem, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { IProvider } from 'components/providerSearchDialog/types';
import { getParStateDisplayValue } from 'util/helpers/provider.helpers';

const StyledMenuItem = styled(MenuItem)({
  padding: '2px 20px',
  display: 'block',
  width: '100%',
  maxWidth: '440px',
  '&.Mui-selected': {
    backgroundColor: COLORS.WHITE,
  },
});

const StyledListItemContainer = styled.ul`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexBox = styled.div`
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 98%;
`;

const AlignedFlexBox = styled(FlexBox)`
  align-items: center;
  padding-left: 2px;
`;

const FlexWrapBox = styled(FlexBox)`
  flex-wrap: wrap;
`;

const AddressWrapper = styled.div`
  display: inline;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledTypography = styled(Typography)({
  marginTop: '0px',
  marginBottom: '0px',
  marginLeft: '3px',
  marginRight: '3px',
});

const ProviderIcon = (option: IProvider) => {
  return useMemo(() => {
    if (option.isFavorite) {
      return (
        <Icon
          icon={ICONS.Filled}
          size={14}
          color={COLORS.YELLOW100}
          tooltipContainerElement="span"
        />
      );
    }
    if (option.isRecent) {
      return (
        <Icon
          icon={ICONS.Update}
          size={14}
          color={COLORS.GREY100}
          tooltipContainerElement="span"
        />
      );
    }
    return <div style={{ width: '14px' }} />;
  }, [option.isRecent, option.isFavorite]);
};

export const ProviderOption = (option: IProvider) => {
  const parDisplayValue = getParStateDisplayValue(option?.par);
  return (
    <StyledListItemContainer>
      <StyledMenuItem>
        <FlexBox>
          <div style={{ paddingRight: '7px' }}>
            <ProviderIcon {...option} />
          </div>
          <FlexWrapBox>
            <StyledTypography
              variant="body2"
              style={{
                fontWeight: 500,
              }}
            >
              {option.firstName} {option.lastName}
            </StyledTypography>
            <StyledTypography
              variant="body2"
              style={{
                fontWeight: 400,
              }}
            >
              {option.providerId}
            </StyledTypography>
            <Icon
              icon={ICONS.Critical}
              size={10}
              height={'15'}
              color={COLORS.GREY100}
            />
            <StyledTypography
              variant="body2"
              style={{
                fontWeight: 400,
              }}
            >
              {option.nPI}
            </StyledTypography>
          </FlexWrapBox>
        </FlexBox>
        <FlexBox>
          <div style={{ minWidth: '20px' }} />
          <AlignedFlexBox>
            <StyledTypography
              variant="body2"
              style={{
                fontWeight: 400,
                color: `${COLORS.GREY100}`,
                fontSize: '12px',
              }}
            >{`PAR ${parDisplayValue}`}</StyledTypography>
            <Icon
              icon={ICONS.Critical}
              size={10}
              height="15"
              color={COLORS.GREY100}
            />
            <AddressWrapper>
              <StyledTypography
                variant="body2"
                noWrap
                style={{
                  maxWidth: 310,
                  fontWeight: 400,
                  color: `${COLORS.GREY100}`,
                  fontSize: '12px',
                }}
              >
                {option.address}
              </StyledTypography>
            </AddressWrapper>
          </AlignedFlexBox>
        </FlexBox>
      </StyledMenuItem>
    </StyledListItemContainer>
  );
};
