import React from 'react';
import { COLORS } from 'consts/styles';

interface IErrorProps {
  children: React.ReactNode;
}

const ErrorLabel = (props: IErrorProps) => {
  const { children } = props;
  return <p style={{ color: COLORS.RED100 }}>{children}</p>;
};

export default ErrorLabel;
