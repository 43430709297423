import React from 'react';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Panel from 'components/panel';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import { openChecklist } from 'store/ui/modals/checklist';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import styled from 'styled-components';

const StyledScenarioBox = styled.div`
  margin-top: 25px;
  padding: 21.5px 30px 21.5px 33px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: ${COLORS.GREY4};
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Typography)({
  fontSize: '22px',
  letterSpacing: 0.15,
  lineHeight: '24px',
  fontWeight: 500,
  paddingLeft: '5px',
});
const StyledEpisodeBox = styled.div`
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 14px 18px 18px 115px;
`;

const FinishHeader = () => {
  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST].checklist
  );
  const dispatch = useDispatch();

  return (
    <>
      <Panel padding="32px 24px" isHighlighted>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              marginRight: '9px',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <Icon
              icon={ICONS.ProgressCompleted}
              size={24}
              color={COLORS.GREEN100}
            />
          </Box>
          <Box>
            <StyledTitle variant="h4">Document completed!</StyledTitle>
          </Box>
        </Box>
        <>
          <StyledScenarioBox>
            <Typography variant="h3" style={{ fontWeight: 500 }}>
              {checklist?.scenarioName}
            </Typography>
            <StyledEpisodeBox>
              <Typography align={'right'}>Episode ID:</Typography>
              <Typography variant="h4" align={'right'}>
                {checklist?.episodeNumber || ''}
              </Typography>
            </StyledEpisodeBox>
          </StyledScenarioBox>
          <Box sx={{ marginTop: '20px' }}>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              data-cy="return-home"
              onClick={() =>
                dispatch(openChecklist({ checklistId: Number(checklist?.id) }))
              }
            >
              View Document
            </Button>
          </Box>
        </>
      </Panel>
    </>
  );
};

export default FinishHeader;
