import React from 'react';
import { LoginLeft } from './LoginLeft';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

const StyledContentWrapper = styled.div`
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
`;

const StyledFormArea = styled.div`
  width: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

interface ISignInFormProps {
  children: React.ReactNode;
  step: string;
}

export const LoginBody = (props: ISignInFormProps) => {
  const { children, step } = props;
  return (
    <StyledContentWrapper>
      <LoginLeft />
      <StyledFormArea
        style={{
          justifyContent: step === 'roles' ? 'flex-start' : 'center',
        }}
      >
        {children}
      </StyledFormArea>
    </StyledContentWrapper>
  );
};
