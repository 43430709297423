import {
  COMPONENT_STYLE_CUSTOM,
  COMPONENT_STYLE_SMALL,
  COMPONENT_STYLE_WIDE,
} from 'components/constants';
import { getTextWidths } from 'util/helpers/canvasHelpers';

const calculateWidth = (
  options: string[],
  minTextWidth: number,
  rightPadding: number,
  font?: string | null
): number => {
  const optionWidths = getTextWidths(options, font);
  return Math.max(...optionWidths, minTextWidth - rightPadding) + rightPadding;
};

export const checklistItemComponentWidth = (
  componentWidth: number,
  componentStyle?: string
) => {
  if (componentStyle === COMPONENT_STYLE_CUSTOM) {
    return `${componentWidth}px`;
  } else if (componentStyle === COMPONENT_STYLE_SMALL) {
    return '100px';
  } else if (componentStyle === COMPONENT_STYLE_WIDE) {
    return '350px';
  } else {
    return 'auto';
  }
};

export const calculateOutOfTurnStepsWidth = (
  options: string[],
  font: string
): number => {
  return calculateWidth(options, 130, 0, font);
};

export const calculateSelectWidth = (
  options: string[],
  isAutoComplete?: boolean
) => {
  const rightPadding = isAutoComplete ? 34 : 32;
  return `${calculateWidth(options, 134, rightPadding)}px`;
};
