export const CLOCK_STATUS = {
  COMPLETE: 'Completed',
  CREATE: 'Created',
  EXTEND: 'Extended',
  PAUSE: 'Paused',
  RECALCULATE: 'Recalculated',
  RESET: 'Reset',
  RESTART: 'Restarted',
};

export const CLOCK_STATE = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'DONE',
  ERROR: 'ERROR',
  PAUSED: 'PAUSED',
};
