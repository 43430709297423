import { TimelineState } from 'graphql/graphqlTypes';

export const convertTimeLineStateToInt = (state: TimelineState) => {
  switch (state) {
    case TimelineState.DocumentCompleted:
      return 0;
    case TimelineState.DocumentInProgress:
      return 1;
    case TimelineState.HasAttachment:
      return 2;
    case TimelineState.ReminderOpen:
      return 3;
    case TimelineState.ReminderClosed:
      return 4;
    case TimelineState.HasLetter:
      return 5;
    case TimelineState.HasFax:
      return 6;
    case TimelineState.Cm:
      return 7;
    case TimelineState.Um:
      return 8;
    case TimelineState.HasEducation:
      return 9;
  }
};
