import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { IState } from 'store';
import Loader from 'components/loader';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import { AttachFilesDialogActions } from './attachFilesDialogActions';
import AttachFilesDialogBody from './attachFilesDialogBody';
import { useMergeAttachmentsMutation } from 'graphql/hooks/mergeAttachments';

const StyledTypography = styled(Typography)({
  color: COLORS.WHITE,
});

const StyledDialogContent = styled(DialogContent)({
  backgroundColor: COLORS.PALE_GREY,
  paddingLeft: '24px',
  paddingRight: '24px',
});

const StyledDialogActions = styled(DialogActions)({
  padding: '16px 24px',
  justifyContent: 'space-between',
});

export interface AttachFilesDialogProps {
  open: boolean;
  patientId: number;
  onClose(): void;
  onAttachFiles(
    attachmentFileCount: number,
    isError: boolean,
    filePath: string
  ): void;
}

const AttachFilesDialog = (props: AttachFilesDialogProps) => {
  const { open, patientId, onClose, onAttachFiles } = props;

  const selectedAttachmentIds = useSelector(
    (state: IState) =>
      state.patientDetailsAttachment?.selectedAttachmentIds ?? []
  );
  const uploadedFiles = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  );

  const uploadedFileIds = uploadedFiles.map((x) => String(x.fileId));
  const attachmentFileCount =
    selectedAttachmentIds.length + uploadedFileIds.length;
  const [mergeAttachments, { isLoading: isMergeAttachments }] =
    useMergeAttachmentsMutation();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAttachFiles = useCallback(() => {
    if (selectedAttachmentIds.length || uploadedFileIds.length) {
      mergeAttachments({
        attachmentIds: selectedAttachmentIds,
        fileIds: uploadedFileIds,
      }).then((result) => {
        if ('data' in result) {
          const filePath = result.data?.mergeAttachments?.filePath ?? '';
          const isError = result.data?.mergeAttachments?.isError ?? false;
          onAttachFiles(attachmentFileCount, isError, filePath);
        }
      });
    }
  }, [
    attachmentFileCount,
    mergeAttachments,
    onAttachFiles,
    selectedAttachmentIds,
    uploadedFileIds,
  ]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <StyledTypography variant="subtitle1">Attach Files</StyledTypography>
        </DialogTitle>
        <StyledDialogContent dividers>
          <AttachFilesDialogBody patientId={patientId} />
        </StyledDialogContent>
        <StyledDialogActions>
          <AttachFilesDialogActions
            handleClose={handleClose}
            handleAttachFiles={handleAttachFiles}
          />
        </StyledDialogActions>
      </Dialog>
      <Loader active={isMergeAttachments} />
    </>
  );
};

export default AttachFilesDialog;
