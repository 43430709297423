import { ILookupValue } from 'backend/types/lookupValue';

type LookupSource = {
  id: number;
  name?: string | null;
  image?: string | null;
  sortOrder?: number | null;
  isDeleted?: boolean;
};

// Type helpers to ensure that if input is non-nullable
// then return is non-nullable as well
type ToLookupValueInput<T> =
  | (T extends LookupSource ? T : never)
  | (T extends null ? null : never)
  | (T extends undefined ? undefined : never);
type ToLookupValueReturn<T> =
  | ILookupValue
  | (T extends null ? null : never)
  | (T extends undefined ? null : never);

export const toLookupValue = <T>(
  source: ToLookupValueInput<T>
): ToLookupValueReturn<T> => {
  if (source === undefined || source === null) {
    return null!;
  }
  return {
    id: source.id,
    name: source.name,
    image: source.image,
    sortOrder: source.sortOrder,
    isDeleted: source.isDeleted,
    isDisabled: source.isDeleted,
  } as ILookupValue;
};
