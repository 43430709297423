import type {} from '@mui/x-date-pickers/themeAugmentation';
import { createTheme } from '@mui/material/styles';
import { COLORS } from 'consts/styles';
import { convertHexToRGBA } from 'util/helpers/iconHelpers';

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.SYMPHONY_BLUE,
    },
    secondary: {
      main: COLORS.ICE_GREY,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Roboto',
    h1: {
      fontSize: 62,
      lineHeight: 1.22,
      fontWeight: 300,
    },
    h2: {
      fontSize: 32,
      lineHeight: 1.25,
      letterSpacing: 0.5,
      fontWeight: 'normal',
    },
    h3: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: 'normal',
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    h5: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    h6: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    button: {
      fontSize: 14,
      lineHeight: 1.4,
      color: COLORS.BLACK,
      letterSpacing: 0.5,
    },
    caption: {
      fontSize: 12,
      color: COLORS.GREY100,
      lineHeight: '16px',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          minHeight: '32px',
        },
        inputRoot: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontSize: '14px',
          fontWeight: 500,
          fontStretch: 'normal',
          fontStyle: 'normal',
          letterSpacing: 0.15,
          lineHeight: '20px',
          textAlign: 'center',
          textTransform: 'none',
        },
        iconSizeSmall: {
          marginLeft: 0,
          marginTop: '-2px',
          '& > *:first-child': {
            fontSize: '14px',
          },
        },
        iconSizeMedium: {
          '& > *:first-child': {
            fontSize: '14px',
          },
        },
        iconSizeLarge: {
          '& > *:first-child': {
            fontSize: '14px',
          },
        },

        startIcon: {
          marginLeft: 0,
        },
        contained: {
          boxShadow: 'none',
          '&.Mui-disabled': {
            color: COLORS.WHITE,
            backgroundColor: COLORS.GREY25,
          },
        },
        containedPrimary: {
          color: COLORS.WHITE,
          backgroundColor: COLORS.SYMPHONY_BLUE,
          '&:hover': {
            backgroundColor: '#0b59a2',
          },
        },
        outlined: {
          color: COLORS.SYMPHONY_BLUE,
          borderColor: COLORS.SYMPHONY_BLUE,
          '&:hover': {
            backgroundColor: COLORS.BLUE10,
          },
          '&.Mui-disabled': {
            color: COLORS.GREY60,
            borderColor: COLORS.GREY25,
          },
        },

        containedSecondary: {
          color: COLORS.SYMPHONY_BLUE,
          backgroundColor: COLORS.WHITE,
          '&:hover': {
            backgroundColor: COLORS.ICE_GREY,
          },
          '&.Mui-disabled': {
            color: COLORS.LIGHT_GREY,
            backgroundColor: COLORS.WHITE,
          },
        },
        sizeSmall: {
          minWidth: '74px',
        },
        textSizeSmall: {
          fontSize: '14px',
          padding: '6px 12px',
        },
        textSizeLarge: {
          fontSize: '14px',
          padding: '10px 24px',
        },
        containedSizeSmall: {
          fontSize: '14px',
          padding: '6px 12px',
        },
        containedSizeLarge: {
          fontSize: '14px',
          padding: '10px 24px',
        },
        outlinedSizeSmall: {
          fontSize: '14px',
          padding: '5px 12px',
        },
        outlinedSizeLarge: {
          fontSize: '14px',
          padding: '9px 24px',
        },
        textPrimary: {
          color: COLORS.SYMPHONY_BLUE,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '8px',
          fontSize: 0,
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        slotProps: {
          leftArrowIcon: {
            sx: {
              fontSize: '1.5rem',
            },
          },
          rightArrowIcon: {
            sx: {
              fontSize: '1.5rem',
            },
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          '*, *::before, *::after': {
            boxSizing: 'revert',
          },
          WebkitFontSmoothing: 'auto',
          '*': {
            scrollbarWidth: 'thin',
            scrollbarColor: `${COLORS.GREY60}`,
            '&::-webkit-scrollbar': {
              width: '8px',
              height: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: 20,
              backgroundColor: `${COLORS.GREY60}`,
              minHeight: 24,
              minWidth: 24,
              backgroundClip: 'content-box',
              border: '1px solid transparent',
            },
            '&::-webkit-scrollbar-thumb:focus': {
              backgroundColor: `${COLORS.GREY80}`,
            },
            '&::-webkit-scrollbar-thumb:active': {
              backgroundColor: `${COLORS.GREY80}`,
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: `${COLORS.GREY80}`,
              backgroundClip: 'border-box',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '70%',
          height: '100%',
        },
        shrink: {
          maxWidth: '109%',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          backgroundColor: convertHexToRGBA(COLORS.BLACK, 60),
          borderRadius: '4px',
          fontFamily: 'Roboto',
          fontWeight: 400,
          lineHeight: '16px',
          fontStyle: 'normal',
          letterSpacing: '0.4px',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        groupedOutlinedHorizontal: {
          marginLeft: 0,
          '&:not(:last-child)': {
            marginLeft: 0,
          },
          '&:not(:first-child)': {
            marginLeft: 0,
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            fontWeight: 300,
            color: COLORS.SYMPHONY_BLUE,
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        alternativeLabel: {
          marginTop: 0,
        },
        root: {
          marginTop: 0,
          fontSize: '12px',
          fontWeight: 300,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: '0px',
        },
        root: {
          '&.Mui-disabled': {
            backgroundColor: COLORS.GREY4,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
        label: {
          marginLeft: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.BLACK,
          },
          '&$focused .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.SYMPHONY_BLUE,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.RED100,
          },
          '&.Mui-disabled': {
            color: COLORS.GREY100,
            backgroundColor: '#F9F9F9',
          },
        },

        input: {
          padding: '6px 12px',
          color: 'inherit',
          height: 'auto',
          lineHeight: '20px',
        },
        multiline: {
          padding: '0 !important',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          backgroundColor: COLORS.WHITE,
        },
        inputAdornedStart: {
          paddingLeft: '0px',
        },
        inputAdornedEnd: {
          paddingRight: '0px',
          display: 'block',
        },
        adornedEnd: {
          paddingRight: '12px',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginLeft: '2px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 400,
          '&> input': {
            backgroundColor: COLORS.WHITE,
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: 400,
            lineHeight: '20px',
            '&.Mui-disabled': {
              backgroundColor: COLORS.GREY4,
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.WHITE,
          '&.Mui-disabled': {
            backgroundColor: COLORS.GREY4,
          },
        },
        formControl: {
          'label + &': {
            marginTop: 0,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '4px',
          border: '1px solid #CECECE',
          boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
          marginTop: '4px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        nativeInput: {
          backgroundColor: COLORS.WHITE,
          fontSize: '14px',
          fontFamily: 'Roboto',
          fontWeight: 400,
          '&.Mui-disabled': {
            backgroundColor: COLORS.GREY4,
          },
        },
        select: {
          minHeight: 'auto',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: '12px',
          paddingBottom: '12px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: COLORS.BLUE10,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: { marginTop: '-4px' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          minHeight: 40,
          padding: '10px 20px',
          '@media (min-width: 600px)': {
            minWidth: 'auto',
          },
          '&:hover': {
            backgroundColor: COLORS.BLUE10,
            color: COLORS.GREY100,
            fontWeight: 500,
          },
          textTransform: 'capitalize',
          '&$selected': {
            color: COLORS.SYMPHONY_BLUE,
          },
        },
        textColorInherit: {
          opacity: 1,
          color: COLORS.GREY100,
        },
        textColorPrimary: {
          color: COLORS.GREY100,
          '&$selected': {
            color: COLORS.SYMPHONY_BLUE,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
        indicator: {
          height: 0,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          marginRight: '8px',
          color: COLORS.GREY30,
          backgroundColor: COLORS.WHITE,
          width: '14px',
          height: '14px',
          '&.Mui-checked': {
            color: COLORS.SYMPHONY_BLUE,
          },
          padding: 2,
          '& .MuiSvgIcon-root': {
            fontSize: 16,
          },
          '&:hover': {
            backgroundColor: COLORS.BLUE10,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginRight: '6px',
          color: COLORS.GREY30,
          backgroundColor: COLORS.WHITE,
          borderRadius: '0',
          width: '14px',
          height: '14px',
          '&.Mui-checked': {
            color: COLORS.SYMPHONY_BLUE,
          },
          padding: 0,
          '& .MuiSvgIcon-root': {
            fontSize: 18,
          },
          '&:hover': {
            backgroundColor: COLORS.BLUE10,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: COLORS.GREY100,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '28px',
          border: 0,
        },
        colorPrimary: {
          backgroundColor: COLORS.GREY10,
        },
        label: {
          color: COLORS.BLACK,
          fontSize: 14,
          fontWeight: 400,
          paddingRight: 0,
          paddingLeft: '14px',
        },
        deleteIcon: {
          color: COLORS.GREY100,
          marginLeft: '8px',
          width: 16,
          marginRight: '10px',
          height: 16,
          '&:hover': {
            color: COLORS.BLACK,
          },
        },
        sizeSmall: {
          root: {
            height: '22px',
          },
          label: {
            fontSize: 12,
          },
          deleteIcon: {
            color: COLORS.GREY100,
            paddingRight: '10px',
            width: 12,
            height: 12,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthXl: {
          maxWidth: '1920px',
        },
        paperWidthSm: {
          maxWidth: '648px',
        },
      },
    },
  },
});

export default theme;
