import React, { useEffect, useRef } from 'react';
import Panel from 'components/panel';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { ChecklistStorageType } from 'store/actions/types';
import { useChecklistForSidebar } from 'hooks/useChecklistForSidebar';
import { useLazyGetLinkEpisodesQuery } from 'graphql/hooks/getLinkEpisodes';
import Loader from 'components/loader';
import StyledLink from 'components/actionlink/StyledLink';
import { useDispatch, useSelector } from 'react-redux';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { IState } from 'store';
import { useGetEpisodeTabTypesQuery } from 'graphql/hooks/getEpisodesTabTypes';
import { EpisodeTabTypes } from 'graphql/graphqlTypes';
import { getTargetEpisodeTabName } from 'components/home/patientdetails/DialogBody.helpers';

const StyledNavHeader = styled.div`
  padding: 24px 24px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledEpisodeLink = styled(StyledLink)({
  padding: '4px 0px 4px 20px',
});

const StyledScrollableSection = styled.div`
  height: auto;
  max-height: 40vh;
  overflow-y: auto;
`;

const StyledNavbarWrapper = styled.div`
  margin-bottom: 10px;
`;

export interface INavbarProps {
  isReview?: boolean;
  storageType: ChecklistStorageType;
}

const LinkEpisodeNavbar = (props: INavbarProps) => {
  const { isReview, storageType } = props;
  const { checklist } = useChecklistForSidebar(storageType);
  const episodeId = checklist?.episodeId as number;
  const { data: epidodesTabType } = useGetEpisodeTabTypesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const tabTypes = epidodesTabType?.getEpisodeTabTypes;

  const [
    getLinkEpisodes,
    { data: linkEpisodeData, isLoading: isLinkEpisodeDataLoading },
  ] = useLazyGetLinkEpisodesQuery();

  const dispatch = useDispatch();
  const navContainerRef = useRef<HTMLDivElement>(null);

  const mozartWorkflow = useSelector(
    (state: IState) => state.mozart.mozartWorkflow
  );
  const lastActionIndex = mozartWorkflow?.actions
    ? mozartWorkflow.actions.length - 1
    : undefined;

  useEffect(() => {
    if (episodeId) {
      getLinkEpisodes({ episodeId: episodeId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeId, lastActionIndex]);

  const linkEpisodes = linkEpisodeData?.getLinkEpisodes;

  const handleClick = (
    patientId: number,
    episodeId: number,
    episodeTypeId: number
  ) => {
    const targetTabName = getTargetEpisodeTabName(
      episodeTypeId,
      tabTypes as EpisodeTabTypes[]
    );
    dispatch(
      openPatientDetailsModal({
        patientId,
        episodeId,
        activeTabName: targetTabName,
        activeSubTabName: '',
      })
    );
  };

  if (!checklist || !linkEpisodes?.length || isReview) {
    return null;
  }
  if (isLinkEpisodeDataLoading) {
    return <Loader active={isLinkEpisodeDataLoading} />;
  }

  return (
    <StyledNavbarWrapper>
      <Panel padding="0px 0px 24px 0px" navigation={true}>
        <StyledScrollableSection ref={navContainerRef}>
          <>
            <StyledNavHeader>
              <Typography variant="h4">Linked Episodes</Typography>
            </StyledNavHeader>
            {linkEpisodes &&
              linkEpisodes?.map(
                (linkEpisode) =>
                  episodeId != linkEpisode?.episodeId && (
                    <StyledEpisodeLink
                      key={linkEpisode?.episodeId}
                      onClick={() => {
                        handleClick(
                          checklist?.patientId,
                          linkEpisode?.episodeId as number,
                          linkEpisode?.episodeTypeId as number
                        );
                      }}
                      data-cy="link-episode"
                    >
                      {linkEpisode?.episodeNumber}
                    </StyledEpisodeLink>
                  )
              )}
          </>
        </StyledScrollableSection>
      </Panel>
    </StyledNavbarWrapper>
  );
};

export default LinkEpisodeNavbar;
