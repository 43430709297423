import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type CreateWorkflowChecklistMutationVariables = Types.Exact<{
  instanceId: Types.Scalars['UUID'];
  scenarioId: Types.Scalars['Int'];
}>;

export type CreateWorkflowChecklistMutation = {
  __typename?: 'Mutation';
  createWorkflowChecklist?: {
    __typename?: 'CreateMozartChecklistResult';
    shouldRetry: boolean;
    message?: string | null;
    checklistId?: number | null;
  } | null;
};

export const CreateWorkflowChecklistDocument = `
    mutation createWorkflowChecklist($instanceId: UUID!, $scenarioId: Int!) {
  createWorkflowChecklist(id: $instanceId, scenarioId: $scenarioId) {
    shouldRetry
    message
    checklistId
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createWorkflowChecklist: build.mutation<
      CreateWorkflowChecklistMutation,
      CreateWorkflowChecklistMutationVariables
    >({
      query: (variables) => ({
        document: CreateWorkflowChecklistDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateWorkflowChecklistMutation } = injectedRtkApi;
