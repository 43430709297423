import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { changePassword } from 'store/user/middlewares';
import ErrorComp from 'components/error';
import { IChangePassword, UpdateType } from 'store/user/types';
import { useForm } from 'react-hook-form';
import { IState } from 'store';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  min-width: 308px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
`;

const StyledInputWrapper = styled.div`
  margin-top: 40px;
`;

const StyledOutlinedInput = styled(OutlinedInput)({
  '.MuiInputBase-input': {
    height: '40px',
  },
  color: COLORS.BLACK10,
  'input:-internal-autofill-selected': {
    backgroundColor: 'transparent!important',
  },
});

const StyledTypographyHeading = styled(Typography)({
  color: COLORS.BLACK,
  fontWeight: 300,
  marginBottom: '40px',
});

const StyledTypographyUnderline = styled(Typography)({
  textDecoration: 'underline',
  color: COLORS.BLACK10,
});

interface IChangePasswordFormProps {
  updateType: number;
}

export const ChangePasswordForm = ({
  updateType,
}: IChangePasswordFormProps) => {
  const user = useSelector((state: IState) => state.user.auth);
  const code = useSelector((state: IState) => state.user.code);
  const dispatch = useDispatch();
  const [values, setValues] = useState<IChangePassword>({} as IChangePassword);
  const [showPassword, setShowPassword] = useState({
    showPassword: false,
    showRepeatPassword: false,
  });

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const shouldMatch = (value: string) => {
    const pass: string = values.newpassword;
    return value === pass;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IChangePassword>();
  const onSubmit = (values: IChangePassword) => {
    values['login'] = user.login;
    values['password'] = user.password;
    dispatch(changePassword(values, code));
  };
  const handleClickShowPassword = (type: string) => {
    if (type === 'password') {
      setShowPassword({
        showPassword: !showPassword.showPassword,
        showRepeatPassword: showPassword.showRepeatPassword,
      });
    } else {
      setShowPassword({
        showPassword: showPassword.showPassword,
        showRepeatPassword: !showPassword.showRepeatPassword,
      });
    }
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const errorMessage: { [key: string]: string } = {
    required: 'Please provide a password',
    minLength: 'Your password must be at least 8 characters long',
    validate: 'Please enter the same password as above',
  };

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginBottom: '35px' }}>
          {updateType === UpdateType.ChangePassword && (
            <StyledTypographyHeading
              variant="h3"
              style={{
                fontSize: '44px',
              }}
            >
              <div>Update</div>Password
            </StyledTypographyHeading>
          )}
          {updateType === UpdateType.UpdatePassword && (
            <StyledTypographyHeading variant="h2">
              <div>Almost there!</div>
              Reset your password
              <Typography variant="body1">
                Username: <strong>{user.login}</strong>
              </Typography>
            </StyledTypographyHeading>
          )}
          <Typography variant="body1" style={{ color: COLORS.GREY80 }}>
            Must include at least one symbol or number and have at least 8
            characters.
          </Typography>
        </div>

        <StyledInputWrapper>
          <Typography
            style={{ color: COLORS.GREY80, paddingBottom: '9px' }}
            variant="body1"
          >
            New Password
          </Typography>
          <StyledOutlinedInput
            id="newpassword"
            placeholder="New Password"
            type={showPassword.showPassword ? 'text' : 'password'}
            fullWidth={true}
            {...register('newpassword', { required: true, minLength: 8 })}
            onChange={inputHandler}
            style={{ height: '54px' }}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ backgroundColor: 'transparent' }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('password')}
                  onMouseDown={handleMouseDownPassword}
                  style={{ padding: '4px', marginRight: '-4px' }}
                  size="large"
                >
                  {showPassword.showPassword ? (
                    <StyledTypographyUnderline>Hide</StyledTypographyUnderline>
                  ) : (
                    <StyledTypographyUnderline>Show</StyledTypographyUnderline>
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <ErrorComp>
            {errors.newpassword && (
              <span>{errorMessage[errors.newpassword.type]}</span>
            )}
          </ErrorComp>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <Typography
            style={{ color: COLORS.GREY80, paddingBottom: '9px' }}
            variant="body1"
          >
            Re-enter your password
          </Typography>
          <StyledOutlinedInput
            id="newpassword2"
            placeholder="Confirm Password"
            type={showPassword.showRepeatPassword ? 'text' : 'password'}
            fullWidth={true}
            style={{ height: '54px' }}
            {...register('newpassword2', {
              required: true,
              minLength: 8,
              validate: shouldMatch,
            })}
            onChange={inputHandler}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ backgroundColor: 'transparent' }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword('re-password')}
                  onMouseDown={handleMouseDownPassword}
                  style={{ padding: '4px', marginRight: '-4px' }}
                  size="large"
                >
                  {showPassword.showRepeatPassword ? (
                    <StyledTypographyUnderline>Hide</StyledTypographyUnderline>
                  ) : (
                    <StyledTypographyUnderline>Show</StyledTypographyUnderline>
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <ErrorComp>
            {errors.newpassword2 && (
              <span>{errorMessage[errors.newpassword2.type]}</span>
            )}
          </ErrorComp>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
            data-cy="change-password"
            style={{ width: '100%' }}
          >
            {updateType === UpdateType.UpdatePassword
              ? 'Update'
              : 'Change Password'}
          </Button>
        </StyledInputWrapper>
      </form>
    </StyledWrapper>
  );
};
