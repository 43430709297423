import { Table } from '@devexpress/dx-react-grid-material-ui';
import { CellComponentStyle } from './CellComponentStyle';
import Icon, { ICONS } from '../icon';
import { COLORS } from 'consts/styles';
import React from 'react';

export const StarSwitchCell = (
  props: Table.DataCellProps & {
    changeValue: (value: boolean) => void;
    disabled?: boolean;
  }
) => {
  const { changeValue, disabled, ...p } = props;
  return (
    <Table.Cell
      {...p}
      style={CellComponentStyle}
      data-cy="table-cell-primary"
      onClick={undefined}
    >
      <Icon
        onClick={() => disabled !== true && changeValue(!p.value)}
        icon={p.value === true ? ICONS.StarFull : ICONS.StarEmpty}
        size={20}
        color={p.value === true ? COLORS.YELLOW100 : COLORS.GREY100}
        style={disabled ? undefined : { cursor: 'pointer' }}
      />
    </Table.Cell>
  );
};
