import React, { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { usePrevious } from 'hooks';
import { SessionExpirationDialog } from './SessionExpirationDialog';
import { useIdleTimer } from 'react-idle-timer';
import { logoutUser, refreshToken } from 'store/user/middlewares';
import { ILogoutUser, ITokenRequest } from 'store/user/types';
import { useNavigate } from 'react-router';

const SessionExpirationTimer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isExpirationDialogOpen, setIsExpirationDialogOpen] = useState(false);

  const user = useSelector((state: IState) => state.user.currentUser);

  const previousUser = usePrevious(user);
  useEffect(() => {
    if (previousUser === undefined && user !== undefined) {
      return;
    }
    restart(user?.tokenEarliestRefreshInClientTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousUser, user]);

  const { restart } = useTimer({
    expiryTimestamp: user.tokenEarliestRefreshInClientTime,
    onExpire: () => onTokenExpire(),
  });

  const onTokenExpire = () => {
    dispatch(
      refreshToken({
        token: user.token,
        refreshToken: user.refreshToken,
      } as ITokenRequest)
    );
  };

  const openIdleTimeoutMs =
    (user.idleTimeout - user.earliestRefreshOffsetFromExpiryDate) * 60 * 1000;
  useIdleTimer({
    timeout: openIdleTimeoutMs,
    onIdle: () => {
      setIsExpirationDialogOpen(true);
    },
  });

  const onOkHandler = () => {
    setIsExpirationDialogOpen(false);
  };

  const onCancelHandler = () => {
    const data: ILogoutUser = {
      sessionId: user.sessionId,
    };
    dispatch(logoutUser(data, navigate, true, true));
    setIsExpirationDialogOpen(false);
  };

  return (
    <SessionExpirationDialog
      isOpen={isExpirationDialogOpen}
      expiryDelayMinutes={user.earliestRefreshOffsetFromExpiryDate}
      user={user}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    />
  );
};

export default SessionExpirationTimer;
