import React, { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import { useDispatch, useSelector } from 'react-redux';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import { useParams } from 'react-router-dom';
import { IState } from 'store';
import { ISystemValue } from 'backend/types/systemValue';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { ROUTE } from 'consts/ROUTE';
import styled from 'styled-components';
import { ChecklistStorageType } from 'store/actions/types';
import withStyles from '@mui/styles/withStyles';
import { useNavigate } from 'react-router';
import { setShowLeavingChecklistConfirmation } from 'store/ui/modals/checklist';
import { useHeaderHelpers } from 'components/header/components/Header.hooks';
import { useDirtyConfirmation } from 'hooks/useDirtyConfirmationModal';
import { DirtyConfirmationModal } from 'components/modal/DirtyConfirmationModal';

interface IStyledStepper {
  $isMozartFlow: boolean;
}

const StyledStepper = styled(Stepper)<IStyledStepper>`
  ${({ $isMozartFlow }) => `width: ${$isMozartFlow ? '278px' : '348px'};`}
  background-color: transparent;
  font-size: 12px !important;
  line-height: 1.33;
  text-align: center;
  padding-right: 0;
`;

const StyledStepLabel = styled(StepLabel)({
  color: COLORS.GREY4,
  lineHeight: '16px',
  '& .MuiStepLabel-label': {
    marginTop: '0!important',
    color: COLORS.WHITE,
    fontWeight: 400,
    fontSize: '12px',
  },
  '&.Mui-disabled': {
    opacity: 0.8,
    fontWeight: 400,
    '&:hover': {
      cursor: 'default !important',
      '& .MuiStepLabel-label, .MuiStepLabel-iconContainer': {
        color: COLORS.WHITE,
        fontWeight: 400,
      },
    },
  },
  '& .MuiStepLabel-alternativeLabel.MuiStepLabel-active': {
    color: COLORS.WHITE,
    opacity: 1,
    fontWeight: 500,
  },
  '& .MuiStepLabel-alternativeLabel.MuiStepLabel-completed': {
    color: COLORS.WHITE,
    fontWeight: 400,
    opacity: 1,
  },
  '&:hover': {
    '& .MuiStepLabel-label': {
      color: COLORS.WHITE,
      fontWeight: 500,
    },
    cursor: 'pointer',
    opacity: 0.8,
  },
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
  },
  active: {
    '& $line': {
      opacity: 0.8,
      borderColor: COLORS.WHITE,
    },
  },
  completed: {
    '& $line': {
      borderColor: COLORS.WHITE,
    },
  },
  line: {
    borderColor: COLORS.WHITE,
    borderTopWidth: 2,
    borderRadius: 1,
    opacity: 0.8,
  },
})(StepConnector);

const StyledWrapper = styled.div`
  display: flex;
  opacity: 1;
  height: 22px;
  align-items: center;
`;

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed } = props;

  const renderCompleted = () => {
    return completed ? (
      <Icon
        icon={ICONS.Progress_inactive}
        size={12}
        color={COLORS.WHITE}
        style={{ opacity: 1 }}
      />
    ) : (
      <Icon icon={ICONS.Progress_inactive} size={12} color={COLORS.GREY4} />
    );
  };

  return (
    <StyledWrapper>
      {active ? (
        <Icon
          icon={ICONS.Progress_active}
          size={22}
          color={COLORS.WHITE}
          style={{ opacity: 1 }}
        />
      ) : (
        renderCompleted()
      )}
    </StyledWrapper>
  );
};

const getSteps = (glossary: ISystemValue[] | null, isMozartFlow: boolean) => {
  const glossaryItems = glossary?.find((x) => x.name === 'Patient')?.value;
  if (glossaryItems) {
    return [glossaryItems];
  }
  return isMozartFlow
    ? ['Member', 'Scenario', 'Actions', 'Done']
    : ['Member', 'Scenario', 'Actions', 'Review', 'Done'];
};

export const ProgressBar = () => {
  const workflowStep = useSelector((state: IState) => state.workflow.step);

  const glossary = useSelector(
    (state: IState) => state.home.mainModel.glossaryItems
  );
  const {
    handleDirtyConfirm,
    clearDirtyState,
    handleConfirm,
    handleCancel,
    showModal,
  } = useDirtyConfirmation();
  const isDirty = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary.isDirty
  );
  const sectionName = 'Episode Summary';
  const [activeStep, setActiveStep] = useState(workflowStep);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { isMozartFlow } = useHeaderHelpers();

  const steps = getSteps(glossary, isMozartFlow);

  const storageType = isMozartFlow
    ? ChecklistStorageType.MOZART
    : ChecklistStorageType.CHECKLIST;

  const patientId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].patientId
  );
  const isAmendingChecklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].isAmendingChecklist
  );
  useEffect(() => setActiveStep(workflowStep), [workflowStep]);

  const stepsToDisableWhenAmendingChecklist = 2;

  const checklistId = Number(params.id);
  const workflowType = isMozartFlow ? 'Workflow' : 'Checklist';

  const Routes = [
    ROUTE.Home,
    `/Checklist/Problems/?patientId=${patientId}`,
    `/${workflowType}/Actions/${checklistId}`,
    `/Checklist/Review/${checklistId}`,
  ];

  const handleStep = (step: number) => () => {
    handleDirtyConfirm(isDirty, () => onConfirmStep(step));
  };

  const onConfirmStep = (step: number) => {
    clearDirtyState();
    const actionsStep = 2;
    const reviewStep = 3;

    if (step === activeStep) {
      return;
    }

    if (
      (isAmendingChecklist && step < stepsToDisableWhenAmendingChecklist) ||
      step > activeStep
    ) {
      return;
    }

    if (
      activeStep === actionsStep ||
      (activeStep === reviewStep && !isMozartFlow && step < actionsStep)
    ) {
      dispatch(
        setShowLeavingChecklistConfirmation({
          exitModalOpen: true,
          action: 'Discard',
          route: Routes[step],
        })
      );
      return;
    }

    if (step < activeStep) {
      setActiveStep(step);
      dispatch(
        setWorkflowStep({ step: step, name: step === 0 ? '' : 'Scenario' })
      );
      navigate(Routes[step]);
    }
  };

  return (
    <>
      <DirtyConfirmationModal
        showModal={showModal}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        sectionName={sectionName}
      />
      <StyledStepper
        $isMozartFlow={isMozartFlow}
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        data-cy="navbar"
      >
        {steps?.map((label, index) => (
          <Step
            key={label}
            disabled={
              (isAmendingChecklist &&
                index < stepsToDisableWhenAmendingChecklist) ||
              activeStep < index
            }
          >
            <StyledStepLabel
              key={label}
              style={{ marginTop: 0 }}
              StepIconComponent={QontoStepIcon}
              onClick={handleStep(index)}
              data-cy={`navbar-${label}`}
            >
              {label}
            </StyledStepLabel>
          </Step>
        ))}
      </StyledStepper>
    </>
  );
};
