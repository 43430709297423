import React from 'react';
import Typography from '@mui/material/Typography';
import { ChecklistItemSubheader } from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { ChecklistStorageType } from 'store/actions/types';
import { useSelector } from 'react-redux';
import { IState } from 'store';

interface StyledProps {
  isPrinting: boolean;
}
interface ExtendedStyledProps extends StyledProps {
  isMozart: boolean;
}
const getPadding = (props: ExtendedStyledProps): string => {
  if (props.isPrinting) {
    return '5px 14px 5px 28px';
  } else {
    return props.isMozart ? '12px 64px 12px 68px' : '12px 34px 12px 34px';
  }
};

const StyledSubHeader = styled.div<ExtendedStyledProps>`
  margin: ${(props) => (props.isMozart ? '0 -28px' : '0')};
  padding: ${(props) => getPadding(props)};
  justify-content: flex-start;
  background-color: ${COLORS.GREY10};
  ${({ isPrinting }) =>
    isPrinting &&
    `page-break-inside: avoid;    
  `}
`;

const SmallSubtitle = styled(Typography)<StyledProps>`
  font-size: ${({ isPrinting }) => (isPrinting ? '13px' : '16px')};
`;

export interface IChecklistItemSubHeaderProps {
  item: ChecklistItemSubheader;
  storageType: ChecklistStorageType;
}

const SubHeader = ({ item, storageType }: IChecklistItemSubHeaderProps) => {
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isOptimizedView = printDocuments.isOptimizedView;
  return (
    <StyledSubHeader
      isMozart={
        storageType === ChecklistStorageType.MOZART ||
        storageType === ChecklistStorageType.VIEW
      }
      isPrinting={isOptimizedView}
    >
      <SmallSubtitle variant="subtitle1" isPrinting={isOptimizedView}>
        {item.preview}
      </SmallSubtitle>
    </StyledSubHeader>
  );
};

export default SubHeader;
