import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import SearchBar from 'components/searchbar';
import { EpisodesTab } from '../patientdetails/tabscontent/episodesTab';
import Loader from 'components/loader';
import { SearchPatientGrid } from './SearchPatientGrid';
import { SearchPatientDialogPatientsViewModel } from 'graphql/graphqlTypes';
import { useLazySearchPatientsQuery } from 'graphql/hooks/searchPatients';

interface ISearchPatientDialogBodyProps {
  selectedPatientId: number;
  onPatientSelection: (patientId: number) => void;
  onEpisodeSelection: (episodeId: number) => void;
}

export const SearchPatientDialogBody = ({
  selectedPatientId,
  onPatientSelection,
  onEpisodeSelection,
}: ISearchPatientDialogBodyProps) => {
  const [searchPatients, { data, isFetching }] = useLazySearchPatientsQuery();

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    if (newSearchTerm) {
      searchPatients({
        fullSearch: newSearchTerm,
      });
    }
    onPatientSelection(0);
    onEpisodeSelection(0);
  };

  const handleSelectionChange = (values: Array<number | string>) => {
    if (values.length > 1) {
      const newId = Number(values[1]);
      onPatientSelection(newId);
    } else if (values.length === 0) {
      onPatientSelection(0);
      onEpisodeSelection(0);
    }
  };

  const actualData = !searchTerm
    ? []
    : ((data?.searchPatients ?? []) as SearchPatientDialogPatientsViewModel[]);

  const isOpen = selectedPatientId > 0;
  return (
    <div style={{ height: '100%' }}>
      <Box
        ml="14px"
        mt="10px"
        mr="7px"
        display="flex"
        flexDirection="column"
        style={{ height: '98%' }}
      >
        <Box display="flex" alignItems="center" mb="16px">
          <Typography variant="subtitle1">Members</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb="16px">
          <SearchBar
            placeholder="Search by Member Name or Member ID..."
            onSubmit={handleSearchTerm}
            style={{ width: '400px' }}
          />
        </Box>
        <Box mb={isOpen ? '30px' : 0}>
          <SearchPatientGrid
            selectedPatientId={selectedPatientId}
            gridData={actualData}
            onSelectionChange={handleSelectionChange}
          />
        </Box>
        {isOpen && (
          <EpisodesTab
            patientId={selectedPatientId}
            showAllTypes={true}
            isDirectOpen={false}
            selectEpisode={undefined}
            showTatColumn={false}
            showCounterColumn={false}
            episodeType="episodes"
            style={{ display: 'flex', flex: '1', height: '100%' }}
            onEpisodeSelection={(episodeId: number) =>
              onEpisodeSelection(episodeId)
            }
            readonlyGrid
          />
        )}
      </Box>
      <Loader active={isFetching} />
    </div>
  );
};
