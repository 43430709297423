import { COLORS } from 'consts/styles';

export const CellComponentStyle = {
  padding: '0px 8px',
  color: COLORS.BLACK,
  lineHeight: '20px',
  minHeight: '40px',
  height: '40px',
  verticalAlign: 'middle',
};
