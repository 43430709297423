import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import { closeErrorPopup } from 'store/errorPopup/errorPopupSlice';
import styled from 'styled-components';
import Icon from '../icon';
import { ROUTE } from 'consts/ROUTE';
import { useNavigate } from 'react-router';

const StyledTypography = styled(Typography)`
  padding: 20px 24px 0 24px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
`;

const ErrorPopup = () => {
  const isApplicationError = useSelector(
    (state: IState) => state.errorPopup.isOpen
  );

  const { message, title, icon, returnHomeAction } = useSelector(
    (state: IState) => state.errorPopup
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    !navigator.onLine && setIsOffline(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.onLine]);

  const onClose = () => {
    setIsOffline(false);
    dispatch(closeErrorPopup());
    if (returnHomeAction) {
      navigate(ROUTE.Home);
    }
  };

  const showError = isApplicationError || isOffline;

  const buttonText = returnHomeAction ? 'Return home' : 'Ok';
  const defaultMessage =
    'The server encountered an internal error and was unable to complete your request. Keep this window open and try again later.';
  return (
    <Dialog
      open={showError}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-cy="error-popup"
    >
      <StyledTypography variant="subtitle1" data-cy="error-popup-title">
        {icon && <Icon icon={icon} size={24} />}
        <span>{title}</span>
      </StyledTypography>
      <DialogContent style={{ width: 400 }}>
        <DialogContentText
          id="alert-dialog-description"
          data-cy="error-popup-text"
        >
          {message ? message : defaultMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button
          onClick={onClose}
          color="primary"
          size="large"
          variant="contained"
          data-cy="ok"
          style={{
            marginRight: '20px',
            marginBottom: '20px',
            marginTop: '10px',
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorPopup;
