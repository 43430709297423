import React from 'react';
import { Typography } from '@mui/material';
import {
  AUTHORIZATION_STATUS_STYLE,
  mapAuthStatusStyle,
} from 'util/helpers/iconHelpers';
import { Ellipsis } from 'components/text';
import { LookupValue } from 'graphql/graphqlTypes';

interface IStatusLabelProps {
  status: string;
  text: string | LookupValue;
  style?: React.CSSProperties;
}

const StatusLabel = ({ status, text, style = {} }: IStatusLabelProps) => {
  const statusColor = mapAuthStatusStyle(status as AUTHORIZATION_STATUS_STYLE);
  return (
    <Ellipsis
      style={{
        backgroundColor: statusColor?.background,
        borderRadius: 4,
        textAlign: 'center',
        padding: '0px 8px',
        ...style,
      }}
    >
      <Typography
        noWrap
        style={{
          color: statusColor?.text,
          textTransform: 'capitalize',
        }}
      >
        {typeof text === 'string'
          ? text?.toLowerCase()
          : text?.name?.toLowerCase()}
      </Typography>
    </Ellipsis>
  );
};
export default StatusLabel;
