import React from 'react';
import { ErrorText, ErrorBox } from './StyledComponents';
import { COLORS } from 'consts/styles';

export const PriorAuthError = ({
  dateErrorMessage,
  hasError,
}: {
  dateErrorMessage: string | null | undefined;
  hasError?: boolean;
}) =>
  hasError ? (
    <ErrorBox>
      <ErrorText style={{ color: COLORS.RED100 }}>{dateErrorMessage}</ErrorText>
    </ErrorBox>
  ) : null;
