import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetFormattedNoteAutocompleteTextQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
  searchText: Types.Scalars['String'];
}>;

export type GetFormattedNoteAutocompleteTextQuery = {
  __typename?: 'EntitiesQuery';
  getFormattedNoteAutocompleteText: string;
};

export const GetFormattedNoteAutocompleteTextDocument = `
    query getFormattedNoteAutocompleteText($episodeId: Int!, $searchText: String!) {
  getFormattedNoteAutocompleteText(episodeId: $episodeId, searchText: $searchText)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFormattedNoteAutocompleteText: build.query<
      GetFormattedNoteAutocompleteTextQuery,
      GetFormattedNoteAutocompleteTextQueryVariables
    >({
      query: (variables) => ({
        document: GetFormattedNoteAutocompleteTextDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetFormattedNoteAutocompleteTextQuery,
  useLazyGetFormattedNoteAutocompleteTextQuery,
} = injectedRtkApi;
