import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetNoteAutocompleteEntriesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetNoteAutocompleteEntriesQuery = {
  __typename?: 'EntitiesQuery';
  getNoteAutocompleteEntries: Array<{
    __typename?: 'NoteAutocomplete';
    searchText: string;
  } | null>;
};

export const GetNoteAutocompleteEntriesDocument = `
    query getNoteAutocompleteEntries {
  getNoteAutocompleteEntries {
    searchText
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNoteAutocompleteEntries: build.query<
      GetNoteAutocompleteEntriesQuery,
      GetNoteAutocompleteEntriesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetNoteAutocompleteEntriesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetNoteAutocompleteEntriesQuery,
  useLazyGetNoteAutocompleteEntriesQuery,
} = injectedRtkApi;
