import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeWorkflowMdStepCountQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['Int']>;
  careSiteId?: Types.InputMaybe<Types.Scalars['Int']>;
  defaultAuthorization?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetEpisodeWorkflowMdStepCountQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeWorkflowMdStepCount?: Array<{
    __typename?: 'EpisodeWorkflowMdStepCountViewModel';
    mDReviewCount: number;
    mDP2PReviewCount: number;
    mDAppealReviewCount: number;
    cSRMDCount: number;
    careSiteName?: string | null;
    careSiteId: number;
  } | null> | null;
};

export const GetEpisodeWorkflowMdStepCountDocument = `
    query getEpisodeWorkflowMdStepCount($userId: Int, $careSiteId: Int, $defaultAuthorization: String) {
  getEpisodeWorkflowMdStepCount(
    userId: $userId
    careSiteId: $careSiteId
    defaultAuthorization: $defaultAuthorization
  ) {
    mDReviewCount
    mDP2PReviewCount
    mDAppealReviewCount
    cSRMDCount
    careSiteName
    careSiteId
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeWorkflowMdStepCount: build.query<
      GetEpisodeWorkflowMdStepCountQuery,
      GetEpisodeWorkflowMdStepCountQueryVariables | void
    >({
      query: (variables) => ({
        document: GetEpisodeWorkflowMdStepCountDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEpisodeWorkflowMdStepCountQuery,
  useLazyGetEpisodeWorkflowMdStepCountQuery,
} = injectedRtkApi;
