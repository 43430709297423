import React from 'react';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { AutocompleteCell } from 'components/gridFormatters';
import { ILookupValue } from 'backend/types/lookupValue';

export const UserEditCell = (props: TableEditRow.CellProps) => {
  const currentMembers =
    useSelector(
      (state: IState) => state.patientDetailsCareTeam.updatedTeamMembers
    )?.map((member) => member.userId) ?? [];
  const users =
    useSelector(
      (state: IState) => state.patientDetailsCareTeam.careTeamTab?.users
    )?.map(
      (user) =>
        ({
          id: user.id,
          name: user.name,
          isDisabled: currentMembers.includes(user.id),
        } as ILookupValue)
    ) ?? [];

  return <AutocompleteCell options={users} required autoFocus {...props} />;
};
