import React, { useCallback } from 'react';
import {
  StyledBody,
  StyledLinkContainer,
  StyledMessage,
  StyledOops,
} from './errorStyles';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { ROUTE } from 'consts/ROUTE';

const NotFound = () => {
  const navigate = useNavigate();

  const handleHomeClick = useCallback(() => {
    navigate(ROUTE.Home);
  }, [navigate]);

  return (
    <div className="errorRegion">
      <StyledBody>
        <StyledMessage>
          <StyledOops>404</StyledOops>
          <b data-testid="not-found-message">Ooops! Page not found</b>
          <StyledLinkContainer>
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={handleHomeClick}
            >
              Return to Home page
            </Button>
          </StyledLinkContainer>
        </StyledMessage>
      </StyledBody>
    </div>
  );
};

export default NotFound;
