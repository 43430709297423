import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { IconButton, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import DocumentActionsProvider from './DocumentActionsProvider';
import {
  ChecklistOrderSetRowView,
  ChecklistStatus,
} from 'graphql/graphqlTypes';

export const TextProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={(formProps: DataTypeProvider.ValueFormatterProps) => (
      <Typography>{formProps.value}</Typography>
    )}
    {...props}
  />
);

export const SituationProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={(formProps: DataTypeProvider.ValueFormatterProps) => (
      <Typography>
        {formProps?.row?.checklistOrderSets.length &&
          formProps?.row?.checklistOrderSets.map(
            (item: ChecklistOrderSetRowView, itemIndex: number) => (
              <span
                title={item?.name as string}
                key={itemIndex}
                style={{
                  backgroundColor: item?.backcolor as string,
                  color: item?.forecolor as string,
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                {item.title}
              </span>
            )
          )}
        {formProps.row?.containsAttachments && (
          <span style={{ paddingLeft: '10px' }}>
            <IconButton
              edge="end"
              title="Attachments"
              style={{ padding: '6px' }}
              onClick={() => {
                formProps.value.setShowAttachmentsForChecklistId(
                  formProps.value.checklistId
                );
                formProps.value.setShowAttachments(true);
              }}
              size="large"
            >
              <Icon
                icon={ICONS.Attachment}
                inline={true}
                size={18}
                color={COLORS.RED100}
              />
            </IconButton>
          </span>
        )}
      </Typography>
    )}
    {...props}
  />
);

export const ChecklistInProgressProvider = (props: DataTypeProviderProps) => {
  return (
    <DataTypeProvider
      formatterComponent={(formProps: DataTypeProvider.ValueFormatterProps) => (
        <>
          {formProps.row?.checklist?.status === ChecklistStatus.InProcess ? (
            <Icon icon={ICONS.InProgress} size={18} color={COLORS.YELLOW100} />
          ) : (
            <Icon
              icon={ICONS.ProgressCompleted}
              size={18}
              color={COLORS.GREY60}
            />
          )}
        </>
      )}
      {...props}
    />
  );
};

export const DocumentActionsProvided = (props: DataTypeProviderProps) => {
  return (
    <DataTypeProvider formatterComponent={DocumentActionsProvider} {...props} />
  );
};
