import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type RevertMozartWorkflowToPreviousStepMutationVariables = Types.Exact<{
  workflowInstanceId: Types.Scalars['UUID'];
  stepId: Types.Scalars['UUID'];
}>;

export type RevertMozartWorkflowToPreviousStepMutation = {
  __typename?: 'Mutation';
  revertMozartWorkflowToPreviousStep?: {
    __typename?: 'MozartActionResult';
    result: boolean;
    message?: string | null;
  } | null;
};

export const RevertMozartWorkflowToPreviousStepDocument = `
    mutation revertMozartWorkflowToPreviousStep($workflowInstanceId: UUID!, $stepId: UUID!) {
  revertMozartWorkflowToPreviousStep(
    workflowInstanceId: $workflowInstanceId
    stepId: $stepId
  ) {
    result
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    revertMozartWorkflowToPreviousStep: build.mutation<
      RevertMozartWorkflowToPreviousStepMutation,
      RevertMozartWorkflowToPreviousStepMutationVariables
    >({
      query: (variables) => ({
        document: RevertMozartWorkflowToPreviousStepDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRevertMozartWorkflowToPreviousStepMutation } = injectedRtkApi;
