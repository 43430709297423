import React from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

interface IAttachmentSearchProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
}

export const InstantSearchBar = ({
  searchTerm,
  setSearchTerm,
}: IAttachmentSearchProps) => (
  <Box>
    <TextField
      id="input-with-icon-adornment"
      size="small"
      type="text"
      variant="outlined"
      placeholder="Search"
      style={{ width: 250 }}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon
              size={14}
              icon={ICONS.Search}
              color={COLORS.GREY100}
              style={{ marginTop: '-2px' }}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {searchTerm.length === 0 ? null : (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setSearchTerm('')}
                  style={{ padding: '0px' }}
                  size="large"
                >
                  <Icon size={18} icon={ICONS.Close} color={COLORS.GREY100} />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  </Box>
);
