import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';

const EpisodesPathwayFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  return (
    <Typography style={{ wordWrap: 'break-word' }}>
      {props.value ? props.value : props.row.authorizationCategory}
    </Typography>
  );
};

const EpisodesPathwayProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={EpisodesPathwayFormatter} {...props} />
);

export default EpisodesPathwayProvider;
