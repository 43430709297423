import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import { Button, Typography } from '@mui/material';
import ProviderSearchDialogBody from './components/ProviderSearchDialogBody';
import { ProviderSearchResultRow } from 'graphql/graphqlTypes';
import { IRecentProvider } from './types';
import Loader from 'components/loader';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

export interface ProviderSearchDialogProps {
  isLoading: boolean;
  open: boolean;
  patientId: number;
  providers: ProviderSearchResultRow[];
  recent: IRecentProvider[];
  favorites: string[];
  providerType: string;
  updateFavorites: (favorites: string[]) => void;
  onSelect: (
    selectedProvider: number,
    selectedProviderAddressId: number
  ) => void;
  onCantFind: () => void;
  onSelectProviderAddressUiId: (uiId: string) => void;
  onSearch: (term: string) => void;
  onClose: () => void;
  setIsAdvancedSearch: (value: boolean) => void;
}

const StyledButton = styled(Button)({
  minWidth: '150px',
  height: '40px',
  '&:hover': {
    minWidth: '150px',
    height: '40px',
  },
});

const StyledLink = styled('a')({
  cursor: 'pointer',
  fontWeight: 500,
  letterSpacing: '0.5px',
  color: COLORS.SYMPHONY_BLUE,
});

const ProviderSearchDialog = (props: ProviderSearchDialogProps) => {
  const {
    isLoading,
    open,
    patientId,
    providers,
    recent,
    favorites,
    providerType,
    onClose,
    onSelect,
    onCantFind,
    onSearch,
    updateFavorites,
    setIsAdvancedSearch,
  } = props;
  const [selected, setSelected] = useState<number>(0);
  const [selectedProviderAddressId, setSelectedProviderAddressId] =
    useState<number>(0);

  const handleClose = () => {
    setSelected(0);
    setIsAdvancedSearch(false);
    onClose();
  };

  const handleSelect = () => {
    onSelect(selected, selectedProviderAddressId);
    handleClose();
  };

  const handleCantFind = () => {
    onCantFind();
    handleClose();
  };

  return (
    <>
      <Dialog
        data-testid="provider-search-dialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            style={{
              fontSize: '16px',
              color: COLORS.WHITE,
              paddingLeft: '12px',
            }}
          >
            Advanced Provider Search
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            height: '578px',
            overflowX: 'auto',
            padding: '24px 16px',
          }}
          dividers
        >
          <ProviderSearchDialogBody
            isFetching={isLoading}
            patientId={patientId}
            favorites={favorites}
            recent={recent}
            providers={providers}
            selected={selected}
            selectedProviderAddressId={selectedProviderAddressId}
            onSelect={setSelected}
            onProviderAddressSelect={setSelectedProviderAddressId}
            onSelectProviderAddressUiId={props.onSelectProviderAddressUiId}
            setSearchTerm={onSearch}
            onUpdateFavorites={updateFavorites}
            setIsAdvancedSearch={setIsAdvancedSearch}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1 }}>
            <StyledLink onMouseDown={handleCantFind}>
              I can&apos;t find {providerType}
            </StyledLink>
          </div>
          <div>
            <StyledButton
              data-testid="provider-search-dialog-cancel-button"
              onClick={handleClose}
              color="primary"
              variant="outlined"
              data-cy="cancel"
              style={{ marginRight: '20px' }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              onClick={handleSelect}
              disabled={selected <= 0}
              color="primary"
              variant="contained"
              data-cy="select"
            >
              Select
            </StyledButton>
          </div>
        </DialogActions>
      </Dialog>
      <Loader active={isLoading} />
    </>
  );
};

export default ProviderSearchDialog;
