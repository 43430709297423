import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Confirmation from 'components/modal/Confirmation';
import { useDiscardChecklistMutation } from 'graphql/hooks/discardChecklist';
import { IState } from 'store';
import Loader from 'components/loader';
import StyledLink from './StyledLink';
import { IChecklistLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IChecklistDeleteLinkProps extends IChecklistLinkProps {
  callback?: () => void;
  label?: string;
  onSuccess?: () => void;
}

const ChecklistDeleteLink = (props: IChecklistDeleteLinkProps) => {
  const [showChecklistDeleteConfirmation, setShowChecklistDeleteConfirmation] =
    useState(false);
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );

  const [
    discardChecklist,
    { isLoading: isDiscardingChecklist, isSuccess: isSuccessDiscardChecklist },
  ] = useDiscardChecklistMutation();

  const onDeleteChecklist = (checklistId: number) => {
    discardChecklist({
      checklistId: Number(checklistId),
      connectionId: sessionId,
    });
  };

  useEffect(() => {
    if (isSuccessDiscardChecklist) {
      props.onSuccess && props.onSuccess();
      props.callback && props.callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDiscardChecklist]);

  return (
    <>
      <CustomTooltip title={props.title ?? 'Delete'}>
        <StyledLink
          onClick={() => setShowChecklistDeleteConfirmation(true)}
          data-cy="Delete"
        >
          Delete
        </StyledLink>
      </CustomTooltip>
      <Confirmation
        open={showChecklistDeleteConfirmation}
        title="Delete Document?"
        okButtonText="Delete"
        okEvent={() => {
          setShowChecklistDeleteConfirmation(false);
          onDeleteChecklist(props.checklistId);
        }}
        cancelEvent={() => setShowChecklistDeleteConfirmation(false)}
      >
        <span>Are you sure you want to delete this document?</span>
      </Confirmation>
      <Loader active={isDiscardingChecklist} />
    </>
  );
};

export default ChecklistDeleteLink;
