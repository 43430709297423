import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type RemoveChecklistActionMutationVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
  actionId: Types.Scalars['String'];
}>;

export type RemoveChecklistActionMutation = {
  __typename?: 'Mutation';
  removeChecklistAction?: boolean | null;
};

export const RemoveChecklistActionDocument = `
    mutation removeChecklistAction($checklistId: Int!, $actionId: String!) {
  removeChecklistAction(checklistId: $checklistId, actionId: $actionId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    removeChecklistAction: build.mutation<
      RemoveChecklistActionMutation,
      RemoveChecklistActionMutationVariables
    >({
      query: (variables) => ({
        document: RemoveChecklistActionDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemoveChecklistActionMutation } = injectedRtkApi;
