import {
  CellComponentStyle,
  ILabelCellProps,
  LabelCell,
} from 'components/gridFormatters';
import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { teamMembersColumn } from '../teamMembers';
import { Typography } from '@mui/material';
import moment from 'moment';
import { UserCell } from './UserCell';
import { RoleCell } from './RoleCell';
import { RemindersCell } from './RemindersCell';
import { OwnerCell } from './OwnerCell';
import { CareTeamLabelCell } from './CareTeamLabelCell';

export const TeamMemberLabelCell = (props: ILabelCellProps) => {
  const { value, column } = props;

  switch (column.name) {
    case teamMembersColumn.member:
      return <UserCell {...props} />;
    case teamMembersColumn.isOwner:
      return <OwnerCell {...props} />;
    case teamMembersColumn.role:
      return <RoleCell {...props} />;
    case teamMembersColumn.note:
      return <CareTeamLabelCell {...props} />;
    case teamMembersColumn.reminders:
      return <RemindersCell {...props} />;
    case teamMembersColumn.updatedOn:
      return (
        <Table.Cell {...props} onClick={undefined} style={CellComponentStyle}>
          <Typography>
            {value ? moment(value).format('MM/DD/YYYY') : ''}
          </Typography>
        </Table.Cell>
      );
    default:
      return <LabelCell {...props} />;
  }
};
