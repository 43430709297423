import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMedsTabViewModelQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetMedsTabViewModelQuery = {
  __typename?: 'EntitiesQuery';
  getMedsTabViewModel: {
    __typename?: 'MedsTabViewModel';
    claimsDataAvailable?: boolean | null;
    filterState: Types.PatientMedicationStatusFilter;
    medicationAttributeTypes: Array<{
      __typename?: 'MedicationAttributeTypesViewModel';
      displayName: string;
      name: string;
      required: boolean;
    }>;
    medications: Array<{
      __typename?: 'MedicationViewModel';
      byVerbalReport?: boolean | null;
      dateFilled?: any | null;
      endDate?: any | null;
      id: number;
      instructions?: string | null;
      mdRecommended?: boolean | null;
      medication?: string | null;
      note?: string | null;
      patientState: Types.PatientMedicationStatus;
      patientUse?: Types.PatientMedicationPatientUse | null;
      pharmacy?: string | null;
      pharmacyFax?: string | null;
      pharmacyPhone?: string | null;
      quantityFilled?: string | null;
      reason?: string | null;
      refills?: string | null;
      source?: string | null;
      startDate?: any | null;
      type: Types.PatientMedicationType;
      provider?: {
        __typename?: 'Provider';
        id: number;
        name?: string | null;
      } | null;
    }>;
  };
};

export const GetMedsTabViewModelDocument = `
    query getMedsTabViewModel($patientId: Int!) {
  getMedsTabViewModel(patientId: $patientId) {
    claimsDataAvailable
    filterState
    medicationAttributeTypes {
      displayName
      name
      required
    }
    medications {
      byVerbalReport
      dateFilled
      endDate
      id
      instructions
      mdRecommended
      medication
      note
      patientState
      patientUse
      pharmacy
      pharmacyFax
      pharmacyPhone
      provider {
        id
        name
      }
      quantityFilled
      reason
      refills
      source
      startDate
      type
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMedsTabViewModel: build.query<
      GetMedsTabViewModelQuery,
      GetMedsTabViewModelQueryVariables
    >({
      query: (variables) => ({
        document: GetMedsTabViewModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetMedsTabViewModelQuery, useLazyGetMedsTabViewModelQuery } =
  injectedRtkApi;
