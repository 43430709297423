import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SaveQuickNoteMutationVariables = Types.Exact<{
  data: Types.SaveQuickNoteDialogDtoInput;
}>;

export type SaveQuickNoteMutation = {
  __typename?: 'Mutation';
  saveQuickNote?: {
    __typename?: 'FinishChecklistResultModel';
    checklistId: number;
    status: Types.FinishChecklistStatus;
    errors?: Array<string | null> | null;
  } | null;
};

export const SaveQuickNoteDocument = `
    mutation saveQuickNote($data: SaveQuickNoteDialogDtoInput!) {
  saveQuickNote(data: $data) {
    checklistId
    status
    errors
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveQuickNote: build.mutation<
      SaveQuickNoteMutation,
      SaveQuickNoteMutationVariables
    >({
      query: (variables) => ({ document: SaveQuickNoteDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSaveQuickNoteMutation } = injectedRtkApi;
