import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'store';
import { ChecklistStorageType, IChecklistState } from './types';
import { ChecklistCategory, ChecklistViewModel } from 'graphql/graphqlTypes';

const getDocumentState = (state: IState) => state.checklist.documentsState;
const getMozartState = (state: IState) => state.mozart;
const getChecklistTypeParam = <T>(_: T, storageType: ChecklistStorageType) =>
  storageType;
const getItemTypeParam = <T1, T2>(_1: T1, _2: T2, itemType: string) => itemType;

const getByItemType = (
  state: { [key: string]: IChecklistState },
  mozart: {
    completedChecklists: { [checklistId: string]: ChecklistViewModel };
  },
  checklistType: ChecklistStorageType,
  itemType: string
) => {
  const filterByType = (cat: ChecklistCategory) =>
    cat.items.filter((item) => item.type === itemType);
  const result =
    state[checklistType]?.checklist?.categories.flatMap(filterByType) ?? [];
  if (
    result.length ||
    checklistType !== ChecklistStorageType.MOZART ||
    !mozart?.completedChecklists
  ) {
    return result;
  }

  const list = Object.values(mozart.completedChecklists);
  list.sort((a, b) => {
    const lastChangeA = new Date(a.finishedOn ?? a.updatedOn ?? a.createdOn);
    const lastChangeB = new Date(b.finishedOn ?? b.updatedOn ?? b.createdOn);
    return lastChangeB.getTime() - lastChangeA.getTime();
  });

  return (
    list
      .map((checklist) => {
        return checklist?.categories?.flatMap(filterByType) ?? [];
      })
      .find((s) => s.length) ?? []
  );
};

export const allItemsByTypeSelector = createSelector(
  getDocumentState,
  getMozartState,
  getChecklistTypeParam,
  getItemTypeParam,
  getByItemType
);
