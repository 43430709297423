import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import {
  MozartProgressState,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import { MozartStep } from 'features/mozart/components/MozartStep/MozartStep';
import ReviewSections from 'components/review/sections';
import Sections from 'components/actions/sections/sections';
import React, { useEffect, useRef } from 'react';
import { useUserNotAuthorizedToStartChecklist } from '../MozartContent.helpers';
import useMozartActionLock from './useLockMozartAction';

interface IMozartUserInputProps {
  message: MozartWorkflowUiModel;
  isLoadingFinished: boolean;
}

export const MozartUserInput = (props: IMozartUserInputProps) => {
  const { message, isLoadingFinished } = props;
  const { userNotAuthorizedToStartChecklist } =
    useUserNotAuthorizedToStartChecklist(message);

  const completedSections = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );

  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].checklist
  );

  const checklistRef = useRef<HTMLDivElement>(null);

  const isCompleted =
    message.state === MozartProgressState.Completed ||
    (message.state === MozartProgressState.UserInputComplete &&
      !!message.checkListId);

  const isAccessible =
    message.state === MozartProgressState.Paused &&
    !userNotAuthorizedToStartChecklist &&
    checklist != null &&
    isLoadingFinished;

  useMozartActionLock(
    !isCompleted && isAccessible,
    message.workflowInstanceId,
    message.actionInstanceId
  );

  useEffect(() => {
    setTimeout(() => {
      if (isLoadingFinished) {
        if (message.autoScrollToActionInstanceId) {
          const section = document.querySelector(
            `#section-${message.autoScrollToActionInstanceId}`
          );
          section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (checklistRef.current) {
          window.scrollTo({
            behavior: 'smooth',
            top: checklistRef.current.offsetTop,
          });
        }
      }
    }, 300);
  }, [isLoadingFinished, message.autoScrollToActionInstanceId]);

  if (isCompleted) {
    return (
      <div>
        <MozartStep
          currentStep={message.leftHead as string}
          message={message}
        />
        <ReviewSections
          storageType={ChecklistStorageType.MOZART}
          checklist={completedSections[message.checkListId]}
          actionInstanceId={message.actionInstanceId}
        />
      </div>
    );
  }

  if (isAccessible) {
    return (
      <div ref={checklistRef}>
        <MozartStep
          currentStep={message.leftHead as string}
          message={message}
        />
        <Sections
          storageType={ChecklistStorageType.MOZART}
          autoSave={true}
          testId="mozart-checklist-sections"
          isMozartFlow
        />
      </div>
    );
  }
  return null;
};
