export const gridColumns = [
  {
    name: 'userName',
    title: 'User Name',
  },
  {
    name: 'totalCaseLoad',
    title: 'Total CaseLoad',
  },
  {
    name: 'caseManagementCaseLoad',
    title: 'CM Caseload',
  },
  {
    name: 'careTransitionCaseLoad',
    title: 'CTH Caseload',
  },
  {
    name: 'eRFollowUpCaseLoad',
    title: 'ER Caseload',
  },
  {
    name: 'reminderDueThisWeek',
    title: 'Reminders Due This Week',
  },
  {
    name: 'reminderDueToday',
    title: 'Reminders Due Today',
  },
  {
    name: 'reminderOverDue',
    title: 'Overdue Reminders',
  },
];

export const columnsExtension = [
  { columnName: 'userName', width: '21%' },
  { columnName: 'totalCaseLoad', width: '10%' },
  { columnName: 'caseManagementCaseLoad', width: '10%' },
  { columnName: 'careTransitionCaseLoad', width: '10%' },
  { columnName: 'eRFollowUpCaseLoad', width: '10%' },
  { columnName: 'reminderDueThisWeek', width: '15%' },
  { columnName: 'reminderDueToday', width: '12%' },
  { columnName: 'reminderOverDue', width: '10%' },
];

export const totalSummaryItems = [
  { columnName: 'totalCaseLoad', type: 'sum' },
  { columnName: 'caseManagementCaseLoad', type: 'sum' },
  { columnName: 'careTransitionCaseLoad', type: 'sum' },
  { columnName: 'eRFollowUpCaseLoad', type: 'sum' },
  { columnName: 'reminderDueThisWeek', type: 'sum' },
  { columnName: 'reminderDueToday', type: 'sum' },
  { columnName: 'reminderOverDue', type: 'sum' },
  { columnName: 'userName', type: 'avg' }, // For Total Label purpose in userName column.
];
