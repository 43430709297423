import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import React from 'react';
import styled from 'styled-components';
import Icon, { ICONS } from 'components/icon';
import { useSelector } from 'react-redux';
import { IState } from 'store';

const StyledTableHeaderRowCell = styled(TableHeaderRow.Cell)({
  fontSize: '12px',
  fontWeight: 500,
  padding: '10px 6px 5px 6px !important',
  color: COLORS.GREY100,
  backgroundColor: COLORS.GREY10,
});

export const HeaderCellComponent = (props: TableHeaderRow.CellProps) => {
  const appliedFilters = useSelector(
    (state: IState) => state.home.homeModel.filterBy
  );
  const isFilterApplied = appliedFilters?.some(
    (filter) => filter.columnName === props.column.name
  );

  return (
    <StyledTableHeaderRowCell {...props}>
      {props.children}
      {isFilterApplied ? (
        <Icon size={12} icon={ICONS.FilterActive} color={COLORS.GREY100} />
      ) : null}
    </StyledTableHeaderRowCell>
  );
};
