import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';

const MozartActionWrapper = styled.div<{
  $isDoneStep: boolean;
  $isPrinting: boolean;
  $borderColor?: COLORS;
}>`
  display: flex;
  align-items: flex-start;
  padding: ${(props) => (props.$isPrinting ? `10px 10px` : `28px 32px`)};
  background-color: ${COLORS.WHITE};
  margin-bottom: ${(props) => (props.$isPrinting ? `5px` : `16px`)};
  border: 1px solid ${COLORS.GREY25};
  border-radius: 4px;
  flex-direction: column;
  border-top: ${(props) =>
    props.$isDoneStep
      ? ` 4px solid ${props.$borderColor ?? COLORS.GREEN100}`
      : `1px solid ${COLORS.GREY25}`};
`;

export interface IMozartActionContainerProps {
  children: ReactNode;
  isDoneStep?: boolean;
  borderColor?: COLORS;
}

export const MozartActionContainer = ({
  children,
  isDoneStep,
  borderColor,
}: IMozartActionContainerProps) => {
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isOptimizedView = printDocuments.isOptimizedView;
  return (
    <MozartActionWrapper
      $isDoneStep={Boolean(isDoneStep)}
      $isPrinting={isOptimizedView}
      $borderColor={borderColor}
    >
      {children}
    </MozartActionWrapper>
  );
};
