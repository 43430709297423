import { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { debounce } from 'lodash';

const getScreenSize = (width?: number | null) => {
  if (!width) {
    return 'md';
  }
  if (width < 320) {
    return 'xs';
  }
  if ((width >= 320 && width < 991) || width < 320) {
    return 'sm';
  }
  if (width >= 320 && width < 991) {
    return 'sm';
  }
  if (width < 1300) {
    return 'md';
  }
  return 'lg';
};

export const useWidth = (elementRef: HTMLDivElement | null) => {
  const [widthProperties, setWidthProperties] = useState({
    width: 0,
    screenSize: '',
  });
  const [refMounted, setRefMounted] = useState(false);

  const updateWidthProperties = useCallback(() => {
    if (elementRef) {
      setWidthProperties({
        width: elementRef?.offsetWidth,
        screenSize: getScreenSize(elementRef?.offsetWidth) ?? 'md',
      });
    }
  }, [elementRef]);
  const debouncedHandler = debounce(updateWidthProperties, 100);

  useLayoutEffect(() => {
    window.addEventListener('resize', debouncedHandler);
    return () => {
      window.removeEventListener('resize', debouncedHandler);
    };
  }, [debouncedHandler, updateWidthProperties]);

  useEffect(() => {
    if (!refMounted && elementRef) {
      updateWidthProperties();
      setRefMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);

  return widthProperties;
};
