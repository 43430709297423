import React, { ChangeEvent } from 'react';
import { InputAdornment, OutlinedInput, styled } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { ILetter } from '..';
import { SelectAction } from 'backend/types/selectAction';

const StyledInput = styled(OutlinedInput)({
  width: '100%',
  marginBottom: '20px',
});

export interface ILetterFilterProps {
  setLetters: (letters: ILetter[]) => void;
  setLetterSearch: (letterSearch: string) => void;
  letters: ILetter[];
  letterGroupId: string;
  letterGroupValue: string;
}

export const LetterFilter = ({
  setLetters,
  setLetterSearch,
  letters,
  letterGroupId,
  letterGroupValue,
}: ILetterFilterProps) => {
  const filterLetters = (text: string) => {
    setLetterSearch(text);
    const value = letterGroupValue.split('-')[1];
    if (!text) {
      const all = letters.map((letter: ILetter) => ({
        ...letter,
        selected:
          value === SelectAction.SelectAll &&
          letter.letterGroup === letterGroupId,
        display: letterGroupId === '' || letter.letterGroup === letterGroupId,
      }));
      setLetters(all);
      return;
    }

    const search = text.toLocaleLowerCase();

    const filtered = letters.map((letter: ILetter) => ({
      ...letter,
      selected:
        value === SelectAction.SelectAll &&
        letter.letterGroup === letterGroupId &&
        letter.title.toLocaleLowerCase().includes(search),
      display:
        (letterGroupId === '' || letter.letterGroup === letterGroupId) &&
        letter.title.toLocaleLowerCase().includes(search),
    }));

    setLetters(filtered);
  };

  return (
    <StyledInput
      type="text"
      placeholder="Search..."
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        filterLetters(event.target.value)
      }
      startAdornment={
        <InputAdornment position="start">
          <Icon
            icon={ICONS.Search}
            size={14}
            color={COLORS.GREY100}
            style={{ marginTop: '-2px' }}
          />
        </InputAdornment>
      }
    />
  );
};
