import { Dispatch } from 'redux';
import {
  getRolesFail,
  getRolesStart,
  getRolesSuccess,
  setRolesLoading,
} from './action';
import { IAccount, IRole, IRoleContext } from './types';
import { ILoginUser } from 'store/user/types';
import axiosGitHubGraphQL from 'util/axiosHttp';
import { authSuccessful } from 'util/authUtils';
import { IAuthUser } from 'backend/types/authUser';
import { AxiosResponse } from 'axios';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import moment from 'moment';
import { DashboardCareSiteRole, SuperAdminRoleId } from 'consts/roles';

export const getUserRoles =
  (user: ILoginUser, isLoggedIn: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    const dispatchFailure = (err: string) => {
      dispatch(getRolesFail(err));
      dispatch(
        showErrorPopup({
          message: 'Cannot Get User Roles: Internal Server Error',
        })
      );
    };

    const onGetUserRoles = (result: AxiosResponse) => {
      if (result.data.isError) {
        dispatch(showErrorPopup(result.data.message));
        return;
      }

      const isDashboardAccess = result.data.roles?.some(
        (role: IRole) => role.roleId == DashboardCareSiteRole.roleId
      );

      const userRoles = isDashboardAccess
        ? result.data.roles?.concat(DashboardCareSiteRole)
        : result?.data?.roles;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const roles = userRoles?.map((r: any) => {
        return {
          ...r,
          id: parseInt(r.id),
          roleId: parseInt(r.roleId),
          careSiteId: parseInt(r.careSiteId),
        } as IRole;
      });
      const lastCareSiteId = result.data.lastCareSite
        ? parseInt(result.data.lastCareSite)
        : result.data.lastCareSite;
      const isSuperAdmin = roles.some(
        (r: IRole) => r.roleId === SuperAdminRoleId
      );
      dispatch(getRolesSuccess(user, lastCareSiteId, roles, isSuperAdmin));
    };

    dispatch(getRolesStart());

    if (isLoggedIn) {
      axiosGitHubGraphQL
        .get('login/getuserroles')
        .then(onGetUserRoles)
        .catch(dispatchFailure);
    } else {
      axiosGitHubGraphQL
        .post('login/getuserroles', user)
        .then(onGetUserRoles)
        .catch(dispatchFailure);
    }
  };

export const getAuthUser = (result: AxiosResponse): IAuthUser => {
  const data = result.data;
  const idleTimeout = parseInt(data.idleTimeout);
  const authorizationTimeout = parseInt(data.authorizationTimeout);
  const earliestRefreshOffsetFromExpiryDate = parseInt(
    data.earliestRefreshOffsetFromExpiryDate
  );

  // NOTE: it is assumed that "getAuthUser" will be called right after the retrieval of a new token pair
  // (access token + refresh token), so the current moment is considered to be when the tokens were
  // created (with some margin of error), but measuring in the client time, instead of the server time
  const tokenExpiryInClientTime = moment()
    .add(authorizationTimeout, 'minutes')
    .toDate();

  const tokenEarliestRefreshInClientTime = moment(tokenExpiryInClientTime)
    .add(-earliestRefreshOffsetFromExpiryDate, 'minutes')
    .toDate();

  return {
    id: parseInt(data.userId),
    login: data.login,
    name: data.userName,
    roleId: parseInt(data.roleId),
    role: data.roleName,
    careSite: data.careSiteName,
    type: data.roleType,
    token: data.token,
    tokenExpiryInClientTime: tokenExpiryInClientTime,
    tokenEarliestRefreshInClientTime: tokenEarliestRefreshInClientTime,
    earliestRefreshOffsetFromExpiryDate: earliestRefreshOffsetFromExpiryDate,
    refreshToken: data.refreshToken,
    timeZone: data.timeZone,
    sessionId: data.sessionId,
    userRoleId: parseInt(data.userRoleId),
    careSiteId: parseInt(data.careSiteId),
    idleTimeout: idleTimeout,
    authorizationTimeout: authorizationTimeout,
    loggedInAsProvider: !!data.loggedInAsProvider,
    redirectUrl: data.redirectTo,
    doesUserRoleHaveOrganizationAccess: data.doesUserRoleHaveOrganizationAccess,
  };
};

export const setUserRole = (
  roleId: number,
  sessionId: string,
  user: ILoginUser,
  primaryRoleId: number,
  isLoggedIn: boolean
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const postToSwitchRole = (url: string, data: IRoleContext | IAccount) => {
      dispatch(setRolesLoading(true));
      axiosGitHubGraphQL
        .post(url, data)
        .then((result) => {
          if (result.data.isError) {
            dispatch(showErrorPopup({ message: result.data.message }));
            return;
          }
          dispatch(setRolesLoading(false));
          authSuccessful(result, dispatch);
        })
        .catch(() => {
          dispatch(setRolesLoading(false));
          dispatch(
            showErrorPopup({ message: 'Cannot Login: Internal Server Error' })
          );
        });
    };
    if (isLoggedIn) {
      const roleCtx: IRoleContext = {
        selectedRole: roleId,
        primaryRole: primaryRoleId,
        sessionId: sessionId,
      };
      postToSwitchRole('login/changeRole', roleCtx);
    } else {
      const account: IAccount = {
        login: user.login,
        password: user.password,
        selectedRole: roleId,
        primaryRole: primaryRoleId,
      };
      postToSwitchRole('login/endjwt', account);
    }
  };
};
