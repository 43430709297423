import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import styled from 'styled-components';

interface IConfirmationDialogProps {
  title: string;
  icon?: ICONS;
  okButtonText?: string;
  cancelButtonText?: string;
  open: boolean;
  children: ReactNode;
  disableBackdropClick?: boolean;

  okEvent(): void;

  cancelEvent(): void;
}

const StyledTypography = styled(Typography)`
  padding: 20px 24px 0 24px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
`;

const Confirmation = (props: IConfirmationDialogProps) => {
  const {
    open,
    title,
    icon,
    okButtonText,
    cancelButtonText,
    okEvent,
    cancelEvent,
    children,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={cancelEvent}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledTypography variant="subtitle1" id="alert-dialog-title">
        {icon && <Icon icon={icon} size={24} />}
        <span>{title}</span>
      </StyledTypography>
      <DialogContent style={{ width: 400 }}>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cancelEvent}
          variant="outlined"
          color="primary"
          size="large"
          autoFocus
          data-cy="cancel"
        >
          {cancelButtonText ? cancelButtonText : 'Cancel'}
        </Button>
        <Button
          onClick={okEvent}
          color="primary"
          size="large"
          variant="contained"
          data-cy="ok"
        >
          {okButtonText ? okButtonText : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
