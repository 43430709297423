import React, { ReactNode } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const RecentFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const iconToRender = (
    props: DataTypeProvider.ValueFormatterProps
  ): ReactNode => (
    <>
      {(props.value.recent as number[]).includes(props.value.id) ? (
        <Icon icon={ICONS.Update} size={13} color={COLORS.GREY100} />
      ) : null}
    </>
  );
  return <div>{iconToRender(props)}</div>;
};

export default RecentFormatter;
