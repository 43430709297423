import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientTagsTabQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientTagsTabQuery = {
  __typename?: 'EntitiesQuery';
  getPatientTagsTab?: Array<{
    __typename?: 'PatientEditTagsTabViewModel';
    id: number;
    icon?: string | null;
    name: string;
    description?: string | null;
    type: string;
    isActive?: boolean | null;
    sortOrder?: number | null;
  } | null> | null;
};

export const GetPatientTagsTabDocument = `
    query getPatientTagsTab($patientId: Int!) {
  getPatientTagsTab(patientId: $patientId) {
    id
    icon
    name
    description
    type
    isActive
    sortOrder
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientTagsTab: build.query<
      GetPatientTagsTabQuery,
      GetPatientTagsTabQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientTagsTabDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPatientTagsTabQuery, useLazyGetPatientTagsTabQuery } =
  injectedRtkApi;
