import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React from 'react';
import Loader from 'components/loader';
import { useGetProviderDetailsQuery } from 'graphql/hooks/getProviderDetails';

interface IProviderDetailsProps {
  patientId: number;
  providerId: number;
  closeProviderDetails: () => void;
}

const ProviderDetails = (props: IProviderDetailsProps) => {
  const { closeProviderDetails, patientId, providerId } = props;

  const { data, isFetching: isFetchingDetails } = useGetProviderDetailsQuery(
    {
      patientId: patientId,
      providerId: providerId,
      addressId: null,
    },
    { skip: !(providerId > 0) }
  );

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="20px"
        >
          <Typography variant="subtitle2">Provider Details</Typography>
          <IconButton
            aria-label="close"
            onClick={closeProviderDetails}
            data-cy="close"
            style={{ padding: '4px' }}
            size="large"
          >
            <Icon icon={ICONS.Close} size={24} color={COLORS.GREY100} />
          </IconButton>
        </Box>
        <Box px="20px">
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              Last Name:
            </Typography>
            <Typography>{data?.getProviderDetails?.lastName}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              First Name:
            </Typography>
            <Typography>{data?.getProviderDetails?.firstName}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              Provider #:
            </Typography>
            <Typography>{data?.getProviderDetails?.number}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              NPI:
            </Typography>
            <Typography>{data?.getProviderDetails?.npi}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              Tax ID:
            </Typography>
            <Typography>{data?.getProviderDetails?.taxId}</Typography>
          </Box>
          <Box display="flex" alignItems="flex-start" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              Address:
            </Typography>
            <Typography>
              {data?.getProviderDetails?.addresses?.join(', ')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              {data?.getProviderDetails?.practiceNamesLabel}:
            </Typography>
            <Typography>
              {data?.getProviderDetails?.practiceNames?.join(', ')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              DPG:
            </Typography>
            <Typography>{data?.getProviderDetails?.dPG?.name}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              Seamless:
            </Typography>
            {data?.getProviderDetails?.seamless && (
              <Typography>
                {data?.getProviderDetails?.seamless === true ? 'Y' : 'N'}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="flex-start" mb="12px">
            <Typography
              noWrap
              variant="body2"
              style={{
                paddingRight: '4px',
                width: '82px',
              }}
            >
              PAR Notes:
            </Typography>
            <Typography>{data?.getProviderDetails?.parNotes}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              {data?.getProviderDetails?.addressIdsLabel}:
            </Typography>
            <Typography>
              {data?.getProviderDetails?.addressIds?.join(', ')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              Type:
            </Typography>
            <Typography>
              {data?.getProviderDetails?.types?.join(', ')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Loader active={isFetchingDetails} />
    </>
  );
};
export default ProviderDetails;
