import React, { forwardRef } from 'react';
import BaseMultiAutocomplete from './BaseMultiAutocomplete';
import { EntityAutoCompleteParams, IBaseAutocompleteRef } from './types';
import { ILookupValue } from 'backend/types/lookupValue';

export interface ILookupAutocompleteParams extends EntityAutoCompleteParams {
  lookupValues: ILookupValue[];
}

const LookupAutocomplete = forwardRef<
  IBaseAutocompleteRef,
  ILookupAutocompleteParams
>(({ lookupValues, ...rest }: ILookupAutocompleteParams, ref) => {
  return <BaseMultiAutocomplete ref={ref} options={lookupValues} {...rest} />;
});

LookupAutocomplete.displayName = 'LookupAutocomplete';
export default LookupAutocomplete;
