import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import PriorAuthTextFormatter from '../formatters/PriorAuthTextFormatter';

const PriorAuthTextProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PriorAuthTextFormatter} {...props} />
);

export default PriorAuthTextProvider;
