import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type CreateOrUpdatePatientMutationVariables = Types.Exact<{
  patient?: Types.InputMaybe<Types.PatientInfoInput>;
  episodeReminders:
    | Array<Types.UpdateReminderDtoInput>
    | Types.UpdateReminderDtoInput;
  memberReminders:
    | Array<Types.UpdateReminderDtoInput>
    | Types.UpdateReminderDtoInput;
  attachments: Types.UpdateAttachmentsModelInput;
  careGaps: Types.UpdatePatientCareGapsModelInput;
  attachmentsToMove:
    | Array<Types.InputMaybe<Types.MoveAttachmentsModelInput>>
    | Types.InputMaybe<Types.MoveAttachmentsModelInput>;
}>;

export type CreateOrUpdatePatientMutation = {
  __typename?: 'Mutation';
  createOrUpdatePatient?: boolean | null;
  updateAttachments?: boolean | null;
  updatePatientCareGaps?: boolean | null;
  moveAttachments?: boolean | null;
  updateEpisodeReminders?: boolean | null;
  updateMemberReminders?: boolean | null;
};

export const CreateOrUpdatePatientDocument = `
    mutation createOrUpdatePatient($patient: PatientInfoInput, $episodeReminders: [UpdateReminderDtoInput!]!, $memberReminders: [UpdateReminderDtoInput!]!, $attachments: UpdateAttachmentsModelInput!, $careGaps: UpdatePatientCareGapsModelInput!, $attachmentsToMove: [MoveAttachmentsModelInput]!) {
  createOrUpdatePatient(patient: $patient)
  updateEpisodeReminders: updateEntityReminders(
    entitiesReminders: $episodeReminders
  )
  updateMemberReminders: updateEntityReminders(
    entitiesReminders: $memberReminders
  )
  updateAttachments(model: $attachments)
  updatePatientCareGaps(model: $careGaps)
  moveAttachments(model: $attachmentsToMove)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createOrUpdatePatient: build.mutation<
      CreateOrUpdatePatientMutation,
      CreateOrUpdatePatientMutationVariables
    >({
      query: (variables) => ({
        document: CreateOrUpdatePatientDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateOrUpdatePatientMutation } = injectedRtkApi;
