import { useLazyDownloadFileQuery } from 'graphql/hooks/downloadFile';
import { IDownloadFile } from 'backend/types/downloadFile';
import { base64ToByteArray } from 'util/helpers/base64Helper';
import { useEffect } from 'react';

const downloadMimeTypes = new Set<string>([
  'application/octet-stream',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useDownloadFile = (label?: string) => {
  const [
    downloadFile,
    {
      data: downloadFileResult,
      isSuccess: isSuccessDownLoadFile,
      isFetching: isDownloadingFile,
      requestId: downloadReqId,
    },
  ] = useLazyDownloadFileQuery();

  //TODO: refactor me, we should not manipulate DOM elements in react.
  const makeDownloadResponse = (file: IDownloadFile) => {
    const url = window.URL.createObjectURL(
      new Blob([base64ToByteArray(file.content)], { type: file.mimeType })
    );
    const link = document.createElement('a');
    link.href = url;
    if (downloadMimeTypes.has(file.mimeType)) {
      link.setAttribute('download', file.fileName); // If need to download instead showing in a new tab
    } else {
      link.setAttribute('target', '_blank');
    }
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  useEffect(
    () => {
      if (isSuccessDownLoadFile) {
        makeDownloadResponse({
          fileName: downloadFileResult?.downloadFile?.fileName ?? '',
          content: downloadFileResult?.downloadFile?.content ?? '',
          mimeType: downloadFileResult?.downloadFile?.mimeType ?? '',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessDownLoadFile, downloadReqId]
  );

  return { downloadFile, isDownloadingFile };
};

export { useDownloadFile };
