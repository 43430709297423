import React, { ReactNode } from 'react';
import { Box, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { map } from 'lodash';
import styled from 'styled-components';

const StyledSelect = styled(Select)({
  paper: {
    borderRadius: '4px',
    border: '1px solid #CECECE',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    width: '100%',
  },
  select: {
    backgroundColor: 'transparent',
  },
  '.MuiSelect-root': {
    backgroundColor: 'transparent',
  },
});

interface IStatusSelectProps {
  value: string;
  onChange: (value: string) => void;
  values: { [key: string]: { name: string; color: COLORS } };
  variant: 'filled' | 'outlined' | 'standard';
}

export const StatusSelect = ({
  value,
  onChange,
  variant,
  values,
}: IStatusSelectProps) => {
  const MenuProps = {
    style: {
      left: '0',
      marginTop: '2px',
      maxHeight: '350px',
      width: '150px',
    },
  };
  const renderSelectedValue = (renderValue: string): ReactNode => {
    const selectedValue = values[renderValue];
    return (
      <Box display="flex" alignItems="center">
        <Box
          style={{
            borderRadius: '50%',
            height: '8px',
            width: '8px',
            marginRight: '8px',
            backgroundColor: selectedValue?.color,
          }}
        />
        <Typography variant="body1">{selectedValue?.name}</Typography>
      </Box>
    );
  };

  return (
    <StyledSelect
      displayEmpty
      value={value}
      variant={variant}
      disableUnderline={variant !== 'outlined'}
      style={{ backgroundColor: 'transparent' }}
      onChange={(event) => onChange(event.target.value as string)}
      MenuProps={{
        ...MenuProps,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      renderValue={() => (value ? renderSelectedValue(value) : '')}
    >
      {map(values, (item, key) => (
        <MenuItem value={key} key={key}>
          <ListItemText>{item.name}</ListItemText>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
