import React, { useState } from 'react';
import { Column, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { ChecklistProblemViewModel } from 'graphql/graphqlTypes';
import { ToggleCellComponent } from 'components/home/patientdetails/tabscontent/episodesTab/subTabs/casesTab/ToggleCellComponent';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import DeleteByIdProvider from './Formatters/DeleteByIdProvider';
import InCarePlanProvider from './Formatters/InCarePlanProvider';
import CommentProvider from './Formatters/CommentProvider';
import { CarePlanProblemRowDetails } from './CarePlanProblemRowDetails';
import { problemsColumnsExtensionStyles } from './problems.helper';

interface ICarePlanProblemsGridProps<T> {
  rows: T[];
  columns: Column[];
  isReadOnly: boolean;
  getRowId: (item: T) => number;
}

const CarePlanProblemsGrid = <T extends ChecklistProblemViewModel>(
  props: ICarePlanProblemsGridProps<T>
) => {
  const { getRowId, rows, columns, isReadOnly } = props;
  const [expandedRowIds, setExpandedRowIds] = useState<number[]>(
    rows?.filter((x) => x.inCarePlan).map(({ id }) => Number(id)) ?? []
  );
  const contentComponent = (contentProps: TableRowDetail.ContentProps) => (
    <CarePlanProblemRowDetails {...contentProps} isReadOnly={isReadOnly} />
  );
  return (
    <Grid rows={rows} columns={columns} getRowId={getRowId}>
      <RowDetailState
        expandedRowIds={expandedRowIds}
        onExpandedRowIdsChange={(ids) => setExpandedRowIds(ids as number[])}
      />
      <InCarePlanProvider for={['inCarePlan']} />
      <CommentProvider for={['note']} />
      <DeleteByIdProvider for={['id']} />
      <SymphonyTable columnExtensions={problemsColumnsExtensionStyles} />
      <SymphonyTableHeaderRow />
      <TableRowDetail
        contentComponent={contentComponent}
        toggleCellComponent={ToggleCellComponent}
      />
    </Grid>
  );
};

export default CarePlanProblemsGrid;
