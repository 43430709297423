import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';

export const IdProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={(formProps: DataTypeProvider.ValueFormatterProps) => (
      <Typography style={{ color: COLORS.SYMPHONY_BLUE }}>
        {formProps.value}
      </Typography>
    )}
    {...props}
  />
);
