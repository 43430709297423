import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getChangedRows } from 'components/gridFormatters/getChangedRows';
import { ChangeSet } from '@devexpress/dx-react-grid';
import {
  CareTeamMemberDtoInput,
  FamilyMemberDtoInput,
  PatientCareTeamFamilyViewModel,
  PatientCareTeamMemberViewModel,
  PatientCareTeamProviderViewModel,
  PatientCareTeamTabViewModel,
  ProviderMemberDtoInput,
} from 'graphql/graphqlTypes';

export interface IPatientDetailsCareTeamState {
  careTeamTab: PatientCareTeamTabViewModel | null;
  areTeamMembersUpdated: boolean;
  updatedTeamMembers: CareTeamMemberDtoInput[] | null;
  areProvidersUpdated: boolean;
  updatedProviders: ProviderMemberDtoInput[] | null;
  isFamilyUpdated: boolean;
  updatedFamily: FamilyMemberDtoInput[] | null;
  selectedProviderId: number | null;
}

export const initialPatientDetailsCareTeamState: IPatientDetailsCareTeamState =
  {
    careTeamTab: null,
    areTeamMembersUpdated: false,
    updatedTeamMembers: null,
    areProvidersUpdated: false,
    updatedProviders: null,
    isFamilyUpdated: false,
    updatedFamily: null,
    selectedProviderId: null,
  };

export const mapTeamMemberToInputDto = (
  row: PatientCareTeamMemberViewModel
): CareTeamMemberDtoInput => {
  return {
    userId: row.member.id,
    isOwner: row.isOwner ?? false,
    note: row.note,
  };
};

export const mapProviderToInputDto = (
  row: PatientCareTeamProviderViewModel
): ProviderMemberDtoInput => {
  return {
    id: row.provider.id,
    isPrimary: row.isPrimary ?? false,
    note: row.note,
  };
};

export const mapFamilyToInputDto = (
  row: PatientCareTeamFamilyViewModel
): FamilyMemberDtoInput => {
  return {
    id: row.id,
    name: row.name,
    consentToShare: row.consentToShare ?? false,
    note: row.note,
    isCaregiver: row.isCaregiver ?? false,
    relationshipId: row.relationshipId ?? 0,
  };
};

const patientDetailsCareTeamSlice = createSlice({
  name: 'PatientDetailsCareTeamState',
  initialState: initialPatientDetailsCareTeamState,
  reducers: {
    closePatientDetailsModal: () => {
      return initialPatientDetailsCareTeamState;
    },
    selectProvider: (
      state,
      action: PayloadAction<{
        id: number | null;
      }>
    ) => {
      const payload = action.payload;
      state.selectedProviderId = payload.id;
    },
    updatePatientCareTeam(
      state,
      action: PayloadAction<PatientCareTeamTabViewModel>
    ) {
      state.careTeamTab = action.payload;
    },
    updateConsentToShare: (
      state,
      action: PayloadAction<{
        id: number;
        value: boolean;
      }>
    ) => {
      const payload = action.payload;
      const found = state.careTeamTab?.family?.find((f) => f.id === payload.id);
      if (found) {
        found.consentToShare = payload.value;
        state.isFamilyUpdated = true;
        state.updatedFamily =
          state.careTeamTab?.family?.map(mapFamilyToInputDto) || [];
      }
    },
    updateOwner: (
      state,
      action: PayloadAction<{
        id: number;
        value: boolean;
      }>
    ) => {
      const payload = action.payload;
      state.careTeamTab?.teamMembers.forEach((tm) => {
        if (tm.id === payload.id) {
          tm.isOwner = payload.value;
        } else if (payload.value) {
          tm.isOwner = false;
        }
      });
      state.areTeamMembersUpdated = true;
      state.updatedTeamMembers =
        state.careTeamTab?.teamMembers?.map(mapTeamMemberToInputDto) || [];
    },
    updatePrimary: (
      state,
      action: PayloadAction<{
        id: number;
        value: boolean;
      }>
    ) => {
      const payload = action.payload;
      state.careTeamTab?.providers?.forEach((p) => {
        if (p.id === payload.id) {
          p.isPrimary = payload.value;
        } else if (payload.value) {
          p.isPrimary = false;
        }
      });
      state.areProvidersUpdated = true;
      state.updatedProviders =
        state.careTeamTab?.providers?.map(mapProviderToInputDto) || [];
    },
    updateCaregiver: (
      state,
      action: PayloadAction<{
        id: number;
        value: boolean;
      }>
    ) => {
      const payload = action.payload;
      state.careTeamTab?.family.forEach((f) => {
        if (f.id === payload.id) {
          f.isCaregiver = payload.value;
        } else if (payload.value) {
          f.isCaregiver = false;
        }
      });
      state.isFamilyUpdated = true;
      state.updatedFamily =
        state.careTeamTab?.family?.map(mapFamilyToInputDto) || [];
    },
    commitTeamMembers: (state, action: PayloadAction<ChangeSet>) => {
      const changeSet = action.payload;

      if (state.careTeamTab) {
        const teamMembers = state.careTeamTab.teamMembers;
        state.careTeamTab.teamMembers = getChangedRows(changeSet, teamMembers);
        const saveDto = state.careTeamTab.teamMembers.map(
          mapTeamMemberToInputDto
        );
        state.areTeamMembersUpdated = true;
        state.updatedTeamMembers = saveDto;
      }
    },
    commitProviders: (state, action: PayloadAction<ChangeSet>) => {
      const changeSet = action.payload;

      if (state.careTeamTab) {
        const providers = state.careTeamTab.providers;
        state.careTeamTab.providers = getChangedRows(changeSet, providers);
        const saveDto = state.careTeamTab.providers.map(mapProviderToInputDto);
        state.areProvidersUpdated = true;
        state.updatedProviders = saveDto;
      }
    },
    commitFamily: (state, action: PayloadAction<ChangeSet>) => {
      const changeSet = action.payload;

      if (state.careTeamTab) {
        const family = state.careTeamTab.family;
        state.careTeamTab.family = getChangedRows(changeSet, family);
        const saveDto = state.careTeamTab.family.map(mapFamilyToInputDto);
        state.isFamilyUpdated = true;
        state.updatedFamily = saveDto;
      }
    },
  },
});

export const {
  updatePatientCareTeam,
  updateOwner,
  updatePrimary,
  updateCaregiver,
  commitTeamMembers,
  commitProviders,
  commitFamily,
  updateConsentToShare,
  closePatientDetailsModal,
  selectProvider,
} = patientDetailsCareTeamSlice.actions;

export default patientDetailsCareTeamSlice.reducer;
