import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type DiscardChecklistMutationVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
  connectionId: Types.Scalars['String'];
}>;

export type DiscardChecklistMutation = {
  __typename?: 'Mutation';
  discardChecklist?: boolean | null;
};

export const DiscardChecklistDocument = `
    mutation discardChecklist($checklistId: Int!, $connectionId: String!) {
  discardChecklist(checklistId: $checklistId, connectionId: $connectionId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    discardChecklist: build.mutation<
      DiscardChecklistMutation,
      DiscardChecklistMutationVariables
    >({
      query: (variables) => ({ document: DiscardChecklistDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDiscardChecklistMutation } = injectedRtkApi;
