import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientInsuranceStatusQueryVariables = Types.Exact<{
  request: Types.PatientInsuranceStatusActionContextInput;
}>;

export type GetPatientInsuranceStatusQuery = {
  __typename?: 'EntitiesQuery';
  getPatientInsuranceStatus?: boolean | null;
};

export const GetPatientInsuranceStatusDocument = `
    query getPatientInsuranceStatus($request: PatientInsuranceStatusActionContextInput!) {
  getPatientInsuranceStatus(request: $request)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientInsuranceStatus: build.query<
      GetPatientInsuranceStatusQuery,
      GetPatientInsuranceStatusQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientInsuranceStatusDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientInsuranceStatusQuery,
  useLazyGetPatientInsuranceStatusQuery,
} = injectedRtkApi;
