import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { CellComponentStyle } from 'components/gridFormatters';

const StyledDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const MainColumns = ['main', 'cell', 'home'];

export interface EditComponentValidationProps
  extends TableEditColumn.CellProps {
  isSystemUser: boolean;
  isReadOnly: boolean;
}

export const EditComponent = (props: EditComponentValidationProps) => {
  let removeDel = false,
    removeEdit = false;

  if (MainColumns.includes(props.row.type.toLowerCase())) {
    removeDel = true;
    removeEdit = props.isSystemUser || props.isReadOnly;
  }

  const subChildren = [] as ReactElement[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.Children.forEach(props.children, (child: any) => {
    let add = true;
    switch (child?.props?.id) {
      case 'delete':
        if (removeDel) {
          add = false;
        }
        break;
      case 'edit':
        if (removeEdit) {
          add = false;
        }
        break;
    }
    if (add) {
      subChildren.push(child);
    }
  });

  return (
    <TableEditColumn.Cell {...props} style={CellComponentStyle}>
      <StyledDiv>{subChildren}</StyledDiv>
    </TableEditColumn.Cell>
  );
};
