import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { IClaimAdditionalFilters } from '../types';
import DateComponent from 'components/DateComponent';
import { validateDate } from '../ClaimSearchDialog.helper';

interface IClaimFilterButtonProps {
  filters: IClaimAdditionalFilters;
  onChangeFilter: (values: IClaimAdditionalFilters) => void;
}

const StyledFilterButton = styled(Button)<{ $activeButton: boolean }>`
  height: 32px;
  ${({ $activeButton }) =>
    $activeButton &&
    `background-color: ${COLORS.PALE_SKY_BLUE};
    `}
`;

const ClaimFilterButton = (props: IClaimFilterButtonProps) => {
  const { filters, onChangeFilter } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const { handleSubmit, control, reset, getValues } =
    useForm<IClaimAdditionalFilters>({
      defaultValues: filters,
    });
  const formValues = {
    ...useWatch({ control }),
    ...getValues(),
  } as IClaimAdditionalFilters;
  const isValid =
    validateDate(formValues.claimDateStart) &&
    validateDate(formValues.claimDateEnd);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onSubmit = (values: IClaimAdditionalFilters) => {
    onChangeFilter(values);
  };

  const handleApply = () => {
    if (isValid) {
      handleSubmit(onSubmit)();
      setAnchorEl(null);
    }
  };

  const handleReset = () => {
    reset();
    setAnchorEl(null);
    onChangeFilter({} as IClaimAdditionalFilters);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <StyledFilterButton
        variant="outlined"
        size="small"
        onClick={handleFilterClick}
        startIcon={
          <Icon icon={ICONS.Settings} size="14" color={COLORS.SYMPHONY_BLUE} />
        }
        $activeButton={open}
      >
        <Typography variant="h5">Filters</Typography>
      </StyledFilterButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        title={'Filters'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '320px',
            padding: '28px',
          }}
        >
          <Typography variant="body2" pb="14px" fontWeight="700">
            Filters
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">Claim Date</Typography>
                <Controller
                  control={control}
                  name="claimDateStart"
                  render={({ field }) => (
                    <DateComponent placeholder="mm/dd/yyyy" {...field} />
                  )}
                />
                <Typography display="inline-flex" sx={{ padding: '6px 12px' }}>
                  to
                </Typography>
                <Controller
                  control={control}
                  name="claimDateEnd"
                  render={({ field }) => (
                    <DateComponent placeholder="mm/dd/yyyy" {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Diagnosis Code</Typography>
                <Controller
                  control={control}
                  name="diagnosisCode"
                  render={({ field }) => (
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Procedure Code</Typography>
                <Controller
                  control={control}
                  name="procedureCode"
                  render={({ field }) => (
                    <TextField
                      type="text"
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ marginRight: '14px' }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleApply}
                    disabled={!isValid}
                  >
                    Apply
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Popover>
    </Box>
  );
};

export default ClaimFilterButton;
