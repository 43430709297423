import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledVirtualTable = styled(VirtualTable.Table)`
  & tbody tr:nth-of-type(even) {
    background-color: ${COLORS.GREY4};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableComponent = (props: any) => (
  <StyledVirtualTable {...props} data-cy="grid-table" />
);
