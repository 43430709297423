import React from 'react';
import { ILabelCellProps } from 'components/gridFormatters';
import { CareTeamLabelCell } from './CareTeamLabelCell';

export const SpecialityCell = (props: ILabelCellProps) => {
  const { row } = props;
  return (
    <CareTeamLabelCell {...props} value={row?.provider?.speciality?.name} />
  );
};
