import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const SimpleTextFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <div
    style={{
      display: 'inline',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    <CustomTooltip title={props.value ?? ''}>
      <Typography noWrap style={{ cursor: 'pointer' }}>
        {props.value}
      </Typography>
    </CustomTooltip>
  </div>
);

export default SimpleTextFormatter;
