import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeClaimsQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeClaimsQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeClaims: Array<{
    __typename?: 'PatientClinicHospital';
    id: number;
    claimId?: string | null;
    claimDate?: any | null;
    serviceFrom?: any | null;
    serviceTo?: any | null;
    billingProviderName?: string | null;
    paidAmount?: any | null;
    performingProvider?: string | null;
    createdOn?: any | null;
    updatedOn?: any | null;
    diagnosis?: {
      __typename?: 'MedicationCode';
      code?: string | null;
      shortDescription?: string | null;
    } | null;
    medProcedure?: {
      __typename?: 'MedicationCode';
      code?: string | null;
      shortDescription?: string | null;
    } | null;
  }>;
};

export const GetEpisodeClaimsDocument = `
    query getEpisodeClaims($episodeId: Int!) {
  getEpisodeClaims(episodeId: $episodeId) {
    id
    claimId
    claimDate
    serviceFrom
    serviceTo
    billingProviderName
    paidAmount
    performingProvider
    diagnosis {
      code
      shortDescription
    }
    medProcedure {
      code
      shortDescription
    }
    createdOn
    updatedOn
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeClaims: build.query<
      GetEpisodeClaimsQuery,
      GetEpisodeClaimsQueryVariables
    >({
      query: (variables) => ({ document: GetEpisodeClaimsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeClaimsQuery, useLazyGetEpisodeClaimsQuery } =
  injectedRtkApi;
