import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import EpisodeNumberFormatter from '../formatters/EpisodeNumberFormatter';

const EpisodeNumberProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={EpisodeNumberFormatter} {...props} />
);

export default EpisodeNumberProvider;
