import { Dispatch } from 'redux';
import {
  fetchGridRowsFail,
  fetchGridRowsStart,
  fetchGridRowsSuccess,
} from './action';
import axiosGitHubGraphQL from 'util/axiosHttp';
import { IRowRequest } from './types';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const remapRow = (row: any) => {
  if (row.id) {
    row.id = parseInt(row.id);
  }
  if (row.episodeId) {
    row.episodeId = parseInt(row.episodeId);
  }
  if (row.patientId) {
    row.patientId = parseInt(row.patientId);
  }
};

export const fetchGridRows =
  (req: IRowRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchGridRowsStart());
    axiosGitHubGraphQL
      .post('home/entitieslist', req)
      .then((result) => {
        if (result.data.isError) {
          dispatch(fetchGridRowsFail(result.data.errorMessage));
          dispatch(showErrorPopup(result.data.errorMessage));
          return;
        }
        const data = result.data.data;
        if (data?.count) {
          data.count = parseInt(data.count);
        }
        data?.rows?.forEach(remapRow);
        dispatch(fetchGridRowsSuccess(data));
      })
      .catch((err) => {
        dispatch(fetchGridRowsFail(err));
        dispatch(
          showErrorPopup({
            message: 'Cannot Get Home Model: Internal Server Error',
          })
        );
      });
  };

export const saveFilterSelection =
  (request: IRowRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    axiosGitHubGraphQL.post('Audit/ExportAudit', request).catch(() => {
      dispatch(
        showErrorPopup({
          message: 'Cannot audit export filters: Internal Server Error',
        })
      );
    });
  };
