import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import PriorAuthPolicyFormatter from '../formatters/PriorAuthPolicyFormatter';

const PriorAuthPolicyProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PriorAuthPolicyFormatter} {...props} />
);

export default PriorAuthPolicyProvider;
