import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetProviderSearchModelQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
  authRole: Types.Scalars['String'];
}>;

export type GetProviderSearchModelQuery = {
  __typename?: 'EntitiesQuery';
  getProviderSearchDialogModel?: {
    __typename?: 'ProviderSearchDialogModel';
    favorites?: Array<string | null> | null;
    searchInformation?: string | null;
    recent?: Array<{
      __typename?: 'RecentProvider';
      id?: number | null;
      added?: any | null;
    } | null> | null;
    preSelectedProvider?: {
      __typename?: 'ProviderSearchResultRow';
      id?: string | null;
      providerAddressUiId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      addressId?: number | null;
      par?: Types.FoundProviderStatus | null;
      parNotes?: string | null;
      address?: string | null;
      practiceName?: string | null;
      seamless?: boolean | null;
      nPI?: string | null;
      providerId?: string | null;
      taxId?: string | null;
      providerType?: {
        __typename?: 'LookupValue';
        name?: string | null;
      } | null;
      type?: { __typename?: 'LookupValue'; name?: string | null } | null;
      dPG?: { __typename?: 'LookupValue'; name?: string | null } | null;
    } | null;
    rows?: Array<{
      __typename?: 'ProviderSearchResultRow';
      id?: string | null;
      providerAddressUiId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      addressId?: number | null;
      par?: Types.FoundProviderStatus | null;
      parNotes?: string | null;
      address?: string | null;
      practiceName?: string | null;
      seamless?: boolean | null;
      nPI?: string | null;
      providerId?: string | null;
      taxId?: string | null;
      providerType?: {
        __typename?: 'LookupValue';
        name?: string | null;
      } | null;
      type?: { __typename?: 'LookupValue'; name?: string | null } | null;
      dPG?: { __typename?: 'LookupValue'; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export const GetProviderSearchModelDocument = `
    query getProviderSearchModel($patientId: Int!, $authRole: String!) {
  getProviderSearchDialogModel(patientId: $patientId, authRole: $authRole) {
    favorites
    recent {
      id
      added
    }
    preSelectedProvider {
      id
      providerAddressUiId
      firstName
      lastName
      addressId
      par
      parNotes
      address
      practiceName
      seamless
      nPI
      providerId
      providerType {
        name
      }
      type {
        name
      }
      dPG {
        name
      }
      taxId
    }
    rows {
      id
      providerAddressUiId
      firstName
      lastName
      addressId
      par
      parNotes
      address
      practiceName
      seamless
      nPI
      providerId
      providerType {
        name
      }
      type {
        name
      }
      dPG {
        name
      }
      taxId
    }
    searchInformation
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProviderSearchModel: build.query<
      GetProviderSearchModelQuery,
      GetProviderSearchModelQueryVariables
    >({
      query: (variables) => ({
        document: GetProviderSearchModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetProviderSearchModelQuery,
  useLazyGetProviderSearchModelQuery,
} = injectedRtkApi;
