import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import PrimaryIconFormatter from '../formatters/PrimaryIconFormatter';

const PrimaryIconProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PrimaryIconFormatter} {...props} />
);

export default PrimaryIconProvider;
