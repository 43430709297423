import React, { useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import { Select } from 'components/select';
import { IComponentProps, IDropdownItem } from '../types';
import {
  DropdownComponent,
  DropdownType,
  EntityAttributeComponent,
} from 'graphql/graphqlTypes';
import {
  IValidationResult,
  validateRequired,
  validateValueNotInTheOptions,
} from 'util/validationUtils';
import { useSelector } from 'react-redux';
import { useChecklistValidation } from '../../Items/Orderable';
import LinkFieldsIcon from 'components/linkFieldsIcon/LinkFieldsIcon';
import { IState } from 'store';
import { calculateSelectWidth } from 'util/helpers/styleHelpers';
import styled from 'styled-components';
import { getDropdownOptions, getBigTableInitialValue } from './helpers';
import { DropdownBigTable } from './DropdownBigTable';
import { DropdownLookupEmpty } from './DropdownLookupEmpty';
import { DropdownAutocomplete } from './DropdownAutocomplete';

const StyledWrapper = styled.div`
  display: flex;
  align-self: center;
  height: inherit;
  width: 100%;
`;

export interface IDropdownProps extends IComponentProps<DropdownComponent> {
  handleSuggestItems: (
    entity: string,
    request: string,
    callback: (arg: ILookupValue[]) => void
  ) => void;
  autoSave: boolean;
  isReadOnly: boolean;
  parentWidth?: number | string;
  isUpdateActionValueRequestFailed: boolean;
  parentLookupId?: string;
}

const Dropdown = (props: IDropdownProps) => {
  const {
    component,
    storageType,
    parentWidth,
    isUpdateActionValueRequestFailed,
    parentLookupId,
  } = props;

  const entities = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.entities
  ) as IEntityLookup[];

  const bigTableValues = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.bigTableValues
  ) as IEntityLookup[];

  const mainModel = useSelector((state: IState) => state.home.mainModel);

  const lookups = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.lookups
  ) as ILookup[];

  const parentLookupSelectedValue = useSelector(
    (state: IState) =>
      (
        (state.checklist.documentsState[storageType].checklistComponents[
          component.uniqueID
        ]?.component ?? {}) as EntityAttributeComponent
      ).parentLookupSelectedValue ?? undefined
  );

  const calculateDisabled = (
    parentLookupId?: string,
    parentLookupSelectedValue?: string
  ): boolean => {
    return (
      (parentLookupId ?? '') != '' && (parentLookupSelectedValue ?? '') == ''
    );
  };

  const [isDisabled, setIsDisabled] = useState<boolean>(
    calculateDisabled(parentLookupId, parentLookupSelectedValue)
  );

  useEffect(() => {
    setIsDisabled(calculateDisabled(parentLookupId, parentLookupSelectedValue));
  }, [parentLookupSelectedValue, parentLookupId]);

  const lookupId = component.lookupId ? parseInt(component.lookupId) : 0;
  if (component.dropDownType === DropdownType.Lookup) {
    const lookup = lookups?.find((l: ILookup) => l.id === lookupId);
    if (!lookup || lookup.isDeleted) {
      return <DropdownLookupEmpty lookup={lookup} />;
    }
  }
  const options = getDropdownOptions(
    component,
    lookups,
    lookupId,
    entities,
    mainModel,
    parentLookupSelectedValue
  );
  const bigTableInitialValue = getBigTableInitialValue(
    component,
    bigTableValues
  );

  const optionNames = options?.map((item) => item.name) ?? [];
  if (bigTableInitialValue) {
    optionNames.push(bigTableInitialValue.name);
  }
  const dropdownWidth = calculateSelectWidth(
    optionNames,
    component.autocomplete
  );

  return (
    <StyledWrapper>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        style={{
          width: '100%',
          minWidth: parentWidth,
          maxWidth: dropdownWidth,
        }}
      >
        <DropdownInner
          {...props}
          dropdownWidth={dropdownWidth}
          options={options}
          bigTableInitialValue={bigTableInitialValue}
          isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
          isReadOnly={isDisabled}
        />
      </FormControl>
      {(component.mirrorGroup || component.oneWaySrc) && (
        <LinkFieldsIcon linkMessage={component.linkMessage as string} />
      )}
    </StyledWrapper>
  );
};

export interface IDropdownInnerProps extends IDropdownProps {
  dropdownWidth: string | undefined;
  options: IDropdownItem[];
  bigTableInitialValue?: ILookupValue;
  isUpdateActionValueRequestFailed: boolean;
}

const DropdownInner = (props: IDropdownInnerProps) => {
  const {
    component,
    handleChecklistInputChange,
    categoryId,
    isActionSelected,
    storageType,
    autoSave,
    isReadOnly,
    dropdownWidth,
    options,
    bigTableInitialValue,
    isUpdateActionValueRequestFailed,
  } = props;

  const value = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistComponents[
        component.uniqueID
      ]?.component?.value
  );

  useEffect(() => {
    if (value !== undefined) {
      handleValidation(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (
      value &&
      options != undefined &&
      !options.some((i) => i.value == value)
    ) {
      handleDropdownChangeInternal('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  const validateInputValue = (
    valueToValidate: string | null,
    options: IDropdownItem[] | null | undefined
  ): IValidationResult => {
    const result = validateValueNotInTheOptions(valueToValidate, options);
    if (result.hasError) {
      return result;
    }
    return validateRequired(valueToValidate, component.required);
  };

  const [validation, setValidation] = useState<IValidationResult>(
    validateInputValue(value || null, options)
  );

  useChecklistValidation(
    validation.hasError && isActionSelected,
    autoSave,
    isActionSelected,
    isReadOnly,
    component.uniqueID,
    categoryId,
    component.userDefinedId || component.componentType,
    validation.message ?? 'Fill in required field',
    storageType
  );

  useEffect(() => {
    if (isActionSelected) {
      handleValidation(value ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionSelected]);

  const handleValidation = (valueToValidate: string | null) => {
    const result = validateInputValue(valueToValidate, options);
    setValidation(result);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDropdownChangeInternal = (curValue: string) => {
    handleValidation(curValue);
    handleChecklistInputChange(curValue, component);
  };

  const handleAutoCompleteChange = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newValue: any
  ) => {
    const curValue = newValue.value;
    handleDropdownChangeInternal(curValue);
  };

  const handleBigTableChange = (selectedValue: ILookupValue | null) => {
    handleDropdownChangeInternal(selectedValue?.id?.toString() ?? '');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (event: React.ChangeEvent<{ value: any }>) => {
    const curValue = event?.target?.value;
    handleDropdownChangeInternal(curValue);
  };

  if (component.isBigTable) {
    return (
      <DropdownBigTable
        {...props}
        handleChange={handleBigTableChange}
        hasError={
          (validation.hasError && isActionSelected && !isReadOnly) ||
          isUpdateActionValueRequestFailed
        }
        initValue={bigTableInitialValue}
      />
    );
  }

  if (component.autocomplete) {
    return (
      <DropdownAutocomplete
        {...props}
        options={options}
        value={value}
        validation={validation}
        handleChange={handleAutoCompleteChange}
        isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
      />
    );
  }

  return (
    <Select
      required={component.required}
      error={
        (validation.hasError && isActionSelected && !isReadOnly) ||
        isUpdateActionValueRequestFailed
      }
      data-testid={`Select_${component.uniqueID}`}
      disabled={isReadOnly}
      {...(component.userDefinedId
        ? { 'data-userdefinedid': component.userDefinedId }
        : '')}
      value={value}
      onChange={handleSelectChange}
      items={options.map(({ value: id, name }) => ({
        id,
        name,
      }))}
      size="small"
      width={dropdownWidth}
      color={component.color ?? undefined}
    />
  );
};
export default Dropdown;
