import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useLazyGetMozartOutOfTurnStepsQuery } from 'graphql/hooks/getMozartOutOfTurnSteps';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { useInsertMozartWorkflowOotStepMutation } from 'graphql/hooks/insertMozartWorkflowOotStep';
import { useLocation } from 'react-router-dom';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { useDispatch } from 'react-redux';
import Loader from 'components/loader';
import {
  mozartStartOotPolling,
  resetMozartState,
  setMozartGlobalParams,
} from 'store/actions/mozartSlice';
import ToggleButton from 'components/mozartOutOfTurnStepsList/ToggleButton';
import { keepSelectedPatientDetailsTabInfo } from 'store/patientdetails/patientDetailsSlice';
import { closePatientDetailsDialog } from 'store/patientdetails/patientDetails.helpers';
import { resetChecklist } from 'store/actions/checklistSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { useNavigate } from 'react-router';
import { useContinueStepCheck } from 'components/scenario/episode/hooks';
import { clearPrintDocumentStates } from 'store/ui/print/print.helper';

interface IMozartOutOfTurnSteps {
  workflowInstanceId: string;
  nextStepName: string;
  displayType: 'button' | 'dropdown';
  mozartWorkflowCompleted?: boolean;
  isDisabled?: boolean;
}

const ProgressWrapper = styled.div`
  display: grid;
  place-items: center;
  color: #fff;
  width: 100%;
  height: 100%;
`;

const MozartOutOfTurnStepsList = (props: IMozartOutOfTurnSteps) => {
  const {
    workflowInstanceId,
    nextStepName,
    displayType,
    mozartWorkflowCompleted,
    isDisabled,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [insertMozartWorkflowOotStep] =
    useInsertMozartWorkflowOotStepMutation();
  const {
    checkContinueOnDifferentUser,
    confirmation: continueEpisodeConfirmation,
    checkContinueEpisodeIsFetching,
  } = useContinueStepCheck<string>((args) => {
    const [id, workflowInstanceId] = args ?? [];
    handleInsertOotStep(id, workflowInstanceId);
  });

  const [
    getMozartOutOfTurnSteps,
    { data: outOfTurnStepsData, isFetching: isOutOfTurnStepsFetching },
  ] = useLazyGetMozartOutOfTurnStepsQuery();

  const location = useLocation();
  const isWorkflowRoute = location.pathname.includes('Workflow');

  if (
    !workflowInstanceId ||
    workflowInstanceId === '00000000000000000000000000000000'
  ) {
    return null;
  }

  const hasAnyoutOfTurnSteps = outOfTurnStepsData?.getMozartOutOfTurnSteps
    ? outOfTurnStepsData?.getMozartOutOfTurnSteps?.filter(
        (x) => x.workflowStepName === nextStepName
      ).length > 0 && !mozartWorkflowCompleted
    : false;

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    open: boolean
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (!outOfTurnStepsData) {
      getMozartOutOfTurnSteps({
        id: workflowInstanceId,
      });
    }
    setAnchorElement(open ? event.currentTarget : null);
    setMenuOpen(open);
  };

  const handleMozartRedirect = () => {
    if (isWorkflowRoute) {
      dispatch(resetChecklist(ChecklistStorageType.MOZART));
      dispatch(resetMozartState());
      closePatientDetailsDialog(dispatch);
      dispatch(
        setMozartGlobalParams({
          mozartInstanceId: workflowInstanceId,
          restartMozart: true,
        })
      );
    } else {
      clearPrintDocumentStates(dispatch);
      dispatch(keepSelectedPatientDetailsTabInfo());
    }
  };

  const handleMozartActionClick = (id: string, workflowStepId: string) => {
    checkContinueOnDifferentUser(workflowInstanceId, id, workflowStepId);
    clearPrintDocumentStates(dispatch);
  };
  const handleInsertOotStep = (id: string, workflowStepId: string) => {
    setMenuOpen(false);
    setIsLoading(true);
    insertMozartWorkflowOotStep({
      id,
      workflowInstanceOotStepId: workflowStepId,
    }).then(
      (result) => {
        setIsLoading(false);
        if ('error' in result) {
          dispatch(
            showErrorPopup({
              message: result.error?.message ?? 'Error executing other action.',
            })
          );
        } else if ('data' in result) {
          if (result.data?.insertMozartWorkflowOOTStep?.result) {
            handleMozartRedirect();
            navigate(`/Workflow/${id}`);
            dispatch(mozartStartOotPolling());
          } else {
            dispatch(
              showErrorPopup({
                message:
                  result.data?.insertMozartWorkflowOOTStep?.message ??
                  'Error executing other action.',
              })
            );
          }
        }
      },
      (error) => {
        setIsLoading(false);
        const errorMessage = typeof error !== 'string' ? error.message : error;
        showErrorPopup({ message: errorMessage });
      }
    );
  };

  const handleView = () => {
    handleMozartRedirect();
    navigate(`/Workflow/${workflowInstanceId}?mozartViewOnly=true`);
  };

  return (
    <div data-testid="mozart-actions">
      <ToggleButton
        displayType={displayType}
        handleToggle={handleToggle}
        menuOpen={menuOpen}
      />
      <Menu
        anchorEl={anchorElement}
        open={menuOpen}
        onClose={(e) => handleToggle(e as React.MouseEvent<HTMLElement>, false)}
        autoFocus={false}
        keepMounted={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            left: '0',
            marginTop: '5px',
            borderRadius: '4px',
            border: '1px solid #CECECE',
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
            minWidth: '158px',
          },
        }}
      >
        <MenuItem key="view" onClick={handleView} disabled={isDisabled}>
          View
        </MenuItem>
        {isOutOfTurnStepsFetching && (
          <MenuItem key="loading">
            <ProgressWrapper>
              <CircularProgress />
            </ProgressWrapper>
          </MenuItem>
        )}
        {outOfTurnStepsData?.getMozartOutOfTurnSteps?.map((item) => (
          <MenuItem
            key={item.workflowInstanceAvailableOOTStepsId}
            value={item.workflowStepId}
            disabled={hasAnyoutOfTurnSteps || isDisabled}
            onClick={() =>
              handleMozartActionClick(
                item.workflowInstance,
                item.workflowStepId
              )
            }
          >
            {item.workflowStepName}
          </MenuItem>
        ))}
      </Menu>
      <Loader active={isLoading || checkContinueEpisodeIsFetching} />
      {continueEpisodeConfirmation}
    </div>
  );
};

export default MozartOutOfTurnStepsList;
