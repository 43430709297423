/* eslint-disable @typescript-eslint/no-explicit-any */
export const rearrangeColumnsEditPrelast = ({ tableColumns }: any) => {
  const lastIndex = tableColumns.length - 1;
  return [
    ...tableColumns.slice(1, lastIndex),
    tableColumns[0],
    ...tableColumns.slice(-1),
  ];
};

export const rearrangeColumnsEditLast = ({ tableColumns }: any) => {
  const [editColumn, ...columns] = tableColumns;
  return [...columns, editColumn];
};

export const rearrangeSecondColumnsEditLast = ({ tableColumns }: any) => {
  const [expandColumn, editColumn, ...columns] = tableColumns;
  return [expandColumn, ...columns, editColumn];
};

export const getAvailableId = <T>(
  entities: T[] | null | undefined,
  idSelector: (enitity: T) => number
): number => {
  return !entities
    ? -1
    : Math.max(...entities.map(idSelector).map((x) => Math.abs(x)), 0) * -1 - 1;
};
