import React, { useEffect, useState } from 'react';
import { EntityAttributeComponent } from 'graphql/graphqlTypes';
import { Radio, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { IComponentProps } from '../types';
import { COLORS } from 'consts/styles';
import { hasValue, IValidationResult } from 'util/validationUtils';
import { IRadioSelection, useChecklistValidation } from '../../Items/Orderable';
import { IState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { usePrevious } from 'hooks';
import { ComponentStyledLabel } from '../ComponentStyledLabel';
import { ConditionIcon } from '../RadioCheckBoxButton/ConditionIcon';

interface IStyledComponentWrapperProps {
  componentWidth?: number | null;
  componentColor?: string | null;
}

interface IStyledRadioProps {
  $showValidationError: boolean;
}

const StyledInlineBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledComponentWrapper = styled.div<IStyledComponentWrapperProps>`
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  height: inherit;
  width: ${(props) =>
    props.componentWidth ? `${props.componentWidth}px` : '150px'};
  color: ${(props) => props.componentColor ?? 'inherit'};
`;

const StyledRadio = styled(Radio)<IStyledRadioProps>(
  ({ $showValidationError }) => ({
    color: $showValidationError ? COLORS.RED100 : COLORS.GREY30,
  })
);

const StyleConditionIcon = styled(ConditionIcon)({
  marginRight: '6px',
  marginLeft: 0,
});

const StyledFormControlLabel = styled(FormControlLabel)({
  alignSelf: 'flex-start',
  marginRight: 0,
});

export interface IBoolAttributeProps
  extends IComponentProps<EntityAttributeComponent> {
  sectionItemId: string;
  autoSave: boolean;
  isReadOnly: boolean;
  isUpdateActionValueRequestFailed: boolean;
  hasCondition: (value: string, conditionalValue: string) => boolean;
}

const validateRadio = (
  value: string | null,
  isActionSelected: boolean,
  component: EntityAttributeComponent
): IValidationResult => {
  // validate required
  if (
    isActionSelected &&
    component.required &&
    value?.toLowerCase() !== 'true' &&
    value?.toLowerCase() !== 'false'
  ) {
    return { hasError: true, message: 'Fill in required field' };
  }
  return { hasError: false };
};

const renderReadOnlyBoolAttribute = (
  selectedValue: IRadioSelection,
  component: EntityAttributeComponent
) => {
  // SYM-16500 Legacy UI uses string.IsNullOrEmpty because
  // it might return empty string if no value is provided
  if (!selectedValue?.radioValue) {
    return null;
  } else {
    return (
      <Typography
        variant="body2"
        style={{ color: component.color ? component.color : COLORS.BLACK }}
      >
        {selectedValue?.radioValue?.toLowerCase() === 'true' ? 'Yes' : 'No'}
      </Typography>
    );
  }
};

const BoolAttribute = (props: IBoolAttributeProps) => {
  const {
    component,
    isActionSelected,
    handleChecklistInputChange,
    sectionItemId,
    categoryId,
    storageType,
    autoSave,
    isReadOnly,
    isUpdateActionValueRequestFailed,
    hasCondition,
  } = props;

  const [error, setError] = useState<boolean>(
    validateRadio(component.value || null, isActionSelected, component)
      .hasError && isActionSelected
  );

  const showValidationError = error || isUpdateActionValueRequestFailed;
  const handleValidation = (value: string | null) => {
    const result = validateRadio(value, isActionSelected, component);
    setError(result.hasError);
  };

  const name = `bool_${component.id}_${sectionItemId}`;
  const reduxValue = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistComponents[
        component.uniqueID
      ]?.component?.value
  );

  const getInitialValue = () => {
    if (reduxValue) {
      return {
        radioId: component.id,
        radioValue: reduxValue,
        radioName: name,
      } as IRadioSelection;
    }
    if (component.value) {
      return {
        radioId: component.id,
        radioValue: component.value,
        radioName: name,
      } as IRadioSelection;
    }
    return {} as IRadioSelection;
  };

  const [selectedValue, setSelectedValue] = React.useState<IRadioSelection>(
    getInitialValue()
  );

  const previousReduxValue = usePrevious(reduxValue);
  useEffect(() => {
    if (selectedValue?.radioValue === reduxValue) {
      return;
    }
    if (previousReduxValue === reduxValue) {
      return;
    }
    if (!selectedValue?.radioValue && !reduxValue) {
      return;
    }
    if (hasValue(reduxValue)) {
      handleValidation(reduxValue);
      setSelectedValue({
        ...selectedValue,
        radioValue: reduxValue,
      } as IRadioSelection);

      handleChecklistInputChange(
        reduxValue,
        {
          ...props.component,
          groupId: null,
          type: 'string',
        },
        component.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxValue, selectedValue]);

  useChecklistValidation(
    error,
    autoSave,
    isActionSelected,
    isReadOnly,
    name,
    categoryId,
    component.userDefinedId || component.componentType,
    'Fill in required field',
    storageType
  );

  useEffect(() => {
    if (isActionSelected) {
      handleValidation(selectedValue?.radioValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionSelected]);

  if (component.readonly) {
    return renderReadOnlyBoolAttribute(selectedValue, component);
  } else {
    const handleRadioInputChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = event.target.value;
      setSelectedValue({
        radioId: component.id,
        radioValue: value,
        radioName: name,
      });
      if (isActionSelected) {
        handleValidation(value);
      }

      handleChecklistInputChange(
        value,
        {
          ...props.component,
          groupId: null,
          type: 'string',
        },
        component.id
      );
    };

    const isChecked = (selectedValue: IRadioSelection, value: string) =>
      selectedValue?.radioValue?.toLowerCase() === value;
    return (
      <StyledComponentWrapper
        componentWidth={component.width}
        componentColor={component.color ?? ''}
      >
        <StyledInlineBox id={`uuid-${name}`}>
          <StyledFormControlLabel
            value={true}
            style={{
              marginLeft: 0,
              marginRight: '6px',
            }}
            control={
              <StyledRadio
                color="primary"
                disabled={isReadOnly}
                data-testid={name + '_yes'}
                $showValidationError={showValidationError}
                checked={isChecked(selectedValue, 'true')}
                name={name}
                value={true}
                onChange={(e) => handleRadioInputChange(e)}
              />
            }
            label={
              <ComponentStyledLabel
                $error={isUpdateActionValueRequestFailed}
                $color={component.color ?? undefined}
              >
                Yes
              </ComponentStyledLabel>
            }
            labelPlacement="end"
          />
          {hasCondition(component.id, 'true') ? <StyleConditionIcon /> : null}
          <StyledFormControlLabel
            value={false}
            control={
              <StyledRadio
                color="primary"
                $showValidationError={showValidationError}
                disabled={isReadOnly}
                data-testid={name + '_no'}
                checked={isChecked(selectedValue, 'false')}
                name={name}
                value={false}
                onChange={handleRadioInputChange}
              />
            }
            label={
              <ComponentStyledLabel
                $error={isUpdateActionValueRequestFailed}
                $color={component.color ?? undefined}
              >
                No
              </ComponentStyledLabel>
            }
            labelPlacement="end"
          />
          {hasCondition(component.id, 'false') ? <StyleConditionIcon /> : null}
        </StyledInlineBox>
      </StyledComponentWrapper>
    );
  }
};

export default BoolAttribute;
