import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { IState } from 'store';
import PageWrapper from '../templates';
import SessionExpirationTimer from 'components/user/sessionExpiration/SessionExpirationTimer';
import { ROUTE } from 'consts/ROUTE';
import ReactGA from 'react-ga4';
import { RoleType } from 'store/roles/types';

interface IPrivateRouteProps {
  children: ReactNode;
  path: string;
}

const excludedUserLogins = [
  'test_cypress_first',
  'Automation_Cypress_1',
  'veracode_composer',
  'veracode',
  'Automation_Cypress_2',
  'TestUserApollo2',
  'ThirdPartyPenTester',
  'cyRobot',
  'vlad-monitor',
];

const PrivateRoute = ({ children, path }: IPrivateRouteProps) => {
  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);
  const authFinished = useSelector((state: IState) => state.user.authFinished);
  const trackingCode = useSelector(
    (state: IState) => state.environment.trackingCode
  );
  const currentUser = useSelector((state: IState) => state.user.currentUser);

  const isExcludedFromAnalytics =
    currentUser?.login && excludedUserLogins.includes(currentUser?.login);

  const location = useLocation();
  const params = useParams<Record<string, string>>();

  const componentWithRouteBasedExpiration = () => {
    return path === ROUTE.FaxPreview || path === ROUTE.InterQual ? (
      children
    ) : (
      <PageWrapper>
        {children}
        <SessionExpirationTimer />
      </PageWrapper>
    );
  };

  const basePath = Object.values(params).reduce(
    (path, param) => path?.replace('/' + param, ''),
    location.pathname
  );

  useEffect(() => {
    if (authFinished && trackingCode && !isExcludedFromAnalytics) {
      ReactGA.initialize(trackingCode);
    }
  }, [authFinished, isExcludedFromAnalytics, trackingCode]);

  useEffect(() => {
    if (authFinished && location && trackingCode && !isExcludedFromAnalytics) {
      ReactGA.send({
        hitType: 'pageview',
        page: basePath,
        title: basePath,
      });
    }
  }, [authFinished, basePath, location, trackingCode, isExcludedFromAnalytics]);

  if (authFinished && !isLoggedIn) {
    return (
      <>
        <Navigate to="/" />
      </>
    );
  }
  if (
    isLoggedIn &&
    currentUser?.type == RoleType.ADMIN &&
    currentUser.redirectUrl
  ) {
    window.location.href = currentUser.redirectUrl;
    return <></>;
  }

  return (
    <>{authFinished ? <>{componentWithRouteBasedExpiration()}</> : null}</>
  );
};

export default PrivateRoute;
