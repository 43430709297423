import React, { CSSProperties } from 'react';
import { Menu as MuiMenu } from '@mui/material';

export interface IMenuProps {
  open: boolean;
  onClose: () => void;
  anchor?: Element | null;
  style?: CSSProperties;
  children?: JSX.Element[] | JSX.Element;
  header?: JSX.Element;
  footer?: JSX.Element;
}

const Menu = ({
  anchor,
  open,
  onClose,
  style,
  children,
  footer,
  header,
}: IMenuProps) => {
  return (
    <MuiMenu
      PaperProps={{
        style: {
          marginTop: '4px',
          border: '1px solid #CECECE',
          boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
          ...style,
        },
      }}
      anchorEl={anchor}
      onClose={onClose}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      {header}
      {children &&
        Array.isArray(children) &&
        React.Children.map(children, (child: JSX.Element, index) => (
          <child.type key={index} {...child.props} />
        ))}
      {children && !Array.isArray(children) ? children : null}
      {footer}
    </MuiMenu>
  );
};

export default Menu;
