import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import ParStateFormatter from './ParFormatter';

const ParDataTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={ParStateFormatter} {...props} />
);

export default ParDataTypeProvider;
