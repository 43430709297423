import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';
import styled from 'styled-components';
import PriorAuthMessage from 'components/actions/sections/SectionBody/Items/PriorAuthCodes/priorAuthMessage';

const PAEnabledText = styled(Box)({
  whiteSpace: 'normal',
  display: 'flex',
});

const PriorAuthMessageFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => (
  <PAEnabledText>
    <PriorAuthMessage props={props} />
  </PAEnabledText>
);

export default PriorAuthMessageFormatter;
