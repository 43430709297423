import React from 'react';
import { IActionLinkProps } from './types';
import ChecklistViewLink from './ChecklistViewLink';
import ChecklistPrintLink from './ChecklistPrintLink';
import ChecklistFaxLink from './ChecklistFaxLink';
import ChecklistAmendLink from './ChecklistAmendLink';
import ChecklistUpdateLink from './ChecklistUpdateLink';
import QuickNoteUpdateLink from './QuickNoteUpdateLink';
import ChecklistContinueLink from './ChecklistContinueLink';
import ChecklistDeleteLink from './ChecklistDeleteLink';
import ReminderCompleteLink from './ReminderCompleteLink';
import ReminderDeleteLink from './ReminderDeleteLink';
import ReminderRestoreLink from './ReminderRestoreLink';
import AttachmentFaxLink from './AttachmentFaxLink';
import AttachmentViewLink from './AttachmentViewLink';
import AttachmentPrintLink from './AttachmentPrintLink';
import AttachmentDeleteLink from './AttachmentDeleteLink';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import AttachmentMoveLink from 'components/actionlink/AttachmentMoveLink';

const ActionLink = (props: IActionLinkProps) => {
  const isFaxAllowed = hasUserPrivilege(Privilege.AllowFax);

  switch (props.type) {
    case 'ChecklistView': {
      return <ChecklistViewLink checklistId={props.entityId} />;
    }
    case 'ChecklistPrint': {
      return (
        <ChecklistPrintLink checklistId={props.entityId} label={props.label} />
      );
    }
    case 'ChecklistFax': {
      return (
        <ChecklistFaxLink
          checklistId={props.entityId}
          episodeId={props.episodeId}
          patientId={props.patientId}
        />
      );
    }
    case 'ChecklistAmend': {
      return (
        <ChecklistAmendLink
          checklistId={props.entityId}
          label={props.label}
          callback={props.callback}
        />
      );
    }
    case 'ChecklistUpdate': {
      return (
        <ChecklistUpdateLink
          checklistId={props.entityId}
          label={props.label}
          callback={props.callback}
        />
      );
    }
    case 'QuickNoteUpdate': {
      return (
        <QuickNoteUpdateLink
          quickNoteId={props.entityId}
          episodeId={props.episodeId}
          patientId={props.patientId}
        />
      );
    }
    case 'ChecklistContinue': {
      return (
        <ChecklistContinueLink
          checklistId={props.entityId}
          callback={props.callback}
        />
      );
    }
    case 'ChecklistDelete': {
      return (
        <ChecklistDeleteLink
          checklistId={props.entityId}
          label={props.label}
          callback={props.callback}
        />
      );
    }
    case 'ReminderComplete': {
      return (
        <ReminderCompleteLink
          reminderId={props.entityId}
          label={props.label}
          callback={props.callback}
        />
      );
    }
    case 'ReminderDelete': {
      return (
        <ReminderDeleteLink
          reminderId={props.entityId}
          label={props.label}
          callback={props.callback}
        />
      );
    }
    case 'ReminderRestore': {
      return (
        <ReminderRestoreLink
          reminderId={props.entityId}
          label={props.label}
          callback={props.callback}
        />
      );
    }
    case 'AttachmentFax': {
      return isFaxAllowed ? (
        <AttachmentFaxLink
          attachmentId={props.entityId}
          episodeId={props.episodeId}
          patientId={props.patientId}
          isDisabled={Boolean(props.isActionsDisabled)}
        />
      ) : null;
    }
    case 'AttachmentView': {
      return (
        <AttachmentViewLink
          attachmentId={props.entityId}
          fileName={props.fileName}
          label={props.label}
          iconProps={props.iconProps}
          asIcon={props.asIcon}
          isDisabled={Boolean(props.isActionsDisabled)}
        />
      );
    }
    case 'AttachmentPrint': {
      return (
        <AttachmentPrintLink
          attachmentId={props.entityId}
          fileName={props.fileName}
          label={props.label}
          isDisabled={Boolean(props.isActionsDisabled)}
        />
      );
    }
    case 'AttachmentDelete': {
      return (
        <AttachmentDeleteLink
          attachmentId={props.entityId}
          patientId={props.patientId!}
          episodeId={props.episodeId}
          isDisabled={Boolean(props.isActionsDisabled)}
        />
      );
    }
    case 'AttachmentMove': {
      return (
        <AttachmentMoveLink
          attachmentId={props.entityId}
          isDisabled={Boolean(props.isActionsDisabled)}
        />
      );
    }
    default:
      return null;
  }
};

export default ActionLink;
export {
  ChecklistViewLink,
  ChecklistPrintLink,
  ChecklistFaxLink,
  ChecklistAmendLink,
  ChecklistUpdateLink,
  QuickNoteUpdateLink,
  ChecklistContinueLink,
  ChecklistDeleteLink,
  ReminderCompleteLink,
  ReminderDeleteLink,
  ReminderRestoreLink,
  AttachmentFaxLink,
  AttachmentViewLink,
  AttachmentPrintLink,
  AttachmentDeleteLink,
};
