import { createAsyncThunk } from '@reduxjs/toolkit';
import { OpenQuickNoteArgs, quickNoteSlice } from './quickNote';
import { ThunkExtraArguments } from 'store/ThunkExtraArguments';

// based on https://wanago.io/2021/04/26/generic-confirmation-modal-redux-redux-toolkit-typescript/
const quickNoteDialogActions = {
  openQuickNoteAsync: createAsyncThunk<
    boolean,
    OpenQuickNoteArgs,
    { extra: ThunkExtraArguments }
  >('quickNoteDialog', async (args, { extra, dispatch }) => {
    const store = extra.store;
    dispatch(quickNoteSlice.actions.openQuickNote(args));

    return new Promise<boolean>((resolve) => {
      const unsubscribe = store.subscribe(() => {
        const state = store.getState();
        if (!state.ui.modals.quickNote.open) {
          unsubscribe();
          resolve(state.ui.modals.quickNote.shouldRefetchNotes);
        }
      });
    });
  }),
};

export const { openQuickNoteAsync } = quickNoteDialogActions;
