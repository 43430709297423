import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IState } from 'store';
import { usePrevious } from 'hooks';
import { isEqual } from 'lodash';
import moment from 'moment';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ChecklistStorageType } from 'store/actions/types';
import {
  setChecklistComponentValidation,
  updateCarePlanActionValue,
} from 'store/actions/checklistSlice';
import {
  CarePlanActionValue,
  CarePlanState,
  ChecklistItemCarePlans,
} from 'graphql/graphqlTypes';
import { useUpdateChecklistProblemMutation } from 'graphql/hooks/updateChecklistProblem';
import { ILookup } from 'backend/types/lookup';
import { LookupKnownName } from 'backend/types/lookupValue';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import { validateRequired } from 'util/validationUtils';
import { formatValidDate } from '../HealthService/HealthService.helpers';
import DatePicker from '../HealthService/Components/DatePicker';
import SelectTextField from '../HealthService/Components/SelectTextField';
import { formatDate as format } from '../HealthService/Components/ServiceRow.helpers';
import { validateDate } from '../../Components/Reminder/index.helpers';

const StyledBox = styled(Box)({
  display: 'flex',
  padding: '10px 20px',
  alignItems: 'center',
});

const StyledTypography = styled(Typography)({
  width: '130px',
});

const StyledGroupBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export interface ICarePlanProblemProps {
  item: ChecklistItemCarePlans;
  storageType: ChecklistStorageType;
  isReadOnly: boolean;
  categoryId?: string;
}

const CarePlan = (props: ICarePlanProblemProps) => {
  const { item, categoryId, storageType, isReadOnly } = props;
  const { showDescription, showGoal, goal, showPriority, showProgress } =
    item.options;
  const dispatch = useDispatch();
  const checklistId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklist?.id
  );
  const [carePlanValue, setCarePlanValue] = useState<CarePlanActionValue>(
    item.cpValue
  );
  const lookups = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.lookups ?? []
  ) as ILookup[];
  const priorityLookup = lookups.find(
    (x) => x.name == LookupKnownName.ProblemPriority
  )?.values;
  const progressLookup = lookups.find(
    (x) => x.name == LookupKnownName.ProblemProgress
  )?.values;
  const outcomeLookup = lookups.find(
    (x) => x.name == LookupKnownName.GoalOutcome
  )?.values;
  const [updateChecklistProblem] = useUpdateChecklistProblemMutation();
  const isCarePlanClosed = carePlanValue.carePlanState == CarePlanState.Closed;
  const previousCarePlanValue = usePrevious(carePlanValue);

  useEffect(() => {
    if (
      isReadOnly ||
      !carePlanValue ||
      !previousCarePlanValue ||
      isEqual(carePlanValue, previousCarePlanValue)
    ) {
      return;
    }
    handleValidation();

    updateChecklistProblem({
      problem: {
        checklistId: Number(checklistId),
        problemId: carePlanValue.problem.id,
        carePlanStartDate: carePlanValue.carePlanStartDate,
        carePlanState: carePlanValue.carePlanState,
        outcomeId: carePlanValue.outcome?.id,
        priorityId: carePlanValue.priority?.id,
        progressId: carePlanValue.progress?.id,
        isCarePlanTab: false,
      },
    });
    dispatch(
      updateCarePlanActionValue({
        storageType,
        itemUid: item.uid,
        cpValue: carePlanValue,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carePlanValue, previousCarePlanValue, isReadOnly]);

  const handleValidation = () => {
    validateStartDate();
    validateOutcome();
  };

  const validateStartDate = () => {
    const startDate = moment(carePlanValue.carePlanStartDate).toDate();
    const result = validateDate(startDate, true);
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: `${item.uid}-startDate`,
          error: result.message ?? '',
          fieldName: 'Problem List: Start Date',
          categoryId: categoryId ?? '',
          isValid: !result.message,
        },
      })
    );
  };

  const validateOutcome = () => {
    if (isCarePlanClosed) {
      const result = validateRequired(carePlanValue.outcome?.id, true);
      dispatch(
        setChecklistComponentValidation({
          storageType,
          error: {
            uuid: `${item.uid}-outcome`,
            error: result.message ?? '',
            fieldName: 'Problem List: Outcome',
            categoryId: categoryId ?? '',
            isValid: !result.message,
          },
        })
      );
    }
  };

  const handleStartDateChange = (date: Date | null | unknown) => {
    setCarePlanValue({
      ...carePlanValue,
      carePlanStartDate: formatValidDate(date as Date),
    });
  };

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const carePlanState = event.target.value as CarePlanState;
    setCarePlanValue({
      ...carePlanValue,
      carePlanState: carePlanState,
      carePlanEndDate:
        carePlanState === CarePlanState.Closed
          ? formatValidDate(new Date())
          : null,
      outcome:
        carePlanState === CarePlanState.Opened ? null : carePlanValue.outcome,
    });
  };

  const handlePriorityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const priority = priorityLookup?.find(
      (x) => x.id === Number(event.target.value)
    );
    setCarePlanValue({
      ...carePlanValue,
      priority,
    });
  };

  const handleProgressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const progress = progressLookup?.find(
      (x) => x.id === Number(event.target.value)
    );
    setCarePlanValue({
      ...carePlanValue,
      progress,
    });
  };

  const handleOutcomeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const outcome = outcomeLookup?.find(
      (x) => x.id === Number(event.target.value)
    );
    setCarePlanValue({
      ...carePlanValue,
      outcome: outcome,
    });
  };

  return (
    <Box id={`uuid-${item.uid}`}>
      {showDescription && (
        <StyledBox>
          <StyledTypography variant="body1">Description:</StyledTypography>
          <Typography variant="body1">
            {carePlanValue.problem?.description}
          </Typography>
        </StyledBox>
      )}
      {showGoal && (
        <StyledBox>
          <StyledTypography variant="body1">Goal:</StyledTypography>
          <Typography variant="body1">{goal}</Typography>
        </StyledBox>
      )}
      <StyledBox>
        <StyledTypography variant="body1">Status:</StyledTypography>
        <RadioGroup
          onChange={handleStatusChange}
          value={carePlanValue.carePlanState}
        >
          <Typography variant="body1">
            <FormControlLabel
              control={<Radio color="primary" disabled={isReadOnly} />}
              label="Open"
              value={CarePlanState.Opened}
            />
            <FormControlLabel
              control={<Radio color="primary" disabled={isReadOnly} />}
              label="Closed"
              value={CarePlanState.Closed}
            />
          </Typography>
        </RadioGroup>
      </StyledBox>
      <StyledBox>
        <StyledGroupBox id={`uuid-${item.uid}-startDate`}>
          <StyledTypography variant="body1">Start Date:</StyledTypography>
          <DatePicker
            value={format(carePlanValue.carePlanStartDate)}
            onChange={handleStartDateChange}
            autoFocus={false}
            readOnly={isReadOnly}
          />
        </StyledGroupBox>
        {isCarePlanClosed && (
          <StyledGroupBox pl="50px">
            <StyledTypography variant="body1">End Date:</StyledTypography>
            <StyledTypography variant="body1">
              {formatDate(carePlanValue.carePlanEndDate)}
            </StyledTypography>
          </StyledGroupBox>
        )}
      </StyledBox>
      {showPriority && (
        <StyledBox>
          <StyledTypography variant="body1">Priority:</StyledTypography>
          {isReadOnly ? (
            <StyledTypography variant="body1">
              {carePlanValue.priority?.name}
            </StyledTypography>
          ) : (
            <SelectTextField
              onChange={handlePriorityChange}
              value={carePlanValue.priority?.id}
              menuItems={priorityLookup}
              width="100px"
            />
          )}
        </StyledBox>
      )}
      {showProgress && (
        <StyledBox>
          <StyledTypography variant="body1">Progress:</StyledTypography>
          {isReadOnly ? (
            <StyledTypography variant="body1">
              {carePlanValue.progress?.name}
            </StyledTypography>
          ) : (
            <SelectTextField
              onChange={handleProgressChange}
              value={carePlanValue.progress?.id}
              menuItems={progressLookup}
              width="100px"
            />
          )}
        </StyledBox>
      )}
      {isCarePlanClosed && (
        <StyledBox id={`uuid-${item.uid}-outcome`}>
          <StyledTypography variant="body1">Outcome:</StyledTypography>
          {isReadOnly ? (
            <StyledTypography variant="body1">
              {carePlanValue.outcome?.name}
            </StyledTypography>
          ) : (
            <SelectTextField
              onChange={handleOutcomeChange}
              value={carePlanValue.outcome?.id}
              menuItems={outcomeLookup}
              hideEmptySelect={true}
              width="230px"
              error={!carePlanValue.outcome?.id}
            />
          )}
        </StyledBox>
      )}
    </Box>
  );
};
export default CarePlan;
