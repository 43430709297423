import React from 'react';
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { MenuItem, Select } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
  .MuiSelect-outlined {
    padding: 4px 8px;
    padding-right: 24px !important;
    font-size: 12px;
  }
  .MuiMenu-paper {
    border-radius: 4px;
    border: 1px solid #cecece;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  }
  .MuiSelect-iconOutlined {
    right: 2px;
    color: ${COLORS.GREY100};
  }
`;

const StyledPagerBox = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 30px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const StyledPaginationText = styled(Typography)`
  padding-left: 4px;
  padding-right: 4px;
  color: ${COLORS.GREY100};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-content: center;
  padding-top: 14px;
`;

const PagingContainer = (props: PagingPanel.ContainerProps) => {
  const {
    currentPage,
    totalPages,
    onCurrentPageChange,
    totalCount,
    pageSize,
    pageSizes,
    onPageSizeChange,
  } = props;

  const getDisplayedRecords = () => {
    if (totalCount < pageSize) {
      return totalCount;
    }
    if (currentPage + 1 === 0) {
      return pageSize;
    }
    if ((currentPage + 1) * pageSize < totalCount) {
      return (currentPage + 1) * pageSize;
    }
    return totalCount;
  };

  const pagingMessage = `Showing ${
    totalCount === 0 ? 0 : currentPage * pageSize + 1
  } -
  ${getDisplayedRecords()}
  of ${totalCount}`;

  return (
    <StyledWrapper>
      {pageSizes && pageSizes.length > 0 ? (
        <StyledPagerBox>
          <StyledPaginationText
            variant="caption"
            style={{ paddingRight: '10px' }}
          >
            Rows per page
          </StyledPaginationText>
          <StyledSelect
            value={pageSize}
            variant="outlined"
            style={{
              padding: '0px 10px 0px 6px',
              color: COLORS.GREY100,
              fontSize: '12px',
              fontWeight: 400,
              paddingBottom: 0,
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              style: {
                left: '-5px',
                marginTop: '5px',
                maxHeight: 350,
                width: 250,
              },
            }}
            onChange={(event) => {
              onPageSizeChange(Number(event.target.value));
            }}
          >
            {pageSizes.map((x) => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledPagerBox>
      ) : null}
      <StyledPagerBox>
        <StyledIconButton
          onClick={() => onCurrentPageChange(0)}
          aria-label="first"
          disabled={currentPage < 1}
          size="small"
          data-testid="first-button"
        >
          <Icon
            icon={ICONS.First}
            size={25}
            color={currentPage < 1 ? COLORS.LIGHT_GREY : COLORS.GREY100}
            hoverColor={COLORS.GREY100}
          />
        </StyledIconButton>
        <StyledIconButton
          onClick={() => onCurrentPageChange(currentPage - 1)}
          aria-label="previous"
          disabled={currentPage < 1}
          size="small"
          data-testid="previous-button"
        >
          <Icon
            icon={ICONS.Left}
            size={25}
            color={currentPage < 1 ? COLORS.LIGHT_GREY : COLORS.GREY100}
            hoverColor={COLORS.GREY100}
          />
        </StyledIconButton>
        <StyledPaginationText variant="caption">
          {pagingMessage}
        </StyledPaginationText>
        <StyledIconButton
          onClick={() => onCurrentPageChange(currentPage + 1)}
          aria-label="next"
          disabled={currentPage === totalPages - 1}
          size="small"
          data-testid="next-button"
        >
          <Icon
            icon={ICONS.Right}
            size={25}
            color={
              currentPage === totalPages - 1
                ? COLORS.LIGHT_GREY
                : COLORS.GREY100
            }
            hoverColor={COLORS.GREY100}
          />
        </StyledIconButton>
        {totalCount < 10000 && (
          <StyledIconButton
            onClick={() => onCurrentPageChange(totalPages - 1)}
            aria-label="last"
            disabled={currentPage === totalPages - 1}
            size="small"
            data-testid="last-button"
          >
            <Icon
              icon={ICONS.Last}
              size={25}
              color={
                currentPage === totalPages - 1
                  ? COLORS.LIGHT_GREY
                  : COLORS.GREY100
              }
              hoverColor={COLORS.GREY100}
            />
          </StyledIconButton>
        )}
      </StyledPagerBox>
    </StyledWrapper>
  );
};

export default PagingContainer;
