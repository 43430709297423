import { Box, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { ChecklistStorageType } from 'store/actions/types';
import MedicationCodesSearch from '../MedicationCodes/medicationCodesSearch';
import EmptyServices from './Components/EmptySevices';
import GroupDecision from './Components/GroupDecision';
import IndividualService from './Components/IndividualService';
import OverallHealthService from './Components/OverallHealthService';
import { HealthServiceSummary } from './Components/HealthServiceSummary';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { DecisionAction, DeterminationDecision } from './types';
import { COLORS } from 'consts/styles';
import { notMaybe } from 'util/helpers/arrayFunctions';
import { debounce } from 'lodash';
import {
  addMedicationCode,
  deleteItemHealthService,
  getValidationDispatch,
  onHealthServiceItemValueChange,
  useSaveHealthService,
  updateDecisionAndReason,
  onHealthServiceOverallItemValueChange,
  applyOnHsDecisionGroup,
} from './HealthService.helpers';
import {
  useDeterminationReasons,
  useLookup,
  usePrivileges,
} from './HealthService.hooks';
import {
  ChecklistItem,
  ChecklistItemHealthServices,
  ChecklistViewModel,
  HealthServiceCodesActionModel,
  HealthServiceCodesActionValue,
  LookupValue,
  SearchResult,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import { HEALTH_SERVICE } from 'components/constants';
import { updateHealthServiceValues } from 'store/actions/checklistSlice';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';

export interface IChecklistItemHealthServiceProps {
  item: ChecklistItemHealthServices;
  categoryId: string;
  autoSave: boolean;
  orderableIndex: number;
  storageType: ChecklistStorageType;
  updateChecklistItemInputValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  selectChecklistItemOnSave: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
  readOnly: boolean;
  hsAuthorizationType: number;
  isDisplayEpisodeLevelRequestedParam: boolean;
  hSDisplayMode: number;
}

const HealthService = ({
  autoSave,
  item,
  orderableIndex,
  storageType,
  updateChecklistItemInputValueOnSave,
  categoryId,
  readOnly,
}: IChecklistItemHealthServiceProps) => {
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );
  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist
  );

  const isUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    item.uid
  );

  const unitTypes = useLookup('UnitsOfServiceType');
  const defaultUnitType = unitTypes.find((x) => x.name === 'Units');
  const determinationReasons = useDeterminationReasons(item.options);
  const { canApprove, canDenied, pendingOnly, noDecision, badConfiguration } =
    usePrivileges();
  const dispatch = useDispatch();

  const getInitialValue = () => {
    let approvedUnitType = null;
    if (!noDecision && !pendingOnly && item.hSValue?.overallApprUnitType) {
      approvedUnitType = item.hSValue?.overallApprUnitType;
    } else {
      approvedUnitType = defaultUnitType;
    }
    return {
      ...item.hSValue,
      overallApprUnitType: approvedUnitType,
      overallReqUnitType: item.hSValue?.overallReqUnitType
        ? item.hSValue?.overallReqUnitType
        : defaultUnitType,
      healthServiceCodesActionValues:
        item.hSValue?.healthServiceCodesActionValues.map(
          (hSCodesActionValues) =>
            hSCodesActionValues?.healthServiceActionValue?.requestedUnitType ===
              null ||
            hSCodesActionValues?.healthServiceActionValue?.requestedUnitType ===
              undefined
              ? {
                  ...hSCodesActionValues,
                  healthServiceActionValue: {
                    ...hSCodesActionValues?.healthServiceActionValue,
                    requestedUnitType: defaultUnitType?.name ?? 'Units',
                    requestedUnits: '',
                  },
                }
              : { ...hSCodesActionValues }
        ),
    } as HealthServiceCodesActionModel;
  };

  const [model, setModel] = useState(getInitialValue());
  const [overallData, setOverallData] = useState({
    start: item.hSValue?.overallReqStartDate,
    end: item.hSValue?.overallReqEndDate,
    unitType: item.hSValue?.overallReqUnitType ?? defaultUnitType,
  });

  const onChangeOverallData = (newModel: HealthServiceCodesActionModel) => {
    const updatedModel = onHealthServiceOverallItemValueChange(newModel, null);
    setOverallData({
      start: newModel.overallReqStartDate,
      end: newModel.overallReqEndDate,
      unitType: newModel.overallReqUnitType ?? defaultUnitType,
    });
    setModel(updatedModel);
  };

  const onDeleteCode = (id: number) => {
    const newModel = deleteItemHealthService(model, id, null);
    setModel(newModel);
    validationDispatch('', false, id.toString());
    validationDispatch('', false, `${id.toString()}-reason`);
  };

  const onAddCode = (newCode: SearchResult) => {
    const newModel = addMedicationCode(newCode, model, unitTypes, null);
    setModel(newModel);
  };

  const onIndividualServiceChange = (
    updatedValue: HealthServiceCodesActionValue
  ) => {
    const newModel = onHealthServiceItemValueChange(model, updatedValue);
    setModel(newModel);
  };

  const saveHealthService = useSaveHealthService();
  const handleInputChange = debounce((value: HealthServiceCodesActionModel) => {
    saveHealthService(
      value,
      checklistId,
      item,
      autoSave,
      updateChecklistItemInputValueOnSave,
      orderableIndex
    );
  }, 100);

  useEffect(() => {
    handleInputChange(model);
    dispatch(
      updateHealthServiceValues({
        storageType,
        itemUid: item.uid,
        code: model,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const applyOnGroup = (
    decision: DeterminationDecision,
    reason: LookupValue | null
  ) => {
    applyOnHsDecisionGroup(
      dispatch,
      storageType,
      checklist as ChecklistViewModel,
      item,
      decision
    );
    const newModel = updateDecisionAndReason(model, decision, reason, null);
    setModel(newModel);
  };

  const validationDispatch = getValidationDispatch(
    dispatch,
    item.uid,
    categoryId,
    storageType,
    HEALTH_SERVICE
  );

  useEffect(() => {
    const medCodeCount = model.healthServiceCodesActionValues.length;
    const isValid =
      medCodeCount >= item.options.minRequired &&
      medCodeCount <= item.options.maxAllowed;
    validationDispatch(
      'Please provide medication codes',
      !badConfiguration && !isValid,
      'medcodes'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    badConfiguration,
    item.options.maxAllowed,
    item.options.minRequired,
    model.healthServiceCodesActionValues.length,
  ]);

  useEffect(
    () => () => {
      validationDispatch('', false, 'medcodes');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onModelChange = (updatedValue: HealthServiceCodesActionModel) => {
    setModel(updatedValue);
  };

  const isPendingOnly = pendingOnly || canApprove || canDenied;
  const isApproveOnly = canApprove || canDenied;

  return (
    <Box id={`uuid-${item.uid}`}>
      <Box bgcolor={COLORS.WHITE} padding="14px 36px 14px 36px">
        <Typography variant="body2">{item.options.codeLabel}</Typography>
      </Box>
      <Box bgcolor={COLORS.GREY4} padding="14px 36px 14px 36px">
        <Box padding="8px 20px 16px 0px">
          <OverallHealthService
            state={model}
            onChange={onChangeOverallData}
            unitTypes={unitTypes}
            readOnly={badConfiguration || readOnly}
            notApproved={noDecision || pendingOnly}
            validationDispatch={validationDispatch}
            isDisplayEpisodeLevelRequestedParam={true}
            hsAuthorizationType={0}
            hsUnitTypeEditable={true}
            hsDefaultUnit={defaultUnitType?.name}
          />
        </Box>
        <Box
          bgcolor={COLORS.WHITE}
          padding="14px 20px 4px 28px"
          borderRadius="4px"
          id={`uuid-${item.uid}-medcodes`}
        >
          <Typography variant="body2">Individual Services</Typography>
          {model.healthServiceCodesActionValues?.length > 0 ? (
            <>
              {model.healthServiceCodesActionValues
                ?.filter(notMaybe)
                .map((code, index: number) => (
                  <IndividualService
                    id={`uuid-${item.uid}-${code.id}`}
                    key={index.toString()}
                    item={code}
                    onDelete={onDeleteCode}
                    unitTypes={unitTypes}
                    onChange={onIndividualServiceChange}
                    readOnly={badConfiguration || readOnly}
                    determinationReasons={determinationReasons}
                    pendingOnly={isPendingOnly}
                    canApprove={isApproveOnly}
                    canDenied={canDenied}
                    overallDates={overallData}
                    validationDispatch={validationDispatch}
                    enableDelete={
                      model.healthServiceCodesActionValues?.length > 1
                    }
                    hsAuthorizationType={0}
                    isHsAllowDeletingCode={true}
                  />
                ))}
            </>
          ) : (
            <EmptyServices />
          )}
          {!badConfiguration && (
            <MedicationCodesSearch
              minRequired={item.options.minRequired}
              maxAllowed={item.options.maxAllowed}
              type={item.options.type}
              readOnly={readOnly}
              selectedCodes={
                model.healthServiceCodesActionValues as SearchResult[]
              }
              onAddCode={onAddCode}
              style={{ padding: '0 0px 14px 0px' }}
            />
          )}
        </Box>
        <Box bgcolor={COLORS.GREY4} padding="14px 20px 0px 0px">
          <GroupDecision
            determinationReasons={determinationReasons}
            actionOnGroup={applyOnGroup}
            actionOnDecision={() => {
              return;
            }}
            readOnly={
              !model.healthServiceCodesActionValues?.length ||
              badConfiguration ||
              readOnly
            }
            pendingOnly={isPendingOnly}
            canApprove={isApproveOnly}
            canDenied={canDenied}
            canPartial={isApproveOnly}
            hsAuthorizationType={0}
            hsModel={model}
          />
        </Box>
      </Box>
      <Box bgcolor={COLORS.WHITE} padding="12px 20px 12px 36px">
        <HealthServiceSummary
          unitTypes={unitTypes}
          hsModel={model}
          episodeDecision={DecisionAction.nonSet}
          hsAuthorizationType={0}
          hSDisplayMode={0}
          onChange={onModelChange}
          defaultUnitType={undefined}
        />
      </Box>
      {isUpdateActionValueRequestFailed && (
        <Typography style={{ paddingLeft: '36px', color: 'red' }}>
          Internal server error: Your changes could not be saved, because of
          technical issues.
        </Typography>
      )}
    </Box>
  );
};
export default HealthService;
