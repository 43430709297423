import {
  DummyProvider,
  FoundProviderStatus,
  ProviderSearchResultRow,
} from 'graphql/graphqlTypes';
import { IProvider } from 'components/providerSearchDialog/types';

export const getParStateDisplayValue = (status: FoundProviderStatus) => {
  switch (status) {
    case FoundProviderStatus.AllMatched:
      return 'Yes';
    case FoundProviderStatus.NotMatched:
      return 'No';
    default:
      return '???';
  }
};

export const getProviderSearchDisplayValue = (
  item?: ProviderSearchResultRow | IProvider | DummyProvider | undefined
) => {
  if (item === undefined || item === null) {
    return '';
  }

  const parDisplayValue = getParStateDisplayValue(
    item.par ?? FoundProviderStatus.NotMatched
  );
  return `${item.firstName ?? ''} ${item.lastName ?? ''} ${
    item.providerId ?? ''
  } ${item.nPI ?? ''} PAR ${parDisplayValue} ${item.address ?? ''}`;
};
