import { Button, Dialog, TextField, Typography } from '@mui/material';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ILookupValue } from 'backend/types/lookupValue';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Loader from 'components/loader';
import { COLORS } from 'consts/styles';
import { useForwardOwnerMutation } from 'graphql/hooks/forwardOwner';
import styled from 'styled-components';

const StyledTextField = styled(TextField)({
  fontSize: '14px',
  color: COLORS.NAVY_BLUE,
});

const StyledDialogContent = styled(DialogContent)({
  padding: '20px 24px 48px 16px',
});

const StyledButton = styled(Button)({
  minWidth: '150px',
});

const BlueTypography = styled(Typography)({
  color: COLORS.SYMPHONY_BLUE,
});
const WhiteTypography = styled(Typography)({
  color: COLORS.WHITE,
});

const StyledSpan = styled.span`
  font-size: 14px;
`;
const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const StyledGrow = styled.div`
  flex-grow: 1;
`;
export interface IForwardOwnerDialogProps {
  open: boolean;
  episodeId: number;

  onClose(closeParentDialog: boolean): void;
}

export const ForwardOwnerDialog = ({
  open,
  episodeId,
  onClose,
}: IForwardOwnerDialogProps) => {
  const [selectedUserId, setUser] = useState(0);

  const users = useSelector((state: IState) => state.home.mainModel.users).map(
    (user) => ({ id: user.id, name: user.name } as ILookupValue)
  );

  const [forwardOwner, { isLoading }] = useForwardOwnerMutation();

  const handleForward = () => {
    if (selectedUserId > 0) {
      forwardOwner({
        episodeId: episodeId,
        userId: selectedUserId,
      }).then(() => {
        onClose(true);
      });
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setUser(Number(value));
  };

  const handleClose = () => {
    setUser(0);
    onClose(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <StyledSpan>Assign Episode</StyledSpan>
        </DialogTitle>
        <StyledDialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ color: COLORS.BLACK }}>
                New owner:
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <StyledTextField
                id="newOwner"
                select
                size="medium"
                defaultValue={''}
                fullWidth={true}
                variant="outlined"
                onChange={handleChange}
              >
                <MenuItem key="" value="" />
                {users?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Grid>
          </Grid>
        </StyledDialogContent>
        <DialogActions style={{ padding: '20px 24px' }}>
          <StyledGrow />
          <StyledButtonsWrapper>
            <StyledButton
              onClick={handleClose}
              color="primary"
              variant="outlined"
              data-cy="cancel"
              size="large"
            >
              <BlueTypography variant="h5">Cancel</BlueTypography>
            </StyledButton>
            <StyledButton
              onClick={handleForward}
              size="large"
              color="primary"
              variant="contained"
              data-cy="new-episode"
              disabled={selectedUserId === 0}
            >
              <WhiteTypography variant="h5">Assign</WhiteTypography>
            </StyledButton>
          </StyledButtonsWrapper>
        </DialogActions>
      </Dialog>
      <Loader active={isLoading} />
    </>
  );
};
