import { useAppSelector } from 'store';
import { QueryStatus } from '@reduxjs/toolkit/query';

export const useMozartLoading = () => {
  const queries = useAppSelector((state) => state?.api?.queries ?? {});
  const mutations = useAppSelector((state) => state?.api?.mutations ?? {});

  const mozartQueries = ['getChecklistModel', 'getChecklistsModel'];
  const pendingQueries = Object?.values(queries)?.some(
    (query) =>
      mozartQueries.includes(query?.endpointName ?? '') &&
      query?.status === QueryStatus.pending
  );
  const pendingMutations = Object?.values(mutations)?.some(
    (query) =>
      query?.endpointName === 'createAndViewWorkflowChecklist' &&
      query?.status === QueryStatus.pending
  );

  return {
    isLoading: pendingQueries || pendingMutations,
    isFinishedLoading: !pendingQueries && !pendingMutations,
  };
};
