import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ILookupValue } from 'backend/types/lookupValue';
import { EpisodeFilters, FilterGrid } from 'components/gridFormatters';

export const EpisodeTabHeader = ({
  onChangeEpisodeState,
  episodeListState,
  title,
}: {
  onChangeEpisodeState: (state: ILookupValue) => void;
  episodeListState: string;
  title: string;
}) => (
  <Grid item xs={12}>
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      pb="14px"
    >
      <Typography variant="subtitle1">{title}</Typography>
      <FilterGrid
        selectedItemId={Number(episodeListState)}
        onChange={onChangeEpisodeState}
        items={EpisodeFilters}
      />
    </Box>
  </Grid>
);
