import React from 'react';
import { useDispatch } from 'react-redux';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import Link from '@mui/material/Link';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import StyledLink from 'components/actionlink/StyledLink';
import { COLORS } from 'consts/styles';

const EpisodeNumberLink = styled(Link)({
  root: {
    width: '120px',
    height: '16px',
    fontWeight: 500,
    color: COLORS.SYMPHONY_BLUE,
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
});

const EpisodeNumberFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    dispatch(
      openPatientDetailsModal({
        patientId: Number(props.row.patientId),
        episodeId: Number(props.row.id),
        activeTabName: 'Episodes',
        activeSubTabName: '',
      })
    );
  };

  if (!props.value) {
    return null;
  }

  return (
    <>
      {props.row.allowViewEpisodeDetail ? (
        <EpisodeNumberLink onClick={handleClick}>
          <CustomTooltip title="Episodes">
            <span>{props.value}</span>
          </CustomTooltip>
        </EpisodeNumberLink>
      ) : (
        <StyledLink>
          <CustomTooltip title="No Access">
            <span>{props.value}</span>
          </CustomTooltip>
        </StyledLink>
      )}
    </>
  );
};

const DuplicateEpisodeNumberProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={EpisodeNumberFormatter} {...props} />
);

export default DuplicateEpisodeNumberProvider;
