import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import MozartNextStepButton from 'components/mozartNextStepButton';

const NextStepFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  return (
    <MozartNextStepButton
      buttonText={props.value}
      workflowInstanceId={props.row.workFlowInstanceId}
      mozartActionEnabled={props.row.mozartActionEnabled}
      mozartWorkflowCompleted={props.row.mozartWorkflowCompleted}
      userInputLock={props.row.userInputLock}
    />
  );
};

const NextStepProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={NextStepFormatter} {...props} />
);

export default NextStepProvider;
