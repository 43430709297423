import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

interface IConfirmationDialogProps {
  open: boolean;
  reminderCount: number;
  onCancel(): void;
  onCompleteAndClose(): void;
}

const ReminderConfirmation = (props: IConfirmationDialogProps) => {
  const { open, onCancel, onCompleteAndClose, reminderCount } = props;
  const reminderPlural = reminderCount === 1 ? 'Reminder' : 'Reminders';
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4">Close Episode</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>
            Patient has{' '}
            <strong>
              {reminderCount} open {reminderPlural}
            </strong>{' '}
            for this Episode.
          </Typography>
          <Typography>
            Do you want to complete reminders and close episode?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant="outlined"
          color="primary"
          size="large"
          autoFocus
          data-cy="cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={onCompleteAndClose}
          variant="contained"
          size="large"
          color="primary"
          data-cy="keep"
        >
          Complete & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReminderConfirmation;
