import React from 'react';
import { Popper, PopperProps } from '@mui/material';
import { Filter, ProviderFilter } from '.';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

export interface IProviderPopperProps extends PopperProps {
  filter?: {
    onFilterChange?: (type: Filter) => void;
    active?: Filter;
  };
  onViewAllClick?: () => void;
  onCantFindClick?: () => void;
  totalCount: number;
  providerType: string;
}

const StyledFilterContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
});

const StyledFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 40px 28px 44px',
});

const StyledLink = styled('a')({
  cursor: 'pointer',
  fontWeight: 500,
  letterSpacing: '0.5px',
  color: COLORS.SYMPHONY_BLUE,
});

export const ProviderPopper = ({
  filter,
  onViewAllClick,
  onCantFindClick,
  totalCount,
  providerType,
  ...rest
}: IProviderPopperProps) => {
  const Paper = rest.children as JSX.Element;
  return (
    <Popper
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
      {...rest}
    >
      <Paper.type {...Paper.props}>
        <StyledFilterContainer>
          <ProviderFilter
            filters={[Filter.All, Filter.Favorite, Filter.Recent]}
            {...filter}
          />
        </StyledFilterContainer>
        {Paper.props.children}
        <StyledFooter>
          <StyledLink onMouseDown={() => onCantFindClick?.()}>
            I can&apos;t find {providerType}
          </StyledLink>
          <StyledLink onMouseDown={() => onViewAllClick?.()}>
            View all ({totalCount})
          </StyledLink>
        </StyledFooter>
      </Paper.type>
    </Popper>
  );
};
