import React, { useState } from 'react';
import { useSetCurrentUserPreferenceMutation } from 'graphql/hooks/setCurrentUserPreference';
import { dashBoardType, userPreference } from './DashBoardGridHelper';
import UserDashboardGridHeader from './UserDashboardGridHeader';

export interface UmRnDashboardGridHeaderProps {
  isDashboardCareSiteRole: boolean;
  careSiteId: string | null;
  onChange: (careSiteId: string) => void;
}

const UmRnDashboardGridHeader = ({
  isDashboardCareSiteRole,
  careSiteId,
  onChange,
}: UmRnDashboardGridHeaderProps) => {
  const [updateCurrentUserPreference] = useSetCurrentUserPreferenceMutation();
  const [selectedCareSiteId, setSelectedCareSiteId] = useState<string | null>(
    careSiteId ?? ''
  );
  const handleSelectChange = (event: React.ChangeEvent<{ value: string }>) => {
    const currentValue = event.target.value;
    setSelectedCareSiteId(currentValue);
    updateCurrentUserPreference({
      name: userPreference.umRnDashboardFilter,
      value: currentValue,
    });
    onChange(currentValue);
  };

  return (
    <UserDashboardGridHeader
      isDashboardCareSiteRole={isDashboardCareSiteRole}
      dashBoardType={dashBoardType.uMRnDashboard}
      selectedCareSiteId={selectedCareSiteId}
      handleSelectChange={handleSelectChange}
    />
  );
};

export default UmRnDashboardGridHeader;
