import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { Ellipsis } from 'components/text';

const StyledTypography = styled(Typography)`
  display: inline;
`;

const StyledTitleWrapper = styled.div<{ $color?: string }>`
  width: 100%;
  display: flex;
  color: ${({ $color }) => $color || COLORS.PALE_GREY};
  align-items: center;
`;

export interface IEpisodeSummaryTitleProps {
  title: string;
}

const EpisodeSummaryTitle = (props: IEpisodeSummaryTitleProps) => {
  const { title } = props;

  return (
    <StyledTitleWrapper $color={COLORS.PALE_GREY}>
      <Ellipsis>
        <StyledTypography noWrap variant="subtitle2">
          {title}
        </StyledTypography>
      </Ellipsis>
    </StyledTitleWrapper>
  );
};

export default EpisodeSummaryTitle;
