import React from 'react';
import { Grid } from '@mui/material';
import PreferencesForm from 'components/user/preferences/Form';

const Preferences = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    data-cy="user-preferences"
  >
    <Grid item xs={6}>
      <PreferencesForm />
    </Grid>
  </Grid>
);

export default Preferences;
