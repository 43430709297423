import MuiDialogActions from '@mui/material/DialogActions';
import styled from 'styled-components';
import theme from 'theme';

const DialogActions = styled(MuiDialogActions)({
  margin: 0,
  padding: theme.spacing(3),
});

export default DialogActions;
