import { useSelector } from 'react-redux';
import { ChecklistStorageType } from 'store/actions/types';
import { IState } from 'store';

export const useChecklistForSidebar = (storageType: ChecklistStorageType) => {
  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist
  );
  const completedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );
  const fallbackChecklist = checklist ?? Object.values(completedChecklists)[0];

  return {
    checklist: checklist || fallbackChecklist,
  };
};
