import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { MOMENT_DATE_FORMAT } from 'components/constants';
import moment from 'moment';
import DatePicker from './DatePicker';
import SelectTextField from './SelectTextField';
import {
  formatValidDate,
  isValidDate,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.helpers';
import {
  HealthServiceCodesActionModel,
  ILookupValue,
  LookupValue,
} from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { formatDate, getNumberOrNull } from './ServiceRow.helpers';
import {
  getDefaultUnitType,
  getHsAuthorizationTypRequestParam,
} from '../HealthServiceV2helper';
import { HealthServiceAuthorizationType, UnitTypes } from '../types';
import { healthServiceDisplayMode } from '../../../HealthServiceDisplayMode';
import { isUndefined } from 'lodash';
import { COLORS } from 'consts/styles';

const InputGroupBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export interface IOverallProps {
  state: HealthServiceCodesActionModel;
  onChange: (state: HealthServiceCodesActionModel) => void;
  unitTypes: ILookupValue[];
  readOnly: boolean;
  notApproved: boolean;
  hsRequestingCodes?: boolean;
  hsUnitTypeEditable: boolean;
  hsDefaultUnit?: string | null;
  isDisplayEpisodeLevelRequestedParam: boolean;
  hsAuthorizationType: number;
  hSDisplayMode?: number;
  validationDispatch: (
    errorMessage: string,
    error: boolean,
    id?: string
  ) => void;
}

const OverallHealthService = (props: IOverallProps) => {
  const {
    state,
    onChange,
    unitTypes,
    readOnly,
    notApproved,
    validationDispatch,
    hsRequestingCodes,
    isDisplayEpisodeLevelRequestedParam,
    hsAuthorizationType,
    hsUnitTypeEditable,
    hsDefaultUnit,
    hSDisplayMode,
  } = props;
  const [error, setError] = useState(false);

  const StyledTypography = styled(Typography)({
    marginRight: '4px',
    marginLeft: '8px',
  });

  const StyledBox = styled(Box)({
    borderLeft: '1px solid',
    borderColor: COLORS.GREY25,
    paddingLeft: '20px',
    height: '32px',
    display: 'flex',
  });

  useEffect(() => {
    setError(
      !readOnly &&
        (!isValidDate(state.overallReqStartDate) ||
          !isValidDate(state.overallReqEndDate))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.overallReqStartDate, state.overallReqEndDate]);

  useEffect(() => {
    const errorMessage = 'missing Overall Request data';
    validationDispatch(errorMessage, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const defaultUnitType = getDefaultUnitType(unitTypes, hsDefaultUnit);

  const callApprovedUnitType = (
    notApprovedFlag: boolean,
    selectedUnitType: LookupValue | undefined | null,
    defUnitType: LookupValue | null
  ) => {
    if (notApprovedFlag) {
      return null;
    }
    if (selectedUnitType) {
      return selectedUnitType;
    }
    return defUnitType;
  };

  const handleStartDateChange = (date: Date | null | unknown) => {
    const newState: HealthServiceCodesActionModel = {
      ...state,
      overallReqStartDate: formatValidDate(date as Date),
      overallApprStartDate: notApproved ? null : formatValidDate(date as Date),
      overallReqUnitType: state.overallReqUnitType
        ? state.overallReqUnitType
        : (defaultUnitType as LookupValue),
      overallApprUnitType: callApprovedUnitType(
        notApproved,
        state.overallApprUnitType,
        defaultUnitType as LookupValue
      ),
    };
    onChange(newState);
  };
  const handleEndDateChange = (date: Date | null | unknown) => {
    const newState: HealthServiceCodesActionModel = {
      ...state,
      overallReqEndDate: formatValidDate(date as Date),
      overallApprEndDate: notApproved ? null : formatValidDate(date as Date),
      overallReqUnitType: state.overallReqUnitType
        ? state.overallReqUnitType
        : (defaultUnitType as LookupValue),
      overallApprUnitType: callApprovedUnitType(
        notApproved,
        state.overallApprUnitType,
        defaultUnitType as LookupValue
      ),
    };
    onChange(newState);
  };

  const handleUnitTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valueId = Number(e.target.value);
    const unitType = unitTypes.find((x) => x.id === valueId);
    const newState: HealthServiceCodesActionModel = {
      ...state,
      overallReqUnitType: unitType ? (unitType as LookupValue) : null,
      overallApprUnitType: callApprovedUnitType(
        notApproved,
        unitType as LookupValue,
        null
      ),
    };
    onChange(newState);
  };

  const handleTotalReqUnitsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newModel: HealthServiceCodesActionModel = {
      ...state,
      overallApprUnitType:
        hsAuthorizationType === HealthServiceAuthorizationType.Episode &&
        !isUndefined(defaultUnitType)
          ? (defaultUnitType as LookupValue)
          : state.overallApprUnitType,
      overallReqUnitType:
        hsAuthorizationType === HealthServiceAuthorizationType.Episode &&
        !isUndefined(defaultUnitType)
          ? (defaultUnitType as LookupValue)
          : state.overallReqUnitType,
    };
    newModel.totalReqUnits = getNumberOrNull(e.target.value);
    if (!isUndefined(onChange)) {
      onChange(newModel);
    }
  };
  const hsRequestParam = getHsAuthorizationTypRequestParam(
    isDisplayEpisodeLevelRequestedParam,
    hsAuthorizationType,
    hSDisplayMode as number
  );

  return (
    <>
      <Box display="flex" alignContent="end">
        <Typography variant="body2">Requested:</Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        pt="14px"
        gap="20px"
        data-testid="ohs-container"
      >
        <InputGroupBox>
          <Typography variant="body1">Start Date</Typography>
          {hsRequestParam ? (
            <DatePicker
              value={formatDate(state.overallReqStartDate)}
              readOnly={readOnly || hsRequestingCodes}
              onChange={handleStartDateChange}
              autoFocus={false}
              error={error}
            />
          ) : (
            <Typography variant="body2">
              {!!state?.overallReqStartDate &&
                moment(state.overallReqStartDate).format(MOMENT_DATE_FORMAT)}
            </Typography>
          )}
        </InputGroupBox>
        <InputGroupBox>
          <Typography variant="body1">End Date</Typography>
          {hsRequestParam ? (
            <DatePicker
              value={formatDate(state.overallReqEndDate)}
              readOnly={readOnly || hsRequestingCodes}
              onChange={handleEndDateChange}
              error={error}
            />
          ) : (
            <Typography variant="body2">
              {!!state?.overallReqEndDate &&
                moment(state.overallReqEndDate).format(MOMENT_DATE_FORMAT)}
            </Typography>
          )}
        </InputGroupBox>
        <StyledBox>
          <InputGroupBox>
            <Typography variant="body1">Unit Type</Typography>
            {(hsAuthorizationType == HealthServiceAuthorizationType.NonSet &&
              hSDisplayMode !== healthServiceDisplayMode.manualReview) ||
            (isDisplayEpisodeLevelRequestedParam && hsUnitTypeEditable) ? (
              <SelectTextField
                onChange={handleUnitTypeChange}
                menuItems={unitTypes}
                readOnly={readOnly}
                value={state.overallReqUnitType?.id ?? defaultUnitType?.id}
                hideEmptySelect={true}
              />
            ) : (
              <Typography variant="body2">
                {state.overallReqUnitType?.name}
              </Typography>
            )}
          </InputGroupBox>
          {hsAuthorizationType === HealthServiceAuthorizationType.Episode && (
            <Box display="flex" alignItems="center">
              <StyledTypography>Value:</StyledTypography>
              {hSDisplayMode === healthServiceDisplayMode.requestingCodes &&
              state.overallReqUnitType?.name !== UnitTypes.days ? (
                <TextField
                  type="tel"
                  size="medium"
                  variant="outlined"
                  value={state?.totalReqUnits ?? 0}
                  onChange={handleTotalReqUnitsChange}
                  style={{ width: '68px' }}
                  data-testid="hs-requnits"
                />
              ) : (
                <Typography variant="body2">{state?.totalReqUnits}</Typography>
              )}
            </Box>
          )}
        </StyledBox>
      </Box>
    </>
  );
};
export default OverallHealthService;
