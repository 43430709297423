import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type DiscardEpisodeMutationVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type DiscardEpisodeMutation = {
  __typename?: 'Mutation';
  discardEpisode?: {
    __typename?: 'ActionEpisodeResult';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const DiscardEpisodeDocument = `
    mutation discardEpisode($episodeId: Int!) {
  discardEpisode(episodeId: $episodeId) {
    success
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    discardEpisode: build.mutation<
      DiscardEpisodeMutation,
      DiscardEpisodeMutationVariables
    >({
      query: (variables) => ({ document: DiscardEpisodeDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDiscardEpisodeMutation } = injectedRtkApi;
