import styled from 'styled-components';
import theme from 'theme';

export { default as RolesList } from './RolesList';

export const StyledWrapper = styled.div`
  background-color: ${theme.palette.background.paper};
  min-width: 308px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
`;
