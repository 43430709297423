import styled from 'styled-components';
import { Grid } from '@mui/material';

export const Line = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 12px 15px;
  margin-right: -16px;
  &:last-child {
    padding: 0 0 0 15px;
  }
`;

export const LineRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 28px;
`;

export const InlineBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const InlineGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '14px 28px',
});

export const StyledRepeaterWrapper = styled.div`
  align-self: flex-start;
  width: 30px;
  padding-top: 2px;
  padding-left: 6px;
`;
