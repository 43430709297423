import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';

const SeamlessFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const displayValue =
    props.value == null ? '' : props.value === true ? 'Y' : 'N';
  return <Typography title={displayValue}>{displayValue}</Typography>;
};

export default SeamlessFormatter;
