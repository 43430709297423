import { Box, Typography } from '@mui/material';
import ProblemList from '../patient/ProblemList';
import React, { useMemo } from 'react';
import { COLORS } from 'consts/styles';
import InsuranceInformation from './InsuranceInformation';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { RoleType } from 'store/roles/types';
import { useGetOpenedClinicalEpisodeQuery } from 'graphql/hooks/getOpenedClinicalEpisode';
import { Patient, Problem } from 'graphql/graphqlTypes';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import styled from 'styled-components';

export interface IProps {
  patient?: Patient;
}
const StyledSpan = styled.span`
  font-weight: 400;
`;

const StyledTypography = styled(Typography)`
  padding-top: 4px;
`;

const CaseInformationCard = (props: IProps) => {
  const { data } = useGetOpenedClinicalEpisodeQuery(
    {
      patientId: props.patient?.id as number,
    },
    { skip: props.patient == undefined }
  );

  const getEntityAttribute = (reason: string) =>
    data?.getOpenedClinicalEpisode?.entityAttributes?.find(
      (item) => item?.name === reason
    )?.value ?? '';

  const problems = (data?.getOpenedEpisodeCarePlanProblems ?? []) as Problem[];

  const { openDate, engagedDate } = useMemo(() => {
    const openDate = data?.getOpenedClinicalEpisode?.updatedOn
      ? formatDate(data?.getOpenedClinicalEpisode?.updatedOn)
      : formatDate(data?.getOpenedClinicalEpisode?.createdOn);
    const engagedDate = getEntityAttribute('CMEngagedDate')
      ? formatDate(getEntityAttribute('CMEngagedDate')) ?? ''
      : '';
    return { openDate, engagedDate };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.getOpenedClinicalEpisode?.updatedOn,
    data?.getOpenedClinicalEpisode?.updatedOn,
    data?.getOpenedClinicalEpisode?.entityAttributes,
  ]);

  const roleType = useSelector((state: IState) => state.user.currentUser.type);

  const hasClinicalEpisode =
    data?.getOpenedClinicalEpisode?.program?.name ||
    data?.getOpenedClinicalEpisode?.status?.name;

  const spacer = !hasClinicalEpisode ? '0' : '14px';

  const renderPatientOwner = () => {
    return props.patient?.owner?.name ? (
      <Box
        px="24px"
        py="14px"
        style={{
          backgroundColor: !hasClinicalEpisode ? COLORS.GREY4 : 'transparent',
        }}
      >
        <Typography variant="body2">
          Member Owner: <StyledSpan>{props.patient?.owner?.name} </StyledSpan>
        </Typography>
      </Box>
    ) : null;
  };

  return (
    <>
      {roleType === RoleType.CLINICAL ? (
        <>
          <Box px="24px" pt="24px" data-testid="clinical-content">
            <Typography variant="h4" style={{ paddingBottom: '20px' }}>
              Case Information
            </Typography>
          </Box>
          <Box
            my={spacer}
            px="24px"
            py="14px"
            style={{
              backgroundColor: !hasClinicalEpisode
                ? COLORS.GREY4
                : 'transparent',
            }}
          >
            {openDate && (
              <Typography variant="body2">
                Open Date: <StyledSpan>{openDate}</StyledSpan>
              </Typography>
            )}
            {data?.getOpenedClinicalEpisode?.program?.name && (
              <StyledTypography variant="body2">
                Program:{' '}
                <StyledSpan data-testid="clinical-program">
                  {data?.getOpenedClinicalEpisode?.program?.name}
                </StyledSpan>
              </StyledTypography>
            )}
            {getEntityAttribute('CMReferralReason') && (
              <StyledTypography variant="body2">
                Referral Reason:{' '}
                <StyledSpan>
                  {getEntityAttribute('CMReferralReason')}
                </StyledSpan>
              </StyledTypography>
            )}
            {engagedDate && (
              <StyledTypography variant="body2">
                Engaged Date: <StyledSpan>{engagedDate}</StyledSpan>
              </StyledTypography>
            )}
            {data?.getOpenedClinicalEpisode?.status?.name && (
              <StyledTypography variant="body2">
                Current Status:{' '}
                <StyledSpan data-testid="clinical-status">
                  {data?.getOpenedClinicalEpisode?.status?.name}
                </StyledSpan>
              </StyledTypography>
            )}
            {data?.getPatientRisk?.acuityLevel !== 'None' && (
              <StyledTypography variant="body2">
                Acuity Level:{' '}
                <StyledSpan>{data?.getPatientRisk?.acuityLevel}</StyledSpan>
              </StyledTypography>
            )}
          </Box>
          <Box
            px="24px"
            mt="14px"
            style={{
              backgroundColor: hasClinicalEpisode
                ? COLORS.GREY4
                : 'transparent',
            }}
          >
            <ProblemList problems={problems} />
          </Box>
          {renderPatientOwner()}
        </>
      ) : null}
      {roleType === RoleType.NONCLINICAL ? (
        <div data-testid="non-clinical-content">
          <InsuranceInformation patient={props.patient} />
        </div>
      ) : null}
    </>
  );
};
export default CaseInformationCard;
