import { useEffect } from 'react';
import { IMessageState } from '..';

export const useMutationEffect = (
  success: IMessageState,
  error: IMessageState,
  setMessage: (message: IMessageState) => void,
  state: { isLoading: boolean; isError: boolean; isSuccess: boolean },
  setLoading?: (loading: boolean) => void,
  onSuccess?: () => void,
  onError?: () => void
) => {
  const { isLoading, isSuccess, isError } = state;
  useEffect(
    () => {
      if ((isSuccess || isError) && !isLoading) {
        const message = isSuccess ? success : error;
        setMessage(message);
        setLoading?.(false);
        isSuccess ? onSuccess?.() : onError?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, isSuccess, isError]
  );
};
