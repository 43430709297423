import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import theme from 'theme';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const IconButton = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '30px',
  textAlign: 'center',
  padding: '6px 0px',
  [theme.breakpoints.up('lg')]: {
    width: '52px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '52px',
  },
  '&:hover': {
    color: COLORS.SYMPHONY_BLUE_HOVER,
    cursor: 'pointer',
  },
});

const IconWrapper = styled.div`
  background-color: ${COLORS.PALE_GREY};
  display: flex;
  padding: 6px 0;
  width: 100%;
  justify-content: center;
  border-radius: 4px;
`;

export interface IActionButtonProps {
  handleClick: () => void;
  text: string;
  tooltipText: string;
  icon: ICONS;
  iconSize?: number;
  testId: string;
  disabled?: boolean;
  areaLabel?: string;
}

const ActionButton = (props: IActionButtonProps) => {
  const {
    handleClick,
    text,
    tooltipText,
    icon,
    testId,
    disabled,
    areaLabel = '',
    iconSize = 24,
  } = props;

  const [buttonHovered, setButtonHovered] = useState<boolean>(false);

  const buttonColor = () => {
    if (disabled) {
      return COLORS.GREY100;
    }
    return buttonHovered ? COLORS.SYMPHONY_BLUE : COLORS.GREY100;
  };

  const buttonBackgroundColor = () => {
    if (disabled) {
      return COLORS.GREY10;
    }
    return buttonHovered ? COLORS.SYMPHONY_BLUE_HOVER : COLORS.PALE_GREY;
  };

  return (
    <CustomTooltip title={tooltipText}>
      <IconButton
        aria-label={areaLabel}
        data-testid={testId}
        onClick={handleClick}
        onMouseEnter={() => setButtonHovered(true)}
        onMouseLeave={() => setButtonHovered(false)}
      >
        <IconWrapper style={{ backgroundColor: buttonBackgroundColor() }}>
          <Icon
            icon={icon}
            size={iconSize}
            color={disabled ? COLORS.GREY100 : COLORS.SYMPHONY_BLUE}
          />
        </IconWrapper>
        <Box
          display={{
            xs: 'none',
            sm: 'none',
            md: 'none',
            lg: 'none',
            xl: 'block',
          }}
        >
          <Typography
            style={{
              fontSize: '10px',
              color: buttonColor(),
            }}
            variant="body2"
          >
            {text}
          </Typography>
        </Box>
      </IconButton>
    </CustomTooltip>
  );
};

export default ActionButton;
