import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SelectActionMutationVariables = Types.Exact<{
  data: Types.SelectActionModelInput;
}>;

export type SelectActionMutation = {
  __typename?: 'Mutation';
  selectAction?: boolean | null;
};

export const SelectActionDocument = `
    mutation selectAction($data: SelectActionModelInput!) {
  selectAction(data: $data)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    selectAction: build.mutation<
      SelectActionMutation,
      SelectActionMutationVariables
    >({
      query: (variables) => ({ document: SelectActionDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSelectActionMutation } = injectedRtkApi;
