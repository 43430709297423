import {
  IRoleActions,
  ROLES_FAIL,
  ROLES_GET,
  ROLES_SET_PRIMARY,
  ROLES_SUCCESS,
  SET_ROLES_LOADING,
} from './action';
import { IRole, IRolesState } from './types';

export const initialRolesState: IRolesState = {
  items: [] as IRole[],
  primaryRoleId: null,
  loading: false,
  error: null,
};

const rolesReducer = (
  state: IRolesState = initialRolesState,
  action: IRoleActions
): IRolesState => {
  switch (action.type) {
    case ROLES_GET:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.roles,
        primaryRoleId: action.roles?.find((role) => role.primary)?.id ?? null,
      };
    case ROLES_SET_PRIMARY:
      return {
        ...state,
        primaryRoleId: action.roleId,
      };
    case SET_ROLES_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ROLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: [] as IRole[],
      };

    default:
      return state;
  }
};
export default rolesReducer;
