import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { MozartIndicator } from 'features/mozart/components/MozartIndicator/MozartIndicator';
import {
  MOZART_MOMENT_DATETIME_FORMAT,
  MOMENT_TIME_SECONDS_FORMAT,
} from 'components/constants';
import { MozartWorkflowUiModel } from 'graphql/graphqlTypes';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { MozartRevertToPriorStepIcon } from './MozartRevertToPriorStepAction';

export interface IMozartStep {
  currentStep?: string;
  isDoneStep?: boolean;
  message?: MozartWorkflowUiModel;
}

const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

const StyledBold = styled.span`
  font-weight: 500;
`;

const StyledGreyTypography = styled(Typography)`
  color: ${COLORS.GREY100};
`;

const StyledTypography = styled(Typography)`
  margin-right: 12px;
`;
const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RightHeadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MozartStep = ({
  currentStep,
  isDoneStep,
  message,
}: IMozartStep) => {
  const date = message?.updatedOn ?? message?.createdOn;
  return (
    <StepWrapper>
      <HeadingWrapper>
        {currentStep && (
          <>
            <StyledTypography
              data-testid="mozart-step-header"
              variant="subtitle1"
            >
              {currentStep}
            </StyledTypography>
            {isDoneStep ? <MozartIndicator /> : null}
          </>
        )}
      </HeadingWrapper>
      <RightHeadWrapper>
        <CustomTooltip title={formatDate(date, MOMENT_TIME_SECONDS_FORMAT)}>
          <StyledGreyTypography variant="body1">
            <StyledBold data-testid="mozart-step-username">
              {message?.updatedBy}
            </StyledBold>{' '}
            {formatDate(date, MOZART_MOMENT_DATETIME_FORMAT)}
          </StyledGreyTypography>
        </CustomTooltip>
        <MozartRevertToPriorStepIcon message={message} />
      </RightHeadWrapper>
    </StepWrapper>
  );
};
