/* eslint-disable @typescript-eslint/no-explicit-any */

//Author: Majid Akbari
// Extracted from https://github.com/magicops/JQuery-expression-builder
// LICENSE: MIT
// Modified marius-klimantavicius 2022-06-07:
// Removed jquery references
// Updated type annotations
// Removed unneeded code
// Fixed code to comply with sonar rules

export interface ParserOption {
  // eslint-disable-next-line @typescript-eslint/ban-types
  functions: Record<string, Function>;
  resolveVariable: (name: string) => { value: any } | undefined;

  variableSpecialCharacters?: string[];
}

//abstract base-class
abstract class GraphNode {
  abstract compute(): any;

  abstract toString(): string;
}

//leaf-nodes
class ValueNode extends GraphNode {
  value: number | string | Array<GraphNode>;

  public getNumberValue(): number {
    return parseInt(this.value.toString());
  }

  public getStringValue(): string {
    return this.value.toString();
  }

  public getArrayValue(): Array<GraphNode> {
    return this.value as Array<GraphNode>;
  }

  constructor(value: number | string | Array<GraphNode>) {
    super();
    this.value = value;
  }

  compute() {
    return this.value;
  }

  toString() {
    if (this.value instanceof Array) {
      return this.value.filter((v) => !(v instanceof CommaNode)).join(',');
    }

    if (typeof this.value === 'string') {
      return `"${this.value}"`;
    }

    return this.value.toString();
  }
}

class CommaNode extends GraphNode {
  compute() {
    return ',';
  }

  toString(): string {
    return ',';
  }
}

class PropertyNode extends GraphNode {
  public property: string;
  public inBrackets: boolean;
  public options: ParserOption;

  constructor(options: ParserOption, property: string, inBrackets = false) {
    super();
    this.options = options;
    this.property = property;
    this.inBrackets = inBrackets;
  }

  compute() {
    const variable = this.options.resolveVariable(this.property);

    if (variable === undefined) {
      throw new Error(`Property '${this.property}' is not defined!`);
    }

    return variable.value;
  }

  toString() {
    return String(this.property);
  }
}

//tree-nodes
class FuncNode extends GraphNode {
  public node: GraphNode;
  public name: string;
  public options: ParserOption;

  constructor(options: ParserOption, name: string, node: GraphNode) {
    super();
    this.options = options;
    this.name = name;
    this.node = node;
  }

  compute() {
    const v = this.node.compute();

    const vars = v instanceof Array ? v : [v];

    const computes = vars
      .filter((s) => !(s instanceof CommaNode)) //remove ,
      .map((s) => (s instanceof GraphNode ? s.compute() : s)); //compute each one

    const func = this.options.functions[this.name];
    return func.apply(func, computes);
  }

  toString() {
    return `${this.name}(${this.node})`;
  }
}

class BinaryNode extends GraphNode {
  static operators: Array<string> = ['*', '/', '+', '-'];
  public op: string;
  public left: GraphNode;
  public right: GraphNode;

  constructor(op: string, left: GraphNode, right: GraphNode) {
    super();
    this.op = op;
    this.left = left;
    this.right = right;
  }

  compute() {
    const l = this.left.compute();
    const r = this.right.compute();
    switch (this.op) {
      //computational operators
      case '+':
        return l + r;
      case '-':
        return l - r;
      case '*':
        return l * r;
      case '/':
        return l / r;
      default:
        throw new Error(`operator not implemented '${this.op}'`);
    }
  }

  toString() {
    return `(${this.left}${this.op}${this.right})`;
  }
}

const processArgumentTokens = (argumentTokens: Array<any>): GraphNode => {
  BinaryNode.operators.forEach((token) => {
    for (let i = 1; (i = argumentTokens.indexOf(token, i - 1)) > -1; ) {
      argumentTokens.splice(
        i - 1,
        3,
        new BinaryNode(token, argumentTokens[i - 1], argumentTokens[i + 1])
      );
    }
  });

  const hasComma = argumentTokens.some((x) => x instanceof CommaNode);

  if (hasComma) {
    const commaCount = argumentTokens.filter(
        (t) => t instanceof CommaNode
      ).length,
      argCount = commaCount * 2 + 1;

    if (argumentTokens.length !== argCount) {
      throw new Error(
        `Syntax error for the arguments: ${argumentTokens
          .filter((t) => !(t instanceof CommaNode))
          .join(',')}`
      );
    }

    argumentTokens = [new ValueNode(argumentTokens)];
  }

  if (argumentTokens.length !== 1) {
    throw new Error(`something went wrong. error: ${argumentTokens.slice()}`);
  }
  return argumentTokens[0];
};

const wrapParentheses = (
  options: ParserOption,
  tokens: Array<GraphNode | string>
): GraphNode => {
  //wrap inside any parentheses
  for (
    let i: number, j;
    (i = tokens.lastIndexOf('(')) > -1 && (j = tokens.indexOf(')', i)) > -1;

  ) {
    //if before parentheses there is a property which means it is a function
    if (
      tokens[i - 1] instanceof PropertyNode &&
      !(tokens[i - 1] as PropertyNode).inBrackets
    ) {
      const op = tokens[i - 1].toString();

      const funcParam =
        i + 1 === j
          ? new ValueNode([])
          : processArgumentTokens(tokens.slice(i + 1, j));

      let varsLength = 1;

      if (funcParam instanceof ValueNode && funcParam.value instanceof Array) {
        varsLength = funcParam.value.filter(
          (v) => !(v instanceof CommaNode)
        ).length;
      } //remove

      const func = options.functions[op];
      if (!func) {
        throw new Error(`${op} is not defined.`);
      }

      if (varsLength !== func.length) {
        throw new Error(`${op} requires ${func.length} argument(s)`);
      }

      const funcNode = new FuncNode(options, op, funcParam);
      tokens.splice(i - 1, j + 2 - i, funcNode);
    } else {
      tokens.splice(
        i,
        j + 1 - i,
        processArgumentTokens(tokens.slice(i + 1, j))
      );
    }
  }

  if (~tokens.indexOf('(') || ~tokens.indexOf(')')) {
    throw new Error('mismatching brackets');
  }

  return processArgumentTokens(tokens);
};

const extractTokens = (
  options: ParserOption,
  exp: string
): Array<string | GraphNode> => {
  const languageCharsString = (options.variableSpecialCharacters ?? [])
    .map((c) => `\\${c}`)
    .join('');

  //dynamically build my parsing regex:
  const regExpStr = [
    //properties
    `\\[[a-zA-Z0-9$_${languageCharsString}]*\\]+`,

    //numbers
    /\d+(?:\.\d*)?|\.\d+/.source,

    //string-literal
    /"(?:\\[\s\S]|[^"])+"|'(?:\\[\s\S]|[^'])+'/.source,

    //booleans
    //"true|false",

    //operators
    ['(', ')']
      .concat(BinaryNode.operators)
      .map((s) => String(s).replace(/[.*+?^=!:${}()|[\]/\\]/g, '\\$&'))
      .join('|'),

    //properties:
    //has to be after the operators
    /[a-zA-Z$_][a-zA-Z\d$_]*/.source,

    //remaining (non-whitespace-)chars, just in case
    //has to be at the end
    /\S/.source,
  ]
    .map((s) => `(${s})`)
    .join('|');
  const tokenParser = new RegExp(regExpStr, 'g');

  const result: Array<string | GraphNode> = [];

  //abusing str.replace() as a RegExp.forEach
  exp.replace(
    tokenParser,
    (token, prop, number, strMatch, op, property): string => {
      let t: string | GraphNode;
      t = token;

      if (number) {
        t = new ValueNode(+number);
      } else if (strMatch) {
        strMatch = strMatch.substring(1, strMatch.length - 1);
        strMatch = strMatch.replace(/"/g, "'");
        t = new ValueNode(JSON.parse(`"${strMatch}"`));
      } else if (property) {
        t = new PropertyNode(options, property);
      } else if (prop) {
        t = new PropertyNode(options, prop.substring(1, prop.length - 1), true);
      } else if (token === ',') {
        t = new CommaNode();
      } else if (!op) {
        throw new Error(`unexpected token '${token}'`);
      }

      result.push(t);

      return '';
    }
  );

  return result;
};

const handleNegativeNumbers = (
  tokens: Array<GraphNode | string>
): Array<GraphNode | string> => {
  //detect negative numbers
  if (tokens[0] === '-' && tokens[1] instanceof ValueNode) {
    tokens[1].value = -1 * tokens[1].getNumberValue();
    tokens.splice(0, 1);
  }

  const isNegativeNumberBoundary = (currentToken: string | GraphNode) => {
    if (typeof currentToken === 'string') {
      return (
        currentToken === '(' || currentToken === '/' || currentToken === '*'
      );
    }

    return currentToken instanceof CommaNode;
  };

  for (let i = 0; i < tokens.length; i++) {
    if (
      isNegativeNumberBoundary(tokens[i]) &&
      tokens[i + 1] === '-' &&
      tokens[i + 2] instanceof ValueNode
    ) {
      (tokens[i + 2] as ValueNode).value =
        (tokens[i + 2] as ValueNode).getNumberValue() * -1;
      tokens.splice(i + 1, 1);
    }
  }
  //end detect negative numbers

  return tokens;
};

const evaluateExpression = (
  expression: string,
  parserOptions?: ParserOption
) => {
  const options: ParserOption = {
    functions: {},
    resolveVariable: () => undefined,
    ...parserOptions,
  };

  const parse = (str: string): GraphNode => {
    let expTokens = extractTokens(options, str);

    expTokens = handleNegativeNumbers(expTokens);

    return wrapParentheses(options, expTokens);
  };

  const tree = parse(expression);
  return tree.compute();
};

export { evaluateExpression };
