import {
  Maybe,
  PriorAuthCodesActionModel,
  PriorAuthInsuranceStatus,
  Scalars,
} from 'graphql/graphqlTypes';
import {
  formatValidDate,
  isValidDate,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.helpers';
import { validateEndDate, validateRequired } from 'util/validationUtils';
import {
  endDateInvalidError,
  startDateInvalidError,
} from './priorAuthConstants';

const currentDate = new Date();

export const getInsuranceStatus = (
  item: Maybe<PriorAuthCodesActionModel> | undefined
) => {
  return item?.status === PriorAuthInsuranceStatus.Active
    ? 'Active'
    : 'Inactive';
};

export const changeStartDate = (
  item: PriorAuthCodesActionModel,
  date: Date | null
): PriorAuthCodesActionModel => {
  const newState: PriorAuthCodesActionModel = {
    ...item,
    reqStartDate: formatValidDate(date),
  };
  return newState;
};

export const changeEndDate = (
  item: PriorAuthCodesActionModel,
  date: Date | null
): PriorAuthCodesActionModel => {
  const newState: PriorAuthCodesActionModel = {
    ...item,
    reqEndDate: formatValidDate(date),
  };
  return newState;
};

export const changeStatus = (
  item: PriorAuthCodesActionModel,
  isInsuranceActive: boolean | null | undefined
): PriorAuthCodesActionModel => {
  item.reqStartDate = getInputDate(item.reqStartDate, true);
  item.reqEndDate = getInputDate(item.reqEndDate, false);
  const newState: PriorAuthCodesActionModel = {
    ...item,
    status: isInsuranceActive
      ? PriorAuthInsuranceStatus.Active
      : PriorAuthInsuranceStatus.Inactive,
  };
  return newState;
};

const getInputDate = (originalDate: Date, isStartDate: boolean) => {
  if (originalDate == null) {
    return isStartDate ? formatValidDate(currentDate) : null;
  }
  return originalDate;
};

export const getStartDate = (
  startDate: Maybe<Scalars['Date']>,
  isHandleStartDateChange: boolean
) => {
  if (!isHandleStartDateChange && startDate === null) {
    return new Date();
  }
  return startDate;
};

export const getStatus = (
  status: Maybe<PriorAuthInsuranceStatus> | undefined
) => (status === PriorAuthInsuranceStatus.Active ? 'Active' : 'Inactive');

export interface IValidationResult {
  hasError: boolean;
  isStartError: boolean;
  message?: string | null;
}

export const getDateError = (
  startDate: Maybe<Scalars['Date']>,
  endDate: Maybe<Scalars['Date']>,
  isHandleEndDateChange: boolean
): IValidationResult => {
  if (!isValidDate(startDate)) {
    return {
      hasError: true,
      isStartError: true,
      message: startDateInvalidError,
    };
  }
  if (!isValidDate(endDate)) {
    return {
      hasError: true,
      isStartError: false,
      message: isHandleEndDateChange ? endDateInvalidError : ``,
    };
  }
  const startReqError = validateRequired(startDate, true);
  const endReqError = validateRequired(endDate, true);
  const endValidationError = validateEndDate(startDate, endDate);
  if (endValidationError.hasError) {
    return {
      hasError: true,
      isStartError: false,
      message: endValidationError.message,
    };
  }
  if (endReqError.hasError) {
    return {
      hasError: true,
      isStartError: false,
      message: isHandleEndDateChange ? `End Date: ${endReqError.message}` : ``,
    };
  }
  if (startReqError.hasError) {
    return {
      hasError: true,
      isStartError: true,
      message: `Start Date: ${startReqError.message}`,
    };
  }
  return { hasError: false, isStartError: false, message: `` };
};
