import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {
  PATIENT_LIST_EPISODE_STATE,
  PATIENT_LIST_SHOW_ACTIVE,
} from 'components/constants';
import { IState } from 'store';
import {
  forceUpdate,
  setShowActive,
  openHomeSearch,
  updateEpisodeState,
  updateFullSearch,
  updateActiveTab,
} from 'store/home/action';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import SearchBar from 'components/searchbar/SearchBar';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import RefreshButton from 'components/refreshbutton';
import HeaderPanelActionButtons from './HeaderPanelActionButtons';
import { IRow, SearchTab } from 'store/home/types';
import { useSetPreferenceMutation } from 'graphql/hooks/setPreference';
import styled from 'styled-components';
import { StyledButtonGroupItem } from 'components/actions/sections/SectionBody/Items/HealthService/styles';
import { useUpdateLastCompletedChecklistMutation } from 'graphql/hooks/updateLastCompletedChecklist';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { RoleType } from 'store/roles/types';
import { openMultiEditDialog } from 'store/multiedit/action';
import Loader from 'components/loader';
import { createSelector } from '@reduxjs/toolkit';
import { useNewEpisodeCheck } from 'components/scenario/episode/hooks';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { useNavigate } from 'react-router';
import { IExportOption } from 'components/home/TabsBody';
import { ROUTE } from 'consts/ROUTE';
import { clearSearch } from 'store/ui/header';
import {
  getSelectedRow,
  hasEpisode,
  isFaxRowSelected,
} from './GridHeaderPanel.helper';

const StyledDivider = styled(Divider)`
  height: 24px;
  margin: 0;
`;

const StyledIconButton = styled(IconButton)<{ $activeButton: boolean }>`
  border: solid 1px ${COLORS.LIGHT_GREY};
  border-radius: 4px;
  height: 32px;
  width: 32px;
  color: ${COLORS.GREY100};
  background-color: inherit;
  ${({ $activeButton }) =>
    $activeButton &&
    `background-color: ${COLORS.PALE_SKY_BLUE};
     border-color: ${COLORS.SYMPHONY_BLUE};
     color: ${COLORS.SYMPHONY_BLUE};
     border: 1px solid ${COLORS.SYMPHONY_BLUE};
      &:enabled: {
        borderRightColor: ${COLORS.SYMPHONY_BLUE}!important,
      };
    `}
`;

const StyledSearchHeader = styled.div`
  padding-bottom: 16px;
`;

const StyledRefreshWrapper = styled.div`
  margin-left: -10px;
`;

const StyledReturnHomeButton = styled(Button)`
  padding: 0,
  margin: 0,
  margin-bottom: 10px,
`;

export const homeModelSelector = createSelector(
  (state: IState) => state.home.homeModel,
  (model) => model
);

export const homeGridData = createSelector(
  (state: IState) => state.home.data,
  (model) => model
);

const GridHeaderPanel = ({
  handleSetLoadingComplete,
  handleTriggerExportGridData,
}: {
  handleSetLoadingComplete: (loadingComplete: boolean) => void;
  handleTriggerExportGridData: (exportOptions: IExportOption) => void;
}) => {
  const roleType = useSelector((state: IState) => state.user.currentUser.type);
  const homeModel = useSelector(homeModelSelector);
  const gridData = useSelector(homeGridData);
  const selection = useSelector(
    (state: IState) => state.home?.homeModel?.selection ?? []
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let patientId = 0;
  let episodeId = 0;
  if (homeModel && homeModel?.selection?.length === 1) {
    const obj = getSelectedRow(gridData, selection[0]) as IRow;
    patientId = obj?.patientId ?? obj?.id;
    episodeId = obj?.episodeId ?? 0;
  }

  const selectedGridData = getSelectedRow(gridData, selection[0]);
  const canUpdateRow =
    selection?.length === 1 &&
    selectedGridData?.CanUpdate &&
    !selectedGridData?.IsMozart;

  const isFaxRow = isFaxRowSelected(gridData, selection);

  const patientSelected = !!(
    homeModel?.selection && homeModel?.selection.length === 1
  );
  const patientMultiSelected = !!(
    homeModel?.selection && homeModel?.selection.length > 0
  );
  const isEditDisabled = !(
    patientMultiSelected && hasEpisode(gridData, selection)
  );

  const [setUserPreference] = useSetPreferenceMutation();
  const onChangeEpisodeState = (state: number) => {
    if (state !== homeModel?.episodeState) {
      handleSetLoadingComplete(true);

      const tabName =
        homeModel?.tabs.find((x) => x.index === homeModel?.activeTab)?.name ??
        '';
      dispatch(updateEpisodeState(state));
      setUserPreference({
        name: PATIENT_LIST_EPISODE_STATE + '|' + roleType + '|' + tabName,
        value: state.toString(),
      });
    }
  };

  const onSetActive = (state: boolean) => {
    handleSetLoadingComplete(true);
    dispatch(setShowActive(state ? true : null));
    setUserPreference({
      name: PATIENT_LIST_SHOW_ACTIVE,
      value: state ? 'true' : '',
    });
  };

  const [
    updateLastChecklist,
    {
      isLoading: isUpdatingChecklist,
      isSuccess: isSuccessUpdateChecklist,
      data: updateChecklistResult,
    },
  ] = useUpdateLastCompletedChecklistMutation();

  useEffect(() => {
    if (isSuccessUpdateChecklist) {
      if (
        updateChecklistResult &&
        updateChecklistResult.updateLastCompletedChecklist &&
        updateChecklistResult.updateLastCompletedChecklist.success
      ) {
        navigate(
          `/Checklist/Actions/${updateChecklistResult?.updateLastCompletedChecklist?.checklistId}`
        );
      } else {
        dispatch(
          showErrorPopup({
            message:
              updateChecklistResult?.updateLastCompletedChecklist?.error ??
              'Error on updating last checklist',
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdateChecklist]);

  const {
    checkNewEpisode,
    checkNewEpisodeIsFetching,
    confirmation: newEpisodeConfirmation,
  } = useNewEpisodeCheck();

  const handleNewScenario = () => {
    if (selection.length === 1) {
      const selectedPatientId =
        selectedGridData?.patientId ?? selectedGridData?.id;
      const selectedEpisodeId =
        roleType === RoleType.NONCLINICAL
          ? 0
          : selectedGridData?.episodeId ?? 0;

      if (selectedPatientId) {
        checkNewEpisode(selectedPatientId, selectedEpisodeId);
      }
    }
  };

  const handleUpdateLastChecklist = () => {
    if (selection.length === 1) {
      const selectedPatientId =
        selectedGridData?.patientId ?? selectedGridData?.id;
      if (selectedPatientId) {
        const checklist =
          roleType === RoleType.CLINICAL
            ? { patientId: selectedPatientId }
            : {
                patientId: selectedPatientId,
                episodeId: selectedGridData?.episodeId,
              };
        updateLastChecklist(checklist);
      }
    }
  };

  const handleMultiEdit = () => {
    if (selection && selection.length > 0) {
      const patientIds = [] as number[];
      const episodeIds = [] as number[];
      selection.forEach((select) => {
        const obj = getSelectedRow(gridData, select) as IRow;
        const selectedPatientId = obj.patientId ?? obj.id;
        patientIds.push(selectedPatientId);
        if (obj.episodeId) {
          episodeIds.push(obj.episodeId);
        }
      });
      dispatch(openMultiEditDialog(patientIds, episodeIds));
    }
  };

  const handleSearch = (searchTerm: string) => {
    dispatch(updateFullSearch(searchTerm));
    handleSetLoadingComplete(true);
  };

  const handleReturnHome = () => {
    dispatch(openHomeSearch(''));
    dispatch(updateActiveTab(0));
    dispatch(clearSearch());
    navigate(ROUTE.Home);
  };

  const renderSearch = () => {
    return (
      <>
        {homeModel?.activeTab !== SearchTab && (
          <>
            <StyledDivider orientation="vertical" />
            <SearchBar placeholder="Search" onSubmit={handleSearch} />
          </>
        )}
      </>
    );
  };
  const renderSearchHeader = () => {
    return (
      <>
        {homeModel.activeTab === SearchTab && (
          <div>
            <Box color={COLORS.GREY100}>
              <StyledReturnHomeButton
                onClick={handleReturnHome}
                color="inherit"
                data-cy="return-home"
                size="small"
                startIcon={
                  <Icon icon={ICONS.Back} size={12} color={COLORS.GREY100} />
                }
              >
                Return Home
              </StyledReturnHomeButton>
            </Box>

            <StyledSearchHeader>
              <Typography variant="subtitle1">
                Search results ({gridData?.count ?? 0})
              </Typography>
            </StyledSearchHeader>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {renderSearchHeader()}
      <Box display="flex" alignItems="center" gap="16px" mb="16px">
        <Box display="flex">
          <CustomTooltip title="New">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleNewScenario}
              disabled={!patientSelected || isFaxRow}
              style={{ marginRight: '16px' }}
              startIcon={
                <Icon icon={ICONS.Add} size={14} color={COLORS.WHITE} />
              }
              data-cy="new-encounter"
            >
              New
            </Button>
          </CustomTooltip>
          <CustomTooltip title="Creates new document transferring the maximum information possible from the original">
            <Button
              variant="outlined"
              size="small"
              data-cy="update-patients"
              onClick={handleUpdateLastChecklist}
              disabled={
                roleType === RoleType.CLINICAL ||
                !canUpdateRow ||
                !patientSelected ||
                isFaxRow
              }
            >
              Update
            </Button>
          </CustomTooltip>
        </Box>

        <HeaderPanelActionButtons
          onMultiEdit={handleMultiEdit}
          patientSelected={patientSelected}
          isEditDisabled={isEditDisabled}
          patientId={patientId}
          episodeId={episodeId}
          isFaxRow={isFaxRow}
          handleTriggerExportGridData={handleTriggerExportGridData}
        />
        <StyledDivider orientation="vertical" />
        <StyledRefreshWrapper>
          <RefreshButton onClick={() => dispatch(forceUpdate(true))} />
        </StyledRefreshWrapper>

        <Box display="flex" alignItems="center" marginLeft="auto">
          <Box display="flex" alignItems="center" mr="16px">
            <Typography style={{ marginRight: '16px' }}>Show:</Typography>
            <CustomTooltip title="Patient active filter">
              <StyledIconButton
                aria-label="active"
                $activeButton={Boolean(homeModel?.settings?.showActive)}
                onClick={() => onSetActive(!homeModel?.settings?.showActive)}
              >
                <Icon
                  icon={ICONS.ActivePatient}
                  size="16"
                  color={
                    homeModel?.settings?.showActive
                      ? COLORS.SYMPHONY_BLUE
                      : COLORS.GREY100
                  }
                />
              </StyledIconButton>
            </CustomTooltip>
          </Box>
          <ButtonGroup aria-label="outlined primary button group">
            <StyledButtonGroupItem
              variant="outlined"
              size="small"
              onClick={() => onChangeEpisodeState(0)}
              $activeButton={homeModel.episodeState === 0}
              data-cy="open-episodes"
            >
              <CustomTooltip title="Show open episodes">
                <div>Open</div>
              </CustomTooltip>
            </StyledButtonGroupItem>
            <StyledButtonGroupItem
              variant="outlined"
              size="small"
              onClick={() => onChangeEpisodeState(1)}
              $activeButton={homeModel.episodeState === 1}
              data-cy="closed-episodes"
            >
              <CustomTooltip title="Show closed episodes">
                <div>Closed</div>
              </CustomTooltip>
            </StyledButtonGroupItem>
            <StyledButtonGroupItem
              variant="outlined"
              size="small"
              onClick={() => onChangeEpisodeState(-1)}
              $activeButton={homeModel.episodeState === -1}
              data-cy="all-episodes"
            >
              <CustomTooltip title="Show all episodes">
                <div>All</div>
              </CustomTooltip>
            </StyledButtonGroupItem>
          </ButtonGroup>
        </Box>

        {renderSearch()}
      </Box>

      {newEpisodeConfirmation}
      <Loader active={isUpdatingChecklist || checkNewEpisodeIsFetching} />
    </>
  );
};

export default GridHeaderPanel;
