import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeDocumentsQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
  viewType?: Types.InputMaybe<Types.ChecklistViewType>;
}>;

export type GetEpisodeDocumentsQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeDocuments?: Array<{
    __typename?: 'EpisodesTabChecklist';
    containsAttachments?: boolean | null;
    operations?: Array<Types.ChecklistOperation | null> | null;
    checklist?: {
      __typename?: 'Checklist';
      author?: number | null;
      createdOn?: any | null;
      entryStep?: string | null;
      finishedBy?: number | null;
      finishedOn?: any | null;
      hasDocument?: boolean | null;
      id: number;
      status?: Types.ChecklistStatus | null;
      timesPrintRequested?: number | null;
      type?: Types.ChecklistType | null;
      attachments?: Array<{
        __typename?: 'Attachment';
        id: number;
        isDeleted: boolean;
        isVisible: boolean;
        name?: string | null;
      } | null> | null;
      authorUser?: {
        __typename?: 'User';
        email?: string | null;
        firstName?: string | null;
        id: number;
        lastName?: string | null;
        name?: string | null;
        phone?: string | null;
        prefix?: string | null;
        suffix?: string | null;
      } | null;
      createdByUser?: {
        __typename?: 'User';
        createdBy: number;
        createdOn?: any | null;
        credentials?: string | null;
        email?: string | null;
        firstName?: string | null;
        fullName?: string | null;
        id: number;
        lastName?: string | null;
        login?: string | null;
        name?: string | null;
        phone?: string | null;
        prefix?: string | null;
        suffix?: string | null;
        updatedBy?: number | null;
        updatedOn?: any | null;
      } | null;
      sourceChecklist?: {
        __typename?: 'Checklist';
        author?: number | null;
        createdBy: number;
        createdOn?: any | null;
        entryStep?: string | null;
        hasDocument?: boolean | null;
        id: number;
        status?: Types.ChecklistStatus | null;
        timesPrintRequested?: number | null;
        type?: Types.ChecklistType | null;
      } | null;
    } | null;
    checklistOrderSets?: Array<{
      __typename?: 'ChecklistOrderSetRowView';
      backcolor?: string | null;
      details?: string | null;
      forecolor?: string | null;
      name?: string | null;
      title?: string | null;
    } | null> | null;
  } | null> | null;
};

export const GetEpisodeDocumentsDocument = `
    query getEpisodeDocuments($episodeId: Int!, $viewType: ChecklistViewType) {
  getEpisodeDocuments(episodeId: $episodeId, viewType: $viewType) {
    checklist {
      attachments {
        id
        isDeleted
        isVisible
        name
      }
      author
      authorUser {
        email
        firstName
        id
        lastName
        name
        phone
        prefix
        suffix
      }
      createdByUser {
        createdBy
        createdOn
        credentials
        email
        firstName
        fullName
        id
        lastName
        login
        name
        phone
        prefix
        suffix
        updatedBy
        updatedOn
      }
      createdOn
      entryStep
      finishedBy
      finishedOn
      hasDocument
      id
      sourceChecklist {
        author
        createdBy
        createdOn
        entryStep
        hasDocument
        id
        status
        timesPrintRequested
        type
      }
      status
      timesPrintRequested
      type
    }
    containsAttachments
    operations
    checklistOrderSets {
      backcolor
      details
      forecolor
      name
      title
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeDocuments: build.query<
      GetEpisodeDocumentsQuery,
      GetEpisodeDocumentsQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeDocumentsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeDocumentsQuery, useLazyGetEpisodeDocumentsQuery } =
  injectedRtkApi;
