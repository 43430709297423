import { Grid } from '@mui/material';
import React, { CSSProperties } from 'react';
import { Attachment } from 'graphql/graphqlTypes';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import ActionLink from 'components/actionlink';
import styled from 'styled-components';

export interface IDocumentAttachmentsProps {
  style?: CSSProperties;
  attachments: Attachment[];
}

const StyledWrapper = styled.div`
  color: black;
  min-width: 200px;
`;

const DocumentAttachments = (props: IDocumentAttachmentsProps) => {
  return (
    <StyledWrapper style={props.style}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={2} style={{ fontWeight: 'bold' }}>
          Type
        </Grid>
        <Grid item xs={10} style={{ fontWeight: 'bold' }}>
          File
        </Grid>
        {props?.attachments &&
          props?.attachments.length &&
          props?.attachments.map((item: Attachment, itemIndex: number) => (
            <>
              <Grid item xs={2} key={`t${itemIndex}`}>
                <Icon
                  icon={ICONS.Document}
                  size={16}
                  color={COLORS.SYMPHONY_BLUE}
                />
              </Grid>
              <Grid item xs={10} key={`n${itemIndex}`}>
                <ActionLink
                  key={`AttachmentView${item.id}`}
                  type="AttachmentView"
                  entityId={Number(item.id)}
                  fileName={item.name ?? ''}
                  label={item?.name?.toString()}
                />
              </Grid>
            </>
          ))}
      </Grid>
    </StyledWrapper>
  );
};

export default DocumentAttachments;
