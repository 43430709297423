import React, { useState } from 'react';
import { Box, Button, TableCell } from '@mui/material';

import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';

import Confirmation from 'components/modal/Confirmation';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { updateMedicationsList } from 'store/patientdetails/patientDetailsSlice';

export const ActionsCell = ({ row }: ILabelCellProps) => {
  const [
    showDeleteMedicationConfirmation,
    setShowDeleteMedicationConfirmation,
  ] = useState(false);

  const medsList = useSelector(
    (state: IState) => state.patientDetails.medicationsTab.medicationsList
  );
  const dispatch = useDispatch();

  const deleteMedication = (id: number) => {
    const deletedRow = medsList!.find((item) => item.id === id);
    dispatch(
      updateMedicationsList({ medications: [deletedRow!], isDelete: true })
    );
  };

  return (
    <TableCell
      style={{ ...CellComponentStyle }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          color="primary"
          variant="text"
          onClick={() => setShowDeleteMedicationConfirmation(true)}
        >
          Delete
        </Button>

        <Confirmation
          open={showDeleteMedicationConfirmation}
          title="Delete Medication?"
          okButtonText="Delete"
          okEvent={() => {
            setShowDeleteMedicationConfirmation(false);
            deleteMedication(row.id);
          }}
          cancelEvent={() => setShowDeleteMedicationConfirmation(false)}
        >
          <span>Are you sure you want to Remove this Medication?</span>
        </Confirmation>
      </Box>
    </TableCell>
  );
};
