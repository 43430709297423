import { SerializedError } from '@reduxjs/toolkit';
import { SelectActionRequestName } from 'components/failedRequests/FailedRequests';
import { SelectActionModelInput } from 'graphql/graphqlTypes';
import {
  SelectActionMutation,
  useSelectActionMutation,
} from 'graphql/hooks/selectAction';
import { useDispatch } from 'react-redux';
import {
  addFailedRequest,
  removeFailedRequest,
} from 'store/failedRequests/failedRequestsSlice';

export const useSelectAction = () => {
  const dispatch = useDispatch();
  const [selectChecklistItemImmediately, { isSuccess }] =
    useSelectActionMutation();

  const selectActionExtended = (
    values: SelectActionModelInput,
    idToRetry: string
  ) => {
    selectChecklistItemImmediately({ data: values }).then(
      (
        value:
          | { data: SelectActionMutation }
          | { error: unknown | SerializedError }
      ) => {
        const responseData = value as { data: SelectActionMutation };
        if (responseData.data && responseData.data.selectAction && idToRetry) {
          dispatch(removeFailedRequest(idToRetry));
          return;
        }

        const responseError = value as {
          error: unknown | SerializedError;
        };
        if (responseError.error && idToRetry) {
          dispatch(
            addFailedRequest({
              name: SelectActionRequestName,
              idToRetry: idToRetry,
              variables: values,
            })
          );
        }
      }
    );
  };

  return { selectActionExtended, isSuccess };
};
