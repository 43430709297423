import {
  MozartActionType,
  MozartProgressState,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import { reduce, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';

export type CompressedMozartWorkflowUiModel = MozartWorkflowUiModel & {
  groupedMessages: MozartWorkflowUiModel[];
};

const isRoutingStep = (val: MozartWorkflowUiModel) =>
  [
    MozartActionType.JumpTo,
    MozartActionType.JumpToStep,
    MozartActionType.JumpToPreviousStep,
    MozartActionType.DuplicateCheck,
  ].includes(val.actionType) || val.state === MozartProgressState.Skipped;

const isPauseStep = (val: MozartWorkflowUiModel) =>
  [MozartActionType.PauseWorkflow].includes(val.actionType);

const compressSteps = (
  isDesiredMessage: (msg: MozartWorkflowUiModel) => boolean,
  message: MozartWorkflowUiModel,
  acc: CompressedMozartWorkflowUiModel[]
): CompressedMozartWorkflowUiModel[] | null => {
  const { length: l } = acc;
  if (isDesiredMessage(message)) {
    const previous = acc[l - 1];
    if (!previous) {
      return [{ ...message, groupedMessages: [{ ...message }] }];
    } else if (isDesiredMessage(previous)) {
      previous.groupedMessages = [
        ...(previous.groupedMessages ?? []),
        { ...message },
      ];
      return acc;
    }
    return acc.concat({
      ...message,
      groupedMessages: [{ ...message }],
    });
  }
  return null;
};
export const getCompressedMessages = (
  msg: MozartWorkflowUiModel[]
): CompressedMozartWorkflowUiModel[] =>
  reduce(
    uniqBy(msg, (m) => m.scopeId),
    (acc, message) => {
      const routingSteps = compressSteps(isRoutingStep, message, acc);
      if (routingSteps) {
        return routingSteps;
      }
      const pauseSteps = compressSteps(isPauseStep, message, acc);
      if (pauseSteps) {
        return pauseSteps;
      }

      return acc.concat({ ...message } as CompressedMozartWorkflowUiModel);
    },
    [] as CompressedMozartWorkflowUiModel[]
  );

export const useUserNotAuthorizedToStartChecklist = (
  message?: MozartWorkflowUiModel
) => {
  const currentUserRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const stepRoles = message?.roles ?? [];
  const userNotAuthorizedToStartChecklist =
    stepRoles.length > 0 && !stepRoles.includes(currentUserRoleId);

  if (!hasUserPrivilege(Privilege.ShowMozartRoutingSteps)) {
    return { userNotAuthorizedToStartChecklist, stepRoles: [] };
  }

  return { userNotAuthorizedToStartChecklist, stepRoles };
};

export const toBoolean = (value: string | null) => {
  if (!value) {
    return false;
  }

  switch (value.toLowerCase()) {
    case 'true':
    case '1':
    case 'yes':
      return true;
  }

  return false;
};
