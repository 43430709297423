import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { IState } from 'store';
import { Privilege } from 'store/roles/types';
import { ChecklistStorageType } from 'store/actions/types';
import {
  updateEpisodeSummaryDirtyState,
  clearEpisodeSummaryDirtyState,
} from 'store/ui/dirtyConfirmationModal/episodeSummary';
import { useChecklistForSidebar } from 'hooks/useChecklistForSidebar';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { useLazyGetFormattedNoteAutocompleteTextQuery } from 'graphql/hooks/getFormattedNoteAutocompleteText';
import { useGetEpisodeSummaryQuery } from 'graphql/hooks/getEpisodeSummary';
import {
  UpdateEpisodeSummaryMutation,
  useUpdateEpisodeSummaryMutation,
} from 'graphql/hooks/updateEpisodeSummary';
import Panel from 'components/panel';
import { ICONS } from 'components/icon';
import Loader from 'components/loader';
import ActionButton from 'components/scenario/ActionButton';
import MultiLineText from 'components/text/MultiLineText';
import AutocompleteInput from 'components/actions/sections/SectionBody/Components/UserFreeText/autocompleteInput';
import EpisodeSummaryDialog from './EpisodeSummaryDialog';

export interface IEpisodeSummaryProps {
  storageType: ChecklistStorageType;
}

const StyledHeader = styled.div`
  padding: 14px 2px 0px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledError = styled.div`
  padding: 0px 2px 9px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: red;
`;
export const StyledBody = styled.div`
  padding: 0px 2px 8px 14px;
  display: flex;
  justify-content: space-between;
`;

const StyledWrapper = styled.div`
  margin-bottom: 10px;
`;

const StyledScrollableSection = styled.div`
  height: auto;
  max-height: 10vh;
  overflow-y: auto;
`;

export const SummaryInputProps = {
  style: {
    height: '170px',
  },
};

const ViewWrapper = styled(Box)({
  width: '100%',
  maxWidth: 293,
  paddingRight: '14px',
});
export const EditableActions = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  mt: 0.5,
  gap: 0.5,
});
export interface IMessageState {
  active: boolean;
  text: string;
}

export const renderSaveAndCancelButtons = (
  handleCancel: () => void,
  handleSave: () => void,
  episodeSummaryPrivilege: boolean,
  iconSize: number
) => {
  return (
    <EditableActions>
      <ActionButton
        handleClick={handleCancel}
        icon={ICONS.Close}
        iconSize={iconSize}
        testId="summary-cancel-icon"
        tooltipText="Cancel Summary"
        text={''}
        disabled={!episodeSummaryPrivilege}
      />
      <ActionButton
        handleClick={handleSave}
        icon={ICONS.Checkmark}
        iconSize={iconSize}
        testId="summary-save-icon"
        tooltipText="Save Summary"
        text={''}
        disabled={!episodeSummaryPrivilege}
      />
    </EditableActions>
  );
};

const EpisodeSummary = (props: IEpisodeSummaryProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState<IMessageState | null>();
  const [summary, setSummary] = useState('');
  const [draftSummary, setDraftSummary] = useState('');
  const [selectedItem, setSelectedItem] = useState<string>();
  const [isSummaryDialogOpen, setIsSummaryDialogOpen] = useState(false);
  const { storageType } = props;
  const { checklist } = useChecklistForSidebar(storageType);
  const episodeId = (checklist?.episodeId as number) ?? 0;
  const dispatch = useDispatch();
  const isReadOnlyWorkflow = useSelector(
    (state: IState) => state.mozart.isReadOnlyWorkflow
  );
  const isReadOnly =
    storageType === ChecklistStorageType.MOZART && isReadOnlyWorkflow;
  const {
    data,
    isFetching: isFetchingDetails,
    refetch: refetchEpisodeSummary,
  } = useGetEpisodeSummaryQuery(
    {
      episodeId: episodeId,
    },
    { skip: episodeId <= 0, refetchOnMountOrArgChange: true }
  );
  const [updateEpisodeSummary, { isLoading: isEpisodeSummaryLoading }] =
    useUpdateEpisodeSummaryMutation();
  const [
    getFormattedNoteAutocompleteText,
    {
      data: formattedNoteAutocompleteTextResult,
      isLoading: isGetFormattedTextLoading,
      isSuccess: isGetFormattedTextSuccess,
      isFetching: isGetFormattedTextFetching,
    },
  ] = useLazyGetFormattedNoteAutocompleteTextQuery();
  const episodeSummaryState = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary
  );
  const isEpisodeSummaryDirty = episodeSummaryState.isDirty;
  const shouldPersistEditable = episodeSummaryState.persistEditable;
  const episodeSummaryPrivilege = hasUserPrivilege(
    Privilege.AllowSeeEpisodeSummary
  );

  useEffect(() => {
    if (!isFetchingDetails && data?.getEpisodeSummary?.summary) {
      setSummary(data.getEpisodeSummary.summary);
    }
  }, [data?.getEpisodeSummary?.summary, isFetchingDetails]);

  useEffect(() => {
    if (!isEpisodeSummaryDirty) {
      setIsEditing(shouldPersistEditable);
    }
  }, [isEpisodeSummaryDirty, shouldPersistEditable]);

  useEffect(() => {
    if (!isGetFormattedTextFetching && isGetFormattedTextSuccess) {
      const formattedText =
        formattedNoteAutocompleteTextResult?.getFormattedNoteAutocompleteText ??
        '';
      if (selectedItem) {
        // Search value in the below replace method is based on the trigger condition in AutocompleteInput component
        const val = draftSummary.replace(`.${selectedItem}. `, formattedText);
        setDraftSummary(val);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isGetFormattedTextFetching,
    isGetFormattedTextSuccess,
    formattedNoteAutocompleteTextResult,
  ]);

  const handleEditClick = () => {
    setDraftSummary(summary);
    updateMessage(false, '');
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    updateMessage(false, '');

    if (validateSummary(draftSummary)) {
      updateEpisodeSummary({ episodeId: episodeId, summary: draftSummary })
        .then((data) => {
          const result = data as { data: UpdateEpisodeSummaryMutation };
          if (!result?.data?.updateEpisodeSummary?.success) {
            updateMessage(
              true,
              result.data.updateEpisodeSummary?.message ?? ''
            );
          }
          setSummary(draftSummary);
          setIsEditing(false);
          dispatchDirty(false);
        })
        .catch(() => {
          const errorText = 'Failed to save episode summary. Please try again.';
          updateMessage(true, errorText);
        });
    }
  };

  const updateMessage = (active: boolean, text: string) => {
    setMessage({
      active: active,
      text: text,
    });
  };

  const setSummaryDialogState = (open: boolean) => {
    setIsSummaryDialogOpen(open);
  };

  const validateSummary = (summary: string) => {
    if (summary.length === 0) {
      updateMessage(true, 'Summary cannot be empty');
      return false;
    }
    if (summary.length > 4000) {
      updateMessage(true, 'Summary cannot exceed 4000 characters');
      return false;
    }
    return true;
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    updateMessage(false, '');
    dispatchDirty(false);
  };

  const handleEpisodeSummaryDialog = () => {
    if (!episodeSummaryPrivilege) {
      return;
    }

    setSummaryDialogState(true);
  };

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const summaryText = event.target.value;
    setDraftSummary(summaryText);
    updateMessage(false, '');
    dispatchDirty(summaryText.trim() !== summary.trim(), true);
  };

  const onItemSelected = (value: string) => {
    setSelectedItem(value);
    getFormattedNoteAutocompleteText({
      episodeId: episodeId,
      searchText: value,
    });
  };

  const dispatchDirty = (isDirty: boolean, persistEditable?: boolean) => {
    if (isDirty) {
      dispatch(
        updateEpisodeSummaryDirtyState({
          episodeId: episodeId ?? 0,
        })
      );
    } else {
      dispatch(
        clearEpisodeSummaryDirtyState({
          persistEditable: persistEditable ?? false,
        })
      );
    }
  };

  const iconSize = 20;

  const renderHistoryButton = () => {
    return (
      <ActionButton
        handleClick={handleEpisodeSummaryDialog}
        icon={ICONS.Expand}
        iconSize={iconSize}
        testId="summary-history-dialog"
        tooltipText="Show History"
        text={''}
        disabled={!episodeSummaryPrivilege}
      />
    );
  };

  return (
    <StyledWrapper>
      <Panel padding="0px 0px 10px 0px">
        <StyledHeader>
          <Typography variant="h4">Episode Summary</Typography>
          {!isReadOnly && !isEditing && (
            <EditableActions>
              {renderHistoryButton()}
              <ActionButton
                handleClick={handleEditClick}
                icon={ICONS.EditAlt}
                iconSize={iconSize}
                testId="summary-edit-icon"
                tooltipText="Edit Summary"
                text={''}
                disabled={!episodeSummaryPrivilege}
              />
            </EditableActions>
          )}

          {!isReadOnly &&
            isEditing &&
            renderSaveAndCancelButtons(
              handleCancelClick,
              handleSaveClick,
              episodeSummaryPrivilege,
              iconSize
            )}
        </StyledHeader>

        <StyledError>
          <Typography>{message?.active ? message?.text : ''}</Typography>
        </StyledError>
        <StyledBody>
          <ViewWrapper>
            {!isEditing ? (
              <Typography variant="body1">
                <StyledScrollableSection>
                  <MultiLineText text={summary} />
                </StyledScrollableSection>
              </Typography>
            ) : (
              <AutocompleteInput
                key="episodeSummary"
                dataTestid="TextField_episodeSummary"
                disabled={false}
                error={false}
                inputProps={SummaryInputProps}
                value={draftSummary}
                onChange={onInputChange}
                onBlur={() => {
                  return;
                }}
                onItemSelected={onItemSelected}
              />
            )}
          </ViewWrapper>
        </StyledBody>
      </Panel>
      {isSummaryDialogOpen != undefined && isSummaryDialogOpen ? (
        <EpisodeSummaryDialog
          open={isSummaryDialogOpen}
          onClose={() => setSummaryDialogState(false)}
          episodeId={episodeId ?? 0}
          storageType={storageType}
          refetchEpisodeSummary={refetchEpisodeSummary}
        />
      ) : null}
      <Loader
        active={
          isEpisodeSummaryLoading ||
          isFetchingDetails ||
          isGetFormattedTextLoading
        }
      />
    </StyledWrapper>
  );
};

export default EpisodeSummary;
