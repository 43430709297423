import React, { useRef } from 'react';
import Panel from 'components/panel';
import { Typography } from '@mui/material';
import { ChecklistCategory } from 'graphql/graphqlTypes';
import NavBarLink from './NavbarLink';
import styled from 'styled-components';

import MenuLegend from './MenuLegend';

import CollapsableSections from 'components/actions/navbar/CollapsableSections';
import { ChecklistStorageType } from 'store/actions/types';
import { useChecklistForSidebar } from 'hooks/useChecklistForSidebar';
import {
  isCompletedCategory,
  isVisibleCategory,
} from 'components/actions/navbar/navbar.helpers';

const StyledNavHeader = styled.div`
  padding: 24px 24px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledScrollableSection = styled.div`
  height: auto;
  max-height: 40vh;
  overflow-y: auto;
`;

const StyledNavbarWrapper = styled.div`
  margin-bottom: 120px;
`;

export interface INavbarProps {
  isReview?: boolean;
  storageType: ChecklistStorageType;
}

const NavBar = (props: INavbarProps) => {
  const { isReview, storageType } = props;
  const { checklist } = useChecklistForSidebar(storageType);

  const ref = useRef<HTMLDivElement | null>(null);

  const carePlanCategory = {
    id: 'careplan',
    name: 'Care Plan',
  } as ChecklistCategory;

  let categoryIndex = 0;
  const navContainerRef = useRef<HTMLDivElement>(null);

  if (!checklist) {
    return null;
  }

  return (
    <StyledNavbarWrapper>
      <Panel padding="0px 0px 24px 0px" navigation={true}>
        <StyledScrollableSection ref={navContainerRef}>
          {storageType !== ChecklistStorageType.MOZART ? (
            <>
              <StyledNavHeader>
                <Typography variant="h4">Navigation</Typography>
                <div ref={ref}>
                  <MenuLegend ref={ref} />
                </div>
              </StyledNavHeader>
              {checklist?.categories?.map(
                (category) =>
                  (isReview
                    ? isCompletedCategory(category)
                    : isVisibleCategory(category)) && (
                    <NavBarLink
                      key={categoryIndex++}
                      categoryIndex={categoryIndex}
                      category={category}
                      storageType={storageType}
                    />
                  )
              )}
              {checklist?.includeCarePlan && (
                <NavBarLink
                  key={categoryIndex++}
                  categoryIndex={categoryIndex}
                  category={carePlanCategory}
                  storageType={storageType}
                />
              )}
            </>
          ) : (
            <CollapsableSections
              storageType={storageType}
              ref={navContainerRef}
              isReview={isReview}
            />
          )}
        </StyledScrollableSection>
      </Panel>
    </StyledNavbarWrapper>
  );
};

export default NavBar;
