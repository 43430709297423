import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '../backgroundTab/formControl';
import { COLORS } from 'consts/styles';
import MenuItem from '@mui/material/MenuItem';
import { ILookupValue } from 'backend/types/lookupValue';
import { suggestEntities } from 'store/patientdetails/middlewares';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { StatusSelect } from './components';
import {
  updateDirtyTabs,
  updateMedicationsList,
  updateSelectedMedicationItem,
  updateClearPatientDetailsDialog,
} from 'store/patientdetails/patientDetailsSlice';
import RemoteAutocomplete from 'components/autocomplete/RemoteAutocomplete';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { patientUseText } from './consts';
import { getAvailableId } from 'components/home/attachmentsList/attachments.helpers';
import { useLazyGetPharmaciesQuery } from 'graphql/hooks/getPharmacies';
import {
  MedicationAttributeTypesViewModel,
  MedicationViewModel,
  PatientMedicationPatientUse,
  PatientMedicationStatus,
  PatientMedicationType,
} from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { camelCase } from 'lodash';
import { ErrorFormControl } from './components/ErrorFormControl';
import DateComponent from 'components/DateComponent';

export const StyledTextField = styled(TextField)({
  fontSize: '14px',
  '& .Mui-disabled': {
    color: COLORS.GREY100,
    backgroundColor: COLORS.GREY4,
    '& .MuiSelect-icon': {
      color: COLORS.GREY60,
    },
  },
});

export const StyledErrorMessage = styled(Typography)({
  color: COLORS.RED100,
  paddingTop: '4px',
  fontWeight: 400,
  fontSize: 12,
});

// working around MUI, components don't work properly if undefined is passed on first render.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialValues: any = (isPrescriptionForm: boolean) => {
  return {
    id: null,
    startDate: null,
    endDate: null,
    dateFilled: null,
    patientState: PatientMedicationStatus.Active,
    medication: '',
    instructions: '',
    quantityFilled: '',
    refills: null,
    mdRecommended: null,
    pharmacy: null,
    pharmacyPhone: null,
    pharmacyFax: null,
    providerId: null,
    providerName: null,
    reason: '',
    source: null,
    patientUse: isPrescriptionForm
      ? PatientMedicationPatientUse.TakesasPrescribed
      : PatientMedicationPatientUse.MemberDirected,
    note: '',
  };
};

interface IPrescriptionFormProps {
  isPrescriptionForm: boolean;
  isNew: boolean;
}

export const getRequiredFields = (
  medicationsAttributeDef: MedicationAttributeTypesViewModel[],
  isPrescriptionForm: boolean
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requiredFields: any = medicationsAttributeDef
    .filter((x) => x.required)
    .reduce(
      (o, key) => ({
        ...o,
        [camelCase(key.name)]: Yup.string().required(
          `${key.displayName} is required`
        ),
      }),
      {}
    );

  if (isPrescriptionForm) {
    delete requiredFields.reason;
    delete requiredFields.source;
  } else {
    delete requiredFields.quantityFilled;
    delete requiredFields.refills;
    delete requiredFields.dateFilled;
    delete requiredFields.pharmacyFax;
    delete requiredFields.pharmacyPhone;
    delete requiredFields.provider;
    delete requiredFields.pharmacy;
  }
  return requiredFields;
};

const PrescriptionForm = ({
  isPrescriptionForm,
  isNew,
}: IPrescriptionFormProps) => {
  const {
    medicationsList,
    medicationsAttributeDef,
    selectedMedicationItems: items,
  } = useSelector((state: IState) => state.patientDetails.medicationsTab);
  const formData = items
    ? medicationsList?.find((item) => items.length && item.id === items[0])
    : null;
  const [isMdRecommended, setIsMdRecommended] = useState<null | boolean>(
    isNew ? null : formData?.mdRecommended ?? null
  );

  const requiredFields = getRequiredFields(
    medicationsAttributeDef,
    isPrescriptionForm
  );

  const validationSchema = Yup.object().shape(requiredFields);

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues(isPrescriptionForm),
  };
  const {
    setValue,
    control,
    trigger,
    reset,
    formState: { errors },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<any>(formOptions);

  const formValues = useWatch({
    control,
  });

  const [medicationSearch, setMedicationSearch] = useState('');
  const [providerSearch, setProviderSearch] = useState('');
  const [providers, setProviders] = useState<ILookupValue[]>([]);

  const dispatch = useDispatch();

  const closeForm = () => {
    dispatch(updateSelectedMedicationItem({ selectedActionType: '' }));
  };

  const checkIsMdRecommended = (mdRecommended: unknown) => {
    if (mdRecommended === 'true') {
      return true;
    }
    if (mdRecommended === 'false') {
      return false;
    }
    return mdRecommended;
  };
  const handleSave = async (isSaveAndExit: boolean) => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { providerId, providerName, mdRecommended, ...rest } = formValues;

    const model = {
      ...rest,
      type: isPrescriptionForm
        ? PatientMedicationType.Prescription
        : PatientMedicationType.Otc,
      provider: {
        id: providerId,
        name: providerName,
      },
      mdRecommended: checkIsMdRecommended(mdRecommended),
    };

    dispatch(
      updateMedicationsList({
        medications: [model] as MedicationViewModel[],
        isUpdate: true,
      })
    );
    dispatch(updateDirtyTabs('Meds'));
    dispatch(updateClearPatientDetailsDialog(isSaveAndExit));

    if (isSaveAndExit) {
      closeForm();
    }
  };

  const getDefaultValue = (field: string) =>
    field.toLocaleLowerCase().includes('date') ? null : '';

  const loadDataIntoForm = () => {
    if (!formData) {
      return;
    }
    for (const [key, value] of Object.entries(formData)) {
      if (initialValues(isPrescriptionForm)[key] !== undefined) {
        setValue(key, value === null ? getDefaultValue(key) : value);
      }
    }
    setIsMdRecommended(formData.mdRecommended ?? null);
    setMedicationSearch(formData.medication ?? '');
    setProviderSearch(formData.provider?.name ?? '');
    setValue('providerName', formData.provider?.name);
    setValue('providerId', formData.provider?.id);
  };

  useEffect(() => {
    if (isNew) {
      const id = getAvailableId(medicationsList, (x) => x.id);
      reset({
        ...initialValues(isPrescriptionForm),
        id,
        type: isPrescriptionForm
          ? PatientMedicationType.Prescription
          : PatientMedicationType.Otc,
      });
    }
  }, [isNew, isPrescriptionForm, medicationsList, reset, setValue]);

  useEffect(() => {
    if (formData && !isNew) {
      loadDataIntoForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isNew]);

  const [getMeds, { data }] = useLazyGetPharmaciesQuery();
  const getProviders = () => {
    return dispatch(suggestEntities('Provider', providerSearch, setProviders));
  };

  return (
    <Grid container>
      <Grid item xs={6} style={{ paddingRight: '50px' }}>
        <FormControl
          title="Medication"
          isRequired={requiredFields['medication']}
          isAlignStart
        >
          <RemoteAutocomplete
            inputValue={medicationSearch}
            onInputChange={setMedicationSearch}
            suggestOptions={() => getMeds({ term: medicationSearch })}
            options={(data?.getPharmacies as ILookupValue[]) ?? []}
            value={
              formValues?.medication
                ? ({ id: 0, name: formValues.medication } as ILookupValue)
                : null
            }
            onChange={(newValue: ILookupValue | null) =>
              setValue('medication', newValue?.name)
            }
          />

          <ErrorFormControl error={errors?.medication} />
        </FormControl>
        <FormControl
          title="Instructions"
          isRequired={requiredFields['instructions']}
          isAlignStart
        >
          <Controller
            name="instructions"
            control={control}
            render={({ field }) => (
              <StyledTextField
                type="text"
                required
                size="small"
                fullWidth
                variant="outlined"
                {...field}
              />
            )}
          />
          <ErrorFormControl error={errors?.instructions} />
        </FormControl>
        <FormControl title="RX Status" isRequired={false}>
          <Controller
            name="patientState"
            control={control}
            render={({ field }) => (
              <StatusSelect
                variant="outlined"
                values={{
                  [PatientMedicationStatus.Discontinued]: {
                    name: 'Discontinued',
                    color: COLORS.GREY60,
                  },
                  [PatientMedicationStatus.Active]: {
                    name: 'Active',
                    color: COLORS.GREEN100,
                  },
                }}
                {...field}
              />
            )}
          />
        </FormControl>
        <FormControl
          title="Start Date"
          isRequired={requiredFields['startDate']}
          isAlignStart
        >
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DateComponent
                testId="startDate"
                placeholder="mm/dd/yyyy"
                {...field}
              />
            )}
          />
          <ErrorFormControl error={errors?.startDate} />
        </FormControl>
        <FormControl
          title="End Date"
          isAlignStart
          isRequired={requiredFields['endDate']}
        >
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DateComponent
                testId="endDate"
                placeholder="mm/dd/yyyy"
                {...field}
              />
            )}
          />
          <ErrorFormControl error={errors?.endDate} />
        </FormControl>
        {isPrescriptionForm && (
          <>
            <FormControl
              title="Date Filled"
              isRequired={requiredFields['dateFilled']}
              isAlignStart
            >
              <Controller
                name="dateFilled"
                control={control}
                render={({ field }) => (
                  <DateComponent
                    testId="dateFilled"
                    placeholder="mm/dd/yyyy"
                    {...field}
                  />
                )}
              />
              <ErrorFormControl error={errors?.dateFilled} />
            </FormControl>
            <FormControl
              title="Quantity Filled"
              isRequired={requiredFields['quantityFilled']}
              isAlignStart
            >
              <Controller
                name="quantityFilled"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    id="quantity"
                    type="text"
                    required
                    size="small"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <ErrorFormControl error={errors?.quantityFilled} />
            </FormControl>
            <FormControl
              title="Refills"
              isRequired={requiredFields['refills']}
              isAlignStart
            >
              <Controller
                name="refills"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    id="refills"
                    type="text"
                    required
                    size="small"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </FormControl>
          </>
        )}
        {!isPrescriptionForm && (
          <FormControl title="MD Recommended" isRequired={false}>
            <Controller
              name="mdRecommended"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={isMdRecommended}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onChange={() => {
                    setIsMdRecommended(!isMdRecommended);
                    setValue('mdRecommended', !isMdRecommended);
                  }}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value={true}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    value={false}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={6} style={{ paddingRight: '50px' }}>
        {isPrescriptionForm && (
          <>
            <FormControl
              title="Pharmacy"
              isRequired={requiredFields['pharmacy']}
              isAlignStart
            >
              <Controller
                name="pharmacy"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    id="pharmacy"
                    type="text"
                    size="small"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <ErrorFormControl error={errors?.pharmacy} />
            </FormControl>
            <FormControl
              title="Pharmacy Phone"
              isRequired={requiredFields['pharmacyPhone']}
              isAlignStart
            >
              <Controller
                name="pharmacyPhone"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    id="phone"
                    type="text"
                    size="small"
                    fullWidth
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <ErrorFormControl error={errors?.pharmacyPhone} />
            </FormControl>
            <FormControl
              title="Pharmacy Fax"
              isRequired={requiredFields['pharmacyFax']}
              isAlignStart
            >
              <Controller
                name="pharmacyFax"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    id="fax"
                    type="text"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <ErrorFormControl error={errors?.pharmacyFax} />
            </FormControl>
            <FormControl
              title="Prescriber"
              isRequired={requiredFields['provider']}
              isAlignStart
            >
              <RemoteAutocomplete
                inputValue={providerSearch}
                onInputChange={setProviderSearch}
                suggestOptions={getProviders}
                options={providers}
                value={
                  formValues?.providerName
                    ? ({
                        id: formValues.providerId,
                        name: formValues.providerName,
                      } as ILookupValue)
                    : null
                }
                onChange={(newValue: ILookupValue | null) => {
                  setValue('providerId', newValue?.id);
                  setValue('providerName', newValue?.name);
                }}
              />
              <ErrorFormControl error={errors?.provider} />
            </FormControl>
          </>
        )}
        {!isPrescriptionForm ? (
          <>
            <FormControl
              title="Reason"
              isAlignStart
              isRequired={requiredFields['reason']}
            >
              <Controller
                name="reason"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    id="reason"
                    type="text"
                    required
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <ErrorFormControl error={errors?.reason} />
            </FormControl>
            <FormControl
              title="Source"
              isAlignStart
              isRequired={requiredFields['source']}
            >
              <Controller
                name="source"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    id="source"
                    type="text"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <ErrorFormControl error={errors?.source} />
            </FormControl>
          </>
        ) : null}
        <FormControl title="Use" isRequired={false}>
          <Controller
            name="patientUse"
            control={control}
            render={({ field }) => (
              <Select
                displayEmpty
                variant="outlined"
                style={{ minWidth: '150px' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                renderValue={() =>
                  formValues.patientUse
                    ? patientUseText[
                        formValues.patientUse as PatientMedicationPatientUse
                      ]
                    : ''
                }
                {...field}
              >
                <MenuItem value={PatientMedicationPatientUse.NotTaking}>
                  Not taking currently
                </MenuItem>
                <MenuItem value={PatientMedicationPatientUse.TakesasPrescribed}>
                  Taking as prescribed
                </MenuItem>
                <MenuItem value={PatientMedicationPatientUse.TakesDifferently}>
                  Taking differently
                </MenuItem>
                {!isPrescriptionForm ? (
                  <MenuItem value={PatientMedicationPatientUse.MemberDirected}>
                    Member directed
                  </MenuItem>
                ) : null}
              </Select>
            )}
          />
        </FormControl>
        <FormControl
          title="Notes"
          isAlignStart
          isRequired={requiredFields['note']}
        >
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <StyledTextField
                id="note"
                type="text"
                rows={3}
                {...field}
                multiline
                size="small"
                fullWidth={true}
                variant="outlined"
              />
            )}
          />
          <ErrorFormControl error={errors?.note} />
        </FormControl>
        <Box display="flex" justifyContent="flex-end" paddingTop="14px">
          <Button
            variant="outlined"
            style={{ marginRight: '20px' }}
            onClick={closeForm}
          >
            Cancel
          </Button>
          {isNew && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '20px' }}
              onClick={() => handleSave(false)}
            >
              Save & Add New
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave(true)}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PrescriptionForm;
