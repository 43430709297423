import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  ChangeSet,
  Column,
  EditingState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  GridEditActions,
  StubCellComponent,
  SymphonyTable,
  SymphonyTableHeaderRow,
  TableEditCell,
} from 'components/gridFormatters';
import {
  conditionColumnsExtensionEditing,
  conditionColumnsExtensionSorting,
  conditionColumnsExtensionStyles,
  conditionsColumnNames,
} from './conditions';
import { PatientCondition } from 'graphql/graphqlTypes';
import { Getter } from '@devexpress/dx-react-core';
import { debounce } from 'lodash';
import { rearrangeColumnsEditLast } from 'components/home/attachmentsList/attachments.helpers';
import { hasAnyError } from 'components/home/patientdetails/tabscontent/careTeamTab/careTeam.helpers';

interface IGridProps<T> {
  getRowId: (item: T) => number;
  rows: T[];
  commitChanges: (changeSet: ChangeSet) => void;
  columns: Column[];
  labelCell: React.ComponentType<Table.DataCellProps>;
  editCell: React.ComponentType<TableEditRow.CellProps>;
  handleUpdatePatientTabValid: (valid: boolean) => void;
  isReadOnly?: boolean;
  editingRowIds: number[];
  setEditingRowIds: (editedRows: number[]) => void;
}

const ConditionsGrid = <T extends PatientCondition>(props: IGridProps<T>) => {
  const {
    getRowId,
    rows,
    columns,
    commitChanges,
    labelCell,
    editCell,
    handleUpdatePatientTabValid,
    isReadOnly,
    editingRowIds,
    setEditingRowIds,
  } = props;

  const [errors, setErrors] = useState({});
  const validationColumns = [
    {
      name: conditionsColumnNames.description,
      title: 'Description',
    },
  ];
  const validate = (
    rowsToValidate: PatientCondition[],
    columnsToValidate: Column[]
  ) =>
    Object.entries(rowsToValidate).reduce(
      (acc, [rowId, row]) => ({
        ...acc,
        [rowId]: columnsToValidate.some(
          (column) =>
            column.name === conditionsColumnNames.description &&
            row[column.name] === ''
        ),
      }),
      {}
    );

  const onEdited = debounce((edited) => {
    const validation = validate(edited, validationColumns);
    handleUpdatePatientTabValid(!hasAnyError(validation));
    setErrors(validation);
  }, 250);

  const onCancel = () => {
    const [id] = editingRowIds;
    if (id > 0) {
      return;
    }
    const cancelRow = rows.find((x) => x.id === id)!;
    const isValid = !hasAnyError(validate([cancelRow], validationColumns));
    if (!isValid) {
      commitChanges({ deleted: [id] });
    }
  };

  useEffect(() => {
    const validation = validate(rows, columns);
    handleUpdatePatientTabValid(!hasAnyError(validation));
    setErrors(validation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRowIds]);

  return (
    <Grid rows={rows} columns={columns} getRowId={getRowId}>
      <EditingState
        onCommitChanges={commitChanges}
        columnExtensions={conditionColumnsExtensionEditing}
        onRowChangesChange={onEdited}
        editingRowIds={editingRowIds}
        onEditingRowIdsChange={(curEditingRowIds: Array<number | string>) => {
          setEditingRowIds(curEditingRowIds as number[]);
        }}
      />
      <SortingState
        defaultSorting={[
          { columnName: conditionsColumnNames.description, direction: 'asc' },
        ]}
        columnExtensions={conditionColumnsExtensionSorting}
      />
      <IntegratedSorting />
      <SymphonyTable
        cellComponent={labelCell}
        columnExtensions={conditionColumnsExtensionStyles}
      />
      <SymphonyTableHeaderRow showSortingControls />
      <TableEditRow cellComponent={editCell} />
      <TableEditColumn
        showEditCommand={!isReadOnly}
        showDeleteCommand={!isReadOnly}
        headerCellComponent={StubCellComponent}
        cellComponent={TableEditCell}
        width={160}
        commandComponent={(cmdProps) => (
          <GridEditActions
            {...cmdProps}
            errors={hasAnyError(errors)}
            editEnabled={!editingRowIds.length}
            itemName={'condition'}
            onCancel={onCancel}
          />
        )}
      />
      <Getter name="tableColumns" computed={rearrangeColumnsEditLast} />
    </Grid>
  );
};

export default ConditionsGrid;
