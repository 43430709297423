import { setupListeners } from '@reduxjs/toolkit/query';
import userReducer from './user/reducer';
import rolesReducer from './roles/reducer';
import { homeReducer } from './home/reducer';
import environmentReducer from './environment/reducer';
import multiEditDialogReducer from './multiedit/reducer';
import { IEnvironmentState } from './environment/types';
import { IAuthState } from './user/types';
import { IWorkflowState } from './workflow/types';
import { IHomeState } from './home/types';
import { IPatientDetailsState } from './patientdetails/types';
import { IMultiEditDialogState } from './multiedit/types';
import { IRolesState } from './roles/types';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import workflowReducer from './workflow/workflowSlice';
import patientDetailsReducer from './patientdetails/patientDetailsSlice';
import patientDetailsAttachmentReducer, {
  IPatientDetailsAttachmentState,
} from './patientdetails/patientDetails.attachment.slice';
import { rtkQueryErrorLogger } from 'middlewares/errorMiddleware';
import { api } from 'graphql/baseApi';
import lettersModalReducer, { ILettersState } from './ui/modals/letters';
import checklistModalReducer, { IChecklistState } from './ui/modals/checklist';
import { IHeaderState } from './ui/header';
import { ErrorPopupState } from './errorPopup/types';
import errorPopupReducer from './errorPopup/errorPopupSlice';
import failedRequestReducer, {
  IFailedRequestState,
} from './failedRequests/failedRequestsSlice';
import { ToastState } from './toast/types';
import { IAllDocumentsState, IMozartReducer } from './actions/types';
import patientDetailsCarePlanReducer, {
  IPatientDetailsCarePlanState,
} from 'store/patientdetails/patientDetails.carePlan.slice';
import patientDetailsCareTeamReducer, {
  IPatientDetailsCareTeamState,
} from './patientdetails/patientDetails.careTeam.slice';
import checklistReducer from './actions/checklistSlice';
import searchPatientReducer, {
  ISearchPatientDialogState,
} from './ui/modals/searchPatient';
import quickNoteReducer, { IQuickNoteState } from 'store/ui/modals/quickNote';
import episodeSummaryReducer, {
  IEpisodeSummaryState,
} from 'store/ui/dirtyConfirmationModal/episodeSummary';
import { ThunkExtraArguments } from './ThunkExtraArguments';
import mozartReducer from 'store/actions/mozartSlice';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import errorReducer, { IErrorState } from 'store/ui/error';
import headerReducer from 'store/ui/header/header';
import fileUploadReducer from './fileUpload/fileUploadSlice';
import { FileUploadState } from './fileUpload/types';
import documentsReducer, { DocumentState } from './ui/print/documents';

export interface IState {
  user: IAuthState;
  roles: IRolesState;
  home: IHomeState;
  workflow: IWorkflowState;
  checklist: IAllDocumentsState;
  environment: IEnvironmentState;
  patientDetails: IPatientDetailsState;
  patientDetailsAttachment: IPatientDetailsAttachmentState;
  patientDetailsCarePlan: IPatientDetailsCarePlanState;
  patientDetailsCareTeam: IPatientDetailsCareTeamState;
  multiEdit: IMultiEditDialogState;
  toast: ToastState;
  ui: {
    dirtyConfirmationModal: {
      episodeSummary: IEpisodeSummaryState;
    };
    print: {
      documents: DocumentState;
    };
    header: IHeaderState;
    modals: {
      letters: ILettersState;
      checklist: IChecklistState;
      searchPatient: ISearchPatientDialogState;
      quickNote: IQuickNoteState;
    };
    error: IErrorState;
  };
  errorPopup: ErrorPopupState;
  fileUpload: FileUploadState;
  mozart: IMozartReducer;
  failedRequest: IFailedRequestState;
}

const allReducers = {
  checklist: checklistReducer,
  user: userReducer,
  roles: rolesReducer,
  home: homeReducer,
  workflow: workflowReducer,
  environment: environmentReducer,
  patientDetails: patientDetailsReducer,
  patientDetailsAttachment: patientDetailsAttachmentReducer,
  patientDetailsCarePlan: patientDetailsCarePlanReducer,
  patientDetailsCareTeam: patientDetailsCareTeamReducer,
  multiEdit: multiEditDialogReducer,
  errorPopup: errorPopupReducer,
  fileUpload: fileUploadReducer,
  ui: combineReducers({
    dirtyConfirmationModal: combineReducers({
      episodeSummary: episodeSummaryReducer,
    }),
    print: combineReducers({
      documents: documentsReducer,
    }),
    header: headerReducer,
    modals: combineReducers({
      letters: lettersModalReducer,
      checklist: checklistModalReducer,
      searchPatient: searchPatientReducer,
      quickNote: quickNoteReducer,
    }),
    error: errorReducer,
  }),
  mozart: mozartReducer,
  failedRequest: failedRequestReducer,
  [api.reducerPath]: api.reducer,
};

export const appReducer = combineReducers<typeof allReducers>(allReducers);

/* eslint-disable @typescript-eslint/no-explicit-any */
const rootReducer = (state: any, action: any) => {
  if (action.type === 'AUTH_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const extraThunkArgument = {} as ThunkExtraArguments;
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: extraThunkArgument,
      },
    }).concat(api.middleware, rtkQueryErrorLogger),
});

extraThunkArgument.store = store;

setupListeners(store.dispatch);

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof appReducer>;
