import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import ReminderRow from './ReminderRow';
import ReminderCell from './ReminderCell';
import ReminderLabel from 'components/reminder';
import { DueDateTimeType, ReminderComponent } from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import { DATE_FORMAT, DATETIME_FORMAT } from 'components/constants';

export interface IReminderProps {
  component: ReminderComponent;
}

const WrapperDiv = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
`;

const StyledDiv = styled.div`
  margin: 10px 0;
`;

const Reminder = (props: IReminderProps) => {
  const { component } = props;
  const selectedReminderReason = useSelector(
    (state: IState) =>
      state.home?.mainModel?.lookups
        ?.filter((x) => x.name === 'ReminderReason')[0]
        ?.values?.filter((y) => y.id === component.reasonIdSelected)[0]
  );
  const accountUsers = useSelector(
    (state: IState) => state.home.mainModel.users
  );

  return (
    <div style={{ margin: '10px 0px' }}>
      <ReminderRow
        {...props}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'initial',
          paddingBottom: '12px',
        }}
      >
        <ReminderLabel
          importanceIdSelected={component.importanceIdSelected?.toString()}
          disabled
        />
        <WrapperDiv>
          <Typography
            style={{
              color: COLORS.BLACK,
            }}
          >
            {component.notes}
          </Typography>
        </WrapperDiv>
      </ReminderRow>
      <ReminderRow {...props}>
        <ReminderCell {...props}>
          <WrapperDiv>
            <Typography
              variant="body1"
              style={{
                color: COLORS.BLACK,
                whiteSpace: 'pre',
              }}
            >
              {'Reason: '}
            </Typography>
            {selectedReminderReason ? (
              <>
                <StyledDiv>
                  <Typography
                    variant="body1"
                    style={{
                      color: COLORS.BLACK,
                      fontWeight: 'bold',
                    }}
                  >
                    {selectedReminderReason.name}
                  </Typography>
                </StyledDiv>
              </>
            ) : null}
          </WrapperDiv>
          <WrapperDiv>
            <StyledDiv>
              <Typography
                variant="body1"
                style={{
                  color: COLORS.BLACK,
                  whiteSpace: 'pre',
                }}
              >
                {'Team Member: '}
              </Typography>
            </StyledDiv>
            <StyledDiv>
              {
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  {
                    accountUsers?.find(
                      (lv) => Number(lv.id) === component.teamMemberIdSelected
                    )?.name
                  }
                </Typography>
              }
            </StyledDiv>
          </WrapperDiv>
          <WrapperDiv>
            <StyledDiv>
              <Typography
                variant="body1"
                style={{
                  color: COLORS.BLACK,
                }}
              >
                {component.dueDateTimeType === DueDateTimeType.Date
                  ? ' Date due:'
                  : 'Date/Time due:'}
              </Typography>
            </StyledDiv>
            <WrapperDiv>
              <Typography style={{ fontWeight: 'bold' }}>
                {component.dueDateTimeType === DueDateTimeType.Date
                  ? formatDateTime(component.dateDue, DATE_FORMAT)
                  : formatDateTime(component.dateDue, DATETIME_FORMAT)}
              </Typography>
            </WrapperDiv>
          </WrapperDiv>
        </ReminderCell>
      </ReminderRow>
      {component.comment && component.comment.trim().length > 0 ? (
        <ReminderRow {...props}>
          <ReminderCell {...props}>
            <WrapperDiv>
              <Typography
                variant="body1"
                style={{
                  color: COLORS.BLACK,
                  whiteSpace: 'pre',
                }}
              >
                {'Note: '}
              </Typography>
              <StyledDiv>
                <Typography
                  variant="body1"
                  style={{
                    color: COLORS.BLACK,
                    fontWeight: 'bold',
                  }}
                >
                  {component.comment}
                </Typography>
              </StyledDiv>
            </WrapperDiv>
          </ReminderCell>
        </ReminderRow>
      ) : null}
    </div>
  );
};

export default Reminder;
