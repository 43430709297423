import React, { forwardRef } from 'react';
import { ChecklistStorageType } from 'store/actions/types';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { PRINT_SOURCES } from 'backend/types/printSources';
import { PrintPreviewWrapper } from 'styles/printStyles';
import { useSelector } from 'react-redux';
import ChecklistPreviewWrapper from 'components/checklistViewPrint/ChecklistPreviewWrapper';
import { IState } from 'store';

const StyledContent = styled.div<{ $isHidden?: boolean }>`
  justify-content: space-between;
  background-color: ${COLORS.GREY4};
  padding-top: 5px;

  ${({ $isHidden }) =>
    !$isHidden &&
    `@media screen {
        display: none;
      }
    `}
`;
const StyledContainer = styled.div`
  padding: 0 30px;
  min-height: 40vh;
`;

interface IChecklistPrintLayoutProps {
  isPrintOpen: boolean;
  storageType: ChecklistStorageType;
  printSource: PRINT_SOURCES;
}

const ChecklistPrintLayout = forwardRef<
  HTMLDivElement,
  IChecklistPrintLayoutProps
>(({ isPrintOpen, storageType, printSource }, ref) => {
  const patientIdFromDocumentState = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].patientId
  );
  const patientIdFromPatientDetails = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const patientId = patientIdFromDocumentState ?? patientIdFromPatientDetails;
  const selectedChecklistIds = useSelector(
    (state: IState) => state.ui.print.documents.nonMozart.selectedChecklistIds
  );
  const hideMediaStyle = printSource !== PRINT_SOURCES.WORKFLOW;

  return (
    <div ref={ref}>
      {isPrintOpen && (
        <PrintPreviewWrapper>
          <StyledContent $isHidden={hideMediaStyle}>
            <StyledContainer>
              <ChecklistPreviewWrapper
                checklistIds={selectedChecklistIds ?? []}
                patientId={patientId}
                storageType={storageType}
              />
            </StyledContainer>
          </StyledContent>
        </PrintPreviewWrapper>
      )}
    </div>
  );
});
ChecklistPrintLayout.displayName = 'ChecklistPrintLayout';

export default ChecklistPrintLayout;
