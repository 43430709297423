import { api as generatedApi } from '../hooks/getPatientById';

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Patient'],
  endpoints: {
    getPatientById: {
      providesTags: (result, error, arg) => [
        { type: 'Patient', id: arg.patientId },
      ],
    },
  },
});

export const { useGetPatientByIdQuery } = api;
