import React, { ChangeEvent, useEffect, useState } from 'react';
import { CellComponentStyle } from 'components/gridFormatters';
import { Table, TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { ILookupValue } from 'graphql/graphqlTypes';
import { MenuItem, TextField } from '@mui/material';
import { useGetLookupValuesQuery } from 'graphql/hooks/getLookups';
import { validateRequired } from 'util/validationUtils';

export const EditLookupCell = (
  props: TableEditRow.CellProps & { lookup: string }
) => {
  const { value, lookup, onValueChange } = props;
  const [error, setError] = useState<boolean>(
    validateRequired(value, true).hasError
  );
  const { data } = useGetLookupValuesQuery(
    {
      name: lookup,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [lookupValues, updateLookupValues] = useState([] as ILookupValue[]);

  useEffect(() => {
    if (data) {
      updateLookupValues(data.getLookupValues as ILookupValue[]);
    }
  }, [data]);

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const valueId = Number(e.target.value);
    const isValid = validateRequired(valueId, true);
    setError(isValid.hasError);
    onValueChange({
      id: valueId,
      name: lookupValues.find((x) => x.id === valueId)?.name,
    });
  };

  return (
    <Table.Cell
      {...props}
      style={CellComponentStyle}
      data-cy="table-cell-primary"
      onClick={undefined}
    >
      <TextField
        id="owner"
        select
        size="medium"
        defaultValue={value?.id ?? 0}
        fullWidth={true}
        onChange={handleOnChange}
        variant="outlined"
        required={true}
        error={error}
      >
        <MenuItem key="" value="" />
        {lookupValues?.map((item) => (
          <MenuItem key={item.id} value={Number(item.id)}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </Table.Cell>
  );
};
