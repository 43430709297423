import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Grid as TableGrid } from '@devexpress/dx-react-grid-material-ui';
import {
  LabelCell,
  SymphonyTable,
  SymphonyTableHeaderRow,
  useData,
} from 'components/gridFormatters';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import {
  benefitDetailsColumns,
  insuranceCurrentColumns,
  insuranceHistoryColumns,
  labelInsuranceHistory,
} from './insuranceHistory';

import { patientInsuranceLoaded } from 'store/patientdetails/patientDetailsSlice';
import {
  GetPatientInsuranceTabQuery,
  useLazyGetPatientInsuranceTabQuery,
} from 'graphql/hooks/getPatientInsuranceTab';
import { Column } from '@devexpress/dx-react-grid';

const InsuranceTab = () => {
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const { data: insuranceData } = useData(
    (state: IState) => state.patientDetails.insurance,
    useLazyGetPatientInsuranceTabQuery,
    { patientId },
    (data: GetPatientInsuranceTabQuery) => patientInsuranceLoaded(data),
    !patientId
  );
  const glossaryItems = useSelector(
    (state: IState) => state.home.mainModel.glossaryItems
  );
  const lobLabel =
    glossaryItems?.find((sv) => sv.name === 'LOB Label')?.value ?? 'LOB';

  if (!insuranceData) {
    return <Typography>Loading</Typography>;
  }

  const fillLOB = (column: Column) => {
    if (column.name === 'lob') {
      column.title = lobLabel;
    }
  };

  insuranceHistoryColumns.forEach(fillLOB);
  benefitDetailsColumns.forEach(fillLOB);

  return (
    <Grid container>
      <Grid item>
        <Box mb="32px">
          <Typography variant="subtitle1" style={{ paddingBottom: '16px' }}>
            Coordination of Benefits
          </Typography>
          <TableGrid
            rows={insuranceData.insuranceCurrent}
            columns={insuranceCurrentColumns}
          >
            <SymphonyTable cellComponent={LabelCell} />
            <SymphonyTableHeaderRow />
          </TableGrid>
        </Box>
      </Grid>
      <Grid item>
        <Box mb="32px">
          <Typography variant="subtitle1" style={{ paddingBottom: '16px' }}>
            Insurance History
          </Typography>
          <TableGrid
            rows={insuranceData.insuranceHistory}
            columns={insuranceHistoryColumns}
            getRowId={(row) => row.name}
          >
            <SymphonyTable cellComponent={labelInsuranceHistory} />
            <SymphonyTableHeaderRow />
          </TableGrid>
        </Box>
      </Grid>
      <Grid item>
        <Box mb="32px">
          <Typography variant="subtitle1" style={{ paddingBottom: '16px' }}>
            Benefit Details
          </Typography>
          <TableGrid
            rows={insuranceData.insuranceHistory}
            columns={benefitDetailsColumns}
            getRowId={(row) => row.name}
          >
            <SymphonyTable cellComponent={labelInsuranceHistory} />
            <SymphonyTableHeaderRow />
          </TableGrid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InsuranceTab;
