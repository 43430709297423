import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import MemberNoteReasonFormatter from '../formatters/MemberNoteReasonFormatter';

const MemberNoteReasonProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={MemberNoteReasonFormatter} {...props} />
);

export default MemberNoteReasonProvider;
