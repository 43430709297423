import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';

const SimpleBoolFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <Typography title={props.value ? 'Yes' : 'No'}>
    {props.value ? 'Yes' : 'No'}
  </Typography>
);

export default SimpleBoolFormatter;
