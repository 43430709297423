import { Box, Typography } from '@mui/material';
import { Asterisk } from 'components/text';
import { COLORS } from 'consts/styles';
import React from 'react';

export const RequiredReason = ({
  isRequired,
  showLabel,
}: {
  isRequired: boolean;
  showLabel?: boolean;
}) => (
  <Box display="flex" flexDirection="row">
    {isRequired && <Asterisk color={COLORS.RED100} />}
    {showLabel === true ? (
      <Typography variant="body2">Reason Group Shortcut:</Typography>
    ) : (
      <Typography variant="body2">Reason</Typography>
    )}
  </Box>
);
