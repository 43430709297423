import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { COLORS } from 'consts/styles';
import { Link } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const DeletePriorAuthLink = withStyles({
  root: {
    marginLeft: '5px',
    marginRight: '5px',
    fontWeight: 500,
    color: COLORS.SYMPHONY_BLUE,
    fontSize: 14,
    cursor: 'pointer',
  },
})(Link);

const DeletePriorAuthRowActionFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  return (
    <DeletePriorAuthLink
      onClick={() => props.value.onDelete(props.value.id)}
      data-cy="Delete"
      title="Delete"
    >
      Delete
    </DeletePriorAuthLink>
  );
};

export default DeletePriorAuthRowActionFormatter;
