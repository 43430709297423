import React from 'react';

import { Box, Typography } from '@mui/material';
import { ReminderHistoryRow } from 'graphql/graphqlTypes';
import { useGetReminderHistoryQuery } from 'graphql/hooks/getReminderHistory';

interface IReminderMoreInfoProps {
  reminderId: number;
  note: string;
}

const MoreInfoModal = (props: IReminderMoreInfoProps) => {
  const { reminderId } = props;

  const { data } = useGetReminderHistoryQuery({
    reminderId,
  });
  return (
    <Box component="div" p="12px">
      <Typography variant="body2" style={{ paddingBottom: '4px' }}>
        Note:
      </Typography>
      <Typography style={{ paddingBottom: '12px' }}>{props.note}</Typography>
      {data && (
        <Typography variant="body2" style={{ paddingBottom: '4px' }}>
          History
        </Typography>
      )}
      {data?.getReminderHistory?.rows?.map(
        (history: ReminderHistoryRow | undefined | null, index: number) =>
          history ? (
            <Typography key={index}>
              {`${history.dateFormatted} - ${history.action} by ${history.userName}`}
            </Typography>
          ) : null
      )}
    </Box>
  );
};

export default MoreInfoModal;
