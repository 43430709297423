import React from 'react';
import Icon, { ICONS } from 'components/icon';
import { useDispatch } from 'react-redux';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { EPISODE_SOURCE_FAX } from '../consts';

const StyledLink = styled.div`
  cursor: pointer;
  text-align: center;
`;

const RisingRiskFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch(
      openPatientDetailsModal({
        patientId: Number(
          props.row.patientId ??
            (props.row.EpisodeSource !== EPISODE_SOURCE_FAX
              ? props.row.id
              : undefined)
        ),
        episodeId: parseInt(props.row.episodeId),
        activeTabName: 'Background',
        activeSubTabName: 'Risk',
      })
    );
  };

  return (
    <CustomTooltip title="Rising risk">
      <StyledLink onClick={handleClick} data-cy="home-grid-reminder">
        {props.value === 'Yes' && (
          <Icon icon={ICONS.Risk} size={16} color={COLORS.GREY100} />
        )}
      </StyledLink>
    </CustomTooltip>
  );
};

export default RisingRiskFormatter;
