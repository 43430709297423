import {
  HealthServiceCodesActionValue,
  LookupValue,
} from 'graphql/graphqlTypes';

import { getApprovedUnits } from '../HealthService.helpers';
import { DeterminationDecision } from '../types';

export const updateItemGroupDecision = (
  item: HealthServiceCodesActionValue,
  decision: DeterminationDecision
): HealthServiceCodesActionValue => ({
  ...item,
  healthServiceActionValue: {
    ...item.healthServiceActionValue,
    determinationDecision: decision,
    approvedStartDate:
      decision === 'Approved'
        ? item.healthServiceActionValue?.requestedStartDate
        : null,
    approvedEndDate:
      decision === 'Approved'
        ? item.healthServiceActionValue?.requestedEndDate
        : null,
    approvedUnitType:
      decision === 'Approved' || decision === 'Partial' || decision === 'Denied'
        ? item.healthServiceActionValue?.requestedUnitType
        : null,
    approvedUnits: getApprovedUnits(item, decision),
    determinationReason: null,
  },
});

export const updateItemDeterminationReason = (
  item: HealthServiceCodesActionValue,
  reason: LookupValue | null
): HealthServiceCodesActionValue => ({
  ...item,
  healthServiceActionValue: {
    ...item.healthServiceActionValue,
    determinationReason: reason,
  },
});
