import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OutlinedInput } from '@mui/material';
import { changeMfaStep } from 'store/user/middlewares';
import ErrorComp from 'components/error';
import { IMfaStep } from 'store/user/types';
import { useForm } from 'react-hook-form';
import { IState } from 'store';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { CountryCodeSelector } from './CountryCodeSelector';

const StyledWrapper = styled.div`
  min-width: 308px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
`;

const StyledInputWrapper = styled.div`
  margin-top: 40px;
`;

const StyledOutlinedInput = styled(OutlinedInput)({
  '.MuiInputBase-input': {
    height: '40px',
  },
  color: COLORS.BLACK10,
  'input:-internal-autofill-selected': {
    backgroundColor: 'transparent!important',
  },
});

export const EnterPhoneForm = () => {
  const { auth, countryCode, countryCodes, phone, mfaType } = useSelector(
    (state: IState) => state.user
  );
  const dispatch = useDispatch();
  const [values, setValues] = useState<IMfaStep>({
    login: auth.login,
    password: auth.password,
    countryCode: countryCode,
    phone: phone,
    mfaType: mfaType,
    countryCodes: countryCodes,
  });

  const inputHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    e.persist();
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();
  const backHandler = () => navigate(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IMfaStep>();
  const onSubmit = (newValues: IMfaStep) => {
    newValues.login = auth.login;
    newValues.password = auth.password;
    newValues.mfaType = mfaType;
    newValues.countryCodes = countryCodes;
    newValues.countryCode = values.countryCode;
    newValues.phone = newValues.phone.replace(/\D/g, '');
    if (newValues.phone.length === 11) {
      newValues.phone = newValues.phone.substring(1);
    }
    dispatch(changeMfaStep(newValues, 'phone'));
  };

  const errorMessage: { [key: string]: string } = {
    required: 'Please provide a phone number',
    minLength: 'Your phone must be at least 10 characters long',
  };

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginBottom: '35px' }}>
          <Typography
            variant="h2"
            style={{
              color: COLORS.BLACK,
              fontWeight: 300,
            }}
          >
            Enter Phone Number
          </Typography>
          <Typography variant="body1" style={{ paddingBottom: '10px' }}>
            <div style={{ marginBottom: '20px' }}>
              Username: <strong>{auth.login}</strong>
            </div>
            <div>Enter your phone number that can be used to</div>
            verify your identifiy with a text message.
          </Typography>
          <Typography variant="caption" style={{ fontStyle: 'italic' }}>
            Message and data rates may apply
          </Typography>
        </div>
        <StyledInputWrapper>
          <CountryCodeSelector
            title="Country Code"
            fullWidth={true}
            countryCodes={values.countryCodes}
            selectedCountryCodeId={values.countryCode}
            setSelectedCountryCodeId={(selectedCode) =>
              setValues({ ...values, countryCode: selectedCode })
            }
          />
          <Typography
            style={{ color: COLORS.GREY80, paddingBottom: '9px' }}
            variant="body1"
          >
            Phone Number
          </Typography>
          <StyledOutlinedInput
            id="phone"
            type="text"
            placeholder="1234567890"
            fullWidth={true}
            {...register('phone', { required: true, minLength: 10 })}
            onChange={inputHandler}
            value={values.phone}
            style={{ height: '54px' }}
            inputProps={{ autocomplete: 'off' }}
          />
          <ErrorComp>
            {errors.phone && <span>{errorMessage[errors.phone.type]}</span>}
          </ErrorComp>
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={backHandler}
            style={{ width: '40%', marginRight: '20%' }}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
            data-cy="enter-phone"
            style={{ width: '40%' }}
          >
            Verify
          </Button>
        </StyledInputWrapper>
      </form>
    </StyledWrapper>
  );
};
