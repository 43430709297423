import { SerializedError } from '@reduxjs/toolkit';
import { UpdateActionValueRequestName } from 'components/failedRequests/FailedRequests';
import { UpdateActionValueModelInput } from 'graphql/graphqlTypes';
import {
  UpdateActionValueMutation,
  useUpdateActionValueMutation,
} from 'graphql/hooks/updateActionValue';
import { useDispatch } from 'react-redux';
import {
  addFailedRequest,
  removeFailedRequest,
} from 'store/failedRequests/failedRequestsSlice';

export const useUpdateActionValue = () => {
  const dispatch = useDispatch();
  const [updateImmediately, { isSuccess }] = useUpdateActionValueMutation();

  const updateActionValueExtended = (
    values: UpdateActionValueModelInput,
    idToRetry: string
  ) => {
    updateImmediately({ data: values }).then(
      (
        value:
          | { data: UpdateActionValueMutation }
          | { error: unknown | SerializedError }
      ) => {
        const responseData = value as { data: UpdateActionValueMutation };
        if (
          responseData.data &&
          responseData.data.updateActionValue &&
          idToRetry
        ) {
          dispatch(removeFailedRequest(idToRetry));
          return;
        }

        const responseError = value as {
          error: unknown | SerializedError;
        };
        if (responseError.error && idToRetry) {
          dispatch(
            addFailedRequest({
              name: UpdateActionValueRequestName,
              idToRetry: idToRetry,
              variables: values,
            })
          );
        }
      }
    );
  };

  return { updateActionValueExtended, isSuccess };
};
