import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const InProgressFormatter = (obj: DataTypeProvider.ValueFormatterProps) => (
  <>
    {obj.value === 'Yes' && (
      <Icon
        icon={ICONS.ProgressCompleted}
        size={18}
        color={COLORS.GREY60}
        title="Document completed"
        style={{ height: '18px' }}
      />
    )}
    {obj.value === 'No' && (
      <Icon
        icon={ICONS.InProgress}
        size={18}
        color={COLORS.YELLOW100}
        title={`Document in progress by ${obj.row.LastChecklistUpdatedByUser}`}
        style={{ height: '18px' }}
      />
    )}
  </>
);

const InProgressProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={InProgressFormatter} {...props} />
);

export default InProgressProvider;
