import { Box, Grid, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import React, { useEffect, useRef, useState } from 'react';
import { StyledDivider } from '../styles';
import { ServiceRow } from './ServiceRow';
import { DecisionButtonGroup } from './DecisionButtonGroup';
import {
  DecisionAction,
  DeterminationDecision,
  HealthServiceAuthorizationType,
} from '../types';
import ReasonSelect from './ReasonSelect';
import { RequiredReason } from './RequiredReason';
import { ServiceRowReview } from 'components/review/sections/SectionBody/Items/HealthService/ServiceRowReview';
import {
  updateItemDeterminationReason,
  updateItemGroupDecision,
} from './IndividualService.helpers';
import {
  HealthServiceCodesActionValue,
  ILookupValue,
  LookupValue,
} from 'graphql/graphqlTypes';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import styled from 'styled-components';
import { DeleteIndividualService } from './DeleteIndividualService';

export interface IIndividualServiceProps {
  id: string;
  item: HealthServiceCodesActionValue;
  onDelete: (id: number) => void;
  onChange: (state: HealthServiceCodesActionValue) => void;
  unitTypes: ILookupValue[];
  determinationReasons: { [key: string]: ILookupValue[] };
  readOnly: boolean;
  pendingOnly?: boolean;
  canApprove?: boolean;
  canDenied?: boolean;
  overallDates: { start: Date | null; end: Date | null };
  hsRequestingCodes?: boolean;
  validationDispatch: (
    errorMessage: string,
    error: boolean,
    id?: string
  ) => void;
  enableDelete?: boolean;
  hsAuthorizationType: number;
  isHsAllowDeletingCode?: boolean;
}

const DecisionBox = styled(Box)({
  borderLeft: '1px solid',
  borderColor: COLORS.GREY25,
  paddingLeft: '20px',
});

const IndividualService = (props: IIndividualServiceProps) => {
  const {
    id,
    item,
    onChange,
    onDelete,
    unitTypes,
    determinationReasons,
    readOnly,
    pendingOnly,
    canApprove,
    canDenied,
    overallDates,
    validationDispatch,
    hsRequestingCodes,
    enableDelete,
    hsAuthorizationType,
    isHsAllowDeletingCode,
  } = props;

  const [groupDecision, updateGroupDecision] = useState<DeterminationDecision>(
    (item.healthServiceActionValue
      ?.determinationDecision as DeterminationDecision) ?? DecisionAction.nonSet
  );

  const reasonRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    updateGroupDecision(
      (item.healthServiceActionValue
        ?.determinationDecision as DeterminationDecision) ??
        DecisionAction.nonSet
    );
  }, [item.healthServiceActionValue?.determinationDecision]);

  const [determinationReason, updateDeterminationReason] = useState<
    LookupValue | null | undefined
  >(item.healthServiceActionValue?.determinationReason);

  useEffect(() => {
    updateDeterminationReason(
      item.healthServiceActionValue?.determinationReason
    );
  }, [item.healthServiceActionValue?.determinationReason]);

  useEffect(() => {
    if (item.healthServiceActionValue?.determinationDecision === 'Approved') {
      const newItem = {
        ...item,
        healthServiceActionValue: {
          ...item.healthServiceActionValue,
          approvedUnits: item.healthServiceActionValue?.requestedUnits,
        },
      };
      onChange(newItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    item.healthServiceActionValue?.requestedUnits,
    item.healthServiceActionValue?.determinationDecision,
  ]);

  useEffect(() => {
    const reasonId = `${item.id.toString()}-reason`;
    if (groupDecision !== DecisionAction.nonSet && !determinationReason) {
      validationDispatch(
        `Decision reason for service ${item.code} is not set`,
        true,
        reasonId
      );
    } else {
      validationDispatch('', false, reasonId);
    }
    return () => {
      validationDispatch('', false, reasonId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupDecision, determinationReason]);

  const onUpdateGroupDecision = (decision: DeterminationDecision) => {
    const newItem = updateItemGroupDecision(item, decision);
    updateGroupDecision(decision);
    SetFocus(decision);
    onChange(newItem);
  };

  const SetFocus = (decision: DeterminationDecision) => {
    if (
      decision !== DecisionAction.nonSet &&
      decision !== DecisionAction.partial
    ) {
      reasonRef?.current?.focus();
    }
  };

  const onUpdateDeterminationReason = (reason: LookupValue | null) => {
    const newItem = updateItemDeterminationReason(item, reason);
    updateDeterminationReason(reason);
    onChange(newItem);
  };

  return (
    <Grid
      id={id}
      xs={12}
      container
      item
      alignItems="center"
      direction="row"
      style={{
        marginBottom: '12px',
        marginTop: '5px',
        backgroundColor: COLORS.GREY4,
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      <Box
        display="grid"
        alignItems="center"
        px="12px 20px"
        style={{
          backgroundColor: COLORS.GREY10,
          width: '100%',
          padding: '10px 20px',
          gridTemplateColumns: 'auto auto minmax(100px, 1fr) auto',
        }}
      >
        <Typography variant="body2">{item.code}</Typography>
        <StyledDivider orientation="vertical" />
        <CustomTooltip title={item.description ?? ''}>
          <Typography noWrap variant="body1">
            {item.description}
          </Typography>
        </CustomTooltip>
        {!readOnly &&
          (isHsAllowDeletingCode ||
            hsAuthorizationType === HealthServiceAuthorizationType.NonSet) && (
            <DeleteIndividualService
              enableDelete={enableDelete}
              onDelete={onDelete}
              id={item.id}
            />
          )}
      </Box>

      {hsAuthorizationType != HealthServiceAuthorizationType.Episode && (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          px="20px"
        >
          <Box width="75%">
            <ServiceRow
              type="Requested"
              item={item}
              readOnly={readOnly}
              onChange={onChange}
              unitTypes={unitTypes}
              overallDates={overallDates}
              validationDispatch={validationDispatch}
            />
            {!hsRequestingCodes && groupDecision === DecisionAction.partial && (
              <ServiceRow
                type="Approved"
                item={item}
                readOnly={readOnly}
                onChange={onChange}
                unitTypes={unitTypes}
                overallDates={overallDates}
                validationDispatch={validationDispatch}
              />
            )}
            {!hsRequestingCodes &&
              groupDecision !== DecisionAction.partial &&
              groupDecision !== DecisionAction.nonSet && (
                <ServiceRowReview
                  type="Approved"
                  hideDates={
                    groupDecision === DecisionAction.pending ||
                    groupDecision === DecisionAction.denied
                  }
                  hideValues={groupDecision === DecisionAction.pending}
                  item={item}
                />
              )}
          </Box>
          {!hsRequestingCodes && (
            <DecisionBox py="16px">
              <Typography variant="body1">Decision</Typography>
              <DecisionButtonGroup
                updateGroupDecision={onUpdateGroupDecision}
                groupDecision={groupDecision}
                readOnly={readOnly}
                pendingOnly={pendingOnly}
                canApprove={canApprove}
                canDenied={canDenied}
                canPartial={canApprove}
              />
              {groupDecision !== DecisionAction.nonSet && (
                <Box pt="24px" id={`${id}-reason`}>
                  <RequiredReason isRequired />
                  <ReasonSelect
                    determinationReasons={determinationReasons}
                    groupDecision={groupDecision}
                    value={determinationReason}
                    ref={reasonRef}
                    readOnly={
                      readOnly || (!canApprove && !canDenied && !pendingOnly)
                    }
                    updateValue={onUpdateDeterminationReason}
                    width="100%"
                    error={!determinationReason}
                  />
                </Box>
              )}
            </DecisionBox>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default IndividualService;
