import React from 'react';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledVirtualTable = styled(VirtualTable.Table)`
  & tbody tr td {
    background-color: ${COLORS.BLUE10}!important;
    color: ${COLORS.GREY100};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DetailsTableComponent = (props: any) => (
  <StyledVirtualTable {...props} data-cy="grid-table" />
);
