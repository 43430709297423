import { Grid, Typography } from '@mui/material';
import { useData } from 'components/gridFormatters';
import React from 'react';
import { IState } from 'store';
import { updatePatientRiskScores } from 'store/patientdetails/patientDetailsSlice';
import { StyledFormControl } from '../../styles';
import { PatientRisk } from 'graphql/graphqlTypes';
import {
  GetPatientRiskQuery,
  useLazyGetPatientRiskQuery,
} from 'graphql/hooks/getPatientRiskInfo';

export interface IRiskInfoProps {
  patientId: number;
  showRisk: boolean;
}

const RiskInfo = (props: IRiskInfoProps) => {
  const { patientId, showRisk } = props;

  const { data: riskData } = useData(
    (state: IState) => state.patientDetails.riskScores,
    useLazyGetPatientRiskQuery,
    { patientId },
    (data: GetPatientRiskQuery) =>
      updatePatientRiskScores(data.getPatientRisk as PatientRisk),
    !patientId || !showRisk
  );

  return (
    <Grid item sm={3}>
      <StyledFormControl container spacing={1}>
        <Grid item xs={4} style={{ display: 'flex', alignSelf: 'start' }}>
          <Typography>{'IP Risk Score:'}</Typography>
        </Grid>
        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">{riskData?.ip.toFixed(3)}</Typography>
        </Grid>
      </StyledFormControl>
      <StyledFormControl container spacing={1}>
        <Grid item xs={4} style={{ display: 'flex', alignSelf: 'start' }}>
          <Typography>{'ER Risk Score:'}</Typography>
        </Grid>
        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">{riskData?.eR.toFixed(3)}</Typography>
        </Grid>
      </StyledFormControl>
      <StyledFormControl container spacing={1}>
        <Grid item xs={4} style={{ display: 'flex', alignSelf: 'start' }}>
          <Typography>{'Total Risk Score:'}</Typography>
        </Grid>
        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">{riskData?.tot.toFixed(3)}</Typography>
        </Grid>
      </StyledFormControl>
      <StyledFormControl container spacing={1}>
        <Grid item xs={4} style={{ display: 'flex', alignSelf: 'start' }}>
          <Typography>{'Rising Risk:'}</Typography>
        </Grid>
        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">
            {riskData?.risingRiskFlag === 1 ? 'Rising' : 'No'}
          </Typography>
        </Grid>
      </StyledFormControl>
      <StyledFormControl container spacing={1}>
        <Grid item xs={4} style={{ display: 'flex', alignSelf: 'start' }}>
          <Typography>{'Acuity Level:'}</Typography>
        </Grid>
        <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1">{riskData?.acuityLevel}</Typography>
        </Grid>
      </StyledFormControl>
    </Grid>
  );
};

export default RiskInfo;
