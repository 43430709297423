import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SaveAllMutationVariables = Types.Exact<{
  model: Types.EntitiesInfoInput;
}>;

export type SaveAllMutation = {
  __typename?: 'Mutation';
  saveAllEntities?: boolean | null;
};

export const SaveAllDocument = `
    mutation saveAll($model: EntitiesInfoInput!) {
  saveAllEntities(model: $model)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveAll: build.mutation<SaveAllMutation, SaveAllMutationVariables>({
      query: (variables) => ({ document: SaveAllDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSaveAllMutation } = injectedRtkApi;
