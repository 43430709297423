import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import { Box, Grid } from '@mui/material';
import {
  Grid as TableGrid,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { SelectionState } from '@devexpress/dx-react-grid';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import DeleteByIdActionProvider from 'components/home/grid/providers/DeleteByIdActionProvider';
import SelectedRowComponent from 'components/home/grid/SelectedRowComponent';
import { useTimer } from 'react-timer-hook';
import { COLORS } from 'consts/styles';
import { validateMinAmountValues } from 'util/validationUtils';
import { usePrevious } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMedicationCode,
  removeMedicationCode,
  setChecklistComponentValidation,
} from 'store/actions/checklistSlice';
import TableComponent from './TableComponent';
import { ChecklistStorageType } from 'store/actions/types';
import MedicationCodesSearch from './medicationCodesSearch';
import { IState } from 'store';
import { SymphonyTable } from 'components/gridFormatters';
import Grey10HeaderCellComponent from 'components/home/grid/Grey10HeaderCellComponent';
import {
  ChecklistItem,
  ChecklistItemMedicalCodes,
  Maybe,
  MedicationCodeCategoryType,
  MedicationCodeType,
  MedicationCodesActionValue,
  SearchResult,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import {
  GridLineRow,
  GridValidationLineRow,
} from 'components/actions/sections/SectionBody/Items/MedicationCodes/StyledComponents';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';
import PrimaryIconProvider from 'components/home/grid/providers/PrimaryIconProvider';
import { isAllowedToAddCodes } from '../PriorAuthCodes/PriorAuthcodes.helpers';

export interface IChecklistItemMedicationCodesProps {
  item: ChecklistItemMedicalCodes;
  categoryId: string;
  autoSave: boolean;
  orderableIndex: number;
  storageType: ChecklistStorageType;
  readOnly: boolean;
  updateChecklistItemInputValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  selectChecklistItemOnSave: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
}

const None = 0;

const useChecklistValidation = (
  error: boolean,
  errorMessage: string,
  uuid: string,
  fieldName: string,
  categoryId: string,
  storageType: ChecklistStorageType
) => {
  const dispatch = useDispatch();
  const previousError = usePrevious(error);
  useEffect(
    () => {
      if (error === previousError) {
        return;
      }
      if (!error && !previousError) {
        return;
      }
      if (error) {
        dispatch(
          setChecklistComponentValidation({
            storageType,
            error: {
              uuid,
              error: errorMessage,
              fieldName,
              categoryId: categoryId,
              isValid: false,
            },
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error]
  );

  useEffect(
    () => () => {
      dispatch(
        setChecklistComponentValidation({
          storageType,
          error: {
            uuid,
            error: errorMessage,
            fieldName,
            categoryId,
            isValid: true,
          },
        })
      );
    },
    // eslint-disable-next-line
    []
  );
};

const MedicationCodes = (props: IChecklistItemMedicationCodesProps) => {
  const { item, categoryId, autoSave, storageType, readOnly } = props;

  useEffect(() => {
    handleValidation(item.value ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.value]);

  const dispatch = useDispatch();
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );
  const [highlightedCode, setHighlightedCode] = useState(None);
  const [model, setModel] = useState<Maybe<MedicationCodesActionValue>[]>(
    item.value ?? []
  );
  const [validation, setValidation] = useState(
    validateMinAmountValues(item.value?.length ?? 0, item.options.minRequired)
  );

  const isUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    item.uid
  );

  const [showErrorBar, setShowErrorBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null
  );

  const { restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      setHighlightedCode(None);
    },
  });

  useChecklistValidation(
    validation.hasError,
    validation.message ?? 'Medication Codes Error',
    item.uid,
    'Medication Codes',
    categoryId,
    storageType
  );

  const columns =
    item.options.type === MedicationCodeType.Icd_10Cm
      ? [
          {
            name: 'code',
            title: item.options.codeLabel,
          },
          {
            name: 'isPrimary',
            title: 'Primary',
            getCellValue: (row: MedicationCodesActionValue) => ({
              id: Number(row?.id ?? 0),
              onSelect: handlePrimaryCode,
              isPrimary: row.isPrimary,
            }),
          },
          { name: 'description', title: item.options.descriptionLabel },
          {
            name: 'id',
            title: 'Delete',
            getCellValue: (row: SearchResult) => ({
              id: Number(row?.id ?? 0),
              onDelete: handleDeleteCode,
            }),
          },
        ]
      : [
          {
            name: 'code',
            title: item.options.codeLabel,
          },
          { name: 'description', title: item.options.descriptionLabel },
          {
            name: 'id',
            title: 'Delete',
            getCellValue: (row: SearchResult) => ({
              id: Number(row?.id ?? 0),
              onDelete: handleDeleteCode,
            }),
          },
        ];
  const columnsExtension = [
    { columnName: 'code', sortingEnabled: false, width: 200 },
    { columnName: 'isPrimary', sortingEnabled: false, width: 100 },
    { columnName: 'description', sortingEnabled: false },
    { columnName: 'id', sortingEnabled: false, width: 60 },
  ];

  const handleDeleteCode = (id: number) => {
    if (highlightedCode === id) {
      setHighlightedCode(None);
    }

    const newCodes = (model?.filter((code) => code?.id !== id) ??
      []) as unknown as MedicationCodesActionValue[];

    const primaryModel = [...newCodes];

    const newModel = primaryModel.map((x) => {
      if (x?.id === newCodes.at(0)?.id) {
        return { ...x, isPrimary: true };
      }
      return x;
    });

    const deletedCode = (model?.find((code) => code?.id == id)?.code ??
      null) as string;

    handleInputChange(newModel);
    handleValidation(newModel);
    setModel(newModel);
    dispatch(
      removeMedicationCode({
        storageType,
        id,
        itemUid: item.uid,
        deletedCode,
      })
    );
  };

  const handlePrimaryCode = (id: number) => {
    const primaryModel = [...model];
    const newModel = primaryModel.map((x) => {
      if (x?.isPrimary === true) {
        return { ...x, isPrimary: false };
      }
      if (x?.id === id) {
        return { ...x, isPrimary: true };
      }
      return x;
    });

    const sortedModel = [...newModel].sort(
      (a, b) => Number(b?.isPrimary) - Number(a?.isPrimary)
    );
    setModel(sortedModel);
    handleInputChange(sortedModel ?? []);
  };

  const handleAddCode = (newValue: SearchResult) => {
    const allowAddCodes = isAllowedToAddCodes(
      item.options.allowedCodeCategory,
      newValue.codeCategory ?? MedicationCodeCategoryType.None
    );

    setShowErrorBar(!allowAddCodes);

    if (!allowAddCodes) {
      setErrorMessage(item.options.disAllowedCodeMessage);
      return;
    }

    const newlyAddedCode = Object.assign(
      { isPrimary: item.value!.length <= 0 },
      newValue
    );

    const newCodes = model
      ? [...model, newlyAddedCode as unknown as MedicationCodesActionValue]
      : [newlyAddedCode as unknown as MedicationCodesActionValue];
    setModel(newCodes);

    setHighlightedCode(newlyAddedCode.id);
    handleInputChange(newCodes ?? []);
    handleValidation(newCodes ?? []);

    dispatch(
      addMedicationCode({
        storageType,
        itemUid: item.uid,
        code: newlyAddedCode as unknown as MedicationCodesActionValue,
      })
    );
    const time = new Date();
    time.setMilliseconds(time.getMilliseconds() + 500);
    restart(time);
  };

  const handleValidation = (newCodes: Maybe<MedicationCodesActionValue>[]) => {
    const result = validateMinAmountValues(
      newCodes.length,
      item.options.minRequired
    );
    setValidation(result);
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: item.uid,
          error: result.message ?? 'Medications Codes Error',
          fieldName: 'Medication Codes',
          categoryId: categoryId,
          isValid: !result.message,
        },
      })
    );
  };

  const { updateActionValueExtended } = useUpdateActionValue();
  const handleInputChange = (value: Maybe<MedicationCodesActionValue>[]) => {
    if (!checklistId) {
      return;
    }

    const data = {
      checklistId: Number(checklistId),
      componentId: 'i',
      id: item.uid,
      type: 'string',
      value: JSON.stringify(value),
    };
    if (autoSave) {
      updateActionValueExtended(data, item.uid);
    } else {
      props.updateChecklistItemInputValueOnSave(
        item,
        props.orderableIndex,
        data
      );
    }
  };
  return (
    <Box id={`uuid-${item.uid}`}>
      <Grid container>
        <GridValidationLineRow
          item
          xs={12}
          style={{
            display: validation.hasError ? 'block' : 'none',
          }}
        >
          <Typography
            variant="body1"
            style={{ color: COLORS.GREY100 }}
          >{`Add at least ${item.options.minRequired} ${
            item.options.codeLabel
          } ${
            item.options.codeLabel.toUpperCase().includes('ITEM') ? '' : 'item'
          }`}</Typography>
        </GridValidationLineRow>
        <GridLineRow item xs={12}>
          <TableGrid
            columns={columns}
            rows={(model ?? []) as unknown as SearchResult[]}
            getRowId={(row: SearchResult) => Number(row.id)}
          >
            <SelectionState
              selection={[highlightedCode]}
              defaultSelection={[]}
            />
            <SimpleTextProvider for={['code']} />
            <PrimaryIconProvider for={['isPrimary']} />
            <SimpleTextProvider for={['description']} />
            <DeleteByIdActionProvider for={['id']} />
            <SymphonyTable
              columnExtensions={columnsExtension}
              containerComponent={TableComponent}
              messages={{ noData: 'No items added' }}
            />
            <TableHeaderRow cellComponent={Grey10HeaderCellComponent} />
            <TableSelection
              highlightRow
              rowComponent={SelectedRowComponent}
              showSelectionColumn={false}
            />
          </TableGrid>
        </GridLineRow>
        {isUpdateActionValueRequestFailed && (
          <Typography style={{ paddingLeft: '43px', color: 'red' }}>
            Internal server error: Your changes could not be saved, because of
            technical issues.
          </Typography>
        )}
        {item?.value?.length === 0 ? (
          <Box px="64px" pt="14px">
            <Typography>Use the search box below to add items</Typography>
          </Box>
        ) : null}
        <MedicationCodesSearch
          minRequired={item.options.minRequired}
          maxAllowed={item.options.maxAllowed}
          type={item.options.type}
          selectedCodes={(item?.value ?? []) as unknown as SearchResult[]}
          readOnly={readOnly}
          onAddCode={handleAddCode}
          showErrorBar={showErrorBar}
          errorMessage={errorMessage ?? ''}
        />
      </Grid>
    </Box>
  );
};

export default MedicationCodes;
