import { Table } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import React from 'react';

export const StubHeaderCellComponent = (props: Table.CellProps) => (
  <Table.StubHeaderCell
    {...props}
    style={{
      fontSize: '12px',
      fontWeight: 500,
      padding: '10px 6px 5px 6px',
      color: COLORS.GREY100,
      backgroundColor: COLORS.GREY10,
    }}
  />
);
