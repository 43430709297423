import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import PriorAuthMessageFormatter from '../formatters/PriorAuthMessageFormatter';

const PriorAuthMessageProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PriorAuthMessageFormatter} {...props} />
);

export default PriorAuthMessageProvider;
