import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SuggestProvidersQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
  authRole?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type SuggestProvidersQuery = {
  __typename?: 'EntitiesQuery';
  suggestProviders: Array<{
    __typename?: 'SuggestProvider';
    id: number;
    name?: string | null;
    specialityName?: string | null;
  }>;
};

export const SuggestProvidersDocument = `
    query suggestProviders($term: String!, $authRole: String) {
  suggestProviders(term: $term, authRole: $authRole) {
    id
    name
    specialityName
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    suggestProviders: build.query<
      SuggestProvidersQuery,
      SuggestProvidersQueryVariables
    >({
      query: (variables) => ({ document: SuggestProvidersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSuggestProvidersQuery, useLazySuggestProvidersQuery } =
  injectedRtkApi;
