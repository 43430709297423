import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@mui/material';
import React from 'react';

export const CodeCell = (props: ILabelCellProps) => (
  <Table.Cell
    {...props}
    onClick={undefined}
    style={CellComponentStyle}
    data-cy="table-cell-type"
  >
    <Typography>{props.value ? props.value : 'Added'}</Typography>
  </Table.Cell>
);
