import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Checkbox } from '@mui/material';
import React from 'react';
import {
  updateDirtyTabs,
  updateOkToContactPhone,
} from 'store/patientdetails/patientDetailsSlice';
import { useDispatch } from 'react-redux';

interface IOkToContactProps extends ILabelCellProps {
  disabled?: boolean;
}

export const OkToContact = (props: IOkToContactProps) => {
  const { row, value: isOkToContact, disabled } = props;
  const dispatch = useDispatch();

  const updateOkToContact = () => {
    dispatch(
      updateOkToContactPhone({
        id: row.type,
        value: !isOkToContact,
      })
    );
    dispatch(updateDirtyTabs('Background'));
  };

  return (
    <Table.Cell
      {...props}
      style={CellComponentStyle}
      data-cy="table-cell"
      onClick={undefined}
    >
      <Checkbox
        disabled={disabled}
        checked={isOkToContact ?? false}
        color="primary"
        onClick={updateOkToContact}
      />
    </Table.Cell>
  );
};
