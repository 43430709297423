import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import DeletePriorAuthRowActionFormatter from '../formatters/DeletePriorAuthRowActionFormatter';

const DeletePriorAuthRowActionProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={DeletePriorAuthRowActionFormatter}
    {...props}
  />
);

export default DeletePriorAuthRowActionProvider;
