import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type DiscardMozartWorkflowMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  checklistId: Types.Scalars['Int'];
}>;

export type DiscardMozartWorkflowMutation = {
  __typename?: 'Mutation';
  discardMozartWorkflow?: {
    __typename?: 'MozartActionResult';
    result: boolean;
    message?: string | null;
  } | null;
};

export const DiscardMozartWorkflowDocument = `
    mutation discardMozartWorkflow($id: UUID!, $checklistId: Int!) {
  discardMozartWorkflow(id: $id, checklistId: $checklistId) {
    result
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    discardMozartWorkflow: build.mutation<
      DiscardMozartWorkflowMutation,
      DiscardMozartWorkflowMutationVariables
    >({
      query: (variables) => ({
        document: DiscardMozartWorkflowDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDiscardMozartWorkflowMutation } = injectedRtkApi;
