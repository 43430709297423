import PriorAuthDisabledMessProvider from 'components/home/grid/providers/PriorAuthDisabledMessProvider';
import PriorAuthMessageProvider from 'components/home/grid/providers/PriorAuthMessageProvider';
import PriorAuthTextProvider from 'components/home/grid/providers/PriorAuthTextProvider';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import React from 'react';

export const PATextProvider = ({
  isDisabled,
  column,
}: {
  isDisabled: boolean;
  column: string;
}) => {
  return isDisabled ? (
    <PriorAuthTextProvider for={[column]} />
  ) : (
    <SimpleTextProvider for={[column]} />
  );
};

export const PAMessageProvider = ({
  isDisabled,
  column,
}: {
  isDisabled: boolean;
  column: string;
}) => {
  return isDisabled ? (
    <PriorAuthDisabledMessProvider for={[column]} />
  ) : (
    <PriorAuthMessageProvider for={[column]} />
  );
};
