import React from 'react';
import { Table, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';

const FilterRow = (props: Table.RowProps) => {
  return (
    <TableFilterRow.Row {...props} style={{ backgroundColor: COLORS.GREY4 }}>
      {props.children}
    </TableFilterRow.Row>
  );
};

export default FilterRow;
