import React from 'react';
import { Box, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

export interface IDetailedMenuItemProps {
  title?: string;
  details?: string | JSX.Element;
  icon: ICONS;
  iconColor: COLORS;
}

export const DetailedMenuItem = ({
  title,
  details,
  icon,
  iconColor,
}: IDetailedMenuItemProps) => {
  return (
    <Box display="flex">
      <Box marginRight="14px">
        <Icon
          icon={icon}
          size={12}
          color={iconColor}
          style={{ marginTop: '-1px' }}
        />
      </Box>
      <Box>
        <Box>
          <Typography variant="body2" noWrap>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" noWrap>
            {details}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
