import React, { useEffect, useState } from 'react';
import {
  BaseComponent,
  ComputedValueComponent,
  TimerScaleType,
  TimerValueType,
} from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { Moment } from 'moment';
import 'moment-business-days';
import {
  MOMENT_DATETIME_FORMAT,
  MOMENT_DATETIME_ISO_FORMAT,
  MOMENT_ISO_FORMAT,
} from 'components/constants';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import {
  ITimerCalculationOperand,
  calculateTimerValue,
} from './calculateTimerValue';

export interface ITimerProps {
  isActionSelected: boolean;
  component: ComputedValueComponent;
  storageType: ChecklistStorageType;
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent,
    componentId?: string
  ) => void;
}

type OptionalTimerScaleType = TimerScaleType | null | undefined;
type OptionalTimerValueType = TimerValueType | null | undefined;
type OptionalNumber = number | null | undefined;
type OptionalBoolean = boolean | null | undefined;

const StyledTypography = styled(Typography)<{ $error: boolean }>(
  ({ $error }) => ({
    color: $error ? COLORS.RED100 : 'inherit',
  })
);

const findComponent = (
  state: IState,
  storageType: ChecklistStorageType,
  userDefinedId: string
): ITimerCalculationOperand => {
  const checklistComponents =
    state.checklist?.documentsState[storageType]?.checklistComponents;
  if (!checklistComponents) {
    return { component: null };
  }

  const list = Object.values(checklistComponents).filter(
    (s) => s.component.userDefinedId === userDefinedId
  );
  if (list.length) {
    return { component: list[0]?.component };
  }

  return { component: null };
};

const hasError = (
  mode: OptionalTimerValueType,
  dangerValue: OptionalNumber,
  timerValue: number
) => {
  if (!dangerValue) {
    return false;
  }

  if (mode === TimerValueType.Countdown) {
    return timerValue < dangerValue;
  }

  return timerValue > dangerValue;
};

const getTargetDateFormat = (timeScale: OptionalTimerScaleType) => {
  if (timeScale === TimerScaleType.Hours) {
    return MOMENT_DATETIME_ISO_FORMAT;
  }
  return MOMENT_ISO_FORMAT;
};

const formatDisplayValue = (
  stringValue: string,
  date: Moment,
  showTargetDate: OptionalBoolean
) => {
  if (showTargetDate) {
    return `${stringValue} (${date.format(MOMENT_DATETIME_FORMAT)})`;
  }

  return stringValue;
};

const Timer = (props: ITimerProps) => {
  const { component, storageType, handleChecklistInputChange } = props;

  const {
    mode,
    fieldId: targetId,
    fieldSourceId: sourceId,
    daysType,
    showTargetDate,
    dangerValue,
    additionalValue: addValue,
    timeScale,
  } = component;

  const reduxValue = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistComponents[
        component.uniqueID
      ]?.component?.value
  );
  const [displayValue, setDisplayValue] = useState<string>(reduxValue ?? '');
  const [error, setError] = useState<boolean>(() => {
    let currentValue = Number.NaN;
    if (reduxValue && reduxValue !== 'N/A') {
      currentValue = Number(reduxValue);
    }
    return hasError(mode, dangerValue, currentValue);
  });
  const source = useSelector((state: IState) => {
    if (sourceId) {
      return findComponent(state, storageType, sourceId);
    }

    return null;
  });

  const target = useSelector((state: IState) => {
    if (targetId) {
      return findComponent(state, storageType, targetId);
    }

    return null;
  });

  useEffect(() => {
    const {
      date,
      isValid,
      value: timerValue,
    } = calculateTimerValue(
      source,
      target,
      mode,
      daysType,
      showTargetDate,
      addValue,
      timeScale
    );

    if (isValid) {
      const stringValue = timerValue.toString();
      const newDisplayValue = formatDisplayValue(
        stringValue,
        date,
        showTargetDate
      );

      if (!displayValue || displayValue !== newDisplayValue) {
        setDisplayValue(newDisplayValue);
        setError(hasError(mode, dangerValue, timerValue));
        handleChecklistInputChange(stringValue, component);

        if (showTargetDate) {
          const dateFormat = getTargetDateFormat(timeScale);
          handleChecklistInputChange(
            date.format(dateFormat),
            { ...component, type: 'string' },
            `${component.id}-ComputedTimerDate`
          );
        }
      }
    } else {
      if (!displayValue || displayValue !== 'N/A') {
        setDisplayValue('N/A');
        setError(false);
        if (reduxValue) {
          handleChecklistInputChange('', component);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target?.component?.value, source?.component?.value]);

  return <StyledTypography $error={error}>{displayValue}</StyledTypography>;
};

export default Timer;
