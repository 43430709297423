import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { useLocation, useParams } from 'react-router-dom';
import Footer from 'components/footer';
import Loader from 'components/loader';
import ErrorNotification from 'components/ErrorNotification';
import { ChecklistStorageType } from 'store/actions/types';
import { COLORS } from 'consts/styles';
import { useCurrentWorkflowStep, useGetChecklistForWorkflow } from './hooks';
import WorkflowLayoutPageContent from './WorkflowLayoutPageContent';
import { useFetchMainModel } from 'hooks';
import { Patient } from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { ActionSidebar } from 'features/mozart/ActionSidebar';
import FailedRequests from 'components/failedRequests/FailedRequests';
import ChecklistExitModal from 'components/modal/ChecklistExitModal';
import { resetChecklist } from 'store/actions/checklistSlice';
import theme from 'theme';
import UploadFilesLayout from 'components/uploadFiles/UploadFilesLayout';

const StyledContent = styled.div`
  justify-content: space-between;
  background-color: ${COLORS.GREY4};
  padding-top: 24px;
`;

const StyledContainer = styled.div`
  padding: 0 60px;
  min-height: 90vh;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: fit-content(25%) minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: ${theme.spacing(3)};

  @media (max-width: 1200px) {
    grid-template-columns: 25% minmax(0, 1fr);
  }
`;

const StyledBody = styled.div`
  padding-bottom: 80px;
  grid-column: 2;
  grid-row: span 2;
`;

const WorkflowLayout = () => {
  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST].checklist
  );

  const mainModel = useSelector((state: IState) => state.home.mainModel);
  const user = useSelector((state: IState) => state.user.currentUser);

  useFetchMainModel(mainModel.lookups.length === 0);

  const params = useParams();
  const location = useLocation();

  const checklistId = Number(params.id);
  const {
    currentUrl,
    isFinishPage,
    isReviewPage,
    isActionPage,
    workflowStep,
    workflowName,
  } = useCurrentWorkflowStep(
    location.pathname.slice(0, location.pathname.lastIndexOf('/'))
  );
  const { isLoading, patientData } = useGetChecklistForWorkflow(
    checklistId,
    workflowStep,
    workflowName,
    isActionPage,
    ChecklistStorageType.CHECKLIST
  );

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetChecklist(ChecklistStorageType.CHECKLIST));
      dispatch(resetChecklist(ChecklistStorageType.CAREPLAN));
    };
  }, [dispatch, checklistId]);

  return (
    <>
      <ErrorNotification
        storageType={ChecklistStorageType.CHECKLIST}
        categories={checklist?.categories ?? []}
      />
      <FailedRequests storageType={ChecklistStorageType.CHECKLIST} />
      {mainModel.lookups.length > 0 ? (
        <StyledContent>
          <StyledContainer>
            <StyledGrid>
              <ActionSidebar
                patientData={patientData}
                isFinishPage={isFinishPage}
                isReviewPage={isReviewPage}
                checklistStorageType={ChecklistStorageType.CHECKLIST}
              />
              <StyledBody>
                {!isLoading && (
                  <WorkflowLayoutPageContent
                    autoSave={isActionPage}
                    isFinishPage={isFinishPage}
                    isReviewPage={isReviewPage}
                    isActionPage={isActionPage}
                    currentUser={user}
                    patient={patientData?.getPatientById as Patient}
                  />
                )}
              </StyledBody>
            </StyledGrid>
          </StyledContainer>
          <Loader active={isLoading} />
          <Footer
            variant={currentUrl}
            storageType={ChecklistStorageType.CHECKLIST}
          />
        </StyledContent>
      ) : null}
      <UploadFilesLayout checklistStorage={ChecklistStorageType.CHECKLIST} />
      <ChecklistExitModal checklistStorage={ChecklistStorageType.CHECKLIST} />
    </>
  );
};

export default WorkflowLayout;
