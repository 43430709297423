import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import DateComponent from 'components/DateComponent/DateComponent';
import { openHomeSearch, openHomeAdvancedSearch } from 'store/home/action';
import { useDispatch } from 'react-redux';
import { useGetRecentSearchesQuery } from 'graphql/hooks/getRecentSearches';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import { useGetAdvancedSearchRequiredFieldsQuery } from 'graphql/hooks/getAdvancedSearchOption';
import { IAdvancedSearchCriteria } from 'backend/types/advancedSearchCriteria';
import { DATE_ISO_FORMAT, DATE_FORMAT } from 'components/constants';
import IconButton from '@mui/material/IconButton';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { setRemoveFilterChips } from 'store/ui/header';
import { filterChipInitialValues } from './MemberSearchBar';
import { isValid } from 'date-fns';

const StyledBoldTypography = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 4px;
`;

const StyledDiv = styled.div`
  width: 448px;
  box-sizing: border-box;
  padding: 30px;
`;

const StyledSpan = styled.span`
  color: red;
`;
interface IAdvancedSearchPopover {
  open: boolean;
  id: string | undefined;
  handleClose: () => void;
  anchorEl: Element | null;
  showPopover: boolean;
  setRecentOpen: (boolean: boolean) => void;
  setShowPopover: (boolean: boolean) => void;
  resetAdvancedSearch: boolean;
  setResetAdvancedSearch: (boolean: boolean) => void;
  setIsAdvancedSearch: (boolean: boolean) => void;
  setFilterChip: (value: IAdvancedSearchCriteria) => void;
}

const AdvancedSearchPopover = (props: IAdvancedSearchPopover) => {
  const {
    open,
    id,
    handleClose,
    anchorEl,
    showPopover,
    setShowPopover,
    setRecentOpen,
    resetAdvancedSearch,
    setResetAdvancedSearch,
    setIsAdvancedSearch,
    setFilterChip,
  } = props;

  const dispatch = useDispatch();
  const { refetch } = useGetRecentSearchesQuery();
  const requiredFields = useGetAdvancedSearchRequiredFieldsQuery().data;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [inputDate, setInputDate] = useState<string | undefined>('');

  const initialValidationErrors = {
    firstName: false,
    lastName: false,
    dob: false,
    phoneNumber: false,
  };

  const [validationErrors, setValidationErrors] = useState(
    initialValidationErrors
  );

  useEffect(() => {
    if (resetAdvancedSearch) {
      setFirstName('');
      setLastName('');
      setDateOfBirth(null);
      setPhoneNumber('');
      setResetAdvancedSearch(false);
      setValidationErrors(initialValidationErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAdvancedSearch, setResetAdvancedSearch]);

  let formattedDate = '';
  const advancedSearchCriteria: IAdvancedSearchCriteria = {};

  const hasValidationErrors = () => {
    let isError = false;
    if (
      inputDate != null &&
      inputDate !== undefined &&
      inputDate !== '' &&
      !isValid(dateOfBirth)
    ) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, dob: true }));
      isError = true;
    } else {
      formattedDate = !isValid(dateOfBirth)
        ? ''
        : formatDate(dateOfBirth, DATE_FORMAT, true);
    }
    if (requiredFields?.getAdvancedSearchRequiredFields) {
      if (
        firstName.trim().length === 0 &&
        requiredFields?.getAdvancedSearchRequiredFields?.includes('FirstName')
      ) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          firstName: true,
        }));
        isError = true;
      }
      if (
        lastName.trim().length === 0 &&
        requiredFields?.getAdvancedSearchRequiredFields?.includes('LastName')
      ) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          lastName: true,
        }));
        isError = true;
      }
      if (
        requiredFields?.getAdvancedSearchRequiredFields?.includes('DOB') &&
        formattedDate === ''
      ) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, dob: true }));
        isError = true;
      }
    }
    return isError;
  };

  const handleQuickSearchClick = () => {
    setResetAdvancedSearch(true);
    setFilterChip(filterChipInitialValues);
    setShowPopover(false);
    setIsAdvancedSearch(false);
    setRecentOpen(true);
  };

  const setAdvancedSearchCriteria = () => {
    try {
      if (formattedDate === '') {
        advancedSearchCriteria.dob = '';
      } else {
        advancedSearchCriteria.dob = formatDate(
          dateOfBirth,
          DATE_ISO_FORMAT,
          true
        );
      }
      setFilterChip({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phoneNumber: phoneNumber
          ? phoneNumber.replace(/\D/g, '')
          : phoneNumber?.trim(),
        dob: formattedDate,
      });
    } catch (error) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, dob: true }));
      return;
    }
    advancedSearchCriteria.firstName = firstName.trim();
    advancedSearchCriteria.lastName = lastName.trim();
    advancedSearchCriteria.phoneNumber = phoneNumber
      ? phoneNumber.replace(/\D/g, '')
      : phoneNumber?.trim();
  };

  const handleAdvancedSearch = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement | MouseEvent | HTMLLIElement>
  ) => {
    setIsAdvancedSearch(true);
    event.preventDefault();
    event.stopPropagation();

    if (!hasValidationErrors()) {
      setAdvancedSearchCriteria();
      dispatch(setRemoveFilterChips(false));
      if (
        advancedSearchCriteria.firstName === '' &&
        advancedSearchCriteria.lastName === '' &&
        advancedSearchCriteria.dob === '' &&
        advancedSearchCriteria.phoneNumber === ''
      ) {
        dispatch(openHomeSearch(''));
      } else {
        dispatch(openHomeAdvancedSearch(advancedSearchCriteria));
      }
      refetch();
      setShowPopover(false);
    } else {
      return;
    }
  };

  return (
    <>
      {showPopover && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: -40,
          }}
          slotProps={{
            paper: {
              style: {
                marginTop: '4px',
                marginLeft: '42px',
                border: '1px solid #CECECE',
                boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: '4px',
              },
            },
          }}
          title={'Advanced Search'}
          style={{ width: 450, padding: '28px' }}
        >
          <StyledDiv>
            <form>
              <Grid container spacing={2}>
                <Grid item>
                  <StyledBoldTypography>
                    {`Please input exact values for the search query:`}
                  </StyledBoldTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledTypography>
                    First Name{' '}
                    {requiredFields?.getAdvancedSearchRequiredFields?.includes(
                      'FirstName'
                    ) ? (
                      <StyledSpan>*</StyledSpan>
                    ) : (
                      ''
                    )}
                  </StyledTypography>
                  <TextField
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    type="text"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    onChange={(event) => {
                      setFirstName(event.target.value);
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        firstName: false,
                      }));
                    }}
                    required={requiredFields?.getAdvancedSearchRequiredFields?.includes(
                      'FirstName'
                    )}
                    error={validationErrors.firstName}
                    InputProps={{
                      endAdornment: firstName && (
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => {
                            setFirstName('');
                          }}
                        >
                          <Icon
                            icon={ICONS.Remove}
                            size={18}
                            color={COLORS.GREY100}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledTypography>
                    Last Name{' '}
                    {requiredFields?.getAdvancedSearchRequiredFields?.includes(
                      'LastName'
                    ) ? (
                      <StyledSpan>*</StyledSpan>
                    ) : (
                      ''
                    )}
                  </StyledTypography>
                  <TextField
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    type="text"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    onChange={(event) => {
                      setLastName(event.target.value);
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        lastName: false,
                      }));
                    }}
                    error={validationErrors.lastName}
                    InputProps={{
                      endAdornment: lastName && (
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => {
                            setLastName('');
                          }}
                        >
                          <Icon
                            icon={ICONS.Remove}
                            size={18}
                            color={COLORS.GREY100}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledTypography>
                    Date of Birth{' '}
                    {requiredFields?.getAdvancedSearchRequiredFields?.includes(
                      'DOB'
                    ) ? (
                      <StyledSpan>*</StyledSpan>
                    ) : (
                      ''
                    )}
                  </StyledTypography>
                  <DateComponent
                    value={dateOfBirth}
                    placeholder="mm/dd/yyyy"
                    onChange={(date, inputValue) => {
                      setDateOfBirth(date);
                      setInputDate(inputValue);
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        dob: false,
                      }));
                    }}
                    error={validationErrors.dob}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledTypography>
                    Phone Number{' '}
                    {requiredFields?.getAdvancedSearchRequiredFields?.includes(
                      'PhoneNumber'
                    ) ? (
                      <StyledSpan>*</StyledSpan>
                    ) : (
                      ''
                    )}
                  </StyledTypography>
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phoneNumber}
                    type="text"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    onChange={(event) => {
                      setPhoneNumber(event.target.value);
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        lastName: false,
                      }));
                    }}
                    error={validationErrors.lastName}
                    InputProps={{
                      endAdornment: lastName && (
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => {
                            setPhoneNumber('');
                          }}
                        >
                          <Icon
                            icon={ICONS.Remove}
                            size={18}
                            color={COLORS.GREY100}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ paddingTop: '20px' }}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-start">
                    <Button
                      onClick={handleQuickSearchClick}
                      variant="text"
                      color="primary"
                      size="small"
                      data-cy="cancel-provider-filters"
                    >
                      Quick Search
                    </Button>
                    <Button
                      onClick={(e) => handleAdvancedSearch(e)}
                      color="primary"
                      size="small"
                      variant="contained"
                      data-cy="ok-provider-filters"
                      style={{ marginLeft: '140px' }}
                    >
                      Search
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </StyledDiv>
        </Popover>
      )}
    </>
  );
};

export default AdvancedSearchPopover;
