import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import styled from 'styled-components';
import { formatDate } from 'util/helpers/dateTimeHelpers';

const StyledRow = styled(Box)({
  marginBottom: '20px',
  display: 'flex',
});

const ClaimsDetails = () => {
  const [selectedClaim] =
    useSelector(
      (state: IState) =>
        state.patientDetails.medicationsTab.selectedMedicationItems
    ) ?? [];

  const claimsData = useSelector((state: IState) =>
    state.patientDetails.medicationsTab.medicationsClaims.find(
      (item) => item.id === selectedClaim
    )
  );
  return (
    <Box>
      <StyledRow>
        <Typography variant="body2">Medication:&nbsp;</Typography>
        <Typography>{claimsData?.medication}</Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Prescribing Provider:&nbsp;</Typography>
        <Typography>{claimsData?.provider?.name}</Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Pharmacy:&nbsp;</Typography>
        <Typography>{claimsData?.pharmacy}</Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Date Dispensed:&nbsp;</Typography>
        <Typography>
          {claimsData?.dispenseDate && formatDate(claimsData?.dispenseDate)}
        </Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Quantity Dispensed:&nbsp;</Typography>
        <Typography>{claimsData?.quantityDispensed}</Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Paid Amount:&nbsp;</Typography>
        <Typography>{claimsData?.paidAmount}</Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Billing Provider Address:&nbsp;</Typography>
        <Typography>{claimsData?.billingProvider?.address}</Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Billing Provider City:&nbsp;</Typography>
        <Typography>{claimsData?.billingProvider?.city}</Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Billing Provider Zip:&nbsp;</Typography>
        <Typography>{claimsData?.billingProvider?.zip}</Typography>
      </StyledRow>
      <StyledRow>
        <Typography variant="body2">Billing Provider Phone:&nbsp;</Typography>
        <Typography>{claimsData?.billingProvider?.phone}</Typography>
      </StyledRow>
    </Box>
  );
};

export default ClaimsDetails;
