import { MedicationCode, PatientClinicHospital } from 'graphql/graphqlTypes';
import ClaimsDetailsClose from './ClaimsDetailsClose';
import ClaimsDetailsItem from './ClaimsDetailsItem';
import React from 'react';

export const ClaimsDetails = (props: {
  selectedClaimId: number | null;
  setSelectedClaimId: (value: number | null) => void;
  claims: PatientClinicHospital[];
}) => {
  const found = props.claims.find((x) => x.id === props.selectedClaimId);

  const getClaimAttribute = (
    name: keyof PatientClinicHospital,
    subName?: keyof MedicationCode
  ) => (subName ? found?.[name]?.[subName] : found?.[name]);

  return (
    <>
      <ClaimsDetailsClose resetSelectedClaimId={props.setSelectedClaimId} />
      <div style={{ width: '100%' }}>
        <ClaimsDetailsItem
          value={getClaimAttribute('claimId')}
          label={'Claim ID'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('claimDate')}
          label={'Claim Date'}
          isDate={true}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('diagnosis', 'shortDescription')}
          label={'Diagnosis'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('performingProvider')}
          label={'Performing Provider'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('medProcedure', 'shortDescription')}
          label={'Procedure'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('billingProviderName')}
          label={'Billing Provider Name'}
        />
        <ClaimsDetailsItem
          value={getClaimAttribute('paidAmount')}
          label={'Paid Amount'}
          isMoney={true}
        />
      </div>
    </>
  );
};
