import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import RecentFormatter from './RecentFormatter';

const RecentProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={RecentFormatter} {...props} />
);

export default RecentProvider;
