import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, OutlinedInput } from '@mui/material';
import { ILoginUser } from 'store/user/types';
import { IState } from 'store';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { loginUser } from 'store/user/middlewares';
import { useNavigate } from 'react-router';

const StyledWrapper = styled.div`
  min-width: 308px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
`;

const StyledInputWrapper = styled.div`
  margin-top: 40px;
`;

const StyledLabel = styled(Typography)({
  color: COLORS.GREY100,
  paddingBottom: '9px',
});

const StyledFormControl = styled.div`
  margin-top: 25px;
`;

const StyledOutlinedInput = styled(OutlinedInput)({
  '.MuiInputBase-input': {
    height: '40px',
  },
  color: COLORS.BLACK10,
  'input:-internal-autofill-selected': {
    backgroundColor: 'transparent!important',
  },
});

const StyledHeader = styled(Typography)({
  color: COLORS.BLACK,
  fontWeight: 300,
  paddingBottom: '25px',
});

const StyledErrorMessage = styled(Typography)({
  color: COLORS.RED100,
  marginTop: '10px',
});

export const PasswordResetForm = () => {
  const serverError = useSelector((state: IState) => state.user.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backHandler = () => navigate(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginUser>();
  const onSubmit = (newValues: ILoginUser) => {
    dispatch(loginUser(newValues, 'passwordReset'));
  };

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledHeader variant="h2">Password Reset</StyledHeader>
        {errors.login && (
          <StyledErrorMessage>Please provide a username</StyledErrorMessage>
        )}
        {!errors.login && serverError && (
          <StyledErrorMessage>{serverError}</StyledErrorMessage>
        )}
        <StyledFormControl>
          <StyledLabel>Username</StyledLabel>
          <StyledOutlinedInput
            id="login"
            placeholder="Login"
            type="text"
            style={{ height: '54px' }}
            fullWidth={true}
            {...register('login', { required: true })}
            data-cy="login"
          />
        </StyledFormControl>
        <StyledInputWrapper>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={backHandler}
            style={{ width: '40%', marginRight: '20%' }}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
            data-cy="enter-phone"
            style={{ width: '40%' }}
          >
            Next
          </Button>
        </StyledInputWrapper>
      </form>
    </StyledWrapper>
  );
};
