import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { getMainModelSuccess } from 'store/home/action';
import { ILookupValue } from 'backend/types/lookupValue';
import { ILookup } from 'backend/types/lookup';
import { ISystemValue } from 'backend/types/systemValue';
import { useDispatch } from 'react-redux';
import { useGetMainModelQuery } from 'graphql/hooks/getMainModel';
import { User } from 'graphql/graphqlTypes';

/**
 * a type-safe version of the `usePrevious` hook described here:
 * @see {@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state}
 */
export const usePrevious = <T>(
  value: T
): MutableRefObject<T | undefined>['current'] => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
};

export const useFetchMainModel = (refetchModel: boolean) => {
  const { data: mainModelData, isSuccess } = useGetMainModelQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !refetchModel,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (mainModelData && refetchModel && isSuccess) {
      dispatch(
        getMainModelSuccess(
          (mainModelData.getAccountUsers as User[]) ?? ([] as User[]),
          (mainModelData.getAttributeLookups as ILookup[]) ?? ([] as ILookup[]),
          (mainModelData.getEnabledPopulations as ILookupValue[]) ??
            ([] as ILookupValue[]),
          (mainModelData.getSystemValues?.nodes as ISystemValue[]) ??
            ([] as ISystemValue[])
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainModelData, refetchModel, isSuccess]);
};
