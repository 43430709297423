import React from 'react';
import { GetHtmlId } from 'components/actions/navbar';
import { ChecklistStorageType } from 'store/actions/types';
import {
  ChecklistActionDataInput,
  ChecklistCategory,
  ChecklistProblemViewModel,
} from 'graphql/graphqlTypes';
import SectionHeader from 'components/actions/sections/SectionHeader';
import SectionItem from 'components/actions/sections/SectionItem';
import { setActiveSectionIndex } from 'store/actions/checklistSlice';
import { useDispatch } from 'react-redux';

export interface ISectionsBodyProps {
  storageType: ChecklistStorageType;
  autoSave: boolean;
  inputData?: ChecklistActionDataInput[];
  setInputData?: (data: ChecklistActionDataInput[]) => void;
  hideNumbers?: boolean;
  carePlanProblems: ChecklistProblemViewModel[];
  updateCarePlanProblems: (problems: ChecklistProblemViewModel[]) => void;
  category: ChecklistCategory;
  categoryIndex: number;
  patientId: number;
}

const SectionsBody = (props: ISectionsBodyProps) => {
  const {
    storageType,
    inputData,
    setInputData,
    hideNumbers,
    carePlanProblems,
    updateCarePlanProblems,
    category,
    categoryIndex,
    patientId,
  } = props;
  const dispatch = useDispatch();

  const handleSetActiveSection = () => {
    dispatch(
      setActiveSectionIndex({
        storageType,
        activeSection: {
          id: categoryIndex,
          categoryId: category.id,
        },
      })
    );
  };
  return (
    <div
      data-cy={GetHtmlId(category.name, category.id)}
      style={{
        paddingTop:
          storageType === ChecklistStorageType.MOZART && categoryIndex !== 1
            ? '24px'
            : '0px',
        position: 'relative',
      }}
      onClick={handleSetActiveSection}
    >
      <SectionHeader
        storageType={storageType}
        category={category}
        hideNumbers={hideNumbers}
        categoryIndex={categoryIndex}
      />
      <SectionItem
        storageType={storageType}
        autoSave={props.autoSave}
        category={category}
        setInputData={setInputData}
        inputData={inputData}
        carePlanProblems={carePlanProblems}
        updateCarePlanProblems={updateCarePlanProblems}
        patientId={patientId}
      />
    </div>
  );
};

export default SectionsBody;
