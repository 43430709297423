import React from 'react';
import { Popper, PopperProps } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

export interface IEpisodePopperProps extends PopperProps {
  onViewAllClick?: () => void;
  onCantFindClick?: () => void;
  totalCount: number;
}

const StyledFooter = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 24px 28px 24px',
});

const StyledLink = styled('a')({
  cursor: 'pointer',
  fontWeight: 500,
  letterSpacing: '0.5px',
  color: COLORS.SYMPHONY_BLUE,
});

export const EpisodePopper = ({
  onViewAllClick,
  onCantFindClick,
  totalCount,
  ...rest
}: IEpisodePopperProps) => {
  const Paper = rest.children as JSX.Element;
  return (
    <Popper
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
      {...rest}
    >
      <Paper.type {...Paper.props}>
        {Paper.props.children}
        <StyledFooter>
          <StyledLink onMouseDown={() => onCantFindClick?.()}>
            I can&apos;t find Episode
          </StyledLink>
          <StyledLink onMouseDown={() => onViewAllClick?.()}>
            View all ({totalCount})
          </StyledLink>
        </StyledFooter>
      </Paper.type>
    </Popper>
  );
};
