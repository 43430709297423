import { AddressDto, EmailDto, PhoneDto } from 'graphql/graphqlTypes';
import { omit } from 'lodash';

const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (email: string): boolean =>
  validEmailRegex.test(String(email).toLowerCase());

const MainColumns = ['main', 'cell', 'home'];

export const getVisibleRows = <T extends AddressDto | EmailDto | PhoneDto>(
  rows: T[]
): {
  [key: string]: boolean;
} =>
  rows
    .map((object) => ({
      key: object.type,
      isVisible:
        MainColumns.includes(object.type.toLowerCase()) ||
        Object.values(omit(object, ['type'])).some(Boolean),
    }))
    .reduce((obj, item) => ({ ...obj, [item.key]: item.isVisible }), {});
