import { IState } from 'store';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  QueryArgFrom,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isEqual } from 'lodash';

export const useData = <
  ReduxResultType,
  ApiResultType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends QueryDefinition<any, any, string, ApiResultType>
>(
  selector: (state: IState) => ReduxResultType | undefined,
  useLazyQuery: UseLazyQuery<D>,
  getDataParam: QueryArgFrom<D>,
  dispatchMessage: (data: ApiResultType) => unknown,
  skip: boolean
): { data: ReduxResultType | undefined; isFetching: boolean } => {
  const dispatch = useDispatch();
  const stateData = useSelector(selector);
  const [getData, queryState, { lastArg }] = useLazyQuery();

  if (skip) {
    throw new Error('skip is not supported!');
  }

  useEffect(() => {
    if (!stateData && queryState.isUninitialized) {
      getData(getDataParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateData) {
      return;
    }

    if (queryState.isLoading || queryState.isError || queryState.isFetching) {
      return;
    }

    const isFresh = isFreshResponse(queryState);
    if (
      queryState.isSuccess &&
      queryState.data &&
      isFresh &&
      isEqual(getDataParam, lastArg)
    ) {
      dispatch(dispatchMessage(queryState.data));
    } else {
      getData(getDataParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData, queryState, lastArg, getDataParam]);

  return {
    data: stateData,
    isFetching: queryState.isFetching || queryState.isLoading,
  };
};

type QueryState = {
  fulfilledTimeStamp?: number;
  startedTimeStamp?: number;
};
const getRequestTiming = (queryState: QueryState): number | undefined => {
  if (!queryState.fulfilledTimeStamp || !queryState.startedTimeStamp) {
    return undefined;
  }
  const diff = queryState.fulfilledTimeStamp - queryState.startedTimeStamp;
  return diff <= 0 ? undefined : diff;
};

const getSinceResponse = (queryState: QueryState): number | undefined => {
  if (!queryState.fulfilledTimeStamp) {
    return undefined;
  }
  return new Date().getTime() - queryState.fulfilledTimeStamp;
};

const isFreshResponse = (queryState: QueryState) => {
  const sinceResponse = getSinceResponse(queryState);
  const requestTiming = getRequestTiming(queryState);
  return sinceResponse && requestTiming && sinceResponse < requestTiming;
};
