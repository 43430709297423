import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetProviderDetailsQueryVariables = Types.Exact<{
  providerId: Types.Scalars['Int'];
  patientId: Types.Scalars['Int'];
  addressId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetProviderDetailsQuery = {
  __typename?: 'EntitiesQuery';
  getProviderDetails: {
    __typename?: 'ProviderDetailsViewModel';
    id: string;
    lastName?: string | null;
    firstName?: string | null;
    number?: string | null;
    npi?: string | null;
    taxId?: string | null;
    practiceNamesLabel: string;
    practiceNames: Array<string>;
    types: Array<string>;
    addresses: Array<string>;
    addressIdsLabel: string;
    addressIds: Array<string>;
    parNotes: string;
    seamless?: boolean | null;
    dPG?: { __typename?: 'LookupValue'; name?: string | null } | null;
  };
};

export const GetProviderDetailsDocument = `
    query getProviderDetails($providerId: Int!, $patientId: Int!, $addressId: Int) {
  getProviderDetails(
    providerId: $providerId
    patientId: $patientId
    addressId: $addressId
  ) {
    id
    lastName
    firstName
    number
    npi
    taxId
    practiceNamesLabel
    practiceNames
    types
    addresses
    addressIdsLabel
    addressIds
    parNotes
    dPG {
      name
    }
    seamless
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProviderDetails: build.query<
      GetProviderDetailsQuery,
      GetProviderDetailsQueryVariables
    >({
      query: (variables) => ({
        document: GetProviderDetailsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProviderDetailsQuery, useLazyGetProviderDetailsQuery } =
  injectedRtkApi;
