import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { last } from 'lodash';
import { ICONS } from 'components/icon';
import Loader from 'components/loader';
import { COLORS } from 'consts/styles';
import { StyledSectionWrapper } from '../MozartContent/components';
import { MozartActionSection } from './MozartActionSection';
import { MozartStep } from '../MozartStep/MozartStep';
import { useRetryMozartWorkflowMutation } from 'graphql/hooks/retryMozartWorkflow';
import {
  mozartResetChecklist,
  mozartStartPolling,
  setMozartInstanceState,
} from 'store/actions/mozartSlice';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { resetChecklist } from 'store/actions/checklistSlice';
import {
  isWorkflowInErrorState,
  isWorkflowInPermanentFailureState,
} from 'features/mozart/MozartWorkflowLayout.helpers';

export const MozartRetryAction = ({
  isLoadingComplete,
}: {
  isLoadingComplete: boolean;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const refAutoFocus = useRef<HTMLDivElement>(null);
  const [retryWorkflow] = useRetryMozartWorkflowMutation();
  const mozartMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );
  const message = last(mozartMessages);
  const maxRetryCount = 2;
  const canRetry = message && !isWorkflowInPermanentFailureState(message);
  const stepTitle = canRetry ? 'Retry Workflow' : 'Exception';

  useEffect(() => {
    setTimeout(() => {
      if (isLoadingComplete && refAutoFocus.current) {
        window.scrollTo({
          behavior: 'smooth',
          top: refAutoFocus.current.offsetTop,
        });
      }
    }, 300);
  }, [isLoadingComplete]);

  const getActionText = (retryCount: number | null | undefined): string => {
    return retryCount == null || retryCount <= maxRetryCount
      ? 'Mozart returned an exception. Click Retry to try again.'
      : 'Please check your network connection. You may want to close your browser and log in again, if the Retry continues to not resolve the issue.';
  };
  const handleRetryAction = () => {
    if (message) {
      setIsLoading(true);
      retryWorkflow({
        id: message.workflowInstanceId,
      }).then((result) => {
        setIsLoading(false);
        if ('data' in result && result?.data?.retryMozartWorkflow?.result) {
          dispatch(resetChecklist(ChecklistStorageType.MOZART));
          dispatch(mozartResetChecklist());
          dispatch(setMozartInstanceState(''));
          dispatch(mozartStartPolling());
        } else {
          dispatch(
            showErrorPopup({
              message: 'Failed to retry workflow.',
            })
          );
        }
      });
    }
  };

  if (message && isWorkflowInErrorState(message)) {
    return (
      <StyledSectionWrapper ref={refAutoFocus}>
        <MozartStep currentStep={stepTitle} message={message} />
        {canRetry ? (
          <MozartActionSection
            actionButtonText="Retry"
            actionIcon={ICONS.Refresh}
            actionIconColor={COLORS.GREY100}
            actionText={getActionText(message?.retryCount)}
            actionTitle="Oops!"
            actionButtonHandler={handleRetryAction}
            borderColor={COLORS.GREY25}
          />
        ) : (
          <MozartActionSection
            actionIcon={ICONS.Warning}
            actionIconColor={COLORS.GREY100}
            actionText="Mozart returned an exception."
            actionTitle="Oops!"
            borderColor={COLORS.GREY25}
          />
        )}
        <Loader active={isLoading} />
      </StyledSectionWrapper>
    );
  } else {
    return null;
  }
};
