import React from 'react';
import Typography from '@mui/material/Typography';
import SectionNumber from '../sectionNumber';
import { ChecklistStorageType } from 'store/actions/types';
import CarePlan from './SectionBody/CarePlan/carePlan';
import {
  ChecklistCategory,
  ChecklistProblemViewModel,
} from 'graphql/graphqlTypes';
import SectionPanel from 'components/panel/sectionPanel';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setActiveSectionIndex } from 'store/actions/checklistSlice';

const StyledCategoryWrapper = styled.div`
  display: flex;
  padding: 0 28px 16px;
  align-items: center;
`;

export interface ICarePlanSectionProps {
  storageType: ChecklistStorageType;
  hideNumbers?: boolean;
  carePlanProblems?: ChecklistProblemViewModel[];
  updateCarePlanProblems?: (problems: ChecklistProblemViewModel[]) => void;
  carePlanIndex: number;
  categoryIndex: number;
}

const CarePlanSection = (props: ICarePlanSectionProps) => {
  const {
    storageType,
    hideNumbers,
    carePlanProblems,
    updateCarePlanProblems,
    carePlanIndex,
    categoryIndex,
  } = props;

  const dispatch = useDispatch();

  const handleSetActiveSection = () => {
    dispatch(
      setActiveSectionIndex({
        storageType,
        activeSection: {
          id: carePlanIndex,
          categoryId: 'careplan',
        },
      })
    );
  };

  return (
    <SectionPanel
      padding="0px 0px 28px 0px"
      key={carePlanIndex}
      sectionIndex={carePlanIndex}
      storageType={storageType}
    >
      <div data-cy={`category-careplan`} onClick={handleSetActiveSection}>
        <StyledCategoryWrapper id="care-plan-careplan">
          {!hideNumbers && (
            <SectionNumber
              number={carePlanIndex}
              categoryId={categoryIndex.toString()}
              isReadOnly={false}
              size="large"
              category={
                { id: 'careplan', name: 'Care Plan' } as ChecklistCategory
              }
              storageType={ChecklistStorageType.CHECKLIST}
            />
          )}
          <Typography variant="h4" style={{ marginLeft: '5px' }}>
            Problem List
          </Typography>
        </StyledCategoryWrapper>
        <StyledCategoryWrapper>
          <div style={{ paddingLeft: '36px' }}>
            <CarePlan
              carePlanProblems={carePlanProblems}
              updateCarePlanProblems={updateCarePlanProblems}
            />
          </div>
        </StyledCategoryWrapper>
      </div>
    </SectionPanel>
  );
};

export default CarePlanSection;
