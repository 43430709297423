/* eslint-disable react/prop-types */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ILetter, IMessageState, SendFax } from '..';
import Loader from 'components/loader';
import { FaxForm, FaxFormData } from '.';
import { useMutationEffect } from '../hooks';
import apiClient from 'util/axiosHttp';
import { useGetFaxDialogModelQuery } from 'graphql/hooks/getFaxDialogViewModel';
import { useOnSendFaxMutation } from 'graphql/hooks/onSendFax';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { LetterType, User } from 'graphql/graphqlTypes';
import { commitAttachments } from 'store/patientdetails/patientDetails.attachment.slice';
import { refreshTimelineGridData } from 'store/patientdetails/patientDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { buildFaxRequest, previewDisplay } from './FaxDetails.helpers';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import styled from 'styled-components';
import ChecklistPreviewWrapper from 'components/checklistViewPrint/ChecklistPreviewWrapper';
import { ChecklistStorageType } from 'store/actions/types';
import { updateFaxState } from 'store/ui/print/documents';

export interface IFaxDetails {
  letter?: ILetter;
  attachmentId?: number;
  checklistId: number;
  episodeId: number;
  patientId: number;
  mode: 'letters' | 'fax';
  onErrors: () => void;
  onFilled: () => void;
  setMessage: (message: IMessageState) => void;
  setPreview: (preview: string | null) => void;
  setLoading: (loading: boolean) => void;
  setSendFax: (sendFax: boolean) => void;
  setFaxFormData: (faxFormData: FaxFormData) => void;
  setFaxLabel: (faxLabel: boolean) => void;
  isSelectedView: boolean;
}

const faxSuccess: IMessageState = {
  active: true,
  type: 'success',
  title: 'Success',
  text: 'Successfully faxed',
};

const faxError: IMessageState = {
  active: true,
  type: 'error',
  title: 'Error',
  text: 'An unexpected error occurred when faxing',
};

const previewSuccess: IMessageState = {
  active: true,
  type: 'success',
  title: 'Success',
  text: `Preview generated`,
};

const previewError: IMessageState = {
  active: true,
  type: 'error',
  title: 'Something went wrong',
  text: 'Could not generate the preview',
};

const HiddenContainer = styled.div`
  display: none;
`;

const getFaxDialogModelQueryParameters = (
  episodeId: number,
  letter?: ILetter
) => ({
  episodeId: episodeId > 0 ? episodeId : undefined,
  letterId: letter && letter.id > 0 ? letter.id : undefined,
  letterType: letter?.type,
});

enum ChecklistLoadingStatus {
  NotStarted = 'Not Started',
  InProgress = 'Checklist Loading',
  Completed = 'Checklist Completed',
}

const FaxDetailsComponent = forwardRef<SendFax, IFaxDetails>(
  (
    {
      mode,
      letter,
      attachmentId,
      checklistId,
      episodeId,
      patientId,
      setMessage,
      setPreview,
      setLoading,
      setSendFax,
      setFaxFormData,
      setFaxLabel,
      isSelectedView,
      ...rest
    },
    ref
  ) => {
    const hiddenHtmlRef = useRef<HTMLDivElement>(null);
    const [selectedView, setSelectedView] = useState<boolean>(isSelectedView);
    const [checklistLoadingStatus, setChecklistLoadingStatus] =
      useState<ChecklistLoadingStatus>(ChecklistLoadingStatus.NotStarted);
    const { isFetching, isSuccess, data } = useGetFaxDialogModelQuery(
      getFaxDialogModelQueryParameters(episodeId, letter)
    );

    const extractHtml = (node: HTMLDivElement | null) => {
      if (!node) {
        return '';
      }
      const htmlContent = node.innerHTML ?? '';

      const styleTags = Array.from(document.querySelectorAll('style')).map(
        (style) => style.outerHTML
      );
      const styleLinks = Array.from(
        document.querySelectorAll('link[rel="stylesheet"]')
      ).map((link) => link.outerHTML);
      const styleSheets = Array.from(document.styleSheets).map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join('\n');
        } catch (error) {
          return '';
        }
      });
      return `
          <html>
            <head>
              ${styleTags.join('\n')}
              ${styleLinks.join('\n')}
              <style>
                ${styleSheets.join('\n')}
              </style>
            </head>
            <body>
              <div>${htmlContent}</div>
            </body>
          </html>
        `;
    };
    const dispatch = useDispatch();
    const modalPatientId = useSelector(
      (state: IState) => state.patientDetails.patientId
    );
    const modalEpisodeId = useSelector(
      (state: IState) => state.patientDetails.episodeId
    );
    const activeTabName = useSelector(
      (state: IState) => state.patientDetails.activeTabName
    );
    const currentUserName = useSelector(
      (state: IState) => state.user.currentUser.name
    );
    const [fax, faxState] = useOnSendFaxMutation();

    useMutationEffect(faxSuccess, faxError, setMessage, faxState);

    const onSubmit = (form: FaxFormData) => fetchPreview(form, false);

    const fetchPreview = async (form: FaxFormData, previewOnLoad: boolean) => {
      const checklistHtml = extractHtml(hiddenHtmlRef.current);
      const request = buildFaxRequest(
        form,
        checklistId,
        episodeId,
        patientId,
        checklistHtml,
        letter,
        attachmentId
      );
      const faxRequest = {
        ...request,
        letters: request.letters?.map((x) => ({
          id: x.id,
          type: x.type == LetterType.DocumentTemplate ? 0 : 1,
          attachmentPathTemp: x.attachmentPathTemp,
        })),
      };

      setLoading(true);
      try {
        const response = await apiClient.post('Fax/FaxPreview', faxRequest, {
          responseType: 'blob',
        });
        setMessage(previewSuccess);
        setPreview(response.data);
        setSelectedView(true);
        setFaxFormData(form);
        setSendFax(!previewOnLoad);
      } catch {
        setMessage(previewError);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (
        !isFetching &&
        isSuccess &&
        mode === 'fax' &&
        selectedView &&
        checklistId === 0
      ) {
        fetchPreview(data?.getFaxDialogModel as FaxFormData, true);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.getFaxDialogModel, isFetching, isSuccess, mode, checklistId]);

    useEffect(() => {
      dispatch(updateFaxState());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (checklistId !== 0 && patientId !== 0) {
        setChecklistLoadingStatus(ChecklistLoadingStatus.InProgress);
      }
    }, [checklistId, patientId]);

    useEffect(() => {
      setSelectedView(isSelectedView);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectedView]);

    useEffect(() => {
      setFaxLabel(!!faxSuccess);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faxState, faxSuccess]);

    const commitChanges = useCallback(
      (changeSet: ChangeSet) => {
        if (activeTabName && activeTabName !== 'Timeline') {
          dispatch(
            commitAttachments({
              patientId: modalPatientId,
              episodeId: modalEpisodeId == 0 ? undefined : modalEpisodeId,
              changeSet,
            })
          );
        }
        dispatch(
          refreshTimelineGridData(
            modalEpisodeId == 0 && activeTabName === 'Timeline'
          )
        );
      },
      [activeTabName, dispatch, modalEpisodeId, modalPatientId]
    );

    useImperativeHandle(ref, () => ({
      onSendFax: (form: FaxFormData | undefined) => {
        const checklistHtml = extractHtml(hiddenHtmlRef.current);
        const request = buildFaxRequest(
          form,
          checklistId,
          episodeId,
          patientId,
          checklistHtml,
          letter,
          attachmentId
        );
        fax(request).then((result) => {
          if ('data' in result) {
            const data = result.data.onSendFax.attachment;
            const newAttachments = [
              {
                id: data?.id,
                description: data?.description,
                createdByUser: {
                  fullName: currentUserName,
                } as User,
                name: data?.name,
                fileExtension: data?.fileExtension,
                location: data?.location,
                createdOn: data?.createdOn,
              },
            ];
            commitChanges({ added: newAttachments });
          } else if ('error' in result) {
            dispatch(
              showErrorPopup({
                message: result.error?.message ?? 'Error on sending fax.',
              })
            );
          }
        });
        setMessage(faxSuccess);
      },
    }));

    return (
      <>
        <Loader active={isFetching} />
        {!isFetching &&
          isSuccess &&
          checklistLoadingStatus != ChecklistLoadingStatus.InProgress && (
            <FaxForm
              data-testid="fax-details"
              initial={data?.getFaxDialogModel}
              faxNumbers={data?.getFaxDialogModel.faxNumbers}
              previewDisplay={previewDisplay(selectedView)}
              onSubmit={onSubmit}
              setSendFax={setSendFax}
              {...rest}
            />
          )}
        <HiddenContainer ref={hiddenHtmlRef}>
          {checklistId !== 0 && patientId !== 0 && (
            <ChecklistPreviewWrapper
              checklistIds={[checklistId]}
              patientId={patientId}
              storageType={ChecklistStorageType.CHECKLIST}
              onCompleted={() =>
                setChecklistLoadingStatus(ChecklistLoadingStatus.Completed)
              }
            />
          )}
        </HiddenContainer>
      </>
    );
  }
);

FaxDetailsComponent.displayName = 'FaxDetails';

export const FaxDetails = FaxDetailsComponent;
