import styled from 'styled-components';
import { Typography } from '@mui/material';
import React from 'react';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const StyledTypography = styled(Typography)({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TitleComponent = (props: any) => (
  <CustomTooltip title={props.children ?? ''}>
    <StyledTypography variant="h5">{props.children}</StyledTypography>
  </CustomTooltip>
);
