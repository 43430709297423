import React from 'react';
import { TableSelection } from '@devexpress/dx-react-grid-material-ui';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

const StyledTableRow = styled.tr<TableSelection.RowProps>`
  max-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  ${({ highlighted }) => highlighted && `backgroundColor: ${COLORS.BLUE10}`}
`;

const SelectedRowComponent = (props: TableSelection.RowProps) => (
  <StyledTableRow {...props} onClick={props.onToggle} data-cy="table-row">
    <>{props.children}</>
  </StyledTableRow>
);

export default SelectedRowComponent;
