import { useSelector } from 'react-redux';
import { IState } from 'store';
import {
  BaseReminder,
  DueDateTimeType,
  LookupValue,
  ReminderImportance,
  User,
} from 'graphql/graphqlTypes';
import { getReminderStatusAndDays } from 'util/helpers/iconHelpers';
import React, { useMemo } from 'react';
import { selectReminderReasons } from './RemindersGrid.selector';
import { MOMENT_ISO_FORMAT } from 'components/constants';
import { getStartOfTodayAtCareSite } from 'util/helpers/dateTimeHelpers';
import { reminderColumn } from './consts';
import { differenceInMinutes } from 'date-fns';
import moment from 'moment';

export const useNewDefaultReminder = () => {
  const [defaultReason] = useSelector(selectReminderReasons);
  const users = useSelector((state: IState) => state.home.mainModel.users);
  const { id: userId } = useSelector((state: IState) => state.user.currentUser);
  const defaultOwner = React.useMemo(
    () => users.find((x) => x.id === userId),
    [users, userId]
  );

  const now = getStartOfTodayAtCareSite();

  const owner = { ...defaultOwner, fullName: defaultOwner?.name || '' };
  return {
    id: -1,
    date: now.format(MOMENT_ISO_FORMAT),
    reason: defaultReason,
    importanceId: 1,
    importance: ReminderImportance.Critical,
    owner: owner,
    note: '',
    isNew: true,
    isJustAdded: true,
    dueDateTimeType: DueDateTimeType.Date,
  } as BaseReminder & { isJustAdded: boolean };
};

export const useReminderStatusAndDays = (reminder: BaseReminder) => {
  const { id: userId } = useSelector((state: IState) => state.user.currentUser);

  const now = getStartOfTodayAtCareSite();

  return getReminderStatusAndDays(reminder, now, userId);
};

export const useColumns = (
  isEpisodeReminders: boolean,
  showEpisodeIdColumn: boolean
) => {
  const now = getStartOfTodayAtCareSite();
  return useMemo(
    () => [
      {
        name: reminderColumn.status,
        title: 'Status',
        getCellValue: (row: BaseReminder) =>
          getReminderStatusAndDays(row, now, row.createdBy).status,
      },
      {
        name: reminderColumn.days,
        title: 'Days',
        getCellValue: (row: BaseReminder) =>
          getReminderStatusAndDays(row, now, row.createdBy).daysFormatted,
      },
      { name: reminderColumn.date, title: 'Due Date/Time' },
      {
        name: reminderColumn.reason,
        title: 'Reason',
      },
      {
        name: reminderColumn.importance,
        title: 'Importance',
      },
      {
        name: reminderColumn.owner,
        title: 'Team Member',
      },
      ...(showEpisodeIdColumn
        ? [
            {
              name: reminderColumn.episodeNumber,
              title: 'Episode Number',
              getCellValue: (row: BaseReminder) => row.episode?.episodeNumber,
            },
          ]
        : []),
      ...(isEpisodeReminders
        ? [
            {
              name: reminderColumn.episode,
              title: 'Episode Type/Info',
            },
          ]
        : []),
      {
        name: reminderColumn.note,
        title: 'Notes',
      },
      {
        name: reminderColumn.actions,
        title: ' ',
        getCellValue: (row: BaseReminder) => row.id,
      },
    ],
    [isEpisodeReminders, now, showEpisodeIdColumn]
  );
};

const getEditModeWidth = (isInEditMode: boolean) =>
  isInEditMode ? '250px' : '150px';

const compareDates = (a: string, b: string) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  const isMidnightA = dateA.getHours() === 0 && dateA.getMinutes() === 0;
  const isMidnightB = dateB.getHours() === 0 && dateB.getMinutes() === 0;

  if (moment(a).isSame(moment(b), 'day')) {
    if (isMidnightA && isMidnightB) {
      return 0;
    }

    if (isMidnightA) {
      return 1;
    }

    if (isMidnightB) {
      return -1;
    }
  }

  return differenceInMinutes(moment(a).toDate(), moment(b).toDate());
};

export const useReminderColumnsExtensions = (
  isEpisodeReminders: boolean,
  readonly: boolean,
  showEpisodeIdColumn: boolean,
  isInEditMode = false
) => {
  return useMemo(
    () => [
      {
        columnName: reminderColumn.status,
        width: '80px',
        sortingEnabled: true,
        compare: (): number => -1,
      },
      {
        columnName: reminderColumn.days,
        width: '70px',
        sortingEnabled: true,
        compare: () => -1,
      },
      {
        columnName: reminderColumn.date,
        sortingEnabled: true,
        width: readonly ? '150px' : getEditModeWidth(isInEditMode),
        compare: compareDates,
      },
      {
        columnName: reminderColumn.reason,
        width: '12%',
        sortingEnabled: true,
        compare: (a: LookupValue, b: LookupValue) =>
          (a.sortOrder ?? 0) - (b.sortOrder ?? 0),
      },
      {
        columnName: reminderColumn.importance,
        sortingEnabled: true,
        width: '10%',
      },
      {
        columnName: reminderColumn.owner,
        width: '15%',
        sortingEnabled: true,
        compare: (a: User, b: User) =>
          a.fullName?.localeCompare(b.fullName ?? '') ?? 0,
      },
      ...(showEpisodeIdColumn
        ? [
            {
              columnName: reminderColumn.episodeNumber,
              width: '12%',
              sortingEnabled: false,
            },
          ]
        : []),
      ...(isEpisodeReminders
        ? [
            {
              columnName: reminderColumn.episode,
              width: '12%',
              sortingEnabled: false,
            },
          ]
        : []),
      {
        columnName: reminderColumn.note,
        sortingEnabled: false,
        width: '12%',
      },
      {
        columnName: reminderColumn.actions,
        sortingEnabled: false,
        width: '143px',
      },
    ],
    [isEpisodeReminders, readonly, showEpisodeIdColumn, isInEditMode]
  );
};
