import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type ContinueMozartStepMutationVariables = Types.Exact<{
  workflowInstanceId: Types.Scalars['UUID'];
}>;

export type ContinueMozartStepMutation = {
  __typename?: 'Mutation';
  continueMozartStep?: {
    __typename?: 'MozartActionResult';
    result: boolean;
    message?: string | null;
  } | null;
};

export const ContinueMozartStepDocument = `
    mutation continueMozartStep($workflowInstanceId: UUID!) {
  continueMozartStep(workflowInstanceId: $workflowInstanceId) {
    result
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    continueMozartStep: build.mutation<
      ContinueMozartStepMutation,
      ContinueMozartStepMutationVariables
    >({
      query: (variables) => ({
        document: ContinueMozartStepDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useContinueMozartStepMutation } = injectedRtkApi;
