import React from 'react';
import Dropdown from '../Dropdown';
import { IEntityLookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import { IComponentProps } from '../types';
import { ReadonlyEntityAttribute } from './ReadonlyEntityAttribute';
import {
  DropdownType,
  EntityAttributeComponent,
  ReferenceAttributeType,
} from 'graphql/graphqlTypes';

export interface IReferenceAttributeProps
  extends IComponentProps<EntityAttributeComponent> {
  entityLookups: IEntityLookup[];
  bigTableValues: IEntityLookup[];
  attributeType: ReferenceAttributeType;
  handleSuggestItems: (
    entity: string,
    request: string,
    callback: (arg: ILookupValue[]) => void
  ) => void;
  autoSave: boolean;
  isReadOnly: boolean;
  parentWidth?: number | string;
  isUpdateActionValueRequestFailed: boolean;
}

const ReferenceAttribute = (props: IReferenceAttributeProps) => {
  const {
    component,
    entityLookups,
    handleChecklistInputChange,
    handleSuggestItems,
    bigTableValues,
    attributeType,
    isActionSelected,
    categoryId,
    storageType,
    autoSave,
    isReadOnly,
    parentWidth,
    isUpdateActionValueRequestFailed,
  } = props;

  if (component.readonly) {
    const lookupValues =
      (attributeType.isBigTable ? bigTableValues : entityLookups).find(
        (l: IEntityLookup) =>
          l.name === (component.entityName ?? attributeType.tableName)
      )?.values ?? [];
    const selectedValue =
      lookupValues.find(
        (lv: ILookupValue) => lv.id.toString() === component.value
      ) || ({} as ILookupValue);
    return <ReadonlyEntityAttribute selectedValues={[selectedValue]} />;
  } else {
    return (
      <Dropdown
        component={{
          componentType: 'DropdownComponent',
          autocomplete: component.autocomplete,
          isBigTable: component.isBigTable,
          color: component.color,
          displayOnlyWhenSelected: component.displayOnlyWhenSelected,
          id: component.id,
          required: component.required || attributeType.required,
          style: component.style,
          uniqueID: component.uniqueID,
          userDefinedId: component.userDefinedId,
          value: component.value,
          width: component.width,
          dropDownType: DropdownType.Entity,
          entityName: attributeType.tableName || '',
          mirrorGroup: component.mirrorGroup,
          oneWaySrc: component.oneWaySrc,
          linkMessage: component.linkMessage,
          fieldName: component.fieldName,
        }}
        handleChecklistInputChange={handleChecklistInputChange}
        handleSuggestItems={handleSuggestItems}
        isActionSelected={isActionSelected}
        categoryId={categoryId}
        autoSave={autoSave}
        storageType={storageType}
        isReadOnly={isReadOnly}
        parentWidth={parentWidth}
        isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
      />
    );
  }
};
export default ReferenceAttribute;
