import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientRiskClinicClassificationsQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientRiskClinicClassificationsQuery = {
  __typename?: 'EntitiesQuery';
  getPatientRiskClinicClassifications?: Array<{
    __typename?: 'PatientRiskClinicalClassification';
    clinicalLabel: string;
    count: number;
  } | null> | null;
};

export const GetPatientRiskClinicClassificationsDocument = `
    query getPatientRiskClinicClassifications($patientId: Int!) {
  getPatientRiskClinicClassifications(patientId: $patientId) {
    clinicalLabel
    count
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientRiskClinicClassifications: build.query<
      GetPatientRiskClinicClassificationsQuery,
      GetPatientRiskClinicClassificationsQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientRiskClinicClassificationsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientRiskClinicClassificationsQuery,
  useLazyGetPatientRiskClinicClassificationsQuery,
} = injectedRtkApi;
