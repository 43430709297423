import React, { useCallback, useEffect, useState } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  moveAttachment,
  toggleAttachmentSidebar,
} from 'store/patientdetails/patientDetails.attachment.slice';
import { IState } from 'store';
import {
  Attachment,
  SearchPatientDialogPatientsViewModel,
} from 'graphql/graphqlTypes';
import SearchBar from 'components/searchbar';
import { useLazySearchPatientsQuery } from 'graphql/hooks/searchPatients';
import { SearchPatientGrid } from 'components/home/SearchPatient/SearchPatientGrid';
import Loader from 'components/loader';
import { EpisodesTab } from 'components/home/patientdetails/tabscontent/episodesTab';
import { IMovedAttachmentDetails } from 'components/home/patientdetails/tabscontent/attachmentsTab/attachmentTab';
import { updateDirtyTabs } from 'store/patientdetails/patientDetailsSlice';

export interface IAttachmentMoveSidebar {
  attachmentData?: Attachment[];
  handleUpdateMovedAttachmentDetails: (
    attachmentDetails: IMovedAttachmentDetails
  ) => void;
  handleToggleSnackBar: (shouldOpenSnackBar?: boolean) => void;
}

const StyledHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px 24px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledContentWrapper = styled.div`
  padding: 0 0 24px 24px;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 14px;
`;

const StyledSearchWrapper = styled.div`
  padding-bottom: 14px;
`;

const StyledButton = styled(Button)`
  margin-top: -47px;
`;

const StyledEpisodesWrapper = styled.div`
  padding-top: 12px;
`;

const episodeColumns = [
  { name: 'createdOn', title: 'Created' },
  { name: 'episodeNumber', title: 'ID' },
  { name: 'episodePathway', title: 'Type' },
];

const AttachmentMoveSidebar = ({
  attachmentData,
  handleUpdateMovedAttachmentDetails,
  handleToggleSnackBar,
}: IAttachmentMoveSidebar) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatientId, setSelectedPatientId] = useState(0);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState(0);
  const [selectedEpisodeNumber, setSelectedEpisodeNumber] = useState('');
  const [episodesLength, setEpisodesLength] = useState(0);

  const selectedAttachmentToMove = useSelector(
    (state: IState) => state.patientDetailsAttachment.moveAttachmentId
  );
  const currentSelectedPatientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const currentSelectedAttachment = attachmentData?.filter(
    (attachment) => attachment.id === selectedAttachmentToMove
  )[0];
  const title = `Move "${currentSelectedAttachment?.name}"`;

  const dispatch = useDispatch();
  const [searchPatients, { data, isFetching }] = useLazySearchPatientsQuery();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actualData = searchTerm
    ? ((data?.searchPatients ?? []) as SearchPatientDialogPatientsViewModel[])
    : [];

  const handleSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    if (newSearchTerm) {
      searchPatients({
        fullSearch: newSearchTerm,
      });
    }
  };

  const handleSelectionChange = (values: Array<number | string>) => {
    if (values.length > 1) {
      const newId = Number(values[1]);
      setSelectedPatientId(newId);
    }
  };
  const handleSelectEpisode = useCallback(
    (episodeId: number, episodeNumber?: string) => {
      setSelectedEpisodeId(episodeId === selectedEpisodeId ? 0 : episodeId);
      setSelectedEpisodeNumber(episodeNumber ?? '');
    },
    [selectedEpisodeId]
  );

  const onAttachmentMoveCloseClick = useCallback(() => {
    dispatch(toggleAttachmentSidebar(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMoveAttachment = useCallback(
    (isMoveToPatient: boolean) => {
      const moveObject = {
        attachmentId: selectedAttachmentToMove,
        ...(isMoveToPatient
          ? { targetPatientId: selectedPatientId }
          : { targetEpisodeId: selectedEpisodeId }),
      };
      const memberMrn =
        data?.searchPatients?.find((member) => member?.id === selectedPatientId)
          ?.mRN ?? '';

      handleUpdateMovedAttachmentDetails({
        ...moveObject,
        ...{
          attachmentTitle:
            attachmentData?.filter(
              (attachment) => attachment.id === selectedAttachmentToMove
            )[0].name ?? '',
          targetEpisodeNumber: selectedEpisodeNumber,
          targetMemberId: memberMrn,
        },
      });
      dispatch(
        moveAttachment({
          moveObject,
          isMoveToSamePatientEpisode:
            currentSelectedPatientId === selectedPatientId,
        })
      );
      handleToggleSnackBar(true);
      dispatch(updateDirtyTabs('Attachments'));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAttachmentToMove, selectedEpisodeId, selectedPatientId]
  );

  const handleEpisodesLengthChange = useCallback((numberOfEpisodes: number) => {
    setEpisodesLength(numberOfEpisodes);
  }, []);

  useEffect(() => {
    setSelectedPatientId(0);
  }, [actualData]);

  return (
    <StyledWrapper>
      <StyledHeadingWrapper>
        <Typography variant="subtitle2" data-testid="attachment-sidebar-title">
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onAttachmentMoveCloseClick}
          data-cy="close"
          style={{ padding: '4px' }}
          size="large"
        >
          <Icon icon={ICONS.Close} size={24} color={COLORS.GREY100} />
        </IconButton>
      </StyledHeadingWrapper>
      <StyledContentWrapper>
        <StyledTypography variant="subtitle1">Members</StyledTypography>

        <StyledSearchWrapper>
          <SearchBar
            placeholder="Search by Member Name or Member ID..."
            onSubmit={handleSearchTerm}
            style={{ width: '400px' }}
          />
        </StyledSearchWrapper>
        <div>
          <SearchPatientGrid
            selectedPatientId={selectedPatientId}
            gridData={actualData}
            onSelectionChange={handleSelectionChange}
          />
        </div>
        {actualData.length > 0 ? (
          <StyledButton
            disabled={selectedPatientId === 0}
            color="primary"
            variant="contained"
            data-testid="attach-to-member"
            data-cy="attach-to-member"
            onClick={() => handleMoveAttachment(true)}
          >
            Attach to Member
          </StyledButton>
        ) : null}
        <div />
        {selectedPatientId ? (
          <StyledEpisodesWrapper>
            <StyledTypography variant="subtitle1">
              Member’s Episodes
            </StyledTypography>
            <EpisodesTab
              patientId={selectedPatientId}
              showAllTypes={true}
              isDirectOpen={false}
              selectEpisode={undefined}
              showTatColumn={false}
              showCounterColumn={false}
              shouldNotOpenSidePanel
              episodeType="episodes"
              style={{ display: 'flex', flex: '1', height: '100%' }}
              onEpisodeSelection={handleSelectEpisode}
              readonlyGrid
              episodeColumns={episodeColumns}
              limitGridHeight
              handleEpisodesLengthChange={handleEpisodesLengthChange}
            />
            {episodesLength > 0 ? (
              <StyledButton
                disabled={selectedEpisodeId === 0}
                color="primary"
                variant="contained"
                data-testid="attach-to-episode"
                data-cy="attach-to-episode"
                onClick={() => handleMoveAttachment(false)}
              >
                Attach to Episode
              </StyledButton>
            ) : null}
          </StyledEpisodesWrapper>
        ) : null}
      </StyledContentWrapper>

      <Loader active={isFetching} />
    </StyledWrapper>
  );
};
export default AttachmentMoveSidebar;
