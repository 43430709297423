import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { useMemo } from 'react';

const defaultFilter = createFilterOptions();

export const useFilterOptions = <T, TAdditional = void>(
  filterOptions?: (
    options: T[],
    state: FilterOptionsState<T>,
    additionalState?: TAdditional
  ) => T[],
  additionalState?: TAdditional
) => {
  return useMemo(() => {
    if (!filterOptions) {
      return undefined;
    }

    return (options: T[], state: FilterOptionsState<T>) => {
      const filtered =
        filterOptions(options, state, additionalState) ?? options;
      return defaultFilter(
        filtered,
        state as FilterOptionsState<unknown>
      ) as T[];
    };
  }, [filterOptions, additionalState]);
};
