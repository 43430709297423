import { IMultiEditDialogState } from 'store/multiedit/types';
import {
  IMultiEditActions,
  MULTI_EDIT_CLOSE,
  MULTI_EDIT_OPEN,
} from 'store/multiedit/action';

export const initialMultiEditState: IMultiEditDialogState = {
  isOpened: false,
  patientIds: [] as number[],
  episodeIds: [] as number[],
};

const multiEditDialogReducer = (
  state: IMultiEditDialogState = initialMultiEditState,
  action: IMultiEditActions
): IMultiEditDialogState => {
  switch (action.type) {
    case MULTI_EDIT_OPEN:
      return {
        ...state,
        isOpened: true,
        patientIds: action.patientIds ? action.patientIds : [],
        episodeIds: action.episodeIds ? action.episodeIds : [],
      };

    case MULTI_EDIT_CLOSE:
      return {
        ...state,
        isOpened: false,
        patientIds: [] as number[],
        episodeIds: [] as number[],
      };

    default:
      return state;
  }
};
export default multiEditDialogReducer;
