import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeCounterQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeCounterQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeCounter?: {
    __typename?: 'CounterViewModel';
    additionalTime?: string | null;
    clockType: string;
    drivers: Array<string>;
    startDateTime: any;
    dateOnly: boolean;
    state: Types.ClockState;
    timeElapsed?: string | null;
    history: Array<{
      __typename?: 'ClockHistoryViewModel';
      action: Types.ClockEventType;
      createdOn: any;
      createdByUser: {
        __typename?: 'User';
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
      };
    }>;
  } | null;
};

export const GetEpisodeCounterDocument = `
    query getEpisodeCounter($episodeId: Int!) {
  getEpisodeCounter(episodeId: $episodeId) {
    additionalTime
    clockType
    drivers
    history {
      action
      createdByUser {
        firstName
        lastName
        fullName
      }
      createdOn
    }
    startDateTime
    dateOnly
    state
    timeElapsed
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeCounter: build.query<
      GetEpisodeCounterQuery,
      GetEpisodeCounterQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeCounterDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeCounterQuery, useLazyGetEpisodeCounterQuery } =
  injectedRtkApi;
