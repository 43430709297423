import TableCell from '@mui/material/TableCell';
import { TextField } from '@mui/material';

import React, { ChangeEvent } from 'react';
import { CellComponentStyle } from 'components/gridFormatters';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';

export const TextEditorCell = (
  props: TableEditRow.CellProps & { autoFocus?: boolean }
) => {
  const { value, onValueChange, autoFocus } = props;

  const onChange = (event: ChangeEvent<{ value: unknown }>) =>
    onValueChange(event.target.value);

  return (
    <TableCell style={CellComponentStyle}>
      <TextField
        value={value ?? ''}
        onChange={onChange}
        variant="outlined"
        fullWidth
        autoFocus={autoFocus}
        multiline
      />
    </TableCell>
  );
};
