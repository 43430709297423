import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientRiskQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientRiskQuery = {
  __typename?: 'EntitiesQuery';
  getPatientRisk?: {
    __typename?: 'PatientRisk';
    ip: any;
    eR: any;
    tot: any;
    rx: any;
    risingRiskFlag: any;
    acuityLevel: string;
  } | null;
};

export const GetPatientRiskDocument = `
    query getPatientRisk($patientId: Int!) {
  getPatientRisk(patientId: $patientId) {
    ip
    eR
    tot
    rx
    risingRiskFlag
    acuityLevel
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientRisk: build.query<
      GetPatientRiskQuery,
      GetPatientRiskQueryVariables
    >({
      query: (variables) => ({ document: GetPatientRiskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPatientRiskQuery, useLazyGetPatientRiskQuery } =
  injectedRtkApi;
