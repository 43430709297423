import { Cookies } from 'react-cookie';
import { Privilege } from 'store/roles/types';
import jwt from 'jwt-decode';

export const hasUserPrivilege = (privilege: Privilege): boolean => {
  const cookies = new Cookies();
  try {
    const token = jwt<{ Privilegies: string }>(
      cookies.get('.AspNetCore.Application.Id')
    );
    if ('Privilegies' in token) {
      const privileges = token.Privilegies.split(',').map((x: string) =>
        Number(x)
      );
      if (privileges.includes(privilege)) {
        return true;
      }
    }
    return false;
  } catch {
    return false;
  }
};
