import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { COLORS } from 'consts/styles';
import MedicationsList from './MedicationsList';
import MedicationsClaims from './MedicationClaims';

const TABS = ['Medication List', 'Medication Claims'];

interface IMedicationSubTabsProps {
  columnOpen: boolean;
}

const MedicationsSubTabs = ({ columnOpen }: IMedicationSubTabsProps) => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  return (
    <div style={{ paddingTop: '50px' }}>
      <Tabs
        value={currentTab}
        indicatorColor="primary"
        onChange={(_, value) => setCurrentTab(value)}
        aria-label="disabled tabs"
        style={{
          padding: '0 20px',
          borderBottom: `1px solid ${COLORS.GREY25}`,
          margin: '0 -24px',
          marginRight: columnOpen ? '0px' : 'inherit',
        }}
      >
        {TABS.map((item, index) => (
          <Tab
            style={{ textTransform: 'capitalize' }}
            label={item}
            key={index}
          />
        ))}
      </Tabs>
      <div style={{ paddingTop: '30px' }}>
        {currentTab === 0 && <MedicationsList columnOpen={columnOpen} />}
        {currentTab === 1 && <MedicationsClaims />}
      </div>
    </div>
  );
};

export default MedicationsSubTabs;
