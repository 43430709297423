import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { IState } from 'store';
import { ROUTE } from 'consts/ROUTE';

interface IPublicRouteProps {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactNode;
  anonymous?: boolean;
}

const PublicRoute = ({ children, anonymous }: IPublicRouteProps) => {
  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);

  if (isLoggedIn && !anonymous) {
    return (
      <>
        <Navigate to={ROUTE.Home} />
      </>
    );
  }
  return <>{children}</>;
};

export default PublicRoute;
