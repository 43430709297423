import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { LookupSelect, Select } from 'components/select';
import { Buttons, Info, Line, Title } from './index';
import Loader from 'components/loader';
import { IUserPreferences } from 'backend/types/userPreferences';
import { useErrorHandler } from 'react-error-boundary';
import { ROUTE } from 'consts/ROUTE';
import {
  UpdateUserPreferencesMutation,
  useUpdateUserPreferencesMutation,
} from 'graphql/hooks/updateUserPreferences';
import { useGetUserPreferencesQuery } from 'graphql/hooks/getUserPreferences';
import { useNavigate } from 'react-router';
import ErrorComp from 'components/error';
import { TextField } from '@mui/material';
import styled from 'styled-components';

const StyledInputField = styled(TextField)({
  fontSize: '12px',
});

const PreferencesForm = () => {
  const navigate = useNavigate();

  const { data, isFetching, isLoading } = useGetUserPreferencesQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [userPreferences, setUserPreferences] = useState<IUserPreferences>(
    data?.getUserPreferences as IUserPreferences
  );

  useEffect(() => {
    if (data) {
      setUserPreferences(data?.getUserPreferences as IUserPreferences);
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all', // blur, change, before submit
    defaultValues: userPreferences,
    reValidateMode: 'onSubmit',
  });

  const [updateUserPreferences, { isLoading: isSavingPreferences }] =
    useUpdateUserPreferencesMutation();

  const onSubmit = handleSubmit(() => {
    updateUserPreferences({
      data: {
        firstName: userPreferences.firstName,
        lastName: userPreferences.lastName,
        prefix: userPreferences.prefix,
        suffix: userPreferences.suffix,
        credentials: userPreferences.credentials,
        phone: userPreferences.phone,
        email: userPreferences.email,
      },
    }).then((data) => {
      const result = data as { data: UpdateUserPreferencesMutation };
      if (result.data?.updateUserPreferences?.isSuccess) {
        reset();
        navigate(ROUTE.Home);
      } else {
        setError('root.serverError', {
          message:
            result.data?.updateUserPreferences?.comment ?? 'Unknown Error',
        });
      }
    });
  });

  const handleError = useErrorHandler();
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    id?: string | undefined
  ) => {
    try {
      if (errors.root?.serverError) {
        clearErrors();
      }
      id = id ? id : e.target.id;
      setUserPreferences({
        ...userPreferences,
        [id]: e.target.value,
      });
    } catch (error) {
      if (handleError) {
        handleError(error);
      }
      throw error;
    }
  };

  const handleCancel = () => navigate(ROUTE.Home);

  const phoneErrorMessage: { [key: string]: string } = {
    maxLength: 'Your phone must not exceed 20 characters long',
    pattern: 'Incorrect phone format, use only numbers and dashes',
  };
  const nameErrorMessage: { [key: string]: string } = {
    required: 'name is required',
    maxLength: 'name must no exceed 80 characters long',
    pattern: 'name contains invalid character or incorrect format',
  };

  return (
    <form onSubmit={onSubmit} style={{ paddingTop: '30px' }}>
      <Title label="Account information" />

      <Line label="ID">
        <Info value={userPreferences?.login} data-cy="hrer" />
      </Line>

      <Line label="Name">
        <Info
          value={`${userPreferences?.firstName} ${userPreferences?.lastName} ${
            userPreferences?.credentials
              ? ', ' + userPreferences?.credentials
              : ''
          }`}
        />
      </Line>

      <Line label="Account created on">
        <Info
          value={moment(userPreferences?.createdOn).format('MM/DD/YYYY HH:mm')}
        />
      </Line>

      <Title label="Details" />

      {userPreferences && userPreferences.login ? (
        <>
          <Line>
            <ErrorComp>
              {errors.root?.serverError && (
                <span>{errors.root?.serverError?.message}</span>
              )}
            </ErrorComp>
          </Line>
          <Line label="Prefix">
            <Select
              value={userPreferences?.prefix}
              onChange={(e) =>
                handleChange(e as ChangeEvent<HTMLSelectElement>, 'prefix')
              }
              items={[
                { id: 'Dr.', name: 'Dr.' },
                { id: 'Mr.', name: 'Mr.' },
                { id: 'Mrs.', name: 'Mrs.' },
                { id: 'Ms.', name: 'Ms.' },
                { id: 'Miss', name: 'Miss' },
              ]}
              size="small"
              fullWidth
            />
          </Line>

          <Line label="First Name(s)" isRequired={true}>
            <StyledInputField
              data-cy="text-firstName"
              id="firstName"
              type="text"
              fullWidth={true}
              {...register('firstName', {
                required: true,
                maxLength: 80,
                pattern: {
                  value: /^[A-Z][a-z]*(([,.] |[ '-])[A-Za-z][a-z]*)*(\.?)$/,
                  message: '',
                },
              })}
              onChange={handleChange}
              value={userPreferences?.firstName}
              variant="outlined"
            />
            <ErrorComp>
              {errors.firstName && (
                <span>{`First ${
                  nameErrorMessage[errors.firstName.type]
                }`}</span>
              )}
            </ErrorComp>
          </Line>

          <Line label="Last Name" isRequired={true}>
            <StyledInputField
              data-cy="text-lastName"
              id="lastName"
              type="text"
              fullWidth={true}
              {...register('lastName', {
                required: true,
                maxLength: 80,
                pattern: {
                  value: /^[A-Z][a-z]*(([,.] |[ '-])[A-Za-z][a-z]*)*(\.?)$/,
                  message: '',
                },
              })}
              onChange={handleChange}
              value={userPreferences?.lastName}
              variant="outlined"
            />
            <ErrorComp>
              {errors.lastName && (
                <span>{`Last ${nameErrorMessage[errors.lastName.type]}`}</span>
              )}
            </ErrorComp>
          </Line>

          <Line label="Suffix">
            <Select
              value={userPreferences?.suffix}
              onChange={(e) =>
                handleChange(e as ChangeEvent<HTMLSelectElement>, 'suffix')
              }
              items={[
                { id: 'Jr.', name: 'Jr.' },
                { id: 'Sr.', name: 'Sr.' },
                { id: 'II', name: 'II' },
                { id: 'III', name: 'III' },
              ]}
              size="small"
              fullWidth
            />
          </Line>

          <Line label="Credentials">
            <LookupSelect
              value={userPreferences?.credentials}
              lookup="Credentials"
              onChange={(e) =>
                handleChange(e as ChangeEvent<HTMLSelectElement>, 'credentials')
              }
              size="small"
              fullWidth
              useNameAsId
            />
          </Line>

          <Line label="Phone">
            <StyledInputField
              data-cy="text-phone"
              id="phone"
              type="text"
              fullWidth={true}
              {...register('phone', {
                maxLength: 20,
                pattern: {
                  value: /^\d+(-\d+)*$/,
                  message: '',
                },
              })}
              onChange={handleChange}
              value={userPreferences?.phone}
              variant="outlined"
            />
            <ErrorComp>
              {errors.phone && (
                <span>{phoneErrorMessage[errors.phone.type]}</span>
              )}
            </ErrorComp>
          </Line>

          <Line label="Email">
            <StyledInputField
              data-cy="text-email"
              id="email"
              type="text"
              fullWidth={true}
              {...register('email', {
                maxLength: 255,
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
                  message: '',
                },
              })}
              onChange={handleChange}
              value={userPreferences?.email}
              variant="outlined"
            />
            <ErrorComp>
              {errors.email && <span>Incorrect email format</span>}
            </ErrorComp>
          </Line>

          <Buttons handleCancel={handleCancel} />
        </>
      ) : null}
      <Loader active={isFetching || isLoading || isSavingPreferences} />
    </form>
  );
};

export default PreferencesForm;
