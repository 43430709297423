import { useSelector } from 'react-redux';
import {
  selectChecklistValidationErrors,
  selectChecklistValidationErrorsAll,
  stoplightsSelector,
} from 'pages/workflow/helperSelectors';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import { uniq } from 'lodash';
import { isChecklistItemOrderable } from 'pages/workflow/helpers';
import { ChecklistCategory } from 'graphql/graphqlTypes';

export const useSectionNumberStatus = (
  category: ChecklistCategory,
  categoryId: string,
  storageType: ChecklistStorageType
) => {
  const stopLights = useSelector(stoplightsSelector(storageType));
  const errorMessagesAll = useSelector(
    selectChecklistValidationErrorsAll(storageType)
  );
  const errorMessages = useSelector(
    selectChecklistValidationErrors(storageType)
  );
  const currentNavigationStep = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].activeSectionIndex
  );
  const isReview = useSelector((state: IState) => state.workflow.step === 3);
  const visitedSections = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].visitedSections
  );
  const showErrorBar = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].showErrorBar
  );

  const categoryStoplightsIds = uniq(
    category.items
      ?.filter(isChecklistItemOrderable)
      .filter((i) => i.qualityMeasure !== null)
      .map((i) => i.qualityMeasure?.id)
  );
  const isStoplightsInvalid =
    !stopLights
      ?.filter((x) => categoryStoplightsIds.includes(x.id))
      .map((x) =>
        x.itemsWithStoplights.map((y) => y.isCompleted && y.isVisible)
      )
      .map((x) => x.some((y) => y))
      .every(Boolean) ?? false;

  const isVisited = visitedSections.some(
    (section) => section.categoryId === category.id
  );

  const isValidationInvalid =
    errorMessages?.some((x) => x.categoryId === categoryId && !x.isValid) ??
    false;

  const hasValidationRules =
    errorMessagesAll?.some((x) => x.categoryId === categoryId) ||
    !!categoryStoplightsIds.length;

  const isInvalid =
    (stopLights?.some((x) => categoryStoplightsIds.includes(x.id)) &&
      isStoplightsInvalid) ||
    isValidationInvalid;

  return {
    isVisited,
    isInvalid,
    hasValidationRules,
    isReview,
    currentNavigationStep,
    showErrorBar,
  };
};
