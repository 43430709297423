import { setGlobalParams } from 'store/actions/checklistSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { Dispatch, SerializedError } from '@reduxjs/toolkit';
import { ClientError } from 'graphql-request';
import { StartMozartWorkflowMutation } from 'graphql/hooks/startMozartWorkflow';
import { setMozartGlobalParams } from 'store/actions/mozartSlice';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { clearFailedRequests } from 'store/failedRequests/failedRequestsSlice';

type StartMozartWorkflowData =
  | { data: StartMozartWorkflowMutation }
  | {
      error: Pick<ClientError, 'name' | 'message' | 'stack'> | SerializedError;
    };

export const startMozartWorkflow = (
  result: StartMozartWorkflowData,
  dispatch: Dispatch,
  patientId: number,
  selectedScenarioId: number,
  workflowDefinitionId: string,
  navigate: NavigateFunction
) => {
  if ('data' in result) {
    const workflowInstanceId: string =
      result.data.startMozartWorkflow?.workflowInstanceId;

    dispatch(
      setGlobalParams({
        storageType: ChecklistStorageType.MOZART,
        patientId: patientId,
        episodeId: -1,
      })
    );
    dispatch(
      setMozartGlobalParams({
        mozartDefinitionId: workflowDefinitionId,
        mozartInstanceId: workflowInstanceId,
        scenarioId: selectedScenarioId,
        pollingTimeout: result.data.startMozartWorkflow?.pollingTimeout ?? 120,
      })
    );
    dispatch(clearFailedRequests());
    const url = `/Workflow/${workflowInstanceId}`;
    navigate(url);
  }
};
