import { useDispatch } from 'react-redux';
import { IState } from 'store';
import { updatePatientConditions } from 'store/patientdetails/patientDetailsSlice';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { getChangedRows, useData } from 'components/gridFormatters';
import { IConditionsProps } from './conditionsTab';
import { PatientCondition } from 'graphql/graphqlTypes';
import {
  GetPatientConditionsQuery,
  useLazyGetPatientConditionsQuery,
} from 'graphql/hooks/getPatientConditions';

const updateConditionsRows = <T extends PatientCondition>(
  changeSet: ChangeSet,
  rows: T[]
): T[] => {
  if (!changeSet) {
    return rows;
  }
  return getChangedRows(changeSet, rows);
};

export const useCommitConditionsChanges = (props: IConditionsProps) => {
  const dispatch = useDispatch();
  const { patientId } = props;
  const { data: conditionsData } = useData(
    (state: IState) => state.patientDetails.conditions,
    useLazyGetPatientConditionsQuery,
    { patientId },
    (data: GetPatientConditionsQuery) =>
      updatePatientConditions(
        data.getPatientById?.conditions as Array<PatientCondition>
      ),
    !patientId
  );

  if (!conditionsData) {
    //ToDo DO something about this
    return {
      conditionsData,
      conditionsCommitChanges: () => void 0,
    };
  }

  const conditionsCommitChanges = (changeSet: ChangeSet) => {
    const conditions = updateConditionsRows(changeSet, conditionsData);
    dispatch(updatePatientConditions(conditions));
  };

  return {
    conditionsData,
    conditionsCommitChanges,
  };
};
