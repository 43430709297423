import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetUmRnDashboardCountQueryVariables = Types.Exact<{
  careSiteId: Types.Scalars['Int'];
}>;

export type GetUmRnDashboardCountQuery = {
  __typename?: 'EntitiesQuery';
  getUmRnDashboardCount: Array<{
    __typename?: 'UmRnDashboardCount';
    nurseOwnerId: number;
    nurseOwner: string;
    opOpen: number;
    ipOpen: number;
    totalOpen: number;
    verifyAdmit: number;
    csr: number;
    totalOpenWork: number;
    completedToday: number;
    csrClosedToday: number;
  }>;
};

export const GetUmRnDashboardCountDocument = `
    query getUmRnDashboardCount($careSiteId: Int!) {
  getUmRnDashboardCount(careSiteId: $careSiteId) {
    nurseOwnerId
    nurseOwner
    opOpen
    ipOpen
    totalOpen
    verifyAdmit
    csr
    totalOpenWork
    completedToday
    csrClosedToday
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUmRnDashboardCount: build.query<
      GetUmRnDashboardCountQuery,
      GetUmRnDashboardCountQueryVariables
    >({
      query: (variables) => ({
        document: GetUmRnDashboardCountDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetUmRnDashboardCountQuery,
  useLazyGetUmRnDashboardCountQuery,
} = injectedRtkApi;
