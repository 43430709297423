import React, { ChangeEvent } from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ILookupValue } from 'backend/types/lookupValue';

import ServerAutocomplete from 'components/autocomplete/ServerAutocomplete';
import MultiSelect from 'components/select/MultiSelect';
import styled from 'styled-components';
import { ValueType } from './types';
import { COLORS } from 'consts/styles';

const StyledTextField = styled(TextField)<{ showValidationError?: boolean }>`
  font-size: 16px;
  color: ${COLORS.NAVY_BLUE};
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${(props: { showValidationError?: boolean }) =>
        props.showValidationError
          ? COLORS.RED100 + '!important'
          : COLORS.GREY100};
    }
  }
`;

export interface IMultiEditSelectProps {
  selectType?: string;
  handleChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  fieldName: string;
  defaultValue?: ValueType;
  selectValues?: Array<ILookupValue>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  handleMultiSelectChange: (values: number[]) => void;
  enabledFields?: Array<ILookupValue | undefined>;
  handleSuggestItems: (
    request: string,
    callback: (arg: ILookupValue[]) => void
  ) => void;
  handlePcpAutoCompleteChange: (
    event: ChangeEvent<unknown>,
    newValue: ILookupValue | null
  ) => void;
  validationRequired: boolean | null | undefined;
  hasValidationError: boolean | null | undefined;
}

const StyledFormControl = styled(FormControl)`
  width: 100%;
  height: 100%;
  display: table;
  text-align: left;
  margin-top: 4px;
`;

const MultiEditSelect = (props: IMultiEditSelectProps) => {
  const {
    selectType,
    handleChange,
    fieldName,
    defaultValue,
    selectValues,
    label,
    placeholder,
    disabled,
    handleMultiSelectChange,
    enabledFields,
    handlePcpAutoCompleteChange,
    handleSuggestItems,
    validationRequired,
    hasValidationError,
  } = props;
  const { register } = useFormContext();

  const isMultiSelectItemDisabled = (item: ILookupValue) =>
    item.isDeleted ||
    (enabledFields &&
      !enabledFields.find((field?) => Number(field?.id) === Number(item.id)));

  return (
    <div>
      {selectType === 'singleSelect' && (
        <StyledTextField
          id={`${fieldName}-label`}
          select
          {...register(`${fieldName}` as const)}
          label={label}
          placeholder={placeholder}
          onChange={handleChange}
          size="small"
          disabled={disabled ?? false}
          fullWidth={true}
          variant="outlined"
          defaultValue={defaultValue}
          showValidationError={
            (validationRequired && hasValidationError) as boolean
          }
        >
          <MenuItem key="" value="" />
          {selectValues?.map((item) => (
            <MenuItem key={item.id} value={Number(item.id)}>
              {item.name}
            </MenuItem>
          ))}
        </StyledTextField>
      )}
      {selectType === 'multiSelect' && (
        <StyledFormControl variant="outlined" margin="dense">
          <MultiSelect
            items={selectValues ?? []}
            value={defaultValue as number[]}
            disabled={disabled ?? false}
            fullWidth={true}
            isItemDisabled={isMultiSelectItemDisabled}
            sortAlphabetically={true}
            onChange={handleMultiSelectChange}
          />
        </StyledFormControl>
      )}
      {selectType === 'serverAutocompleteSelect' && (
        <ServerAutocomplete
          id={fieldName}
          label={label}
          disabled={disabled ?? false}
          initValue={defaultValue as ILookupValue}
          suggestItems={handleSuggestItems}
          {...register(`${fieldName}` as const)}
          onChange={handlePcpAutoCompleteChange}
        />
      )}
    </div>
  );
};

export default MultiEditSelect;
