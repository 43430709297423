import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledOops = styled.div`
  font-size: 60px;
  padding-bottom: 24px;
  line-height: 70px;
`;

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledBody = styled.div`
  text-align: center;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const StyledLinkContainer = styled.div`
  padding-top: 28px;
`;

export const StyledOopsMessage = styled(Typography)`
  max-width: 55%;
  display: flex;
  align-self: center;
`;
