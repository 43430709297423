import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { IconButton } from '@mui/material';

const FavoriteFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const handleClick = (id: string) => {
    if (props.value.favorites.includes(id)) {
      const removedFavorite = props.value.favorites.filter(
        (item: string) => item !== id
      );
      props.value.onUpdateFavoriteProviders(removedFavorite);
    } else {
      const addedFavorite = [...props.value.favorites, id];
      props.value.onUpdateFavoriteProviders(addedFavorite);
    }
  };

  const iconToRender = () => (
    <IconButton
      edge="end"
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
        handleClick(props.value.id);
      }}
      style={{ padding: '0px' }}
      size="large"
    >
      {(props.value.favorites as string[]).includes(props.value.id) ? (
        <Icon icon={ICONS.Filled} size={16} color={COLORS.YELLOW100} />
      ) : (
        <Icon icon={ICONS.Outline} size={16} color={COLORS.GREY100} />
      )}
    </IconButton>
  );

  return <div>{iconToRender()}</div>;
};

export default FavoriteFormatter;
