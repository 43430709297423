import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import SeamlessFormatter from './SeamlessFormatter';

const SeamlessDataTypeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={SeamlessFormatter} {...props} />
);

export default SeamlessDataTypeProvider;
