import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetAllowedFileTypesAndSizeQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAllowedFileTypesAndSizeQuery = {
  __typename?: 'EntitiesQuery';
  getAllowedFileTypesAndSize: {
    __typename?: 'AllowedFileTypesAndSizeModel';
    fileTypes: string;
    fileSizeLimitInBytes: number;
  };
};

export const GetAllowedFileTypesAndSizeDocument = `
    query getAllowedFileTypesAndSize {
  getAllowedFileTypesAndSize {
    fileTypes
    fileSizeLimitInBytes
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllowedFileTypesAndSize: build.query<
      GetAllowedFileTypesAndSizeQuery,
      GetAllowedFileTypesAndSizeQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAllowedFileTypesAndSizeDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAllowedFileTypesAndSizeQuery,
  useLazyGetAllowedFileTypesAndSizeQuery,
} = injectedRtkApi;
