import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import { dashBoardDataColumnNames } from './DashBoardGridHelper';
import Icon, { ICONS } from 'components/icon';

const DashBoardHeaderCell = (props: TableHeaderRow.CellProps) => {
  switch (props.column.name) {
    case dashBoardDataColumnNames.lessThanZero:
      return (
        <TableHeaderRow.Cell
          {...props}
          style={{
            fontSize: '12px',
            fontWeight: 500,
            padding: '10px 0px 5px 6px',
            color: COLORS.RED100,
            backgroundColor: COLORS.GREY10,
          }}
        >
          <>
            <Icon
              icon={ICONS.ClockFilled}
              size={12}
              color={COLORS.RED100}
              style={{ marginRight: '4px' }}
            />
          </>
          {props.children}
        </TableHeaderRow.Cell>
      );

    case dashBoardDataColumnNames.lessThanTwo:
      return (
        <TableHeaderRow.Cell
          {...props}
          style={{
            fontSize: '12px',
            fontWeight: 500,
            padding: '10px 0px 5px 0px',
            color: COLORS.ORANGE100,
            backgroundColor: COLORS.GREY10,
          }}
        >
          <>
            <Icon
              icon={ICONS.ClockFilled}
              size={12}
              color={COLORS.ORANGE100}
              style={{ marginRight: '4px' }}
            />
          </>
          {props.children}
        </TableHeaderRow.Cell>
      );

    case dashBoardDataColumnNames.lessThanThree:
    case dashBoardDataColumnNames.greaterThanThree:
      return (
        <TableHeaderRow.Cell
          {...props}
          style={{
            fontSize: '12px',
            fontWeight: 500,
            padding: '10px 0px 5px 0px',
            color: COLORS.COMPLETED_GREEN,
            backgroundColor: COLORS.GREY10,
          }}
        >
          <>
            <Icon
              icon={ICONS.ClockFilled}
              size={12}
              color={COLORS.COMPLETED_GREEN}
              style={{ marginRight: '4px' }}
            />
          </>
          {props.children}
        </TableHeaderRow.Cell>
      );

    default:
      return (
        <TableHeaderRow.Cell
          {...props}
          style={{
            fontSize: '12px',
            fontWeight: 500,
            padding: '10px 0px 5px 6px',
            color: COLORS.GREY100,
            backgroundColor: COLORS.GREY10,
          }}
        >
          {props.children}
        </TableHeaderRow.Cell>
      );
  }
};
export default DashBoardHeaderCell;
