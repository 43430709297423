import '@webscopeio/react-textarea-autocomplete/style.css';
import React, { useEffect } from 'react';
import Routes from './routes';
import { useDispatch } from 'react-redux';
import { checkAuthentication } from 'store/user/middlewares';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { CookiesProvider } from 'react-cookie';
import theme from 'theme';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandler } from 'store/errorHandler';
import ErrorPage from './pages/error/error';
import { CssBaseline } from '@mui/material';
import VersionCheckTimer from './components/version/VersionCheckTimer';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuthentication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    errorHandler(
      'React component',
      `${error.name} / ${error.message}`,
      `${error.stack} / ${info.componentStack}`
    );
  };

  return (
    <CookiesProvider>
      <ErrorBoundary FallbackComponent={ErrorPage} onError={myErrorHandler}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes />
            <VersionCheckTimer />
          </ThemeProvider>
        </StyledEngineProvider>
      </ErrorBoundary>
    </CookiesProvider>
  );
};

export default App;
