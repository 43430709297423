import React, { useCallback, useMemo } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ILookupValueOrDefault } from 'backend/types/lookupValue';
import { IBaseAutocompleteParams, IBaseAutocompleteRef } from './types';
import { useFilterOptions } from './useFilterOptions';

const getOptionLabelDefault = <T,>(option: ILookupValueOrDefault<T>) =>
  option.name;
const getOptionDisabled = <T,>(option: ILookupValueOrDefault<T>) =>
  !!option.isDisabled;

const BaseMultiAutocompleteInner = <T,>(
  {
    options,
    value,
    placeholder,
    onChange,
    textFieldProps,
    disabled,
    limitTags,
    getOptionLabel,
    filterOptions,
    isOptionEqualToValue,
    ...rest
  }: IBaseAutocompleteParams<T>,
  ref: React.ForwardedRef<IBaseAutocompleteRef>
) => {
  const selectedValue = Array.isArray(value) ? value : [];

  const handleChange = useCallback(
    (e: React.BaseSyntheticEvent, value: ILookupValueOrDefault<T>[] | null) => {
      if (disabled) {
        return;
      }
      const newEvent: React.BaseSyntheticEvent = {
        ...e,
        target: { value: value },
      };
      onChange(newEvent, value);
    },
    [onChange, disabled]
  );

  const actualFilterOptions = useFilterOptions(filterOptions);

  const isOptionEqualToValueInner = useMemo(
    () =>
      isOptionEqualToValue ??
      ((option: ILookupValueOrDefault<T>, value: ILookupValueOrDefault<T>) =>
        option.id === value.id),
    [isOptionEqualToValue]
  );

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={limitTags}
      options={options}
      getOptionLabel={getOptionLabel ?? getOptionLabelDefault}
      getOptionDisabled={getOptionDisabled}
      value={selectedValue}
      onChange={handleChange}
      isOptionEqualToValue={isOptionEqualToValueInner}
      sx={{ width: '100%' }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          inputRef={ref}
          placeholder={placeholder}
          {...params}
          {...textFieldProps}
        />
      )}
      disabled={disabled}
      filterOptions={actualFilterOptions}
      {...rest}
    />
  );
};

BaseMultiAutocompleteInner.displayName = 'BaseMultiAutocompleteInner';

const BaseMultiAutocomplete = React.forwardRef(BaseMultiAutocompleteInner) as <
  T
>(
  props: IBaseAutocompleteParams<T> & {
    ref?: React.ForwardedRef<IBaseAutocompleteRef>;
  }
) => ReturnType<typeof BaseMultiAutocompleteInner>;

export default BaseMultiAutocomplete;
