import React from 'react';
import { Typography } from '@mui/material';
import { formatAddress, formatPatientAge } from './PatientCard.helper';
import { COLORS } from 'consts/styles';
import { BaseReminder, Patient, Tag } from 'graphql/graphqlTypes';
import styled from 'styled-components';
import Tags from 'components/scenario/patient/Tags';
import { useSelector } from 'react-redux';
import { IState } from 'store';

export interface IPatientCardProps {
  patient: Patient;
  variant: 'small' | 'large';
  episodeLabelHasBorder?: boolean;
  patientTopReminder?: BaseReminder;
  tags: Tag[];
}
interface IStyledContainerProps {
  episodeLabelHasBorder?: boolean;
  isPrintingOpen: boolean;
}
const StyledContainer = styled.div<IStyledContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${(props) =>
    !props.isPrintingOpen ? '12px 24px 14px' : '7px 24px 5px'};
  background-color: ${(props) =>
    props.episodeLabelHasBorder && !props.isPrintingOpen
      ? 'transparent'
      : COLORS.GREY4};
`;
const StyledTextWrapper = styled.div`
  margin-bottom: 4px;
  max-width: 275px;
`;
const StyledPatientNameWrapper = styled.div<{
  $episodeLabelHasBorder?: boolean;
}>`
  margin-bottom: 8px;
`;
const StyledText = styled.span`
  font-weight: 400;
`;

const PatientMainDetails = (props: IPatientCardProps) => {
  const { patient, episodeLabelHasBorder, variant, patientTopReminder, tags } =
    props;
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isOptimizedView = printDocuments.isOptimizedView;
  const patientAgeFormatted = formatPatientAge(patient);
  const formattedAddress = formatAddress(patient);
  const primaryPhoneNumber =
    patient?.preferredPhoneNumber ?? patient?.cellPhone ?? patient?.homePhone;
  return (
    <StyledContainer
      episodeLabelHasBorder={episodeLabelHasBorder}
      isPrintingOpen={isOptimizedView}
    >
      {!isOptimizedView && (
        <Tags tags={tags} patientTopReminder={patientTopReminder} />
      )}
      <StyledPatientNameWrapper $episodeLabelHasBorder={episodeLabelHasBorder}>
        <Typography variant="h4">{patient?.fullName}</Typography>
      </StyledPatientNameWrapper>
      <StyledTextWrapper>
        <Typography variant="body1">{patientAgeFormatted}</Typography>
      </StyledTextWrapper>
      {patient?.id ? (
        <StyledTextWrapper>
          <Typography variant="body2">
            Member ID: <StyledText>{patient?.mRN}</StyledText>
          </Typography>
        </StyledTextWrapper>
      ) : null}
      {variant !== 'large' &&
      primaryPhoneNumber !== '' &&
      primaryPhoneNumber ? (
        <StyledTextWrapper>
          <Typography variant="body2">
            Phone: <StyledText>{primaryPhoneNumber}</StyledText>
          </Typography>
        </StyledTextWrapper>
      ) : null}
      {variant !== 'large' && formattedAddress !== '' ? (
        <StyledTextWrapper>
          <Typography variant="body2">
            Address: <StyledText>{formattedAddress}</StyledText>
          </Typography>
        </StyledTextWrapper>
      ) : null}
    </StyledContainer>
  );
};

export default PatientMainDetails;
