import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { useData } from 'components/gridFormatters';

import {
  commitFamily,
  commitProviders,
  commitTeamMembers,
  updatePatientCareTeam,
} from 'store/patientdetails/patientDetails.careTeam.slice';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { updateDirtyTabs } from 'store/patientdetails/patientDetailsSlice';
import {
  LookupValue,
  PatientCareTeamCareSiteUserViewModel,
  PatientCareTeamFamilyViewModel,
  PatientCareTeamMemberViewModel,
  PatientCareTeamTabViewModel,
} from 'graphql/graphqlTypes';
import {
  GetPatientCareTeamTabQuery,
  useLazyGetPatientCareTeamTabQuery,
} from 'graphql/hooks/getPatientCareTeamTab';

export const useCommitCareTeamChanges = () => {
  const dispatch = useDispatch();

  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const { data: careTeamData } = useData(
    (state: IState) => state.patientDetailsCareTeam.careTeamTab,
    useLazyGetPatientCareTeamTabQuery,
    { patientId },
    (data: GetPatientCareTeamTabQuery) =>
      updatePatientCareTeam(
        data.getPatientCareTeamTab as PatientCareTeamTabViewModel
      ),
    !patientId
  );

  if (!careTeamData) {
    return {
      careTeamData,
      teamMembersCommitChanges: () => void 0,
      teamMembersCancelChanges: () => void 0,
      providersCommitChanges: () => void 0,
      providersCancelChanges: () => void 0,
      familyCommitChanges: () => void 0,
      familyCancelChanges: () => void 0,
    };
  }

  const teamMembersCommitChanges = (changeSet: ChangeSet) => {
    dispatch(commitTeamMembers(changeSet));
    dispatch(updateDirtyTabs('Care Team'));
  };

  const teamMembersCancelChanges = (changeSet: ChangeSet) => {
    dispatch(commitTeamMembers(changeSet));
  };

  const providersCommitChanges = (changeSet: ChangeSet) => {
    dispatch(commitProviders(changeSet));
    dispatch(updateDirtyTabs('Care Team'));
  };

  const providersCancelChanges = (changeSet: ChangeSet) => {
    dispatch(commitProviders(changeSet));
  };

  const familyCommitChanges = (changeSet: ChangeSet) => {
    dispatch(commitFamily(changeSet));
    dispatch(updateDirtyTabs('Care Team'));
  };

  const familyCancelChanges = (changeSet: ChangeSet) => {
    dispatch(commitFamily(changeSet));
  };

  return {
    careTeamData,
    teamMembersCommitChanges,
    teamMembersCancelChanges,
    providersCommitChanges,
    providersCancelChanges,
    familyCommitChanges,
    familyCancelChanges,
  };
};

export const useTeamMemberCareSiteUser = (
  teamMember: PatientCareTeamMemberViewModel
): PatientCareTeamCareSiteUserViewModel | null => {
  const users = useSelector(
    (state: IState) => state.patientDetailsCareTeam.careTeamTab?.users
  );
  return (
    users?.find(
      (user: PatientCareTeamCareSiteUserViewModel) =>
        user.id === teamMember.member.id
    ) ?? null
  );
};

export const useTeamMemberFamilyRelationship = (
  family: PatientCareTeamFamilyViewModel
): LookupValue | null => {
  const relationships = useSelector(
    (state: IState) => state.patientDetailsCareTeam.careTeamTab?.relationships
  );
  return (
    relationships?.find((r: LookupValue) => r.id === family.relationshipId) ??
    null
  );
};
