import TableCell from '@mui/material/TableCell';
import React from 'react';
import { useSelector } from 'react-redux';
import { CellComponentStyle } from 'components/gridFormatters';
import { Select, SelectChangeEvent, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IState } from 'store';
import { COLORS } from 'consts/styles';
import { LookupValue } from 'graphql/graphqlTypes';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import styled from 'styled-components';

const StyledSelect = styled(Select)({
  width: '100%',
  backgroundColor: COLORS.WHITE,
  paper: {
    borderRadius: '4px',
    border: '1px solid #CECECE',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
  },
});

export const RelationshipEditCell = (props: TableEditRow.CellProps) => {
  const { value, onValueChange } = props;

  const relationships = useSelector(
    (state: IState) =>
      state.patientDetailsCareTeam.careTeamTab?.relationships ?? []
  );

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value;
    onValueChange(newValue);
  };

  return (
    <TableCell style={CellComponentStyle}>
      <StyledSelect
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          style: {
            left: '0',
            maxHeight: 350,
            maxWidth: 250,
          },
        }}
      >
        {relationships.map((item: LookupValue) => (
          <MenuItem
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            key={item.id}
            value={item.id}
          >
            <Typography noWrap>{item.name}</Typography>
          </MenuItem>
        ))}
      </StyledSelect>
    </TableCell>
  );
};
