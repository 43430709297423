import {
  BaseComponent,
  ChecklistCategory,
  ChecklistProblemViewModel,
  ChecklistViewModel,
  Maybe,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';

export interface IChecklistComponent {
  sectionId: number;
  itemIndex: number;
  lineIndex: number;
  componentIndex: number;
  uuid: string;
  component: BaseComponent;
  isVisible: boolean;
  orderableId: string;
}

export interface IChecklistError {
  uuid: string;
  error: string;
  fieldName: string;
  categoryId: string;
  isValid: boolean;
}

interface IScoringGroup {
  scoringTotal: number;
}

export interface IVisitedSections {
  categoryId: string;
  id: number;
}

export interface IWorkflowViewModel {
  actions: IWorkflowAction[];
}

export interface IWorkflowAction {
  id: string;
  checklistId?: number | null;
  stepName: string | null | undefined;
  categories: ChecklistCategory[];
}

export interface IWorkflowCategory {
  id: string;
  name: string;
  isReadOnly: boolean;
}

export interface IChecklistState {
  checklist: ChecklistViewModel | null;
  problems: ChecklistProblemViewModel[] | null;
  scoringValues: { [scoringGroupId: string]: IScoringGroup };
  checklistComponents: { [key: string]: IChecklistComponent };
  checklistErrors: {
    [key: string]: IChecklistError;
  };
  isSaving: boolean;
  patientId: number | null;
  episodeId: number | null;
  providers: {
    [key: string]: {
      id: number;
      providerAddressId?: number | null;
      isSetByUserAction: boolean;
    };
  };
  showErrorBar: boolean;
  showMozartActionLockErrorBar: boolean;
  linkedComponents: { [groupId: string]: string[] };
  stoplightsRemaining: ISetStoplightValidationBody[];
  selectedActions: { [orderableId: string]: boolean };
  activeSectionIndex: number | null;
  visitedSections: IVisitedSections[];
  remindersInitialized?: boolean;
  conditionalItems: string[];
  isAmendingChecklist: boolean;
  serviceLocation: ServiceLocationType | null;
  diagnosisCodes: IDiagnosisCodes[];
  actionsBeingSetVisible: { actionId: string; selected: boolean }[] | null;
  entityAttributesBeingSet: {
    fieldName: string;
    value: Maybe<string> | undefined;
  }[];
}
export interface IDiagnosisCodes {
  code: string | undefined;
  description: string | undefined | null;
}
export interface IAllDocumentsState {
  documentsState: { [key: string]: IChecklistState };
}

export enum ChecklistStorageType {
  CHECKLIST = 'actionsPage',
  MOZART = 'mozart',
  QUICKNOTE = 'quickNoteModal',
  CAREPLAN = 'carePlanModal',
  VIEW = 'viewDialog',
}

export interface IFinishChecklistResult {
  checklistId: number;
  errors: string;
  status: string;
}

export interface IFinishChecklistLaterRequestBody {
  checklistId: number;
  connectionId: string;
  entryStep: string;
}

export interface IFinishChecklistRequestBody {
  checklistId: number;
  patientId: number;
  sessionId: string;
}

export interface ICategoryVisibilityAction {
  storageType: ChecklistStorageType;
  visibility: ICategoryVisibility[];
}

export interface ICategoryVisibility {
  orderableId: string;
  isVisible: boolean;
}

export interface ICompletedStoplight {
  id: string;
  isCompleted: boolean;
  orderableId: string;
  isVisible: boolean;
  categoryId: string;
}

export interface ISetStoplightValidationBodyAction {
  storageType: ChecklistStorageType;
  stoplights: ISetStoplightValidationBody[];
}

export interface ISetStoplightValidationBody {
  categoryId: string;
  uuid: string;
  errorType: string;
  itemsWithStoplights: Array<ICompletedStoplight>;
  id: string;
  name?: string | null;
  displayName?: string | null;
  isReadOnly: boolean;
  userChoice: number;
  measureLevel: string;
}

export interface IRemoveStoplights {
  storageType: ChecklistStorageType;
  ids: string[];
}

export interface IUpdateStoplightsStateAction {
  storageType: ChecklistStorageType;
  id: string;
  isCompleted: boolean;
}

export interface IMozartReducer {
  workflowChecklistId: number;
  mozartMessages: MozartWorkflowUiModel[];
  startPolling: boolean;
  startOotPolling: boolean;
  completedChecklists: { [checklistId: string]: ChecklistViewModel };
  completedChecklistsIds: string[] | [];
  isUserAuthorizedMozartNextStep: boolean;
  isWorkflowPaused: boolean;
  mozartDefinitionId: string | null;
  mozartInstanceId: string | null;
  mozartWorkflow: IWorkflowViewModel | null;
  scenarioId: number;
  mozartWorkflowInstanceState: string | null;
  duplicateCheckLoading: boolean;
  mozartViewOnly: boolean;
  isReadOnlyWorkflow: boolean;
  canContinueWorkflow: boolean;
  pollingTimeout: number;
  authorizationStatus: { name?: string | null; id?: number } | null;
  restartMozart: boolean;
}
export interface ServiceLocationType {
  id: number;
  isDeleted: boolean;
  name: string;
}
