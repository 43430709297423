import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from 'consts/styles';
import { Box, Typography } from '@mui/material';
import { groupBy, sortBy } from 'lodash';
import Loader from 'components/loader';
import { CareSiteSelector } from 'components/roleslist/CareSiteSelector';
import ProviderSelectList from 'components/provider/ProviderSelectList';
import { GetUserProvidersSelectModelQuery } from 'graphql/hooks/getUserProvidersSelectModel';
import {
  GetUserProvidersSelectModelProvider,
  Maybe,
} from 'graphql/graphqlTypes';
import { changeProvider } from 'store/user/middlewares';
import { StyledWrapper } from '.';
import { IState } from 'store';
import { useResetStateAfterRoleChange } from './useResetStateAfterRoleChange';

export interface IProvidersListProps {
  callback?: (changed: boolean) => void;
  padding?: string;
  selectedCareSiteId?: Maybe<number>;
  providersSelectModel: GetUserProvidersSelectModelQuery | undefined;
  isFetchingProvidersSelect: boolean;
  isProvidersSelectLoaded: boolean;
}
const ProvidersList = (props: IProvidersListProps) => {
  const {
    providersSelectModel,
    isFetchingProvidersSelect,
    isProvidersSelectLoaded,
  } = props;
  const dispatch = useDispatch();
  const resetStateAfterRoleChange = useResetStateAfterRoleChange();

  const [providersByCareSiteId, setProvidersByCareSiteId] = useState<{
    [key: number]: GetUserProvidersSelectModelProvider[];
  }>({});
  const [selectedProviderId, setSelectedProviderId] = useState<number | null>();
  const [selectedCareSiteId, setSelectedCareSiteId] = useState<number>(
    props.selectedCareSiteId ?? 0
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authLogin = useSelector((state: IState) => state.user.auth.login);
  const currentUserLogin = useSelector(
    (state: IState) => state.user.currentUser.login
  );

  useEffect(() => {
    if (isProvidersSelectLoaded && !isFetchingProvidersSelect) {
      const providers = sortBy(
        providersSelectModel?.getUserProvidersSelectModel?.providers ?? [],
        ['name'],
        ['asc']
      );
      const m = groupBy(providers, (p) => p.careSiteId);
      setProvidersByCareSiteId(m);
      setSelectedProviderId(
        providersSelectModel?.getUserProvidersSelectModel?.selectedProviderId
      );
    }
  }, [
    providersSelectModel,
    isFetchingProvidersSelect,
    isProvidersSelectLoaded,
  ]);
  useEffect(() => {
    if (props.selectedCareSiteId) {
      setSelectedCareSiteId(props.selectedCareSiteId);
    }
  }, [props.selectedCareSiteId]);

  const careSites =
    providersSelectModel?.getUserProvidersSelectModel?.careSites ?? [];
  const showProviders =
    providersSelectModel?.getUserProvidersSelectModel?.showProvidersSelect ??
    false;

  const onCareSiteSelected = (careSiteId: number) => {
    setSelectedCareSiteId(careSiteId);
    if (!showProviders) {
      onSelected(careSiteId, 0);
    }
  };

  const onProviderSelected = (providerId: number) => {
    setSelectedProviderId(providerId);
    onSelected(selectedCareSiteId, providerId);
  };

  const onSelected = (careSiteId: number, providerId: number) => {
    setIsLoading(true);
    resetStateAfterRoleChange(false);
    dispatch(
      changeProvider(
        {
          providerId: providerId,
          careSiteId: careSiteId,
        },
        (success) => {
          setIsLoading(false);
          if (props.callback) {
            props.callback(success);
          }
        }
      )
    );
  };

  const providers = providersByCareSiteId[selectedCareSiteId] ?? [];

  return (
    <StyledWrapper
      style={{ padding: props.padding ? props.padding : '15vh 0 10vh 0' }}
    >
      <Box paddingBottom="50px">
        <Typography
          style={{
            color: COLORS.BLACK,
            fontWeight: 400,
            fontSize: '32px',
          }}
          variant="h2"
        >
          {showProviders ? 'Select Location & Provider' : 'Select Location'}
        </Typography>
        <Typography style={{ color: COLORS.GREY100 }} variant="body1">
          SSO Name: <strong>{currentUserLogin || authLogin}</strong>
        </Typography>
      </Box>
      <CareSiteSelector
        careSites={careSites}
        selectedCareSiteId={selectedCareSiteId}
        setSelectedCareSiteId={onCareSiteSelected}
        title="Location"
        fullWidth={true}
      />
      {showProviders && (
        <>
          <Box>
            <Typography
              style={{ color: COLORS.GREY100, marginTop: '38px' }}
              variant="body1"
            >
              Provider
            </Typography>
          </Box>
          <ProviderSelectList
            providersList={providers}
            selectedProviderId={selectedProviderId}
            onProviderSelected={onProviderSelected}
          />
        </>
      )}
      <Loader active={isFetchingProvidersSelect || isLoading} />
    </StyledWrapper>
  );
};

export default ProvidersList;
