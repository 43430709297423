import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import { Box, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IState } from 'store';
import { COLORS } from 'consts/styles';
import { ArrowDropDown } from '@mui/icons-material';
import { ILogoutUser } from 'store/user/types';
import { logoutUser } from 'store/user/middlewares';
import { ROUTE } from 'consts/ROUTE';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { setShowLeavingChecklistConfirmation } from 'store/ui/modals/checklist';
import { MozartActionType, MozartProgressState } from 'graphql/graphqlTypes';
import { useHeaderHelpers } from './Header.hooks';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { getCareSiteTimeDebugInfo } from 'util/helpers/dateTimeHelpers';

interface IAccountMenuProps {
  dark: boolean;
}
const StyledInitialsBox = styled.div<{ open: boolean }>`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: ${(open) => (open ? COLORS.BLUE10 : COLORS.GREY10)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledButtonBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
`;
const StyledMenuItem = styled(MenuItem)`
  padding: 6px 20px;
`;

export const AccountMenu = (props: IAccountMenuProps) => {
  const { dark } = props;
  const userName = useSelector((state: IState) => state.user.currentUser);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const navigate = useNavigate();
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );
  const location = useLocation();
  const isWorkflowRoute =
    location.pathname.includes('/Workflow') ||
    location.pathname.includes('/Checklist/Actions') ||
    location.pathname.includes('/Checklist/Review');

  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  enum MenuAction {
    Preferences = 'Preferences',
    Support = 'Support',
    Logout = 'Logout',
  }

  const mozartMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );

  const checkAction = mozartMessages.some(
    (message) =>
      message.actionType === MozartActionType.UserInput &&
      message.state === MozartProgressState.Completed
  )
    ? 'FinishLater'
    : 'Discard';

  const { showExitChecklistPopup } = useHeaderHelpers();

  const handleMenuClick = (action: MenuAction, event: MouseEvent) => {
    handleCloseMenu();

    if (isWorkflowRoute && showExitChecklistPopup) {
      dispatch(
        setShowLeavingChecklistConfirmation({
          exitModalOpen: true,
          action: checkAction,
          route:
            action === MenuAction.Support
              ? ROUTE.SupportInformation
              : ROUTE.Preferences,
          shouldLogout: action === MenuAction.Logout,
        })
      );
    } else {
      switch (action) {
        case MenuAction.Preferences:
          return navigate(ROUTE.Preferences);
        case MenuAction.Support:
          return navigate(ROUTE.SupportInformation);
        case MenuAction.Logout:
          return logoutUserFrom(event);
        default:
          return null;
      }
    }
  };

  const logoutUserFrom = (event: MouseEvent) => {
    event.persist();
    const user: ILogoutUser = {
      sessionId: sessionId,
    };
    dispatch(logoutUser(user, navigate, true, true));
  };

  const getInitialsColor = () => {
    if (menuOpen) {
      return COLORS.SYMPHONY_BLUE;
    }
    if (dark) {
      return COLORS.SYMPHONY_STAGE_BLUE;
    }
    return COLORS.GREY100;
  };

  const getUserInitials = () => {
    if (userName && userName.firstName && userName.lastName) {
      return `${userName.firstName.charAt(0)}${userName.lastName.charAt(0)}`;
    }
    if (userName !== null && userName.name) {
      return `${userName?.name?.split(' ')[0].charAt(0)}${userName?.name
        ?.split(' ')[1]
        .charAt(0)}`;
    }
    return null;
  };

  const getDebugInfo = () => {
    return getCareSiteTimeDebugInfo().map((item) => {
      return (
        <div key={item}>
          <span>{item}</span>
        </div>
      );
    });
  };

  return (
    <Box display="flex">
      {userName ? (
        <StyledButtonBox onClick={handleClick} data-cy="user-menu">
          <StyledInitialsBox
            open={Boolean(anchorEl)}
            style={{ opacity: dark ? 0.8 : 1 }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                lineHeight: '18px',
                color: getInitialsColor(),
                textTransform: 'capitalize',
              }}
            >
              {getUserInitials()}
            </Typography>
          </StyledInitialsBox>
          <ArrowDropDown style={{ opacity: 0.8 }} />
        </StyledButtonBox>
      ) : null}
      {menuOpen ? (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          style={{ padding: '20px 18px' }}
          onClose={handleCloseMenu}
          data-cy="user-menu-body"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              left: '0',
              padding: '10px 0',
            },
          }}
        >
          <Box
            style={{
              padding: '0 20px',
            }}
          >
            <CustomTooltip
              title={getDebugInfo()}
              enterDelay={5000}
              enterTouchDelay={5000}
              noMaxWidth
            >
              <Typography
                variant="subtitle1"
                style={{
                  paddingBottom: '14px',
                  marginBottom: '10px',
                  borderBottom: `1px solid #D7D7D7`,
                }}
              >
                {userName?.name}
              </Typography>
            </CustomTooltip>
          </Box>
          <StyledMenuItem
            data-cy="user-preference"
            onClick={(event) => handleMenuClick(MenuAction.Preferences, event)}
          >
            Preferences
          </StyledMenuItem>
          <StyledMenuItem
            data-cy="support-information"
            onClick={(event) => handleMenuClick(MenuAction.Support, event)}
          >
            Support Information
          </StyledMenuItem>
          <StyledMenuItem
            onClick={(event) => handleMenuClick(MenuAction.Logout, event)}
            data-cy="sign-out"
          >
            Sign out
          </StyledMenuItem>
        </Menu>
      ) : null}
    </Box>
  );
};
