import React from 'react';
import { IEntityLookup } from 'backend/types/lookup';
import {
  EntityAttributeComponent,
  LookupValue,
  ReferenceAttributeType,
} from 'graphql/graphqlTypes';
import { StyledReviewTypography } from '../styles';

export interface IReferenceAttributeProps {
  component: EntityAttributeComponent;
  entityLookups: IEntityLookup[];
  bigTableValues: IEntityLookup[];
  attributeType: ReferenceAttributeType;
}

const ReferenceAttribute = (props: IReferenceAttributeProps) => {
  const { component, bigTableValues, attributeType, entityLookups } = props;

  if (component.value) {
    const lookupValues = ((attributeType.isBigTable
      ? bigTableValues
      : entityLookups
    ).find(
      (l: IEntityLookup) =>
        l.name === (component.entityName ?? attributeType.tableName)
    )?.values ?? []) as LookupValue[];
    const lookupValue = lookupValues.find(
      (lv: LookupValue) => lv.id.toString() === component.value
    );
    return (
      <StyledReviewTypography
        variant="body1"
        $color={component.color ?? undefined}
      >
        {lookupValue?.name || ''}
      </StyledReviewTypography>
    );
  } else {
    return null;
  }
};
export default ReferenceAttribute;
