import { IValidationResult, validateDateField } from 'util/validationUtils';
import { Scalars } from 'graphql/graphqlTypes';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from 'components/constants';

export const validateDate = (
  date: Date | null,
  isActionSelected: boolean,
  required = true
): IValidationResult => {
  if (isActionSelected) {
    return validateDateField(date, {
      required: required,
    });
  }
  return { hasError: false };
};

export const validateUser = (
  value: number | null,
  isActionSelected: boolean
): IValidationResult => {
  if (isActionSelected && (!value || value === 0)) {
    return { hasError: true, message: 'Fill in required field' };
  }
  return { hasError: false };
};

export const getDate = (value?: Scalars['Date']): Date | undefined => {
  if (value) {
    const parsedValue = moment(value, MOMENT_DATE_FORMAT, true);
    if (parsedValue.isValid()) {
      return parsedValue.toDate();
    }

    return moment(value).toDate();
  } else {
    return undefined;
  }
};
