import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type OnLetterPrintMutationVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
  patientId: Types.Scalars['Int'];
  checklistId: Types.Scalars['Int'];
  letters: Array<Types.LetterDtoInput> | Types.LetterDtoInput;
}>;

export type OnLetterPrintMutation = {
  __typename?: 'Mutation';
  onLetterPrint?: boolean | null;
};

export const OnLetterPrintDocument = `
    mutation onLetterPrint($episodeId: Int!, $patientId: Int!, $checklistId: Int!, $letters: [LetterDtoInput!]!) {
  onLetterPrint(
    model: {episodeId: $episodeId, patientId: $patientId, checklistId: $checklistId, letters: $letters}
  )
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    onLetterPrint: build.mutation<
      OnLetterPrintMutation,
      OnLetterPrintMutationVariables
    >({
      query: (variables) => ({ document: OnLetterPrintDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useOnLetterPrintMutation } = injectedRtkApi;
