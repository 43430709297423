import React from 'react';
import { TableCell } from '@mui/material';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';

import { StatusSelect } from './StatusSelect';
import { COLORS } from 'consts/styles';
import { useDispatch } from 'react-redux';
import {
  updateDirtyTabs,
  updateMedicationsList,
} from 'store/patientdetails/patientDetailsSlice';
import { MedicationViewModel } from 'graphql/graphqlTypes';

export const StatusColumn = (
  props: ILabelCellProps & {
    values: { [p: string]: { name: string; color: COLORS } };
    value: string;
  }
) => {
  const { value, values } = props;
  const dispatch = useDispatch();

  const setStatus = (status: string) => {
    const model = {
      ...props.row,
      patientState: status,
    };

    dispatch(
      updateMedicationsList({
        medications: [model] as MedicationViewModel[],
        isUpdate: true,
      })
    );
    dispatch(updateDirtyTabs('Meds'));
  };

  return (
    <TableCell style={CellComponentStyle} onClick={(e) => e.stopPropagation()}>
      <StatusSelect
        value={value}
        onChange={setStatus}
        variant="standard"
        values={values}
      />
    </TableCell>
  );
};
