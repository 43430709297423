import React from 'react';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { Box } from '@mui/material';

import { useSelector } from 'react-redux';
import store, { IState } from 'store';
import {
  MEMBER_TAGS_COLUMNS,
  MEMBER_TAGS_COLUMNS_EXTENSIONS,
  MEMBER_TAGS_DEFAULT_SORT,
  compareOrder,
} from './consts';
import TagActiveProvider from './TagActiveProvider';
import TagIconProvider from './TagIconProvider';
import { api } from 'graphql/hooks/getPatientTagsTab';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';

interface IPatientTagsGridProps {
  disabled: boolean;
}

const PatientTagsGrid = ({ disabled }: IPatientTagsGridProps) => {
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const { data: patientTags } = api.endpoints.getPatientTagsTab.select({
    patientId,
  })(store.getState());
  const sortOrderExtension: IntegratedSorting.ColumnExtension[] = [
    { columnName: 'sortOrder', compare: compareOrder },
  ];
  return (
    <Box mb="32px">
      <Box>
        <Grid
          rows={patientTags?.getPatientTagsTab ?? []}
          columns={MEMBER_TAGS_COLUMNS}
        >
          <SortingState defaultSorting={MEMBER_TAGS_DEFAULT_SORT} />
          <IntegratedSorting columnExtensions={sortOrderExtension} />
          <TagActiveProvider for={['active']} disabled={disabled} />
          <TagIconProvider for={['icon']} />
          <SymphonyTable columnExtensions={MEMBER_TAGS_COLUMNS_EXTENSIONS} />
          <SymphonyTableHeaderRow />
        </Grid>
      </Box>
    </Box>
  );
};

export default PatientTagsGrid;
