import React from 'react';
import SectionBody from './SectionBody/sectionBody';
import { COLORS } from 'consts/styles';
import { ChecklistStorageType } from 'store/actions/types';
import {
  ChecklistActionComponentDataInput,
  ChecklistActionDataInput,
  ChecklistCategory,
  ChecklistItem,
  ChecklistItemHealthServices,
  ChecklistItemOrderable,
  ChecklistProblemViewModel,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import styled from 'styled-components';

const StyledSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface ISectionItemProps {
  storageType: ChecklistStorageType;
  patientId: number;
  autoSave: boolean;
  inputData?: ChecklistActionDataInput[];
  setInputData?: (data: ChecklistActionDataInput[]) => void;
  category: ChecklistCategory;
  carePlanProblems?: ChecklistProblemViewModel[];
  updateCarePlanProblems?: (problems: ChecklistProblemViewModel[]) => void;
}

const SectionItem = (props: ISectionItemProps) => {
  const {
    category,
    patientId,
    storageType,
    inputData,
    setInputData,
    autoSave,
    carePlanProblems,
    updateCarePlanProblems,
  } = props;

  const orderableIndex: { [key: number]: number } = {};

  const handleInputChange = (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => {
    let foundItem = inputData?.find(
      (x) => x.id === Number(item.orderableId) && x.index === index
    );
    const newDataInputs = inputData ?? [];
    if (!foundItem) {
      foundItem = {
        id: Number(item.orderableId),
        index: index,
        data: [] as ChecklistActionComponentDataInput[],
        isSelected: true,
        isRepeated:
          item.type === 'orderable' &&
          (item as ChecklistItemOrderable).repeaterOptions !== undefined,
      } as ChecklistActionDataInput;
      newDataInputs.push(foundItem);
    }
    foundItem.data = foundItem.data.filter(
      (x) => x?.componentId !== data.componentId
    );
    foundItem.data.push({
      componentId: data.componentId,
      groupId: data.groupId,
      type: data.type,
      value: data.value,
    } as ChecklistActionComponentDataInput);
    if (setInputData) {
      setInputData(newDataInputs);
    }
  };

  const handleRemoveChanges = (item: ChecklistItem, index: number) => {
    const newInputData =
      inputData?.filter(
        (x) => !(x.id === Number(item.orderableId) && x.index === index)
      ) ?? [];
    newInputData?.forEach((x) => {
      if (x.id === Number(item.orderableId) && x.index > index) {
        x.index--;
      }
    });
    if (setInputData) {
      setInputData(newInputData);
    }
  };

  const handleSelectAction = (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => {
    let foundItem = inputData?.find(
      (x) => x.id === Number(item.orderableId) && x.index === index
    );
    const newDataInputs = inputData ?? [];
    if (!foundItem) {
      foundItem = {
        id: Number(item.orderableId),
        index: index,
        data: [] as ChecklistActionComponentDataInput[],
        isSelected: value,
        isRepeated:
          item.type === 'orderable' &&
          (item as ChecklistItemOrderable).repeaterOptions !== undefined,
      } as ChecklistActionDataInput;
      newDataInputs.push(foundItem);
    } else {
      foundItem.isSelected = value;
    }
    if (setInputData) {
      setInputData(newDataInputs);
    }
  };

  return (
    <>
      {category.items.map((item, itemIndex: number) => {
        const itemIsVisible = item.isVisible;
        if (itemIsVisible) {
          if (orderableIndex[Number(item.orderableId)]) {
            orderableIndex[Number(item.orderableId)]++;
          } else {
            orderableIndex[Number(item.orderableId)] = 1;
          }
        }
        const hsDispMode =
          item.type === 'healthservices'
            ? (item as ChecklistItemHealthServices).options.hSDisplayMode
            : 0;
        const hsAuthorizationType =
          item.type === 'healthservices'
            ? (item as ChecklistItemHealthServices).options.hSAuthorizationType
            : 0;
        const isDisplayEpisodeLevelRequestedParam =
          item.type === 'healthservices'
            ? ((item as ChecklistItemHealthServices).options
                .hSDisplayEpisodeLevelRequestedParam as boolean)
            : false;
        const isHsAllowAddingCodes =
          item.type === 'healthservices'
            ? ((item as ChecklistItemHealthServices).options
                .hSAllowAddingCodes as boolean)
            : false;
        const isHsAllowDeletingCode =
          item.type === 'healthservices'
            ? ((item as ChecklistItemHealthServices).options
                .hSAllowDeletingCode as boolean)
            : false;
        return (
          itemIsVisible && (
            <StyledSectionWrapper
              key={item.uid ?? itemIndex}
              style={{
                backgroundColor: `${
                  !(itemIndex & 1) ? COLORS.GREY4 : COLORS.WHITE
                }`,
              }}
            >
              <SectionBody
                checklistItem={item}
                key={item.uid}
                categoryId={category.id}
                patientId={patientId}
                isReadOnly={category.isReadOnly}
                storageType={storageType}
                autoSave={autoSave}
                orderableIndex={orderableIndex[Number(item.orderableId)] - 1}
                changeItemValueOnSave={handleInputChange}
                removeItemValues={handleRemoveChanges}
                selectItemOnSave={handleSelectAction}
                carePlanProblems={carePlanProblems}
                updateCarePlanProblems={updateCarePlanProblems}
                uiDisplayMode={hsDispMode}
                isDisplayEpisodeLevelRequestedParam={
                  isDisplayEpisodeLevelRequestedParam
                }
                isHsAllowAddingCodes={isHsAllowAddingCodes}
                isHsAllowDeletingCode={isHsAllowDeletingCode}
                hsAuthorizationType={hsAuthorizationType}
              />
            </StyledSectionWrapper>
          )
        );
      })}
      <div style={{ paddingLeft: '15px' }}>
        {category.subCategories.map(
          (subCategory, subCategoryIndex) =>
            subCategory.isVisible && (
              <StyledSectionWrapper key={subCategoryIndex}>
                {subCategory.items.map((item) => {
                  if (!item.isVisible) {
                    return null;
                  }

                  if (orderableIndex[Number(item.orderableId)]) {
                    orderableIndex[Number(item.orderableId)]++;
                  } else {
                    orderableIndex[Number(item.orderableId)] = 1;
                  }
                  return (
                    <SectionBody
                      checklistItem={item}
                      key={item.uid}
                      categoryId={category.id}
                      patientId={patientId}
                      isReadOnly={category.isReadOnly}
                      storageType={storageType}
                      autoSave={autoSave}
                      orderableIndex={
                        orderableIndex[Number(item.orderableId)] - 1
                      }
                      changeItemValueOnSave={handleInputChange}
                      removeItemValues={handleRemoveChanges}
                      selectItemOnSave={handleSelectAction}
                      uiDisplayMode={0}
                      isDisplayEpisodeLevelRequestedParam={false}
                      isHsAllowAddingCodes={false}
                      isHsAllowDeletingCode={false}
                      hsAuthorizationType={0}
                    />
                  );
                })}
              </StyledSectionWrapper>
            )
        )}
      </div>
    </>
  );
};

export default SectionItem;
