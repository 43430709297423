import React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import {
  MozartActionType,
  MozartDefinitionType,
  MozartProgressState,
} from 'graphql/graphqlTypes';

const StyledNavHeader = styled.div`
  margin-top: 16px;
  margin-bottom: 0;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReadOnlyNavBarSection = () => {
  const isPausedWorkflow = useSelector(
    (state: IState) => state.mozart.isWorkflowPaused
  );
  const isUserAuthorizedMozartNextStep = useSelector(
    (state: IState) => state.mozart.isUserAuthorizedMozartNextStep
  );
  const pausedActionText = useSelector(
    (state: IState) => state.mozart.mozartMessages
  )?.find(
    (i) =>
      i.actionType === MozartActionType.PauseWorkflow &&
      (i?.state === MozartProgressState.Paused ||
        i?.state === MozartProgressState.Started)
  )?.leftHead;

  const lockedActionText = useSelector(
    (state: IState) => state.mozart.mozartMessages
  )?.find(
    (i) =>
      (i.actionType === MozartActionType.None ||
        i.actionType === MozartActionType.UserInput) &&
      (i.type === MozartDefinitionType.Action ||
        i.type === MozartDefinitionType.Step) &&
      i.state === MozartProgressState.Paused
  )?.leftHead;

  const icon = isPausedWorkflow ? ICONS.PauseAction : ICONS.Lock;
  const text = isPausedWorkflow ? pausedActionText : lockedActionText;

  if (!isPausedWorkflow && isUserAuthorizedMozartNextStep) {
    return null;
  }

  return (
    <StyledNavHeader>
      <Typography>{text}</Typography>
      <Icon
        size={18}
        icon={icon}
        color={COLORS.GREY100}
        style={{ marginRight: '2px', marginBottom: '2px' }}
      />
    </StyledNavHeader>
  );
};

export default ReadOnlyNavBarSection;
