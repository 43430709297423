import React from 'react';
import { AssociatedClaimAttributes } from 'graphql/graphqlTypes';
import { AssociatedClaimAction } from './AssociatedClaim.helper';
import AssociatedClaimRow from './AssociatedClaimRow';

export interface IAssociatedClaimTableProps {
  claimAttributes: AssociatedClaimAttributes[];
  claimActionValues: AssociatedClaimAction[];
  onDelete?: (claim: AssociatedClaimAction) => void;
  onChange?: (claim: AssociatedClaimAction) => void;
}

const AssociatedClaimTable = (props: IAssociatedClaimTableProps) => {
  const { claimAttributes, claimActionValues, onChange, onDelete } = props;
  return (
    <>
      {claimActionValues.map((claim) => {
        return (
          <AssociatedClaimRow
            key={claim.uniqueId}
            claimAttributes={claimAttributes}
            claimActionValue={claim}
            onChange={onChange}
            onDelete={onDelete}
          />
        );
      })}
    </>
  );
};
export default AssociatedClaimTable;
