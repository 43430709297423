import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';

const EpisodesDateTimeFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => <Typography>{formatDateTime(props.value)}</Typography>;

export default EpisodesDateTimeFormatter;
