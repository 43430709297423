import React from 'react';
import Tab from '@mui/material/Tab';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface StyledTabProps {
  label: string;
  value: string | number;
  isLast?: boolean;
  isDirty?: boolean;
  isFirst: boolean;
  isDisabled?: boolean;
}

const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 500,
  textAlign: 'center',
  border: `1px solid ${COLORS.GREY25}`,
  padding: '0',
  borderTopRightRadius: '0px',
  borderTopLeftRadius: '0px',
  boxShadow: 'none',
  backgroundColor: COLORS.GREY4,
  letterSpacing: 0.25,
  width: '130px',
  maxWidth: '140px',
  display: 'flex',
  color: COLORS.GREY100,
  '&:first-child': {
    borderTopLeftRadius: '4px',
  },
  '&:nth-last-child': {
    borderTopLeftRadius: '4px',
    border: `1px solid ${COLORS.GREY25}`,
    borderRightWidth: '1px!important',
  },
  '&:hover': {
    backgroundColor: COLORS.BLUE10,
    color: COLORS.GREY100,
    fontWeight: 500,
  },
  '&.Mui-selected': {
    color: COLORS.SYMPHONY_BLUE,
    fontWeight: 500,
    backgroundColor: COLORS.WHITE,
    borderBottom: '0',
    height: 0,
    borderTop: `3px solid ${COLORS.SYMPHONY_BLUE}`,
  },
  '&:focus': {
    color: COLORS.SYMPHONY_BLUE,
    fontWeight: 'bold',
  },
  '.MuiTab-wrapper': {
    flexDirection: 'row-reverse',
  },
  '&.MuiTab-labelIcon': {
    minHeight: '40px',
    paddingTop: '0',
  },
});

const TabLabelStyledSpan = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 0 12px;
`;

const AntTab = (props: StyledTabProps) => {
  const { isDirty, isLast, isFirst, isDisabled, ...tabProps } = props;
  return (
    <CustomTooltip title={props.label}>
      <StyledTab
        key={props.label}
        disabled={isDisabled}
        disableRipple
        style={{
          borderTopLeftRadius: isFirst ? '4px' : 0,
          borderRightWidth: isLast ? '1px' : '0px',
          borderTopRightRadius: isLast ? '4px' : '0px',
        }}
        {...tabProps}
        label={
          <TabLabelStyledSpan>
            {props.label}
            {isDirty ? (
              <span
                style={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50px',
                  marginRight: '4px',
                  backgroundColor: COLORS.RED100,
                  position: 'absolute',
                  top: '4px',
                  right: '0px',
                }}
              />
            ) : null}
          </TabLabelStyledSpan>
        }
        data-cy={`tab-${props.label}`}
      />
    </CustomTooltip>
  );
};

export default AntTab;
