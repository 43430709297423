import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientCardInfoQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientCardInfoQuery = {
  __typename?: 'EntitiesQuery';
  getPatientById?: {
    __typename?: 'Patient';
    id: number;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    mRN: string;
    mRN2?: string | null;
    mRN3?: string | null;
    mRN4?: string | null;
    mRN5?: string | null;
    dOB?: any | null;
    email?: string | null;
    preferredPhoneNumber?: string | null;
    cellPhone?: string | null;
    homePhone?: string | null;
    alternatePhone?: string | null;
    alternatePhone2?: string | null;
    address1?: string | null;
    address2?: string | null;
    zipCode?: string | null;
    city?: string | null;
    state?: string | null;
    age?: number | null;
    owner?: { __typename?: 'User'; id: number; name?: string | null } | null;
    gender?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
    primaryCareProvider?: {
      __typename?: 'Provider';
      id: number;
      name?: string | null;
      preferredPhone?: string | null;
      preferredFax?: string | null;
      preferredEmail?: string | null;
    } | null;
    language?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
  } | null;
  getAttributes?: Array<{
    __typename?: 'EntityAttribute';
    id: number;
    name?: string | null;
    value?: string | null;
  } | null> | null;
  getAttributeDefinitions?: Array<{
    __typename?: 'EntityAttributeDef';
    entityName?: string | null;
    name?: string | null;
    attributeType?:
      | {
          __typename?: 'BoolAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename?: 'DateAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename?: 'DateTimeAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | { __typename?: 'ExpressionAttributeType' }
      | {
          __typename?: 'FloatAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename?: 'IntAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename?: 'LookupAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
          multiValues: boolean;
          lookup?: {
            __typename?: 'Lookup';
            id: number;
            name?: string | null;
            isDeleted?: boolean | null;
          } | null;
        }
      | {
          __typename?: 'ReferenceAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
          tableName?: string | null;
          isBigTable: boolean;
          required: boolean;
          values: Array<{
            __typename?: 'LookupValue';
            id: number;
            name?: string | null;
          }>;
        }
      | {
          __typename?: 'TextAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | null;
  }> | null;
  getTopReminder?: {
    __typename?: 'BaseReminder';
    id: number;
    date?: any | null;
    daysLeft: number;
    importance?: Types.ReminderImportance | null;
    isCompleted?: boolean | null;
    isDeleted?: boolean | null;
    isExpired?: boolean | null;
    isNew?: boolean | null;
  } | null;
  getPatientTags: Array<{
    __typename?: 'Tag';
    id: number;
    imagePath?: string | null;
    description?: string | null;
    sortOrder?: number | null;
    name?: string | null;
  }>;
  getPatientOpIpAuthorizations?: {
    __typename?: 'PatientOpIpAuthorizationsViewModel';
    inpatientDate?: any | null;
    inpatientStatus?: string | null;
    inpatientDiagnosisCode?: string | null;
    inpatientDiagnosisDescription?: string | null;
    outpatientDate?: any | null;
    outpatientStatus?: string | null;
    outpatientDiagnosisCode?: string | null;
    outpatientDiagnosisDescription?: string | null;
  } | null;
};

export const GetPatientCardInfoDocument = `
    query getPatientCardInfo($patientId: Int!) {
  getPatientById(id: $patientId) {
    id
    firstName
    middleName
    lastName
    fullName
    mRN
    mRN2
    mRN3
    mRN4
    mRN5
    dOB
    email
    preferredPhoneNumber
    cellPhone
    homePhone
    alternatePhone
    alternatePhone2
    address1
    address2
    zipCode
    city
    state
    age
    owner {
      id
      name
    }
    gender {
      id
      name
    }
    primaryCareProvider {
      id
      name
      preferredPhone
      preferredFax
      preferredEmail
    }
    language {
      id
      name
    }
  }
  getAttributes(entityId: $patientId, entityName: "patient") {
    id
    name
    value
  }
  getAttributeDefinitions(includeDeleted: true, includeSystem: false) {
    entityName
    name
    attributeType {
      ... on TextAttributeType {
        type
        name
        displayName
        fullName
        dataType
      }
      ... on IntAttributeType {
        type
        name
        displayName
        fullName
        dataType
      }
      ... on FloatAttributeType {
        type
        name
        displayName
        fullName
        dataType
      }
      ... on BoolAttributeType {
        type
        name
        displayName
        fullName
        dataType
      }
      ... on DateTimeAttributeType {
        type
        name
        displayName
        fullName
        dataType
      }
      ... on DateAttributeType {
        type
        name
        displayName
        fullName
        dataType
      }
      ... on LookupAttributeType {
        type
        name
        displayName
        fullName
        dataType
        lookup {
          id
          name
          isDeleted
        }
        multiValues
      }
      ... on ReferenceAttributeType {
        type
        name
        displayName
        fullName
        dataType
        tableName
        isBigTable
        required
        values {
          id
          name
        }
      }
    }
  }
  getTopReminder(patientId: $patientId) {
    id
    date
    daysLeft
    importance
    isCompleted
    isDeleted
    isExpired
    isNew
  }
  getPatientTags(patientId: $patientId) {
    id
    imagePath
    description
    sortOrder
    name
  }
  getPatientOpIpAuthorizations(patientId: $patientId) {
    inpatientDate
    inpatientStatus
    inpatientDiagnosisCode
    inpatientDiagnosisDescription
    outpatientDate
    outpatientStatus
    outpatientDiagnosisCode
    outpatientDiagnosisDescription
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientCardInfo: build.query<
      GetPatientCardInfoQuery,
      GetPatientCardInfoQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientCardInfoDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPatientCardInfoQuery, useLazyGetPatientCardInfoQuery } =
  injectedRtkApi;
