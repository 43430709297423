import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { TableSelection } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  &:not(checked) .MuiIconButton-label:after {
    left: 7px !important;
    top: 7px !important;
  }
`;

const SelectCell = (props: TableSelection.CellProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selected, tableRow, tableColumn, ...restProps } = props;
  return (
    <td
      style={{
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '8px 0px 5px 10px',
        maxWidth: '32px',
      }}
    >
      <StyledCheckbox
        data-cy="checkbox"
        icon={<Icon icon={ICONS.Empty} size={24} color={COLORS.LIGHT_GREY} />}
        checked={selected}
        onSelect={() => props.onToggle()}
        checkedIcon={
          <Icon
            data-cy="checkbox-selected"
            icon={ICONS.Selected}
            size={24}
            color={COLORS.SYMPHONY_BLUE}
          />
        }
        {...restProps}
      />
    </td>
  );
};

export default SelectCell;
