import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import { ChecklistItemNote, ChecklistItemReminder } from 'graphql/graphqlTypes';
import styled from 'styled-components';

const StyledBox = styled.div<{ $isReview: boolean }>`
  border-radius: 16px;
  padding: 8px 18px;
  display: flex;
  margin: ${(props) =>
    props.$isReview ? ' 14px 0px 14px 32px' : '14px 32px 14px 64px'};
  align-items: center;
`;

const reminderProperties = {
  icon: ICONS.Lightbulb,
  textColor: COLORS.BLUE115,
  backgroundColor: COLORS.BLUE10,
  iconSize: 16,
};
const noteProperties = {
  icon: ICONS.Information,
  textColor: COLORS.GREY100,
  backgroundColor: COLORS.GREY10,
  iconSize: 16,
};

interface IChecklistNotification {
  item: ChecklistItemReminder | ChecklistItemNote;
  type: 'reminder' | 'note';
  isReview?: boolean;
}

const ChecklistNotification = ({
  item,
  type,
  isReview,
}: IChecklistNotification) => {
  const { icon, textColor, backgroundColor, iconSize } =
    type === 'reminder' ? reminderProperties : noteProperties;
  return (
    <StyledBox
      style={{ backgroundColor: backgroundColor }}
      $isReview={Boolean(isReview)}
    >
      <Icon
        icon={icon}
        size={iconSize}
        color={textColor}
        style={{ marginTop: '-2px' }}
      />
      <Typography style={{ paddingLeft: '10px', color: textColor }}>
        {item.preview}
      </Typography>
    </StyledBox>
  );
};

export default ChecklistNotification;
