import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UnlockMozartActionMutationVariables = Types.Exact<{
  workflowInstanceId: Types.Scalars['UUID'];
  actionInstanceId: Types.Scalars['UUID'];
}>;

export type UnlockMozartActionMutation = {
  __typename?: 'Mutation';
  unlockMozartAction?: {
    __typename?: 'MozartActionResult';
    result: boolean;
    message?: string | null;
  } | null;
};

export const UnlockMozartActionDocument = `
    mutation unlockMozartAction($workflowInstanceId: UUID!, $actionInstanceId: UUID!) {
  unlockMozartAction(
    workflowInstanceId: $workflowInstanceId
    actionInstanceId: $actionInstanceId
  ) {
    result
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    unlockMozartAction: build.mutation<
      UnlockMozartActionMutation,
      UnlockMozartActionMutationVariables
    >({
      query: (variables) => ({
        document: UnlockMozartActionDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUnlockMozartActionMutation } = injectedRtkApi;
