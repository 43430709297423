import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import styled from 'styled-components';
import { Maybe, PaRequirement } from 'graphql/graphqlTypes';

interface IPAValue {
  value: Maybe<PaRequirement>;
}

const PAPolicyBox = styled(Box)<IPAValue>`
  ${({ value }) => `
      background: ${getBackground(value)};       
    border-radius: 6px;
    margin: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    display:flex;
    font-size: 12px;
    display: inline-flex;
  `}
`;

const getBackground = (value: Maybe<PaRequirement>) => {
  switch (value) {
    case PaRequirement.InternalRequired:
      return COLORS.RED20;
    case PaRequirement.InternalMaybeRequired:
      return COLORS.ORANGE20;
    case PaRequirement.VendorRequired:
      return COLORS.YELLOW20;
    case PaRequirement.NonCovered:
      return COLORS.GREY20;
    default:
      return COLORS.GREEN20;
  }
};

const StyledIcon = styled(Icon)({
  marginRight: '8px',
  marginTop: '-3px',
});

const PriorAuthPolicyFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  {
    switch (props.value) {
      case PaRequirement.InternalRequired:
        return (
          <PAPolicyBox value={props.value}>
            <StyledIcon icon={ICONS.Denied} size={12} color={COLORS.RED100} />
            PA Required
          </PAPolicyBox>
        );
      case PaRequirement.InternalMaybeRequired:
        return (
          <PAPolicyBox value={props.value}>
            <StyledIcon
              icon={ICONS.Stoplight_incompleted}
              size={12}
              color={COLORS.ORANGE100}
            />
            PA May Be Required
          </PAPolicyBox>
        );
      case PaRequirement.VendorRequired:
        return (
          <PAPolicyBox value={props.value}>
            <StyledIcon
              icon={ICONS.warning_filled}
              size={12}
              color={COLORS.YELLOW100}
            />
            PA Required
          </PAPolicyBox>
        );
      case PaRequirement.NonCovered:
        return (
          <PAPolicyBox value={props.value}>
            <StyledIcon
              icon={ICONS.PANonCovered}
              size={12}
              color={COLORS.GREY100}
            />
            Non-Covered
          </PAPolicyBox>
        );
      default:
        return (
          <PAPolicyBox value={props.value}>
            <StyledIcon
              icon={ICONS.Stoplight_completed}
              size={12}
              color={COLORS.COMPLETED_GREEN}
            />
            No PA Required
          </PAPolicyBox>
        );
    }
  }
};

export default PriorAuthPolicyFormatter;
