import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PRINT_SOURCES } from 'backend/types/printSources';
import Dialog from '@mui/material/Dialog';
import { Box, Button, DialogContent, Typography } from '@mui/material';
import { Ellipsis } from 'components/text/Ellipsis';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import DialogTitle from 'components/modal/DialogTitle';
import Icon, { ICONS } from 'components/icon';
import ChecklistPrintLayout from 'components/checklistViewPrint/ChecklistPrintLayout';
import { ChecklistStorageType } from 'store/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { clearPrintDocumentStates } from 'store/ui/print/print.helper';
import MozartPrintLayout from 'features/mozart/components/MozartPrintLayout/MozartPrintLayout';

interface DocumentsPrintPreviewProps {
  open: boolean;
  onClose: () => void;
  printSource: PRINT_SOURCES;
}
const StyledTypography = styled(Typography)`
  display: inline;
`;

const StyledTitleWrapper = styled.div<{ $color?: string }>`
  width: 100%;
  display: flex;
  color: ${({ $color }) => $color || COLORS.PALE_GREY};
  align-items: center;
`;
const StyledDialogBody = styled(DialogContent)`
  height: 75vh;
  background-color: ${COLORS.GREY4};
  padding: 24px;
`;
const DocumentsPrintPreview = ({
  open,
  onClose,
  printSource,
}: DocumentsPrintPreviewProps) => {
  const [isPrintOpen, setIsPrintOpen] = useState(open);
  const printRef = useRef(null);
  const storageType = useSelector(
    (state: IState) => state.ui.print.documents.storageType
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    clearPrintDocumentStates(dispatch);
    onClose();
  };
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      setIsPrintOpen(true);
      return new Promise<void>((resolve) => {
        resolve();
      });
    },
    content: () => printRef.current,
    onAfterPrint: () => {
      setIsPrintOpen(false);
      handleClose();
    },
  });

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <StyledTitleWrapper $color={COLORS.PALE_GREY}>
            <Ellipsis>
              <StyledTypography noWrap variant="subtitle2">
                Print Preview
              </StyledTypography>
            </Ellipsis>
          </StyledTitleWrapper>
        </DialogTitle>
        <StyledDialogBody dividers>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              pb="2px"
              mr="30px"
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                startIcon={
                  <Icon icon={ICONS.Print} size={14} color={COLORS.WHITE} />
                }
                onClick={handlePrint}
              >
                Print
              </Button>
            </Box>
            <Box>
              {storageType === ChecklistStorageType.MOZART ? (
                <MozartPrintLayout
                  isPrintOpen={isPrintOpen}
                  ref={printRef}
                  printSource={printSource}
                />
              ) : (
                <ChecklistPrintLayout
                  isPrintOpen={isPrintOpen}
                  ref={printRef}
                  storageType={storageType}
                  printSource={printSource}
                />
              )}
            </Box>
          </Box>
        </StyledDialogBody>
      </Dialog>
    </div>
  );
};
export default DocumentsPrintPreview;
