import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import React, { ReactElement } from 'react';
import { providersColumn } from '../providers';
import { ProviderAutocompleteCell } from './ProviderAutocompleteCell';
import { ProviderLabelCell } from './ProviderLabelCell';
import { TextEditorCell } from './TextEditorCell';

export const ProviderEditCell = (
  props: TableEditRow.CellProps
): ReactElement<TableEditRow.CellProps> => {
  const { column } = props;
  switch (column.name) {
    case providersColumn.provider:
      return <ProviderAutocompleteCell {...props} />;
    case providersColumn.note:
      return <TextEditorCell {...props} />;
    default:
      return <ProviderLabelCell {...props} />;
  }
};
export default ProviderEditCell;
