import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';

export interface IAsteriskProps {
  color?: string;
  mr?: string;
  ml?: string;
}

export const Asterisk = ({ color, mr, ml }: IAsteriskProps) => (
  <Typography
    variant="body1"
    style={{ color: color ?? COLORS.RED100, marginRight: mr, marginLeft: ml }}
  >
    *
  </Typography>
);
