import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from 'components/home/Tabs';
import { IState } from 'store';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import { Box } from '@mui/material';
import PatientDetailsDialog from 'components/home/patientdetails';
import MultiEditDialog from 'components/home/multieditdialog';
import { closeMultiEditDialog } from 'store/multiedit/action';
import Loader from 'components/loader';
import { useFetchMainModel, usePrevious } from 'hooks';
import { closePatientDetailsDialog } from 'store/patientdetails/patientDetails.helpers';
import { SearchPatientDialog } from 'components/home/SearchPatient/SearchPatientDialog';
import { closeSearchPatientDialog } from 'store/ui/modals/searchPatient';
import { useNewEpisodeCheck } from 'components/scenario/episode/hooks';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';

const Home = () => {
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const mainModel = useSelector((state: IState) => state.home.mainModel);

  const dispatch = useDispatch();
  const loading = useSelector((state: IState) => state.home.loading);
  const prevRoleId = usePrevious(currentRoleId);
  const shouldRefetchMainModel =
    currentRoleId !== prevRoleId || mainModel.lookups.length === 0;
  useFetchMainModel(shouldRefetchMainModel);

  const isPatientDetailsOpen = useSelector(
    (state: IState) => state.patientDetails.isOpened
  );
  const isMultiEditOpen = useSelector(
    (state: IState) => state.multiEdit.isOpened
  );
  const isSearchPatientOpen = useSelector(
    (state: IState) => state.ui.modals.searchPatient.isOpen
  );

  const selectedPatientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const {
    checkNewEpisode,
    checkNewEpisodeIsFetching,
    confirmation: newEpisodeConfirmation,
  } = useNewEpisodeCheck();

  useEffect(() => {
    dispatch(setWorkflowStep({ step: 0, name: '' }));
  }, [dispatch]);

  const handleNewScenario = (patientId: number, episodeId: number) => {
    checkNewEpisode(patientId, episodeId);
  };

  useEffect(() => {
    if (selectedPatientId) {
      dispatch(
        openPatientDetailsModal({
          patientId: selectedPatientId ?? 0,
          episodeId: 0,
          activeTabName: 'Episodes',
          activeSubTabName: '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box px="60px" pt="24px" pb="60px" data-testid="home-page-body">
      <Tabs isHomeGridDataLoading={loading} />
      {isPatientDetailsOpen ? (
        <PatientDetailsDialog
          open={isPatientDetailsOpen}
          onNew={(patientId: number, episodeId: number) =>
            handleNewScenario(patientId, episodeId)
          }
          onClose={() => {
            closePatientDetailsDialog(dispatch);
          }}
        />
      ) : null}
      <MultiEditDialog
        open={isMultiEditOpen}
        onClose={() => {
          dispatch(closeMultiEditDialog());
        }}
      />
      <SearchPatientDialog
        open={isSearchPatientOpen}
        onNew={(patientId: number, episodeId: number) =>
          handleNewScenario(patientId, episodeId)
        }
        onClose={() => {
          dispatch(closeSearchPatientDialog());
        }}
      />
      {newEpisodeConfirmation}
      <Loader active={checkNewEpisodeIsFetching} />
    </Box>
  );
};

export default Home;
