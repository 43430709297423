import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetScenarioMainModelQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
  checklistId?: Types.InputMaybe<Types.Scalars['Int']>;
  episodeId?: Types.InputMaybe<Types.Scalars['Int']>;
  isNewEpisode?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetScenarioMainModelQuery = {
  __typename?: 'EntitiesQuery';
  findScenarios?: Array<{
    __typename?: 'Scenario';
    id: number;
    name?: string | null;
    displayName?: string | null;
    index?: number | null;
    favorite?: boolean | null;
    workflowDefinitionId?: any | null;
  }> | null;
};

export const GetScenarioMainModelDocument = `
    query getScenarioMainModel($patientId: Int!, $checklistId: Int, $episodeId: Int, $isNewEpisode: Boolean) {
  findScenarios(
    patientId: $patientId
    checklistId: $checklistId
    episodeId: $episodeId
    newEpisode: $isNewEpisode
  ) {
    id
    name
    displayName
    index
    favorite
    workflowDefinitionId
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getScenarioMainModel: build.query<
      GetScenarioMainModelQuery,
      GetScenarioMainModelQueryVariables
    >({
      query: (variables) => ({
        document: GetScenarioMainModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetScenarioMainModelQuery,
  useLazyGetScenarioMainModelQuery,
} = injectedRtkApi;
