import React from 'react';
import {
  HealthServiceCodesActionValue,
  ILookupValue,
} from 'graphql/graphqlTypes';
import { StyledServiceRowDiv } from './styles';
import { ServiceRow } from 'components/actions/sections/SectionBody/Items/HealthService/Components/ServiceRow';
import { ServiceRowReview } from './ServiceRowReview';

export interface IServiceRowManualReviewProps {
  item: HealthServiceCodesActionValue;
  readOnly: boolean;
  onChange: (state: HealthServiceCodesActionValue) => void;
  unitTypes: ILookupValue[];
  groupDecision: string;
  overallDates: { start: Date | null; end: Date | null };
  validationDispatch: (
    errorMessage: string,
    error: boolean,
    id?: string
  ) => void;
}

export const ServiceRowManualReview = (props: IServiceRowManualReviewProps) => {
  const {
    item,
    readOnly,
    onChange,
    unitTypes,
    groupDecision,
    overallDates,
    validationDispatch,
  } = props;

  return (
    <>
      <StyledServiceRowDiv>
        <ServiceRowReview
          type="Requested"
          hideDates={false}
          hideValues={false}
          item={item}
        />
        {groupDecision === 'Partial' && (
          <ServiceRow
            type="Approved"
            item={item}
            readOnly={readOnly}
            onChange={onChange}
            unitTypes={unitTypes}
            overallDates={overallDates}
            validationDispatch={validationDispatch}
          />
        )}

        {groupDecision !== 'NonSet' && groupDecision !== 'Partial' && (
          <ServiceRowReview
            type="Approved"
            hideDates={
              groupDecision === 'Pending' || groupDecision === 'Denied'
            }
            hideValues={groupDecision === 'Pending'}
            item={item}
          />
        )}
      </StyledServiceRowDiv>
    </>
  );
};
export default ServiceRowManualReview;
