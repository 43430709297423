export const MULTI_EDIT_OPEN = 'MULTI_EDIT_OPEN';
export type MULTI_EDIT_OPEN = typeof MULTI_EDIT_OPEN;

export const MULTI_EDIT_CLOSE = 'MULTI_EDIT_CLOSE';
export type MULTI_EDIT_CLOSE = typeof MULTI_EDIT_CLOSE;

export interface IOpenMultiEditDialogAction {
  type: MULTI_EDIT_OPEN;
  patientIds: number[];
  episodeIds: number[];
}

export const openMultiEditDialog = (
  patientIds: number[],
  episodeIds: number[]
): IOpenMultiEditDialogAction => ({
  type: MULTI_EDIT_OPEN,
  patientIds: patientIds,
  episodeIds: episodeIds,
});

export interface ICloseMultiEditDialogAction {
  type: MULTI_EDIT_CLOSE;
}

export const closeMultiEditDialog = (): ICloseMultiEditDialogAction => ({
  type: MULTI_EDIT_CLOSE,
});

export type IMultiEditActions =
  | IOpenMultiEditDialogAction
  | ICloseMultiEditDialogAction;
