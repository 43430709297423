import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SetCurrentUserPreferenceMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  value: Types.Scalars['String'];
}>;

export type SetCurrentUserPreferenceMutation = {
  __typename?: 'Mutation';
  setCurrentUserPreference?: boolean | null;
};

export const SetCurrentUserPreferenceDocument = `
    mutation setCurrentUserPreference($name: String!, $value: String!) {
  setCurrentUserPreference(name: $name, value: $value)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    setCurrentUserPreference: build.mutation<
      SetCurrentUserPreferenceMutation,
      SetCurrentUserPreferenceMutationVariables
    >({
      query: (variables) => ({
        document: SetCurrentUserPreferenceDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSetCurrentUserPreferenceMutation } = injectedRtkApi;
