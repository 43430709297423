import React from 'react';
import { ExternalUrlComponent } from 'graphql/graphqlTypes';

export interface IExternalUrlProps {
  component: ExternalUrlComponent;
}

const ExternalUrl = (props: IExternalUrlProps) => {
  const { component } = props;

  if (component.value && component.name) {
    return (
      <div>
        <a
          href={component.value}
          data-testid={`external_link_${component.uniqueID}`}
          target="_blank"
          rel="noreferrer"
        >
          {component.name}
        </a>
      </div>
    );
  }
  return null;
};

export default ExternalUrl;
