import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import DateTimeFormatter from '../formatters/DateTimeFormatter';

const DateTimeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={DateTimeFormatter} {...props} />
);

export default DateTimeProvider;
