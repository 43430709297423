import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMedicationReviewHistoryViewModelQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetMedicationReviewHistoryViewModelQuery = {
  __typename?: 'EntitiesQuery';
  getMedicationReviewHistoryViewModel: Array<{
    __typename?: 'MedicationReviewHistoryViewModel';
    reviewedByName?: string | null;
    reviewedByRoleName?: string | null;
    reviewedOn?: any | null;
  } | null>;
};

export const GetMedicationReviewHistoryViewModelDocument = `
    query getMedicationReviewHistoryViewModel($patientId: Int!) {
  getMedicationReviewHistoryViewModel(patientId: $patientId) {
    reviewedByName
    reviewedByRoleName
    reviewedOn
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMedicationReviewHistoryViewModel: build.query<
      GetMedicationReviewHistoryViewModelQuery,
      GetMedicationReviewHistoryViewModelQueryVariables
    >({
      query: (variables) => ({
        document: GetMedicationReviewHistoryViewModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetMedicationReviewHistoryViewModelQuery,
  useLazyGetMedicationReviewHistoryViewModelQuery,
} = injectedRtkApi;
