import { Maybe } from 'graphql/graphqlTypes';
import {
  ActionButtonComponentEntity,
  ActionButtonComponentSource,
  ActionButtonComponentTypes,
} from '../types';

export const fixGuid = (strGuid: string | undefined) => {
  if (strGuid && strGuid.indexOf('-') < 0) {
    return (
      strGuid.substring(0, 8) +
      '-' +
      strGuid.substring(8, 12) +
      '-' +
      strGuid.substring(12, 16) +
      '-' +
      strGuid.substring(16, 20) +
      '-' +
      strGuid.substring(20, 32)
    );
  }
  return strGuid;
};

export const isSameAsSourceActionButton = (actionButton: {
  type?: Maybe<string>;
  entity?: Maybe<string>;
  attributeForSource?: Maybe<string>;
}): boolean =>
  actionButton &&
  actionButton.type === ActionButtonComponentTypes.EntityLookup &&
  actionButton.entity === ActionButtonComponentEntity.Provider &&
  (actionButton.attributeForSource ===
    ActionButtonComponentSource.SameAsAdmitting ||
    actionButton.attributeForSource ===
      ActionButtonComponentSource.SameAsFacility ||
    actionButton.attributeForSource ===
      ActionButtonComponentSource.SameAsRequesting ||
    actionButton.attributeForSource ===
      ActionButtonComponentSource.SameAsServicing);
