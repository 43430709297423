import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { IScenario } from 'backend/types/scenario';
import Scenarios from 'components/scenario/scenarios';
import Loader from 'components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import TimelineTab from 'components/home/patientdetails/tabscontent/timelineTab';
import Panel from 'components/panel';
import { COLORS } from 'consts/styles';
import PatientCard from 'components/scenario/patient/PatientCard';
import CaseInformationCard from 'components/scenario/episode/CaseInformationCard';
import { resetChecklist } from 'store/actions/checklistSlice';
import { EpisodesTab } from 'components/home/patientdetails/tabscontent/episodesTab';
import { useFetchMainModel } from 'hooks';
import { Privilege, RoleType } from 'store/roles/types';
import { ChecklistStorageType } from 'store/actions/types';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import ClinicalConditionView from 'components/home/patientdetails/tabscontent/backgroundTab/PatientDetailsBackgroundSubTabs/Tabs/riskTab/clinicalConditionView';
import { isEmpty } from 'lodash';
import {
  BaseReminder,
  EntityAttribute,
  Patient,
  PatientRiskClinicalClassification,
  Tag,
} from 'graphql/graphqlTypes';
import { useGetScenarioMainModelQuery } from 'graphql/hooks/getScenarioMainModel';
import { useGetChecklistModelQuery } from 'graphql/hooks/getChecklist';
import { useGetPatientRiskClinicClassificationsQuery } from 'graphql/hooks/getPatientRiskClinicClassifications';
import { useGetPatientCardInfoQuery } from 'graphql/hooks/getPatientCardInfo';
import styled from 'styled-components';
import { resetMozartState } from 'store/actions/mozartSlice';

const StyledWrapper = styled.div`
  height: auto;
  display: block;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  background-color: ${COLORS.GREY4};
  padding: 24px 60px 30px 60px;
  min-height: 100vh;
`;

const StyledGridItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '670px',
  maxHeight: '670px',
  overflowX: 'auto',
});

export interface IScenarioFilters {
  patientId?: string;
  episodeId?: string;
  newEpisode?: string;
  attachEpisodeId?: string;
}
const getNumberOrNull = (param: string | undefined) =>
  param ? Number(param) : null;

const Scenario = () => {
  const [selectedScenario, updateSelectedScenario] = useState<number>();
  const [patientId, updatePatientId] = useState<number>(0);
  const currentUser = useSelector((state: IState) => state.user.currentUser);
  const systemValues = useSelector(
    (state: IState) => state.home.mainModel.systemValues
  );
  const roleType = useSelector((state: IState) => state.user.currentUser.type);
  const mainModel = useSelector((state: IState) => state.home.mainModel);
  useFetchMainModel(mainModel.lookups.length === 0);
  const dispatch = useDispatch();

  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  ) as IScenarioFilters;

  const checklistParam = useParams();
  const checklistId = getNumberOrNull(checklistParam.id);
  const episodeId = getNumberOrNull(params.episodeId);
  const isNewEpisode = params.newEpisode ? params.newEpisode === 'True' : null;
  const attachEpisodeId = getNumberOrNull(params.attachEpisodeId);

  const {
    data: patientData,
    isFetching: isPatientFetching,
    isSuccess: isGetSuccessPatient,
  } = useGetPatientCardInfoQuery(
    {
      patientId: patientId!,
    },
    {
      skip: !(patientId && patientId > 0),
    }
  );

  const { data: checklistData, isSuccess: isGetSuccessChecklist } =
    useGetChecklistModelQuery(
      {
        checklistId: checklistId!,
      },
      {
        skip: !(checklistId && checklistId > 0),
      }
    );

  const {
    data: mainModelData,
    isSuccess: isGetSuccessModel,
    isLoading: mainModelLoading,
  } = useGetScenarioMainModelQuery(
    {
      patientId: patientId!,
      checklistId: checklistId,
      episodeId: episodeId,
      isNewEpisode: isNewEpisode,
    },
    {
      skip: !(patientId && patientId > 0),
    }
  );

  const {
    data: patientClinicClassificationData,
    isSuccess: isGetSuccessPatientClinicClassification,
    isLoading: patientClinicClassificationLoading,
  } = useGetPatientRiskClinicClassificationsQuery(
    {
      patientId: patientId!,
    },
    {
      skip:
        !(patientId && patientId > 0) ||
        !hasUserPrivilege(Privilege.Risk) ||
        roleType === RoleType.NONCLINICAL,
    }
  );

  useEffect(() => {
    if (isGetSuccessChecklist) {
      updatePatientId(checklistData?.getChecklist.patientId ?? 0);
      updateSelectedScenario(checklistData?.getChecklist.scenarioId ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistData]);

  useEffect(() => {
    params.patientId && updatePatientId(Number(params.patientId));
  }, [params.patientId]);

  useEffect(() => {
    dispatch(setWorkflowStep({ step: 1, name: 'Scenario' }));
    dispatch(resetChecklist(ChecklistStorageType.CHECKLIST));
    dispatch(resetChecklist(ChecklistStorageType.MOZART));
    dispatch(resetMozartState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDataTable = () => {
    return roleType === RoleType.NONCLINICAL ? (
      <EpisodesTab
        style={{
          display: 'block',
        }}
        patientId={patientId}
        showAllTypes={false}
        isDirectOpen={false}
        showTatColumn={true}
        showCounterColumn={true}
        episodeType="episodes"
        readonly
        readonlyGrid
      />
    ) : (
      <TimelineTab
        style={{
          display: 'block',
        }}
        patientId={patientId}
      />
    );
  };

  const renderMaraData = () => {
    return isGetSuccessPatientClinicClassification &&
      !isEmpty(
        patientClinicClassificationData?.getPatientRiskClinicClassifications
      ) ? (
      <>
        <div style={{ padding: '0 28px' }}>
          <Typography variant="h4">Clinical Condition Risk Driver</Typography>
        </div>
        <ClinicalConditionView
          title=""
          rows={
            (patientClinicClassificationData?.getPatientRiskClinicClassifications ??
              []) as PatientRiskClinicalClassification[]
          }
          width={600}
          legendPosition="bottom"
        />
      </>
    ) : null;
  };

  return (
    <>
      <StyledWrapper data-testid="scenario-page-body">
        {patientData?.getPatientById &&
        patientData?.getAttributeDefinitions &&
        patientData?.getAttributes ? (
          <Grid
            container
            spacing={3}
            style={{
              flexDirection:
                roleType === RoleType.NONCLINICAL ? 'row-reverse' : 'row',
            }}
          >
            <Grid item xs={12} sm={8} style={{ display: 'flex' }}>
              <Panel padding="0">
                <Grid container spacing={0}>
                  <StyledGridItem
                    item
                    xs={12}
                    sm={6}
                    style={{ borderRight: `1px solid ${COLORS.GREY25}` }}
                  >
                    <Box px="24px" pt="24px" pb="20px">
                      <Typography variant="h4">Member Details</Typography>
                    </Box>
                    <PatientCard
                      patient={patientData?.getPatientById as Patient}
                      patientTopReminder={
                        patientData?.getTopReminder as BaseReminder
                      }
                      tags={patientData?.getPatientTags as Tag[]}
                      hideQuickNote
                      storageType={ChecklistStorageType.CHECKLIST}
                      attributes={
                        patientData?.getAttributes as EntityAttribute[]
                      }
                      variant="large"
                      OutInPatientAuthorizations={
                        patientData?.getPatientOpIpAuthorizations
                      }
                    />
                  </StyledGridItem>
                  <StyledGridItem item xs={12} sm={6}>
                    <CaseInformationCard
                      patient={patientData?.getPatientById as Patient}
                    />
                    {renderMaraData()}
                  </StyledGridItem>
                </Grid>
              </Panel>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Scenarios
                currentUser={currentUser}
                scenarios={(mainModelData?.findScenarios as IScenario[]) ?? []}
                patientId={patientId}
                checklistId={checklistId}
                episodeId={episodeId}
                isNewEpisode={isNewEpisode}
                attachEpisodeId={attachEpisodeId}
                systemValues={systemValues}
                selectedScenarioId={selectedScenario}
                onSelectScenario={updateSelectedScenario}
                scenariosLoading={mainModelLoading}
                updateSelectedScenario={updateSelectedScenario}
              />
            </Grid>
          </Grid>
        ) : null}

        {patientId && isGetSuccessPatient && isGetSuccessModel ? (
          <Grid container>
            <Grid item xs={12}>
              <Panel>{renderDataTable()}</Panel>
            </Grid>
          </Grid>
        ) : null}
      </StyledWrapper>
      <Loader
        active={isPatientFetching || patientClinicClassificationLoading}
      />
    </>
  );
};

export default Scenario;
