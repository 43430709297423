export enum dashBoardGridColumnNames {
  nurseOwner = 'nurseOwner',
  opOpen = 'opOpen',
  ipOpen = 'ipOpen',
  totalOpen = 'totalOpen',
  verifyAdmit = 'verifyAdmit',
  csr = 'csr',
  totalOpenWork = 'totalOpenWork',
  completedToday = 'completedToday',
  csrClosedToday = 'csrClosedToday',
}

export const gridColumns = [
  {
    name: dashBoardGridColumnNames.nurseOwner,
    title: 'Nurse Owner',
  },
  {
    name: dashBoardGridColumnNames.opOpen,
    title: 'OP Open',
  },
  {
    name: dashBoardGridColumnNames.ipOpen,
    title: 'IP Open',
  },
  {
    name: dashBoardGridColumnNames.totalOpen,
    title: 'Total Open',
  },
  {
    name: dashBoardGridColumnNames.verifyAdmit,
    title: 'Verify Admit',
  },
  {
    name: dashBoardGridColumnNames.csr,
    title: 'CSR',
  },
  {
    name: dashBoardGridColumnNames.totalOpenWork,
    title: 'Total Open Work',
  },
  {
    name: dashBoardGridColumnNames.completedToday,
    title: 'Completed Today',
  },
  {
    name: dashBoardGridColumnNames.csrClosedToday,
    title: 'CSR Closed Today',
  },
];

export const columnsExtension = [
  { columnName: dashBoardGridColumnNames.nurseOwner, width: '21%' },
  { columnName: dashBoardGridColumnNames.ipOpen, width: '9%' },
  { columnName: dashBoardGridColumnNames.opOpen, width: '9%' },
  { columnName: dashBoardGridColumnNames.totalOpen, width: '9%' },
  { columnName: dashBoardGridColumnNames.verifyAdmit, width: '10%' },
  { columnName: dashBoardGridColumnNames.csr, width: '9%' },
  { columnName: dashBoardGridColumnNames.totalOpenWork, width: '11%' },
  { columnName: dashBoardGridColumnNames.completedToday, width: '11%' },
  { columnName: dashBoardGridColumnNames.csrClosedToday, width: '11%' },
];

export const totalSummaryItems = [
  { columnName: dashBoardGridColumnNames.ipOpen, type: 'sum' },
  { columnName: dashBoardGridColumnNames.opOpen, type: 'sum' },
  { columnName: dashBoardGridColumnNames.totalOpen, type: 'sum' },
  { columnName: dashBoardGridColumnNames.verifyAdmit, type: 'sum' },
  { columnName: dashBoardGridColumnNames.csr, type: 'sum' },
  { columnName: dashBoardGridColumnNames.totalOpenWork, type: 'sum' },
  { columnName: dashBoardGridColumnNames.completedToday, type: 'sum' },
  { columnName: dashBoardGridColumnNames.csrClosedToday, type: 'sum' },
  { columnName: dashBoardGridColumnNames.nurseOwner, type: 'avg' }, // For Total Label purpose in userName column.
];
