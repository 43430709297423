import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@mui/material';
import {
  medicationsClaimsColumnsTypes,
  medicationsListColumnsTypes,
  StatusColumnValues,
} from '../consts';
import { ActionsCell, StatusColumn, UseColumn } from '.';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { COLORS } from 'consts/styles';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export const MedicationLabelCell = (props: ILabelCellProps) => {
  const { value, column, children } = props;

  const cellRenderer = React.useCallback(() => {
    switch (column.name) {
      case medicationsListColumnsTypes.actions:
        return <ActionsCell {...props} />;
      case medicationsListColumnsTypes.patientUseName:
        return <UseColumn {...props} />;
      case medicationsListColumnsTypes.rxStatus:
        return <StatusColumn {...props} values={StatusColumnValues} />;
      case medicationsClaimsColumnsTypes.dispenseDate: {
        const dateFormatted = formatDate(value);
        return (
          <Table.Cell
            {...props}
            value={dateFormatted}
            style={CellComponentStyle}
          />
        );
      }
      case medicationsListColumnsTypes.medication:
        return (
          <Table.Cell
            {...props}
            style={{ ...CellComponentStyle, color: COLORS.SYMPHONY_BLUE }}
          >
            <CustomTooltip title={props.value}>
              <Typography>{props.value}</Typography>
            </CustomTooltip>
          </Table.Cell>
        );

      default:
        return <Table.Cell {...props} style={CellComponentStyle} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column.name, props]);

  return (
    <>
      {children}
      {cellRenderer()}
    </>
  );
};
