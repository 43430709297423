import React from 'react';
import {
  CellComponent,
  NoDataCellComponent,
  NoDataRowComponent,
  RowComponent,
  StubHeaderCellComponent,
  TableComponent,
} from 'components/gridFormatters';
import {
  Table,
  TableProps,
  VirtualTable,
  VirtualTableProps,
} from '@devexpress/dx-react-grid-material-ui';

export const SymphonyVirtualTable = (props: VirtualTableProps) => (
  <VirtualTable
    stubHeaderCellComponent={StubHeaderCellComponent}
    tableComponent={TableComponent}
    rowComponent={RowComponent}
    cellComponent={CellComponent}
    noDataCellComponent={NoDataCellComponent}
    messages={{ noData: 'No data' }}
    {...props}
  />
);
export const SymphonyTable = (props: TableProps) => (
  <Table
    stubHeaderCellComponent={StubHeaderCellComponent}
    cellComponent={props.cellComponent ?? CellComponent}
    tableComponent={TableComponent}
    rowComponent={RowComponent}
    noDataRowComponent={NoDataRowComponent}
    noDataCellComponent={NoDataCellComponent}
    messages={{ noData: 'No data' }}
    {...props}
  />
);
