import { MozartActionSection } from './MozartActionSection';
import { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React, { useEffect, useRef, useState } from 'react';
import {
  MozartActionType,
  MozartProgressState,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  mozartResetChecklist,
  mozartStartPolling,
  mozartWorkflowPaused,
} from 'store/actions/mozartSlice';
import { useContinueMozartWorkflowMutation } from 'graphql/hooks/continueMozartWorkflow';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import Loader from 'components/loader';
import { StyledSectionWrapper } from '../MozartContent/components';
import { MozartStep } from '../MozartStep/MozartStep';
import { last } from 'lodash';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { useLazyGetMozartWorkFlowActionDataQuery } from 'graphql/hooks/getMozartWorkFlowActionData';
import { useUserNotAuthorizedToStartChecklist } from 'features/mozart/components/MozartContent/MozartContent.helpers';

export const MozartPauseAction = ({
  messages,
  isLoadingComplete,
}: {
  messages: MozartWorkflowUiModel[];
  isLoadingComplete: boolean;
}) => {
  const message = last(messages);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(500);

  const episodeId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].episodeId
  );
  const patientId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].patientId
  );

  const refAutoFocus = useRef<HTMLDivElement>(null);
  const [continueWorkflow] = useContinueMozartWorkflowMutation();

  const [getMozartWorkflowData, dataStatus] =
    useLazyGetMozartWorkFlowActionDataQuery({
      pollingInterval: pollingInterval,
    });

  const { userNotAuthorizedToStartChecklist } =
    useUserNotAuthorizedToStartChecklist(message);

  const isPause =
    message?.state === MozartProgressState.Paused ||
    message?.state === MozartProgressState.Started;

  useEffect(() => {
    setTimeout(() => {
      if (isPause && isLoadingComplete) {
        dispatch(mozartWorkflowPaused(true));
        if (refAutoFocus?.current) {
          window.scrollTo({
            behavior: 'smooth',
            top: refAutoFocus.current.offsetTop,
          });
        }
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingComplete]);

  useEffect(() => {
    const mozartWorkFlowActionData =
      dataStatus?.data?.getMozartWorkFlowActionData;
    if (
      isLoading &&
      mozartWorkFlowActionData?.result &&
      mozartWorkFlowActionData.data
    ) {
      const mozartMessages =
        mozartWorkFlowActionData.data as MozartWorkflowUiModel[];

      const isPauseCompleted = mozartMessages.some(
        (x) =>
          x.state === MozartProgressState.Completed &&
          x.actionType === MozartActionType.PauseWorkflow
      );
      if (isPauseCompleted) {
        setIsLoading(false);
        setPollingInterval(Infinity);
        dispatch(mozartStartPolling());
        dispatch(mozartWorkflowPaused(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStatus]);

  const handleContinuePausedAction = () => {
    setIsLoading(true);
    message &&
      continueWorkflow({
        id: message.workflowInstanceId,
        actionId: message.actionInstanceId,
        episodeId: episodeId ?? 0,
        patientId: patientId ?? 0,
        stepId: message.stepInstanceId,
        action: 'next',
      }).then((result) => {
        dispatch(mozartResetChecklist());
        if (
          'data' in result &&
          result?.data?.continueMozartWorkflow?.message === 'UnPause'
        ) {
          getMozartWorkflowData({ id: message.workflowInstanceId });
        } else {
          setIsLoading(false);
          dispatch(showErrorPopup({ message: 'Failed to unpause workflow.' }));
        }
      });
  };

  if (userNotAuthorizedToStartChecklist || !isPause) {
    return null;
  }

  return (
    <StyledSectionWrapper ref={refAutoFocus}>
      <MozartStep currentStep={message?.leftHead ?? ''} message={message} />
      <MozartActionSection
        actionButtonText={'Continue'}
        actionIcon={ICONS.PauseAction}
        actionIconColor={COLORS.GREY100}
        actionText={message?.showMessageValue ?? ''}
        actionTitle={'Done!'}
        actionButtonHandler={handleContinuePausedAction}
        isDoneStep
        borderColor={COLORS.GREY25}
      />
      <Loader active={isLoading} />
    </StyledSectionWrapper>
  );
};
