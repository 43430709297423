import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType, IDiagnosisCodes } from 'store/actions/types';
import {
  resetServiceLocationAndDiagnosisCodes,
  setMedicationCodes,
} from 'store/actions/checklistSlice';

interface IStyledEpisodeNumberWrapperProps {
  episodeLabelHasBorder: boolean;
  isPrintingOpen: boolean;
}

const getPadding = (props: IStyledEpisodeNumberWrapperProps): string => {
  if (props.isPrintingOpen) {
    return '2px 24px';
  } else {
    return !props.episodeLabelHasBorder ? '16px 28px' : '16px 0 0 0';
  }
};

const StyledEpisodeDetailsWrapper = styled.div<IStyledEpisodeNumberWrapperProps>`
  padding: ${(props) => getPadding(props)};
  margin: ${(props) =>
    props.episodeLabelHasBorder && !props.isPrintingOpen ? '0px 28px' : 0};
`;

export interface IPatientCardProps {
  episodeLabelHasBorder?: boolean;
  storageType: ChecklistStorageType;
  serviceLocation: string;
  diagnosisCodes: IDiagnosisCodes[];
}

const StyledTypography = styled(Typography)`
  display: inline;
  margin-right: 4px;
`;

const EpisodeDetails = (props: IPatientCardProps) => {
  const {
    episodeLabelHasBorder,
    storageType,
    serviceLocation,
    diagnosisCodes,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (diagnosisCodes) {
      dispatch(setMedicationCodes({ storageType, codes: diagnosisCodes }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisCodes]);

  useEffect(() => {
    return () => {
      dispatch(resetServiceLocationAndDiagnosisCodes({ storageType }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stateServiceLocation = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].serviceLocation
  );

  const stateDxCodes = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].diagnosisCodes
  );

  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );

  const isOptimizedView = printDocuments.isOptimizedView;
  const currentServiceLocation = stateServiceLocation?.name || serviceLocation;
  const currentDxCodes = stateDxCodes.length ? stateDxCodes : diagnosisCodes;

  return (
    <>
      {currentServiceLocation && (
        <StyledEpisodeDetailsWrapper
          episodeLabelHasBorder={!!episodeLabelHasBorder}
          isPrintingOpen={isOptimizedView}
        >
          <StyledTypography variant="body2">Service Location:</StyledTypography>
          <Typography display="inline" variant="body1">
            {currentServiceLocation}
          </Typography>
        </StyledEpisodeDetailsWrapper>
      )}

      {currentDxCodes.length > 0 && (
        <StyledEpisodeDetailsWrapper
          episodeLabelHasBorder={!!episodeLabelHasBorder}
          isPrintingOpen={isOptimizedView}
        >
          <StyledTypography variant="body2">Dx Codes:</StyledTypography>
          <Typography display="inline" variant="body1">
            {currentDxCodes.map((code, i) => (
              <span key={code.code} title={code.description as string}>
                {i > 0 && ', '}
                {code.code}
              </span>
            ))}
          </Typography>
        </StyledEpisodeDetailsWrapper>
      )}
    </>
  );
};

export default EpisodeDetails;
