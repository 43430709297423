import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type AddChecklistProblemsMutationVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
  problemIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;

export type AddChecklistProblemsMutation = {
  __typename?: 'Mutation';
  addChecklistProblems?: boolean | null;
};

export const AddChecklistProblemsDocument = `
    mutation addChecklistProblems($checklistId: Int!, $problemIds: [Int!]!) {
  addChecklistProblems(checklistId: $checklistId, problemIds: $problemIds)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addChecklistProblems: build.mutation<
      AddChecklistProblemsMutation,
      AddChecklistProblemsMutationVariables
    >({
      query: (variables) => ({
        document: AddChecklistProblemsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddChecklistProblemsMutation } = injectedRtkApi;
