import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';

const DashValueFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <>
    <Typography>{props.value == 0 ? <>-</> : props.value}</Typography>
  </>
);

const DashValueProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={DashValueFormatter} {...props} />
);

export default DashValueProvider;
