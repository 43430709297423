import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { StyledWrapper } from 'components/actions/sections/SectionBody/Components/Reminder/index';
import { ReminderComponent } from 'graphql/graphqlTypes';
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  color: ${COLORS.BLACK};
`;
const StyledInnerWrapper = styled.div`
  margin-right: 10px;
`;
const ReminderReason = ({ component }: { component: ReminderComponent }) => {
  const selectedReminderReason = useSelector(
    (state: IState) =>
      state.home?.mainModel?.lookups
        ?.filter((x) => x.name === 'ReminderReason')[0]
        ?.values?.filter((y) => y.id === component.reasonIdSelected)[0]
  );

  return (
    <StyledWrapper style={{ marginRight: '20px' }}>
      {selectedReminderReason && (
        <>
          {selectedReminderReason.image ? (
            <StyledInnerWrapper data-testid="selected-reminder-reason-image">
              <img
                alt={selectedReminderReason.name}
                style={{ display: 'block' }}
                src={selectedReminderReason.image}
              />
            </StyledInnerWrapper>
          ) : null}
          <StyledTypography variant="body2">
            {selectedReminderReason.name}
          </StyledTypography>
        </>
      )}
    </StyledWrapper>
  );
};

export default ReminderReason;
