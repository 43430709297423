import { ILookupValue } from 'backend/types/lookupValue';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';

export const StatusFilterAll: ILookupValue = {
  id: -1,
  name: 'All',
};
export const StatusFilterOpened: ILookupValue = {
  id: 0,
  name: 'Open',
};
export const StatusFilterClosed: ILookupValue = {
  id: 1,
  name: 'Closed',
};
export const EpisodeFilters: ILookupValue[] = [
  StatusFilterAll,
  StatusFilterOpened,
  StatusFilterClosed,
];
export const FilterGrid = ({
  selectedItemId,
  items,
  onChange,
}: {
  selectedItemId: number;
  onChange: (filter: ILookupValue) => void;
  items: ILookupValue[];
}) => (
  <Box display="flex" alignItems="center">
    <Typography style={{ paddingRight: '8px' }}>Show:</Typography>
    <TextField
      select
      size="small"
      variant="outlined"
      data-cy="grid-filter"
      value={selectedItemId}
      style={{ minWidth: '100px' }}
    >
      {items.map((item) => (
        <MenuItem key={item.id} value={item.id} onClick={() => onChange(item)}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  </Box>
);
