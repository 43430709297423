import { Menu } from '@mui/material';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledPopper = styled(Menu)({
  paper: {
    maxWidth: '400px',
    minWidth: '400px',
    backgroundColor: 'white',
    padding: '8px 20px',
    borderRadius: '4px',
    border: '1px solid #CECECE',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
  },
});

interface IPopperProps {
  anchor: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  children: ReactNode;
}

const Popper = ({ anchor, handleClick, children }: IPopperProps) => {
  const open = Boolean(anchor);
  const id = open ? 'details-popper' : undefined;
  return (
    <StyledPopper
      open={open}
      id={id}
      anchorEl={anchor}
      onClick={handleClick}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </StyledPopper>
  );
};
export default Popper;
