import axiosGitHubGraphQL from 'util/axiosHttp';

export interface IUploadedFileResponse {
  name: string;
  fileId?: string;
  createdOn?: Date;
  isSuccess: boolean;
  message: string;
}

export const uploadFile = async (
  file: File,
  onProgress: (percentage: number) => void,
  onSuccess: (uploadedFile: IUploadedFileResponse) => void,
  onError: (uploadedFile: IUploadedFileResponse) => void
) => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      onProgress(percentCompleted);
    },
  };
  try {
    const response = await axiosGitHubGraphQL.post(
      'file/upload',
      formData,
      config
    );
    onSuccess(response.data);
  } catch (e) {
    const uploadedFile: IUploadedFileResponse = {
      name: file.name,
      isSuccess: false,
      message: 'Could not upload the file.',
    };
    onError(uploadedFile);
  }
};
