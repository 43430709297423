import Orderable from './Items/Orderable';
import SubHeader from './Items/SubHeader';
import React from 'react';
import MedicationCodes from './Items/MedicationCodes';
import ChecklistNotification from 'components/actions/sections/SectionBody/Items/ChecklistNotification';
import { HealthServiceReview } from './Items/HealthService';
import AssociatedClaimReview from './Items/AssociatedClaimReview';
import DuplicateCheck from 'components/actions/sections/SectionBody/Items/DuplicateCheck/DuplicateCheck';
import LetterSummary from 'components/actions/sections/SectionBody/Items/LetterSummary/LetterSummary';
import CarePlan from 'components/actions/sections/SectionBody/Items/CarePlan/CarePlan';
import {
  ChecklistItem,
  ChecklistItemHealthServices,
  ChecklistItemMedicalCodes,
  ChecklistItemNote,
  ChecklistItemOrderable,
  ChecklistItemPriorAuthCodes,
  ChecklistItemReminder,
  ChecklistItemSubheader,
  ChecklistItemDuplicateCheck,
  AttributeType,
  ChecklistItemLetterSummary,
  ChecklistItemAssociatedClaims,
  ChecklistItemAssociatedEpisodes,
  ChecklistItemCarePlans,
} from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';
import PriorAuthCodesReview from './Items/PriorAuthCodes/PriorAuthCodesReview';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import AssociatedEpisodeReview from './Items/AssociatedEpisodeReview';

export interface ISectionBodyProps {
  checklistItem: ChecklistItem;
  categoryId: string;
  bigTableValues: IEntityLookup[];
  storageType: ChecklistStorageType;
  checklistId?: string;
  entityLookups: IEntityLookup[];
  attributeTypes: AttributeType[];
  lookups: ILookup[];
}

const SectionBody = (props: ISectionBodyProps) => {
  const item = props.checklistItem;
  const checklistId = props.checklistId;
  switch (item.type) {
    case 'orderable':
      return item.isVisible ? (
        <Orderable
          item={item as ChecklistItemOrderable}
          sectionItemId={item.uid}
          categoryId={props.categoryId}
          bigTableValues={props.bigTableValues}
          storageType={props.storageType}
          checklistId={props.checklistId}
          entityLookups={props.entityLookups}
          attributeTypes={props.attributeTypes}
          lookups={props.lookups}
        />
      ) : null;
    case 'subheader':
      return (
        <SubHeader
          item={item as ChecklistItemSubheader}
          storageType={props.storageType}
        />
      );
    case 'medical codes':
      return <MedicationCodes item={item as ChecklistItemMedicalCodes} />;
    case 'priorauthorization':
      return (
        <PriorAuthCodesReview item={item as ChecklistItemPriorAuthCodes} />
      );
    case 'reminder':
      return (
        <ChecklistNotification
          item={item as ChecklistItemReminder}
          type="reminder"
          isReview
        />
      );
    case 'note':
      return item.isVisible ? (
        <ChecklistNotification
          item={item as ChecklistItemNote}
          type="note"
          isReview
        />
      ) : null;
    case 'healthservices':
      return item.isVisible ? (
        <HealthServiceReview
          item={item as ChecklistItemHealthServices}
          checklistId={checklistId}
        />
      ) : null;
    case 'associated claim':
      return item.isVisible ? (
        <AssociatedClaimReview item={item as ChecklistItemAssociatedClaims} />
      ) : null;
    case 'associated episode':
      return item.isVisible ? (
        <AssociatedEpisodeReview
          item={item as ChecklistItemAssociatedEpisodes}
        />
      ) : null;
    case 'care plan':
      return (
        <CarePlan
          item={item as ChecklistItemCarePlans}
          storageType={props.storageType}
          isReadOnly={true}
        />
      );
    case 'duplicate check':
      return item.isVisible ? (
        <DuplicateCheck
          item={item as ChecklistItemDuplicateCheck}
          categoryId={'0'}
          autoSave={true}
          orderableIndex={0}
          storageType={ChecklistStorageType.CHECKLIST}
          isReadOnly={true}
          checklistId={props.checklistId}
        />
      ) : null;
    case 'letter summary':
      return item.isVisible ? (
        <LetterSummary
          item={item as ChecklistItemLetterSummary}
          storageType={props.storageType}
          isReadOnly={true}
        />
      ) : null;
    case 'conditions':
    case 'medications':
    default:
      return null;
  }
};

export default SectionBody;
