import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { Button, Divider, IconButton } from '@mui/material';
import React from 'react';
import { DeleteCommand } from '.';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

export const GridEditActions = ({
  id,
  text,
  onExecute,
  editEnabled,
  itemName,
  onCancel,
  divider = true,
  errors = false,
}: TableEditColumn.CommandProps & {
  itemName: string;
  editEnabled: boolean;
  onCancel?: () => void;
  errors?: boolean;
  divider?: boolean;
}) => {
  const onCancelClick = () => {
    onExecute();
    onCancel?.();
  };
  switch (id) {
    case 'edit':
      return (
        <>
          <CustomTooltip title="Edit">
            <div style={{ cursor: 'pointer', marginTop: '-2px' }}>
              <IconButton
                onClick={onExecute}
                style={{ padding: '2px' }}
                disabled={!editEnabled}
                size="large"
              >
                <Icon
                  icon={ICONS.EditAlt}
                  size={16}
                  color={editEnabled ? undefined : COLORS.GREY25}
                />
              </IconButton>
            </div>
          </CustomTooltip>
          {divider && (
            <Divider
              orientation="vertical"
              style={{
                backgroundColor: COLORS.GREY25,
                height: '18px',
                margin: '0px 8px 0px 16px',
              }}
            />
          )}
        </>
      );
    case 'delete':
      return (
        <DeleteCommand
          itemName={itemName}
          onExecute={onExecute}
          editEnabled={editEnabled}
        />
      );
    case 'commit':
      return (
        <Button onClick={onExecute} color="primary" disabled={errors}>
          OK
        </Button>
      );
    case 'cancel':
      return (
        <Button onClick={onCancelClick} color="primary">
          Cancel
        </Button>
      );
    default:
      return (
        <Button onClick={onExecute} color="primary">
          {text}
        </Button>
      );
  }
};
