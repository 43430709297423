import React from 'react';
import { StyledReviewTypography } from '../styles';
import { CheckboxRadioButtonComponent } from 'graphql/graphqlTypes';

export interface IRadioCheckBoxButtonProps {
  component: CheckboxRadioButtonComponent;
}

// If we ever decide to render anything when the component is unselected
// then we need to update <Line /> as well
const RadioCheckBoxButton = ({ component }: IRadioCheckBoxButtonProps) => {
  return (
    <>
      {component.selected ? (
        <StyledReviewTypography variant="body1">
          {component.value}
        </StyledReviewTypography>
      ) : null}
    </>
  );
};

export default RadioCheckBoxButton;
