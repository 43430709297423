import { IAuthUser } from 'backend/types/authUser';
import { ICountryCode, ILoginUser } from './types';

export const AUTH_START = 'AUTH_START';
export type AUTH_START = typeof AUTH_START;

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export type AUTH_SUCCESS = typeof AUTH_SUCCESS;

export const AUTH_FAIL = 'AUTH_FAIL';
export type AUTH_FAIL = typeof AUTH_FAIL;

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export type AUTH_LOGOUT = typeof AUTH_LOGOUT;

export const AUTH_REGISTER = 'AUTH_REGISTER';
export type AUTH_REGISTER = typeof AUTH_REGISTER;

export const PROFILE_DETAIL = 'PROFILE_DETAIL';
export type PROFILE_DETAIL = typeof PROFILE_DETAIL;

export const AUTH_SELECT_ROLE = 'AUTH_SELECT_ROLE';
export type AUTH_SELECT_ROLE = typeof AUTH_SELECT_ROLE;

export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export type AUTH_CHANGE_PASSWORD = typeof AUTH_CHANGE_PASSWORD;

export const AUTH_MFA_STEP = 'AUTH_MFA_STEP';
export type AUTH_MFA_STEP = typeof AUTH_MFA_STEP;

export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export type AUTH_FORGOT_PASSWORD = typeof AUTH_FORGOT_PASSWORD;

export const AUTH_SELECT_PROVIDER = 'AUTH_SELECT_PROVIDER';
export type AUTH_SELECT_PROVIDER = typeof AUTH_SELECT_PROVIDER;

export interface IAuthStartAction {
  type: AUTH_START;
}

export const authStart = (): IAuthStartAction => ({
  type: AUTH_START,
});

export interface IAuthSuccessAction {
  type: AUTH_SUCCESS;
  user: IAuthUser;
}

export const authSuccess = (authUser: IAuthUser): IAuthSuccessAction => ({
  type: AUTH_SUCCESS,
  user: authUser ? authUser : ({} as IAuthUser),
});

export interface IAuthLogoutAction {
  type: AUTH_LOGOUT;
  shouldRefreshPage: boolean;
}

export const authLogout = (shouldRefreshPage?: boolean): IAuthLogoutAction => ({
  type: AUTH_LOGOUT,
  shouldRefreshPage: Boolean(shouldRefreshPage),
});

export interface IAuthChangePasswordAction {
  type: AUTH_CHANGE_PASSWORD;
  user: ILoginUser;
  code?: string | null;
}

export const authChangePassword = (
  authUser: ILoginUser,
  code?: string | null
): IAuthChangePasswordAction => ({
  type: AUTH_CHANGE_PASSWORD,
  user: authUser ? authUser : ({} as ILoginUser),
  code: code,
});

export interface IAuthForgotPasswordAction {
  type: AUTH_FORGOT_PASSWORD;
}

export const authForgotPassword = (): IAuthForgotPasswordAction => ({
  type: AUTH_FORGOT_PASSWORD,
});

export interface IAuthMfaStepAction {
  type: AUTH_MFA_STEP;
  user: ILoginUser;
  countryCode: string;
  countryCodes: ICountryCode[];
  phone: string;
  mfaType: string;
  step: string;
}

export const authChangeMfaStep = (
  authUser: ILoginUser,
  countryCode: string,
  phone: string,
  mfaType: string,
  step: string,
  countryCodes: ICountryCode[]
): IAuthMfaStepAction => ({
  type: AUTH_MFA_STEP,
  user: authUser ? authUser : ({} as ILoginUser),
  phone: phone,
  mfaType: mfaType,
  step: step,
  countryCode: countryCode,
  countryCodes: countryCodes,
});

export interface IAuthSelectRoleAction {
  type: AUTH_SELECT_ROLE;
  user: ILoginUser;
}

export const authSelectRole = (
  authUser: ILoginUser
): IAuthSelectRoleAction => ({
  type: AUTH_SELECT_ROLE,
  user: authUser ? authUser : ({} as ILoginUser),
});

export interface IAuthSelectProviderAction {
  type: AUTH_SELECT_PROVIDER;
  user: ILoginUser;
}

export const authSelectProvider = (
  authUser: ILoginUser
): IAuthSelectProviderAction => ({
  type: AUTH_SELECT_PROVIDER,
  user: authUser ? authUser : ({} as ILoginUser),
});

export interface IAuthFailAction {
  type: AUTH_FAIL;
  error: string;
  isCaptchaRequired: boolean;
}

export const authFail = (
  error: string,
  isCaptchaRequired = false
): IAuthFailAction => ({
  type: AUTH_FAIL,
  error: error,
  isCaptchaRequired: isCaptchaRequired,
});

export type ILoginActions =
  | IAuthStartAction
  | IAuthSuccessAction
  | IAuthLogoutAction
  | IAuthSelectRoleAction
  | IAuthChangePasswordAction
  | IAuthForgotPasswordAction
  | IAuthMfaStepAction
  | IAuthFailAction
  | IAuthSelectProviderAction;
