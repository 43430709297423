import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import SimpleBoolFormatter from '../formatters/SimpleBoolFormatter';

const SimpleBoolProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={SimpleBoolFormatter} {...props} />
);

export default SimpleBoolProvider;
