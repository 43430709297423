import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import PriorAuthReqIconFormatter from '../formatters/PriorAuthReqIconFormatter';

const PriorAuthReqIconProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PriorAuthReqIconFormatter} {...props} />
);

export default PriorAuthReqIconProvider;
