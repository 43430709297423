import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdateUserPreferencesMutationVariables = Types.Exact<{
  data: Types.UserPreferencesViewModelInput;
}>;

export type UpdateUserPreferencesMutation = {
  __typename?: 'Mutation';
  updateUserPreferences?: {
    __typename?: 'UniversalResultModel';
    isSuccess?: boolean | null;
    comment?: string | null;
  } | null;
};

export const UpdateUserPreferencesDocument = `
    mutation updateUserPreferences($data: UserPreferencesViewModelInput!) {
  updateUserPreferences(data: $data) {
    isSuccess
    comment
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateUserPreferences: build.mutation<
      UpdateUserPreferencesMutation,
      UpdateUserPreferencesMutationVariables
    >({
      query: (variables) => ({
        document: UpdateUserPreferencesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateUserPreferencesMutation } = injectedRtkApi;
