import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import React, { ReactElement } from 'react';
import { teamMembersColumn } from '../teamMembers';
import { TextEditorCell } from './TextEditorCell';
import { UserEditCell } from './UserEditCell';
import { TeamMemberLabelCell } from './TeamMemberLabelCell';

export const TeamMemberEditCell = (
  props: TableEditRow.CellProps
): ReactElement<TableEditRow.CellProps> => {
  const { column } = props;
  switch (column.name) {
    case teamMembersColumn.member:
      return <UserEditCell {...props} />;
    case teamMembersColumn.note:
      return <TextEditorCell {...props} />;
    default:
      return <TeamMemberLabelCell {...props} />;
  }
};
