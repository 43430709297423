import axiosClient from 'util/axiosHttp';

export const launchCoachEducation = async (
  patientId: number,
  onSuccess: () => void,
  onError: (err?: string) => void
) => {
  let myCoachWindow = window.open('', 'healthwise');
  try {
    const response = await axiosClient.get(`Coach/LaunchCoach/${patientId}`);
    if (response.data.status == 'OK') {
      myCoachWindow = window.open(response.data.launchURL, 'healthwise');
      if (patientId) {
        const interval = setInterval(async function () {
          if (myCoachWindow?.closed) {
            clearInterval(interval);
            await axiosClient.get(
              `Coach/EndCoach/${response.data.callId}/${patientId}`
            );
            onSuccess();
          }
        }, 3000);
      }
    } else {
      throw new Error(response.data.errMsg);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    myCoachWindow?.close();
    if (e instanceof Error) {
      onError(e.message);
    }
  }
};
