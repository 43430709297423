import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetUserProvidersSelectModelQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserProvidersSelectModelQuery = {
  __typename?: 'EntitiesQuery';
  getUserProvidersSelectModel: {
    __typename?: 'GetUserProvidersSelectModel';
    selectedProviderId?: number | null;
    showProvidersSelect: boolean;
    showDialog: boolean;
    providers: Array<{
      __typename?: 'GetUserProvidersSelectModelProvider';
      id: number;
      name: string;
      npi?: string | null;
      providerId?: string | null;
      careSiteId: number;
    }>;
    careSites: Array<{
      __typename?: 'GetUserProvidersSelectModelCareSite';
      id: number;
      name: string;
    }>;
  };
};

export const GetUserProvidersSelectModelDocument = `
    query getUserProvidersSelectModel {
  getUserProvidersSelectModel {
    providers {
      id
      name
      npi
      providerId
      careSiteId
    }
    careSites {
      id
      name
    }
    selectedProviderId
    showProvidersSelect
    showDialog
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserProvidersSelectModel: build.query<
      GetUserProvidersSelectModelQuery,
      GetUserProvidersSelectModelQueryVariables | void
    >({
      query: (variables) => ({
        document: GetUserProvidersSelectModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetUserProvidersSelectModelQuery,
  useLazyGetUserProvidersSelectModelQuery,
} = injectedRtkApi;
