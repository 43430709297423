import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { close } from 'store/ui/modals/checklist';
import ChecklistDialog from '.';
import { useGetPatientByIdQuery } from 'graphql/hooks/getPatientById';
import { ChecklistViewModel, Patient } from 'graphql/graphqlTypes';
import { useGetChecklistModelQuery } from 'graphql/hooks/getChecklist';
import { ChecklistStorageType } from 'store/actions/types';
import {
  getActionsModelSuccess,
  resetChecklist,
} from 'store/actions/checklistSlice';

const ChecklistDialogController = () => {
  const dispatch = useDispatch();
  const checklistState = useSelector(
    (store: IState) => store.ui.modals.checklist
  );

  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const {
    data: checklistData,
    isFetching: checklistLoading,
    isSuccess: isGetSuccessChecklist,
  } = useGetChecklistModelQuery(
    {
      checklistId: checklistState.checklistId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !checklistState.open && !checklistState.checklistId,
    }
  );

  const { data: patientData } = useGetPatientByIdQuery(
    { patientId: patientId },
    { skip: patientId === 0 }
  );

  useEffect(
    () => {
      if (isGetSuccessChecklist && checklistData) {
        dispatch(
          getActionsModelSuccess({
            storageType: ChecklistStorageType.VIEW,
            checklist: checklistData.getChecklist as ChecklistViewModel,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isGetSuccessChecklist, checklistData]
  );

  const onClose = () => {
    dispatch(resetChecklist(ChecklistStorageType.VIEW));
    dispatch(close());
  };

  return (
    <>
      {checklistState.open && checklistData?.getChecklist && (
        <ChecklistDialog
          {...checklistState}
          onClose={onClose}
          open={checklistState.open}
          storageType={ChecklistStorageType.VIEW}
          isLoading={checklistLoading}
          patientData={(patientData?.getPatientById as Patient) ?? null}
        />
      )}
    </>
  );
};

export default ChecklistDialogController;
