import { useEffect, useState } from 'react';
import { ChecklistViewModel } from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { useGetChecklistModelQuery } from 'graphql/hooks/getChecklist';
import { useGetPatientCardInfoQuery } from 'graphql/hooks/getPatientCardInfo';
import { usePrevious } from 'hooks';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import {
  getActionsModelSuccess,
  setGlobalParams,
  updateExpressions,
} from 'store/actions/checklistSlice';
import { IState } from 'store';
import { setMozartGlobalParams } from 'store/actions/mozartSlice';
import { clearFailedRequests } from 'store/failedRequests/failedRequestsSlice';

interface IWorkflowState {
  currentUrl: string;
  isActionPage: boolean;
  isReviewPage: boolean;
  isFinishPage: boolean;
  workflowStep: number;
  workflowName: string;
}

export const useCurrentWorkflowStep = (url: string) => {
  const [workflowState, setWorkflowState] = useState<IWorkflowState>(
    {} as IWorkflowState
  );

  useEffect(() => {
    if (url === '/Checklist/Actions' || url === '/Workflow') {
      setWorkflowState({
        currentUrl: 'action',
        isActionPage: true,
        isReviewPage: false,
        isFinishPage: false,
        workflowStep: 2,
        workflowName: 'Actions',
      });
    } else if (url === '/Checklist/Review') {
      setWorkflowState({
        currentUrl: 'review',
        isActionPage: false,
        isReviewPage: true,
        isFinishPage: false,
        workflowStep: 3,
        workflowName: 'Review',
      });
    } else {
      setWorkflowState({
        currentUrl: 'finish',
        isActionPage: false,
        isReviewPage: false,
        isFinishPage: true,
        workflowStep: 4,
        workflowName: 'Done',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { ...workflowState };
};

export const useGetChecklistForWorkflow = (
  checklistId: number,
  workflowStep: number,
  workflowName: string,
  isActionPage: boolean,
  storageType: ChecklistStorageType,
  workflowInstanceId?: string
) => {
  const dispatch = useDispatch();
  const patientId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].patientId
  );
  const mozartWorkflowTitle = useSelector(
    (state: IState) =>
      state.mozart.completedChecklists[state.mozart.completedChecklistsIds[0]]
        ?.scenarioName
  );

  const {
    data: checklistData,
    isFetching: isChecklistFetching,
    isSuccess: isGetSuccessChecklist,
    refetch: refetchChecklist,
    isUninitialized,
  } = useGetChecklistModelQuery(
    {
      checklistId,
    },
    {
      skip: checklistId <= 0,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: patientData, isFetching: isPatientFetching } =
    useGetPatientCardInfoQuery(
      {
        patientId: patientId ?? 0,
      },
      {
        skip: !patientId,
      }
    );
  const prevWorkflowStep = usePrevious(workflowStep);

  useEffect(() => {
    if (prevWorkflowStep !== workflowStep && !isUninitialized) {
      refetchChecklist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUninitialized, prevWorkflowStep, workflowStep]);

  useEffect(() => {
    if (isGetSuccessChecklist && checklistData && !isChecklistFetching) {
      dispatch(
        getActionsModelSuccess({
          storageType,
          checklist: checklistData.getChecklist as ChecklistViewModel,
        })
      );
      dispatch(
        setGlobalParams({
          storageType,
          patientId: checklistData.getChecklist.patientId,
          episodeId: checklistData.getChecklist.episodeId ?? 0,
        })
      );
      dispatch(
        setMozartGlobalParams({
          mozartInstanceId: workflowInstanceId,
        })
      );
      if (isActionPage) {
        dispatch(updateExpressions({ storageType }));
      }

      dispatch(clearFailedRequests());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistData, isChecklistFetching, isGetSuccessChecklist]);

  useEffect(() => {
    dispatch(
      setWorkflowStep({
        step: workflowStep,
        name:
          checklistData?.getChecklist?.scenarioName ??
          mozartWorkflowTitle ??
          workflowName,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowStep, checklistData, mozartWorkflowTitle]);

  return { isLoading: isChecklistFetching || isPatientFetching, patientData };
};
