import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetAuthTurnAroundTimeQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['Int']>;
  careSiteId?: Types.InputMaybe<Types.Scalars['Int']>;
  priority?: Types.InputMaybe<Types.Scalars['String']>;
  defaultAuthorization?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetAuthTurnAroundTimeQuery = {
  __typename?: 'EntitiesQuery';
  getAuthTurnAroundTime?: Array<{
    __typename?: 'AuthTurnAroundTimeModel';
    lessThanZero: number;
    lessThanTwo: number;
    lessThanThree: number;
    greaterThanThree: number;
    careSiteId: number;
    careSiteName?: string | null;
  } | null> | null;
};

export const GetAuthTurnAroundTimeDocument = `
    query getAuthTurnAroundTime($userId: Int, $careSiteId: Int, $priority: String, $defaultAuthorization: String) {
  getAuthTurnAroundTime(
    userId: $userId
    careSiteId: $careSiteId
    priority: $priority
    defaultAuthorization: $defaultAuthorization
  ) {
    lessThanZero
    lessThanTwo
    lessThanThree
    greaterThanThree
    careSiteId
    careSiteName
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAuthTurnAroundTime: build.query<
      GetAuthTurnAroundTimeQuery,
      GetAuthTurnAroundTimeQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAuthTurnAroundTimeDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAuthTurnAroundTimeQuery,
  useLazyGetAuthTurnAroundTimeQuery,
} = injectedRtkApi;
