import { ILabelCellProps } from 'components/gridFormatters';
import React from 'react';
import { DateCell } from './cells/dateCell';
import { FloatCell } from './cells/floatCell';
import { RisingRiskCell } from './cells/risingRiskCell';
import { Table } from '@devexpress/dx-react-grid-material-ui';

export enum riskScoresColumnNames {
  createdOn = 'createdOn',
  ipScore = 'ip',
  erScore = 'eR',
  opScore = 'op',
  phyScore = 'phy',
  rxScore = 'rx',
  medScore = 'med',
  partBRx = 'brx',
  other = 'oth',
  total = 'tot',
  risingRisk = 'risingRiskFlag',
  riskLevel = 'acuityLevel',
}

export const riskScoresColumns = [
  { name: riskScoresColumnNames.createdOn, title: 'Date Added' },
  { name: riskScoresColumnNames.ipScore, title: 'IP Score' },
  { name: riskScoresColumnNames.erScore, title: 'ER Score' },
  { name: riskScoresColumnNames.opScore, title: 'OP Score' },
  { name: riskScoresColumnNames.phyScore, title: 'PHY Score' },
  { name: riskScoresColumnNames.rxScore, title: 'RX Score' },
  { name: riskScoresColumnNames.medScore, title: 'Med Score' },
  { name: riskScoresColumnNames.partBRx, title: 'Part B RX' },
  { name: riskScoresColumnNames.other, title: 'Other' },
  { name: riskScoresColumnNames.total, title: 'Total Score' },
  { name: riskScoresColumnNames.risingRisk, title: 'Rising Risk' },
  { name: riskScoresColumnNames.riskLevel, title: 'Acuity Level' },
];

export const riskScoresColumnsExtensionStyles = [
  {
    columnName: riskScoresColumnNames.createdOn,
    width: '10%',
  },
  {
    columnName: riskScoresColumnNames.ipScore,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.erScore,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.opScore,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.phyScore,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.rxScore,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.medScore,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.partBRx,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.other,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.total,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.risingRisk,
    width: '8%',
  },
  {
    columnName: riskScoresColumnNames.riskLevel,
    width: '10%',
  },
];

export const riskScoresColumnsExtensionSorting = [
  {
    columnName: riskScoresColumnNames.createdOn,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.ipScore,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.erScore,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.opScore,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.phyScore,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.rxScore,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.medScore,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.partBRx,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.other,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.total,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.risingRisk,
    sortingEnabled: true,
  },
  {
    columnName: riskScoresColumnNames.riskLevel,
    sortingEnabled: true,
  },
];

export const labelRiskScore = (props: ILabelCellProps) => {
  const { column } = props;

  switch (column.name) {
    case riskScoresColumnNames.createdOn:
      return <DateCell {...props} />;
    case riskScoresColumnNames.risingRisk:
      return <RisingRiskCell accuracy={0} {...props} />;
    case riskScoresColumnNames.riskLevel:
      return <Table.Cell {...props} />;
    default:
      return <FloatCell accuracy={3} {...props} />;
  }
};
