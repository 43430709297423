import React, { useEffect, useRef } from 'react';
import { BaseComponent, ComputedValueComponent } from 'graphql/graphqlTypes';
import { IState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { ChecklistStorageType } from 'store/actions/types';
import { addChecklistScoringValue } from 'store/actions/checklistSlice';

export interface IBasicScoringProps {
  isActionSelected?: boolean | null;
  component: ComputedValueComponent;
  storageType: ChecklistStorageType;
  handleChecklistInputChange: (
    value: string,
    field: BaseComponent,
    componentId?: string
  ) => void;
}

const BasicScoring = (props: IBasicScoringProps) => {
  const {
    component,
    isActionSelected,
    storageType,
    handleChecklistInputChange,
  } = props;

  const dispatch = useDispatch();

  const scoringValue = useSelector((state: IState) =>
    component.scoringGroupId
      ? state.checklist?.documentsState[storageType]?.scoringValues[
          component.scoringGroupId
        ]?.scoringTotal || 0
      : 0
  );

  useEffect(() => {
    if (component.scoringGroupId) {
      dispatch(
        addChecklistScoringValue({
          storageType,
          scoringGroupId: component?.scoringGroupId.toString() ?? '',
          score: Number(component.value),
          initial: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component.scoringGroupId]);

  const savedValue = useRef<number | null>(null);
  useEffect(() => {
    if (!isActionSelected) {
      return;
    }

    if (scoringValue !== savedValue.current) {
      savedValue.current = scoringValue;
      handleChecklistInputChange(
        scoringValue.toString() || '',
        {
          ...component,
          groupId: '',
          type: 'string',
        },
        component.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoringValue, isActionSelected]);

  if (isActionSelected && component.scoringGroupId) {
    return <Typography variant="body1">{scoringValue ?? 0}</Typography>;
  }
  return null;
};

export default BasicScoring;
