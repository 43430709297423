import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { IState } from 'store';
import { COLORS } from 'consts/styles';

import HeaderDialog from 'components/home/headerdialog';
import { useGetUserProvidersSelectModelQuery } from 'graphql/hooks/getUserProvidersSelectModel';
import {
  StyledDivider,
  StyledMenuTrigger,
  StyledTypography,
} from './StyledComponents';
import ProvidersList from 'components/roleslist/ProvidersList';

const ChangeProviderMenu = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);
  const loggedInAsProvider = useSelector(
    (state: IState) => state.user.currentUser.loggedInAsProvider
  );
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const currentCareSiteName = useSelector(
    (state: IState) => state.user.currentUser.careSite
  );

  const skipGetUserProvidersSelectModelQuery = !(
    currentCareSiteId &&
    isLoggedIn &&
    loggedInAsProvider
  );
  const {
    data: providersSelectModel,
    isSuccess: isProvidersSelectLoaded,
    isFetching: isFetchingProvidersSelect,
    refetch: refetchProvidersSelect,
  } = useGetUserProvidersSelectModelQuery(
    {},
    { skip: skipGetUserProvidersSelectModelQuery }
  );

  const onChangeProviderClick = () => {
    setOpenDialog(true);
  };
  const onDialogClose = (changed: boolean) => {
    if (changed) {
      refetchProvidersSelect();
    }
    setOpenDialog(false);
  };

  const buttonTitle = providersSelectModel?.getUserProvidersSelectModel
    ?.showProvidersSelect
    ? 'Change Provider'
    : 'Change Location';

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <StyledMenuTrigger $menuOpen={false} $disableHover={true}>
          <StyledTypography variant="h6" $textColor={COLORS.WHITE}>
            {currentCareSiteName}
          </StyledTypography>
        </StyledMenuTrigger>
      </Box>
      <StyledDivider orientation="vertical" />
      <HeaderDialog open={openDialog} onClose={onDialogClose}>
        <ProvidersList
          padding="0"
          callback={onDialogClose}
          selectedCareSiteId={currentCareSiteId}
          providersSelectModel={providersSelectModel}
          isFetchingProvidersSelect={isFetchingProvidersSelect}
          isProvidersSelectLoaded={isProvidersSelectLoaded}
        />
      </HeaderDialog>
      {isProvidersSelectLoaded &&
        providersSelectModel?.getUserProvidersSelectModel?.showDialog && (
          <>
            <div>
              <StyledMenuTrigger
                onClick={() => onChangeProviderClick()}
                $menuOpen={false}
                $disableHover={false}
              >
                <StyledTypography variant="h6" $textColor={COLORS.BLUE10}>
                  {buttonTitle}
                </StyledTypography>
                <ArrowDropDown />
              </StyledMenuTrigger>
            </div>
            <StyledDivider orientation="vertical" />
          </>
        )}
    </Box>
  );
};

export default ChangeProviderMenu;
