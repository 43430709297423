import React, { useState } from 'react';

export interface IValidationProps<TValue> {
  children: JSX.Element;
  validation: (value: TValue) => boolean;
}

export const ValidationWrapper = <T,>(props: IValidationProps<T>) => {
  const { validation, children } = props;
  const callback = children.props.onChange;
  const [error, onChange] = useValidation<T>({ callback, validation });

  return (
    <children.type {...children.props} error={error} onChange={onChange} />
  );
};

interface ValidationHookProps<TValue> {
  callback?: (value: TValue) => void;
  validation: (value: TValue) => boolean;
}

const useValidation = <T,>(props: ValidationHookProps<T>) => {
  const [error, setError] = useState(false);

  const { callback, validation } = props;
  const withValidation = (value: T) => {
    const hasError = validation(value);
    setError(hasError);
    return callback?.(value);
  };

  return [error, withValidation];
};
