import React, { ChangeEvent, useEffect, useMemo } from 'react';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import throttle from 'lodash/throttle';
import { ILookupValue } from 'backend/types/lookupValue';
import { usePrevious } from 'hooks';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

interface IAutocompleteProps {
  label?: string;
  suggestOptions: () => void;
  options: ILookupValue[];
  inputValue: string;
  onInputChange: (input: string) => void;
  onChange: (newValue: ILookupValue | null) => void;
  value: ILookupValue | null;
  error?: boolean;
  noOptionsText?: string;
}

const StyledTextField = styled(TextField)`
  font-size: 14px;
  color: ${COLORS.NAVY_BLUE};
  background-color: ${COLORS.WHITE};
  .MuiAutocomplete-inputRoot {
    padding: 4px 32px 4px 6px !important;
    .MuiAutocomplete-input {
      padding: 2px 4px 2px 6px;
    }
  }
`;

const RemoteAutocomplete = ({
  onInputChange,
  suggestOptions,
  options,
  onChange,
  inputValue,
  value,
  error,
  noOptionsText,
}: IAutocompleteProps) => {
  const prevInput = usePrevious(inputValue);
  const fetchData = useMemo(
    () => throttle(() => suggestOptions(), 250),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suggestOptions, inputValue]
  );

  useEffect(() => {
    if (
      inputValue.length > 2 &&
      inputValue !== value?.name &&
      inputValue !== prevInput
    ) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Autocomplete
      getOptionLabel={(option: ILookupValue) => option.name}
      filterOptions={(x) => x}
      autoComplete
      options={inputValue.length > 2 ? options : []}
      style={{ width: '100%' }}
      includeInputInList
      noOptionsText={noOptionsText ?? 'No options'}
      filterSelectedOptions
      onInputChange={(_event: ChangeEvent<unknown>, newInputValue: string) =>
        onInputChange(newInputValue)
      }
      value={value}
      onChange={(
        _event: ChangeEvent<unknown>,
        newValue: ILookupValue | null
      ) => {
        onChange(newValue);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <StyledTextField
          type="text"
          {...params}
          variant="outlined"
          fullWidth={true}
          error={error}
        />
      )}
    />
  );
};

export default RemoteAutocomplete;
