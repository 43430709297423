import React from 'react';
import { Patient } from 'graphql/graphqlTypes';
import Sections from 'components/actions/sections/sections';
import ReviewHeader from 'components/review/header';
import ReviewSections from 'components/review/sections';
import { IAuthUser } from 'backend/types/authUser';
import Actions from 'components/finish/actions';
import FinishHeader from 'components/finish/header';
import Panel from 'components/panel';
import { ChecklistStorageType } from 'store/actions/types';

export interface IWorkflowLayoutPageContentProps {
  patient: Patient | undefined;
  currentUser: IAuthUser;
  autoSave: boolean;
  isReviewPage: boolean;
  isActionPage: boolean;
  isFinishPage: boolean;
}

const WorkflowLayoutPageContent = (props: IWorkflowLayoutPageContentProps) => {
  const { patient, currentUser, isActionPage, isReviewPage, isFinishPage } =
    props;

  return (
    <>
      {isActionPage ? (
        <Sections
          storageType={ChecklistStorageType.CHECKLIST}
          autoSave={true}
          testId="actions-sections"
        />
      ) : null}
      {isReviewPage ? (
        <Panel padding="24px" testId="review-sections" isHighlighted>
          <ReviewHeader patient={patient} currentUser={currentUser} />
          <ReviewSections storageType={ChecklistStorageType.CHECKLIST} />
        </Panel>
      ) : null}
      {isFinishPage ? (
        <div data-testid="finish-actions">
          <FinishHeader />
          <Actions />
        </div>
      ) : null}
    </>
  );
};

export default WorkflowLayoutPageContent;
