import { IDropdownItem } from '../types';
import { IValidationResult } from 'util/validationUtils';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import React from 'react';
import { IDropdownProps } from './Dropdown';
import styled from 'styled-components';
import { Popper, TextField } from '@mui/material';
import { PopperProps } from '@mui/material/Popper/Popper';

const StyledTextField = styled(TextField)<{ $color?: string }>`
  font-size: 14px;
  border-radius: 4px;
  margin-bottom: 0;
  padding: 0 !important;
  min-height: 32px !important;
  .MuiAutocomplete-inputRoot {
    padding: 3.5px 32px 3.5px 6px !important;
    ${({ $color }) => ($color ? `color: ${$color};` : '')}
  }
`;

export interface IDropdownAutocompleteProps extends IDropdownProps {
  options: IDropdownItem[];
  value: string | null | undefined;
  validation: IValidationResult;
  handleChange: (selectedValue: IDropdownItem | null) => void;
  dropdownWidth?: string;
  isUpdateActionValueRequestFailed: boolean;
}

export const DropdownAutocomplete = (props: IDropdownAutocompleteProps) => {
  const {
    component,
    options,
    value,
    isActionSelected,
    isReadOnly,
    validation,
    handleChange,
    dropdownWidth,
    isUpdateActionValueRequestFailed,
  } = props;

  const activeValueName =
    options?.find((item) => item.value === value)?.name || '';

  const defaultProps = {
    options: [
      {
        value: '',
        name: '',
      } as IDropdownItem,
    ].concat(options),
  };

  const AutocompletePopper = (popperProps: PopperProps) => {
    return (
      <Popper
        onResizeCapture={undefined}
        nonce={undefined}
        onResize={undefined}
        {...popperProps}
        style={{ width: dropdownWidth }}
        placement="bottom-end"
      >
        {popperProps.children}
      </Popper>
    );
  };

  return (
    <Autocomplete
      {...defaultProps}
      getOptionLabel={(option: IDropdownItem) => option.name}
      data-testid={`Autocomplete_${component.uniqueID}`}
      {...(component.userDefinedId
        ? { 'data-userdefinedid': component.userDefinedId }
        : '')}
      disabled={isReadOnly}
      autoComplete
      isOptionEqualToValue={(option: IDropdownItem, selValue: IDropdownItem) =>
        option.value === selValue.value
      }
      defaultValue={{} as IDropdownItem}
      disableClearable
      fullWidth={true}
      size="small"
      style={{
        minWidth: dropdownWidth,
        backgroundColor: 'white',
      }}
      onChange={(_event, selectedValue) =>
        handleChange(selectedValue as IDropdownItem)
      }
      value={{ value: value ?? '', name: activeValueName }}
      renderOption={(params, option) => (
        <li {...params}>{option.name ? option.name : <i>None</i>}</li>
      )}
      PopperComponent={AutocompletePopper}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <StyledTextField
          $color={component.color ? component.color : undefined}
          type="text"
          {...params}
          variant="outlined"
          fullWidth={true}
          error={
            (validation.hasError && isActionSelected && !isReadOnly) ||
            isUpdateActionValueRequestFailed
          }
        />
      )}
    />
  );
};
