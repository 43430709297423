import React from 'react';
import Divider from '@mui/material/Divider';
import { Patient, PatientEditTagsTabViewModel } from 'graphql/graphqlTypes';
import { formatPatientAge } from 'components/scenario/patient/PatientCard.helper';
import { Box, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { IconFallBack } from 'components/IconFallBack';
import { IMG_TO_SVG, imageName } from '../../IconFallBack/IconFallBack';
import styled from 'styled-components';
import { Ellipsis } from 'components/text';

const StyledWrapper = styled.div`
  width: 100%;
  color: ${COLORS.PALE_GREY};
  overflow-y: auto;
`;
const StyledTypography = styled(Typography)({
  color: `${COLORS.WHITE}!important`,
});

const StyledDivider = styled(Divider)({
  marginLeft: '18px',
  marginRight: '18px',
  backgroundColor: COLORS.WHITE,
  height: '4px',
  width: '4px',
  borderRadius: '50%',
  marginTop: '0px',
  opacity: '0.2',
  display: 'flex',
  alignSelf: 'center',
});

export interface IPatientDetailsTitleProps {
  patientInfo?: Patient | null;
}

const PatientDetailsTitle = (props: IPatientDetailsTitleProps) => {
  const { patientInfo } = props;
  const patientTags = useSelector(
    (state: IState) => state.patientDetails.patientTags
  );

  const isNewTagIcon = (item: PatientEditTagsTabViewModel) =>
    IMG_TO_SVG[imageName(item.icon ?? String(null))];

  return (
    <StyledWrapper>
      {patientInfo && patientInfo.mRN ? (
        <div style={{ width: '100%', display: 'flex' }}>
          <Ellipsis>
            <StyledTypography noWrap variant="h4">
              {patientInfo.fullName}
            </StyledTypography>
          </Ellipsis>
          <StyledDivider orientation="vertical" flexItem />
          <Ellipsis>
            <StyledTypography noWrap variant="subtitle2">
              {formatPatientAge(patientInfo)}
            </StyledTypography>
          </Ellipsis>
          <StyledDivider orientation="vertical" flexItem />
          <Ellipsis>
            <StyledTypography noWrap variant="subtitle2">
              Member ID {patientInfo.mRN}
            </StyledTypography>
          </Ellipsis>
          {patientTags && (
            <Box display="flex" alignItems="center" paddingLeft="20px">
              {patientTags.map((item) => (
                <Box
                  key={item.id}
                  style={{
                    width: isNewTagIcon(item) ? 20 : 16,
                    height: isNewTagIcon(item) ? 20 : 16,
                    marginRight: '8px',
                    padding: isNewTagIcon(item) ? '0px' : '2px',
                    borderRadius: '50%',
                    backgroundColor: isNewTagIcon(item)
                      ? undefined
                      : COLORS.WHITE,
                  }}
                >
                  <IconFallBack
                    name={item.name}
                    image={String(item.icon)}
                    type="Tag"
                    iconSize={20}
                  />
                </Box>
              ))}
            </Box>
          )}
        </div>
      ) : (
        <StyledTypography variant="h4">Add New Member</StyledTypography>
      )}
    </StyledWrapper>
  );
};

export default PatientDetailsTitle;
