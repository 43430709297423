import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { Divider, Grid, Box, Typography } from '@mui/material';

export const StyledHeaderDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StyledBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px;
`;

export const StyledServiceRowDiv = styled.div`
  width: 60%;
  margin-left: 20px;
`;

export const StyledDivider = styled(Divider)`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  height: 20px;
  background-color: ${COLORS.BLACK};
`;

export const StyledContainer = styled(Grid)({
  marginBottom: '20px',
  backgroundColor: COLORS.GREY4,
  borderRadius: '4px',
  overflow: 'hidden',
});

export const GridBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  flex-direction: row;
`;

export const StyledHeader = styled(GridBox)<{ $color: string }>`
  background-color: ${(props) => props.$color};
  width: 100%;
  padding: 10px 20px;
`;

export const DecisionGroupBox = styled(Box)({
  borderLeft: '1px solid',
  borderColor: COLORS.GREY25,
  paddingLeft: '30px',
  width: '40%',
});

export const HsrnDecisionBox = styled(Box)({
  borderLeft: '1px solid',
  borderColor: COLORS.GREY25,
  paddingLeft: '20px',
  width: '40%',
});

export const InputGroupBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const StyledWrapper = styled.div`
  display: flex;
  padding: 4px 10px 4px 10px;
  border-radius: 10%;
`;

export const SpanYellow = styled.span`
  background-color: ${COLORS.YELLOW20};
  padding: 2px 5px 2px 5px;
  border-radius: 4%;
`;

export const SpanGreen = styled.span`
  background-color: ${COLORS.GREEN20};
  padding: 2px 5px 2px 5px;
  border-radius: 4%;
`;

export const StyledDecision = styled(Typography)({
  marginBottom: '10px',
  borderRadius: '4px',
  overflow: 'hidden',
});

export const OverallReviewBox = styled(Box)({
  padding: '0px 0px 0px 0px',
});

export const ManualReviewBox = styled(Box)({
  padding: '0px 0px 12px 0px',
  backgroundColor: COLORS.WHITE,
});

export const IndividualServiceBox = styled(Box)({
  padding: '12px 60px 0px 60px',
  backgroundColor: COLORS.WHITE,
  borderRadius: '4px',
});

export const OverallSummaryBox = styled(Box)({
  padding: '12px 16px 16px 60px',
  backgroundColor: COLORS.GREY4,
});

export const OverallStatusBox = styled(Box)({
  padding: '12px 16px 5px 0px',
});

export const OverallStyledBox = styled(Box)({
  padding: '12px 16px 12px 0px',
  alignItems: 'center',
  display: 'flex',
});

export const StyledReasonBox = styled(Box)({
  borderLeft: '1px solid',
  borderColor: COLORS.GREY25,
  paddingLeft: '20px',
});

export const OverallStyledTypography = styled(Typography)({
  marginRight: '4px',
});

export const OverallTypographyDenial = styled(OverallStyledTypography)({
  marginLeft: '30px',
});
