import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type AddEpisodeBalancerCriteriaToQueueMutationVariables = Types.Exact<{
  episodeBalancerCriteria: Types.EpisodeBalancerCriteriaInput;
}>;

export type AddEpisodeBalancerCriteriaToQueueMutation = {
  __typename?: 'Mutation';
  addEpisodeBalancerCriteriaToQueue?: boolean | null;
};

export const AddEpisodeBalancerCriteriaToQueueDocument = `
    mutation addEpisodeBalancerCriteriaToQueue($episodeBalancerCriteria: EpisodeBalancerCriteriaInput!) {
  addEpisodeBalancerCriteriaToQueue(
    episodeBalancerCriteria: $episodeBalancerCriteria
  )
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addEpisodeBalancerCriteriaToQueue: build.mutation<
      AddEpisodeBalancerCriteriaToQueueMutation,
      AddEpisodeBalancerCriteriaToQueueMutationVariables
    >({
      query: (variables) => ({
        document: AddEpisodeBalancerCriteriaToQueueDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddEpisodeBalancerCriteriaToQueueMutation } = injectedRtkApi;
