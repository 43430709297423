import { attachmentColumns } from './attachmentColumns';

const compareStrings = (a: string, b: string) => {
  return a < b ? -1 : 1;
};

export const attachmentColumnExtension = [
  {
    columnName: attachmentColumns.createdOn,
    editingEnabled: false,
    compare: compareStrings,
    width: '140px',
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: attachmentColumns.addedBy,
    editingEnabled: false,
    compare: compareStrings,
    width: '15%',
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: attachmentColumns.type,
    editingEnabled: false,
    sortingEnabled: false,
    width: '65px',
  },
  {
    columnName: attachmentColumns.description,
    editingEnabled: false,
    width: '15%',
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: attachmentColumns.actions,
    editingEnabled: false,
    sortingEnabled: false,
    width: '240px',
    wordWrapEnabled: true,
  },
  {
    columnName: attachmentColumns.name,
    compare: compareStrings,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
];

export const attachmentColumnExtensionsSidebarOpen = [
  {
    columnName: attachmentColumns.createdOn,
    editingEnabled: false,
    compare: compareStrings,
    width: '140px',
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: attachmentColumns.type,
    editingEnabled: false,
    sortingEnabled: false,
    width: '45px',
  },
  {
    columnName: attachmentColumns.description,
    editingEnabled: false,
    width: '25%',
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
  {
    columnName: attachmentColumns.name,
    compare: compareStrings,
    wordWrapEnabled: true,
    sortingEnabled: true,
  },
];
