import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { useLocation } from 'react-router-dom';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { useDispatch } from 'react-redux';
import { keepSelectedPatientDetailsTabInfo } from 'store/patientdetails/patientDetailsSlice';
import { closePatientDetailsDialog } from 'store/patientdetails/patientDetails.helpers';
import {
  resetMozartState,
  setMozartGlobalParams,
} from 'store/actions/mozartSlice';
import { resetChecklist } from 'store/actions/checklistSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { useNavigate } from 'react-router';
import { useContinueStepCheck } from 'components/scenario/episode/hooks';
interface IMozartNextStepButtonProps {
  buttonText?: string | null;
  mozartWorkflowCompleted?: boolean;
  userInputLock?: boolean;
  mozartActionEnabled?: boolean;
  workflowInstanceId: string;
  normalPadding?: boolean;
  isDisabled?: boolean;
}

const StyledSpan = styled.div`
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledButton = styled(Button)<{
  $stepType: string;
  $normalPadding: boolean;
}>`
  width: 100%;
  padding: ${(props) => (props.$normalPadding ? '6px 12px' : '0 12px')};

  ${(props) => {
    switch (props.$stepType) {
      case 'complete': // Gray10 - completed
        return `
          &.MuiButton-contained.Mui-disabled {
            color: ${COLORS.GREY100};
            background-color: ${COLORS.GREY10};
          }
        `;
      case 'disable': // Gray - Cant access
        return `
          &.MuiButton-contained.Mui-disabled {
            color: ${COLORS.WHITE};
            background-color: ${COLORS.GREY60};
          }
        `;
      default:
        return '';
    }
  }}
`;

const MozartNextStepButton = (props: IMozartNextStepButtonProps) => {
  const {
    buttonText,
    mozartWorkflowCompleted,
    userInputLock,
    mozartActionEnabled,
    workflowInstanceId,
    normalPadding,
    isDisabled,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isWorkflowRoute = location.pathname.includes('Workflow');

  const handleNextStepConfirm = () => {
    if (isWorkflowRoute) {
      dispatch(resetChecklist(ChecklistStorageType.MOZART));
      dispatch(resetMozartState());
      closePatientDetailsDialog(dispatch);
      dispatch(
        setMozartGlobalParams({
          mozartInstanceId: workflowInstanceId,
          restartMozart: true,
        })
      );
    } else {
      dispatch(keepSelectedPatientDetailsTabInfo());
    }
    navigate(`/Workflow/${workflowInstanceId}`);
  };

  const {
    checkContinueOnDifferentUser,
    confirmation: continueEpisodeConfirmation,
    checkContinueEpisodeIsFetching,
  } = useContinueStepCheck(handleNextStepConfirm);

  const handleNextStepClick = () => {
    checkContinueOnDifferentUser(workflowInstanceId);
  };

  const getNextStepType = (): string => {
    if (mozartWorkflowCompleted) {
      return 'complete';
    }
    if (!mozartActionEnabled) {
      return 'disable';
    }
    return userInputLock ? 'inProgress' : 'notStarted';
  };

  if (!buttonText) {
    return null;
  }

  const isActionsDisabled =
    mozartWorkflowCompleted ||
    !mozartActionEnabled ||
    isDisabled ||
    checkContinueEpisodeIsFetching;

  return (
    <div>
      <StyledButton
        onClick={handleNextStepClick}
        $stepType={getNextStepType()}
        variant="contained"
        data-testid="next-step"
        disableElevation
        disabled={isActionsDisabled}
        color={isActionsDisabled ? 'inherit' : 'primary'}
        $normalPadding={Boolean(normalPadding)}
      >
        <CustomTooltip title={buttonText}>
          <StyledSpan>
            {mozartWorkflowCompleted ? 'Completed' : buttonText}
          </StyledSpan>
        </CustomTooltip>
      </StyledButton>
      {continueEpisodeConfirmation}
    </div>
  );
};

export default MozartNextStepButton;
