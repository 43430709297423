import React, { ChangeEvent, useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { IValidationResult } from 'util/validationUtils';
import { IDropdownItem } from '../types';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { useChecklistValidation } from 'components/actions/sections/SectionBody/Items/Orderable';
import { calculateSelectWidth } from 'util/helpers/styleHelpers';
import styled from 'styled-components';
import { validateUser } from 'components/actions/sections/SectionBody/Components/Reminder/index.helpers';
import { ChecklistStorageType } from 'store/actions/types';
import { COLORS } from 'consts/styles';
import { StyledWrapper } from 'components/actions/sections/SectionBody/Components/Reminder/index';
import { ReminderComponent } from 'graphql/graphqlTypes';

const StyledTextField = styled(TextField)`
  .MuiAutocomplete-inputRoot {
    padding: 3.5px 32px 3.5px 6px !important;
  }
`;

const StyledTypography = styled(Typography)`
  color: ${COLORS.BLACK};
`;

const StyledInnerWrapper = styled.div`
  margin-right: 10px;
`;
const StyledAutocompleteWrapper = styled.div`
  margin-right: 20px;
`;

interface IReminderUserProps {
  component: ReminderComponent;
  handleReminderInputChange: (
    value: string | Date | null,
    reminderComponentType: string
  ) => void;
  disabled?: boolean;
  autoSave: boolean;
  isReadOnly: boolean;
  isActionSelected: boolean;
  categoryId: string;
  storageType: ChecklistStorageType;
}

const ReminderUser = (props: IReminderUserProps) => {
  const {
    component,
    disabled,
    isActionSelected,
    categoryId,
    storageType,
    autoSave,
    isReadOnly,
    handleReminderInputChange,
  } = props;

  const accountUsers = useSelector(
    (state: IState) => state.home.mainModel.users
  ).map((i) => ({ value: i.id.toString(), name: i.name } as IDropdownItem));

  const [selectedUser, changeSelectedUser] = useState<IDropdownItem | null>(
    accountUsers.find(
      (item) => item?.value === component.teamMemberIdSelected.toString()
    ) || null
  );

  const [userValidation, setUserValidation] = useState<IValidationResult>(
    validateUser(component.teamMemberIdSelected, isActionSelected)
  );

  const getValidationErrorMessage = () => {
    if (userValidation.hasError) {
      return userValidation.message ?? 'Reminder user is invalid';
    } else {
      return '';
    }
  };

  useChecklistValidation(
    userValidation.hasError && isActionSelected,
    autoSave,
    isActionSelected,
    isReadOnly,
    component.uniqueID + '-user',
    categoryId,
    component.userDefinedId || component.componentType,
    getValidationErrorMessage(),
    storageType
  );

  const handleUserSelectChange = (
    _event: ChangeEvent<unknown>,
    // eslint-disable-next-line
    newValue: any
  ) => {
    const selectedUserId = newValue.value;
    const result = validateUser(selectedUserId, isActionSelected);
    setUserValidation(result);
    changeSelectedUser(newValue);
    handleReminderInputChange(
      selectedUserId.toString(),
      'TeamMemberIdSelected'
    );
  };

  useEffect(() => {
    const resultUser = validateUser(
      Number(selectedUser?.value),
      isActionSelected
    );
    setUserValidation(resultUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionSelected]);

  const dropdownWidth =
    accountUsers &&
    calculateSelectWidth(
      accountUsers?.map((item) => item?.name),
      true
    );
  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledTypography variant="body1">
          {!disabled ? 'To' : 'Team Member:'}
        </StyledTypography>
      </StyledInnerWrapper>
      <StyledAutocompleteWrapper id={`uuid-${component.uniqueID}-user`}>
        {!disabled ? (
          <Autocomplete
            autoComplete
            disableClearable
            disabled={isReadOnly}
            fullWidth={true}
            size={'small'}
            data-testid="reminder-user-autocomplete"
            options={accountUsers}
            style={{
              minWidth: dropdownWidth,
              backgroundColor: 'white',
            }}
            isOptionEqualToValue={(
              option: IDropdownItem,
              value: IDropdownItem
            ) => option.value === value.value}
            renderOption={(params, option) => (
              <li {...params}>{option.name ? option.name : <i>None</i>}</li>
            )}
            onChange={handleUserSelectChange}
            value={
              selectedUser ?? {
                value: '',
                name: '',
              }
            }
            getOptionLabel={(option: IDropdownItem) => option.name}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <StyledTextField
                type="text"
                {...params}
                variant="outlined"
                size="small"
                fullWidth={true}
                error={
                  userValidation.hasError && isActionSelected && !isReadOnly
                }
              />
            )}
          />
        ) : (
          <Typography variant="body2">{selectedUser?.name}</Typography>
        )}
      </StyledAutocompleteWrapper>
    </StyledWrapper>
  );
};

export default ReminderUser;
