import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { Backdrop } from '@mui/material';
import theme from 'theme';

const StyledBackDrop = styled(Backdrop)({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
});
interface IProps {
  active: boolean;
}

const Loader = (props: IProps) => {
  // This is no longer backdrop as regular Backdrop requires Portal to work correctly
  // The easiest way is to just use Modal, alternatively we could use <Portal container={.body.}><StyledBackdrop>...
  return (
    <StyledBackDrop open={props.active}>
      <CircularProgress color="inherit" />
    </StyledBackDrop>
  );
};

export default Loader;
