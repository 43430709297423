import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeDetailsQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeDetailsQuery = {
  __typename?: 'EntitiesQuery';
  getEpisode?: {
    __typename?: 'Episode';
    id: number;
    createdOn?: any | null;
    updatedOn?: any | null;
    closedOn?: any | null;
    faxDateTime?: any | null;
    internalStartDate?: any | null;
    authorizationStartDate?: any | null;
    authorizationExpDate?: any | null;
    requestedEndDate?: any | null;
    approvedUnits?: string | null;
    note?: string | null;
    state?: Types.EpisodeState | null;
    referralDate?: any | null;
    actualDischargeDate?: any | null;
    createdByUser?: { __typename?: 'User'; fullName?: string | null } | null;
    updatedByUser?: { __typename?: 'User'; fullName?: string | null } | null;
    source?: { __typename?: 'LookupValue'; name?: string | null } | null;
    type?: { __typename?: 'EpisodeType'; name?: string | null } | null;
    authorizationStatus?: {
      __typename?: 'LookupValue';
      name?: string | null;
    } | null;
    authorizationCategory?: {
      __typename?: 'LookupValue';
      name?: string | null;
    } | null;
    requestingClinician?: {
      __typename?: 'Provider';
      name?: string | null;
    } | null;
    servicingClinician?: {
      __typename?: 'Provider';
      name?: string | null;
    } | null;
    facility?: { __typename?: 'Provider'; name?: string | null } | null;
    admittingClinician?: {
      __typename?: 'Provider';
      name?: string | null;
    } | null;
    approvedUnitType?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
    procedureCodesAll?: Array<{
      __typename?: 'MedicationCode';
      code?: string | null;
      shortDescription?: string | null;
    } | null> | null;
    diagnosisCodesAll?: Array<{
      __typename?: 'MedicationCode';
      code?: string | null;
      shortDescription?: string | null;
    } | null> | null;
    owner?: { __typename?: 'User'; fullName?: string | null } | null;
    careStage?: { __typename?: 'LookupValue'; name?: string | null } | null;
    program?: { __typename?: 'LookupValue'; name?: string | null } | null;
    entityAttributes?: Array<{
      __typename?: 'EntityAttribute';
      name?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
  getCarePlanProblemsByEpisode: Array<{
    __typename?: 'Problem';
    id: number;
    name?: string | null;
  }>;
};

export const GetEpisodeDetailsDocument = `
    query getEpisodeDetails($episodeId: Int!) {
  getEpisode(episodeId: $episodeId) {
    id
    createdOn
    updatedOn
    closedOn
    createdByUser {
      fullName
    }
    updatedByUser {
      fullName
    }
    source {
      name
    }
    type {
      name
    }
    faxDateTime
    internalStartDate
    authorizationStatus {
      name
    }
    authorizationStartDate
    authorizationExpDate
    requestedEndDate
    authorizationCategory {
      name
    }
    requestingClinician {
      name
    }
    servicingClinician {
      name
    }
    facility {
      name
    }
    admittingClinician {
      name
    }
    approvedUnits
    approvedUnitType {
      id
      name
    }
    note
    state
    procedureCodesAll {
      code
      shortDescription
    }
    diagnosisCodesAll {
      code
      shortDescription
    }
    owner {
      fullName
    }
    careStage {
      name
    }
    program {
      name
    }
    referralDate
    actualDischargeDate
    entityAttributes {
      name
      value
    }
  }
  getCarePlanProblemsByEpisode(episodeId: $episodeId) {
    id
    name
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeDetails: build.query<
      GetEpisodeDetailsQuery,
      GetEpisodeDetailsQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeDetailsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeDetailsQuery, useLazyGetEpisodeDetailsQuery } =
  injectedRtkApi;
