import Icon, { ICONS } from 'components/icon';
import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  mapReminderColor,
  mapStatusToIconColor,
  mapStatusToText,
} from 'util/helpers/iconHelpers';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IReminderLabelProps {
  importanceIdSelected: string;
  disabled?: boolean;
  showExtendedTooltip?: boolean;
}

const StyledWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  border-radius: 4px;
  min-width: 20px;
  height: 31px;
`;

const ReminderLabel = ({
  importanceIdSelected,
  disabled,
  showExtendedTooltip,
}: IReminderLabelProps) => {
  const statusColor = mapReminderColor(importanceIdSelected);
  return disabled ? (
    <Typography
      variant="h5"
      style={{
        color: COLORS.BLACK,
        fontWeight: 700,
      }}
    >
      {`Reminder (${mapStatusToText(importanceIdSelected)}):`}
    </Typography>
  ) : (
    <StyledWrapper
      style={{
        backgroundColor: statusColor?.background,
      }}
    >
      <CustomTooltip
        title={mapStatusToText(importanceIdSelected, showExtendedTooltip)}
      >
        <Box display="flex">
          <Icon
            icon={ICONS.Bell}
            size={15}
            style={{ marginRight: '10px' }}
            color={mapStatusToIconColor(importanceIdSelected)}
          />
          <Typography
            variant="body2"
            style={{
              color: statusColor?.text,
            }}
          >
            Reminder
          </Typography>
        </Box>
      </CustomTooltip>
    </StyledWrapper>
  );
};

export default ReminderLabel;
