import React, { useState } from 'react';
import { Box } from '@mui/material';
import GridHeader, { MedicationStatusAll } from './GridHeader';
import { MedicationsGrid } from './MedicationsGrid';
import {
  medicationsListColumns,
  medicationsListColumnsExtensions,
  medicationsListColumnsExtensionsOpen,
  medicationsListColumnsOpen,
} from './consts';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import {
  updateMedicationsList,
  updateSelectedMedicationItem,
} from 'store/patientdetails/patientDetailsSlice';
import { useData } from 'components/gridFormatters';
import {
  MedicationViewModel,
  PatientMedicationStatusFilter,
} from 'graphql/graphqlTypes';
import {
  GetMedsTabViewModelQuery,
  useLazyGetMedsTabViewModelQuery,
} from 'graphql/hooks/getMedsTabViewModel';

export interface IMedicationsListProps {
  columnOpen: boolean;
}

const MedicationsList = ({ columnOpen }: IMedicationsListProps) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState(MedicationStatusAll);

  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const { data: medicationsData } = useData(
    (state: IState) => state.patientDetails.medicationsTab.medicationsList,
    useLazyGetMedsTabViewModelQuery,
    { patientId },
    (data: GetMedsTabViewModelQuery) =>
      updateMedicationsList({
        medications: data.getMedsTabViewModel
          .medications as MedicationViewModel[],
        medicationsAttributeDef:
          data.getMedsTabViewModel.medicationAttributeTypes,
      }),
    !patientId
  );

  const handleAddMedication = () =>
    dispatch(
      updateSelectedMedicationItem({ selectedActionType: 'form', isNew: true })
    );
  const includeSearchTerm = (field?: string) => {
    return field?.toLowerCase().includes(searchTerm.toLowerCase());
  };
  const activeMedications =
    medicationsData?.filter((item) => !item.deleted) ?? [];
  const filteredData =
    selectedFilter.name === PatientMedicationStatusFilter.All
      ? activeMedications
      : activeMedications.filter(
          (item) => item.patientState === selectedFilter.name
        );
  const filteredMeds = filteredData.filter(
    (item) =>
      includeSearchTerm(item.patientState) ||
      includeSearchTerm(item.medication ?? '') ||
      includeSearchTerm(item.instructions ?? '') ||
      includeSearchTerm(item.patientUse ?? '') ||
      includeSearchTerm(item.note ?? '')
  );
  const isColumnOpen = columnOpen
    ? medicationsListColumnsExtensionsOpen
    : medicationsListColumnsExtensions;

  const columns = columnOpen
    ? medicationsListColumnsOpen
    : medicationsListColumns;

  return (
    <Box>
      <GridHeader
        handleSearchTermChange={setSearchTerm}
        handleFilterChange={setSelectedFilter}
        handleAddMedication={handleAddMedication}
        selectedFilter={selectedFilter}
        columnOpen={columnOpen}
        medications={filteredMeds ?? []}
      />
      <Box>
        <MedicationsGrid
          columnExtensions={isColumnOpen}
          columns={columns}
          medications={filteredData ?? []}
          searchTerm={searchTerm}
          isMultiSelect={false}
        />
      </Box>
    </Box>
  );
};

export default MedicationsList;
