import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { Grid, Typography } from '@mui/material';
import { IconFallBack } from 'components/IconFallBack';
import styled from 'styled-components';
import { TimelineType } from 'components/constants';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { PatientChecklistOrderSetViewModel } from 'graphql/graphqlTypes';

const StyledDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
});
const StyledMiddleTypography = styled(Typography)({
  marginLeft: '4px',
});

const ChecklistDescriptionFormatter = (
  orderSets: PatientChecklistOrderSetViewModel[],
  workflowStepName: string
) => {
  if (workflowStepName) {
    return workflowStepName;
  }

  return orderSets
    .map((os: PatientChecklistOrderSetViewModel) => os.name)
    .filter((name: string) => name !== null)
    .join(', ');
};

const TimelineDescriptionFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  if (props.row.type === TimelineType.Checklist) {
    return (
      <Grid container direction="row">
        <StyledDiv>
          <CustomTooltip
            title={ChecklistDescriptionFormatter(
              props.row.orderSets,
              props.row.workflowStepName
            )}
          >
            <Typography>
              {ChecklistDescriptionFormatter(
                props.row.orderSets,
                props.row.workflowStepName
              )}
            </Typography>
          </CustomTooltip>
          {props.row.containsAttachments ? (
            <Icon icon={ICONS.Attachment} size={24} color={COLORS.RED100} />
          ) : null}
        </StyledDiv>
      </Grid>
    );
  } else if (props.row.type === TimelineType.Reminder) {
    return (
      <Grid container direction="row">
        <StyledDiv>
          <IconFallBack
            name={props.row.reminderDescription}
            image={props.row.descriptionImage}
            type="Reminder"
            color={COLORS.GREY100}
          />
          <CustomTooltip title={props.row.reminderDescription}>
            <StyledMiddleTypography>
              {props.row.reminderDescription}
            </StyledMiddleTypography>
          </CustomTooltip>
        </StyledDiv>
      </Grid>
    );
  } else {
    return (
      <CustomTooltip title={props.row.attachmentDescription}>
        <Typography>{props.row.attachmentDescription}</Typography>
      </CustomTooltip>
    );
  }
};

export default TimelineDescriptionFormatter;
