import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { IconFallBack } from 'components/IconFallBack';
import { COLORS } from 'consts/styles';

export const ReasonCell = (props: ILabelCellProps) => {
  const { value } = props;

  return (
    <Table.Cell {...props} style={CellComponentStyle}>
      <IconFallBack
        name={value.name}
        image={value.image}
        type="Reminder"
        color={COLORS.GREY100}
        iconSize={16}
        showText
      />
    </Table.Cell>
  );
};
