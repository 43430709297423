import React from 'react';
import {
  ComputedValueComponent,
  ComputedValueType,
} from 'graphql/graphqlTypes';
import { IComponentProps } from '../types';
import BasicScoring from './BasicScoring';
import Timer from './Timer';
import ReadOnlyTimer from './ReadOnlyTimer';

export interface IComputedValueProps
  extends IComponentProps<ComputedValueComponent> {
  isReadOnly: boolean;
}

const ComputedValue = (props: IComputedValueProps) => {
  const {
    component,
    storageType,
    isActionSelected,
    handleChecklistInputChange,
    isReadOnly,
  } = props;

  if (component.computedValueType === ComputedValueType.Basicscoring) {
    return (
      <BasicScoring
        component={component}
        storageType={storageType}
        isActionSelected={isActionSelected}
        handleChecklistInputChange={handleChecklistInputChange}
      />
    );
  } else if (component.computedValueType === ComputedValueType.Timer) {
    if (isReadOnly) {
      return <ReadOnlyTimer component={component} />;
    }

    return (
      <Timer
        component={component}
        storageType={storageType}
        isActionSelected={isActionSelected}
        handleChecklistInputChange={handleChecklistInputChange}
      />
    );
  }
  return null;
};

export default ComputedValue;
