import React from 'react';
import { ComputedValueComponent } from 'graphql/graphqlTypes';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from 'components/constants';
import { StyledReviewTypography } from '../styles';

export interface ITimerProps {
  component: ComputedValueComponent;
}
const Timer = (props: ITimerProps) => {
  const { component } = props;

  let addValue = '';
  if (component.targetDate) {
    const targetDate = moment(component.targetDate);
    if (targetDate.isValid()) {
      addValue = ` (${targetDate.format(MOMENT_DATE_FORMAT)})`;
    }
  }
  return (
    <StyledReviewTypography variant="body1">
      {component.value}
      {addValue}
    </StyledReviewTypography>
  );
};

export default Timer;
