import React from 'react';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

export enum stoplightStatus {
  INCOMPLETE = 'INCOMPLETE',
  DONE = 'DONE',
}

interface IStopLightProps {
  status: stoplightStatus | null;
}

const StopLight = (props: IStopLightProps) => {
  const { status } = props;
  const statusIcon =
    status === stoplightStatus.INCOMPLETE
      ? ICONS.Stoplight_incompleted
      : ICONS.Stoplight_completed;

  const iconColor =
    status === stoplightStatus.INCOMPLETE ? COLORS.RED100 : COLORS.GREEN100;

  return (
    <Icon
      testId="stoplight-icon"
      icon={statusIcon}
      size={16}
      color={iconColor}
    />
  );
};

export default StopLight;
