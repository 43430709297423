import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type DeleteChecklistProblemMutationVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
  problemId: Types.Scalars['Int'];
}>;

export type DeleteChecklistProblemMutation = {
  __typename?: 'Mutation';
  deleteChecklistProblem?: boolean | null;
};

export const DeleteChecklistProblemDocument = `
    mutation deleteChecklistProblem($checklistId: Int!, $problemId: Int!) {
  deleteChecklistProblem(checklistId: $checklistId, problemId: $problemId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    deleteChecklistProblem: build.mutation<
      DeleteChecklistProblemMutation,
      DeleteChecklistProblemMutationVariables
    >({
      query: (variables) => ({
        document: DeleteChecklistProblemDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteChecklistProblemMutation } = injectedRtkApi;
