import React from 'react';
import LetterDialogController from 'features/letters';
import ChecklistDialogController from 'components/checklistViewDialog/ChecklistDialogController';
import QuickNoteController from 'components/quickNoteDialog/QuickNoteDialogController';

export const ModalContainer = () => {
  return (
    <>
      <LetterDialogController />
      <ChecklistDialogController />
      <QuickNoteController />
    </>
  );
};
