import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { Episode } from 'graphql/graphqlTypes';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import Loader from 'components/loader';
import EpisodeSearchDialogBody from './EpisodeSearchDialogBody';
import { isUndefined } from 'lodash';

export interface EpisodeSearchDialogProps {
  isLoading: boolean;
  open: boolean;
  episodes: Episode[];
  totalCount: number;
  patientId: number;
  onSearch: (term: string) => void;
  onCantFind: () => void;
  onSelect: (selectedEpisode: Episode) => void;
  onClose: () => void;
}

const StyledButton = styled(Button)({
  minWidth: '150px',
  height: '40px',
  '&:hover': {
    minWidth: '150px',
    height: '40px',
  },
});

const StyledLink = styled('a')({
  cursor: 'pointer',
  fontWeight: 500,
  letterSpacing: '0.5px',
  color: COLORS.SYMPHONY_BLUE,
});

const EpisodeSearchDialog = (props: EpisodeSearchDialogProps) => {
  const {
    isLoading,
    open,
    episodes,
    onClose,
    onSelect,
    onCantFind,
    onSearch,
    totalCount,
    patientId,
  } = props;
  const [selected, setSelected] = useState<Episode>({} as Episode);
  const handleClose = () => {
    setSelected({} as Episode);
    onClose();
  };

  const handleSelect = () => {
    if (selected) {
      onSelect(selected);
      handleClose();
    }
  };

  const handleCantFind = () => {
    onCantFind();
    handleClose();
  };

  return (
    <>
      <Dialog
        data-testid="episode-search-dialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            style={{
              fontSize: '16px',
              color: COLORS.WHITE,
              paddingLeft: '12px',
            }}
          >
            Advanced Episode Search
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            height: '578px',
            overflowX: 'auto',
            padding: '24px 16px',
          }}
          dividers
        >
          <EpisodeSearchDialogBody
            isFetching={isLoading}
            episodes={episodes}
            onSelect={setSelected}
            setSearchTerm={onSearch}
            totalCount={totalCount}
            patientId={patientId}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1 }}>
            <StyledLink onMouseDown={handleCantFind}>
              I can&apos;t find Episode
            </StyledLink>
          </div>
          <div>
            <StyledButton
              data-testid="episode-search-dialog-cancel-button"
              onClick={handleClose}
              color="primary"
              variant="outlined"
              data-cy="cancel"
              style={{ marginRight: '20px' }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              onClick={handleSelect}
              disabled={isUndefined(selected)}
              color="primary"
              variant="contained"
              data-cy="select"
            >
              Select
            </StyledButton>
          </div>
        </DialogActions>
      </Dialog>
      <Loader active={isLoading} />
    </>
  );
};

export default EpisodeSearchDialog;
