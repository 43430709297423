import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMainModelQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMainModelQuery = {
  __typename?: 'EntitiesQuery';
  getAccountUsers?: Array<{
    __typename?: 'User';
    id: number;
    name?: string | null;
    canBeMemberOwner: boolean;
  } | null> | null;
  getAttributeLookups?: Array<{
    __typename?: 'Lookup';
    name?: string | null;
    values?: Array<{
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
      image?: string | null;
      isDeleted?: boolean | null;
    } | null> | null;
  } | null> | null;
  getEnabledPopulations?: Array<
    | { __typename?: 'CareSite'; id: number; name?: string | null }
    | { __typename?: 'EpisodeType'; id: number; name?: string | null }
    | { __typename?: 'Lookup'; id: number; name?: string | null }
    | { __typename?: 'LookupValue'; id: number; name?: string | null }
    | { __typename?: 'Pharmacy'; id: number; name?: string | null }
    | { __typename?: 'Problem'; id: number; name?: string | null }
    | { __typename?: 'Provider'; id: number; name?: string | null }
    | { __typename?: 'Role'; id: number; name?: string | null }
    | { __typename?: 'Scenario'; id: number; name?: string | null }
    | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
    | { __typename?: 'Tag'; id: number; name?: string | null }
    | { __typename?: 'User'; id: number; name?: string | null }
    | null
  > | null;
  getSystemValues?: {
    __typename?: 'GetSystemValuesConnection';
    nodes?: Array<{
      __typename?: 'SystemValue';
      name?: string | null;
      value?: string | null;
      type?: Types.SystemValueType | null;
    } | null> | null;
  } | null;
};

export const GetMainModelDocument = `
    query getMainModel {
  getAccountUsers {
    id
    name
    canBeMemberOwner
  }
  getAttributeLookups {
    name
    values {
      id
      name
      image
      isDeleted
    }
  }
  getEnabledPopulations {
    id
    name
  }
  getSystemValues {
    nodes {
      name
      value
      type
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMainModel: build.query<
      GetMainModelQuery,
      GetMainModelQueryVariables | void
    >({
      query: (variables) => ({ document: GetMainModelDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetMainModelQuery, useLazyGetMainModelQuery } =
  injectedRtkApi;
