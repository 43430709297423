import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Confirmation from 'components/modal/Confirmation';
import { updateDirtyTabs } from 'store/patientdetails/patientDetailsSlice';
import { commitAttachments } from 'store/patientdetails/patientDetails.attachment.slice';
import StyledLink from './StyledLink';
import { IAttachmentLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IAttachmentDeleteLinkProps extends IAttachmentLinkProps {
  patientId: number;
  episodeId?: number;
  isDisabled: boolean;
}

const AttachmentDeleteLink = (props: IAttachmentDeleteLinkProps) => {
  const dispatch = useDispatch();

  const [
    showAttachmentDeleteConfirmation,
    setShowAttachmentDeleteConfirmation,
  ] = useState(false);

  return (
    <>
      <CustomTooltip title={props.title ?? 'Delete'}>
        <StyledLink
          onClick={() => setShowAttachmentDeleteConfirmation(true)}
          data-cy="Delete"
          $isDisabled={props.isDisabled}
        >
          Delete
        </StyledLink>
      </CustomTooltip>
      <Confirmation
        open={showAttachmentDeleteConfirmation}
        title="Delete Attachment?"
        okButtonText="Delete"
        okEvent={() => {
          setShowAttachmentDeleteConfirmation(false);
          dispatch(
            updateDirtyTabs(props.episodeId ? 'Episodes' : 'Attachments')
          );

          dispatch(
            commitAttachments({
              patientId: props.patientId,
              episodeId: props.episodeId,
              changeSet: { deleted: [props.attachmentId] },
            })
          );
        }}
        cancelEvent={() => setShowAttachmentDeleteConfirmation(false)}
      >
        <span>Are you sure you want to delete this attachment?</span>
      </Confirmation>
    </>
  );
};

export default AttachmentDeleteLink;
