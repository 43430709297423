export enum teamMembersColumn {
  member = 'member',
  isOwner = 'isOwner',
  role = 'role',
  note = 'note',
  reminders = 'reminders',
  updatedOn = 'updatedOn',
}

export const editingStateTeamMembersColumnsExtension = [
  { columnName: teamMembersColumn.member, width: '18%' },
  { columnName: teamMembersColumn.isOwner, width: '6%' },
  { columnName: teamMembersColumn.role, width: '18%' },
  { columnName: teamMembersColumn.note },
  { columnName: teamMembersColumn.reminders, width: '16%' },
  { columnName: teamMembersColumn.updatedOn, width: '8%' },
];

export const teamMembersColumns = () => {
  return [
    {
      name: teamMembersColumn.member,
      title: 'Team Member',
    },
    { name: teamMembersColumn.isOwner, title: 'Owner' },
    { name: teamMembersColumn.role, title: 'Role' },
    { name: teamMembersColumn.note, title: 'Note' },
    { name: teamMembersColumn.reminders, title: 'Reminders' },
    { name: teamMembersColumn.updatedOn, title: 'Last Update' },
  ];
};
