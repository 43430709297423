import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import TimelineActionsFormatter from '../formatters/TimelineActionsFormatter';

const TimelineActionsProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TimelineActionsFormatter} {...props} />
);

export default TimelineActionsProvider;
