import React from 'react';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { GetPatientCardInfoQuery } from 'graphql/hooks/getPatientCardInfo';
import { Tooltip, Typography } from '@mui/material';

export interface IPatientIpOpProps {
  OutInPatientAuthorizations: GetPatientCardInfoQuery['getPatientOpIpAuthorizations'];
  variant: 'small' | 'large';
}

const selectBorder = (props: { variant?: string }) => {
  switch (props.variant) {
    case 'small':
      return '1px dashed ' + COLORS.GREY25;
    case 'large':
    default:
      return '1px solid ' + COLORS.GREY10;
  }
};

const StyledContainer = styled.div<{ variant?: string }>`
  padding: ${(props) => {
    switch (props.variant) {
      case 'small':
        return '0 0 0 0';
      case 'large':
      default:
        return '12px 24px 12px 24px';
    }
  }};
  margin: ${(props) => {
    switch (props.variant) {
      case 'small':
        return '16px 28px 16px 28px;';
      case 'large':
      default:
        return '0';
    }
  }};
  font-size: 0.89em;
  overflow: auto;
  border-top: ${selectBorder};
  border-bottom: ${selectBorder};
`;

const InOutContainer = styled.div<{ variant?: string }>`
  ${(props) => {
    switch (props.variant) {
      case 'small':
        return 'display: block;';
      case 'large':
      default:
        return (
          'display: table;' +
          'table-layout: auto;' +
          'width: auto;' +
          'min-width: 100%;'
        );
    }
  }}
`;

const InOutEntry = styled.div<{ variant?: string }>`
  ${(props) => {
    switch (props.variant) {
      case 'small':
        return 'display: block;margin: 12px 0 12px 0';
      case 'large':
      default:
        return 'display: table-row;';
    }
  }}
`;

const DateCell = styled.div<{ variant?: string }>`
  ${(props) => {
    switch (props.variant) {
      case 'small':
        return 'display: block;padding-left:12px;';
      case 'large':
      default:
        return (
          'display: table-cell;' +
          'padding-right: 5px;' +
          'white-space: nowrap;' +
          'width: 160px;'
        );
    }
  }}
`;

const DiagnosisCodeCell = styled.div<{ variant?: string }>`
  ${(props) => {
    switch (props.variant) {
      case 'small':
        return 'display: block;padding-left:12px;';
      case 'large':
      default:
        return (
          'display: table-cell;' +
          'padding-right: 10px;' +
          'white-space: nowrap;'
        );
    }
  }}
`;

const StatusCell = styled.div<{ variant?: string }>`
  ${(props) => {
    switch (props.variant) {
      case 'small':
        return 'display: block;padding-left:12px;';
      case 'large':
      default:
        return 'display: table-cell;' + 'min-width: 180px;';
    }
  }}
`;

const OutInPatientLabel = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-right: 4px;
`;

const PatientOutpatientInpatient = (props: IPatientIpOpProps) => {
  const { OutInPatientAuthorizations, variant } = props;

  const inPatient =
    OutInPatientAuthorizations?.inpatientDate != null &&
    OutInPatientAuthorizations?.inpatientDiagnosisCode != null &&
    OutInPatientAuthorizations?.inpatientStatus != null;

  const outPatient =
    OutInPatientAuthorizations?.outpatientDate != null &&
    OutInPatientAuthorizations?.outpatientDiagnosisCode != null &&
    OutInPatientAuthorizations?.outpatientStatus != null;

  const outPatientDiagnosis =
    OutInPatientAuthorizations?.outpatientDiagnosisDescription
      ? OutInPatientAuthorizations?.outpatientDiagnosisDescription
      : '';

  const inPatientDiagnosis =
    OutInPatientAuthorizations?.inpatientDiagnosisDescription
      ? OutInPatientAuthorizations?.inpatientDiagnosisDescription
      : '';

  const showOPTitle = variant == 'small';
  const showINTitle = variant == 'small';

  const labels = {
    OPTitle: 'Last Outpatient',
    OPDateLabel: 'Last OP Date:',
    OPDxLabel: 'Dx:',
    OPStatusLabel: 'Status:',
    INTitle: 'Last Inpatient',
    INDateLabel: 'Last IP Admit:',
    INDxLabel: 'Dx:',
    INStatusLabel: 'Status:',
  };

  if (inPatient || outPatient) {
    return (
      <StyledContainer className="inPatientOutPatient" variant={variant}>
        <InOutContainer variant={variant}>
          {outPatient && (
            <InOutEntry variant={variant}>
              {showOPTitle && (
                <Typography fontSize={'1em'}>{labels.OPTitle}</Typography>
              )}
              <DateCell variant={variant}>
                <OutInPatientLabel>{labels.OPDateLabel}</OutInPatientLabel>
                {formatDate(OutInPatientAuthorizations.outpatientDate)}
              </DateCell>
              <DiagnosisCodeCell variant={variant}>
                <OutInPatientLabel>{labels.OPDxLabel}</OutInPatientLabel>
                <Tooltip title={outPatientDiagnosis}>
                  <Typography display={'inline'}>
                    {OutInPatientAuthorizations.outpatientDiagnosisCode}
                  </Typography>
                </Tooltip>
              </DiagnosisCodeCell>
              <StatusCell variant={variant}>
                <OutInPatientLabel>{labels.OPStatusLabel}</OutInPatientLabel>
                {OutInPatientAuthorizations.outpatientStatus}
              </StatusCell>
            </InOutEntry>
          )}
          {inPatient && (
            <InOutEntry variant={variant}>
              {showINTitle && (
                <Typography fontSize={'1em'}>{labels.INTitle}</Typography>
              )}
              <DateCell variant={variant}>
                <OutInPatientLabel>{labels.INDateLabel}</OutInPatientLabel>
                {formatDate(OutInPatientAuthorizations.inpatientDate)}
              </DateCell>
              <DiagnosisCodeCell variant={variant}>
                <OutInPatientLabel>{labels.INDxLabel}</OutInPatientLabel>
                <Tooltip title={inPatientDiagnosis}>
                  <Typography display={'inline'}>
                    {OutInPatientAuthorizations.inpatientDiagnosisCode}
                  </Typography>
                </Tooltip>
              </DiagnosisCodeCell>
              <StatusCell variant={variant}>
                <OutInPatientLabel>{labels.INStatusLabel}</OutInPatientLabel>
                {OutInPatientAuthorizations.inpatientStatus}
              </StatusCell>
            </InOutEntry>
          )}
        </InOutContainer>
      </StyledContainer>
    );
  }
  return null;
};

export default PatientOutpatientInpatient;
