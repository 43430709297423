import { ChangeSet } from '@devexpress/dx-react-grid';
import {
  BaseReminder,
  ReminderDtoInput,
  ReminderImportance,
  ReminderStateDto,
} from 'graphql/graphqlTypes';

interface RowWithId {
  id: number | string;
}

export const getChangedRows = <T extends RowWithId>(
  changeSet: ChangeSet,
  rows?: T[] | null
): { changedRows: T[]; updatedRows: T[] } => {
  rows = rows ?? [];
  let changedRows: T[] = [];
  const updatedRows: T[] = [];
  const { added, changed } = changeSet;
  if (added) {
    changedRows = [...rows, ...added];
    updatedRows.push(...added);
  }
  if (changed) {
    changedRows = rows.map((row) => {
      if (changed[row.id]) {
        const updatedRow = { ...row, ...changed[row.id] };
        updatedRows.push(updatedRow);
        return updatedRow;
      } else {
        return row;
      }
    });
  }

  return { changedRows, updatedRows };
};

export const mapBaseReminderToInputDto = (
  row: BaseReminder
): ReminderDtoInput => {
  return {
    date: row.date,
    importanceId: getImportanceId(row),
    note: row.note,
    reasonId: row.reason!.id,
    reminderId: row.id,
    state: getState(row),
    userId: row.owner!.id,
  };
};

const getImportanceId = (row: BaseReminder) => {
  switch (row.importance) {
    case ReminderImportance.Critical:
      return 1;
    case ReminderImportance.High:
      return 2;
    case ReminderImportance.Low:
      return 3;
    default:
      return row.importanceId!;
  }
};

const getState = (row: BaseReminder) => {
  if (row.isDeleted) {
    return ReminderStateDto.Deleted;
  }
  if (row.isCompleted) {
    return ReminderStateDto.Completed;
  }
  if (!row.isExpired) {
    return ReminderStateDto.Opened;
  }
  return undefined;
};

export const resetIsJustAdded = (
  changeSet: ChangeSet,
  reminders: BaseReminder[]
) => {
  const changedId = Number(Object.keys(changeSet.changed!)[0]);
  const oldRow = reminders.find((x) => x.id === changedId);
  if (oldRow && 'isJustAdded' in oldRow && oldRow.isJustAdded === true) {
    const newRow = changeSet.changed![changedId];
    if (!newRow) {
      changeSet.changed![changedId] = {};
    }
    changeSet.changed![changedId].isJustAdded = false;
  }
};

export const getJustAddedReminderId = (
  editingRowIds: Array<number> | undefined,
  reminders: BaseReminder[]
) => {
  const [editId] = editingRowIds ?? [];
  const edit = reminders.find((x) => x.id === editId);
  const shouldDeleteNewReminder =
    edit && 'isJustAdded' in edit && edit.isJustAdded === true;
  return shouldDeleteNewReminder ? editId : undefined;
};
