import { IValidationResult } from 'util/validationUtils';

export const getDateTimeErrorMessage = (
  dateError: boolean,
  timeError: boolean,
  fullValueError: boolean,
  attributeComparisonError: IValidationResult
): string => {
  let errorMessage = getErrorMessage(dateError, timeError, fullValueError);

  if (attributeComparisonError.hasError && attributeComparisonError.message) {
    errorMessage = attributeComparisonError.message;
  }

  return errorMessage;
};

const getErrorMessage = (
  dateError: boolean,
  timeError: boolean,
  fullValueError: boolean
) => {
  if ((dateError && timeError) || fullValueError) {
    return 'Fill in a valid date and time';
  } else if (dateError) {
    return 'Fill in a valid date';
  } else if (timeError) {
    return 'Fill in a valid time';
  }
  return 'Fill in required date and time';
};
