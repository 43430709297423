import { useEffect, useState } from 'react';
import { IActionButtonProps } from './ActionButton';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { usePrevious } from 'hooks';

// SYM-18294 This is an empty component to make sure that linked "same as ..."
// component is updated in server if ProviderSearchActionButton is updated
export const ProviderSameAsSourceActionButton = (
  props: IActionButtonProps
): JSX.Element | null => {
  const { component, handleChecklistInputChange, storageType, isReadOnly } =
    props;

  const componentValue = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistComponents[
        component.uniqueID
      ]?.component?.value
  );

  const [currentValue, setCurrentValue] = useState<string | null | undefined>(
    componentValue
  );
  const previousValue = usePrevious(currentValue);
  useEffect(() => {
    if (isReadOnly) {
      return;
    }
    if (previousValue === componentValue) {
      return;
    }
    if (!previousValue && !componentValue) {
      return;
    }

    if (componentValue !== undefined) {
      setCurrentValue(componentValue);
      handleChecklistInputChange(componentValue ?? '', component);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentValue]);

  // This is deliberate
  return null;
};
