import React from 'react';
import { ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';

import {
  EntityAttributeComponent,
  LookupAttributeType,
} from 'graphql/graphqlTypes';
import { StyledReviewTypography } from '..//styles';

export interface ILookupAttributeProps {
  component: EntityAttributeComponent;
  lookups: ILookup[];
  attributeType: LookupAttributeType;
}

const LookupAttribute = (props: ILookupAttributeProps) => {
  const { component, lookups, attributeType } = props;

  const lookup = lookups.find(
    (lookupItem: ILookup) => lookupItem.name === attributeType.lookup?.name
  );
  const lookupValues: ILookupValue[] = [];
  const lookupId = component.value;
  if (lookupId) {
    let ids = [];
    if (attributeType.multiValues) {
      ids = lookupId.split(',');
    } else {
      ids.push(lookupId);
    }
    ids.forEach((id: string) => {
      const lookupValue = lookup?.values.find(
        (lv: ILookupValue) => lv.id.toString() === id
      );
      if (lookupValue) {
        lookupValues.push(lookupValue);
      }
    });
  }

  if (lookupValues && lookupValues.length > 0) {
    const names = lookupValues.map((lv: ILookupValue) => lv.name);
    return (
      <StyledReviewTypography
        variant="body1"
        $color={component.color ?? undefined}
      >
        {names.join(', ')}
      </StyledReviewTypography>
    );
  } else {
    return null;
  }
};
export default LookupAttribute;
