import { HealthServiceCodesActionValue } from 'graphql/graphqlTypes';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export const ServiceRowReview = (props: {
  type: 'Requested' | 'Approved';
  item: HealthServiceCodesActionValue;
  hideDates?: boolean;
  hideValues?: boolean;
}) => {
  const { item, type, hideDates, hideValues } = props;

  const getStartDate = () =>
    type === 'Requested'
      ? item.healthServiceActionValue?.requestedStartDate
      : item.healthServiceActionValue?.approvedStartDate;

  const getEndDate = () =>
    type === 'Requested'
      ? item.healthServiceActionValue?.requestedEndDate
      : item.healthServiceActionValue?.approvedEndDate;
  const getUnitType = () =>
    type === 'Requested'
      ? item.healthServiceActionValue?.requestedUnitType
      : item.healthServiceActionValue?.approvedUnitType;

  const getValue = () =>
    type === 'Requested'
      ? item.healthServiceActionValue?.requestedUnits
      : item.healthServiceActionValue?.approvedUnits;

  return (
    <Box my="20px">
      <Box display="flex" style={{ minHeight: '40px' }}>
        <Box display="flex" alignItems="end" width="10%" minWidth="80px">
          <Typography variant="body2"> {type}</Typography>
        </Box>
        <Box width="25%" pr="20px">
          {hideDates ? null : (
            <>
              <Typography variant="body1">Start Date&nbsp;</Typography>
              <Typography variant="body2">
                {formatDate(getStartDate())}
              </Typography>
            </>
          )}
        </Box>
        <Box width="25%" pr="20px">
          {hideDates ? null : (
            <>
              <Typography variant="body1">End Date&nbsp;</Typography>
              <Typography variant="body2">
                {formatDate(getEndDate())}
              </Typography>
            </>
          )}
        </Box>
        <Box width="15%">
          {hideValues ? null : (
            <>
              <Typography variant="body1">Unit Type&nbsp;</Typography>
              <Typography variant="body2">{getUnitType()}</Typography>
            </>
          )}
        </Box>
        <Box width="15%">
          {hideValues ? null : (
            <>
              <Typography variant="body1">Value&nbsp;</Typography>
              <Typography variant="body2">{getValue()}</Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
