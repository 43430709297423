import React from 'react';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@mui/material';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export const DateCell = (props: ILabelCellProps) => (
  <Table.Cell
    {...props}
    onClick={undefined}
    style={CellComponentStyle}
    data-cy="table-cell-type"
  >
    <Typography>{props.value ? formatDate(props.value) : ''}</Typography>
  </Table.Cell>
);
