import React from 'react';
import { Box, Button, ButtonGroup, Divider, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { DataSource, IAdditionalFilters } from '../types';
import FilterDialog from './FilterDialog';
import styled from 'styled-components';

interface IFilterBarProps {
  source: DataSource;
  onChangeSource: (value: DataSource) => void;
  filters: IAdditionalFilters;
  onUpdateFilters: (filterValues: IAdditionalFilters) => void;
}

const StyledDivider = styled(Divider)`
  margin-left: 20px;
  margin-right: 20px;
  height: 24px;
  align-self: center;
`;

const StyledWrapper = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
`;

const StyledButtonGroupItem = styled(Button)<{ $activeFilter: boolean }>(
  ({ $activeFilter }) => ({
    padding: '5px 26px',
    height: '32px',
    border: $activeFilter
      ? `1px solid ${COLORS.SYMPHONY_BLUE}`
      : `1px solid ${COLORS.LIGHT_GREY}`,
    borderRightColor: $activeFilter
      ? `${COLORS.SYMPHONY_BLUE}!important`
      : COLORS.LIGHT_GREY,
    borderColor: $activeFilter ? COLORS.SYMPHONY_BLUE : COLORS.LIGHT_GREY,
    minWidth: '87px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    backgroundColor: $activeFilter ? '#c5e3ff' : 'white',
    color: $activeFilter ? COLORS.SYMPHONY_BLUE : COLORS.GREY100,
  })
);

const FilterBar = (props: IFilterBarProps) => {
  const { source, onChangeSource, filters, onUpdateFilters } = props;
  return (
    <Box
      display="flex"
      flexDirection={'row'}
      alignContent={'center'}
      justifyContent="flex-end"
      alignItems="center"
      data-cy="filters"
    >
      <StyledWrapper>
        <div style={{ margin: '5px 5px' }}>
          <Typography>Show: </Typography>
        </div>
      </StyledWrapper>
      <ButtonGroup aria-label="outlined primary button group">
        <StyledButtonGroupItem
          variant="outlined"
          size="small"
          $activeFilter={source === DataSource.All}
          onClick={() => onChangeSource(DataSource.All)}
          data-cy="all-providers"
        >
          All
        </StyledButtonGroupItem>
        <StyledButtonGroupItem
          variant="outlined"
          size="small"
          onClick={() => onChangeSource(DataSource.Favorites)}
          $activeFilter={source === DataSource.Favorites}
          data-cy="favorite-providers"
        >
          Favorite
        </StyledButtonGroupItem>
        <StyledButtonGroupItem
          variant="outlined"
          size="small"
          onClick={() => onChangeSource(DataSource.Recent)}
          $activeFilter={source === DataSource.Recent}
          data-cy="recent-providers"
        >
          Recent
        </StyledButtonGroupItem>
      </ButtonGroup>
      <StyledDivider orientation="vertical" flexItem />

      <FilterDialog filters={filters} onChangeFilter={onUpdateFilters} />
    </Box>
  );
};

export default FilterBar;
