import { Dispatch } from 'redux';
import axiosGitHubGraphQL from 'util/axiosHttp';
import { ILookupValue } from 'backend/types/lookupValue';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';

export const suggestEntities =
  (entity: string, query: string, callback: (arg: ILookupValue[]) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    axiosGitHubGraphQL
      .post('graphql?suggestEntities', {
        query: ` query suggestEntities($entity: String!, $term: String!){
          suggestEntities(entity: $entity, term: $term) {
            id
            name
          }
        }`,
        variables: {
          entity: entity,
          term: query,
        },
      })
      .then((response) => {
        if (response.data.errors) {
          dispatch(
            showErrorPopup({
              message: response.data.errors
                .map((x: Error) => x.message)
                .join('; '),
            })
          );
          return;
        }
        callback(response.data.data.suggestEntities as ILookupValue[]);
      })
      .catch(() =>
        dispatch(
          showErrorPopup({
            message: 'Cannot Get Patient: Internal Server Error',
          })
        )
      );
  };
