import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetProblemsListQueryVariables = Types.Exact<{
  excludeIds:
    | Array<Types.InputMaybe<Types.Scalars['Int']>>
    | Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetProblemsListQuery = {
  __typename?: 'EntitiesQuery';
  getProblemsList: Array<{
    __typename?: 'Problem';
    id: number;
    name?: string | null;
  } | null>;
};

export const GetProblemsListDocument = `
    query getProblemsList($excludeIds: [Int]!) {
  getProblemsList(excludeIds: $excludeIds) {
    id
    name
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProblemsList: build.query<
      GetProblemsListQuery,
      GetProblemsListQueryVariables
    >({
      query: (variables) => ({ document: GetProblemsListDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProblemsListQuery, useLazyGetProblemsListQuery } =
  injectedRtkApi;
