import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SearchProvidersQueryVariables = Types.Exact<{
  request: Types.ProviderSearchRequestInput;
}>;

export type SearchProvidersQuery = {
  __typename?: 'EntitiesQuery';
  searchProvider?: {
    __typename?: 'ProviderSearchResult';
    count?: number | null;
    rows?: Array<{
      __typename?: 'ProviderSearchResultRow';
      id?: string | null;
      providerAddressUiId?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      addressId?: number | null;
      par?: Types.FoundProviderStatus | null;
      parNotes?: string | null;
      address?: string | null;
      practiceName?: string | null;
      seamless?: boolean | null;
      nPI?: string | null;
      providerId?: string | null;
      taxId?: string | null;
      providerType?: {
        __typename?: 'LookupValue';
        name?: string | null;
      } | null;
      type?: { __typename?: 'LookupValue'; name?: string | null } | null;
      dPG?: { __typename?: 'LookupValue'; name?: string | null } | null;
    } | null> | null;
  } | null;
};

export const SearchProvidersDocument = `
    query searchProviders($request: ProviderSearchRequestInput!) {
  searchProvider(request: $request) {
    count
    rows {
      id
      providerAddressUiId
      firstName
      lastName
      addressId
      par
      parNotes
      address
      practiceName
      seamless
      nPI
      providerId
      providerType {
        name
      }
      type {
        name
      }
      dPG {
        name
      }
      taxId
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchProviders: build.query<
      SearchProvidersQuery,
      SearchProvidersQueryVariables
    >({
      query: (variables) => ({ document: SearchProvidersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSearchProvidersQuery, useLazySearchProvidersQuery } =
  injectedRtkApi;
