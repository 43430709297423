import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'store';
import {
  ChecklistStorageType,
  ISetStoplightValidationBody,
} from 'store/actions/types';
import { filter, isEmpty } from 'lodash';

export const selectChecklistValidationErrors = (
  storageType: ChecklistStorageType
) =>
  createSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistErrors,
    (errors) => filter(errors, (error) => !error.isValid)
  );
export const selectChecklistValidationErrorsAll = (
  storageType: ChecklistStorageType
) =>
  createSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistErrors,
    (errors) => filter(errors, (error) => !isEmpty(error.error))
  );

export const mapStoplights = (stoplights: ISetStoplightValidationBody[]) =>
  stoplights
    ?.filter((x) => x.itemsWithStoplights.some((y) => y.isVisible))
    ?.filter(
      (item) =>
        !item.itemsWithStoplights.some(
          (element) => element.isVisible && element.isCompleted
        )
    );

export const stoplightsSelector = (storageType: ChecklistStorageType) =>
  createSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].stoplightsRemaining,
    mapStoplights
  );

export const selectCarePlanValidationErrors = createSelector(
  (state: IState) =>
    state.checklist.documentsState[ChecklistStorageType.CAREPLAN]
      ?.checklistErrors,
  (errors) => filter(errors, (error) => !error.isValid)
);

export const carePlanStoplightsSelector = createSelector(
  (state: IState) =>
    state.checklist.documentsState[ChecklistStorageType.CAREPLAN]
      .stoplightsRemaining,
  mapStoplights
);
