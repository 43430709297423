import React, { useState } from 'react';
import {
  AttachmentDtoInput,
  UpdateAttachmentDtoInput,
  UpdateAttachmentsModelInput,
} from 'graphql/graphqlTypes';
import UploadFilesDialog from 'components/uploadFiles';
import { closeUploadFile } from 'store/fileUpload/fileUploadSlice';
import { useUpdateAttachmentsMutation } from 'graphql/hooks/updateAttachments';
import { IUploadedFile } from 'store/fileUpload/types';
import { IMessageState } from 'features/letters';
import Snackbar from 'components/snackbar';
import { ICONS } from 'components/icon';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';

const UploadFilesLayout = ({
  checklistStorage,
}: {
  checklistStorage: ChecklistStorageType;
}) => {
  const [message, setMessage] = useState<IMessageState | null>();

  const showUploadFilesDialog = useSelector(
    (state: IState) =>
      state.fileUpload.open && state.fileUpload.fileUploadFromActionButton
  );

  const uploadedFiles = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  );

  const [updateAttachments] = useUpdateAttachmentsMutation();

  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[checklistStorage].checklist
  );

  const dispatch = useDispatch();

  const handleCloseUploadFilesDialog = () => {
    dispatch(closeUploadFile());
  };

  const attachment = (uploadedFile: IUploadedFile): AttachmentDtoInput => {
    return {
      attachmentId: 0,
      attachmentIsDeleted: false,
      attachmentName: String(uploadedFile.name),
      attachmentPathTemp: uploadedFile.fileId,
      attachmentCategoryId: uploadedFile.categoryId,
    };
  };

  const episodeAttachments = (): UpdateAttachmentDtoInput[] => {
    const attachments = uploadedFiles.map(attachment);
    return [
      {
        attachments,
        episodeId: checklist?.episodeId as number,
      },
    ];
  };

  const handleAddFilesDialog = () => {
    updateAttachments({
      attachments: {
        attachments: [],
        episodeAttachments: episodeAttachments(),
        patientId: 0,
      } as UpdateAttachmentsModelInput,
    }).then((result) => {
      if ('data' in result && result.data?.updateAttachments) {
        setMessage({
          active: true,
          type: 'success',
          title: 'Success',
          text: `File(s) attached successfully`,
        });
      }
    });
    dispatch(closeUploadFile());
  };

  return (
    <>
      {showUploadFilesDialog && (
        <UploadFilesDialog
          open={showUploadFilesDialog}
          onClose={handleCloseUploadFilesDialog}
          onAddFiles={handleAddFilesDialog}
        />
      )}
      {message && (
        <Snackbar
          icon={message.type === 'success' ? ICONS.Checkmark : ICONS.Warning}
          {...message}
          open={message?.active}
          onClose={() => setMessage({ ...message, active: false })}
          duration={message.type === 'success' ? 4000 : 'infinite'}
        />
      )}
    </>
  );
};

export default UploadFilesLayout;
