import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FailedRequestItem {
  name: string;
  idToRetry: string;
  data: unknown;
  message: string | null | undefined;
}

export interface IFailedRequestState {
  requests: Array<FailedRequestItem>;
}

export const initialFailedRequestState: IFailedRequestState = {
  requests: [],
};

const failedRequestSlice = createSlice({
  name: 'failedRequest',
  initialState: initialFailedRequestState,
  reducers: {
    addFailedRequest(
      state: IFailedRequestState,
      action: PayloadAction<{
        name: string;
        idToRetry: string;
        message?: string | null;
        variables: unknown;
      }>
    ) {
      const newReqests = state.requests.filter(
        (item: FailedRequestItem) => item.idToRetry != action.payload.idToRetry
      );
      newReqests.push({
        name: action.payload.name,
        idToRetry: action.payload.idToRetry,
        data: action.payload.variables,
        message: action.payload.message,
      });
      state.requests = newReqests;
    },
    removeFailedRequest(
      state: IFailedRequestState,
      action: PayloadAction<string>
    ) {
      const idToRetry = action.payload;
      const elementToRemove = state.requests.find(
        (item: FailedRequestItem) => item.idToRetry === idToRetry
      );

      if (!elementToRemove) {
        return;
      }

      const index = state.requests.indexOf(elementToRemove);
      if (index > -1) {
        state.requests.splice(index, 1);
      }
    },
    clearFailedRequests(state: IFailedRequestState) {
      state.requests = [];
    },
  },
});

export const { addFailedRequest, removeFailedRequest, clearFailedRequests } =
  failedRequestSlice.actions;

export default failedRequestSlice.reducer;
