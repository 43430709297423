import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledTableContainer = styled(TableContainer)({
  minHeight: '100px',
  maxHeight: '476px',
  borderRadius: '4px',
  border: `solid 1px ${COLORS.LIGHT_GREY}`,
  backgroundColor: COLORS.WHITE,
  marginBottom: '15px',
});

const StyledTable = styled(Table)`
  & tbody tr:nth-of-type(even) {
    background-color: ${COLORS.GREY4};
  }
`;

const StyledTableCell = styled(TableCell)({
  textAlign: 'left',
  border: 'none',
  padding: '17px 0 17px 17px',
});

const StyledTableHead = styled(TableHead)({
  maxWidth: 'inherit',
});

const StyledTableRow = styled(TableRow)({
  maxWidth: 'inherit',
  backgroundColor: COLORS.GREY4,
});

const StyledBodyTableRow = styled(TableRow)({
  '&.MuiTableRow-root.Mui-selected': {
    backgroundColor: COLORS.BLUE20,
  },
});

const StyledBodyTableCell = styled(TableCell)({
  border: 'none',
  padding: '17px',
  cursor: 'pointer',
});

const StyledText = styled(Typography)({
  display: 'inline',
  verticalAlign: 'middle',
});

const HeaderText = styled(StyledText)({
  fontWeight: 500,
});

interface IProviderSelectListProps {
  selectedProviderId?: number | null;
  providersList?: IProvider[];
  onProviderSelected: (providerId: number) => void;
}

interface IProvider {
  id: number;
  name: string;
  providerId?: string | null;
  npi?: string | null;
}

const ProviderSelectList = (props: IProviderSelectListProps) => {
  const { selectedProviderId, providersList, onProviderSelected } = props;
  return (
    <>
      <StyledTableContainer>
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell>
                <HeaderText variant="caption">Name</HeaderText>
              </StyledTableCell>
              <StyledTableCell>
                <HeaderText variant="caption">NPI</HeaderText>
              </StyledTableCell>
              <StyledTableCell>
                <HeaderText variant="caption">Provider ID</HeaderText>
              </StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <TableBody>
            {(providersList ?? []).map((provider: IProvider) => (
              <StyledBodyTableRow
                hover={true}
                key={provider.id}
                selected={provider.id === selectedProviderId}
                onClick={() => onProviderSelected(provider.id)}
              >
                <StyledBodyTableCell>
                  <Typography variant="body1">{provider.name}</Typography>
                </StyledBodyTableCell>
                <StyledBodyTableCell>
                  <Typography variant="body1">{provider.npi}</Typography>
                </StyledBodyTableCell>
                <StyledBodyTableCell>
                  <Typography variant="body1">{provider.providerId}</Typography>
                </StyledBodyTableCell>
              </StyledBodyTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </>
  );
};

export default ProviderSelectList;
