import {
  isRejected,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import store from 'store';
import { errorHandler } from 'store/errorHandler';
import { clearAuthUser } from 'util/authUtils';
import { authLogout } from 'store/user/action';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { AnyAction } from 'redux';
import { actionNamesHandledInFailedRequests } from 'components/failedRequests/FailedRequests';
/**
 * Log a warning and show a toast!
 */

interface IError {
  extensions: { code: string };
}

const hasUnauthorizedAccessError = (errors: IError[]) => {
  return (
    errors?.some((error) => error.extensions?.code === 'AUTH_NOT_AUTHORIZED') ??
    false
  );
};

export const rtkQueryErrorLogger: Middleware =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    const userNotAuthorized = hasUnauthorizedAccessError(
      action.payload?.errors
    );

    if (
      action.payload &&
      ((action.payload.message &&
        (action.payload.message.includes(
          'The current user is not authorized to access this resource.'
        ) ||
          action.payload.message ===
            'Cannot Get User Roles: Internal Server Error' ||
          action.payload.message === 'Not authorized')) ||
        userNotAuthorized)
    ) {
      store.dispatch(authLogout());
      clearAuthUser();

      if (userNotAuthorized) {
        return next(action);
      }
    }

    if (isRejectedWithValue(action)) {
      // after 2 retries (count - from baseApi)
      errorHandler(
        `GraphQL request: ${action?.meta?.arg?.endpointName}`,
        `${action?.error?.message} / ${action?.payload?.name}`,
        action?.payload?.stack
      );

      showError(action);
    } else if (
      isRejected(action) &&
      action?.error?.message === 'Network request failed'
    ) {
      //browser offline:
      showError(action);
    }
    return next(action);
  };

interface IAction {
  type: string;
  meta: { arg: { endpointName: string } };
}

const requestErrorIsHandledInFailedRequests = (actionName: string) =>
  actionName &&
  actionNamesHandledInFailedRequests.find((x) => x === actionName);

const showError = (action: IAction) => {
  const actionName = action?.meta?.arg?.endpointName;
  if (!requestErrorIsHandledInFailedRequests(actionName)) {
    store.dispatch(showErrorPopup({ message: getErrorMessage(action) }));
  }
};

const getErrorMessage = (action: AnyAction): string =>
  action.type === 'api/executeMutation/rejected'
    ? 'Your changes could not be saved, because of technical issues. Keep this window open and try again later.'
    : '';
