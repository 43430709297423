import React, { useState } from 'react';
import { Box, Button, IconButton, Menu, TableCell } from '@mui/material';

import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import {
  commitReminders,
  removeJustAddedReminder,
  setActiveEpisode,
  setActiveTab,
  updateDirtyTabs,
} from 'store/patientdetails/patientDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import MoreInfoModal from 'components/home/patientdetails/tabscontent/remindersTab/components/reminderMoreInfo';
import { IState } from 'store';
import Confirmation from 'components/modal/Confirmation';
import styled from 'styled-components';
import { getEpisodeTabName } from 'components/home/grid/formatters/EpisodeNumberFormatter';

const StyledPopper = styled(Menu)({
  paper: {
    maxWidth: '400px',
    minWidth: '400px',
    backgroundColor: 'white',
    padding: '8px 20px',
    borderRadius: '4px',
    border: '1px solid #CECECE',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
  },
});

const StyledButton = styled(Button)`
  padding: 2px 8px;
`;

export const ActionsCell = (props: ILabelCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const modalPatientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const modalEpisodeId = useSelector(
    (state: IState) => state.patientDetails.episodeId
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const episodeNumber = modalEpisodeId || props.row?.episode?.id;
  const [patientId, episodeId] = episodeNumber
    ? [undefined, episodeNumber]
    : [modalPatientId, undefined];

  const { value: reminderId, row } = props;
  const dispatch = useDispatch();

  const commitReminder = (changed: {
    isDeleted?: boolean;
    isCompleted?: boolean;
    isExpired?: boolean;
  }) => {
    dispatch(
      commitReminders({
        episodeId: episodeId,
        patientId: patientId,
        reminders: { changed: { [Number(reminderId)]: changed } },
      })
    );
    dispatch(updateDirtyTabs(episodeId ? 'Episodes' : 'Reminders'));
  };

  const onCompleteReminder = () => {
    commitReminder({ isCompleted: true });
  };
  const deleteReminder = () => {
    commitReminder({ isDeleted: true });
  };

  const openReminder = () => {
    commitReminder({ isDeleted: false, isCompleted: false, isExpired: false });
  };

  const openEpisodeDetails = () => {
    dispatch(removeJustAddedReminder());
    dispatch(setActiveEpisode(props.row.episode.id));
    dispatch(
      setActiveTab({
        tab: getEpisodeTabName(props.row.episode.type?.name),
        subTab: 'Reminders',
      })
    );
  };
  const rowIsActive = !row.isCompleted && !row.isExpired && !row.isDeleted;
  const readonly = props.isActionsDisabled === true;
  const showCompleteButton = rowIsActive && !row.episode && !readonly;
  const showRestoreButton = !rowIsActive && !readonly;
  const showGoToDetailsButton = row.episode && rowIsActive;

  return (
    <TableCell style={CellComponentStyle}>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {showCompleteButton ? (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <StyledButton
              variant="text"
              color="primary"
              onClick={onCompleteReminder}
              disabled={props.disabled}
            >
              Complete
            </StyledButton>
          </Box>
        ) : null}
        {showRestoreButton ? (
          <StyledButton
            color="primary"
            variant="text"
            onClick={openReminder}
            disabled={props.disabled}
          >
            Restore
          </StyledButton>
        ) : null}
        {showCompleteButton ? (
          <StyledButton
            color="primary"
            variant="text"
            onClick={() => setShowDeleteConfirmation(true)}
            disabled={props.disabled}
          >
            Delete
          </StyledButton>
        ) : null}
        {showGoToDetailsButton ? (
          <StyledButton
            color="primary"
            variant="text"
            onClick={openEpisodeDetails}
            disabled={props.disabled}
          >
            Go to Details
          </StyledButton>
        ) : null}
        <IconButton
          aria-label="history reminder"
          data-cy="action-history"
          onClick={(e: React.MouseEvent<HTMLElement>) =>
            setAnchorEl(e.currentTarget)
          }
          style={{ padding: '4px' }}
          size="large"
        >
          <Icon
            title="More info"
            icon={ICONS.Info}
            size={14}
            color={COLORS.GREY100}
            hoverColor={COLORS.SYMPHONY_BLUE}
          />
        </IconButton>
        <StyledPopper
          aria-labelledby="reminder-history-dialog"
          open={open}
          id={id}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          style={{}}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MoreInfoModal reminderId={row.id} note={row.note} />
        </StyledPopper>

        <Confirmation
          open={showDeleteConfirmation}
          title="Delete Reminder?"
          okButtonText="Delete"
          okEvent={() => {
            setShowDeleteConfirmation(false);
            deleteReminder();
          }}
          cancelEvent={() => setShowDeleteConfirmation(false)}
        >
          <span>Are you sure you want to delete this reminder?</span>
        </Confirmation>
      </Box>
    </TableCell>
  );
};
