import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';

const Grey10HeaderCellComponent = (props: TableHeaderRow.CellProps) => (
  <TableHeaderRow.Cell
    {...props}
    style={{
      fontSize: '12px',
      fontWeight: 500,
      padding: '10px 0px 5px 10px',

      color: COLORS.GREY100,
      backgroundColor: COLORS.GREY10,
    }}
  >
    {props.children}
  </TableHeaderRow.Cell>
);

export default Grey10HeaderCellComponent;
