import { ILookup } from 'backend/types/lookup';
import React from 'react';
import { COLORS } from 'consts/styles';

export const DropdownLookupEmpty = (props: {
  lookup: ILookup | null | undefined;
}) => {
  const { lookup } = props;

  if (!lookup) {
    return (
      <span style={{ color: COLORS.RED100 }}>Lookup has been deleted</span>
    );
  } else if (lookup.isDeleted) {
    return (
      <span style={{ color: COLORS.RED100 }}>
        {lookup.name} lookup has been deleted
      </span>
    );
  } else {
    return null;
  }
};
