import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';

export const GridValidationLineRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '14px 28px 0px 64px',
});
export const GridLineRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '14px 28px 0px 64px',
});
export const GridSearchLineRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '12px 28px 12px 64px',
});

export const MedCodeOptionWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MedCodeSuggestionTypography = styled(Typography)({
  margin: '0 3px',
});
