import React from 'react';
import { DateComponent } from 'graphql/graphqlTypes';
import { StyledReviewTypography } from 'components/review/sections/SectionBody/Components/styles';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export interface IDateComponentProps {
  component: DateComponent;
}

const DateComp = ({ component }: IDateComponentProps) => (
  <StyledReviewTypography variant="body1" $color={component.color ?? undefined}>
    {component.value ? formatDate(component.value) : ''}
  </StyledReviewTypography>
);

export default DateComp;
