import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ChecklistStorageType } from 'store/actions/types';
import { IState } from 'store';
import {
  duplicateEpisodeColumnsExtension,
  getDuplicateEpisodeColumns,
} from 'components/home/patientdetails/tabscontent/episodesTab/consts';
import { useDispatch, useSelector } from 'react-redux';
import { EpisodeTabHeader } from 'components/home/patientdetails/tabscontent/episodesTab/EpisodeTabHeader';
import { EpisodeGrid } from 'components/home/patientdetails/tabscontent/episodesTab/EpisodeGrid';
import { EpisodeListState } from 'backend/types/episodeListState';
import { ILookupValue } from 'backend/types/lookupValue';
import { debounce } from 'lodash';
import {
  ChecklistItem,
  ChecklistItemDuplicateCheck,
  UpdateActionValueModelInput,
  DuplicateCheckActionValue,
  EpisodeWithMozartInfoViewModel,
} from 'graphql/graphqlTypes';
import { useLazyGetDuplicateEpisodesQuery } from 'graphql/hooks/getDuplicateEpisodes';
import { IEpisodesTabState } from 'components/home/patientdetails/tabscontent/episodesTab/types';
import DuplicateCheckConfirm from './DuplicateCheckConfirm';
import { useSaveDuplicateCheck } from './DuplicateCheck.helpers';
import { getValidationDispatch } from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.helpers';
import { Sorting } from '@devexpress/dx-react-grid';
import { DUPLICATE_CHECK } from 'components/constants';
import { setDuplicateCheckLoading } from 'store/actions/mozartSlice';
import { COLORS } from 'consts/styles';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';

export interface IChecklistItemDuplicateCheckProps {
  item: ChecklistItemDuplicateCheck;
  categoryId: string;
  autoSave: boolean;
  orderableIndex?: number;
  storageType: ChecklistStorageType;
  isReadOnly: boolean;
  checklistId?: string;
  updateChecklistItemInputValueOnSave?: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  selectChecklistItemOnSave?: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
}
//ToDo this component needs refactor as it's re-rendering multiple times and causing issues with loading flags
const DuplicateCheck = ({
  item,
  autoSave,
  orderableIndex,
  storageType,
  isReadOnly,
  checklistId,
  updateChecklistItemInputValueOnSave,
  categoryId,
}: IChecklistItemDuplicateCheckProps) => {
  const checklistIdState = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.id ?? checklistId
  );

  const isUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    item.uid
  );

  const dispatch = useDispatch();
  const saveDuplicateCheck = useSaveDuplicateCheck();
  const handleInputChange = debounce((value: DuplicateCheckActionValue) => {
    saveDuplicateCheck(
      value,
      checklistIdState,
      item,
      autoSave,
      updateChecklistItemInputValueOnSave,
      orderableIndex
    );
  }, 100);
  const [episodeTabModel, setEpisodeTabModel] = useState<IEpisodesTabState>({
    patientId: 0,
    showTatColumn: false,
    showCounterColumn: false,
    isDirectOpen: false,
    selectEpisode: 0,
  } as IEpisodesTabState);
  const [searchDuplicateEpisodes, { data, isFetching }] =
    useLazyGetDuplicateEpisodesQuery();

  const handleSetPage = (pageNumber: number) => {
    setEpisodeTabModel({
      ...episodeTabModel,
      isDirectOpen: false,
      selectEpisode: undefined,
      page: pageNumber,
    } as IEpisodesTabState);
    searchDuplicateEpisodes({
      request: {
        page: pageNumber,
        pageSize: Math.min(totalCount - pageNumber * 15, 15),
        patientId: 0,
        orderBy: 'createdOn',
        ascDesc: 'desc',
        checklistId: Number(checklistIdState ?? 0),
        showTatColumn: false,
        showCounterColumn: false,
      },
    });
  };

  const handleChangeEpisodeState = (filter: ILookupValue) => {
    setEpisodeTabModel({
      ...episodeTabModel,
      episodeListState: String(filter.id),
      isDirectOpen: false,
      selectEpisode: undefined,
      page: 0,
    } as IEpisodesTabState);
    searchDuplicateEpisodes({
      request: {
        page: 0,
        pageSize: 15,
        patientId: 0,
        orderBy: 'createdOn',
        ascDesc: 'desc',
        state: String(filter.id),
        checklistId: Number(checklistIdState ?? 0),
        showTatColumn: false,
        showCounterColumn: false,
      },
    });
  };
  const handleSetSorting = (sorting: Sorting[]) => {
    setEpisodeTabModel({
      ...episodeTabModel,
      page: 0,
      orderBy: sorting[0].columnName,
      ascDesc: sorting[0].direction.toString(),
      isDirectOpen: false,
      selectEpisode: undefined,
    } as IEpisodesTabState);
    searchDuplicateEpisodes({
      request: {
        page: 0,
        pageSize: 15,
        patientId: 0,
        orderBy: sorting[0].columnName,
        ascDesc: sorting[0].direction.toString(),
        state: episodeTabModel.episodeListState,
        checklistId: Number(checklistIdState ?? 0),
        showTatColumn: false,
        showCounterColumn: false,
      },
    });
  };
  const gridColumnExtensions = duplicateEpisodeColumnsExtension;
  const tabColumns = getDuplicateEpisodeColumns();
  const totalCount = Number(data?.getDuplicateEpisodes?.pager?.totalCount ?? 0);
  const [model, setModel] = useState({
    ...item.dcValue,
    duplicateFound: item.dcValue?.duplicateFound ?? null,
    duplicateFoundConfirmation:
      item.dcValue?.duplicateFoundConfirmation ?? null,
  } as DuplicateCheckActionValue);

  useEffect(() => {
    const newStateConfirm: DuplicateCheckActionValue = {
      ...item.dcValue,
      duplicateFound: totalCount > 0,
      duplicateFoundConfirmation:
        item.dcValue?.duplicateFoundConfirmation ?? null,
    };
    setModel(newStateConfirm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  useEffect(() => {
    searchDuplicateEpisodes({
      request: {
        page: 0,
        pageSize: 15,
        patientId: 0,
        orderBy: 'createdOn',
        ascDesc: 'desc',
        state: '-1',
        checklistId: Number(checklistIdState ?? 0),
        showTatColumn: false,
        showCounterColumn: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistIdState]);

  useEffect(() => {
    setEpisodeTabModel({
      ...episodeTabModel,
      episodeListState: EpisodeListState.All,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEpisodeTabModel]);

  useEffect(() => {
    dispatch(setDuplicateCheckLoading(isFetching));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationDispatch = getValidationDispatch(
    dispatch,
    item.uid,
    categoryId,
    storageType,
    DUPLICATE_CHECK
  );

  const onChange = (newModel: DuplicateCheckActionValue) => {
    setModel(newModel);
    handleInputChange(newModel);
  };

  return (
    <div
      data-cy="episodes-tab-content"
      data-testid="duplicate-check"
      style={{
        padding: isReadOnly ? '14px 0' : '0 28px 0 64px',
        backgroundColor: COLORS.WHITE,
      }}
    >
      <Grid container style={{ width: '100%' }}>
        <Grid item>
          {!isReadOnly && (
            <EpisodeTabHeader
              onChangeEpisodeState={handleChangeEpisodeState}
              episodeListState={episodeTabModel.episodeListState}
              title="Episodes"
            />
          )}
          <EpisodeGrid
            tabColumns={tabColumns}
            tabRows={
              (data?.getDuplicateEpisodes?.episodes ??
                []) as EpisodeWithMozartInfoViewModel[]
            }
            totalCount={totalCount}
            selectedEpisodeId={0}
            episodeTabModel={episodeTabModel}
            onSetSorting={handleSetSorting}
            columnExtensions={gridColumnExtensions}
            isFromDuplicateEpisode={true}
            onSetCurrentPage={handleSetPage}
          />
        </Grid>
      </Grid>
      <DuplicateCheckConfirm
        state={model}
        isReadOnly={isReadOnly}
        onChange={onChange}
        validationDispatch={validationDispatch}
      />
      {isUpdateActionValueRequestFailed && (
        <Typography style={{ color: 'red' }}>
          Internal server error: Your changes could not be saved, because of
          technical issues.
        </Typography>
      )}
    </div>
  );
};
export default React.memo(DuplicateCheck); //ToDo investigate component and remove this - this is temporary fix
