import React, { useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from 'components/modal/DialogActions';
import { Button, Typography } from '@mui/material';
import MultiEditDialogBody from './DialogBody';
import { IMultiEditDataModel, MultiEditRef } from './types';
import { IState } from 'store';
import { forceUpdate } from 'store/home/action';
import { useIsMounted } from 'hooks';
import Loader from 'components/loader';
import { useGetMultiEditDataQuery } from 'graphql/hooks/getMultiEditData';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

interface MultiEditDialogProps {
  open: boolean;
  onClose: () => void;
}

const StyledDialogBody = styled(DialogContent)({
  height: 'auto',
  overflowX: 'auto',
  padding: '24px',
  backgroundColor: `${COLORS.GREY4}`,
});

const StyledButton = styled(Button)({
  minWidth: '152px',
  margin: '12px 10px',
});

const StyledGrow = styled.div`
  flex-grow: 1;
`;

const StyledRightDiv = styled.div`
  margin-right: 20px;
`;

const StyledTypography = styled(Typography)`
  color: ${COLORS.WHITE};
`;

const MultiEditDialog = (props: MultiEditDialogProps) => {
  const { open, onClose } = props;
  const childRef = useRef<MultiEditRef>(null);
  const isMounted = useIsMounted();

  const patientIds = useSelector((state: IState) => state.multiEdit.patientIds);
  const episodeIds = useSelector((state: IState) => state.multiEdit.episodeIds);
  const dispatch = useDispatch();

  const { data, isFetching, isSuccess } = useGetMultiEditDataQuery(
    {
      patientIds: patientIds,
      episodeIds: episodeIds,
    },
    {
      skip: !(patientIds.length > 0 || episodeIds.length > 0) || !isMounted(),
    }
  );

  const handleSave = () => {
    const childNode = childRef.current as MultiEditRef;
    if (childNode) {
      childNode.submitForm();
    }
  };

  const handleClose = () => {
    const childNode = childRef.current as MultiEditRef;
    if (childNode) {
      childNode.resetForm();
    }
    onClose();
  };

  const afterSave = () => {
    dispatch(forceUpdate(true));
    handleClose();
  };

  if (isFetching) {
    return <Loader active={true} />;
  }

  return (
    <>
      {data && data.getMultiEditData && isSuccess && (
        <>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              <StyledTypography variant="subtitle1">
                Select checkbox to edit
              </StyledTypography>
            </DialogTitle>
            <StyledDialogBody dividers>
              <MultiEditDialogBody
                model={data.getMultiEditData as IMultiEditDataModel}
                ref={childRef}
                afterSaveCallback={afterSave}
              />
            </StyledDialogBody>
            <DialogActions>
              <StyledGrow />
              <StyledRightDiv>
                <StyledButton
                  onClick={handleClose}
                  color="primary"
                  size="large"
                  variant="outlined"
                  data-cy="cancel"
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  onClick={handleSave}
                  color="primary"
                  size="large"
                  variant="contained"
                  data-cy="save"
                >
                  Save
                </StyledButton>
              </StyledRightDiv>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default MultiEditDialog;
