import React, { forwardRef } from 'react';
import styled from 'styled-components';

const LargeFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export interface ILetterPreviewProps {
  document: string;
}

const LetterPreviewRef = forwardRef<HTMLIFrameElement, ILetterPreviewProps>(
  ({ document }, ref) => {
    const blob = new Blob([document], {
      type: 'application/pdf',
    });
    return (
      <LargeFrame
        ref={ref}
        src={URL.createObjectURL(blob)}
        id="letterPreview"
      />
    );
  }
);

LetterPreviewRef.displayName = 'LetterPreview';

const LetterPreview = React.memo(LetterPreviewRef) as typeof LetterPreviewRef;
LetterPreview.displayName = 'LetterPreviewMemo';

export { LetterPreview };
