import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetRoleNamesQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;

export type GetRoleNamesQuery = {
  __typename?: 'EntitiesQuery';
  getRoles?: Array<{
    __typename?: 'Role';
    displayName?: string | null;
  } | null> | null;
};

export const GetRoleNamesDocument = `
    query getRoleNames($ids: [Int!]!) {
  getRoles(ids: $ids) {
    displayName
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRoleNames: build.query<GetRoleNamesQuery, GetRoleNamesQueryVariables>({
      query: (variables) => ({ document: GetRoleNamesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRoleNamesQuery, useLazyGetRoleNamesQuery } =
  injectedRtkApi;
