import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeRemindersQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeRemindersQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeReminders: Array<{
    __typename?: 'BaseReminder';
    id: number;
    importanceId?: number | null;
    daysOverdue: number;
    date?: any | null;
    dueDateTimeType?: Types.DueDateTimeType | null;
    isCompleted?: boolean | null;
    isDeleted?: boolean | null;
    isExpired?: boolean | null;
    isNew?: boolean | null;
    importance?: Types.ReminderImportance | null;
    createdBy: number;
    note?: string | null;
    reason?: {
      __typename?: 'LookupValue';
      id: number;
      image?: string | null;
      name?: string | null;
      sortOrder?: number | null;
    } | null;
    owner?: {
      __typename?: 'User';
      fullName?: string | null;
      id: number;
    } | null;
  }>;
};

export const GetEpisodeRemindersDocument = `
    query getEpisodeReminders($episodeId: Int!) {
  getEpisodeReminders(episodeId: $episodeId) {
    id
    importanceId
    daysOverdue
    date
    dueDateTimeType
    reason {
      id
      image
      name
      sortOrder
    }
    isCompleted
    isDeleted
    isExpired
    isNew
    importance
    createdBy
    owner {
      fullName
      id
    }
    note
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeReminders: build.query<
      GetEpisodeRemindersQuery,
      GetEpisodeRemindersQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeRemindersDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeRemindersQuery, useLazyGetEpisodeRemindersQuery } =
  injectedRtkApi;
