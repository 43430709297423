import React, { useState } from 'react';
import Button from '@mui/material/Button';
import apiClient from 'util/axiosHttp';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistItemMedicalCodes } from 'graphql/graphqlTypes';
import { Box } from '@mui/material';
import { ROUTE } from 'consts/ROUTE';
import { fixGuid } from 'components/actions/sections/SectionBody/Components/ActionButton/actionButton.helpers';
import { allItemsByTypeSelector } from 'store/actions/checklist.selectors';
import { IActionButtonProps } from './ActionButton';
import { setComponentValueByUserDefinedId } from 'store/actions/checklistSlice';

export const InterQualActionButton = ({
  storageType,
  ...props
}: IActionButtonProps) => {
  const dispatch = useDispatch();
  let pdfSaved = false;
  const [commentUpdated, setCommentUpdated] = useState<string>();
  const [reviewIdUpdated, setReviewIdUpdated] = useState<string>();

  const medicationCodes = useSelector((state: IState) =>
    allItemsByTypeSelector(state, storageType, 'medical codes')
  );
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const handleSetMet = (userDefinedId: string, value: string) => {
    dispatch(
      setComponentValueByUserDefinedId({
        storageType,
        userDefinedId,
        value,
      })
    );
  };

  const iqCallbackSetStatusAndReviewId = (status: string, reviewId: string) => {
    if (status) {
      setCommentUpdated(' / ' + status);
      setReviewIdUpdated(reviewId);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function checkPdfSavedLongPoll(payload: any, numberAttempted: number) {
    const newNumberAttempted = numberAttempted + 1;
    if (pdfSaved === true) {
      return;
    } else {
      if (numberAttempted < 6) {
        setTimeout(() => {
          checkPdfSavedLongPoll(payload, newNumberAttempted);
        }, 6000);
      } else {
        apiClient.post('InterQual/ReviewSummaryPdf', payload, {
          responseType: 'json',
        });
      }
    }
  }

  const handleInterQual = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[
      '$IqCallbackSetStatusAndReviewId_' +
        props.component.uniqueID.replace(/-/g, '')
    ] = (status: string, reviewId: string) => {
      iqCallbackSetStatusAndReviewId(status, reviewId);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[
      '$IqCallbackSetMet_' + props.component.uniqueID.replace(/-/g, '')
    ] = (met: string) => {
      if (props.component.metComponentId) {
        handleSetMet(props.component.metComponentId, met);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[
      '$IqCallbackSavePdfStart_' + props.component.uniqueID.replace(/-/g, '')
    ] = (payload: string) => {
      checkPdfSavedLongPoll(payload, 0);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[
      '$IqCallbackSavePdfComplete_' + props.component.uniqueID.replace(/-/g, '')
    ] = () => {
      pdfSaved = true;
    };

    const allUniqueMedicationCodes = [
      ...new Set(
        medicationCodes.flatMap((item) =>
          ((item as ChecklistItemMedicalCodes).value ?? []).map(
            (value) => value?.code
          )
        )
      ),
    ];

    const url =
      ROUTE.InterQual +
      '?checklistId=' +
      checklistId +
      '&actionGuid=' +
      fixGuid(props.actionId) +
      '&fieldId=' +
      props.component.id +
      '&buttonUid=' +
      fixGuid(props.component.uniqueID) +
      '&medicationCodesStr=' +
      allUniqueMedicationCodes +
      '&reviewId=' +
      (reviewIdUpdated ? reviewIdUpdated : props.component.value) +
      '&metComponentId=' +
      props.component.metComponentId;
    window.open(url, '_blank');
  };

  return (
    <Box display="flex">
      <Button
        data-testid="InterQualButton"
        data-interqualid={fixGuid(props.component.uniqueID)}
        color="primary"
        variant={
          props.component.actionButtonStyle === 'button' ? 'contained' : 'text'
        }
        aria-required={props.component.required}
        onClick={handleInterQual}
        disabled={props.isReadOnly}
      >
        {props.component.name}
        {commentUpdated ? commentUpdated : props.component.comment}
      </Button>
    </Box>
  );
};
