import { Typography } from '@mui/material';
import {
  updateItemDeterminationReason,
  updateItemGroupDecision,
} from 'components/actions/sections/SectionBody/Items/HealthService/Components/IndividualService.helpers';
import React, { useEffect, useState, useRef } from 'react';
import { ServiceRowReview } from './ServiceRowReview';
import {
  HealthServiceCodesActionValue,
  ILookupValue,
  LookupValue,
  HealthServiceCodesActionModel,
} from 'graphql/graphqlTypes';
import {
  getGridBackground,
  getDecisionIcon,
  getGrouDecision,
  isOverallStatus,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthServiceV2helper';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import {
  StyledContainer,
  StyledHeaderDiv,
  StyledBox,
  StyledServiceRowDiv,
  StyledDivider,
  StyledDecision,
  StyledHeader,
} from './styles';
import { healthServiceDisplayMode } from 'components/actions/sections/SectionBody/HealthServiceDisplayMode';
import { ManualReviewDecisionGroup } from './ManualReviewDecisionGroup';
import ServiceRowManualReview from './ServiceRowManualReview';
import {
  DeterminationDecision,
  HealthServiceAuthorizationType,
} from 'components/actions/sections/SectionBody/Items/HealthService/types';
import ServiceRowFinalOutCome from './ServiceRowFinalOutCome';

export interface IHSRNIndividualServiceProps {
  id: string;
  item: HealthServiceCodesActionValue;
  determinationReasons: { [key: string]: ILookupValue[] };
  onChange: (state: HealthServiceCodesActionValue) => void;
  unitTypes: ILookupValue[];
  overallDates: { start: Date | null; end: Date | null };
  validationDispatch: (
    errorMessage: string,
    error: boolean,
    id?: string
  ) => void;
  hsRules: number;
  overallStatus: boolean;
  readOnly: boolean;
  healthServiceModel: HealthServiceCodesActionModel;
  hsAuthorizationType: number;
  pendingOnly?: boolean;
  canApprove?: boolean;
  canDenied?: boolean;
  canPartial?: boolean;
}

const HSRNIndividualServiceReview = (props: IHSRNIndividualServiceProps) => {
  const {
    id,
    item,
    determinationReasons,
    onChange,
    unitTypes,
    overallDates,
    validationDispatch,
    hsRules,
    readOnly,
    healthServiceModel,
    hsAuthorizationType,
    pendingOnly,
    canApprove,
    canDenied,
    canPartial,
  } = props;

  const isAuthRuleOutcome =
    hsRules === healthServiceDisplayMode.authRulesOutcome;
  const isFinalOutcome = hsRules === healthServiceDisplayMode.finalOutcome;
  const isManualReview = hsRules === healthServiceDisplayMode.manualReview;
  const isAuthOutcomeRollUp =
    hsRules === healthServiceDisplayMode.authOutcomeRollUp;

  const reasonRef = useRef<HTMLInputElement>(null);
  const [groupDecision, updateGroupDecision] = useState<DeterminationDecision>(
    getGrouDecision(item)
  );

  const setFocus = (decision: DeterminationDecision) => {
    if (decision !== 'NonSet' && decision !== 'Partial') {
      reasonRef?.current?.focus();
    }
  };
  const isOverallStatusApproved = isOverallStatus(
    healthServiceModel,
    'Approved'
  );
  const gridColor = getGridBackground(
    item.healthServiceActionValue?.determinationDecision,
    isOverallStatusApproved,
    hsRules
  );
  useEffect(() => {
    updateGroupDecision(getGrouDecision(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.healthServiceActionValue?.determinationDecision]);

  const [determinationReason, updateDeterminationReason] = useState<
    LookupValue | null | undefined
  >(item.healthServiceActionValue?.determinationReason);

  useEffect(() => {
    const reasonId = `${item.id}-reason`;
    if (groupDecision !== 'NonSet' && !determinationReason) {
      validationDispatch(
        `Decision reason for service ${item.code} is not set`,
        true,
        reasonId
      );
    } else {
      validationDispatch('', false, reasonId);
    }
    return () => {
      validationDispatch('', false, reasonId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupDecision, determinationReason]);

  useEffect(() => {
    if (item.healthServiceActionValue?.determinationDecision === 'Approved') {
      const newItem = {
        ...item,
        healthServiceActionValue: {
          ...item.healthServiceActionValue,
          approvedUnits: item.healthServiceActionValue?.requestedUnits,
        },
      };
      onChange(newItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    item.healthServiceActionValue?.requestedUnits,
    item.healthServiceActionValue?.determinationDecision,
  ]);

  useEffect(() => {
    updateDeterminationReason(
      item.healthServiceActionValue?.determinationReason
    );
  }, [item.healthServiceActionValue?.determinationReason]);

  const onUpdateDeterminationReason = (reason: LookupValue | null) => {
    const newItem = updateItemDeterminationReason(item, reason);
    updateDeterminationReason(reason);
    onChange(newItem);
  };

  const onUpdateGroupDecision = (decision: DeterminationDecision) => {
    const newItem = updateItemGroupDecision(item, decision);
    updateGroupDecision(decision);
    setFocus(decision);
    onChange(newItem);
  };

  return (
    <>
      {isAuthRuleOutcome || isAuthOutcomeRollUp ? null : (
        <StyledDecision variant="body2">
          {item.healthServiceActionValue?.determinationDecision}
        </StyledDecision>
      )}
      <StyledContainer
        id={id}
        xs={12}
        item
        alignItems="center"
        direction="row"
        data-testid="hsRNIndividualServiceReview"
      >
        <StyledHeader $color={gridColor}>
          <StyledHeaderDiv>
            {!isAuthRuleOutcome && (
              <>
                {getDecisionIcon(
                  item.healthServiceActionValue?.determinationDecision,
                  isOverallStatusApproved,
                  hsRules
                )}
              </>
            )}
            <Typography variant="body2">{item.code}</Typography>
            <StyledDivider orientation="vertical" />
            <CustomTooltip title={item.description ?? ''}>
              <Typography noWrap variant="body1">
                {item.description}
              </Typography>
            </CustomTooltip>
          </StyledHeaderDiv>
        </StyledHeader>
        {(isAuthRuleOutcome || isAuthOutcomeRollUp) &&
          hsAuthorizationType != HealthServiceAuthorizationType.Episode && (
            <StyledBox>
              <StyledServiceRowDiv>
                <ServiceRowReview
                  type="Requested"
                  hideDates={false}
                  hideValues={false}
                  item={item}
                />
              </StyledServiceRowDiv>
            </StyledBox>
          )}

        {isManualReview && (
          <>
            <StyledBox>
              <ServiceRowManualReview
                item={item}
                readOnly={false}
                onChange={onChange}
                unitTypes={unitTypes}
                overallDates={overallDates}
                validationDispatch={validationDispatch}
                groupDecision={groupDecision}
              />
              <ManualReviewDecisionGroup
                updateGroupDecision={onUpdateGroupDecision}
                groupDecision={groupDecision}
                groupDecisionReadOnly={false}
                pendingOnly={pendingOnly}
                canApprove={canApprove}
                canDenied={canDenied}
                canPartial={canPartial}
                determinationReasons={determinationReasons}
                value={determinationReason}
                reasonSelectReadOnly={false}
                updateValue={onUpdateDeterminationReason}
                error={!determinationReason}
                determinationReasonName={determinationReason?.name}
                readOnly={readOnly}
              />
            </StyledBox>
          </>
        )}

        {isFinalOutcome &&
          hsAuthorizationType != HealthServiceAuthorizationType.Episode && (
            <StyledBox>
              <ServiceRowFinalOutCome
                item={item}
                determinationReasonName={determinationReason?.name}
              />
            </StyledBox>
          )}
      </StyledContainer>
    </>
  );
};

export default HSRNIndividualServiceReview;
