import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import IndividualService from './IndividualServiceReview';
import OverallReview from './OverallReview';
import {
  useDeterminationReasons,
  useLookup,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.hooks';
import { COLORS } from 'consts/styles';
import { HealthServiceSummary } from 'components/actions/sections/SectionBody/Items/HealthService/Components/HealthServiceSummary';
import { omit } from 'lodash';
import {
  ChecklistItemHealthServices,
  HealthServiceCodesActionModel,
  HealthServiceCodesActionValue,
  Maybe,
} from 'graphql/graphqlTypes';
import {
  DecisionAction,
  DeterminationDecision,
  HealthServiceAuthorizationType,
} from 'components/actions/sections/SectionBody/Items/HealthService/types';
import { useDispatch } from 'react-redux';
import { updateCompletedChecklistsIsVisible } from 'store/actions/mozartSlice';
import { healthServiceDisplayMode } from 'components/actions/sections/SectionBody/HealthServiceDisplayMode';
import { HsManualSummary } from 'components/actions/sections/SectionBody/Items/HealthService/Components/HsManualSummary';
import { getDefaultUnitType } from 'components/actions/sections/SectionBody/Items/HealthService/HealthServiceV2helper';
import { initHsGroupDecision } from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.helpers';
import HSRNManualReview from './HSRNManualReview';
import { ChecklistStorageType } from 'store/actions/types';

export interface IChecklistItemHealthServiceProps {
  item: ChecklistItemHealthServices;
  checklistId?: string;
}

const isHealthServiceEmpty = (hsModel: HealthServiceCodesActionModel) => {
  const hsModelWithoutActionValues = omit(
    hsModel,
    'healthServiceCodesActionValues'
  );
  const hsModelIsFilled = Object.values(hsModelWithoutActionValues).some(
    Boolean
  );
  const hsModelActionsIsFilled =
    hsModel.healthServiceCodesActionValues.length > 0;
  return !hsModelIsFilled && !hsModelActionsIsFilled;
};

const HealthServiceReview = (props: IChecklistItemHealthServiceProps) => {
  const { item } = props;
  const unitTypes = useLookup('UnitsOfServiceType');
  const hsModel = (item.hSValue ?? {}) as HealthServiceCodesActionModel;
  const determinationReasons = useDeterminationReasons(item.options, true);
  const hsDefaultUnit = item.options.hSDefaultUnit;
  const defaultUnitType = getDefaultUnitType(unitTypes, hsDefaultUnit);
  const episodeDecision =
    initHsGroupDecision(hsModel) ?? item?.hSValue?.overallDeterminationDecision;
  const dispatch = useDispatch();
  const hsDisplayMode = item.options.hSDisplayMode;

  useEffect(() => {
    if (hsModel) {
      const hsGroupDecision =
        (hsModel?.overallDeterminationDecision as DeterminationDecision) ??
        (hsModel?.healthServiceCodesActionValues[0]?.healthServiceActionValue
          ?.determinationDecision as DeterminationDecision) ??
        DecisionAction.nonSet;
      dispatch(
        updateCompletedChecklistsIsVisible({
          items: item,
          checklistId: props.checklistId,
          decision: hsGroupDecision,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hsModel]);

  if (isHealthServiceEmpty(hsModel)) {
    return null;
  }
  return (
    <Box
      bgcolor={COLORS.WHITE}
      marginLeft="-24px"
      marginRight="-24px"
      id={`uuid-${item.uid}`}
      data-testid="hsManualReview"
    >
      {hsDisplayMode === healthServiceDisplayMode.authOutcomeRollUp ? (
        <HSRNManualReview
          item={item}
          categoryId={''}
          autoSave={false}
          orderableIndex={0}
          storageType={ChecklistStorageType.MOZART}
          updateChecklistItemInputValueOnSave={() => {
            return;
          }}
          selectChecklistItemOnSave={() => {
            return;
          }}
          hsRules={hsDisplayMode}
          readOnly={false}
          isHsAllowAddingCodes={false}
          isHsAllowDeletingCode={false}
          hsAuthorizationType={item.options.hSAuthorizationType}
          isDisplayEpisodeLevelRequestedParam={false}
        />
      ) : (
        <>
          <Box padding="14px 36px 14px 68px">
            <Typography variant="body2">{item.options.codeLabel}</Typography>
          </Box>
          <Box>
            <Box bgcolor={COLORS.GREY4} padding="8px 20px 16px 68px">
              <OverallReview
                item={hsModel}
                unitTypes={unitTypes}
                isRNReview={false}
                hsRules={0}
                hsAuthorizationType={0}
              />
            </Box>
            <Box padding="16px 58px 4px 68px" borderRadius="4px">
              <Typography variant="body2" padding="0px 0px 20px 0px">
                Individual Services:
              </Typography>
              {hsModel.healthServiceCodesActionValues?.length > 0 ? (
                <>
                  {hsModel.healthServiceCodesActionValues?.map(
                    (
                      modelItem: Maybe<HealthServiceCodesActionValue>,
                      index: number
                    ) => (
                      <IndividualService
                        key={index.toString()}
                        determinationReasons={determinationReasons}
                        item={
                          (modelItem ?? {}) as HealthServiceCodesActionValue
                        }
                        hsAuthorizationType={item.options.hSAuthorizationType}
                        hsDisplayMode={item.options.hSDisplayMode}
                      />
                    )
                  )}
                </>
              ) : null}
            </Box>
          </Box>
          {item.options.hSAuthorizationType ===
            HealthServiceAuthorizationType.NonSet && (
            <Box padding="10px 0px 10px 68px">
              <HealthServiceSummary
                unitTypes={unitTypes}
                hsModel={hsModel}
                hsAuthorizationType={0}
                hSDisplayMode={0}
                episodeDecision={DecisionAction.nonSet}
                defaultUnitType={undefined}
              />
            </Box>
          )}
          {item.options.hSAuthorizationType !=
            HealthServiceAuthorizationType.NonSet &&
            item.options.hSDisplayMode !==
              healthServiceDisplayMode.requestingCodes &&
            item.options.hSDisplayMode !== healthServiceDisplayMode.nonSet && (
              <Box bgcolor={COLORS.GREY4} padding="0px 0px 0px 8px">
                <HsManualSummary
                  determinationReasons={determinationReasons}
                  unitTypes={unitTypes}
                  hsModel={item.hSValue as HealthServiceCodesActionModel}
                  episodeDecision={episodeDecision}
                  onChange={() => {
                    return;
                  }}
                  hsAuthorizationType={item.options.hSAuthorizationType}
                  hSDisplayMode={item.options.hSDisplayMode}
                  defaultUnitType={defaultUnitType}
                  actionOnGroup={() => {
                    return;
                  }}
                  actionOnDecision={() => {
                    return;
                  }}
                  canPending={false}
                  canApprove={false}
                  canDenied={false}
                  canPartial={false}
                  readOnly={false}
                  readOnlyView={true}
                />
              </Box>
            )}
        </>
      )}
    </Box>
  );
};

export default HealthServiceReview;
