import React, { SyntheticEvent, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import PatientDetailsTab from './Tabs/PatientDetailsTab';
import { IBackgroundTabProps } from '../types';
import { COLORS } from 'consts/styles';
import ContactTab from './Tabs/contactTab';
import MemberNotesTab from './Tabs/memberNotes';
import PatientTagsTab from './Tabs/patientTags';
import InsuranceTab from './Tabs/insuranceTab/insuranceTab';
import RiskTab from './Tabs/riskTab';
import { Privilege } from 'store/roles/types';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { useSelector } from 'react-redux';
import { IState } from 'store';

const PatientDetailsBackgroundSubTabs = (props: IBackgroundTabProps) => {
  const {
    style,
    isExternalDataReadOnly,
    selectedSubTab,
    handleUpdatePatientTabValid,
    patientDetails,
  } = props;
  const TABS = ['Summary', 'Contact', 'Insurance', 'Tags', 'Notes'];
  if (hasUserPrivilege(Privilege.Risk)) {
    TABS.push('Risk');
  }

  const [currentTab, setCurrentTab] = useState<number>(
    selectedSubTab && TABS.includes(selectedSubTab)
      ? TABS.indexOf(selectedSubTab)
      : 0
  );

  const handleChange = <T,>(_: SyntheticEvent<T>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );

  const disabled =
    !hasUserPrivilege(Privilege.EditMember) ||
    !hasUserPrivilege(Privilege.EditMemberTab);

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label="disabled tabs"
        style={{
          padding: '0 24px',
          borderBottom: `1px solid ${COLORS.GREY25}`,
        }}
      >
        {TABS.map((item, index) => (
          <Tab
            style={{ textTransform: 'capitalize' }}
            label={item}
            key={index}
            disabled={isInEditMode && index !== currentTab}
          />
        ))}
      </Tabs>
      <Box pt="24px" px="24px">
        {currentTab === 0 && (
          <PatientDetailsTab
            style={style}
            disabled={disabled}
            isExternalDataReadOnly={isExternalDataReadOnly}
            handleUpdatePatientTabValid={handleUpdatePatientTabValid}
            patientDetails={patientDetails}
          />
        )}
        {currentTab === 1 && (
          <ContactTab
            disabled={disabled}
            isExternalDataReadOnly={isExternalDataReadOnly}
          />
        )}
        {currentTab === 3 && <PatientTagsTab disabled={disabled} />}
        {currentTab === 2 && <InsuranceTab />}
        {currentTab === 4 && <MemberNotesTab />}
        {currentTab === 5 && (
          <RiskTab
            disabled={disabled}
            handleUpdatePatientTabValid={handleUpdatePatientTabValid}
          />
        )}
      </Box>
    </Box>
  );
};

export default PatientDetailsBackgroundSubTabs;
