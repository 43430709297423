import React from 'react';

import AttachmentsGrid from './attachmentsGrid';

import { IState } from 'store';
import { useData } from 'components/gridFormatters';
import { commitAttachments } from 'store/patientdetails/patientDetails.attachment.slice';
import {
  GetEpisodeAttachmentsQuery,
  useLazyGetEpisodeAttachmentsQuery,
} from 'graphql/hooks/getEpisodeAttachments';
import { Attachment } from 'graphql/graphqlTypes';

const EpisodeAttachments = (props: {
  patientId: number;
  episodeId: number;
  readonly?: boolean;
}) => {
  const { patientId, episodeId, readonly } = props;
  const { data: attachmentData, isFetching } = useData(
    (state: IState) => state.patientDetailsAttachment?.episodeTab[episodeId],
    useLazyGetEpisodeAttachmentsQuery,
    { episodeId },
    (data: GetEpisodeAttachmentsQuery) =>
      commitAttachments({
        patientId,
        episodeId,
        changeSet: { added: data.getEpisodeAttachments as Attachment[] },
      }),
    !episodeId
  );

  return (
    <AttachmentsGrid
      episodeId={episodeId}
      attachments={attachmentData ?? []}
      isFetching={isFetching}
      canAddFiles={!readonly}
      readonly={readonly}
      showInstantSearch={true}
    />
  );
};

export default EpisodeAttachments;
