import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Dialog } from '@mui/material';
import { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { useIsMounted } from 'hooks';
import Snackbar from 'components/snackbar';
import Loader from 'components/loader';
import apiClient from 'util/axiosHttp';
import { LetterPreview, PreviewInstruction } from './preview';
import { FaxDetails, FaxFormData } from './fax';
import { LetterList } from './list';
import { ILetter, ILetterGroup, IMessageState, RightPanel } from '.';
import {
  DictionaryValue,
  LetterModel,
  LetterDialogModel,
  LetterType,
} from 'graphql/graphqlTypes';
import { useGetLetterDialogModelQuery } from 'graphql/hooks/getLetterDialogModel';
import styled from 'styled-components';
import axios, { AxiosError } from 'axios';
import Confirmation from '../../components/modal/Confirmation';

const grayBorder = `1px solid ${COLORS.GREY25}`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    min-height: 99vh;
    min-width: 99vw;
    display: flex;
    flex-direction: row;
    border: ${grayBorder};
  }
`;

const PreviewBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.GREY4,
  borderRight: grayBorder,
});

export type SendFax = {
  onSendFax: (form: FaxFormData | undefined) => void;
};

const mapResponseToModel = (response: LetterDialogModel) => {
  const allLetters =
    response?.letters?.map(
      (letter: LetterModel | undefined | null) =>
        ({
          id: letter?.id ?? 0,
          type: letter?.letterType,
          title: letter?.name ?? '',
          selected: false,
          printShop: false,
          print: false,
          fax: false,
          display: true,
          attachmentErrorCount: 0,
          letterGroup: letter?.letterGroup ?? '',
        } as ILetter)
    ) ?? [];

  const allLetterGroups =
    response?.letterGroups?.map(
      (template: DictionaryValue | undefined | null) =>
        ({
          id: template?.id,
          key: template?.key ?? 0,
          value: template?.value ?? '',
        } as ILetterGroup)
    ) ?? [];

  const isPrintShopActive = response?.printShopIsActive ?? false;
  const faxParameter = response?.faxParameter;
  const patientLanguage = response?.patientLanguage?.name;

  return {
    allLetters,
    isPrintShopActive,
    faxParameter,
    allLetterGroups,
    patientLanguage,
  };
};

const getServerError = async (error: AxiosError) => {
  const response = error.response;
  if (
    response?.status == 500 &&
    response.headers['content-type'].includes('application/json')
  ) {
    if (typeof response.data.text === 'function') {
      const errorBody = JSON.parse(await response.data.text());
      if ('errorMessage' in errorBody) {
        return errorBody.errorMessage;
      }
    } else if ('errorMessage' in response.data) {
      return response.data.errorMessage;
    }
  }
};

export interface ILetterDialogProps {
  patientId: number;
  episodeId: number;
  checklistId: number;
  open: boolean;
  onClose: () => void;
  mode: 'letters' | 'fax';
  attachmentId?: number;
}

export const LetterDialog = ({
  attachmentId,
  patientId,
  episodeId,
  checklistId,
  open,
  mode,
  onClose,
}: ILetterDialogProps) => {
  const { isFetching, data } = useGetLetterDialogModelQuery({ patientId });
  const {
    allLetters,
    isPrintShopActive,
    faxParameter,
    allLetterGroups,
    patientLanguage,
  } = mapResponseToModel(data?.getLetterDialogModel as LetterDialogModel);
  const [letters, setLetters] = useState<ILetter[]>([]);
  const [letterGroups, setLetterGroups] = useState<ILetterGroup[]>([]);

  const isMounted = useIsMounted();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<IMessageState | null>();
  const [preview, setPreview] = useState<string | null>(null);
  const [canFax, setCanFax] = useState(true);
  const [sendFax, setSendFax] = useState<boolean>(false);
  const [faxFormData, setFaxFormData] = useState<FaxFormData>();
  const [faxLabel, setFaxLabel] = useState<boolean>();
  const [view, setView] = useState<'letters' | 'fax'>(mode || 'letters');
  const faxDetailsRef = useRef<SendFax>(null);
  const [actionExecuted, setActionExecuted] = useState(false);
  const [showPreventClose, setShowPreventClose] = useState(false);

  const pdfPreviewRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (!isFetching && isMounted()) {
      setLetters(allLetters);
      setLetterGroups(allLetterGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const [isSelectedView, setIsSelectedView] = useState(true);

  const selectedLetters = letters.filter(({ selected }) => selected);

  const fetchPreview = useCallback(async () => {
    const letters = selectedLetters.map((x) => ({
      id: x.id,
      type: x.type == LetterType.DocumentTemplate ? 0 : 1,
      attachmentPathTemp: x.attachment?.path,
    }));

    setLoading(true);
    try {
      const response = await apiClient.post(
        'Letter/LetterPreview',
        {
          checklistId: 0,
          patientId,
          episodeId,
          letters,
        },
        {
          responseType: 'blob',
        }
      );
      setMessage({
        active: true,
        type: 'success',
        title: 'Preview generated',
        text: `Preview generated with ${selectedLetters.length} templates`,
      });
      setPreview(response.data);
      setIsSelectedView(true);
    } catch (e) {
      setIsSelectedView(false);
      let errorText = 'Could not generate the preview';
      if (axios.isAxiosError(e)) {
        const serverError = await getServerError(e);
        if (serverError) {
          errorText = serverError;
        }
      }
      setMessage({
        active: true,
        type: 'error',
        title: 'Something went wrong',
        text: errorText,
      });
    } finally {
      setLoading(false);
    }
  }, [episodeId, patientId, selectedLetters]);

  const printIframe = () => {
    if (pdfPreviewRef) {
      const iframe = pdfPreviewRef?.current?.contentWindow;
      iframe?.focus();
      iframe?.print();
      return false;
    }
  };

  const showPreview = preview && (view == 'fax' || isSelectedView);

  const onComplete = () => {
    try {
      faxDetailsRef?.current?.onSendFax(faxFormData);
    } catch (e) {
      setMessage({
        active: true,
        type: 'error',
        title: 'Something went wrong',
        text: 'Could not send fax',
      });
    } finally {
      setSendFax(false);
      setIsSelectedView(false);
    }
    const updated = letters.map((letter: ILetter) => ({
      ...letter,
      fax: letter.selected || letter.fax,
    }));

    if (faxLabel) {
      setLetters(updated);
    }

    if (mode == 'letters') {
      setPreview(null);
      setView('letters');
    } else {
      setTimeout(onClose, 60);
    }
  };

  const onPreventClose = () => {
    const canClose = !showPreview || (showPreview && actionExecuted);
    if (canClose) {
      setShowPreventClose(false);
      onClose();
      return;
    }
    setShowPreventClose(true);
  };

  const preventCloseConfirmation = (
    <Confirmation
      open={showPreventClose}
      title="Exit Letters Without Sending?"
      okEvent={onClose}
      okButtonText="Yes"
      cancelEvent={() => setShowPreventClose(false)}
    >
      Are you sure you want to leave without sending/printing/ faxing letters?
    </Confirmation>
  );

  return (
    <>
      <StyledDialog
        data-testid="letter-dialog"
        open={open}
        onClose={onPreventClose}
      >
        <PreviewBox>
          <Loader active={loading || isFetching} />
          {showPreview ? (
            <LetterPreview document={preview} ref={pdfPreviewRef} />
          ) : (
            <PreviewInstruction
              text={
                {
                  fax: 'Refresh preview to view PDF',
                  letters: 'Click preview to generate PDF',
                }[view]
              }
            />
          )}
        </PreviewBox>
        {{
          letters: () => (
            <RightPanel
              title="Letters"
              onClose={onPreventClose}
              onComplete={onPreventClose}
              complete="Done"
              canComplete
            >
              <LetterList
                setLetters={setLetters}
                setLoading={setLoading}
                setMessage={setMessage}
                setView={setView}
                setPreview={setPreview}
                setIsSelectedView={setIsSelectedView}
                setActionExecuted={setActionExecuted}
                letters={letters}
                letterGroups={letterGroups}
                patientId={patientId}
                episodeId={episodeId}
                isPrintShopActive={isPrintShopActive}
                printIframe={printIframe}
                fetchPreview={fetchPreview}
                isSelectedView={isSelectedView}
                faxParameter={faxParameter}
                patientLanguage={patientLanguage}
              />
            </RightPanel>
          ),
          fax: () => (
            <RightPanel
              title="Fax Cover Sheet"
              onClose={onClose}
              onComplete={onComplete}
              canComplete={sendFax}
              complete="Send"
              onBack={() => setView('letters')}
              back={mode === 'letters' ? 'Letters' : undefined}
            >
              <FaxDetails
                mode={mode}
                attachmentId={attachmentId}
                patientId={patientId}
                episodeId={episodeId}
                checklistId={checklistId}
                isSelectedView={isSelectedView && canFax}
                letter={selectedLetters[0]}
                onErrors={() => setCanFax(false)}
                onFilled={() => setCanFax(true)}
                setMessage={setMessage}
                setLoading={setLoading}
                setPreview={setPreview}
                setSendFax={setSendFax}
                setFaxFormData={setFaxFormData}
                ref={faxDetailsRef}
                setFaxLabel={setFaxLabel}
              />
            </RightPanel>
          ),
        }[view]()}
      </StyledDialog>
      {message && (
        <Snackbar
          icon={message.type === 'success' ? ICONS.Checkmark : ICONS.Warning}
          {...message}
          open={open && message?.active}
          onClose={() => setMessage({ ...message, active: false })}
          duration={message.type === 'success' ? 4000 : 'infinite'}
        />
      )}
      {preventCloseConfirmation}
    </>
  );
};
