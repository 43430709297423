import React from 'react';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import { IState } from 'store';
import { Grid, Typography, styled } from '@mui/material';
import { Select } from 'components/select';

const StyledGrid = styled(Grid)({
  alignItems: 'center',
  height: '45px',
  justifyContent: 'space-between',
  rowSpacing: 0,
});

const StyledTypography = styled(Typography)`
  margin-left: 0px;
  margin-top: 6px;
  font-weight: bold;
  font-size: 14px;
`;

interface ISelectItem {
  id: string;
  name: string;
}

export interface UserDashboardGridHeaderProps {
  isDashboardCareSiteRole: boolean;
  dashBoardType: string;
  selectedCareSiteId: string | null;
  handleSelectChange: (event: React.ChangeEvent<{ value: string }>) => void;
}

const UserDashboardGridHeader = ({
  isDashboardCareSiteRole,
  dashBoardType,
  selectedCareSiteId,
  handleSelectChange,
}: UserDashboardGridHeaderProps) => {
  const roles = useSelector((state: IState) => state.roles.items);
  const allCareSites: ISelectItem[] = roles
    .filter((x) => x.careSiteId && x.careSiteId > 0)
    .map((x, index) => {
      if (index === 0) {
        return { id: '0', name: 'All' };
      }
      return { id: x.careSiteId.toString(), name: x.careSiteName };
    });
  const careSites = uniqBy<ISelectItem>(allCareSites, 'id');

  return (
    <StyledGrid container>
      <Grid>
        <StyledTypography>{dashBoardType}</StyledTypography>
      </Grid>
      {isDashboardCareSiteRole && (
        <Grid>
          <Select
            items={careSites}
            value={selectedCareSiteId}
            onChange={handleSelectChange}
            size="small"
            fullWidth
            required
          />
        </Grid>
      )}
    </StyledGrid>
  );
};

export default UserDashboardGridHeader;
