import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { Box } from '@mui/material';
import { ChecklistStorageType } from 'store/actions/types';
import { openLetters } from 'store/ui/modals/letters';
import { openUploadFileFromActionButton } from 'store/fileUpload/fileUploadSlice';
import { InterQualActionButton } from './InterQualActionButton';
import { ProviderSearchActionButton } from './ProviderSearchActionButton';
import {
  ActionButtonComponent,
  BaseComponent,
  ChecklistItemOrderable,
} from 'graphql/graphqlTypes';
import {
  ActionButtonComponentEntity,
  ActionButtonComponentSource,
  ActionButtonComponentTypes,
} from '../types';
import { isSameAsSourceActionButton } from './actionButton.helpers';
import { useGetEpisodeTypeQuery } from 'graphql/hooks/getEpisodeType';
import { getEpisodeTabName } from 'components/home/grid/formatters/EpisodeNumberFormatter';
import { ProviderSameAsSourceActionButton } from './ProviderSameAsSourceActionButton';
import { McgActionButton } from './McgActionButton';

export interface IActionButtonProps {
  component: ActionButtonComponent;
  storageType: ChecklistStorageType;
  actionId?: string;
  item: ChecklistItemOrderable;
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent,
    componentId?: string,
    fieldName?: string | null,
    itemUid?: string
  ) => void;
  isReadOnly: boolean;
  isUpdateActionValueRequestFailed: boolean;
}

const getTabToOpen = (name: string | undefined | null): string =>
  ({
    '': 'Background',
    details: 'Background',
    'patient-summary': 'Background',
    'more-info': 'Background',
    'care-team': 'Care Team',
    'care-plan': 'Care Plan',
    conditions: 'Conditions',
    meds: 'Meds',
    claims: 'Claims',
    history: 'Cases',
    reminders: 'Reminders',
    episodes: 'Episodes',
    'appeal/griev': 'Appeal/Griev',
    'clinic/hospital': 'Clinic/Hospital',
  }[name || ''] ||
  name ||
  '');

const carePlanSubtab = (patientId: number, episodeId: number) => ({
  patientId,
  episodeId,
  activeTabName: 'Cases',
  activeSubTabName: 'CarePlan',
});

const ActionButton = (props: IActionButtonProps) => {
  const {
    component: {
      name,
      type,
      entity,
      actionButtonStyle,
      attributeForSource,
      value,
    },
    storageType,
  } = props;
  const dispatch = useDispatch();

  const patientId = Number(
    useSelector(
      (state: IState) => state.checklist.documentsState[storageType].patientId
    ) ?? 0
  );

  let episodeId = Number(
    useSelector(
      (state: IState) => state.checklist.documentsState[storageType].episodeId
    ) ?? 0
  );

  const completedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );

  if (storageType === ChecklistStorageType.MOZART && episodeId === 0) {
    const checklists = Object.values(completedChecklists);
    const checklist = checklists.filter((x) => x?.episodeId)[0];
    episodeId = checklist?.episodeId ?? 0;
  }

  const { data: episodeType } = useGetEpisodeTypeQuery(
    { episodeId: episodeId },
    { skip: !episodeId }
  );

  const editingChecklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const getAction = () => {
    switch (type) {
      case ActionButtonComponentTypes.PatientDialog:
        if (value === 'care-plan') {
          return openPatientDetailsModal(carePlanSubtab(patientId, episodeId));
        }
        return openPatientDetailsModal({
          patientId,
          episodeId: 0,
          editingChecklistId: editingChecklistId,
          activeTabName: getTabToOpen(value?.toLowerCase()),
          activeSubTabName: '',
        });
      case ActionButtonComponentTypes.EpisodeAttachments:
        return openPatientDetailsModal({
          patientId: patientId,
          episodeId: episodeId,
          editingChecklistId: editingChecklistId,
          activeTabName: getEpisodeTabName(
            episodeType?.getEpisode?.type?.name ?? ''
          ),
          activeSubTabName: 'Attachments',
        });
      case ActionButtonComponentTypes.LetterDialog:
        return openLetters({ patientId, episodeId });
      case ActionButtonComponentTypes.UploadFilesDialog:
        return openUploadFileFromActionButton();
      default:
        return undefined;
    }
  };

  const handleClick = () => {
    const action = getAction();
    if (action) {
      dispatch(action);
    }
  };

  const getDefaultButton = () => (
    <Button
      data-testid="Button"
      color="primary"
      variant={actionButtonStyle === 'button' ? 'contained' : 'text'}
      aria-required={props.component.required}
      onClick={handleClick}
      disabled={props.isReadOnly}
    >
      {name}
    </Button>
  );

  const getButton = function () {
    switch (type) {
      case ActionButtonComponentTypes.LaunchMcg:
        return <McgActionButton {...props} />;
      case ActionButtonComponentTypes.LaunchInterQual:
        return <InterQualActionButton {...props} />;
      case ActionButtonComponentTypes.EntityLookup: {
        const isProviderSearch =
          type === ActionButtonComponentTypes.EntityLookup &&
          entity === ActionButtonComponentEntity.Provider &&
          attributeForSource === ActionButtonComponentSource.Search;
        if (isProviderSearch) {
          return <ProviderSearchActionButton {...props} />;
        }
        if (isSameAsSourceActionButton(props.component)) {
          return <ProviderSameAsSourceActionButton {...props} />;
        }
        return getDefaultButton();
      }
      default:
        return getDefaultButton();
    }
  };

  const button = getButton();

  return <Box display="block">{button}</Box>;
};

export default ActionButton;
