import { IMedicationsTab } from 'store/patientdetails/types';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

export const MedicationAllergies = (props: {
  isSidePanelOpen: boolean;
  medicationsData: IMedicationsTab;
  updateMedicationsInfo: <T>(value: T) => void;
}) => {
  const { isSidePanelOpen, medicationsData, updateMedicationsInfo } = props;
  const [allergyNotes, setAllergyNotes] = useState(
    medicationsData.allergyNotes || ''
  );

  return (
    <Grid item xs={isSidePanelOpen ? 12 : 4}>
      <Box pb="20px">
        <Typography variant="subtitle1">Allergies</Typography>
      </Box>
      <Box pb="16px">
        <FormControlLabel
          control={
            <Checkbox
              checked={medicationsData.allergiesCheck}
              onChange={() =>
                updateMedicationsInfo({
                  allergiesCheck: !medicationsData.allergiesCheck,
                })
              }
              name="allergiesCheck"
              color="primary"
            />
          }
          label="No known drug allergies"
        />
      </Box>
      <Box>
        <TextField
          multiline
          variant="outlined"
          size="small"
          value={allergyNotes}
          fullWidth
          onChange={(e) => setAllergyNotes(e.target.value)}
          onBlur={() => updateMedicationsInfo({ allergyNotes: allergyNotes })}
          placeholder="Other allergies"
          rows={3}
        />
      </Box>
    </Grid>
  );
};
