import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { addMinutes } from 'date-fns';
import axios from 'axios';
import Confirmation from 'components/modal/Confirmation';
import { useSelector } from 'react-redux';
import { IState } from 'store';

const apiClient = axios.create({
  baseURL: process.env.PUBLIC_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'SYM-UI': 'new',
  },
});

const checkVersion = async () => {
  if (!process.env.SYMPHONY_BUILD) {
    return true;
  }

  const response = await apiClient.get(
    `${
      process.env.PUBLIC_URL
    }/symphony-build.json?noCache=${new Date().getTime()}`
  );

  if (!response.data.SYMPHONY_BUILD) {
    return true;
  }

  return process.env.SYMPHONY_BUILD === response.data.SYMPHONY_BUILD;
};

const CHECK_INTERVAL_MINUTES = 10;

const VersionCheckTimer = () => {
  const [newTime, setNewTime] = useState(
    addMinutes(new Date(), CHECK_INTERVAL_MINUTES)
  );
  const [open, setOpen] = useState(false);
  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);

  const handleExpire = useCallback(() => {
    checkVersion().then(
      (result) => {
        const newTime = addMinutes(new Date(), CHECK_INTERVAL_MINUTES);
        setNewTime(newTime);
        if (!result) {
          setOpen(true);
        }
      },
      () => {
        // Ignore errors - could be AWS configuration issue or something else
      }
    );
  }, []);

  useEffect(() => {
    handleExpire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const timerOptions = useMemo(
    () => ({
      expiryTimestamp: newTime,
      onExpire: handleExpire,
      autoStart: false,
    }),
    [newTime, handleExpire]
  );

  const { restart } = useTimer(timerOptions);

  useEffect(() => {
    restart(newTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTime]);

  return (
    <Confirmation
      open={open}
      title="New Version Available"
      okEvent={() => location.reload()}
      cancelEvent={() => setOpen(false)}
      okButtonText="Reload"
      cancelButtonText="Ignore"
    >
      <p>A new version of Symphony is available.</p>
      <p>
        Click Reload to reload the page or reload via browser. If you are
        getting this dialog after reloading then try doing a full reload by
        pressing Ctrl+F5.
      </p>
      <p>
        You may continue to use Symphony, but you might experience unexpected
        errors.
      </p>
    </Confirmation>
  );
};

export default VersionCheckTimer;
