import { IEnvironmentState } from './types';

const getGoogleTrackingCode = (environmentName: string | undefined) => {
  switch (environmentName) {
    case 'Stage':
      return process.env.REACT_APP_GA_TRACKING_CODE_STAGE;
    case 'Test':
      return process.env.REACT_APP_GA_TRACKING_CODE_QA;
    case 'Develop':
      return process.env.REACT_APP_GA_TRACKING_CODE_DEV;
    case 'Demo':
      return process.env.REACT_APP_GA_TRACKING_CODE_DEMO;
    case 'Production':
      return process.env.REACT_APP_GA_TRACKING_CODE_PROD;
    default:
      return undefined;
  }
};

export const initialEnvironmentState = {
  name: process.env.REACT_APP_ENVIRONMENT_NAME,
  trackingCode: getGoogleTrackingCode(process.env.REACT_APP_ENVIRONMENT_NAME),
};

const environmentReducer = (
  state: IEnvironmentState = initialEnvironmentState
): IEnvironmentState => state;
export default environmentReducer;
