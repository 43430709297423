import { Provider, SearchEpisodeRequestInput } from 'graphql/graphqlTypes';

import { Sorting } from '@devexpress/dx-react-grid';
import { formatValidDate } from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.helpers';
import { IEpisodeAdditionalFilters } from './types';

export const getDefaultEpisodeAdditionalFilter =
  (): IEpisodeAdditionalFilters => {
    return {
      dXCode: null,
      cPTCode: null,
      episodeStartDate: null,
      episodeEndDate: null,
    };
  };

export const columnsExtension = [
  { columnName: 'episodeNumber', sortingEnabled: true },
  { columnName: 'faxDateTime', sortingEnabled: true, width: 143 },
  { columnName: 'determinationDate', sortingEnabled: true, width: 170 },
  { columnName: 'diagnosisCode', sortingEnabled: false },
  { columnName: 'procedureCode', sortingEnabled: false },
  { columnName: 'pathway', sortingEnabled: false },
  { columnName: 'decisionby', sortingEnabled: false },
  { columnName: 'parStatus', sortingEnabled: false },
  { columnName: 'authorizationStatus', sortingEnabled: false, width: 140 },
  { columnName: 'owner', sortingEnabled: false },
  { columnName: 'provider', sortingEnabled: true },
];

export const toSearchEpisodeRequestInput = (
  patientId: number,
  searchTerm: string,
  filters: IEpisodeAdditionalFilters,
  pageNumber?: number,
  sorting?: Sorting
): SearchEpisodeRequestInput => {
  const filter = {
    ...filters,
    episodeStartDate: formatValidDate(filters.episodeStartDate),
    episodeEndDate: formatValidDate(filters.episodeEndDate),
  };
  return {
    patientId,
    searchTerm,
    pageNumber: pageNumber ?? 0,
    pageSize: 15,
    orderBy: sorting?.columnName ?? 'FaxDateTime',
    ascDesc: sorting?.direction.toString() ?? 'desc',
    ...filter,
  };
};

export const validateDate = (date: Date | null) => {
  if (!date) {
    return true;
  }
  return date.toDateString() !== 'Invalid Date';
};

export const getProviderInfo = (provider?: Provider | null) => {
  return provider
    ? `${provider.name ?? ''}    
       ${provider.providerId ?? ''}      
       ${provider.nPI ?? ''} `
    : '';
};
