import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { ILetter, ILetterGroup } from '..';
import { Select } from 'components/select';
import styled from 'styled-components';
import { SelectAction } from 'backend/types/selectAction';

const StyledTypography = styled(Typography)`
  margin-bottom: 10px;
`;

const StyledFormControl = styled.div`
  max-width: 300px;
  text-align: left;
  margin-bottom: 30px;
`;

export interface ILetterGroupProps {
  setLetters: (letters: ILetter[]) => void;
  setLetterGroupId: (letterGroupId: string) => void;
  setLetterGroupValue: (letterGroupValue: string) => void;
  letters: ILetter[];
  letterGroups: ILetterGroup[];
  letterGroupId: string;
  letterGroupValue: string;
  letterSearch: string;
}

export const LetterGroup = ({
  setLetters,
  setLetterGroupId,
  setLetterGroupValue,
  letters,
  letterGroups,
  letterSearch,
}: ILetterGroupProps) => {
  const [currentValue, setCurrentValue] = useState('');

  const handleSelectAction = useCallback(
    (itemValue: string) => {
      const letterGroupId = itemValue.split('-')[0];
      const value = itemValue.split('-')[1];

      const allLetters = letters.map((letter: ILetter) => ({
        ...letter,
        display: true,
      }));
      setLetters(allLetters);

      const updated = letters.map((letter: ILetter) => ({
        ...letter,
        selected:
          letter.title
            .toLocaleLowerCase()
            .includes(letterSearch.toLocaleLowerCase()) &&
          value === SelectAction.SelectAll &&
          letter.letterGroup === letterGroupId,
        display:
          (letterGroupId === '' || letter.letterGroup === letterGroupId) &&
          letter.title
            .toLocaleLowerCase()
            .includes(letterSearch.toLocaleLowerCase()),
      }));
      setLetters(updated);
      setLetterGroupId(letterGroupId);
      setLetterGroupValue(itemValue);
    },
    [letters, letterSearch, setLetters, setLetterGroupValue, setLetterGroupId]
  );

  const handleSelectChange = (
    event: React.ChangeEvent<{ name: string | undefined; value: string }>
  ) => {
    const currentValue = event.target.value;
    setCurrentValue(currentValue);
    handleSelectAction(currentValue);
  };

  return (
    <>
      <StyledTypography variant="body1">Letter Type</StyledTypography>
      <StyledFormControl>
        <Select
          data-testid="letter-group-filter"
          value={currentValue}
          onChange={handleSelectChange}
          items={letterGroups.map(({ id, key, value }) => ({
            id: `${id}-${value}`,
            name: key,
          }))}
          size="small"
          fullWidth
        />
      </StyledFormControl>
    </>
  );
};
