import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUploadedFile } from 'store/fileUpload/types';
import { FileUploadState } from './types';

export const initialFileUploadState: FileUploadState = {
  open: false,
  fileUploadFromActionButton: false,
  uploadedFiles: [],
};

const fileUploadSlice = createSlice({
  name: 'FileUpload',
  initialState: initialFileUploadState,
  reducers: {
    openUploadFile: (state) => {
      state.open = true;
    },
    openUploadFileFromActionButton: (state) => {
      state.open = true;
      state.fileUploadFromActionButton = true;
    },
    closeUploadFile: (state) => {
      state.open = false;
      state.fileUploadFromActionButton = false;
      state.uploadedFiles = [];
    },
    setUploadedFiles(
      state: FileUploadState,
      action: PayloadAction<{
        uploadedFiles: IUploadedFile[];
      }>
    ) {
      state.uploadedFiles = action.payload.uploadedFiles;
    },
    resetUploadedFiles: (state) => {
      state.uploadedFiles = [];
    },
  },
});

export const {
  openUploadFile,
  openUploadFileFromActionButton,
  closeUploadFile,
  setUploadedFiles,
  resetUploadedFiles,
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
