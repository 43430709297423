import React from 'react';
import { ISelectBaseProps, ISelectItem, Select } from './Select';
import { useGetLookupValuesQuery } from 'graphql/hooks/getLookups';

export interface ILookupSelectProps extends ISelectBaseProps {
  lookup: string;
  useNameAsId?: boolean;
}

export const LookupSelect = ({
  useNameAsId,
  lookup,
  ...rest
}: ILookupSelectProps) => {
  const { data, isFetching, isError } = useGetLookupValuesQuery({
    name: lookup,
  });

  const items =
    isFetching || isError
      ? []
      : data?.getLookupValues?.map(
          (node) =>
            ({
              id: useNameAsId ? node?.name : node?.id,
              name: node?.name,
            } as ISelectItem)
        ) ?? [];

  return <Select items={items as ISelectItem[]} {...rest} />;
};
