import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';

import {
  updateDirtyTabs,
  updatePatientContacts,
} from 'store/patientdetails/patientDetailsSlice';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { useData } from 'components/gridFormatters';
import {
  AddressDto,
  EmailDto,
  LookupValue,
  PatientContactsViewModel,
  PhoneDto,
} from 'graphql/graphqlTypes';
import {
  GetPatientContactsQuery,
  useLazyGetPatientContactsQuery,
} from 'graphql/hooks/getPatientContacts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const emptyRow = <T>(row: { [p: string]: any }): T => {
  const newRow = Object.assign({}, row);

  const defaultValue = (key: string) => {
    switch (typeof newRow[key]) {
      case 'boolean':
        return false;
      case 'string':
        return '';
      default:
        return undefined;
    }
  };

  Object.keys(newRow)
    .filter((k) => k !== 'type')
    .forEach((key) => {
      return (newRow[key] = defaultValue(key));
    });

  return newRow as T;
};

const updateContactRows = <T extends AddressDto | EmailDto | PhoneDto>(
  changeSet: ChangeSet,
  rows: T[]
): T[] => {
  let changedRows: T[] = [];
  const { added, changed, deleted } = changeSet;
  if (added) {
    changedRows = [...rows, ...added];
  }
  if (changed) {
    changedRows = rows.map((row) =>
      changed[row.type] ? { ...row, ...changed[row.type] } : row
    );
  }

  if (deleted) {
    changedRows = rows.map((row) =>
      deleted.includes(row.type) ? emptyRow(row) : row
    );
  }
  return changedRows;
};

export const useCommitContactsChanges = () => {
  const dispatch = useDispatch();

  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const { data: contactData } = useData(
    (state: IState) => state.patientDetails.contacts,
    useLazyGetPatientContactsQuery,
    { patientId },
    (data: GetPatientContactsQuery) =>
      updatePatientContacts(
        data.getPatientContacts as PatientContactsViewModel
      ),
    !patientId
  );

  if (!contactData) {
    //ToDo Do something about this
    return {
      contactData,
      addressCommitChanges: () => void 0,
      emailCommitChanges: () => void 0,
      phoneCommitChanges: () => void 0,
      languageCommitChanges: () => void 0,
    };
  }

  const addressCommitChanges = (changeSet: ChangeSet) => {
    const addresses = updateContactRows(changeSet, contactData.addresses);
    dispatch(updatePatientContacts({ ...contactData, addresses }));
    dispatch(updateDirtyTabs('Background'));
  };

  const emailCommitChanges = (changeSet: ChangeSet) => {
    const emails = updateContactRows(changeSet, contactData.emails);
    dispatch(updatePatientContacts({ ...contactData, emails }));
    dispatch(updateDirtyTabs('Background'));
  };

  const phoneCommitChanges = (changeSet: ChangeSet) => {
    const phones = updateContactRows(changeSet, contactData.phones);
    dispatch(updatePatientContacts({ ...contactData, phones }));
    dispatch(updateDirtyTabs('Background'));
  };

  const languageCommitChanges = (language: LookupValue) => {
    dispatch(updatePatientContacts({ ...contactData, language }));
    dispatch(updateDirtyTabs('Background'));
  };

  return {
    contactData,
    addressCommitChanges,
    emailCommitChanges,
    phoneCommitChanges,
    languageCommitChanges,
  };
};
