import { useEffect, useState } from 'react';
import { ChecklistStorageType } from 'store/actions/types';
import { IState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import {
  api,
  useLazyGetMozartWorkFlowActionDataQuery,
} from 'graphql/hooks/getMozartWorkFlowActionData';
import { useCreateWorkflowChecklistMutation } from 'graphql/hooks/createWorkflowChecklist';
import { useLazyGetMozartChecklistQuery } from 'graphql/hooks/getMozartChecklist';
import {
  ChecklistViewModel,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import { setGlobalParams } from 'store/actions/checklistSlice';
import {
  addMozartMessage,
  mozartStartPolling,
  mozartStopOotPolling,
  mozartStopPolling,
  mozartUpdateWorkflow,
  setMozartCanContinueWorkflow,
  setMozartGlobalParams,
  setMozartInstanceState,
  setMozartWorkflowEpisodeAuthorizationStatus,
  setWorkflowChecklistId,
  updateCompletedChecklists,
} from 'store/actions/mozartSlice';
import axiosGitHubGraphQL from 'util/axiosHttp';
import { GetChecklistModelDocument } from 'graphql/hooks/getChecklist';
import { useLazyGetMozartWorkflowNavigationInfoQuery } from 'graphql/hooks/getMozartWorkflowNavigationInfo';
import { useLazyGetMozartWorkflowEpisodeAuthorizationStatusQuery } from 'graphql/hooks/getMozartWorkflowEpisodeAuthorizationStatus';
import { usePrevious } from 'hooks';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import {
  createWorkflowChecklistResult,
  isMozartReady,
  isWorkflowCompleted,
  isWorkflowInErrorState,
  isWorkflowInvalid,
  isWorkflowPaused,
  shouldNotCreateNextChecklist,
} from 'features/mozart/MozartWorkflowLayout.helpers';
import { isEqual, uniq } from 'lodash';
import { getMozartWorkflowViewModel } from './MozartWorkflowLayout.helpers';
import { useMozartLoading } from 'features/mozart/useMozartLoading';
import { useTimer } from 'react-timer-hook';
import { errorHandler } from 'store/errorHandler';
import { useNavigate } from 'react-router';
import { setErrorMessage } from 'store/ui/error';
import { ROUTE } from 'consts/ROUTE';
import { useUserNotAuthorizedToStartChecklist } from 'features/mozart/components/MozartContent/MozartContent.helpers';
import { clearFailedRequests } from 'store/failedRequests/failedRequestsSlice';

const MOZART = ChecklistStorageType.MOZART;

export const useGetMozartChecklist = (
  instanceId?: string,
  skip = false,
  isMozartViewOnly?: boolean
) => {
  const [pollingInterval, setPollingInterval] = useState(500);
  const [createChecklistStarted, setCreateChecklistStarted] = useState(false);
  const [mozartDataLoading, setMozartDataLoading] = useState(false);
  const [checklistsDataLoading, setChecklistsDataLoading] = useState(false);
  const [seenMessages, setSeenMessages] = useState<string[]>([]);
  const { isLoading: mozartLoading, isFinishedLoading: mozartFinishedLoading } =
    useMozartLoading();
  const currentUserRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[MOZART].checklist
  );
  const reduxMozartInstanceId = useSelector(
    (state: IState) => state.mozart.mozartInstanceId
  );
  const mozartInstanceId = instanceId ?? reduxMozartInstanceId;

  const shouldStartPolling = useSelector(
    (state: IState) => state.mozart.startPolling
  );
  const shouldStartOotPolling = useSelector(
    (state: IState) => state.mozart.startOotPolling
  );
  const patientId = useSelector(
    (state: IState) => state.checklist.documentsState[MOZART].patientId
  );

  const pollingTimeout = useSelector(
    (state: IState) => state.mozart.pollingTimeout
  );

  const mozartWorkflowMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );

  const scenarioId = useSelector((state: IState) => state.mozart.scenarioId);
  /* F5 scenario is when workflow page is refreshed */
  /* Not F5 scenario is when user comes to action page from Scenario page */
  const isF5scenario = !scenarioId;

  const completedChecklistIds = useSelector(
    (state: IState) => state.mozart.completedChecklistsIds
  );
  const completedAndLoadedChecklistsByIds = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );
  const completedAndLoadedChecklistIds = Object.keys(
    completedAndLoadedChecklistsByIds
  ).map((x) => Number(x));
  const navigate = useNavigate();

  const handleMozartExpiration = () => {
    setPollingInterval(Infinity);
    dispatch(mozartStopPolling());
    dispatch(
      setErrorMessage(
        'The workflow failed to load, please return to the home page and try again'
      )
    );
    setMozartDataLoading(false);
    errorHandler(
      'Workflow polling',
      `Workflow polling timed out, workflowInstanceId: ${mozartInstanceId}, timeout duration: ${pollingTimeout}s`,
      ''
    );
    navigate(ROUTE.Error);
  };

  const { pause, restart, start } = useTimer({
    expiryTimestamp: new Date(Date.now() + pollingTimeout * 1000),
    onExpire: () => handleMozartExpiration(),
    autoStart: false,
  });

  const dispatch = useDispatch();
  const [getMozartWorkflowData, dataStatus] =
    useLazyGetMozartWorkFlowActionDataQuery({
      pollingInterval: pollingInterval,
    });
  const [getMozartChecklist, mozartChecklistStatus] =
    useLazyGetMozartChecklistQuery();

  const [createWorkflowChecklist, createWorkflowChecklistStatus] =
    useCreateWorkflowChecklistMutation();

  const [getMozartWorkflowNavigationInfo, navigationInfo] =
    useLazyGetMozartWorkflowNavigationInfoQuery();

  const [
    getMozartWorkflowEpisodeAuthorizationStatus,
    mozartWorkflowEpisodeAuthorizationStatusData,
  ] = useLazyGetMozartWorkflowEpisodeAuthorizationStatusQuery();

  useEffect(() => {
    if (!skip) {
      setMozartDataLoading(true);
      if (isF5scenario) {
        getMozartChecklist({
          id: mozartInstanceId,
          readOnly: isMozartViewOnly,
        });
      } else {
        getMozartWorkflowData({ id: mozartInstanceId });
        start();
      }
      getMozartWorkflowNavigationInfo({ id: mozartInstanceId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMozartInDoneForUserState = useUserNotAuthorizedToStartChecklist(
    mozartWorkflowMessages[mozartWorkflowMessages.length - 1]
  );

  useEffect(() => {
    const isCompleted = mozartWorkflowMessages.some(isWorkflowCompleted);
    const isPaused = mozartWorkflowMessages.some(isWorkflowPaused);
    const isInErrorState = mozartWorkflowMessages.some(isWorkflowInErrorState);
    if (
      isCompleted ||
      isPaused ||
      isInErrorState ||
      isMozartInDoneForUserState.userNotAuthorizedToStartChecklist
    ) {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mozartWorkflowMessages]);

  useEffect(() => {
    if (navigationInfo?.data) {
      const allChecklistsByIds = {
        ...completedAndLoadedChecklistsByIds,
      };
      if (checklist) {
        allChecklistsByIds[checklist.id] = checklist;
      }
      dispatch(
        mozartUpdateWorkflow({
          workflow: getMozartWorkflowViewModel(
            navigationInfo.data,
            allChecklistsByIds
          ),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist, navigationInfo]);

  const prevCompletedChecklistsIds = usePrevious(completedChecklistIds);
  useEffect(() => {
    const fetchChecklists = async () => {
      const checklistIds = [
        ...new Set(completedChecklistIds.map((item) => Number(item))),
      ];
      const checklistPromises = checklistIds
        .filter((x) => !completedAndLoadedChecklistIds.includes(x))
        .map((checklistId) => {
          return axiosGitHubGraphQL.post('graphql?getChecklistModel', {
            query: GetChecklistModelDocument,
            variables: {
              checklistId: checklistId,
            },
          });
        });
      setChecklistsDataLoading(true);
      await Promise.all(checklistPromises).then((response) => {
        setChecklistsDataLoading(false);
        const checklists = response.map(
          (x) => x.data.data.getChecklist
        ) as ChecklistViewModel[];
        dispatch(updateCompletedChecklists({ checklists }));
        const checklistModel = checklists[0];
        if (!patientId && checklistModel) {
          dispatch(
            setGlobalParams({
              storageType: ChecklistStorageType.MOZART,
              patientId: checklistModel.patientId,
              episodeId: checklistModel.episodeId as number,
            })
          );
        }

        dispatch(clearFailedRequests());
      });
    };
    if (
      !isEqual(completedChecklistIds, prevCompletedChecklistsIds) &&
      completedChecklistIds.length
    ) {
      fetchChecklists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedChecklistIds]);

  useEffect(() => {
    getMozartWorkflowNavigationInfo({ id: mozartInstanceId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedAndLoadedChecklistsByIds]);

  useEffect(() => {
    if (shouldStartPolling) {
      setMozartDataLoading(true);
      getMozartWorkflowData({ id: mozartInstanceId });
      setPollingInterval(500);
      restart(new Date(Date.now() + pollingTimeout * 1000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStartPolling]);

  useEffect(() => {
    if (
      mozartChecklistStatus?.error?.message?.startsWith(
        'UUID cannot parse the given literal'
      ) ||
      mozartChecklistStatus?.data?.getMozartChecklist?.steps?.some(
        isWorkflowInvalid
      )
    ) {
      pause();
      setMozartDataLoading(false);
      setPollingInterval(Infinity);
      navigate(ROUTE.Error);
    }

    if (mozartChecklistStatus.isSuccess) {
      setMozartDataLoading(false);
      pause();
    }

    const mozartChecklist = mozartChecklistStatus?.data?.getMozartChecklist;

    mozartChecklistStatus?.data?.getMozartChecklist &&
      dispatch(
        setMozartGlobalParams({
          pollingTimeout:
            mozartChecklistStatus?.data?.getMozartChecklist?.pollingTimeout ??
            120,
        })
      );
    const mozartReady = isMozartReady(
      mozartChecklistStatus?.data?.getMozartChecklist
        ?.steps as MozartWorkflowUiModel[]
    );

    if (mozartReady) {
      pause();
      setPollingInterval(Infinity);
      setMozartDataLoading(false);
      dispatch(mozartStopOotPolling());
    }

    const id = mozartChecklist?.checklist?.id;
    if (id && id > 0) {
      dispatch(setWorkflowChecklistId(id));
    }
    if (mozartChecklist?.steps && !shouldStartPolling) {
      const steps = mozartChecklist?.steps as MozartWorkflowUiModel[];
      setSeenMessages(steps.map((x) => x.uniqueMsgId));
      dispatch(
        addMozartMessage({
          mozartMessages: steps,
        })
      );

      if (shouldStartOotPolling && !mozartReady) {
        dispatch(mozartStopOotPolling());
        setMozartDataLoading(true);
        getMozartWorkflowData({ id: mozartInstanceId });
        setPollingInterval(500);
        restart(new Date(Date.now() + pollingTimeout * 1000));
      }
    }
    dispatch(
      setMozartInstanceState(mozartChecklist?.mozartWorkflowInstanceState ?? '')
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mozartChecklistStatus]);

  useEffect(
    () => {
      if (mozartWorkflowEpisodeAuthorizationStatusData.isSuccess) {
        const status =
          mozartWorkflowEpisodeAuthorizationStatusData.data
            ?.getMozartWorkflowEpisodeAuthorizationStatus?.authorizationStatus;
        if (status) {
          dispatch(setMozartWorkflowEpisodeAuthorizationStatus(status));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mozartWorkflowEpisodeAuthorizationStatusData.isSuccess,
      mozartWorkflowEpisodeAuthorizationStatusData.requestId,
      mozartWorkflowEpisodeAuthorizationStatusData.data,
    ]
  );

  const handleCreateWorkflowChecklist = (
    result: createWorkflowChecklistResult
  ) => {
    if ('data' in result) {
      const id = result.data.createWorkflowChecklist?.checklistId;
      const shouldRetry = result.data.createWorkflowChecklist?.shouldRetry;
      if (shouldRetry) {
        dispatch(mozartStartPolling());
      } else {
        const errorMessage = result.data.createWorkflowChecklist?.message;
        dispatch(setMozartCanContinueWorkflow(!errorMessage));
        if (errorMessage) {
          dispatch(
            showErrorPopup({
              message: errorMessage,
              title: 'Failed to start Workflow',
            })
          );
        }
        pause();
        setMozartDataLoading(false);
      }
      dispatch(setWorkflowChecklistId(id ?? -1));
      getMozartWorkflowNavigationInfo({ id: mozartInstanceId });
    }
    setCreateChecklistStarted(false);
  };

  const isUnseenMessages = (message: MozartWorkflowUiModel) =>
    !seenMessages.includes(message.uniqueMsgId);

  useEffect(() => {
    const mozartWorkFlowActionData =
      dataStatus?.data?.getMozartWorkFlowActionData;
    if (mozartWorkFlowActionData?.result && mozartWorkFlowActionData.data) {
      const mozartMessages =
        mozartWorkFlowActionData.data as MozartWorkflowUiModel[];
      const unseenMessages = mozartMessages.filter(isUnseenMessages);
      const mozartIsReady = isMozartReady(unseenMessages);

      setSeenMessages(
        uniq([...seenMessages, ...mozartMessages.map((x) => x.uniqueMsgId)])
      );
      dispatch(addMozartMessage({ mozartMessages }));

      if (!mozartIsReady) {
        return;
      }

      dispatch(mozartStopPolling());
      dispatch(
        api.util.updateQueryData(
          'getMozartWorkFlowActionData',
          { id: mozartInstanceId },
          (draftPosts) => {
            draftPosts?.getMozartWorkFlowActionData?.data?.pop();
          }
        )
      );
      setPollingInterval(Infinity);

      if (createChecklistStarted) {
        return;
      }
      if (shouldNotCreateNextChecklist(unseenMessages, currentUserRoleId)) {
        getMozartWorkflowEpisodeAuthorizationStatus({ id: instanceId });
        setMozartDataLoading(false);
        return;
      }

      setCreateChecklistStarted(true);
      dispatch(setMozartWorkflowEpisodeAuthorizationStatus(null));

      createWorkflowChecklist({
        instanceId: mozartInstanceId,
        scenarioId: scenarioId,
      }).then(handleCreateWorkflowChecklist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStatus]);

  const isLoading =
    mozartDataLoading ||
    checklistsDataLoading ||
    createWorkflowChecklistStatus.isLoading ||
    mozartWorkflowEpisodeAuthorizationStatusData.isFetching ||
    mozartLoading;

  const isFinishedLoading =
    !mozartDataLoading &&
    !checklistsDataLoading &&
    !createWorkflowChecklistStatus.isLoading &&
    !mozartWorkflowEpisodeAuthorizationStatusData.isFetching &&
    mozartFinishedLoading;
  return { isLoading, isFinishedLoading };
};
