import { useSelector } from 'react-redux';
import {
  carePlanStoplightsSelector,
  selectCarePlanValidationErrors,
  selectChecklistValidationErrors,
  stoplightsSelector,
} from 'pages/workflow/helperSelectors';
import { ChecklistStorageType } from 'store/actions/types';

export const useShowValidation = (storageType: ChecklistStorageType) => {
  const stoplights = useSelector(stoplightsSelector(storageType));
  const carePlanStopLights = useSelector(carePlanStoplightsSelector);

  const validationErrors = useSelector(
    selectChecklistValidationErrors(storageType)
  );
  const carePlanValidationErrors = useSelector(selectCarePlanValidationErrors);

  const showStoplights = stoplights && stoplights.length > 0;
  const showValidation =
    validationErrors && Object.keys(validationErrors).length > 0;
  const showCarePlanStoplights =
    carePlanStopLights && carePlanStopLights.length > 0;
  const showCarePlanValidation =
    carePlanValidationErrors &&
    Object.keys(carePlanValidationErrors).length > 0;

  return {
    hasValidationRules:
      showStoplights ||
      showValidation ||
      showCarePlanStoplights ||
      showCarePlanValidation,
  };
};
