import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { Typography } from '@mui/material';
import {
  mapStatusToIconColor,
  mapStatusToText,
} from 'util/helpers/iconHelpers';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const EpisodesInProgressFormatter = (
  obj: DataTypeProvider.ValueFormatterProps
) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CustomTooltip
        title={
          obj.row.anyProgress ? 'Document in progress' : 'Document completed'
        }
      >
        <div style={{ paddingRight: '8px' }}>
          {obj.row.anyProgress ? (
            <Icon icon={ICONS.InProgress} size={18} color={COLORS.YELLOW100} />
          ) : (
            <Icon
              icon={ICONS.ProgressCompleted}
              size={18}
              color={COLORS.GREY60}
            />
          )}
        </div>
      </CustomTooltip>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {obj.row.topReminder && (
          <>
            {obj.row.topReminder.daysLeft > 0 ? (
              <>
                <CustomTooltip title="Reminder Completed">
                  <Icon
                    icon={ICONS.Bell}
                    size={16}
                    color={COLORS.COMPLETED_GREEN}
                  />
                </CustomTooltip>
                <Typography style={{ color: COLORS.COMPLETED_GREEN }}>
                  {obj.row.topReminder.daysLeft}
                </Typography>
              </>
            ) : (
              <>
                <CustomTooltip
                  title={`Reminder (${
                    mapStatusToText(
                      obj.row.topReminder.importance.toString()
                    ) ?? ''
                  })`}
                >
                  <Icon
                    icon={ICONS.Bell}
                    size={16}
                    color={mapStatusToIconColor(obj.row.topReminder.importance)}
                  />
                </CustomTooltip>

                <Typography
                  style={{
                    color: mapStatusToIconColor(obj.row.topReminder.importance),
                    paddingTop: 1,
                  }}
                >
                  {`+ ${obj.row.topReminder.daysOverdue}`}
                </Typography>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const EpisodesInProgressProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={EpisodesInProgressFormatter}
    {...props}
  />
);

export default EpisodesInProgressProvider;
