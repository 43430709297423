import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import {
  mapStatusToIconColor,
  mapStatusToText,
} from 'util/helpers/iconHelpers';
import styled from 'styled-components';
import { useReminderClickHandle } from './ReminderOverdueFormatter.helpers';

const StyledLinkWrapper = styled.div`
  cursor: pointer;
  text-align: left;
`;

const ReminderImportanceFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const [reminderClickHandle] = useReminderClickHandle(
    props.row.patientId,
    props.row.episodeId,
    props.row.EpisodeType,
    props.row.id,
    props.row.EpisodeSource
  );

  return (
    <StyledLinkWrapper
      onClick={reminderClickHandle}
      data-cy="home-grid-reminder"
    >
      <StyledLinkWrapper
        onClick={reminderClickHandle}
        data-cy="home-grid-reminder"
      >
        {props.value ? (
          <Icon
            icon={ICONS.Bell}
            size={16}
            title={`Reminder (${
              mapStatusToText(props.value?.toString()) ?? ''
            })`}
            color={mapStatusToIconColor(props.value)}
          />
        ) : null}
      </StyledLinkWrapper>
    </StyledLinkWrapper>
  );
};

export default ReminderImportanceFormatter;
