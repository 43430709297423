import React, { useCallback, useEffect, useState } from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export interface ICustomTooltipProps extends TooltipProps {
  containerElement?: 'div' | 'p' | 'span';
  isHovered?: boolean;
  noMaxWidth?: boolean;
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export const CustomTooltip = ({
  children,
  containerElement,
  isHovered,
  noMaxWidth,
  ...rest
}: ICustomTooltipProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      setRenderTooltip(false);
    }
  }, [isHovered]);

  const childElement = useCallback(() => {
    switch (containerElement) {
      case 'span':
        return <span>{children}</span>;
      case 'p':
        return <p>{children}</p>;
      default:
        return <div>{children}</div>;
    }
  }, [children, containerElement]);

  const specialCase = children && children.props && children.props.children;
  if (specialCase && Object.keys(children.props.children).length == 0) {
    return <></>;
  }

  const tooltip =
    noMaxWidth === true ? (
      <NoMaxWidthTooltip data-testid="tooltip" {...rest}>
        {childElement()}
      </NoMaxWidthTooltip>
    ) : (
      <Tooltip data-testid="tooltip" {...rest}>
        {childElement()}
      </Tooltip>
    );

  return React.createElement(
    containerElement ?? 'div',
    {
      onMouseEnter: () => !renderTooltip && setRenderTooltip(true),
    },
    renderTooltip ? tooltip : children
  );
};
