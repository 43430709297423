import React from 'react';
import FixedText from '../Components/FixedText';
import UserFreeText from '../Components/UserFreeText';
import DateTimeComp from '../Components/DateTime';
import Reminder from '../Components/Reminder';
import RadioCheckBoxButton from '../Components/RadioCheckBoxButton';
import DateComp from '../Components/Date';
import Dropdown from '../Components/Dropdown';
import EntityAttribute from '../Components/EntityAttribute';
import ComputedValue from '../Components/ComputedValue';
import {
  AttributeType,
  BaseComponent,
  CheckboxRadioButtonComponent,
  ComputedValueComponent,
  DateComponent,
  DateTimeComponent,
  DropdownComponent,
  EntityAttributeComponent,
  ExternalUrlComponent,
  FixedTextComponent,
  ReminderComponent,
  UserFreeTextComponent,
} from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';

import styled from 'styled-components';
import { checklistItemComponentWidth } from 'util/helpers/styleHelpers';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import ExternalUrl from 'components/actions/sections/SectionBody/Components/ExternalUrl';

interface IComponentProps {
  component: BaseComponent;
  storageType: ChecklistStorageType;
  checklistId?: string;
  bigTableValues: IEntityLookup[];
  entityLookups: IEntityLookup[];
  attributeTypes: AttributeType[];
  lookups: ILookup[];
}
interface IStyledBaseComponentProps {
  width: string;
  componentColor: string;
}
const StyledBaseComponent = styled.div<IStyledBaseComponentProps>`
  width: ${(props) => props.width};
  color: ${(props) => `${props.componentColor}!important` ?? 'inherit'};
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const Component = (props: IComponentProps) => {
  const {
    component,
    bigTableValues,
    storageType,
    checklistId,
    entityLookups,
    lookups,
    attributeTypes,
  } = props;

  const width = checklistItemComponentWidth(
    component?.width,
    component?.style ?? ''
  );

  switch (component.componentType) {
    case 'FixedTextComponent':
    case 'ExpressionComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          <FixedText component={component as FixedTextComponent} />
        </StyledBaseComponent>
      );
    case 'ReminderComponent':
      return <Reminder component={component as ReminderComponent} />;
    case 'CheckboxRadioButtonComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          <RadioCheckBoxButton
            component={component as CheckboxRadioButtonComponent}
          />
        </StyledBaseComponent>
      );
    case 'UserFreeTextComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          <UserFreeText component={component as UserFreeTextComponent} />{' '}
        </StyledBaseComponent>
      );
    case 'DateComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          <DateComp component={component as DateComponent} />
        </StyledBaseComponent>
      );
    case 'ActionButtonComponent':
      return null;
    case 'DropdownComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          <Dropdown
            component={component as DropdownComponent}
            storageType={storageType}
            entityLookups={entityLookups}
          />
        </StyledBaseComponent>
      );
    case 'DateTimeComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          <DateTimeComp component={component as DateTimeComponent} />
        </StyledBaseComponent>
      );
    case 'EntityAttributeComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          <EntityAttribute
            component={component as EntityAttributeComponent}
            storageType={storageType}
            bigTableValues={bigTableValues}
            entityLookups={entityLookups}
            attributeTypes={attributeTypes}
            lookups={lookups}
            checklistId={checklistId}
          />
        </StyledBaseComponent>
      );
    case 'ComputedComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          {' '}
          <ComputedValue component={component as ComputedValueComponent} />
        </StyledBaseComponent>
      );
    case 'ExternalUrlComponent':
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          <ExternalUrl component={component as ExternalUrlComponent} />
        </StyledBaseComponent>
      );
    default:
      return (
        <StyledBaseComponent
          width={width}
          componentColor={component?.color ?? 'inherit'}
        >
          {component.componentType}
        </StyledBaseComponent>
      );
  }
};
