import React from 'react';
import { useForm } from 'react-hook-form';
import { IAdditionalFilters } from '../types';
import {
  Box,
  Button,
  Grid,
  Paper,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

interface IFilterDialogProps {
  filters: IAdditionalFilters;
  onChangeFilter: (values: IAdditionalFilters) => void;
}

const StyledPaper = styled(Paper)({
  textAlign: 'start',
  marginTop: '5px',
  width: '100%',
  boxShadow: 'none',
  fontSize: '12px',
});

const StyledFilterButton = styled(Button)<{ $activeButton: boolean }>`
  border: solid 1px ${COLORS.LIGHT_GREY};
  border-radius: 4px;
  height: 32px;
  width: 96px;
  color: ${COLORS.GREY100};
  border: 1px solid ${COLORS.SYMPHONY_BLUE};
  background-color: inherit;
  ${({ $activeButton }) =>
    $activeButton &&
    `background-color: ${COLORS.PALE_SKY_BLUE};
     color: ${COLORS.SYMPHONY_BLUE};
      &:enabled: {
        borderRightColor: ${COLORS.SYMPHONY_BLUE}!important,
      };
    `}
`;

const FilterDialog = (props: IFilterDialogProps) => {
  const { filters, onChangeFilter } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { handleSubmit, register, reset } = useForm<IAdditionalFilters>({
    defaultValues: filters,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    reset();
    onChangeFilter({} as IAdditionalFilters);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSubmit = (values: IAdditionalFilters) => {
    onChangeFilter(values);
  };

  const handleApply = () => {
    handleSubmit(onSubmit)();
    setAnchorEl(null);
  };

  return (
    <Box>
      <StyledFilterButton
        data-testid="filter-dialog-button"
        variant="outlined"
        size="small"
        onClick={handleClick}
        startIcon={
          <Icon icon={ICONS.Settings} size="14" color={COLORS.SYMPHONY_BLUE} />
        }
        $activeButton={open}
        data-cy="button-filters"
      >
        <Typography variant="h5" style={{ color: COLORS.SYMPHONY_BLUE }}>
          Filters
        </Typography>
      </StyledFilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            left: '0',
            marginTop: '5px',
            borderRadius: '4px',
            border: '1px solid #CECECE',
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        title={'Filters'}
        style={{ width: 400, padding: '28px' }}
      >
        <div style={{ width: '320px', padding: '28px' }}>
          <Typography
            variant="body2"
            style={{ fontWeight: 700, paddingBottom: '14px' }}
          >
            Filters
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography style={{ fontSize: 12 }}>First Name</Typography>
                <TextField
                  id="firstName"
                  type="text"
                  {...register('firstName')}
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
              <Grid container item xs={6}>
                <Typography style={{ fontSize: 12 }}>Last Name</Typography>
                <TextField
                  id="lastName"
                  type="text"
                  {...register('lastName')}
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography style={{ fontSize: 12 }}>Provider #</Typography>

                <TextField
                  id="providerId"
                  type="text"
                  {...register('providerId')}
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
              <Grid container item xs={6}>
                <StyledPaper>{'NPI'}</StyledPaper>
                <TextField
                  id="nPI"
                  type="text"
                  {...register('nPI')}
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 12 }}>Address</Typography>
                <TextField
                  id="address"
                  type="text"
                  {...register('address')}
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: '20px' }}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={handleClose}
                    variant="text"
                    color="primary"
                    size="small"
                    data-cy="cancel-provider-filters"
                    style={{ marginRight: '14px' }}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleApply}
                    color="primary"
                    size="small"
                    variant="contained"
                    data-cy="ok-provider-filters"
                  >
                    Apply
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </Popover>
    </Box>
  );
};

export default FilterDialog;
