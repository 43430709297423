import React from 'react';
import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';

const CellDetail = (props: TableRowDetail.CellProps) => (
  <td
    {...props}
    style={{
      padding: '0px',
      color: COLORS.GREY100,
      height: '24px',
      maxHeight: '40px',
    }}
    data-cy="table-cell"
  >
    {props.children}
  </td>
);

export default CellDetail;
