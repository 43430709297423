import React, { useState } from 'react';
import {
  Grid,
  PagingPanel,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SelectedRow,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import {
  CustomPaging,
  Grid as GridBase,
  IntegratedSelection,
  PagingState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import { searchPatientColumns, searchPatientColumnsExtension } from './consts';
import { SearchPatientDialogPatientsViewModel } from 'graphql/graphqlTypes';
import PagingContainer from 'components/home/grid/pager';
import styled from 'styled-components';

interface ISearchPatientGridProps {
  selectedPatientId: number;
  gridData: SearchPatientDialogPatientsViewModel[];
  onSelectionChange: (values: Array<number | string>) => void;
}

const StyledGridRoot = styled(Grid.Root)`
  max-height: 240px;
  overflow: auto;
`;

const GridRoot = (props: GridBase.RootProps) => {
  return <StyledGridRoot {...props} />;
};

export const SearchPatientGrid = ({
  selectedPatientId,
  gridData,
  onSelectionChange,
}: ISearchPatientGridProps) => {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const offset = page * pageSize;
  const pagedItems = gridData.slice(offset, offset + pageSize);
  const totalCount = gridData.length;

  return (
    <Grid
      rows={pagedItems}
      columns={searchPatientColumns()}
      getRowId={(item) => item.id}
      rootComponent={GridRoot}
    >
      <SelectionState
        selection={[selectedPatientId]}
        defaultSelection={[0]}
        onSelectionChange={onSelectionChange}
      />
      <IntegratedSelection />
      <PagingState
        currentPage={page}
        onCurrentPageChange={(currPage: number) => setPage(currPage)}
        pageSize={pageSize}
      />
      <CustomPaging totalCount={totalCount} />
      {totalCount > 0 && <PagingPanel containerComponent={PagingContainer} />}
      <SymphonyTable columnExtensions={searchPatientColumnsExtension} />
      <SymphonyTableHeaderRow />
      <TableSelection
        selectByRowClick
        highlightRow
        rowComponent={SelectedRow}
        showSelectionColumn={false}
      />
    </Grid>
  );
};
