import { ChecklistStorageType, IWorkflowAction } from 'store/actions/types';
import {
  isCompletedCategory,
  isVisibleCategory,
} from 'components/actions/navbar/navbar.helpers';
import NavBarLink from 'components/actions/navbar/NavbarLink';
import { ChecklistCategory } from 'graphql/graphqlTypes';
import React from 'react';
import styled from 'styled-components';
import { AccordionDetails } from '@mui/material';

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  margin-bottom: 0 !important;
  width: 100%;
  display: block;
`;

const CollapsableItems = ({
  action,
  actionIndex,
  isReview,
  lastActionIndex,
  storageType,
}: {
  action: IWorkflowAction;
  actionIndex: number;
  isReview?: boolean;
  lastActionIndex?: number;
  storageType: ChecklistStorageType;
}) => {
  return (
    <>
      {action.categories.map((link, categoryIndex) => (
        <StyledAccordionDetails key={link.id}>
          {(isReview ? isCompletedCategory(link) : isVisibleCategory(link)) && (
            <NavBarLink
              key={link.id}
              categoryIndex={categoryIndex}
              category={link as unknown as ChecklistCategory}
              status={
                actionIndex !== lastActionIndex || isReview ? 'done' : undefined
              }
              storageType={storageType}
            />
          )}
        </StyledAccordionDetails>
      ))}
    </>
  );
};

export default CollapsableItems;
