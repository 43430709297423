import React, { ChangeEvent, ReactNode } from 'react';
import { MenuItem, Select as MUISelect, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { styled } from '@mui/styles';
import { Maybe } from 'graphql/graphqlTypes';

export interface ISelectItem {
  id: string;
  name: string;
}

export interface ISelectProps extends ISelectBaseProps {
  items: ISelectItem[];
  width?: string;
}

export interface ISelectBaseProps {
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  className?: string;
  onChange: (
    e: ChangeEvent<{ name: string | undefined; value: string }>
  ) => void;
  value?: Maybe<string>;
  size?: 'medium' | 'small';
  fullWidth?: boolean;
  placeholder?: string;
  renderValue?: (value: string) => ReactNode;
  labelId?: string;
  id?: string;
  color?: string;
  emptyOption?: boolean;
}

// maybe it's better to keep it separate from GridHeaderPanel MUI theme, so we can use it however we want.
const theme = {
  small: {
    width: '136px',
    height: '32px',
  },
  medium: {
    width: '308px',
    height: '54px',
  },
};

const StyledSelect = styled(MUISelect)({
  background: COLORS.WHITE,
  overflow: 'hidden',
  fontSize: '14px',
  minHeight: '32px',
  width: ({ size, fullWidth }: ISelectBaseProps) =>
    fullWidth ? '100%' : theme[size ?? 'medium'].width,
  height: ({ size }: ISelectBaseProps) => theme[size ?? 'medium'].height,
});

export const Select = ({
  value,
  className,
  items,
  required,
  onChange,
  renderValue,
  width,
  color,
  emptyOption,
  ...rest
}: ISelectProps) => {
  return (
    <StyledSelect
      className={className}
      value={value ?? ''}
      variant="outlined"
      defaultValue=""
      required={required}
      size={rest.size}
      style={{ minWidth: width ?? '134px', color: color }}
      renderValue={
        renderValue
          ? (selectedValue) => renderValue(selectedValue as string)
          : undefined
      }
      onChange={(e) =>
        onChange(
          e as unknown as ChangeEvent<{
            name: string | undefined;
            value: string;
          }>
        )
      }
      {...rest}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          style: {
            left: '0',
            marginTop: {
              medium: '14px',
              small: '4px',
            }[rest.size ?? 'medium'],
            borderRadius: '4px',
            border: '1px solid #CECECE',
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
            maxHeight: '400px',
            minWidth: {
              medium: '306px',
              small: '134px',
            }[rest.size ?? 'medium'],
          },
        },
      }}
    >
      {!required && !emptyOption && (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      )}
      {!required && emptyOption && (
        <MenuItem value="-1">
          <em>Empty</em>
        </MenuItem>
      )}
      {items?.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
          style={{
            minWidth: {
              medium: '306px',
              small: '134px',
            }[rest.size ?? 'medium'],
          }}
        >
          <Typography variant="inherit" noWrap>
            {item.name}
          </Typography>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
