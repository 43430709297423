import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { ITimelineChecklistOrderset } from 'components/home/patientdetails/tabscontent/timelineTab/types';
import { Typography } from '@mui/material';
import { TimelineType } from 'components/constants';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const TimelineDetailsFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  let details = '';
  switch (props.row.type) {
    case TimelineType.Checklist:
      details = props.row.orderSets
        .map((os: ITimelineChecklistOrderset) => os.details)
        .join(', ');

      if (props.row.episodeNumber) {
        details = details ? `. ${details}` : details;
        details = `Episode ID: ${props.row.episodeNumber}${details}`;
      }

      break;
    case TimelineType.Reminder:
      details = props.row.reminderDetails;
      break;
    default:
      details = props.row.attachmentDetails;
  }
  details =
    details
      ?.toUpperCase()
      .startsWith(props.row.externalReferenceId?.toUpperCase()) === true
      ? ''
      : details;

  return (
    <CustomTooltip title={details}>
      <Typography
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        noWrap
      >
        {details}
      </Typography>
    </CustomTooltip>
  );
};

export default TimelineDetailsFormatter;
