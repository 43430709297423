import React from 'react';
import {
  ChecklistItemMedicalCodes,
  MedicationCodeType,
  SearchResult,
} from 'graphql/graphqlTypes';
import { Box, Grid } from '@mui/material';
import {
  Grid as TableGrid,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import Grey10HeaderCellComponent from 'components/home/grid/Grey10HeaderCellComponent';
import TableComponent from 'components/actions/sections/SectionBody/Items/MedicationCodes/TableComponent';
import { SymphonyTable } from 'components/gridFormatters';
import styled from 'styled-components';
import PrimaryIconProvider from 'components/home/grid/providers/PrimaryIconProvider';
import { useSelector } from 'react-redux';
import { IState } from 'store';

export interface IChecklistItemMedicationCodesProps {
  item: ChecklistItemMedicalCodes;
}
interface StyledProps {
  isPrinting: boolean;
}
const GridLineRow = styled(Grid)<StyledProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: props.isPrinting ? '5px 0px 5px 12px' : '14px 0px 14px 42px',
}));

const MedicationCodes = (props: IChecklistItemMedicationCodesProps) => {
  const { item } = props;
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isOptimizedView = printDocuments.isOptimizedView;
  const columns =
    item.options.type === MedicationCodeType.Icd_10Cm
      ? [
          {
            name: 'code',
            title: item.options.codeLabel,
          },
          {
            name: 'isPrimary',
            title: 'Primary',
          },
          { name: 'description', title: item.options.descriptionLabel },
        ]
      : [
          {
            name: 'code',
            title: item.options.codeLabel,
          },
          { name: 'description', title: item.options.descriptionLabel },
        ];

  const columnsExtension = [
    {
      columnName: 'code',
      sortingEnabled: false,
      width: 200,
      wordWrapEnabled: true,
    },
    {
      columnName: 'isPrimary',
      sortingEnabled: false,
      width: 100,
      wordWrapEnabled: true,
    },
    {
      columnName: 'description',
      sortingEnabled: false,
      wordWrapEnabled: true,
    },
  ];

  if (!item.value?.length) {
    return null;
  }

  return (
    <Box id={`uuid-${item.uid}`}>
      <Grid container>
        <GridLineRow isPrinting={isOptimizedView} item xs={12}>
          <TableGrid
            columns={columns}
            rows={item.value ?? []}
            getRowId={(row: SearchResult) => Number(row.id)}
          >
            <SimpleTextProvider for={['code']} />
            <PrimaryIconProvider for={['isPrimary']} />
            <SimpleTextProvider for={['description']} />
            <SymphonyTable
              columnExtensions={columnsExtension}
              containerComponent={TableComponent}
            />
            <TableHeaderRow cellComponent={Grey10HeaderCellComponent} />
          </TableGrid>
        </GridLineRow>
      </Grid>
    </Box>
  );
};

export default MedicationCodes;
