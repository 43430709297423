import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import TimelineDescriptionFormatter from '../formatters/TimelineDescriptionFormatter';

const TimelineDescriptionProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={TimelineDescriptionFormatter}
    {...props}
  />
);

export default TimelineDescriptionProvider;
