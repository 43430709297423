import styled, { css } from 'styled-components';

const printStyles = css`
  @media print {
    body {
      margin: 0;
    }

    @page {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }
`;

export const PrintPreviewWrapper = styled.div`
  ${printStyles}
`;
