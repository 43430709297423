import React from 'react';
import { Box, IconButton } from '@mui/material';
import MedicationForm from './MedicationForm';
import ClaimsDetails from './ClaimsDetails';
import Typography from '@mui/material/Typography';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { updateSelectedMedicationItem } from 'store/patientdetails/patientDetailsSlice';

const MedicationsActionColumn = () => {
  const dispatch = useDispatch();
  const medicationsData = useSelector(
    (state: IState) => state.patientDetails.medicationsTab
  );
  const isNew =
    useSelector(
      (state: IState) => state.patientDetails?.medicationsTab?.isNew
    ) ?? false;

  const actionType = medicationsData.selectedActionType;
  const closeActionsColumn = () => {
    dispatch(updateSelectedMedicationItem({ selectedActionType: '', id: [] }));
  };

  const medicationTitle = isNew ? 'Add Medication' : 'Medication';
  return (
    <Box>
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb="28px"
        >
          <Typography variant="subtitle1">
            {actionType === 'form'
              ? medicationTitle
              : 'Medication Claims Details'}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeActionsColumn}
            data-cy="close"
            style={{ padding: '4px' }}
            size="large"
          >
            <Icon icon={ICONS.Close} size={24} color={COLORS.GREY100} />
          </IconButton>
        </Box>
      </Box>
      {actionType === 'form' ? <MedicationForm /> : <ClaimsDetails />}
    </Box>
  );
};

export default MedicationsActionColumn;
