import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientTimelineModelQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPatientTimelineModelQuery = {
  __typename?: 'EntitiesQuery';
  getPatientTimelineModel?: {
    __typename?: 'TimelineTabModel';
    first?: number | null;
    pageSize?: number | null;
    orderBy?: string | null;
    orderDirection?: string | null;
    states?: Array<Types.TimelineState | null> | null;
  } | null;
};

export const GetPatientTimelineModelDocument = `
    query getPatientTimelineModel {
  getPatientTimelineModel {
    first
    pageSize
    orderBy
    orderDirection
    states
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientTimelineModel: build.query<
      GetPatientTimelineModelQuery,
      GetPatientTimelineModelQueryVariables | void
    >({
      query: (variables) => ({
        document: GetPatientTimelineModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientTimelineModelQuery,
  useLazyGetPatientTimelineModelQuery,
} = injectedRtkApi;
