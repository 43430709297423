import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import DetailsSimpleTextFormatter from '../formatters/DetailsSimpleTextFormatter';

const DetailsSimpleTextProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={DetailsSimpleTextFormatter}
    {...props}
  />
);

export default DetailsSimpleTextProvider;
