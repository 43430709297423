import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { EpisodeState } from 'graphql/graphqlTypes';

const EpisodesStateFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  return (
    <>
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            height: '8px',
            width: '8px',
            marginRight: '8px',
            backgroundColor:
              (props.value.state as EpisodeState) === EpisodeState.Opened
                ? COLORS.GREEN100
                : COLORS.GREY60,
          }}
        />
        <Typography variant="body1">
          {(props.value.state as EpisodeState) === EpisodeState.Opened
            ? 'Open'
            : 'Closed'}
        </Typography>
      </div>
    </>
  );
};

export default EpisodesStateFormatter;
