import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Grid as TableGrid,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  CustomPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingPanel,
  PagingState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { PatientClaimDto } from 'graphql/graphqlTypes';
import { useLazySearchClaimsQuery } from 'graphql/hooks/searchClaims';
import { COLORS } from 'consts/styles';
import Loader from 'components/loader';
import SearchBar from 'components/searchbar/SearchBar';
import PagingContainer from 'components/home/grid/pager';
import {
  SelectedRow,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { AssociatedClaimAction } from 'components/actions/sections/SectionBody/Items/AssociatedClaim/AssociatedClaim.helper';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import EpisodesDateProvider from 'components/home/grid/providers/EpisodesDateProvider';
import ClaimFilterButton from './ClaimFilterButton';
import { IClaimAdditionalFilters } from '../types';
import {
  columns,
  columnsExtension,
  getDefaultClaimAdditionalFilter,
  toSearchClaimRequestInput,
} from '../ClaimSearchDialog.helper';

export interface IClaimSearchDialogModel {
  patientId: number;
  onSelect: (selectedClaim: AssociatedClaimAction) => void;
}

const ClaimSearchDialogBody = (props: IClaimSearchDialogModel) => {
  const { patientId, onSelect } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedId, setSelectedId] = useState<number>(0);
  const [sorting, setSorting] = useState<Sorting>();
  const [currentPage, setCurrentPage] = useState<number>();
  const [filters, setFilters] = useState(getDefaultClaimAdditionalFilter());
  const [
    searchClaims,
    { data: searchClaimData, isFetching: isSearchClaimLoading },
  ] = useLazySearchClaimsQuery();

  useEffect(() => {
    searchClaims({
      request: toSearchClaimRequestInput(
        patientId,
        searchTerm,
        filters,
        currentPage,
        sorting
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filters, patientId, currentPage, sorting]);

  const claims = searchClaimData?.searchClaims;
  const claimData = claims?.rows ?? [];
  const totalCount = claims?.totalCount ?? 0;

  const onSelectionChange = (values: Array<number | string>) => {
    if (values.length > 1) {
      const selectedClaimId = Number(values[1]);
      setSelectedId(selectedClaimId);
      const selectedClaim = claimData.find((x) => x.id == selectedClaimId);
      onSelect(selectedClaim as AssociatedClaimAction);
    }
  };

  const onUpdateFilters = (filterValues: IClaimAdditionalFilters) => {
    setFilters(filterValues);
  };

  const handleSetCurrentPage = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const handleSetSorting = (sorting: Sorting[]) => {
    setSorting(sorting[0]);
  };

  return (
    <>
      <Box style={{ backgroundColor: COLORS.PALE_GREY, height: '100%' }}>
        <Paper
          elevation={0}
          style={{ paddingLeft: '8px', paddingRight: '8px' }}
        >
          <Grid container>
            <Grid item xs={5}>
              <SearchBar
                placeholder="Search by Claim ID (at least 3 chars)"
                onSubmit={setSearchTerm}
                style={{ width: '300px' }}
              />
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" justifyContent="flex-end">
                <ClaimFilterButton
                  filters={filters}
                  onChangeFilter={onUpdateFilters}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: '20px' }} />
          </Grid>
          <Box>
            <TableGrid
              rows={claimData}
              columns={columns}
              getRowId={(row: PatientClaimDto) => row.id}
            >
              <SelectionState
                selection={[selectedId]}
                defaultSelection={[]}
                onSelectionChange={onSelectionChange}
              />
              <SortingState
                defaultSorting={[
                  { columnName: 'claimDate', direction: 'desc' },
                ]}
                columnExtensions={columnsExtension}
                onSortingChange={handleSetSorting}
              />
              <PagingState
                currentPage={claims?.pageNumber ?? 0}
                pageSize={claims?.pageSize ?? 15}
                onCurrentPageChange={handleSetCurrentPage}
              />
              <CustomPaging totalCount={totalCount} />
              <IntegratedSelection />
              <IntegratedSorting columnExtensions={columnsExtension} />
              <EpisodesDateProvider
                for={['claimDate', 'serviceFrom', 'serviceTo']}
              />
              <SimpleTextProvider
                for={['claimId', 'diagnosis', 'medProcedure', 'provider']}
              />
              {totalCount > 0 && (
                <PagingPanel containerComponent={PagingContainer} />
              )}
              <SymphonyTable columnExtensions={columnsExtension} />
              <SymphonyTableHeaderRow showSortingControls />
              <TableSelection
                selectByRowClick
                highlightRow
                rowComponent={SelectedRow}
                showSelectionColumn={false}
              />
            </TableGrid>
          </Box>
        </Paper>
      </Box>
      <Loader active={isSearchClaimLoading} />
    </>
  );
};

export default ClaimSearchDialogBody;
