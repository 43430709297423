import Confirmation from '../modal/Confirmation';
import { Button, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import React, { useState } from 'react';

interface IDeleteCommandProps {
  itemName: string;
  onExecute: () => void;
  editEnabled: boolean;
}

export const DeleteCommand = (props: IDeleteCommandProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <>
      <Confirmation
        open={showConfirmation}
        title={`Delete ${props.itemName}?`}
        okButtonText="Delete"
        okEvent={() => {
          setShowConfirmation(false);
          props.onExecute();
        }}
        cancelEvent={() => setShowConfirmation(false)}
      >
        <Typography>{`Are you sure you want to delete this ${props.itemName}?`}</Typography>
      </Confirmation>
      <Button
        onClick={() => setShowConfirmation(true)}
        color="primary"
        disabled={!props.editEnabled}
        style={{ padding: '0px 8px' }}
      >
        <Typography
          variant="body2"
          style={{
            color: props.editEnabled ? COLORS.SYMPHONY_BLUE : undefined,
          }}
        >
          Delete
        </Typography>
      </Button>
    </>
  );
};
