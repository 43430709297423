import React from 'react';
import { ILabelCellProps } from 'components/gridFormatters';
import { useTeamMemberFamilyRelationship } from '../careTeam.hooks';
import { CareTeamLabelCell } from './CareTeamLabelCell';

export const RelationshipCell = (props: ILabelCellProps) => {
  const { row } = props;
  const value = useTeamMemberFamilyRelationship(row);

  return <CareTeamLabelCell {...props} value={value?.name} />;
};
