import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { useDispatch } from 'react-redux';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { IconFallBack } from 'components/IconFallBack';
import { EPISODE_SOURCE_FAX } from '../consts';

const TagsFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch(
      openPatientDetailsModal({
        patientId: Number(
          props.row.patientId ??
            (props.row.EpisodeSource !== EPISODE_SOURCE_FAX
              ? props.row.id
              : undefined)
        ),
        episodeId: 0,
        activeTabName: 'Background',
        activeSubTabName: 'Tags',
      })
    );
  };

  return (
    <div
      data-cy="patient-tags"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {props.value
        ? props.value.map(
            (item: { name: string; imagePath: string }, index: number) => (
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  marginRight: index !== props.value.length - 1 ? '2px' : 0,
                }}
                onClick={handleClick}
                key={`${item}-${index}`}
              >
                <IconFallBack
                  name={item.name}
                  image={item.imagePath}
                  type="Tag"
                  iconSize={22}
                />
              </div>
            )
          )
        : null}
    </div>
  );
};

const TagsProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TagsFormatter} {...props} />
);

export default TagsProvider;
