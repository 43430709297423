import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { Box } from '@mui/material';
import { ROUTE } from 'consts/ROUTE';
import { fixGuid } from 'components/actions/sections/SectionBody/Components/ActionButton/actionButton.helpers';
import { IActionButtonProps } from './ActionButton';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';

export const McgActionButton = ({
  storageType,
  component,
  actionId,
  isReadOnly,
}: IActionButtonProps) => {
  const dispatch = useDispatch();
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const providers = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].providers
  );

  const attendingId = providers['attending']?.id;
  const facilityId = providers['facility']?.id;
  const requestingId = providers['requesting']?.id;

  const handleMcg = () => {
    const showError = (providerType: string) =>
      dispatch(
        showErrorPopup({
          title: 'MCG Error',
          message:
            providerType +
            ' Provider needs to have First Name, Last Name and Provider ID.',
        })
      );

    if (!attendingId) {
      return showError('Attending');
    }
    if (!facilityId) {
      return showError('Facility');
    }
    if (!requestingId) {
      return showError('Requesting');
    }

    const url = `${ROUTE.Mcg}?checklistId=${checklistId}&actionId=${fixGuid(
      actionId
    )}&fieldId=${
      component.id
    }&requestingProviderId=${requestingId}&attendingProviderId=${attendingId}&facilityProviderId=${facilityId}&isNewUI=true`;
    window.open(url, '_blank');
  };

  const buttonVariant =
    component.actionButtonStyle === 'button' ? 'contained' : 'text';
  return (
    <Box display="flex">
      <Button
        data-testid="Button"
        color="primary"
        variant={buttonVariant}
        aria-required={component.required}
        onClick={handleMcg}
        disabled={isReadOnly}
      >
        {component.name}
      </Button>
    </Box>
  );
};
