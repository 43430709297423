import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { resetUploadedFiles } from 'store/fileUpload/fileUploadSlice';
import { clearAttachments } from 'store/patientdetails/patientDetails.attachment.slice';
import { close } from 'store/ui/modals/letters';
import { LetterDialog } from '.';

const LetterDialogController = () => {
  const dispatch = useDispatch();
  const state = useSelector((store: IState) => store.ui.modals.letters);

  const onClose = () => {
    dispatch(close());
    dispatch(clearAttachments());
    dispatch(resetUploadedFiles());
  };

  return (
    <>
      {state.open && (
        <LetterDialog mode={state.view} {...state} onClose={onClose} />
      )}
    </>
  );
};

export default LetterDialogController;
