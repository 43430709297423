import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMultiEditDataQueryVariables = Types.Exact<{
  patientIds?: Types.InputMaybe<
    Array<Types.Scalars['Int']> | Types.Scalars['Int']
  >;
  episodeIds?: Types.InputMaybe<
    Array<Types.Scalars['Int']> | Types.Scalars['Int']
  >;
}>;

export type GetMultiEditDataQuery = {
  __typename?: 'EntitiesQuery';
  getMultiEditData?: {
    __typename?: 'MultiEditModel';
    canChangeOwner?: boolean | null;
    episodeOnly?: boolean | null;
    requiredFields: Array<string>;
    data?: {
      __typename?: 'MultiEditDataModel';
      isActive?: boolean | null;
      note?: string | null;
      providerName?: string | null;
      careStage?:
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
        | null;
      episodeOwner?:
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
        | null;
      patientOwner?:
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
        | null;
      patientSecondaryOwner?:
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
        | null;
      payor?:
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
        | null;
      populations?: Array<
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
        | null
      > | null;
      primaryCareProvider?:
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
        | null;
      status?:
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
        | null;
    } | null;
  } | null;
};

export const GetMultiEditDataDocument = `
    query getMultiEditData($patientIds: [Int!], $episodeIds: [Int!]) {
  getMultiEditData(patientIds: $patientIds, episodeIds: $episodeIds) {
    canChangeOwner
    episodeOnly
    requiredFields
    data {
      careStage {
        id
        name
      }
      episodeOwner {
        id
        name
      }
      isActive
      note
      patientOwner {
        id
        name
      }
      patientSecondaryOwner {
        id
        name
      }
      payor {
        id
        name
      }
      populations {
        id
        name
      }
      primaryCareProvider {
        id
        name
      }
      providerName
      status {
        id
        name
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMultiEditData: build.query<
      GetMultiEditDataQuery,
      GetMultiEditDataQueryVariables | void
    >({
      query: (variables) => ({ document: GetMultiEditDataDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetMultiEditDataQuery, useLazyGetMultiEditDataQuery } =
  injectedRtkApi;
