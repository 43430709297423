import React from 'react';
import { MenuItem, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import Divider from '@mui/material/Divider';
import styled from 'styled-components';
import Icon, { ICONS } from 'components/icon';

const StyledIcon = styled(Icon)`
  margin-top: -2px;
`;

export const getColor = (open: boolean) =>
  open ? COLORS.WHITE : COLORS.BLUE10;

export const getDropdownIcon = (open: boolean) => {
  return (
    <StyledIcon
      size={10}
      icon={open ? ICONS.DropdownUp : ICONS.DropdownDown}
      color={open ? COLORS.BLUE10 : COLORS.WHITE}
    />
  );
};

export const StyledMenuTrigger = styled.div<{
  $menuOpen: boolean;
  $disableHover: boolean;
}>`
  display: flex;
  padding: 6px 8px 6px 8px;
  margin: 0 16px;
  align-items: center;
  ${({ $menuOpen }) =>
    $menuOpen &&
    `background-color: ${COLORS.BLUE115};
       border-radius: 4px;
       color: ${COLORS.SYMPHONY_BLUE};
      `}

  ${({ $disableHover }) =>
    !$disableHover &&
    `  &:hover {
      cursor: pointer;
      background-color: ${COLORS.BLUE115};
      color: ${COLORS.WHITE};
      border-radius: 4px;
      & span {
        color: ${COLORS.WHITE} !important;
      }
    }`}
`;

export const StyledMenuItem = styled(MenuItem)<{
  $active: boolean;
  $paddingLeft: string;
}>(({ $active, $paddingLeft }) => ({
  paddingRight: '18px',
  paddingTop: '6px',
  paddingBottom: '6px',
  paddingLeft: $paddingLeft,
  backgroundColor: $active ? COLORS.GREY10 : 'inherit',
  '& .Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
}));

export const StyledTypography = styled(Typography)<{
  $textColor: string;
}>(({ $textColor }) => ({
  color: $textColor,
  paddingRight: '8px',
}));

export const StyledDivider = styled(Divider)({
  height: '30px',
  backgroundColor: COLORS.WHITE,
  opacity: 0.3,
});
