import moment from 'moment';
import { IValidationResult } from 'util/validationUtils';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import {
  ChecklistItem,
  ChecklistItemPriorAuthCodes,
  CheckPriorAuthorizationResult,
  MedicationCodeCategoryType,
  PriorAuthCodesActionModel,
  PriorAuthCodesActionValue,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import { CheckPriorAuthorizationQuery } from 'graphql/hooks/checkPriorAuthorization';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';

export const useSavePriorAuth = () => {
  const { updateActionValueExtended } = useUpdateActionValue();
  return ({
    value,
    checklistId,
    item,
    autoSave,
    updateChecklistItemInputValueOnSave,
    orderableIndex,
  }: {
    value: PriorAuthCodesActionModel;
    checklistId: string | undefined;
    item: ChecklistItemPriorAuthCodes;
    autoSave: boolean;
    updateChecklistItemInputValueOnSave: (
      item: ChecklistItem,
      index: number,
      data: UpdateActionValueModelInput
    ) => void;
    orderableIndex: number;
  }) => {
    const data = {
      checklistId: Number(checklistId) ?? 0,
      componentId: 'i',
      id: item.uid,
      type: 'string',
      value: JSON.stringify(value),
    };
    if (autoSave) {
      updateActionValueExtended(data, item.uid);
    } else {
      updateChecklistItemInputValueOnSave(item, orderableIndex, data);
    }
  };
};

export const addNewPriorAuthCode = (
  newCode: PriorAuthCodesActionValue,
  paModel: PriorAuthCodesActionModel,
  pAShowCheckPolicy: boolean
): PriorAuthCodesActionModel => {
  const newPAValue: PriorAuthCodesActionValue = {
    id: newCode.id,
    code: newCode.code,
    description: newCode.description,
    isPARequired: pAShowCheckPolicy ? newCode.isPARequired : true,
    externalURL: newCode.externalURL,
    priorAuthRequirement: newCode.priorAuthRequirement,
    primaryMessage: newCode.primaryMessage,
    secondaryMessage: newCode.secondaryMessage,
    isDeleted: false,
    byCodeFound: true,
  };

  const allPAValues = [...paModel.priorAuthCodesActionValues, newPAValue];

  const newModel: PriorAuthCodesActionModel = {
    ...paModel,
    reqStartDate: paModel.reqStartDate,
    reqEndDate: paModel.reqEndDate,
    status: paModel.status,
    priorAuthCodesActionValues: allPAValues,
  };
  return newModel;
};

export const removePriorAuthCode = (
  paModel: PriorAuthCodesActionModel,
  id: number
): PriorAuthCodesActionModel => {
  const newModel: PriorAuthCodesActionModel = {
    ...paModel,
    reqStartDate: paModel.reqStartDate,
    reqEndDate: paModel.reqEndDate,
    status: paModel.status,
    priorAuthCodesActionValues: paModel.priorAuthCodesActionValues.filter(
      (x) => x?.id !== id
    ),
  };
  return newModel;
};

export const onPriorAuthRequestedDatesChange = (
  model: PriorAuthCodesActionModel,
  updatedValue: PriorAuthCodesActionModel
) => {
  const newModel: PriorAuthCodesActionModel = {
    ...model,
    reqStartDate: updatedValue.reqStartDate,
    reqEndDate: updatedValue.reqEndDate,
    status: updatedValue.status,
  };
  return newModel;
};

export const updateSelectedItem = (
  selectedId: number,
  model: PriorAuthCodesActionModel,
  isSelected: boolean
) => {
  const selectedItem = model.priorAuthCodesActionValues.find(
    (x) => x?.id === selectedId
  );

  if (selectedItem) {
    selectedItem.isPARequired = isSelected;
  }
  const newModel: PriorAuthCodesActionModel = {
    ...model,
    priorAuthCodesActionValues: model.priorAuthCodesActionValues,
  };
  return newModel;
};

export const getSelectedItem = (
  model: PriorAuthCodesActionModel,
  pAShowCheckPolicy: boolean
) => {
  return pAShowCheckPolicy
    ? model.priorAuthCodesActionValues
        ?.filter((o) => o?.isPARequired)
        .map((x) => Number(x?.id))
    : model.priorAuthCodesActionValues?.map((x) => Number(x?.id));
};

export const updateModelForNewSelection = (
  selection: (string | number)[],
  model: PriorAuthCodesActionModel
) => {
  const selectedIndices = selection.map(Number);
  let newModel = model;

  const allIndices = model.priorAuthCodesActionValues?.map((x) =>
    Number(x?.id)
  );
  const nonSelectedIndices = allIndices.filter(
    (x) => !selectedIndices.includes(x)
  );
  selectedIndices.forEach((x) => {
    newModel = updateSelectedItem(x, newModel, true);
  });
  nonSelectedIndices.forEach((x) => {
    newModel = updateSelectedItem(x, newModel, false);
  });
  return newModel;
};

export const addorRefreshCodes = (
  actualData: CheckPriorAuthorizationQuery | undefined,
  model: PriorAuthCodesActionModel,
  pAShowCheckPolicy: boolean,
  isOnAddCode: boolean
): PriorAuthCodesActionModel => {
  let newModel = model;
  if (actualData?.checkPriorAuthorization != null) {
    if (isOnAddCode) {
      const loadedOptions = (
        actualData?.checkPriorAuthorization as CheckPriorAuthorizationResult[]
      )[0] as PriorAuthCodesActionValue;
      return addNewPriorAuthCode(loadedOptions, model, pAShowCheckPolicy);
    } else {
      model.priorAuthCodesActionValues.forEach((x) => {
        if (x != null) {
          newModel = removePriorAuthCode(newModel, x.id);
        }
      });
      actualData?.checkPriorAuthorization.forEach((x) => {
        newModel = addNewPriorAuthCode(
          x as PriorAuthCodesActionValue,
          newModel,
          pAShowCheckPolicy
        );
      });
    }
  }
  return newModel;
};

export const getCodeLabel = (codeLabel: string) => {
  return codeLabel ? 'Codes' : codeLabel;
};

export const getDescriptionLabel = (descriptionLabel: string) => {
  return descriptionLabel ? 'Description' : descriptionLabel;
};

export const getColumns = (
  codeLabel: string,
  descriptionLabel: string,
  paShowCheckPolicy: boolean
) => {
  return paShowCheckPolicy
    ? [
        { name: 'isPARequired', title: ' ' },
        { name: 'code', title: codeLabel },
        {
          name: 'description',
          title: descriptionLabel,
        },
        { name: 'priorAuthRequirement', title: 'Auth Policy' },
        { name: 'primaryMessage', title: ' ' },
      ]
    : [
        { name: 'code', title: codeLabel },
        { name: 'description', title: descriptionLabel },
      ];
};

export const isAllowedToAddCodes = (
  allowedCodeCategory: MedicationCodeCategoryType,
  newlyAddedCodeCategory: MedicationCodeCategoryType
): boolean => {
  if (allowedCodeCategory === MedicationCodeCategoryType.AllowAllCodes) {
    return true;
  } else if (allowedCodeCategory === MedicationCodeCategoryType.None) {
    return false;
  }
  return allowedCodeCategory === newlyAddedCodeCategory;
};

export const validatePriorAuthCode = (
  requestedStartDate: Date,
  expirationDate: Date | null
): IValidationResult => {
  if (
    expirationDate &&
    moment(requestedStartDate).toDate() > moment(expirationDate).toDate()
  ) {
    return {
      hasError: true,
      message: `This code was discontinued on ${formatDate(
        expirationDate
      )}, please check your date of service or select a new code.`,
    };
  }
  return { hasError: false };
};
