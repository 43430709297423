import { useReminderStatusAndDays } from '../RemindersGrid.hooks';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Typography } from '@mui/material';

export const DaysCell = (props: ILabelCellProps) => {
  const { daysFormatted } = useReminderStatusAndDays(props.row);

  return (
    <Table.Cell {...props} value={daysFormatted} style={CellComponentStyle}>
      <Typography>
        {Number(daysFormatted) < 1 && props.row.isNew ? 'New' : daysFormatted}
      </Typography>
    </Table.Cell>
  );
};
