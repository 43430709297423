import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeTabDataQueryVariables = Types.Exact<{
  request: Types.PatientEpisodesFilterModelInput;
}>;

export type GetEpisodeTabDataQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeTabData?: {
    __typename?: 'PatientEpisodesWithMozartInfoViewModel';
    readonly: boolean;
    showTatColumn: boolean;
    showCounterColumn: boolean;
    pager?: {
      __typename?: 'PatientEpisodesTablePagerViewModel';
      currentPage?: number | null;
      pagesCount?: number | null;
      pageSize?: number | null;
      totalCount?: number | null;
    } | null;
    episodes?: Array<{
      __typename?: 'EpisodeWithMozartInfoViewModel';
      id?: number | null;
      createdOn?: any | null;
      episodeNumber?: string | null;
      authorizationStartDate?: any | null;
      type?: string | null;
      episodePathway?: string | null;
      episodeServiceLocation?: string | null;
      authorizationCategory?: string | null;
      updatedBy?: string | null;
      anyProgress?: boolean | null;
      state?: Types.EpisodeState | null;
      timeZone?: string | null;
      workFlowInstanceId: string;
      nextStep?: string | null;
      mozartActionEnabled: boolean;
      mozartWorkflowCompleted: boolean;
      incidentRegulatory?: boolean | null;
      medicarePartType?: string | null;
      authorizationVendor?: string | null;
      faxDateTime?: any | null;
      requestedBy?: string | null;
      tat?: {
        __typename?: 'TatClockColumnViewModel';
        state?: Types.ClockState | null;
        dueDateTime?: any | null;
        timeRemaining?: string | null;
      } | null;
      counter?: {
        __typename?: 'CounterClockColumnViewModel';
        state?: Types.ClockState | null;
        startDateTime?: any | null;
        timeElapsed?: string | null;
      } | null;
      topReminder?: {
        __typename?: 'BaseReminder';
        importance?: Types.ReminderImportance | null;
        daysOverdue: number;
        daysLeft: number;
      } | null;
      authStatus?: {
        __typename?: 'LookupValue';
        name?: string | null;
        style?: string | null;
      } | null;
      status?: {
        __typename?: 'LookupValue';
        name?: string | null;
        style?: string | null;
      } | null;
    }> | null;
  } | null;
};

export const GetEpisodeTabDataDocument = `
    query getEpisodeTabData($request: PatientEpisodesFilterModelInput!) {
  getEpisodeTabData(request: $request) {
    readonly
    showTatColumn
    showCounterColumn
    pager {
      currentPage
      pagesCount
      pageSize
      totalCount
    }
    episodes {
      ... on EpisodeWithMozartInfoViewModel {
        id
        createdOn
        episodeNumber
        authorizationStartDate
        tat {
          state
          dueDateTime
          timeRemaining
        }
        counter {
          state
          startDateTime
          timeElapsed
        }
        type
        episodePathway
        episodeServiceLocation
        authorizationCategory
        updatedBy
        anyProgress
        topReminder {
          importance
          daysOverdue
          daysLeft
        }
        state
        timeZone
        workFlowInstanceId
        nextStep
        mozartActionEnabled
        mozartWorkflowCompleted
        authStatus {
          name
          style
        }
        incidentRegulatory
        medicarePartType
        authorizationVendor
        faxDateTime
        status {
          name
          style
        }
        requestedBy
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeTabData: build.query<
      GetEpisodeTabDataQuery,
      GetEpisodeTabDataQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeTabDataDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeTabDataQuery, useLazyGetEpisodeTabDataQuery } =
  injectedRtkApi;
