import { ILabelCellProps, LabelCell } from 'components/gridFormatters';
import React from 'react';
import { familyColumn } from '../family';
import { ConsentToShareCell } from './ConsentToShareCell';
import { RelationshipCell } from './RelationshipCell';
import { CaregiverCell } from './CaregiverCell';
import { CareTeamLabelCell } from './CareTeamLabelCell';

export const FamilyLabelCell = (props: ILabelCellProps) => {
  const { column } = props;

  switch (column.name) {
    case familyColumn.name:
      return <CareTeamLabelCell {...props} />;
    case familyColumn.isCaregiver:
      return <CaregiverCell {...props} />;
    case familyColumn.relationshipId:
      return <RelationshipCell {...props} />;
    case familyColumn.note:
      return <CareTeamLabelCell {...props} />;
    case familyColumn.consentToShare:
      return <ConsentToShareCell {...props} />;
    default:
      return <LabelCell {...props} />;
  }
};
