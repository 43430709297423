import React from 'react';
import { Box, Typography } from '@mui/material';
import { Problem } from 'graphql/graphqlTypes';

interface IProblemListProps {
  problems: Problem[];
}

const ProblemList = (props: IProblemListProps) => {
  const { problems } = props;
  if (!problems || problems.length === 0) {
    return null;
  }

  return (
    <Box>
      <Typography variant="body2">Active Problem List:</Typography>
      <ul style={{ columns: 2, paddingLeft: '18px', paddingRight: '18px' }}>
        {problems.map((problem) => (
          <li key={problem.id}>
            <Typography>{problem?.name}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default ProblemList;
