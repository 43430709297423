import { RadioGroup, FormControlLabel, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import { DuplicateCheckActionValue } from 'graphql/graphqlTypes';
import Icon, { ICONS } from 'components/icon';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { Asterisk } from 'components/text';
import { useSelector } from 'react-redux';
import { IState } from 'store';

export interface IDuplicateCheckConfirmProps {
  state: DuplicateCheckActionValue;
  isReadOnly: boolean;
  onChange: (state: DuplicateCheckActionValue) => void;
  validationDispatch: (
    errorMessage: string,
    error: boolean,
    id?: string
  ) => void;
}
interface StyledWrapperProps {
  $isPrinting: boolean;
  $isReadOnly: boolean;
}
const getMargin = (props: StyledWrapperProps): string => {
  if (props.$isPrinting) {
    return props.$isReadOnly ? '15px -12px 0 -12px' : '15px -14px 0 -32px';
  } else {
    return props.$isReadOnly ? '30px -24px 0 -24px' : '30px -28px 0 -64px';
  }
};
const StyledWrapper = styled.div<StyledWrapperProps>`
  padding: ${(props) =>
    props.$isPrinting ? '5px 5px 5px 10px' : '24px 24px 24px 64px'};
  background-color: ${COLORS.YELLOW20};
  margin: ${(props) => getMargin(props)};
`;

interface IStyledRadioProps {
  $error: boolean;
}

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRadio = styled(Radio)<IStyledRadioProps>(({ $error }) => ({
  color: $error ? COLORS.RED100 : 'inherit',
}));

const DuplicateCheckConfirm = (props: IDuplicateCheckConfirmProps) => {
  const { state, isReadOnly, onChange, validationDispatch } = props;
  const [error, setError] = useState(false);
  const [duplicateConfirmation, setDuplicateConfirmation] = useState(
    state.duplicateFoundConfirmation
  );
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isOptimizedView = printDocuments.isOptimizedView;
  useEffect(() => {
    setError(
      state.duplicateFoundConfirmation === undefined ||
        state.duplicateFoundConfirmation === null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, state.duplicateFoundConfirmation]);
  useEffect(() => {
    const errorMessage =
      'Please select an appropriate option in Duplicate Check section';
    validationDispatch(errorMessage, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  const handleDuplicateConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDuplicateConfirmation(e.target.value);
    const newState: DuplicateCheckActionValue = {
      ...state,
      duplicateFoundConfirmation: e.target.value,
    };
    onChange(newState);
  };

  return (
    <StyledWrapper $isReadOnly={isReadOnly} $isPrinting={isOptimizedView}>
      <FlexBox>
        <Icon
          icon={ICONS.Exclamation_Point}
          size={15}
          style={{ marginTop: '-3px' }}
        />
        <Asterisk color={COLORS.RED100} />
        <Typography variant="h4" style={{ marginLeft: '4px' }}>
          Is it a duplicate?
        </Typography>
      </FlexBox>
      <Typography
        variant="body1"
        style={{ color: COLORS.GREY100, marginLeft: '25px' }}
      >
        These codes and criteria are already selected in another active
        authorization. Please review for possible duplicate.
      </Typography>
      <RadioGroup
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '25px',
          marginTop: '5px',
        }}
        name="DuplicateConfirmation"
        value={duplicateConfirmation}
        onChange={handleDuplicateConfirmChange}
      >
        <Typography variant="body1">
          <FormControlLabel
            disabled={isReadOnly}
            control={
              <StyledRadio
                color="primary"
                data-validationstatus={error ? 'error' : ''}
                $error={error}
              />
            }
            label="Yes, it's a duplicate"
            value="Duplicate"
          />
          <FormControlLabel
            disabled={isReadOnly}
            control={
              <StyledRadio
                color="primary"
                data-validationstatus={error ? 'error' : ''}
                $error={error}
              />
            }
            label="No, it's not a duplicate"
            value="NotDuplicate"
          />
        </Typography>
      </RadioGroup>
    </StyledWrapper>
  );
};
export default DuplicateCheckConfirm;
