export enum reminderColumn {
  status = 'status',
  days = 'days',
  date = 'date',
  reason = 'reason',
  importance = 'importance',
  episode = 'episode',
  episodeNumber = 'episodeNumber',
  owner = 'owner',
  note = 'note',
  actions = 'actions',
}

export const reminderEditingColumnExtensions = [
  { columnName: reminderColumn.status, editingEnabled: false },
  { columnName: reminderColumn.days, editingEnabled: false },
  { columnName: reminderColumn.date, editingEnabled: true },
  { columnName: reminderColumn.reason, editingEnabled: true },
  { columnName: reminderColumn.importance, editingEnabled: true },
  { columnName: reminderColumn.owner, editingEnabled: true },
  { columnName: reminderColumn.note, editingEnabled: true },
  { columnName: reminderColumn.actions, editingEnabled: false },
];
