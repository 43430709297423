import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdateAttachmentsMutationVariables = Types.Exact<{
  attachments: Types.UpdateAttachmentsModelInput;
}>;

export type UpdateAttachmentsMutation = {
  __typename?: 'Mutation';
  updateAttachments?: boolean | null;
};

export const UpdateAttachmentsDocument = `
    mutation updateAttachments($attachments: UpdateAttachmentsModelInput!) {
  updateAttachments(model: $attachments)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateAttachments: build.mutation<
      UpdateAttachmentsMutation,
      UpdateAttachmentsMutationVariables
    >({
      query: (variables) => ({
        document: UpdateAttachmentsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateAttachmentsMutation } = injectedRtkApi;
