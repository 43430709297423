import { ILabelCellProps, LabelCell } from 'components/gridFormatters';
import { COLORS } from 'consts/styles';
import React from 'react';

export const InsuranceEffectiveCell = (props: ILabelCellProps) => {
  const { row } = props;
  const color =
    row.inconsistentDates || row.gapWithPreviousDate
      ? COLORS.RED100
      : COLORS.BLACK;
  return <LabelCell {...props} cellColor={color} />;
};
