import React from 'react';
import Dropdown from '../Dropdown';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import { IComponentProps } from '../types';
import MultiSelectAttribute from './MultiSelectAttribute';
import { LookupRadiobuttonList } from './LookupRadiobuttonList';
import { ReadonlyEntityAttribute } from './ReadonlyEntityAttribute';
import {
  DropdownType,
  EntityAttributeComponent,
  LookupAttributeType,
} from 'graphql/graphqlTypes';

export interface IRadioSelection {
  radioId: string;
  radioValue: number;
}

export interface ILookupAttributeProps
  extends IComponentProps<EntityAttributeComponent> {
  lookups: ILookup[];
  entityLookups: IEntityLookup[];
  attributeType: LookupAttributeType;
  handleSuggestItems: (
    entity: string,
    request: string,
    callback: (arg: ILookupValue[]) => void
  ) => void;
  autoSave: boolean;
  isReadOnly: boolean;
  parentWidth?: number | string;
  isUpdateActionValueRequestFailed: boolean;
}

export enum MultiValueType {
  DropdownList = 'dropdown',
  List = 'list',
}

const LookupAttribute = (props: ILookupAttributeProps) => {
  const {
    component,
    lookups,
    handleChecklistInputChange,
    handleSuggestItems,
    attributeType,
    isActionSelected,
    categoryId,
    storageType,
    autoSave,
    isReadOnly,
    parentWidth,
    isUpdateActionValueRequestFailed,
  } = props;

  const lookup = lookups.find((l) => l.name === attributeType.lookup?.name);

  let selectedValues: ILookupValue[] = [];
  if (component.value) {
    selectedValues = component.value
      .split(',')
      .map((lookupValueId) =>
        lookup?.values.find((lv) => lv.id.toString() === lookupValueId)
      )
      .filter((lv) => lv !== undefined) as ILookupValue[];
  }

  if (component.readonly) {
    return <ReadonlyEntityAttribute selectedValues={selectedValues} />;
  }
  if (component.multiValueType === MultiValueType.List) {
    return (
      <LookupRadiobuttonList
        lookup={lookup}
        selectedValues={selectedValues}
        {...props}
      />
    );
  }

  if (attributeType.multiValues) {
    return (
      <MultiSelectAttribute
        lookup={lookup}
        selectedValues={selectedValues}
        {...props}
      />
    );
  }

  return (
    <Dropdown
      component={{
        componentType: 'DropdownComponent',
        autocomplete: component.autocomplete,
        isBigTable: component.isBigTable,
        color: component.color,
        displayOnlyWhenSelected: component.displayOnlyWhenSelected,
        id: component.id,
        required: component.required,
        style: component.style,
        uniqueID: component.uniqueID,
        userDefinedId: component.userDefinedId,
        value: component.value,
        width: component.width,
        dropDownType: DropdownType.Lookup,
        lookupId: lookup?.id.toString(),
        mirrorGroup: component.mirrorGroup,
        oneWaySrc: component.oneWaySrc,
        linkMessage: component.linkMessage,
      }}
      handleChecklistInputChange={handleChecklistInputChange}
      handleSuggestItems={handleSuggestItems}
      isActionSelected={isActionSelected}
      categoryId={categoryId}
      autoSave={autoSave}
      storageType={storageType}
      isReadOnly={isReadOnly}
      parentWidth={parentWidth}
      isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
      parentLookupId={component.parentLookupComponentId ?? undefined}
    />
  );
};
export default LookupAttribute;
