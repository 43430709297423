import styled from 'styled-components';
import { Box, Grid, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';

export const GridValidationLineRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '0px 28px 0px 64px',
});

export const RequestedDatesBox = styled(Box)({
  padding: '12px 28px 12px 64px',
  backgroundColor: `${COLORS.GREY4}`,
});

export const GridLineRow = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '0px 28px 0px 64px',
  backgroundColor: `${COLORS.WHITE}`,
});

export const RequestedBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  pt: '20px',
  gridGap: '20px',
});

export const InputGroupBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const ErrorBox = styled(Box)({
  display: 'flex',
  alignItems: 'right',
  paddingTop: '10px',
});

export const ErrorText = styled(Typography)({
  color: COLORS.RED100,
});

export const StatusText = styled(Box)({
  display: 'flex',
});
