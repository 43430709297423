import React from 'react';
import { Box, Typography } from '@mui/material';
import { PatientRiskClinicalClassification } from 'graphql/graphqlTypes';
import {
  Chart,
  Legend,
  PieSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { Palette } from '@devexpress/dx-react-chart';
import { COLORS } from 'consts/styles';

type Position = 'left' | 'right' | 'top' | 'bottom';

interface IGridProps<T> {
  title: string;
  rows: T[];
  width: number;
  legendPosition?: Position;
}

const Label = (props: Legend.LabelProps) => (
  <Typography variant="caption" style={{ marginLeft: '10px' }}>
    {props.text}
  </Typography>
);

const ClinicalConditionView = <T extends PatientRiskClinicalClassification>(
  props: IGridProps<T>
) => {
  const { title, rows, width, legendPosition } = props;
  const summ = rows.reduce((sum, current) => sum + current.count, 0);
  const toDisplayRows = rows.map((x) => ({
    label: x.clinicalLabel + ' ' + ((x.count * 100) / summ).toFixed(0) + '%',
    count: x.count,
  }));

  return (
    <Box mb="32px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="16px"
      >
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="16px"
        width={'100%'}
      >
        <Chart data={toDisplayRows} width={width}>
          <Palette
            scheme={[
              COLORS.GREEN100,
              COLORS.ORANGE100,
              COLORS.YELLOW100,
              COLORS.PURPLE,
              COLORS.BLUE,
              COLORS.DARK_GREEN,
              COLORS.BLUE115,
              COLORS.BROWN,
              COLORS.DARK_RED,
            ]}
          />
          <PieSeries valueField="count" name="label" argumentField="label" />
          <Legend labelComponent={Label} position={legendPosition ?? 'right'} />
        </Chart>
      </Box>
    </Box>
  );
};

export default ClinicalConditionView;
