import React from 'react';

import { FixedTextComponent } from 'graphql/graphqlTypes';
import { StyledReviewTypography } from '../styles';
import { COLORS } from 'consts/styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';

export interface IFixedTextProps {
  component: FixedTextComponent;
}

const FixedText = ({ component }: IFixedTextProps) => {
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isOptimizedView = printDocuments.isOptimizedView;
  return (
    <StyledReviewTypography
      variant="body1"
      $color={component?.color ?? COLORS.BLACK}
      $fontWeight={400}
      $breakEnabled={isOptimizedView}
    >
      {component.value}
    </StyledReviewTypography>
  );
};

export default FixedText;
