import { addDays } from 'date-fns';
import { DayType } from 'graphql/graphqlTypes';

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export function formatDate(date: Date): string {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

export function IsHolidayInUnitedStates(date: Date): boolean {
  const fixedHolidays = [
    { month: 1, day: 1, name: "New Year's Day" },
    { month: 6, day: 19, name: 'Juneteenth' },
    { month: 7, day: 4, name: 'Independence Day' },
    { month: 11, day: 11, name: 'Veterans Day' },
    { month: 12, day: 25, name: 'Christmas Day' },
  ];

  for (const holiday of fixedHolidays) {
    const holidayDate = new Date(
      date.getFullYear(),
      holiday.month - 1,
      holiday.day
    );

    // Adjust for holidays falling on a Saturday or Sunday
    if (holidayDate.getDay() === DayOfWeek.Saturday) {
      holidayDate.setDate(holidayDate.getDate() - 1); // Move to Friday
    } else if (holidayDate.getDay() === DayOfWeek.Sunday) {
      holidayDate.setDate(holidayDate.getDate() + 1); // Move to Monday
    }

    if (
      date.getMonth() === holidayDate.getMonth() &&
      date.getDate() === holidayDate.getDate()
    ) {
      return true;
    }
  }

  const floatingHolidays = [
    {
      month: 1,
      day: DayOfWeek.Monday,
      ordinal: 3,
      name: 'Martin Luther King Jr. Day',
    }, // Third Monday in January
    { month: 2, day: DayOfWeek.Monday, ordinal: 3, name: "Presidents' Day" }, // Third Monday in February
    { month: 5, day: DayOfWeek.Monday, ordinal: -1, name: 'Memorial Day' }, // Last Monday in May
    { month: 9, day: DayOfWeek.Monday, ordinal: 1, name: 'Labor Day' }, // First Monday in September
    { month: 10, day: DayOfWeek.Monday, ordinal: 2, name: 'Columbus Day' }, // Second Monday in October
    {
      month: 11,
      day: DayOfWeek.Thursday,
      ordinal: 4,
      name: 'Thanksgiving Day',
    }, // Fourth Thursday in November
  ];

  for (const holiday of floatingHolidays) {
    if (
      date.getMonth() + 1 === holiday.month &&
      CalculateNthDayOfWeek(date, holiday.day, holiday.ordinal)
    ) {
      return true;
    }
  }

  return false;
}

export function getNumericDayOfWeek(targetDayOfWeek: DayOfWeek): number {
  if (typeof targetDayOfWeek === 'number') {
    return targetDayOfWeek;
  } else {
    return parseInt(DayOfWeek[targetDayOfWeek]);
  }
}

export function CalculateNthDayOfWeek(
  date: Date,
  targetDayOfWeek: DayOfWeek,
  n: number
): boolean {
  const numericDayOfWeek = getNumericDayOfWeek(targetDayOfWeek);

  // Calculate the Nth occurrence
  if (n > 0) {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    const daysUntilTargetDay =
      (numericDayOfWeek - firstDayOfMonth.getDay() + 7) % 7;

    // Calculate the first occurrence of the target day in the month
    const firstOccurrence = new Date(firstDayOfMonth);
    firstOccurrence.setDate(firstOccurrence.getDate() + daysUntilTargetDay);

    // Calculate the Nth occurrence by adding (n - 1) weeks to the first occurrence
    const nthOccurrence = new Date(firstOccurrence);
    nthOccurrence.setDate(nthOccurrence.getDate() + (n - 1) * 7);

    return date.getTime() === nthOccurrence.getTime();
  }
  // Calculate the Nth occurrence backwards
  else {
    // Start with the last day of the month
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const daysUntilLastTargetDay =
      (lastDayOfMonth.getDay() - numericDayOfWeek + 7) % 7;

    // Calculate the last occurrence of the target day in the month
    const lastOccurrence = new Date(lastDayOfMonth);
    lastOccurrence.setDate(lastOccurrence.getDate() - daysUntilLastTargetDay);

    // Calculate the Nth occurrence by subtracting (n + 1) weeks from the last occurrence
    const nthOccurrence = new Date(lastOccurrence);
    nthOccurrence.setDate(nthOccurrence.getDate() + (n + 1) * 7);

    return date.getTime() === nthOccurrence.getTime();
  }
}

export function SkipSatSunHolidays(date: Date) {
  let dateAdjusted = new Date(date);
  while (
    dateAdjusted.getDay() === DayOfWeek.Saturday ||
    dateAdjusted.getDay() === DayOfWeek.Sunday ||
    IsHolidayInUnitedStates(dateAdjusted)
  ) {
    dateAdjusted = addDays(dateAdjusted, 1);
  }
  return dateAdjusted;
}

export function CalculateDateDue(
  date: Date,
  dayType: DayType,
  daysForward: number
) {
  if (dayType === DayType.Business) {
    date = SkipSatSunHolidays(date);

    let daysAdded = 0;
    while (daysAdded < daysForward) {
      if (date.getDay() === DayOfWeek.Friday) {
        date = addDays(date, 3);
      } else {
        date = addDays(date, 1);
      }
      daysAdded++;

      date = SkipSatSunHolidays(date);
    }
  } else {
    date = addDays(date, daysForward);
  }

  return date;
}
