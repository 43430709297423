import React from 'react';
import { DataTypeProviderProps } from '@devexpress/dx-react-grid';
import CountLinkFormatter from '../formatters/CountLinkFormatter';
import {
  dashBoardAuthorization,
  dashBoardType,
  getTotalRowValue,
} from '../Dashboard/DashBoardGridHelper';
import { TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@mui/material';

interface TotalCountProviderProps extends DataTypeProviderProps {
  priority: string | null;
  defaultAuthorization: dashBoardAuthorization;
  dashBoardType: dashBoardType;
  searchLinkEnabled: boolean;
}

const TotalCountProvider = ({
  priority,
  defaultAuthorization,
  dashBoardType,
  for: columns,
  searchLinkEnabled,
  ...restProps
}: TotalCountProviderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const summaryItemComponent = ({ value, children }: any) => {
    const { column } = children.props;
    const columnName = column.name;
    const props = { value, column: column };
    if (columns.includes(columnName) && searchLinkEnabled) {
      return (
        <CountLinkFormatter
          priority={priority}
          defaultAuthorization={defaultAuthorization}
          dashBoardType={dashBoardType}
          {...props}
        />
      );
    }
    return (
      <Typography
        variant="body2"
        sx={{ fontWeight: 'bold' }}
        data-testid={`Typography-${value == 0 ? '-' : value}`}
      >
        {getTotalRowValue(value)}
      </Typography>
    );
  };
  return (
    <TableSummaryRow itemComponent={summaryItemComponent} {...restProps} />
  );
};

export default TotalCountProvider;
