/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormControl,
  Paper,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import { LookupValue, Maybe, LanguageBarrierDto } from 'graphql/graphqlTypes';
import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'store';
import { useSelector } from 'react-redux';
import { ILookup } from 'backend/types/lookup';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

const StyledAutoComplete = styled(Autocomplete)`
  height: 32px;
  background-color: ${COLORS.WHITE};
  .MuiAutocomplete-inputRoot {
    padding: 1.5px 0 !important;
    padding-left: 12px !important;
    background-color: ${COLORS.WHITE};
  }
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    border: 1px solid #cecece;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .MuiAutocomplete-listbox {
      max-height: 220px;
    }
  }
  .MuiAutocomplete-option {
    background-color: ${COLORS.WHITE};
    &:hover {
      background-color: ${COLORS.SYMPHONY_BLUE_HOVER};
    }
    :active {
      background-color: ${COLORS.SYMPHONY_BLUE_HOVER};
    }
    [aria-selected='true'] {
      background-color: ${COLORS.SYMPHONY_BLUE_HOVER};
    }
    [data-focus='true'] {
      background-color: ${COLORS.SYMPHONY_BLUE_HOVER};
    }
  }
`;

interface CommunicationNeedsProps {
  language: Maybe<LookupValue> | undefined;
  languageBarriers: Maybe<Array<LanguageBarrierDto>> | undefined;
  languageCommitChanges: (value: LookupValue) => void;
  disabled: boolean;
}

export const CommunicationNeeds = (props: CommunicationNeedsProps) => {
  const { language, languageBarriers, languageCommitChanges, disabled } = props;
  const languages = useSelector(selectLanguages);
  const selectedUser = languages.find((x) => x.id === language?.id);

  const onChange = (_event: ChangeEvent<unknown>, newValue: any) =>
    languageCommitChanges({
      id: newValue?.id,
      name: newValue?.name,
    } as LookupValue);

  const getAlternativeLanguageBarriers = () => {
    if (languageBarriers) {
      const result = languageBarriers
        .filter((x) => x.isEnabled === true)
        .map((x) => x.language)
        .join(', ');

      const trimmedResult = result.endsWith(', ')
        ? result.slice(0, -2)
        : result;

      if (trimmedResult.length > 0) {
        return trimmedResult;
      }

      return 'None';
    }
  };

  const alternativeLanguageBarriers = getAlternativeLanguageBarriers();

  return (
    <Box ml="60px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="16px"
        minHeight="32px"
      >
        <Typography variant="subtitle1">Communication Needs</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography style={{ width: '200px' }}>Preferred Language</Typography>
        <FormControl variant="outlined" style={{ width: '300px' }}>
          <StyledAutoComplete
            options={languages}
            autoComplete
            disableClearable
            fullWidth={true}
            disabled={disabled}
            size="small"
            getOptionLabel={(option: any) => option?.name ?? ''}
            value={selectedUser}
            onChange={onChange}
            PaperComponent={StyledPaper}
            data-testid="language-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                data-testid="language-autocomplete-input"
                size="small"
                fullWidth={true}
              />
            )}
          />
        </FormControl>
      </Box>
      {alternativeLanguageBarriers && (
        <Box display="flex" alignItems="center" mt="12px">
          <Typography style={{ width: '200px' }}>Alternative</Typography>
          <Typography>{alternativeLanguageBarriers}</Typography>
        </Box>
      )}
    </Box>
  );
};

export const selectLanguages = createSelector(
  (state: IState) => state.home?.mainModel?.lookups,
  (lookups: ILookup[]) =>
    (lookups.find((x) => x.name === 'Language')?.values as LookupValue[]) || []
);
