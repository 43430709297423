import { Box, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from 'consts/styles';
import { Asterisk } from 'components/text/Asterisk';
import { DecisionGroupBox } from './styles';

export const DecisionGroup = (props: {
  determinationDecision: string | undefined | null;
  determinationReason: string | undefined | null;
}) => {
  const { determinationDecision, determinationReason } = props;

  return (
    <DecisionGroupBox py="16px">
      <>
        <Typography variant="body1">Decision&nbsp;</Typography>
        <Typography variant="body2">
          {determinationDecision ?? determinationDecision}
        </Typography>
        <Box pt="24px">
          <Box display="flex" flexDirection="row">
            <Asterisk color={COLORS.BLACK} />
            <Typography variant="body1">Reason</Typography>
          </Box>
          <Typography variant="body2">
            {determinationReason ?? determinationReason}
          </Typography>
        </Box>
      </>
    </DecisionGroupBox>
  );
};
