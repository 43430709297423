import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { formatDate } from 'util/helpers/dateTimeHelpers';

const EpisodesDateFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <Typography>{formatDate(props.value)}</Typography>
);

export default EpisodesDateFormatter;
