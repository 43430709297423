import { Patient } from 'graphql/graphqlTypes';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useGetPatientInsuranceQuery } from 'graphql/hooks/getPatientInsurance';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

export interface IProps {
  patient?: Patient;
}

const StyledSpan = styled.span`
  font-weight: 400;
`;

const StyledTypography = styled(Typography)`
  padding-top: 4px;
`;

const InsuranceInformation = (props: IProps) => {
  const { data } = useGetPatientInsuranceQuery(
    {
      patientId: props.patient?.id as number,
    },
    { skip: props.patient == undefined }
  );

  return (
    <>
      <Box px="24px" pt="24px">
        <Typography variant="h4">Insurance</Typography>
      </Box>
      <Box
        mt="20px"
        px="24px"
        py="14px"
        style={{ backgroundColor: COLORS.GREY4 }}
      >
        <Typography variant="body2">
          Eligibility:
          <StyledSpan>
            {data?.getPatientInsurance?.isActive ? 'Active' : 'Inactive'}
          </StyledSpan>
        </Typography>
        <StyledTypography variant="body2">
          {'Effective: '}
          <StyledSpan>
            {data?.getPatientInsurance && data.getPatientInsurance.effective
              ? moment(data?.getPatientInsurance?.effective).format(
                  'MM/DD/yyyy'
                )
              : null}
          </StyledSpan>
        </StyledTypography>
        <StyledTypography variant="body2">
          {'Term: '}
          <StyledSpan>
            {data?.getPatientInsurance && data.getPatientInsurance.terminate
              ? moment(data?.getPatientInsurance?.terminate).format(
                  'MM/DD/yyyy'
                )
              : null}
          </StyledSpan>
        </StyledTypography>
      </Box>
      <Box px="24px" pt="10px">
        <StyledTypography variant="body2">
          Contract:{' '}
          <StyledSpan>{data?.getPatientInsurance?.contract}</StyledSpan>
        </StyledTypography>
        <StyledTypography variant="body2">
          Number: <StyledSpan>{data?.getPatientInsurance?.number}</StyledSpan>{' '}
        </StyledTypography>
        <StyledTypography variant="body2">
          LOB: <StyledSpan>{data?.getPatientInsurance?.lob}</StyledSpan>{' '}
        </StyledTypography>
        <StyledTypography variant="body2">
          Group: <StyledSpan>{data?.getPatientInsurance?.group}</StyledSpan>{' '}
        </StyledTypography>
      </Box>

      {props.patient?.owner?.name ? (
        <Box
          px="24px"
          py="14px"
          mt="12px"
          style={{ backgroundColor: COLORS.GREY4 }}
        >
          <Typography variant="body2">
            Patient Owner:{' '}
            <StyledSpan data-testid="patient-owner">
              {props.patient?.owner?.name}
            </StyledSpan>
          </Typography>
        </Box>
      ) : null}
    </>
  );
};
export default InsuranceInformation;
