import { ISelectItem } from 'components/select';
import {
  AssociatedEpisodeActionValue,
  MedicationCode,
  Provider,
} from 'graphql/graphqlTypes';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';

export const yes = 'Yes';
export const no = 'No';
export interface IAssociatedEpisodeAttributes {
  name: string;
  value: string;
}

export enum AssociatedEpisodeAttributeNames {
  episodeNumber = 'episodeNumber',
  episodeId = 'episodeId',
  determinationDateTime = 'determinationDateTime',
  receivedDateTime = 'receivedDateTime',
  episodeDateTime = 'episodeDateTime',
  serviceCode = 'serviceCode',
  diagnosisCode = 'diagnosisCode',
  provider = 'provider',
  pathway = 'Pathway',
  authorizationStatus = 'authorizationStatus',
  nPI = 'nPI',
  patientId = 'patientId',
  parStatus = 'parStatus',
  decisionBy = 'DecisionBy',
  owner = 'owner',
  requestingClinicianPar = 'RequestingClinicianPar',
  authLookup = 'AuthorizedStatus',
}

export const associatedEpisodeAttributeHelper = [
  {
    name: AssociatedEpisodeAttributeNames.episodeNumber,
    value: AssociatedEpisodeAttributeNames.episodeNumber,
  },
  {
    name: AssociatedEpisodeAttributeNames.receivedDateTime,
    value: AssociatedEpisodeAttributeNames.receivedDateTime,
  },
  {
    name: AssociatedEpisodeAttributeNames.pathway,
    value: AssociatedEpisodeAttributeNames.pathway,
  },
  {
    name: AssociatedEpisodeAttributeNames.serviceCode,
    value: AssociatedEpisodeAttributeNames.serviceCode,
  },
  {
    name: AssociatedEpisodeAttributeNames.diagnosisCode,
    value: AssociatedEpisodeAttributeNames.diagnosisCode,
  },
  {
    name: AssociatedEpisodeAttributeNames.determinationDateTime,
    value: AssociatedEpisodeAttributeNames.determinationDateTime,
  },
  {
    name: AssociatedEpisodeAttributeNames.authorizationStatus,
    value: AssociatedEpisodeAttributeNames.authorizationStatus,
  },
  {
    name: AssociatedEpisodeAttributeNames.provider,
    value: AssociatedEpisodeAttributeNames.provider,
  },
  {
    name: AssociatedEpisodeAttributeNames.nPI,
    value: AssociatedEpisodeAttributeNames.nPI,
  },
  {
    name: AssociatedEpisodeAttributeNames.parStatus,
    value: AssociatedEpisodeAttributeNames.parStatus,
  },
  {
    name: AssociatedEpisodeAttributeNames.decisionBy,
    value: AssociatedEpisodeAttributeNames.decisionBy,
  },
  {
    name: AssociatedEpisodeAttributeNames.owner,
    value: AssociatedEpisodeAttributeNames.owner,
  },
];

export const getProviderDetails = (provider?: Provider | null) => {
  const name =
    provider && provider?.name
      ? provider?.name
      : `${provider?.firstName ?? ''} ${provider?.lastName ?? ''}`;
  return provider ? `${name ?? ''} ${provider.providerId ?? ''}` : '';
};

export const getStringCodeToMedicationCode = (code: string) => {
  if (code) {
    const codeArray = code.split(',');
    const medicationCode = codeArray.map(
      (value, index) =>
        ({ id: index + 1, code: value?.trim() } as MedicationCode)
    );
    return medicationCode ? medicationCode : [];
  }
  return [];
};

export const convertParStatusValue = (parStatus: string) => {
  return parStatus && parStatus.toLowerCase() === 'true' ? true : false;
};

export const firstCharCapitalize = (str: string) => {
  const strLabel = str.charAt(0).toUpperCase() + str.slice(1);
  const strArray = strLabel.match(/[A-Z][a-z]+/g);
  return strArray?.join(' ');
};

export const getEpisodeValue = (
  actionValue: AssociatedEpisodeActionValue,
  attributeName: string,
  userLookup: ISelectItem[],
  authLookup: ISelectItem[],
  pathWayLookup: ISelectItem[]
) => {
  const owner = userLookup?.find(
    (x) => x.id == actionValue.ownerId?.toString()
  )?.name;

  const decisionBy = userLookup?.find(
    (x) => x.id == actionValue.decisionById?.toString()
  )?.name;

  const parStatus = actionValue?.requestingClinicianPar ? yes : no;

  const provider = actionValue.provider;

  const authStatus = authLookup?.find(
    (x) => x.id == actionValue.authorizationStatus?.toString()
  )?.name;

  const pathWay = pathWayLookup?.find(
    (x) => x.id == actionValue.episodePathway?.toString()
  )?.name;

  switch (attributeName) {
    case AssociatedEpisodeAttributeNames.episodeId:
      return actionValue.id;
    case AssociatedEpisodeAttributeNames.episodeDateTime:
      return formatDateTime(actionValue.episodeDateTime);
    case AssociatedEpisodeAttributeNames.determinationDateTime:
      return formatDateTime(actionValue.determinationDateTime as Date);
    case AssociatedEpisodeAttributeNames.receivedDateTime:
      return formatDateTime(actionValue.receivedDateTime as Date);
    case AssociatedEpisodeAttributeNames.owner:
      return owner;
    case AssociatedEpisodeAttributeNames.parStatus:
      return parStatus;
    case AssociatedEpisodeAttributeNames.pathway:
      return pathWay;
    case AssociatedEpisodeAttributeNames.decisionBy:
      return decisionBy;
    case AssociatedEpisodeAttributeNames.episodeNumber:
      return actionValue.episodeId;
    case AssociatedEpisodeAttributeNames.provider:
      return getProviderDetails(provider);

    case AssociatedEpisodeAttributeNames.serviceCode:
      return actionValue.procedureCode;
    case AssociatedEpisodeAttributeNames.diagnosisCode:
      return actionValue.diagnosisCode;
    case AssociatedEpisodeAttributeNames.authorizationStatus:
      return authStatus;
    case AssociatedEpisodeAttributeNames.nPI:
      return provider?.nPI;
    default:
      return '';
  }
};
