import 'regenerator-runtime/runtime';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SymphonyTable } from 'components/gridFormatters';
import {
  Grid,
  TableHeaderRow,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { IState } from 'store';
import styled from 'styled-components';
import { useGetAuthTurnAroundTimeQuery } from 'graphql/hooks/getAuthTurnAroundTime';
import {
  dashBoardAuthorization,
  dashBoardDataColumnNames,
  dashBoardDataColumnsExtensionStyles,
  dashBoardGridColumns,
  dashBoardGridPriority,
  dashBoardType,
  getTotalRowValue,
  totalTatSummaryItems,
} from './DashBoardGridHelper';
import Loader from 'components/loader';
import { AuthTurnAroundTimeModel } from 'graphql/graphqlTypes';
import DashBoardHeaderCell from './DashBoardHeaderCell';
import { IUserPreferences } from 'backend/types/userPreferences';
import DashValueProvider from './DashValueProvider';
import CountLinkProvider from '../providers/CountLinkProvider';
import { DashboardCareSiteRole } from 'consts/roles';
import { TitleComponent } from 'components/gridFormatters/TitleComponent';
import TooltipProvider from '../providers/TooltipProvider';
import { SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';

const StyledGrid = styled(Grid)({
  marginTop: '20px',
  marginBottom: '80px',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const summaryItem = ({ value }: any) => {
  return (
    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
      {getTotalRowValue(value)}
    </Typography>
  );
};

const DashBoardGrid = ({
  priority,
  defaultAuthorization,
  priorityBy,
  userPreferences,
  refreshDashboard,
}: {
  isLoadingCompleted: boolean;
  priority: string;
  headerName: string;
  defaultAuthorization: string;
  priorityBy: string;
  userPreferences: IUserPreferences;
  refreshDashboard: number;
}) => {
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const isDashboardAccess = currentRoleId == DashboardCareSiteRole.roleId;
  const gridColumnNames = dashBoardGridColumns
    .filter((column) => {
      return column.name !== dashBoardDataColumnNames.careSiteName;
    })
    .map((i) => i.name);
  const defaultAuth =
    priority === dashBoardGridPriority.standard
      ? userPreferences?.standardAuthorization
      : userPreferences?.expeditedAuthorization ?? defaultAuthorization;
  const userId = useSelector((state: IState) => state.user.currentUser.id);
  const {
    data: standardData,
    refetch: refetchStandardData,
    isFetching: standardFetching,
  } = useGetAuthTurnAroundTimeQuery(
    {
      userId: userId,
      careSiteId: currentCareSiteId,
      priority: dashBoardGridPriority.standard,
      defaultAuthorization:
        priorityBy == dashBoardGridPriority.standard
          ? defaultAuthorization
          : userPreferences?.standardAuthorization,
    },
    {
      refetchOnMountOrArgChange: false,
    }
  );
  const {
    data: expeditedData,
    refetch: refetchExpeditedData,
    isFetching: expeditedFetching,
  } = useGetAuthTurnAroundTimeQuery(
    {
      userId: userId,
      careSiteId: currentCareSiteId,
      priority: dashBoardGridPriority.expedited,
      defaultAuthorization:
        priorityBy == dashBoardGridPriority.expedited
          ? defaultAuthorization
          : userPreferences.expeditedAuthorization,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if ((refreshDashboard ?? 0) > 0) {
      priority == dashBoardGridPriority.standard
        ? refetchStandardData()
        : refetchExpeditedData();
    }
  }, [priority, refetchExpeditedData, refetchStandardData, refreshDashboard]);

  if (standardFetching || expeditedFetching) {
    return <Loader active={true} />;
  }

  const dashBoardData = (
    priority == dashBoardGridPriority.standard
      ? standardData?.getAuthTurnAroundTime
      : expeditedData?.getAuthTurnAroundTime
  ) as AuthTurnAroundTimeModel[];

  const showTotalSummary = dashBoardData.length > 1;

  return (
    <StyledGrid
      data-testid="dashboard-grid"
      rows={dashBoardData}
      columns={dashBoardGridColumns}
      getRowId={(row) => row.careSiteId}
    >
      {showTotalSummary && <SummaryState totalItems={totalTatSummaryItems} />}
      <TooltipProvider for={[dashBoardDataColumnNames.careSiteName]} />
      {isDashboardAccess && <DashValueProvider for={gridColumnNames} />}
      {!isDashboardAccess && (
        <CountLinkProvider
          for={gridColumnNames}
          priority={priority}
          defaultAuthorization={defaultAuth as dashBoardAuthorization}
          dashBoardType={dashBoardType.umRnTatDashboard}
        />
      )}
      <SymphonyTable columnExtensions={dashBoardDataColumnsExtensionStyles} />
      <TableHeaderRow
        cellComponent={DashBoardHeaderCell}
        titleComponent={TitleComponent}
      />
      {showTotalSummary && <IntegratedSummary />}
      {showTotalSummary && <TableSummaryRow itemComponent={summaryItem} />}
    </StyledGrid>
  );
};

export default DashBoardGrid;
