import {
  IColumn,
  IFilterValue,
  IHomeStateModel,
  SearchTab,
} from 'store/home/types';
import { IColumnType } from 'backend/types/grid/IColumnType';
import { ILookupValue } from 'backend/types/lookupValue';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { ColumnWidthModelInput } from 'graphql/graphqlTypes';

export const getTableColumnsExtensions = (
  columns: IColumn[],
  showProgress: boolean
) => {
  return columns
    ? showProgress
      ? columns
          .map((column) => ({
            columnName: String(column.fullPropertyName),
            wordWrapEnabled: false,
          }))
          .concat({
            columnName: 'IsCompleted',
            wordWrapEnabled: false,
          })
      : columns.map((column) => ({
          columnName: String(column.fullPropertyName),
          wordWrapEnabled: false,
        }))
    : [];
};

export const getSortingColumnExtensions = (
  columns: IColumn[],
  showProgress: boolean
) => {
  return columns
    ? showProgress
      ? columns
          .map((column) => ({
            columnName: String(column.fullPropertyName),
            sortingEnabled: Boolean(column.canSort),
          }))
          .concat({
            columnName: 'IsCompleted',
            sortingEnabled: false,
          })
      : columns.map((column) => ({
          columnName: String(column.fullPropertyName),
          sortingEnabled: Boolean(column.canSort),
        }))
    : [];
};

export const getColumnsExtensions = (
  columns: IColumn[],
  showProgress: boolean
) =>
  columns
    ? [
        ...columns.map((column) => ({
          columnName: String(column.fullPropertyName),
          filteringEnabled: Boolean(column.hasFilter),
        })),
        ...(showProgress
          ? [
              {
                columnName: 'IsCompleted',
                filteringEnabled: false,
              },
            ]
          : []),
      ]
    : [];
export const getColumns = (
  columns: IColumn[],
  filterValues: IFilterValue[]
) => {
  const mapColumns = (column: IColumn): IColumnType => ({
    name: column.fullPropertyName,
    title: column.displayName,
    multiValues: column.filter?.multiValues ?? false,
    filterType: column.entityAttributeDef?.attributeType?.type ?? '',
    filterValues:
      filterValues?.find((x) => x.name === column.fullPropertyName)?.values ??
      ([] as ILookupValue[]),
  });

  return (columns ?? []).map(mapColumns);
};

export const getNoDataMessage = (homeModel: IHomeStateModel) => {
  const noSearchResultsFound = hasUserPrivilege(Privilege.AllowAnyPHISearch)
    ? 'No results found'
    : 'No results found. Make sure to search by the entire Member ID or Episode ID. Partial searches will not find any results.';
  return homeModel?.activeTab === SearchTab ? noSearchResultsFound : 'No data';
};

export const getUpdatedColumnWidths = (
  originalWidths: ColumnWidthModelInput[],
  changedWidths: TableColumnWidthInfo[],
  tabIndex: number
): {
  modifiedWidth?: ColumnWidthModelInput;
  width: ColumnWidthModelInput[];
} => {
  const originalColumnInfo = (input: ColumnWidthModelInput) =>
    originalWidths.find(
      (x) => x.columnName === input.columnName && x.tabIndex === input.tabIndex
    );

  const width = changedWidths.map<ColumnWidthModelInput>((x) => ({
    columnName: x.columnName,
    width: x.width as number,
    tabIndex: tabIndex,
  }));

  const modifiedWidth = width.find(
    (x) => originalColumnInfo(x)?.width !== x.width
  );
  return { width, modifiedWidth };
};
