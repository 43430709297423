import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { Button, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

export interface AttachFilesDialogActionsProps {
  handleClose: () => void;
  handleAttachFiles: () => void;
}

const StyledWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const StyledTypography = styled(Typography)({
  color: COLORS.SYMPHONY_BLUE,
});

const StyledWhiteTypography = styled(Typography)({
  color: COLORS.WHITE,
});

const StyledButton = styled(Button)({
  minWidth: '121px',
  height: '40px',
});

export const AttachFilesDialogActions = (
  props: AttachFilesDialogActionsProps
) => {
  const { handleClose, handleAttachFiles } = props;

  const selectedAttachmentIds = useSelector(
    (state: IState) =>
      state.patientDetailsAttachment?.selectedAttachmentIds ?? []
  );
  const uploadedFiles = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  );
  const attachmentCount = selectedAttachmentIds.length + uploadedFiles.length;

  return (
    <>
      <StyledWrapper>
        {!!attachmentCount && (
          <Typography variant="body1">
            {attachmentCount} files selected
          </Typography>
        )}
      </StyledWrapper>
      <StyledWrapper>
        <StyledButton
          onClick={handleClose}
          data-testid="attach-dialog-cancel"
          color="primary"
          variant="outlined"
          size="large"
        >
          <StyledTypography variant="h5">Cancel</StyledTypography>
        </StyledButton>
        <StyledButton
          onClick={handleAttachFiles}
          data-testid="attach-dialog-attach-file"
          disabled={!attachmentCount}
          size="large"
          color="primary"
          variant="contained"
        >
          <StyledWhiteTypography variant="h5">
            Attach Files
          </StyledWhiteTypography>
        </StyledButton>
      </StyledWrapper>
    </>
  );
};
