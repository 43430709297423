import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import StatusLabel from 'components/StatusLabel';
import { getLastCompletedAuthorizationStatus } from 'features/mozart/MozartWorkflowLayout.helpers';
import { AUTHORIZATION_STATUS_STYLE } from 'util/helpers/iconHelpers';

export const MozartIndicator = () => {
  const authorizationStatusName = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].checklist
        ?.authorizationStatus?.name ?? state.mozart?.authorizationStatus?.name
  );
  const authorizationStatusStyle = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].checklist
        ?.authorizationStatus?.style
  );
  const completedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );

  const statusStyle =
    authorizationStatusStyle ??
    getLastCompletedAuthorizationStatus(completedChecklists).style;

  const statusName =
    authorizationStatusName ??
    getLastCompletedAuthorizationStatus(completedChecklists).name;

  if (!statusName) {
    return null;
  }

  return (
    <StatusLabel
      text={statusName}
      status={statusStyle ?? AUTHORIZATION_STATUS_STYLE.Grey}
      style={{ width: 'fit-content' }}
    />
  );
};
