/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import { PriorAuthCodesActionModel } from 'graphql/graphqlTypes';
import { useLazyGetPatientInsuranceStatusQuery } from 'graphql/hooks/getPatientInsuranceStatus';
import { ChecklistStorageType } from 'store/actions/types';
import {
  changeEndDate,
  changeStartDate,
  changeStatus,
  getDateError,
  getStartDate,
  getStatus,
} from './PriorAuthRequestedDates.helpers';
import DatePicker from '../HealthService/Components/DatePicker';
import { COLORS } from 'consts/styles';
import { StatusText, InputGroupBox, RequestedBox } from './StyledComponents';
import { PriorAuthError } from './PriorAuthError';
import { endDateError, startDateError } from './priorAuthConstants';

interface IPAValue {
  value?: string;
}

export const StatusSymbol = styled(Box)<IPAValue>`
  ${({ value }) => `
      background: ${value === 'Active' ? COLORS.GREEN100 : COLORS.GREY100};
    border-radius: 10px;
    width: 8px;
    height: 8px;
  `}
`;

export interface IPriorAuthRequestedDatesProps {
  item: PriorAuthCodesActionModel;
  storageType: ChecklistStorageType;
  onChange: (state: PriorAuthCodesActionModel) => void;
  readOnly: boolean;
  patientId: number;
  validationDispatch: (
    errorMessage: string,
    error: boolean,
    id?: string
  ) => void;
}

export const PriorAuthRequestedDates = (
  props: IPriorAuthRequestedDatesProps
) => {
  const { item, onChange, patientId, readOnly, validationDispatch } = props;

  const [requestedDates, setRequestedDates] = useState({
    startDate: item.reqStartDate,
    endDate: item.reqEndDate,
    status: item.status,
  });

  const [startDateErrors, setStartDateErrors] = useState(false);
  const [endDateErrors, setEndDateErrors] = useState(false);
  const [dateErrorMessage, setDateErrorMessage] = useState<
    string | null | undefined
  >(null);

  const [isHandleEndDateChange, setHandleEndDateChange] = useState(false);
  const [isHandleStartDateChange, setHandleStartDateChange] = useState(false);
  const [
    getPatientInsuranceStatus,
    { data: patientInsuranceStatusData, isSuccess: isFetchSuccess },
  ] = useLazyGetPatientInsuranceStatusQuery();

  const setPatientInsuranceStatus = () => {
    getPatientInsuranceStatus({
      request: {
        patientId,
        reqStartDate: requestedDates.startDate,
        reqEndDate: requestedDates.endDate,
      },
    });
  };

  const validateDates = () => {
    requestedDates.startDate = getStartDate(
      requestedDates.startDate,
      isHandleStartDateChange
    );

    const dateError = getDateError(
      requestedDates.startDate,
      requestedDates.endDate,
      isHandleEndDateChange
    );
    setDateErrorMessage(dateError.message);
    setStartDateErrors(dateError.hasError && dateError.isStartError);
    setEndDateErrors(dateError.hasError && !dateError.isStartError);
  };

  const handleStartDateChange = (date: Date | null | unknown) => {
    updateModel(changeStartDate(item, date as Date));
    setHandleStartDateChange(true);
  };

  const handleEndDateChange = (date: Date | null | unknown) => {
    updateModel(changeEndDate(item, date as Date));
    setHandleEndDateChange(true);
  };

  const updateModel = (inputModel: PriorAuthCodesActionModel) => {
    setRequestedDates({
      startDate: inputModel.reqStartDate,
      endDate: inputModel.reqEndDate,
      status: inputModel.status,
    });
    onChange(inputModel);
  };

  const isValidDateSelected = () =>
    requestedDates.startDate && requestedDates.endDate;

  useEffect(() => {
    validationDispatch(startDateErrors ? startDateError : ``, startDateErrors);
  }, [startDateErrors]);

  useEffect(() => {
    validationDispatch(endDateErrors ? endDateError : ``, endDateErrors);
  }, [endDateErrors]);

  useEffect(() => {
    if (isFetchSuccess) {
      updateModel(
        changeStatus(
          item,
          patientInsuranceStatusData?.getPatientInsuranceStatus
        )
      );
    }
  }, [patientInsuranceStatusData]);

  useEffect(() => {
    validateDates();
    if (isValidDateSelected()) {
      setPatientInsuranceStatus();
    }
  }, [requestedDates.startDate, requestedDates.endDate]);

  return (
    <>
      <RequestedBox>
        <Typography variant="body2">Requested</Typography>
        <InputGroupBox>
          <Typography variant="body1">Start Date</Typography>
          <DatePicker
            value={requestedDates.startDate}
            readOnly={readOnly}
            onChange={handleStartDateChange}
            error={startDateErrors && !readOnly}
            placeholder="mm/dd/yyyy"
          />
        </InputGroupBox>
        <InputGroupBox>
          <Typography variant="body1">End Date</Typography>
          <DatePicker
            value={requestedDates.endDate}
            readOnly={readOnly}
            onChange={handleEndDateChange}
            error={endDateErrors && !readOnly}
            placeholder="mm/dd/yyyy"
          />
        </InputGroupBox>
        <InputGroupBox>
          {isValidDateSelected() ? (
            <>
              <Typography variant="body1">Eligibility:</Typography>
              <StatusSymbol value={getStatus(requestedDates.status)} />
              <StatusText>
                <Typography>{getStatus(requestedDates.status)}</Typography>
              </StatusText>
            </>
          ) : null}
        </InputGroupBox>
      </RequestedBox>
      {(startDateErrors || endDateErrors) && dateErrorMessage ? (
        <PriorAuthError
          dateErrorMessage={dateErrorMessage}
          hasError={startDateErrors || endDateErrors}
        />
      ) : null}
    </>
  );
};
PriorAuthRequestedDates.displayName = 'PriorAuthRequestedDates';
export default PriorAuthRequestedDates;
