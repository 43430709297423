import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type ContinueMozartWorkflowMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  actionId: Types.Scalars['UUID'];
  episodeId: Types.Scalars['Int'];
  patientId: Types.Scalars['Int'];
  stepId: Types.Scalars['UUID'];
  action: Types.Scalars['String'];
}>;

export type ContinueMozartWorkflowMutation = {
  __typename?: 'Mutation';
  continueMozartWorkflow?: {
    __typename?: 'MozartActionResult';
    result: boolean;
    message?: string | null;
  } | null;
};

export const ContinueMozartWorkflowDocument = `
    mutation continueMozartWorkflow($id: UUID!, $actionId: UUID!, $episodeId: Int!, $patientId: Int!, $stepId: UUID!, $action: String!) {
  continueMozartWorkflow(
    request: {actionId: $actionId, episodeId: $episodeId, patientId: $patientId, stepId: $stepId, action: $action}
    id: $id
  ) {
    result
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    continueMozartWorkflow: build.mutation<
      ContinueMozartWorkflowMutation,
      ContinueMozartWorkflowMutationVariables
    >({
      query: (variables) => ({
        document: ContinueMozartWorkflowDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useContinueMozartWorkflowMutation } = injectedRtkApi;
