import React, { ReactNode } from 'react';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';
import { useDispatch } from 'react-redux';
import { Box, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import {
  updateDirtyTabs,
  updatePatientConditionStatus,
} from 'store/patientdetails/patientDetailsSlice';

export const StatusCell = (props: ILabelCellProps) => {
  const { row, value } = props;
  const dispatch = useDispatch();

  const MenuProps = {
    style: {
      left: '0',
      marginTop: '2px',
      maxHeight: 350,
      width: 100,
    },
  };

  const updateStatus = (status: boolean) => {
    if (value.isReadOnly) {
      return;
    }
    dispatch(
      updatePatientConditionStatus({
        id: row.id,
        value: status,
      })
    );
    dispatch(updateDirtyTabs('Conditions'));
  };

  const renderSelectedValue = (renderValue: string): ReactNode => {
    return (
      <Box display="flex" alignItems="center">
        <Box
          style={{
            borderRadius: '50%',
            height: '8px',
            width: '8px',
            marginRight: '8px',
            backgroundColor:
              renderValue === 'true' ? COLORS.GREEN100 : COLORS.GREY60,
          }}
        />
        <Typography variant="body1">
          {renderValue === 'true' ? 'Active' : 'Inactive'}
        </Typography>
      </Box>
    );
  };

  return (
    <Table.Cell
      {...props}
      style={CellComponentStyle}
      data-cy="table-cell-primary"
      onClick={undefined}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Select
          value={props.value.status ? 'true' : 'false'}
          variant="outlined"
          disabled={props.value.isReadOnly}
          style={{ width: '110px' }}
          onChange={(event) => updateStatus(event.target.value === 'true')}
          MenuProps={{
            ...MenuProps,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          renderValue={(selected) =>
            selected ? renderSelectedValue(selected as string) : ''
          }
        >
          <MenuItem value={'true'}>
            <ListItemText style={{ marginLeft: '9px' }}>Active</ListItemText>
          </MenuItem>
          <MenuItem value={'false'}>
            <ListItemText style={{ marginLeft: '9px' }}>Inactive</ListItemText>
          </MenuItem>
        </Select>
      </Box>
    </Table.Cell>
  );
};
