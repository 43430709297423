import { Maybe } from 'graphql/graphqlTypes';

export const split = <T>(list: T[], n: number): T[][] => {
  if (!list || list.length === 0) {
    return [list];
  }
  const result: T[][] = [];
  const cnt = Math.ceil(list.length / n);
  for (let col = 0; col < cnt; col++) {
    result[col] = [];
    for (let i = 0; i < n; i++) {
      const value = list[i + col * cnt];
      if (!value) {
        continue;
      }
      result[col].push(value);
    }
  }
  return result;
};

export const hashNumbers = (ids: number[]): number => {
  return ids.reduce(
    (prev: number, current: number) => (prev * 10 + current) % 12345,
    0
  );
};

export const notMaybe = <T>(value: Maybe<T>): value is T => !!value;
