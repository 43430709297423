import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeSummaryQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeSummaryQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeSummary?: {
    __typename?: 'EpisodeSummary';
    id: number;
    timeStamp?: any | null;
    summary?: string | null;
    author?: string | null;
  } | null;
};

export const GetEpisodeSummaryDocument = `
    query getEpisodeSummary($episodeId: Int!) {
  getEpisodeSummary(episodeId: $episodeId) {
    id
    timeStamp
    summary
    author
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeSummary: build.query<
      GetEpisodeSummaryQuery,
      GetEpisodeSummaryQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeSummaryDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeSummaryQuery, useLazyGetEpisodeSummaryQuery } =
  injectedRtkApi;
