import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdateLastCompletedChecklistMutationVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
  episodeId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type UpdateLastCompletedChecklistMutation = {
  __typename?: 'Mutation';
  updateLastCompletedChecklist?: {
    __typename?: 'UpdateChecklistResult';
    checklistId?: number | null;
    success: boolean;
    error?: string | null;
  } | null;
};

export const UpdateLastCompletedChecklistDocument = `
    mutation updateLastCompletedChecklist($patientId: Int!, $episodeId: Int) {
  updateLastCompletedChecklist(patientId: $patientId, episodeId: $episodeId) {
    checklistId
    success
    error
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateLastCompletedChecklist: build.mutation<
      UpdateLastCompletedChecklistMutation,
      UpdateLastCompletedChecklistMutationVariables
    >({
      query: (variables) => ({
        document: UpdateLastCompletedChecklistDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateLastCompletedChecklistMutation } = injectedRtkApi;
