import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdateChecklistScenarioMutationVariables = Types.Exact<{
  data: Types.UpdateChecklistModelInput;
}>;

export type UpdateChecklistScenarioMutation = {
  __typename?: 'Mutation';
  updateChecklistScenario?: {
    __typename?: 'CreateChecklistResultModel';
    status?: Types.CreateChecklistStatus | null;
    checklistId?: number | null;
  } | null;
};

export const UpdateChecklistScenarioDocument = `
    mutation updateChecklistScenario($data: UpdateChecklistModelInput!) {
  updateChecklistScenario(data: $data) {
    status
    checklistId
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateChecklistScenario: build.mutation<
      UpdateChecklistScenarioMutation,
      UpdateChecklistScenarioMutationVariables
    >({
      query: (variables) => ({
        document: UpdateChecklistScenarioDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateChecklistScenarioMutation } = injectedRtkApi;
