import { ILabelCellProps, LabelCell } from 'components/gridFormatters';
import { InsuranceEffectiveCell } from './cells/insuranceEffectiveCell';
import { InsuranceDisenrolledCell } from './cells/insuranceDisenrolledCell';
import React from 'react';

export const insuranceCurrentColumns = [
  { name: 'name', title: 'Name' },
  { name: 'number', title: 'Number' },
  { name: 'insuranceId', title: 'Insurance Id' },
  { name: 'effective', title: 'Effective' },
  { name: 'term', title: 'Term' },
];

export const insuranceHistoryColumns = [
  { name: 'contract', title: 'Contract' },
  { name: 'number', title: 'Number' },
  { name: 'lob', title: '@lobLabel' },
  { name: 'group', title: 'Group' },
  { name: 'effective', title: 'Effective' },
  { name: 'disenrolled', title: 'Disenrolled' },
];

export const benefitDetailsColumns = [
  { name: 'lob', title: '@lobLabel' },
  { name: 'benefitNo', title: 'Benefit ID' },
  { name: 'benefitDescription', title: 'Description' },
];

export const labelInsuranceHistory = (props: ILabelCellProps) => {
  const { column } = props;
  switch (column.name) {
    case 'effective':
      return <InsuranceEffectiveCell {...props} />;
    case 'disenrolled':
      return <InsuranceDisenrolledCell {...props} />;
    default:
      return <LabelCell {...props} />;
  }
};
