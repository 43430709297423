import { ChecklistItem } from 'graphql/graphqlTypes';

export enum problemsColumnNames {
  name = 'name',
  inCarePlan = 'inCarePlan',
  comment = 'note',
  id = 'id',
}

export const problemsColumnsExtensionStyles = [
  {
    columnName: problemsColumnNames.name,
    width: '20%',
  },
  {
    columnName: problemsColumnNames.comment,
  },
  {
    columnName: problemsColumnNames.inCarePlan,
    width: '10%',
  },
  {
    columnName: problemsColumnNames.id,
    width: '10%',
  },
];

export const addProblemsColumnsExtensionStyles = [
  {
    columnName: problemsColumnNames.name,
  },
];

export const itemsWithProblem = (problemId: number) => (item: ChecklistItem) =>
  item?.orderSet?.problems?.some((problem) => problem.id === problemId);
