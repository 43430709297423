import React from 'react';
import { Box, Typography } from '@mui/material';
import PatientTagsGrid from './PatientTagsGrid';

interface IPatientTagsTabProps {
  disabled: boolean;
}

const PatientTagsTab = ({ disabled }: IPatientTagsTabProps) => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="16px"
      >
        <Typography variant="subtitle1" style={{ paddingBottom: '16px' }}>
          Patient Tags
        </Typography>
      </Box>
      <Box>
        <PatientTagsGrid disabled={disabled} />
      </Box>
    </Box>
  );
};

export default PatientTagsTab;
