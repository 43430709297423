import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuickNoteType } from 'graphql/graphqlTypes';

export interface IQuickNoteState {
  open: boolean;
  shouldRefetchNotes: boolean;
  patientId: number;
  episodeId: number | null;
  baseQuickNoteId: number | null;
  type: QuickNoteType | null;
}

export const initialQuickNoteState: IQuickNoteState = {
  open: false,
  shouldRefetchNotes: false,
  patientId: 0,
  episodeId: 0,
  baseQuickNoteId: 0,
  type: null,
};

export interface OpenQuickNoteArgs {
  patientId: number;
  episodeId: number | null;
  type: QuickNoteType;
  baseQuickNoteId: number | null;
}

export const quickNoteSlice = createSlice({
  name: 'quickNoteState',
  initialState: initialQuickNoteState,
  reducers: {
    openQuickNote: (
      state,
      {
        payload: { patientId, episodeId, type, baseQuickNoteId },
      }: PayloadAction<OpenQuickNoteArgs>
    ) => {
      state.open = true;
      state.shouldRefetchNotes = false;
      state.episodeId = episodeId;
      state.patientId = patientId;
      state.baseQuickNoteId = baseQuickNoteId;
      state.type = type ?? QuickNoteType.Episode;
    },
    shouldRefetch: (
      state,
      {
        payload: { shouldRefetchNotes },
      }: PayloadAction<{ shouldRefetchNotes: boolean }>
    ) => {
      state.shouldRefetchNotes = shouldRefetchNotes;
    },
    close: (state) => {
      state.open = false;
      state.episodeId = 0;
      state.patientId = 0;
      state.baseQuickNoteId = 0;
      state.type = null;
    },
  },
});

export const { openQuickNote, shouldRefetch, close } = quickNoteSlice.actions;
export default quickNoteSlice.reducer;
