import { Patient } from 'graphql/graphqlTypes';
import moment from 'moment';

export const formatPatientAge = (patient: Patient) => {
  const age = patient?.age ? patient?.age + ' yo' : '';
  const gender = patient?.gender?.name ?? '';
  const dob = patient?.dOB
    ? ` (${moment(patient?.dOB).format('MM/DD/YYYY')})`
    : '';
  return [age, gender, dob].filter(Boolean).join(' ');
};
export const formatAddress = (patient: Patient) => {
  return [
    patient?.address1,
    patient?.address2,
    patient?.city,
    patient?.state,
    patient?.zipCode,
  ]
    .filter(Boolean)
    .join(', ');
};
