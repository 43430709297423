import { FormControl, TableCell, TextField, Autocomplete } from '@mui/material';
import React, { useState } from 'react';
import { ILookupValue } from 'backend/types/lookupValue';
import { CellComponentStyle } from 'components/gridFormatters';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { validateRequired } from 'util/validationUtils';
import { StyledPopper } from './StyledPopper';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

export interface AutocompleteCellProps extends TableEditRow.CellProps {
  autoFocus?: boolean;
  options: ILookupValue[];
  required?: boolean | null;
}

const StyledAutoComplete = styled(Autocomplete)({
  height: '32px',
  backgroundColor: COLORS.WHITE,
  input: {
    padding: '6px 0px!important',
    paddingLeft: '6px!important',
  },
  paper: {
    border: '1px solid #CECECE',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  },
  '.MuiAutocomplete-inputRoot': {
    padding: '0!important',
    paddingRight: '30px!important',
    backgroundColor: COLORS.WHITE,
  },
  option: {
    backgroundColor: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&:active': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&[aria-selected="true"]': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&[data-focus="true"]': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
  },
});

export const AutocompleteCell = (props: AutocompleteCellProps) => {
  const { options, value, required, onValueChange, autoFocus } = props;

  const [error, setError] = useState<boolean>(
    validateRequired(value?.id, required ?? false).hasError
  );

  const onSelectionChange = (newValue: ILookupValue) => {
    setError(validateRequired(newValue?.id, required ?? false).hasError);
    onValueChange({ id: newValue?.id, fullName: newValue?.name });
  };

  const selectedValue = options.find((x) => x.id === value?.id);

  const getOptionLabel = (option: ILookupValue): string => option.name;
  const getOptionDisabled = (option: ILookupValue): boolean =>
    option.isDisabled ?? false;

  return (
    <TableCell style={CellComponentStyle}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <StyledAutoComplete
          data-testid={`ac-${props.column.name}`}
          id={`ac-${props.column.name}`}
          options={options}
          autoComplete
          disableClearable
          PopperComponent={StyledPopper}
          fullWidth={true}
          size="small"
          getOptionLabel={(option) => getOptionLabel(option as ILookupValue)}
          getOptionDisabled={(option) =>
            getOptionDisabled(option as ILookupValue)
          }
          value={selectedValue ?? null}
          onChange={(_event, values) =>
            onSelectionChange(values as ILookupValue)
          }
          isOptionEqualToValue={(option, value) =>
            (option as ILookupValue).id === (value as ILookupValue).id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus={autoFocus}
              variant="outlined"
              fullWidth={true}
              error={error}
            />
          )}
        />
      </FormControl>
    </TableCell>
  );
};
