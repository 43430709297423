export enum COLORS {
  NAVY_BLUE = '#262b68',
  SYMPHONY_BLUE = '#0070d8',
  SYMPHONY_STAGE_BLUE = '#00386C',
  SYMPHONY_BLUE_HOVER = '#F0F6FE',
  PALE_SKY_BLUE = '#c5e3ff',
  PALE_GREY_BLUE = '#f5faff',
  BLACK = '#000000',
  BLACK10 = '#373f41',
  LIGHT_GREY = '#d8d8d8',
  ICE_GREY = '#eff4f8',
  PALE_GREY = '#fafbfc',
  WHITE = '#ffffff',
  COMPLETED_GREEN = '#34a193',
  GREEN20 = '#D7ECEA',
  GREY10 = '#EFEFEF',
  GREY20 = '#DFDFDF',
  GREY25 = '#D7D7D7',
  GREY4 = '#F9F9F9',
  GREEN100 = '#35A194',
  GREY80 = '#737B7D',
  GREY100 = '#5D5D5D',
  RED20 = '#F7CCCD',
  RED100 = '#D80003',
  BLUE10 = '#E5F1FB',
  BLUE20 = '#CCE2F7',
  BLUE115 = '#005FB8',
  GREY60 = '#9E9E9E',
  GREY30 = '#CECECE',
  RED160 = '#560001',
  ORANGE160 = '#612C0E',
  ORANGE100 = '#F36E22',
  YELLOW160 = '#644D06',
  YELLOW100 = '#FBC010',
  PURPLE = '#855CF8',
  BLUE = '#338CE0',
  DARK_GREEN = '#00695C',
  LIGHT_GREEN = '#009E18',
  GREEN160 = '#15403B',
  YELLOW20 = '#FEF2CF',
  ORANGE20 = '#FDE2D3',
  BROWN = '#995F28',
  DARK_RED = '#BE4242',
  VERY_DARK_BLUE = '#0C243E',
  DARK_GRAYISH_BLUE = '#525659',
  VERY_DARK_GRAYISH_BLUE = '#323639',
  BROWN30 = '#776224',
  YELLOW30 = '#FADC84',
}

export const CHARTS_PALETTE = [
  COLORS.GREEN100,
  COLORS.ORANGE100,
  COLORS.YELLOW100,
  COLORS.PURPLE,
  COLORS.BLUE,
  COLORS.DARK_GREEN,
  COLORS.BLUE115,
  COLORS.BROWN,
  COLORS.DARK_RED,
];
