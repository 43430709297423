import { DeterminationDecision } from '../types';
import { StyledButtonGroupItem } from '../styles';
import { ButtonGroup } from '@mui/material';
import React from 'react';

export const DecisionButtonGroup = (props: {
  updateGroupDecision: (value: DeterminationDecision) => void;
  groupDecision: DeterminationDecision;
  readOnly?: boolean;
  pendingOnly?: boolean;
  canApprove?: boolean;
  canDenied?: boolean;
  canPartial?: boolean;
}) => {
  const {
    groupDecision,
    updateGroupDecision,
    readOnly,
    pendingOnly,
    canApprove,
    canDenied,
    canPartial,
  } = props;

  return (
    <ButtonGroup aria-label="outlined primary button group">
      <StyledButtonGroupItem
        onClick={() => updateGroupDecision('Pending')}
        size="small"
        variant="outlined"
        $activeButton={groupDecision === 'Pending' && !readOnly}
        disabled={readOnly || !pendingOnly}
      >
        Pending
      </StyledButtonGroupItem>
      <StyledButtonGroupItem
        onClick={() => updateGroupDecision('Approved')}
        size="small"
        $activeButton={groupDecision === 'Approved' && !readOnly}
        variant="outlined"
        disabled={readOnly || !canApprove}
      >
        Approved
      </StyledButtonGroupItem>
      <StyledButtonGroupItem
        onClick={() => updateGroupDecision('Partial')}
        size="small"
        variant="outlined"
        $activeButton={groupDecision === 'Partial' && !readOnly}
        disabled={readOnly || !canPartial}
      >
        Partial
      </StyledButtonGroupItem>
      <StyledButtonGroupItem
        onClick={() => updateGroupDecision('Denied')}
        size="small"
        variant="outlined"
        $activeButton={groupDecision === 'Denied'}
        disabled={readOnly || !canDenied}
      >
        Denied
      </StyledButtonGroupItem>
    </ButtonGroup>
  );
};
