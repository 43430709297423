import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import PatientMRNFormatter from '../formatters/PatientMRNFormatter';

const PatientMRNProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={PatientMRNFormatter} {...props} />
);

export default PatientMRNProvider;
