import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { Button, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

interface UploadFilesDialogActionsProps {
  handleClose: () => void;
  handleAddFiles: () => void;
}

const StyledWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const StyledTypography = styled(Typography)({
  color: COLORS.SYMPHONY_BLUE,
});

const StyledWhiteTypography = styled(Typography)({
  color: COLORS.WHITE,
});

const StyledButton = styled(Button)({
  minWidth: '105px',
  height: '40px',
});

const UploadFilesDialogActions = (props: UploadFilesDialogActionsProps) => {
  const { handleClose, handleAddFiles } = props;

  const uploadedFileCount = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  ).length;

  const uploadedFiles = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  );

  const allFilesHaveCategoryId = uploadedFiles.every(
    (file) => file.categoryId !== undefined && !isNaN(file.categoryId)
  );

  return (
    <StyledWrapper>
      <StyledButton
        onClick={handleClose}
        data-testid="upload-dialog-cancel"
        color="primary"
        variant="outlined"
        size="large"
      >
        <StyledTypography variant="h5">Cancel</StyledTypography>
      </StyledButton>
      <StyledButton
        onClick={handleAddFiles}
        data-testid="upload-dialog-add-file"
        disabled={!uploadedFileCount || !allFilesHaveCategoryId}
        size="large"
        color="primary"
        variant="contained"
      >
        <StyledWhiteTypography variant="h5">Add Files</StyledWhiteTypography>
      </StyledButton>
    </StyledWrapper>
  );
};

export default UploadFilesDialogActions;
