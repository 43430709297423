import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import { IconFallBack } from 'components/IconFallBack';

const TagIconFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
  return (
    <>
      <IconFallBack
        name={obj.row.name}
        image={obj.value}
        type="Tag"
        iconSize={22}
      />
    </>
  );
};
const TagIconProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TagIconFormatter} {...props} />
);

export default TagIconProvider;
