import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { COLORS } from 'consts/styles';

export const TableEditRows = (props: TableEditRow.RowProps) => {
  return (
    <TableEditRow.Row {...props} style={{ backgroundColor: COLORS.BLUE10 }}>
      {props.children}
    </TableEditRow.Row>
  );
};
