import {
  EditingCell,
  GridEditCell,
  ILabelCellProps,
  LabelCell,
} from 'components/gridFormatters';
import { PatientCareGap } from 'graphql/graphqlTypes';
import React from 'react';
import { Table, TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { DateCell } from './cells/dateCell';
import { EditLookupCell } from './cells/editLookupCell';
import { ViewLookupCell } from './cells/viewLookupCell';
import { Typography } from '@mui/material';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export enum CareGapsColumnName {
  CreatedOn = 'createdOn',
  UpdatedOn = 'updatedOn',
  CareGap = 'careGap',
  Source = 'createdBy',
  Priority = 'priority',
  Status = 'status',
}

export const careGapsColumns = [
  {
    name: CareGapsColumnName.CreatedOn,
    title: 'Date Added',
  },
  { name: CareGapsColumnName.UpdatedOn, title: 'Date Last Updated' },
  { name: CareGapsColumnName.CareGap, title: 'Care Gap' },
  {
    name: CareGapsColumnName.Source,
    title: 'Source',
    getCellValue: (row: PatientCareGap) => row.createdBy?.name,
  },
  {
    name: CareGapsColumnName.Priority,
    title: 'Priority',
  },
  {
    name: CareGapsColumnName.Status,
    title: 'Status',
  },
];

export const careGapsColumnsExtensionStyles = [
  {
    columnName: CareGapsColumnName.CreatedOn,
    width: '10%',
  },
  {
    columnName: CareGapsColumnName.UpdatedOn,
    width: '10%',
  },
  {
    columnName: CareGapsColumnName.CareGap,
  },
  {
    columnName: CareGapsColumnName.Source,
    width: '15%',
  },
  {
    columnName: CareGapsColumnName.Priority,
    width: '15%',
  },
  {
    columnName: CareGapsColumnName.Status,
    width: '15%',
  },
];

export const careGapsColumnsExtensionSorting = [
  {
    columnName: CareGapsColumnName.CreatedOn,
    sortingEnabled: true,
  },
  {
    columnName: CareGapsColumnName.UpdatedOn,
    sortingEnabled: true,
  },
  {
    columnName: CareGapsColumnName.CareGap,
    sortingEnabled: true,
  },
  {
    columnName: CareGapsColumnName.Source,
    sortingEnabled: true,
  },
  {
    columnName: CareGapsColumnName.Priority,
    sortingEnabled: true,
  },
  {
    columnName: CareGapsColumnName.Status,
    sortingEnabled: true,
  },
];

export const careGapsColumnsExtensionEditing = [
  {
    columnName: CareGapsColumnName.CreatedOn,
    editingEnabled: false,
  },
  {
    columnName: CareGapsColumnName.UpdatedOn,
    editingEnabled: false,
  },
  {
    columnName: CareGapsColumnName.CareGap,
    editingEnabled: true,
  },
  {
    columnName: CareGapsColumnName.Source,
    editingEnabled: false,
  },
  {
    columnName: CareGapsColumnName.Priority,
    editingEnabled: true,
  },
  {
    columnName: CareGapsColumnName.Status,
    editingEnabled: true,
  },
];

export const editCareGap = (props: TableEditRow.CellProps) => {
  if (props.editingEnabled) {
    switch (props.column.name) {
      case CareGapsColumnName.Priority:
        return <EditLookupCell lookup="CareGapPriority" {...props} />;
      case CareGapsColumnName.Status:
        return <EditLookupCell lookup="CareGapStatus" {...props} />;
      case CareGapsColumnName.CareGap:
        return <GridEditCell {...props} />;
      default:
        return <EditingCell fullwidth={true} {...props} />;
    }
  } else {
    switch (props.column.name) {
      case CareGapsColumnName.CreatedOn:
      case CareGapsColumnName.UpdatedOn:
        return (
          <Table.Cell {...props}>
            <Typography>
              {props.value ? formatDate(props.value) : ''}
            </Typography>
          </Table.Cell>
        );
      default:
        return <Table.Cell {...props} />;
    }
  }
};

export const labelCareGap = (props: ILabelCellProps) => {
  const { column } = props;

  switch (column.name) {
    case CareGapsColumnName.CreatedOn:
    case CareGapsColumnName.UpdatedOn:
      return <DateCell {...props} />;
    case CareGapsColumnName.Priority:
    case CareGapsColumnName.Status:
      return <ViewLookupCell {...props} />;
    default:
      return <LabelCell {...props} />;
  }
};
