import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import PriorAuthMessage from 'components/actions/sections/SectionBody/Items/PriorAuthCodes/priorAuthMessage';
import { Maybe, PaRequirement } from 'graphql/graphqlTypes';

const PriorAuthMessageText = styled(Box)<{
  $priorAuthRequirement: Maybe<PaRequirement>;
}>(({ $priorAuthRequirement }) => ({
  whiteSpace: 'normal',
  display: 'flex',
  color:
    $priorAuthRequirement === PaRequirement.InternalRequired ||
    $priorAuthRequirement === PaRequirement.InternalMaybeRequired
      ? COLORS.BLACK
      : COLORS.GREY80,
  wordWrap: 'break-word',
}));

const PriorAuthDisabledMessFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => (
  <>
    <PriorAuthMessageText
      data-testid="PriorAuthDisabledText"
      $priorAuthRequirement={props.row.priorAuthRequirement}
    >
      <PriorAuthMessage props={props} />
    </PriorAuthMessageText>
  </>
);

export default PriorAuthDisabledMessFormatter;
