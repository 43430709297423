import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { useGetEpisodeBalancerQueueRecentCriteriaQuery } from 'graphql/hooks/getEpisodeBalancerQueueRecentCriteria';
import Loader from 'components/loader';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import EpisodeDistributeDialogBody from './EpisodeDistributeDialogBody';
import {
  resetToDefaultEpisodeDistributeDetails,
  useEpisodeDistributeForm,
} from './EpisodeDistributeDialog.helper';
import { EpisodeDistributeForm } from './types';

export interface EpisodeDistributeDialogProps {
  open: boolean;
  onDistribute: (value: EpisodeDistributeForm) => void;
  onClose: () => void;
}

const StyledDialogContent = styled(DialogContent)({
  padding: '24px 16px',
});

const StyledButton = styled(Button)({
  minWidth: '105px',
  height: '40px',
});

const StyledWrapper = styled(Box)({
  display: 'flex',
  gap: '20px',
});

export const StyledErrorMessage = styled(Typography)({
  color: COLORS.RED100,
  paddingBottom: '20px',
  fontWeight: 400,
  fontSize: 12,
});

const EpisodeDistributeDialog = (props: EpisodeDistributeDialogProps) => {
  const { open, onDistribute, onClose } = props;
  const form = useEpisodeDistributeForm();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = form;
  const watchCareStages = useWatch({ control, name: `careStages` });
  const watchPathways = useWatch({ control, name: `pathways` });
  const watchServiceLocations = useWatch({ control, name: `serviceLocations` });
  const { data, isFetching, isSuccess } =
    useGetEpisodeBalancerQueueRecentCriteriaQuery(
      {},
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const episodeBalancer = data?.getEpisodeBalancerQueueRecentCriteria;
  const hasFilterSelected =
    watchCareStages.length > 0 ||
    watchPathways.length > 0 ||
    watchServiceLocations.length > 0;

  useEffect(() => {
    if (isFetching || !isSuccess || !episodeBalancer) {
      return;
    }
    resetToDefaultEpisodeDistributeDetails(episodeBalancer, setValue);
  }, [episodeBalancer, isFetching, isSuccess, setValue]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (values: EpisodeDistributeForm) => {
    if (isValid && isDirty && hasFilterSelected) {
      onDistribute(values);
    }
  };

  return (
    <Dialog
      data-testid="episode-distribute-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="episode-distribute-dialog-title" onClose={handleClose}>
        <Typography sx={{ color: COLORS.WHITE }} variant="subtitle1">
          Select criteria for episode distribution
        </Typography>
      </DialogTitle>
      <StyledDialogContent dividers>
        {!hasFilterSelected && (
          <StyledErrorMessage variant="body1">
            At least one filter (CareStage or Pathway or Location) is required
          </StyledErrorMessage>
        )}
        <EpisodeDistributeDialogBody form={form} />
        <Loader active={isFetching} />
      </StyledDialogContent>
      <DialogActions>
        <StyledWrapper>
          <StyledButton
            data-testid="episode-distribute-dialog-cancel-button"
            onClick={handleClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </StyledButton>
          <StyledButton
            data-testid="episode-distribute-dialog-submit-button"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            variant="contained"
            disabled={!isValid || !isDirty || !hasFilterSelected}
          >
            Distribute
          </StyledButton>
        </StyledWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default EpisodeDistributeDialog;
