import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SaveColumnWidthMutationVariables = Types.Exact<{
  model: Types.ColumnWidthModelInput;
}>;

export type SaveColumnWidthMutation = {
  __typename?: 'Mutation';
  saveColumnWidth?: boolean | null;
};

export const SaveColumnWidthDocument = `
    mutation saveColumnWidth($model: ColumnWidthModelInput!) {
  saveColumnWidth(model: $model)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveColumnWidth: build.mutation<
      SaveColumnWidthMutation,
      SaveColumnWidthMutationVariables
    >({
      query: (variables) => ({ document: SaveColumnWidthDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSaveColumnWidthMutation } = injectedRtkApi;
