import { PopperProps } from '@mui/material/Popper';
import { Popper } from '@mui/material';
import React from 'react';

export const StyledPopper = (props: PopperProps) => (
  <Popper
    nonce={undefined}
    onResize={undefined}
    onResizeCapture={undefined}
    {...props}
    style={{ maxWidth: 'fit-content' }}
    placement="bottom-start"
  />
);
