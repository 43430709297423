import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientInsuranceQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientInsuranceQuery = {
  __typename?: 'EntitiesQuery';
  getPatientInsurance?: {
    __typename?: 'PatientInsuranceInfo';
    contract?: string | null;
    effective?: any | null;
    terminate?: any | null;
    number?: string | null;
    lob?: string | null;
    group?: string | null;
    isActive?: boolean | null;
  } | null;
};

export const GetPatientInsuranceDocument = `
    query getPatientInsurance($patientId: Int!) {
  getPatientInsurance(patientId: $patientId) {
    contract
    effective
    terminate
    number
    lob
    group
    isActive
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientInsurance: build.query<
      GetPatientInsuranceQuery,
      GetPatientInsuranceQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientInsuranceDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPatientInsuranceQuery, useLazyGetPatientInsuranceQuery } =
  injectedRtkApi;
