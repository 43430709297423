import React from 'react';
import { Typography } from '@mui/material';
import { MozartActionContainer } from './MozartActionContainer';

export interface IMozartMessageProps {
  message: string;
}

export const MozartMessage = ({ message }: IMozartMessageProps) => {
  const markup = { __html: message };
  return (
    <MozartActionContainer>
      <Typography dangerouslySetInnerHTML={markup} />
    </MozartActionContainer>
  );
};
