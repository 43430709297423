import React from 'react';
import HSRequestingCode from './Items/HealthService/HSRequestingCode';
import HSRNManualReview from 'components/review/sections/SectionBody/Items/HealthService/HSRNManualReview';
import { healthServiceDisplayMode } from './HealthServiceDisplayMode';
import { HealthService } from './Items/HealthService';
import {
  ChecklistItem,
  ChecklistItemHealthServices,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';

export interface ISectionBodyProps {
  checklistItem: ChecklistItem;
  categoryId: string;
  storageType: ChecklistStorageType;
  autoSave: boolean;
  orderableIndex: number;
  episodeId?: number;
  uiDisplayMode: number;
  isDisplayEpisodeLevelRequestedParam: boolean;
  isHsAllowAddingCodes: boolean;
  isHsAllowDeletingCode: boolean;
  hsAuthorizationType: number;
  changeItemValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  selectItemOnSave: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
  readOnly: boolean;
}

const HealthServiceWrapper = (props: ISectionBodyProps) => {
  const {
    checklistItem,
    categoryId,
    storageType,
    autoSave,
    orderableIndex,
    changeItemValueOnSave,
    selectItemOnSave,
    uiDisplayMode,
    readOnly,
    isDisplayEpisodeLevelRequestedParam,
    isHsAllowAddingCodes,
    isHsAllowDeletingCode,
    hsAuthorizationType,
  } = props;
  switch (uiDisplayMode) {
    case healthServiceDisplayMode.requestingCodes:
      return (
        <HSRequestingCode
          item={checklistItem as ChecklistItemHealthServices}
          categoryId={categoryId}
          autoSave={autoSave}
          orderableIndex={orderableIndex}
          storageType={storageType}
          updateChecklistItemInputValueOnSave={changeItemValueOnSave}
          selectChecklistItemOnSave={selectItemOnSave}
          readOnly={readOnly}
          hsAuthorizationType={hsAuthorizationType}
          isDisplayEpisodeLevelRequestedParam={
            isDisplayEpisodeLevelRequestedParam
          }
          hSDisplayMode={healthServiceDisplayMode.requestingCodes}
          isHsAllowAddingCodes={isHsAllowAddingCodes}
          isHsAllowDeletingCode={isHsAllowDeletingCode}
        />
      );
    case healthServiceDisplayMode.manualReview:
    case healthServiceDisplayMode.authRulesOutcome:
    case healthServiceDisplayMode.finalOutcome:
    case healthServiceDisplayMode.authOutcomeRollUp:
      return (
        <HSRNManualReview
          item={checklistItem as ChecklistItemHealthServices}
          categoryId={categoryId}
          autoSave={autoSave}
          orderableIndex={orderableIndex}
          storageType={storageType}
          updateChecklistItemInputValueOnSave={changeItemValueOnSave}
          selectChecklistItemOnSave={selectItemOnSave}
          hsRules={uiDisplayMode}
          readOnly={readOnly}
          isHsAllowAddingCodes={isHsAllowAddingCodes}
          isHsAllowDeletingCode={isHsAllowDeletingCode}
          hsAuthorizationType={hsAuthorizationType}
          isDisplayEpisodeLevelRequestedParam={
            isDisplayEpisodeLevelRequestedParam
          }
        />
      );
    default:
      return (
        <HealthService
          item={checklistItem as ChecklistItemHealthServices}
          categoryId={categoryId}
          autoSave={autoSave}
          orderableIndex={orderableIndex}
          storageType={storageType}
          updateChecklistItemInputValueOnSave={changeItemValueOnSave}
          selectChecklistItemOnSave={selectItemOnSave}
          readOnly={readOnly}
          hsAuthorizationType={0}
          hSDisplayMode={0}
          isDisplayEpisodeLevelRequestedParam={false}
        />
      );
  }
};

export default HealthServiceWrapper;
