import { api as generatedApi } from '../hooks/createOrUpdatePatient';

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Patient'],
  endpoints: {
    createOrUpdatePatient: {
      invalidatesTags: (result, error, arg) => {
        if (!arg.patient?.id || error) {
          return [];
        }
        return [{ type: 'Patient', id: arg.patient.id }];
      },
    },
  },
});

export const { useCreateOrUpdatePatientMutation } = api;
