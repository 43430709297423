import styled from 'styled-components';
import Link from '@mui/material/Link';
import { COLORS } from 'consts/styles';

const StyledLink = styled(Link)<{ $isDisabled?: boolean }>`
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 500;
  color: ${COLORS.SYMPHONY_BLUE};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  ${({ $isDisabled }) =>
    $isDisabled &&
    ` color: ${COLORS.GREY60};
      cursor: default;
      pointer-events: none;
    `}
`;

export default StyledLink;
