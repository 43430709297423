import React, { useEffect } from 'react';
import { ReminderStateDto } from 'graphql/graphqlTypes';
import { useChangeReminderStateMutation } from 'graphql/hooks/changeReminderState';
import Loader from 'components/loader';
import StyledLink from './StyledLink';
import { IReminderLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const ReminderCompleteLink = (props: IReminderLinkProps) => {
  const [
    changeReminderState,
    { isLoading: isUpdatingReminder, isSuccess: isSuccessReminder },
  ] = useChangeReminderStateMutation();

  useEffect(
    () => {
      if (isSuccessReminder && props.callback) {
        props.callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessReminder]
  );

  const onCompleteReminder = (reminderId: number) => {
    changeReminderState({
      model: {
        reminderId: Number(reminderId),
        state: ReminderStateDto.Completed,
      },
    });
  };

  return (
    <>
      <CustomTooltip title={props.title ?? 'Mark reminder as Completed'}>
        <StyledLink
          onClick={() => onCompleteReminder(props.reminderId)}
          data-cy="Complete"
        >
          Complete
        </StyledLink>
      </CustomTooltip>
      <Loader active={isUpdatingReminder} />
    </>
  );
};

export default ReminderCompleteLink;
