import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@mui/material';
import React from 'react';
import { COLORS } from 'consts/styles';

export const RisingRiskCell = (
  props: ILabelCellProps & { accuracy: number }
) => (
  <Table.Cell
    {...props}
    onClick={undefined}
    style={CellComponentStyle}
    data-cy="table-cell-type"
  >
    <Typography
      style={{
        color:
          props.value && props.value.toFixed(props.accuracy) === '1'
            ? COLORS.RED100
            : 'inherit',
      }}
    >
      {props.value ? props.value.toFixed(props.accuracy) : '0'}
    </Typography>
  </Table.Cell>
);
