import { v4 as uuid } from 'uuid';
import {
  AssociatedClaimActionValue,
  MedicationCode,
  Provider,
} from 'graphql/graphqlTypes';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import { IValidationResult } from 'util/validationUtils';

export type AssociatedClaimAction = AssociatedClaimActionValue & {
  uniqueId: string;
};

export enum AssociatedClaimAttributeNames {
  ClaimId = '[Episode.AssociatedClaim.ClaimId]',
  ClaimDate = '[Episode.AssociatedClaim.ClaimDate]',
  ServiceFrom = '[Episode.AssociatedClaim.ServiceFrom]',
  ServiceTo = '[Episode.AssociatedClaim.ServiceTo]',
  AmountPaid = '[Episode.AssociatedClaim.AmountPaid]',
  ProcedureCode = '[Episode.AssociatedClaim.ProcedureCode]',
  DiagnosisCode = '[Episode.AssociatedClaim.DiagnosisCode]',
  Provider = '[Episode.AssociatedClaim.Provider]',
}

export const defaultClaimActionValue = () => {
  return {
    uniqueId: uuid(),
    id: 0,
    claimId: null,
    claimDate: null,
    serviceFrom: null,
    serviceTo: null,
    diagnosisCodeId: null,
    diagnosisCode: null,
    procedureCodeId: null,
    procedureCode: null,
    providerId: null,
    paidAmount: null,
  } as AssociatedClaimAction;
};

export const mapAssociatedClaim = (values: AssociatedClaimActionValue[]) => {
  return values.map((x: AssociatedClaimActionValue) => {
    return { ...x, uniqueId: uuid() } as AssociatedClaimAction;
  });
};

export const getClaimValue = (
  claim: AssociatedClaimAction,
  attributeName: string
) => {
  switch (attributeName) {
    case AssociatedClaimAttributeNames.ClaimId:
      return claim.claimId;
    case AssociatedClaimAttributeNames.ClaimDate:
      return formatDate(claim.claimDate);
    case AssociatedClaimAttributeNames.ServiceFrom:
      return formatDate(claim.serviceFrom);
    case AssociatedClaimAttributeNames.ServiceTo:
      return formatDate(claim.serviceTo);
    case AssociatedClaimAttributeNames.AmountPaid:
      return formatPaidAmount(claim.paidAmount);
    case AssociatedClaimAttributeNames.ProcedureCode:
      return getMedicationCodeWithDescription(claim.procedureCode);
    case AssociatedClaimAttributeNames.DiagnosisCode:
      return getMedicationCodeWithDescription(claim.diagnosisCode);
    case AssociatedClaimAttributeNames.Provider:
      return getProviderDetails(claim.provider);
    default:
      return '';
  }
};

export const getMedicationCodeWithDescription = (
  medicationCode?: MedicationCode | null
) => {
  return medicationCode
    ? `${medicationCode.code ?? ''} - ${medicationCode.shortDescription ?? ''}`
    : '';
};

export const getProviderDetails = (provider?: Provider | null) => {
  return provider
    ? `${provider.firstName ?? ''} ${provider.lastName ?? ''} ${
        provider.providerId ?? ''
      } ${provider.nPI ?? ''}`
    : '';
};

export const formatPaidAmount = (paidAmount: number) => {
  return paidAmount ? `$ ${paidAmount}` : null;
};

export const isClaimIdAlreadyExists = (
  existingClaims: AssociatedClaimAction[],
  newClaim: AssociatedClaimActionValue
): IValidationResult => {
  if (
    newClaim.claimId &&
    existingClaims.find((x) => x.claimId === newClaim.claimId)
  ) {
    return {
      hasError: true,
      message: `The Claim "${newClaim.claimId}" selected already exists.`,
    };
  }
  return { hasError: false };
};
