import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetHomeModelQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetHomeModelQuery = {
  __typename?: 'EntitiesQuery';
  getHomeModel?: {
    __typename?: 'HomePageModel';
    activeTab?: number | null;
    tabs?: Array<{
      __typename?: 'EntityListTabDefinition';
      index?: number | null;
      name?: string | null;
    } | null> | null;
    settings?: {
      __typename?: 'HomePageSettings';
      pageSize: number;
      showActive?: boolean | null;
    } | null;
  } | null;
};

export const GetHomeModelDocument = `
    query getHomeModel {
  getHomeModel {
    tabs {
      index
      name
    }
    activeTab
    settings {
      pageSize
      showActive
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHomeModel: build.query<
      GetHomeModelQuery,
      GetHomeModelQueryVariables | void
    >({
      query: (variables) => ({ document: GetHomeModelDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetHomeModelQuery, useLazyGetHomeModelQuery } =
  injectedRtkApi;
