import { useSelector } from 'react-redux';
import { IState } from 'store';
import {
  getCareGapsRequest,
  getPatientInfoRequest,
} from 'components/home/patientdetails/patientdetails.helpers';
import { IPatientForm } from 'components/home/patientdetails/tabscontent/backgroundTab/types';
import {
  MoveAttachmentsModelInput,
  PatientInfoInput,
  UpdateAttachmentsModelInput,
  UpdatePatientCareGapsModelInput,
  UpdateReminderDtoInput,
} from 'graphql/graphqlTypes';

interface IPatientSaveInfoObject {
  patient?: PatientInfoInput;
  episodeReminders: UpdateReminderDtoInput[];
  memberReminders: UpdateReminderDtoInput[];
  attachments: UpdateAttachmentsModelInput;
  careGaps: UpdatePatientCareGapsModelInput;
  attachmentsToMove: MoveAttachmentsModelInput[];
}

export const usePatientSaveInfo = (): IPatientSaveInfoObject | null => {
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const memberReminders = useSelector(
    (state: IState) => state.patientDetails.updatedReminders.memberReminders
  );
  const episodeReminders = useSelector(
    (state: IState) => state.patientDetails.updatedReminders.episodeReminders
  );
  const attachments = useSelector(
    (state: IState) =>
      state.patientDetailsAttachment?.updatedAttachments[patientId]
  );
  const careGaps = useSelector(
    (state: IState) => state.patientDetails.risksTab?.patientCareGap
  );
  const contacts = useSelector(
    (state: IState) => state.patientDetails.contacts
  );
  const arePatientTagsUpdated = useSelector(
    (state: IState) => state.patientDetails.arePatientTagsUpdated
  );
  const updatedPatientTags = useSelector(
    (state: IState) => state.patientDetails.patientTags
  );
  const conditions = useSelector(
    (state: IState) => state.patientDetails.conditions
  );
  const patientDetails = useSelector(
    (state: IState) => state.patientDetails.patientDetails
  );
  const medications = useSelector(
    (state: IState) => state.patientDetails.medicationsTab.medicationsList
  );
  const deniesActiveMedications = useSelector(
    (state: IState) => state.patientDetails.medicationsTab.medicationsCheck
  );
  const noKnownDrugAllergies = useSelector(
    (state: IState) => state.patientDetails.medicationsTab.allergiesCheck
  );
  const allergies = useSelector(
    (state: IState) => state.patientDetails.medicationsTab.allergyNotes
  );
  const lastReviewed = useSelector(
    (state: IState) => state.patientDetails.medicationsTab.lastReviewed
  );
  const areTeamMembersUpdated = useSelector(
    (state: IState) => state.patientDetailsCareTeam.areTeamMembersUpdated
  );
  const teamMembers = useSelector(
    (state: IState) => state.patientDetailsCareTeam.updatedTeamMembers
  );
  const areProvidersUpdated = useSelector(
    (state: IState) => state.patientDetailsCareTeam.areProvidersUpdated
  );
  const providers = useSelector(
    (state: IState) => state.patientDetailsCareTeam.updatedProviders
  );
  const isFamilyUpdated = useSelector(
    (state: IState) => state.patientDetailsCareTeam.isFamilyUpdated
  );
  const family = useSelector(
    (state: IState) => state.patientDetailsCareTeam.updatedFamily
  );
  const dirtyTabs = useSelector(
    (state: IState) => state.patientDetails.dirtyTabs
  );
  const attachmentsToMove = useSelector(
    (state: IState) => state.patientDetailsAttachment.attachmentsToMove
  );
  const getAttachmentsRequest = (): UpdateAttachmentsModelInput => {
    return {
      patientId,
      attachments: attachments?.attachments || [],
      episodeAttachments:
        Object.entries(attachments?.episodeAttachments ?? {}).map(
          ([episodeId, attachs]) => ({
            episodeId: Number(episodeId),
            attachments: attachs,
          })
        ) ?? [],
    };
  };

  const dirtyBackgroundTab = dirtyTabs?.includes('Background');
  const patient = getPatientInfoRequest(
    patientId,
    patientDetails as IPatientForm,
    contacts,
    arePatientTagsUpdated,
    updatedPatientTags,
    dirtyTabs?.includes('Conditions') ? conditions : null,
    dirtyTabs?.includes('Meds') ? medications : [],
    deniesActiveMedications,
    noKnownDrugAllergies,
    lastReviewed,
    allergies,
    areTeamMembersUpdated,
    dirtyTabs?.includes('Meds'),
    teamMembers,
    areProvidersUpdated,
    providers,
    isFamilyUpdated,
    family,
    dirtyBackgroundTab
  );

  const updateMemberReminders = memberReminders || [];
  const updateEpisodeReminders = episodeReminders || [];
  const updateAttachments = getAttachmentsRequest();
  const updateCareGaps = getCareGapsRequest(
    patientId,
    careGaps,
    dirtyBackgroundTab
  );

  const saveDTO = {
    patient,
    memberReminders: updateMemberReminders,
    episodeReminders: updateEpisodeReminders,
    attachments: updateAttachments,
    careGaps: updateCareGaps,
    attachmentsToMove: attachmentsToMove,
  };

  if (
    patient ||
    updateMemberReminders ||
    updateAttachments ||
    updateEpisodeReminders ||
    updateCareGaps ||
    attachmentsToMove
  ) {
    return saveDTO;
  }

  return null;
};
