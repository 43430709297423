import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMozartWorkflowEpisodeAuthorizationStatusQueryVariables =
  Types.Exact<{
    id: Types.Scalars['UUID'];
  }>;

export type GetMozartWorkflowEpisodeAuthorizationStatusQuery = {
  __typename?: 'EntitiesQuery';
  getMozartWorkflowEpisodeAuthorizationStatus?: {
    __typename?: 'GetWorkflowEpisodeAuthorizationStatusResult';
    authorizationStatus?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
  } | null;
};

export const GetMozartWorkflowEpisodeAuthorizationStatusDocument = `
    query getMozartWorkflowEpisodeAuthorizationStatus($id: UUID!) {
  getMozartWorkflowEpisodeAuthorizationStatus(id: $id) {
    authorizationStatus {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMozartWorkflowEpisodeAuthorizationStatus: build.query<
      GetMozartWorkflowEpisodeAuthorizationStatusQuery,
      GetMozartWorkflowEpisodeAuthorizationStatusQueryVariables
    >({
      query: (variables) => ({
        document: GetMozartWorkflowEpisodeAuthorizationStatusDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetMozartWorkflowEpisodeAuthorizationStatusQuery,
  useLazyGetMozartWorkflowEpisodeAuthorizationStatusQuery,
} = injectedRtkApi;
