import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import StatusLabel from 'components/StatusLabel';
import { AUTHORIZATION_STATUS_STYLE } from 'util/helpers/iconHelpers';

const EpisodeStatusFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
  if (!obj.value) {
    return null;
  }
  return (
    <div style={{ display: 'flex' }}>
      <StatusLabel
        text={obj?.value?.name}
        status={obj.value.style ?? AUTHORIZATION_STATUS_STYLE.Grey}
      />
    </div>
  );
};

const EpisodeStatusProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={EpisodeStatusFormatter} {...props} />
);

export default EpisodeStatusProvider;
