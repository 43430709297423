import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import StyledLink from './StyledLink';
import { IAttachmentLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { toggleAttachmentSidebar } from 'store/patientdetails/patientDetails.attachment.slice';

interface IAttachmentFaxLinkProps extends IAttachmentLinkProps {
  attachmentId: number;
  isDisabled: boolean;
}

const AttachmentFaxLink = ({
  attachmentId,
  isDisabled,
}: IAttachmentFaxLinkProps) => {
  const dispatch = useDispatch();

  const onAttachmentMoveClick = useCallback(() => {
    dispatch(toggleAttachmentSidebar(attachmentId));
  }, [attachmentId, dispatch]);
  return (
    <CustomTooltip title={'Move Attachment'}>
      <StyledLink
        onClick={onAttachmentMoveClick}
        data-cy="move-attachment"
        $isDisabled={isDisabled}
      >
        Move
      </StyledLink>
    </CustomTooltip>
  );
};

export default AttachmentFaxLink;
