import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeTatQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeTatQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeTat?: {
    __typename?: 'TatViewModel';
    additionalTime?: string | null;
    calculatedTat?: string | null;
    clockType: string;
    drivers: Array<string>;
    dueDateTime?: any | null;
    startDateTime: any;
    state: Types.ClockState;
    timeRemaining?: string | null;
    history: Array<{
      __typename?: 'ClockHistoryViewModel';
      action: Types.ClockEventType;
      createdOn: any;
      createdByUser: {
        __typename?: 'User';
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
      };
    }>;
  } | null;
};

export const GetEpisodeTatDocument = `
    query getEpisodeTat($episodeId: Int!) {
  getEpisodeTat(episodeId: $episodeId) {
    additionalTime
    calculatedTat
    clockType
    drivers
    dueDateTime
    history {
      action
      createdByUser {
        firstName
        lastName
        fullName
      }
      createdOn
    }
    startDateTime
    state
    timeRemaining
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeTat: build.query<GetEpisodeTatQuery, GetEpisodeTatQueryVariables>(
      {
        query: (variables) => ({ document: GetEpisodeTatDocument, variables }),
      }
    ),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeTatQuery, useLazyGetEpisodeTatQuery } =
  injectedRtkApi;
