import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import ActionLink from 'components/actionlink';
import { Grid } from '@mui/material';
import { TimelineType } from 'components/constants';
import TimelineActionLinks from './TimelineActionLinks';
import { useDispatch } from 'react-redux';
import {
  TimelineActionLinkDivider,
  TimelineActionLinkConditionDivider,
} from './TimelineActionLinkDivider';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { updateShowSuccessMessage } from 'store/patientdetails/patientDetailsSlice';

type RowOperation = keyof typeof TimelineActionLinks;

const TimelineActionsFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const dispatch = useDispatch();
  const isFaxAllowed = hasUserPrivilege(Privilege.AllowFax);

  const renderFax = () => {
    return isFaxAllowed ? (
      <>
        <TimelineActionLinkDivider />
        <ActionLink
          key="AttachmentFax"
          type="AttachmentFax"
          entityId={Number(props.row.id)}
        />
      </>
    ) : null;
  };

  if (props.row.type === TimelineType.Checklist) {
    return (
      <Grid container direction="row">
        {props.row.operations.map((op: string, index: number) => (
          <React.Fragment key={index}>
            <TimelineActionLinkConditionDivider index={index} />
            {TimelineActionLinks[op as RowOperation]({
              index: index,
              rowId: Number(props.row.id),
              patientId: props.value.patientId,
              onGridRefetch: props.value.onGridRefetch,
              dispatch: dispatch,
            })}
          </React.Fragment>
        ))}
      </Grid>
    );
  } else if (props.row.type === TimelineType.Reminder) {
    const showSuccessMessage = () => dispatch(updateShowSuccessMessage(true));

    return (
      <Grid container direction="row">
        {props.row.opened ? (
          <>
            <ActionLink
              key={'ReminderComplete'}
              type="ReminderComplete"
              entityId={Number(props.row.id)}
              callback={() => {
                props.value.onGridRefetch();
                showSuccessMessage();
              }}
            />
            <TimelineActionLinkDivider />
            <ActionLink
              key={'ReminderDelete'}
              type="ReminderDelete"
              entityId={Number(props.row.id)}
              callback={() => {
                props.value.onGridRefetch();
                showSuccessMessage();
              }}
            />
          </>
        ) : (
          <ActionLink
            key="ReminderRestore"
            type="ReminderRestore"
            entityId={Number(props.row.id)}
            callback={() => {
              props.value.onGridRefetch();
              showSuccessMessage();
            }}
          />
        )}
      </Grid>
    );
  } else {
    return (
      <Grid container direction="row" justifyContent="flex-end">
        {props.row.isLetter || props.row.isFax ? (
          <>
            <ActionLink
              key="AttachmentPrint"
              type="AttachmentPrint"
              entityId={Number(props.row.id)}
              fileName={props.row.attachmentDetails}
            />
            {renderFax()}
          </>
        ) : (
          <ActionLink
            key="AttachmentView"
            type="AttachmentView"
            entityId={Number(props.row.id)}
            fileName={props.row.attachmentDetails}
          />
        )}
      </Grid>
    );
  }
};

export default TimelineActionsFormatter;
