import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMozartOutOfTurnStepsQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type GetMozartOutOfTurnStepsQuery = {
  __typename?: 'EntitiesQuery';
  getMozartOutOfTurnSteps?: Array<{
    __typename?: 'WorkflowInstanceAvailableOOTSteps';
    id: number;
    workflowInstance: any;
    workflowInstanceAvailableOOTStepsId: any;
    workflowStepId: any;
    workflowStepName?: string | null;
  }> | null;
};

export const GetMozartOutOfTurnStepsDocument = `
    query getMozartOutOfTurnSteps($id: UUID!) {
  getMozartOutOfTurnSteps(id: $id) {
    id
    workflowInstance
    workflowInstanceAvailableOOTStepsId
    workflowStepId
    workflowStepName
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMozartOutOfTurnSteps: build.query<
      GetMozartOutOfTurnStepsQuery,
      GetMozartOutOfTurnStepsQueryVariables
    >({
      query: (variables) => ({
        document: GetMozartOutOfTurnStepsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetMozartOutOfTurnStepsQuery,
  useLazyGetMozartOutOfTurnStepsQuery,
} = injectedRtkApi;
