import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type FinishChecklistMutationVariables = Types.Exact<{
  data: Types.FinishChecklistModelInput;
}>;

export type FinishChecklistMutation = {
  __typename?: 'Mutation';
  finishChecklist?: {
    __typename?: 'FinishChecklistResultModel';
    checklistId: number;
    status: Types.FinishChecklistStatus;
    errors?: Array<string | null> | null;
    reminderCount?: number | null;
  } | null;
};

export const FinishChecklistDocument = `
    mutation finishChecklist($data: FinishChecklistModelInput!) {
  finishChecklist(data: $data) {
    checklistId
    status
    errors
    reminderCount
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    finishChecklist: build.mutation<
      FinishChecklistMutation,
      FinishChecklistMutationVariables
    >({
      query: (variables) => ({ document: FinishChecklistDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFinishChecklistMutation } = injectedRtkApi;
