import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import { IconButton } from '@mui/material';

const PrimaryIconFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  {
    const isPrimary =
      typeof props.value === 'object' ? props.value.isPrimary : props.value;
    return (
      <IconButton
        style={{ padding: '0px' }}
        onClick={() => props.value.onSelect(props.value.id)}
        size="large"
      >
        <Icon
          icon={ICONS.StarFull}
          size={14}
          color={isPrimary ? COLORS.SYMPHONY_BLUE : COLORS.GREY100}
        />
      </IconButton>
    );
  }
};

export default PrimaryIconFormatter;
