import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeTabModelQueryVariables = Types.Exact<{
  tabType: Types.Scalars['String'];
}>;

export type GetEpisodeTabModelQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeTabModel?: {
    __typename?: 'PatientEpisodesTabModel';
    episodeListState?: string | null;
    selectedSubTab?: string | null;
    episodeTypes?: Array<{
      __typename?: 'EpisodeType';
      id: number;
      name?: string | null;
    } | null> | null;
    subTabs?: Array<{
      __typename?: 'PatientDetailsSubTabDefinition';
      name?: string | null;
      isVisible: boolean;
    } | null> | null;
  } | null;
};

export const GetEpisodeTabModelDocument = `
    query getEpisodeTabModel($tabType: String!) {
  getEpisodeTabModel(tabType: $tabType) {
    episodeListState
    selectedSubTab
    episodeTypes {
      id
      name
    }
    subTabs {
      name
      isVisible
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeTabModel: build.query<
      GetEpisodeTabModelQuery,
      GetEpisodeTabModelQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeTabModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeTabModelQuery, useLazyGetEpisodeTabModelQuery } =
  injectedRtkApi;
