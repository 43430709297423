import React from 'react';

import { COLORS } from 'consts/styles';
import { TableSelection } from '@devexpress/dx-react-grid-material-ui';
import styled from 'styled-components';

const StyledTr = styled.tr`
  &:hover {
    background-color: ${COLORS.BLUE10}!important;
    cursor: pointer;
  }
`;

export const SelectedRow = (props: TableSelection.RowProps) => {
  return (
    <StyledTr
      style={{ backgroundColor: props.highlighted ? COLORS.BLUE10 : '' }}
      onClick={() => {
        props.onToggle();
      }}
    >
      {props.children}
    </StyledTr>
  );
};
