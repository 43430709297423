import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HSRNIndividualServiceReview from './HSRNIndividualServiceReview';
import {
  useDeterminationReasons,
  useLookup,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.hooks';
import {
  onHealthServiceItemValueChange,
  getValidationDispatch,
  useSaveHealthService,
  updateDecisionAndReason,
  onHealthServiceOverallItemValueChange,
  deleteItemHealthService,
  addMedicationCode,
  getHsVersion,
  initHsGroupDecision,
  applyOnHsDecisionGroup,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.helpers';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  ChecklistItem,
  ChecklistItemHealthServices,
  ChecklistViewModel,
  HealthServiceCodesActionModel,
  HealthServiceCodesActionValue,
  LookupValue,
  SearchResult,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';
import { IState } from 'store';
import { notMaybe } from 'util/helpers/arrayFunctions';
import {
  getDefaultUnitType,
  getHsAuthorizationType,
  isOverallStatus,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthServiceV2helper';
import { HEALTH_SERVICE } from 'components/constants';
import {
  IndividualServiceBox,
  ManualReviewBox,
  OverallReviewBox,
} from './styles';
import GroupDecision from 'components/actions/sections/SectionBody/Items/HealthService/Components/GroupDecision';
import {
  DeterminationDecision,
  HealthServiceAuthorizationType,
  hsVersion,
} from 'components/actions/sections/SectionBody/Items/HealthService/types';
import { healthServiceDisplayMode } from 'components/actions/sections/SectionBody/HealthServiceDisplayMode';
import { COLORS } from 'consts/styles';
import MedicationCodesSearch from 'components/actions/sections/SectionBody/Items/MedicationCodes/medicationCodesSearch';
import OverallHealthService from 'components/actions/sections/SectionBody/Items/HealthService/Components/OverallHealthService';
import IndividualService from 'components/actions/sections/SectionBody/Items/HealthService/Components/IndividualService';
import { HsManualSummary } from 'components/actions/sections/SectionBody/Items/HealthService/Components/HsManualSummary';
import { updateHealthServiceValues } from 'store/actions/checklistSlice';
import OverallReview from './OverallReview';

export interface IChecklistItemHealthServiceProps {
  item: ChecklistItemHealthServices;
  categoryId: string;
  autoSave: boolean;
  orderableIndex: number;
  storageType: ChecklistStorageType;
  hsRules: number;
  isHsAllowAddingCodes: boolean;
  isHsAllowDeletingCode: boolean;
  hsAuthorizationType: number;
  isDisplayEpisodeLevelRequestedParam: boolean;
  updateChecklistItemInputValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  selectChecklistItemOnSave: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
  readOnly: boolean;
}

const HSRNManualReview = ({
  autoSave,
  item,
  orderableIndex,
  storageType,
  updateChecklistItemInputValueOnSave,
  categoryId,
  hsRules,
  readOnly,
  isHsAllowAddingCodes,
  isHsAllowDeletingCode,
  hsAuthorizationType,
  isDisplayEpisodeLevelRequestedParam,
}: IChecklistItemHealthServiceProps) => {
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist
  );

  const OverallHealthServiceBox = styled(Box)({
    padding: '12px 16px 16px 60px',
    backgroundColor: COLORS.GREY4,
  });

  const StyledBox = styled(Box)({
    padding: '12px 60px 12px 60px',
  });

  const hsDefaultUnit = item.options.hSDefaultUnit;
  const hsUnitTypeEditable = item.options.hSUnitTypeEditable;
  const episodeNumber = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklist?.episodeNumber
  );

  const unitTypes = useLookup('UnitsOfServiceType');
  const defaultUnitType = getDefaultUnitType(unitTypes, hsDefaultUnit);
  const determinationReasons = useDeterminationReasons(item.options);

  const canPending = getHsAuthorizationType(
    item.options.hSAllowPending,
    hsAuthorizationType
  );

  const canApprove = getHsAuthorizationType(
    item.options.hSAllowApproved,
    hsAuthorizationType
  );

  const canDenied = getHsAuthorizationType(
    item.options.hSAllowDenied,
    hsAuthorizationType
  );

  const canPartial = getHsAuthorizationType(
    item.options.hSAllowPartial,
    hsAuthorizationType
  );

  const hsModel = (item.hSValue ?? {}) as HealthServiceCodesActionModel;
  const dispatch = useDispatch();
  const getInitialValue = () => {
    return item.hSValue?.overallApprUnitType
      ? item.hSValue?.overallApprUnitType
      : defaultUnitType;
  };

  const hsGroupDecision = initHsGroupDecision(hsModel);
  const [model, setHsManualModel] = useState({
    ...item.hSValue,
    overallApprUnitType: getInitialValue(),
    overallReqUnitType: item.hSValue?.overallReqUnitType
      ? item.hSValue?.overallReqUnitType
      : defaultUnitType,
    overallDeterminationDecision:
      item.hSValue?.overallDeterminationDecision ?? hsGroupDecision,
  } as HealthServiceCodesActionModel);

  const [overallData, setOverallData] = useState({
    start: item.hSValue?.overallReqStartDate,
    end: item.hSValue?.overallReqEndDate,
    unitType: item.hSValue?.overallReqUnitType ?? defaultUnitType,
  });
  const [episodeDecision, setEpisodeDecision] = useState<DeterminationDecision>(
    (model?.overallDeterminationDecision as DeterminationDecision) ??
      hsGroupDecision
  );

  const validationDispatch = getValidationDispatch(
    dispatch,
    item.uid,
    categoryId,
    storageType,
    HEALTH_SERVICE
  );

  const onDeleteCode = (id: number) => {
    const newModel = deleteItemHealthService(model, id, hsVersion);
    setHsManualModel(newModel);
    validationDispatch('', false, id.toString());
    validationDispatch('', false, `${id}-reason`);
  };

  const actionOnDecision = (decision: DeterminationDecision) => {
    applyOnHsDecisionGroup(
      dispatch,
      storageType,
      checklist as ChecklistViewModel,
      item,
      decision
    );
  };

  const applyOnGroup = (
    decision: DeterminationDecision,
    reason: LookupValue | null
  ) => {
    const version = getHsVersion(
      hsAuthorizationType,
      HealthServiceAuthorizationType.Episode
    );
    applyOnHsDecisionGroup(
      dispatch,
      storageType,
      checklist as ChecklistViewModel,
      item,
      decision
    );
    const newModel = updateDecisionAndReason(model, decision, reason, version);
    setHsManualModel(newModel);
    setEpisodeDecision(decision);
  };

  const saveHealthService = useSaveHealthService();
  const hsManualReviewInputChange = debounce(
    (value: HealthServiceCodesActionModel) => {
      saveHealthService(
        value,
        checklistId,
        item,
        autoSave,
        updateChecklistItemInputValueOnSave,
        orderableIndex
      );
    },
    100
  );

  useEffect(() => {
    hsManualReviewInputChange(model);
    applyOnHsDecisionGroup(
      dispatch,
      storageType,
      checklist as ChecklistViewModel,
      item,
      model.overallDeterminationDecision as DeterminationDecision
    );
    dispatch(
      updateHealthServiceValues({
        storageType,
        itemUid: item.uid,
        code: model,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const onChangeOverallData = (newModel: HealthServiceCodesActionModel) => {
    setOverallData({
      start: newModel.overallReqStartDate,
      end: newModel.overallReqEndDate,
      unitType: newModel.overallReqUnitType ?? defaultUnitType,
    });

    const updatedModel = onHealthServiceOverallItemValueChange(
      newModel,
      hsVersion
    );
    setHsManualModel(updatedModel);
  };

  const onAddCode = (newCode: SearchResult) => {
    const newModel = addMedicationCode(newCode, model, unitTypes, hsVersion);
    setHsManualModel(newModel);
  };

  const onModelChange = (updatedValue: HealthServiceCodesActionModel) => {
    setHsManualModel(updatedValue);
  };

  const hsManualReviewValidationDispatch = getValidationDispatch(
    dispatch,
    item.uid,
    categoryId,
    storageType,
    HEALTH_SERVICE
  );

  const overallStatus = isOverallStatus(hsModel, 'Approved');

  const onIndividualServiceChange = (
    updatedValue: HealthServiceCodesActionValue
  ) => {
    if (hsAuthorizationType != HealthServiceAuthorizationType.Episode) {
      const newModel = onHealthServiceItemValueChange(model, updatedValue);
      setHsManualModel(newModel);
      applyOnHsDecisionGroup(
        dispatch,
        storageType,
        checklist as ChecklistViewModel,
        item,
        newModel.overallDeterminationDecision as DeterminationDecision
      );
    }
  };
  const hSSummary = () => {
    if (
      hsAuthorizationType == HealthServiceAuthorizationType.NonSet &&
      hsRules === healthServiceDisplayMode.finalOutcome
    ) {
      return null;
    }
    if (
      hsAuthorizationType == HealthServiceAuthorizationType.NonSet &&
      hsRules === healthServiceDisplayMode.authRulesOutcome
    ) {
      return null;
    }

    return hsAuthorizationType == HealthServiceAuthorizationType.NonSet ? (
      <OverallHealthServiceBox>
        <GroupDecision
          determinationReasons={determinationReasons}
          actionOnGroup={applyOnGroup}
          actionOnDecision={() => {
            return;
          }}
          readOnly={!model.healthServiceCodesActionValues?.length || readOnly}
          pendingOnly={true}
          canApprove={true}
          canDenied={canDenied}
          canPartial={true}
          hsAuthorizationType={hsAuthorizationType}
          hsModel={model}
        />
      </OverallHealthServiceBox>
    ) : (
      <HsManualSummary
        determinationReasons={determinationReasons}
        unitTypes={unitTypes}
        hsModel={model}
        episodeDecision={episodeDecision}
        onChange={onModelChange}
        hsAuthorizationType={hsAuthorizationType}
        hSDisplayMode={hsRules}
        defaultUnitType={defaultUnitType}
        actionOnGroup={applyOnGroup}
        actionOnDecision={actionOnDecision}
        canPending={canPending}
        canApprove={canApprove}
        canDenied={canDenied}
        canPartial={canPartial}
        readOnly={readOnly}
      />
    );
  };

  return (
    <Box id={`uuid-${item.uid}`} data-testid="hsRNManualReview">
      <ManualReviewBox>
        <OverallReviewBox>
          {hsRules === healthServiceDisplayMode.manualReview &&
          hsAuthorizationType === HealthServiceAuthorizationType.Episode ? (
            <>
              <OverallHealthServiceBox>
                <OverallHealthService
                  state={model}
                  onChange={onChangeOverallData}
                  unitTypes={unitTypes}
                  readOnly={false}
                  notApproved={true}
                  validationDispatch={validationDispatch}
                  isDisplayEpisodeLevelRequestedParam={
                    isDisplayEpisodeLevelRequestedParam
                  }
                  hsAuthorizationType={hsAuthorizationType}
                  hsUnitTypeEditable={hsUnitTypeEditable}
                  hsDefaultUnit={hsDefaultUnit}
                />
              </OverallHealthServiceBox>
            </>
          ) : (
            <>
              <OverallHealthServiceBox>
                {hsAuthorizationType ==
                HealthServiceAuthorizationType.NonSet ? (
                  <OverallReview
                    item={model}
                    unitTypes={unitTypes}
                    isRNReview={true}
                    episodeNumber={episodeNumber}
                    hsRules={hsRules}
                    hsAuthorizationType={hsAuthorizationType}
                  />
                ) : (
                  <OverallHealthService
                    state={model}
                    onChange={onChangeOverallData}
                    unitTypes={unitTypes}
                    readOnly={false}
                    notApproved={true}
                    validationDispatch={validationDispatch}
                    isDisplayEpisodeLevelRequestedParam={
                      isDisplayEpisodeLevelRequestedParam
                    }
                    hsAuthorizationType={hsAuthorizationType}
                    hsUnitTypeEditable={hsUnitTypeEditable}
                    hsDefaultUnit={hsDefaultUnit}
                    hSDisplayMode={hsRules}
                  />
                )}
              </OverallHealthServiceBox>
              {hsRules === healthServiceDisplayMode.manualReview &&
                hsAuthorizationType !=
                  HealthServiceAuthorizationType.NonSet && (
                  <StyledBox>
                    <GroupDecision
                      determinationReasons={determinationReasons}
                      actionOnGroup={applyOnGroup}
                      actionOnDecision={actionOnDecision}
                      readOnly={
                        !model.healthServiceCodesActionValues?.length ||
                        readOnly
                      }
                      pendingOnly={canPending}
                      canApprove={canApprove}
                      canDenied={canDenied}
                      canPartial={canPartial}
                      hsModel={model}
                    />
                  </StyledBox>
                )}
            </>
          )}
        </OverallReviewBox>
        <IndividualServiceBox>
          {model.healthServiceCodesActionValues?.length >= 0 ? (
            <>
              {model.healthServiceCodesActionValues
                ?.filter(notMaybe)
                .map((code, index: number) =>
                  hsRules === healthServiceDisplayMode.manualReview &&
                  hsAuthorizationType ===
                    HealthServiceAuthorizationType.Episode ? (
                    <IndividualService
                      id={`uuid-${item.uid}-${code.id}`}
                      key={item.uid}
                      item={code}
                      onDelete={onDeleteCode}
                      onChange={onIndividualServiceChange}
                      unitTypes={unitTypes}
                      readOnly={false}
                      determinationReasons={determinationReasons}
                      pendingOnly={true}
                      canApprove={true}
                      canDenied={true}
                      overallDates={overallData}
                      validationDispatch={validationDispatch}
                      enableDelete={
                        model.healthServiceCodesActionValues?.length > 0
                      }
                      hsAuthorizationType={hsAuthorizationType}
                      isHsAllowDeletingCode={isHsAllowDeletingCode}
                    />
                  ) : (
                    <HSRNIndividualServiceReview
                      id={`uuid-${item.uid}-${code.id}-reason`}
                      key={index.toString()}
                      determinationReasons={determinationReasons}
                      item={code}
                      onChange={onIndividualServiceChange}
                      unitTypes={unitTypes}
                      overallDates={overallData}
                      validationDispatch={hsManualReviewValidationDispatch}
                      hsRules={hsRules}
                      overallStatus={overallStatus}
                      readOnly={readOnly}
                      healthServiceModel={model}
                      hsAuthorizationType={hsAuthorizationType}
                      pendingOnly={canPending}
                      canApprove={canApprove}
                      canDenied={canDenied}
                      canPartial={canPartial}
                    />
                  )
                )}
              {isHsAllowAddingCodes && (
                <MedicationCodesSearch
                  minRequired={item.options.minRequired}
                  maxAllowed={item.options.maxAllowed}
                  type={item.options.type}
                  readOnly={readOnly}
                  selectedCodes={
                    model.healthServiceCodesActionValues as SearchResult[]
                  }
                  onAddCode={onAddCode}
                  style={{ padding: '0 0px 14px 0px' }}
                />
              )}
            </>
          ) : null}
        </IndividualServiceBox>
      </ManualReviewBox>
      {hsRules !== healthServiceDisplayMode.authOutcomeRollUp ? (
        <>{hSSummary()}</>
      ) : null}
    </Box>
  );
};

export default HSRNManualReview;
