import React from 'react';
import { Divider } from '@mui/material';

interface TimelineActionLinkDividerProps {
  index?: number;
}

interface TimelineActionLinkConditionDividerProps {
  index: number;
}

export const TimelineActionLinkDivider = (
  params: TimelineActionLinkDividerProps
) => (
  <Divider
    key={`divider-${params.index}`}
    orientation="vertical"
    flexItem
    style={{ marginLeft: '10px', marginRight: '10px' }}
    {...params}
  />
);

export const TimelineActionLinkConditionDivider = (
  params: TimelineActionLinkConditionDividerProps
) => {
  return params.index > 0 ? <TimelineActionLinkDivider {...params} /> : null;
};

export default TimelineActionLinkDivider;
