import React from 'react';
import { ILabelCellProps, StarSwitchCell } from 'components/gridFormatters';
import { useDispatch } from 'react-redux';
import {
  updateContactsPrimary,
  updateDirtyTabs,
} from 'store/patientdetails/patientDetailsSlice';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getContactType = (row: any) => {
  if ('address1' in row) {
    return 'addresses';
  }
  if ('address' in row) {
    return 'emails';
  }
  if ('number' in row) {
    return 'phones';
  }
  throw new Error('This row is not covered');
};

interface IPrimaryCellProps extends ILabelCellProps {
  disabled?: boolean;
}

export const PrimaryCell = (props: IPrimaryCellProps) => {
  const { row } = props;
  const dispatch = useDispatch();
  const updatePrimary = (value: boolean) => {
    dispatch(
      updateContactsPrimary({
        id: row.type,
        value: value,
        type: getContactType(row),
      })
    );
    dispatch(updateDirtyTabs('Contact'));
  };
  return <StarSwitchCell {...props} changeValue={updatePrimary} />;
};
