import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const TimelineMoreInfoFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  if (props.row.externalReferenceId) {
    return (
      <>
        <IconButton
          aria-label="more info"
          data-testid="more-info"
          onClick={(e: React.MouseEvent<HTMLElement>) =>
            setAnchorEl(e.currentTarget)
          }
          style={{ padding: '2px' }}
          size="large"
        >
          <Icon
            title="More info"
            icon={ICONS.Info}
            size={14}
            color={COLORS.GREY100}
            hoverColor={COLORS.SYMPHONY_BLUE}
          />
        </IconButton>
        <Popover
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Box component="div" p="12px">
            <Typography variant="body2" style={{ paddingBottom: '4px' }}>
              External Reference ID
            </Typography>
            <Typography>{props.row.externalReferenceId}</Typography>
          </Box>
        </Popover>
      </>
    );
  }
  return null;
};

export default TimelineMoreInfoFormatter;
