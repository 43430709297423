import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import ReminderImportanceFormatter from '../formatters/ReminderImportanceFormatter';

const ReminderImportanceProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={ReminderImportanceFormatter}
    {...props}
  />
);

export default ReminderImportanceProvider;
