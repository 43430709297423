import React from 'react';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Link from '@mui/material/Link';
import { COLORS } from 'consts/styles';
import { selectProvider } from 'store/patientdetails/patientDetails.careTeam.slice';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledLink = styled(Link)({
  marginLeft: '4px',
  marginRight: '4px',
  fontWeight: 500,
  color: COLORS.SYMPHONY_BLUE,
  fontSize: 14,
  cursor: 'pointer',
});

export const ProviderCell = (props: ILabelCellProps) => {
  const { row } = props;
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      selectProvider({
        id: row?.provider?.id,
      })
    );
  };
  return (
    <Table.Cell {...props} style={CellComponentStyle}>
      <StyledLink
        onClick={onClick}
        title="Opens provider details"
        data-cy="View"
      >
        {row?.provider?.name}
      </StyledLink>
    </Table.Cell>
  );
};
