import React, { useEffect, useState } from 'react';
import { ReminderStateDto } from 'graphql/graphqlTypes';
import { useChangeReminderStateMutation } from 'graphql/hooks/changeReminderState';
import Loader from 'components/loader';
import StyledLink from './StyledLink';
import Confirmation from '../modal/Confirmation';
import { IReminderLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const ReminderDeleteLink = (props: IReminderLinkProps) => {
  const [showReminderDeleteConfirmation, setShowReminderDeleteConfirmation] =
    useState(false);

  const [
    changeReminderState,
    { isLoading: isUpdatingReminder, isSuccess: isSuccessReminder },
  ] = useChangeReminderStateMutation();

  const onDeleteReminder = (reminderId: number) => {
    changeReminderState({
      model: {
        reminderId: Number(reminderId),
        state: ReminderStateDto.Deleted,
      },
    });
  };

  useEffect(
    () => {
      if (isSuccessReminder && props.callback) {
        props.callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessReminder]
  );

  return (
    <>
      <CustomTooltip title={props.title ?? 'Delete this reminder'}>
        <StyledLink
          onClick={() => setShowReminderDeleteConfirmation(true)}
          data-cy="Delete"
        >
          Delete
        </StyledLink>
      </CustomTooltip>
      <Confirmation
        open={showReminderDeleteConfirmation}
        title="Delete Reminder?"
        okButtonText="Delete"
        okEvent={() => {
          setShowReminderDeleteConfirmation(false);
          onDeleteReminder(props.reminderId);
        }}
        cancelEvent={() => setShowReminderDeleteConfirmation(false)}
      >
        <span>Are you sure you want to delete this reminder?</span>
      </Confirmation>
      <Loader active={isUpdatingReminder} />
    </>
  );
};

export default ReminderDeleteLink;
