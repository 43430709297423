import React, { ChangeEvent, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const CommentFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const { note, isReadOnly, id } = props.value;
  const [comment, changeComment] = useState<string>(note ?? '');
  return isReadOnly ? (
    <CustomTooltip title={comment}>
      <Typography variant="body1" noWrap>
        {comment}
      </Typography>
    </CustomTooltip>
  ) : (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      value={comment}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        changeComment(event.target.value)
      }
      onBlur={() => props.value.changeComment(id, comment)}
    />
  );
};

const CommentProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={CommentFormatter} {...props} />
);

export default CommentProvider;
