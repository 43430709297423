import {
  BaseComponent,
  ChecklistCategory,
  ChecklistItem,
  ConditionTarget,
  ConditionViewModel,
  DateTimeComponent,
  OrderableDependentConditionType,
} from 'graphql/graphqlTypes';
import {
  ChecklistStorageType,
  IAllDocumentsState,
  IChecklistComponent,
} from './types';
import {
  MOMENT_ISO_FORMAT,
  MOMENT_TIME_FORMAT,
  MOMENT_TIME_SECONDS_FORMAT,
} from 'components/constants';
import { ATTRIBUTE_TYPE } from 'backend/types/entityAttributeDef';
import moment from 'moment/moment';

export interface ConditionWithMeta {
  ConditionViewModel: ConditionViewModel;
  checklistItem: ChecklistItem;
}

const getDependentComponent = (
  item: ChecklistItem,
  componentId: string,
  checklistComponents: { [key: string]: IChecklistComponent }
): BaseComponent | undefined => {
  const dependentComponents: BaseComponent[] = [];

  if (!('lines' in item)) {
    return;
  }
  item.lines.forEach((line) =>
    line.components.forEach((component) => {
      if (
        component.id === componentId &&
        (component.componentType === 'CheckboxRadioButtonComponent' ||
          component.componentType === 'EntityAttributeComponent')
      ) {
        dependentComponents.push(component);
      }
    })
  );

  if (dependentComponents.length > 0) {
    return checklistComponents[dependentComponents[0].uniqueID].component;
  }
};

export const getChecklistItems = (
  categories: ChecklistCategory[]
): { categoryId: string; item: ChecklistItem }[] => {
  return (
    categories?.flatMap((category) =>
      category.items
        .map((item) => {
          return {
            categoryId: category.id,
            item: item,
          };
        })
        .concat(getChecklistItems(category.subCategories))
    ) ?? []
  );
};

const getConditionsWithMeta = (
  checklistItems: { categoryId: string; item: ChecklistItem }[]
): ConditionWithMeta[] =>
  checklistItems
    .flatMap((checklistItem) => {
      if (
        'conditions' in checklistItem.item &&
        checklistItem.item.conditions?.length > 0
      ) {
        return checklistItem.item.conditions.map((condition) => {
          const conditionWithMeta: ConditionWithMeta = {
            ConditionViewModel: condition,
            checklistItem: checklistItem.item,
          };
          return conditionWithMeta;
        });
      }
      return [];
    })
    .filter((item) => item);

export const getChecklistItemsWithConditions = (
  checklistItems: { categoryId: string; item: ChecklistItem }[]
): {
  checklistItem: ChecklistItem;
  conditions: ConditionWithMeta[];
}[] => {
  const conditionsWithMeta = getConditionsWithMeta(checklistItems);

  const itemWithConditions: {
    checklistItem: ChecklistItem;
    conditions: ConditionWithMeta[];
  }[] = [];

  checklistItems.forEach((item) => {
    const categoryId = item.categoryId;
    const orderableId = item.item.orderableId;

    const items = conditionsWithMeta.filter((actionCondition) => {
      const condition = actionCondition.ConditionViewModel;
      return (
        (!condition.categoryId || condition.categoryId === categoryId) &&
        (!condition.orderableId || condition.orderableId === orderableId)
      );
    });

    if (items.length > 0) {
      itemWithConditions.push({
        checklistItem: item.item,
        conditions: items,
      });
    }
  });
  return itemWithConditions;
};

const isCategoryVisible = (category: ChecklistCategory): boolean => {
  return (
    category.items.some((item) => item.isVisible) ||
    category.subCategories.some((cat) => isCategoryVisible(cat))
  );
};

function setCurrentDateForDateTime(
  checklistComponent: IChecklistComponent,
  careSiteNow: moment.Moment
) {
  const component = checklistComponent.component;
  if ('useCurrentDate' in component && component.useCurrentDate) {
    let newVal;
    if (
      component.componentType === 'DateTimeComponent' ||
      ('attributeType' in component &&
        component.attributeType === ATTRIBUTE_TYPE.DATETIME)
    ) {
      const date = careSiteNow.format(MOMENT_ISO_FORMAT);
      const time = careSiteNow.format(
        (component as DateTimeComponent).useSeconds
          ? MOMENT_TIME_SECONDS_FORMAT
          : MOMENT_TIME_FORMAT
      );
      newVal = `${date}T${time}`;
    } else {
      newVal = careSiteNow.startOf('day').format(MOMENT_ISO_FORMAT);
    }
    if (component.value !== newVal) {
      component.value = newVal;
    }
  }
}

export const getConditionTypeByAction = (
  condition: ConditionWithMeta,
  showByCategoryConditions: boolean | null,
  isActionEnabled: boolean | null
) => {
  if (condition.ConditionViewModel.targetType === ConditionTarget.Action) {
    // to item
    // by item to item is not implemented, maybe later
    return null;
  } else {
    // to category
    if (showByCategoryConditions === null) {
      return isActionEnabled;
    } else {
      return showByCategoryConditions || isActionEnabled;
    }
  }
};

export const getShowByActionConditions = (
  showByActionConditions: boolean | null,
  isComponentSelected: boolean,
  isActionEnabled: boolean
) => {
  if (showByActionConditions === null) {
    return isActionEnabled && isComponentSelected;
  } else {
    return showByActionConditions || (isActionEnabled && isComponentSelected);
  }
};

export const getShowByCategoryConditions = (
  showByCategoryConditions: boolean | null,
  isComponentSelected: boolean,
  isActionEnabled: boolean
) => {
  if (showByCategoryConditions === null) {
    return isActionEnabled && isComponentSelected;
  } else {
    return showByCategoryConditions || (isActionEnabled && isComponentSelected);
  }
};

const getComponentConditions = (
  component: BaseComponent | undefined,
  conditionVieModel: ConditionViewModel,
  defaultActionEnabled = true
) => {
  let isComponentSelected = false;
  if (component) {
    isComponentSelected =
      'selected' in component
        ? component.selected
        : component.value == conditionVieModel.conditionValue;
  } else {
    return [isComponentSelected, true];
  }

  return [isComponentSelected, defaultActionEnabled];
};

export const setLineComponents = (
  item: {
    checklistItem: ChecklistItem;
    conditions: ConditionWithMeta[];
  },
  isVisible: boolean,
  checklistComponents: { [key: string]: IChecklistComponent },
  careSiteNow: moment.Moment
) => {
  if ('lines' in item.checklistItem) {
    item.checklistItem.lines.forEach((line) =>
      line.components.forEach((comp) => {
        const checklistComponent = checklistComponents[comp.uniqueID];
        if (checklistComponent) {
          checklistComponent.isVisible = isVisible;
          if (isVisible) {
            setCurrentDateForDateTime(checklistComponent, careSiteNow);
          }
        }
      })
    );
  }
};

export const setComponentIsVisible = (
  item: {
    checklistItem: ChecklistItem;
    conditions: ConditionWithMeta[];
  },
  showByCategoryConditions: boolean | null,
  showByActionConditions: boolean | null,
  checklistComponents: { [key: string]: IChecklistComponent },
  careSiteNow: moment.Moment
) => {
  const isVisible =
    (showByCategoryConditions === null || showByCategoryConditions) &&
    (showByActionConditions === null || showByActionConditions);

  const isHsCondition =
    item?.conditions.length > 0 &&
    item?.conditions[0]?.checklistItem.type === 'healthservices';

  if (item.checklistItem.isVisible !== isVisible && !isHsCondition) {
    item.checklistItem.isVisible = isVisible;
    setLineComponents(item, isVisible, checklistComponents, careSiteNow);
  }
};

export const calculateActions = (
  state: IAllDocumentsState,
  type: ChecklistStorageType,
  careSiteNow: moment.Moment
) => {
  const checklist = state.documentsState[type].checklist;
  const selectedActions = state.documentsState[type].selectedActions;
  const checklistItems = getChecklistItems(checklist?.categories || []);
  const itemWithConditions = getChecklistItemsWithConditions(checklistItems);
  const checklistComponents = state.documentsState[type]?.checklistComponents;

  itemWithConditions.forEach((item) => {
    let showByCategoryConditions: boolean | null = null;
    let showByActionConditions: boolean | null = null;
    item.conditions.forEach((condition: ConditionWithMeta) => {
      let isActionEnabled =
        selectedActions[condition.checklistItem.uid] &&
        condition.checklistItem.isVisible;

      if (
        condition.ConditionViewModel.type ===
        OrderableDependentConditionType.ByAction
      ) {
        // by action
        showByCategoryConditions = getConditionTypeByAction(
          condition,
          showByCategoryConditions,
          isActionEnabled
        );
      } else {
        // by component
        const dependentComponent = getDependentComponent(
          condition.checklistItem,
          condition.ConditionViewModel.value || '',
          checklistComponents
        );

        let isComponentSelected = false;
        [isComponentSelected, isActionEnabled] = getComponentConditions(
          dependentComponent,
          condition.ConditionViewModel,
          isActionEnabled
        );

        if (
          condition.ConditionViewModel.targetType === ConditionTarget.Action
        ) {
          // to item
          showByActionConditions = getShowByActionConditions(
            showByActionConditions,
            isComponentSelected,
            isActionEnabled
          );
        } else {
          // to category
          showByCategoryConditions = getShowByCategoryConditions(
            showByCategoryConditions,
            isComponentSelected,
            isActionEnabled
          );
        }
      }
    });

    state.documentsState[type].conditionalItems = itemWithConditions.flatMap(
      (element) => element.checklistItem.orderableId
    );

    setComponentIsVisible(
      item,
      showByCategoryConditions,
      showByActionConditions,
      checklistComponents,
      careSiteNow
    );
  });

  (checklist?.categories || []).forEach((category: ChecklistCategory) => {
    category.isVisible = isCategoryVisible(category);
  });
};
