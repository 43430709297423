import React from 'react';
import { Box, IconButton } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import Typography from '@mui/material/Typography';

export interface IClaimsDetailsClose {
  resetSelectedClaimId: (claimId: number | null) => void;
}

const ClaimsDetailsClose = ({ resetSelectedClaimId }: IClaimsDetailsClose) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <Typography variant="subtitle2">Details</Typography>
    <IconButton
      aria-label="close"
      onClick={() => resetSelectedClaimId(null)}
      data-cy="close"
      size="large"
    >
      <Icon icon={ICONS.Close} size={24} color={COLORS.GREY100} />
    </IconButton>
  </Box>
);

export default ClaimsDetailsClose;
