import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import DateComponent from 'components/DateComponent';
import { validateDate } from './EpisodeSearchDialog.helper';
import { IEpisodeAdditionalFilters } from './types';

interface IEpisodeFilterButtonProps {
  filters: IEpisodeAdditionalFilters;
  onChangeFilter: (values: IEpisodeAdditionalFilters) => void;
}

const StyledFilterButton = styled(Button)<{ $activeButton: boolean }>`
  height: 32px;
  ${({ $activeButton }) =>
    $activeButton &&
    `background-color: ${COLORS.PALE_SKY_BLUE};
    `}
`;

const EpisodeFilterButton = (props: IEpisodeFilterButtonProps) => {
  const { filters, onChangeFilter } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const popoverOpen = Boolean(anchorEl);
  const { handleSubmit, control, reset, getValues } =
    useForm<IEpisodeAdditionalFilters>({
      defaultValues: filters,
    });
  const formValues = {
    ...useWatch({ control }),
    ...getValues(),
  } as IEpisodeAdditionalFilters;
  const isValid =
    validateDate(formValues.episodeStartDate) &&
    validateDate(formValues.episodeEndDate);

  const handleFilterButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const onSubmit = (values: IEpisodeAdditionalFilters) => {
    onChangeFilter(values);
  };

  const handleButtonApply = () => {
    if (isValid) {
      handleSubmit(onSubmit)();
      setAnchorEl(null);
    }
  };

  const handleButtonReset = () => {
    reset();
    setAnchorEl(null);
    onChangeFilter({} as IEpisodeAdditionalFilters);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const title = 'Filters';
  const right = 'right';

  return (
    <Box>
      <StyledFilterButton
        variant="outlined"
        size="small"
        onClick={handleFilterButtonClick}
        startIcon={
          <Icon icon={ICONS.Settings} size="14" color={COLORS.SYMPHONY_BLUE} />
        }
        $activeButton={popoverOpen}
      >
        <Typography variant="h5">{title}</Typography>
      </StyledFilterButton>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        title={title}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: right,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: right,
        }}
      >
        <Box
          sx={{
            width: '320px',
            padding: '28px',
          }}
        >
          <Typography variant="body2" pb="14px" fontWeight="700">
            {title}
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">Received Date</Typography>
                <Controller
                  control={control}
                  name="episodeStartDate"
                  render={({ field }) => (
                    <DateComponent
                      title="From Date"
                      placeholder="mm/dd/yyyy"
                      {...field}
                    />
                  )}
                />
                <Typography display="inline-flex" sx={{ padding: '6px 12px' }}>
                  to
                </Typography>
                <Controller
                  control={control}
                  name="episodeEndDate"
                  render={({ field }) => (
                    <DateComponent
                      title="To Date"
                      placeholder="mm/dd/yyyy"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Diagnosis Code</Typography>
                <Controller
                  control={control}
                  name="dXCode"
                  render={({ field }) => (
                    <TextField
                      title="Diagnosis Code"
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Procedure Code</Typography>
                <Controller
                  control={control}
                  name="cPTCode"
                  render={({ field }) => (
                    <TextField
                      title="Procedure Code"
                      type="text"
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ marginRight: '14px' }}
                    onClick={handleButtonReset}
                  >
                    Reset
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleButtonApply}
                    disabled={!isValid}
                  >
                    Apply
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Popover>
    </Box>
  );
};

export default EpisodeFilterButton;
