import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetCmUserCaseLoadAndReminderCountQueryVariables = Types.Exact<{
  careSiteId: Types.Scalars['Int'];
}>;

export type GetCmUserCaseLoadAndReminderCountQuery = {
  __typename?: 'EntitiesQuery';
  getCmUserCaseLoadAndReminderCount: Array<{
    __typename?: 'UserCaseLoadAndReminderCount';
    userId: number;
    userName: string;
    totalCaseLoad: number;
    caseManagementCaseLoad: number;
    careTransitionCaseLoad: number;
    eRFollowUpCaseLoad: number;
    reminderDueThisWeek: number;
    reminderDueToday: number;
    reminderOverDue: number;
  }>;
};

export const GetCmUserCaseLoadAndReminderCountDocument = `
    query getCmUserCaseLoadAndReminderCount($careSiteId: Int!) {
  getCmUserCaseLoadAndReminderCount(careSiteId: $careSiteId) {
    userId
    userName
    totalCaseLoad
    caseManagementCaseLoad
    careTransitionCaseLoad
    eRFollowUpCaseLoad
    reminderDueThisWeek
    reminderDueToday
    reminderOverDue
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCmUserCaseLoadAndReminderCount: build.query<
      GetCmUserCaseLoadAndReminderCountQuery,
      GetCmUserCaseLoadAndReminderCountQueryVariables
    >({
      query: (variables) => ({
        document: GetCmUserCaseLoadAndReminderCountDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetCmUserCaseLoadAndReminderCountQuery,
  useLazyGetCmUserCaseLoadAndReminderCountQuery,
} = injectedRtkApi;
