import React from 'react';
import { useDispatch } from 'react-redux';
import { openChecklist } from 'store/ui/modals/checklist';
import StyledLink from './StyledLink';
import { IChecklistLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const ChecklistViewLink = (props: IChecklistLinkProps) => {
  const dispatch = useDispatch();

  return (
    <CustomTooltip
      title={props.title ?? 'Opens document in a read-only window'}
    >
      <StyledLink
        onClick={() =>
          dispatch(openChecklist({ checklistId: Number(props.checklistId) }))
        }
        data-cy="View"
      >
        View
      </StyledLink>
    </CustomTooltip>
  );
};

export default ChecklistViewLink;
