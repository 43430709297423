import React from 'react';
import { ILabelCellProps } from 'components/gridFormatters';
import { attachmentColumns } from '../columnSettings/attachmentColumns';
import { AttachmentTypeCell } from './attachmentTypeCell';
import { AttachmentActionsCell } from './attachmentActionsCell';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import ActionLink from 'components/actionlink';
import { CellComponentPadded } from 'components/gridFormatters/CellComponentPadded';

export const AttachmentLabelCell = (
  props: ILabelCellProps & {
    episodeId?: number;
    readonly?: boolean;
    isAttachmentsTab?: boolean;
    isActionsDisabled?: boolean;
  }
) => {
  const {
    value,
    column,
    episodeId,
    children,
    row,
    readonly,
    isAttachmentsTab,
    isActionsDisabled,
  } = props;

  const cellRenderer = React.useCallback(() => {
    switch (column.name) {
      case attachmentColumns.actions:
        return (
          <>
            {readonly ? (
              <CellComponentPadded {...props}>
                <ActionLink
                  key={`AttachmentView${row.id}`}
                  type="AttachmentView"
                  entityId={Number(row.id)}
                  fileName={row.name}
                  label="View"
                />
              </CellComponentPadded>
            ) : (
              <AttachmentActionsCell
                {...props}
                episodeId={episodeId}
                isAttachmentsTab={isAttachmentsTab}
                attachmentType={row.type?.name}
                isActionsDisabled={Boolean(isActionsDisabled)}
              />
            )}
          </>
        );
      case attachmentColumns.type:
        return (
          <AttachmentTypeCell
            {...props}
            isActionsDisabled={Boolean(isActionsDisabled)}
          />
        );
      case attachmentColumns.attachmentCategory:
        return <CellComponentPadded {...props} />;
      case attachmentColumns.createdOn:
        return (
          <CellComponentPadded {...props}>
            {value ? formatDateTime(value) : ''}
          </CellComponentPadded>
        );
      default:
        return <CellComponentPadded {...props} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column.name, props]);

  return (
    <>
      {children}
      {cellRenderer()}
    </>
  );
};
