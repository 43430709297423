import React from 'react';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';

export const TableEditCell = (
  props: TableEditColumn.CellProps & { style?: React.CSSProperties }
) => (
  <TableEditColumn.Cell
    {...props}
    style={{ padding: '0px 16px 0px 4px', verticalAlign: 'middle' }}
  >
    <Box display="flex" alignItems="flex-start" style={props.style}>
      {props.children}
    </Box>
  </TableEditColumn.Cell>
);
