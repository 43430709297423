import React from 'react';
import {
  EditingCell,
  ILabelCellProps,
  LabelCell,
} from 'components/gridFormatters';
import { PrimaryCell } from './cells/primaryCell';
import { EmailEditCell } from './cells/emailEditCell';
import { EmailViewCell } from './cells/emailViewCell';
import { EditCellValidationProps } from './contactsGrid';

export const emailColumns = [
  { name: 'type', title: 'Type' },
  { name: 'address', title: 'Email Address' },
  { name: 'isPrimary', title: 'Primary' },
];

export const emailColumnExtension = [
  { columnName: 'type', width: '20%' },
  { columnName: 'address' },
  { columnName: 'isPrimary', width: '60px' },
];

export const EditEmail = (props: EditCellValidationProps) => {
  const { column } = props;
  switch (column.name) {
    case 'isPrimary':
      return <PrimaryCell {...props} />;
    case 'address':
      return <EmailEditCell {...props} />;
    case 'type':
      return <LabelCell {...props} />;
    default:
      return <EditingCell {...props} />;
  }
};

interface ILabelEmailProps extends ILabelCellProps {
  disabled?: boolean;
}

export const LabelEmail = ({ disabled, ...props }: ILabelEmailProps) => {
  switch (props.column.name) {
    case 'type':
      return <LabelCell {...props} />;
    case 'isPrimary':
      return <PrimaryCell {...props} disabled={disabled} />;
    case 'address':
      return <EmailViewCell {...props} />;
    default:
      return <LabelCell {...props} />;
  }
};
