import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { OutlinedInput } from '@mui/material';

export const StyledOutlinedInput = styled(OutlinedInput)`
  margin-left: 2px;
  margin-right: 8px;
  font-size: 14px;
  width: 100%;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  & ::-webkit-input-placeholder {
    color: ${COLORS.GREY100}!important;
    opacity: 1;
    font-weight: 400;
  }
  & ::-moz-placeholder {
    color: ${COLORS.GREY100}!important;
  }
  & ::-ms-input-placeholder {
    color: ${COLORS.GREY100}!important;
  }
`;
