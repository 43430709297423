import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type CheckIsPatientActiveQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type CheckIsPatientActiveQuery = {
  __typename?: 'EntitiesQuery';
  checkIsPatientActive: boolean;
};

export const CheckIsPatientActiveDocument = `
    query checkIsPatientActive($patientId: Int!) {
  checkIsPatientActive(patientId: $patientId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkIsPatientActive: build.query<
      CheckIsPatientActiveQuery,
      CheckIsPatientActiveQueryVariables
    >({
      query: (variables) => ({
        document: CheckIsPatientActiveDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useCheckIsPatientActiveQuery,
  useLazyCheckIsPatientActiveQuery,
} = injectedRtkApi;
