import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const InstructionBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const PreviewInstruction = ({ text }: { text: string }) => (
  <InstructionBox>
    <Icon size={34} icon={ICONS.PreviewFile} style={{ marginBottom: '6px' }} />
    <Typography variant="subtitle2" style={{ color: COLORS.GREY100 }}>
      {text}
    </Typography>
  </InstructionBox>
);
