import { ILabelCellProps, LabelCell } from 'components/gridFormatters';
import React from 'react';
import { COLORS } from 'consts/styles';
import { isValidEmail } from '../contacts.helpers';

export const EmailViewCell = (props: ILabelCellProps) => {
  const { value } = props;
  const isValid = isValidEmail(value);
  const color = isValid ? COLORS.BLACK : COLORS.RED100;
  return <LabelCell {...props} cellColor={color} />;
};
