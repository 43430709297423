import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { TableSelection } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const SelectCellWithoutRowClick = (props: TableSelection.CellProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selected, tableRow, tableColumn, ...restProps } = props;
  return (
    <td
      style={{
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '8px 0px 0px 10px',
        width: '30px',
      }}
    >
      <Checkbox
        data-testid="SelectionCheckBox"
        icon={<Icon icon={ICONS.Empty} size={24} color={COLORS.LIGHT_GREY} />}
        checked={selected}
        onChange={props.onToggle}
        checkedIcon={
          <Icon icon={ICONS.Selected} size={24} color={COLORS.SYMPHONY_BLUE} />
        }
        {...restProps}
      />
    </td>
  );
};

export default SelectCellWithoutRowClick;
