export enum ATTRIBUTE_TYPE {
  LOOKUP = 'LookupAttributeType',
  TEXT = 'TextAttributeType',
  BOOL = 'BoolAttributeType',
  DATE = 'DateAttributeType',
  DATETIME = 'DateTimeAttributeType',
  FLOAT = 'FloatAttributeType',
  INT = 'IntAttributeType',
  REFERENCE = 'ReferenceAttributeType',
}
