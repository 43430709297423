import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { IProvider } from 'components/providerSearchDialog/types';
import { keyBy, sortBy, take } from 'lodash';
import { Filter, ProviderOption, ProviderPopper } from '.';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { getProviderSearchDisplayValue } from 'util/helpers/provider.helpers';
import Icon, { ICONS } from 'components/icon';

const StyledAutocomplete = styled(Autocomplete)({
  width: '440px',
  height: '32px',
  marginRight: '20px',
  '& .MuiAutocomplete-inputRoot': {
    padding: '3.5px 12px!important',
  },
  backgroundColor: COLORS.WHITE,
});

const StyledIconButton = styled(IconButton)`
  padding: 4px;
`;

export interface IProviderQuickSearchProps {
  isLoading: boolean;
  providers: IProvider[];
  onSelect: (selectedId: number, selectedProviderAddressId: number) => void;
  searchTerm: string;
  useDefaultProviders: boolean;
  onSearch: (term: string) => void;
  onViewAllClick: () => void;
  onCantFindClick: () => void;
  providerAddressUiId: string;
  onSelectProviderAddressUiId: (uiId: string) => void;
  isReadOnly: boolean;
  isUpdateActionValueRequestFailed: boolean;
  providerType: string;
}

const ProviderQuickSearch = ({
  isLoading,
  providers,
  searchTerm,
  useDefaultProviders,
  onSearch,
  onSelect,
  onViewAllClick,
  onCantFindClick,
  providerAddressUiId,
  onSelectProviderAddressUiId,
  isReadOnly,
  isUpdateActionValueRequestFailed,
  providerType,
}: IProviderQuickSearchProps) => {
  const [options, setOptions] = useState<{
    [providerAddressUiId: string]: IProvider;
  }>({});

  const [filter, setFilter] = useState<Filter>(Filter.All);
  const [opened, setOpened] = useState<boolean | undefined>(
    !useDefaultProviders && providerAddressUiId === ''
  );

  useEffect(() => {
    if (!isLoading) {
      const groupedOptions = keyBy(providers, 'providerAddressUiId');
      setOptions(groupedOptions);
    }
  }, [isLoading, providers]);

  const onSelectionChange = (newValue: unknown) => {
    if (newValue === null || newValue === undefined) {
      return;
    }

    const split = (newValue as string).toString().split('_');
    const newProviderId = Number(split[0]);
    if (split.length > 1) {
      const providerAddressId = Number(split[1]);
      onSelect(newProviderId ?? 0, providerAddressId ?? 0);
    } else {
      onSelect(newProviderId ?? 0, 0);
    }

    onSelectProviderAddressUiId(newValue as string);
  };

  const optionIds = Object.keys(options).map((key: string) => key);

  const filtered = {
    [Filter.All]: () => optionIds,
    [Filter.Favorite]: () =>
      optionIds.filter((uiId: string) => options[uiId]?.isFavorite ?? false),
    [Filter.Recent]: () =>
      optionIds.filter((uiId: string) => options[uiId]?.isRecent ?? false),
  }[filter]();

  const clipped = take(filtered, 20);

  const sortedOptions = sortBy<string>(
    clipped,
    [
      (id) => (options[id].isFavorite ? 0 : 1),
      (id) => (options[id].lastName || '').toLowerCase(),
      (id) => (options[id].firstName || '').toLowerCase(),
    ],
    ['asc']
  );

  return (
    <>
      <StyledAutocomplete
        data-testid="provider-quick-search-autocomplete"
        getOptionLabel={(value: unknown) => {
          const option = options[value as string];
          return getProviderSearchDisplayValue(option);
        }}
        onChange={(_, value) => {
          onSelectionChange(value);
          setOpened(false);
        }}
        options={sortedOptions}
        autoComplete
        openOnFocus={opened}
        size="small"
        loading={isLoading}
        includeInputInList
        value={providerAddressUiId ? providerAddressUiId : null}
        inputValue={searchTerm}
        disabled={isReadOnly}
        onInputChange={(event, value: string) => {
          if (
            event != null &&
            (event.type === 'change' || event.type === 'click')
          ) {
            onSearch(value);
          }
          setOpened(true);
        }}
        renderOption={(params, option: unknown) => (
          <li {...params} style={{ padding: 0 }}>
            <ProviderOption {...options[option as number]} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            type="text"
            {...params}
            variant="outlined"
            fullWidth={true}
            error={isUpdateActionValueRequestFailed}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {searchTerm.length === 0 ? null : (
                    <InputAdornment
                      position="end"
                      style={{ position: 'absolute', right: '30px' }}
                    >
                      <StyledIconButton onClick={() => onSearch('')}>
                        <Icon size={20} icon={ICONS.Remove} />
                      </StyledIconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        )}
        PopperComponent={(props) => (
          <ProviderPopper
            {...props}
            totalCount={optionIds.length}
            providerType={providerType}
            filter={{
              onFilterChange: (value: Filter) => setFilter(value),
              active: filter,
            }}
            onViewAllClick={onViewAllClick}
            onCantFindClick={onCantFindClick}
          />
        )}
      />
    </>
  );
};

export default ProviderQuickSearch;
