import { TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { usePrevious } from 'hooks';

import {
  EntityAttribute,
  Patient,
  PatientInfoInput,
} from 'graphql/graphqlTypes';
import { useCreateOrUpdatePatientMutation } from 'graphql/hooks/createOrUpdatePatient';
import Loader from 'components/loader';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';

interface IPatientNotesParams {
  patient: Patient;
  attributes?: EntityAttribute[];
}

const PatientNotes = ({ patient, attributes }: IPatientNotesParams) => {
  const patientNote = attributes?.filter(
    (attr: EntityAttribute) => attr.name === 'Note'
  )[0]?.value;

  const [note, setNote] = useState(patientNote);

  const [updatePatient, { isLoading }] = useCreateOrUpdatePatientMutation();
  const previousNote = usePrevious(note);

  const canEditMember =
    hasUserPrivilege(Privilege.EditMember) &&
    hasUserPrivilege(Privilege.EditMemberTab);

  useEffect(() => setNote(patientNote), [patientNote]);
  const updatePatientNote = () => {
    if (previousNote === note) {
      return;
    }
    const getEntityAttributeFlag = (attribute: string) =>
      attributes?.find((item) => item?.name === attribute)?.value === 'True' ??
      false;

    const patientSaveObject: PatientInfoInput = {
      id: patient.id.toString(),
      areTagsUpdated: false,
      deniesActiveMedications: getEntityAttributeFlag(
        'DeniesActiveMedications'
      ),
      noKnownDrugAllergies: getEntityAttributeFlag('NoKnownDrugAllergies'),
      reviewed: false,
      values: [{ key: 'Note', value: note }],
      areTeamMembersUpdated: false,
      areProvidersUpdated: false,
      isFamilyUpdated: false,
      areMedsUpdated: false,
    };
    updatePatient({
      patient: patientSaveObject,
      memberReminders: [],
      episodeReminders: [],
      attachments: {
        patientId: Number(patient.id),
        attachments: [],
        episodeAttachments: [],
      },
      careGaps: {
        patientId: Number(patient.id),
      },
      attachmentsToMove: [],
    });
  };
  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setNote(event.target.value);
  };

  return (
    <>
      <Typography variant="body2" style={{ paddingBottom: '15px' }}>
        Additional notes
      </Typography>
      <TextField
        type="text"
        multiline
        size="small"
        value={note}
        onChange={onChange}
        fullWidth
        variant="outlined"
        disabled={!canEditMember}
        maxRows={2}
        minRows={2}
        onBlur={updatePatientNote}
      />
      <Loader active={isLoading} />
    </>
  );
};

export default PatientNotes;
