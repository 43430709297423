import { Box, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import React from 'react';

const EmptyServices = () => {
  return (
    <Box
      display="flex"
      bgcolor={COLORS.GREY4}
      py="20px"
      my="12px"
      justifyContent="center"
    >
      <Typography variant="body2" style={{ color: COLORS.GREY100 }}>
        {`Click on the "Add Code" button to add items`}
      </Typography>
    </Box>
  );
};

export default EmptyServices;
