import { AttributeComparison, Maybe } from 'graphql/graphqlTypes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';

export const useAttributeComparison = (
  storageType: ChecklistStorageType,
  initialPrimaryComparison: Maybe<AttributeComparison> | undefined,
  initialSecondaryComparison: Maybe<AttributeComparison> | undefined
) => {
  const [primaryComparison, setPrimaryComparison] =
    useState<AttributeComparison>(initialPrimaryComparison ?? {});
  const [secondaryComparison, setSecondaryComparison] =
    useState<AttributeComparison>(initialSecondaryComparison ?? {});

  const entityAttributesBeingSet = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.entityAttributesBeingSet
  );

  useEffect(() => {
    if (entityAttributesBeingSet) {
      const primaryValidator = entityAttributesBeingSet.find(
        (x) => x.fieldName === primaryComparison?.fieldName
      );

      if (primaryValidator) {
        setPrimaryComparison((current) => {
          return {
            ...current,
            value: primaryValidator.value,
          };
        });
      }

      const secondaryValidator = entityAttributesBeingSet.find(
        (x) => x.fieldName === secondaryComparison?.fieldName
      );

      if (secondaryValidator) {
        setSecondaryComparison((current) => {
          return {
            ...current,
            value: secondaryValidator.value,
          };
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityAttributesBeingSet]);

  return { primaryComparison, secondaryComparison };
};
