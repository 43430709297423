import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import DatePicker from './DatePicker';
import { validateDateRange } from 'util/validationUtils';
import SelectTextField from './SelectTextField';
import {
  changeEndDate,
  changeStartDate,
  changeUnitType,
  changeValue,
  formatDate,
  getDifferenceInDays,
  getItemEndDate,
  getItemStartDate,
  getItemUnits,
  getItemUnitType,
  getNumberOrNull,
} from './ServiceRow.helpers';
import { isNumber } from 'lodash';
import moment from 'moment';
import {
  HealthServiceCodesActionValue,
  ILookupValue,
} from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';

interface IServiceRowProps {
  type: 'Requested' | 'Approved';
  item: HealthServiceCodesActionValue;
  onChange: (state: HealthServiceCodesActionValue) => void;
  unitTypes: ILookupValue[];
  readOnly: boolean;
  overallDates: { start: Date | null; end: Date | null };
  validationDispatch: (
    errorMessage: string,
    error: boolean,
    id?: string
  ) => void;
}

export const ServiceRow = (props: IServiceRowProps) => {
  const {
    item,
    onChange,
    unitTypes,
    type,
    readOnly,
    overallDates,
    validationDispatch,
  } = props;

  const [dateErrors, setDateErrors] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [unitTypeError, setUnitTypeError] = useState(false);

  const validateRow = () => {
    const startError = handleValidation(getStartDate());
    const endError = handleValidation(getEndDate());
    const dateValidationError = startError.hasError || endError.hasError;

    setDateErrors(!readOnly && dateValidationError);
    setValueError(!readOnly && !isNumber(getValue()));
    setUnitTypeError(!readOnly && !getUnitTypeId());
  };
  useEffect(() => {
    validationDispatch(
      `missing data for individual service ${item.code}${
        item.description ?? ''
      }`,
      dateErrors || valueError || unitTypeError,
      item.id.toString()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateErrors, valueError, unitTypeError]);

  const handleValidation = (date: Date | null) =>
    validateDateRange(
      date ? moment(date).toDate() : null,
      overallDates.start ? moment(overallDates.start).toDate() : null,
      overallDates.end ? moment(overallDates.end).toDate() : null,
      !readOnly,
      'The date is outside the Overall range'
    );

  const getStartDate = () => getItemStartDate(item, type);
  const getEndDate = () => getItemEndDate(item, type);
  const getUnitType = () => getItemUnitType(item, type);

  const getUnitTypeId = () =>
    unitTypes?.find((x) => x.name === getUnitType())?.id;

  const getValue = () => {
    if (getUnitType() === 'Days') {
      return getDifferenceInDays(item, type);
    }
    return getItemUnits(item, type);
  };

  const handleStartDateChange = (date: Date | null | unknown) =>
    onChange(changeStartDate(item, type, date as Date));

  const handleEndDateChange = (date: Date | null | unknown) =>
    onChange(changeEndDate(item, type, date as Date));

  useEffect(() => {
    validateRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, overallDates, readOnly, unitTypes]);

  const handleUnitTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newId = Number(e.target.value);
    const name = unitTypes.find((x) => x.id === newId)?.name;
    const newState = changeUnitType(item, type, name!);
    onChange(newState);
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(changeValue(item, type, getNumberOrNull(e.target.value)));

  useEffect(
    () => () => {
      validationDispatch('', false, item.id.toString());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box my="20px">
      <Box display="flex">
        <Box display="flex" alignItems="end" width="10%" minWidth="80px">
          <Typography
            variant="body2"
            style={{
              paddingBottom: '6px',
            }}
          >
            {type}
          </Typography>
        </Box>
        <Box
          width="25%"
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          pr="20px"
        >
          <Typography variant="body1">Start Date</Typography>
          <DatePicker
            value={formatDate(getStartDate())}
            onChange={handleStartDateChange}
            error={dateErrors}
            readOnly={readOnly}
          />
        </Box>
        <Box
          width="25%"
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          pr="20px"
        >
          <Typography variant="body1">End Date</Typography>
          <DatePicker
            value={formatDate(getEndDate())}
            onChange={handleEndDateChange}
            error={dateErrors}
            readOnly={readOnly}
          />
        </Box>
        <Box width="15%" paddingRight="12px">
          <Typography variant="body1">Unit Type</Typography>
          <SelectTextField
            onChange={handleUnitTypeChange}
            readOnly={readOnly}
            width="90px"
            value={getUnitTypeId()}
            menuItems={unitTypes}
            error={unitTypeError}
            hideEmptySelect={true}
          />
        </Box>
        <Box width="15%">
          <Typography variant="body1">Value</Typography>
          <TextField
            type="tel"
            size="medium"
            value={getValue() ?? ''}
            disabled={readOnly || getUnitType() === 'Days'}
            onChange={handleValueChange}
            style={{ width: '68px' }}
            variant="outlined"
            error={valueError}
          />
        </Box>
      </Box>

      {dateErrors && (
        <Box display="flex">
          <Box width="10%" minWidth="80px">
            &nbsp;
          </Box>
          <Typography style={{ color: COLORS.RED100 }}>
            The date is outside the Overall range
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ServiceRow.displayName = 'ServiceRow';

export default ServiceRow;
