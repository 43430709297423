import React from 'react';
import { Box, Typography } from '@mui/material';

import {
  HealthServiceCodesActionModel,
  ILookupValue,
  LookupValue,
} from 'graphql/graphqlTypes';
import {
  getDefaultUnitType,
  isOverallStatus,
  overallStatus,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthServiceV2helper';
import { OverallRNReview } from './OverallRNReview';
import { OverallHealthServiceReview } from './OverallHealthServiceReview';
import { healthServiceDisplayMode } from 'components/actions/sections/SectionBody/HealthServiceDisplayMode';
import {
  HealthServiceAuthorizationType,
  UnitTypes,
} from 'components/actions/sections/SectionBody/Items/HealthService/types';

export interface IOverallProps {
  item: HealthServiceCodesActionModel;
  unitTypes: ILookupValue[];
  isRNReview?: boolean;
  episodeNumber?: string | null;
  hsRules: number;
  determinationReason?: LookupValue | null;
  hsAuthorizationType: number;
}

const OverallReview = (props: IOverallProps) => {
  const {
    unitTypes,
    item,
    isRNReview,
    episodeNumber,
    determinationReason,
    hsAuthorizationType,
    hsRules,
  } = props;
  const defaultUnitType = getDefaultUnitType(unitTypes, UnitTypes.units);
  const unitType =
    unitTypes.find((x) => x.id === item?.overallReqUnitType?.id) ??
    defaultUnitType;
  const isOverallStatusApproved = isOverallStatus(item, 'Approved');
  const overallStatusVal = overallStatus(item);
  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap="20px"
      data-testid="overallReview"
    >
      <Box display="flex" alignContent="end">
        <Typography variant="body2">
          {hsAuthorizationType === HealthServiceAuthorizationType.NonSet
            ? 'Overall Request'
            : 'Requested:'}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap="20px">
        {!isRNReview && (
          <OverallHealthServiceReview
            item={item}
            unitType={unitType?.name?.toString()}
          />
        )}
        {isRNReview && (
          <OverallRNReview
            episodeNumber={episodeNumber}
            isOverallStatusApproved={isOverallStatusApproved}
            overallStatus={overallStatusVal}
          />
        )}
        {hsAuthorizationType === HealthServiceAuthorizationType.Episode &&
          hsRules === healthServiceDisplayMode.finalOutcome && (
            <Box>
              <Typography variant="body2">
                {determinationReason?.name}
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
};
export default OverallReview;
