import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Checkbox, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import {
  updateDirtyTabs,
  updatePatientTags,
} from 'store/patientdetails/patientDetailsSlice';
import { PatientEditTagsTabViewModel } from 'graphql/graphqlTypes';

interface ITagActiveFormatterProps
  extends DataTypeProvider.ValueFormatterProps {
  disabled?: boolean;
}

const TagActiveFormatter = (props: ITagActiveFormatterProps) => {
  const patientTags = useSelector(
    (state: IState) => state.patientDetails.patientTags
  );
  const dispatch = useDispatch();
  const isActive = patientTags?.find((item) => item.id === props.value.id);

  const isDisabled =
    props.disabled ||
    props.value.type === 'External Data' ||
    props.value.type === 'Symphony';
  const handleClick = () => {
    let updatedTags = [] as PatientEditTagsTabViewModel[];
    if (patientTags) {
      updatedTags = patientTags?.some((item) => item.id === props.value.id)
        ? patientTags?.filter((item) => item.id !== props.value.id)
        : [...patientTags, { ...props.value, isActive: true }];
    }
    dispatch(updatePatientTags({ tags: updatedTags, areUpdated: true }));
    dispatch(updateDirtyTabs('Background'));
  };

  return (
    <IconButton
      onClick={handleClick}
      style={{ padding: 0 }}
      disabled={isDisabled}
      size="large"
    >
      <Checkbox
        color="primary"
        checked={isActive?.isActive ?? false}
        disabled={isDisabled}
      />
    </IconButton>
  );
};

export default TagActiveFormatter;
