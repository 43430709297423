import { IAuthUser } from 'backend/types/authUser';
import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import Confirmation from 'components/modal/Confirmation';
import { COLORS } from 'consts/styles';
import { addMinutes } from 'date-fns';

interface ISessionExpirationDialogProps {
  user: IAuthUser;
  expiryDelayMinutes: number;
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const SessionExpirationDialog = (
  props: ISessionExpirationDialogProps
) => {
  const { onCancel, onOk, isOpen, expiryDelayMinutes } = props;

  useEffect(() => {
    if (isOpen) {
      restart(addMinutes(new Date(), expiryDelayMinutes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const formatTime = (time: number) => {
    return String(time).padStart(2, '0');
  };

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: addMinutes(new Date(), expiryDelayMinutes),
    onExpire: () => {
      if (isOpen) {
        onCancel();
      }
    },
  });

  return (
    <Confirmation
      open={isOpen}
      okButtonText={'Continue Session'}
      cancelButtonText={'Log Out'}
      title="Session Timeout"
      okEvent={onOk}
      cancelEvent={onCancel}
      data-testid="confirmation"
      disableBackdropClick={true}
    >
      Your session is about to expire.
      <br /> You will be logged out in
      <span
        style={{
          color: COLORS.SYMPHONY_BLUE,
          marginLeft: '3px',
          marginRight: '3px',
        }}
      >
        {formatTime(minutes)}:{formatTime(seconds)}
      </span>
      minutes.
    </Confirmation>
  );
};
