export const SuperAdminRoleId = -1000;
const DashboardAccessRoleId = -2000;
export const DashboardTabName = 'Dashboard';
export const DashboardCareSite = {
  id: DashboardAccessRoleId,
  name: 'MY CARE SITES',
};
export const DashboardCareSiteRole = {
  careSiteId: DashboardCareSite.id,
  careSiteName: DashboardCareSite.name,
  displayName: 'Dashboard Access',
  id: DashboardAccessRoleId,
  name: 'Dashboard Access',
  primary: false,
  roleId: DashboardAccessRoleId,
  selected: false,
};
