import React from 'react';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { AutocompleteCell } from 'components/gridFormatters';
import { ILookupValue } from 'backend/types/lookupValue';

export const AttachmentCategoryEditCell = (props: TableEditRow.CellProps) => {
  const mainModel = useSelector((state: IState) => state.home.mainModel);
  const attachmentCategoryLookup = mainModel?.lookups?.find(
    (x) => x.name === 'AttachmentCategory'
  );

  const options: ILookupValue[] =
    attachmentCategoryLookup?.values?.map((category) => ({
      id: category.id,
      name: category.name || '',
    })) ?? [];

  const isRequired = props.row.attachmentType === null;

  return (
    <AutocompleteCell
      options={options}
      required={isRequired}
      autoFocus
      {...props}
    />
  );
};
