import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdateActionValueMutationVariables = Types.Exact<{
  data: Types.UpdateActionValueModelInput;
}>;

export type UpdateActionValueMutation = {
  __typename?: 'Mutation';
  updateActionValue?: boolean | null;
};

export const UpdateActionValueDocument = `
    mutation updateActionValue($data: UpdateActionValueModelInput!) {
  updateActionValue(data: $data)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateActionValue: build.mutation<
      UpdateActionValueMutation,
      UpdateActionValueMutationVariables
    >({
      query: (variables) => ({
        document: UpdateActionValueDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateActionValueMutation } = injectedRtkApi;
