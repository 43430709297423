import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPharmaciesQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
}>;

export type GetPharmaciesQuery = {
  __typename?: 'EntitiesQuery';
  getPharmacies: Array<{
    __typename?: 'Pharmacy';
    id: number;
    name?: string | null;
  } | null>;
};

export const GetPharmaciesDocument = `
    query getPharmacies($term: String!) {
  getPharmacies(term: $term) {
    id
    name
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPharmacies: build.query<GetPharmaciesQuery, GetPharmaciesQueryVariables>(
      {
        query: (variables) => ({ document: GetPharmaciesDocument, variables }),
      }
    ),
  }),
});

export { injectedRtkApi as api };
export const { useGetPharmaciesQuery, useLazyGetPharmaciesQuery } =
  injectedRtkApi;
