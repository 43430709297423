import React from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import PriorAuthPolicyIcon from './priorAuthPolicyIcon';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const StyledTypography = styled(Typography)`
  display: inline;
`;

export interface IPriorAuthMessageProps {
  props: DataTypeProvider.ValueFormatterProps;
}

const PriorAuthMessage = ({ props }: IPriorAuthMessageProps) => {
  const authMessage = props.value;
  const secondaryMessage = props.row.secondaryMessage;
  if (authMessage.match(/\[(.*?)\]/g) != null) {
    const splittedAuthMessage = authMessage.split('[');
    const splittedAuthMessage1 = splittedAuthMessage[1].split(']');
    return (
      <div>
        {splittedAuthMessage[0]}
        <a href={props.row.externalURL} target="_blank" rel="noreferrer">
          {splittedAuthMessage1[0]}
        </a>
        {splittedAuthMessage1[1]}
        <PriorAuthPolicyIcon secondaryMessage={secondaryMessage} />
      </div>
    );
  }
  return (
    <div data-testid="PriorAuthMessage">
      <StyledTypography>
        {authMessage}{' '}
        <PriorAuthPolicyIcon secondaryMessage={secondaryMessage} />
      </StyledTypography>
    </div>
  );
};

export default PriorAuthMessage;
