import React from 'react';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Patient } from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import { formatPatientAge } from 'components/scenario/patient/PatientCard.helper';
import { IAuthUser } from 'backend/types/authUser';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import styled from 'styled-components';

const StyledScenarioBox = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  justify-content: space-between;
`;

const StyledPatientBox = styled.div`
  border-radius: 4px;
  justify-content: space-between;
`;

const StyledPatientDetailsInfo = styled(Typography)({
  color: COLORS.GREY100,
});
const StyledDivider = styled(Divider)({
  marginTop: '20px',
  marginBottom: '20px',
  color: COLORS.GREY25,
});

const StyledEpisodeBox = styled.div`
  border-radius: 4px;
  background-color: ${COLORS.GREY4};
  padding: 14px 18px 18px 115px;
`;

interface IHeaderProps {
  patient: Patient | undefined;
  currentUser: IAuthUser;
}

const ReviewHeader = (props: IHeaderProps) => {
  const { patient, currentUser } = props;
  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST].checklist
  );
  const patientAgeFormatted = formatPatientAge(patient!);

  if (!checklist) {
    return null;
  }
  return (
    <div style={{ padding: '29px 31px 10px 34px' }}>
      <StyledScenarioBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" style={{ color: COLORS.BLACK }}>
            {checklist?.scenarioName}
          </Typography>
        </Box>
        <StyledEpisodeBox>
          <Typography variant="body2" align={'right'}>
            Episode ID:
          </Typography>
          <Typography
            variant="h3"
            align={'right'}
            style={{
              color: COLORS.BLACK,
            }}
          >
            {checklist?.episodeNumber || ''}
          </Typography>
        </StyledEpisodeBox>
      </StyledScenarioBox>
      <StyledDivider />
      <StyledScenarioBox>
        <StyledPatientBox
          style={{
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 700, color: COLORS.GREY100 }}
          >
            Patient
          </Typography>
          <StyledPatientDetailsInfo variant="body1">
            {patient?.fullName}
          </StyledPatientDetailsInfo>
          <StyledPatientDetailsInfo variant="body1">
            {patientAgeFormatted}
          </StyledPatientDetailsInfo>
          <StyledPatientDetailsInfo variant="body1">
            {`Patient ID: ${patient?.id} `}
          </StyledPatientDetailsInfo>
          <StyledPatientDetailsInfo variant="body1">
            {`Medicare/Medicaid: ${patient?.mRN2 ?? ''} / ${
              patient?.mRN3 ?? ''
            }`}
          </StyledPatientDetailsInfo>
        </StyledPatientBox>
        <StyledPatientBox>
          <Typography
            variant="h5"
            style={{ fontWeight: 700, color: COLORS.GREY100 }}
            align={'right'}
          >
            Author
          </Typography>
          <StyledPatientDetailsInfo variant="body1" align={'right'}>
            {currentUser?.name}
          </StyledPatientDetailsInfo>
          <StyledPatientDetailsInfo variant="body1" align={'right'}>
            {currentUser?.role ?? ''}
          </StyledPatientDetailsInfo>
          <StyledPatientDetailsInfo variant="body1" align={'right'}>
            {currentUser?.careSite ?? ''}
          </StyledPatientDetailsInfo>
        </StyledPatientBox>
      </StyledScenarioBox>
      <StyledDivider />
    </div>
  );
};

export default ReviewHeader;
