import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  DataSource,
  IAdditionalFilters,
  IIndexable,
  IRecentProvider,
} from '../types';
import Loader from 'components/loader';
import SearchBar from 'components/searchbar/SearchBar';
import {
  Grid as TableGrid,
  PagingPanel,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import SimpleTextProvider from './SimpleTextProvider';
import {
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import PagingContainer from 'components/home/grid/pager';
import FilterBar from './FilterBar';
import RowDetail from './DetailsRow';
import FavoriteProvider from './FavoriteProvider';
import { PROVIDER_SEARCH_FAVORITE_PROVIDERS } from 'components/constants';
import { Chip } from '@mui/material';
import RecentProvider from './RecentProvider';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import SelectedRowComponent from 'components/home/grid/SelectedRowComponent';
import CellDetail from './DetailsCell';
import TableComponent from './TableComponent';
import ToggleCell from './ToggleCell';
import ParDataTypeProvider from './ParDataTypeProvider';
import {
  ProviderSearchResult,
  ProviderSearchResultRow,
} from 'graphql/graphqlTypes';
import { useSetPreferenceMutation } from 'graphql/hooks/setPreference';
import { COLORS } from 'consts/styles';

export interface IProviderSearchDialogModel {
  isFetching: boolean;
  patientId: number;
  selected: number;
  selectedProviderAddressId: number;
  favorites: string[];
  recent: IRecentProvider[];
  providers: ProviderSearchResultRow[];
  onSelect: (id: number) => void;
  onProviderAddressSelect: (id: number) => void;
  onSelectProviderAddressUiId: (uiId: string) => void;
  onUpdateFavorites: (values: string[]) => void;
  setSearchTerm: (term: string) => void;
  setIsAdvancedSearch: (value: boolean) => void;
}

const ProviderSearchDialogBody = (props: IProviderSearchDialogModel) => {
  const {
    providers,
    recent,
    favorites,
    isFetching,
    setSearchTerm,
    setIsAdvancedSearch,
  } = props;
  const fieldNames: { [index: string]: string } = {
    firstName: 'First Name',
    lastName: 'Last Name',
    providerId: 'Provider #',
    nPI: 'NPI',
    address: 'Address',
  };

  const [displayData, updateDisplayData] = useState({
    count: providers.length,
    rows: providers,
  } as ProviderSearchResult);

  const [viewState, updateViewState] = useState<DataSource>(DataSource.All);
  const [filters, updateFilters] = useState({} as IAdditionalFilters);
  const [selectedUId, setSelectedUId] = useState('0');

  const columns = [
    {
      name: 'recent',
      title: ' ',
      getCellValue: (row: ProviderSearchResultRow) => ({
        id: Number(row?.id ?? 0),
        recent: recent.map((x) => x.id),
      }),
    },
    { name: 'firstName', title: 'First Name' },
    { name: 'lastName', title: 'Last Name' },
    { name: 'providerId', title: 'Provider #' },
    { name: 'nPI', title: 'NPI' },
    { name: 'address', title: 'Address' },
    {
      name: 'type',
      title: 'Type',
      getCellValue: (row: ProviderSearchResultRow) => row?.type?.name ?? '',
    },
    { name: 'par', title: 'PAR' },
    {
      name: 'id',
      title: 'Favorite',
      getCellValue: (row: ProviderSearchResultRow) => ({
        id: row?.providerAddressUiId ?? '',
        favorites: favorites,
        onUpdateFavoriteProviders,
      }),
    },
  ];

  const columnsExtension = [
    { columnName: 'recent', sortingEnabled: false, width: 30 },
    { columnName: 'firstName', sortingEnabled: true, width: 120 },
    { columnName: 'lastName', sortingEnabled: true, width: 120 },
    { columnName: 'providerId', sortingEnabled: false, width: 100 },
    { columnName: 'nPI', sortingEnabled: false, width: 100 },
    { columnName: 'address', sortingEnabled: false },
    { columnName: 'type', sortingEnabled: true, width: 100 },
    { columnName: 'par', sortingEnabled: true, width: 70 },
    { columnName: 'id', sortingEnabled: false, width: 100 },
  ];

  const [setUserPreference] = useSetPreferenceMutation();

  useEffect(() => {
    if (providers) {
      onUpdateDisplayData(
        {
          count: providers?.length,
          rows: providers ?? [],
        },
        favorites,
        viewState,
        filters
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  const onUpdateFavoriteProviders = (values: string[]) => {
    props.onUpdateFavorites(values);
    onUpdateDisplayData(
      { count: providers.length, rows: providers },
      values,
      viewState,
      filters
    );
    setUserPreference({
      name: PROVIDER_SEARCH_FAVORITE_PROVIDERS,
      value: JSON.stringify(values),
    });
  };

  const onUpdateDisplayData = (
    data: ProviderSearchResult,
    favoriteItems: string[],
    value: DataSource,
    filtersToApply: IAdditionalFilters
  ) => {
    const rows = (data?.rows ?? []) as ProviderSearchResultRow[];
    const withDataSourceFilter = {
      [DataSource.All]: () => rows,
      [DataSource.Favorites]: () =>
        rows.filter((row: ProviderSearchResultRow) =>
          favoriteItems.includes(row.providerAddressUiId ?? '')
        ),
      [DataSource.Recent]: () =>
        rows.filter((row: ProviderSearchResultRow) =>
          recent.some((r: IRecentProvider) => Number(row.id) === r.id)
        ),
    }[value]();
    const filtered = applyFilters(withDataSourceFilter, filtersToApply);
    updateDisplayData({
      rows: filtered,
      count: filtered.length,
    });
  };

  const applyFilters = (
    data: ProviderSearchResultRow[],
    filter: IAdditionalFilters
  ): ProviderSearchResultRow[] => {
    let result = data;
    let key: keyof typeof filter;
    for (key in filter) {
      const value = filter[key];
      if (value) {
        result = result.filter((x: ProviderSearchResultRow) =>
          (x as IAdditionalFilters)[key]
            ?.toLowerCase()
            ?.includes(value.toLowerCase())
        );
      }
    }

    return result;
  };

  const onUpdateViewState = (value: DataSource) => {
    props.onProviderAddressSelect(0);
    props.onSelectProviderAddressUiId('');
    updateViewState(value);
    onUpdateDisplayData(
      { count: providers.length, rows: providers },
      favorites,
      value,
      filters
    );
  };

  const onSelectionChange = (values: Array<number | string>) => {
    const newSelection = (values.find((value) => value !== selectedUId) ??
      '0') as string;
    setSelectedUId(newSelection);
    const split = newSelection.toString().split('_');
    const newId = Number(split[0]);
    if (split.length > 1) {
      const providerAddressId = Number(split[1]);
      props.onProviderAddressSelect(providerAddressId);
    }
    props.onSelect(newId);
    props.onSelectProviderAddressUiId(newSelection);
  };

  const onUpdateFilters = (filterValues: IAdditionalFilters) => {
    updateFilters(filterValues);
    props.onSelect(0);
    props.onProviderAddressSelect(0);
    props.onSelectProviderAddressUiId('');
    onUpdateDisplayData(
      { count: providers.length, rows: providers },
      favorites,
      viewState,
      filterValues
    );
  };

  const onDeleteFilter = (key: string) => {
    const newfilters = filters;
    (newfilters as unknown as IIndexable)[key] = '';
    updateFilters(newfilters);
    props.onSelect(0);
    props.onProviderAddressSelect(0);
    props.onSelectProviderAddressUiId('');
    onUpdateDisplayData(
      { count: providers.length, rows: providers },
      favorites,
      viewState,
      newfilters
    );
  };

  return (
    <div data-cy="provider-search-dialog-body">
      <Box style={{ backgroundColor: COLORS.PALE_GREY, height: '100%' }}>
        <div
          style={{
            height: '100%',
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
            justifyContent: 'space-between',
          }}
        >
          <Paper
            elevation={0}
            style={{ paddingLeft: '8px', paddingRight: '8px' }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={5}>
                <SearchBar
                  placeholder="Search by Name, Provider #, NPI (at least 3 chars)"
                  onSubmit={setSearchTerm}
                  style={{ width: '400px' }}
                  setIsAdvancedSearch={setIsAdvancedSearch}
                />
              </Grid>
              <Grid item xs={7}>
                <FilterBar
                  source={viewState}
                  filters={filters}
                  onUpdateFilters={onUpdateFilters}
                  onChangeSource={onUpdateViewState}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                {Object.keys(filters).map((k: string) => (
                  <>
                    {(filters as unknown as IIndexable)[k] ? (
                      <Chip
                        key={k}
                        size="small"
                        style={{
                          marginTop: '10px',
                          marginRight: '8px',
                          marginBottom: '5px',
                          padding: '4px 11px',
                        }}
                        label={`${fieldNames[k]}:  ${
                          (filters as unknown as IIndexable)[k]
                        }`}
                        onDelete={() => onDeleteFilter(k)}
                      />
                    ) : null}
                  </>
                ))}
              </Grid>
            </Grid>
            <Box data-cy="search-dialog">
              <TableGrid
                columns={columns}
                rows={(displayData.rows ?? []) as ProviderSearchResultRow[]}
                getRowId={(row: ProviderSearchResultRow) =>
                  row.providerAddressUiId ?? ''
                }
              >
                <SelectionState
                  selection={[selectedUId]}
                  defaultSelection={[]}
                  onSelectionChange={onSelectionChange}
                />
                <RowDetailState
                  expandedRowIds={[selectedUId]}
                  onExpandedRowIdsChange={onSelectionChange}
                />
                <SimpleTextProvider
                  for={[
                    'firstName',
                    'lastName',
                    'providerId',
                    'nPI',
                    'address',
                    'type',
                  ]}
                />
                <ParDataTypeProvider for={['par']} />
                <FavoriteProvider for={['id']} />
                <RecentProvider for={['recent']} />
                <SortingState
                  defaultSorting={[
                    { columnName: 'lastName', direction: 'asc' },
                    { columnName: 'firstName', direction: 'asc' },
                  ]}
                  columnExtensions={columnsExtension}
                />
                <IntegratedSorting />
                <PagingState defaultCurrentPage={0} pageSize={20} />
                <IntegratedPaging />
                <PagingPanel containerComponent={PagingContainer} />
                <SymphonyTable
                  columnExtensions={columnsExtension}
                  containerComponent={TableComponent}
                />

                <SymphonyTableHeaderRow showSortingControls />
                <TableRowDetail
                  contentComponent={RowDetail}
                  cellComponent={CellDetail}
                  toggleCellComponent={ToggleCell}
                />
                <TableSelection
                  selectByRowClick
                  highlightRow
                  rowComponent={SelectedRowComponent}
                  showSelectionColumn={false}
                />
              </TableGrid>
            </Box>
          </Paper>
        </div>
      </Box>
      <Loader active={isFetching} />
    </div>
  );
};

export default ProviderSearchDialogBody;
