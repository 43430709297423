import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, UseFormReturn } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Divider, TextField } from '@mui/material';
import styled from 'styled-components';
import { IState } from 'store';
import { COLORS } from 'consts/styles';
import { LookupKnownName } from 'backend/types/lookupValue';
import { ErrorFormControl } from 'components/home/patientdetails/tabscontent/medicationsTab/components/ErrorFormControl';
import LookupAutocomplete from 'components/autocomplete/LookupValuesAutocomplete';
import FormControl from 'components/home/patientdetails/tabscontent/backgroundTab/formControl';
import { EpisodeDistributeForm } from './types';
import {
  toLookupValues,
  toUserLookupValues,
} from './EpisodeDistributeDialog.helper';

export const StyledDivider = styled(Divider)({
  marginBottom: '20px',
  backgroundColor: COLORS.GREY25,
});

interface EpisodeDistributeDialogBodyProps {
  form: UseFormReturn<EpisodeDistributeForm, unknown>;
}

const EpisodeDistributeDialogBody = ({
  form,
}: EpisodeDistributeDialogBodyProps) => {
  const { control } = form;
  const lookups = useSelector((state: IState) => state.home.mainModel.lookups);
  const users = useSelector((state: IState) => state.home.mainModel.users);
  const userLookupValues = toUserLookupValues(users);
  const careStageLookupValues = toLookupValues(
    lookups,
    LookupKnownName.CareStage
  );
  const pathWayLookupValues = toLookupValues(lookups, LookupKnownName.Pathway);
  const serviceLocationLookupValues = toLookupValues(
    lookups,
    LookupKnownName.EpisodeServiceLocation
  );

  return (
    <Grid>
      <FormControl title="User(s)" isRequired isAlignStart>
        <Controller
          name="users"
          control={control}
          rules={{
            required: 'User(s) is required',
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <>
              <LookupAutocomplete
                lookupValues={userLookupValues}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                limitTags={2}
                textFieldProps={{ error: !!error }}
              />
              <ErrorFormControl error={error} />
            </>
          )}
        />
      </FormControl>
      <FormControl title="Total # Episodes Owned" isRequired isAlignStart>
        <Controller
          name="totalEpisodesOwned"
          control={control}
          rules={{
            required: 'Total # Episodes Owned is required',
            min: {
              value: 1,
              message: 'Total # Episodes Owned must be greater than 0',
            },
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => (
            <>
              <TextField
                type="number"
                size="small"
                fullWidth
                variant="outlined"
                sx={{ width: '90px' }}
                inputProps={{ min: 1 }}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
              />
              <ErrorFormControl error={error} />
            </>
          )}
        />
      </FormControl>
      <StyledDivider />
      <FormControl title="Episode CareStage(s)" isRequired={false} isAlignStart>
        <Controller
          name="careStages"
          control={control}
          render={({ field }) => (
            <LookupAutocomplete
              lookupValues={careStageLookupValues}
              limitTags={1}
              {...field}
            />
          )}
        />
      </FormControl>
      <FormControl title="Episode Pathway(s)" isRequired={false} isAlignStart>
        <Controller
          name="pathways"
          control={control}
          render={({ field }) => (
            <LookupAutocomplete
              lookupValues={pathWayLookupValues}
              limitTags={2}
              {...field}
            />
          )}
        />
      </FormControl>
      <FormControl title="Episode Location(s)" isRequired={false} isAlignStart>
        <Controller
          name="serviceLocations"
          control={control}
          render={({ field }) => (
            <LookupAutocomplete
              lookupValues={serviceLocationLookupValues}
              limitTags={1}
              {...field}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default EpisodeDistributeDialogBody;
