import { EditingCell } from 'components/gridFormatters';
import React, { useEffect, useState } from 'react';
import { isValidEmail } from '../contacts.helpers';
import { EditCellValidationProps } from '../contactsGrid';

export const EmailEditCell = (props: EditCellValidationProps) => {
  const { value, onValueChange } = props;
  const [error, setError] = useState(false);
  const onChange = (newValue: string) => {
    const isValid = isValidEmail(newValue);
    setError(!isValid);
    onValueChange(newValue);
    props.setErrors(!isValid);
  };

  useEffect(() => {
    props.setErrors(!isValidEmail(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EditingCell
      {...props}
      onValueChange={onChange}
      error={error}
      autoFocus
      required
    />
  );
};
