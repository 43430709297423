import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdateChecklistMutationVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
}>;

export type UpdateChecklistMutation = {
  __typename?: 'Mutation';
  updateChecklist?: number | null;
};

export const UpdateChecklistDocument = `
    mutation updateChecklist($checklistId: Int!) {
  updateChecklist(checklistId: $checklistId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateChecklist: build.mutation<
      UpdateChecklistMutation,
      UpdateChecklistMutationVariables
    >({
      query: (variables) => ({ document: UpdateChecklistDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateChecklistMutation } = injectedRtkApi;
