import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';
import { debounce } from 'lodash';
import {
  MedicationCode,
  MedicationCodeType,
  SearchResult,
} from 'graphql/graphqlTypes';
import { useLazySearchMedicationCodesQuery } from 'graphql/hooks/searchMedicationCodes';
import { StyledAutocomplete } from 'components/autocomplete/styles';
import MedCodeOption from 'components/actions/sections/SectionBody/Items/MedicationCodes/MedCodeOption';
import MedicationCodeDialog from './MedicationCodeDialog';
import { TextField } from '@mui/material';
import AutocompleteSearchInput from '../AutocompleteSearchInput';

export interface IMedicationCodeSearchProps {
  type: MedicationCodeType;
  value: MedicationCode | MedicationCode[] | null;
  onChange: (value: MedicationCode | MedicationCode[] | null) => void;
  isMultiple: boolean;
}

const MedicationCodeSearch = (props: IMedicationCodeSearchProps) => {
  const { type, value, onChange, isMultiple } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [options, setOptions] = useState<SearchResult[]>([] as SearchResult[]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [readMoreOption, setReadMoreOption] = useState<SearchResult | null>(
    null
  );

  const [searchMedicationCodes, { data, isFetching: isCodesFetching }] =
    useLazySearchMedicationCodesQuery();

  useEffect(() => {
    if (isCodesFetching) {
      setOptions([]);
    }
    if (data && !isCodesFetching) {
      const loadedOptions = data.searchMedicationCodes as SearchResult[];
      setOptions(loadedOptions);
    }
  }, [isCodesFetching, data]);

  const handleSearchTermChange = debounce((term: string) => {
    if (term.length > 1) {
      searchMedicationCodes({
        request: {
          term,
          type,
        },
      });
    }
  }, 200);

  const handleInputChange = useCallback(
    (
      _: ChangeEvent<unknown>,
      newTerm: string,
      reason: AutocompleteInputChangeReason
    ) => {
      setSearchTerm(newTerm);
      if (reason === 'input') {
        handleSearchTermChange(newTerm);
      }
    },
    [handleSearchTermChange]
  );

  const isOptionEqualToValueInner = (option: unknown, value: unknown) => {
    return (option as SearchResult)?.id === (value as MedicationCode)?.id;
  };

  const isOptionEqualToCodeValueInner = (option: unknown, value: unknown) => {
    return (option as SearchResult)?.code === (value as MedicationCode)?.code;
  };

  const onSelectionChange = (value: SearchResult | SearchResult[] | null) => {
    setOptions([]);
    onChange(
      isMultiple
        ? (value as unknown as MedicationCode[])
        : (value as unknown as MedicationCode)
    );
  };

  const getOptionLabel = (option: SearchResult): string => {
    return `${option.code} - ${option.shortDescription}`;
  };

  const getMultipleOptionLabel = (option: SearchResult): string => {
    return `${option?.code}`;
  };

  const handleClickClear = () => {
    setSearchTerm('');
    setReadMoreOption(null);
    onSelectionChange(null);
  };

  const handleClickReadMore = (
    event: React.MouseEvent<HTMLElement>,
    option: SearchResult
  ) => {
    setReadMoreOption(option);
    setShowDialog(true);
    event.stopPropagation();
  };

  const handleAdd = () => {
    if (isMultiple && value) {
      const multipleValue = value as MedicationCode[];
      const valueArray = [...multipleValue, readMoreOption];
      setSearchTerm('');
      onSelectionChange(valueArray as SearchResult[]);
    } else {
      onSelectionChange(readMoreOption as SearchResult);
    }
    setShowDialog(false);
  };

  const handleClose = () => {
    setReadMoreOption(null);
    setShowDialog(false);
  };

  const renderOption = (
    params: React.HTMLAttributes<HTMLLIElement>,
    option: unknown
  ) => (
    <li {...params}>
      <MedCodeOption
        value={option as SearchResult}
        handleClickReadMore={(e: React.MouseEvent<HTMLElement>) =>
          handleClickReadMore(e, option as SearchResult)
        }
      />
    </li>
  );

  return (
    <>
      {
        <StyledAutocomplete
          multiple={isMultiple}
          style={{ height: 'auto' }}
          getOptionLabel={(option) =>
            isMultiple
              ? getMultipleOptionLabel(option as SearchResult)
              : getOptionLabel(option as SearchResult)
          }
          options={options}
          filterOptions={(x) => x}
          forcePopupIcon={false}
          size="small"
          loading={isCodesFetching}
          includeInputInList
          clearOnBlur={false}
          clearOnEscape={true}
          autoHighlight={options.length === 1}
          filterSelectedOptions
          isOptionEqualToValue={
            isMultiple
              ? isOptionEqualToCodeValueInner
              : isOptionEqualToValueInner
          }
          inputValue={searchTerm}
          value={isMultiple ? (value as MedicationCode[]) : value}
          onKeyDown={(e) => e.key === 'Escape' && handleClickClear()}
          onInputChange={(
            event: ChangeEvent<unknown>,
            newTerm: string,
            reason: AutocompleteInputChangeReason
          ) => {
            handleInputChange(event, newTerm, reason);
          }}
          onChange={(_event, value) =>
            onSelectionChange(
              isMultiple ? (value as SearchResult[]) : (value as SearchResult)
            )
          }
          renderOption={(params, option) => renderOption(params, option)}
          renderInput={(params) =>
            isMultiple ? (
              <TextField {...params} variant="outlined" />
            ) : (
              <AutocompleteSearchInput
                inputParams={params}
                searchTerm={searchTerm}
                onClear={handleClickClear}
              />
            )
          }
        />
      }
      {showDialog && (
        <MedicationCodeDialog
          open={true}
          medicationCode={readMoreOption as SearchResult}
          onAdd={handleAdd}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default MedicationCodeSearch;
