import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { MozartActionContainer } from './MozartActionContainer';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import { MozartIndicator } from 'features/mozart/components/MozartIndicator/MozartIndicator';
import { ChecklistStorageType } from 'store/actions/types';
import { getLastCompletedAuthorizationStatus } from 'features/mozart/MozartWorkflowLayout.helpers';
interface StyledProps {
  isPrinting: boolean;
}
const ScenarioBox = styled.div<StyledProps>`
  margin-top: ${({ isPrinting }) => (isPrinting ? '5px' : '24px')};
  padding: ${({ isPrinting }) =>
    isPrinting ? '5px 5px 5px 5px' : '20px 30px 20px 34px'};
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: ${COLORS.GREY4};
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Typography)<StyledProps>`
  font-size: ${({ isPrinting }) => (isPrinting ? '16px' : '22px')};
  letter-spacing: ${({ isPrinting }) => (isPrinting ? '0.05' : '0.15')};
  line-height: ${({ isPrinting }) => (isPrinting ? '10px' : '24px')};
  font-weight: 500;
  padding-left: '5px';
`;

const StyledScenario = styled(Typography)<StyledProps>`
  font-size: ${({ isPrinting }) => (isPrinting ? '16px' : '24px')};
  font-weight: 500;
`;

const AuthIdBox = styled.div<StyledProps>`
  border: solid 1px ${COLORS.GREY25};
  border-radius: 4px;
  padding: ${({ isPrinting }) =>
    isPrinting ? '5px 10px 10px 30px' : '10px 20px 20px 70px'};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  width: 100%;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconBox = styled.div({
  marginRight: '9px',
  display: 'inline-flex',
  alignItems: 'center',
});

const StyledStatusBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
`;
const StyledTypography = styled(Typography)`
  color: ${COLORS.BLACK};
  padding-right: 8px;
`;

export const MozartComplete = ({
  isLoadingComplete,
}: {
  isLoadingComplete: boolean;
}) => {
  const checklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );
  const printDocuments = useSelector(
    (state: IState) => state.ui.print.documents
  );
  const isOptimizedView = printDocuments.isOptimizedView;
  const checklist = Object.values(checklists).find(
    (x) => x?.episodeNumber && x?.scenarioName
  );
  const authorizationStatus = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].checklist
        ?.authorizationStatus?.name
  );
  const completedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );

  const refAutoFocus = useRef<HTMLDivElement>(null);

  const isWorkflowVoided =
    checklists &&
    Object.values(checklists)?.some((item) =>
      item?.authorizationStatus?.name?.toLocaleLowerCase().includes('void')
    );
  const mozartWorkflowStatus =
    authorizationStatus ??
    getLastCompletedAuthorizationStatus(completedChecklists);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      if (isLoadingComplete && refAutoFocus.current) {
        refAutoFocus.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);

    dispatch(
      setWorkflowStep({ step: 3, name: checklist?.scenarioName ?? 'Done' })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingComplete]);

  return (
    <MozartActionContainer isDoneStep>
      <Container>
        <TitleBox>
          <IconBox>
            <Icon
              icon={ICONS.ProgressCompleted}
              size={24}
              color={COLORS.GREEN100}
            />
          </IconBox>
          <Box>
            <StyledTitle isPrinting={isOptimizedView} variant="h4">
              Workflow completed!
            </StyledTitle>
          </Box>
        </TitleBox>
        <ScenarioBox isPrinting={isOptimizedView} ref={refAutoFocus}>
          <StyledScenario isPrinting={isOptimizedView} variant="h3">
            {checklist?.scenarioName || ''}
          </StyledScenario>
          {checklist?.episodeNumber && !isWorkflowVoided && (
            <AuthIdBox isPrinting={isOptimizedView}>
              <StyledStatusBox>
                <StyledTypography variant="caption">
                  Episode ID:
                </StyledTypography>
                <Typography variant="h4">
                  {checklist?.episodeNumber || ''}
                </Typography>
              </StyledStatusBox>
              {mozartWorkflowStatus ? (
                <StyledStatusBox>
                  <StyledTypography variant="caption">
                    Episode status:
                  </StyledTypography>
                  <MozartIndicator />
                </StyledStatusBox>
              ) : null}
            </AuthIdBox>
          )}
        </ScenarioBox>
      </Container>
    </MozartActionContainer>
  );
};
