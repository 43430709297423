import Grid from '@mui/material/Grid';
import React, { ReactNode } from 'react';
import { StyledFormControl } from './styles';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

interface IFormControlProps {
  title: string;
  isRequired: boolean;
  children: ReactNode;
  isAlignStart?: boolean;
}

const StyledGridItem = styled(Grid)<{ $isAlignStart: boolean }>`
  display: flex;
  align-self: ${({ $isAlignStart }) =>
    $isAlignStart ? 'flex-start' : 'center'};
  margin-top: ${({ $isAlignStart }) => ($isAlignStart ? '6px' : '0')};
`;

const StyledGridItemWide = styled(Grid)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
});

const StyledRequired = styled.span`
  color: ${COLORS.RED100};
  padding-right: 4px;
`;
const StyledTypography = styled(Typography)`
  display: flex;
`;

const FormControl = (props: IFormControlProps) => {
  const { title, isRequired, children, isAlignStart } = props;

  return (
    <StyledFormControl container spacing={1}>
      <StyledGridItem item xs={4} $isAlignStart={Boolean(isAlignStart)}>
        <StyledTypography>
          {isRequired ? <StyledRequired>*</StyledRequired> : ''}
          {title}
        </StyledTypography>
      </StyledGridItem>
      <StyledGridItemWide item xs={8}>
        {children}
      </StyledGridItemWide>
    </StyledFormControl>
  );
};
export default FormControl;
