import React from 'react';
import { StyledSectionWrapper } from './components';
import { MozartStep } from 'features/mozart/components';
import { CompressedMozartWorkflowUiModel } from './MozartContent.helpers';
import { MozartMessage } from 'features/mozart/components/MozartAction/MozartMessage';

export interface IMozartStepMessageProcessByUserProps {
  message: CompressedMozartWorkflowUiModel;
}
export const MozartStepMessageProcessByUser = ({
  message,
}: IMozartStepMessageProcessByUserProps) => {
  const messageText = message.showMessageValue
    ? message?.showMessageValue
    : `Step is being processed by: ${message.updatedBy ?? ''}`;
  return (
    <StyledSectionWrapper key={message.scopeId}>
      <MozartStep currentStep={message?.leftHead ?? ''} message={message} />
      <MozartMessage message={messageText} />
    </StyledSectionWrapper>
  );
};
