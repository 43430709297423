import { isWorkflowInErrorState } from 'features/mozart/MozartWorkflowLayout.helpers';
import {
  MozartActionType,
  MozartDefinitionType,
  MozartProgressState,
} from 'graphql/graphqlTypes';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IState } from 'store';

export const useHeaderHelpers = () => {
  const location = useLocation();
  const isMozartFlow =
    location.pathname.slice(0, location.pathname.lastIndexOf('/')) ===
    '/Workflow';

  const isUserAuthorizedMozartNextStep = useSelector(
    (state: IState) => state.mozart.isUserAuthorizedMozartNextStep
  );

  const isPausedWorkflow = useSelector(
    (state: IState) => state.mozart.isWorkflowPaused
  );

  const mozartViewOnly = useSelector(
    (state: IState) => state.mozart.mozartViewOnly
  );

  const mozartMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );

  const isWorkflowCompleted = mozartMessages.some(
    (message) =>
      message.type === MozartDefinitionType.Workflow &&
      message.state === MozartProgressState.Completed
  );

  const mozartWorkflowInstanceState = useSelector(
    (state: IState) => state.mozart.mozartWorkflowInstanceState
  );

  const hasWorkflowFailed =
    mozartMessages.some(isWorkflowInErrorState) ||
    mozartWorkflowInstanceState?.toUpperCase() ==
      MozartProgressState.Failed.toString().toUpperCase();

  const isMozartWorkflowInRoleCareSite = mozartMessages.some(
    (message) =>
      message.state === MozartProgressState.Paused &&
      message.actionType === MozartActionType.None &&
      message.type === MozartDefinitionType.Action
  );

  const showExitChecklistPopup = !(
    isWorkflowCompleted ||
    hasWorkflowFailed ||
    isMozartWorkflowInRoleCareSite ||
    !isUserAuthorizedMozartNextStep ||
    isPausedWorkflow ||
    mozartViewOnly
  );
  return { isMozartFlow, showExitChecklistPopup };
};
