import { FooterContainer } from 'components/footer/FooterContainer';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import ReminderConfirmation from 'components/modal/ReminderConfirmation';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { useFinishChecklistMutation } from 'graphql/hooks/finishChecklist';
import { FinishChecklistStatus } from 'graphql/graphqlTypes';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { ChecklistStorageType } from 'store/actions/types';
import { useNavigate } from 'react-router';
import { useDirtyConfirmation } from 'hooks/useDirtyConfirmationModal';
import { DirtyConfirmationModal } from 'components/modal/DirtyConfirmationModal';

interface IReviewFooterProps {
  handleFinishLater: () => void;
  patientId?: string;
  storageType: ChecklistStorageType;
  handleDiscardConfirmation: (discard: boolean) => void;
}

const ReviewFooter = ({
  handleFinishLater,
  patientId,
  storageType,
  handleDiscardConfirmation,
}: IReviewFooterProps) => {
  const [reminderConfirmation, setReminderConfirmation] = useState(false);
  const [reminderCount, setReminderCount] = useState(0);
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [
    finishChecklist,
    {
      isLoading: isFinishingChecklist,
      isSuccess: isFinishSuccess,
      data: finishChecklistResult,
    },
  ] = useFinishChecklistMutation();

  const sectionName = 'Episode Summary';
  const isDirty = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary.isDirty
  );
  const {
    handleDirtyConfirm,
    clearDirtyState,
    handleConfirm,
    handleCancel,
    showModal,
  } = useDirtyConfirmation();

  const handleCompleteAndCloseReminder = () => {
    setReminderConfirmation(false);
    finishChecklist({
      data: {
        checklistId: Number(checklistId),
        patientId: Number(patientId),
        sessionId: sessionId,
        completeReminder: true,
      },
    });
  };
  const handleDone = () => {
    handleDirtyConfirm(isDirty, onConfirmFinishChecklist);
  };

  const onConfirmFinishChecklist = () => {
    clearDirtyState();
    finishChecklist({
      data: {
        checklistId: Number(checklistId),
        patientId: Number(patientId),
        sessionId: sessionId,
      },
    });
  };

  useEffect(() => {
    if (isFinishSuccess) {
      const status = finishChecklistResult?.finishChecklist?.status;
      if (status === FinishChecklistStatus.Success) {
        navigate(`/Checklist/Finish/${checklistId}`);
      } else if (status === FinishChecklistStatus.OpenReminders) {
        setReminderCount(
          finishChecklistResult?.finishChecklist?.reminderCount ?? 0
        );
        setReminderConfirmation(true);
      } else {
        if (
          finishChecklistResult?.finishChecklist?.errors &&
          finishChecklistResult.finishChecklist.errors.length > 0
        ) {
          dispatch(
            showErrorPopup({
              message:
                finishChecklistResult?.finishChecklist?.errors?.join(', ') ??
                '',
              title: 'Server Validation Error',
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinishSuccess]);

  return (
    <FooterContainer data-testid="ReviewFooter" loading={isFinishingChecklist}>
      <Box>
        <Button
          onClick={() => navigate(`/Checklist/Actions/${checklistId}`)}
          color="primary"
          data-cy="back-to-scenario"
          size="large"
          startIcon={
            <Icon icon={ICONS.Back} size={22} color={COLORS.SYMPHONY_BLUE} />
          }
        >
          Actions
        </Button>
      </Box>
      <DirtyConfirmationModal
        showModal={showModal}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        sectionName={sectionName}
      />
      <Box display="flex">
        <Button
          onClick={() => handleDiscardConfirmation(true)}
          color="primary"
          data-cy="discard-scenario"
          size="large"
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
        >
          Discard
        </Button>
        <Button
          color="primary"
          size="large"
          onClick={handleFinishLater}
          data-cy="finish-later"
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
          variant="outlined"
        >
          Finish Later
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginLeft: '12px' }}
          onClick={handleDone}
          data-cy="return-home"
        >
          Finish
        </Button>
      </Box>
      <ReminderConfirmation
        open={reminderConfirmation}
        reminderCount={reminderCount}
        onCompleteAndClose={() => handleCompleteAndCloseReminder()}
        onCancel={() => setReminderConfirmation(false)}
      />
    </FooterContainer>
  );
};

export default ReviewFooter;
