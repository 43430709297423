import React from 'react';
import StyledLink from './StyledLink';
import { IChecklistLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { useNavigate } from 'react-router';

interface IChecklistContinueLinkProps extends IChecklistLinkProps {
  callback?: () => void;
}

const ChecklistContinueLink = (props: IChecklistContinueLinkProps) => {
  const navigate = useNavigate();

  const onContinueChecklist = (checklistId: number) => {
    navigate(`/Checklist/Actions/${checklistId}`);
    if (props.callback) {
      props.callback();
    }
  };
  return (
    <CustomTooltip
      title={props.title ?? 'Reopens document at previous save point'}
    >
      <StyledLink
        onClick={() => onContinueChecklist(props.checklistId)}
        data-cy="Continue"
      >
        Continue
      </StyledLink>
    </CustomTooltip>
  );
};

export default ChecklistContinueLink;
