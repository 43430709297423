import React from 'react';
import { useDispatch } from 'react-redux';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { IconFallBack } from 'components/IconFallBack';
import { COLORS } from 'consts/styles';
import {
  getEpisodeRemindersSubTabName,
  getEpisodeRemindersTabName,
} from './EpisodeNumberFormatter';
import { EPISODE_SOURCE_FAX } from '../consts';

const ReminderReasonFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const dispatch = useDispatch();
  const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const isEpisodeRow = props.row.episodeId && props.row.EpisodeType;
    const activeTabName = isEpisodeRow
      ? getEpisodeRemindersTabName(props.row.EpisodeType)
      : 'Reminders';
    const activeSubTabName = isEpisodeRow
      ? getEpisodeRemindersSubTabName(props.row.EpisodeType)
      : '';
    dispatch(
      openPatientDetailsModal({
        patientId: Number(
          props.row.patientId ??
            (props.row.EpisodeSource !== EPISODE_SOURCE_FAX
              ? props.row.id
              : undefined)
        ),
        episodeId: Number(props.row.episodeId ?? 0),
        activeTabName: activeTabName,
        activeSubTabName: activeSubTabName,
      })
    );
  };

  return (
    <>
      <div
        data-testid="reminder-reason-formatter"
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        onClick={handleClick}
      >
        <IconFallBack
          name={props.value?.name}
          image={props.value?.image}
          type="Reminder"
          showText={!!props.value?.name}
          color={COLORS.GREY100}
          iconSize={16}
        />
      </div>
    </>
  );
};

export default ReminderReasonFormatter;
