import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  IMultiEditDialogModel,
  IMultiEditForm,
  InputNameType,
  MultiEditRef,
} from './types';
import { suggestEntities } from 'store/patientdetails/middlewares';
import { IState } from 'store';
import { ILookupValue } from 'backend/types/lookupValue';
import { IKeyValuePair } from 'backend/types/keyValuePair';
import MultiEditFormRow from './MultiEditFormRow';
import { useSaveAllMutation } from 'graphql/hooks/saveAllEntities';
import Loader from 'components/loader';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { pickBy } from 'lodash';
import Icon, { ICONS } from 'components/icon';
import { validateRequired } from 'util/validationUtils';
import { Box, Button, Typography } from '@mui/material';
import { NavButton } from 'components/ErrorNotification/components/NavButton';
import { RoleType } from 'store/roles/types';

const StyledWrapper = styled.div`
  background-color: ${COLORS.PALE_GREY};
  height: 100%;
`;

const StyledFormWrap = styled.div`
  flex-grow: 1;
  height: 100%;
  background-color: ${COLORS.WHITE};
`;
const StyledContainer = styled(Grid)`
  padding: 24px 0;
`;
const StyledContent = styled.div`
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  border: 1px solid ${COLORS.GREY25};
  border-radius: 4px;
`;

const ValidationError = styled.div`
  display: flex;
  background-color: ${COLORS.RED20};
  height: 30px;
  padding: 9px 60px;
  align-items: center;
  border-radius: 0;
  border-bottom: 1px solid ${COLORS.GREY25};
  color: rgba(0, 0, 0, 0.87);
  position: fixed;
  width: 528px;
`;

const ErrorText = styled.span`
  display: flex;
  margin-left: 7px;
  margin-top: 2px;
  flex-grow: 1;
`;

const ErrorContainer = styled.div`
  position: relative;
  top: -24px;
  left: -24px;
  z-index: 100;
`;

const ErrorWrapper = styled.div`
  position: absolute;
`;

const StyledCurrentError = styled.span`
  color: #aa7f7f;
`;

const StyledTotalErrors = styled.span`
  color: ${COLORS.RED160};
`;

const MultiEditDialogBody = forwardRef<MultiEditRef, IMultiEditDialogModel>(
  ({ model, afterSaveCallback }: IMultiEditDialogModel, ref) => {
    const patientIds = useSelector(
      (state: IState) => state.multiEdit.patientIds
    );
    const episodeIds = useSelector(
      (state: IState) => state.multiEdit.episodeIds
    );
    const mainModel = useSelector((state: IState) => state.home.mainModel);
    const roleType = useSelector(
      (state: IState) => state.user.currentUser.type
    );

    const [showStatus, changeStatus] = useState(model.data?.isActive ?? false);
    const dispatch = useDispatch();
    const [selectedFields, setSelectedFields] = useState<Array<string>>(['']);

    const [errorFields, setErrorFields] = useState<Array<string>>([]);
    const [currentErrorField, setCurrentErrorField] = useState('');

    const methods = useForm();

    useEffect(() => {
      setSelectedFields(['']);
    }, [episodeIds, patientIds]);

    const [saveEntities, { isLoading: isSavingEntities }] =
      useSaveAllMutation();

    const getNumberValue = (value?: string | number) => {
      return value ? Number(value) : null;
    };

    const { handleSubmit, setValue, reset } = useForm<IMultiEditForm>({
      defaultValues: {
        Patient_IsActive: model.data?.isActive,
        Patient_Owner: getNumberValue(model.data?.patientOwner?.id),
        Patient_SecondaryOwner: getNumberValue(
          model.data?.patientSecondaryOwner?.id
        ),
        Episode_Owner: getNumberValue(model.data?.episodeOwner?.id),
        Patient_CareStage: getNumberValue(model.data?.careStage?.id),
        Patient_Populations: model.data?.populations?.map((item) =>
          Number(item.id)
        ),
        Patient_Payor: getNumberValue(model.data?.payor?.id),
        Patient_ProviderName: model.data?.providerName ?? '',
        Patient_PrimaryCareProvider: model.data?.primaryCareProvider,
        Patient_Status: getNumberValue(model.data?.status?.id),
        Patient_Note: model.data?.note,
      },
    });

    const getValidationDetails = (values: IMultiEditForm, id: string) => {
      const currentValue = values[id as keyof IMultiEditForm];
      const currentField = formFields.current.find(
        (field) => field.fieldName === id
      );
      const validationResult = validateRequired(
        currentValue as string,
        currentField?.validationRequired as boolean
      );
      return { currentField, validationResult };
    };

    const handleFormValidation = (values: IMultiEditForm, onSave?: boolean) => {
      const errorFields: string[] = [];
      Object.keys(values).forEach((id) => {
        const { currentField, validationResult } = getValidationDetails(
          values,
          id
        );
        if (currentField) {
          const hasValidationError =
            (onSave && validationResult.hasError) ||
            (currentField?.isTouched && validationResult.hasError);
          if (hasValidationError) {
            errorFields.push(currentField?.label);
            currentField.isTouched = true;
          }
          currentField.hasValidationError = hasValidationError;
        }
      });
      setCurrentErrorField(errorFields[0]);
      setErrorFields(errorFields);
    };

    const divRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const carestageLookupName =
      roleType === RoleType.CLINICAL ? 'CareStage' : 'EpisodeCareStage';

    const formFields = useRef([
      {
        fieldName: 'Patient_Owner',
        id: 'patientOwner',
        fieldType: 'select',
        selectValues: mainModel.users
          .filter((x) => x.canBeMemberOwner)
          .map((y) => ({ id: y.id, name: y.name } as ILookupValue)),
        label: 'Member Owner',
        selectType: 'singleSelect',
        defaultValue:
          model.data && model.data.patientOwner
            ? model.data.patientOwner.id
            : '',
        showField:
          model.canChangeOwner &&
          !model.episodeOnly &&
          roleType === RoleType.CLINICAL,
        validationRequired:
          model.canChangeOwner &&
          !model.episodeOnly &&
          roleType === RoleType.CLINICAL &&
          model.requiredFields?.includes('PatientOwner'),
        hasValidationError: false,
        isTouched: false,
      },
      {
        fieldName: 'Episode_Owner',
        id: 'episodeOwner',
        fieldType: 'select',
        selectValues: mainModel.users.map(
          (y) => ({ id: y.id, name: y.name } as ILookupValue)
        ),
        label: 'Episode Owner',
        selectType: 'singleSelect',
        defaultValue:
          model.data && model.data.episodeOwner
            ? Number(model.data.episodeOwner.id)
            : '',
        showField: model.canChangeOwner,
        validationRequired:
          model.canChangeOwner &&
          model.requiredFields?.includes('EpisodeOwner'),
        hasValidationError: false,
        isTouched: false,
      },
      {
        fieldName:
          roleType === RoleType.CLINICAL
            ? 'Episode_CMCareStage'
            : 'Patient_CareStage',
        id: 'patientCareStage',
        fieldType: 'select',
        selectValues: mainModel?.lookups?.find(
          (l) => l.name === carestageLookupName
        )?.values,
        label: 'CareStage',
        selectType: 'singleSelect',
        defaultValue:
          model.data && model.data.careStage
            ? Number(model.data.careStage.id)
            : '',
        showField: !model.episodeOnly && roleType === RoleType.CLINICAL,
        validationRequired:
          !model.episodeOnly &&
          roleType === RoleType.CLINICAL &&
          model.requiredFields?.includes('CareStage'),
        hasValidationError: false,
        isTouched: false,
      },
    ]);

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        if (formRef?.current?.requestSubmit) {
          formRef.current.requestSubmit();
        }
      },
      resetForm: () => {
        reset();
      },
      current: divRef.current,
    }));

    const handlePcpAutoCompleteChange = (
      _event: ChangeEvent<unknown>,
      newValue: ILookupValue | null
    ) => {
      setValue('Patient_PrimaryCareProvider', newValue);
      setSelectedFields([...selectedFields, 'usePatient_PrimaryCareProvider']);
    };

    const onChangeStatus = (status: boolean) => {
      setValue('Patient_IsActive', status);
      changeStatus(status);
      setSelectedFields([...selectedFields, 'Patient_IsActive']);
    };

    const handleMultiSelectChange = (values: number[]) => {
      setValue('Patient_Populations', values);
      setSelectedFields([...selectedFields, 'Patient_Populations']);
    };

    const handleChange = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = event.target;
      setValue(name as InputNameType, value);
      setSelectedFields([...selectedFields, name]);
      const formFieldIndex = formFields.current.findIndex(
        (item) => item.fieldName === name
      );
      formFields.current[formFieldIndex].isTouched = true;
    };

    const handleCheckBoxClick = (event: ChangeEvent<HTMLInputElement>) => {
      const fieldName = event.target.name.substring(3);
      const updatedSelectedFields = selectedFields.includes(fieldName)
        ? selectedFields.filter((item) => item !== fieldName)
        : [...selectedFields, fieldName];
      setSelectedFields(updatedSelectedFields);
    };

    const handleSuggestItems = (
      request: string,
      callback: (arg: ILookupValue[]) => void
    ) => {
      if (request.length >= 3) {
        dispatch(suggestEntities('Provider', request, callback));
      }
    };

    const onBlur = (values: IMultiEditForm) => {
      handleFormValidation(values);
    };

    const isFieldValid = (values: IMultiEditForm, id: string) => {
      const { currentField, validationResult } = getValidationDetails(
        values,
        id
      );
      return currentField?.validationRequired && validationResult.hasError;
    };

    const isValidationErrorOccurred = (values: IMultiEditForm) => {
      return Object.keys(values).some((id) => isFieldValid(values, id));
    };

    const getFieldValue = (value: string) => {
      if (Array.isArray(value)) {
        return value?.map((x: string) => x.toString()).join(',') ?? '';
      }
      if (typeof value === 'object') {
        return (value as ILookupValue)?.id?.toString() ?? '';
      }
      return String(value);
    };

    const getRequestValues = (values: IMultiEditForm) => {
      const object = Object.fromEntries(Object.entries(values));
      const includedFields = Object.keys(object)
        .filter((key) => selectedFields.includes(key))
        .reduce((item, key) => ({ ...item, [key]: object[key] }), {});
      return Object.entries(includedFields).map(
        ([key, value]) =>
          ({ key: key, value: getFieldValue(value as string) } as IKeyValuePair)
      );
    };

    const onSubmit = (values: IMultiEditForm) => {
      const hasAnyValidationErrors = isValidationErrorOccurred(values);
      if (hasAnyValidationErrors) {
        handleFormValidation(values, true);
        return;
      }
      const request = {
        patientIds: patientIds.filter((x) => x > 0),
        episodeIds: episodeIds.filter((x) => x > 0),
        values: [] as IKeyValuePair[],
      };
      const reqValues = getRequestValues(values);
      if (reqValues.length > 0) {
        request.values = reqValues;
        saveEntities({ model: pickBy(request) }).then(() => {
          reset();
          afterSaveCallback();
        });
      } else {
        reset();
        afterSaveCallback();
      }
    };

    const currentErrorFieldIndex = errorFields.indexOf(currentErrorField);

    const handleClick = (offset = 0) => {
      const error = errorFields[currentErrorFieldIndex + offset];
      if (error) {
        const formField = formFields.current.find(
          (field) => field.label === error
        );
        if (formField) {
          const section = document.querySelector(
            `#${formField?.fieldName}-label`
          );
          if (!section) {
            // eslint-disable-next-line no-console
            console.error(`#${formField?.fieldName}-label is not found!!`);
          }
          section?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setCurrentErrorField(error);
        }
      }
    };

    return (
      <>
        {errorFields.length > 0 && (
          <ErrorContainer>
            <ErrorWrapper>
              <ValidationError>
                <Box
                  display="flex"
                  flexGrow={0}
                  alignItems="center"
                  paddingRight="20px"
                >
                  <NavButton
                    onClick={() => {
                      handleClick(-1);
                    }}
                    direction="prev"
                    disabled={currentErrorField === errorFields[0]}
                  />
                  <Typography data-testid="current-error">
                    <StyledCurrentError>
                      {currentErrorFieldIndex + 1}
                    </StyledCurrentError>
                    /<StyledTotalErrors>{errorFields.length}</StyledTotalErrors>
                  </Typography>
                  <NavButton
                    onClick={() => {
                      handleClick(1);
                    }}
                    direction="next"
                    disabled={
                      errorFields.length === 0 ||
                      errorFields.length - 1 === currentErrorFieldIndex
                    }
                  />
                </Box>
                <Icon
                  size={18}
                  icon={ICONS.warning_filled}
                  color={COLORS.RED100}
                />
                <ErrorText>
                  Fill in required field: {currentErrorField}
                </ErrorText>
                <Box display="flex" flexGrow={0}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: COLORS.RED100,
                      color: COLORS.WHITE,
                      marginLeft: 60,
                    }}
                    onClick={() => handleClick()}
                  >
                    Fix it
                  </Button>
                </Box>
              </ValidationError>
            </ErrorWrapper>
          </ErrorContainer>
        )}
        <div ref={divRef} data-testid="multi-edit-dialog-body">
          <StyledWrapper
            style={{ marginTop: `${errorFields.length > 0 ? '30px' : '0px'}` }}
          >
            <StyledContent>
              <StyledFormWrap>
                <FormProvider {...methods}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    ref={formRef}
                    onBlur={handleSubmit(onBlur)}
                  >
                    <StyledContainer container spacing={0}>
                      {formFields.current.map((item) =>
                        item.showField === true ? (
                          <MultiEditFormRow
                            key={item.fieldName}
                            selectedFields={selectedFields}
                            handleCheckBoxClick={handleCheckBoxClick}
                            showStatus={showStatus}
                            onChangeStatus={onChangeStatus}
                            fieldRow={item}
                            handleChange={handleChange}
                            handleMultiSelectChange={handleMultiSelectChange}
                            handlePcpAutoCompleteChange={
                              handlePcpAutoCompleteChange
                            }
                            handleSuggestItems={handleSuggestItems}
                          />
                        ) : null
                      )}
                    </StyledContainer>
                  </form>
                </FormProvider>
              </StyledFormWrap>
            </StyledContent>
          </StyledWrapper>
          <Loader active={isSavingEntities} />
        </div>
      </>
    );
  }
);

MultiEditDialogBody.displayName = 'MultiEditDialogBody';

export default MultiEditDialogBody;
