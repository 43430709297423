import React from 'react';
import { Box, Grid } from '@mui/material';
import MedicationsSubTabs from './MedicationsSubTabs';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { updateMedicationsData } from 'store/patientdetails/patientDetailsSlice';
import MedicationsActionColumn from './MedicationsActionColumn';
import { MedicationAllergies } from './components/MedicationAllergies';
import { MedicationReview } from './components/MedicationReview';

export const MedicationsTab = () => {
  const medicationsData = useSelector(
    (state: IState) => state.patientDetails.medicationsTab
  );
  const actionType = medicationsData.selectedActionType;
  const dispatch = useDispatch();
  const updateMedicationsInfo = <T,>(value: T) => {
    dispatch(
      updateMedicationsData({ ...medicationsData, ...value, isUpdate: true })
    );
  };

  const columnSizeDetails =
    actionType === 'form' ? 8 : actionType === 'details' ? 3 : 12;
  const columnSizeMain =
    actionType === 'form' ? 4 : actionType === 'details' ? 9 : 12;

  const isSidePanelOpen = !!actionType;
  return (
    <Grid container spacing={3} style={{ marginTop: 0 }}>
      <Grid
        item
        xs={columnSizeMain}
        style={{
          paddingRight: isSidePanelOpen ? '24px' : '',
          paddingTop: '24px',
          marginTop: 0,
        }}
      >
        <Grid container spacing={8}>
          <MedicationAllergies
            isSidePanelOpen={isSidePanelOpen}
            medicationsData={medicationsData}
            updateMedicationsInfo={updateMedicationsInfo}
          />
          {!isSidePanelOpen && (
            <MedicationReview
              medicationsData={medicationsData}
              updateMedicationsInfo={updateMedicationsInfo}
            />
          )}
        </Grid>
        <Box>
          <MedicationsSubTabs columnOpen={isSidePanelOpen} />
        </Box>
      </Grid>
      {isSidePanelOpen && (
        <Grid
          item
          xs={columnSizeDetails}
          style={{
            borderLeft: isSidePanelOpen ? `1px solid ${COLORS.GREY25}` : '',
            paddingTop: '18px',
          }}
        >
          <MedicationsActionColumn />
        </Grid>
      )}
    </Grid>
  );
};
