import { ReminderImportance } from 'graphql/graphqlTypes';
import TableCell from '@mui/material/TableCell';
import { Select, SelectChangeEvent, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { CellComponentStyle } from 'components/gridFormatters';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';

const StyledSelect = styled(Select)({
  display: 'flex',
  background: `${COLORS.WHITE}`,
  paper: {
    borderRadius: '4px',
    border: '1px solid #CECECE',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
  },
});

export const ImportanceEditCell = (props: TableEditRow.CellProps) => {
  const { value, onValueChange } = props;
  const options = Object.values(ReminderImportance);

  const onChange = (event: SelectChangeEvent<unknown>) =>
    onValueChange(event.target.value);

  return (
    <TableCell style={CellComponentStyle}>
      <StyledSelect
        value={value}
        onChange={onChange}
        variant="outlined"
        fullWidth
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          style: {
            left: '0',
            marginTop: '2px',
            maxHeight: 350,
            width: 250,
          },
        }}
      >
        {options.map((item) => (
          <MenuItem
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            key={item}
            value={item}
          >
            <Typography noWrap>{item}</Typography>
          </MenuItem>
        ))}
      </StyledSelect>
    </TableCell>
  );
};
