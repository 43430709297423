import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  BigMarginBox,
  BoldText,
  SmallMarginBox,
  StyledUl,
} from './EpisodeDetails';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { formatDate, formatDateTime } from 'util/helpers/dateTimeHelpers';
import { EpisodeDetailsType } from '../../types';
import { StyledSummaryBox } from 'components/scenario/patient/PatientCard';

export interface IEpisodePrimaryDetailsProps {
  episodeType: 'episodes' | 'orgDet' | 'cases' | 'appealgriev';
  canAllowSeeEpisodeSummary: boolean;
  episode?: EpisodeDetailsType | null;
}

const EpisodePrimaryDetails = (props: IEpisodePrimaryDetailsProps) => {
  const { episodeType, episode, canAllowSeeEpisodeSummary } = props;
  const careTeamMembers = useSelector(
    (state: IState) => state.patientDetails.careTeamMembers
  );

  const getEntityAttribute = (name: string) =>
    episode?.entityAttributes?.find(
      (attr) => attr?.name?.toLowerCase() === name.toLowerCase()
    )?.value ?? '';
  return (
    <>
      {episodeType === 'cases' ? (
        <>
          <SmallMarginBox>
            <Typography>
              <BoldText>Owner: </BoldText>
              {episode?.owner?.fullName}
            </Typography>
          </SmallMarginBox>
          <SmallMarginBox display="block" alignItems="center">
            <Typography variant="body2">Care Team members: </Typography>
            <Box display="flex">
              <StyledUl>
                {careTeamMembers &&
                  careTeamMembers.map((teamMember) => (
                    <li key={teamMember.id}>
                      <Typography>{teamMember.member?.fullName}</Typography>
                    </li>
                  ))}
              </StyledUl>
            </Box>
          </SmallMarginBox>
          <SmallMarginBox>
            <Typography>
              <BoldText>Last Updated: </BoldText>
              {formatDate(episode?.updatedOn)}
            </Typography>
          </SmallMarginBox>
          <SmallMarginBox>
            <Typography>
              <BoldText>Carestage: </BoldText>
              {getEntityAttribute('CMCareStage')}
            </Typography>
          </SmallMarginBox>
          <SmallMarginBox>
            <Typography>
              <BoldText>Program: </BoldText>
              {getEntityAttribute('CMProgram')}
            </Typography>
          </SmallMarginBox>
          <SmallMarginBox>
            <Typography>
              <BoldText>Risk Level: </BoldText>
              {getEntityAttribute('CMRiskLevel')}
            </Typography>
          </SmallMarginBox>
        </>
      ) : (
        <>
          {episodeType === 'appealgriev' && (
            <>
              <SmallMarginBox>
                <Typography>
                  <BoldText>A&G Type: </BoldText>
                  {episode?.type?.name}
                </Typography>
              </SmallMarginBox>
              <SmallMarginBox>
                <Typography>
                  <BoldText>Received Date/Time: </BoldText>
                  {formatDateTime(episode?.faxDateTime)}
                </Typography>
              </SmallMarginBox>
              <BigMarginBox>
                <Typography>
                  <BoldText>Updated by: </BoldText>
                  {episode?.updatedByUser?.fullName}
                </Typography>
              </BigMarginBox>
            </>
          )}

          <SmallMarginBox>
            <Typography>
              <BoldText>Created: </BoldText>
              {formatDate(episode?.createdOn)}
            </Typography>
          </SmallMarginBox>
          <SmallMarginBox>
            <Typography>
              <BoldText>Created by: </BoldText>
              {episode?.createdByUser?.fullName}
            </Typography>
          </SmallMarginBox>
          <BigMarginBox>
            <Typography>
              <BoldText>Source: </BoldText>
              {episode?.source?.name}
            </Typography>
          </BigMarginBox>

          {episodeType !== 'appealgriev' && (
            <>
              <SmallMarginBox>
                <Typography>
                  <BoldText>Internal Start: </BoldText>
                  {formatDate(episode?.internalStartDate)}
                </Typography>
              </SmallMarginBox>
              <SmallMarginBox>
                <Typography>
                  <BoldText>First Day of Service: </BoldText>
                  {formatDate(episode?.authorizationStartDate)}
                </Typography>
              </SmallMarginBox>
              <BigMarginBox>
                <Typography>
                  <BoldText>Last Day of Service: </BoldText>
                  {formatDate(episode?.requestedEndDate)}
                </Typography>
              </BigMarginBox>
            </>
          )}

          {episodeType === 'appealgriev' && (
            <>
              <SmallMarginBox>
                <Typography>
                  <BoldText>Case Description: </BoldText>
                </Typography>
              </SmallMarginBox>
              <BigMarginBox pr="24px">
                <StyledSummaryBox>
                  {getEntityAttribute('Description')}
                </StyledSummaryBox>
              </BigMarginBox>
            </>
          )}

          {canAllowSeeEpisodeSummary && (
            <>
              <SmallMarginBox>
                <Typography>
                  <BoldText>Episode Summary: </BoldText>
                </Typography>
              </SmallMarginBox>
              <BigMarginBox pr="24px">
                <StyledSummaryBox>
                  {getEntityAttribute('EpisodeSummary')}
                </StyledSummaryBox>
              </BigMarginBox>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EpisodePrimaryDetails;
