import React from 'react';
import { useDispatch } from 'react-redux';
import { openChecklistFax } from 'store/ui/modals/letters';
import StyledLink from './StyledLink';
import { IChecklistLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { useGetChecklistModelQuery } from 'graphql/hooks/getChecklist';

interface IChecklistFaxLinkProps extends IChecklistLinkProps {
  patientId?: number;
  episodeId?: number;
}

const ChecklistFaxLink = (props: IChecklistFaxLinkProps) => {
  const { data: checklistData } = useGetChecklistModelQuery(
    {
      checklistId: props.checklistId,
    },
    {
      skip: !(props.checklistId && props.checklistId > 0),
    }
  );
  const dispatch = useDispatch();
  const onChecklistFax = () => {
    const checklist = checklistData?.getChecklist;
    dispatch(
      openChecklistFax({
        patientId: props.patientId ?? checklist?.patientId ?? 0,
        episodeId: props.episodeId ?? checklist?.episodeId ?? 0,
        checklistId: props.checklistId,
      })
    );
  };

  return (
    <CustomTooltip title={props.title ?? 'Send a Fax of the document'}>
      <StyledLink onClick={onChecklistFax} data-cy="Fax">
        Fax
      </StyledLink>
    </CustomTooltip>
  );
};

export default ChecklistFaxLink;
