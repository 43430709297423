import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export interface IReminderCellProps {
  children: ReactNode;
  style?: CSSProperties;
}
const StyledCell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: row;
`;

const ReminderCell = (props: IReminderCellProps) => (
  <StyledCell>{props.children}</StyledCell>
);

export default ReminderCell;
