import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, RadioGroup } from '@mui/material';
import Radio from '@mui/material/Radio';
import FormControl from '../backgroundTab/formControl';
import PrescriptionForm from './PrescriptionForm';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { PatientMedicationType } from 'graphql/graphqlTypes';
import { usePrevious } from 'hooks';

const MedicationForm = () => {
  const items =
    useSelector(
      (state: IState) =>
        state.patientDetails.medicationsTab?.selectedMedicationItems
    ) ?? [];

  const formData =
    useSelector((state: IState) =>
      state.patientDetails.medicationsTab.medicationsList?.find(
        (item) => items.length && item.id === items[0]
      )
    ) ?? null;

  const isNew = useSelector(
    (state: IState) => state.patientDetails?.medicationsTab?.isNew
  );

  const [selectedForm, setSelectedForm] = useState<PatientMedicationType>(
    formData?.type ?? PatientMedicationType.Prescription
  );
  const previousSelectedForm = usePrevious(selectedForm);

  useEffect(() => {
    const newValue = isNew
      ? PatientMedicationType.Prescription
      : formData?.type ?? PatientMedicationType.Prescription;
    if (newValue !== previousSelectedForm) {
      setSelectedForm(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={6} style={{ paddingRight: '50px' }}>
          <FormControl title="Type" isRequired={false}>
            <RadioGroup
              value={selectedForm}
              style={{ display: 'flex', flexDirection: 'row' }}
              onChange={(e) =>
                setSelectedForm(e.target.value as PatientMedicationType)
              }
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Prescription"
                value={PatientMedicationType.Prescription}
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="OTC"
                value={PatientMedicationType.Otc}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <PrescriptionForm
        isPrescriptionForm={selectedForm === PatientMedicationType.Prescription}
        isNew={!!isNew}
      />
    </Box>
  );
};

export default MedicationForm;
