import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';

const DetailRowCellComponent = (props: Table.DataCellProps) => (
  <td
    style={{
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 'normal',
      padding: '0px 8px 16px 8px',
      color: COLORS.GREY100,
      verticalAlign: 'initial',
      backgroundColor: COLORS.WHITE,
      height: '24px',

      ...(props.column.name === 'empty'
        ? {
            backgroundColor: COLORS.BLUE10,
          }
        : {}),
      ...(props.column.name === 'parNotes'
        ? {
            backgroundColor: COLORS.BLUE10,
          }
        : {}),
      maxHeight: '40px',
    }}
    data-cy="table-cell"
  >
    {props.children}
  </td>
);

export default DetailRowCellComponent;
