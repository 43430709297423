import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdateChecklistProblemMutationVariables = Types.Exact<{
  problem: Types.UpdateChecklistProblemModelInput;
}>;

export type UpdateChecklistProblemMutation = {
  __typename?: 'Mutation';
  updateChecklistProblem?: boolean | null;
};

export const UpdateChecklistProblemDocument = `
    mutation updateChecklistProblem($problem: UpdateChecklistProblemModelInput!) {
  updateChecklistProblem(problem: $problem)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateChecklistProblem: build.mutation<
      UpdateChecklistProblemMutation,
      UpdateChecklistProblemMutationVariables
    >({
      query: (variables) => ({
        document: UpdateChecklistProblemDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateChecklistProblemMutation } = injectedRtkApi;
