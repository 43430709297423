import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableComponent = (props: any) => {
  return (
    <Table.Container
      {...props}
      style={{
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px',
      }}
    />
  );
};

export default TableComponent;
