import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { openFaxFlowModal } from 'store/ui/modals/searchPatient';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { EPISODE_SOURCE_FAX } from '../consts';

const MrnLink = styled(Link)({
  width: '120px',
  color: COLORS.SYMPHONY_BLUE,
  cursor: 'pointer',
  fontWeight: 500,
  lineHeight: '20px',
});

const PatientMRNFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const dispatch = useDispatch();
  const handleMRNClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch(
      openPatientDetailsModal({
        patientId: Number(
          props.row.patientId ??
            (props.row.EpisodeSource !== EPISODE_SOURCE_FAX
              ? props.row.id
              : undefined)
        ),
        episodeId: 0,
        activeTabName: 'Background',
        activeSubTabName: '',
      })
    );
  };

  const handleAttachmentClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch(
      openFaxFlowModal({
        episodeId: Number(props.row.episodeId),
        episodeAttachmentId: Number(props.row.episodeAttachmentId),
      })
    );
    window.open(
      `/FaxPreview/${props.row.episodeAttachmentId}`,
      '_blank',
      'menubar=1,resizable=1,width=1350,height=1250'
    );
  };

  return (
    <>
      {props.value && (
        <MrnLink onClick={handleMRNClick}>
          <span>{props.value}</span>
        </MrnLink>
      )}
      {!props.value && Number(props.row.episodeAttachmentId) > 0 && (
        <Button
          onClick={handleAttachmentClick}
          color="primary"
          variant="outlined"
        >
          {props.row.EpisodeSource}
        </Button>
      )}
    </>
  );
};

export default PatientMRNFormatter;
