import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeTabTypesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetEpisodeTabTypesQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeTabTypes?: Array<{
    __typename?: 'EpisodeTabTypes';
    tabName?: string | null;
    episodeTypes?: Array<number | null> | null;
  } | null> | null;
};

export const GetEpisodeTabTypesDocument = `
    query getEpisodeTabTypes {
  getEpisodeTabTypes {
    tabName
    episodeTypes
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeTabTypes: build.query<
      GetEpisodeTabTypesQuery,
      GetEpisodeTabTypesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetEpisodeTabTypesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeTabTypesQuery, useLazyGetEpisodeTabTypesQuery } =
  injectedRtkApi;
