import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionSummary, Typography } from '@mui/material';

import styled from 'styled-components';
import MenuLegend from './MenuLegend';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { COLORS } from 'consts/styles';
import { MozartIndicator } from 'features/mozart/components';
import { ChecklistStorageType } from 'store/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import ReadOnlyNavBarSection from 'components/actions/navbar/ReadOnlyNavBarSection';
import CollapsableItems from 'components/actions/navbar/CollapsableItems';
import { setActiveSectionIndex } from 'store/actions/checklistSlice';

const StyledNavHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIndicatorWrapper = styled.div`
  margin-bottom: 8px;
`;

const StyledNavHeaderWrapper = styled.div`
  padding: 24px 24px 8px 24px;
`;
const StyledInnerPanel = styled.div`
  padding-bottom: 8px;
`;

export interface ICollapsableSectionsProps {
  storageType: ChecklistStorageType;
  isReview?: boolean;
}

const StyledAccordionSummary = styled(AccordionSummary)`
  margin-bottom: 0;
  padding: 0 24px;

  &.MuiAccordionSummary-root {
    min-height: auto !important;
  }

  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
    margin: 0 !important;
  }

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    margin: 0;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  :last-of-type {
    margin-bottom: 0 !important;
  }

  :before {
    display: none;
  }
`;

interface HandleOpenSectionParams {
  checklistId: number | null | undefined;
}

const CollapsableSections = forwardRef<
  HTMLDivElement,
  ICollapsableSectionsProps
>(({ storageType, isReview }, navContainerRef) => {
  const [activeChecklistId, setActiveChecklistId] = useState<number | null>(
    null
  );

  const ref = useRef<HTMLDivElement | null>(null);
  const lastNavItemRef = useRef<HTMLDivElement | null>(null);

  const mozartWorkflow = useSelector(
    (state: IState) => state.mozart.mozartWorkflow
  );
  const currentChecklistId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].checklist?.id
  );
  const mozartViewOnly = useSelector(
    (state: IState) => state.mozart.mozartViewOnly
  );
  useEffect(() => {
    setActiveChecklistId(Number(currentChecklistId));
  }, [currentChecklistId]);

  const lastActionIndex = mozartWorkflow?.actions
    ? mozartWorkflow.actions.length - 1
    : undefined;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      lastActionIndex &&
      lastActionIndex > 0 &&
      navContainerRef != null &&
      typeof navContainerRef !== 'function' &&
      navContainerRef.current &&
      lastNavItemRef.current
    ) {
      const navHeight = navContainerRef.current?.clientHeight / 2;
      navContainerRef.current.scrollTo({
        behavior: 'smooth',
        top: lastNavItemRef.current.offsetTop + navHeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActionIndex]);

  const handleOpenSection = ({ checklistId }: HandleOpenSectionParams) => {
    setActiveChecklistId(
      checklistId === activeChecklistId ? null : (checklistId as number)
    );
    if (checklistId !== activeChecklistId) {
      const section = document.querySelector(`#section-${checklistId}`);
      section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      dispatch(
        setActiveSectionIndex({
          storageType,
          activeSection: {
            id: 1,
            categoryId: String(activeChecklistId),
          },
        })
      );
    }
  };

  const isReviewSection = ({ checklistId }: HandleOpenSectionParams) =>
    isReview ||
    mozartViewOnly ||
    currentChecklistId !== checklistId?.toString();

  const typographyVariant = ({ checklistId }: HandleOpenSectionParams) =>
    activeChecklistId === checklistId ? 'body2' : 'body1';

  const renderArrow = ({ checklistId }: HandleOpenSectionParams) => {
    return activeChecklistId === checklistId ? (
      <KeyboardArrowUp style={{ color: COLORS.GREY100 }} />
    ) : (
      <KeyboardArrowDown style={{ color: COLORS.GREY100 }} />
    );
  };

  return (
    <>
      <StyledNavHeaderWrapper>
        <StyledIndicatorWrapper>
          <MozartIndicator />
        </StyledIndicatorWrapper>
        <StyledNavHeader>
          <Typography variant="h4">Mozart Workflow</Typography>
          <div ref={ref}>
            <MenuLegend ref={ref} />
          </div>
        </StyledNavHeader>
      </StyledNavHeaderWrapper>
      <StyledInnerPanel>
        {mozartWorkflow?.actions.map((action, actionIndex) => (
          <StyledAccordion
            expanded={activeChecklistId === action.checklistId}
            key={action.id}
          >
            <StyledAccordionSummary
              ref={actionIndex === lastActionIndex ? lastNavItemRef : null}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() =>
                handleOpenSection({ checklistId: action.checklistId })
              }
            >
              <Typography
                variant={typographyVariant({ checklistId: action.checklistId })}
              >
                {action.stepName}
              </Typography>
              {renderArrow({ checklistId: action.checklistId })}
            </StyledAccordionSummary>
            <CollapsableItems
              action={action}
              lastActionIndex={lastActionIndex}
              isReview={isReviewSection({ checklistId: action.checklistId })}
              storageType={storageType}
              actionIndex={actionIndex}
            />
          </StyledAccordion>
        ))}
        <ReadOnlyNavBarSection />
      </StyledInnerPanel>
    </>
  );
});

CollapsableSections.displayName = 'CollapsableSections';

export default CollapsableSections;
