import React from 'react';
import { Table, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';

const CellComponentStyle = {
  padding: '10px 8px',
  color: COLORS.BLACK,
  lineHeight: '20px',
};

export const CellComponentPadded = (props: Table.DataCellProps) => (
  <VirtualTable.Cell {...props} style={CellComponentStyle} data-cy="table-cell">
    {props.children}
  </VirtualTable.Cell>
);
