import { Box, Divider, Grid, Typography } from '@mui/material';
import { RequiredReason } from 'components/actions/sections/SectionBody/Items/HealthService/Components/RequiredReason';
import { COLORS } from 'consts/styles';
import React from 'react';
import { ServiceRowReview } from './ServiceRowReview';
import {
  HealthServiceCodesActionValue,
  ILookupValue,
} from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { StyledHeaderDiv, StyledHeader } from './styles';
import {
  getGridBackground,
  getDecisionIcon,
} from 'components/actions/sections/SectionBody/Items/HealthService/HealthServiceV2helper';
import {
  DecisionAction,
  HealthServiceAuthorizationType,
} from 'components/actions/sections/SectionBody/Items/HealthService/types';
import { healthServiceDisplayMode } from 'components/actions/sections/SectionBody/HealthServiceDisplayMode';

export interface IIndividualServiceProps {
  item: HealthServiceCodesActionValue;
  determinationReasons: { [key: string]: ILookupValue[] };
  hsAuthorizationType: number;
  hsDisplayMode?: number;
}

const StyledDivider = styled(Divider)({
  marginLeft: '10px',
  marginRight: '10px',
  marginBottom: '0px',
  height: '20px',
  backgroundColor: COLORS.BLACK,
});
const StyledGridContainer = styled(Grid)({
  marginBottom: '20px',
  backgroundColor: COLORS.GREY4,
  borderRadius: '4px',
  overflow: 'hidden',
});
const StyledDecisionBox = styled(Box)({
  borderLeft: '1px solid #D7D7D7',
  paddingLeft: '30px',
  marginTop: '3px',
  width: '25%',
});

const IndividualService = (props: IIndividualServiceProps) => {
  const { item, determinationReasons, hsAuthorizationType, hsDisplayMode } =
    props;
  const determinationReason = item.healthServiceActionValue
    ?.determinationDecision
    ? determinationReasons[
        item.healthServiceActionValue?.determinationDecision
      ].find(
        (x) => x.id === item?.healthServiceActionValue?.determinationReason?.id
      )
    : null;

  const gridColor = getGridBackground(
    item.healthServiceActionValue?.determinationDecision
  );
  return (
    <StyledGridContainer
      xs={12}
      container
      item
      alignItems="center"
      direction="row"
    >
      <StyledHeader $color={gridColor}>
        <StyledHeaderDiv>
          {getDecisionIcon(
            item.healthServiceActionValue?.determinationDecision
          )}
          <Typography variant="body2">{item.code}</Typography>
          <StyledDivider orientation="vertical" />
          <CustomTooltip title={item.description ?? ''}>
            <Typography noWrap variant="body1">
              {item.description}
            </Typography>
          </CustomTooltip>
        </StyledHeaderDiv>
      </StyledHeader>
      {hsAuthorizationType != HealthServiceAuthorizationType.Episode && (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          px="20px"
        >
          <Box style={{ width: '75%' }}>
            <ServiceRowReview type="Requested" item={item} />
            {hsDisplayMode !== healthServiceDisplayMode.requestingCodes && (
              <ServiceRowReview
                type="Approved"
                item={item}
                hideDates={
                  item.healthServiceActionValue?.determinationDecision ===
                    DecisionAction.pending ||
                  item.healthServiceActionValue?.determinationDecision ===
                    DecisionAction.denied
                }
                hideValues={
                  item.healthServiceActionValue?.determinationDecision ===
                  DecisionAction.pending
                }
              />
            )}
          </Box>
          {hsDisplayMode !== healthServiceDisplayMode.requestingCodes && (
            <StyledDecisionBox py="16px">
              <Typography variant="body1">Decision&nbsp;</Typography>
              <Typography variant="body2">
                {item.healthServiceActionValue?.determinationDecision}
              </Typography>
              <Box pt="24px">
                <RequiredReason isRequired={false} />
                <Typography variant="body2">
                  {determinationReason?.name}
                </Typography>
              </Box>
            </StyledDecisionBox>
          )}
        </Box>
      )}
    </StyledGridContainer>
  );
};

export default IndividualService;
