import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import MozartOutOfTurnStepsList from 'components/mozartOutOfTurnStepsList';

const MozartActionFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  return (
    <div style={{ textAlign: props.row.nextStep ? 'center' : 'left' }}>
      <MozartOutOfTurnStepsList
        workflowInstanceId={props.row.workFlowInstanceId}
        nextStepName={props.row.nextStep || props.row.Episode_NextStep}
        displayType="button"
        mozartWorkflowCompleted={props.row.mozartWorkflowCompleted}
      />
    </div>
  );
};

const MozartActionProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={MozartActionFormatter} {...props} />
);

export default MozartActionProvider;
