/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import {
  Grid as TableGrid,
  TableSelection,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import {
  removePriorAuthCode,
  updateModelForNewSelection,
} from './PriorAuthcodes.helpers';

import { SelectedRow, SymphonyTable } from 'components/gridFormatters';
import {
  ChecklistItemPriorAuthCodes,
  CheckPriorAuthorizationResult,
  PriorAuthCodesActionModel,
  PriorAuthCodesActionValue,
} from 'graphql/graphqlTypes';
import HeaderSelectCell from 'components/home/grid/selectors/HeaderSelectCell';
import SelectCellWithoutRowClick from 'components/home/grid/selectors/SelectCellWithoutRowClick';
import DeletePriorAuthRowActionProvider from 'components/home/grid/providers/DeletePriorAuthRowActionProvider';
import PriorAuthPolicyProvider from 'components/home/grid/providers/PriorAuthPolicyProvider';
import { PAMessageProvider, PATextProvider } from './priorAuthTextProvider';
import { apEnabledColumnsExtension } from './ColumnSettings/apEnabledColumnsExtension';
import { apDisabledColumnsExtension } from './ColumnSettings/apDisabledColumnsExtension';
import {
  getCodeLabel,
  getDescriptionLabel,
} from 'components/actions/sections/SectionBody/Items/PriorAuthCodes/PriorAuthcodes.helpers';
import PriorAuthHeaderCellComponent from './priorAuthHeaderCellComponent';
import { CellComponentPadded } from 'components/gridFormatters/CellComponentPadded';

export interface IChecklistItemPriorAuthPolicyTableProps {
  model: PriorAuthCodesActionModel;
  item: ChecklistItemPriorAuthCodes;
  onChange: (state: PriorAuthCodesActionModel) => void;
  selectedItem: Array<number | string>;
}

const PriorAuthPolicyTable = (
  props: IChecklistItemPriorAuthPolicyTableProps
) => {
  const { model, item, onChange, selectedItem } = props;
  const paOrderableOptions = item.options;
  const pAShowCheckPolicy = paOrderableOptions.pAShowCheckPolicy;
  const pAAllowSelection = paOrderableOptions.pAAllowSelection;
  const isDisabled = pAShowCheckPolicy && !pAAllowSelection;
  const codeLabel = getCodeLabel(paOrderableOptions.codeLabel);
  const descriptionLabel = getDescriptionLabel(
    paOrderableOptions.descriptionLabel
  );

  const columns = pAShowCheckPolicy
    ? [
        { name: 'code', title: codeLabel },
        { name: 'description', title: descriptionLabel },
        { name: 'priorAuthRequirement', title: 'Auth Policy' },
        { name: 'primaryMessage', title: ' ' },
        {
          name: 'id',
          title: ' ',
          getCellValue: (row: CheckPriorAuthorizationResult) => ({
            id: Number(row?.id ?? 0),
            onDelete: handleDeleteCode,
          }),
        },
      ]
    : [
        { name: 'code', title: codeLabel },
        { name: 'description', title: descriptionLabel },
        {
          name: 'id',
          title: ' ',
          getCellValue: (row: CheckPriorAuthorizationResult) => ({
            id: Number(row?.id ?? 0),
            onDelete: handleDeleteCode,
          }),
        },
      ];

  const handleDeleteCode = (id: number) => {
    const newModel = removePriorAuthCode(model, id);
    onChange(newModel);
  };

  const handleSetSelection = (selection: (string | number)[]) => {
    if (pAAllowSelection && pAShowCheckPolicy) {
      const newModel = updateModelForNewSelection(selection, model);
      onChange(newModel);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <TableGrid
        columns={columns}
        rows={model.priorAuthCodesActionValues}
        getRowId={(row: PriorAuthCodesActionValue) => Number(row.id)}
      >
        <SelectionState
          selection={selectedItem}
          defaultSelection={[0]}
          onSelectionChange={handleSetSelection}
        />
        <PATextProvider isDisabled={isDisabled} column={'code'} />
        <PATextProvider isDisabled={isDisabled} column={'description'} />
        <PriorAuthPolicyProvider for={['priorAuthRequirement']} />
        <PAMessageProvider isDisabled={isDisabled} column={'primaryMessage'} />

        <DeletePriorAuthRowActionProvider for={['id']} />
        <SymphonyTable
          columnExtensions={
            pAShowCheckPolicy
              ? apEnabledColumnsExtension
              : apDisabledColumnsExtension
          }
          cellComponent={CellComponentPadded}
          messages={{ noData: 'No items added' }}
        />
        <IntegratedSelection />
        <TableHeaderRow cellComponent={PriorAuthHeaderCellComponent} />
        <TableSelection
          cellComponent={SelectCellWithoutRowClick}
          showSelectAll={true}
          headerCellComponent={HeaderSelectCell}
          rowComponent={SelectedRow}
          showSelectionColumn={pAShowCheckPolicy}
          selectionColumnWidth={40}
        />
      </TableGrid>
    </div>
  );
};

PriorAuthPolicyTable.displayName = 'PriorAuthPolicyTable';
export default PriorAuthPolicyTable;
