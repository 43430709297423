import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type FinishChecklistLaterMutationVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
  connectionId: Types.Scalars['String'];
  entryStep?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type FinishChecklistLaterMutation = {
  __typename?: 'Mutation';
  finishChecklistLater?: boolean | null;
};

export const FinishChecklistLaterDocument = `
    mutation finishChecklistLater($checklistId: Int!, $connectionId: String!, $entryStep: String) {
  finishChecklistLater(
    checklistId: $checklistId
    connectionId: $connectionId
    entryStep: $entryStep
  )
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    finishChecklistLater: build.mutation<
      FinishChecklistLaterMutation,
      FinishChecklistLaterMutationVariables
    >({
      query: (variables) => ({
        document: FinishChecklistLaterDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFinishChecklistLaterMutation } = injectedRtkApi;
