import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UnlockEpisodeMutationVariables = Types.Exact<{
  episodeId?: Types.InputMaybe<Types.Scalars['Int']>;
  checklistId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type UnlockEpisodeMutation = {
  __typename?: 'Mutation';
  unlockEpisode?: {
    __typename?: 'ActionEpisodeResult';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const UnlockEpisodeDocument = `
    mutation unlockEpisode($episodeId: Int, $checklistId: Int) {
  unlockEpisode(episodeId: $episodeId, checklistId: $checklistId) {
    success
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    unlockEpisode: build.mutation<
      UnlockEpisodeMutation,
      UnlockEpisodeMutationVariables | void
    >({
      query: (variables) => ({ document: UnlockEpisodeDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUnlockEpisodeMutation } = injectedRtkApi;
