import React from 'react';
import Typography from '@mui/material/Typography';
import SectionNumber from '../sectionNumber';
import { COLORS } from 'consts/styles';
import { GetHtmlId } from 'components/actions/navbar';
import { ChecklistStorageType } from 'store/actions/types';
import { ChecklistCategory } from 'graphql/graphqlTypes';
import Icon, { ICONS } from 'components/icon';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IState } from 'store';

const StyledCategoryWrapper = styled.div`
  display: flex;
  padding: 0 24px 16px;
  align-items: center;
  position: relative;
`;

const StyledTitle = styled(Typography)({
  fontSize: '20px',
  letterSpacing: 0.15,
  lineHeight: '24px',
  fontWeight: 500,
  paddingLeft: '8px',
});

const StyledAnchor = styled.div`
  position: absolute;
  top: -120px;
  left: 0;
`;

export interface ISectionsHeaderProps {
  storageType: ChecklistStorageType;
  category: ChecklistCategory;
  hideNumbers?: boolean;
  categoryIndex: number;
}

const SectionHeader = (props: ISectionsHeaderProps) => {
  const { storageType, category, categoryIndex, hideNumbers } = props;
  const isMozartReadOnly = useSelector(
    (state: IState) => state.mozart.mozartViewOnly
  );

  return (
    <>
      {storageType !== ChecklistStorageType.CAREPLAN && (
        <StyledCategoryWrapper data-testid="section-header-item">
          <StyledAnchor id={GetHtmlId(category.name, category.id)} />
          {!hideNumbers && (
            <SectionNumber
              number={categoryIndex}
              status={
                category.isReadOnly || isMozartReadOnly ? 'done' : undefined
              }
              size="large"
              categoryId={category.id}
              isReadOnly={category.isReadOnly || isMozartReadOnly}
              category={category}
              storageType={storageType}
            />
          )}
          <StyledTitle variant="h4">{category.name}</StyledTitle>
          {category.isReadOnly && (
            <Icon
              icon={ICONS.Lock}
              size={20}
              color={COLORS.GREY100}
              style={{ marginLeft: '8px' }}
              testId="section-lock-icon"
            />
          )}
        </StyledCategoryWrapper>
      )}
    </>
  );
};

export default SectionHeader;
