import React from 'react';
import Loader from 'components/loader';
import StyledLink from './StyledLink';
import { useDownloadFile } from './hooks';
import { IAttachmentLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IAttachmentPrintLinkProps extends IAttachmentLinkProps {
  label?: string;
  isDisabled: boolean;
}

const AttachmentPrintLink = (props: IAttachmentPrintLinkProps) => {
  const { downloadFile, isDownloadingFile } = useDownloadFile(props.label);
  return (
    <>
      <CustomTooltip title={props.title ?? 'View'}>
        <StyledLink
          onClick={() =>
            downloadFile({
              id: Number(props.attachmentId),
              name: props.fileName ?? '',
            })
          }
          $isDisabled={props.isDisabled}
          data-cy="View"
        >
          Download
        </StyledLink>
      </CustomTooltip>
      <Loader active={isDownloadingFile} />
    </>
  );
};

export default AttachmentPrintLink;
