import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { QuickNoteType } from 'graphql/graphqlTypes';
import { useAddEpisodeBalancerCriteriaToQueueMutation } from 'graphql/hooks/addEpisodeBalancerCriteriaToQueue';
import { IMessageState } from 'features/letters';
import { launchCoachEducation } from 'features/education/coach';
import { openLetters } from 'store/ui/modals/letters';
import { openQuickNote } from 'store/ui/modals/quickNote';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { Privilege } from 'store/roles/types';
import Icon, { ICONS } from 'components/icon';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import Snackbar from 'components/snackbar';
import { EpisodeDistributeForm } from 'components/episodeDistributeDialog/types';
import { toAddEpisodeBalancerCriteriaMutationVariables } from 'components/episodeDistributeDialog/EpisodeDistributeDialog.helper';
import ExportToggleButton from 'components/home/grid/ExportToggleButton';
import EpisodeDistributeDialog from 'components/episodeDistributeDialog';
import IGridHeaderModel from './GridHeaderModel';

const StyledDivider = styled(Divider)`
  height: 24px;
  margin: 0;
`;

const HeaderPanelActionButtons = (props: IGridHeaderModel) => {
  const {
    onMultiEdit,
    patientSelected,
    isEditDisabled,
    patientId,
    episodeId,
    isFaxRow,
    handleTriggerExportGridData,
  } = props;
  const dispatch = useDispatch();
  const [addEpisodeBalancerCriteria] =
    useAddEpisodeBalancerCriteriaToQueueMutation();
  const [distributeDialogOpen, setDistributeDialogOpen] = useState(false);
  const [message, setMessage] = useState<IMessageState | null>();
  const canEditSelected = hasUserPrivilege(Privilege.EditSelected);
  const canAddLetters = hasUserPrivilege(Privilege.AddLetters);
  const canAddNote = hasUserPrivilege(Privilege.AddMemberEpisodeNotes);
  const canEducate = hasUserPrivilege(Privilege.Education);
  const canExportGridData = hasUserPrivilege(Privilege.ExportHomeScreen);
  const canBulkAssign = hasUserPrivilege(Privilege.BulkAssign);

  const showDivider =
    canEditSelected ||
    canAddLetters ||
    canAddNote ||
    canEducate ||
    canBulkAssign;

  const actionsDisabled = !patientSelected || isFaxRow;
  const lettersDisabled = actionsDisabled || episodeId <= 0;

  const handleClickOpenQuickDialog = (type: QuickNoteType) => {
    dispatch(
      openQuickNote({
        patientId: patientId,
        type: type,
        episodeId: episodeId ?? 0,
        baseQuickNoteId: null,
      })
    );
  };

  const handleCoachClick = async () => {
    await launchCoachEducation(
      patientId,
      () => void 0,
      (err) => {
        if (err) {
          dispatch(showErrorPopup({ message: err }));
        }
      }
    );
  };

  const handleBulkAssign = () => {
    setDistributeDialogOpen(true);
  };

  const handleDistribute = (value: EpisodeDistributeForm) => {
    const episodeBalancerCriteria =
      toAddEpisodeBalancerCriteriaMutationVariables(value);
    addEpisodeBalancerCriteria({ episodeBalancerCriteria }).then((result) => {
      setDistributeDialogOpen(false);
      if ('data' in result) {
        setMessage({
          active: true,
          type: 'success',
          title: 'Success',
          text: 'Selected criteria has been successfully queued for execution.',
        });
      } else if ('error' in result) {
        dispatch(
          showErrorPopup({
            message: 'Error occurred while queuing for episode distribution.',
          })
        );
      }
    });
  };

  const getIconColor = (status: boolean) =>
    status ? COLORS.GREY60 : COLORS.SYMPHONY_BLUE;

  return (
    <>
      {showDivider && (
        <StyledDivider orientation="vertical" data-testid="panel-divider" />
      )}

      <div
        style={{ display: 'flex', marginRight: '-10px', marginLeft: '-10px' }}
      >
        {canAddNote && (
          <CustomTooltip title="Episode note">
            <span>
              <IconButton
                aria-label="episode note"
                data-testid="episode-note"
                disabled={actionsDisabled}
                onClick={() =>
                  handleClickOpenQuickDialog(QuickNoteType.Episode)
                }
                size="large"
              >
                <Icon
                  icon={ICONS.Episode_Note}
                  size={24}
                  color={getIconColor(actionsDisabled)}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        )}
        {canAddNote && (
          <CustomTooltip title="Member note">
            <span>
              <IconButton
                aria-label="member note"
                data-testid="member-note"
                disabled={actionsDisabled}
                onClick={() =>
                  handleClickOpenQuickDialog(QuickNoteType.Patient)
                }
                size="large"
              >
                <Icon
                  icon={ICONS.Member_Note}
                  size="24"
                  color={getIconColor(actionsDisabled)}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        )}
        {canAddLetters ? (
          <CustomTooltip title="Letters">
            <span>
              <IconButton
                aria-label="letters"
                data-testid="letter-button"
                disabled={lettersDisabled}
                onClick={() => dispatch(openLetters({ episodeId, patientId }))}
                size="large"
              >
                <Icon
                  icon={ICONS.Letters}
                  size="24"
                  color={getIconColor(lettersDisabled)}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        ) : null}
        {canEditSelected ? (
          <CustomTooltip title="Edit selection">
            <span>
              <IconButton
                aria-label="edit selected"
                onClick={() => onMultiEdit()}
                data-cy="edit-selected"
                disabled={isEditDisabled}
                data-testid="multi-edit-button"
                size="large"
              >
                <Icon
                  icon={ICONS.Edit}
                  size="24"
                  color={getIconColor(isEditDisabled)}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        ) : null}
        {canBulkAssign && (
          <CustomTooltip title="Bulk Assign" containerElement="span">
            <IconButton
              aria-label="bulkAssign"
              onClick={handleBulkAssign}
              size="large"
            >
              <Icon
                icon={ICONS.AssignUser}
                size="24"
                color={COLORS.SYMPHONY_BLUE}
              />
            </IconButton>
          </CustomTooltip>
        )}
        {canEducate && (
          <CustomTooltip title="Education/Healthwise">
            <span>
              <IconButton
                aria-label="educate"
                onClick={handleCoachClick}
                size="large"
              >
                <Icon
                  icon={ICONS.Educate}
                  size="24"
                  color={COLORS.SYMPHONY_BLUE}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        )}
        {canExportGridData ? (
          <ExportToggleButton
            handleTriggerExportGridData={handleTriggerExportGridData}
            buttonMessage="Export Data"
          />
        ) : null}
      </div>
      {distributeDialogOpen && (
        <EpisodeDistributeDialog
          open={distributeDialogOpen}
          onDistribute={handleDistribute}
          onClose={() => {
            setDistributeDialogOpen(false);
          }}
        />
      )}
      {message && (
        <Snackbar
          icon={message.type === 'success' ? ICONS.Checkmark : ICONS.Warning}
          {...message}
          open={message?.active}
          onClose={() => setMessage({ ...message, active: false })}
          duration={message.type === 'success' ? 4000 : 'infinite'}
        />
      )}
    </>
  );
};

export default HeaderPanelActionButtons;
