import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISearchPatientDialogState {
  isOpen: boolean;
  episodeId: number;
  episodeAttachmentId: number;
}

export const initialSearchPatientState: ISearchPatientDialogState = {
  isOpen: false,
  episodeId: 0,
  episodeAttachmentId: 0,
};

const searchPatient = createSlice({
  name: 'SearchPatientState',
  initialState: initialSearchPatientState,
  reducers: {
    openFaxFlowModal(
      state,
      action: PayloadAction<{
        episodeId: number;
        episodeAttachmentId: number;
      }>
    ) {
      state.isOpen = true;
      state.episodeId = action.payload.episodeId;
      state.episodeAttachmentId = action.payload.episodeAttachmentId;
    },
    closeSearchPatientDialog() {
      return initialSearchPatientState;
    },
  },
});

export const { openFaxFlowModal, closeSearchPatientDialog } =
  searchPatient.actions;

export default searchPatient.reducer;
