import React from 'react';
import {
  EditingCell,
  ILabelCellProps,
  LabelCell,
} from 'components/gridFormatters';
import { PrimaryCell } from './cells/primaryCell';
import { OkToContact } from './cells/okToContact';
import { EditCellValidationProps } from './contactsGrid';

export const phoneColumns = [
  { name: 'type', title: 'Type' },
  { name: 'number', title: 'Number' },
  { name: 'isPrimary', title: 'Primary' },
  { name: 'isOk', title: 'OK to Contact' },
];

export const phoneColumnExtension = [
  { columnName: 'type', width: '80px' },
  { columnName: 'number' },
  { columnName: 'isPrimary', width: '60px' },
  { columnName: 'isOk', width: '100px' },
];

export const EditPhone = (props: EditCellValidationProps) => {
  switch (props.column.name) {
    case 'type':
      return <LabelCell {...props} />;
    case 'isPrimary':
      return <PrimaryCell {...props} />;
    case 'isOk':
      return <OkToContact {...props} />;
    default:
      return <EditingCell {...props} autoFocus required />;
  }
};

interface ILabelPhoneProps extends ILabelCellProps {
  disabled?: boolean;
}

export const LabelPhone = (props: ILabelPhoneProps) => {
  const { disabled, ...p } = props;
  switch (props.column.name) {
    case 'type':
      return <LabelCell {...p} />;
    case 'isPrimary':
      return <PrimaryCell {...p} disabled={disabled} />;
    case 'isOk':
      return <OkToContact {...p} disabled={disabled} />;
    default:
      return <LabelCell {...p} />;
  }
};
