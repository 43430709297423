import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes as Router } from 'react-router-dom';
import { ROUTE } from 'consts/ROUTE';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Loader from 'components/loader';
import NotFound from 'pages/error/notFound';
import { ErrorPopup } from 'components/ErrorPopup';
import ErrorPage from 'pages/error/error';

const Routes = () => {
  const Login = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "login" */
        'pages/login/Login'
      )
  );
  const SSO = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "sso" */
        'pages/sso/Sso'
      )
  );
  const Home = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "home" */
        'pages/home/Home'
      )
  );
  const ScenarioContainer = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "scenario" */
        'pages/scenario'
      )
  );
  const UserPreferences = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "preferences" */
        'pages/user/preferences'
      )
  );
  const InterQual = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "interQual" */
        'pages/interQual'
      )
  );
  const WorkflowLayout = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "workflow" */
        'pages/workflow'
      )
  );
  const MozartWorkflow = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "mozartWorkflow" */
        'pages/MozartWorkflow'
      )
  );
  const Version = React.lazy(
    () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "version" */
        'pages/version'
      )
  );
  const FaxPreview = React.lazy(
    () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "faxPreview" */
        'pages/FaxPreview'
      )
  );
  const UserSupportInformation = React.lazy(
    () =>
      import(
        /* webpackMode: "eager" */
        /* webpackChunkName: "SupportInformation" */
        'pages/user/SupportInformation'
      )
  );

  // targetComponent: the component that the route should normally show for a given path
  // defaultComponent: the component that would get shown if the targetComponent can't be
  // e.g. home component is what a user should see if they are logged in even if they type in the login page url.
  // authComponent: the component that would handle the auth if the user is not logged in

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader active={true} />}>
        <Router>
          <Route
            path="/"
            element={
              <PublicRoute path="/">
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/Login"
            element={
              <PublicRoute path="/Login">
                <Login />
              </PublicRoute>
            }
          />

          <Route
            path={ROUTE.InterQual}
            element={
              <PrivateRoute path={ROUTE.InterQual}>
                <InterQual />
              </PrivateRoute>
            }
          />
          <Route
            path="/Sso/:token"
            element={
              <PublicRoute path="/Sso/:token" anonymous={true}>
                <SSO />
              </PublicRoute>
            }
          />
          <Route
            path="/Version"
            element={
              <PublicRoute path="/Version" anonymous={true}>
                <Version />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTE.Home}
            element={
              <PrivateRoute path={ROUTE.Home}>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/Checklist/Problems"
            element={
              <PrivateRoute path="/Checklist/Problems">
                <ScenarioContainer />
              </PrivateRoute>
            }
          />
          <Route
            path="/Checklist/Problems/:id"
            element={
              <PrivateRoute path="/Checklist/Problems/:id">
                <ScenarioContainer />
              </PrivateRoute>
            }
          />
          <Route
            path="/Workflow/:id"
            element={
              <PrivateRoute path="/Workflow/:id">
                <MozartWorkflow />
              </PrivateRoute>
            }
          />
          <Route
            path="/Checklist/Actions/:id"
            element={
              <PrivateRoute path="/Checklist/Actions/:id">
                <WorkflowLayout />
              </PrivateRoute>
            }
          />
          <Route
            path="/Checklist/Review/:id"
            element={
              <PrivateRoute path="/Checklist/Review/:id">
                <WorkflowLayout />
              </PrivateRoute>
            }
          />
          <Route
            path="/Checklist/Finish/:id"
            element={
              <PrivateRoute path="/Checklist/Finish/:id">
                <WorkflowLayout />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE.FaxPreview}
            element={
              <PrivateRoute path={ROUTE.FaxPreview}>
                <FaxPreview />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE.Preferences}
            element={
              <PrivateRoute path={ROUTE.Preferences}>
                <UserPreferences />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTE.SupportInformation}
            element={
              <PrivateRoute path={ROUTE.SupportInformation}>
                <UserSupportInformation />
              </PrivateRoute>
            }
          />
          <Route path={ROUTE.Error} element={<ErrorPage />} />
          <Route path="*" element={<NotFound />} />
        </Router>
      </Suspense>
      <ErrorPopup />
    </BrowserRouter>
  );
};

export default Routes;
