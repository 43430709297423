import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type CheckPriorAuthorizationQueryVariables = Types.Exact<{
  request: Types.CheckPriorAuthorizationActionContextInput;
}>;

export type CheckPriorAuthorizationQuery = {
  __typename?: 'EntitiesQuery';
  checkPriorAuthorization?: Array<{
    __typename?: 'CheckPriorAuthorizationResult';
    id: number;
    code: string;
    isPARequired: boolean;
    description?: string | null;
    externalURL?: string | null;
    priorAuthRequirement?: Types.PaRequirement | null;
    primaryMessage?: string | null;
    secondaryMessage?: string | null;
    notificationMessage?: string | null;
  } | null> | null;
};

export const CheckPriorAuthorizationDocument = `
    query checkPriorAuthorization($request: CheckPriorAuthorizationActionContextInput!) {
  checkPriorAuthorization(request: $request) {
    id
    code
    isPARequired
    description
    externalURL
    priorAuthRequirement
    primaryMessage
    secondaryMessage
    notificationMessage
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkPriorAuthorization: build.query<
      CheckPriorAuthorizationQuery,
      CheckPriorAuthorizationQueryVariables
    >({
      query: (variables) => ({
        document: CheckPriorAuthorizationDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useCheckPriorAuthorizationQuery,
  useLazyCheckPriorAuthorizationQuery,
} = injectedRtkApi;
