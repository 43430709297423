export enum providersColumn {
  provider = 'provider',
  isPrimary = 'isPrimary',
  name = 'name',
  note = 'note',
  specialityName = 'specialityName',
}

export const editingStateProvidersColumnsExtension = [
  { columnName: providersColumn.provider, width: '18%' },
  { columnName: providersColumn.isPrimary, width: '6%' },
  { columnName: providersColumn.specialityName, width: '18%' },
  { columnName: providersColumn.note },
];

export const providersColumns = () => {
  return [
    { name: providersColumn.provider, title: 'Provider' },
    { name: providersColumn.isPrimary, title: 'Primary' },
    { name: providersColumn.specialityName, title: 'Speciality' },
    { name: providersColumn.note, title: 'Note' },
  ];
};
