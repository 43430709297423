import TableCell from '@mui/material/TableCell';
import React from 'react';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { useTeamMemberCareSiteUser } from '../careTeam.hooks';
import { Box, Typography } from '@mui/material';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

export interface IReminderStateProps {
  state?: number;
  color: COLORS;
  title: string;
}

export const ReminderState = (props: IReminderStateProps) => {
  const { state, color, title } = props;
  return state && state !== 0 ? (
    <CustomTooltip title={title}>
      <Box
        style={{ display: 'flex', flexDirection: 'row', marginRight: '11px' }}
      >
        <Icon icon={ICONS.Bell} size={16} color={color} />
        <Typography style={{ marginLeft: '5px' }}>x{state}</Typography>
      </Box>
    </CustomTooltip>
  ) : null;
};

export const RemindersCell = (props: ILabelCellProps) => {
  const value = useTeamMemberCareSiteUser(props.row);
  return (
    <TableCell {...props} style={CellComponentStyle}>
      <Box display="flex">
        <ReminderState
          state={value?.stateRed}
          color={COLORS.RED100}
          title={'Reminder overdue, Critical Importance'}
        />
        <ReminderState
          state={value?.stateOrange}
          color={COLORS.ORANGE100}
          title={'Reminder overdue, High Importance'}
        />
        <ReminderState
          state={value?.stateYellow}
          color={COLORS.YELLOW100}
          title={'Reminder overdue, Low Importance'}
        />
        <ReminderState
          state={value?.stateGreen}
          color={COLORS.GREEN100}
          title={'Reminder on schedule'}
        />
      </Box>
    </TableCell>
  );
};
