import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import { IState } from 'store';
import { useLocation, useParams } from 'react-router-dom';
import { MozartContent, MozartFooter } from './components';
import { resetChecklist } from 'store/actions/checklistSlice';
import ErrorNotification from 'components/ErrorNotification';
import { ChecklistStorageType } from 'store/actions/types';
import { COLORS } from 'consts/styles';
import { useFetchMainModel } from 'hooks';
import styled from 'styled-components';
import {
  useCurrentWorkflowStep,
  useGetChecklistForWorkflow,
} from 'pages/workflow/hooks';
import {
  resetMozartState,
  setMozartGlobalParams,
} from 'store/actions/mozartSlice';
import { ActionSidebar } from 'features/mozart/ActionSidebar';
import Icon, { ICONS } from 'components/icon';
import { throttle } from 'lodash';
import FailedRequests from 'components/failedRequests/FailedRequests';
import ChecklistExitModal from 'components/modal/ChecklistExitModal';
import theme from 'theme';
import UploadFilesLayout from 'components/uploadFiles/UploadFilesLayout';

const StyledContent = styled.div`
  justify-content: space-between;
  background-color: ${COLORS.GREY4};
  padding-top: 24px;
`;

const StyledContainer = styled.div`
  padding: 0 60px;
  min-height: 90vh;
`;

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: fit-content(25%) minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: ${theme.spacing(3)};

  @media (max-width: 1200px) {
    grid-template-columns: 25% minmax(0, 1fr);
  }
`;

const StyledBody = styled(Box)`
  padding-bottom: 80px;
  grid-column: 2;
  grid-row: span 2;
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${COLORS.BLUE10};
  position: fixed;
  bottom: 16px;
  right: 16px;
  &:hover {
    background-color: ${COLORS.BLUE20} !important;
  }
`;

const MozartWorkflowLayout = () => {
  const [showScrollToBottom, setShowScrollToBottom] = useState(true);
  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].checklist
  );

  const mainModel = useSelector((state: IState) => state.home.mainModel);
  const mozartWorkflowId = useSelector(
    (state: IState) => state.mozart.workflowChecklistId
  );

  const currentStep = useSelector((state: IState) => state.workflow.step);

  const restartMozart = useSelector(
    (state: IState) => state.mozart.restartMozart
  );

  const isReadOnlyWorkflow = useSelector(
    (state: IState) => state.mozart.isReadOnlyWorkflow
  );

  useFetchMainModel(mainModel.lookups.length === 0);
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const ref = useRef<HTMLDivElement | null>(null);

  const { isActionPage, workflowStep, workflowName } = useCurrentWorkflowStep(
    location.pathname.slice(0, location.pathname.lastIndexOf('/'))
  );

  const { isLoading, patientData } = useGetChecklistForWorkflow(
    mozartWorkflowId,
    workflowStep,
    workflowName,
    isActionPage,
    ChecklistStorageType.MOZART,
    params.id
  );

  useEffect(() => {
    return () => {
      dispatch(resetChecklist(ChecklistStorageType.MOZART));
      dispatch(resetMozartState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (restartMozart) {
      dispatch(
        setMozartGlobalParams({
          restartMozart: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restartMozart]);

  const checkScrollTop = () => {
    if (ref.current) {
      const { scrollHeight, offsetTop } = ref.current;
      const currentScroll = window.scrollY;
      const isBottomOfMozartSection =
        scrollHeight - 120 > window.innerHeight + currentScroll - offsetTop;
      setShowScrollToBottom(isBottomOfMozartSection);
    }
  };

  const throttledScroll = throttle(checkScrollTop, 400, { leading: true });

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll, false);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScrollToBottom = () => {
    if (ref?.current) {
      ref?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  };

  return (
    <StyledWrapper ref={ref}>
      <ErrorNotification
        storageType={ChecklistStorageType.MOZART}
        categories={checklist?.categories ?? []}
      />
      <FailedRequests storageType={ChecklistStorageType.MOZART} />
      {mainModel.lookups.length > 0 ? (
        <StyledContent>
          <StyledContainer>
            <StyledGrid data-testid="mozart-main-container">
              <ActionSidebar
                patientData={patientData}
                isFinishPage={false}
                isReviewPage={false}
                checklistStorageType={ChecklistStorageType.MOZART}
              />
              <StyledBody>
                {!restartMozart ? (
                  <MozartContent
                    isLoadingFinished={!isLoading}
                    isLoading={isLoading}
                    isPrinting={false}
                  />
                ) : null}
                {!isReadOnlyWorkflow && <MozartFooter />}
              </StyledBody>
            </StyledGrid>
          </StyledContainer>
        </StyledContent>
      ) : null}
      {currentStep !== 3 && !isLoading && showScrollToBottom ? (
        <StyledIconButton
          onClick={handleScrollToBottom}
          data-testid="scroll-down-button"
        >
          <Icon
            size={24}
            icon={ICONS.KeyboardArrowDown}
            color={COLORS.SYMPHONY_BLUE}
          />
        </StyledIconButton>
      ) : null}
      <UploadFilesLayout checklistStorage={ChecklistStorageType.MOZART} />
      <ChecklistExitModal checklistStorage={ChecklistStorageType.MOZART} />
    </StyledWrapper>
  );
};

export default MozartWorkflowLayout;
