import React, { forwardRef, useState } from 'react';

import Menu from 'components/menu';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { Box, IconButton, Typography } from '@mui/material';

const StyledIconButton = styled(IconButton)`
  padding: 4px;
`;

const MenuHeader = () => (
  <Box padding="14px 24px">
    <Typography variant="subtitle1">Legend</Typography>
  </Box>
);

const StyledMenuWrapper = styled.div`
  padding: 6px 24px;
`;
const StyledMenuInnerContainer = styled.div`
  display: flex;
`;
const StyledMenuInnerItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 48px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  align-items: center;
`;

const StyledHeader = styled(Typography)`
  padding-bottom: 12px;
`;

const StyledValidationBubble = styled.span<{ $color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
  margin-right: 16px;
`;

const StyledBubble = styled.span<{ $color: string; $hasBorder?: boolean }>`
  width: ${(props) => (props.$hasBorder ? '14px' : '16px')};
  height: ${(props) => (props.$hasBorder ? '14px' : '16px')};
  border-radius: 50%;
  background-color: ${(props) => props.$color};
  margin-right: 8px;
  border: ${(props) =>
    props.$hasBorder ? `1px solid ${COLORS.SYMPHONY_BLUE}` : 'none'};
`;

const MenuLegend = forwardRef<HTMLDivElement>((_props, ref) => {
  const [showLegend, setShowLegend] = useState(false);
  const anchor =
    ref != null && typeof ref !== 'function' ? (ref?.current as Element) : null;
  return (
    <>
      <StyledIconButton onClick={() => setShowLegend(!showLegend)}>
        <Icon size={16} icon={ICONS.Info} color={COLORS.GREY100} />
      </StyledIconButton>
      <Menu
        open={showLegend}
        onClose={() => setShowLegend(false)}
        anchor={anchor}
        header={<MenuHeader />}
      >
        <StyledMenuWrapper>
          <StyledHeader variant="body2">Sections</StyledHeader>
          <StyledMenuInnerContainer>
            <StyledMenuInnerItem>
              <InfoWrapper>
                <StyledBubble $color={COLORS.GREY10} />
                <Typography variant="body1">Unvisited</Typography>
              </InfoWrapper>
              <InfoWrapper>
                <StyledBubble $color={COLORS.WHITE} $hasBorder />
                <Typography variant="body1">Current</Typography>
              </InfoWrapper>
              <InfoWrapper>
                <StyledBubble $color={COLORS.GREY100} />

                <Typography variant="body1">Done</Typography>
              </InfoWrapper>
            </StyledMenuInnerItem>
            <StyledMenuInnerItem>
              <InfoWrapper>
                <StyledBubble $color={COLORS.SYMPHONY_BLUE} />
                <Typography variant="body1">Visited</Typography>
              </InfoWrapper>
              <InfoWrapper>
                <StyledBubble $color={COLORS.RED100} />
                <Typography variant="body1">Incomplete</Typography>
              </InfoWrapper>
            </StyledMenuInnerItem>
          </StyledMenuInnerContainer>
          <StyledHeader style={{ paddingTop: '16px' }} variant="body2">
            Required fields
          </StyledHeader>
          <StyledMenuInnerContainer>
            <StyledMenuInnerItem>
              <InfoWrapper>
                <StyledValidationBubble $color="#F02C0C" />
                <Typography variant="body1">Incomplete</Typography>
              </InfoWrapper>
            </StyledMenuInnerItem>
            <StyledMenuInnerItem>
              <InfoWrapper>
                <StyledValidationBubble $color={COLORS.COMPLETED_GREEN} />
                <Typography variant="body1">Completed</Typography>
              </InfoWrapper>
            </StyledMenuInnerItem>
          </StyledMenuInnerContainer>
        </StyledMenuWrapper>
      </Menu>
    </>
  );
});
MenuLegend.displayName = 'MenuLegend';

export default MenuLegend;
