import React from 'react';
import { Button } from '@mui/material';

export const CarePlanFilter = (props: {
  expandAll: () => void;
  collapseAll: () => void;
  disableActions: boolean;
}) => {
  const { expandAll, collapseAll, disableActions } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        paddingBottom: '16px',
      }}
    >
      <div>
        <Button
          size="small"
          variant="outlined"
          style={{ marginRight: '20px' }}
          onClick={expandAll}
          disabled={disableActions}
        >
          Expand All
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={collapseAll}
          disabled={disableActions}
        >
          Collapse All
        </Button>
      </div>
    </div>
  );
};
