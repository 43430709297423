import React from 'react';
import {
  StyledBody,
  StyledLinkContainer,
  StyledMessage,
  StyledOops,
  StyledOopsMessage,
} from './errorStyles';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { Button, Typography } from '@mui/material';
import VersionCheckTimer from 'components/version/VersionCheckTimer';

const ErrorPage = () => {
  const errorMessage = useSelector(
    (state: IState) => state.ui.error.errorMessage
  );

  const handleHomeClick = () => {
    location.href = '/';
  };

  return (
    <div className="errorRegion">
      <StyledBody>
        <StyledMessage>
          <StyledOops>Oops!</StyledOops>
          <Typography variant="subtitle2" style={{ paddingBottom: '20px' }}>
            Something went wrong
          </Typography>
          <StyledOopsMessage>
            We have captured this error message, if this continues to keep
            occurring please reach out to support
          </StyledOopsMessage>
          <b data-testid="error-page-message">{errorMessage ?? 'Error!'}</b>
          <StyledLinkContainer>
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={handleHomeClick}
            >
              Return to Home page
            </Button>
          </StyledLinkContainer>
        </StyledMessage>
      </StyledBody>
      <VersionCheckTimer />
    </div>
  );
};

export default ErrorPage;
