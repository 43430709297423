import React, { ReactElement } from 'react';
import {
  EditingCell,
  ILabelCellProps,
  LabelCell,
} from 'components/gridFormatters';
import { PrimaryCell } from './cells/primaryCell';
import { AddressStateCell } from './cells/addressStateCell';
import { EditCellValidationProps } from './contactsGrid';

export const addressColumns = [
  { name: 'address1', title: 'Address 1' },
  { name: 'address2', title: 'Address 2' },
  { name: 'city', title: 'City' },
  { name: 'state', title: 'State' },
  { name: 'zip', title: 'Zip' },
  { name: 'isPrimary', title: 'Primary' },
];

export const addressColumnExtension = [
  { columnName: 'address1', width: '20%' },
  { columnName: 'address2', width: '20%' },
  { columnName: 'city', width: '15%' },
  { columnName: 'state', width: '10%' },
  { columnName: 'zip', width: '10%' },
  { columnName: 'isPrimary', width: '60px' },
];

export const EditAddress = (
  props: EditCellValidationProps
): ReactElement<EditCellValidationProps> => {
  const { column } = props;
  switch (column.name) {
    case 'state':
      return <AddressStateCell {...props} />;
    case 'isPrimary':
      return <PrimaryCell {...props} />;
    default:
      return <EditingCell {...props} autoFocus={column.name === 'address1'} />;
  }
};

interface ILabelAddressProps extends ILabelCellProps {
  disabled?: boolean;
}

export const LabelAddress = ({ disabled, ...props }: ILabelAddressProps) => {
  if (props.column.name === 'isPrimary') {
    return <PrimaryCell {...props} disabled={disabled} />;
  }
  return <LabelCell {...props} />;
};
