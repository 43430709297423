import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { COLORS } from 'consts/styles';
import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled(Select)<SelectProps>(
  ({ fullWidth }: SelectProps) => ({
    width: fullWidth ? 'inherit' : '330px',
    height: '56px',
    '.MuiSelect-root': {
      paddingLeft: '17px',
    },
    paper: {
      borderRadius: '4px',
      border: '1px solid #CECECE',
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    },
  })
);

const StyledCareSiteWrapper = styled.div<{ $fullWidth?: boolean }>`
  border-radius: 4px;
  border: 1px solid #cecece;
  align-items: center;
  padding: 15px 0 15px 20px;
  ${({ $fullWidth }) => `
      margin-right: ${$fullWidth ? '0' : '10px'};
      width: ${$fullWidth ? 'inherit' : '308px'};
  `}
`;

export interface ICareSiteSelectorProps {
  careSites: { name: string; id: number }[];
  selectedCareSiteId: number;
  setSelectedCareSiteId: (value: number) => void;
  title: string;
  fullWidth?: boolean;
}

export const CareSiteSelector = ({
  careSites,
  selectedCareSiteId,
  setSelectedCareSiteId,
  title,
  fullWidth,
}: ICareSiteSelectorProps) => {
  if (careSites.length === 1) {
    return (
      <div style={{ marginBottom: '38px' }}>
        <Typography
          variant="body1"
          style={{ marginBottom: '8px', color: COLORS.GREY100 }}
        >
          {title}
        </Typography>
        <StyledCareSiteWrapper $fullWidth={fullWidth}>
          <Typography variant="body2" style={{ color: COLORS.GREY100 }}>
            {careSites[0]?.name}
          </Typography>
        </StyledCareSiteWrapper>
      </div>
    );
  }
  if (careSites.length > 1) {
    return (
      <Box>
        <Typography
          variant="body1"
          style={{ marginBottom: '8px', color: COLORS.GREY100 }}
        >
          {title}
        </Typography>
        <FormControl
          variant="outlined"
          style={{
            width: fullWidth ? '100%' : '330px',
            marginBottom: '38px',
          }}
        >
          <StyledSelect
            fullWidth={fullWidth}
            data-testid="role-selector"
            value={selectedCareSiteId}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              style: {
                left: '0',
                marginTop: '10px',
              },
            }}
          >
            {careSites.map((item) => {
              const result = [
                <MenuItem
                  value={item.id}
                  key={item.id}
                  onClick={() => setSelectedCareSiteId(item.id)}
                >
                  {item.name}
                </MenuItem>,
              ];
              if (item.id < 1) {
                result.push(<Divider key={`${item.id}-divider`} />);
              }
              return result;
            })}
          </StyledSelect>
        </FormControl>
      </Box>
    );
  }

  return null;
};
