import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import { UmRnDashboardCount } from 'graphql/graphqlTypes';
import { useGetUmRnDashboardCountQuery } from 'graphql/hooks/getUmRnDashboardCount';
import { DashboardCareSiteRole } from 'consts/roles';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import TooltipProvider from 'components/home/grid/providers/TooltipProvider';
import Loader from 'components/loader';
import DashValueProvider from './DashValueProvider';
import {
  columnsExtension,
  gridColumns,
  totalSummaryItems,
  dashBoardGridColumnNames,
} from './UmRnDashBoardGrid.helper';
import UmRnDashboardGridHeader from './UmRnDashboardGridHeader';
import {
  BaseDashboardProps,
  dashBoardAuthorization,
  dashBoardType,
} from './DashBoardGridHelper';
import CountLinkProvider from '../providers/CountLinkProvider';
import TotalCountProvider from '../providers/TotalCountProvider';

const UmRnDashboardGrid = ({
  userPreferences,
  setUserPreferences,
  refreshDashboard,
}: BaseDashboardProps) => {
  const [careSiteId, setCareSiteId] = useState<string>(
    userPreferences.umRnDashboardFilter ?? '0'
  );
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  ) as number;
  const isDashboardCareSiteRole = currentRoleId == DashboardCareSiteRole.roleId;
  const { data, isFetching, refetch } = useGetUmRnDashboardCountQuery({
    careSiteId: isDashboardCareSiteRole
      ? Number(careSiteId)
      : currentCareSiteId,
  });
  const dashboardData = (data?.getUmRnDashboardCount ??
    []) as UmRnDashboardCount[];
  const showTotalSummary = dashboardData.length > 1;

  useEffect(() => {
    if ((refreshDashboard ?? 0) > 0) {
      refetch();
    }
  }, [refetch, refreshDashboard]);

  const handleChange = (careSiteId: string) => {
    setCareSiteId(careSiteId);
    setUserPreferences({
      ...userPreferences,
      umRnDashboardFilter: careSiteId,
    });
  };

  const gridColumnNames = gridColumns
    .filter((column) => {
      return column.name !== dashBoardGridColumnNames.nurseOwner;
    })
    .map((i) => i.name);

  if (isFetching) {
    return <Loader active={true} />;
  }
  return (
    <>
      <UmRnDashboardGridHeader
        isDashboardCareSiteRole={isDashboardCareSiteRole}
        careSiteId={careSiteId}
        onChange={handleChange}
      />
      <Grid
        rows={dashboardData}
        columns={gridColumns}
        getRowId={(row) => row.userId}
      >
        {showTotalSummary && <SummaryState totalItems={totalSummaryItems} />}
        <TooltipProvider for={[dashBoardGridColumnNames.nurseOwner]} />
        <DashValueProvider for={gridColumnNames} />
        {!isDashboardCareSiteRole && (
          <CountLinkProvider
            for={gridColumnNames}
            priority={null}
            defaultAuthorization={dashBoardAuthorization.all}
            dashBoardType={dashBoardType.uMRnDashboard}
          />
        )}
        <SymphonyTable columnExtensions={columnsExtension} />
        <SymphonyTableHeaderRow />
        {showTotalSummary && <IntegratedSummary />}
        {showTotalSummary && (
          <TotalCountProvider
            for={gridColumnNames}
            priority={null}
            defaultAuthorization={dashBoardAuthorization.all}
            dashBoardType={dashBoardType.uMRnDashboard}
            searchLinkEnabled={!isDashboardCareSiteRole}
          />
        )}
      </Grid>
    </>
  );
};

export default UmRnDashboardGrid;
