import React, { useState } from 'react';
import iconSet from './selection.json';
import IcomoonReact from 'icomoon-react';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { Box } from '@mui/material';

export enum ICONS {
  PANonCovered = 'PANonCovered',
  Phone = 'Phone',
  Medication = 'Medication',
  Letter = 'Letter',
  Date = 'Date',
  Suggested = 'Suggested',
  Satisfied = 'Satisfied',
  Needed = 'Needed',
  On_Schedule = 'On-Schedule',
  On_Schedule_Large = 'On-Schedule-Large',
  Neutral = 'Neutral',
  Neutral_Large = 'Neutral-Large',
  Low = 'Low',
  Low_Large = 'Low-Large',
  High = 'High',
  High_Large = 'High-Large',
  Critical = 'Critical',
  Critical_Large = 'Critical-Large',
  Outline = 'Outline',
  Filled = 'Filled',
  Descending = 'Descending',
  Ascending = 'Ascending',
  Selected = 'Selected',
  Empty = 'Empty',
  Print_Shop = 'Print_Shop_New',
  Care_Plan = 'Care-Plan',
  Up = 'Up',
  Right = 'Right',
  Down = 'Down',
  Warning = 'Warning',
  Update = 'Update',
  Start_New_Encounter = 'Start-New-Encounter',
  Settings = 'Settings',
  Search = 'Search',
  Removed = 'Removed',
  Quick_Tip = 'Quick-Tip',
  Remove = 'Remove',
  Quick_Note = 'Quick-Note',
  Print = 'Print',
  Preview = 'Preview',
  Patient_Details = 'Patient-Details',
  New_Scenario = 'New-Scenario',
  Move = 'Move',
  Eye = 'eye',
  Marked_for_Deletion = 'Marked-for-Deletion',
  Lock = 'Lock',
  Locked = 'Locked',
  Letters = 'Letters',
  Last = 'Last',
  Info = 'Info',
  Home = 'Home',
  Expand = 'Expand',
  First = 'First',
  Edit_Selected = 'Edit-Selected',
  Dropdown = 'Dropdown',
  Date_Pick = 'Date-Pick',
  Condense = 'Condense',
  Back = 'Back',
  Adds_to_Problem_List = 'Adds-to-Problem-List',
  Additional_Fields = 'Additional-Fields',
  Amend = 'Amend',
  Attachment = 'Attachment',
  AttachmentClip = 'AttachmentClip',
  Add_Document = 'Add-Document',
  Add_Details = 'Add-Details',
  Close = 'Close',
  Progress_inactive = 'Progress_inactive',
  Progress_active = 'Progress_active',
  Left = 'Left',
  Bell = 'Bell',
  PhoneCall = 'Phone-call',
  Clock = 'Clock',
  AddComment = 'Add_comment',
  Document = 'Document',
  UM = 'UM',
  CM = 'CM',
  ArrowDropDown = 'Arrow_drop_down',
  Stoplight_completed = 'Stoplight_completed',
  Stoplight_incompleted = 'Stoplight_incompleted',
  warning_filled = 'warning_filled',
  Refresh = 'Refresh',
  Active = 'Active',
  Checkmark = 'Checkmark',
  ClosedReminder = 'ClosedReminder',
  Fax = 'Fax_New',
  TimelineLetter = 'TimelineLetter',
  Educate = 'Educate',
  Symphony_Logo = 'Symphony_Logo',
  Add = 'Add',
  Member_Note = 'Member-Note',
  Episode_Note = 'Episode-Note',
  Condition_Arrow = 'condition-arrow',
  Copy = 'copy',
  Down_Bold = 'DownBold',
  PreviewFile = 'PreviewFile',
  StarEmpty = 'star-empty',
  StarFull = 'star-full',
  Checkbox_selected = 'Checkbox-selected',
  Checkbox_deselected = 'Checkbox-deselected',
  ClockFilled = 'clockFilled',
  InProgress = 'InProgress',
  ProgressCompleted = 'ProgressCompleted',
  Expired = 'Expired',
  LinkFields = 'LinkFields',
  Edit = 'Edit',
  Risk = 'Risk',
  Lightbulb = 'lightbulb',
  Information = 'Information',
  PDF = 'Icon_Adobe',
  PPT = 'Icon_PowerPoint',
  TXT = 'Icon_Txt',
  XLS = 'Icon_Excel',
  Archive = 'Icon_Archive',
  DOC = 'Icon_Word',
  HTML = 'Icon_HTML',
  Img = 'Icon_IMG',
  Office = 'Icon_OpenOffice',
  FileType_Default = 'FileType_Default',
  EditAlt = 'Edit_Alt',
  FaxOutline = 'Fax_Outline',
  TagCalendar = 'Tag_Calendar',
  TagCalendar30 = 'Tag_Calendar30',
  TagCalendar60 = 'Tag_Calendar60',
  TagCalendar90 = 'Tag_Calendar90',
  TagDoNotSendNewsletter = 'Tag_DoNotSendNewsletter',
  TagDoNotSendEducation = 'Tag_DoNotSendEducation',
  TagDoNotSendHRA = 'Tag_DoNotSendHRA',
  TagDoNotCall = 'Tag_DoNotCall',
  TagOwner = 'Tag_Owner',
  TagDuplicatesActive = 'Tag_DuplicatesActive',
  TagDuplicatesInactive = 'Tag_DuplicatesInactive',
  TagSharedAddress = 'Tag_SharedAddress',
  TagNeedsTransportationAssist = 'Tag_NeedsTransportationAssist',
  TagSocialIsolation = 'Tag_SocialIsolation',
  ReminderMedicalFile = 'Reminder_MedicalFile',
  ReminderCalendar = 'Reminder_Calendar',
  ReminderPhoneCall = 'Reminder_PhoneCall',
  ReminderBloodPressure = 'Reminder_BloodPressure',
  ReminderPhoneCallP2P = 'Reminder_PhoneCallP2P',
  ReminderEnvelope = 'Reminder_Envelope',
  ReminderPills = 'Reminder_Pills',
  ReminderFax = 'Reminder_Fax',
  ActivePatient = 'Active_Patient',
  Bin = 'Bin',
  Forward = 'Forward',
  ZoomIn = 'Zoom_In',
  ZoomOut = 'Zoom_Out',
  FileDownload = 'File_Download',
  Rotate90Degrees = 'Rotate_90_Degrees',
  CarePlan = 'CarePlan',
  KeyboardArrowDown = 'keyboard_arrow_down', // This is slightly different from down (https://jiradc.prod.advantasure.com/browse/SYM-16708)
  KeyboardArrowUp = 'keyboard_arrow_up', // This is slightly different from up (https://jiradc.prod.advantasure.com/browse/SYM-16708)
  PauseAction = 'Pause_Action',
  Exclamation_Point = 'Exclamation_Point',
  Denied = 'Denied',
  MoreVert = 'more_vert',
  Stop = 'stop',
  DropdownUp = 'dropdown_up',
  DropdownDown = 'dropdown_down',
  Upload = 'Upload',
  FilterActive = 'filter_active',
  ExportNew = 'Export_New',
  Partially_Selected = 'Partially_Selected',
  Arrow = 'Arrow',
  Chat = 'Chat',
  Kidney = 'Kidney',
  Heart = 'Heart',
  Volume = 'Volume',
  No_Clipboard = 'No_Clipboard',
  No_Gear = 'No_Gear',
  No_Folder = 'No_Folder',
  AssignUser = 'Assign_User',
}

export interface IIconProps {
  color?: string;
  size: string | number;
  icon: ICONS;
  className?: string;
  hoverColor?: string;
  inline?: boolean;
  style?: React.CSSProperties;
  testId?: string;
  height?: string;
  onClick?: () => void;
  title?: string;
  tooltipContainerElement?: 'div' | 'p' | 'span';
}

const Icon = (props: IIconProps) => {
  const {
    color,
    size = '100%',
    icon,
    className = '',
    hoverColor,
    style,
    testId,
    height,
    onClick,
    title,
    tooltipContainerElement,
  } = props;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const boxStyle: { display?: string } = {};
  if (props.inline) {
    boxStyle.display = 'inline-block';
  }
  return (
    <CustomTooltip
      title={title ?? ''}
      containerElement={tooltipContainerElement}
      isHovered={isHovered}
    >
      <Box
        component="div"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={boxStyle}
      >
        <IcomoonReact
          className={className}
          iconSet={iconSet}
          color={hoverColor && isHovered ? hoverColor : color}
          size={size}
          icon={icon}
          style={{ ...style, ...{ fill: color } }}
          data-testid={testId}
          name={icon}
          height={height || size}
          onClick={onClick}
        />
      </Box>
    </CustomTooltip>
  );
};

export default Icon;
