import React from 'react';
import { Typography } from '@mui/material';
import {
  Maybe,
  PriorAuthCodesActionModel,
  PriorAuthInsuranceStatus,
} from 'graphql/graphqlTypes';
import { getInsuranceStatus } from 'components/actions/sections/SectionBody/Items/PriorAuthCodes/PriorAuthRequestedDates.helpers';
import {
  StatusText,
  InputGroupBox,
  RequestedBox,
} from 'components/actions/sections/SectionBody/Items/PriorAuthCodes/StyledComponents';
import { StatusSymbol } from 'components/actions/sections/SectionBody/Items/PriorAuthCodes/PriorAuthRequestedDates';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export interface IPriorAuthRequestedDatesReview {
  state: Maybe<PriorAuthCodesActionModel> | undefined;
  readOnly: boolean;
}

const PriorAuthRequestedDatesReview = (
  props: IPriorAuthRequestedDatesReview
) => {
  const { state } = props;

  const startDate = formatDate(state?.reqStartDate);
  const endDate = formatDate(state?.reqEndDate);
  const insuranceStatus = getInsuranceStatus(state);
  return (
    <>
      <RequestedBox>
        <Typography variant="body2">Requested</Typography>
        <InputGroupBox>
          <Typography variant="body1">Start Date&nbsp;</Typography>
          <Typography variant="body2">{startDate}</Typography>
        </InputGroupBox>
        <InputGroupBox>
          <Typography variant="body1">End Date&nbsp;</Typography>
          <Typography variant="body2">{endDate}</Typography>
        </InputGroupBox>
        <InputGroupBox>
          <Typography variant="body1">Eligibility:</Typography>
          <StatusSymbol
            value={
              insuranceStatus == null
                ? PriorAuthInsuranceStatus.Inactive
                : insuranceStatus
            }
          />
          <StatusText>
            <Typography>{insuranceStatus}</Typography>
          </StatusText>
        </InputGroupBox>
      </RequestedBox>
    </>
  );
};
export default PriorAuthRequestedDatesReview;
