import { FoundProviderStatus } from 'graphql/graphqlTypes';

export interface IAdditionalFilters {
  firstName: string;
  lastName: string;
  providerId: string;
  nPI: string;
  address: string;
}

export interface IRecentProvider {
  added: Date;
  id: number;
}

export interface IProvider {
  id: number;
  providerId?: string;
  providerAddressUiId: string;
  nPI?: string;
  address?: string;
  firstName?: string;
  lastName?: string;
  isFavorite: boolean;
  isRecent: boolean;
  par: FoundProviderStatus;
}

export interface IIndexable {
  [key: string]: string;
}

export enum DataSource {
  All = 'All',
  Favorites = 'Favorites',
  Recent = 'Recent',
}
