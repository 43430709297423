import React, { useCallback, useEffect, useState } from 'react';
import { IEditorProps } from 'backend/types/grid/IEditorProps';
import { StyledOutlinedInput } from './styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';

const IntFilter = (props: IEditorProps) => {
  const [inputValue, setInputValue] = useState('');
  const reduxValue = useSelector(
    (state: IState) => state.home.homeModel.filterBy
  )?.find((filter) => filter.columnName === props.column.name)?.value;

  useEffect(() => {
    setInputValue(reduxValue ?? '');
  }, [reduxValue]);

  const handleValueChange = useCallback(
    (value: string) => {
      setInputValue(value);
      props.onChange(value);
    },
    [props]
  );

  return (
    <StyledOutlinedInput
      id={props.column?.name}
      type="number"
      name={props.column?.name}
      placeholder={props.column?.title}
      value={inputValue}
      data-testid="number-filter"
      onChange={(event) => {
        handleValueChange(event.target.value);
      }}
    />
  );
};

export default IntFilter;
