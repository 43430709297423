import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type ReopenEpisodeMutationVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type ReopenEpisodeMutation = {
  __typename?: 'Mutation';
  reopenEpisode?: boolean | null;
};

export const ReopenEpisodeDocument = `
    mutation reopenEpisode($episodeId: Int!) {
  reopenEpisode(episodeId: $episodeId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    reopenEpisode: build.mutation<
      ReopenEpisodeMutation,
      ReopenEpisodeMutationVariables
    >({
      query: (variables) => ({ document: ReopenEpisodeDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useReopenEpisodeMutation } = injectedRtkApi;
