import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import { FixedText, IFixedTextProps } from '.';
import { usePrevious } from 'hooks';
import { ValidationWrapper } from 'components/validation/ValidationWrapper';
import { hasValue, validateRequired } from 'util/validationUtils';
import { setChecklistComponentValidation } from 'store/actions/checklistSlice';
import { Asterisk } from 'components/text';

export interface IExpressionProps extends IFixedTextProps {
  storageType: ChecklistStorageType;
  uniqueID: string;
  expressionString: string;
  userDefinedId: string;
  required: boolean;
  categoryId: string;
  isActionSelected: boolean;
  onChange: (value: string) => void;
}

export const Expression = ({
  onChange,
  value,
  storageType,
  uniqueID,
  required,
  userDefinedId,
  categoryId,
  isActionSelected,
  ...rest
}: IExpressionProps) => {
  const dispatch = useDispatch();

  const calculatedValue = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklistComponents?.[
        uniqueID
      ]?.component?.value
  );

  const error = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].checklistErrors[uniqueID]
  );

  const previous = usePrevious(calculatedValue);
  useEffect(
    () => {
      if (
        hasValue(calculatedValue) &&
        calculatedValue.length > 0 &&
        calculatedValue !== previous
      ) {
        onChange(calculatedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, calculatedValue]
  );

  const shownValue = calculatedValue ?? value;
  const validate = () => {
    const result = validateRequired(shownValue, required);
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: uniqueID,
          error: result.message ?? '',
          fieldName: userDefinedId ?? 'Expression',
          categoryId,
          isValid: !result.message,
        },
      })
    );

    return result.hasError;
  };

  useEffect(
    () => {
      if (isActionSelected) {
        validate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActionSelected, shownValue, required]
  );

  return (
    <>
      {error?.error && <Asterisk />}
      <ValidationWrapper validation={validate}>
        <FixedText {...rest} {...error} value={shownValue} />
      </ValidationWrapper>
    </>
  );
};
