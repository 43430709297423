import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ActionLink from 'components/actionlink';
import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import styled from 'styled-components';

const isPdf = (fileExtension: string) =>
  fileExtension === 'pdf' || fileExtension === 'Pdf document';

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: auto;
`;

export const AttachmentActionsCell = (
  props: ILabelCellProps & {
    episodeId?: number;
    isAttachmentsTab?: boolean;
    attachmentType?: string | null;
    isActionsDisabled: boolean;
  }
) => {
  const { row, episodeId, isAttachmentsTab, isActionsDisabled } = props;
  const attachmentType = row.attachmentType?.name;
  const fileExtension = row.fileExtension;
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const isNewFile = row.id < 0;
  const canDeleteAttachment = hasUserPrivilege(Privilege.DeleteAttachment);
  const canMoveAttachment = hasUserPrivilege(Privilege.AllowMoveAttachment);
  const isFaxAllowed = hasUserPrivilege(Privilege.AllowFax);

  const isAttachmentMoveAllowed =
    canMoveAttachment &&
    isAttachmentsTab &&
    attachmentType !== 'Education' &&
    row.id > 0;

  return (
    <Table.Cell {...props} style={CellComponentStyle}>
      <StyledWrapper>
        {!isNewFile && (
          <ActionLink
            key={`AttachmentView${row.id}`}
            type="AttachmentView"
            entityId={Number(row.id)}
            fileName={row.name}
            label="View/Print"
            isActionsDisabled={isActionsDisabled}
          />
        )}
        {!isNewFile && isPdf(fileExtension) && isFaxAllowed && (
          <ActionLink
            key={`AttachmentFax${row.id}`}
            type="AttachmentFax"
            entityId={Number(row.id)}
            label={row.description === 'Fax Failed' ? 'Retry Fax' : 'Fax'}
            isActionsDisabled={isActionsDisabled}
          />
        )}
        {canDeleteAttachment && (
          <ActionLink
            key={`AttachmentDelete${row.id}`}
            type="AttachmentDelete"
            entityId={Number(row.id)}
            episodeId={Number(episodeId)}
            patientId={Number(patientId)}
            isActionsDisabled={isActionsDisabled}
            label="Delete"
          />
        )}
        {isAttachmentMoveAllowed ? (
          <ActionLink
            key={`AttachmentMove${row.id}`}
            type="AttachmentMove"
            entityId={Number(row.id)}
            episodeId={Number(episodeId)}
            patientId={Number(patientId)}
            isActionsDisabled={isActionsDisabled}
            label="Move"
          />
        ) : null}
      </StyledWrapper>
    </Table.Cell>
  );
};
