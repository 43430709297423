import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeAttachmentsQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeAttachmentsQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeAttachments: Array<{
    __typename?: 'Attachment';
    id: number;
    name?: string | null;
    createdOn?: any | null;
    description?: string | null;
    isDeleted: boolean;
    isVisible: boolean;
    fileExtension: string;
    createdByUser?: { __typename?: 'User'; fullName?: string | null } | null;
    attachmentCategory?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
    attachmentType?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
  }>;
};

export const GetEpisodeAttachmentsDocument = `
    query getEpisodeAttachments($episodeId: Int!) {
  getEpisodeAttachments(episodeId: $episodeId) {
    id
    name
    createdOn
    description
    isDeleted
    isVisible
    createdByUser {
      fullName
    }
    fileExtension
    attachmentCategory {
      id
      name
    }
    attachmentType {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeAttachments: build.query<
      GetEpisodeAttachmentsQuery,
      GetEpisodeAttachmentsQueryVariables
    >({
      query: (variables) => ({
        document: GetEpisodeAttachmentsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEpisodeAttachmentsQuery,
  useLazyGetEpisodeAttachmentsQuery,
} = injectedRtkApi;
