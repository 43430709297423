import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import { AppBar, Box, Toolbar } from '@mui/material';
import {
  AccountMenu,
  MemberSearchBar,
  ProgressBar,
  RoleMenu,
} from './components';
import { IState } from 'store';
import { useLocation } from 'react-router-dom';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { ROUTE } from 'consts/ROUTE';
import { Row } from 'components/layout';
import { Ellipsis } from 'components/text';
import styled from 'styled-components';
import ChangeProviderMenu from './components/ChangeProviderMenu';
import { CareSiteName } from './components/CareSiteName';
import { RoleName } from './components/RoleName';
import { ChecklistStorageType } from 'store/actions/types';
import { setShowLeavingChecklistConfirmation } from 'store/ui/modals/checklist';
import { useNavigate } from 'react-router';
import { useHeaderHelpers } from 'components/header/components/Header.hooks';
import { DashboardCareSiteRole } from 'consts/roles';
import { useDirtyConfirmation } from 'hooks/useDirtyConfirmationModal';
import { DirtyConfirmationModal } from 'components/modal/DirtyConfirmationModal';

const WorkflowStep = styled.h2`
  display: inline;
  color: ${COLORS.WHITE};
  line-height: 26px;
  font-size: 20px;
  letter-spacing: 0.15px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  padding-left: 16px;
`;

const WorkflowRole = styled.h6`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.BLUE20};
  margin: 0;
  padding-left: 16px;
`;

const StyledDivider = styled(Divider)<{
  $margin: string;
}>`
  height: 30px;
  margin: ${(props) => props.$margin};
  background-color: ${COLORS.WHITE};
  opacity: 0.3;
`;

const StyledAppBar = styled(AppBar)<{ $bgColor: COLORS | null }>`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.0524476);
  height: 64px;
  background-color: ${(props) => props.$bgColor};
`;

const StyledToolBar = styled(Toolbar)`
  padding-right: 60px;
  padding-left: 60px;
`;

const StyledLink = styled.div<{ $isDisabled: boolean }>`
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'initial')};
  &:hover {
    cursor: ${(props) => (props.$isDisabled ? 'none' : 'pointer')};
  }
`;

function getEnvironmentTitle(environmentName: string | undefined) {
  switch (environmentName) {
    case 'Stage':
      return 'STAGE';
    case 'Test':
      return 'QA';
    case 'Develop':
      return 'DEV';
    case 'Demo':
      return 'DEMO';
    default:
      return null;
  }
}

const Header = () => {
  const environmentName = useSelector(
    (state: IState) => state.environment.name
  );

  const showDarkHeader = environmentName !== 'Production';
  const envTitle = getEnvironmentTitle(environmentName);
  const currentStep = useSelector((state: IState) => state.workflow.name);
  const workflowStep = useSelector((state: IState) => state.workflow.step);
  const loggedInAsProvider = useSelector(
    (state: IState) => state.user.currentUser.loggedInAsProvider
  );
  const isAmendingChecklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST]
        .isAmendingChecklist
  );
  const isReadOnlyWorkflow = useSelector(
    (state: IState) => state.mozart.isReadOnlyWorkflow
  );

  const { isMozartFlow, showExitChecklistPopup } = useHeaderHelpers();
  const isFirstStep = workflowStep === 0;

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.userRoleId
  );

  const {
    handleDirtyConfirm,
    clearDirtyState,
    handleConfirm,
    handleCancel,
    showModal,
  } = useDirtyConfirmation();
  const isDirty = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary.isDirty
  );
  const sectionName = 'Episode Summary';

  const hideControls =
    location.pathname === ROUTE.Preferences ||
    location.pathname === ROUTE.InterQual;

  const renderActionSection = () => {
    if (currentRoleId !== DashboardCareSiteRole.roleId) {
      return isFirstStep ? <MemberSearchBar /> : <ProgressBar />;
    } else {
      return <></>;
    }
  };

  const dividerMargin = isFirstStep ? '0 20px 0 20px' : '0 20px 0 1px';

  const firstStepMenu = loggedInAsProvider ? (
    <ChangeProviderMenu />
  ) : (
    <RoleMenu />
  );

  const handleLogoClick = () => {
    handleDirtyConfirm(isDirty, onConfirmLogoClick);
  };

  const onConfirmLogoClick = () => {
    clearDirtyState();
    const actionsStep = 2;
    const reviewStep = 3;
    if (
      (workflowStep === actionsStep && showExitChecklistPopup) ||
      (workflowStep === reviewStep && !isMozartFlow)
    ) {
      dispatch(
        setShowLeavingChecklistConfirmation({
          exitModalOpen: true,
          action: 'Discard',
          route: ROUTE.Home,
        })
      );
    } else {
      navigate(ROUTE.Home);
    }
  };

  return (
    <StyledAppBar
      position="sticky"
      $bgColor={showDarkHeader ? COLORS.SYMPHONY_STAGE_BLUE : null}
    >
      <StyledToolBar>
        <Row>
          <StyledLink
            $isDisabled={isAmendingChecklist || isReadOnlyWorkflow}
            onClick={handleLogoClick}
          >
            <Icon
              size={122}
              color={COLORS.WHITE}
              icon={ICONS.Symphony_Logo}
              height="80px"
            />
          </StyledLink>
          <DirtyConfirmationModal
            showModal={showModal}
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            sectionName={sectionName}
          />
        </Row>
        {envTitle && (
          <Box
            fontSize={12}
            marginLeft={'20px'}
            bgcolor={COLORS.VERY_DARK_BLUE}
            padding={'6px 8px 5px'}
            borderRadius="4px"
          >
            {envTitle}
          </Box>
        )}
        <StyledDivider $margin="0 0 0 20px" orientation="vertical" />
        {isFirstStep ? (
          firstStepMenu
        ) : (
          <div>
            <WorkflowRole>
              <CareSiteName /> • <RoleName />
            </WorkflowRole>
            <Ellipsis>
              <WorkflowStep>{currentStep}</WorkflowStep>
            </Ellipsis>
          </div>
        )}
        {!isReadOnlyWorkflow && (
          <Box display="flex" marginLeft="auto" alignItems="center">
            {hideControls ? null : (
              <>
                {renderActionSection()}
                <StyledDivider $margin={dividerMargin} orientation="vertical" />
              </>
            )}
            <AccountMenu dark />
          </Box>
        )}
      </StyledToolBar>
    </StyledAppBar>
  );
};

export default Header;
