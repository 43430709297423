import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import MemberNotesGrid from './MemberNotesGrid';
import QuickNoteDialog from 'components/quickNoteDialog/QuickNoteDialog';
import { QuickNoteType } from 'graphql/graphqlTypes';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';

const MemberNotesTab = () => {
  const [quickDialogOpen, setQuickDialogOpen] = useState<boolean>(false);
  const [refetchNotes, setRefetchNotes] = useState<boolean>(false);
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  useEffect(() => {
    refetchNotes && setRefetchNotes(false);
  }, [refetchNotes]);

  const canAddMemberEpisodeNotes = hasUserPrivilege(
    Privilege.AddMemberEpisodeNotes
  );
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="16px"
      >
        <Typography variant="subtitle1" style={{ paddingBottom: '16px' }}>
          Member notes
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={!canAddMemberEpisodeNotes}
          startIcon={<Icon icon={ICONS.Add} size={14} color={COLORS.WHITE} />}
          onClick={() => setQuickDialogOpen(true)}
        >
          Add
        </Button>
      </Box>
      <Box>
        <MemberNotesGrid shouldRefetch={refetchNotes} />
      </Box>
      <QuickNoteDialog
        open={quickDialogOpen}
        patientId={patientId}
        episodeId={0}
        type={QuickNoteType.Patient}
        shouldRefetchNotes={() => setRefetchNotes(true)}
        onClose={() => setQuickDialogOpen(false)}
      />
    </Box>
  );
};

export default MemberNotesTab;
