import {
  ChecklistStorageType,
  IAllDocumentsState,
  IChecklistComponent,
} from './types';
import { maxBy, minBy, orderBy } from 'lodash';
import { BaseComponent, ExpressionComponent } from 'graphql/graphqlTypes';
import { MOMENT_DATE_FORMAT } from 'components/constants';
import { hoursInADay, millsInADay, saturday, sunday } from './consts';
import moment, { Moment } from 'moment';
import { evaluateExpression } from 'components/expression';
import { hasValue } from 'util/validationUtils';

// why this code is needed:
// try executing this
// const date1 = new Date('2022-05-15');
// const date2 = new Date('05/15/2022');
// date2.getTime() - date1.getTime(); // result: -10800000 (might be different depending on your TZ), in this case is -3h
// we store dates in multiple formats and the browser treats those formats differently, therefore we have to parse dates ourselves.

//yyyy-mm-dd
const standardDateRegex = /(\d{4})-(\d{2})-(\d{2})/g;
//mm-dd-yyyy
const formattedDateRegex = /(\d{2})\/(\d{2})\/(\d{4})/g;

export type PrimitiveValue = number | Moment | null;

export const getDateParts = (dateString: string) => {
  const standardMatch = dateString.match(standardDateRegex);
  if (standardMatch) {
    const [year, month, day] = standardMatch[0].split('-');
    return {
      year: Number(year),
      // date strings display as 05 for the 5th month, but the months are counted from 0
      month: Number(month) - 1,
      day: Number(day),
    };
  }

  const formattedMatch = dateString.match(formattedDateRegex);
  if (formattedMatch) {
    const [month, day, year] = formattedMatch[0].split('/');
    return {
      year: Number(year),
      // date strings display as 05 for the 5th month, but the months are counted from 0
      month: Number(month) - 1,
      day: Number(day),
    };
  }

  return null;
};

export const mapComponentValueToTypedValue = (
  value: string | null
): PrimitiveValue => {
  if (value === null || value.length === 0) {
    return null;
  }

  const numberValue = Number(value);
  if (!Number.isNaN(numberValue)) {
    return numberValue;
  }

  const parts = getDateParts(value);
  if (parts === null) {
    return null;
  }

  return moment(parts);
};

export const extractVariable = (
  previous: IChecklistComponent[],
  previousWithCurrent: IChecklistComponent[],
  all: IChecklistComponent[],
  method: ExtractionMethod,
  variable: string
): PrimitiveValue => {
  const extractValue = getExtractionFunction(method);
  let components = all;
  if (method === 'CURRENT') {
    components = previousWithCurrent;
  } else if (method === 'PREVIOUS') {
    components = previous;
  }

  const eligibleComponents = components.filter(
    (c) => c.component.userDefinedId === variable
  );
  const component = extractValue(eligibleComponents, (x) =>
    mapComponentValueToTypedValue(x.component.value ?? null)
  );
  return mapComponentValueToTypedValue(component?.component.value ?? null);
};

export type ExtractionMethod =
  | 'MIN'
  | 'MAX'
  | 'FIRST'
  | 'LAST'
  | 'PREVIOUS'
  | 'CURRENT';

const getExtractionFunction = (method: ExtractionMethod) =>
  ({
    MIN: minBy,
    MAX: maxBy,
    FIRST: <T>(items: T[]) => items[0],
    LAST: <T>(items: T[]) => items[items.length - 1],
    PREVIOUS: <T>(items: T[]) => items[items.length - 1],
    CURRENT: <T>(items: T[]) => items[items.length - 1],
  }[method]);

const usableInExpressions = (
  component: IChecklistComponent,
  selected: { [key: string]: boolean }
) => {
  return (
    component?.isVisible &&
    selected[component.uuid] &&
    (!!component.component.userDefinedId ||
      component.component.componentType === 'ExpressionComponent')
  );
};

export const getEligibleComponents = (
  state: IAllDocumentsState,
  type: ChecklistStorageType
) => {
  if (!state.documentsState[type].checklistComponents) {
    return [];
  }

  const checklist = state.documentsState[type];
  const eligibleComponents =
    Object.values(checklist.checklistComponents).filter((c) =>
      usableInExpressions(c, checklist.selectedActions)
    ) ?? [];

  return orderBy(
    eligibleComponents,
    [
      (component) => component.sectionId,
      (component) => component.itemIndex,
      (component) => component.lineIndex,
      (component) => component.componentIndex,
    ],
    ['asc', 'asc', 'asc', 'asc']
  );
};

// Expression execution
class ComponentValue {
  private currentValue: PrimitiveValue | undefined;

  constructor(
    public name: string,
    private readonly previousComponents: IChecklistComponent[],
    private readonly previousWithCurrentComponents: IChecklistComponent[],
    private readonly allComponents: IChecklistComponent[]
  ) {}

  get value() {
    if (this.currentValue === undefined) {
      this.currentValue = extractVariable(
        this.previousComponents,
        this.previousWithCurrentComponents,
        this.allComponents,
        'CURRENT',
        this.name
      );
    }
    return this.currentValue;
  }

  [Symbol.toPrimitive]() {
    return this.value;
  }

  valueOf() {
    return this.value;
  }
}

type ExpressionValue = PrimitiveValue | ComponentValue;

const getName = (value: ExpressionValue) => {
  if (value === null) {
    return '';
  }

  if (value instanceof ComponentValue) {
    return value.name;
  }

  return value.toString();
};

const getValue = <T extends PrimitiveValue | string>(
  value: ExpressionValue
): T | null => {
  if (value === null) {
    return null as T;
  }

  if (value instanceof ComponentValue) {
    return value.value as T;
  }

  return value as T;
};

const addDay = (
  date: ExpressionValue,
  days: ExpressionValue
): Moment | null => {
  const dateValue = getValue<Moment>(date);
  const daysValue = getValue<number>(days);
  if (!hasValue(dateValue) || !hasValue(daysValue)) {
    return null;
  }

  return dateValue.clone().add(daysValue, 'days');
};

const addWorkDay = (
  date: ExpressionValue,
  days: ExpressionValue
): Moment | null => {
  const dateValue = getValue<Moment>(date);
  const daysValue = getValue<number>(days);
  if (!hasValue(daysValue) || !hasValue(dateValue)) {
    return null;
  }

  const result = dateValue.clone();
  const day = dateValue.day();
  const weekends = Math.floor((daysValue - 1 + (day % 6 || 1)) / 5) * 2;
  let offset: number;
  if (day === saturday) {
    offset = 1;
  } else {
    offset = day === sunday ? 0 : +!day;
  }
  result.date(dateValue.date() + daysValue + offset + weekends);

  return result;
};

const dateDiff = (
  first: ExpressionValue,
  second: ExpressionValue,
  format: ExpressionValue
) => {
  const firstValue = getValue<Moment>(first);
  const secondValue = getValue<Moment>(second);
  const formatValue = getValue<string>(format);
  if (
    !hasValue(firstValue) ||
    !hasValue(secondValue) ||
    !hasValue(formatValue)
  ) {
    return null;
  }

  const diffInMills = secondValue.diff(firstValue);
  return {
    d: Math.trunc(diffInMills / millsInADay),
    h: Math.trunc(diffInMills / millsInADay) * hoursInADay,
  }[formatValue];
};

const convertExecutionResult = (result: unknown): string => {
  if (!hasValue(result)) {
    return '';
  }
  switch (typeof result) {
    case 'number':
      return result.toString();
    case 'object':
      return moment(result).format(MOMENT_DATE_FORMAT);
    case 'string':
      return result;
    default:
      throw new Error(`Invalid Expression: ${result}`);
  }
};

const executeExpression = (
  expression: string,
  previousComponents: IChecklistComponent[],
  previousWithCurrentComponents: IChecklistComponent[],
  allComponents: IChecklistComponent[],
  target: ExpressionComponent
) => {
  try {
    const extractAggregateValue = (
      expressionValue: ExpressionValue,
      method: ExtractionMethod
    ) => {
      const name = getName(expressionValue);
      return extractVariable(
        previousComponents,
        previousWithCurrentComponents,
        allComponents,
        method,
        name
      );
    };

    const variableCache = new Map<string, ComponentValue>();
    const resolveVariable = (name: string) => {
      if (variableCache.has(name)) {
        return {
          value: variableCache.get(name),
        };
      }
      const variableValue = new ComponentValue(
        name,
        previousComponents,
        previousWithCurrentComponents,
        allComponents
      );
      variableCache.set(name, variableValue);
      return { value: variableValue };
    };

    const functions = {
      MIN: (value: ExpressionValue) => extractAggregateValue(value, 'MIN'),
      MAX: (value: ExpressionValue) => extractAggregateValue(value, 'MAX'),
      FIRST: (value: ExpressionValue) => extractAggregateValue(value, 'FIRST'),
      LAST: (value: ExpressionValue) => extractAggregateValue(value, 'LAST'),
      PREVIOUS: (value: ExpressionValue) =>
        extractAggregateValue(value, 'PREVIOUS'),
      CURRENT: (value: ExpressionValue) => {
        if (value instanceof ComponentValue) {
          return value.value;
        }

        return extractAggregateValue(value, 'CURRENT');
      },
      ADDDAY: addDay,
      ADDWORKDAY: addWorkDay,
      DATEDIF: dateDiff,
    };

    const result = evaluateExpression(expression, {
      functions,
      resolveVariable,
    });

    const newValue = convertExecutionResult(result);
    if (newValue !== target.value) {
      target.value = newValue;
      return true;
    }

    return false;
  } catch (error) {
    target.value = 'Invalid Expression';
    throw new Error(`Invalid Expression: ${expression} Error: ${error}`);
  }
};

export const calculateExpressionValues = (
  components: IChecklistComponent[]
): Array<BaseComponent> => {
  const changedComponents = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i].component;
    if (component.componentType === 'ExpressionComponent') {
      // Items are already orders by SectionId, ItemId, LineId, ComponentId.
      let prevInd = i; // We're getting all components less than current itemId
      while (
        prevInd >= 0 &&
        components[prevInd].sectionId === components[i].sectionId &&
        components[prevInd].itemIndex === components[i].itemIndex
      ) {
        prevInd--;
      }
      let curInd = i + 1; // We're getting all components less or equal than current itemId
      while (
        curInd < components.length &&
        components[curInd].sectionId === components[i].sectionId &&
        components[curInd].itemIndex === components[i].itemIndex
      ) {
        curInd++;
      }
      const previousComponents = components.slice(0, prevInd + 1);
      const previousWithCurrentComponents = components.slice(0, curInd);
      const isModified = executeExpression(
        (component as ExpressionComponent).expressionString ?? '',
        previousComponents,
        previousWithCurrentComponents,
        components,
        component as ExpressionComponent
      );

      if (isModified) {
        changedComponents.push(component);
      }
    }
  }

  return changedComponents;
};

export const recalculateExpressions = (
  state: IAllDocumentsState,
  type: ChecklistStorageType
) => {
  const components = getEligibleComponents(state, type);
  return calculateExpressionValues(components);
};
