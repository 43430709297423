import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { close, shouldRefetch } from 'store/ui/modals/quickNote';
import QuickNoteDialog from 'components/quickNoteDialog/QuickNoteDialog';

const QuickNoteController = () => {
  const dispatch = useDispatch();
  const state = useSelector((store: IState) => store.ui.modals.quickNote);
  const onClose = () => dispatch(close());
  const onShouldRefetcNotes = (shouldRefetchNotes: boolean) =>
    dispatch(shouldRefetch({ shouldRefetchNotes }));

  return (
    <>
      {state.open && (
        <QuickNoteDialog
          {...state}
          onClose={onClose}
          shouldRefetchNotes={onShouldRefetcNotes}
        />
      )}
    </>
  );
};

export default QuickNoteController;
