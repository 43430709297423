import React from 'react';
import { DateTimeComponent } from 'graphql/graphqlTypes';
import { StyledReviewTypography } from '../styles';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import { DATETIME_FORMAT, DATETIME_SECONDS_FORMAT } from 'components/constants';

export interface IDateTimeProps {
  component: DateTimeComponent;
}

const DateTime = ({ component }: IDateTimeProps) => {
  const dateTimeFormat = component.useSeconds
    ? DATETIME_SECONDS_FORMAT
    : DATETIME_FORMAT;

  return (
    <StyledReviewTypography
      variant="body1"
      $color={component.color ?? undefined}
    >
      {component.value ? formatDateTime(component.value, dateTimeFormat) : ''}
    </StyledReviewTypography>
  );
};

export default DateTime;
