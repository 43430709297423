import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Tooltip, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { PaRequirement } from 'graphql/graphqlTypes';

const PriorAuthText = styled(Typography)<{
  $textColor: string;
}>(({ $textColor }) => ({
  color: $textColor,
  wordWrap: 'break-word',
  whiteSpace: 'normal',
}));

const PriorAuthTextFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => (
  <Tooltip
    title={
      props.row.priorAuthRequirement === PaRequirement.InternalRequired ||
      props.row.priorAuthRequirement === PaRequirement.InternalMaybeRequired
        ? props.value
        : "This code wont be submitted if you proceed, but we'll save the lookup information"
    }
  >
    {props.row.priorAuthRequirement === PaRequirement.InternalRequired ||
    props.row.priorAuthRequirement === PaRequirement.InternalMaybeRequired ? (
      <PriorAuthText data-testid="PABlackText" $textColor={COLORS.BLACK}>
        {props.value}
      </PriorAuthText>
    ) : (
      <PriorAuthText data-testid="PAGreyText" $textColor={COLORS.GREY80}>
        {props.value}
      </PriorAuthText>
    )}
  </Tooltip>
);

export default PriorAuthTextFormatter;
