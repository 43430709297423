import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { IconButton } from '@mui/material';

const DeleteByIdActionFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  return (
    <IconButton
      style={{ padding: '0px' }}
      onClick={() => props.value.onDelete(props.value.id)}
      size="large"
      data-testid="delete-button"
    >
      <Icon icon={ICONS.Remove} size={22} color={COLORS.RED100} />
    </IconButton>
  );
};

export default DeleteByIdActionFormatter;
