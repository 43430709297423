import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeBalancerQueueRecentCriteriaQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetEpisodeBalancerQueueRecentCriteriaQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeBalancerQueueRecentCriteria?: {
    __typename?: 'EpisodeBalancerCriteria';
    totalEpisodesOwned: number;
    users: Array<
      | { __typename?: 'CareSite'; id: number; name?: string | null }
      | { __typename?: 'EpisodeType'; id: number; name?: string | null }
      | { __typename?: 'Lookup'; id: number; name?: string | null }
      | { __typename?: 'LookupValue'; id: number; name?: string | null }
      | { __typename?: 'Pharmacy'; id: number; name?: string | null }
      | { __typename?: 'Problem'; id: number; name?: string | null }
      | { __typename?: 'Provider'; id: number; name?: string | null }
      | { __typename?: 'Role'; id: number; name?: string | null }
      | { __typename?: 'Scenario'; id: number; name?: string | null }
      | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
      | { __typename?: 'Tag'; id: number; name?: string | null }
      | { __typename?: 'User'; id: number; name?: string | null }
    >;
    careStages: Array<
      | { __typename?: 'CareSite'; id: number; name?: string | null }
      | { __typename?: 'EpisodeType'; id: number; name?: string | null }
      | { __typename?: 'Lookup'; id: number; name?: string | null }
      | { __typename?: 'LookupValue'; id: number; name?: string | null }
      | { __typename?: 'Pharmacy'; id: number; name?: string | null }
      | { __typename?: 'Problem'; id: number; name?: string | null }
      | { __typename?: 'Provider'; id: number; name?: string | null }
      | { __typename?: 'Role'; id: number; name?: string | null }
      | { __typename?: 'Scenario'; id: number; name?: string | null }
      | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
      | { __typename?: 'Tag'; id: number; name?: string | null }
      | { __typename?: 'User'; id: number; name?: string | null }
    >;
    pathways: Array<
      | { __typename?: 'CareSite'; id: number; name?: string | null }
      | { __typename?: 'EpisodeType'; id: number; name?: string | null }
      | { __typename?: 'Lookup'; id: number; name?: string | null }
      | { __typename?: 'LookupValue'; id: number; name?: string | null }
      | { __typename?: 'Pharmacy'; id: number; name?: string | null }
      | { __typename?: 'Problem'; id: number; name?: string | null }
      | { __typename?: 'Provider'; id: number; name?: string | null }
      | { __typename?: 'Role'; id: number; name?: string | null }
      | { __typename?: 'Scenario'; id: number; name?: string | null }
      | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
      | { __typename?: 'Tag'; id: number; name?: string | null }
      | { __typename?: 'User'; id: number; name?: string | null }
    >;
    serviceLocations: Array<
      | { __typename?: 'CareSite'; id: number; name?: string | null }
      | { __typename?: 'EpisodeType'; id: number; name?: string | null }
      | { __typename?: 'Lookup'; id: number; name?: string | null }
      | { __typename?: 'LookupValue'; id: number; name?: string | null }
      | { __typename?: 'Pharmacy'; id: number; name?: string | null }
      | { __typename?: 'Problem'; id: number; name?: string | null }
      | { __typename?: 'Provider'; id: number; name?: string | null }
      | { __typename?: 'Role'; id: number; name?: string | null }
      | { __typename?: 'Scenario'; id: number; name?: string | null }
      | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
      | { __typename?: 'Tag'; id: number; name?: string | null }
      | { __typename?: 'User'; id: number; name?: string | null }
    >;
  } | null;
};

export const GetEpisodeBalancerQueueRecentCriteriaDocument = `
    query getEpisodeBalancerQueueRecentCriteria {
  getEpisodeBalancerQueueRecentCriteria {
    users {
      id
      name
    }
    totalEpisodesOwned
    careStages {
      id
      name
    }
    pathways {
      id
      name
    }
    serviceLocations {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeBalancerQueueRecentCriteria: build.query<
      GetEpisodeBalancerQueueRecentCriteriaQuery,
      GetEpisodeBalancerQueueRecentCriteriaQueryVariables | void
    >({
      query: (variables) => ({
        document: GetEpisodeBalancerQueueRecentCriteriaDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEpisodeBalancerQueueRecentCriteriaQuery,
  useLazyGetEpisodeBalancerQueueRecentCriteriaQuery,
} = injectedRtkApi;
