import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ILoginUser, ILogoutUser } from 'store/user/types';
import { loginUser, logoutUser } from 'store/user/middlewares';
import { IState } from 'store';
import { useParams } from 'react-router-dom';
import { authStart } from 'store/user/action';
import { useNavigate } from 'react-router';

const Sso = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );
  const { token } = useParams<{ token: string }>();
  const serverError = useSelector((state: IState) => state.user.error);
  const navigate = useNavigate();

  const message = serverError
    ? serverError
    : 'Please wait while you are being logged in...';

  useEffect(() => {
    if (isLoggedIn) {
      const logoutContext: ILogoutUser = {
        sessionId: sessionId,
      };
      dispatch(logoutUser(logoutContext, navigate, false));
    }
  }, [dispatch, isLoggedIn, navigate, sessionId]);

  useEffect(() => {
    async function fn() {
      navigate('/login', { replace: true });
      dispatch(authStart());
      const user: ILoginUser = { login: '', password: '', ssoToken: token };
      dispatch(loginUser(user));
    }

    if (!isLoggedIn) {
      fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, isLoggedIn]);

  return <>{message}</>;
};

export default Sso;
