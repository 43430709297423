import React from 'react';
import { Box, Menu } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { ILookupValue } from 'backend/types/lookupValue';
import {
  StyledDivider,
  StyledMenuItem,
  StyledMenuTrigger,
  StyledTypography,
  getColor,
  getDropdownIcon,
} from '../StyledComponents';
import { DashboardCareSiteRole } from 'consts/roles';

interface CareSitesButtonProps {
  careSites: Array<ILookupValue>;
  careSiteMenuOpen: boolean;
  selectedCareSite?: ILookupValue;
  handleClick: (event: React.MouseEvent<HTMLElement>, type: string) => void;
  anchorCareSiteEl: null | HTMLElement;
  handleClose: (type: string) => void;
  handleClickCareSite: (careSite: ILookupValue) => void;
}

const CareSitesButton = ({
  careSites,
  careSiteMenuOpen,
  selectedCareSite,
  handleClick,
  anchorCareSiteEl,
  handleClose,
  handleClickCareSite,
}: CareSitesButtonProps) => {
  return (
    <>
      {careSites.length === 1 ? (
        <Box>
          <StyledMenuTrigger $menuOpen={careSiteMenuOpen} $disableHover={true}>
            <StyledTypography variant="h6" $textColor={COLORS.WHITE}>
              {selectedCareSite?.name}
            </StyledTypography>
          </StyledMenuTrigger>
        </Box>
      ) : null}
      {careSites.length > 1 ? (
        <Box>
          <StyledMenuTrigger
            onClick={(event) => handleClick(event, 'careSite')}
            $menuOpen={careSiteMenuOpen}
            $disableHover={false}
          >
            <StyledTypography
              variant="h6"
              $textColor={getColor(careSiteMenuOpen)}
            >
              {selectedCareSite?.name}
            </StyledTypography>
            {getDropdownIcon(careSiteMenuOpen)}
          </StyledMenuTrigger>
          <Menu
            anchorEl={anchorCareSiteEl}
            open={careSiteMenuOpen}
            onClose={() => handleClose('careSite')}
            autoFocus={false}
            keepMounted={true}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                left: '0',
                marginTop: '5px',
                borderRadius: '4px',
                border: '1px solid #CECECE',
                boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            {careSites.map((item) => (
              <StyledMenuItem
                key={item.id}
                value={item.name}
                $active={selectedCareSite?.id === item.id}
                $paddingLeft={
                  selectedCareSite?.id === item.id ? '20px' : '44px'
                }
                onClick={() => handleClickCareSite(item)}
              >
                {selectedCareSite?.id === item.id ? (
                  <Icon
                    icon={ICONS.Checkmark}
                    size={12}
                    style={{ marginRight: '6px' }}
                  />
                ) : null}
                {item.name}
              </StyledMenuItem>
            ))}
          </Menu>
        </Box>
      ) : null}
      {careSites.length &&
      selectedCareSite?.id != DashboardCareSiteRole.careSiteId ? (
        <StyledDivider orientation="vertical" />
      ) : null}
    </>
  );
};

export default CareSitesButton;
