import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Grid as TableGrid,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  CustomPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingPanel,
  PagingState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Episode } from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import Loader from 'components/loader';
import SearchBar from 'components/searchbar/SearchBar';
import PagingContainer from 'components/home/grid/pager';
import {
  SelectedRow,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import { useLazySearchEpisodesQuery } from 'graphql/hooks/searchEpisodes';
import {
  columnsExtension,
  getDefaultEpisodeAdditionalFilter,
  getProviderInfo,
  toSearchEpisodeRequestInput,
} from './EpisodeSearchDialog.helper';
import { IEpisodeAdditionalFilters } from './types';
import EpisodeFilterButton from './EpisodeFilterButton';
import EpisodesDateTimeProvider from 'components/home/grid/providers/EpisodesDateTimeProvider';
import {
  AssociatedEpisodeAttributeNames,
  convertParStatusValue,
  no,
  yes,
} from 'components/actions/sections/SectionBody/Items/AssociatedEpisode/AssociatedEpisode.helper';

export interface IEpisodeSearchDialogModel {
  isFetching: boolean;
  episodes: Episode[];
  totalCount: number;
  patientId: number;
  onSelect: (episode: Episode) => void;
  setSearchTerm: (term: string) => void;
}

const EpisodeSearchDialogBody = (props: IEpisodeSearchDialogModel) => {
  const { onSelect, patientId } = props;
  const [selectedId, setSelectedId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const [sorting, setSorting] = useState<Sorting>();
  const [filters, setFilters] = useState(getDefaultEpisodeAdditionalFilter());

  const columns = [
    { name: 'episodeNumber', title: 'Episode Id' },
    { name: 'faxDateTime', title: 'Received Data/Time' },
    {
      name: 'pathway',
      title: 'Episode Pathway',
      getCellValue: (row: Episode) =>
        row?.entityAttributes?.find(
          (x) => x?.name == AssociatedEpisodeAttributeNames.pathway
        )?.lookup?.name,
    },
    {
      name: 'diagnosisCode',
      title: 'Diagnosis',
      getCellValue: (row: Episode) =>
        row?.diagnosisCodesAll?.map((x) => x?.code)?.join(', '),
    },
    {
      name: 'procedureCode',
      title: 'Procedure',
      getCellValue: (row: Episode) =>
        row?.procedureCodesAll?.map((x) => x?.code)?.join(', '),
    },
    {
      name: 'authorizationStatus',
      title: 'Authorization Status',
      getCellValue: (row: Episode) => row?.authorizationStatus?.name,
    },
    {
      name: 'provider',
      title: 'Provider Name',
      getCellValue: (row: Episode) => getProviderInfo(row?.requestingClinician),
    },
    {
      name: 'parStatus',
      title: 'Par Status',
      getCellValue: (row: Episode) => {
        const parStatus = row?.entityAttributes?.find(
          (x) =>
            x?.name == AssociatedEpisodeAttributeNames.requestingClinicianPar
        )?.value;
        return parStatus && convertParStatusValue(parStatus) ? yes : no;
      },
    },
    { name: 'determinationDate', title: 'Determination Date/Time' },
    {
      name: 'decisionby',
      title: 'Decision By',
      getCellValue: (row: Episode) =>
        row?.entityAttributes?.find(
          (x) => x?.name == AssociatedEpisodeAttributeNames.decisionBy
        )?.value,
    },
    {
      name: 'owner',
      title: 'Owner',
      getCellValue: (row: Episode) => row?.owner?.fullName,
    },
  ];
  const [
    searchEpisodes,
    { data: searchEpisodeData, isFetching: isSearchEpisodeLoading },
  ] = useLazySearchEpisodesQuery();

  useEffect(() => {
    searchEpisodes({
      request: toSearchEpisodeRequestInput(
        patientId,
        searchTerm,
        filters,
        currentPage,
        sorting
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filters, patientId, currentPage, sorting]);

  const episodes = searchEpisodeData?.searchEpisodes;
  const episodeData = episodes?.rows ?? [];
  const totalCount = episodes?.totalCount ?? 0;

  const onSelectionChange = (values: Array<number | string>) => {
    if (values.length > 1) {
      const selectedEpisodeId = Number(values[1]);
      setSelectedId(selectedEpisodeId);
      const selectedEpisode = episodeData.find(
        (x) => x.id == selectedEpisodeId
      );
      onSelect(selectedEpisode as Episode);
    }
  };

  const onUpdateFilters = (filterValues: IEpisodeAdditionalFilters) => {
    setFilters(filterValues);
    setCurrentPage(0);
  };

  const handleSetCurrentPage = (currentPage: number) => {
    setCurrentPage(currentPage);
  };

  const handleSetSorting = (sorting: Sorting[]) => {
    setSorting(sorting[0]);
  };

  return (
    <>
      <Box style={{ backgroundColor: COLORS.PALE_GREY, height: '100%' }}>
        <Paper
          elevation={0}
          style={{ paddingLeft: '8px', paddingRight: '8px' }}
          data-testid="episode-search-dialog-body"
        >
          <Grid container>
            <Grid item xs={5}>
              <SearchBar
                placeholder="Search by Episode ID (at least 3 chars)"
                onSubmit={setSearchTerm}
                style={{ width: '300px' }}
              />
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" justifyContent="flex-end">
                <EpisodeFilterButton
                  filters={filters}
                  onChangeFilter={onUpdateFilters}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: '20px' }} />
          </Grid>
          <Box>
            <TableGrid
              rows={episodeData}
              columns={columns}
              getRowId={(row: Episode) => row.id}
            >
              <SelectionState
                selection={[selectedId]}
                defaultSelection={[]}
                onSelectionChange={onSelectionChange}
              />
              <SortingState
                defaultSorting={[
                  { columnName: 'faxDateTime', direction: 'desc' },
                ]}
                columnExtensions={columnsExtension}
                onSortingChange={handleSetSorting}
              />
              <PagingState
                currentPage={episodes?.pageNumber ?? 0}
                pageSize={episodes?.pageSize ?? 15}
                onCurrentPageChange={handleSetCurrentPage}
              />
              <CustomPaging totalCount={totalCount} />
              <IntegratedSelection />
              <IntegratedSorting columnExtensions={columnsExtension} />
              <EpisodesDateTimeProvider
                for={['faxDateTime', 'determinationDate']}
              />
              <SimpleTextProvider
                for={[
                  'episodeNumber',
                  'diagnosisCode',
                  'procedureCode',
                  'provider',
                  'owner',
                  'decisionby',
                  'pathway',
                  'authorizationStatus',
                ]}
              />
              {totalCount > 0 && (
                <PagingPanel containerComponent={PagingContainer} />
              )}
              <SymphonyTable columnExtensions={columnsExtension} />
              <SymphonyTableHeaderRow showSortingControls />
              <TableSelection
                selectByRowClick
                highlightRow
                rowComponent={SelectedRow}
                showSelectionColumn={false}
              />
            </TableGrid>
          </Box>
        </Paper>
      </Box>
      <Loader active={isSearchEpisodeLoading} />
    </>
  );
};

export default EpisodeSearchDialogBody;
