import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import { Box, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon, { ICONS } from 'components/icon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { IRole } from 'store/roles/types';

const StyledListItemWrapper = styled.div`
  border: 1px solid #cecece;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 0 15px 20px;
  margin-right: 10px;
  min-width: 308px;
  justify-content: space-between;
  width: 100%;

  &:hover {
    border-color: ${COLORS.SYMPHONY_BLUE};
  }
`;

export interface IRoleListItemProps {
  role: IRole;
  handleChangeUserRole: (selectedRoleId: number, roleId: number | null) => void;
  primaryRoleId: number | null;
  setPrimaryRole: (value: number) => void;
  showFavoriteButton?: boolean;
}

export const RoleListItem = ({
  handleChangeUserRole,
  primaryRoleId,
  role,
  setPrimaryRole,
  showFavoriteButton = true,
}: IRoleListItemProps) => {
  const [hoveredItem, setHoveredItem] = useState<string>('');
  return (
    <ListItem
      key={role.id}
      data-cy={`role-item-${role.id}`}
      style={{
        marginBottom: '5px',
        paddingLeft: 0,
      }}
      onMouseEnter={() => setHoveredItem(role.name)}
      onMouseLeave={() => setHoveredItem('')}
    >
      <StyledListItemWrapper
        onClick={() => handleChangeUserRole(role.id, primaryRoleId)}
      >
        <Typography
          style={{
            color: COLORS.GREY100,
            whiteSpace: 'nowrap',
          }}
        >
          {role.displayName}
        </Typography>
        <ListItemIcon style={{ minWidth: 'auto' }}>
          <Icon
            icon={ICONS.Right}
            size={24}
            color={
              hoveredItem === role.name ? COLORS.SYMPHONY_BLUE : COLORS.WHITE
            }
          />
        </ListItemIcon>
      </StyledListItemWrapper>
      <ListItemSecondaryAction style={{ marginLeft: '5px' }}>
        {showFavoriteButton && (
          <CustomTooltip title="Favorite">
            <Box component="div">
              <IconButton
                onClick={() => setPrimaryRole(role.id)}
                aria-label="set primary"
                data-cy={`set-primary-${role.id}`}
                style={{ padding: '6px' }}
                disabled={role.id === primaryRoleId}
                size="large"
              >
                <Icon
                  icon={
                    role.id === primaryRoleId ? ICONS.Filled : ICONS.Outline
                  }
                  size={24}
                  color={
                    role.id === primaryRoleId
                      ? COLORS.YELLOW100
                      : COLORS.LIGHT_GREY
                  }
                />
              </IconButton>
            </Box>
          </CustomTooltip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
