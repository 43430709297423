import React from 'react';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const PriorAuthPolicyIcon = ({
  secondaryMessage,
}: {
  secondaryMessage: string;
}) => {
  return secondaryMessage ? (
    <Icon
      icon={ICONS.Info}
      size={14}
      color={COLORS.GREY100}
      title={secondaryMessage}
      style={{ marginLeft: '4px', marginTop: '-2px' }}
      tooltipContainerElement="span"
    />
  ) : null;
};

export default PriorAuthPolicyIcon;
