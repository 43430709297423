import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import OpenRemindersTab from './OpenRemindersTab';
import ClosedRemindersTab, { IReminders } from './ClosedRemindersTab';
import { useNewDefaultReminder } from 'components/home/RemindersGrid/RemindersGrid.hooks';
import {
  commitReminders,
  removeJustAddedReminder,
  setActiveEpisode,
  setIsInEditMode,
  updatePatientRemindersRows,
} from 'store/patientdetails/patientDetailsSlice';
import { IState } from 'store';
import { StatusFilterAll, useData } from 'components/gridFormatters';
import { getAvailableId } from 'components/home/attachmentsList/attachments.helpers';
import { BaseReminder } from 'graphql/graphqlTypes';
import {
  GetPatientRemindersQuery,
  useLazyGetPatientRemindersQuery,
} from 'graphql/hooks/getPatientReminders';
import styled from 'styled-components';
import Loader from 'components/loader';

const TABS = ['Open', 'Closed'];

const StyledTabs = styled(Tabs)`
  padding: 0 12px;
  border-bottom: 1px solid ${COLORS.GREY25};
  margin: -14px -24px 22px -24px;
`;

const isOpened = (item: BaseReminder) =>
  !item.isCompleted && !item.isExpired && !item.isDeleted;

const RemindersTab = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [editingRowIds, setEditingRowIds] = useState<number[]>([]);
  const [selectedFilter, setSelectedFilter] = useState(StatusFilterAll);

  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const removeEditingRowId = (rowId: number) => {
    setEditingRowIds((current) => current.filter((x) => x !== rowId));
  };

  useEffect(() => {
    dispatch(setIsInEditMode({ isInEditMode: editingRowIds?.length !== 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRowIds]);

  const { data: memberReminders, isFetching } = useData(
    (state: IState) => state.patientDetails.memberReminders,
    useLazyGetPatientRemindersQuery,
    { patientId },
    (data: GetPatientRemindersQuery) =>
      updatePatientRemindersRows({
        reminders: data.getPatientReminders as BaseReminder[],
      }),
    !patientId
  );
  const handleChange = <T,>(_: SyntheticEvent<T>, newValue: number) => {
    setCurrentTab(newValue);
    dispatch(removeJustAddedReminder());
  };
  const newDefaultReminder = useNewDefaultReminder();
  const episodeReminders: BaseReminder[] =
    memberReminders?.filter((item) => item.episode && isOpened(item)) ?? [];

  const memberOpenReminders =
    memberReminders?.filter((item) => !item.episode && isOpened(item)) ?? [];

  const closedReminders: IReminders = {
    completedReminders:
      memberReminders?.filter((item) => item.isCompleted) ?? [],
    expiredReminders: memberReminders?.filter((item) => item.isExpired) ?? [],
    deletedReminders: memberReminders?.filter((item) => item.isDeleted) ?? [],
  };
  const handleAddReminder = () => {
    const newId = getAvailableId(memberReminders, (x) => x.id);
    dispatch(
      commitReminders({
        patientId: patientId,
        reminders: {
          added: [{ ...newDefaultReminder, id: newId }],
        },
      })
    );
    setEditingRowIds([newId]);
  };

  useEffect(() => {
    dispatch(setActiveEpisode(0));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredEpisodeReminders =
    selectedFilter.id === -1
      ? episodeReminders
      : episodeReminders.filter(
          (item) => item.episode?.type?.id === selectedFilter.id
        );

  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );

  return (
    <div style={{ paddingTop: '24px' }}>
      <div>
        <StyledTabs
          value={currentTab}
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs"
        >
          {TABS.map((item, index) => (
            <Tab
              style={{ textTransform: 'capitalize' }}
              label={item}
              key={item}
              disabled={isInEditMode && index !== currentTab}
            />
          ))}
        </StyledTabs>
      </div>
      {currentTab === 0 ? (
        <OpenRemindersTab
          reminders={memberOpenReminders}
          episodeReminders={filteredEpisodeReminders}
          handleAddReminder={handleAddReminder}
          selectedEpisodeRemindersFilter={selectedFilter}
          updateFilter={setSelectedFilter}
          editingRowIds={editingRowIds}
          setEditingRowIds={setEditingRowIds}
          isDisabled={isInEditMode}
          removeEditingRowId={removeEditingRowId}
        />
      ) : currentTab === 1 ? (
        <ClosedRemindersTab reminders={closedReminders} />
      ) : null}

      <Loader active={isFetching} />
    </div>
  );
};

export default RemindersTab;
