import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import Panel from 'components/panel';
import Reminder from 'components/actions/sections/SectionBody/Components/Reminder';
import { COLORS } from 'consts/styles';
import { ChecklistStorageType } from 'store/actions/types';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import {
  BaseComponent,
  ChecklistCategory,
  ChecklistItem,
  ChecklistItemOrderable,
  ComponentsLine,
  ReminderComponent,
} from 'graphql/graphqlTypes';
import { useGetEpisodeRemindersQuery } from 'graphql/hooks/getEpisodeReminders';

const Actions = () => {
  const checklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST].checklist
  );

  const episodeId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CHECKLIST]
        .episodeId as number
  );

  const { data: episodeReminders } = useGetEpisodeRemindersQuery(
    { episodeId: episodeId },
    { skip: !episodeId }
  );

  const getReminders = (
    categories: ChecklistCategory[]
  ): ReminderComponent[] => {
    const components: ReminderComponent[] = [];
    if (categories) {
      categories.forEach((category: ChecklistCategory) => {
        category.items
          .filter(
            (item: ChecklistItem) =>
              item.isVisible && item.type === 'orderable' && item.isSelected
          )
          .map((item: ChecklistItem) => item as ChecklistItemOrderable)
          .forEach((item: ChecklistItemOrderable) =>
            item.lines.forEach((line: ComponentsLine) =>
              line.components
                .filter(
                  (component: BaseComponent) =>
                    component.componentType === 'ReminderComponent'
                )
                .map(
                  (component: BaseComponent) => component as ReminderComponent
                )
                .forEach((component: ReminderComponent) =>
                  components.push(component)
                )
            )
          );

        const subComponents = getReminders(category.subCategories);
        subComponents.forEach((component: ReminderComponent) =>
          components.push(component)
        );
      });
    }

    return components.filter(
      (c) =>
        episodeReminders?.getEpisodeReminders.some(
          (e) => e.id === c.reminderId
        ) &&
        (c.isReminderModified || c.isReminderCreated)
    );
  };

  const remainders = getReminders(checklist?.categories || []);

  if (remainders.length > 0) {
    return (
      <>
        <Box
          sx={{
            margin: '36px 0 14px 0',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography variant="h4" data-testid="Header">
            Created Actions
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: COLORS.GREY100,
              marginLeft: '5px',
            }}
          >
            ({remainders.length})
          </Typography>
        </Box>
        <Panel padding="24px 32px">
          {remainders.map((reminder: ReminderComponent, itemIndex: number) => (
            <Box
              display="flex"
              flexDirection="column"
              key={itemIndex}
              style={{
                backgroundColor: `${
                  !(itemIndex & 1) ? COLORS.WHITE : COLORS.PALE_GREY
                }`,
              }}
            >
              <Reminder
                component={reminder}
                disabled={true}
                categoryId=""
                handleChecklistInputChange={() => void 0}
                autoSave={false}
                storageType={ChecklistStorageType.CHECKLIST}
                isActionSelected={true}
                isReadOnly={true}
              />
            </Box>
          ))}
        </Panel>
      </>
    );
  } else {
    return null;
  }
};

export default Actions;
