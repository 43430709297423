import { ROUTE } from 'consts/ROUTE';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearHomeModel } from 'store/home/action';
import { clearSearch } from 'store/ui/header';
import { setWorkflowStep } from 'store/workflow/workflowSlice';

export const useResetStateAfterRoleChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useCallback(
    (skipRedirect?: boolean) => {
      dispatch(setWorkflowStep({ step: 0, name: '' }));
      dispatch(clearSearch());
      dispatch(clearHomeModel());
      if (!skipRedirect && location.pathname !== ROUTE.Home) {
        navigate(ROUTE.Home);
      }
    },
    [dispatch, navigate]
  );
};
