import { IAuthUser } from 'backend/types/authUser';

export interface IAuthState {
  currentUser: IAuthUser;
  error: string;
  step: string;
  isLoggedIn: boolean;
  isCaptchaRequired: boolean;
  auth: ILoginUser;
  loading: boolean;
  cnt: number;
  authFinished: boolean;
  countryCode: string;
  countryCodes: ICountryCode[];
  phone: string;
  mfaType: string;
  code?: string | null;
  isUserLoggedOut: boolean;
}

export interface ILoginUser {
  login: string;
  password: string;
  captcha?: string | null;
  ssoToken?: string | null;
}

export interface ILogoutUser {
  sessionId: string;
}

export interface IChangePassword {
  login: string;
  password: string;
  newpassword: string;
  newpassword2: string;
}

export interface IChangeProvider {
  providerId: number;
  careSiteId: number;
}

export interface IMfaStep {
  login: string;
  password: string;
  countryCode: string;
  countryCodes: ICountryCode[];
  phone: string;
  mfaType: string;
  code?: string | null;
}

export interface ITokenRequest {
  token: string;
  refreshToken: string;
}

export enum UpdateType {
  ChangePassword = 0,
  UpdatePassword = 1,
}

export interface ICountryCode {
  id: string;
  name: string;
}
