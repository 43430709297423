import React, { useEffect } from 'react';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { Box, Button, Typography } from '@mui/material';
import {
  DocumentActionsProvided,
  SituationProvider,
  TextProvider,
} from 'components/home/patientdetails/tabscontent/episodesTab/subTabs/DocumentProviders';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import Loader from 'components/loader';
import MemberNoteReasonProvider from 'components/home/grid/providers/MemberNoteReasonProvider';
import {
  MEMBER_NOTES_COLUMNS,
  MEMBER_NOTES_COLUMNS_EXTENSIONS,
} from './consts';
import DateTimeProvider from 'components/home//grid/providers/DateTimeProvider';
import { useGetPatientQuickNotesQuery } from 'graphql/hooks/getPatientQuickNotes';

interface IMemberNotesGridProps {
  shouldRefetch: boolean;
}

const MemberNotesGrid = (props: IMemberNotesGridProps) => {
  const { shouldRefetch } = props;
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const {
    data: patientNotes,
    isFetching,
    refetch: refetchNotes,
    isError,
  } = useGetPatientQuickNotesQuery(
    { patientId },
    {
      skip: !(patientId > 0),
    }
  );

  useEffect(() => {
    shouldRefetch && refetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  if (isError) {
    return (
      <Box paddingBottom="20px" textAlign="center">
        <Typography style={{ paddingBottom: '10px' }} variant="h4">
          Unexpected error has occurred
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={refetchNotes}
        >
          Try again
        </Button>
      </Box>
    );
  }

  return (
    <Box mb="32px">
      {isFetching ? (
        <Loader active={true} />
      ) : (
        <Box>
          <Grid
            rows={patientNotes?.getPatientQuickNotes ?? []}
            columns={MEMBER_NOTES_COLUMNS}
          >
            <DateTimeProvider for={['started']} />
            <SituationProvider for={['situation']} />
            <TextProvider for={['author']} />
            <MemberNoteReasonProvider for={['reason']} />
            <DocumentActionsProvided for={['operations']} />
            <SymphonyTable columnExtensions={MEMBER_NOTES_COLUMNS_EXTENSIONS} />
            <SymphonyTableHeaderRow />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default MemberNotesGrid;
