import { claimColumns } from './claimColumns';
import { PatientClinicHospital } from 'graphql/graphqlTypes';

export const claimGridColumns = [
  {
    name: claimColumns.claimId,
    title: 'Claim ID',
  },
  {
    name: claimColumns.claimDate,
    title: 'Claim Date',
  },
  {
    name: claimColumns.diagnosis,
    title: 'Diagnosis Code',
    getCellValue: (row: PatientClinicHospital) => row?.diagnosis?.code,
  },
  {
    name: claimColumns.performingProvider,
    title: 'Performing Provider',
  },
  {
    name: claimColumns.procedure,
    title: 'Procedure Code',
    getCellValue: (row: PatientClinicHospital) => row?.medProcedure?.code,
  },
  {
    name: claimColumns.paidAmount,
    title: 'Paid Amount',
    getCellValue: (row: PatientClinicHospital) =>
      row?.paidAmount ? `$ ${row?.paidAmount}` : null,
  },
];
