import React from 'react';
import AttachmentViewLink from 'components/actionlink/AttachmentViewLink';
import { ICONS } from 'components/icon';
import { CellComponentPadded } from 'components/gridFormatters/CellComponentPadded';
import { ILabelCellProps } from 'components/gridFormatters';

export const getAttachmentIcon = (type: string) => {
  switch (type) {
    case 'doc':
    case 'docx':
    case 'rtf':
    case 'Word document':
      return { icon: ICONS.DOC, tooltip: 'Word document' };
    case 'xls':
    case 'xlsx':
    case 'Excel document':
      return { icon: ICONS.XLS, tooltip: 'Excel document' };
    case '7z':
    case 'zip':
    case 'rar':
    case 'tar':
    case 'Archive':
      return { icon: ICONS.Archive, tooltip: 'Archive' };
    case 'pdf':
    case 'Pdf document':
      return { icon: ICONS.PDF, tooltip: 'Pdf document' };
    case 'ppt':
    case 'pptx':
    case 'Powerpoint presentation':
      return { icon: ICONS.PPT, tooltip: 'Powerpoint presentation' };
    case 'img':
    case 'png':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'bmp':
    case 'tif':
    case 'tiff':
    case 'Image':
      return { icon: ICONS.Img, tooltip: 'Image' };
    case 'ods':
    case 'odt':
    case 'Open Office':
      return { icon: ICONS.Office, tooltip: 'Open Office' };
    case 'html':
    case 'htm':
    case 'HTML file':
      return { icon: ICONS.HTML, tooltip: 'HTML file' };
    case 'txt':
    case 'Text file':
      return { icon: ICONS.TXT, tooltip: 'Text file' };
    default:
      return { icon: ICONS.FileType_Default, tooltip: '' };
  }
};

export const AttachmentTypeCell = (props: ILabelCellProps) => {
  const { icon, tooltip } = getAttachmentIcon(props.row?.fileExtension);
  return (
    <CellComponentPadded {...props}>
      <AttachmentViewLink
        key={`AttachmentView${props.row?.id}`}
        attachmentId={Number(props.row?.id ?? 0)}
        fileName={props.row?.name ?? ''}
        title={tooltip}
        asIcon
        isDisabled={Boolean(props.isActionsDisabled)}
        iconProps={{
          icon: icon,
          color: undefined,
          size: 18,
        }}
      />
    </CellComponentPadded>
  );
};
