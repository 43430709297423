import { useEffect, useRef } from 'react';
import { setChecklistComponentValidation } from 'store/actions/checklistSlice';
import { usePrevious } from 'hooks';
import { ChecklistStorageType } from 'store/actions/types';
import { useDispatch } from 'react-redux';

export const useChecklistValidation = (
  error: boolean,
  autoSave: boolean,
  isActionSelected: boolean,
  isReadOnly: boolean,
  uuid: string,
  categoryId: string,
  fieldName: string,
  errorMessage: string,
  storageType: ChecklistStorageType
) => {
  useValidationOnErrorChange(
    error,
    autoSave,
    isActionSelected,
    isReadOnly,
    uuid,
    categoryId,
    errorMessage,
    fieldName,
    storageType
  );
  useResetValidationOnUnmount(
    error,
    autoSave,
    uuid,
    fieldName,
    categoryId,
    storageType
  );
};

const useResetValidationOnUnmount = (
  error: boolean,
  autoSave: boolean,
  uuid: string,
  fieldName: string,
  categoryId: string,
  storageType: ChecklistStorageType
) => {
  const dispatch = useDispatch();

  // We need to use ref here because
  // otherwise cleanup function remembers (in closure)
  // value of error during the first call
  const currentError = useRef<boolean>();
  currentError.current = error;
  useEffect(
    () => () => {
      const hasError = currentError.current;
      if (!hasError) {
        return;
      }
      dispatch(
        setChecklistComponentValidation({
          storageType,
          error: {
            uuid,
            error: '',
            fieldName,
            categoryId,
            isValid: true,
          },
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

const useValidationOnErrorChange = (
  error: boolean,
  autoSave: boolean,
  isActionSelected: boolean,
  isReadOnly: boolean,
  uuid: string,
  categoryId: string,
  errorMessage: string,
  fieldName: string,
  storageType: ChecklistStorageType
) => {
  const dispatch = useDispatch();
  const previousError = usePrevious(error);
  const previousErrorMessage = usePrevious(errorMessage);
  const previousIsActionSelected = usePrevious(isActionSelected);
  useEffect(() => {
    if (isReadOnly) {
      return;
    }
    if (
      error === previousError &&
      errorMessage === previousErrorMessage &&
      isActionSelected === previousIsActionSelected
    ) {
      return;
    }
    if (!error && !previousError) {
      return;
    }
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid,
          error: errorMessage,
          fieldName: fieldName,
          categoryId: categoryId,
          isValid: !(error && isActionSelected),
        },
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isActionSelected, errorMessage]);
};
