import { COLORS } from 'consts/styles';
import {
  PatientMedicationPatientUse,
  PatientMedicationStatus,
} from 'graphql/graphqlTypes';

export enum medicationsListColumnsTypes {
  medication = 'medication',
  instructions = 'instructions',
  rxStatus = 'patientState',
  patientUseName = 'patientUse',
  note = 'note',
  actions = 'actions',
}

export enum medicationsClaimsColumnsTypes {
  medication = 'medication',
  prescribingProvider = 'prescribingProvider',
  pharmacy = 'pharmacy',
  dispenseDate = 'dispenseDate',
  quantity = 'quantityDispensed',
}

export const patientUseText = {
  [PatientMedicationPatientUse.NotTaking]: 'Not taking currently',
  [PatientMedicationPatientUse.TakesasPrescribed]: 'Taking as prescribed',
  [PatientMedicationPatientUse.TakesDifferently]: 'Taking differently',
  [PatientMedicationPatientUse.MemberDirected]: 'Member directed',
};

export const medicationsListColumnsExtensions = [
  {
    columnName: medicationsListColumnsTypes.medication,
    sortingEnabled: true,
    width: '30%',
  },
  {
    columnName: medicationsListColumnsTypes.instructions,
    width: '10%',
    sortingEnabled: true,
  },
  {
    columnName: medicationsListColumnsTypes.rxStatus,
    width: '10%',
    sortingEnabled: true,
  },
  {
    columnName: medicationsListColumnsTypes.patientUseName,
    width: '10%',
    sortingEnabled: true,
  },
  {
    columnName: medicationsListColumnsTypes.note,
    sortingEnabled: false,
  },
  {
    columnName: medicationsListColumnsTypes.actions,
    width: '10%',
    sortingEnabled: false,
  },
];

export const medicationsListColumnsExtensionsOpen = [
  {
    columnName: medicationsListColumnsTypes.medication,
    sortingEnabled: true,
    width: '50%',
  },
  {
    columnName: medicationsListColumnsTypes.instructions,
    width: '50%',
    sortingEnabled: true,
  },
];
export const medicationsListColumnsOpen = [
  {
    name: medicationsListColumnsTypes.medication,
    title: 'Medication',
  },
  {
    name: medicationsListColumnsTypes.instructions,
    title: 'Instructions',
  },
];

export const medicationsClaimsColumnsExtensions = [
  {
    columnName: medicationsClaimsColumnsTypes.medication,
    sortingEnabled: true,
    width: '30%',
  },
  {
    columnName: medicationsClaimsColumnsTypes.prescribingProvider,
    width: '20%',
    sortingEnabled: true,
  },
  {
    columnName: medicationsClaimsColumnsTypes.pharmacy,
    sortingEnabled: true,
  },
  {
    columnName: medicationsClaimsColumnsTypes.dispenseDate,
    width: '10%',
    sortingEnabled: true,
  },
  {
    columnName: medicationsClaimsColumnsTypes.quantity,
    width: '10%',
    sortingEnabled: false,
  },
];
export const medicationsClaimsColumns = [
  {
    name: medicationsClaimsColumnsTypes.medication,
    title: 'Medication',
  },
  {
    name: medicationsClaimsColumnsTypes.prescribingProvider,
    title: 'Prescribing Provider',
  },
  {
    name: medicationsClaimsColumnsTypes.pharmacy,
    title: 'Pharmacy',
  },
  {
    name: medicationsClaimsColumnsTypes.dispenseDate,
    title: 'Dispense Date',
  },
  {
    name: medicationsClaimsColumnsTypes.quantity,
    title: 'Quantity',
  },
];
export const medicationsListColumns = [
  {
    name: medicationsListColumnsTypes.medication,
    title: 'Medication',
  },
  {
    name: medicationsListColumnsTypes.instructions,
    title: 'Instructions',
  },
  {
    name: medicationsListColumnsTypes.rxStatus,
    title: 'RX Status',
  },
  {
    name: medicationsListColumnsTypes.patientUseName,
    title: 'Use',
  },
  {
    name: medicationsListColumnsTypes.note,
    title: 'Notes',
  },
  {
    name: medicationsListColumnsTypes.actions,
    title: ' ',
  },
];

export const StatusColumnValues = {
  [PatientMedicationStatus.Discontinued]: {
    name: 'Discontinued',
    color: COLORS.GREY60,
  },
  [PatientMedicationStatus.Active]: {
    name: 'Active',
    color: COLORS.GREEN100,
  },
};
