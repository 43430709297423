import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type InsertMozartWorkflowOotStepMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
  workflowInstanceOotStepId: Types.Scalars['UUID'];
}>;

export type InsertMozartWorkflowOotStepMutation = {
  __typename?: 'Mutation';
  insertMozartWorkflowOOTStep?: {
    __typename?: 'MozartActionResult';
    result: boolean;
    message?: string | null;
  } | null;
};

export const InsertMozartWorkflowOotStepDocument = `
    mutation insertMozartWorkflowOotStep($id: UUID!, $workflowInstanceOotStepId: UUID!) {
  insertMozartWorkflowOOTStep(
    id: $id
    workflowInstanceOOTStepId: $workflowInstanceOotStepId
  ) {
    result
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    insertMozartWorkflowOotStep: build.mutation<
      InsertMozartWorkflowOotStepMutation,
      InsertMozartWorkflowOotStepMutationVariables
    >({
      query: (variables) => ({
        document: InsertMozartWorkflowOotStepDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInsertMozartWorkflowOotStepMutation } = injectedRtkApi;
