import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetLinkEpisodesQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetLinkEpisodesQuery = {
  __typename?: 'EntitiesQuery';
  getLinkEpisodes?: Array<{
    __typename?: 'LinkEpisode';
    episodeId: number;
    episodeNumber: string;
    linkEpisodeId: string;
    episodeTypeId?: number | null;
  } | null> | null;
};

export const GetLinkEpisodesDocument = `
    query getLinkEpisodes($episodeId: Int!) {
  getLinkEpisodes(episodeId: $episodeId) {
    episodeId
    episodeNumber
    linkEpisodeId
    episodeTypeId
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLinkEpisodes: build.query<
      GetLinkEpisodesQuery,
      GetLinkEpisodesQueryVariables
    >({
      query: (variables) => ({ document: GetLinkEpisodesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetLinkEpisodesQuery, useLazyGetLinkEpisodesQuery } =
  injectedRtkApi;
