import { uniqBy } from 'lodash';
import {
  ICompletedStoplight,
  ISetStoplightValidationBody,
} from 'store/actions/types';
import {
  ChecklistItemOrderable,
  ChecklistViewModel,
} from 'graphql/graphqlTypes';
export const isChecklistItemOrderable = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any
): item is ChecklistItemOrderable => !!item && !!item.qualityMeasure?.id;

const isRequired = (item: ChecklistItemOrderable) =>
  item.qualityMeasure?.measureLevel === 'Required';

export const getStoplights = (
  checklist: ChecklistViewModel | undefined
): ISetStoplightValidationBody[] => {
  if (!checklist) {
    return [];
  }
  const clearedStopLights: ISetStoplightValidationBody[] = checklist.categories
    .map((category, index) =>
      category.items
        .filter(isChecklistItemOrderable)
        .filter(isRequired)
        .map<ISetStoplightValidationBody>((item) => ({
          id: item.qualityMeasure!.id,
          name: item.qualityMeasure!.name,
          displayName: item.qualityMeasure!.displayName,
          isReadOnly: item.qualityMeasure!.isReadOnly,
          userChoice: item.qualityMeasure!.userChoice,
          measureLevel: item.qualityMeasure!.measureLevel!,
          uuid: item.uid,
          sectionId: index + 1,
          categoryId: category.id,
          errorType: 'Stoplight',
          itemsWithStoplights: checklist.categories
            .flatMap((x) => x.items)
            .filter(isChecklistItemOrderable)
            .filter(
              (stoplight) =>
                stoplight.qualityMeasure?.id === item.qualityMeasure?.id
            )
            .map<ICompletedStoplight>((stoplight) => ({
              id: stoplight.uid,
              isCompleted: stoplight.isSelected,
              orderableId: stoplight.orderableId,
              isVisible: stoplight.isVisible,
              categoryId: category.id,
            })),
        }))
    )
    .flat();
  return uniqBy(clearedStopLights, 'id');
};
