import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';

const DateTimeFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <Typography>{props.value ? formatDateTime(props.value) : ''}</Typography>
);

export default DateTimeFormatter;
