import React, { ChangeEvent, ReactNode } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import MultiEditSelect from './MultiEditSelect';
import { ILookupValue } from 'backend/types/lookupValue';
import { IFieldRow } from './types';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const StyledTextField = styled(TextField)({
  fontSize: '16px',
  color: COLORS.NAVY_BLUE,
});

const StyledFormControl = styled.div`
  width: 100%;
  height: 100%;
  max-width: 300px;
  text-align: left;
`;

const StyledGridContainer = styled(Grid)`
  padding: 12px 24px;
  background-color: ${COLORS.WHITE};
  :nth-child(odd) {
    background-color: ${COLORS.GREY4};
  }
`;

export interface IMultiEditFormRow {
  selectedFields: Array<string>;
  handleCheckBoxClick: (event: ChangeEvent<HTMLInputElement>) => void;
  showStatus: boolean;
  onChangeStatus: (status: boolean) => void;
  fieldRow: IFieldRow;
  handleChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleMultiSelectChange: (values: number[]) => void;
  handlePcpAutoCompleteChange: (
    event: ChangeEvent<unknown>,
    newValue: ILookupValue | null
  ) => void;
  handleSuggestItems: (
    request: string,
    callback: (arg: ILookupValue[]) => void
  ) => void;
}

const StyledFormControlLabel = styled(FormControlLabel)({
  label: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
  },
});

const MultiEditFormRow = (props: IMultiEditFormRow) => {
  const {
    selectedFields,
    handleCheckBoxClick,
    showStatus,
    onChangeStatus,
    fieldRow,
    handleChange,
    handleMultiSelectChange,
    handlePcpAutoCompleteChange,
    handleSuggestItems,
  } = props;
  const { register } = useFormContext();

  const renderSelectedValue = (value: string): ReactNode => {
    return (
      <Box display="flex" alignItems="center">
        <div
          style={{
            borderRadius: '50%',
            height: '8px',
            width: '8px',
            marginRight: '8px',
            backgroundColor: value === 'true' ? COLORS.GREEN100 : COLORS.GREY60,
          }}
        />
        <Typography variant="body1">
          {value === 'true' ? 'Active' : 'Inactive'}
        </Typography>
      </Box>
    );
  };

  return (
    <StyledGridContainer container spacing={0}>
      <Grid
        item
        xs={4}
        alignItems={'center'}
        style={{ display: 'flex' }}
        direction="row"
      >
        <StyledFormControlLabel
          control={
            <Checkbox
              name={`use${fieldRow.fieldName}`}
              icon={
                <Icon icon={ICONS.Empty} size={24} color={COLORS.LIGHT_GREY} />
              }
              color="default"
              checked={selectedFields.includes(fieldRow.fieldName)}
              onChange={handleCheckBoxClick}
              checkedIcon={
                <Icon
                  icon={ICONS.Selected}
                  size={24}
                  color={COLORS.SYMPHONY_BLUE}
                />
              }
            />
          }
          label={fieldRow.label}
        />
      </Grid>
      <Grid item xs={8} justifyContent="flex-start">
        <StyledFormControl>
          {fieldRow.fieldType === 'button' && (
            <Select
              value={showStatus ? 'true' : 'false'}
              variant="outlined"
              style={{ width: '110px' }}
              disabled={!selectedFields.includes(fieldRow.fieldName)}
              onChange={(event) =>
                onChangeStatus(event.target.value === 'true')
              }
              renderValue={(selected) =>
                selected ? renderSelectedValue(selected as string) : ''
              }
            >
              <MenuItem value={'true'}>
                <ListItemText style={{ marginLeft: '9px' }}>
                  Active
                </ListItemText>
              </MenuItem>
              <MenuItem value={'false'}>
                <ListItemText style={{ marginLeft: '9px' }}>
                  Inactive
                </ListItemText>
              </MenuItem>
            </Select>
          )}
          {fieldRow.fieldType === 'select' && (
            <MultiEditSelect
              selectType={fieldRow.selectType}
              handleChange={handleChange}
              fieldName={fieldRow.fieldName}
              defaultValue={fieldRow.defaultValue}
              selectValues={fieldRow?.selectValues}
              disabled={!selectedFields.includes(fieldRow.fieldName)}
              handleMultiSelectChange={handleMultiSelectChange}
              handlePcpAutoCompleteChange={handlePcpAutoCompleteChange}
              handleSuggestItems={handleSuggestItems}
              enabledFields={fieldRow?.enabledFields}
              validationRequired={fieldRow?.validationRequired}
              hasValidationError={fieldRow?.hasValidationError}
            />
          )}
          {fieldRow.fieldType === 'textField' && (
            <StyledFormControl>
              <StyledTextField
                id={fieldRow.id}
                type="text"
                {...register(`${fieldRow.fieldName}` as const)}
                onChange={handleChange}
                size="small"
                disabled={!selectedFields.includes(fieldRow.fieldName)}
                defaultValue={fieldRow.defaultValue}
                multiline={fieldRow.multiline ?? false}
                minRows={fieldRow.multiline ? 3 : 1}
                fullWidth={true}
                variant="outlined"
              />
            </StyledFormControl>
          )}
        </StyledFormControl>
      </Grid>
    </StyledGridContainer>
  );
};

export default MultiEditFormRow;
