import React from 'react';
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const ProgressBox = styled(Box)({
  paddingTop: '10px',
});

const ErrorMessage = styled(Typography)({
  paddingTop: '10px',
  color: COLORS.RED100,
});

const ErrorLinearProgress = withStyles({
  bar: {
    backgroundColor: COLORS.RED100,
  },
})(LinearProgress);

const PercentageText = styled(Typography)({
  paddingTop: '10px',
  color: COLORS.GREY60,
});

const LinearProgressPercentage = (
  props: LinearProgressProps & {
    value: number;
    errors?: { id: number; message: string }[];
  }
) => {
  const { value, errors } = props;

  return (
    <>
      {errors?.length ? (
        <ProgressBox>
          <ErrorLinearProgress variant="determinate" value={value} />
          {errors?.map((error) => (
            <ErrorMessage key={error.id} variant="h6">
              {error.message}
            </ErrorMessage>
          ))}
        </ProgressBox>
      ) : (
        <ProgressBox>
          <LinearProgress variant="determinate" {...props} />
          <PercentageText variant="h6">{`${value}%`}</PercentageText>
        </ProgressBox>
      )}
    </>
  );
};

export default LinearProgressPercentage;
