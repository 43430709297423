import { IDataResult, IRow } from 'store/home/types';

export const isFaxRowSelected = (
  gridData: IDataResult,
  selection: (string | number)[]
) => {
  const selectedRow = getSelectedRow(gridData, selection[0]);

  return (
    selection?.length === 1 &&
    (selectedRow?.patientId === undefined ||
      selectedRow?.patientId === null ||
      selectedRow?.patientId <= 0)
  );
};

export const hasEpisode = (
  gridData: IDataResult,
  selection: (string | number)[]
) => {
  const episodeIds = [] as number[];
  selection?.forEach((select) => {
    const obj = getSelectedRow(gridData, select);
    if (obj?.episodeId) {
      episodeIds.push(obj.episodeId);
    }
  });
  return episodeIds.length > 0;
};

export const getSelectedRow = (
  gridData: IDataResult,
  selection: string | number
) => {
  const selectedRow = gridData.rows[selection as number] as IRow;
  return selectedRow != undefined ? selectedRow : null;
};
