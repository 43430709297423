import React from 'react';
import {
  ComputedValueComponent,
  ComputedValueType,
} from 'graphql/graphqlTypes';
import Timer from './Timer';
import { StyledReviewTypography } from '../styles';

export interface IComputedValueProps {
  component: ComputedValueComponent;
}

const ComputedValue = (props: IComputedValueProps) => {
  const { component } = props;

  if (component.computedValueType === ComputedValueType.Basicscoring) {
    return (
      <StyledReviewTypography variant="body1">
        {component.value}
      </StyledReviewTypography>
    );
  } else if (component.computedValueType === ComputedValueType.Timer) {
    return <Timer component={component} />;
  }
  return null;
};

export default ComputedValue;
