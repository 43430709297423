import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWorkflowState, IWorkflowStepAction } from './types';

export const initialWorkflowState: IWorkflowState = {
  step: 0,
  name: '',
};

const workflowSlice = createSlice({
  name: 'workflow',
  initialState: initialWorkflowState,
  reducers: {
    setWorkflowStep(state, action: PayloadAction<IWorkflowStepAction>) {
      const step = action.payload.step;
      const name = action.payload.name;
      state.step = step ? step : 0;
      state.name = name ? name : '';
    },
  },
});

export const { setWorkflowStep } = workflowSlice.actions;

export default workflowSlice.reducer;
