import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type CreateChecklistMutationVariables = Types.Exact<{
  data: Types.CreateChecklistModelInput;
}>;

export type CreateChecklistMutation = {
  __typename?: 'Mutation';
  createChecklist?: {
    __typename?: 'CreateChecklistResultModel';
    status?: Types.CreateChecklistStatus | null;
    checklistId?: number | null;
  } | null;
};

export const CreateChecklistDocument = `
    mutation createChecklist($data: CreateChecklistModelInput!) {
  createChecklist(data: $data) {
    status
    checklistId
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createChecklist: build.mutation<
      CreateChecklistMutation,
      CreateChecklistMutationVariables
    >({
      query: (variables) => ({ document: CreateChecklistDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateChecklistMutation } = injectedRtkApi;
