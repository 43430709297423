import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetTabModelQueryVariables = Types.Exact<{
  tabId: Types.Scalars['Int'];
}>;

export type GetTabModelQuery = {
  __typename?: 'EntitiesQuery';
  getTabModel?: {
    __typename?: 'HomeTabModel';
    episodeState?: number | null;
    showProgress?: boolean | null;
    columns?: Array<{
      __typename?: 'EntityListColumnDefinition';
      fullPropertyName?: string | null;
      displayName?: string | null;
      hasFilter?: boolean | null;
      canSort?: boolean | null;
      width?: number | null;
      sortOrder?: number | null;
      sortDirection?: Types.EnumSortDirection | null;
      filter?: {
        __typename?: 'EntityListColumnFilter';
        multiValues?: boolean | null;
      } | null;
      entityAttributeDef?: {
        __typename?: 'EntityAttributeDef';
        attributeType?:
          | { __typename?: 'BoolAttributeType'; type: string }
          | { __typename?: 'DateAttributeType'; type: string }
          | {
              __typename?: 'DateTimeAttributeType';
              type: string;
              displayFormat: string;
            }
          | { __typename?: 'ExpressionAttributeType' }
          | { __typename?: 'FloatAttributeType'; type: string }
          | { __typename?: 'IntAttributeType'; type: string }
          | { __typename?: 'LookupAttributeType'; type: string }
          | { __typename?: 'ReferenceAttributeType'; type: string }
          | { __typename?: 'TextAttributeType'; type: string }
          | null;
      } | null;
    } | null> | null;
    filterValues?: Array<{
      __typename?: 'Lookup';
      name?: string | null;
      values?: Array<{
        __typename?: 'LookupValue';
        id: number;
        name?: string | null;
        image?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export const GetTabModelDocument = `
    query getTabModel($tabId: Int!) {
  getTabModel(tabId: $tabId) {
    columns {
      fullPropertyName
      displayName
      hasFilter
      canSort
      filter {
        multiValues
      }
      entityAttributeDef {
        attributeType {
          ... on TextAttributeType {
            type
          }
          ... on IntAttributeType {
            type
          }
          ... on FloatAttributeType {
            type
          }
          ... on BoolAttributeType {
            type
          }
          ... on DateTimeAttributeType {
            type
            displayFormat
          }
          ... on DateAttributeType {
            type
          }
          ... on LookupAttributeType {
            type
          }
          ... on ReferenceAttributeType {
            type
          }
        }
      }
      width
      sortOrder
      sortDirection
    }
    filterValues {
      name
      values {
        id
        name
        image
      }
    }
    episodeState
    showProgress
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTabModel: build.query<GetTabModelQuery, GetTabModelQueryVariables>({
      query: (variables) => ({ document: GetTabModelDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTabModelQuery, useLazyGetTabModelQuery } = injectedRtkApi;
