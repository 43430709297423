import { Sorting } from '@devexpress/dx-react-grid';
import { PatientEditTagsTabViewModel } from 'graphql/graphqlTypes';

export const MEMBER_TAGS_COLUMNS = [
  {
    name: 'icon',
    title: 'Icon',
  },
  {
    name: 'name',
    title: 'Name',
  },
  {
    name: 'description',
    title: 'Description',
  },
  {
    name: 'type',
    title: 'Type',
  },
  {
    name: 'active',
    title: 'Active',
    getCellValue: (row: PatientEditTagsTabViewModel) => row,
  },
];
export const MEMBER_TAGS_COLUMNS_EXTENSIONS = [
  { columnName: 'icon', sortingEnabled: false, width: 50 },
  { columnName: 'name', sortingEnabled: false, width: 250 },
  {
    columnName: 'description',
    sortingEnabled: false,
    width: 500,
  },
  { columnName: 'type', sortingEnabled: false, width: 200 },
  { columnName: 'active', sortingEnabled: false, width: 80 },
];

export const MEMBER_TAGS_DEFAULT_SORT: Sorting[] = [
  { columnName: 'sortOrder', direction: 'asc' },
];

export const compareOrder = (priorityA = 0, priorityB = 0): number => {
  if (priorityA === priorityB) {
    return 0;
  }
  return priorityA < priorityB ? -1 : 1;
};
