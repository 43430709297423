import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import { Typography } from '@mui/material';

const LetterSummaryCreatedOnFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => <Typography>{props.value && formatDateTime(props.value)}</Typography>;

export const LetterSummaryCreatedOnProvider = (
  props: DataTypeProviderProps
) => (
  <DataTypeProvider
    formatterComponent={LetterSummaryCreatedOnFormatter}
    {...props}
  />
);
