import React from 'react';
import Confirmation from './Confirmation';
interface IDiscardConfirmationModalProps {
  showModal: boolean;
  sectionName: string;
  handleConfirm: () => void;
  handleCancel: () => void;
}
export const DirtyConfirmationModal = ({
  showModal,
  sectionName,
  handleConfirm,
  handleCancel,
}: IDiscardConfirmationModalProps) => {
  return (
    <div data-cy="dirty-page-modal" data-testid="dirtyPageModal">
      <Confirmation
        open={showModal}
        title="Discard Changes"
        okButtonText="Yes"
        okEvent={handleConfirm}
        cancelEvent={handleCancel}
      >
        {sectionName !== ''
          ? `There are some unsaved changes in ${sectionName}. Are you sure you want to discard changes?`
          : 'Are you sure you want to discard changes?'}
      </Confirmation>
    </div>
  );
};
