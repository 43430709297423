import React from 'react';
import Typography from '@mui/material/Typography';
import { ChecklistItemSubheader } from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import { ChecklistStorageType } from 'store/actions/types';
import styled from 'styled-components';

const StyledTypography = styled(Typography)({
  color: COLORS.BLACK,
  letterSpacing: 0.15,
  lineHeight: '24px',
});

const StyledWrapper = styled.div<{ padding: string; background: string }>`
  justify-content: flex-start;
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.background};
`;

export interface IChecklistItemSubHeaderProps {
  item: ChecklistItemSubheader;
  storageType: ChecklistStorageType;
}

const SubHeader = (props: IChecklistItemSubHeaderProps) => {
  return (
    <StyledWrapper
      background={
        props.storageType === ChecklistStorageType.CAREPLAN
          ? 'inherit'
          : COLORS.GREY10
      }
      padding={
        props.storageType === ChecklistStorageType.CAREPLAN
          ? '12px 32px 12px 0px'
          : '12px 32px 12px 64px'
      }
    >
      <StyledTypography
        style={{
          paddingLeft:
            props.storageType === ChecklistStorageType.CAREPLAN ? '28px' : 0,
        }}
        variant="subtitle1"
      >
        {props.item.preview}
      </StyledTypography>
    </StyledWrapper>
  );
};

export default SubHeader;
