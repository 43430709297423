import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type MergeAttachmentsMutationVariables = Types.Exact<{
  attachmentIds?: Types.InputMaybe<
    Array<Types.Scalars['Int']> | Types.Scalars['Int']
  >;
  fileIds?: Types.InputMaybe<
    Array<Types.Scalars['String']> | Types.Scalars['String']
  >;
}>;

export type MergeAttachmentsMutation = {
  __typename?: 'Mutation';
  mergeAttachments?: {
    __typename?: 'MergeResult';
    filePath?: string | null;
    isError: boolean;
  } | null;
};

export const MergeAttachmentsDocument = `
    mutation mergeAttachments($attachmentIds: [Int!], $fileIds: [String!]) {
  mergeAttachments(attachmentIds: $attachmentIds, fileIds: $fileIds) {
    filePath
    isError
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    mergeAttachments: build.mutation<
      MergeAttachmentsMutation,
      MergeAttachmentsMutationVariables | void
    >({
      query: (variables) => ({ document: MergeAttachmentsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMergeAttachmentsMutation } = injectedRtkApi;
