import React from 'react';
import { styled, TableSortLabel } from '@mui/material';
import classNames from 'clsx';
import { TableHeaderRow } from '@devexpress/dx-react-grid';

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

const PREFIX = 'ResizingControl';
export const classes = {
  root: `${PREFIX}-root`,
  sortLabelRoot: `${PREFIX}-sortLabelRoot`,
  sortLabelRight: `${PREFIX}-sortLabelRight`,
  sortLabelActive: `${PREFIX}-sortLabelActive`,
};
const StyledDiv = styled('div')(() => ({
  [`&.${classes.root}`]: {
    width: '100%',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
  [`& .${classes.sortLabelRoot}`]: {
    maxWidth: '100%',
  },
  [`& .${classes.sortLabelRight}`]: {
    flexDirection: 'row-reverse',
  },
}));

const onClick = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any,
  onSort: (parameters: {
    direction?: 'asc' | 'desc' | null;
    keepOther?: boolean;
  }) => void
) => {
  const isActionKeyDown =
    e.code === ENTER_KEY_CODE || e.code === SPACE_KEY_CODE;
  const isMouseClick = e.code === undefined;

  const cancelSortingRelatedKey = e.metaKey || e.ctrlKey;
  const direction =
    (isMouseClick || isActionKeyDown) && cancelSortingRelatedKey
      ? null
      : undefined;
  const keepOther = e.shiftKey || cancelSortingRelatedKey;

  e.preventDefault();
  onSort({ direction, keepOther });
};

export const SortLabel = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  column,
  align,
  direction,
  children,
  onSort,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMessage,
  disabled,
  ...restProps
}: TableHeaderRow.SortLabelProps): JSX.Element => {
  if (disabled) {
    return children as JSX.Element;
  }
  return (
    <StyledDiv className={classes.root} {...restProps}>
      <TableSortLabel
        active={!!direction}
        direction={direction === null ? undefined : direction}
        onClick={(e) => onClick(e, onSort)}
        disabled={disabled}
        classes={{
          root: classNames({
            [classes.sortLabelRoot]: true,
            [classes.sortLabelRight]: align === 'right',
          }),
        }}
      >
        {children}
      </TableSortLabel>
    </StyledDiv>
  );
};
