import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetCasesTabDataQueryVariables = Types.Exact<{
  request: Types.PatientCasesFilterModelInput;
}>;

export type GetCasesTabDataQuery = {
  __typename?: 'EntitiesQuery';
  getCasesTabData: {
    __typename?: 'PatientCasesViewModel';
    pager?: {
      __typename?: 'PatientEpisodesTablePagerViewModel';
      currentPage?: number | null;
      pagesCount?: number | null;
      pageSize?: number | null;
      totalCount?: number | null;
    } | null;
    episodes?: Array<
      | { __typename?: 'AppealGrievViewModel' }
      | {
          __typename?: 'CaseEpisodeViewModel';
          id?: number | null;
          episodeNumber?: string | null;
          createdOn?: any | null;
          program?: string | null;
          careStage?: string | null;
          referralDate?: any | null;
          engagedDate?: any | null;
          anyProgress?: boolean | null;
          timeZone?: string | null;
          state?: Types.EpisodeState | null;
          owner?: string | null;
          topReminder?: {
            __typename?: 'BaseReminder';
            importance?: Types.ReminderImportance | null;
            daysOverdue: number;
            daysLeft: number;
          } | null;
          tat?: {
            __typename?: 'TatClockColumnViewModel';
            state?: Types.ClockState | null;
            dueDateTime?: any | null;
            timeRemaining?: string | null;
          } | null;
          counter?: {
            __typename?: 'CounterClockColumnViewModel';
            state?: Types.ClockState | null;
            startDateTime?: any | null;
            timeElapsed?: string | null;
          } | null;
        }
      | { __typename?: 'EpisodeViewModel' }
      | { __typename?: 'EpisodeWithMozartInfoViewModel' }
    > | null;
  };
};

export const GetCasesTabDataDocument = `
    query getCasesTabData($request: PatientCasesFilterModelInput!) {
  getCasesTabData(request: $request) {
    pager {
      currentPage
      pagesCount
      pageSize
      totalCount
    }
    episodes {
      ... on CaseEpisodeViewModel {
        id
        episodeNumber
        createdOn
        program
        careStage
        referralDate
        engagedDate
        anyProgress
        topReminder {
          importance
          daysOverdue
          daysLeft
        }
        timeZone
        tat {
          state
          dueDateTime
          timeRemaining
        }
        counter {
          state
          startDateTime
          timeElapsed
        }
        state
        owner
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCasesTabData: build.query<
      GetCasesTabDataQuery,
      GetCasesTabDataQueryVariables
    >({
      query: (variables) => ({ document: GetCasesTabDataDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCasesTabDataQuery, useLazyGetCasesTabDataQuery } =
  injectedRtkApi;
