import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useDispatch } from 'react-redux';
import { useNewDefaultReminder } from 'components/home/RemindersGrid/RemindersGrid.hooks';
import {
  commitReminders,
  updatePatientEpisodeRemindersRows,
  setIsInEditMode,
} from 'store/patientdetails/patientDetailsSlice';
import Icon, { ICONS } from 'components/icon';
import { RemindersGrid } from 'components/home/RemindersGrid';
import { IState } from 'store';
import { useData } from 'components/gridFormatters';
import { getAvailableId } from 'components/home/attachmentsList/attachments.helpers';
import { BaseReminder } from 'graphql/graphqlTypes';
import {
  GetEpisodeRemindersQuery,
  useLazyGetEpisodeRemindersQuery,
} from 'graphql/hooks/getEpisodeReminders';
import Loader from 'components/loader';

interface IRemindersSubTabProps {
  episodeId: number;
  readonly: boolean;
}

const RemindersSubTab = ({ episodeId, readonly }: IRemindersSubTabProps) => {
  const [editingRowIds, setEditingRowIds] = useState<number[]>([]);
  const dispatch = useDispatch();
  const newDefaultReminder = useNewDefaultReminder();
  const { data: episodeReminders, isFetching } = useData(
    (state: IState) => state.patientDetails.episodeTab[episodeId]?.reminders,
    useLazyGetEpisodeRemindersQuery,
    { episodeId },
    (data: GetEpisodeRemindersQuery) =>
      updatePatientEpisodeRemindersRows({
        episodeId: episodeId,
        reminders: data.getEpisodeReminders as BaseReminder[],
      }),
    !episodeId
  );

  const handleAddReminder = () => {
    const newId = getAvailableId(episodeReminders, (x) => x.id);
    dispatch(
      commitReminders({
        episodeId: episodeId,
        reminders: {
          added: [{ ...newDefaultReminder, id: newId }],
        },
      })
    );
    setEditingRowIds([newId]);
  };

  const openedReminders =
    episodeReminders?.filter((item) => !item.isCompleted || !item.isDeleted) ??
    [];

  const isInEditMode = editingRowIds.length !== 0;

  useEffect(() => {
    dispatch(setIsInEditMode({ isInEditMode: isInEditMode }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRowIds]);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="16px"
      >
        <Typography variant="subtitle1">Episode Reminders</Typography>
        {!readonly ? (
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={isInEditMode}
            startIcon={<Icon icon={ICONS.Add} size={14} color={COLORS.WHITE} />}
            onClick={handleAddReminder}
          >
            Add Reminder
          </Button>
        ) : null}
      </Box>
      <RemindersGrid
        reminders={openedReminders}
        isDisabled={isInEditMode}
        readonly={readonly}
        isActionsDisabled={readonly}
        episodeId={episodeId}
        showEpisodeIdColumn={false}
        editingRowIds={editingRowIds}
        setEditingRowIds={setEditingRowIds}
      />
      <Loader active={isFetching} />
    </Box>
  );
};

export default RemindersSubTab;
