import DetailsSimpleTextProvider from 'components/home/grid/providers/DetailsSimpleTextProvider';
import React from 'react';
import {
  Grid as TableGrid,
  Table,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import DetailRowHeaderCellComponent from 'components/home/grid/DetailRowHeaderCellComponent';
import DetailRowCellComponent from 'components/home/grid/DetailRowCellComponent';
import { DetailsTableComponent } from 'components/home/grid/DetailsTableComponent';
import SeamlessDataTypeProvider from './SeamlessDataTypeProvider';
import { ProviderSearchResultRow } from 'graphql/graphqlTypes';

const RowDetail = (props: TableRowDetail.ContentProps) => {
  const columns = [
    { name: 'empty', title: ' ' },
    { name: 'taxId', title: 'Tax ID' },
    { name: 'practiceName', title: 'Practice Name' },
    { name: 'addressId', title: 'Address ID' },
    {
      name: 'dPG',
      title: 'DPG',
      getCellValue: (row: ProviderSearchResultRow) => row?.dPG?.name,
    },
    { name: 'seamless', title: 'Seamless' },
    { name: 'parNotes', title: 'PAR Notes' },
  ];

  const columnsExtension = [
    { columnName: 'empty', sortingEnabled: false, width: 76 },
    { columnName: 'taxId', sortingEnabled: false, width: 120 },
    { columnName: 'practiceName', sortingEnabled: false, width: 220 },
    { columnName: 'addressId', sortingEnabled: false, width: 100 },
    { columnName: 'dPG', sortingEnabled: false, width: 100 },
    { columnName: 'seamless', sortingEnabled: false, width: 100 },
    { columnName: 'parNotes', sortingEnabled: false },
  ];

  return (
    <TableGrid columns={columns} rows={[props.row]}>
      <DetailsSimpleTextProvider
        for={['taxId', 'practiceName', 'addressId', 'parNotes', 'dPG']}
      />
      <SeamlessDataTypeProvider for={['seamless']} />
      <Table
        tableComponent={DetailsTableComponent}
        columnExtensions={columnsExtension}
        cellComponent={DetailRowCellComponent}
      />
      <TableHeaderRow cellComponent={DetailRowHeaderCellComponent} />
    </TableGrid>
  );
};

export default RowDetail;
