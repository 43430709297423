import React from 'react';
import UserFreeText from '../UserFreeText';
import DateComp from '../Date';
import DateTimeComp from '../DateTime';
import { ATTRIBUTE_TYPE } from 'backend/types/entityAttributeDef';
import LookupAttribute from './LookupAttribute';
import ReferenceAttribute from './ReferenceAttribute';
import BoolAttribute from './BoolAttribute';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { getFreeTextComponent } from 'components/actions/sections/SectionBody/Components/EntityAttribute';
import { ChecklistStorageType } from 'store/actions/types';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import {
  AttributeType,
  EntityAttributeComponent,
  LookupAttributeType,
  ReferenceAttributeType,
} from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { formatDate, formatDateTime } from 'util/helpers/dateTimeHelpers';
import { DATETIME_FORMAT, DATETIME_SECONDS_FORMAT } from 'components/constants';

export interface IEntityAttributeProps {
  component: EntityAttributeComponent;
  bigTableValues: IEntityLookup[];
  storageType: ChecklistStorageType;
  checklistId?: string;
  entityLookups: IEntityLookup[];
  attributeTypes: AttributeType[];
  lookups: ILookup[];
}

const renderDate = (component: EntityAttributeComponent) => {
  if (component.readonly) {
    return (
      <StyledTypography variant="body1" $color={component.color ?? undefined}>
        {formatDate(component.value)}
      </StyledTypography>
    );
  } else {
    return (
      <DateComp
        component={{
          componentType: 'DateComponent',
          color: component.color,
          displayOnlyWhenSelected: component.displayOnlyWhenSelected,
          id: component.id,
          required: component.required,
          style: component.style,
          uniqueID: component.uniqueID,
          userDefinedId: component.userDefinedId,
          value: component.value,
          width: component.width,
          useCurrentDate: component.useCurrentDate || false,
          pastDatesNotAllowed: false,
          daysBeforeLimit: component.daysBeforeLimit,
          daysAfterLimit: component.daysAfterLimit,
        }}
      />
    );
  }
};

const renderDateTime = (component: EntityAttributeComponent) => {
  if (component.readonly) {
    return (
      <StyledTypography variant="body1" $color={component.color ?? undefined}>
        {formatDateTime(
          component.value,
          component.useSeconds ? DATETIME_SECONDS_FORMAT : DATETIME_FORMAT
        )}
      </StyledTypography>
    );
  } else {
    return (
      <DateTimeComp
        component={{
          componentType: 'DateTimeComponent',
          color: component.color,
          displayOnlyWhenSelected: component.displayOnlyWhenSelected,
          id: component.id,
          required: component.required,
          style: component.style,
          uniqueID: component.uniqueID,
          userDefinedId: component.userDefinedId,
          value: component.value,
          width: component.width,
          useSeconds: component.useSeconds,
          useCurrentDate: component.useCurrentDate || false,
          pastDatesNotAllowed: false,
          daysBeforeLimit: component.daysBeforeLimit,
          daysAfterLimit: component.daysAfterLimit,
        }}
      />
    );
  }
};

const StyledTypography = styled(Typography)<{
  $isMultiline?: boolean;
  $color?: string;
}>`
  color: ${({ $color }) => $color ?? COLORS.BLACK};
  font-weight: 700;
  display: ${(props) => (props.$isMultiline ? 'inline-block' : 'flex')};
  white-space: ${(props) => (props.$isMultiline ? 'break-spaces' : 'normal')};
`;

const EntityAttribute = (props: IEntityAttributeProps) => {
  const { component, bigTableValues, entityLookups, attributeTypes, lookups } =
    props;

  const attributeType = attributeTypes?.find(
    (attr) => attr.fullName === component.fieldName
  );

  if (!attributeType) {
    return null;
  }

  const color = component.color ?? undefined;
  if (attributeType?.type === ATTRIBUTE_TYPE.LOOKUP) {
    const lookupAttributeType = attributeType as LookupAttributeType;
    return (
      <LookupAttribute
        component={component}
        lookups={lookups}
        attributeType={lookupAttributeType}
      />
    );
  }
  if (attributeType?.type === ATTRIBUTE_TYPE.REFERENCE) {
    return (
      <ReferenceAttribute
        component={component}
        entityLookups={entityLookups}
        bigTableValues={bigTableValues}
        attributeType={attributeType as ReferenceAttributeType}
      />
    );
  }
  if (attributeType?.type === ATTRIBUTE_TYPE.TEXT) {
    return (
      <StyledTypography
        $isMultiline={component.multiline}
        variant="body1"
        $color={color}
      >
        {component.value}
      </StyledTypography>
    );
  }
  if (attributeType?.type === ATTRIBUTE_TYPE.BOOL) {
    return <BoolAttribute component={component} />;
  }
  if (attributeType?.dataType === 'date') {
    return renderDate(component);
  }
  if (attributeType?.dataType === 'datetime') {
    return renderDateTime(component);
  }

  if (component.readonly) {
    return (
      <StyledTypography variant="body1" $color={color}>
        {!component.value ? 'N/A' : component.value}
      </StyledTypography>
    );
  }
  const freeTextComponent = getFreeTextComponent(attributeType, component);

  return <UserFreeText component={freeTextComponent} />;
};
export default EntityAttribute;
