import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { ChangeEvent } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface ITimePickerProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  error?: boolean;
  isReadOnly?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  onBlur?: (event: ChangeEvent) => void;
  testId?: string;
  id?: string;
  name?: string;
  required?: boolean;
  timeFormat: string;
  useSeconds?: boolean;
  color?: string;
}

const StyledKeyboardTimePicker = styled(TimePicker)`
  width: 130px;
  .MuiOutlinedInput-adornedEnd {
    padding-right: 4px;
  }
`;

const TimeComponent = (props: ITimePickerProps) => {
  const {
    value,
    onChange,
    error,
    isReadOnly,
    autoFocus,
    placeholder,
    testId,
    id,
    name,
    required,
    timeFormat,
    onBlur,
    useSeconds,
    color,
  } = props;
  const textfieldProps: TextFieldProps & { 'data-testid': string | undefined } =
    {
      id: id,
      name: name,
      style: { backgroundColor: COLORS.WHITE },
      required: required,
      disabled: isReadOnly ?? false,
      autoFocus: autoFocus ?? false,
      onBlur: onBlur,
      error: error,
      'data-testid': testId,
      InputProps: {
        sx: color ? { color: color } : undefined,
        endAdornment: (
          <InputAdornment position="end">
            <Icon
              style={{ pointerEvents: 'none' }}
              icon={ICONS.Clock}
              size={14}
              color={COLORS.GREY100}
            />
          </InputAdornment>
        ),
      },
      inputProps: {
        placeholder: placeholder,
        style: { backgroundColor: COLORS.WHITE, paddingRight: 0 },
      },
    };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledKeyboardTimePicker
        value={value ? dayjs(value) : null}
        onChange={(newValue) =>
          onChange(newValue ? dayjs(newValue as string).toDate() : null)
        }
        ampm={false}
        format={timeFormat}
        disableOpenPicker
        views={
          useSeconds ? ['hours', 'minutes', 'seconds'] : ['minutes', 'seconds']
        }
        slotProps={{
          textField: textfieldProps,
        }}
      />
    </LocalizationProvider>
  );
};

export default TimeComponent;
