import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { Checkbox } from '@mui/material';

const InCarePlanFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const onChange = (id: number, state: boolean) => {
    props.value.changeInCarePlan(id, state);
  };

  return (
    <Checkbox
      disabled={props.value.isReadOnly}
      icon={<Icon icon={ICONS.Empty} size={24} color={COLORS.LIGHT_GREY} />}
      checked={props.value.inCarePlan}
      onChange={(event) => onChange(props.value.id, event.target.checked)}
      checkedIcon={
        <Icon icon={ICONS.Selected} size={24} color={COLORS.SYMPHONY_BLUE} />
      }
    />
  );
};

const InCarePlanProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={InCarePlanFormatter} {...props} />
);

export default InCarePlanProvider;
