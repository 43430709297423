import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';

const MemberNoteReasonFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  return <Typography>{props.value}</Typography>;
};

export default MemberNoteReasonFormatter;
