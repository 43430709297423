import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientQuickNotesQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientQuickNotesQuery = {
  __typename?: 'EntitiesQuery';
  getPatientQuickNotes?: Array<{
    __typename?: 'EpisodesTabChecklist';
    operations?: Array<Types.ChecklistOperation | null> | null;
    checklist?: {
      __typename?: 'Checklist';
      id: number;
      createdOn?: any | null;
      status?: Types.ChecklistStatus | null;
      createdByUser?: { __typename?: 'User'; fullName?: string | null } | null;
      reasonForNote?: {
        __typename?: 'LookupValue';
        name?: string | null;
      } | null;
    } | null;
    checklistOrderSets?: Array<{
      __typename?: 'ChecklistOrderSetRowView';
      backcolor?: string | null;
      details?: string | null;
      forecolor?: string | null;
      name?: string | null;
      title?: string | null;
    } | null> | null;
  } | null> | null;
};

export const GetPatientQuickNotesDocument = `
    query getPatientQuickNotes($patientId: Int!) {
  getPatientQuickNotes(patientId: $patientId) {
    checklist {
      id
      createdByUser {
        fullName
      }
      createdOn
      reasonForNote {
        name
      }
      status
    }
    operations
    checklistOrderSets {
      backcolor
      details
      forecolor
      name
      title
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientQuickNotes: build.query<
      GetPatientQuickNotesQuery,
      GetPatientQuickNotesQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientQuickNotesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientQuickNotesQuery,
  useLazyGetPatientQuickNotesQuery,
} = injectedRtkApi;
