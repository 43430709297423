import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography, styled } from '@mui/material';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const StyledTypography = styled(Typography)({
  wordWrap: 'break-word',
  whiteSpace: 'normal',
});

const SimpleTextFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <CustomTooltip title={props.value ?? ''}>
    <StyledTypography>{props.value}</StyledTypography>
  </CustomTooltip>
);

export default SimpleTextFormatter;
