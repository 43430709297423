import { Divider } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

export const StyledFlexDiv = styled.div`
  display: flex;
  gap: 20px;
`;

export const StyledDivider = styled(Divider)`
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0;
  height: 25px;
  color: ${COLORS.GREY25};
`;
