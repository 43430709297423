import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { NavBarLinkStatus } from '../navbar/NavbarLink';
import {
  BACKGROUND_COLOR,
  COLOR,
  getBorderFromStatus,
  getDimensions,
  getStatus,
  isValidationBubbleVisible,
} from './sectionNumber.helpers';
import { ChecklistCategory } from 'graphql/graphqlTypes';
import { useSectionNumberStatus } from 'components/actions/sectionNumber/sectionNumber.hooks';
import { ChecklistStorageType } from 'store/actions/types';

interface ISectionNumberProps {
  number: number;
  status?: NavBarLinkStatus;
  hovered?: boolean;
  size?: 'medium' | 'large';
  categoryId: string;
  isReadOnly: boolean;
  hideNumber?: boolean;
  category: ChecklistCategory;
  storageType: ChecklistStorageType;
}

interface IStyledTypographyProps {
  $textColor: string;
  $backgroundColor: string;
  $status?: NavBarLinkStatus;
  $size?: 'medium' | 'large' | null;
  $hovered?: boolean;
  $visited?: boolean;
}

const StyledValidationBubble = styled.span<{ $background: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  top: -5px;
  left: 14px;
  ${({ $background }) => `
      background-color: ${$background};
  `}
`;

const StyledTypography = styled(Typography)<IStyledTypographyProps>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 8px;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
  position: relative;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $textColor }) => $textColor};
  ${({ $status }) => `
      border: ${getBorderFromStatus($status)};
  `}
  ${({ $size, $status }) => `
      width: ${getDimensions($status, $size ?? 'medium')};
      height: ${getDimensions($status, $size ?? 'medium')};
      min-height: ${getDimensions($status, $size ?? 'medium')};
      min-width: ${getDimensions($status, $size ?? 'medium')};
  `}
  ${({ $hovered }) =>
    $hovered &&
    `
      background-color: ${$hovered && COLORS.GREY25};
  `}
`;

const SectionNumber = (props: ISectionNumberProps) => {
  const {
    number,
    status,
    hovered,
    size,
    categoryId,
    isReadOnly,
    hideNumber,
    category,
    storageType,
  } = props;

  const {
    isVisited,
    isInvalid,
    hasValidationRules,
    isReview,
    currentNavigationStep,
    showErrorBar,
  } = useSectionNumberStatus(category, categoryId, storageType);

  const currentStatus = getStatus({
    currentNavigationStep,
    number,
    isVisited,
    isInvalid,
    isReadOnly,
    status: status ?? null,
    showErrorBar,
    isReview,
    storageType,
  });

  const bubbleBackgroundColor = isInvalid
    ? COLORS.RED100
    : COLORS.COMPLETED_GREEN;

  const renderValidationBubble = () => {
    return isValidationBubbleVisible({
      status,
      storageType,
      hasValidationRules,
      isReview,
    }) ? (
      <StyledValidationBubble
        data-testid="validation-bubble"
        $background={bubbleBackgroundColor}
      />
    ) : null;
  };

  return (
    <StyledTypography
      $textColor={COLOR[currentStatus]}
      $backgroundColor={BACKGROUND_COLOR[currentStatus]}
      $hovered={hovered && status === 'remaining'}
      $status={currentStatus}
      data-testid="section-number"
      $size={size}
    >
      {!hideNumber ? number : null}
      {renderValidationBubble()}
    </StyledTypography>
  );
};

export default SectionNumber;
