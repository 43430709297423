import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import ActionLink from 'components/actionlink';

const LetterSummaryActionsFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const { row } = props;
  const isNewFile = row.id < 0;
  if (isNewFile) {
    return null;
  }

  return (
    <ActionLink
      key={`AttachmentView${row.id}`}
      type="AttachmentView"
      entityId={Number(row.id)}
      fileName={row.name}
      label="View/Print"
    />
  );
};

export const LetterSummaryActionsProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={LetterSummaryActionsFormatter}
    {...props}
  />
);
