import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { styled } from '@mui/styles';

export type ComponentStyle =
  | 'order-sentence-small'
  | 'order-sentence-medium'
  | 'order-sentence-wide'
  | 'order-sentence-custom';

export interface IChecklistComponentProps {
  style?: ComponentStyle;
  width?: number;
  className?: string;
}

interface IComponentWrapperProps {
  className?: string;
  children: ReactElement<IChecklistComponentProps>;
}

export const ComponentWrapper = ({
  className,
  children,
}: IComponentWrapperProps) => (
  <children.type
    {...children.props}
    className={clsx(children.props.className, className)}
  />
);

export const ChecklistComponent = styled(ComponentWrapper)({
  width: ({
    children: {
      props: { style, width },
    },
  }: IComponentWrapperProps) =>
    style
      ? {
          'order-sentence-small': '100px !important',
          'order-sentence-medium': '200px !important',
          'order-sentence-wide': '350px !important',
          'order-sentence-custom': `${width}px !important`,
        }[style]
      : 'auto',
});
