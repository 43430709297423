import { Dispatch } from 'redux';

import {
  clearAfterSave,
  closePatientDetailsModal as ClearPatientDetails,
  clearMedicationsList,
} from './patientDetailsSlice';
import {
  clearAttachments,
  IPatientDetailsAttachmentState,
} from './patientDetails.attachment.slice';
import { Attachment, AttachmentDtoInput } from 'graphql/graphqlTypes';
import { closePatientDetailsModal as ClearCarePlan } from './patientDetails.carePlan.slice';
import { closePatientDetailsModal as ClearCareTeam } from './patientDetails.careTeam.slice';
import { IPatientDetailsState } from './types';
import { clearPrintDocumentStates } from 'store/ui/print/print.helper';

export const closePatientDetailsDialog = (dispatch: Dispatch) => {
  dispatch(ClearPatientDetails());
  dispatch(clearAttachments());
  dispatch(ClearCarePlan());
  dispatch(ClearCareTeam());
  clearPrintDocumentStates(dispatch);
};

export const clearPatientDetailsDialog = (dispatch: Dispatch) => {
  dispatch(clearAfterSave());
  dispatch(clearAttachments());
  dispatch(ClearCarePlan());
  dispatch(ClearCareTeam());
};

export const clearMedications = (dispatch: Dispatch) => {
  dispatch(clearMedicationsList());
};

export const mapAttachmentToInputDto = (
  row: Attachment
): AttachmentDtoInput => {
  return {
    attachmentId: row.id,
    attachmentIsDeleted: row.isDeleted ?? false,
    attachmentName: row?.name ?? '',
    attachmentPathTemp: row?.location ?? '',
    attachmentCategoryId: row?.attachmentCategory?.id,
  };
};

export const setDefaultAttachmentState = (
  state: IPatientDetailsAttachmentState,
  patientId: number,
  episodeId?: number
) => {
  if (!state.updatedAttachments[patientId]) {
    state.updatedAttachments[patientId] = {
      attachments: [],
      episodeAttachments: {},
    };
  }
  if (episodeId) {
    if (!state.episodeTab[episodeId]) {
      state.episodeTab[episodeId] = [];
    }
    if (!state.updatedAttachments[patientId].episodeAttachments[episodeId]) {
      state.updatedAttachments[patientId].episodeAttachments[episodeId] = [];
    }
  } else {
    if (!state.attachmentsTab) {
      state.attachmentsTab = [];
    }
  }
};
export const setDefaultRemindersState = (
  state: IPatientDetailsState,
  episodeId?: number
) => {
  if (episodeId) {
    if (!state.episodeTab[episodeId]) {
      state.episodeTab[episodeId] = {};
    }
    if (!state.episodeTab[episodeId].reminders) {
      state.episodeTab[episodeId].reminders = [];
    }
  }
  if (!state.updatedReminders) {
    state.updatedReminders = { episodeReminders: [], memberReminders: [] };
  }
  if (!state.memberReminders) {
    state.memberReminders = [];
  }
};

export const removeEpisodeReminder = (
  state: IPatientDetailsState,
  episodeId: number,
  deletedId: number
) => {
  state.episodeTab[episodeId].reminders = state.episodeTab[
    episodeId
  ].reminders!.filter((x) => x.id !== deletedId);
  const episodeReminders = state.updatedReminders.episodeReminders.find(
    (x) => x.episodeId === episodeId
  );
  episodeReminders!.reminders = episodeReminders!.reminders.filter(
    (x) => x!.reminderId !== deletedId
  );
};

export const removeMemberReminder = (
  state: IPatientDetailsState,
  deleteId: number
) => {
  state.memberReminders = state.memberReminders!.filter(
    (x) => x.id !== deleteId
  );
  const memberReminders = state.updatedReminders.memberReminders.find(
    (x) => x.patientId === state.patientId
  );
  memberReminders!.reminders = memberReminders!.reminders.filter(
    (x) => x!.reminderId !== deleteId
  );
};

export const getOrAddUpdatedEpisodeReminder = (
  state: IPatientDetailsState,
  episodeId: number
) => {
  const episodeReminders = state.updatedReminders.episodeReminders.find(
    (x) => x.episodeId === episodeId
  );
  if (!episodeReminders) {
    const result = {
      episodeId,
      patientId: 0,
      reminders: [],
    };
    state.updatedReminders.episodeReminders.push(result);

    return result;
  }
  return episodeReminders;
};

export const getOrAddUpdatedMemberReminder = (state: IPatientDetailsState) => {
  const memberReminders = state.updatedReminders.memberReminders.find(
    (x) => x.patientId === state.patientId
  );
  if (!memberReminders) {
    const result = {
      patientId: state.patientId,
      episodeId: 0,
      reminders: [],
    };
    state.updatedReminders.memberReminders.push(result);

    return result;
  }
  return memberReminders;
};
