import { isPlainObject } from '@reduxjs/toolkit';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { DocumentNode } from 'graphql';
import { GraphQLClient, ClientError, Variables } from 'graphql-request';
import type {
  ErrorResponse,
  GraphqlRequestBaseQueryArgs,
  PrepareHeaders,
  RequestHeaders,
} from './GraphqlBaseQueryTypes';

const stripUndefinedHeaders = (obj: Headers) => {
  const toRemove: string[] = [];
  obj.forEach((v, k) => {
    if (typeof v === 'undefined') {
      toRemove.push(k);
    }
  });
  for (const k of toRemove) {
    obj.delete(k);
  }
  return obj;
};

const stripUndefinedArray = (arr: string[][]) => {
  const result: [string, string][] = [];
  for (const [k, v] of arr) {
    if (typeof v !== 'undefined') {
      result.push([k, v]);
    }
  }
  return result;
};

const stripUndefined = (
  obj: Headers | string[][] | Record<string, string> | undefined
) => {
  if (!isPlainObject(obj)) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return stripUndefinedArray(obj);
  }

  if (obj instanceof Headers) {
    return stripUndefinedHeaders(obj);
  }
  const copy: Record<string, string> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (typeof v === 'undefined') {
      delete copy[k];
    }
  }
  return copy;
};

export const graphqlRequestBaseQuery = <E = ErrorResponse>(
  options: GraphqlRequestBaseQueryArgs<E>
): BaseQueryFn<
  {
    document: string | DocumentNode;
    variables?: Variables | void;
  },
  unknown,
  E,
  Partial<Pick<ClientError, 'request' | 'response'>>
> => {
  const client =
    'client' in options ? options.client : new GraphQLClient(options.url);
  const requestHeaders: RequestHeaders =
    'requestHeaders' in options ? options.requestHeaders : {};

  return async (
    { document, variables },
    { getState, endpoint, forced, type, signal, extra }
  ) => {
    try {
      const prepareHeaders: PrepareHeaders =
        options.prepareHeaders ?? ((x) => x);
      const headers = new Headers(stripUndefined(requestHeaders));

      const preparedHeaders = await prepareHeaders(headers, {
        getState,
        endpoint,
        forced,
        type,
        extra,
      });

      return {
        data: await client.request({
          document,
          variables: variables as Variables,
          signal,
          requestHeaders: preparedHeaders,
        }),
        meta: {},
      };
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error;
        const errors = error.response.errors?.map((x) => ({
          message: x.message,
          extensions: x.extensions,
        }));

        const customErrors =
          options.customErrors ?? (() => ({ name, message, stack, errors }));

        const customizedErrors = customErrors(error) as E;

        return { error: customizedErrors, meta: { request, response } };
      } else if (error instanceof Error) {
        const { name, message, stack } = error;
        return {
          error: { name, message, stack } as E,
          meta: {},
        };
      }
      throw error;
    }
  };
};
