import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type LockEpisodeMutationVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type LockEpisodeMutation = {
  __typename?: 'Mutation';
  lockEpisode?: {
    __typename?: 'ActionEpisodeResult';
    success?: boolean | null;
    message?: string | null;
  } | null;
};

export const LockEpisodeDocument = `
    mutation lockEpisode($episodeId: Int!) {
  lockEpisode(episodeId: $episodeId) {
    success
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    lockEpisode: build.mutation<
      LockEpisodeMutation,
      LockEpisodeMutationVariables
    >({
      query: (variables) => ({ document: LockEpisodeDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLockEpisodeMutation } = injectedRtkApi;
