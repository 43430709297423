import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeTypesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetEpisodeTypesQuery = {
  __typename?: 'EntitiesQuery';
  getEpisodeTypes: Array<{
    __typename?: 'EpisodeType';
    id: number;
    name?: string | null;
  }>;
};

export const GetEpisodeTypesDocument = `
    query getEpisodeTypes {
  getEpisodeTypes {
    id
    name
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeTypes: build.query<
      GetEpisodeTypesQuery,
      GetEpisodeTypesQueryVariables | void
    >({
      query: (variables) => ({ document: GetEpisodeTypesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeTypesQuery, useLazyGetEpisodeTypesQuery } =
  injectedRtkApi;
