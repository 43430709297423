import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type CheckNewEpisodeQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type CheckNewEpisodeQuery = {
  __typename?: 'EntitiesQuery';
  checkNewEpisode: {
    __typename?: 'CheckNewEpisodeResult';
    status?: Types.CheckNewEpisodeStatus | null;
    message?: string | null;
  };
};

export const CheckNewEpisodeDocument = `
    query checkNewEpisode($patientId: Int!) {
  checkNewEpisode(patientId: $patientId) {
    status
    message
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkNewEpisode: build.query<
      CheckNewEpisodeQuery,
      CheckNewEpisodeQueryVariables
    >({
      query: (variables) => ({ document: CheckNewEpisodeDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCheckNewEpisodeQuery, useLazyCheckNewEpisodeQuery } =
  injectedRtkApi;
