import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';

const DetailsSimpleTextFormatter = (
  props: DataTypeProvider.ValueFormatterProps
) => <Typography style={{ color: COLORS.GREY100 }}>{props.value}</Typography>;

export default DetailsSimpleTextFormatter;
