import moment, { Moment } from 'moment';
import {
  BaseComponent,
  TimerDaysType,
  TimerScaleType,
  TimerValueType,
} from 'graphql/graphqlTypes';

export interface ITimerCalculationOperand {
  component: BaseComponent | null;
}

type OptionalTimerScaleType = TimerScaleType | null | undefined;
type OptionalTimerValueType = TimerValueType | null | undefined;
type OptionalTimerDaysType = TimerDaysType | null | undefined;
type OptionalNumber = number | null | undefined;
type OptionalBoolean = boolean | null | undefined;

const CALCULATION_ERROR = {
  value: Number.NaN,
  date: moment(''),
  isValid: false,
};

const toDuration = (timescale: OptionalTimerScaleType) => {
  if (timescale === TimerScaleType.Hours) {
    return 'hours';
  } else if (timescale === TimerScaleType.Days) {
    return 'days';
  }

  return undefined;
};

const workdayCount = (start: Moment, end: Moment) => {
  const first = start.clone().endOf('week'); // end of first week
  const last = end.clone().startOf('week'); // start of last week
  const days = (last.diff(first, 'days') * 5) / 7; // this will always multiply of 7

  let firstWeek = first.day() - start.day(); // check first week
  if (start.day() === 0) {
    --firstWeek; // -1 if start with sunday
  }

  let lastWeek = end.day() - last.day(); // check last week
  if (end.day() === 6) {
    --lastWeek; // -1 if end with saturday
  }

  let result = Math.trunc(firstWeek + days + lastWeek) - 1; // get the total
  if (result < 0) {
    result = 0;
  }
  return result;
};

const calculateCountdownDate = (
  dateB: moment.Moment,
  mode: OptionalTimerValueType,
  daysType: OptionalTimerDaysType,
  addValue: OptionalNumber,
  timescale: OptionalTimerScaleType
) => {
  if (mode === TimerValueType.Countdown && addValue) {
    if (daysType === TimerDaysType.All) {
      dateB = dateB.add(addValue, toDuration(timescale));
    } else {
      // business
      if (timescale === TimerScaleType.Hours) {
        addValue = Math.floor(addValue / 24);
      }
      dateB = dateB.businessAdd(addValue);
    }
  }
  return dateB;
};

const calculateResult = (
  dateFrom: Moment,
  dateTo: Moment,
  timescale: OptionalTimerScaleType,
  daysType: OptionalTimerDaysType
) => {
  if (daysType === TimerDaysType.All) {
    return dateTo.diff(dateFrom, toDuration(timescale));
  }

  let result = workdayCount(dateFrom, dateTo);
  if (timescale === TimerScaleType.Hours) {
    result = result * 24 + dateTo.get('hours') - dateFrom.get('hours');
  }

  return result;
};

const calculateValue = (
  dateA: Moment,
  dateB: Moment,
  mode: OptionalTimerValueType,
  daysType: OptionalTimerDaysType,
  timescale: OptionalTimerScaleType
) => {
  if (mode === TimerValueType.Elapsed) {
    if (dateA.isAfter(dateB)) {
      return calculateResult(dateB, dateA, timescale, daysType);
    }
  } else {
    if (dateB.isAfter(dateA)) {
      return calculateResult(dateA, dateB, timescale, daysType);
    }
  }

  return 0;
};

export const calculateTimerValue = (
  source: ITimerCalculationOperand | null,
  target: ITimerCalculationOperand | null,
  mode: OptionalTimerValueType,
  daysType: OptionalTimerDaysType,
  showDate: OptionalBoolean,
  addValue: OptionalNumber,
  timescale: OptionalTimerScaleType
) => {
  let dateA: Moment | undefined;
  if (source) {
    const sourceComponent = source.component;
    if (!sourceComponent?.value) {
      return CALCULATION_ERROR;
    }
    dateA = moment(sourceComponent.value);
  } else {
    dateA = moment();
  }

  let dateB: Moment | undefined;
  if (target) {
    const targetComponent = target.component;
    if (!targetComponent?.value) {
      return CALCULATION_ERROR;
    }
    dateB = moment(targetComponent.value);
  } else {
    return CALCULATION_ERROR;
  }

  if (!dateA || !dateA.isValid() || !dateB || !dateB.isValid()) {
    return CALCULATION_ERROR;
  }

  dateB = calculateCountdownDate(dateB, mode, daysType, addValue, timescale);

  return {
    value: calculateValue(dateA, dateB, mode, daysType, timescale),
    date: dateB,
    isValid: true,
  };
};
