import { ILabelCellProps, LabelCell } from 'components/gridFormatters';
import React from 'react';
import { providersColumn } from '../providers';
import { PrimaryCell } from './PrimaryCell';
import { SpecialityCell } from './SpecialityCell';
import { CareTeamLabelCell } from './CareTeamLabelCell';
import { ProviderCell } from './ProviderCell';

export const ProviderLabelCell = (props: ILabelCellProps) => {
  const { column } = props;

  switch (column.name) {
    case providersColumn.provider:
      return <ProviderCell {...props} />;
    case providersColumn.isPrimary:
      return <PrimaryCell {...props} />;
    case providersColumn.specialityName:
      return <SpecialityCell {...props} />;
    case providersColumn.note:
      return <CareTeamLabelCell {...props} />;
    default:
      return <LabelCell {...props} />;
  }
};
