import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ChangePasswordForm,
  EnterPhoneForm,
  LoginBody,
  LoginForm,
  PhoneVerifiedForm,
  VerifyCodeForm,
  PasswordResetForm,
} from './components';
import { RolesList } from 'components/roleslist';
import { IState } from 'store';
import { UpdateType } from 'store/user/types';
import SelectProviderForm from './components/SelectProviderForm';
import { useIdleTimer } from 'react-idle-timer';

const Login = () => {
  const isUserLoggedOut = useSelector(
    (state: IState) => state.user.isUserLoggedOut
  );

  useIdleTimer({
    timeout: 600000,
    onIdle: () => {
      window.location.reload();
    },
  });

  useEffect(() => {
    if (isUserLoggedOut) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const step = useSelector((state: IState) => state.user.step);
  const steps: { [key: string]: JSX.Element } = {
    start: <LoginForm />,
    roles: <RolesList />,
    changePassword: (
      <ChangePasswordForm updateType={UpdateType.ChangePassword} />
    ),
    enterPhone: <EnterPhoneForm />,
    verifyCode: <VerifyCodeForm />,
    phoneVerified: <PhoneVerifiedForm />,
    updatePassword: (
      <ChangePasswordForm updateType={UpdateType.UpdatePassword} />
    ),
    forgotPassword: <PasswordResetForm />,
    selectProvider: <SelectProviderForm />,
  };
  return <LoginBody step={step}>{steps[step]}</LoginBody>;
};

export default Login;
