import React from 'react';
import { Table, VirtualTable } from '@devexpress/dx-react-grid-material-ui';

export const RowComponent = (props: Table.DataRowProps) => (
  <VirtualTable.Row
    {...props}
    data-cy="table-row"
    style={{ verticalAlign: 'top' }}
  >
    {props.children}
  </VirtualTable.Row>
);
