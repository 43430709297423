import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IEpisodeSummaryState {
  isDirty: boolean;
  persistEditable: boolean;
  episodeId: number;
}

export const initialEpisodeSummaryState: IEpisodeSummaryState = {
  isDirty: false,
  episodeId: 0,
  persistEditable: false,
};

export interface EpisodeSummaryArgs {
  episodeId: number;
}

export interface EpisodeSummaryClearArgs {
  persistEditable?: boolean;
}

export const episodeSummarySlice = createSlice({
  name: 'episodeSummaryState',
  initialState: initialEpisodeSummaryState,
  reducers: {
    updateEpisodeSummaryDirtyState: (
      state,
      { payload: { episodeId } }: PayloadAction<EpisodeSummaryArgs>
    ) => {
      state.isDirty = true;
      state.episodeId = episodeId;
    },
    clearEpisodeSummaryDirtyState: (
      state,
      { payload }: PayloadAction<EpisodeSummaryClearArgs | undefined>
    ) => {
      const { persistEditable = false } = payload || {};
      return {
        ...initialEpisodeSummaryState,
        persistEditable,
      };
    },
  },
});

export const { updateEpisodeSummaryDirtyState, clearEpisodeSummaryDirtyState } =
  episodeSummarySlice.actions;
export default episodeSummarySlice.reducer;
