import React from 'react';
import { useDispatch } from 'react-redux';
import { openAttachmentFax } from 'store/ui/modals/letters';
import StyledLink from './StyledLink';
import { IAttachmentLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IAttachmentFaxLinkProps extends IAttachmentLinkProps {
  patientId?: number;
  episodeId?: number;
  isDisabled: boolean;
}

const AttachmentFaxLink = (props: IAttachmentFaxLinkProps) => {
  const dispatch = useDispatch();

  const onAttachmentFax = () => {
    dispatch(
      openAttachmentFax({
        patientId: props.patientId ?? 0,
        episodeId: props.episodeId ?? 0,
        attachmentId: props.attachmentId,
      })
    );
  };

  return (
    <CustomTooltip title={props.title ?? 'Send a Fax of the document'}>
      <StyledLink
        onClick={onAttachmentFax}
        data-cy="Fax"
        $isDisabled={props.isDisabled}
      >
        Fax
      </StyledLink>
    </CustomTooltip>
  );
};

export default AttachmentFaxLink;
