import axiosGitHubGraphQL from 'util/axiosHttp';

export const errorHandlerUrl = 'LogHandler/Log';

enum Severity {
  Information = 'information',
  Warning = 'warning',
  Error = 'error',
}

const getSeverity = (errorMessage: string) => {
  if (errorMessage === 'Network Error') {
    return Severity.Warning;
  }
  return Severity.Error;
};

export const errorHandler = (
  place: string,
  errorMessage: string,
  stackTrace: string
) => {
  axiosGitHubGraphQL
    .post(errorHandlerUrl, {
      Url: window.location.href,
      ErrorMessage: errorMessage,
      StackTrace: stackTrace,
      Place: place,
      Severity: getSeverity(errorMessage),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(
        `Failed to store error: ${errorMessage}. Failed with: ${err}`
      );
    });
};
