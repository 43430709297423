import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetEpisodeTypeQueryVariables = Types.Exact<{
  episodeId: Types.Scalars['Int'];
}>;

export type GetEpisodeTypeQuery = {
  __typename?: 'EntitiesQuery';
  getEpisode?: {
    __typename?: 'Episode';
    type?: { __typename?: 'EpisodeType'; name?: string | null } | null;
  } | null;
};

export const GetEpisodeTypeDocument = `
    query getEpisodeType($episodeId: Int!) {
  getEpisode(episodeId: $episodeId) {
    type {
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEpisodeType: build.query<
      GetEpisodeTypeQuery,
      GetEpisodeTypeQueryVariables
    >({
      query: (variables) => ({ document: GetEpisodeTypeDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEpisodeTypeQuery, useLazyGetEpisodeTypeQuery } =
  injectedRtkApi;
