import React, { useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import ReminderLabel from 'components/reminder';
import { DueDateTimeType, ReminderComponent } from 'graphql/graphqlTypes';
import { IComponentProps } from '../types';
import styled from 'styled-components';
import ReminderUser from './ReminderUser';
import ReminderDate from './ReminderDate';
import ReminderNote from './ReminderNote';
import ReminderReason from './ReminderReason';
import { ChecklistStorageType } from 'store/actions/types';
import ReminderDateTime from './ReminderDateTime';

export interface IReminderProps extends IComponentProps<ReminderComponent> {
  disabled?: boolean;
  autoSave: boolean;
  isReadOnly: boolean;
}

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
`;

export const StyledColumn = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
`;

const StyledContainer = styled(Grid)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: initial;
`;

const Reminder = (props: IReminderProps) => {
  const {
    component,
    handleChecklistInputChange,
    disabled,
    isActionSelected,
    categoryId,
    storageType,
    autoSave,
    isReadOnly,
  } = props;

  const [showComment, changeShowComment] = useState<boolean>(
    (component.comment || '').length > 0 ?? false
  );

  const handleReminderInputChange = (
    value: string | Date | null,
    reminderComponentType: string
  ) => {
    if (handleChecklistInputChange) {
      handleChecklistInputChange(
        value ? value.toString() : '',
        component,
        `${component.id}-${reminderComponentType}`
      );
    }
  };

  return (
    <div data-cy="checklist-reminder-component">
      <StyledContainer container>
        <ReminderLabel
          importanceIdSelected={component.importanceIdSelected?.toString()}
          showExtendedTooltip
        />
        <StyledWrapper style={{ marginLeft: '20px' }}>
          <Typography
            variant="body1"
            style={{
              color: COLORS.BLACK,
            }}
          >
            {storageType !== ChecklistStorageType.MOZART && component.notes}
          </Typography>
          {!disabled && (
            <IconButton
              edge="end"
              style={{
                display: showComment ? 'none' : 'block',
                padding: '4px',
              }}
              title="add comment"
              onClick={() => {
                changeShowComment(true);
              }}
              size="large"
            >
              <Icon
                icon={ICONS.AddComment}
                size={20}
                color={COLORS.SYMPHONY_BLUE}
              />
            </IconButton>
          )}
        </StyledWrapper>
      </StyledContainer>
      <Grid container>
        <StyledColumn item xs={12}>
          <ReminderReason component={component} />
          <ReminderUser
            component={component}
            disabled={disabled}
            isActionSelected={isActionSelected}
            categoryId={categoryId}
            storageType={storageType}
            autoSave={autoSave}
            isReadOnly={isReadOnly}
            handleReminderInputChange={handleReminderInputChange}
          />

          {component.dueDateTimeType === DueDateTimeType.Date ? (
            <ReminderDate
              component={component}
              disabled={disabled}
              isActionSelected={isActionSelected}
              categoryId={categoryId}
              storageType={storageType}
              autoSave={autoSave}
              isReadOnly={isReadOnly}
              handleReminderInputChange={handleReminderInputChange}
            />
          ) : (
            <ReminderDateTime
              component={component}
              disabled={disabled}
              isActionSelected={isActionSelected}
              categoryId={categoryId}
              storageType={storageType}
              autoSave={autoSave}
              isReadOnly={isReadOnly}
              handleReminderInputChange={handleReminderInputChange}
            />
          )}
        </StyledColumn>
      </Grid>
      <ReminderNote
        component={component}
        disabled={disabled}
        isReadOnly={isReadOnly}
        handleReminderInputChange={handleReminderInputChange}
        showComment={showComment}
        changeShowComment={changeShowComment}
      />
    </div>
  );
};

export default Reminder;
