import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetMozartWorkFlowActionDataQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type GetMozartWorkFlowActionDataQuery = {
  __typename?: 'EntitiesQuery';
  getMozartWorkFlowActionData?: {
    __typename?: 'WorkFlowActionDataResult';
    result: boolean;
    data?: Array<{
      __typename?: 'MozartWorkflowUIModel';
      id: number;
      uniqueMsgId: any;
      scopeId: any;
      workflowInstanceId: any;
      stepInstanceId: any;
      actionInstanceId: any;
      autoScrollToActionInstanceId?: any | null;
      checkListId: number;
      stepName?: string | null;
      createdBy?: string | null;
      createdOn?: any | null;
      updatedBy?: string | null;
      updatedOn?: any | null;
      postEntityDiffLocationURI?: string | null;
      state: Types.MozartProgressState;
      actionType: Types.MozartActionType;
      type: Types.MozartDefinitionType;
      roles?: Array<number> | null;
      executionNote?: string | null;
      rightHead?: string | null;
      leftHead?: string | null;
      showMessageKey?: string | null;
      showMessageValue?: string | null;
      retryCount?: number | null;
    } | null> | null;
  } | null;
};

export const GetMozartWorkFlowActionDataDocument = `
    query getMozartWorkFlowActionData($id: UUID!) {
  getMozartWorkFlowActionData(id: $id) {
    result
    data {
      id
      uniqueMsgId
      scopeId
      workflowInstanceId
      stepInstanceId
      actionInstanceId
      autoScrollToActionInstanceId
      checkListId
      stepName
      createdBy
      createdOn
      updatedBy
      updatedOn
      postEntityDiffLocationURI
      state
      actionType
      type
      roles
      executionNote
      rightHead
      leftHead
      showMessageKey
      showMessageValue
      retryCount
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMozartWorkFlowActionData: build.query<
      GetMozartWorkFlowActionDataQuery,
      GetMozartWorkFlowActionDataQueryVariables
    >({
      query: (variables) => ({
        document: GetMozartWorkFlowActionDataDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetMozartWorkFlowActionDataQuery,
  useLazyGetMozartWorkFlowActionDataQuery,
} = injectedRtkApi;
