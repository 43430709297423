import { ILookupValue, LookupValue } from 'graphql/graphqlTypes';
import { DeterminationDecision } from '../types';
import React, { forwardRef } from 'react';
import { RequiredReason } from './RequiredReason';
import SelectTextField from './SelectTextField';
import { Box } from '@mui/material';

interface IReasonSelectProps {
  determinationReasons: { [p: string]: ILookupValue[] };
  groupDecision: DeterminationDecision;
  width?: string;
  value?: LookupValue | null;
  updateValue: (reason: LookupValue | null) => void;
  showLabel?: boolean;
  readOnly?: boolean;
  isRequired?: boolean;
  error?: boolean;
}

const ReasonSelect = forwardRef<HTMLInputElement | null, IReasonSelectProps>(
  (props, ref) => {
    const {
      determinationReasons,
      groupDecision,
      width,
      value,
      updateValue,
      readOnly,
      isRequired,
      error,
    } = props;
    const changeReason = (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      const valueId = Number(e.target.value);
      const reasonValue =
        valueId > 0
          ? determinationReasons[groupDecision]?.find(
              (x) => x.id === valueId
            ) ?? null
          : null;
      updateValue(reasonValue as LookupValue);
    };

    return (
      <Box display="flex" alignItems="center" gap="8px">
        {props.showLabel && (
          <RequiredReason isRequired={isRequired ?? false} showLabel={true} />
        )}
        <SelectTextField
          onChange={changeReason}
          ref={ref}
          readOnly={readOnly ?? false}
          menuItems={determinationReasons[groupDecision]}
          value={value?.id}
          width={width}
          error={error}
        />
      </Box>
    );
  }
);

ReasonSelect.displayName = 'ReasonSelect';

export default ReasonSelect;
