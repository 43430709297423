import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetDummyProviderQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
  authRole: Types.Scalars['String'];
}>;

export type GetDummyProviderQuery = {
  __typename?: 'EntitiesQuery';
  getDummyProvider?: {
    __typename?: 'DummyProvider';
    id?: string | null;
    providerAddressUiId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    providerId?: string | null;
    nPI?: string | null;
    addressId?: number | null;
    address?: string | null;
    par?: Types.FoundProviderStatus | null;
  } | null;
};

export const GetDummyProviderDocument = `
    query getDummyProvider($patientId: Int!, $authRole: String!) {
  getDummyProvider(patientId: $patientId, authRole: $authRole) {
    id
    providerAddressUiId
    firstName
    lastName
    providerId
    nPI
    addressId
    address
    par
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDummyProvider: build.query<
      GetDummyProviderQuery,
      GetDummyProviderQueryVariables
    >({
      query: (variables) => ({ document: GetDummyProviderDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetDummyProviderQuery, useLazyGetDummyProviderQuery } =
  injectedRtkApi;
