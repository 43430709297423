import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { Cookies } from 'react-cookie';
import { GraphQLClient } from 'graphql-request';
import { graphqlRequestBaseQuery } from './graphql-request-base-query';

const API_URL = `${process.env.REACT_APP_API_URL}/api/graphql`;

const middleware = <T extends RequestInit>(request: T) => {
  if (typeof request?.body === 'string') {
    const operationName = JSON.parse(request?.body).operationName;
    return {
      ...request,
      url: `${API_URL}?${operationName}`,
    };
  }
  return request;
};

const client = new GraphQLClient(
  API_URL,

  { requestMiddleware: middleware }
);
const cookies = new Cookies();
const token = cookies.get('.AspNetCore.Application.Id');
client.setHeader('authorization', `Bearer ${token}`);
client.setHeader('Content-Type', 'application/json');
client.setHeader('X-Requested-With', 'XMLHttpRequest');
client.setHeader('SYM-UI', 'new');

export default client;

// Review this once rtk has latest GraphQL client as a dependency
// Story for fixing this issue: https://jiradc.prod.advantasure.com/browse/SYM-17900
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const staggeredBaseQuery = retry(graphqlRequestBaseQuery({ client }), {
  maxRetries: 2,
});

export const api = createApi({
  baseQuery: staggeredBaseQuery,
  endpoints: () => ({}),
  keepUnusedDataFor: 5,
  refetchOnMountOrArgChange: false,
});
