import React from 'react';
import { Box, Button } from '@mui/material';
import { MedicationsGrid } from './MedicationsGrid';
import {
  medicationsClaimsColumns,
  medicationsClaimsColumnsExtensions,
} from './consts';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import {
  updateMedicationClaims,
  updateMedicationsList,
  updateSelectedMedicationItem,
} from 'store/patientdetails/patientDetailsSlice';
import { getAvailableId } from 'components/home/attachmentsList/attachments.helpers';
import {
  MedicationViewModel,
  PatientMedicationClaim,
  PatientMedicationStatus,
  PatientMedicationType,
} from 'graphql/graphqlTypes';

const MedicationsClaims = () => {
  const dispatch = useDispatch();
  const medicationClaims =
    useSelector(
      (state: IState) => state.patientDetails.medicationsTab.medicationsClaims
    ) ?? [];
  const medicationsList = useSelector(
    (state: IState) => state.patientDetails.medicationsTab.medicationsList
  );

  const selectedMedicationItems =
    useSelector(
      (state: IState) =>
        state.patientDetails.medicationsTab.selectedMedicationItems
    ) ?? [];

  const claimsToAdd = medicationClaims
    .filter((claim) => selectedMedicationItems.includes(claim.id!))
    .filter(
      (claim) =>
        !medicationsList?.some((m) => m.medication === claim.medication)
    );

  const handleTransferMedication = () => {
    if (!claimsToAdd.length) {
      return;
    }
    const newId = getAvailableId(medicationsList, (x) => x.id);
    const medicationsToAdd = claimsToAdd.map((claim, index) =>
      claimToMedication(claim, newId - index)
    );
    dispatch(
      updateMedicationsList({ isUpdate: true, medications: medicationsToAdd })
    );
    dispatch(
      updateMedicationClaims(
        medicationClaims.filter(
          (claim) => !selectedMedicationItems.includes(claim.id!)
        )
      )
    );
    dispatch(updateSelectedMedicationItem({ selectedActionType: '' }));
  };

  const claimToMedication = (
    input: PatientMedicationClaim,
    id: number
  ): MedicationViewModel => ({
    id: id,
    medication: input.medication,
    pharmacy: input.pharmacy,
    patientState: PatientMedicationStatus.Active,
    instructions: 'N/A',
    reason: 'N/A',
    note: 'N/A',
    provider: input.provider!,
    dateFilled: input.dispenseDate,
    quantityFilled: input.quantityDispensed?.toString(),
    type: PatientMedicationType.Prescription,
  });

  const medications =
    medicationClaims?.map((claim) => ({
      ...claim,
      prescribingProvider: claim.provider?.name,
    })) ?? [];

  return (
    <Box>
      <Box pb="10px">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleTransferMedication}
          style={{ marginRight: '10px' }}
          disabled={claimsToAdd.length === 0}
        >
          Transfer to Med List
        </Button>
      </Box>

      <Box>
        <MedicationsGrid
          columnExtensions={medicationsClaimsColumnsExtensions}
          columns={medicationsClaimsColumns}
          medications={medications}
          isMultiSelect
        />
      </Box>
    </Box>
  );
};

export default MedicationsClaims;
