import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Loader from 'components/loader';
import Confirmation from 'components/modal/Confirmation';
import { ForwardOwnerDialog } from './ForwardOwnerDialog';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { useDispatch } from 'react-redux';
import { forceUpdate } from 'store/home/action';
import { useDiscardEpisodeMutation } from 'graphql/hooks/discardEpisode';
import {
  AttachFromEpisodeToEpisodeMutation,
  useAttachFromEpisodeToEpisodeMutation,
} from 'graphql/hooks/attachFromEpisodeToEpisode';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { openAttachmentFax } from 'store/ui/modals/letters';
import {
  LockEpisodeMutation,
  useLockEpisodeMutation,
} from 'graphql/hooks/lockEpisode';
import { SerializedError } from '@reduxjs/toolkit';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';

const StyledButton = styled(Button)({
  minWidth: '150px',
});

const BlueTypography = styled(Typography)({
  color: COLORS.SYMPHONY_BLUE,
});

const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledDivider = styled(Divider)({
  height: '34px',
  margin: 0,
  marginRight: '6px',
});

interface ISearchPatientDialogActionsProps {
  episodeId: number;
  episodeAttachmentId: number;
  selectedPatientId: number;
  selectedEpisodeId: number;
  onClose: () => void;
  onNew: (patient: number, episode: number) => void;
}

export const SearchPatientDialogActions = ({
  onClose,
  onNew,
  episodeId,
  episodeAttachmentId,
  selectedPatientId,
  selectedEpisodeId,
}: ISearchPatientDialogActionsProps) => {
  const dispatch = useDispatch();
  const [discardEpisode, { isLoading: isDiscardEpisodeLoading }] =
    useDiscardEpisodeMutation();
  const canChangeOwner = hasUserPrivilege(Privilege.ChangeOwner);

  const [lockEpisode, { isLoading: isLockEpisodeLoading }] =
    useLockEpisodeMutation();

  const [
    attachFromEpisodeToEpisode,
    { isLoading: isAttachFromEpisodeToEpisodeLoading },
  ] = useAttachFromEpisodeToEpisodeMutation();

  const [showDiscardEpisodeConfirmation, setShowDiscardEpisodeConfirmation] =
    useState(false);

  const [showForwardEpisodeConfirmation, setShowForwardEpisodeConfirmation] =
    useState(false);

  const handleDelete = () => {
    discardEpisode({ episodeId: episodeId }).then(() => {
      onClose();
    });
  };

  const handleSelection = () => {
    if (selectedPatientId > 0) {
      lockEpisode({
        episodeId: episodeId,
      }).then(
        (
          result:
            | { data: LockEpisodeMutation }
            | { error: unknown | SerializedError }
        ) => {
          const responseData = result as { data: LockEpisodeMutation };
          if (responseData.data && responseData?.data?.lockEpisode?.success) {
            onClose();
            onNew(selectedPatientId, episodeId);
          } else {
            dispatch(
              showErrorPopup({
                title: 'Episode lock error',
                message:
                  responseData?.data?.lockEpisode?.message ??
                  'Failed to lock episode.',
              })
            );
          }
        }
      );
    }
  };

  const handleAttachToPatient = () => {
    if (selectedPatientId > 0) {
      attachFromEpisodeToEpisode({
        episodeId: episodeId,
        targetPatientId: selectedPatientId,
      }).then((result) => {
        if ('data' in result) {
          successCallback(result.data, true);
        } else if ('error' in result) {
          errorCallback(true);
        }
      });
    }
  };

  const handleAttachToEpisode = () => {
    if (selectedPatientId > 0 && selectedEpisodeId > 0) {
      attachFromEpisodeToEpisode({
        episodeId: episodeId,
        targetPatientId: selectedPatientId,
        targetEpisodeId: selectedEpisodeId,
      }).then((result) => {
        if ('data' in result) {
          successCallback(result.data, false);
        } else if ('error' in result) {
          errorCallback(false);
        }
      });
    }
  };

  const successCallback = (
    data: AttachFromEpisodeToEpisodeMutation,
    isAttachToMember: boolean
  ) => {
    const response = data?.attachFromEpisodeToEpisode;
    if (response?.success) {
      onClose();
      dispatch(forceUpdate(true));
    } else {
      dispatch(
        showErrorPopup({
          message:
            response?.message ??
            `Error occurred while attaching fax to ${
              isAttachToMember ? 'Member' : 'Episode'
            }.`,
        })
      );
    }
  };

  const errorCallback = (isAttachToMember: boolean) => {
    dispatch(
      showErrorPopup({
        message: `Error occurred while attaching fax to ${
          isAttachToMember ? 'Member' : 'Episode'
        }.`,
      })
    );
  };

  const handleCloseForwardOwnerDialog = (closeParentDialog: boolean) => {
    setShowForwardEpisodeConfirmation(false);
    if (closeParentDialog) {
      onClose();
      dispatch(forceUpdate(true));
    }
  };

  const handleOpenOutboundFax = () => {
    dispatch(
      openAttachmentFax({
        episodeId: episodeId ?? 0,
        patientId: Number(selectedPatientId ?? 0),
        attachmentId: episodeAttachmentId,
      })
    );
  };

  return (
    <>
      <div>
        <StyledButton
          onClick={onClose}
          color="primary"
          variant="outlined"
          data-cy="cancel"
          size="large"
        >
          <BlueTypography variant="h5">Cancel</BlueTypography>
        </StyledButton>
      </div>
      <StyledButtonsWrapper>
        <Button
          onClick={() => setShowDiscardEpisodeConfirmation(true)}
          size="large"
          color="primary"
          data-cy="delete-fax"
          title="Delete Fax"
        >
          <BlueTypography variant="h5">Delete Fax</BlueTypography>
        </Button>
        <Button
          onClick={() => handleOpenOutboundFax()}
          size="large"
          color="primary"
          data-cy="outbound-fax"
          title="Outbound Fax"
          data-testid="outbound-fax-button"
        >
          <BlueTypography variant="h5">Outbound Fax</BlueTypography>
        </Button>
        <Button
          onClick={() => setShowForwardEpisodeConfirmation(true)}
          size="large"
          color="primary"
          data-cy="forward"
          title={
            canChangeOwner
              ? 'Assign Episode'
              : "To make this action user should have 'ChangeOwner' privilege"
          }
          disabled={!canChangeOwner}
        >
          <BlueTypography variant="h5">Assign Episode</BlueTypography>
        </Button>
        <StyledDivider orientation="vertical" />
        <StyledButton
          onClick={() => handleAttachToPatient()}
          size="large"
          color="primary"
          variant="outlined"
          data-cy="attach-to-patient"
          disabled={selectedPatientId === 0}
          title="Attach to Member"
          data-testid="attach-to-member-button"
        >
          <Typography variant="h5">Attach to Member</Typography>
        </StyledButton>
        <StyledButton
          onClick={handleAttachToEpisode}
          size="large"
          color="primary"
          variant="outlined"
          data-cy="attach-to-episode"
          disabled={selectedPatientId === 0 || selectedEpisodeId === 0}
          title="Attach to Episode"
          data-testid="attach-to-episode-button"
        >
          <Typography variant="h5">Attach to Episode</Typography>
        </StyledButton>
        <StyledButton
          onClick={handleSelection}
          size="large"
          color="primary"
          variant="contained"
          data-cy="new-episode"
          disabled={selectedPatientId === 0}
          title="New Episode"
          data-testid="new-episode-button"
        >
          <Typography variant="h5">New Episode</Typography>
        </StyledButton>
      </StyledButtonsWrapper>
      <Confirmation
        open={showDiscardEpisodeConfirmation}
        title="Delete Fax?"
        okButtonText="Delete"
        okEvent={() => {
          handleDelete();
          setShowDiscardEpisodeConfirmation(false);
          dispatch(forceUpdate(true));
        }}
        cancelEvent={() => setShowDiscardEpisodeConfirmation(false)}
      >
        <span>Are you sure you want to delete this fax?</span>
      </Confirmation>
      <ForwardOwnerDialog
        open={showForwardEpisodeConfirmation}
        episodeId={episodeId}
        onClose={handleCloseForwardOwnerDialog}
      />
      <Loader
        active={
          isDiscardEpisodeLoading ||
          isAttachFromEpisodeToEpisodeLoading ||
          isLockEpisodeLoading
        }
      />
    </>
  );
};
