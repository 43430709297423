import React, { ReactNode } from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Asterisk } from 'components/text';

const StyledContainer = styled(Grid)({
  marginBottom: '12px',
});
const StyledCellValue = styled(Grid)({
  fontSize: '12px',
});
const StyledTitleCell = styled(Grid)({
  display: 'flex',
  justifyContent: 'right',
  fontSize: '14px',
});

export interface IUserLineProp {
  label?: string | null;
  isRequired?: boolean | null;
  children?: ReactNode;
  layoutType?: string | null;
}

const Line = (props: IUserLineProp) => {
  return (
    <StyledContainer container spacing={3} alignItems="center">
      <StyledTitleCell item xs={4}>
        {props.label}
        {props?.isRequired && <Asterisk />}
      </StyledTitleCell>
      <StyledCellValue item xs={8}>
        {props.children}
      </StyledCellValue>
    </StyledContainer>
  );
};
export default Line;
