import { COLORS } from 'consts/styles';
import { NavBarLinkStatus } from 'components/actions/navbar/NavbarLink';
import { ChecklistStorageType } from 'store/actions/types';

export const BACKGROUND_COLOR = {
  required: COLORS.WHITE,
  remaining: COLORS.GREY10,
  inProgress: COLORS.WHITE,
  error: COLORS.RED100,
  visited: COLORS.SYMPHONY_BLUE,
  done: COLORS.GREY100,
  readOnly: COLORS.GREY100,
  hover: COLORS.GREY20,
};

export const COLOR = {
  required: COLORS.RED100,
  remaining: COLORS.GREY100,
  inProgress: COLORS.BLACK,
  visited: COLORS.WHITE,
  error: COLORS.WHITE,
  done: COLORS.WHITE,
  readOnly: COLORS.WHITE,
  hover: COLORS.GREY100,
};

interface IStatusParams {
  currentNavigationStep: number | null;
  number: number;
  isVisited: boolean;
  isInvalid: boolean;
  isReadOnly: boolean;
  status: NavBarLinkStatus;
  showErrorBar: boolean;
  isReview: boolean;
  storageType?: ChecklistStorageType;
}

export const getStatus = (params: IStatusParams) => {
  const {
    currentNavigationStep,
    number,
    isVisited,
    isInvalid,
    isReadOnly,
    status,
    showErrorBar,
    isReview,
    storageType,
  } = params;
  if (storageType === ChecklistStorageType.VIEW) {
    return 'remaining';
  }
  if (isReview) {
    return 'done';
  }
  if (status) {
    return status;
  }
  if (isReadOnly) {
    return 'readOnly';
  }

  if (currentNavigationStep === number) {
    return 'inProgress';
  }
  if (showErrorBar && isInvalid) {
    return 'error';
  }

  if (currentNavigationStep !== number && isVisited) {
    return 'visited';
  }

  return 'remaining';
};

export const getBorderFromStatus = (status: NavBarLinkStatus | undefined) => {
  return status === 'inProgress' ? `1px solid ${COLORS.SYMPHONY_BLUE}` : 'none';
};

export const getDimensions = (
  status: NavBarLinkStatus | undefined,
  size: string
) => {
  if (status === 'inProgress' && size === 'medium') {
    return '19px';
  }
  if (status === 'inProgress' && size === 'large') {
    return '22px';
  }
  if (size === 'large') {
    return '24px';
  }
  return '21px';
};

export const isValidationBubbleVisible = ({
  status,
  storageType,
  hasValidationRules,
  isReview,
}: {
  status: NavBarLinkStatus | undefined;
  storageType: ChecklistStorageType;
  hasValidationRules: boolean;
  isReview: boolean;
}) => {
  if (status === 'done') {
    return false;
  }
  switch (storageType) {
    case ChecklistStorageType.MOZART:
      return true;
    case ChecklistStorageType.CHECKLIST:
    case ChecklistStorageType.CAREPLAN:
      return hasValidationRules && !isReview;
    default:
      return false;
  }
};
