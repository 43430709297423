export enum familyColumn {
  name = 'name',
  isCaregiver = 'isCaregiver',
  relationshipId = 'relationshipId',
  note = 'note',
  consentToShare = 'consentToShare',
}

export const editingStateFamilyColumnsExtension = [
  { columnName: familyColumn.name, width: '18%' },
  { columnName: familyColumn.isCaregiver, width: '6%' },
  { columnName: familyColumn.relationshipId, width: '18%' },
  { columnName: familyColumn.note },
  { columnName: familyColumn.consentToShare, width: '24%' },
];

export const familyColumns = () => {
  return [
    { name: familyColumn.name, title: 'Name' },
    { name: familyColumn.isCaregiver, title: 'Caregiver' },
    { name: familyColumn.relationshipId, title: 'Relationship' },
    { name: familyColumn.note, title: 'Note' },
    { name: familyColumn.consentToShare, title: 'Consent to Share' },
  ];
};
