import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { find, uniqBy } from 'lodash';
import { IState } from 'store';
import { IRole } from 'store/roles/types';
import { getUserRoles, setUserRole } from 'store/roles/middlewares';

import HeaderDialog from 'components/home/headerdialog';
import { RolesList } from 'components/roleslist';
import RolesButton from './RolesButton';
import CareSitesButton from './CareSitesButton';
import { ILookupValue } from 'backend/types/lookupValue';
import { useResetStateAfterRoleChange } from 'components/roleslist/useResetStateAfterRoleChange';
import { DashboardCareSite, DashboardCareSiteRole } from 'consts/roles';

export const RoleMenu = () => {
  const roleName = useSelector((state: IState) => state.user.currentUser.role);
  const roleId = useSelector((state: IState) => state.user.currentUser.roleId);
  const [openRole, setOpenRole] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorCareSiteEl, setAnchorCareSiteEl] = useState<null | HTMLElement>(
    null
  );
  const [careSiteMenuOpen, setCareSiteMenuOpen] = useState<boolean>(false);
  const [selectedCareSite, setSelectedCareSite] = useState<ILookupValue>();
  const [careSites, setCareSites] = useState<Array<ILookupValue>>([]);
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.userRoleId
  );
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const currentCareSiteName = useSelector(
    (state: IState) => state.user.currentUser.careSite
  );
  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);
  const roles = useSelector((state: IState) => state.roles.items);
  const primaryRoleId = useSelector(
    (state: IState) => state.roles.primaryRoleId
  );
  const user = useSelector((state: IState) => state.user.auth);
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );
  const displayRole = find(roles, { id: currentRoleId });
  const dispatch = useDispatch();
  const resetStateAfterRoleChange = useResetStateAfterRoleChange();

  const handleClick = (event: React.MouseEvent<HTMLElement>, type: string) => {
    if (type === 'role') {
      setAnchorEl(event.currentTarget);
      setMenuOpen(true);
    } else {
      setAnchorCareSiteEl(event.currentTarget);
      setCareSiteMenuOpen(true);
    }
  };

  useEffect(() => {
    if (roles.length > 0) {
      const careSitesFilter = roles
        .filter((r) => r.careSiteId)
        .map((item) => ({
          name: item.careSiteName,
          id: item.careSiteId,
        }));

      setCareSites(uniqBy(careSitesFilter, 'id'));
    }
  }, [roles, roleName]);

  useEffect(() => {
    if (!openRole && roleId === DashboardCareSiteRole.roleId) {
      setSelectedCareSite(DashboardCareSite);
    } else if (!openRole && currentCareSiteId !== selectedCareSite?.id) {
      setSelectedCareSite(
        currentCareSiteId && currentCareSiteName
          ? {
              id: currentCareSiteId,
              name: currentCareSiteName,
            }
          : undefined
      );
    }
  }, [
    currentCareSiteId,
    currentCareSiteName,
    currentRoleId,
    openRole,
    selectedCareSite?.id,
    roleName,
    roleId,
  ]);

  const handleClose = (type: string) => {
    if (type === 'role') {
      setAnchorEl(null);
      setMenuOpen(false);
    } else {
      setAnchorCareSiteEl(null);
      setCareSiteMenuOpen(false);
    }
  };

  useEffect(() => {
    roles.length === 0 && dispatch(getUserRoles(user, isLoggedIn));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoggedIn]);

  const handleClickCareSite = (careSite: ILookupValue) => {
    setSelectedCareSite(careSite);
    const dashboardAccessRole = find<IRole>(roles, {
      roleId: DashboardCareSiteRole.roleId,
      id: DashboardCareSiteRole.careSiteId,
    });
    const newRole =
      careSite?.id == DashboardCareSiteRole.careSiteId
        ? ({ id: dashboardAccessRole?.id ?? 0 } as IRole)
        : find<IRole>(roles, {
            careSiteId: careSite.id,
            displayName: displayRole?.displayName,
          });
    if (newRole && newRole.id !== currentRoleId) {
      dispatch(
        setUserRole(newRole.id, sessionId, user, primaryRoleId ?? 0, isLoggedIn)
      );
      resetStateAfterRoleChange();
    } else {
      setOpenRole(true);
    }
    setAnchorCareSiteEl(null);
    setCareSiteMenuOpen(false);
  };

  const closeRoleSelectDialog = () => {
    setOpenRole(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <CareSitesButton
        anchorCareSiteEl={anchorCareSiteEl}
        careSiteMenuOpen={careSiteMenuOpen}
        careSites={careSites}
        handleClick={handleClick}
        handleClickCareSite={handleClickCareSite}
        handleClose={handleClose}
        selectedCareSite={selectedCareSite}
      />
      {selectedCareSite?.id !== DashboardCareSiteRole.careSiteId && (
        <RolesButton
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          menuOpen={menuOpen}
          roles={roles}
          selectedCareSite={selectedCareSite}
          setAnchorEl={setAnchorEl}
          setMenuOpen={setMenuOpen}
          displayRole={displayRole}
        />
      )}
      <HeaderDialog open={openRole} onClose={closeRoleSelectDialog}>
        <RolesList
          padding="0"
          callback={closeRoleSelectDialog}
          selectedCareSiteId={selectedCareSite?.id}
        />
      </HeaderDialog>
    </Box>
  );
};
