import React, { ChangeEvent, useEffect, useState } from 'react';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';
import { debounce } from 'lodash';
import { Provider, ProviderSearchResultRow } from 'graphql/graphqlTypes';
import { useLazySearchProvidersQuery } from 'graphql/hooks/searchProviders';
import { StyledAutocomplete } from 'components/autocomplete/styles';
import { ProviderOption } from './ProviderOption';
import AutocompleteSearchInput from '../AutocompleteSearchInput';

export interface IProviderSearchProps {
  patientId: number;
  value: Provider | null;
  onChange: (value: Provider | null) => void;
}

const ProviderSearch = (props: IProviderSearchProps) => {
  const { patientId, value, onChange } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [options, setOptions] = useState<ProviderSearchResultRow[]>(
    [] as ProviderSearchResultRow[]
  );

  const [searchProviders, { data, isFetching: isProvidersFetching }] =
    useLazySearchProvidersQuery();

  useEffect(() => {
    if (isProvidersFetching) {
      setOptions([]);
    }
    if (data && !isProvidersFetching) {
      const loadedOptions = data.searchProvider
        ?.rows as ProviderSearchResultRow[];
      setOptions(loadedOptions);
    }
  }, [isProvidersFetching, data]);

  const handleSearchTermChange = debounce((term: string) => {
    if (term.length > 2) {
      searchProviders({
        request: {
          searchTerm: term,
          patientId,
          skipAddress: true,
        },
      });
    }
  }, 200);

  const handleInputChange = (
    _: ChangeEvent<unknown>,
    newTerm: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setSearchTerm(newTerm);
    if (reason === 'input') {
      handleSearchTermChange(newTerm);
    }
  };

  const isOptionEqualToValueInner = (option: unknown, value: unknown) => {
    return (
      Number((option as ProviderSearchResultRow)?.id) ===
      (value as Provider)?.id
    );
  };

  const onSelectionChange = (value: ProviderSearchResultRow | null) => {
    setOptions([]);
    onChange(value as unknown as Provider);
  };

  const getOptionLabel = (option: ProviderSearchResultRow): string => {
    return `${option.firstName} ${option.lastName} ${option.providerId} ${option.nPI}`;
  };

  const handleClickClear = () => {
    setSearchTerm('');
    onSelectionChange(null);
  };

  return (
    <StyledAutocomplete
      getOptionLabel={(option) =>
        getOptionLabel(option as ProviderSearchResultRow)
      }
      options={options}
      filterOptions={(x) => x}
      autoComplete
      forcePopupIcon={false}
      size="small"
      loading={isProvidersFetching}
      includeInputInList
      clearOnBlur={false}
      clearOnEscape={true}
      autoHighlight={options.length === 1}
      filterSelectedOptions
      isOptionEqualToValue={isOptionEqualToValueInner}
      inputValue={searchTerm}
      value={value}
      onKeyDown={(e) => e.key === 'Escape' && handleClickClear()}
      onInputChange={(
        event: ChangeEvent<unknown>,
        newTerm: string,
        reason: AutocompleteInputChangeReason
      ) => {
        handleInputChange(event, newTerm, reason);
      }}
      onChange={(_event, value) =>
        onSelectionChange(value as ProviderSearchResultRow)
      }
      renderOption={(params, option: unknown) => (
        <li {...params} style={{ padding: 0 }}>
          <ProviderOption {...(option as Provider)} />
        </li>
      )}
      renderInput={(params) => (
        <AutocompleteSearchInput
          inputParams={params}
          searchTerm={searchTerm}
          onClear={handleClickClear}
        />
      )}
    />
  );
};

export default ProviderSearch;
