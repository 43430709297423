import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import React from 'react';
import {
  HealthServiceCodesActionModel,
  HealthServiceCodesActionValue,
  ILookupValue,
} from 'graphql/graphqlTypes';
import {
  DeterminationDecision,
  HealthServiceAuthorizationType,
  UnitTypes,
} from './types';
import { healthServiceDisplayMode } from 'components/actions/sections/SectionBody/HealthServiceDisplayMode';

type decisionType = string | undefined | null;
const getDecisionIconByDisplayMode = (
  decision: decisionType,
  isOverallStatusApproved?: boolean,
  hsDisplayMode?: number
): decisionType => {
  if (hsDisplayMode === healthServiceDisplayMode.authOutcomeRollUp) {
    return isOverallStatusApproved ? 'Approved' : 'Pending';
  } else {
    return decision;
  }
};
export const getDecisionIcon = (
  decision: decisionType,
  isOverallStatusApproved?: boolean,
  hsDisplayMode?: number
) => {
  const finalDecision = getDecisionIconByDisplayMode(
    decision,
    isOverallStatusApproved,
    hsDisplayMode
  );
  switch (finalDecision) {
    case 'Approved':
      return (
        <Icon
          icon={ICONS.Stoplight_completed}
          size={18}
          color={COLORS.GREEN100}
          style={{ marginRight: '4px' }}
        />
      );
    case 'Denied':
      return (
        <Icon
          icon={ICONS.Denied}
          size={18}
          color={COLORS.RED100}
          style={{ marginRight: '4px' }}
        />
      );
    case null:
    case undefined:
      return null;
    default:
      return (
        <Icon
          icon={ICONS.Stoplight_incompleted}
          size={18}
          color={COLORS.YELLOW100}
          style={{ marginRight: '4px' }}
        />
      );
  }
};

export const getGridBackground = (
  decision: decisionType,
  isOverallStatusApproved?: boolean,
  hsDisplayMode?: number
): string => {
  const finalDecision = getDecisionIconByDisplayMode(
    decision,
    isOverallStatusApproved,
    hsDisplayMode
  );
  switch (finalDecision) {
    case 'Approved': {
      return COLORS.GREEN20;
    }
    case 'Denied': {
      return COLORS.RED20;
    }
    case null:
    case undefined:
      return COLORS.GREY10;
    default: {
      return COLORS.YELLOW20;
    }
  }
};

export const isOverallStatus = (
  item: HealthServiceCodesActionModel,
  matchStr: string
): boolean => {
  return item.healthServiceCodesActionValues.every(
    (e) => e?.healthServiceActionValue?.determinationDecision === matchStr
  );
};

export const overallStatus = (item: HealthServiceCodesActionModel): string => {
  if (isOverallStatus(item, 'Approved')) {
    return 'Approved';
  } else if (isOverallStatus(item, 'Denied')) {
    return 'Denied';
  } else if (isOverallStatus(item, 'Partial')) {
    return 'Partial';
  } else {
    return 'Pending';
  }
};

export const getGrouDecision = (
  item: HealthServiceCodesActionValue
): DeterminationDecision => {
  return (
    (item.healthServiceActionValue
      ?.determinationDecision as DeterminationDecision) ?? 'NonSet'
  );
};

export const getDefaultUnitType = (
  unitTypes: ILookupValue[],
  defaultUnitName: string | null | undefined
): ILookupValue | undefined =>
  unitTypes?.find((x) => x.name === defaultUnitName ?? UnitTypes.units);

export const getHsAuthorizationType = (
  hSAllowOptions: boolean,
  hsAuthorizationType: number
): boolean =>
  hsAuthorizationType === HealthServiceAuthorizationType.NonSet
    ? true
    : hSAllowOptions;

export const getHsAuthorizationTypRequestParam = (
  isDisplayEpisodeLevelRequestedParam: boolean,
  hsAuthorizationType: number,
  hsDisplayMode: number
): boolean =>
  (hsAuthorizationType == HealthServiceAuthorizationType.NonSet &&
    hsDisplayMode !== healthServiceDisplayMode.manualReview) ||
  isDisplayEpisodeLevelRequestedParam === true;
