import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import EpisodesDateFormatter from '../formatters/EpisodesDateFormatter';

const EpisodesDateProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={EpisodesDateFormatter} {...props} />
);

export default EpisodesDateProvider;
