import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import TagActiveDataTypeFormatter from './TagActiveFormatter';

interface TagActiveProviderProps extends DataTypeProviderProps {
  disabled: boolean;
}

const TagActiveProvider = ({ disabled, ...props }: TagActiveProviderProps) => {
  return (
    <DataTypeProvider
      formatterComponent={(p) => (
        <TagActiveDataTypeFormatter {...p} disabled={disabled} />
      )}
      {...props}
    />
  );
};

export default TagActiveProvider;
