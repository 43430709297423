import React from 'react';
import { Typography } from '@mui/material';
import { InputGroupBox } from './styles';
import { HealthServiceCodesActionModel } from 'graphql/graphqlTypes';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export const OverallHealthServiceReview = (props: {
  item: HealthServiceCodesActionModel;
  unitType: string | undefined;
}) => {
  const { item, unitType } = props;

  return (
    <>
      <InputGroupBox>
        <Typography variant="body1">Start Date:&nbsp;</Typography>
        <Typography variant="body2">
          {formatDate(item?.overallReqStartDate)}
        </Typography>
      </InputGroupBox>
      <InputGroupBox>
        <Typography variant="body1">End Date:&nbsp;</Typography>
        <Typography variant="body2">
          {formatDate(item?.overallReqEndDate)}
        </Typography>
      </InputGroupBox>
      <InputGroupBox>
        <Typography variant="body1">Unit Type:</Typography>
        <Typography variant="body2">{unitType}</Typography>
      </InputGroupBox>
      <InputGroupBox>
        <Typography variant="body1">Value:</Typography>
        <Typography variant="body2">{item?.totalReqUnits}</Typography>
      </InputGroupBox>
    </>
  );
};
