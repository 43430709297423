import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledReviewTypography = styled(Typography)<{
  $color?: string;
  $fontWeight?: number;
  $breakEnabled?: boolean;
}>(({ $color, $fontWeight, $breakEnabled }) => ({
  color: $color ?? COLORS.BLACK,
  fontWeight: $fontWeight ?? 700,
  ...($breakEnabled == null || $breakEnabled === true
    ? { whiteSpace: 'break-spaces' }
    : {}),
}));
