import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { IState } from 'store';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import FileUpload from 'components/fileUpload/fileUpload';
import Confirmation from 'components/modal/Confirmation';
import UploadFilesDialogActions from './uploadFilesDialogActions';

const StyledTypography = styled(Typography)({
  color: COLORS.WHITE,
});

const StyledDialogContent = styled(DialogContent)({
  backgroundColor: COLORS.PALE_GREY,
  padding: '24px 24px 48px 24px',
});

const StyledDialogActions = styled(DialogActions)({
  padding: '16px 24px',
  justifyContent: 'flex-end',
});

export interface UploadFilesDialogProps {
  open: boolean;
  onClose(): void;
  onAddFiles(): void;
}

const UploadFilesDialog = (props: UploadFilesDialogProps) => {
  const { open, onClose, onAddFiles } = props;

  const uploadedFileCount = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles?.length
  );
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleClose = useCallback(() => {
    if (uploadedFileCount) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  }, [uploadedFileCount, onClose]);

  const handleAddFiles = useCallback(() => {
    onAddFiles();
  }, [onAddFiles]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="uploadFile-dialog-title"
      open={open}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="uploadFile-dialog-title" onClose={handleClose}>
        <StyledTypography variant="subtitle1">Upload Files</StyledTypography>
      </DialogTitle>
      <StyledDialogContent dividers>
        <FileUpload allowMultiple />
        <Confirmation
          open={showConfirmation}
          title="Discard unsaved changes"
          okButtonText="Discard"
          okEvent={() => {
            setShowConfirmation(false);
            onClose();
          }}
          cancelEvent={() => setShowConfirmation(false)}
        >
          <span>Are you sure you want to discard unsaved changes?</span>
        </Confirmation>
      </StyledDialogContent>
      <StyledDialogActions>
        <UploadFilesDialogActions
          handleClose={handleClose}
          handleAddFiles={handleAddFiles}
        />
      </StyledDialogActions>
    </Dialog>
  );
};

export default UploadFilesDialog;
