import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import { AssociatedClaimAction } from 'components/actions/sections/SectionBody/Items/AssociatedClaim/AssociatedClaim.helper';
import ClaimSearchDialogBody from './components/ClaimSearchDialogBody';

export interface ClaimSearchDialogProps {
  patientId: number;
  open: boolean;
  onCantFind: () => void;
  onSelect: (selectedClaim: AssociatedClaimAction) => void;
  onClose: () => void;
}

const StyledButton = styled(Button)({
  minWidth: '150px',
  height: '40px',
  '&:hover': {
    minWidth: '150px',
    height: '40px',
  },
});

const StyledLink = styled('a')({
  cursor: 'pointer',
  fontWeight: 500,
  letterSpacing: '0.5px',
  color: COLORS.SYMPHONY_BLUE,
});

const ClaimSearchDialog = (props: ClaimSearchDialogProps) => {
  const { patientId, open, onClose, onSelect, onCantFind } = props;
  const [selectedClaim, setSelectedClaim] =
    useState<AssociatedClaimAction | null>(null);

  const handleClose = () => {
    setSelectedClaim(null);
    onClose();
  };

  const handleSelect = () => {
    if (selectedClaim) {
      onSelect(selectedClaim);
      handleClose();
    }
  };

  const handleCantFind = () => {
    onCantFind();
    handleClose();
  };

  return (
    <Dialog
      data-testid="claim-search-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{
            fontSize: '16px',
            color: COLORS.WHITE,
            paddingLeft: '12px',
          }}
        >
          Advanced Claim Search
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{
          height: '578px',
          overflowX: 'auto',
          padding: '24px 16px',
        }}
        dividers
      >
        <ClaimSearchDialogBody
          patientId={patientId}
          onSelect={setSelectedClaim}
        />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }}>
          <StyledLink onMouseDown={handleCantFind}>
            I can&apos;t find Claim
          </StyledLink>
        </div>
        <div>
          <StyledButton
            data-testid="claim-search-dialog-cancel-button"
            onClick={handleClose}
            color="primary"
            variant="outlined"
            data-cy="cancel"
            style={{ marginRight: '20px' }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handleSelect}
            disabled={selectedClaim === null}
            color="primary"
            variant="contained"
            data-cy="select"
          >
            Select
          </StyledButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ClaimSearchDialog;
