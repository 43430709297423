import { useGetRoleNamesQuery } from 'graphql/hooks/getRoleNames';
import React, { useEffect, useRef, useState } from 'react';
import { MozartActionSection } from './MozartActionSection';
import { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { mozartUserAuthorizedNextStep } from 'store/actions/mozartSlice';
import { MozartStep } from '../MozartStep/MozartStep';
import { useUserNotAuthorizedToStartChecklist } from '../MozartContent/MozartContent.helpers';

export const MozartDoneAction = ({
  isLoadingComplete,
}: {
  isLoadingComplete: boolean;
}) => {
  const dispatch = useDispatch();
  const refFinish = useRef<HTMLDivElement>(null);
  const [rolesNames, setRolesNames] = useState<string[]>();
  const mozartMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );

  const lastMessage = mozartMessages?.[mozartMessages.length - 1];

  const { userNotAuthorizedToStartChecklist, stepRoles } =
    useUserNotAuthorizedToStartChecklist(lastMessage);

  const { data: getRoleNames, isSuccess: isLoadingRolesSuccess } =
    useGetRoleNamesQuery(
      { ids: stepRoles },
      { skip: !stepRoles.length || !userNotAuthorizedToStartChecklist }
    );

  useEffect(() => {
    if (getRoleNames?.getRoles) {
      const roleNames =
        getRoleNames?.getRoles
          ?.map((x) => x?.displayName ?? '')
          .filter(Boolean) ?? [];
      setRolesNames(roleNames);
    }
  }, [getRoleNames]);

  useEffect(() => {
    setTimeout(() => {
      if (
        userNotAuthorizedToStartChecklist &&
        isLoadingComplete &&
        (isLoadingRolesSuccess || !stepRoles.length) &&
        refFinish.current
      ) {
        window.scrollTo({
          behavior: 'smooth',
          top: refFinish.current.offsetTop,
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNotAuthorizedToStartChecklist, isLoadingComplete]);

  useEffect(() => {
    dispatch(mozartUserAuthorizedNextStep(!userNotAuthorizedToStartChecklist));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNotAuthorizedToStartChecklist]);

  const actionText = rolesNames
    ? 'Next step will be performed by ' + rolesNames?.join(', ')
    : '';

  if (!userNotAuthorizedToStartChecklist) {
    return null;
  }

  return (
    <div ref={refFinish}>
      <MozartStep currentStep={lastMessage.leftHead ?? ''} />
      <MozartActionSection
        actionIcon={ICONS.ProgressCompleted}
        actionIconColor={COLORS.GREEN100}
        actionText={actionText}
        actionTitle={'Done!'}
        isDoneStep
      />
    </div>
  );
};
