import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientByIdQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientByIdQuery = {
  __typename?: 'EntitiesQuery';
  getPatientById?: {
    __typename?: 'Patient';
    id: number;
    fullName?: string | null;
    mRN: string;
    mRN2?: string | null;
    mRN3?: string | null;
    mRN4?: string | null;
    mRN5?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    dOB?: any | null;
    dOD?: any | null;
    email?: string | null;
    cellPhone?: string | null;
    homePhone?: string | null;
    alternatePhone?: string | null;
    alternatePhone2?: string | null;
    age?: number | null;
    guardian?: string | null;
    isActive?: boolean | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    medicationLastReviewedOn?: any | null;
    allergies?: string | null;
    preferredPhoneNumber?: string | null;
    preferredPhone?: Types.PreferredPhone | null;
    gender?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
    owner?: { __typename?: 'User'; id: number; name?: string | null } | null;
    medicationLastReviewedBy?: {
      __typename?: 'User';
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
    } | null;
    medicationClaims?: Array<{
      __typename?: 'PatientMedicationClaim';
      dispenseDate: any;
      medication?: string | null;
      pharmacy?: string | null;
      id: number;
      claimId?: string | null;
      paidAmount: any;
      quantityDispensed?: number | null;
      provider?: {
        __typename?: 'Provider';
        id: number;
        name?: string | null;
      } | null;
      billingProvider?: {
        __typename?: 'PatientMedicationClaimBillingProvider';
        address?: string | null;
        city?: string | null;
        phone?: string | null;
        zip?: string | null;
      } | null;
    } | null> | null;
    entityAttributes?: Array<{
      __typename?: 'EntityAttribute';
      name?: string | null;
      value?: string | null;
      lookup?: { __typename?: 'LookupValue'; id: number } | null;
    } | null> | null;
    primaryCareProvider?: {
      __typename?: 'Provider';
      id: number;
      name?: string | null;
    } | null;
    populations?: Array<{
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null> | null;
    careTeamMembers?: Array<{
      __typename?: 'PatientCareTeamMember';
      id: string;
      member: { __typename?: 'User'; fullName?: string | null };
    }> | null;
  } | null;
};

export const GetPatientByIdDocument = `
    query getPatientById($patientId: Int!) {
  getPatientById(id: $patientId) {
    id
    fullName
    mRN
    mRN2
    mRN3
    mRN4
    mRN5
    firstName
    lastName
    middleName
    dOB
    dOD
    email
    cellPhone
    homePhone
    alternatePhone
    alternatePhone2
    age
    gender {
      id
      name
    }
    owner {
      id
      name
    }
    guardian
    isActive
    address1
    address2
    city
    state
    zipCode
    medicationLastReviewedBy {
      firstName
      lastName
      fullName
    }
    medicationLastReviewedOn
    medicationClaims {
      dispenseDate
      medication
      pharmacy
      id
      claimId
      paidAmount
      quantityDispensed
      provider {
        id
        name
      }
      billingProvider {
        address
        city
        phone
        zip
      }
    }
    entityAttributes {
      name
      value
      lookup {
        id
      }
    }
    primaryCareProvider {
      id
      name
    }
    allergies
    populations {
      id
      name
    }
    preferredPhoneNumber
    preferredPhone
    careTeamMembers {
      id
      member {
        fullName
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientById: build.query<
      GetPatientByIdQuery,
      GetPatientByIdQueryVariables
    >({
      query: (variables) => ({ document: GetPatientByIdDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPatientByIdQuery, useLazyGetPatientByIdQuery } =
  injectedRtkApi;
