import React, { useState } from 'react';
import Snackbar from 'components/snackbar';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { Button } from '@mui/material';

export interface IClipboardProps {
  copyText: string;
  buttonLabel: string;
  snackBarTitle?: string;
  snackBarText?: string;
  icon?: ICONS;
  tooltip?: string;
}

const Clipboard = ({
  copyText,
  buttonLabel,
  snackBarTitle,
  snackBarText,
  icon,
  tooltip,
}: IClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard(copyText).then(() => {
      setIsCopied(true);
    });
  };
  return (
    <>
      <CustomTooltip title={tooltip ?? ''}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          data-testid="clipboard-button"
          startIcon={
            icon && (
              <Icon
                icon={icon}
                size={14}
                color={COLORS.SYMPHONY_BLUE}
                testId="clipboard-button-icon"
              />
            )
          }
          onClick={handleCopyClick}
        >
          {buttonLabel}
        </Button>
      </CustomTooltip>
      <Snackbar
        icon={ICONS.Checkmark}
        open={isCopied}
        onClose={() => setIsCopied(false)}
        duration={4000}
        title={snackBarTitle ?? ''}
        text={snackBarText ?? ''}
        type="success"
      />
    </>
  );
};
export default Clipboard;
