/* eslint-disable sonarjs/no-identical-functions */
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from 'consts/styles';
import { CLOCK_STATE, CLOCK_STATUS } from './consts';
import { useGetEpisodeTatQuery } from 'graphql/hooks/getEpisodeTat';
import { useGetEpisodeCounterQuery } from 'graphql/hooks/getEpisodeCounter';
import Loader from 'components/loader';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import { ClockEventType } from 'graphql/graphqlTypes';
import { ClockCounterType } from '../../consts';

export interface IEpisodeDetailsTabProps {
  episodeId: number;
  counterType: ClockCounterType;
}

function displayHistory(
  index: number,
  action: ClockEventType,
  fullName: string | null | undefined,
  createdOn: string | Date | null | undefined
): JSX.Element {
  return (
    <Box pb="12px" key={-index} display="flex">
      <Typography variant="body2" style={{ paddingRight: '4px' }}>
        {CLOCK_STATUS[action]}:
      </Typography>
      <Typography>{`${fullName} at ${formatDateTime(createdOn)}`}</Typography>
    </Box>
  );
}

function displayDriver(index: number, item: string): JSX.Element {
  return (
    <Typography key={-index} variant="body2">
      {item}
    </Typography>
  );
}

const ClockTab = (props: IEpisodeDetailsTabProps) => {
  const { episodeId, counterType } = props;

  const isTat = counterType === ClockCounterType.TAT;

  const { data: tatData, isFetching: tatIsFetching } = useGetEpisodeTatQuery(
    {
      episodeId: episodeId,
    },
    { refetchOnMountOrArgChange: true, skip: episodeId <= 0 }
  );
  const { data: counterData, isFetching: counterIsFetching } =
    useGetEpisodeCounterQuery(
      {
        episodeId: episodeId,
      },
      { refetchOnMountOrArgChange: true, skip: episodeId <= 0 }
    );

  return (
    <div data-cy="details-tab" style={{ marginTop: '-6px' }}>
      <Grid container spacing={6} data-cy="details-grid">
        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRight: `1px solid ${COLORS.GREY25}`,
            minHeight: '50vh',
          }}
        >
          <Box pb="16px" display="flex" alignItems="center">
            <Typography variant="body2" style={{ paddingRight: '6px' }}>
              Drivers
            </Typography>
          </Box>
          <Box>
            {isTat &&
              tatData?.getEpisodeTat?.drivers.map((item, index) =>
                displayDriver(index, item)
              )}
            {!isTat &&
              counterData?.getEpisodeCounter?.drivers.map((item, index) =>
                displayDriver(index, item)
              )}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRight: `1px solid ${COLORS.GREY25}`,
          }}
        >
          <Box pb="16px" display="flex" alignItems="center">
            <Typography variant="body2" style={{ paddingRight: '6px' }}>
              Current State
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" pb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              Status:
            </Typography>
            <Typography style={{ textTransform: 'uppercase' }}>
              {isTat &&
                tatData?.getEpisodeTat?.state &&
                CLOCK_STATE[tatData.getEpisodeTat.state]}
              {!isTat &&
                counterData?.getEpisodeCounter?.state &&
                CLOCK_STATE[counterData.getEpisodeCounter.state]}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" pb="12px">
            <Typography variant="body2" style={{ paddingRight: '4px' }}>
              Start Date/Time:
            </Typography>
            {isTat && tatData?.getEpisodeTat?.startDateTime
              ? formatDateTime(tatData.getEpisodeTat.startDateTime)
              : ''}
            {!isTat && counterData?.getEpisodeCounter?.startDateTime
              ? formatDateTime(
                  counterData.getEpisodeCounter.startDateTime,
                  undefined,
                  counterData.getEpisodeCounter.dateOnly,
                  false
                )
              : ''}
            <Typography />
          </Box>
          {isTat && (
            <>
              <Box display="flex" alignItems="center" pb="12px">
                <Typography variant="body2" style={{ paddingRight: '4px' }}>
                  Calculated TAT:
                </Typography>
                <Typography>
                  {' '}
                  {tatData?.getEpisodeTat?.calculatedTat}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" pb="12px">
                <Typography variant="body2" style={{ paddingRight: '4px' }}>
                  Additional Time Added:
                </Typography>
                <Typography>
                  {tatData?.getEpisodeTat?.additionalTime
                    ? tatData?.getEpisodeTat?.additionalTime
                    : '(None)'}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" pb="12px">
                <Typography variant="body2" style={{ paddingRight: '4px' }}>
                  Due:
                </Typography>
                <Typography>
                  {tatData?.getEpisodeTat?.dueDateTime
                    ? formatDateTime(tatData.getEpisodeTat.dueDateTime)
                    : ''}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" pb="12px">
                <Typography variant="body2" style={{ paddingRight: '4px' }}>
                  Time Remaining:
                </Typography>
                <Typography>{tatData?.getEpisodeTat?.timeRemaining}</Typography>
              </Box>
            </>
          )}
          {!isTat && (
            <Box display="flex" alignItems="center" pb="12px">
              <Typography variant="body2" style={{ paddingRight: '4px' }}>
                Time Elapsed:
              </Typography>
              <Typography>
                {counterData?.getEpisodeCounter?.timeElapsed}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
          <Box pb="16px" display="flex" alignItems="center">
            <Typography variant="body2" style={{ paddingRight: '6px' }}>
              History
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" pb="12px">
            <Typography>
              {isTat &&
                tatData?.getEpisodeTat?.history?.map((item, index) =>
                  displayHistory(
                    index,
                    item.action,
                    item.createdByUser.fullName,
                    item.createdOn
                  )
                )}
              {!isTat &&
                counterData?.getEpisodeCounter?.history?.map((item, index) =>
                  displayHistory(
                    index,
                    item.action,
                    item.createdByUser.fullName,
                    item.createdOn
                  )
                )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Loader active={tatIsFetching || counterIsFetching} />
    </div>
  );
};

export default ClockTab;
