import { EpisodeTabTypes } from 'graphql/graphqlTypes';

export const AppealGrievTabName = 'A&G';
export const CasesTabName = 'Cases';
export const OrgDetTabName = 'Org Det';
export const EpisodesTabName = 'Episodes';

export const getTargetEpisodeTabName = (
  episodeType: number,
  tabTypes: EpisodeTabTypes[]
) => {
  if (tabTypes) {
    const tabType = tabTypes.find((tabType) =>
      tabType?.episodeTypes?.includes(episodeType)
    );
    if (tabType?.tabName) {
      switch (tabType.tabName) {
        case 'cases':
          return CasesTabName;
        case 'episodes':
          return EpisodesTabName;
        case 'orgDet':
          return OrgDetTabName;
        case 'appealgriev':
          return AppealGrievTabName;
        default:
          return '';
      }
    }
  }

  return '';
};
