import React from 'react';
import ActionLink from 'components/actionlink';
import { closePatientDetailsDialog } from 'store/patientdetails/patientDetails.helpers';
import { Dispatch } from 'redux';
import { updateShowSuccessMessage } from 'store/patientdetails/patientDetailsSlice';

interface ActionLinkArgs {
  index: number;
  rowId: number;
  patientId: number;
  onGridRefetch: () => void;
  dispatch: Dispatch;
}

const TimelineActionLinks = {
  View: ({ index, rowId }: ActionLinkArgs) => (
    <ActionLink
      key={`ChecklistView-${index}`}
      type="ChecklistView"
      entityId={Number(rowId)}
    />
  ),
  Print: ({ index, rowId }: ActionLinkArgs) => (
    <ActionLink
      key={`ChecklistPrint-${index}`}
      type="ChecklistPrint"
      entityId={rowId}
    />
  ),
  Fax: ({ index, rowId }: ActionLinkArgs) => (
    <ActionLink
      key={`ChecklistFax-${index}`}
      type="ChecklistFax"
      entityId={rowId}
    />
  ),
  Amend: ({ index, rowId, dispatch }: ActionLinkArgs) => (
    <ActionLink
      key={`ChecklistAmend-${index}`}
      type="ChecklistAmend"
      entityId={rowId}
      callback={() => {
        closePatientDetailsDialog(dispatch);
      }}
    />
  ),
  Update: ({ index, rowId, dispatch }: ActionLinkArgs) => (
    <ActionLink
      key={`ChecklistUpdate-${index}`}
      type="ChecklistUpdate"
      entityId={Number(rowId)}
      callback={() => {
        closePatientDetailsDialog(dispatch);
      }}
    />
  ),
  QuickNoteUpdate: ({ index, rowId, patientId, dispatch }: ActionLinkArgs) => (
    <ActionLink
      key={`QuickNoteUpdate-${index}`}
      type="QuickNoteUpdate"
      entityId={Number(rowId)}
      patientId={Number(patientId)}
      callback={() => {
        closePatientDetailsDialog(dispatch);
      }}
    />
  ),
  Continue: ({ index, rowId, dispatch }: ActionLinkArgs) => (
    <ActionLink
      key={`ChecklistContinue-${index}`}
      type="ChecklistContinue"
      entityId={Number(rowId)}
      callback={() => {
        closePatientDetailsDialog(dispatch);
      }}
    />
  ),
  Delete: ({ index, rowId, onGridRefetch, dispatch }: ActionLinkArgs) => (
    <ActionLink
      key={`ChecklistDelete-${index}`}
      type="ChecklistDelete"
      entityId={Number(rowId)}
      callback={() => {
        onGridRefetch();
        dispatch(updateShowSuccessMessage(true));
      }}
    />
  ),
};

export default TimelineActionLinks;
