import { Attachment } from 'graphql/graphqlTypes';
import { attachmentColumns } from './attachmentColumns';

export const gridColumnsSidePanelClosed = [
  {
    name: attachmentColumns.createdOn,
    title: 'Added',
  },
  {
    name: attachmentColumns.type,
    title: 'Type',
  },
  {
    name: attachmentColumns.description,
    title: 'Description',
  },
  {
    name: attachmentColumns.name,
    title: 'Name',
  },
  {
    name: attachmentColumns.attachmentCategory,
    title: 'Category',
  },
  {
    name: attachmentColumns.addedBy,
    title: 'Added By',
    getCellValue: (row: Attachment) => row?.createdByUser?.fullName,
  },
  {
    name: attachmentColumns.actions,
    title: 'Actions',
  },
];

export const gridColumnsSidePanelOpen = [
  {
    name: attachmentColumns.createdOn,
    title: 'Added',
  },
  {
    name: attachmentColumns.type,
    title: 'Type',
  },
  {
    name: attachmentColumns.description,
    title: 'Description',
  },
  {
    name: attachmentColumns.name,
    title: 'Name',
  },
];
