import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import EpisodesDateTimeFormatter from '../formatters/EpisodesDateTimeFormatter';

const EpisodesDateTimeProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={EpisodesDateTimeFormatter} {...props} />
);

export default EpisodesDateTimeProvider;
