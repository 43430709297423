import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import EpisodeClockFormatter from '../formatters/EpisodeClockFormatter';

const EpisodesClockProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={EpisodeClockFormatter} {...props} />
);

export default EpisodesClockProvider;
