import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import styled from 'styled-components';

const StyledTableRow = styled.tr`
  height: 40px;
  max-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const NoDataRowComponent = (props: Table.RowProps) => (
  <StyledTableRow {...props} data-cy="table-nodata-row">
    {props.children}
  </StyledTableRow>
);
