import React from 'react';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Checkbox } from '@mui/material';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { updateConsentToShare } from 'store/patientdetails/patientDetails.careTeam.slice';
import { updateDirtyTabs } from 'store/patientdetails/patientDetailsSlice';
import { IState } from 'store';

export const ConsentToShareCell = (props: ILabelCellProps) => {
  const { row, value: isChecked } = props;
  const dispatch = useDispatch();
  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );

  const onClick = () => {
    dispatch(
      updateConsentToShare({
        id: row.id,
        value: !isChecked,
      })
    );

    if (!isInEditMode) {
      dispatch(updateDirtyTabs('Care Team'));
    }
  };

  return (
    <Table.Cell
      {...props}
      style={CellComponentStyle}
      data-cy="table-cell"
      onClick={undefined}
    >
      <Checkbox
        checked={isChecked ?? false}
        color="primary"
        onClick={onClick}
      />
    </Table.Cell>
  );
};
