import React, { useCallback, useEffect, useState } from 'react';
import { IEditorProps } from 'backend/types/grid/IEditorProps';
import { StyledOutlinedInput } from './styles';
import { useSelector } from 'react-redux';
import { IState } from 'store';

const TextFilter = (props: IEditorProps) => {
  const [inputValue, setInputValue] = useState('');

  const reduxValue = useSelector(
    (state: IState) => state.home.homeModel.filterBy
  )?.find((filter) => filter.columnName === props.column.name)?.value;

  useEffect(() => {
    setInputValue(reduxValue ?? '');
  }, [reduxValue]);

  const handleValueChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  return (
    <StyledOutlinedInput
      id={props.column?.name}
      type="text"
      name={props.column?.name}
      placeholder={props.column?.title}
      data-testid="text-filter"
      value={inputValue}
      onChange={(event) => handleValueChange(event.target.value)}
      onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          props.onChange(inputValue);
        }
      }}
    />
  );
};

export default TextFilter;
