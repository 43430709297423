import {
  HealthServiceCodesOrderableOptions,
  ILookupValue,
} from 'graphql/graphqlTypes';
import { useEffect, useState } from 'react';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { useGetLookupValuesQuery } from 'graphql/hooks/getLookups';

export const useLookup = (lookupName: string, includeSystemValues = false) => {
  const { data } = useGetLookupValuesQuery(
    {
      name: lookupName,
      includeSystemValues: includeSystemValues,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [lookupValues, updateLookupValues] = useState([] as ILookupValue[]);

  useEffect(() => {
    if (data) {
      updateLookupValues(data.getLookupValues as ILookupValue[]);
    }
  }, [data]);

  return lookupValues;
};

export const useDeterminationReasons = (
  options: HealthServiceCodesOrderableOptions,
  isReview = false
) => ({
  Approved: useLookup(
    options.hSApprovedReasonLookup ?? 'HSDeterminationReasonApproved',
    isReview
  ),
  Partial: useLookup(
    options.hSPartialReasonLookup ?? 'HSDeterminationReasonPartial',
    isReview
  ),
  Denied: useLookup(
    options.hSDeniedReasonLookup ?? 'HSDeterminationReasonDenied',
    isReview
  ),
  Pending: useLookup(
    options.hSPendingReasonLookup ?? 'HSDeterminationReasonPending',
    isReview
  ),
});

export const usePrivileges = () => {
  const privileges = [
    hasUserPrivilege(Privilege.HealthServices1_NODECISIONS),
    hasUserPrivilege(Privilege.HealthServices2_PENDINGONLY),
    hasUserPrivilege(Privilege.HealthServices3_ADDAPPROVALS),
    hasUserPrivilege(Privilege.HealthServices4_ADDDENIALS),
  ];

  const badConfiguration =
    privileges.reduce((sum, current) => sum + (current ? 1 : 0), 0) !== 1;

  return {
    noDecision: privileges[0],
    pendingOnly: privileges[1],
    canApprove: privileges[2],
    canDenied: privileges[3],
    badConfiguration,
  };
};
