import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Checkbox,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Chip from 'components/chips';
import { COLORS } from 'consts/styles';
import { ICONS } from 'components/icon';
import { ILetter } from '..';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const StyledTable = styled(Table)({
  overflow: 'auto',
});

const StyledTableCell = styled(TableCell)({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  maxWidth: 'inherit',
});

const StyledMenuItem = styled(MenuItem)({
  display: 'block',
  maxWidth: 'inherit',
  textOverflow: 'ellipsis',
  width: '100%',
});

const StyledTableContainer = styled(TableContainer)({
  overflow: 'auto',
  maxHeight: '60vh',
  maxWidth: '600px',
  marginBottom: '16px',
  display: 'flex',
});

const StyledTableHead = styled(TableHead)({
  maxWidth: 'inherit',
});

const StyledTableRow = styled(TableRow)({
  maxWidth: 'inherit',
});

const StyledTableBody = styled(TableBody)({
  maxWidth: 'inherit',
  overflowX: 'auto',
});

const StyledText = styled(Typography)({
  display: 'inline',
  verticalAlign: 'middle',
});

const HeaderText = styled(StyledText)({
  fontWeight: 500,
});

const MenuItemContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Centered = styled(Box)({
  maxWidth: 'inherit',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '& .MuiChip-root': {
    marginRight: '4px',
  },
  '& .MuiChip-root:last-child': {
    marginRight: 0,
  },
});

const getFileText = (files: number) =>
  files > 0 ? `${files} File(s)` : 'No File(s)';

export interface IHeaderCheckbox {
  checked: boolean;
  indeterminate: boolean;
  color: 'primary' | 'secondary' | 'default';
  isListCheckBoxChange: boolean;
}

export interface ILetterTableProps {
  letters: ILetter[];
  letterGroupId: string;
  letterGroupValue: string;
  setLetters: (selection: ILetter[]) => void;
  setIsSelectedView: (IsSelectedView: boolean) => void;
  letterSearch: string;
}

export const LetterTable = ({
  setLetters,
  letters,
  letterGroupId,
  letterGroupValue,
  letterSearch,
  setIsSelectedView,
}: ILetterTableProps) => {
  const displayedLetters = letters.filter(({ display }: ILetter) => display);

  const [headerCheckbox, setHeaderCheckbox] = useState({
    checked: false,
    indeterminate: false,
    color: 'primary',
    isListCheckBoxChange: false,
  } as IHeaderCheckbox);

  useEffect(() => {
    setHeaderCheckbox({
      checked: true,
      indeterminate: false,
      color: 'default',
      isListCheckBoxChange: true,
    });
    setIsSelectedView(false);
  }, [letterGroupValue, letterSearch, setIsSelectedView]);

  useEffect(() => {
    if (headerCheckbox.isListCheckBoxChange) {
      const filterSelected = displayedLetters.filter((x) => x.selected);
      if (filterSelected.length === 0) {
        setHeaderCheckbox({
          checked: false,
          indeterminate: false,
          color: 'default',
          isListCheckBoxChange: false,
        });
      } else if (filterSelected.length === displayedLetters.length) {
        setHeaderCheckbox({
          checked: true,
          indeterminate: false,
          color: 'primary',
          isListCheckBoxChange: false,
        });
      } else if (
        filterSelected.length > 0 &&
        filterSelected.length < displayedLetters.length
      ) {
        setHeaderCheckbox({
          checked: true,
          indeterminate: true,
          color: 'primary',
          isListCheckBoxChange: false,
        });
      }
    }
  }, [displayedLetters, headerCheckbox]);

  const onSelectAllClick = useCallback(() => {
    const filterSelected = displayedLetters.filter((x) => x.selected);
    const letterWithAttachment = displayedLetters?.find((x) => x.attachment);
    const updated = letters.map((letter: ILetter) => ({
      ...letter,
      selected:
        !filterSelected.length &&
        (letterGroupId === '' || letter.letterGroup === letterGroupId),
      attachment: !filterSelected.length
        ? letterWithAttachment?.attachment
        : undefined,
      attachmentFileCount: !filterSelected.length
        ? letterWithAttachment?.attachmentFileCount ?? 0
        : 0,
    }));
    setLetters(updated);
    setHeaderCheckbox({
      checked: !headerCheckbox.checked,
      indeterminate: false,
      color: 'primary',
      isListCheckBoxChange: false,
    });
    setIsSelectedView(false);
  }, [
    letters,
    letterGroupId,
    displayedLetters,
    headerCheckbox.checked,
    setIsSelectedView,
    setLetters,
  ]);

  const onSelect = (id: number) => {
    const letterWithAttachment = letters?.find((x) => x.attachment);
    const attachment = (letter: ILetter) =>
      !letter.selected ? letterWithAttachment?.attachment : undefined;
    const attachmentFileCount = (letter: ILetter) =>
      !letter.selected ? letterWithAttachment?.attachmentFileCount ?? 0 : 0;
    const updated = letters.map((letter: ILetter) => ({
      ...letter,
      selected: letter.id === id ? !letter.selected : letter.selected,
      attachment: letter.id === id ? attachment(letter) : letter.attachment,
      attachmentFileCount:
        letter.id === id
          ? attachmentFileCount(letter)
          : letter.attachmentFileCount,
    }));
    setLetters(updated);
    setHeaderCheckbox({
      checked: true,
      indeterminate: false,
      color: 'default',
      isListCheckBoxChange: true,
    });
    setIsSelectedView(false);
  };

  return (
    <StyledTableContainer data-testid="letter-table">
      <StyledTable>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell style={{ backgroundColor: COLORS.GREY4 }}>
              <StyledMenuItem
                onMouseDown={onSelectAllClick}
                data-testid="letter-head-styledMenu"
              >
                <Checkbox
                  color={headerCheckbox.color}
                  checked={headerCheckbox.checked}
                  indeterminate={headerCheckbox.indeterminate}
                />
                <HeaderText variant="caption">Name</HeaderText>
              </StyledMenuItem>
            </StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {displayedLetters.map(
            (
              {
                id,
                title,
                selected,
                print,
                printShop,
                fax,
                attachment,
                attachmentErrorCount,
                attachmentFileCount,
              }: ILetter,
              index: number
            ) => (
              <StyledTableRow key={id}>
                <StyledTableCell
                  style={{
                    backgroundColor: selected
                      ? COLORS.BLUE10
                      : index % 2
                      ? COLORS.GREY4
                      : COLORS.WHITE,
                  }}
                >
                  <StyledMenuItem
                    onMouseDown={() => onSelect(id)}
                    data-testId="letter-list-styledMenu"
                  >
                    <MenuItemContent>
                      <Centered>
                        <Checkbox color="primary" checked={selected} />
                        <CustomTooltip
                          title={title ?? ''}
                          containerElement="span"
                        >
                          <StyledText variant="body1">{title}</StyledText>
                        </CustomTooltip>
                      </Centered>
                      <Centered>
                        {printShop && (
                          <Chip
                            text="Print Shop"
                            type="success"
                            icon={ICONS.Checkmark}
                          />
                        )}
                        {print && (
                          <Chip
                            text="Print"
                            type="success"
                            icon={ICONS.Checkmark}
                          />
                        )}
                        {fax && (
                          <Chip
                            text="Fax"
                            type="success"
                            icon={ICONS.Checkmark}
                          />
                        )}
                        {attachment && (
                          <Chip
                            text={getFileText(attachmentFileCount)}
                            icon={ICONS.AttachmentClip}
                            onDelete={() => {
                              const withoutAttachments = letters.map(
                                (letter: ILetter) => ({
                                  ...letter,
                                  attachment:
                                    letter.id === id
                                      ? undefined
                                      : letter.attachment,
                                })
                              );
                              setLetters(withoutAttachments);
                              setIsSelectedView(false);
                            }}
                          />
                        )}
                        {attachmentErrorCount > 0 && (
                          <Chip
                            text={getFileText(attachmentErrorCount)}
                            icon={ICONS.AttachmentClip}
                            type="error"
                            onDelete={() => {
                              const withoutErrors = letters.map(
                                (letter: ILetter) => ({
                                  ...letter,
                                  attachmentErrorCount:
                                    letter.id === id
                                      ? 0
                                      : letter.attachmentErrorCount,
                                })
                              );
                              setLetters(withoutErrors);
                            }}
                          />
                        )}
                      </Centered>
                    </MenuItemContent>
                  </StyledMenuItem>
                </StyledTableCell>
              </StyledTableRow>
            )
          )}
        </StyledTableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};
