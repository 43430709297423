import React, { CSSProperties, ReactNode } from 'react';

export interface IReminderRowProps {
  children: ReactNode;
  style?: CSSProperties;
}

const ReminderRow = (props: IReminderRowProps) => (
  <div style={props.style}>{props.children}</div>
);

export default ReminderRow;
