import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetFaxDialogModelQueryVariables = Types.Exact<{
  episodeId?: Types.InputMaybe<Types.Scalars['Int']>;
  letterId?: Types.InputMaybe<Types.Scalars['Int']>;
  letterType?: Types.InputMaybe<Types.LetterType>;
}>;

export type GetFaxDialogModelQuery = {
  __typename?: 'EntitiesQuery';
  getFaxDialogModel: {
    __typename?: 'FaxDialogViewModel';
    note?: string | null;
    recipientFaxNumber?: string | null;
    recipientTo?: string | null;
    senderFrom?: string | null;
    senderRespondTo?: number | null;
    faxNumbers?: Array<{
      __typename?: 'CareSiteFaxNumberViewModel';
      faxId: number;
      faxLabel: string;
      faxNumber: string;
    }> | null;
  };
};

export const GetFaxDialogModelDocument = `
    query getFaxDialogModel($episodeId: Int, $letterId: Int, $letterType: LetterType) {
  getFaxDialogModel(
    episodeId: $episodeId
    letterId: $letterId
    letterType: $letterType
  ) {
    faxNumbers {
      faxId
      faxLabel
      faxNumber
    }
    note
    recipientFaxNumber
    recipientTo
    senderFrom
    senderRespondTo
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFaxDialogModel: build.query<
      GetFaxDialogModelQuery,
      GetFaxDialogModelQueryVariables | void
    >({
      query: (variables) => ({
        document: GetFaxDialogModelDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetFaxDialogModelQuery, useLazyGetFaxDialogModelQuery } =
  injectedRtkApi;
