import { some } from 'lodash';
import { CHECKBOX_RADIOBUTTON_TYPE } from '../../Components/RadioCheckBoxButton/types';
import { ISetStoplightValidationBody } from 'store/actions/types';
import {
  CheckboxRadioButtonComponent,
  ChecklistItemOrderable,
  ComponentsLine,
} from 'graphql/graphqlTypes';
import React from 'react';

export const getStoplightsStatus = (
  stoplights: ISetStoplightValidationBody[] | null,
  item: ChecklistItemOrderable
) =>
  stoplights &&
  !!item?.qualityMeasure?.id &&
  stoplights?.some(
    (stoplight) =>
      stoplight.id === item.qualityMeasure?.id &&
      some(stoplight.itemsWithStoplights, {
        isCompleted: true,
        isVisible: true,
      })
  );

export const getGroupId = (
  component: CheckboxRadioButtonComponent,
  sectionItemId: string
) => {
  const prefix =
    component.type === CHECKBOX_RADIOBUTTON_TYPE.RADIOBUTTON ? 'rb' : 'cb';
  return `${prefix}-${component.groupId || ''}-${sectionItemId}`;
};

export const buildLineStyle = (
  line: ComponentsLine,
  lineIndex: number,
  needsPaddingLeft: boolean,
  isActionSelected: boolean
): React.CSSProperties => {
  let gridTemplateColumns = '1fr';
  if (line.components.length > 1) {
    const repeat = line.components.length - (lineIndex === 0 ? 0 : 1);
    gridTemplateColumns = `repeat(${repeat}, auto) minmax(150px, 1fr)`;
  } else if (lineIndex === 0) {
    gridTemplateColumns = `auto 1fr`;
  }

  let paddingLeft: string | number;
  if (lineIndex !== 0) {
    paddingLeft = '36px';
  } else if (lineIndex === 0 && needsPaddingLeft) {
    paddingLeft = '13px';
  } else {
    paddingLeft = 0;
  }

  const displayOnlyWhenSelectedLine = line.components.every(
    (x) => x.displayOnlyWhenSelected
  );
  const isHiddenLine = !isActionSelected && displayOnlyWhenSelectedLine;

  return {
    paddingLeft: paddingLeft,
    display: 'grid',
    paddingBottom: isHiddenLine ? 0 : undefined,
    gridTemplateColumns,
  };
};
