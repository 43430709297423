import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { formatDate } from 'util/helpers/dateTimeHelpers';

const ClaimsDateProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={(formProps: DataTypeProvider.ValueFormatterProps) => {
      return (
        <Typography>
          {formProps.value ? formatDate(formProps.value) : ''}
        </Typography>
      );
    }}
    {...props}
  />
);

export default ClaimsDateProvider;
