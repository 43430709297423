import { Button, Typography } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React from 'react';
import { IState } from 'store';
import { useSelector } from 'react-redux';
import { Privilege, RoleType } from 'store/roles/types';
import styled from 'styled-components';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';

interface PatientDialogActionsProps {
  onClose: () => void;
  onNew: (patient: number, episode: number) => void;
  patientId: number;
  handleClickOpenQuickDialog: () => void;
  handleClose: () => void;
  patientTabValid: boolean;
  isActionsDisabled?: boolean;
}

const StyledWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const StyledTypography = styled(Typography)({
  color: COLORS.SYMPHONY_BLUE,
});

const StyledButton = styled(Button)({
  minWidth: '150px',
  height: '40px',
});

export const PatientDialogActions = (props: PatientDialogActionsProps) => {
  const {
    onClose,
    onNew,
    patientId,
    handleClickOpenQuickDialog,
    handleClose,
    patientTabValid,
    isActionsDisabled,
  } = props;
  const episodeId = useSelector(
    (state: IState) => state?.patientDetails?.episodeId ?? 0
  );
  const roleType = useSelector((state: IState) => state.user.currentUser.type);

  const activeTab = useSelector(
    (state: IState) => state.patientDetails.activeTabName
  );

  const onNewEncounter = () => {
    onClose();
    onNew(patientId, roleType === RoleType.NONCLINICAL ? 0 : episodeId);
  };

  const canAddMemberEpisodeNotes = hasUserPrivilege(
    Privilege.AddMemberEpisodeNotes
  );
  const notesDisabled = !episodeId || !canAddMemberEpisodeNotes;
  const notesColor = notesDisabled ? COLORS.GREY25 : COLORS.SYMPHONY_BLUE;
  const isExitDisabled = !patientTabValid || isActionsDisabled;

  return (
    <>
      <StyledWrapper>
        {roleType === RoleType.CLINICAL && activeTab === 'Episodes' ? null : (
          <StyledButton
            color="primary"
            variant="outlined"
            onClick={onNewEncounter}
            startIcon={
              <Icon
                icon={ICONS.Add}
                size="14"
                color={COLORS.SYMPHONY_BLUE}
                style={{ marginTop: '-2px' }}
              />
            }
            data-cy="new-encounter"
          >
            <StyledTypography variant="h5">New</StyledTypography>
          </StyledButton>
        )}
        <StyledButton
          color="primary"
          variant="outlined"
          size="large"
          disabled={notesDisabled}
          startIcon={
            <Icon
              icon={ICONS.Episode_Note}
              size="14"
              color={notesColor}
              style={{ marginTop: '-2px' }}
            />
          }
          data-cy="new-encounter"
          onClick={handleClickOpenQuickDialog}
        >
          <StyledTypography variant="h5" style={{ color: notesColor }}>
            Episode Note
          </StyledTypography>
        </StyledButton>
      </StyledWrapper>
      <StyledWrapper>
        <StyledButton
          onClick={handleClose}
          color="primary"
          variant="outlined"
          data-cy="exit"
          size="large"
          disabled={isExitDisabled}
        >
          <StyledTypography variant="h5">Exit</StyledTypography>
        </StyledButton>
      </StyledWrapper>
    </>
  );
};
