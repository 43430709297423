import { LetterSummaryColumns } from './LetterSummaryColumns';
import { Attachment } from 'graphql/graphqlTypes';

export const gridColumns = [
  {
    name: LetterSummaryColumns.createdOn,
    title: 'Date/Time',
  },
  {
    name: LetterSummaryColumns.description,
    title: 'Description',
  },
  {
    name: LetterSummaryColumns.name,
    title: 'Name',
  },
  {
    name: LetterSummaryColumns.addedBy,
    title: 'Added By',
    getCellValue: (row: Attachment) => row?.createdByUser?.fullName,
  },
  {
    name: LetterSummaryColumns.actions,
    title: 'Actions',
  },
];
