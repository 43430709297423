import React from 'react';
import { IBackgroundTabProps } from './types';
import PatientDetailsBackgroundSubTabs from './PatientDetailsBackgroundSubTabs';

const BackgroundTab = (props: IBackgroundTabProps) => {
  const {
    style,
    isExternalDataReadOnly,
    selectedSubTab,
    handleUpdatePatientTabValid,
    patientDetails,
  } = props;

  return (
    <div>
      <PatientDetailsBackgroundSubTabs
        style={style}
        isExternalDataReadOnly={isExternalDataReadOnly}
        selectedSubTab={selectedSubTab}
        handleUpdatePatientTabValid={handleUpdatePatientTabValid}
        patientDetails={patientDetails}
      />
    </div>
  );
};

export default BackgroundTab;
