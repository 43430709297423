import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetDuplicateEpisodesQueryVariables = Types.Exact<{
  request: Types.PatientEpisodesFilterModelInput;
}>;

export type GetDuplicateEpisodesQuery = {
  __typename?: 'EntitiesQuery';
  getDuplicateEpisodes?: {
    __typename?: 'PatientEpisodesViewModel';
    readonly: boolean;
    showTatColumn: boolean;
    showCounterColumn: boolean;
    pager?: {
      __typename?: 'PatientEpisodesTablePagerViewModel';
      currentPage?: number | null;
      pagesCount?: number | null;
      pageSize?: number | null;
      totalCount?: number | null;
    } | null;
    episodes?: Array<
      | { __typename?: 'AppealGrievViewModel' }
      | { __typename?: 'CaseEpisodeViewModel' }
      | {
          __typename?: 'EpisodeViewModel';
          id?: number | null;
          createdOn?: any | null;
          episodeNumber?: string | null;
          patientId: number;
          authorizationStartDate?: any | null;
          type?: string | null;
          createdBy?: string | null;
          anyProgress?: boolean | null;
          state?: Types.EpisodeState | null;
          timeZone?: string | null;
          allowViewEpisodeDetail?: boolean | null;
          servicingProvider?: string | null;
          facility?: string | null;
          procedureCode?: string | null;
          tat?: {
            __typename?: 'TatClockColumnViewModel';
            state?: Types.ClockState | null;
            dueDateTime?: any | null;
            timeRemaining?: string | null;
          } | null;
          counter?: {
            __typename?: 'CounterClockColumnViewModel';
            state?: Types.ClockState | null;
            startDateTime?: any | null;
            timeElapsed?: string | null;
          } | null;
          authStatus?: {
            __typename?: 'LookupValue';
            name?: string | null;
            style?: string | null;
          } | null;
          topReminder?: {
            __typename?: 'BaseReminder';
            importance?: Types.ReminderImportance | null;
            daysOverdue: number;
            daysLeft: number;
          } | null;
        }
      | { __typename?: 'EpisodeWithMozartInfoViewModel' }
    > | null;
  } | null;
};

export const GetDuplicateEpisodesDocument = `
    query getDuplicateEpisodes($request: PatientEpisodesFilterModelInput!) {
  getDuplicateEpisodes(request: $request) {
    readonly
    showTatColumn
    showCounterColumn
    pager {
      currentPage
      pagesCount
      pageSize
      totalCount
    }
    episodes {
      ... on EpisodeViewModel {
        id
        createdOn
        episodeNumber
        patientId
        authorizationStartDate
        tat {
          state
          dueDateTime
          timeRemaining
        }
        counter {
          state
          startDateTime
          timeElapsed
        }
        type
        createdBy
        authStatus {
          name
          style
        }
        anyProgress
        topReminder {
          importance
          daysOverdue
          daysLeft
        }
        state
        timeZone
        allowViewEpisodeDetail
        servicingProvider
        facility
        procedureCode
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDuplicateEpisodes: build.query<
      GetDuplicateEpisodesQuery,
      GetDuplicateEpisodesQueryVariables
    >({
      query: (variables) => ({
        document: GetDuplicateEpisodesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetDuplicateEpisodesQuery,
  useLazyGetDuplicateEpisodesQuery,
} = injectedRtkApi;
