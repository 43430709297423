import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import RisingRiskProviderFormatter from '../formatters/RisingRiskFormatter';

const RisingRiskProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider
    formatterComponent={RisingRiskProviderFormatter}
    {...props}
  />
);

export default RisingRiskProvider;
