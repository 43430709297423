import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { COLORS } from 'consts/styles';

const DetailRowHeaderCellComponent = (props: TableHeaderRow.CellProps) => (
  <TableHeaderRow.Cell
    {...props}
    style={{
      fontSize: '14px',
      fontWeight: 500,
      padding: '16px 8px 0px 8px',
      color: COLORS.GREY100,
      backgroundColor: COLORS.BLUE10,
      borderBottomWidth: '0px',
    }}
  >
    {props.children}
  </TableHeaderRow.Cell>
);

export default DetailRowHeaderCellComponent;
