import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DecisionAction,
  DeterminationDecision,
  HealthServiceAuthorizationType,
} from '../types';
import {
  HealthServiceCodesActionModel,
  ILookupValue,
  LookupValue,
} from 'graphql/graphqlTypes';
import { DecisionButtonGroup } from './DecisionButtonGroup';
import ReasonSelect from './ReasonSelect';

export interface IGroupDecisionProps {
  determinationReasons: { [key: string]: ILookupValue[] };
  actionOnGroup: (
    decision: DeterminationDecision,
    reason: LookupValue | null
  ) => void;
  actionOnDecision: (decision: DeterminationDecision) => void;
  readOnly?: boolean;
  pendingOnly?: boolean;
  canApprove?: boolean;
  canDenied?: boolean;
  canPartial?: boolean;
  hsAuthorizationType?: number;
  hsModel: HealthServiceCodesActionModel;
}

const GroupDecision = (props: IGroupDecisionProps) => {
  const {
    determinationReasons,
    actionOnGroup,
    readOnly,
    pendingOnly,
    canApprove,
    canDenied,
    canPartial,
    hsAuthorizationType,
    hsModel,
  } = props;
  const [groupDecision, updateGroupDecision] = useState<DeterminationDecision>(
    hsAuthorizationType === HealthServiceAuthorizationType.Episode
      ? (hsModel?.healthServiceCodesActionValues[0]?.healthServiceActionValue
          ?.determinationDecision as DeterminationDecision) ??
          DecisionAction.nonSet
      : DecisionAction.nonSet
  );

  const [determinationReason, updateDeterminationReason] =
    useState<LookupValue | null>(null);

  const onUpdateDeterminationReason = (reason: LookupValue | null) => {
    updateDeterminationReason(reason);
    if (
      hsAuthorizationType === HealthServiceAuthorizationType.Episode &&
      reason !== null
    ) {
      actionOnGroup(groupDecision, reason);
    }
  };

  useEffect(() => {
    const reason =
      hsAuthorizationType === HealthServiceAuthorizationType.Episode
        ? (hsModel?.healthServiceCodesActionValues[0]?.healthServiceActionValue
            ?.determinationReason as LookupValue)
        : null;
    updateDeterminationReason(reason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupDecision]);

  const onGroupAction = () => {
    actionOnGroup(groupDecision, determinationReason);
    updateDeterminationReason(null);
    updateGroupDecision(DecisionAction.nonSet);
  };

  const isReasonReadOnly =
    readOnly || (!canApprove && !canDenied && !pendingOnly && !canPartial);

  const decisionLabel = (hsAuthorizationType: number | undefined) => {
    switch (hsAuthorizationType) {
      case HealthServiceAuthorizationType.NonSet:
        return 'Group Decision';
      case HealthServiceAuthorizationType.Episode:
        return 'Episode Decision';
      default:
        return 'Decision Group Shortcut:';
    }
  };
  return (
    <>
      <Box display="flex" alignContent="end" pb="16px">
        <Typography variant="body2">
          {decisionLabel(hsAuthorizationType)}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap="20px">
        <DecisionButtonGroup
          updateGroupDecision={updateGroupDecision}
          groupDecision={groupDecision}
          readOnly={readOnly}
          pendingOnly={pendingOnly}
          canApprove={canApprove}
          canDenied={canDenied}
          canPartial={canPartial}
        />
        <Typography variant="body2">Reason</Typography>

        <ReasonSelect
          showLabel={false}
          isRequired={false}
          determinationReasons={determinationReasons}
          groupDecision={groupDecision}
          width="236px"
          readOnly={isReasonReadOnly}
          value={determinationReason}
          updateValue={onUpdateDeterminationReason}
        />
        {hsAuthorizationType !== HealthServiceAuthorizationType.Episode ? (
          <Button
            onClick={onGroupAction}
            size="small"
            color="primary"
            disabled={readOnly ?? false}
            variant="contained"
          >
            {hsAuthorizationType == HealthServiceAuthorizationType.NonSet
              ? 'Apply'
              : 'Apply to all'}
          </Button>
        ) : null}
      </Box>
    </>
  );
};

export default GroupDecision;
