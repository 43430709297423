import React from 'react';
import { TextField } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { FieldErrors, Message } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form';
import { ValidationRule } from 'react-hook-form/dist/types/validator';
import { IAuthUser } from 'backend/types/authUser';
import styled from 'styled-components';

const StyledError = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 8px;
`;

const StyledInputField = styled(TextField)({
  fontSize: '12px',
});
export interface ITextProp {
  defaultValue?: string | null;
  handleOnChange?: StandardInputProps['onChange'];
  id?: string | undefined;
  isRequired?: boolean | null;
  register?: UseFormRegister<IAuthUser>;
  errors?: FieldErrors | null;
  validationErrorMessage?: string;
  pattern?: RegExp | null;
}

const Text = (props: ITextProp) => {
  return (
    <div data-cy={`text-${props.id}`}>
      <StyledInputField
        id={props?.id}
        type="text"
        size="small"
        {...props.register?.(props?.id as 'id' | 'prefix', {
          required: props.isRequired as Message | ValidationRule<boolean>,
          pattern: {
            value: props.pattern || new RegExp(''),
            message: '',
          },
        })}
        error={
          (props.errors && (props?.id as string) in props.errors) as boolean
        }
        key={props.id}
        onChange={props.handleOnChange}
        value={props.defaultValue || ''}
        fullWidth={true}
        variant="outlined"
      />
      {props &&
        props.errors &&
        props.validationErrorMessage &&
        props.errors[props?.id as string] && (
          <StyledError>{props?.validationErrorMessage}</StyledError>
        )}
    </div>
  );
};
export default Text;
