import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import {
  GridValidationLineRow,
  GridLineRow,
  RequestedDatesBox,
} from './StyledComponents';
import { usePrevious } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  useSavePriorAuth,
  onPriorAuthRequestedDatesChange,
  addorRefreshCodes,
  getSelectedItem,
  isAllowedToAddCodes,
  validatePriorAuthCode,
} from './PriorAuthcodes.helpers';
import { ChecklistStorageType } from 'store/actions/types';
import { IState } from 'store';
import {
  ChecklistItem,
  ChecklistItemPriorAuthCodes,
  MedicationCodeCategoryType,
  PriorAuthCodesActionModel,
  SearchResult,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import { useLazyCheckPriorAuthorizationQuery } from 'graphql/hooks/checkPriorAuthorization';
import PriorAuthRequestedDates from './PriorAuthRequestedDates';
import { hasValue, validateMinAmountValues } from 'util/validationUtils';
import MedicationCodesSearch from '../MedicationCodes/medicationCodesSearch';
import PriorAuthPolicyTable from './priorAuthPolicyTable';
import { COLORS } from 'consts/styles';
import { getValidationDispatch } from '../HealthService/HealthService.helpers';
import { PRIORAUTH_CHECK } from 'components/constants';
import {
  endDateInvalidError,
  startDateInvalidError,
} from './priorAuthConstants';
import styled from 'styled-components';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';

const StyledMedsSearchWrapper = styled.div`
  padding-top: 12px;
  background-color: ${COLORS.WHITE};
  width: 100%;
`;

export interface IChecklistItemPriorAuthCodesProps {
  item: ChecklistItemPriorAuthCodes;
  categoryId: string;
  autoSave: boolean;
  orderableIndex: number;
  storageType: ChecklistStorageType;
  readOnly: boolean;
  patientId: number;
  updateChecklistItemInputValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  selectChecklistItemOnSave: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
}

const PriorAuthCodes = ({
  autoSave,
  item,
  orderableIndex,
  storageType,
  updateChecklistItemInputValueOnSave,
  categoryId,
  patientId,
  readOnly,
}: IChecklistItemPriorAuthCodesProps) => {
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const isUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    item.uid
  );

  const pAShowCheckPolicy = item.options.pAShowCheckPolicy;
  const minRequired = item.options.minRequired;
  const [model, setModel] = useState({
    ...item.pAValue,
  } as PriorAuthCodesActionModel);
  const [error, setError] = useState<string | null | undefined>(
    validateMinAmountValues(
      model.priorAuthCodesActionValues?.length ?? 0,
      minRequired
    ).message || ''
  );

  const [isOnAddCode, setIsOnAddCode] = useState(false);
  const [showErrorBar, setShowErrorBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null
  );
  const dispatch = useDispatch();
  const previousError = usePrevious(error);
  const [selectedItem, setSelectedItem] = useState<Array<number | string>>([]);
  const codeLabel = `Add at least ${minRequired} ${item.options.codeLabel} ${
    item.options.codeLabel.toUpperCase().includes('ITEM') ? '' : 'item'
  }`;

  useEffect(() => {
    if (error === previousError) {
      return;
    }
    if (!error && !previousError) {
      return;
    }
    if (hasValue(error) && error.length > 0) {
      validationDispatch(error, true, item.uid);
    } else {
      validationDispatch('', false, item.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [
    checkPriorAuthorization,
    {
      data: actualData,
      isSuccess: isSuccessCheckPriorAuthorization,
      isFetching: isFetchingCheckPriorAuthorization,
    },
  ] = useLazyCheckPriorAuthorizationQuery();

  useEffect(() => {
    if (
      isSuccessCheckPriorAuthorization &&
      !isFetchingCheckPriorAuthorization
    ) {
      setModel(
        addorRefreshCodes(actualData, model, pAShowCheckPolicy, isOnAddCode)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualData,
    isFetchingCheckPriorAuthorization,
    isSuccessCheckPriorAuthorization,
  ]);

  useEffect(() => {
    handleValidation(model);
    setSelectedItem(getSelectedItem(model, pAShowCheckPolicy));
    handleInputChange(model);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const onAddCode = (newCode: SearchResult) => {
    if (model.reqStartDate && model.reqEndDate) {
      const result = validatePriorAuthCode(
        model.reqStartDate,
        newCode.expirationDate
      );
      if (result.hasError) {
        setErrorMessage(result.message);
        setShowErrorBar(true);
        return;
      }
      const allowAddCodes = isAllowedToAddCodes(
        item.options.allowedCodeCategory,
        newCode.codeCategory ?? MedicationCodeCategoryType.None
      );
      if (allowAddCodes) {
        setIsOnAddCode(true);
        checkPriorAuthorization({
          request: {
            reqStartDate: model.reqStartDate,
            reqEndDate: model.reqEndDate,
            codes: newCode.id.toString().split(','),
          },
        });
      }
      setShowErrorBar(!allowAddCodes);
      setErrorMessage(item.options.disAllowedCodeMessage);
    } else {
      if (!model.reqStartDate) {
        setErrorMessage(startDateInvalidError);
      }
      if (!model.reqEndDate) {
        setErrorMessage(endDateInvalidError);
      }
      setShowErrorBar(true);
    }
  };

  useEffect(() => {
    if (
      model.reqStartDate &&
      model.reqEndDate &&
      model.priorAuthCodesActionValues?.length > 0
    ) {
      setIsOnAddCode(false);
      checkPriorAuthorization({
        request: {
          reqStartDate: model.reqStartDate,
          reqEndDate: model.reqEndDate,
          codes: model.priorAuthCodesActionValues?.map((x) =>
            String(x?.id.toString())
          ),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.reqStartDate, model.reqEndDate]);

  const onReqDatesChange = (updatedValue: PriorAuthCodesActionModel) => {
    setShowErrorBar(false);
    const newModel = onPriorAuthRequestedDatesChange(model, updatedValue);
    setModel(newModel);
  };

  const onPriorAuthPolicyTableChange = (
    updatedValue: PriorAuthCodesActionModel
  ) => {
    setModel(updatedValue);
  };

  const savePriorAuth = useSavePriorAuth();
  const handleInputChange = debounce((value: PriorAuthCodesActionModel) => {
    savePriorAuth({
      value,
      checklistId,
      item,
      autoSave,
      updateChecklistItemInputValueOnSave,
      orderableIndex,
    });
  }, 100);

  const handleValidation = (newCodes: PriorAuthCodesActionModel) => {
    const result = validateMinAmountValues(
      newCodes.priorAuthCodesActionValues != null
        ? newCodes.priorAuthCodesActionValues.length
        : 0,
      minRequired
    );
    if (result.hasError) {
      setError(result.message);
    } else {
      setError('');
    }
  };

  const validationDispatch = getValidationDispatch(
    dispatch,
    item.uid,
    categoryId,
    storageType,
    PRIORAUTH_CHECK
  );

  return (
    <Box id={`uuid-${item.uid}`}>
      <RequestedDatesBox data-testid="PriorAuthRequestedDates">
        <PriorAuthRequestedDates
          item={model}
          storageType={storageType}
          onChange={onReqDatesChange}
          patientId={patientId}
          readOnly={readOnly}
          validationDispatch={validationDispatch}
        />
      </RequestedDatesBox>
      <Box data-testid="PriorAuthPolicyTable">
        <Grid container>
          <GridValidationLineRow
            item
            xs={12}
            style={{
              display: error ? 'block' : 'none',
            }}
          >
            <Typography variant="body1" style={{ color: COLORS.GREY100 }}>
              {codeLabel}
            </Typography>
          </GridValidationLineRow>
          <GridLineRow item xs={12}>
            <PriorAuthPolicyTable
              model={model}
              item={item}
              onChange={onPriorAuthPolicyTableChange}
              selectedItem={selectedItem}
            />
          </GridLineRow>
          {model.priorAuthCodesActionValues?.length === 0 &&
          minRequired !== 0 ? (
            <Box px="64px" pt="14px">
              <Typography>Use the search box below to add items</Typography>
            </Box>
          ) : null}
          <StyledMedsSearchWrapper>
            <MedicationCodesSearch
              minRequired={minRequired}
              maxAllowed={item.options.maxAllowed}
              type={item.options.type}
              selectedCodes={model.priorAuthCodesActionValues as SearchResult[]}
              onAddCode={onAddCode}
              readOnly={readOnly}
              showErrorBar={showErrorBar}
              errorMessage={errorMessage ?? ''}
            />
          </StyledMedsSearchWrapper>
          {isUpdateActionValueRequestFailed && (
            <Typography style={{ paddingLeft: '43px', color: 'red' }}>
              Internal server error: Your changes could not be saved, because of
              technical issues.
            </Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default PriorAuthCodes;
