import React, { ReactNode } from 'react';
import { Paper, styled as muiStyled } from '@mui/material';
import { Row } from 'components/layout';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import Loader from 'components/loader';

const SpacedRow = styled(Row)`
  justify-content: space-between;
  padding: 0 60px;
`;

const Container = muiStyled(Paper)({
  width: '100%',
  backgroundColor: COLORS.PALE_GREY,
  padding: '18px 0px',
  position: 'fixed',
  bottom: 0,
  left: 0,
  borderTop: `1px solid ${COLORS.LIGHT_GREY}`,
  borderRadius: 0,
});

export interface IFooterContainerProps {
  loading?: boolean;
  children: ReactNode;
  shouldHideLoader?: boolean;
}

export const FooterContainer = ({
  loading,
  children,
  shouldHideLoader,
  ...rest
}: IFooterContainerProps) => {
  return (
    <Container {...rest}>
      <SpacedRow>{children}</SpacedRow>
      {shouldHideLoader ? null : <Loader active={loading ?? false} />}
    </Container>
  );
};
