import React from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { HeaderCellComponent } from './HeaderCellComponent';
import { SortLabel } from './SortLabel';
import { TitleComponent } from 'components/gridFormatters/TitleComponent';
import { TableHeaderRowComponent } from 'components/gridFormatters/TableHeaderRowComponent';

export const SymphonyTableHeaderRow = (props: {
  showSortingControls?: boolean;
}) => (
  <TableHeaderRow
    showSortingControls={props.showSortingControls}
    cellComponent={HeaderCellComponent}
    sortLabelComponent={SortLabel}
    titleComponent={TitleComponent}
    rowComponent={TableHeaderRowComponent}
  />
);
