import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import SimpleTextFormatter from './SimpleTextFormatter';

const SimpleTextProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={SimpleTextFormatter} {...props} />
);

export default SimpleTextProvider;
