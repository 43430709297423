import React from 'react';
import { ILabelCellProps, StarSwitchCell } from 'components/gridFormatters';
import { useDispatch, useSelector } from 'react-redux';
import { updateOwner } from 'store/patientdetails/patientDetails.careTeam.slice';
import { IState } from 'store';
import { updateDirtyTabs } from 'store/patientdetails/patientDetailsSlice';

export const OwnerCell = (props: ILabelCellProps) => {
  const { row } = props;
  const dispatch = useDispatch();
  const isCanChangeOwner = useSelector(
    (state: IState) =>
      state.patientDetailsCareTeam.careTeamTab?.isCanChangeOwner
  );
  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );

  const changeValue = (value: boolean) => {
    if (isCanChangeOwner && value) {
      dispatch(
        updateOwner({
          id: row.id,
          value: value,
        })
      );

      if (!isInEditMode) {
        dispatch(updateDirtyTabs('Care Team'));
      }
    }
  };
  return <StarSwitchCell {...props} changeValue={changeValue} />;
};
