import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type AddProviderToRecentProvidersMutationVariables = Types.Exact<{
  providerId: Types.Scalars['Int'];
}>;

export type AddProviderToRecentProvidersMutation = {
  __typename?: 'Mutation';
  addProviderToRecentProviders?: boolean | null;
};

export const AddProviderToRecentProvidersDocument = `
    mutation addProviderToRecentProviders($providerId: Int!) {
  addProviderToRecentProviders(providerId: $providerId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addProviderToRecentProviders: build.mutation<
      AddProviderToRecentProvidersMutation,
      AddProviderToRecentProvidersMutationVariables
    >({
      query: (variables) => ({
        document: AddProviderToRecentProvidersDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddProviderToRecentProvidersMutation } = injectedRtkApi;
