import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';

export interface IStatusBadgeProps {
  active: boolean;
  activeText?: string;
  inactiveText?: string;
}

export const StatusBadge = ({
  active,
  activeText,
  inactiveText,
}: IStatusBadgeProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          borderRadius: '50%',
          height: '8px',
          width: '8px',
          marginRight: '8px',
          backgroundColor: active ? COLORS.GREEN100 : COLORS.GREY60,
        }}
      />
      <Typography variant="body1">
        {active ? activeText ?? 'Active' : inactiveText ?? 'Inactive'}
      </Typography>
    </div>
  );
};
