import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

interface IInformationDialogProps {
  title: string;
  okButtonText?: string;
  open: boolean;
  okEvent: () => void;
  children: ReactNode;
}

const Information = (props: IInformationDialogProps) => {
  const { open, title, okButtonText, okEvent, children } = props;
  return (
    <Dialog
      open={open}
      onClose={okEvent}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-cy="information-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent data-cy="information-modal-content">
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.okEvent}
          variant="contained"
          color="primary"
          data-cy="ok"
          style={{ height: '30px' }}
        >
          {okButtonText ? okButtonText : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Information;
