import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IErrorState {
  errorMessage: string;
}

export const initialErrorState: IErrorState = { errorMessage: '' };

const errorSlice = createSlice({
  name: 'errorState',
  initialState: initialErrorState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setErrorMessage } = errorSlice.actions;

export default errorSlice.reducer;
