import React from 'react';
import SubHeader from './Items/subHeader';

import MedicationCodes from './Items/MedicationCodes/medicationCodes';
import PriorAuthCodes from './Items/PriorAuthCodes/priorAuthCodes';
import { ChecklistStorageType } from 'store/actions/types';
import ChecklistNotification from './Items/ChecklistNotification';

import Orderable from './Items/Orderable';
import DuplicateCheck from './Items/DuplicateCheck/DuplicateCheck';
import LetterSummary from './Items/LetterSummary/LetterSummary';
import AssociatedClaim from './Items/AssociatedClaim/AssociatedClaim';
import CarePlan from './Items/CarePlan/CarePlan';
import {
  ChecklistItem,
  ChecklistItemAssociatedClaims,
  ChecklistItemDuplicateCheck,
  ChecklistItemAssociatedEpisodes,
  ChecklistItemLetterSummary,
  ChecklistItemMedicalCodes,
  ChecklistItemNote,
  ChecklistItemOrderable,
  ChecklistItemPriorAuthCodes,
  ChecklistItemReminder,
  ChecklistItemSubheader,
  ChecklistProblemViewModel,
  UpdateActionValueModelInput,
  ChecklistItemCarePlans,
} from 'graphql/graphqlTypes';
import AssociatedEpisode from './Items/AssociatedEpisode/AssociatedEpisode';
import HealthServiceWrapper from './HealthServiceWrapper';
import { useSelector } from 'react-redux';
import { IState } from 'store';

export interface ISectionBodyProps {
  checklistItem: ChecklistItem;
  categoryId: string;
  patientId: number;
  storageType: ChecklistStorageType;
  autoSave: boolean;
  orderableIndex: number;
  isReadOnly: boolean;
  episodeId?: number;
  uiDisplayMode: number;
  isDisplayEpisodeLevelRequestedParam: boolean;
  isHsAllowAddingCodes: boolean;
  isHsAllowDeletingCode: boolean;
  hsAuthorizationType: number;
  changeItemValueOnSave: (
    item: ChecklistItem,
    index: number,
    data: UpdateActionValueModelInput
  ) => void;
  removeItemValues: (item: ChecklistItem, index: number) => void;
  selectItemOnSave: (
    item: ChecklistItem,
    index: number,
    value: boolean
  ) => void;
  carePlanProblems?: ChecklistProblemViewModel[];
  updateCarePlanProblems?: (problems: ChecklistProblemViewModel[]) => void;
}

const SectionBody = (props: ISectionBodyProps) => {
  const {
    checklistItem,
    categoryId,
    patientId,
    storageType,
    carePlanProblems,
    isReadOnly,
    autoSave,
    orderableIndex,
    updateCarePlanProblems,
    changeItemValueOnSave,
    selectItemOnSave,
    episodeId,
    uiDisplayMode,
    isDisplayEpisodeLevelRequestedParam,
    isHsAllowAddingCodes,
    isHsAllowDeletingCode,
    hsAuthorizationType,
  } = props;

  const mozartViewOnly = useSelector(
    (state: IState) => state.mozart.mozartViewOnly
  );
  switch (checklistItem.type) {
    case 'orderable': {
      const orderableItem = checklistItem as ChecklistItemOrderable;
      return (
        <Orderable
          item={orderableItem}
          sectionItemId={checklistItem.uid}
          categoryId={categoryId}
          storageType={storageType}
          autoSave={autoSave}
          orderableIndex={orderableIndex}
          isReadOnly={isReadOnly || orderableItem.isReadOnly || mozartViewOnly}
          updateChecklistItemInputValueOnSave={changeItemValueOnSave}
          removeRepeatingItem={props.removeItemValues}
          selectChecklistItemOnSave={selectItemOnSave}
          carePlanProblems={carePlanProblems}
          updateCarePlanProblems={updateCarePlanProblems}
        />
      );
    }
    case 'subheader':
      return (
        <SubHeader
          item={checklistItem as ChecklistItemSubheader}
          storageType={storageType}
        />
      );
    case 'priorauthorization':
      return (
        <PriorAuthCodes
          item={checklistItem as ChecklistItemPriorAuthCodes}
          categoryId={categoryId}
          autoSave={autoSave}
          orderableIndex={orderableIndex}
          storageType={storageType}
          readOnly={isReadOnly || mozartViewOnly}
          patientId={patientId}
          updateChecklistItemInputValueOnSave={changeItemValueOnSave}
          selectChecklistItemOnSave={selectItemOnSave}
        />
      );
    case 'associated episode':
      return (
        <AssociatedEpisode
          item={checklistItem as ChecklistItemAssociatedEpisodes}
          autoSave={autoSave}
          categoryId={categoryId}
          orderableIndex={orderableIndex}
          storageType={storageType}
          isReadOnly={mozartViewOnly}
          updateChecklistItemInputValueOnSave={changeItemValueOnSave}
        />
      );
    case 'medical codes':
      return (
        <MedicationCodes
          item={checklistItem as ChecklistItemMedicalCodes}
          categoryId={categoryId}
          autoSave={autoSave}
          orderableIndex={orderableIndex}
          storageType={storageType}
          updateChecklistItemInputValueOnSave={changeItemValueOnSave}
          selectChecklistItemOnSave={selectItemOnSave}
          readOnly={mozartViewOnly}
        />
      );
    case 'healthservices':
      return (
        <HealthServiceWrapper
          checklistItem={checklistItem}
          categoryId={categoryId}
          autoSave={autoSave}
          orderableIndex={orderableIndex}
          storageType={storageType}
          changeItemValueOnSave={changeItemValueOnSave}
          selectItemOnSave={selectItemOnSave}
          episodeId={episodeId}
          uiDisplayMode={uiDisplayMode}
          readOnly={mozartViewOnly}
          isDisplayEpisodeLevelRequestedParam={
            isDisplayEpisodeLevelRequestedParam
          }
          isHsAllowAddingCodes={isHsAllowAddingCodes}
          isHsAllowDeletingCode={isHsAllowDeletingCode}
          hsAuthorizationType={hsAuthorizationType}
        />
      );
    case 'associated claim':
      return (
        <AssociatedClaim
          item={checklistItem as ChecklistItemAssociatedClaims}
          categoryId={categoryId}
          autoSave={autoSave}
          orderableIndex={orderableIndex}
          storageType={storageType}
          isReadOnly={mozartViewOnly}
          updateChecklistItemInputValueOnSave={changeItemValueOnSave}
        />
      );
    case 'care plan':
      return (
        <CarePlan
          item={checklistItem as ChecklistItemCarePlans}
          categoryId={categoryId}
          storageType={storageType}
          isReadOnly={mozartViewOnly}
        />
      );
    case 'reminder':
      return (
        <ChecklistNotification
          item={checklistItem as ChecklistItemReminder}
          type="reminder"
        />
      );
    case 'note':
      return (
        <ChecklistNotification
          item={checklistItem as ChecklistItemNote}
          type="note"
        />
      );
    case 'duplicate check':
      return (
        <DuplicateCheck
          item={checklistItem as ChecklistItemDuplicateCheck}
          categoryId={categoryId}
          autoSave={props.autoSave}
          orderableIndex={props.orderableIndex}
          storageType={storageType}
          isReadOnly={mozartViewOnly}
          updateChecklistItemInputValueOnSave={props.changeItemValueOnSave}
          selectChecklistItemOnSave={props.selectItemOnSave}
        />
      );
    case 'letter summary':
      return (
        <LetterSummary
          item={checklistItem as ChecklistItemLetterSummary}
          storageType={storageType}
          isReadOnly={mozartViewOnly}
        />
      );
    case 'conditions':
    case 'medications':
    default:
      return null;
  }
};
export default SectionBody;
