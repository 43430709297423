import React, { forwardRef } from 'react';
import DateComponent from 'components/DateComponent';

interface IDatePickerProps {
  value: Date | null;
  onChange: (date: Date | null | unknown) => void;
  error?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
}

const DatePicker = forwardRef<HTMLInputElement | null, IDatePickerProps>(
  (props, ref) => {
    return (
      <DateComponent
        testId="KeyboardDatePicker"
        readOnly={props.readOnly}
        autoFocus={props.autoFocus ?? false}
        placeholder="mm/dd/yyyy"
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        ref={ref}
      />
    );
  }
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
