import React from 'react';
import { Box, Typography } from '@mui/material';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export interface IClaimsDetailsItem {
  label: string;
  value: string;
  isMoney?: boolean;
  isDate?: boolean;
}

const ClaimsDetailsItem = (props: IClaimsDetailsItem) => (
  <Box display="flex" alignItems="center" mb="12px">
    <Typography variant="body2" style={{ paddingRight: '4px' }}>
      {props.label}:
    </Typography>
    <Typography>
      {props.isMoney && props.value ? '$ ' : null}
      {props.value && !props.isDate ? props.value : null}
      {props.value && props.isDate ? formatDate(props.value) : null}
    </Typography>
  </Box>
);

export default ClaimsDetailsItem;
