import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientTimelineDataQueryVariables = Types.Exact<{
  request: Types.TimelineRequestInput;
}>;

export type GetPatientTimelineDataQuery = {
  __typename?: 'EntitiesQuery';
  getPatientTimelineData?: {
    __typename?: 'TimelineResponse';
    localDateTime?: string | null;
    count?: number | null;
    rows?: Array<
      | {
          __typename?: 'PatientTimelineAttachmentRowViewModel';
          id?: string | null;
          date?: string | null;
          externalReferenceId?: string | null;
          individual?: string | null;
          type?: string | null;
          attachmentDescription?: string | null;
          attachmentDetails?: string | null;
          typeClass?: string | null;
          typeTitle?: string | null;
          source?: string | null;
          isLetter?: boolean | null;
          isFax?: boolean | null;
          finishedOn?: string | null;
        }
      | {
          __typename?: 'PatientTimelineChecklistRowViewModel';
          id?: string | null;
          date?: string | null;
          individual?: string | null;
          type?: string | null;
          containsAttachments?: boolean | null;
          operations?: Array<string | null> | null;
          checklistStatus?: Types.ChecklistStatus | null;
          episodeNumber?: string | null;
          workflowStepName?: string | null;
          finishedOn?: string | null;
          orderSets?: Array<{
            __typename?: 'ChecklistOrderSetRowView';
            name?: string | null;
            forecolor?: string | null;
            details?: string | null;
            backcolor?: string | null;
            title?: string | null;
          } | null> | null;
        }
      | {
          __typename?: 'PatientTimelineReminderRowViewModel';
          id?: string | null;
          date?: string | null;
          individual?: string | null;
          type?: string | null;
          reminderDescription?: string | null;
          reminderStatus?: number | null;
          descriptionImage?: string | null;
          reminderDetails?: string | null;
          opened?: boolean | null;
          finishedOn?: string | null;
        }
      | null
    > | null;
  } | null;
};

export const GetPatientTimelineDataDocument = `
    query getPatientTimelineData($request: TimelineRequestInput!) {
  getPatientTimelineData(request: $request) {
    localDateTime
    count
    rows {
      ... on PatientTimelineAttachmentRowViewModel {
        id
        date
        externalReferenceId
        individual
        type
        attachmentDescription
        attachmentDetails
        typeClass
        typeTitle
        source
        isLetter
        isFax
        finishedOn
      }
      ... on PatientTimelineChecklistRowViewModel {
        id
        date
        individual
        type
        containsAttachments
        operations
        checklistStatus
        episodeNumber
        orderSets {
          name
          forecolor
          details
          backcolor
          title
        }
        workflowStepName
        finishedOn
      }
      ... on PatientTimelineReminderRowViewModel {
        id
        date
        individual
        type
        reminderDescription
        reminderStatus
        descriptionImage
        reminderDetails
        opened
        finishedOn
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientTimelineData: build.query<
      GetPatientTimelineDataQuery,
      GetPatientTimelineDataQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientTimelineDataDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetPatientTimelineDataQuery,
  useLazyGetPatientTimelineDataQuery,
} = injectedRtkApi;
